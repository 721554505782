import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { PagesService } from '../pages.service';
import { EMAIL_REGEXP } from '../../constants';
@Component({
  selector: 'jhi-createtenant',
  templateUrl: './CreateTenant.component.html',
  styleUrls: ['./CreateTenant.component.scss'],
  providers: [DialogService],
})
export class CreateTenantComponent implements OnInit {

  CreateTenantDetailsForm = this.fb.group({
    tenantName: [null, [Validators.required]],
    domainName: [null, [Validators.required]],
    newSolutionPageIconChooseFilebtn: [null, []],
  });
  CreateTenantForm = this.fb.group({
    firstName: [null, [Validators.required]],
    middleName: ['', []],
    lastName: [null, [Validators.required]],
    emailAddress: [null, [Validators.required,Validators.pattern(EMAIL_REGEXP.source)]],
  });
  pageloadvalues: any;
  nameFlag: boolean = false;
  defaultFlag: boolean = true;
  defaultFlag2: boolean = true;
  enabledbtn1: boolean = false;
  activeIndex1: number = 0;
  clicked1 = false;
  clicked = true;
  tab1 = false;
  tab2 = false;
  activeIndex2: number = 0;
  uploadedFiles: any[] = [];
  tenantCheckName: any;
  domain: string;
  productPrimaryImage: any;
  tenantLogo: File;
  solImage: boolean;
  formValidFlag: boolean;
  enableBtn = false;
  fieldFlag = true;
  tenantId: any;
  firstSave = false;
  domainEditFlag = true;
  appId: any;
  imageURL = '';
  imageFileFlag: boolean = false;
  pdfFileFlag: boolean = false;
  solImageFileSize: boolean = false;
  imagevalid: boolean = false;
  default: boolean = false;

  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public pagesService: PagesService) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.CreateTenantDetailsForm.patchValue(JSON.parse(data));
    }
  }

  ngOnInit(): void {
    this.enabledbtn1 = true;
  }

  onClose($event: UntypedFormGroup): void {
    this.ref.close();
  }

  onClose1($event: UntypedFormGroup): void {
    this.ref.close();
  }

  /**
   * create tenant in tenants
   */
  domainname() {
    this.tenantCheckName = this.CreateTenantDetailsForm.value.tenantName;
    if (this.CreateTenantDetailsForm.value.tenantName == "") {
      this.domain = "";
    }
    else if (this.domainEditFlag) {
      this.domain = this.tenantCheckName + '.ciam.com';
      this.CreateTenantDetailsForm.value.domainName = this.domain;
    }
  }

  colorChange() {
    document.getElementById('tenantName_id')!.style.borderColor = 'grey';
  }

  showTab() {
    if (this.activeIndex1 === 0) {
      this.activeIndex1 = 1;
    }
  }

  onUpload(event) {
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }
  }

  onSelectSolutionIcon(event: any): void {

    this.tenantLogo = new File([event.files[0]], event.files[0].name, {
      type: event.files[0].type
    });
    this.imageFileFlag = true;
    const isvalid = /(\.jpg|\.jpeg|\.png|\.svg)$/i;
    if (isvalid.test(this.tenantLogo.name)) {
      this.default = true;
      this.solImage = false;
      this.solImageFileSize = false;
      const regex = /[~#%&*{}:<>?/|'"]/g;
      this.productPrimaryImage = event.currentFiles[0].objectURL;
      let res = regex.test(this.tenantLogo.name);
      if (res) {
        this.solImage = true;
        this.formValidFlag = false;
      }
      else if (event.currentFiles[0].size > 1000000) {
        this.solImageFileSize = true;
        this.formValidFlag = false;
      }
      else {
        setTimeout(() => {
        }, 1000);
      }
    } else {
      this.imagevalid = true;
    }
  }

  onRemoveIcon(event: any, fileUploadPageIconChooseFilebtn): void {
    const index = fileUploadPageIconChooseFilebtn.files.indexOf(
      event
    );
    fileUploadPageIconChooseFilebtn.files.splice(index, 1);
    this.productPrimaryImage = "";
    this.imagevalid = false;
    this.solImageFileSize = false;
    setTimeout(() => {
    }, 1000);
  }

  checkValidation() {
    throw new Error('Method not implemented.');
  }

  // check availability for tenant name
  onSaveCheckTenantName($event: UntypedFormGroup): void {
    const reqBody = {};
    reqBody['tenantName'] = this.CreateTenantDetailsForm.value.tenantName;
    const formData = reqBody;
    this.pagesService.onCheckTenantName(formData).subscribe(
      results => {
        if (results?.error?.code == 400 || results?.success?.code == 400) {
          this.nameFlag = true;
          document.getElementById('tenantName_id')!.style.borderColor = 'red';
        } else if (results.success.code == 200) {
          this.nameFlag = false;
          document.getElementById('tenantName_id')!.style.borderColor = 'grey';
          this.onSaveCreateTenant();
        }
      },
    );
  }

  onSaveCreateTenant() {
    const formdata = new FormData();
    const formData = formdata;
    if (this.tenantLogo != null) {
      formdata.append('logoFile', this.tenantLogo ?? null);
    }
    formdata.append('name', this.CreateTenantDetailsForm.value.tenantName);
    formdata.append('tenantDomain', this.CreateTenantDetailsForm.value.domainName);
    this.pagesService.onSaveCreateTenant(formData).subscribe(
      {
        next: results => {
          this.tenantId = results.success.result.id
          this.tab1 = true;
          this.showTab();


          this.CreateTenantDetailsForm.controls['tenantName'].disable();
          this.CreateTenantDetailsForm.controls['domainName'].disable();
          this.firstSave = true;
          this.pagesService.createTenantSave = true;
        },
        error: error => {
          this.ref.close("Tenant Creation Failed");
        }
      });
  }

  // saving owner details API
  onSaveTenantOwnerDetails() {
    const reqBody = {};
    reqBody['firstName'] = this.CreateTenantForm.value.firstName;
    reqBody['middleName'] = this.CreateTenantForm.value.middleName;
    reqBody['lastName'] = this.CreateTenantForm.value.lastName;
    reqBody['email'] = this.CreateTenantForm.value.emailAddress.toLowerCase();
    const formValues = reqBody;
    const data = this.tenantId;
    this.pagesService.onSaveTenantOwnerDetails(formValues, data).subscribe(
      {
        next: results => {
          this.tab2 = true;
          this.pagesService.createTenantSave = false;
          this.ref.close("Tenant Details Saved Successfully");
        },
        error: error => {
          this.ref.close("Tenant Creation Failed");
        }
      });
  }

}