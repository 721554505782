import { Component, OnInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute, Router, NavigationExtras } from "@angular/router";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { PagesService } from "../pages.service";
import { validateAllFormFields } from "app/shared/util/reactiveform-util";
import { MessageService } from "primeng/api";
import { DatePipe } from "@angular/common";
import { REBAC_UM_ENTITLEMENT_FLAG } from "app/app.constants";
import { EMAIL_REGEXP } from '../../constants';
import {
  ADMS,
  ALLOW,
  APPROVAL,
  APPROVED,
  COMPONENT,
  DENY,
  DROPDOWN,
  ENTITLEMENT,
  IMPORT,
  INACTIVE,
  INHERITED,
  INTERNAL,
  INTERNAL_USER_TYPE,
  NOT_APPROVED,
  NUMERIC,
  ONE,
  OVERRIDDEN,
  PENDING,
  RAISED_INDEPENDENT,
  SOURCE_ROLE,
  STATUS,
  TWO,
  ZERO,
} from "app/constants";
import { UserDetailsService } from "./EditUser.service";
import { DateFormatterPipe } from "app/pipes/date-formatter.pipe";
import { DataService } from "./user-details-main-service";
import { ContextName, EventName } from "app/shared/constants/logging-event.constants";
@Component({
  selector: "jhi-edituser",
  templateUrl: "./EditUser.component.html",
  styleUrls: ["./EditUser.component.scss"],
  providers: [MessageService, DatePipe, DateFormatterPipe],
})
export class EditUserComponent implements OnInit {
  EditUserForm = this.fb.group({
    uid: [null, []],
    firstName: [null, [Validators.required]],
    middleName: [" ", []],
    lastName: [null, [Validators.required]],
    emailAddress: [null, [Validators.required, Validators.email,Validators.pattern(EMAIL_REGEXP.source)]],
    phoneNumbers: [null, []],
    userName: [null, []],
    phoneID: [null, []],
    select2: [null, []],
    select4: [null, []],
    roleList: [null, []],
    groupList: [null, []],
    countryCode: [null, []],
    selectSwitch: [null, []],
    selectedEntRole: [null, []],
    selectedEntitlementDropdown: [null, []],
    selectedComponent: [null, []],
    entitlementSearch: [null, []],
    account:[null,[]]
  });
  form: UntypedFormGroup | undefined;
  pageloadvalues: any;
  @ViewChild("manageUserComp") manageUserComp: any;
  @ViewChild("manageUserEnt") manageUserEnt: any;
  @ViewChild("manageUserStatus") manageUserStatus: any;
  @ViewChild("manageUserSource") manageUserSource: any;
  @ViewChild("manageUserRaisedIndependent") manageUserRaisedIndependent: any;
  ccprimebreadcrumb1List = [
    { label: `User Management` },
    { label: `Users`, routerLink: ["/pages/userlisting"] },
    { label: `Edit User` },
  ];
  homeiconccprimebreadcrumb1 = { icon: "pi pi-home", routerLink: ["/"] };
  selectSwitchList: any[] = [
    { label: "Internal", value: "false" },
    { label: "External", value: "true" },
  ];
  select2List: any[] = [];
  select4List: any[] = [];
  messageFlag = true;
  codes: any[] = [];
  activeIndex1: number = 0;
  clicked1 = false;
  clicked = true;
  enableBtn1 = true;
  enableBtn2 = true;
  enableBtn3 = true;
  activeIndex2: number = 0;
  roleListList: any[] = [];
  groupListList: any[] = [];
  selectedRole: any[] = [];
  selectedGroup: any[] = [];
  exRoleList: any[] = [];
  newRoleList: any[] = [];
  exGroupList: any[] = [];
  newGroupList: any[] = [];
  savedId: any;
  data: any;
  navigation: any;
  userID = "";
  tab1 = false;
  tab2 = false;
  tab3 = false;
  tab4 = false;
  assignGroupCount = 0;
  deAssignGroupCount = 0;
  assignRoleCount = 0;
  deAssignRoleCount = 0;
  msg: string[] = [];
  msg1: string[] = [];
  oldFirstName: any;
  oldMiddleName: any;
  oldLastName: any;
  oldPhoneId: any;
  oldCountryCode: any;
  fullNameFlag = false;
  oldPhoneNumber: any;
  codeFlag = false;
  phoneNumberFlag = false;
  customJSON: any;
  customList: any = [];
  fieldTypeList: any = [];
  cnt = 0;
  checked = false;
  value4: string;
  moduleID = "";
  checkboxArr: any = [];
  customFielDto = [];
  customFieldValues = [];
  date3: string;
  disabledFlag: boolean;
  someArr: any = [];
  wfFlag = false;
  defaultselect_selectSwitch: any;
  userType: any;
  isExternal: any;
  oldUserName: any;
  oldUserType: any;
  userNameCheckError: boolean = false;
  oldEmail: any;

  //entitlement
  userAttributeData: any = [];
  orginalUserAttributeData: any = [];
  attributeColumns: {
    field: string;
    header: string;
    filterMatchMode: string;
    display: boolean;
  }[];
  attributeList: any = [];
  attributeResultList: (
    | {
      id: string;
      fieldName: string;
      fieldInputtype: string;
      mandatoryField: boolean;
      maxCharacters: string;
      pattern: any;
      specialChar: string;
      inputFormat: string;
      isExisting: boolean;
      fieldValue: string;
      isConfigurable: boolean;
      inherited: boolean;
    }
    | {
      id: string;
      fieldName: string;
      fieldInputtype: string;
      mandatoryField: boolean;
      maxCharacters: string;
      pattern: any;
      specialChar: string;
      inputFormat: { label: string; value: string }[];
      isExisting: boolean;
      fieldValue: { label: string; value: string };
      isConfigurable: boolean;
      inherited: boolean;
    }
  )[];
  existingParameterList: any[];
  selectedUserAttribute: any[] = [];
  isInvalidIpRange: boolean = false;
  addButtonEnable = true;
  showAttributeValueFlag: boolean;
  userAttrColumnList: any[];
  attributeName: any;
  parameterData: {
    id: string;
    fieldName: string;
    fieldInputtype: string;
    mandatoryField: boolean;
    maxCharacters: string;
    pattern: any;
    specialChar: string;
    inputFormat: string;
    isExisting: boolean;
    fieldValue: string;
    isConfigurable: boolean;
    inherited: boolean;
  }[];
  count = 0;
  userParameterList: any[] = [];
  currentRowIndex: any;
  currentAttribute: any;
  deleteUserAttribute: boolean;
  confirmMessage: string;
  userParameterFlag = false;
  attributeTableList: any[] = [];
  prevattributeTableList: any[] = [];
  enableConfigFlag = false;
  firstSave = false;
  attributeList1: any[] = [];
  updatedAttributeTableList: any[] = [];
  paraArray: any = [];
  inputLength = 0;
  rebacFlag: any;
  IpData: any;
  childSetIPCheck: boolean;
  mastercheck: boolean;
  IP4startValue: any;
  IP4endValue: any;
  masterSetIP = new Set();
  childSetIP = new Set();
  childStartCheckFlag: boolean;
  childEndCheckFlag: boolean;
  IpList: any = [];
  index: any = 0;
  ipTest: boolean;
  checkIPCondition: boolean;

  IpData1: any;
  IpList1: any = [];
  ipTest1: boolean;
  checkIPConditionDialog: boolean;
  isInvalidIpRangeDialog: boolean = true;

  ipRangeList: any = [];
  closeOverlay: boolean;
  disableButtons: any;
  isServiceLineBlank: any;
  isSuccess = false;
  isFailure = false;
  successMessage: string = "";
  failureMessage: string = "";
  userId: any;
  bankColumns = [];
  userRoleColumns = [];
  entitlementColumns = [];
  userRoleList: any[] = [];
  bankList: any[] = [];
  entitlementList: any[] = [];
  isAddNewBank: boolean = false;
  bankDropdownList: any;
  serviceLineDropdownList: any;
  selectedBank: any;
  selectedServiceLine: any;
  user: any;
  isServiceDropdown: boolean = false;
  newlyAddedBanks: any[] = [];
  isConfirmDialog: boolean = false;
  isImportConfirmDialog: boolean = false;
  actionTable: any;
  updateBankId: any;
  isBankServiceLineExist: boolean = false;
  selectedBankFilter: any = [];
  isBankFilterSelection: boolean = false;
  isEntitlementFilterSelection: boolean = false;
  userDetailsResp: string;
  emailUpdateOn: string;
  timeStamp: boolean = false;
  onTabChange: boolean = false;
  navigationMessage: string;
  deletedEnt: any;
  deletedUserEntIdList: any[] = [];
  deletedUserEntList: any[] = [];
  userTypeId: any;
  emailSendButtonLabel: string = "Send E-mail";
  hideTooltip: boolean;
  emailSendButtonTooltip: string = "";
  activeTab: any = {
    isBankTab: false,
    isServiceLineTab: false,
  };

  activeGlobalTab: any = {
    isGlobalBankTab: false,
    isGlobalServiceLineTab: false,
    isGlobalRoleTab: false,
    isGlobalComponentTab: false,
    isGlobalEntitlementTab: false,
  };

  activeEntitlementTab: any = {
    isComponentTab: false,
    isEntitlementTab: false,
    isSourceTab: false,
    isRaisedIndependentlytab: false,
    isStatusTab: false,
    isApprovalTab: false,
  };

  bankFilterDropdown: any = [];
  serviceFilterDropdown: any = [];
  componentFilterDropdown: any = [];
  entitlementFilterDropdown: any = [];
  selectedServiceFilter: any = [];
  originalBankList: any = [];
  bankFilterSidebarCount: number = 0;
  globalFilterSidebarCount: number = 0;
  EntitlmentFilterSidebarCount: number = 0;
  appliedServiceFilter: any = [];
  appliedBankFilter: any = [];
  userList: any = [];
  selectedUser: any = [];
  originalUserRoleBankServiceLine: any = [];
  userRoleBankServiceLineList: any = [];
  roleListFilterDropdown: any = [];
  selectedUserRoleBank: any = [];
  selectedUserRoleBankServiceLine: any = [];
  isRoleServiceDropDown: boolean;
  selectedRowRoleId: any;
  selectedRowBankId: any;

  selectedComponent: any;
  selectedEntitlement: any;

  appliedComponentFilter: any = [];
  appliedEntitlementFilter: any = [];
  appliedStatusFilter: any = [];

  appliedNoApprovalRequired: boolean = false;
  selectedNoApprovalRequired: boolean = false;
  isNoApprovalRequiredData: any = [];
  selectedStatusFilter: any = [];
  selectedComponentFilter: any = [];
  selectedEntitlementFilter: any = [];
  componentDropdownList: any[] = [];
  entitlementDropdownList: any[] = [];
  isEntitlementDropdown: boolean = false;
  isAddNewEntitlement: boolean = false;
  isEntitlementExist: boolean = false;
  originalEntitlementList: any[] = [];
  isOverrideDialog: boolean = false;
  overrideColumns = [];
  overrideList: any = [];
  entitlementStatusDropdownList: any = [];
  allowStatusId: any;
  pendingStatusId: any;
  updatedEntitlementIndex: any;
  selectedOverrideStatus: any;
  isOverideChangesDisplay: boolean = false;
  modifiedOverrideColumns: any;
  isOverrideFilterSelection: boolean = false;
  statusFilterDropdown: any[] = [];
  selectedOverrideStatusFilter: any[] = [];
  appliedOverrideStatusFilter: any[] = [];
  originalOverrideList: any[] = [];
  appliedOverrideServiceFilter: any = [];
  appliedOverrideBankFilter: any = [];
  selectedOverrideBankFilter: any = [];
  selectedOverrideServiceFilter: any = [];
  isServiceLineEmptyForBank: boolean = false;
  isServiceLineEmptyForRole: boolean = false;
  updatedBankName: string;

  isRoleFilterSelection: boolean = false;
  roleFilterSidebarCount: number = 0;

  roleFilterDropdown: any = [];
  roleBankFilterDropdown: any = [];
  roleServiceFilterDropdown: any = [];
  selectedRoleBankFilter: any = [];
  selectedRoleFilter: any = [];
  selectedRoleServiceFilter: any = [];

  appliedRoleFilter: any = [];
  appliedRoleServiceFilter: any = [];
  appliedRoleBankFilter: any = [];

  isGlobalFilterSelection: boolean = false;
  appliedGlobleBankFilter: any = [];
  appliedGlobleServiceFilter: any = [];
  appliedGlobleRoleNameFilter: any = [];
  appliedGlobleComponentNameFilter: any = [];
  appliedGlobleEntilementNameFilter: any = [];

  selectedGlobaleBankFilter: any = [];
  selectedGlobalServiceFilter: any = [];
  selectedGlobalRoleFilter: any = [];
  selectedGlobalComponentFilter: any = [];
  selectedGlobalEntitlementFilter: any = [];

  bankServiceFilterDropdown: any = [];
  bankGlobalFilterDropdown: any = [];
  filterTab: any = {
    isRoleTab: false,
    isBankTab: false,
    isServiceLineTab: false,
  };

  isEntitlementRoleDialog: boolean = false;
  entitlementRoleList: any = [];
  entitlementRoleColumns: any = [];
  selectedUserToImport: any;
  importUserList: any = [];
  entitlementStatusResponse: any = [];
  roleNameList: any[] = [];
  roleBankList: any[] = [];
  roleServiceLineList: any[] = [];
  selectedRoleEnt: any; //changed from selectedRole
  selectedRoleBank: any;
  selectedRoleServiceLine: any;
  isRoleBankDropdown: boolean = false;
  isRoleServiceLineDropdown: boolean = false;
  intersectingBankServiceRoleRecords: any[] = [];
  isRoleBankServiceLineExist: boolean = false;
  changeUserDropdown: any = [];
  splittedRoleBankServiceLineList: any[] = [];
  onRoleServiceLineHover: string[] = [];
  onRoleBankHover: string[] = [];
  selectedRowRoleName: any;
  bankServiceLineList: any[] = [];

  totalDependentCount: number = 0;
  dependentColumns = [];
  dependentList: any[] = [];
  isDependentDialog: boolean = false;
  dependentComponentName: string;
  unSavedChanges: boolean = false;
  nextRouteInfo: any;
  userMap = new Map();
  organizationList: any[] = [];
  userAttributeColumns = [];
  updatedAttr = [];
  isOrgAssigned: any = 0;
  filterMessage = "";
  isOrgAssignedToUser: boolean;

  originalAttributeList = [];
  discardPopUp: boolean = false;
  selectedSourceFilter: any = [];
  appliedSourceFilter: any = [];
  sourceFilterDropdown: any[] = [];
  isRaisedIndependentlyData: any = [];
  selectedRaisedIndependentlyDataFilter: any = [];
  appliedRaisedIndependentDataFilter: any = [];
  deletedEntOfUser: any = [];
  isEmailSendFlag: any;
  entitlementsAssociatedWithDeletedRole: any = [];
  entitlementsAssociatedWithAddedRole: any = [];
  userDeleteEntList: any = [];
  userAddedEntList: any = [];

  dependencyEntitlementList: any[] = [];
  selectedOrganisation: any = {};
  organizationName: string = "";
  userAttributeList: any[] = [];
  activeUserTab: any = {
    isUserAttributeTab: true,
  };
  appliedUserAttribute: any = "";
  selectedUserAttrValue: any;
  appliedRoleType: any = "";
  addAtrributeFlag: boolean = false;
  isOrganizationUpdateFlag: boolean = false;
  orgId: any;
  isAssignButtonDisabled: boolean = true;
  isEntitlementDisabled: boolean = false;
  isSaveAndApplyBtnDisabled: boolean = true;

  originalUserFilterDropdown = [];
  attributeIdsList = [];

  selectedUmStatusName: string;
  selectedUmStatusId: number;
  umStatusList: any;
  selectedUmStatus: any;
  orgStatus: string;
  isUserChanged: boolean = false;
  currentUser: any;
  previousUser: any;
  message: string;
  adms_entitlement: string = "";
  compId_concat: string = "";
  search: any;
  searchArray: any[] = [];
  isAddNewUserRole: boolean = false;
  selectedUserRole: any;
  roleUserList: any[] = [];
  userRoleDropdown: any = [];
  entitlementCount: any;
  originalUserRoleList: any[] = [];
  userRole: any;
  existngRoleIds: any = [];
  roleEntitlementSection: any = {};
  loginUserName: string;
  showFilter: boolean = false;
  roleDeletePopUp: boolean = false;
  roleNameWithId: any;
  rowIndexDelete: number = 0;
  deleteRoleIds: any = [];
  role_Name: string = "";
  roleUserName: string = "";
  userWithIdName: any;
  sourceCountWithRole: number = 0;
  totalSourceCount: number = 0;
  listOfRole: any;
  finalDeleteRoleIds: any = [];
  selectedUserRoleData: any;
  isOrganizationAttr: any = [];
  previousAttrList = [];
  changeUserFlag: boolean = false;
  deletedRoleInfo: any[] = [];
  userDeletedAttributeList: any[] = [];
  umPKId: any;
  LOGIN_ID: any;
  USER_STATUS: any;
  accountStatus: any;
  firstSaveEnt = false;
  applicationList: any[] = [];
  InactiveStatus = INACTIVE;

  lastMailSent: any;
  enabledTooltip: boolean = false;
  userFullName :any;
  loginUserStatus:any;
  emailFlag: boolean;
  emailInvalidFlag: boolean;
  emailsID: any;
  autocompleteList: any[] = [];
  noUserFound: boolean = false;
  userFound: boolean = false;
  accountList: any[]=[];
  preValueCount=1;
  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    private messageService: MessageService,
    public datepipe: DatePipe,
    private userDetailsService: UserDetailsService,
    private dateFormatterPipe: DateFormatterPipe,
    private cdr: ChangeDetectorRef,
    private dataService: DataService
  ) {
    this.navigation = this.router.getCurrentNavigation();
    this.data = this.navigation?.extras?.state?.data;
    if (this.data) {
      this.setPageProperties(JSON.parse(this.data));
    }
    let userName: string;
    userName = localStorage.getItem("username");
    if (userName.length > 0) {
      this.loginUserName = userName;
    } else {
      this.loginUserName = "anonymousUser";
    }
    this.umPKId = this.navigation?.extras?.state?.umPkId;
  }

  ngOnInit(): void {
    this.rebacFlag = REBAC_UM_ENTITLEMENT_FLAG + "";
    this.EditUserForm.get('account').disable();
    this.getApplicationList();
    this.getEntityList();
    this.getroleList();
    this.getgroupList();
    this.getExRoleList();
    this.getExGroupList();
    this.getCountryCode();
    this.EditUserForm.get("selectedEntitlementDropdown").disable();
    this.isEntitlementDropdown = true;
    this.entitlementColumns = [
      {
        field: "ENT ID",
        header: "ENT Id",
        filterMatchMode: "contains",
        display: true,
      },
      {
        field: "COMPONENT_NAME",
        header: "Component Name",
        filterMatchMode: "contains",
        display: true,
      },
      {
        field: "ENTITLEMENT_NAME",
        header: "Entitlement Name",
        filterMatchMode: "contains",
        display: true,
      },
      {
        field: "ENTITLEMENT_STATUS_NAME",
        header: "Status",
        filterMatchMode: "contains",
        display: true,
      },
      {
        field: "OWNER_UM_ENTITLEMENT_ID",
        header: "Owner",
        filterMatchMode: "contains",
        display: true,
      },
      {
        field: "APPROVAL_LEVELS",
        header: "Approval Status",
        filterMatchMode: "contains",
        display: true,
      },
      {
        field: "NOTIFICATION_ENTITLEMENT_IDS",
        header: "Notification",
        filterMatchMode: "contains",
        display: true,
      },
    ];
    this.dependentColumns = [
      {
        field: "COMPONENT_NAME",
        header: "Component Name",
        filterMatchMode: "contains",
        display: true,
      },
      {
        field: "ENTITLEMENT",
        header: "Entitlement Name",
        filterMatchMode: "contains",
        display: true,
      },
    ];

    this.userAttributeColumns = [
      {
        field: "",
        header: "",
        filterMatchMode: "contains",
        display: true,
        width: "5%",
      },
      {
        field: "NAME",
        header: "Attributes",
        filterMatchMode: "contains",
        display: true,
        width: "30%",
      },
    ];
    this.userRoleColumns = [
      {
        field: "Role ID",
        header: "Role ID",
        filterMatchMode: "contains",
        display: true,
      },
      {
        field: "Role Name",
        header: "Role Name",
        filterMatchMode: "contains",
        display: true,
      },
      {
        field: "Updated On",
        header: "Updated On",
        filterMatchMode: "contains",
        display: true,
      },
    ];
    this.attributeColumns = [
      {
        field: "COMPONENT_NAME",
        header: "Attributes",
        filterMatchMode: "contains",
        display: true,
      },
      {
        field: "ENTITLEMENT",
        header: "Value (S)",
        filterMatchMode: "contains",
        display: true,
      },
      {
        field: "ENTITLEMENT",
        header: "State",
        filterMatchMode: "contains",
        display: true,
      },
      {
        field: "ACTION",
        header: "",
        filterMatchMode: "contains",
        display: true,
      },
    ];

    if (this.rebacFlag == "true") {
      this.refreshUserDetails(null);
      this.fetchAttributeList();
      this.getExistingParameter();
      this.onAccountsdropdown();
    }
    this.emailSentDetails();
    //this.onEditUserPageLoad();
  }
  checkUserName() {
    const regularExpressionEmail = /(?=.{1,64}@)[A-Za-z0-9_-]+(\.[A-Za-z0-9_-]+)*@[^-][A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,})$/;
    const userNameValue = this.EditUserForm.value.userName;
    const validateEnd = regularExpressionEmail.test(
      String(userNameValue).toLowerCase()
    );
    if (validateEnd) {
      if(this.isExternal==this.selectSwitchList[1].value){
        if (this.EditUserForm.value.emailAddress==this.EditUserForm.value.userName) {
          this.userNameCheckError = false;
          document.getElementById("userName_id")!.style.borderColor = "grey";
        } 
        else {
          this.userNameCheckError = true;
          document.getElementById("userName_id")!.style.borderColor = "red";
        }
      }
      else if(this.isExternal==this.selectSwitchList[0].value){
        if (this.oldEmail==this.EditUserForm.value.userName) {
          this.userNameCheckError = false;
          document.getElementById("userName_id")!.style.borderColor = "grey";
        } 
        else {
          this.userNameCheckError = true;
          document.getElementById("userName_id")!.style.borderColor = "red";
        }
      }
      
    } else {
      this.userNameCheckError = false;
      document.getElementById("userName_id")!.style.borderColor = "grey";
    }
  }
  // get entity list
  getEntityList(): void {
    this.pagesService.getEntity().subscribe((results) => {
      const responseData = results?.success?.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          if (data.label == "Users") {
            this.moduleID = data.value;
            this.fetchCustomField();
          }
        });
      }
    });
  }

  getCountryCode() {
    this.pagesService.getCountryCode().subscribe((results) => {
      const responseData = results?.success?.result;
      if (responseData && responseData?.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
            name: null,
          };
          data.label = element.countryCode;
          data.value = element.countryCode;
          data.name = element.countryName;
          this.codes.push(data);
        });
        this.codes = [...this.codes];
      }
    });
  }

  setDefaultValue_selectSwitch() {
    if (this.isExternal) {
      this.defaultselect_selectSwitch = this.selectSwitchList[1]?.value;
      return this.defaultselect_selectSwitch;
    } else {
      this.defaultselect_selectSwitch = this.selectSwitchList[0]?.value;
      return this.defaultselect_selectSwitch;
    }
  }

  checkNumberSo(codeFlag: any) {
    if (
      (codeFlag == "setNumber" &&
        this.EditUserForm.value.countryCode == null) ||
      this.EditUserForm.value.countryCode == "" ||
      (this.EditUserForm.value.countryCode == null &&
        (this.EditUserForm.value.phoneNumbers != null ||
          this.EditUserForm.value.phoneNumbers != ""))
    ) {
      this.enableBtn1 = true;
      this.codeFlag = true;
    } else if (
      this.EditUserForm.value.countryCode != null &&
      (this.EditUserForm.value.phoneNumbers != null ||
        this.EditUserForm.value.phoneNumbers != "")
    ) {
      this.enableBtn1 = false;
    }
  }

  CheckNumber(codeFlag: any) {
    this.codeFlag = false;
    this.phoneNumberFlag = false;
    if (
      codeFlag == "setCode" &&
      (this.EditUserForm.value.phoneNumbers == null ||
        this.EditUserForm.value.phoneNumbers == "") &&
      (this.EditUserForm.value.countryCode == "" ||
        this.EditUserForm.value.countryCode == null)
    ) {
      this.enableBtn1 = false;
    } else if (
      codeFlag == "setNumber" &&
      (this.EditUserForm.value.countryCode == null ||
        this.EditUserForm.value.countryCode == "") &&
      (this.EditUserForm.value.phoneNumbers == null ||
        this.EditUserForm.value.phoneNumbers == "")
    ) {
      this.enableBtn1 = false;
    } else if (
      (codeFlag == "setCode" && this.EditUserForm.value.phoneNumbers == null) ||
      this.EditUserForm.value.phoneNumbers == ""
    ) {
      this.enableBtn1 = true;
      this.phoneNumberFlag = true;
    }

    this.checkNumberSo(codeFlag);

    if (this.EditUserForm.get("phoneNumbers").errors?.pattern) {
      this.codeFlag = false;
      this.phoneNumberFlag = false;
    }
  }

  onEditUser():void{
    // $event.updateValueAndValidity();
    // if (!$event.valid) {
    //   validateAllFormFields($event);
    //   return;
    // }
    const reqBody = {};
    reqBody["tenantId"] = this.pagesService.tenantId;
    reqBody["id"] = this.userID;


    if (
      (this.EditUserForm.value.phoneNumbers == null ||
        this.EditUserForm.value.phoneNumbers == "") &&
      this.EditUserForm.value.countryCode == null
    ) {
      reqBody["phoneNumbers"] = [];
    } else if (this.oldPhoneNumber != this.EditUserForm.value.phoneNumbers) {
      reqBody["phoneNumbers"] = [
        {
          countryCode: this.EditUserForm.value.countryCode,
          id: this.EditUserForm.value.phoneID,
          value: this.EditUserForm.value.phoneNumbers,
        },
      ];
    }
    if (
      (this.EditUserForm.value.phoneNumbers == null ||
        this.EditUserForm.value.phoneNumbers == "") &&
      this.EditUserForm.value.countryCode == null
    ) {
      reqBody["phoneNumbers"] = [];
    } else if (this.oldCountryCode != this.EditUserForm.value.countryCode) {
      reqBody["phoneNumbers"] = [
        {
          countryCode: this.EditUserForm.value.countryCode,
          id: this.EditUserForm.value.phoneID,
          value: this.EditUserForm.value.phoneNumbers,
        },
      ];
    }
    if (this.oldFirstName != this.EditUserForm.value.firstName) {
      this.fullNameFlag = true;
      reqBody["first_name"] = this.EditUserForm.value.firstName;
    }
    if (this.oldMiddleName != this.EditUserForm.value.middleName) {
      this.fullNameFlag = true;
      if (this.EditUserForm.value.middleName == null) {
        reqBody["middle_name"] = " ";
        this.EditUserForm.value.middleName = " ";
      } else {
        reqBody["middle_name"] = this.EditUserForm.value.middleName;
      }
    }
    if (this.oldLastName != this.EditUserForm.value.lastName) {
      this.fullNameFlag = true;
      reqBody["last_name"] = this.EditUserForm.value.lastName;
    }
    if (this.fullNameFlag) {
      if (this.EditUserForm.value.middleName == null) {
        const fname = this.EditUserForm.value.firstName + ' ' + this.EditUserForm.value.lastName;
        reqBody['full_name'] = fname.replace(/\s+/g, ' ').trim();
      }
      else {
        const fname = this.EditUserForm.value.firstName + ' ' + this.EditUserForm.value.middleName + ' ' + this.EditUserForm.value.lastName;
        reqBody['full_name'] = fname.replace(/\s+/g, ' ').trim();
      }
    }
    reqBody["isExternal"] = this.isExternal;
    if (this.oldUserName != this.EditUserForm.value.userName) {
      reqBody["user_name"] = this.EditUserForm.value.userName?.toLowerCase();
    }
    
    if(this.EditUserForm.value.selectSwitch==this.selectSwitchList[1].value || this.isExternal==this.selectSwitchList[1].value){
      if(this.oldEmail!=this.EditUserForm.value.emailAddress){
        reqBody['emails'] = [{ value: this.EditUserForm.value.emailAddress.toLowerCase(), type: 'work', primary: true ,id:this.emailsID}];
      }
    }
    reqBody["customFieldDataDtoList"] = [];
    if (this.customList && this.customList.length > 0) {
      this.customList.forEach((element: any = {}) => {
        const data = {
          customFieldId: null,
          customFieldLabel: null,
          customFieldValue: null,
        };
        data.customFieldId = element.id;
        data.customFieldLabel = element.fieldName;
        if (element.fieldInputtype == "Dropdown") {
          let cc = this.EditUserForm.value[element.id];

          if (cc == null) {
            // dont send
          } else {
            data.customFieldValue = this.EditUserForm.value[element.id];
          }
        } else if (element.inputFormat[0] == "Date range") {
          data.customFieldValue = JSON.stringify(
            this.EditUserForm.value[element.id]
          );
        } else if (element.inputFormat[0] == "Text/Number range") {
          const reqRange = {};
          reqRange["start"] = this.EditUserForm.value[element.id + "_start"];
          reqRange["last"] = this.EditUserForm.value[element.id + "_end"];
          if (reqRange["start"] == null || reqRange["last"] == null) {
            data.customFieldValue = null;
          } else {
            data.customFieldValue = JSON.stringify(reqRange);
          }
        } else {
          data.customFieldValue = this.EditUserForm.value[element.id];
        }
        if (data.customFieldValue == null || data.customFieldValue == "") {
          // dont push
        } else {
          this.customFielDto.push(data);
        }
      });
      this.customFielDto = [...this.customFielDto];
    }

    if (this.checkboxArr && this.checkboxArr.length > 0) {
      this.checkboxArr.forEach((element: any = {}) => {
        const data = {
          customFieldId: null,
          customFieldLabel: null,
          customFieldValue: null,
        };
        data.customFieldId = element.id;
        data.customFieldLabel = element.fieldName;
        data.customFieldValue = this.EditUserForm.value[element.id];
        if (data.customFieldValue == null || !data.customFieldValue) {
          data.customFieldValue = "false";
        } else {
          data.customFieldValue = "true";
        }
        this.customFielDto.push(data);
      });
      this.customFielDto = [...this.customFielDto];
    }
    reqBody["customFieldDataDtoList"] = this.customFielDto;
    let configParamJson = [];
    if (this.rebacFlag == "true") {
      
      this.attributeTableList.forEach((element) => {


        if(element.fieldName=="Multifactor Authentication" && !element.inherited){
          if(this.preValueCount==1){
            this.prevattributeTableList.push(element);
          }
        }

        if(element.fieldName.includes("IPRange") && element.inherited !=null){
          if(this.preValueCount==1){
            this.prevattributeTableList.push(element);
            
          }
        }

        if (!element.inherited || element.inherited == null) {
          const data = {
            id: null,
            fieldName: null,
            fieldInputtype: null,
            inputFormat: null,
            mandatoryField: null,
            editableField: null,
            maxCharacters: null,
            pattern: null,
            specialChar: null,
            fieldValue: null,
            inherited: null,
            isDeleteAble: null,
          };
          data.id = element.id;
          data.fieldName = element.fieldName;
          data.fieldInputtype = element.fieldInputtype;
          data.mandatoryField = element.mandatoryField;
          data.editableField = element.editableField;
          data.maxCharacters = element.maxCharacters;
          data.pattern = element.pattern;
          data.specialChar = element.specialChar;
          data.inherited = element.inherited;
          data.inputFormat = element.inputFormat;
          data.isDeleteAble = element.isDeleteAble;
          if (data.fieldInputtype == "Dropdown") {
            data.fieldValue = {
              label: this.EditUserForm.value[element.id],
              value: this.EditUserForm.value[element.id],
            };
          } else {
            data.fieldValue = this.EditUserForm.value[element.id];
          }

          configParamJson.push(data);
        }
      });
      this.preValueCount++;
      reqBody["userParamJson"] = JSON.stringify(configParamJson);
      reqBody["attributeTableList"]= this.attributeTableList;
      reqBody["prevattributeTableList"]= this.prevattributeTableList;
      reqBody["USER_ID_WITH_LOGIN_ID"]= this.umPKId + ' - ' +this.EditUserForm.get('userName').value.toLowerCase();

    }
    if (this.rebacFlag == "true") {
      reqBody['org_name'] = this.accountList.filter(a => a.value.includes(this.EditUserForm.get('account').value))[0].label;
    }
    
    const formData = reqBody;
    this.messageFlag = true;
    this.pagesService.onEditUser(formData).subscribe({
      next:
        (results) => {
          if (results?.success?.messageCode == "CIAM 154") {
            this.navigateToMyReq("Edit User request has been sent for approval");
          } else if (results?.error?.messageCode == "CIAM 113") {
            this.messageService.add({
              key: "myKey1",
              severity: "error",
              detail: results?.error?.reason,
            });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
          } else if (results?.success?.messageCode == "CIAM 252") {
            this.messageService.add({
              key: "myKey1",
              severity: "success",
              detail: "User edited successfully.",
            });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
            this.savedId = results?.success?.result?.id;
            this.oldFirstName = results?.success?.result?.first_name;
            this.oldMiddleName = results?.success?.result?.middle_name;
            this.oldLastName = results?.success?.result?.last_name;
            if (results?.success?.result?.phoneNumbers != null) {
              this.oldPhoneNumber =
                results?.success?.result?.phoneNumbers[0]?.value;
              this.oldPhoneId = results?.success?.result?.phoneNumbers[0]?.id;
              this.oldCountryCode =
                results?.success?.result?.phoneNumbers[0]?.countryCode;
            }
            this.oldUserName = results?.success?.result?.user_name;
            this.messageFlag = true;
            this.firstSave = true;

            this.updateEditedValue();
            this.prevattributeTableList=[];
            this.prevattributeTableList=configParamJson;
            this.logEvent(EventName.EDIT_USER,ContextName.EDIT_USER,formData);
            this.tab1 = true;
            this.clicked = false;
            this.enableBtn1 = true;
            if (this.pagesService.pageRebacFlag == "false") {
              this.showTab();
            }
          } else {
            this.messageFlag = true;
            this.messageService.add({
              key: "myKey1",
              severity: "error",
              detail: "Failed to Edit User.",
            });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
          }
        },
      error: (error) => {
        if (error.error?.error?.messageCode == "CIAM 105") {
          this.messageService.add({
            key: "myKey1",
            severity: "error",
            detail: "Request already available for approval",
          });
          setTimeout(() => {
            this.messageFlag = false;
          }, 3000);
        }
        else if (error.error?.error?.messageCode == "CIAM 115") {
          this.messageService.add({
            key: "myKey1",
            severity: "error",
            detail: "Mail cannot be edited for internal user ",
          });
          setTimeout(() => {
            this.messageFlag = false;
          }, 3000);
        } else {
          this.messageFlag = true;
          this.messageService.add({
            key: "myKey1",
            severity: "error",
            detail: "Failed to Edit User.",
          });
          setTimeout(() => {
            this.messageFlag = false;
          }, 3000);
        }
      }
    });
  }

  onEditUserPageLoad() {
    this.someArr = [];
    this.pagesService
      .onEditUserPageLoad(this.userID)
      .subscribe((results: any) => {
        let pagePropBind = {
          firstName: results?.success?.result?.first_name,
          middleName: results?.success?.result?.middle_name,
          lastName: results?.success?.result?.last_name,
          emailAddress: results?.success?.result?.emails[0]?.value,
          phoneNumbers: results?.success?.result?.phoneNumbers[0]?.value,
          phoneID: results?.success?.result?.phoneNumbers[0]?.id,
          countryCode: results?.success?.result?.phoneNumbers[0]?.countryCode,
          userName: results?.success?.result?.user_name,
          roleList: results?.success?.result?.roles,
          groupList: results?.success?.result?.groups,
          account: results.success.result?.account?.value
        };
        this.isExternal = results?.success?.result?.isExternal;
        this.oldUserType = results?.success?.result?.userType;
        this.oldEmail = results?.success?.result?.emails[0]?.value;
        this.emailsID=results?.success?.result?.emails[0].id;
        if (this.oldUserType == "4f026b3c-159d-11ee-be56-0242ac120002") {
          this.EditUserForm.get("userName").disable();
        }
        if (this.isExternal) {
          this.isExternal = this.selectSwitchList[1]?.value;
        } else if (!this.isExternal) {
          this.isExternal = this.selectSwitchList[0]?.value;
          //this.isExternal=false
          this.EditUserForm.get("emailAddress").disable(); 
        } else {
          this.EditUserForm.controls["selectSwitch"].setValidators(
            Validators.required
          );
          this.EditUserForm.controls["selectSwitch"].updateValueAndValidity();
        }
        this.customFieldValues =
          results?.success.result?.customFieldDataDtoList;
        this.customFieldValues?.forEach((element1) => {
          this.checkboxArr?.forEach((element2) => {
            if (element1?.customFieldId == element2?.id) {
              if (element2?.fieldInputtype == "Checkbox") {
                if (element1?.customFieldValue == "true") {
                  this[element1.customFieldId] = true;
                }
              }
            }
          });
        });
        let cc;
        this.customFieldValues?.forEach((element1) => {
          this.customList?.forEach((element2) => {
            if (element1.customFieldId == element2.id) {
              if (element2.inputFormat[0] == "Text/Number range") {
                cc = JSON.parse(element1.customFieldValue);
                pagePropBind[element1.customFieldId + "_start"] = cc.start;
                pagePropBind[element1.customFieldId + "_end"] = cc.last;
              } else if (element2.inputFormat[0] == "Date range") {
                if (element1.customFieldValue == null) {
                  // ignore
                } else {
                  let ccc = JSON.parse(element1.customFieldValue);
                  let startDate = new Date(ccc[0]);
                  let endDate = new Date(ccc[1]);
                  this[element1.customFieldId] = [startDate, endDate];
                }
              } else {
                pagePropBind[element1.customFieldId] =
                  element1.customFieldValue;
              }
            }
          });
        });
        this.oldFirstName = pagePropBind.firstName;
        this.oldMiddleName = pagePropBind.middleName;
        this.oldLastName = pagePropBind.lastName;
        this.oldPhoneNumber = pagePropBind.phoneNumbers;
        this.oldPhoneId = pagePropBind.phoneID;
        this.oldCountryCode = pagePropBind.countryCode;
        this.oldUserName = pagePropBind.userName;
        this.pageloadvalues = pagePropBind;

        this.EditUserForm.patchValue(pagePropBind);
        this.customFieldValues?.forEach((element) => {
          //dto
          this.someArr.push(element.customFieldId);
        });
        this.customList?.forEach((element1) => {
          //fetch
          if (this.someArr.includes(element1.id)) {
            if (!element1.editableField) {
              this.EditUserForm.controls[element1.id]?.disable();
            }
          } else {
            element1.editableField = true;
          }
        });
        this.getSelectRole();
        this.getSelectGroup();
      });
  }
  setPageProperties(props: any): void {
    const pageData: any = {};
    const calendarControls = ["createdOn"];
    Object.keys(props).forEach((key) => {
      if (calendarControls.includes(key)) {
        pageData[key] = new Date(props[key]);
      } else {
        pageData[key] = props[key];
      }
    });
    if (pageData.viewUserID == undefined) {
      this.userID = pageData.id;
    } else {
      this.userID = pageData.viewUserID;
    }
    this.LOGIN_ID = pageData.userName;
    this.USER_STATUS = pageData.statusMode;
    this.EditUserForm.patchValue(pageData);
    //this.loginUserStatus = pageData.userStatus;
    if(pageData.userStatus == undefined){
      this.loginUserStatus = pageData.statusMode;
    }else{
      this.loginUserStatus = pageData.userStatus;
    }
    //this.userFullName = pageData.fullName;
    if(pageData.fullName == undefined){
      this.userFullName = pageData.firstName+" "+pageData.lastName;
    }else{
      this.userFullName = pageData.fullName;
    }
    if(pageData.isExternal == undefined){
      this.userType=this.navigation?.extras?.state?.userType;
    }else{
      this.userType = pageData.isExternal;
    }
    
  }

  onCcprimebutton4($event: UntypedFormGroup): void {
    this.router.navigate(["/pages/addroles"]);
  }

  getroleList(): void {
    this.pagesService.gettableDataRoles().subscribe((results) => {
      const responseData = results?.success?.result?.records;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.displayName;
          data.value = element.id;
          this.roleListList.push(data);
        });
        this.roleListList = [...this.roleListList];
      }
    });
  }

  getgroupList(): void {
    this.pagesService.gettableDataListGroups().subscribe((results) => {
      const responseData = results?.success?.result?.records;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.displayName;
          data.value = element.id;
          this.groupListList.push(data);
        });
        this.groupListList = [...this.groupListList];
      }
    });
  }

  getSelectRole(): void {
    this.selectedRole = [];
    const responseData = this.EditUserForm.value.roleList;
    if (responseData && responseData.length > 0) {
      responseData.forEach((element: any = {}) => {
        const data = {
          label: null,
          value: null,
        };
        data.label = element.displayName;
        data.value = element.id;
        this.selectedRole.push(data.value);
      });
      this.selectedRole = [...this.selectedRole];
    }
  }

  getSelectGroup(): void {
    this.selectedGroup = [];
    const responseData = this.EditUserForm.value.groupList;
    if (responseData && responseData.length > 0) {
      responseData.forEach((element: any = {}) => {
        const data = {
          label: null,
          value: null,
        };
        data.label = element.displayName;
        data.value = element.id;
        this.selectedGroup.push(data.value);
      });
      this.selectedGroup = [...this.selectedGroup];
    }
  }

  getExRoleList() {
    this.pagesService
      .getViewUserRoles(this.userID)
      .subscribe((results: any) => {
        const responseData = results?.success?.result;
        this.exRoleList = [];
        if (responseData && responseData.length > 0) {
          responseData.forEach((element: any = {}) => {
            const data = {
              label: null,
              value: null,
            };
            data.label = element.displayName;
            data.value = element.id;
            this.exRoleList.push(data.value);
          });
          this.exRoleList = [...this.exRoleList];
        }
      });
  }

  RUSo(assignList: any, results: any) {
    for (let i = 0; i < assignList.length; i++) {
      if (
        results?.success?.result?.AssignRolesToUser[i]?.messageCode ==
        "CIAM 151"
      ) {
        this.assignRoleCount = this.assignRoleCount + 1;
        this.wfFlag = false;
      } else if (
        results?.success?.result?.AssignRolesToUser[i]?.messageCode ==
        "CIAM 154"
      ) {
        this.wfFlag = true;
      }
    }
  }
  assignRoles($event: UntypedFormGroup): void {
    $event.updateValueAndValidity();
    this.newRoleList = this.EditUserForm.value.roleList;
    const assignList = this.newRoleList.filter(
      (x) => !this.exRoleList.includes(x)
    );
    const deassignList = this.exRoleList.filter(
      (x) => !this.newRoleList.includes(x)
    );
    const reqBody = {};
    reqBody["tenantId"] = this.pagesService.tenantId;
    reqBody["userId"] = this.userID;
    reqBody["assignList"] = assignList;
    reqBody["deAssignList"] = deassignList;
    const formValues = reqBody;
    this.messageFlag = true;
    this.pagesService.onSaveAssignRolesToUser(formValues).subscribe({
      next:
        (results) => {
          this.RUSo(assignList, results);
          for (let i = 0; i < deassignList.length; i++) {
            if (
              results?.success?.result["De-AssignRolesFromUser"][i]
                ?.messageCode == "CIAM 152"
            ) {
              this.deAssignRoleCount = this.deAssignRoleCount + 1;
              this.wfFlag = false;
            } else if (
              results?.success?.result["De-AssignRolesFromUser"][i]
                ?.messageCode == "CIAM 154"
            ) {
              this.wfFlag = true;
            }
          }
          if (this.wfFlag) {
            this.navigateToMyReq(
              "Assign/Deassign Role to User request has been sent for approval"
            );
          } else {
            if (this.deAssignRoleCount == 0 && this.assignRoleCount > 0) {
              this.msg.push(this.assignRoleCount + " Role Assigned successfully");
            } else if (this.deAssignRoleCount > 0 && this.assignRoleCount > 0) {
              this.msg.push(this.assignRoleCount + " Role Assigned successfully");

              this.msg.push(
                this.deAssignRoleCount + " Role De-assigned successfully"
              );
            } else if (this.deAssignRoleCount > 0 && this.assignRoleCount == 0) {
              this.msg.push(
                this.deAssignRoleCount + " Role De-assigned successfully"
              );
            }
            this.messageFlag = true;
            this.messageService.add({
              key: "myKey1",
              severity: "success",
              detail: this.msg.join("\n"),
            });
            setTimeout(() => {
              this.messageFlag = false;
              this.assignRoleCount = 0;
              this.deAssignRoleCount = 0;
              this.msg = [];
            }, 3000);
            this.tab2 = true;
            this.showTab();
            this.enableBtn2 = true;
            this.getExRoleList();
          }
        },
      error: (error) => {
        if (error.error?.error?.messageCode == "CIAM 105") {
          this.messageService.add({
            key: "myKey1",
            severity: "error",
            detail: "Request already available for approval",
          });
          setTimeout(() => {
            this.messageFlag = false;
          }, 3000);
        } else {
          this.messageFlag = true;
          this.messageService.add({
            key: "myKey1",
            severity: "error",
            detail: "Failed to Assign Roles.",
          });
          setTimeout(() => {
            this.messageFlag = false;
          }, 3000);
        }
      }
    });
  }

  getExGroupList() {
    this.pagesService
      .getViewUserGroups(this.userID)
      .subscribe((results: any) => {
        const responseData = results?.success?.result;
        this.exGroupList = [];
        if (responseData && responseData.length > 0) {
          responseData.forEach((element: any = {}) => {
            const data = {
              label: null,
              value: null,
            };
            data.label = element.displayName;
            data.value = element.id;
            this.exGroupList.push(data.value);
          });
          this.exGroupList = [...this.exGroupList];
        }
      });
  }

  assignGroups($event: UntypedFormGroup): void {
    $event.updateValueAndValidity();
    this.newGroupList = this.EditUserForm.value.groupList;
    const assignList = this.newGroupList.filter(
      (x) => !this.exGroupList.includes(x)
    );
    const deassignList = this.exGroupList.filter(
      (x) => !this.newGroupList.includes(x)
    );
    const reqBody = {};
    reqBody["assignList"] = assignList;
    reqBody["deAssignList"] = deassignList;
    const ID = this.userID;
    const formValues = reqBody;
    this.messageFlag = true;
    this.pagesService.onSaveAssignGroup(formValues, ID).subscribe({
      next:
        (results) => {
          for (let i = 0; i < assignList.length; i++) {
            if (
              results?.success?.result?.AssingGroupsToUser[i]?.messageCode ==
              "CIAM 151"
            ) {
              this.assignGroupCount = this.assignGroupCount + 1;
            }
          }
          for (let i = 0; i < deassignList.length; i++) {
            if (
              results?.success?.result["De-AssingGroupsFromUser"][i]
                ?.messageCode == "CIAM 152"
            ) {
              this.deAssignGroupCount = this.deAssignGroupCount + 1;
            }
          }
          if (this.deAssignGroupCount == 0 && this.assignGroupCount > 0) {
            this.msg1.push(
              this.assignGroupCount + " Group Assigned successfully"
            );
          } else if (this.deAssignGroupCount > 0 && this.assignGroupCount > 0) {
            this.msg1.push(
              this.assignGroupCount + " Group Assigned successfully"
            );

            this.msg1.push(
              this.deAssignGroupCount + " Group De-assigned successfully"
            );
          } else if (this.deAssignGroupCount > 0 && this.assignGroupCount == 0) {
            this.msg1.push(
              this.deAssignGroupCount + " Group De-assigned successfully"
            );
          }

          this.messageFlag = true;
          this.messageService.add({
            key: "myKey1",
            severity: "success",
            detail: this.msg1.join("\n"),
          });
          setTimeout(() => {
            this.messageFlag = false;
            this.assignGroupCount = 0;
            this.deAssignGroupCount = 0;
            this.msg1 = [];
          }, 3000);
          this.tab3 = true;
          this.enableBtn3 = true;
          this.getExGroupList();
        },
      error: () => {
        this.messageFlag = true;
        this.messageService.add({
          key: "myKey1",
          severity: "error",
          detail: "Failed to Assign Groups.",
        });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
    });
  }

  showTab() {
    if (this.pagesService.pageRebacFlag == "false") {
      if (this.activeIndex1 === 0) {
        this.activeIndex1 = 1;
      } else if (this.activeIndex1 === 1) {
        this.activeIndex1 = 2;
      }
    } else if (this.activeIndex1 === 0 || this.activeIndex1 === 2) {
      this.activeIndex1 = 2;
    }
  }

  fetchCustomField() {
    this.pagesService
      .getFetchCustomFieldListing(this.moduleID)
      .subscribe((results) => {
        const responseData = results?.success?.result;
        if (responseData && responseData.length > 0) {
          responseData.forEach((element: any = {}) => {
            const data = {
              id: null,
              fieldName: null,
              fieldInputtype: null,
              inputFormat: null,
              mandatoryField: null,
              editableField: null,
              configJson: null,
              maxCharacters: null,
              pattern: null,
              specialChar: null,
            };
            data.id = element.id;
            data.fieldName = element.fieldName;
            this.customJSON = JSON.parse(element.fieldConfigJason);
            data.configJson = this.customJSON;
            data.fieldInputtype = data.configJson.fieldLabel;
            data.inputFormat = data.configJson.inputvalue_format;
            data.specialChar = data.configJson?.specialCharacter;
            let dropSelect = [];
            if (data.fieldInputtype == "Dropdown") {
              data.inputFormat.forEach((element1: any = {}) => {
                const data1 = {
                  label: null,
                  value: null,
                };
                data1.label = element1.label;
                data1.value = element1.label;
                dropSelect.push(data1);
              });
              dropSelect = [...dropSelect];
              data.inputFormat = dropSelect;
            }
            data.mandatoryField = data.configJson.mandatory;
            data.editableField = data.configJson.editable;
            data.maxCharacters = data.configJson.MaxCharacters;

            if (
              data.fieldInputtype == "Checkbox" ||
              (data.inputFormat[0] == "Date range" &&
                data.fieldInputtype == "Input field in range")
            ) {
              this.EditUserForm.addControl(data.id, this.fb.control(null, []));
            } else if (
              data.inputFormat[0] == "Text/Number range" &&
              data.fieldInputtype == "Input field in range"
            ) {
              this.EditUserForm.addControl(
                data.id + "_start",
                this.fb.control(null, [])
              );
              this.EditUserForm.addControl(
                data.id + "_end",
                this.fb.control(null, [])
              );
            } else {
              if (
                data.fieldInputtype == "Text" ||
                data.fieldInputtype == "Text area"
              ) {
                data.pattern = this.setValidationsPatterns(
                  data.inputFormat,
                  data.specialChar
                );
              }
              this.EditUserForm.addControl(data.id, this.fb.control(null, []));
            }
            if (data.fieldInputtype == "Checkbox") {
              this.checkboxArr.push(data);
            } else {
              this.customList.push(data);
            }
          });
          this.customList = [...this.customList];
        }
        this.onEditUserPageLoad();
      });
  }

  setValidationsPatterns(format: any, specialChar: any): string {
    let upper = "";
    let lower = "";
    let num = "";
    let spCh = "";
    let pattern = "";
    format.forEach((element) => {
      if (element == "Uppercase alphabet") {
        upper = "A-Z";
      } else if (element == "Lowercase alphabet") {
        lower = "a-z";
      } else if (element == "Numbers") {
        num = "0-9";
      }
    });
    specialChar?.forEach((element) => {
      spCh = spCh + "\\" + element.value;
    });
    pattern =
      "^([" +
      lower +
      "" +
      upper +
      "" +
      num +
      "" +
      spCh +
      "]+\\s)*[" +
      lower +
      "" +
      upper +
      "" +
      num +
      "" +
      spCh +
      "]+$";
    return pattern;
  }

  navigateToMyReq(msg: any): void {
    const navigationExtras: NavigationExtras = {
      state: {
        msg: msg,
      },
    };
    this.router.navigate(["/pages/myrequestslisting"], navigationExtras);
  }

  //entitlement

  fetchAttributeList() {
    let reqBody = {};
    reqBody["tenantId"] = this.pagesService.tenantId;
    reqBody["userId"] = this.userID;
    const formValues = reqBody;
    this.pagesService.getAccountParameter(formValues).subscribe((results) => {
      const responseData = results?.success?.result;
      this.accountStatus = responseData?.accountStatus;
      let accountParamJson = JSON.parse(responseData?.accountParamJson);

      if (accountParamJson && accountParamJson?.length > 0) {
        accountParamJson.forEach((element: any = {}) => {
          if (element.isConfigurable) {
            const data = {
              id: null,
              fieldName: null,
              fieldInputtype: null,
              inputFormat: null,
              mandatoryField: null,
              editableField: null,
              configJson: null,
              maxCharacters: null,
              pattern: null,
              specialChar: null,
              fieldValue: null,
              inherited: null,
              isDeleteAble: null,
            };
            data.id = element.id;
            data.fieldName = element.fieldName;
            data.fieldInputtype = element.fieldInputtype;

            data.mandatoryField = element.mandatoryField;

            data.pattern = element?.pattern;
            data.fieldValue = element.fieldValue;
            data.inherited = true;
            data.inputFormat = element.inputFormat;

            this.attributeList.push(data);
            this.attributeList1.push(data);
          }
        });
        this.getUserParameter();
      }
    });
  }
  getExistingParameter(): void {
    this.existingParameterList = [];
    this.pagesService.getExistingParamter().subscribe((results) => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          if (element.configurable && element.userSpecific) {
            const data = {
              label: null,
              data: null,
              isMultiple: null,
              isUserSpecific: null,
              selectable: true,
              children: [],
            };
            data.label = element.fieldname;
            data.data = JSON.parse(element.jsonTemplate);
            data.isMultiple = element.multiple;
            data.isUserSpecific = element.userSpecific;
            this.existingParameterList.push(data);
            this.userAttributeData.push(data);
            this.orginalUserAttributeData.push(data);
          }
        });
        this.existingParameterList = [...this.existingParameterList];
        this.userAttributeData = [...this.userAttributeData];
        this.orginalUserAttributeData = [...this.orginalUserAttributeData];
      }
    });
  }

  openAddAttribute() {
    this.selectedUserAttribute = [];
    this.isInvalidIpRange = false;
  }
  onUserAttrSelected(event) {
    if (this.selectedUserAttribute.length > 0) {
      this.addButtonEnable = false;
    }
  }
  applyUserAttributeFilter() {
    this.showAttributeValueFlag = true;
    this.userAttrColumnList = [];
    let filteredNodeList = this.selectedUserAttribute;
    let attributeData = filteredNodeList["data"];
    this.attributeName = attributeData.fieldName;
    this.count = this.count + 1;
    attributeData["id"] = attributeData.fieldName + this.count;
    this.EditUserForm.addControl(
      attributeData["id"] + "_dialog",
      this.fb.control(null, [])
    );
    this.EditUserForm.controls[
      attributeData["id"] + "_dialog"
    ].updateValueAndValidity();
    this.userAttrColumnList.push(attributeData);
    this.closeOverlay = true;
  }
  onConfirmAttributeValue() {
    this.showAttributeValueFlag = false;
    let ipRangeCounter = this.attributeTableList.filter(
      (e) => e.fieldName === "IPRange"
    ).length;
    let phoneNumberCounter = this.attributeTableList.filter(
      (e) => e.fieldName === "Phone Number"
    ).length;
    this.userAttrColumnList.forEach((element: any = {}) => {
      const data = {
        id: null,
        fieldName: null,
        fieldInputtype: null,
        inputFormat: null,
        mandatoryField: null,
        editableField: null,
        configJson: null,
        maxCharacters: null,
        pattern: null,
        specialChar: null,
        fieldValue: null,
        inherited: null,
        isDeleteAble: null,
        newlyAdded: false,
      };

      data.fieldName = element.fieldName;
      if (
        data.fieldName == "UserSalesForceID" ||
        data.fieldName == "ADUserName"
      ) {
        data.id = element.fieldName;
      } else if (data.fieldName == "Phone Number") {
        data.id = element.fieldName + (phoneNumberCounter + 1);
      } else if (data.fieldName == "IPRange") {
        data.id = element.fieldName + (ipRangeCounter + 1);
      }
      data.fieldInputtype = element.fieldLabel;
      data.mandatoryField = element.mandatory;
      data.pattern = element?.pattern;
      data.fieldValue = this.EditUserForm.value[element.id + "_dialog"];
      data.inherited = null;
      data.inputFormat = element.inputFormat;
      data.isDeleteAble = true;
      data.newlyAdded = true;

      this.EditUserForm.addControl(data.id, this.fb.control(null, []));
      this.EditUserForm.controls[data.id].setValue(data.fieldValue);
      this.EditUserForm.controls[element.id + "_dialog"].reset();
      this.EditUserForm.controls[
        element.id + "_dialog"
      ].updateValueAndValidity();
      this.EditUserForm.removeControl(element.id + "_dialog");

      this.attributeTableList.unshift(data);
      if (data.fieldName == "IPRange") {
        const IP4startValue = data.fieldValue.split("-")[0];
        const IP4endValue = data.fieldValue.split("-")[1];
        const data1 = {
          startIpValue: null,
          endIpValue: null,
          fieldId: null,
        };
        data1.startIpValue = IP4startValue;
        data1.endIpValue = IP4endValue;
        data1.fieldId = data.id;
        this.IpList.push(data1);
        this.IpList1.push(data1);
      }
    });
    const attrName = this.attributeName;
    this.existingParameterList.forEach((e) => {
      if (e.label == attrName && !e.isMultiple) {
        let myNewArray = this.userAttributeData.filter(function (item) {
          return item.label !== attrName;
        });
        this.userAttributeData = myNewArray;
      }
    });
    this.userAttrColumnList = [];
    this.enableBtn1 = false;
    this.isInvalidIpRangeDialog = true;
  }
  getUserParameter(): void {
    let reqBody = {};
    reqBody["tenantId"] = this.pagesService.tenantId;
    reqBody["userId"] = this.userID;
    const formValues = reqBody;
    this.pagesService.getUserParameter(formValues).subscribe({
      next:
        (results) => {
          const responseData = results.success.result;
          let userParamJson = JSON.parse(responseData.parameterJson);
          if (userParamJson && userParamJson.length > 0) {
            this.userParameterFlag = true;
            userParamJson.forEach((element: any = {}) => {
              const data = {
                id: null,
                fieldName: null,
                fieldInputtype: null,
                inputFormat: null,
                mandatoryField: null,
                editableField: null,
                configJson: null,
                maxCharacters: null,
                pattern: null,
                specialChar: null,
                fieldValue: null,
                inherited: null,
                isDeleteAble: null,
              };
              data.id = element.id;
              data.fieldName = element.fieldName;
              data.fieldInputtype = element.fieldInputtype;

              data.mandatoryField = element.mandatoryField;

              data.pattern = element?.pattern;
              data.inherited = element.inherited;
              data.inputFormat = element.inputFormat;
              data.isDeleteAble = element.isDeleteAble;
              if (
                data.fieldInputtype == "Text Range" ||
                data.fieldInputtype == "numeric" ||
                data.fieldInputtype == "Text"
              ) {
                this["userParam" + data.id] = element.fieldValue;
                data.fieldValue = element.fieldValue;
              } else if (data.fieldInputtype == "Dropdown") {
                data.fieldValue = element.fieldValue;
                this["userParamDropDown" + data.id] = data.fieldValue.value;
              }

              this.userParameterList.push(data);

              const d = data.fieldName;
              this.paraArray.push(data.fieldName);
              this.userAttributeData.forEach((e, i) => {
                if (
                  e.label == d &&
                  (d == "UserSalesForceID" || d == "ADUserName")
                ) {
                  this.userAttributeData[i]["selectable"] = false;
                  this.userAttributeData.splice(i, 1);
                }
              });
            });
            this.userParameterList = [...this.userParameterList];

            this.checkOveriddenParam();
          } else {
            this.userParameterFlag = false;
            this.attributeTableList = this.attributeList;
            this.setOldNewValue();
          }
        },
      error: (error) => {
        this.userParameterFlag = false;
        this.attributeTableList = this.attributeList;
        this.setOldNewValue();
      }
    });
  }
  checkOveriddenParam() {
    let removeAccData = [];
    this.userParameterList.forEach((element1, index) => {
      this.attributeList.forEach((element2, index2) => {
        if (element1.id == element2.id) {
          if (element1.fieldInputtype == "Dropdown") {
            if (
              element1.fieldValue.value != element2.fieldValue.value ||
              (element1.fieldValue.value == element2.fieldValue.value &&
                (!element1.inherited || element1.inherited == null))
            ) {
              removeAccData.push(element1.id);
              this.attributeTableList.push(element1);
            } else if (element1.fieldValue.value == element2.fieldValue.value) {
              removeAccData.push(element1.id);
              this.attributeTableList.push(element2);
            }
          } else {
            removeAccData.push(element1.id);
            this.attributeTableList.push(element1);
          }
        }
      });
    });
    if (removeAccData.length > 0) {
      let myNewArray2 = [];
      for (let i = 0; i < removeAccData.length; i++) {
        let myNewArray = this.userParameterList.filter(function (item) {
          return item.id !== removeAccData[i];
        });
        myNewArray2 = this.attributeList.filter(function (item) {
          return item.id !== removeAccData[i];
        });
        this.userParameterList = myNewArray;
        this.attributeList = myNewArray2;
      }
      let arrUser = [];
      this.userParameterList.forEach((e) => {
        e["inherited"] = null;
        arrUser.push(e);
      });
      this.attributeTableList.push(...myNewArray2);
      this.attributeTableList.push(...arrUser);
    } else {
      this.attributeTableList.push(...this.attributeList);
      this.attributeTableList.push(...this.userParameterList);
    }
    this.prevattributeTableList=[...this.attributeTableList];
    this.setOldNewValue();
  }
  setOldNewValue() {
    this.attributeTableList.forEach((element: any = {}) => {
      const data = {
        id: null,
        fieldName: null,
        fieldInputtype: null,
        inputFormat: null,
        mandatoryField: null,
        editableField: null,
        configJson: null,
        maxCharacters: null,
        pattern: null,
        specialChar: null,
        fieldValue: null,
        inherited: null,
        isDeleteAble: null,
      };
      data.id = element.id;
      data.fieldName = element.fieldName;
      data.fieldInputtype = element.fieldInputtype;
      data.mandatoryField = element.mandatoryField;
      data.pattern = element?.pattern;
      data.fieldValue = element.fieldValue;
      data.inherited = element.inherited;
      data.inputFormat = element.inputFormat;
      if (data.fieldName == "IPRange") {
        const IP4startValue = data.fieldValue.split("-")[0];
        const IP4endValue = data.fieldValue.split("-")[1];
        const data1 = {
          startIpValue: null,
          endIpValue: null,
          fieldId: null,
        };
        data1.startIpValue = IP4startValue;
        data1.endIpValue = IP4endValue;
        data1.fieldId = data.id;
        this.IpList.push(data1);
        this.IpList1.push(data1);
      }
      if (this.firstSave) {
        data.isDeleteAble = false;
        this["oldInherited_" + data.id] = data.inherited;
        if (data.fieldInputtype == "Dropdown") {
          this.EditUserForm.controls[data.id].setValue(
            this.EditUserForm.value[data.id]
          );
          this["oldDropdown_" + data.id] = data.fieldValue?.value;
        } else {
          this.EditUserForm.controls[data.id].setValue(
            this.EditUserForm.value[data.id]
          );
          this["oldText_" + data.id] = data.fieldValue;
        }
      } else {
        this.EditUserForm.addControl(data.id, this.fb.control(null, []));
        this["oldInherited_" + data.id] = data.inherited;
        if (data.fieldInputtype == "Dropdown") {
          this.EditUserForm.controls[data.id].setValue(data.fieldValue?.value);
          this["oldDropdown_" + data.id] = data.fieldValue?.value;
        } else {
          this.EditUserForm.controls[data.id].setValue(data.fieldValue);
          this["oldText_" + data.id] = data.fieldValue;
        }
      }
    });
  }
  deleteAttribute(rowIndex: any, attribute: any) {
    this.attributeName = attribute.fieldName;
    this.currentRowIndex = rowIndex;
    this.currentAttribute = attribute;
    this.deleteUserAttribute = true;
    this.confirmMessage = "Are you sure you want to delete this attribute?";
  }
  onConfirmPopup() {
    this.deleteUserAttribute = false;
    let attrId = this.attributeTableList[this.currentRowIndex]["id"];
    let attrsName = this.attributeTableList[this.currentRowIndex]["fieldName"];
    if (attrsName == "IPRange") {
      for (let i = 0; i < this.IpList.length; i++) {
        if (this.IpList[i].fieldId == attrId) {
          this.IpList.splice(i, 1);
        }
      }
      for (let i = 0; i < this.IpList1.length; i++) {
        if (this.IpList1[i].fieldId == attrId) {
          this.IpList1.splice(i, 1);
        }
      }
    }
    this.attributeTableList.splice(this.currentRowIndex, 1);

    this.EditUserForm.controls[attrId].clearValidators();

    this.EditUserForm.controls[attrId].reset();
    this.EditUserForm.controls[attrId].updateValueAndValidity();
    this.EditUserForm.removeControl(attrId);
    this.existingParameterList.forEach((e) => {
      if (e.label == attrsName && !e.isMultiple) {
        let myNewArray = this.orginalUserAttributeData.filter(function (item) {
          return item.label === attrsName;
        });
        this.userAttributeData.push(...myNewArray);
      }
    });
    this.enableBtn1 = false;
  }
  onInputChange(id: any, index: any) {
    this.attributeTableList.forEach((element, index) => {
      if (element.inherited != null) {
        if (
          element.fieldInputtype == "Text Range" ||
          element.fieldInputtype == "Text"
        ) {
          if (
            this["oldText_" + element.id] != this.EditUserForm.value[element.id]
          ) {
            this.attributeTableList[index]["inherited"] = false;
          } else if (
            this["oldText_" + element.id] == this.EditUserForm.value[element.id]
          ) {
            this.attributeTableList[index]["inherited"] = this[
              "oldInherited_" + element.id
            ];
          }
        } else if (element.fieldInputtype == "Dropdown") {
          if (
            this["oldDropdown_" + element.id] !=
            this.EditUserForm.value[element.id]
          ) {
            this.attributeTableList[index]["inherited"] = false;
          } else if (
            this["oldDropdown_" + element.id] ==
            this.EditUserForm.value[element.id]
          ) {
            this.attributeTableList[index]["inherited"] = this[
              "oldInherited_" + element.id
            ];
          }
        }
      }
    });
  }
  onCancelAttributeValue() {
    this.showAttributeValueFlag = false;
    this.count = this.count - 1;
    this.userAttrColumnList.forEach((element: any = {}) => {
      this['duplicityFlag_Dialog' + element.id] = false;
      this['IpFlag_Dialog' + element.id] = false;
      this['greaterFlag_Dialog' + element.id] = false;
      this.EditUserForm.controls[element.id + "_dialog"].reset();
      this.EditUserForm.controls[
        element.id + "_dialog"
      ].updateValueAndValidity();
      this.EditUserForm.removeControl(element.id + "_dialog");
    });
    this.userAttrColumnList = [];
    this.isInvalidIpRangeDialog = true;
  }
  updateEditedValue() {
    this.updatedAttributeTableList = this.attributeTableList;
    this.attributeTableList = [];
    this.IpList = [];
    this.IpList1 = [];
    this.updatedAttributeTableList.forEach((element: any = {}) => {
      const data = {
        id: null,
        fieldName: null,
        fieldInputtype: null,
        inputFormat: null,
        mandatoryField: null,
        editableField: null,
        configJson: null,
        maxCharacters: null,
        pattern: null,
        specialChar: null,
        fieldValue: null,
        inherited: null,
        isDeleteAble: null,
      };
      data.id = element.id;
      data.fieldName = element.fieldName;
      data.fieldInputtype = element.fieldInputtype;
      data.mandatoryField = element.mandatoryField;
      data.pattern = element?.pattern;
      data.fieldValue = element.fieldValue;
      data.inherited = element.inherited;
      data.inputFormat = element.inputFormat;
      if (data.fieldName == "IPRange") {
        const IP4startValue = data.fieldValue.split("-")[0];
        const IP4endValue = data.fieldValue.split("-")[1];
        const data1 = {
          startIpValue: null,
          endIpValue: null,
          fieldId: null,
        };
        data1.startIpValue = IP4startValue;
        data1.endIpValue = IP4endValue;
        data1.fieldId = data.id;
        this.IpList.push(data1);
        this.IpList1.push(data1);
      }
      if (this.firstSave) {
        data.isDeleteAble = element.isDeleteAble;
        this["oldInherited_" + data.id] = data.inherited;
        if (data.fieldInputtype == "Dropdown") {
          this.EditUserForm.controls[data.id].setValue(
            this.EditUserForm.value[data.id]
          );
          this["oldDropdown_" + data.id] = data.fieldValue?.value;
        } else {
          this.EditUserForm.controls[data.id].setValue(
            this.EditUserForm.value[data.id]
          );
          this["oldText_" + data.id] = data.fieldValue;
        }
      } else {
        this.EditUserForm.addControl(data.id, this.fb.control(null, []));
        this["oldInherited_" + data.id] = data.inherited;
        if (data.fieldInputtype == "Dropdown") {
          this.EditUserForm.controls[data.id].setValue(data.fieldValue?.value);
          this["oldDropdown_" + data.id] = data.fieldValue?.value;
        } else {
          this.EditUserForm.controls[data.id].setValue(data.fieldValue);
          this["oldText_" + data.id] = data.fieldValue;
        }
      }

      this.attributeTableList.push(data);
    });
  }

  dialogInputChange(field: any) {
    if (
      field.fieldName == "ADUserName" ||
      field.fieldName == "Phone Number" ||
      field.fieldName == "UserSalesForceID"
    ) {
      if (this.EditUserForm.value[field.id + "_dialog"].length > 0) {
        this.isInvalidIpRangeDialog = false;
      }
    }
  }

  onCancelUserPopup() {
    this.deleteUserAttribute = false;
  }

  addressStartIpCheck(id: any) {
    this["IpFlag" + id] = false;
    this["duplicityFlag" + id] = false;
    const regularExpressionIP4 = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)-(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;//NOSONAR
    const IP4startValue = this.EditUserForm.value[id];

    const validateStart = regularExpressionIP4.test(
      String(IP4startValue).toLowerCase()
    );
    if (IP4startValue == null || IP4startValue == "") {
      document.getElementById(id)!.style.borderColor = "grey";
      this["duplicityFlag" + id] = false;
      this["IpFlag" + id] = false;
      this["greaterFlag" + id] = false;
      this.isInvalidIpRange = false;
    } else if (
      !validateStart &&
      (IP4startValue != "" || IP4startValue != null)
    ) {
      document.getElementById(id)!.style.borderColor = "red";
      this["IpFlag" + id] = true;
      this["duplicityFlag" + id] = false;
      this["greaterFlag" + id] = false;
      this.isInvalidIpRange = true;
    } else {
      document.getElementById(id)!.style.borderColor = "grey";
      this.EditUserForm.setErrors(null);
      this.isInvalidIpRange = false;
      this["IpFlag" + id] = false;
      this["greaterFlag" + id] = false;

      this["duplicityFlag" + id] = false;
      this.duplicityCheck(id);
    }
  }

  duplicityCheck(id: any) {
    this["greaterFlag" + id] = false;
    this["duplicityFlag" + id] = false;
    const IP4startValue = this.EditUserForm.value[id].split("-")[0];
    const IP4endValue = this.EditUserForm.value[id].split("-")[1];
    if (this.IpList.length == 0) {
      const data = {
        index: null,
        startIpValue: null,
        endIpValue: null,
        fieldId: null,
      };
      data.index = this.index;
      data.startIpValue = this.EditUserForm.value[id].split("-")[0];
      data.endIpValue = this.EditUserForm.value[id].split("-")[1];
      data.fieldId = id;
      if (data.startIpValue == data.endIpValue) {
        document.getElementById(id)!.style.borderColor = "red";
        this["duplicityFlag" + id] = true;
        this.isInvalidIpRange = true;
      } else if (data.startIpValue > data.endIpValue) {
        document.getElementById(id)!.style.borderColor = "red";
        this["greaterFlag" + id] = true;
        this.isInvalidIpRange = true;
      } else {
        this.IpList.push(data);
        this.IpList = [...this.IpList];
      }
    } else if (IP4startValue == IP4endValue) {
      document.getElementById(id)!.style.borderColor = "red";
      this["duplicityFlag" + id] = true;
      this.isInvalidIpRange = true;
    } else if (IP4startValue > IP4endValue) {
      document.getElementById(id)!.style.borderColor = "red";
      this["greaterFlag" + id] = true;
      this.isInvalidIpRange = true;
    } else {
      this.checkIPCondition = true;
      for (let i = 0; i < this.IpList.length; i++) {
        if (this.IpList[i].fieldId == id) {
          this.IpList.splice(i, 1);
          if (
            this.IpList[i].startIpValue == IP4startValue ||
            this.IpList[i].endIpValue == IP4endValue ||
            this.IpList[i].startIpValue == IP4endValue ||
            this.IpList[i].endIpValue == IP4startValue
          ) {
            this["duplicityFlag" + id] = true;
            document.getElementById(id)!.style.borderColor = "red";
            this.isInvalidIpRange = true;
            this.checkIPCondition = false;
            break;
          }
        } else {
          this.ipTest = this.IpList[i].startIpValue.includes(IP4startValue);
          if (
            this.IpList[i].startIpValue == IP4startValue ||
            this.IpList[i].endIpValue == IP4endValue ||
            this.IpList[i].startIpValue == IP4endValue ||
            this.IpList[i].endIpValue == IP4startValue
          ) {
            this["duplicityFlag" + id] = true;
            document.getElementById(id)!.style.borderColor = "red";
            this.isInvalidIpRange = true;
            this.checkIPCondition = false;
            break;
          }
        }
      }
      if (this.checkIPCondition) {
        const data = {
          index: null,
          startIpValue: null,
          endIpValue: null,
          fieldId: null,
        };
        data.index = this.index + 1;
        data.startIpValue = this.EditUserForm.value[id].split("-")[0];
        data.endIpValue = this.EditUserForm.value[id].split("-")[1];
        data.fieldId = id;
        this["duplicityFlag" + id] = false;
        this.IpList.push(data);
        this.IpList = [...this.IpList];
        document.getElementById(id)!.style.borderColor = "grey";
        this.isInvalidIpRange = false;
        this.EditUserForm.setErrors(null);
        this.enableBtn1 = false;
      }
    }
  }

  addressStartIpCheckDialog(id: any) {
    this["IpFlag_Dialog" + id] = false;
    this["duplicityFlag_Dialog" + id] = false;
    const regularExpressionIP4 = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)-(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;//NOSONAR
    const IP4startValue = this.EditUserForm.value[id + "_dialog"];

    const validateStart = regularExpressionIP4.test(
      String(IP4startValue).toLowerCase()
    );
    if (IP4startValue == null || IP4startValue == "") {
      document.getElementById(id + "_dialog")!.style.borderColor = "grey";
      this["duplicityFlag_Dialog" + id] = false;
      this["IpFlag_Dialog" + id] = false;
      this["greaterFlag_Dialog" + id] = false;
      this.isInvalidIpRangeDialog = false;
    } else if (
      !validateStart &&
      (IP4startValue != "" || IP4startValue != null)
    ) {
      document.getElementById(id + "_dialog")!.style.borderColor = "red";
      this["IpFlag_Dialog" + id] = true;
      this["duplicityFlag_Dialog" + id] = false;
      this["greaterFlag_Dialog" + id] = false;
      this.isInvalidIpRangeDialog = true;
    } else {
      document.getElementById(id + "_dialog")!.style.borderColor = "grey";
      this.isInvalidIpRangeDialog = false;
      this["IpFlag_Dialog" + id] = false;
      this["greaterFlag_Dialog" + id] = false;

      this["duplicityFlag_Dialog" + id] = false;
      this.duplicityCheckDialog(id);
    }
  }

  duplicityCheckDialog(id: any) {
    this["greaterFlag_Dialog" + id] = false;
    this["duplicityFlag_Dialog" + id] = false;
    const IP4startValue = this.EditUserForm.value[id + "_dialog"].split("-")[0];
    const IP4endValue = this.EditUserForm.value[id + "_dialog"].split("-")[1];
    if (this.IpList1.length == 0) {
      const data = {
        index: null,
        startIpValue: null,
        endIpValue: null,
        fieldId: null,
      };
      data.index = this.index;
      data.startIpValue = this.EditUserForm.value[id + "_dialog"].split("-")[0];
      data.endIpValue = this.EditUserForm.value[id + "_dialog"].split("-")[1];
      data.fieldId = id;
      if (data.startIpValue == data.endIpValue) {
        document.getElementById(id + "_dialog")!.style.borderColor = "red";
        this["duplicityFlag_Dialog" + id] = true;
        this.isInvalidIpRangeDialog = true;
      } else if (data.startIpValue > data.endIpValue) {
        document.getElementById(id + "_dialog")!.style.borderColor = "red";
        this["greaterFlag_Dialog" + id] = true;
        this.isInvalidIpRangeDialog = true;
      } else {
        this.IpList1.push(data);
        this.IpList1 = [...this.IpList1];
      }
    } else if (IP4startValue == IP4endValue) {
      document.getElementById(id + "_dialog")!.style.borderColor = "red";
      this["duplicityFlag_Dialog" + id] = true;
      this.isInvalidIpRangeDialog = true;
    } else if (IP4startValue > IP4endValue) {
      document.getElementById(id + "_dialog")!.style.borderColor = "red";
      this["greaterFlag_Dialog" + id] = true;
      this.isInvalidIpRangeDialog = true;
    } else {
      this.checkIPConditionDialog = true;
      for (let i = 0; i < this.IpList1.length; i++) {
        if (this.IpList1[i].fieldId == id) {
          this.IpList1.splice(i, 1);
        } else {
          this.ipTest1 = this.IpList1[i].startIpValue.includes(IP4startValue);

          if (
            this.IpList1[i].startIpValue == IP4startValue ||
            this.IpList1[i].endIpValue == IP4endValue ||
            this.IpList1[i].startIpValue == IP4endValue ||
            this.IpList1[i].endIpValue == IP4startValue
          ) {
            this["duplicityFlag_Dialog" + id] = true;
            document.getElementById(id + "_dialog")!.style.borderColor =
              "red";
            this.isInvalidIpRangeDialog = true;
            this.checkIPConditionDialog = false;
            break;
          }
        }
      }
      if (this.checkIPConditionDialog) {
        const data = {
          index: null,
          startIpValue: null,
          endIpValue: null,
          fieldId: null,
        };
        data.index = this.index + 1;
        data.startIpValue = this.EditUserForm.value[id + "_dialog"].split(
          "-"
        )[0];
        data.endIpValue = this.EditUserForm.value[id + "_dialog"].split(
          "-"
        )[1];
        data.fieldId = id;
        this["duplicityFlag_Dialog" + id] = false;
        this.IpList1.push(data);
        this.IpList1 = [...this.IpList1];
        document.getElementById(id + "_dialog")!.style.borderColor = "grey";
        this.isInvalidIpRangeDialog = false;
      }
    }
  }

  refreshUserDetails(varUser) {
    this.isEntitlementExist = false;
    this.selectedEntitlement = [];
    this.selectedComponent = {};
    this.isAddNewEntitlement = false;
    this.isUserChanged = false;
    this.onTabChange = false;

    this.organizationName = "";
    this.selectedOrganisation = {};
    this.deletedUserEntList = [];
    this.deletedEntOfUser = [];
    this.deletedUserEntIdList = [];
    this.search = "";
    this.userMap.clear();
    if (varUser != null) {
      varUser["NAME"] = varUser["NAME"].toString().split("-")[0];
      this.user = varUser;
    }
    this.userDeleteEntList = [];
    this.userAddedEntList = [];
    this.getEntitlementStatusList();
    this.getUserList();
    this.getDependencyEntitlementList();
    this.getUserRoleList();
    this.clearAllEntitlementFilters();
  }
  async getUserEntitlementList() {
    this.bankList = [];
    this.originalBankList = [];
    this.entitlementList = [];
    this.originalEntitlementList = [];
    this.totalDependentCount = 0;
    this.deletedEntOfUser = [];
    this.isEmailSendFlag = [];
    const formData: FormData = new FormData();
    formData.append("userId", this.umPKId.toString());
    await this.userDetailsService.getUserEntitlementList(formData).subscribe({ //NOSONAR
      next:
        async response => { //NOSONAR
          let nonDeletedEntOfUser: any = [];
          nonDeletedEntOfUser = response["USER_ENTITLEMENTS"].filter(
            (data) => data["IS_DELETED"] == "N"
          );
          this.deletedEntOfUser = response["USER_ENTITLEMENTS"].filter(
            (data) => data["IS_DELETED"] == "Y"
          );
          let entitlementResponse = nonDeletedEntOfUser;
          for (let i = 0; i < entitlementResponse.length; i++) {
            entitlementResponse[i]['USER_TYPE_ID'] = response['USER_TYPE_ID'];
            entitlementResponse[i][
              "COMPONENT_NAME"
            ] = this.componentDropdownList.filter(
              (c) => c.COMPONENT_ID == entitlementResponse[i].COMPONENT_ID
            )[0].NAME;

            if (
              entitlementResponse[i]["OVERALL_STATUS"] === PENDING ||
              (this.user?.USER_TYPE?.toString().toLowerCase() ==
                INTERNAL?.toString().toLowerCase() &&
                entitlementResponse[i].IS_ADMS_ENTITLEMENT == ONE &&
                entitlementResponse[i].IS_ADMS_APPROVED == ZERO)
            ) {
              entitlementResponse[i]["OVERALL_STATUS"] = DENY;
            }

            if (
              entitlementResponse[i].DEPENDENT_LIST &&
              entitlementResponse[i].DEPENDENT_LIST.length > 0
            ) {
              this.totalDependentCount++;
              let dependenList = [];
              let dependenData = entitlementResponse[
                i
              ].DEPENDENT_LIST.toString().split(",");
              for (let j = 0; j < dependenData.length; j++) {
                let rowData = dependenData[j].toString().split(":");
                let data = {
                  ENTITLEMENT_NAME: rowData[1],
                };
                dependenList.push(data);
              }
              entitlementResponse[i]["DEPENDENT_LIST"] = dependenList;
            }

            if (entitlementResponse[i].hasOwnProperty("SOURCE")) {
              entitlementResponse[i]["SOURCE"] = entitlementResponse[i]["SOURCE"]
                .toString()
                .replaceAll(",", " \n ");
            }
            let approvalLevels = [];
            if (entitlementResponse[i].APPROVAL_LEVELS) {
              let approvalList = entitlementResponse[
                i
              ].APPROVAL_LEVELS.toString().split(";");
              for (let k = 0; k < approvalList.length; k++) {
                let rowData = approvalList[k].toString().split("|");
                let data = {
                  USER_NAME: rowData[0],
                  LEVEL_NO: rowData[1],
                  STATUS: rowData[2],
                  ACTION_DATE: this.dateFormatterPipe.transform(rowData[3]),
                };
                approvalLevels.push(data);
              }
              entitlementResponse[i]["APPROVAL_LEVELS"] = approvalLevels;
            }

            if (approvalLevels.length == 0) {
              entitlementResponse[i][
                "selectedUmStatus"
              ] = this.entitlementStatusDropdownList.filter(
                (s) => s.value == entitlementResponse[i].OVERALL_STATUS_ID
              )[0];
            }
            let rejectedApproval = approvalLevels.filter(
              (app) => app.STATUS == NOT_APPROVED
            );

            if (rejectedApproval.length > 0) {
              rejectedApproval[0]["COLOR"] = "#D6002A";
              rejectedApproval = approvalLevels
                .filter((app) => app.STATUS == NOT_APPROVED)
                .map((a) => {
                  if (this.loginUserName.toLowerCase() === approvalLevels[0]["USER_NAME"].toLowerCase()) {
                    (a['COLOR'] = '#e91224'); (a['TOOLTIP'] = 'Not Approved by ' + 'you' + ' on ' + a['ACTION_DATE']);
                  } else {
                    (a['COLOR'] = '#e91224'); (a['TOOLTIP'] = 'Not Approved by ' + a.USER_NAME + ' on ' + a['ACTION_DATE']);
                  }
                });
              approvalLevels             //NOSONAR
                .filter((app) => app.STATUS != NOT_APPROVED)
                .map((a) => {
                  (a["COLOR"] = "#6e706e");
                  (a["TOOLTIP"] = "Approval process is disabled.");
                });
            } else {
              let approvedApproval = approvalLevels
                .filter((app) => app.STATUS == APPROVED)
                .map((a) => {
                  if (
                    this.loginUserName.toLowerCase() ===
                    a["USER_NAME"].toLowerCase()
                  ) {
                    (a["COLOR"] = "#9AE198");
                    (a["TOOLTIP"] =
                      "Approved by " + "you" + " on " + a["ACTION_DATE"]);
                  } else {
                    (a["COLOR"] = "#9AE198");
                    (a["TOOLTIP"] =
                      "Approved by " + a.USER_NAME + " on " + a["ACTION_DATE"]);
                  }
                });
              if (approvedApproval.length > 0) {
                approvalLevels //NOSONAR
                  .filter((app) => app.STATUS != APPROVED)
                  .map((a) => {
                    if (
                      this.loginUserName.toLowerCase() ===
                      approvalLevels[0]["USER_NAME"].toLowerCase()
                    ) {
                      (a["COLOR"] = "#F4B40F");
                      (a["TOOLTIP"] =
                        " Raised by " + "you" + " on " + a["ACTION_DATE"]);
                    } else {
                      (a["COLOR"] = "#F4B40F");
                      (a["TOOLTIP"] =
                        " Raised by " +
                        a.USER_NAME +
                        " on " +
                        a["ACTION_DATE"]);
                    }
                  });
              } else {
                let pendingApproval = approvalLevels.filter(
                  (app) => app.STATUS == PENDING
                );
                if (pendingApproval.length > 0) {
                  approvalLevels.map((a) => { //NOSONAR
                    if (
                      this.loginUserName.toLowerCase() ===
                      approvalLevels[0]["USER_NAME"].toLowerCase()
                    ) {
                      a["COLOR"] = "#F4B40F";
                      a["TOOLTIP"] =
                        " Raised by " +
                        "you" +
                        " on " +
                        pendingApproval[0].ACTION_DATE;
                    } else {
                      a["COLOR"] = "#F4B40F";
                      a["TOOLTIP"] =
                        " Raised by " +
                        pendingApproval[0].USER_NAME +
                        " on " +
                        pendingApproval[0].ACTION_DATE;
                    }
                  });
                }
              }
            }
          }
          this.entitlementList = entitlementResponse;
          this.originalEntitlementList = this.entitlementList;
          let localTime;
          if (
            this.user?.USER_TYPE.toString().toLowerCase() !=
            INTERNAL_USER_TYPE.toString().toLowerCase()
          ) {
            this.emailUpdateOn = response["IS_EMAIL_SENT"][0].EMAIL_UPDATE_ON;
            localTime = this.dateFormatterPipe.transform(this.emailUpdateOn);
            this.emailUpdateOn = response["IS_EMAIL_SENT"][0].EMAIL_UPDATE_ON;
            if (response["IS_EMAIL_SENT"][0].hasOwnProperty("IS_EMAIL_SENT")) {
              this.isEmailSendFlag = response["IS_EMAIL_SENT"][0].IS_EMAIL_SENT;
              if (this.isEmailSendFlag == "-1") {
                this.hideTooltip = true;
              }
            }
          }
          if (this.isEmailSendFlag == 1) {
            this.emailSendButtonLabel = "E-mail Sent";
            this.hideTooltip = false;
            this.emailSendButtonTooltip =
              "E-mail has been sent to this user on " + localTime;
          }
        },
      error: (error) => {
        this.messageFlag = true;
        this.showErrorValidations(error.error);
      }
    });
  }

  openStatusUpdateModal(section, updateId, role) {
    this.confirmMessage = "";
    this.isConfirmDialog = true;
    this.actionTable = section;
    if (section == IMPORT) {
      this.confirmMessage =
        "Existing user data will be deleted before the new data imported. Please confirm.";
    }
  }

  openImportModal(section) {
    this.confirmMessage = "";
    this.isImportConfirmDialog = true;
    this.actionTable = section;
    this.confirmMessage =
      "Existing user data will be deleted before the new data imported. Please confirm.";
  }

  onActionConfirmationClick() {
    this.isConfirmDialog = false;
    this.isImportConfirmDialog = false;
    if (this.actionTable == IMPORT) {
      this.importUserData();
    }
  }

  cancelConfimDialog() {
    this.isConfirmDialog = false;
    this.isImportConfirmDialog = false;
  }

  updateAttrStatus(attId) {
    this.unSavedChanges = true;
    let originalAttr = this.originalAttributeList.filter(
      (attr) => attr.ID == attId
    )[0];
    let updatedAttr = this.attributeList.filter((attr) => attr.ID == attId)[0];
    this.isSaveAndApplyBtnDisabled = false;
    this.isEntitlementDisabled = false;

    this.attributeList.forEach((ele) => {
      if (
        ele.isMandatory == ONE &&
        (ele.attributeValue == null ||
          ele.attributeValue.toString().trim().length == 0)
      ) {
        this.isSaveAndApplyBtnDisabled = true;
        this.isEntitlementDisabled = true;
      }
    });

    if (updatedAttr.TYPE == NUMERIC) {
      updatedAttr.attributeValue = updatedAttr.attributeValue + "";
    }

    if (originalAttr !== undefined && originalAttr.isOrganizationAttr == ONE) {
      if (
        JSON.stringify(updatedAttr.attributeValue) ===
        JSON.stringify(originalAttr.attributeValue) &&
        originalAttr.attributeStatus != OVERRIDDEN
      ) {
        updatedAttr.attributeStatus = INHERITED;
      } else {
        updatedAttr.attributeStatus = OVERRIDDEN;
      }
    }
  }

  async updateUserDetails(isUpdateFlag) {
    this.isAddNewEntitlement = false;
    this.isEntitlementExist = false;
    let resultList = [];

    for (let Index = 0; Index < this.attributeList.length; Index++) {
      if (
        this.attributeList[Index].isMandatory == ONE &&
        (this.attributeList[Index].attributeValue == null ||
          this.attributeList[Index].attributeValue.length == 0)
      ) {
        this.messageFlag = true;
        this.showErrorValidations(
          this.attributeList[Index].attributeName + " Value should not be empty"
        );
        this.onCancel();
        return;
      }
      let attributeId = this.attributeList[Index].attributeId;
      this.isOrganizationAttr = this.attributeList[Index].isOrganizationAttr;
      let attributeTypeId = this.attributeList[Index].attributeTypeId;
      let attributeStatus = this.attributeList[Index].attributeStatus;
      if (
        (this.isOrganizationAttr == ONE &&
          attributeStatus != null &&
          attributeStatus != INHERITED) ||
        this.isOrganizationAttr == TWO
      ) {
        let id = this.attributeList[Index].ID;
        if (this.attributeList[Index].TYPE == DROPDOWN) {
          let value = this.attributeList[Index].attributeValue.value;
          resultList.push({
            id: id,
            attributeTypeId: attributeTypeId,
            attributeId: attributeId,
            attributeValue: value,
            isOrganizationAttr: this.isOrganizationAttr,
            attributeStatus,
            attributeName: this.attributeList[Index].attributeName,
          });
        } else {
          resultList.push({
            id: id,
            attributeTypeId: attributeTypeId,
            attributeId: attributeId,
            attributeValue: this.attributeList[Index].attributeValue,
            isOrganizationAttr: this.isOrganizationAttr,
            attributeStatus,
            attributeName: this.attributeList[Index].attributeName,
          });
        }
      }
    }

    if (this.EntitlmentFilterSidebarCount > 0)
      this.clearAllEntitlementFilters();

    const entitlementResultList = this.originalEntitlementList
      .filter((x) => [0, 1].includes(x.ACTION_TYPE))
      .map(({ ID, ROLE_COUNT, DEPENDENT_LIST, ...rest }) => rest);

    const userRolesResultList = this.originalUserRoleList
      .filter((x) => [0, 1].includes(x.ACTION_TYPE))
      .map(({ ROLE_ID, CREATED_DATE, ...rest }) => rest);

    let userDetails = {
      USER_ID: this.umPKId,
      ENTITLEMENT_LIST: entitlementResultList,
      USER_ATTRIBUTE_LIST: resultList,
      USER_ATTRIBUTE_DELETED_LIST: this.userDeletedAttributeList,
      USER_ROLE_LIST: userRolesResultList,
      DELETE_ROLE_IDS: this.finalDeleteRoleIds,
      DELETE_USER_ENTITLEMENT_ID_LIST: this.deletedUserEntIdList,
      DELETED_ROLE_INFO: this.deletedRoleInfo,
      USER_ID_WITH_LOGIN_ID: this.umPKId + " - " + this.LOGIN_ID,
      DELETED_ENT_LIST_ASSOCIATED_WITH_ROLE: this.userDeleteEntList,
      ADDED_ENT_LIST_ASSOCIATED_WITH_ROLE: this.userAddedEntList,
      LOGIN_ID: this.LOGIN_ID,
      USER_TYPE: this.userType,
      USER_FULL_NAME: this.userFullName,
      USER_STATUS: this.loginUserStatus
    };

    if (this.orgId) {
      userDetails["ORG_ID"] = this.orgId;
    } else {
      userDetails["ORG_ID"] = this.selectedOrganisation.ID;
    }
    userDetails["previousAttrList"] = this.previousAttrList;
    userDetails["attributeList"] = this.attributeList;
    this.messageFlag = true;
    this.userDetailsService.updateUserDetails(userDetails).subscribe(
      {
        next: (response) => {
          this.tab4 = true;
          this.timeStamp = true;
          this.firstSaveEnt = true;
          this.userDetailsResp = response.toString();//NOSONAR

          this.messageFlag = true;
          this.showSuccessValidations(
            "The changes have been saved and applied successfully."
          );
          this.isSaveAndApplyBtnDisabled = true;
          this.finalDeleteRoleIds = [];
          this.deletedRoleInfo = [];
          this.userDeletedAttributeList = [];
          if (this.unSavedChanges) {
            if (!this.onTabChange) {
              this.refreshUserDetails(this.selectedUser);
            } else if (this.isUserChanged) {
              this.refreshUserDetails(this.selectedUser);
            } else {
              this.onTabChange = false;
              this.router.navigate([this.nextRouteInfo], {
                state: { role: this.selectedUserRoleData },
                skipLocationChange: false,
              });
            }
          }
          this.unSavedChanges = false;
          this.isUserChanged = false;
          this.isAddNewEntitlement = false;
          this.isAddNewUserRole = false;
        },
        error: (error) => {
          this.messageFlag = true;
          this.showErrorValidations(error.error);
        }
      });
  }

  openEntitlementFilterSideBar() {
    this.showFilter = true;
    if (this.EntitlmentFilterSidebarCount == 0) {
      this.loadEntitlmentFilterData();
    }
  }

  discardOwnerFilter(event, element) {
    element.hide(event);
  }

  loadEntitlmentFilterData() {
    this.componentFilterDropdown = [];
    this.entitlementFilterDropdown = [];
    this.statusFilterDropdown = [];
    this.sourceFilterDropdown = [];
    let duplicateForSourceName = [];
    for (let Index = 0; Index < this.originalEntitlementList.length; Index++) {
      this.compId_concat =
        this.originalEntitlementList[Index].COMPONENT_ID +
        "-" +
        this.originalEntitlementList[Index].COMPONENT_NAME;

      const componentObj = {
        label: this.compId_concat,
        data: this.originalEntitlementList[Index],
        children: [],
        selectable: true,
        expanded: true,
        key: this.compId_concat,
      };

      if (this.originalEntitlementList[Index].IS_ADMS_ENTITLEMENT == 1) {
        this.adms_entitlement =
          this.originalEntitlementList[Index].ENTITLEMENT_ID +
          "-" +
          this.originalEntitlementList[Index].ENTITLEMENT_NAME +
          " " +
          ADMS;
      } else {
        this.adms_entitlement =
          this.originalEntitlementList[Index].ENTITLEMENT_ID +
          "-" +
          this.originalEntitlementList[Index].ENTITLEMENT_NAME;
      }

      const entitlementListObj = {
        label: this.adms_entitlement,
        data: this.originalEntitlementList[Index],
        children: [],
        selectable: true,
        expanded: true,
        key: this.adms_entitlement,
        ent_name: this.originalEntitlementList[Index].ENTITLEMENT_NAME,
      };

      let data =
        this.originalEntitlementList[Index].SOURCE != null &&
          this.originalEntitlementList[Index].SOURCE != undefined &&
          this.originalEntitlementList[Index].SOURCE != ""
          ? this.originalEntitlementList[Index].SOURCE.split("\n")
          : [];

      for (let i = 0; i < data.length; i++) {
        const sourceListObj = {
          label: data[i],
          data: this.originalEntitlementList[Index],
          children: [],
          selectable: true,
          expanded: true,
          key: data[i],
        };

        if (!duplicateForSourceName.includes(data[i].trim())) {
          this.sourceFilterDropdown.push(sourceListObj);
          duplicateForSourceName.push(data[i].trim());
        }
      }

      this.isRaisedIndependentlyData = [];
      const parentNode = {};
      if (this.isRaisedIndependentlyData.length == 0) {
        parentNode["label"] = "Raised Independently";
        parentNode["temp"] = ONE;
        this.isRaisedIndependentlyData.push(parentNode);
      }

      let statusListObj: any;
      if (this.originalEntitlementList[Index].OVERALL_STATUS == "Allow") {
        statusListObj = {
          label: "Allow",
          data: this.originalEntitlementList[Index],
          children: [],
          selectable: true,
          expanded: true,
          Key: this.originalEntitlementList[Index].ID,
        };
      } else {
        statusListObj = {
          label: "Deny",
          data: this.originalEntitlementList[Index],
          children: [],
          selectable: true,
          expanded: true,
          key: this.originalEntitlementList[Index].ID,
        };
      }

      if (
        this.componentFilterDropdown.filter(
          (c) => c.key.toString() == componentObj.key.toString()
        ).length == 0
      ) {
        this.componentFilterDropdown.push(componentObj);
        this.componentFilterDropdown.sort((a, b) =>
          a.label.split("-")[1].localeCompare(b.label.split("-")[1])
        );
      }

      if (
        this.entitlementFilterDropdown.filter(
          (c) => c.key.toString() == entitlementListObj.key.toString()
        ).length == 0
      ) {
        this.entitlementFilterDropdown.push(entitlementListObj);
        this.entitlementFilterDropdown.sort((a, b) =>
          a.ent_name.localeCompare(b.ent_name)
        );
      }
      if (
        this.statusFilterDropdown.filter(
          (c) => c.label.toString() == statusListObj.label.toString()
        ).length == 0
      ) {
        this.statusFilterDropdown.push(statusListObj);
        this.statusFilterDropdown.sort((a, b) =>
          a.label.localeCompare(b.label)
        );
      }
    }

    this.isNoApprovalRequiredData = [];
    const parentNode = {};
    if (this.isNoApprovalRequiredData.length == 0) {
      parentNode["label"] = "No approval Required";
      this.isNoApprovalRequiredData.push(parentNode);
    }
  }

  loadEntitlementData() {
    this.loadEntitlmentFilterData();
    this.applyEntitlementFilter();
  }

  applyEntitlementFilter(): void {
    this.searchArray = [];
    this.EntitlmentFilterSidebarCount++;
    this.showFilter = false;

    this.appliedComponentFilter = this.selectedComponentFilter;
    this.appliedEntitlementFilter = this.selectedEntitlementFilter;
    this.appliedSourceFilter = this.selectedSourceFilter;
    this.appliedRaisedIndependentDataFilter = this.selectedRaisedIndependentlyDataFilter;
    this.appliedStatusFilter = this.selectedStatusFilter;
    this.appliedNoApprovalRequired = this.selectedNoApprovalRequired;

    if (this.search) {
      this.searchArray = this.search.split(",").map((x) => x.trim());
    }

    if (
      this.selectedComponentFilter.length > 0 ||
      this.selectedEntitlementFilter.length > 0 ||
      this.selectedSourceFilter.length > 0 ||
      this.selectedRaisedIndependentlyDataFilter.length > 0 ||
      this.search ||
      this.selectedStatusFilter.length > 0 ||
      this.selectedNoApprovalRequired
    ) {
      this.entitlementList = this.originalEntitlementList.filter((b) => {
        if (
          this.selectedComponentFilter &&
          this.selectedComponentFilter.length > 0 &&
          !this.selectedComponentFilter.some(
            (bf) => bf.key.split("-")[0] == b.COMPONENT_ID
          )
        ) {
          return false;
        }
        if (
          this.selectedEntitlementFilter &&
          this.selectedEntitlementFilter.length > 0 &&
          !this.selectedEntitlementFilter.some(
            (bf) => b.ENTITLEMENT_NAME == bf.ent_name
          )
        ) {
          return false;
        }
        if (
          this.selectedSourceFilter &&
          this.selectedSourceFilter.length > 0 &&
          !this.selectedSourceFilter.some((bf) =>
            b.SOURCE.match(bf.label.trim())
          )
        ) {
          return false;
        }
        if (
          this.selectedRaisedIndependentlyDataFilter &&
          this.selectedRaisedIndependentlyDataFilter.length > 0 &&
          b.IS_USER_DEFINED != null &&
          b.IS_USER_DEFINED != undefined &&
          !this.selectedRaisedIndependentlyDataFilter.some(
            (bf) => bf.temp === b.IS_USER_DEFINED
          )
        ) {
          return false;
        }
        if (
          this.selectedStatusFilter &&
          this.selectedStatusFilter.length > 0 &&
          !this.selectedStatusFilter.some((bf) => bf.label === b.OVERALL_STATUS)
        ) {
          return false;
        }
        if (
          this.selectedNoApprovalRequired &&
          !(b.APPROVAL_LEVELS && b.APPROVAL_LEVELS.length == 0) &&
          ((b.IS_APPROVAL_REQUIRED && b.IS_APPROVAL_REQUIRED === 1) ||
            b.APPROVAL_LEVELS)
        ) {
          return false;
        }
        if (
          this.searchArray &&
          this.searchArray.length > 0 &&
          !this.searchArray.some(
            (bf) =>
              b.COMPONENT_NAME.toLowerCase().includes(bf.toLowerCase()) ||
              b.NAME_WITH_ADMS.toLowerCase().includes(bf.toLowerCase()) ||
              b.ENTITLEMENT_ID == bf
          )
        ) {
          return false;
        }
        return true;
      });
    } else {
      this.clearAllEntitlementFilters();
    }

    this.isEntitlementFilterSelection = false;
    this.manageUserComp.filteredNodes = this.manageUserComp.value;
    this.manageUserEnt.filteredNodes = this.manageUserEnt.value;
    this.manageUserSource.filteredNodes = this.manageUserSource.value;
    this.manageUserRaisedIndependent.filteredNodes = this.manageUserRaisedIndependent.value;
    this.manageUserStatus.filteredNodes = this.manageUserStatus.value;

    [
      this.manageUserComp,
      this.manageUserEnt,
      this.manageUserSource,
      this.manageUserRaisedIndependent,
      this.manageUserStatus,
    ].forEach((treeEl) => {
      treeEl.el.nativeElement.querySelectorAll(
        ".ui-tree-filter.ui-inputtext"
      ).value = "";
    });
    this.cdr.detectChanges();
  }

  discardUserEntFilter(event, element) {
    element.hide(event);
  }

  clearAllEntitlementFilters() {
    this.selectedComponentFilter = [];
    this.selectedEntitlementFilter = [];
    this.appliedComponentFilter = [];
    this.appliedEntitlementFilter = [];
    this.EntitlmentFilterSidebarCount = 0;
    this.entitlementList = this.originalEntitlementList;
    this.componentFilterDropdown = [];
    this.entitlementFilterDropdown = [];
    this.statusFilterDropdown = [];
    this.appliedStatusFilter = [];
    this.selectedStatusFilter = [];
    this.selectedNoApprovalRequired = false;
    this.appliedNoApprovalRequired = false;
    this.activeEntitlementTab = {
      isComponentTab: false,
      isEntitlementTab: false,
      isSourceTab: false,
      isRaisedIndependentlyTab: false,
      isStatusTab: false,
      isApprovalTab: false,
    };
    this.search = "";
    this.searchArray = [];
    this.selectedSourceFilter = [];
    this.appliedSourceFilter = [];
    this.selectedRaisedIndependentlyDataFilter = [];
    this.appliedRaisedIndependentDataFilter = [];
  }

  setActiveEntitTab(activeTabName: any) {
    this.activeEntitlementTab.isComponentTab = false;
    this.activeEntitlementTab.isEntitlementTab = false;
    this.activeEntitlementTab.isSourceTab = false;
    this.activeEntitlementTab.isRaisedIndependentlyTab = false;
    this.activeEntitlementTab.isStatusTab = false;
    this.activeEntitlementTab.isApprovalTab = false;

    if (activeTabName == COMPONENT) {
      this.activeEntitlementTab.isComponentTab = true;
    } else if (activeTabName == ENTITLEMENT) {
      this.activeEntitlementTab.isEntitlementTab = true;
    } else if (activeTabName == SOURCE_ROLE) {
      this.activeEntitlementTab.isSourceTab = true;
    } else if (activeTabName == RAISED_INDEPENDENT) {
      this.activeEntitlementTab.isRaisedIndependentlyTab = true;
    } else if (activeTabName == STATUS) {
      this.activeEntitlementTab.isStatusTab = true;
    } else if (activeTabName == APPROVAL) {
      this.activeEntitlementTab.isApprovalTab = true;
    }

    this.openEntitlementFilterSideBar();
  }

  getUserList() {
    let filter = {};
    let status = [];

    filter["SORT_BY"] = "NAME";

    this.userDetailsService.getUsersList(filter).subscribe(
      {
        next: (resource) => {
          this.userList = resource["RESULT"];
          this.userList.forEach((rl) => {
            let userName = [];
            userName.push(rl.NAME);
            userName.push(rl.ID);

            let obj = rl;
            obj["NAME"] = userName.join("-");

            this.changeUserDropdown.push(obj);
            this.userMap.set(rl.ID, rl.LAST_MODIFIED_DATE);
          });
          this.currentUser = JSON.parse(
            JSON.stringify(this.userList.filter((u) => u.ID == this.umPKId)[0])
          );
          this.user = this.currentUser;
          this.selectedUser = this.currentUser;
          if (this.currentUser != null && !this.firstSaveEnt) {
            this.timeStamp = true;
            this.userDetailsResp = this.currentUser.LAST_MODIFIED_DATE;
          }
        },
        error: (error) => {
          this.messageFlag = true;
          this.showErrorValidations(error.error);
        }
      });
  }

  addEntitlement() {
    this.isAddNewEntitlement = true;
    this.selectedComponent = {};
    this.selectedEntitlement = [];
    this.isEntitlementDropdown = false;
    this.EditUserForm.get("selectedEntitlementDropdown").disable();
    this.discardUserRole();
  }

  discardEntitlement() {
    this.isAddNewEntitlement = false;
    this.selectedComponent = {};
    this.selectedEntitlement = [];
    this.isEntitlementDropdown = false;
    this.EditUserForm.get("selectedEntitlementDropdown").disable();
    this.isEntitlementExist = false;
    this.entitlementList = this.originalEntitlementList;
  }

  addUserRole() {
    this.isAddNewUserRole = true;
    this.selectedUserRole = {};
    this.entitlementCount = [];
    this.discardEntitlement();
    this.getRoleList();
  }

  discardUserRole() {
    this.isAddNewUserRole = false;
    this.selectedUserRole = {};
    this.entitlementCount = [];
  }

  getComponentList() {
    this.userDetailsService.getComponentList().subscribe(
      {
        next: (response) => {
          this.componentDropdownList = response;
          this.getUserEntitlementList();
        },
        error: (err) => {
          this.messageFlag = true;
          this.showErrorValidations(err.error);
        }
      });
  }

  getEntitlementData() {
    this.entitlementDropdownList = [];
    this.isEntitlementDropdown = false;
    this.EditUserForm.get("selectedEntitlementDropdown").enable();
    this.isEntitlementExist = false;
    this.search = "";
    this.entitlementList = this.originalEntitlementList;
    this.selectedEntitlement = [];
    let data = {
      componentId: this.selectedComponent.COMPONENT_ID,
      isEligibleToAssignToUser: 1,
    };
    this.dataService.setIsLoader(false);
    this.userDetailsService.getEntitlementData(data).subscribe(
      {
        next: (response) => {
          let entitlementDropdownList = response["entitlementDataList"];
          let existngEntIds = this.originalEntitlementList
            .filter((e) => e.COMPONENT_ID == this.selectedComponent.COMPONENT_ID)
            .map((e) => e.ENTITLEMENT_ID)
            .slice(0);
          this.entitlementDropdownList = entitlementDropdownList
            .filter((e) => {
              if (existngEntIds.includes(e.ENTITLEMENT_ID)) {
                return false;
              }
              return true;
            })
            .slice(0);
          this.dataService.setIsLoader(true);
        },
        error: (err) => {
          this.messageFlag = true;
          this.showErrorValidations(err.error);
          this.dataService.setIsLoader(true);
        }
      });
  }

  addEntitlementToTable() {
    this.isEntitlementExist = false;
    this.entitlementList = this.originalEntitlementList;
    for (let i = 0; i < this.selectedEntitlement.length; i++) {
      let obj: any;
      let data: any;
      let APPROVAL_LEVELS: any = [];
      let sourceValue = [];
      if (this.deletedUserEntList.length > 0) {
        this.deletedUserEntList.forEach((x) => {
          if (
            x.ENTITLEMENT_ID == this.selectedEntitlement[i].ENTITLEMENT_ID &&
            x.COMPONENT_ID == this.selectedEntitlement[i].COMPONENT_ID
          ) {
            sourceValue = x.SOURCE;
          }
        });
      }

      data = this.deletedEntOfUser.filter(
        (a) =>
          a.COMPONENT_ID == this.selectedEntitlement[i].COMPONENT_ID &&
          a.ENTITLEMENT_ID == this.selectedEntitlement[i]["ENTITLEMENT_ID"]
      );

      if (data.length == 0 && this.deletedUserEntList.length > 0) {
        data = this.deletedUserEntList.filter(
          (a) =>
            a.COMPONENT_ID == this.selectedEntitlement[i].COMPONENT_ID &&
            a.ENTITLEMENT_ID == this.selectedEntitlement[i]["ENTITLEMENT_ID"]
        );
      }

      if (data.length > 0) {
        if (data[0].hasOwnProperty("APPROVAL_LEVELS")) {
          APPROVAL_LEVELS = data[0].APPROVAL_LEVELS.toString().split("|");
        }
      }

      if (
        data.length == 0 ||
        (data[0]["APPROVAL_LEVEL_COUNT"] == 0 &&
          this.selectedEntitlement[i]["APPROVAL_LEVEL_COUNT"] > 0 &&
          data[0]["OVERALL_STATUS"] == "Allow") ||
        APPROVAL_LEVELS.toString().includes(NOT_APPROVED) ||
        (data[0]["OVERALL_STATUS"] == DENY &&
          this.selectedEntitlement[i]["APPROVAL_LEVEL_COUNT"] >
          data[0]["APPROVAL_LEVEL_COUNT"])
      ) {
        obj = this.prepareObjForEntitlement(
          this.selectedEntitlement[i],
          this.selectedComponent.COMPONENT_ID,
          this.selectedComponent.NAME,
          1,
          sourceValue,
          this.userTypeId
        );
      } else {
        sourceValue = data[0].SOURCE.replace(",", "\n");
        obj = this.prepareObjForEntitlement(
          data[0],
          this.selectedComponent.COMPONENT_ID,
          this.selectedComponent.NAME,
          1,
          sourceValue,
          this.userTypeId
        );
      }

      this.originalEntitlementList.unshift(obj);
    }
    if (this.originalEntitlementList.length > 0) {
      this.originalEntitlementList //NOSONAR
        .filter((e) => e.OVERALL_STATUS === PENDING)
        .map((e) => (e["OVERALL_STATUS"] = DENY));
    }
    this.isAddNewEntitlement = false;
    this.entitlementList = this.originalEntitlementList;
    this.isSaveAndApplyBtnDisabled = false;
    this.unSavedChanges = true;
    this.getDependencyEntitlementList();
  }

  refreshDependencyList() {
    this.totalDependentCount = 0;
    if (this.dependencyEntitlementList.length > 0) {
      for (let i = 0; i < this.entitlementList.length; i++) {
        if (this.entitlementList[i].DEPENDENT_LIST) {
          this.entitlementList[i].DEPENDENT_LIST = null;
        }
        let dependencyEntitlementList = this.dependencyEntitlementList.filter(
          (ent) =>
            ent.COMPONENT_ID == this.entitlementList[i].COMPONENT_ID &&
            ent.PARENT_ENTITLEMENT_ID == this.entitlementList[i].ENTITLEMENT_ID
        );

        if (dependencyEntitlementList.length > 0) {
          for (
            let dependencyIndex = dependencyEntitlementList.length - 1;
            dependencyIndex >= 0;
            dependencyIndex--
          ) {
            if (
              this.entitlementList.filter(
                (entitlement) =>
                  entitlement.COMPONENT_ID ==
                  this.entitlementList[i].COMPONENT_ID &&
                  entitlement.ENTITLEMENT_ID ==
                  dependencyEntitlementList[dependencyIndex].ENTITLEMENT_ID &&
                  entitlement.OVERALL_STATUS_ID ==
                  this.entitlementList[i].OVERALL_STATUS_ID
              ).length > 0
            ) {
              dependencyEntitlementList.splice(dependencyIndex, 1);

              if (dependencyEntitlementList.length == 0) break;
            }
          }

          if (
            dependencyEntitlementList &&
            dependencyEntitlementList.length > 0 &&
            !this.isAddNewEntitlement
          ) {
            this.entitlementList[i].DEPENDENT_LIST = dependencyEntitlementList;
            this.totalDependentCount++;
          }
        }
      }
    }
  }

  getDependencyEntitlementList() {
    if (this.dependencyEntitlementList.length == 0) {
      this.userDetailsService.getDependencyEntitlementData().subscribe(
        {
          next: (response) => {
            this.dependencyEntitlementList = response;
            this.refreshDependencyList();
          },
          error: (error) => {
            this.messageFlag = true;
            this.showErrorValidations(error.error);
          }
        });
    } else {
      this.refreshDependencyList();
    }
  }

  getEntitlementStatusList() {
    this.entitlementStatusDropdownList = [];
    this.userDetailsService.getEntitlementStatusList().subscribe(
      {
        next: (response) => {
          let statusRes = response;
          this.entitlementStatusResponse = statusRes;
          let namesArray: string[] = [ALLOW, DENY];
          for (let i = 0; i < this.entitlementStatusResponse.length; i++) {
            if (namesArray.includes(this.entitlementStatusResponse[i].NAME)) {
              let res = statusRes[i];
              this.entitlementStatusDropdownList.push({
                label: res.NAME,
                value: res.ID,
              });
            }
          }

          this.allowStatusId = this.entitlementStatusDropdownList.filter(
            (status) => status.label == ALLOW
          )[0].value;
          this.pendingStatusId = this.entitlementStatusResponse.filter(
            (status) => status.NAME == PENDING
          )[0].ID;

          this.getComponentList();
        },
        error: (err) => {
          this.messageFlag = true;
          this.showErrorValidations(err.error);
        }
      });
  }

  loadStatusFilterData() {
    this.statusFilterDropdown = [];

    for (
      let parentIndex = 0;
      parentIndex < this.entitlementStatusDropdownList.length;
      parentIndex++
    ) {
      const parentNode = {
        label: this.entitlementStatusDropdownList[parentIndex].label,
        data: this.entitlementStatusDropdownList[parentIndex],
        children: [],
        selectable: true,
        expanded: true,
        key: this.entitlementStatusDropdownList[parentIndex].value,
      };

      this.statusFilterDropdown.push(parentNode);
    }
  }

  loadSourceFilterData() {
    this.sourceFilterDropdown = [];

    for (
      let parentIndex = 0;
      parentIndex < this.entitlementStatusDropdownList.length;
      parentIndex++
    ) {
      const parentNode = {
        label: this.entitlementStatusDropdownList[parentIndex].label,
        data: this.entitlementStatusDropdownList[parentIndex],
        children: [],
        selectable: true,
        expanded: true,
        key: this.entitlementStatusDropdownList[parentIndex].value,
      };

      this.sourceFilterDropdown.push(parentNode);
    }
  }

  checkUserData() {
    if (this.selectedUserToImport) {
      const formData: FormData = new FormData();
      formData.append("userId", this.umPKId.toString());

      this.userDetailsService.checkUserData(formData).subscribe(
        {
          next: (response) => {
            if (response == "true") {
              this.openImportModal(IMPORT);
            } else {
              this.importUserData();
            }
          },
          error: (error) => {
            this.messageFlag = true;
            this.showErrorValidations(error.error);
          }
        });
    }
  }

  importUserData() {
    let userDetails = {
      USER_ID: this.umPKId,
      IMPORT_USER_ID: this.selectedUserToImport.ID,
    };
    this.userDetailsService.importUserData(userDetails).subscribe(
      {
        next: (response) => {
          this.refreshUserDetails(this.user);
          this.selectedUserToImport = {};
          this.messageFlag = true;
          this.showSuccessValidations("User data imported successfully.");
        },
        error: (error) => {
          this.messageFlag = true;
          this.showErrorValidations(error.error);
        }
      });
  }

  loadImportUser() {
    this.importUserList = [];
    this.importUserList = this.userList.filter(
      (user) => user.ID !== this.umPKId
    );
  }

  onChangeRoleServiceDropdown(isUpdate, role, event) {
    let toggledValue = event.itemValue;
    if (toggledValue.serviceLineId == -1) {
      if (!isUpdate) {
        this.selectedRoleServiceLine = this.selectedRoleServiceLine.filter(
          (s) => s.serviceLineId == -1
        );
      } else {
        role.SERVICE_LINE_SELECTED = role.SERVICE_LINE_SELECTED.filter(
          (s) => s.serviceLineId == -1
        );
      }
    } else if (!isUpdate) {
      this.selectedRoleServiceLine = this.selectedRoleServiceLine.filter(
        (s) => s.serviceLineId != -1
      );
    } else {
      role.SERVICE_LINE_SELECTED = role.SERVICE_LINE_SELECTED.filter(
        (s) => s.serviceLineId != -1
      );
    }
  }


  canDeactivate(nextState?: any) {
    if (this.unSavedChanges) {
      this.navigationMessage = "There is unsaved data in the current page";
      this.nextRouteInfo = nextState.url;
      this.onTabChange = true;
    }
    return !this.onTabChange;
  }

  onSaveAndApply() {
    this.updateUserDetails(false);
  }

  unSaveChangesPopUp() {
    this.isEntitlementDisabled = false;
    if (this.unSavedChanges) {
      this.previousUser = JSON.parse(JSON.stringify(this.currentUser));
      this.currentUser = JSON.parse(JSON.stringify(this.selectedUser));
      this.onTabChange = true;
    } else {
      this.changeUserFlag = true;
      this.refreshUserDetails(this.selectedUser);
    }
    this.isUserChanged = true;
  }

  onDiscardChange() {
    this.onTabChange = false;
    this.isSaveAndApplyBtnDisabled = true;
    this.unSavedChanges = false;
    if (this.isUserChanged) {
      this.changeUserFlag = true;
      this.refreshUserDetails(this.selectedUser);
    } else {
      this.router.navigate([this.nextRouteInfo], {
        state: { role: this.selectedUserRoleData },
        skipLocationChange: false,
      });
    }
    this.onTabChange = false;
  }

  onCancel() {
    this.onTabChange = false;
    if (this.isUserChanged) {
      this.selectedUser = this.previousUser;
    }
    this.dataService.sendMessage(0);
    return false;
  }

  onDiscard() {
    this.unSavedChanges = false;
    this.discardPopUp = false;
    this.router.navigate([""]);
  }

  sidebarCloseEvent() {
    if (
      this.appliedComponentFilter.length !=
      this.selectedComponentFilter.length ||
      this.appliedEntitlementFilter.length !=
      this.selectedEntitlementFilter.length ||
      this.appliedSourceFilter != this.selectedSourceFilter ||
      this.appliedRaisedIndependentDataFilter !=
      this.selectedRaisedIndependentlyDataFilter ||
      this.appliedStatusFilter.length != this.selectedStatusFilter.length ||
      this.appliedNoApprovalRequired != this.selectedNoApprovalRequired
    ) {
      this.selectedComponentFilter = this.appliedComponentFilter;
      this.selectedEntitlementFilter = this.appliedEntitlementFilter;
      this.selectedSourceFilter = this.appliedSourceFilter;
      this.selectedStatusFilter = this.appliedStatusFilter;
      this.selectedNoApprovalRequired = this.appliedNoApprovalRequired;
    }
    if (this.selectedComponentFilter.length == 0) {
      this.activeEntitlementTab.isComponentTab = false;
    }
    if (this.selectedEntitlementFilter.length == 0) {
      this.activeEntitlementTab.isEntitlementTab = false;
    }
    if (this.selectedSourceFilter) {
      this.activeEntitlementTab.isSourceTab = false;
    }
    if (this.selectedRaisedIndependentlyDataFilter) {
      this.activeEntitlementTab.isRaisedIndependentlyTab = false;
    }
    if (this.selectedStatusFilter.length == 0) {
      this.activeEntitlementTab.isStatusTab = false;
    }
    if (!this.selectedNoApprovalRequired) {
      this.activeEntitlementTab.isApprovalTab = false;
    }
  }

  cancelImport(event, element) {
    element.hide(event);
    this.selectedUserToImport = "";
  }

  getOrganizationInfo() {
    let filter = {};
    filter["IS_MANDATORY_ATTRIBUTES_ASSIGNED"] = 1;
    this.userDetailsService.getOrganizationList(filter).subscribe(
      {
        next: (orgs) => {
          this.organizationList = orgs["RESULT"];
        },
        error: (error) => {
          this.showErrorValidations(error.error);
        }
      });
  }

  getOrganizationAttributes() {
    this.attributeList = [];
    this.originalAttributeList = [];
    this.orgStatus = "";
    this.isOrgAssignedToUser = true;
    this.isSaveAndApplyBtnDisabled = false;
    this.isEntitlementDisabled = false;
    this.unSavedChanges = true;
    let obj = {
      organisationId: this.selectedOrganisation.ID,
    };

    this.userDetailsService.getOrganizationAttributes(obj).subscribe(
      {
        next: (response) => {
          this.organizationName = this.selectedOrganisation.NAME;
          this.attributeList = response["ORG_ATTRIBUTES"];
          this.isOrgAssigned = response["IS_ORG_ASSIGNED"];

          if (response.hasOwnProperty("ORG_STATUS")) {
            this.orgStatus = response["ORG_STATUS"];
          }

          for (let Index = 0; Index < this.attributeList.length; Index++) {
            let type = this.attributeList[Index].TYPE;
            this.attributeList[Index]["attributeStatus"] = INHERITED;

            let attrList = [];

            if (type == DROPDOWN) {
              let splitedDropDownList = [];
              splitedDropDownList = this.attributeList[Index].default_Value.split(
                ","
              );
              attrList = [];
              splitedDropDownList.forEach((ele) => {
                let dropdownObj = { label: ele, value: ele };
                attrList.push(dropdownObj);
              });

              this.attributeList[Index]["dropdownList"] = attrList;
              this.attributeList[Index].attributeValue = attrList.filter(
                (ele) => ele.label == this.attributeList[Index].attributeValue
              )[0];
            }
          }
          let userManadatoryAttr = this.userAttributeList.filter(
            (atr) =>
              atr.isMandatory == ONE &&
              atr.isReadOnly == ZERO &&
              atr.isInheritedFromOrg == ZERO
          );
          if (userManadatoryAttr.length > 0) {
            for (let i = 0; i < userManadatoryAttr.length; i++) {
              let selectedAttribute = userManadatoryAttr[i];
              if (selectedAttribute.TYPE == DROPDOWN) {
                let splitedDropDownList = [];
                splitedDropDownList = selectedAttribute.columnList.split(",");
                selectedAttribute.columnList = [];
                splitedDropDownList.forEach((ele) => {
                  let dropdownObj = { label: ele, value: ele };
                  selectedAttribute.columnList.push(dropdownObj);
                });
              }
              this.attributeList.unshift({
                ID: selectedAttribute.ID,
                isOrganizationAttr: TWO,
                attributeName: selectedAttribute.columnLabel,
                attributeValue: null,
                attributeId: selectedAttribute.attributeId,
                TYPE: selectedAttribute.TYPE,
                dropdownList: selectedAttribute.columnList,
                attributeTypeId: selectedAttribute.attributeTypeId,
                isDeletable: ZERO,
                isMandatory: selectedAttribute.isMandatory,
                isMultiple: selectedAttribute.isMultiple,
                isOverriden: selectedAttribute.isOverriden,
              });
            }
          }

          this.originalAttributeList = JSON.parse(
            JSON.stringify(this.attributeList)
          );
        },
        error: (err) => {
          this.showErrorValidations(err.error);
        }
      });
  }

  discardOrganization() {
    this.selectedOrganisation = {};
    this.isAssignButtonDisabled = true;
  }

  updateEntitlementStatus(selectedUmStatus, rowIndex) {
    this.entitlementList[rowIndex].OVERALL_STATUS = selectedUmStatus.label;
    this.entitlementList[rowIndex].OVERALL_STATUS_ID = selectedUmStatus.value;
    this.entitlementList[rowIndex].ACTION_TYPE = 0;
    this.entitlementList[rowIndex].LAST_MODIFIED_DATE = new Date();
    this.isSaveAndApplyBtnDisabled = false;
    this.unSavedChanges = true;
  }

  onDiscardConfirm() {
    if (this.unSavedChanges) {
      this.confirmMessage = "Do you want to discard unsaved changes?";
      this.discardPopUp = true;
    } else {
      this.router.navigate(["/pages/userlisting"], {
        skipLocationChange: false,
      });
    }
  }

  getUserRoleList() {
    this.originalUserRoleList = [];
    this.userRoleList = [];
    const formData: FormData = new FormData();
    formData.append("userId", this.umPKId.toString());
    this.userDetailsService.getUserRoleList(formData).subscribe(
      {
        next: (response) => {
          this.userRoleList = response;
          this.originalUserRoleList = JSON.parse(
            JSON.stringify(this.userRoleList)
          );
        },
        error: (err) => {
          this.showErrorValidations(err.error);
        }
      });
  }

  getRoleList() {
    let filter = {};
    this.userRoleDropdown = [];
    this.roleUserList = [];
    this.existngRoleIds = [];
    this.userRole = [];
    filter["SORT_BY"] = "NAME";
    filter["ROLE_STATUS_ID"] = [1];

    this.userDetailsService.getRoleList(filter).subscribe(
      {
        next: (resource) => {
          this.roleUserList = resource["RESULT"];

          this.roleUserList.forEach((rl) => {
            let roleName = [];
            roleName.push(rl.PRIME_ROLE_ID);
            roleName.push(rl.ROLE_NAME);

            let obj = rl;
            obj["NAME"] = roleName.join("-");

            this.userRoleDropdown.push(obj);
          });
          this.userRole = this.originalUserRoleList.map((r) => r.NAME);

          this.existngRoleIds = this.userRoleDropdown
            .filter((rl) =>
              this.userRole.includes(
                rl.ROLE_NAME.substr(
                  rl.ROLE_NAME.indexOf("-") + 1,
                  rl.ROLE_NAME.length
                )
              )
            )
            .map((e) => e.PRIME_ROLE_ID)
            .slice(0);

          this.userRoleDropdown = this.userRoleDropdown
            .filter((e) => !this.existngRoleIds.includes(e.PRIME_ROLE_ID))
            .slice(0);
        },
        error: (error) => {
          this.showErrorValidations(error.error);
        }
      });
  }

  getUserRoleEntitlementData(selectedUserRole) {
    this.entitlementCount = [];
    let data = {
      ROLE_ID: selectedUserRole.PRIME_ROLE_ID,
    };
    this.userDetailsService.getRoleEntitlementData(data).subscribe(
      {
        next: (response) => {
          let grantYesFiltered = [];
          let grantNoFiltered = [];
          let roleEntitlements: any[] = response;
          if (roleEntitlements.length > 0) {
            const result = roleEntitlements.reduce(
              (r, o) => {
                grantYesFiltered = this.originalEntitlementList.filter(
                  (e) =>
                    e.COMPONENT_ID == o.COMPONENT_ID &&
                    e.ENTITLEMENT_ID == o.ENTITLEMENT_ID
                );

                if (grantYesFiltered.length > 0) {
                  if (
                    grantYesFiltered.filter((f) => f.OVERALL_STATUS == ALLOW)
                      .length > 0
                  ) {
                    r["GRANT_YES_AND_APPROVED"].push(o);
                  } else if (
                    grantYesFiltered.filter((f) => f.OVERALL_STATUS == DENY)
                      .length > 0
                  ) {
                    r["GRANT_YES_AND_NOT_APPROVED"].push(o);
                  }
                } else {
                  grantNoFiltered = this.deletedEntOfUser.filter(
                    (e) =>
                      e.COMPONENT_ID == o.COMPONENT_ID &&
                      e.ENTITLEMENT_ID == o.ENTITLEMENT_ID
                  );

                  if (grantNoFiltered.length > 0) {
                    if (
                      grantNoFiltered.filter(
                        (f) =>
                          f.OVERALL_STATUS == APPROVED ||
                          f.OVERALL_STATUS == ALLOW
                      ).length > 0
                    ) {
                      r["GRANT_NO_AND_APPROVED"].push(o);
                    } else if (
                      grantNoFiltered.filter(
                        (f) =>
                          f.OVERALL_STATUS == NOT_APPROVED ||
                          f.OVERALL_STATUS == PENDING
                      ).length > 0
                    ) {
                      r["GRANT_NO_AND_NOT_APPROVED"].push(o);
                    }
                  } else {
                    r["GRANT_NO_AND_APPROVED"].push(o);
                  }
                }
                return r;
              },
              {
                GRANT_NO_AND_APPROVED: [],
                GRANT_NO_AND_NOT_APPROVED: [],
                GRANT_YES_AND_APPROVED: [],
                GRANT_YES_AND_NOT_APPROVED: [],
              }
            );

            this.roleEntitlementSection = result;
          }
          this.entitlementCount = response.length;
        },
        error: (err) => {
          this.showErrorValidations(err.error);
        }
      });
  }

  addUserRoleToTable() {
    this.isSaveAndApplyBtnDisabled = false;
    this.unSavedChanges = true;
    let obj = {
      ID: this.selectedUserRole.PRIME_ROLE_ID,
      ROLE_ID: this.selectedUserRole.PRIME_ROLE_ID,
      NAME: this.selectedUserRole.ROLE_NAME.substr(
        this.selectedUserRole.ROLE_NAME.indexOf("-") + 1,
        this.selectedUserRole.length
      ),
      CREATED_DATE: new Date(),
      ACTION_TYPE: 1,
    };

    this.originalUserRoleList.unshift(obj);

    this.isAddNewUserRole = false;
    this.userRoleList = JSON.parse(JSON.stringify(this.originalUserRoleList));
    this.addRoleEntitlementToUser(obj.NAME);
  }

  addRoleEntitlementToUser(roleName) {
    if (
      this.roleEntitlementSection &&
      this.roleEntitlementSection?.GRANT_NO_AND_APPROVED?.length > 0
    ) {
      for (
        let i = 0;
        i < this.roleEntitlementSection?.GRANT_NO_AND_APPROVED?.length;
        i++
      ) {
        let obj = this.prepareObjForEntitlement( //NOSONAR
          this.roleEntitlementSection.GRANT_NO_AND_APPROVED[i],
          this.roleEntitlementSection.GRANT_NO_AND_APPROVED[i].COMPONENT_ID,
          this.roleEntitlementSection.GRANT_NO_AND_APPROVED[i].COMPONENT_NAME,
          0,
          roleName,
          this.userTypeId
        );

        let data: any;
        let APPROVAL_LEVELS: any = [];
        data = this.deletedEntOfUser.filter(
          (a) =>
            a.ENTITLEMENT_ID ==
            this.roleEntitlementSection.GRANT_NO_AND_APPROVED[i][
            "ENTITLEMENT_ID"
            ] &&
            a.COMPONENT_ID ==
            this.roleEntitlementSection.GRANT_NO_AND_APPROVED[i][
            "COMPONENT_ID"
            ]
        );

        if (data.length > 0) {
          if (data[0].hasOwnProperty("APPROVAL_LEVELS")) {
            APPROVAL_LEVELS = data[0].APPROVAL_LEVELS.toString().split("|");
          }
        }

        if (
          data.length == 0 ||
          (data[0]["APPROVAL_LEVEL_COUNT"] == 0 &&
            this.roleEntitlementSection.GRANT_NO_AND_APPROVED[i][
            "APPROVAL_LEVEL_COUNT"
            ] > 0 &&
            data[0]["OVERALL_STATUS"] == "Allow") ||
          APPROVAL_LEVELS.toString().includes(NOT_APPROVED)
        ) {
          obj = this.prepareObjForEntitlement(
            this.roleEntitlementSection.GRANT_NO_AND_APPROVED[i],
            this.roleEntitlementSection.GRANT_NO_AND_APPROVED[i].COMPONENT_ID,
            this.roleEntitlementSection.GRANT_NO_AND_APPROVED[i].COMPONENT_NAME,
            0,
            roleName,
            this.userTypeId
          );
        } else {
          obj = this.prepareObjForEntitlement(
            data[0],
            this.roleEntitlementSection.GRANT_NO_AND_APPROVED[i].COMPONENT_ID,
            this.roleEntitlementSection.GRANT_NO_AND_APPROVED[i].COMPONENT_NAME,
            0,
            roleName,
            this.userTypeId
          );
        }

        this.originalEntitlementList.unshift(obj);
      }
    }
    if (
      this.roleEntitlementSection &&
      this.roleEntitlementSection.GRANT_NO_AND_NOT_APPROVED.length > 0
    ) {
      for (
        let i = 0;
        i < this.roleEntitlementSection.GRANT_NO_AND_NOT_APPROVED.length;
        i++
      ) {
        let obj = this.prepareObjForEntitlement( //NOSONAR
          this.roleEntitlementSection.GRANT_NO_AND_NOT_APPROVED[i],
          this.roleEntitlementSection.GRANT_NO_AND_NOT_APPROVED[i].COMPONENT_ID,
          this.roleEntitlementSection.GRANT_NO_AND_NOT_APPROVED[i]
            .COMPONENT_NAME,
          0,
          roleName,
          this.userTypeId
        );

        let data: any;
        let APPROVAL_LEVELS: any = [];
        data = this.deletedEntOfUser.filter(
          (a) =>
            a.ENTITLEMENT_ID ==
            this.roleEntitlementSection.GRANT_NO_AND_NOT_APPROVED[i][
            "ENTITLEMENT_ID"
            ] &&
            a.COMPONENT_ID ==
            this.roleEntitlementSection.GRANT_NO_AND_NOT_APPROVED[i][
            "COMPONENT_ID"
            ]
        );

        if (data.length > 0) {
          if (data[0].hasOwnProperty("APPROVAL_LEVELS")) {
            APPROVAL_LEVELS = data[0].APPROVAL_LEVELS.toString().split("|");
          }
        }

        if (
          data.length == 0 ||
          (data[0]["APPROVAL_LEVEL_COUNT"] == 0 &&
            this.roleEntitlementSection.GRANT_NO_AND_NOT_APPROVED[i][
            "APPROVAL_LEVEL_COUNT"
            ] > 0 &&
            data[0]["OVERALL_STATUS"] == "Allow") ||
          APPROVAL_LEVELS.toString().includes(NOT_APPROVED)
        ) {
          obj = this.prepareObjForEntitlement(
            this.roleEntitlementSection.GRANT_NO_AND_NOT_APPROVED[i],
            this.roleEntitlementSection.GRANT_NO_AND_NOT_APPROVED[i]
              .COMPONENT_ID,
            this.roleEntitlementSection.GRANT_NO_AND_NOT_APPROVED[i]
              .COMPONENT_NAME,
            0,
            roleName,
            this.userTypeId
          );
        } else {
          obj = this.prepareObjForEntitlement(
            data[0],
            this.roleEntitlementSection.GRANT_NO_AND_NOT_APPROVED[i]
              .COMPONENT_ID,
            this.roleEntitlementSection.GRANT_NO_AND_NOT_APPROVED[i]
              .COMPONENT_NAME,
            0,
            roleName,
            this.userTypeId
          );
        }

        this.originalEntitlementList.unshift(obj);
      }
    }
    if (
      this.roleEntitlementSection &&
      this.roleEntitlementSection.GRANT_YES_AND_APPROVED.length > 0
    ) {
      for (
        let i = 0;
        i < this.roleEntitlementSection.GRANT_YES_AND_APPROVED.length;
        i++
      ) {
        let obj = this.roleEntitlementSection.GRANT_YES_AND_APPROVED[i];

        let filteredRecord = this.originalEntitlementList.filter(
          (e) =>
            e.ENTITLEMENT_ID == obj.ENTITLEMENT_ID &&
            e.COMPONENT_ID == obj.COMPONENT_ID &&
            e.OVERALL_STATUS == ALLOW
        )[0];
        if (filteredRecord.SOURCE) {
          filteredRecord["SOURCE"] = filteredRecord.SOURCE + "\n" + roleName;
        } else {
          filteredRecord["SOURCE"] = roleName;
        }
      }
    }
    if (
      this.roleEntitlementSection &&
      this.roleEntitlementSection.GRANT_YES_AND_NOT_APPROVED.length > 0
    ) {
      for (
        let i = 0;
        i < this.roleEntitlementSection.GRANT_YES_AND_NOT_APPROVED.length;
        i++
      ) {
        let obj = this.roleEntitlementSection.GRANT_YES_AND_NOT_APPROVED[i];

        let filteredRecord = this.originalEntitlementList.filter(
          (e) =>
            e.ENTITLEMENT_ID == obj.ENTITLEMENT_ID &&
            e.COMPONENT_ID == obj.COMPONENT_ID &&
            e.OVERALL_STATUS == DENY
        )[0];
        if (filteredRecord.SOURCE) {
          filteredRecord["SOURCE"] = filteredRecord.SOURCE + "\n" + roleName;
        } else {
          filteredRecord["SOURCE"] = roleName;
        }
      }
    }
    if (this.originalEntitlementList.length > 0) {
      this.originalEntitlementList  //NOSONAR
        .filter((e) => e.OVERALL_STATUS === PENDING)
        .map((e) => (e["OVERALL_STATUS"] = DENY));
    }

    this.entitlementList = JSON.parse(
      JSON.stringify(this.originalEntitlementList)
    ).sort(
      (x, y) =>
        +new Date(y.LAST_MODIFIED_DATE) - +new Date(x.LAST_MODIFIED_DATE)
    );

    this.entitlementsAssociatedWithAddedRole = this.originalEntitlementList.filter(
      (data) => data?.SOURCE?.includes(roleName)
    );

    this.getDetailsForEditedRole(
      this.entitlementsAssociatedWithAddedRole,
      this.userAddedEntList
    );
  }

  prepareObjForEntitlement(
    ent,
    compId,
    compName,
    isUserDefined,
    roleName,
    userTypeId
  ) {
    let obj = {
      ENT_ID: "P" + ent.ID,
      COMPONENT_ID: compId,
      ENTITLEMENT_ID: ent.ENTITLEMENT_ID,
      ENTITLEMENT_NAME: ent.ENTITLEMENT_NAME,
      COMPONENT_NAME: compName,
      OWNER_UM_ENTITLEMENT_ID: ent.OWNER_UM_ENTITLEMENT_ID,
      OWNER_ENTITLEMENT_NAME: ent.OWNER_ENTITLEMENT_NAME,
      NOTIFICATION_ENTITLEMENT_IDS: ent.NOTIFICATION_ENTITLEMENT_IDS,
      IS_APPROVAL_TREE: ent.IS_APPROVAL_TREE,
      IS_APPROVAL_REQUIRED: ent.IS_APPROVAL_REQUIRED,
      IS_ADMS_ENTITLEMENT: ent.IS_ADMS_ENTITLEMENT,
      ACTION_TYPE: 1,
      IS_USER_DEFINED: isUserDefined,
      SOURCE: roleName,
      LAST_MODIFIED_DATE: new Date(),
      UM_ENTITLEMENT_ID: ent.ID,
      USER_TYPE_ID: this.user.USER_TYPE.toString().toLowerCase() == INTERNAL ? 1 : 0,
      IS_EMAIL_SEND: -1,
    };
    if (ent.IS_APPROVAL_REQUIRED) {
      if (ent.IS_ADMS_APPROVED == ONE) {
        obj["IS_ADMS_APPROVED"] = ent.IS_ADMS_APPROVED;
      } else {
        obj["IS_ADMS_APPROVED"] = ZERO;
      }
      if (ent.OVERALL_STATUS != ALLOW) {
        (obj["OVERALL_STATUS"] = DENY);
        (obj["OVERALL_STATUS_ID"] = this.pendingStatusId);
      } else {
        (obj["OVERALL_STATUS"] = ALLOW);
        (obj["OVERALL_STATUS_ID"] = this.allowStatusId);
      }
    } else {
      obj["STATUS_DROPDOWN_LIST"] = JSON.parse(
        JSON.stringify(this.entitlementStatusDropdownList)
      );
      obj["selectedUmStatus"] = JSON.parse(
        JSON.stringify(
          this.entitlementStatusDropdownList.filter(
            (s) => (s.value = this.allowStatusId)
          )[0]
        )
      );
      if (
        ent.IS_ADMS_ENTITLEMENT == ONE &&
        this.user?.USER_TYPE.toLowerCase() == INTERNAL.toLowerCase()
      ) {
        if (ent.hasOwnProperty("IS_ADMS_APPROVED")) {
          if (
            ent.IS_ADMS_APPROVED == ONE &&
            ent.OVERALL_STATUS != ALLOW &&
            ent.APPROVAL_LEVEL_COUNT == 0
          ) {
            ent["OVERALL_STATUS"] = ALLOW;
          }
          if (ent.IS_ADMS_APPROVED == ZERO || ent.OVERALL_STATUS != ALLOW) {
            (obj["OVERALL_STATUS"] = DENY);
            (obj["OVERALL_STATUS_ID"] = this.pendingStatusId);
            (obj["IS_ADMS_APPROVED"] = ent.IS_ADMS_APPROVED);
          } else {
            (obj["OVERALL_STATUS"] = ALLOW);
            (obj["OVERALL_STATUS_ID"] = this.allowStatusId);
            (obj["IS_ADMS_APPROVED"] = ONE);
          }
        } else {
          (obj["OVERALL_STATUS"] = DENY);
          (obj["OVERALL_STATUS_ID"] = this.allowStatusId);
          (obj["IS_ADMS_APPROVED"] = ZERO);
        }
      } else if (
        ent.OVERALL_STATUS == ALLOW ||
        ent.IS_APPROVAL_REQUIRED == 0 ||
        ent.APPROVAL_LEVEL_COUNT == 0
      ) {
        (obj["OVERALL_STATUS"] = ALLOW);
        (obj["OVERALL_STATUS_ID"] = this.allowStatusId);
      } else {
        (obj["OVERALL_STATUS"] = PENDING);
        (obj["OVERALL_STATUS_ID"] = this.pendingStatusId);
      }
    }

    if (ent.APPROVAL_LEVELS) {
      let approvalLevels = [];
      let approvalList = ent.APPROVAL_LEVELS.toString().split(";");
      for (let k = 0; k < approvalList.length; k++) {
        let rowData = approvalList[k].toString().split("|");
        let data: any;
        if (rowData[2] == APPROVED) {
          data = {
            COLOR: "#3eb539",
          };
        } else if (rowData[2] == NOT_APPROVED) {
          data = {
            COLOR: "#e91224",
          };
        } else {
          data = {
            COLOR: "#F4B40F",
          };
        }

        approvalLevels.push(data);
      }
      if (approvalList.length == 1) {
        if (approvalList[0].toString().split("|").length == 1) {
          let data: any;
          approvalLevels = [];
          if (ent["APPROVAL_LEVELS"][0]["STATUS"] == APPROVED) {
            data = {
              COLOR: "#3eb539",
            };
          }
          approvalLevels.push(data);
        }
      }
      obj["APPROVAL_LEVELS"] = approvalLevels;
    } else {
      obj["APPROVAL_LEVELS"] = [
        ...Array(ent.APPROVAL_LEVEL_COUNT),
      ].map((cnt) => ({ COLOR: "#FFBF00" }));
    }
    return obj;
  }

  deleteRole(role, rowIndex) {
    this.roleDeletePopUp = true;
    this.roleNameWithId = role.NAME + "-" + role.ROLE_ID;
    this.rowIndexDelete = rowIndex;
    this.role_Name = role.NAME;
    this.userWithIdName = this.user.NAME.split("-")[0] + "-" + this.umPKId;
    this.roleUserName = this.user.NAME.split("-")[0];
    this.totalSourceCount = 0;
    this.sourceCountWithRole = 0;

    this.totalSourceCount = this.originalEntitlementList.filter((data) =>
      data.SOURCE.includes(role.NAME)
    ).length;

    this.entitlementsAssociatedWithDeletedRole = this.originalEntitlementList.filter(
      (data) => data.SOURCE.includes(role.NAME)
    );

    this.sourceCountWithRole = this.originalEntitlementList.filter(
      (data) =>
        data.SOURCE.includes(role.NAME) &&
        data.SOURCE.split("\n").length == 1 &&
        data.IS_USER_DEFINED == 0
    ).length;
  }

  confirmDeleteRole() {
    this.roleDeletePopUp = false;
    this.isSaveAndApplyBtnDisabled = false;
    this.finalDeleteRoleIds.push(
      this.userRoleList.filter((data, i) => i == this.rowIndexDelete)[0].ID
    );
    this.deletedRoleInfo.push(
      this.userRoleList.filter((data, i) => i == this.rowIndexDelete)[0]
    );
    if (this.EntitlmentFilterSidebarCount > 0)
      this.clearAllEntitlementFilters();

    this.userRoleList.splice(this.rowIndexDelete, 1);

    this.originalUserRoleList = JSON.parse(JSON.stringify(this.userRoleList));

    for (let i = 0; i < this.originalEntitlementList.length; i++) {
      if (
        this.originalEntitlementList[i].SOURCE.trim() ==
        this.role_Name.trim() &&
        this.originalEntitlementList[i].IS_USER_DEFINED == 0
      ) {
        this.originalEntitlementList.splice(i, 1);
        i--;
      } else if (
        this.originalEntitlementList[i].SOURCE.includes(this.role_Name)
      ) {
        this.listOfRole = this.originalEntitlementList[i].SOURCE.split("\n");
        this.listOfRole = this.listOfRole
          .filter((data) => data.toString().trim() != this.role_Name.toString())
          .toString()
          .trim()
          .replaceAll(",", " \n ");
        this.entitlementList[i].SOURCE = this.listOfRole;
        this.originalEntitlementList[i].SOURCE = this.listOfRole;
      }
    }
    this.entitlementList = this.originalEntitlementList;
    let deletedRoleSource: any;
    for (let i = 0; i < this.deletedUserEntList.length; i++) {
      if (this.deletedUserEntList[i].SOURCE.includes(this.role_Name)) {
        deletedRoleSource = this.deletedUserEntList[i].SOURCE.split("\n");
        deletedRoleSource = deletedRoleSource
          .filter((data) => data.toString().trim() != this.role_Name.toString())
          .toString()
          .trim()
          .replaceAll(",", " \n ");
        this.deletedUserEntList[i].SOURCE = deletedRoleSource;

        this.deletedUserEntList[i].SOURCE = this.deletedUserEntList[
          i
        ].SOURCE.toString().replace(this.role_Name, "");
      }
    }

    this.getDetailsForEditedRole(
      this.entitlementsAssociatedWithDeletedRole,
      this.userDeleteEntList
    );

    this.isAddNewUserRole = false;
    this.unSavedChanges = true;
  }

  deleteEntitlementPopUp(entitlement) {
    this.deletedEnt = JSON.parse(JSON.stringify(entitlement));
    this.isConfirmDialog = true;
    this.confirmMessage = "Do you want to Delete this Entitlement ?";
  }

  deleteEntitlementToTable(): void {
    let deletedEntId = this.deletedEnt.ENTITLEMENT_ID;
    let deletedCompId = this.deletedEnt.COMPONENT_ID;
    this.entitlementList = this.entitlementList.filter(
      (e) =>
        !(e.COMPONENT_ID == deletedCompId && e.ENTITLEMENT_ID == deletedEntId)
    );
    this.originalEntitlementList = this.originalEntitlementList.filter(
      (e) =>
        !(e.COMPONENT_ID == deletedCompId && e.ENTITLEMENT_ID == deletedEntId)
    );
    if (this.deletedEnt.USER_ENT_ID) {
      let obj = {
        USER_ENT_ID: this.deletedEnt.USER_ENT_ID,
        ENTITLEMENT_NAME: this.deletedEnt.ENTITLEMENT_NAME,
        IS_ADMS_ENTITLEMENT: this.deletedEnt.IS_ADMS_ENTITLEMENT,
        ENTITLEMENT_ID: this.deletedEnt.ENTITLEMENT_ID,
        COMPONENT_ID: this.deletedEnt.COMPONENT_ID,
      };
      this.deletedUserEntIdList.push(obj);
    }
    this.deletedUserEntList.push(this.deletedEnt);
    this.isConfirmDialog = false;
    this.unSavedChanges = true;
    this.isSaveAndApplyBtnDisabled = false;
    this.isAddNewUserRole = false;
    this.isAddNewEntitlement = false;
  }

  navigateToDetailScreen(roleData) {
    roleData["PRIME_ROLE_ID"] = roleData.ID;
    roleData["ROLE_NAME"] = roleData.NAME;
    this.selectedRole = roleData;
    this.dataService.sendMessage(1);
    this.router.navigateByUrl("/pages/roledetails", {
      state: { role: roleData },
      skipLocationChange: false,
    });
  }

  showSuccessValidations(message: string) {
    this.messageFlag = true;
    this.messageService.add({
      key: "myKey1",
      severity: "success",
      detail: message,
    });
    setTimeout(() => {
      this.messageFlag = false;
    }, 3000);
  }

  showErrorValidations(message: string) {
    this.messageFlag = true;
    this.messageService.add({
      key: "myKey1",
      severity: "error",
      detail: message,
    });
    setTimeout(() => {
      this.messageFlag = false;
    }, 3000);
  }

  createUserSendEmail() {
    let Name = this.user.NAME.split("-")[0];
    let data = {
      userId: this.umPKId.toString(),
      is_Email_send: 1,
      emailId: this.user.EMAIL_ID,
      userName: this.LOGIN_ID.toString(),
      orgId: this.orgId,
      uName: Name,
    };
    this.userDetailsService.createUserSendEmail(data).subscribe(
      {
        next: (response) => {
          this.emailUpdateOn = response["emailUpdateOnDate"].toString();
          const localtime = this.dateFormatterPipe.transform(this.emailUpdateOn);
          this.isEmailSendFlag = response["isEmailSendFlag"];
          if (this.isEmailSendFlag == 1) {
            this.emailSendButtonLabel = "E-mail Sent";
            this.hideTooltip = false;
            this.emailSendButtonTooltip = "E-mail has been sent to this user on " + localtime;//NOSONAR
          }
          this.messageFlag = true;
          this.showSuccessValidations("E-mail has been sent successfully.");
        },
        error: (error) => {
          this.showErrorValidations(error.error);
        }
      });
  }


  navigateToEntDetailScreens(entitlementData) {
    this.dataService.sendMessage(2);
    this.router.navigateByUrl('/pages/entitlementsdetails', {
      state: { entitlement: entitlementData },
      skipLocationChange: false,
    });
  }

  private getDetailsForEditedRole(sourceList: any[], targetList: any[]) {
    for (const e of sourceList) {
      let userObj = {
        LOGIN_ID: this.LOGIN_ID,
        USER_ID: this.umPKId,
        ENTITLEMENT_ID: e["ENTITLEMENT_ID"],
        ENTITLEMENT_NAME: e["ENTITLEMENT_NAME"],
        COMPONENT_ID: e["COMPONENT_ID"],
        COMPONENT_NAME: e["COMPONENT_NAME"],
      };
      targetList.push(userObj);
    }
  }
  getApplicationList(): void {
    const reqBody = {};
    reqBody["tenantId"] = this.pagesService.tenantId;
    reqBody["userId"] = this.userID;
    this.pagesService.getMappedApplicationList(reqBody).subscribe((results) => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          this.applicationList.push(element.value);
        });
        this.applicationList = [...this.applicationList];
      }
    });
  }
  emailSentDetails(): void {
    const reqBody = {};
    reqBody["tenantId"] = this.pagesService.tenantId;
    reqBody["userId"] = this.userID;
    this.pagesService.getLastMailSentDetail(reqBody).subscribe((results) => {
      if (results?.success?.messageCode == "CIAM 159") {
        this.lastMailSent = "E-mail has been sent to this user on ";
        this.enabledTooltip = true;
        this.lastMailSent += results.success.result;
      } else if (results?.error?.messageCode == "CIAM 120") {
        this.enabledTooltip = false;
      }

    });
  }
  sendEmail() {
    const reqBody = {};
    reqBody["userId"] = this.userID;
    reqBody["tenantId"] = this.pagesService.tenantId;
    reqBody["applicationIds"] = this.applicationList;
    if (this.rebacFlag == 'true' && this.entitlementList.length > 0 && (this.applicationList.length == 0 || !this.applicationList.includes(this.pagesService.applicationId, 0))) {
      this.applicationList.push(this.pagesService.applicationId);
    }
    const data = reqBody;
    this.pagesService.sendEmail(data).subscribe(
      {
        next: (results: any) => {
          this.messageFlag = true;
          this.messageService.add({
            key: "myKey1",
            severity: "success",
            detail: "Email send successfully.",
          });
          setTimeout(() => {
            this.messageFlag = false;
          }, 3000);
          this.emailSentDetails();
        },
        error: (error) => { }
      });
  }
  onCheckEmail($event: UntypedFormGroup): void {
    if(this.oldEmail!=this.EditUserForm.value.emailAddress){
      const reqBody = this.EditUserForm.value.emailAddress.toLowerCase();
    const formData = reqBody;
    const formValues = this.EditUserForm.value.emailAddress.toLowerCase();
    this.pagesService.onCheckEmailId(formData, formValues).subscribe(
      {
        next: results => {
          if (results?.error?.messageCode == 'CIAM 105' || results?.success?.messageCode == 'CIAM 105') {
            this.emailFlag = true;
            this.enableBtn1 = true;
            document.getElementById('emailAddress_id')!.style.borderColor = 'red';
          }
          if (results?.error?.messageCode == 'CIAM 201' || results?.success?.messageCode == 'CIAM 201') {
            this.emailInvalidFlag = true;
            this.enableBtn1 = true;
            document.getElementById('emailAddress_id')!.style.borderColor = 'red';
          }
          else if (results.success.code == 200) {
            this.emailFlag = false;
            this.enableBtn1 = false;
            //this.onEditUser();
            this.checkUserName();
            document.getElementById('emailAddress_id')!.style.borderColor = 'grey';
            // if (this.rebacFlag == "true" && !this.createInExternalLdap) {
            //   this.onFirstCreateUser();
            // }
            // else if (this.rebacFlag == "true" && this.createInExternalLdap) {
            //   this.setUsernameExternalLdap();
            // }
            // else {
            //   this.onFirstCreateUser();
            // }

          }
        },

      });
    }
    
  }
  emailDisableEnable(){
   if(this.EditUserForm.value.selectSwitch==this.selectSwitchList[0].value){
      this.EditUserForm.get("emailAddress").disable();
    }
    else if(this.EditUserForm.value.selectSwitch==this.selectSwitchList[1].value) {
      this.EditUserForm.get("emailAddress").enable();
    }
  }
  colorChange() {
    document.getElementById('emailAddress_id')!.style.borderColor = 'grey';
  }
  onAccountsdropdown() {
    this.pagesService.getAccountsdropdown().subscribe(results => {
      const responseData = results.success?.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.accountList.push(data);
        });
        this.accountList = [...this.accountList];
      }
    });
  }

  logEvent(eventName,eventContextName,value){
    const data={
      "ContextName":eventContextName,
      "value":value,
      "EventName":eventName
    };
    this.pagesService.logUserEvent(data).subscribe(resp =>{
      //console.log(resp);
      
    },
            error => {
              console.log("Error >> ",ContextName.EDIT_USER,":", error);
            });
  }
  
}
