import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PagesService } from '../pages.service';
import { validateAllFormFields } from 'app/shared/util/reactiveform-util';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'jhi-addroles',
  templateUrl: './AddRoles.component.html',
  styleUrls: ['./AddRoles.component.scss'],
  providers: [MessageService],
})
export class AddRolesComponent implements OnInit {
  AddRolesForm = this.fb.group({
    displayName: [null, [Validators.required]],
    roleDescription: [null, []],
    roleType: ['Simple', [Validators.required]],
    selectRole: [null, []],
    userList: [null, []],
    groupList: [null, []],
    selectAccessPolicies: [null, []],
  });
  messageFlag = true;
  activeIndex1: number = 0;
  activeIndex2: number = 0;
  enableBtn1 = true;
  enableBtn2 = true;
  enableBtn3 = true;
  clicked1 = false;
  clicked2 = true;
  clicked = true;
  tab1 = false;
  tab2 = false;
  tab3 = false;
  tab4 = false;
  tab5 = false;
  stateOptions: any[];
  value1: string = 'simple';
  pageloadvalues: any;
  ccprimebreadcrumbList = [{ label: `Roles`, routerLink: ['/pages/roles'] }, { label: `Add New Role` }];
  ccprimebreadcrumbList1 = [{ label: `Roles`, routerLink: ['/pages/roles'] }, { label: `Edit Role` }];
  homeiconccprimebreadcrumb = { icon: 'pi pi-home', routerLink: ['/'] };
  selectRoleList: any[] = [];
  selectUsersList: any[] = [];
  selectGroupList: any[] = [];
  selectAccessPoliciesList: any[] = [];
  selectButtonValue: any;
  firstSave = false;
  savedId: any;
  userListList: any[] = [];
  groupListList: any[] = [];
  exUserList: any[] = [];
  newUserList: any[] = [];
  exGroupList: any[] = [];
  newGroupList: any[] = [];
  nameFlag: boolean = false;
  assignGroupCount = 0;
  deAssignGroupCount = 0;
  assignUserCount = 0;
  deAssignUserCount = 0;
  msg: string[] = [];
  msg1: string[] = [];

  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    private messageService: MessageService
  ) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.AddRolesForm.patchValue(JSON.parse(data));
    }
    this.stateOptions = [
      { label: 'Simple', value: 'Simple' },
      { label: 'Composite', value: 'Composite' },
    ];
  }
  ngOnInit(): void {
    this.getUserList();
    this.getGroupList();
  }

  //type of roles
  onCcprimebutton($event: UntypedFormGroup): void {
    $event.reset();
    this.AddRolesForm = this.fb.group({
      roleType: ['Simple', []],
    });
  }

  //check role name on first save
  createRole($event: UntypedFormGroup) {
    if (this.firstSave) {
      this.onEditRole($event);
    } else {
      this.onCheckRoleName($event);
    }
  }

  colorChange() {
    document.getElementById('displayName_id')!.style.borderColor = 'grey';
  }

  //check role name availability api
  onCheckRoleName($event: UntypedFormGroup): void {
    const reqBody = this.AddRolesForm.value?.displayName;
    const formData = reqBody;
    const formValues = this.AddRolesForm.value?.displayName;
    this.pagesService.onCheckRoleName(formData, formValues).subscribe(
      {
        next: results => {
          if (results?.error?.code == 400 || results?.success?.code == 400) {
            this.nameFlag = true;
            this.enableBtn1 = true;
            document.getElementById('displayName_id')!.style.borderColor = 'red';
          } else if (results.success.code == 200) {
            this.nameFlag = false;
            document.getElementById('displayName_id')!.style.borderColor = 'grey';
            this.onFirstCreateRole($event);
          }
        },
        error: () => {

        }
      });
  }

  CheckRoleName($event: UntypedFormGroup): void {
    const reqBody = this.AddRolesForm.value?.displayName;
    const formData = reqBody;
    const formValues = this.AddRolesForm.value?.displayName;
    this.pagesService.onCheckRoleName(formData, formValues).subscribe(
      {
        next: results => {
          if (results?.error?.code == 400 || results?.success?.code == 400) {
            this.nameFlag = true;
            this.enableBtn1 = true;
            document.getElementById('displayName_id')!.style.borderColor = 'red';
          } else if (results.success.code == 200) {
            this.nameFlag = false;
            this.enableBtn1 = false;
            document.getElementById('displayName_id')!.style.borderColor = 'grey';
          }
        },
        error: () => {
        }
      });
  }

  // Role creation functionality
  onFirstCreateRole($event: UntypedFormGroup): void {
    $event.updateValueAndValidity();
    if (!$event.valid) {
      validateAllFormFields($event);
      return;
    }
    const reqBody = {};
    reqBody['displayName'] = this.AddRolesForm.value?.displayName;
    reqBody['description'] = this.AddRolesForm.value?.roleDescription;
    const formData = reqBody;
    const formValues = $event.value;
    this.messageFlag = true;
    this.pagesService.onCreateRoles(formData, formValues).subscribe(
      {
        next: results => {
          this.savedId = results.success.result.id;
          this.messageFlag = true;
          this.clicked1 = false;
          this.AddRolesForm.controls['displayName'].disable();
          this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'Role added successfully.' });
          setTimeout(() => {
            this.messageFlag = false;
          }, 3000);
          this.tab1 = true;
          this.enableBtn1 = true;
          this.showTab();
          this.clicked = false;
          this.firstSave = true;
          if (this.selectButtonValue == 'composite') {
            this.clicked2 = false;
          } else {
            this.clicked = false;
          }
          this.exUserList = [];
          this.exGroupList = [];
        },
        error: () => {
          this.messageFlag = true;
          this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Please try again.' });
          setTimeout(() => {
            this.messageFlag = false;
          }, 3000);
        }
      });
  }

  // edit role functionality
  onEditRole($event: UntypedFormGroup): void {
    $event.updateValueAndValidity();
    const reqBody = {};
    reqBody['description'] = this.AddRolesForm.value?.roleDescription;
    const formData = reqBody;
    this.messageFlag = true;
    this.pagesService.onEditRole(formData, this.savedId).subscribe(
      {
        next: results => {
          this.messageFlag = true;
          this.clicked1 = false;
          this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'Role Edited Successfully.' });
          setTimeout(() => {
            this.messageFlag = false;
          }, 3000);
          this.tab1 = true;
          this.enableBtn1 = true;
          this.showTab();
          this.clicked = false;
          if (this.selectButtonValue == 'composite') {
            this.clicked2 = false;
          } else {
            this.clicked = false;
          }
        },
        error: () => {
          this.messageFlag = true;
          this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Failed to Edit Roles.' });
          setTimeout(() => {
            this.messageFlag = false;
          }, 3000);
        }
      });
  }

  // getting user list from API
  getUserList(): void {
    this.pagesService.getUserListingExD().subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.userListList.push(data);
        });
        this.userListList = [...this.userListList];
      }
    });
  }

  // getting usergroup list from API
  getGroupList(): void {
    this.pagesService.gettableDataListGroups().subscribe(results => {
      const responseData = results.success.result.records;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.displayName;
          data.value = element.id;
          this.groupListList.push(data);
        });
        this.groupListList = [...this.groupListList];
      }
    });
  }

  onCcprimebutton19($event: UntypedFormGroup): void { }

  //getting existing user list
  getExUserList() {
    this.pagesService.getuserTableDataViewRole(this.savedId).subscribe(results => {
      const responseData = results.success.result;
      this.exUserList = [];
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.first_name;
          data.value = element.id;
          this.exUserList.push(data.value);
        });
        this.exUserList = [...this.exUserList];
      }
    });
  }

  //getting new user list
  getNewUserList() {
    const responseData = this.AddRolesForm.value.userList;
    this.newUserList = [];
    if (responseData && responseData.length > 0) {
      responseData.forEach((element: any = {}) => {
        const data = {
          label: null,
          value: null,
        };
        data.label = element.label;
        data.value = element.value;
        this.newUserList.push(data.value);
      });
      this.newUserList = [...this.newUserList];
    }
  }

  //Assigning user to role functionality
  onAssignUsersToRole($event: UntypedFormGroup): void {
    $event.updateValueAndValidity();
    this.newUserList = this.AddRolesForm.value.userList;
    const assignList = this.newUserList.filter(x => !this.exUserList.includes(x));
    const deassignList = this.exUserList.filter(x => !this.newUserList.includes(x));
    const reqBody = {};
    reqBody['assignList'] = assignList;
    reqBody['deAssignList'] = deassignList;
    const ID = this.savedId;
    this.messageFlag = true;
    const formValues = reqBody;
    this.pagesService.onSaveAssignUsersToRole(formValues, ID).subscribe(
      {
        next: results => {
          for (let i = 0; i < assignList.length; i++) {
            if (results.success.result.AssingUsersToRole[i]?.messageCode == 'CIAM 151') {
              this.assignUserCount = this.assignUserCount + 1;
            }
          }
          for (let i = 0; i < deassignList.length; i++) {
            if (results.success.result["De-AssingUsersFromRole"][i]?.messageCode == 'CIAM 152') {
              this.deAssignUserCount = this.deAssignUserCount + 1;
            }
          }
          if (this.deAssignUserCount == 0 && this.assignUserCount > 0) {
            this.msg1.push(this.assignUserCount + ' User Assigned successfully');
          } else if (this.deAssignUserCount > 0 && this.assignUserCount > 0) {
            this.msg1.push(this.assignUserCount + ' User Assigned successfully');

            this.msg1.push(this.deAssignUserCount + ' User De-assigned successfully');
          } else if (this.deAssignUserCount > 0 && this.assignUserCount == 0) {
            this.msg1.push(this.deAssignUserCount + ' User De-assigned successfully');
          }

          this.messageFlag = true;
          this.clicked1 = false;
          this.messageService.add({ key: 'myKey1', severity: 'success', detail: this.msg1.join('\n') });
          setTimeout(() => {
            this.messageFlag = false;
            this.assignUserCount = 0;
            this.deAssignUserCount = 0;
            this.msg1 = [];
          }, 3000);
          this.tab3 = true;
          this.enableBtn2 = true;
          this.showTab();
          this.clicked = false;
          this.getExUserList();
        },
        error: () => {
          this.messageFlag = true;
          this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Failed to Assign Users.' });
          setTimeout(() => {
            this.messageFlag = false;
          }, 3000);
        }
      });
  }


  //getting existing group list
  getExGroupList() {
    this.pagesService.getGroupByRole(this.savedId).subscribe((results: any) => {
      const responseData = results.success.result;
      this.exGroupList = [];
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.exGroupList.push(data.value);
        });
        this.exGroupList = [...this.exGroupList];
      }
    });
  }

  getNewGroupList() {
    const responseData = this.AddRolesForm.value.groupList;
    this.newGroupList = [];
    if (responseData && responseData.length > 0) {
      responseData.forEach((element: any = {}) => {
        const data = {
          label: null,
          value: null,
        };
        data.label = element.label;
        data.value = element.value;
        this.newGroupList.push(data.value);
      });
      this.newGroupList = [...this.newGroupList];
    }
  }

  // assigning usergroup to role functionality
  onAssignGroupsToRole($event: UntypedFormGroup): void {
    $event.updateValueAndValidity();
    this.newGroupList = this.AddRolesForm.value.groupList;
    const assignList = this.newGroupList.filter(x => !this.exGroupList.includes(x));
    const deassignList = this.exGroupList.filter(x => !this.newGroupList.includes(x));
    const reqBody = {};
    reqBody['assignList'] = assignList;
    reqBody['deAssignList'] = deassignList;
    const ID = this.savedId;
    const formValues = reqBody;
    this.messageFlag = true;
    this.pagesService.onSaveAssignGroupsToRole(formValues, ID).subscribe(
      {
        next: results => {
          for (let i = 0; i < assignList.length; i++) {
            if (results.success.result.AssingGroupsToRole[i]?.messageCode == 'CIAM 151') {
              this.assignGroupCount = this.assignGroupCount + 1;
            }
          }
          for (let i = 0; i < deassignList.length; i++) {
            if (results.success.result["De-AssingGroupsFromRole"][i]?.messageCode == 'CIAM 152') {
              this.deAssignGroupCount = this.deAssignGroupCount + 1;
            }
          }
          if (this.deAssignGroupCount == 0 && this.assignGroupCount > 0) {
            this.msg.push(this.assignGroupCount + ' Group Assigned successfully');
          } else if (this.deAssignGroupCount > 0 && this.assignGroupCount > 0) {
            this.msg.push(this.assignGroupCount + ' Group Assigned successfully');

            this.msg.push(this.deAssignGroupCount + ' Group De-assigned successfully');
          } else if (this.deAssignGroupCount > 0 && this.assignGroupCount == 0) {
            this.msg.push(this.deAssignGroupCount + ' Group De-assigned successfully')
          }

          this.messageFlag = true;
          this.clicked1 = false;
          this.messageService.add({ key: 'myKey1', severity: 'success', detail: this.msg.join('\n') });
          setTimeout(() => {
            this.messageFlag = false;
            this.assignGroupCount = 0;
            this.deAssignGroupCount = 0;
            this.msg = [];
          }, 3000);
          this.tab4 = true;
          this.enableBtn3 = true;
          this.clicked = false;
          this.getExGroupList();
        },
        error: () => {
          this.messageFlag = true;
          this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Failed to Assign Groups.' });
          setTimeout(() => {
            this.messageFlag = false;
          }, 3000);
        }
      });
  }

  onCcprimebutton11($event: UntypedFormGroup): void { }

  //tab activation function
  showTab() {
    if (this.activeIndex1 === 0) {
      this.activeIndex1 = 2; //show users tab since composite tab disabled
    } else if (this.activeIndex1 === 2) {
      this.activeIndex1 = 3;
    } else if (this.activeIndex1 === 3) {
      this.activeIndex1 = 4;
    }
  }

}
