<div class="layout-content custom-container">
    <form [formGroup]="ManageUserFieldsForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class=" p-col-12">
                <div class="ui-fluid p-formgrid p-grid">
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 left">
                        <div class=" p-fluid   p-field    ">
                            <p-breadcrumb [model]="ccprimebreadcrumbList" [home]="homeiconccprimebreadcrumb">
                            </p-breadcrumb>
                        </div>

                    </div>
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">

                        <h5 class="custom-head1">Manage user fields</h5>

                    </div>
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                        <div class=" p-fluid   p-field    ">
                            <label>
                                Select the fields you want as part of user profiles
                            </label>
                            <div *ngFor="let item of checkboxList" class="p-field-checkbox ">
                                <p-checkbox id="checkbox_id" [label]="item.label" inputId="_id" name="checkbox_name"
                                    [value]="item.value" binary=true formControlName="checkbox">
                                </p-checkbox>
                            </div>

                        </div>

                    </div>
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">

                        <div class="ui-fluid p-formgrid p-grid">
                            <div class="p-col-12 p-sm-12 p-md-6 p-lg-3 p-xl-3 p-offset-0 left">
                                <div class=" p-fluid   p-field    ">
                                    <label>
                                        Field Name
                                    </label>
                                    <input type="text" id="textField_id" name="textField_name"
                                        formControlName="textField" pInputText placeholder="Field name"
                                        class="p-inputtext inputtextHeight  " />

                                </div>

                            </div>
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3 p-offset-undefined left">
                                <div class=" p-fluid   p-field    ">
                                    <label>
                                        Type
                                    </label>
                                    <div class="p-fluid  p-field">
                                        <p-dropdown id="selectid" name="select" placeholder="Field type"
                                            [options]="selectList" [autoDisplayFirst]="false" formControlName="select">
                                            <ng-template let-items pTemplate="item">
                                                <div *ngIf="items?.label?.length &lt;= 35;">
                                                    {{items.label}}
                                                </div>
                                                <div *ngIf="items?.label?.length > 35;" pTooltip="{{items.label}}"
                                                    tooltipPosition="bottom">
                                                    {{items.label.substring(0,35) + "..." }}
                                                </div>
                                            </ng-template>
                                        </p-dropdown>
                                    </div>

                                </div>

                            </div>
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6 p-offset-undefined left">
                            </div>
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3 p-offset-undefined left">
                                <div class=" p-fluid   p-field    ">
                                    <label>
                                        Field Name
                                    </label>
                                    <input type="text" id="textField2_id" name="textField2_name"
                                        formControlName="textField2" pInputText placeholder="Field name"
                                        class="p-inputtext inputtextHeight  " />

                                </div>

                            </div>
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3 p-offset-undefined left">
                                <div class=" p-fluid   p-field    ">
                                    <label>
                                        Type
                                    </label>
                                    <div class="p-fluid  p-field">
                                        <p-dropdown id="select2id" name="select2" placeholder="Field type"
                                            [options]="select2List" [autoDisplayFirst]="false"
                                            formControlName="select2">
                                        </p-dropdown>
                                    </div>

                                </div>

                            </div>
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6 p-offset-undefined left">
                            </div>
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3 p-offset-undefined left">
                                <div class="btnwrap">
                                    <button pButton class="p-button-info" type="button" icon="pi pi-plus" iconPos="left"
                                        (click)="onCcprimebutton2(ManageUserFieldsForm);" label="Add more">
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right">
                        <div class="btnwrap">
                            <button pButton class="p-button-secondary p-button" type="button"
                                (click)="onCcprimebutton(ManageUserFieldsForm);" label="Cancel">
                            </button>
                        </div>
                        <div class="btnwrap">
                            <button pButton class="p-button-primary p-button" type="button"
                                (click)="onCcprimebutton1(ManageUserFieldsForm);" label="Save Fields">
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>