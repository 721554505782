<div class="layout-content custom-container">
    <form [formGroup]="GenerateTokenForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class=" p-col-12">
                <div class="ui-fluid p-formgrid p-grid">
                    
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6 p-offset-undefined left">
                        <div class=" p-fluid   p-field    ">
                            <label [ngClass]="{'field-required': GenerateTokenForm.get('select')?.errors?.required}">
                                Token Name
                            </label> <span class='invalid-feedback-astrik'>*</span>
                            <div class="p-fluid  p-field">
                                <input type="text" id="textField_id" name="textField_name" formControlName="select" pInputText placeholder="Enter name" class="p-inputtext inputtextHeight  " pattern="^[^\s]+(\s+[^\s]+)*$" maxlength="50"/>
                            </div>

                            <div class="invalid-feedback" *ngIf="GenerateTokenForm.get('select')!.invalid && (GenerateTokenForm.get('select')!.dirty || GenerateTokenForm.get('select')!.touched)">
                                <div *ngIf="GenerateTokenForm.get('select')?.errors?.required">
                                    Type is required.
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6 p-offset-undefined left">
                        <div class=" p-fluid   p-field    ">
                            <label  [ngClass]="{'field-required': GenerateTokenForm.get('textField')?.errors?.required}">Expiry Date</label><span class='invalid-feedback-astrik'>*</span>
                            <p-calendar [showIcon]="true" id="date_id" name="date_name" formControlName="textField" placeholder="SelectDate" 
                            yearRange="2000:2030" hourFormat="12" dateFormat="dd-mm-yy" 
                            [hideOnDateTimeSelect]="true" [stepHour]="1" [stepMinute]="1" [showButtonBar]="true"></p-calendar>
                            <div class="invalid-feedback" *ngIf="GenerateTokenForm.get('textField')!.invalid && (GenerateTokenForm.get('textField')!.dirty || GenerateTokenForm.get('textField')!.touched)">
                                <div *ngIf="GenerateTokenForm.get('textField')?.errors?.required">
                                    Date is required
                                </div>
                            </div> 
                        </div>

                    </div><br>
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right">
                        <div class="btnwrap">
                            <button pButton class="p-button-secondary" type="button" (click)="onCcprimebutton1(GenerateTokenForm);" label="Close">
                            </button>
                        </div>
                        <div class="btnwrap">
                            <button pButton class="p-button-primary" type="button" [disabled]="!GenerateTokenForm.valid" (click)="GenerateTokenResource();" label="Generate">
                            </button>
                        </div>
                        <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>