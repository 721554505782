import { Routes } from '@angular/router';
import { UserRouteAccessService } from 'app/core/auth/user-route-access-service';
import { AddUserComponent } from './AddUser/AddUser.component';
import { ManageUserFieldsComponent } from './ManageUserFields/ManageUserFields.component';
import { UserListingComponent } from './UserListing/UserListing.component';
import { ViewUserComponent } from './ViewUser/ViewUser.component';
import { InviteUsersComponent } from './InviteUsers/InviteUsers.component';
import { AddRolesComponent } from './AddRoles/AddRoles.component';
import { AddUserGroupComponent } from './AddUserGroup/AddUserGroup.component';
import { RolesComponent } from './Roles/Roles.component';
import { ListGroupsComponent } from './ListGroups/ListGroups.component';
import { ViewRoleComponent } from './ViewRole/ViewRole.component';
import { ViewGroupComponent } from './ViewGroup/ViewGroup.component';
import { AssignUsersComponent } from './AssignUsers/AssignUsers.component';
import { EditUserComponent } from './EditUser/EditUser.component';
import { EditGroupComponent } from './EditGroup/EditGroup.component';
import { EditRoleComponent } from './EditRole/EditRole.component';
import { UserGroupMappingComponent } from './UserGroupMapping/UserGroupMapping.component';
import { UserMappingComponent } from './UserMapping/UserMapping.component';
import { MirrorUserComponent } from './MirrorUser/MirrorUser.component';
import { DeactivateComponent } from './Deactivate/Deactivate.component';
import { ActivateUserComponent } from './ActivateUser/ActivateUser.component';
import { UnlockUserComponent } from './UnlockUser/UnlockUser.component';
import { SuspendUserComponent } from './SuspendUser/SuspendUser.component';
import { ForceLogOffComponent } from './ForceLogOff/ForceLogOff.component';
import { ApplicationOnboardingComponent } from './ApplicationOnboarding/ApplicationOnboarding.component';
import { ApplicationResourcesComponent } from './ApplicationResources/ApplicationResources.component';
import { AttributesandGroupsComponent } from './AttributesandGroups/AttributesandGroups.component';
import { CreateResourcesComponent } from './CreateResources/CreateResources.component';
import { CreateAttributeComponent } from './CreateAttribute/CreateAttribute.component';
import { DeleteApplicationResourceComponent } from './DeleteApplicationResource/DeleteApplicationResource.component';
import { EditApplicationResourceComponent } from './EditApplicationResource/EditApplicationResource.component';
import { CreateAttributeGroupComponent } from './CreateAttributeGroup/CreateAttributeGroup.component';
import { EditAttributeGroupComponent } from './EditAttributeGroup/EditAttributeGroup.component';
import { DeleteAttributeGroupComponent } from './DeleteAttributeGroup/DeleteAttributeGroup.component';
import { EditAtrributeComponent } from './EditAtrribute/EditAtrribute.component';
import { DeleteAttributeComponent } from './DeleteAttribute/DeleteAttribute.component';
import { ApplicationRolesUserMappingComponent } from './ApplicationRolesUserMapping/ApplicationRolesUserMapping.component';
import { ApplicationRolesUserGroupMappingComponent } from './ApplicationRolesUserGroupMapping/ApplicationRolesUserGroupMapping.component';
import { DeleteGroupComponent } from './DeleteGroup/DeleteGroup.component';
import { DeleteRoleComponent } from './DeleteRole/DeleteRole.component';
import { MyAccountComponent } from './MyAccount/MyAccount.component';
import { APIAccessTokenComponent } from './APIAccessToken/APIAccessToken.component';
import { GenerateTokenComponent } from './GenerateToken/GenerateToken.component';
import { RegenerateTokenComponent } from './RegenerateToken/RegenerateToken.component';
import { TokenGeneratedComponent } from './TokenGenerated/TokenGenerated.component';
import { DeleteTokenComponent } from './DeleteToken/DeleteToken.component';
import { CreateComponent } from './CreateComponent/CreateComponent.component';
import { EditComponent } from './EditComponent/EditComponent.component';
import { CreateApplicationComponent } from './CreateApplication/CreateApplication.component';
import { EditApplicationComponent } from './EditApplication/EditApplication.component';
import { CreateAttributeTypeComponent } from './CreateAttributeType/CreateAttributeType.component';
import { EditAttributeTypeComponent } from './EditAttributeType/EditAttributeType.component';
import { CreateApplicationRoleComponent } from './CreateApplicationRole/CreateApplicationRole.component';
import { EditApplicationRoleComponent } from './EditApplicationRole/EditApplicationRole.component';
import { TenantListingComponent } from './TenantListing/TenantListing.component';
import { CreateTenantComponent } from './CreateTenant/CreateTenant.component';
import { EditTenantComponent } from './EditTenant/EditTenant.component';
import { ApplicationTenantMappingComponent } from './ApplicationTenantMapping/ApplicationTenantMapping.component';
import { TenantSettingsListingComponent } from './TenantSettingsListing/TenantSettingsListing.component';
import { AddCustomField } from './AddCustomField/AddCustomField.component';
import { DeleteCustomFieldComponent } from './DeleteCustomField/DeleteCustomField.component';
import { EditCustomField } from './EditCustomField/EditCustomField.component';
import { WorkflowConfigurationListingComponent } from './WorkflowConfigurationListing/WorkflowConfigurationListing.component';
import { CreateWorkflowConfigurationComponent } from './CreateWorkflowConfiguration/CreateWorkflowConfiguration.component';
import { ApprovalListingComponent } from './ApprovalListing/ApprovalListing.component';
import { ViewTransactionDetailsComponent } from './ViewTransactionDetails/ViewTransactionDetails.component';
import { ApproveTransactionComponent } from './ApproveTransaction/ApproveTransaction.component'
import { RejectTransactionComponent } from './RejectTransaction/RejectTransaction.component';
import { MyRequestsListingComponent } from './MyRequestsListing/MyRequestsListing.component';
import { EditWorkflowConfigurationComponent } from './EditWorkflowConfiguration/EditWorkflowConfiguration.component';
import { DeleteUserComponent } from './DeleteUser/DeleteUser.component';
import { RevokeDeletedUserComponent } from './RevokeDeletedUser/RevokeDeletedUser.component';
import { ViewWorkflowConfigurationDetailsComponent } from './ViewWorkflowConfigurationDetails/ViewWorkflowConfigurationDetails.component';
import { PlanListingComponent } from './planListing/planListing.component';
import { AddPlanComponent } from './AddPlan/AddPlan.component';
import { EditPlanComponent } from './EditPlan/EditPlan.component';
import { ViewApplicationPlanComponent } from './ViewApplicationPlan/ViewApplicationPlan.component';
import { AddSubscriptionComponent } from './AddSubscription/AddSubscription.component';
import { ManageSubscriptionComponent } from './ManageSubscription/ManageSubscription.component';
import { AddAsSubscriptionComponent } from './AddAsSubscription/AddAsSubscription.component';
import { EditSubscriptionComponent } from './EditSubscription/EditSubscription.component';
import { AuthenticationComponent } from './Authentication/Authentication.component';
import { CreateHostResourcesEndpointComponent } from './CreateHostResourcesEndpoint/CreateHostResourcesEndpoint.component';
import { EditHostApplicationResourceComponent } from './EditHostApplicationResource/EditHostApplicationResource.component';
import { DeleteHostApplicationEndpointComponent } from './DeleteHostApplicationEndpoint/DeleteHostApplicationEndpoint.component';
import { EndpointMappingComponent } from './EndpointMapping/EndpointMapping.component';
import { ManageEntitlementsComponent } from './ManageEntitlements/ManageEntitlements.component';
import { EntitlementsDetailsComponent } from './EntitlementDetails/EntitlementsDetails.component';
import { AccountManagementComponent } from './accountManagement/accountManagement.component';
import { CreateAccountComponent } from './CreateAccount/CreateAccount.component';
import { EditAccountComponent } from './EditAccount/EditAccount.component';
import { AccountUserMappingComponent } from './AccountUserMapping/AccountUserMapping.component';
import { CanDeactivateGuard } from 'app/guards/can-activate.guard';
import { AddAccountParameterComponent } from './AddAccountParameter/AddAccountParameter.component';
import { DeactivateAccountComponent } from './DeactivateAccount/DeactivateAccount.component';
import { ActivateAccountComponent } from './ActivateAccount/ActivateAccount.component';
import { ImportUserComponent } from './ImportUser/ImportUser.component';
import { BulkActionCheckStatusComponent } from './BulkActionCheckStatus/BulkActionCheckStatus.component';
import { EntitlementEndpointMappingComponent } from './EntitlementEndpointMapping/EntitlementEndpointMapping.component';
import { CancelRequestComponent } from './CancelRequest/CancelRequest.component';
import { RoleManagementComponent } from './ManageRoles/ManageRoles.component';
import { RoleDetailsComponent } from './ManageRolesDetails/ManageRolesDetails.component';
import { InviteEmailComponent } from './InviteEmail/InviteEmail.component';
import { CreateHostAttributeComponent } from './CreateHostAttribute/CreateHostAttribute.component';
import { EmailTemplateMappingComponent } from './EmailTemplateMapping/EmailTemplateMapping.component';
import { EditHostAttributeComponent } from './EditHostAttribute/EditHostAttribute.component';
import { DeleteHostAttributeComponent } from './DeleteHostAttribute/DeleteHostAttribute.component';
import { DeletePlanComponent } from './DeletePlan/DeletePlan.component';
import { ReorderPlanComponent } from './ReorderPlan/ReorderPlan.component';
import { CreateHostAttributeGroupComponent } from './CreateHostAttributeGroup/CreateHostAttributeGroup.component';
import { EditHostAttributeGroupComponent } from './EditHostAttributeGroup/EditHostAttributeGroup.component';
import { DeleteHostAttributeGroupComponent } from './DeleteHostAttributeGroup/DeleteHostAttributeGroup.component';
import { HostAttributeGroupMappingComponent } from './HostAttributeGroupMapping/HostAttributeGroupMapping.component';
import { EditEmailTemplateComponent } from './EditEmailTemplate/EditEmailTemplate.component';
import { ViewHostHierarchyAttributesComponent } from './ViewHostHierarchyAttributes/ViewHostHierarchyAttributes.component';

export const PAGES_ROUTE: Routes = [
  {
    path: 'adduser',
    component: AddUserComponent,
    data: {
      authorities: [],
      pageTitle: 'Add User',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'manageuserfields',
    component: ManageUserFieldsComponent,
    data: {
      authorities: [],
      pageTitle: 'Manage User Fields',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'userlisting',
    component: UserListingComponent,
    data: {
      authorities: [],
      pageTitle: 'Users',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'viewuser',
    component: ViewUserComponent,
    data: {
      authorities: [],
      pageTitle: 'View User',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'inviteusers',
    component: InviteUsersComponent,
    data: {
      authorities: [],
      pageTitle: 'Invite Users',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'addroles',
    component: AddRolesComponent,
    data: {
      authorities: [],
      pageTitle: 'Add Roles',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'addusergroup',
    component: AddUserGroupComponent,
    data: {
      authorities: [],
      pageTitle: 'Add User Group',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'roles',
    component: RolesComponent,
    data: {
      authorities: [],
      pageTitle: 'Roles',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'listgroups',
    component: ListGroupsComponent,
    data: {
      authorities: [],
      pageTitle: 'User Groups',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'viewrole',
    component: ViewRoleComponent,
    data: {
      authorities: [],
      pageTitle: 'View Role',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'viewgroup',
    component: ViewGroupComponent,
    data: {
      authorities: [],
      pageTitle: 'View User Group',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'assignusers',
    component: AssignUsersComponent,
    data: {
      authorities: [],
      pageTitle: 'Assign Users',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'edituser',
    component: EditUserComponent,
    data: {
      authorities: [],
      pageTitle: 'Edit User',
    },
    canActivate: [UserRouteAccessService],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'editgroup',
    component: EditGroupComponent,
    data: {
      authorities: [],
      pageTitle: 'Edit User Group',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'editrole',
    component: EditRoleComponent,
    data: {
      authorities: [],
      pageTitle: 'Edit Role',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'usermapping',
    component: UserMappingComponent,
    data: {
      authorities: [],
      pageTitle: 'User Mapping',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'usergroupmapping',
    component: UserGroupMappingComponent,
    data: {
      authorities: [],
      pageTitle: 'User Group Mapping',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'mirroruser',
    component: MirrorUserComponent,
    data: {
      authorities: [],
      pageTitle: 'Mirror User',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'deactivate',
    component: DeactivateComponent,
    data: {
      authorities: [],
      pageTitle: 'Deactivate User',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'activate',
    component: ActivateUserComponent,
    data: {
      authorities: [],
      pageTitle: 'Activate User',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'suspendUser',
    component: SuspendUserComponent,
    data: {
      authorities: [],
      pageTitle: 'Suspend User',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'forceLogoff',
    component: ForceLogOffComponent,
    data: {
      authorities: [],
      pageTitle: 'ForceLogOff User',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'unlockuser',
    component: UnlockUserComponent,
    data: {
      authorities: [],
      pageTitle: 'Unlock User',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'applicationonboarding',
    component: ApplicationOnboardingComponent,
    data: {
      authorities: [],
      pageTitle: 'Application Onboarding',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'applicationresources',
    component: ApplicationResourcesComponent,
    data: {
      authorities: [],
      pageTitle: 'Application Resources',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'attribute&group',
    component: AttributesandGroupsComponent,
    data: {
      authorities: [],
      pageTitle: 'Attributes and Groups',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'createResources',
    component: CreateResourcesComponent,
    data: {
      authorities: [],
      pageTitle: 'Create Resources',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'createAttribute',
    component: CreateAttributeComponent,
    data: {
      authorities: [],
      pageTitle: 'Create Attribute',
    },
    canActivate: [UserRouteAccessService],
  }, {
    path: 'createAttributeGroup',
    component: CreateAttributeGroupComponent,
    data: {
      authorities: [],
      pageTitle: 'Create Attribute Group',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'editapplicationresource',
    component: EditApplicationResourceComponent,
    data: {
      authorities: [],
      pageTitle: 'Edit Application Resource',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'deleteapplicationresource',
    component: DeleteApplicationResourceComponent,
    data: {
      authorities: [],
      pageTitle: 'Delete Application Resource',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'deleteattribute',
    component: DeleteAttributeComponent,
    data: {
      authorities: [],
      pageTitle: 'Delete Attribute',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'editatrribute',
    component: EditAtrributeComponent,
    data: {
      authorities: [],
      pageTitle: 'Edit Atrribute',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'deleteattributegroup',
    component: DeleteAttributeGroupComponent,
    data: {
      authorities: [],
      pageTitle: 'Delete Attribute Group',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'editattributegroup',
    component: EditAttributeGroupComponent,
    data: {
      authorities: [],
      pageTitle: 'Edit Attribute Group',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'applicationroleusermapping',
    component: ApplicationRolesUserMappingComponent,
    data: {
      authorities: [],
      pageTitle: 'Application Roles',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'applicationroleusergroupmapping',
    component: ApplicationRolesUserGroupMappingComponent,
    data: {
      authorities: [],
      pageTitle: 'Application Roles UserGroup Mapping',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'deletegroup',
    component: DeleteGroupComponent,
    data: {
      authorities: [],
      pageTitle: 'Delete Group',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'deleterole',
    component: DeleteRoleComponent,
    data: {
      authorities: [],
      pageTitle: 'Delete Role',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'ApiAccessToken',
    component: APIAccessTokenComponent,
    data: {
      authorities: [],
      pageTitle: 'APIAccessToken',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'myaccount',
    component: MyAccountComponent,
    data: {
      authorities: [],
      pageTitle: 'My Account',
    },
    canActivate: [UserRouteAccessService],
  },

  {
    path: 'generatetoken',
    component: GenerateTokenComponent,
    data: {
      authorities: [],
      pageTitle: 'Generate Token',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'regeneratetoken',
    component: RegenerateTokenComponent,
    data: {
      authorities: [],
      pageTitle: 'Regenerate Token',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'tokengenerated',
    component: TokenGeneratedComponent,
    data: {
      authorities: [],
      pageTitle: 'Token Generated',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'deletetoken',
    component: DeleteTokenComponent,
    data: {
      authorities: [],
      pageTitle: 'Delete Token',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'createcomponent',
    component: CreateComponent,
    data: {
      authorities: [],
      pageTitle: 'Create Component',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'createapplication',
    component: CreateApplicationComponent,
    data: {
      authorities: [],
      pageTitle: 'Create Application',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'editcomponent',
    component: EditComponent,
    data: {
      authorities: [],
      pageTitle: 'Edit Component',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'editApplication',
    component: EditApplicationComponent,
    data: {
      authorities: [],
      pageTitle: 'Edit Application',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'createattributetype',
    component: CreateAttributeTypeComponent,
    data: {
      authorities: [],
      pageTitle: 'Create AttributeType',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'editattributetype',
    component: EditAttributeTypeComponent,
    data: {
      authorities: [],
      pageTitle: 'Edit AttributeType',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'createapplicationrole',
    component: CreateApplicationRoleComponent,
    data: {
      authorities: [],
      pageTitle: 'Create ApplicationRole',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'editapplicationrole',
    component: EditApplicationRoleComponent,
    data: {
      authorities: [],
      pageTitle: 'Edit ApplicationRole',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'createTenant',
    component: CreateTenantComponent,
    data: {
      authorities: [],
      pageTitle: 'Create TenantComponent',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'tenantlisting',
    component: TenantListingComponent,
    data: {
      authorities: [],
      pageTitle: 'Tenants',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'editTenant',
    component: EditTenantComponent,
    data: {
      authorities: [],
      pageTitle: 'Edit Tenant',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'applicationTenant',
    component: ApplicationTenantMappingComponent,
    data: {
      authorities: [],
      pageTitle: 'Application Tenant Mapping',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'tenantsettinglisting',
    component: TenantSettingsListingComponent,
    data: {
      authorities: [],
      pageTitle: 'Tenant Settings',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'addCustomField',
    component: AddCustomField,
    data: {
      authorities: [],
      pageTitle: 'Add CustomField',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'deleteCustomField',
    component: DeleteCustomFieldComponent,
    data: {
      authorities: [],
      pageTitle: 'Delete CustomField',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'approvals',
    component: ApprovalListingComponent,
    data: {
      authorities: [],
      pageTitle: 'My Approvals',
    },
    canActivate: [UserRouteAccessService],
  }, {
    path: 'editCustomField',
    component: EditCustomField,
    data: {
      authorities: [],
      pageTitle: 'Edit CustomField',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'WorkflowConfigurationListing',
    component: WorkflowConfigurationListingComponent,
    data: {
      authorities: [],
      pageTitle: 'Workflow Configurations',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'createworkflowconfiguration',
    component: CreateWorkflowConfigurationComponent,
    data: {
      authorities: [],
      pageTitle: 'Create Workflow Configuration',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'viewtransactions',
    component: ViewTransactionDetailsComponent,
    data: {
      authorities: [],
      pageTitle: 'View Transaction Details',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'approvetransaction',
    component: ApproveTransactionComponent,
    data: {
      authorities: [],
      pageTitle: 'Approve Transaction',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'rejecttransaction',
    component: RejectTransactionComponent,
    data: {
      authorities: [],
      pageTitle: 'Reject Transaction',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'myrequestslisting',
    component: MyRequestsListingComponent,
    data: {
      authorities: [],
      pageTitle: 'My Requests',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'editworkflowconfiguration',
    component: EditWorkflowConfigurationComponent,
    data: {
      authorities: [],
      pageTitle: 'Edit WorkflowConfiguration',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'deleteUser',
    component: DeleteUserComponent,
    data: {
      authorities: [],
      pageTitle: 'Delete User',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'revokedeleteduser',
    component: RevokeDeletedUserComponent,
    data: {
      authorities: [],
      pageTitle: 'Revoke Deleted User',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'viewworkflow',
    component: ViewWorkflowConfigurationDetailsComponent,
    data: {
      authorities: [],
      pageTitle: 'View Workflow Configuration Details',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'viewplan',
    component: ViewApplicationPlanComponent,
    data: {
      authorities: [],
      pageTitle: 'View Application Plan',
    },
    canActivate: [UserRouteAccessService],
  }
  ,
  {
    path: 'planListing',
    component: PlanListingComponent,
    data: {
      authorities: [],
      pageTitle: 'Application Plans',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'addPlan',
    component: AddPlanComponent,
    data: {
      authorities: [],
      pageTitle: 'Add Plan',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'editPlan',
    component: EditPlanComponent,
    data: {
      authorities: [],
      pageTitle: 'Edit Plan',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'addSubscription',
    component: AddSubscriptionComponent,
    data: {
      authorities: [],
      pageTitle: 'Add Subscription',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'manageSubscription',
    component: ManageSubscriptionComponent,
    data: {
      authorities: [],
      pageTitle: 'Manage Subscriptions',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'addasSubscription',
    component: AddAsSubscriptionComponent,
    data: {
      authorities: [],
      pageTitle: 'Add Subscription',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'editSubscription',
    component: EditSubscriptionComponent,
    data: {
      authorities: [],
      pageTitle: 'Edit Subscription',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'authentication',
    component: AuthenticationComponent,
    data: {
      authorities: [],
      pageTitle: 'Authentication',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'endpointmapping',
    component: EndpointMappingComponent,
    data: {
      authorities: [],
      pageTitle: 'EndpointMapping',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'createHostResourceEndpoint',
    component: CreateHostResourcesEndpointComponent,
    data: {
      authorities: [],
      pageTitle: 'Create Resource Enpoint',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'editHostApplicationEndpoint',
    component: EditHostApplicationResourceComponent,
    data: {
      authorities: [],
      pageTitle: 'Edit Resource Enpoint',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'deleteHostApplicationEndpoint',
    component: DeleteHostApplicationEndpointComponent,
    data: {
      authorities: [],
      pageTitle: 'Delete Resource Enpoint',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'manageentitlements',
    component: ManageEntitlementsComponent,
    data: {
      authorities: [],
      pageTitle: 'Manage Entitlements',
    },
    canActivate: [UserRouteAccessService],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'entitlementsdetails',
    component: EntitlementsDetailsComponent,
    data: {
      authorities: [],
      pageTitle: 'Entitlements Details',
    },
    canActivate: [UserRouteAccessService],
    canDeactivate: [CanDeactivateGuard]
  }, {
    path: 'accountManagement',
    component: AccountManagementComponent,
    data: {
      authorities: [],
      pageTitle: 'Account Mangement',
    },
    canActivate: [UserRouteAccessService],
  }, {
    path: 'accountUserMapping',
    component: AccountUserMappingComponent,
    data: {
      authorities: [],
      pageTitle: 'Account User Mapping',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'createaccount',
    component: CreateAccountComponent,
    data: {
      authorities: [],
      pageTitle: 'Create Account',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'editaccount',
    component: EditAccountComponent,
    data: {
      authorities: [],
      pageTitle: 'Edit Account',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'addaccountparameter',
    component: AddAccountParameterComponent,
    data: {
      authorities: [],
      pageTitle: 'Add Parameter',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'activateAccount',
    component: ActivateAccountComponent,
    data: {
      authorities: [],
      pageTitle: 'DeactivateAccount',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'deactivateAccount',
    component: DeactivateAccountComponent,
    data: {
      authorities: [],
      pageTitle: 'DeactivateAccount',
    },
    canActivate: [UserRouteAccessService],
  }, {
    path: 'importuser',
    component: ImportUserComponent,
    data: {
      authorities: [],
      pageTitle: 'Import User',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'bulkactioncheckstatus',
    component: BulkActionCheckStatusComponent,
    data: {
      authorities: [],
      pageTitle: 'Bulk Action check status',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'entitlementendpointmapping',
    component: EntitlementEndpointMappingComponent,
    data: {
      authorities: [],
      pageTitle: 'EntitlementEndpointMapping',
    },
    canActivate: [UserRouteAccessService],
  }, {
    path: 'cancelrequest',
    component: CancelRequestComponent,
    data: {
      authorities: [],
      pageTitle: 'CancelRequest',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'manageroles',
    component: RoleManagementComponent,
    data: {
      authorities: [],
      pageTitle: 'Manage Roles',
    },
    canActivate: [UserRouteAccessService],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'roledetails',
    component: RoleDetailsComponent,
    data: {
      authorities: [],
      pageTitle: 'Role details',
    },
    canActivate: [UserRouteAccessService],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'inviteemail',
    component: InviteEmailComponent
    ,
    data: {
      authorities: [],
      pageTitle: 'Invite Email',
    },
    canActivate: [UserRouteAccessService],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'createhostattribute',
    component: CreateHostAttributeComponent
    ,
    data: {
      authorities: [],
      pageTitle: 'Create Host Attribute',
    },
    canActivate: [UserRouteAccessService]
  }, {
    path: 'emailTemplateMapping',
    component: EmailTemplateMappingComponent
    ,
    data: {
      authorities: [],
      pageTitle: 'Email Template Mapping',
    },
    canActivate: [UserRouteAccessService],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'edithostattribute',
    component: EditHostAttributeComponent
    ,
    data: {
      authorities: [],
      pageTitle: 'Edit Host Attribute',
    },
    canActivate: [UserRouteAccessService]
  },
  {
    path: 'deletehostattribute',
    component: DeleteHostAttributeComponent
    ,
    data: {
      authorities: [],
      pageTitle: 'Delete Host Attribute',
    },
    canActivate: [UserRouteAccessService]
  },
  {
    path: 'deleteplan',
    component: DeletePlanComponent
    ,
    data: {
      authorities: [],
      pageTitle: 'Delete Plan',
    },
    canActivate: [UserRouteAccessService]
  }, {
    path: 'createhostattributegroup',
    component: CreateHostAttributeGroupComponent
    ,
    data: {
      authorities: [],
      pageTitle: 'Create Host Attribute Group',
    },
    canActivate: [UserRouteAccessService]
  },
  {
    path: 'reorderplan',
    component: ReorderPlanComponent
    ,
    data: {
      authorities: [],
      pageTitle: 'Re-order Plan',
    },
    canActivate: [UserRouteAccessService]
  }, {
    path: 'edithostattributegroup',
    component: EditHostAttributeGroupComponent
    ,
    data: {
      authorities: [],
      pageTitle: 'Edit Host Attribute Group',
    },
    canActivate: [UserRouteAccessService]
  },
  {
    path: 'deletehostattributegroup',
    component: DeleteHostAttributeGroupComponent
    ,
    data: {
      authorities: [],
      pageTitle: 'Delete Host Attribute Group',
    },
    canActivate: [UserRouteAccessService]
  },
  {
    path: 'mapUnmaphostattributegroup',
    component: HostAttributeGroupMappingComponent
    ,
    data: {
      authorities: [],
      pageTitle: 'Host Attribute Group Mapping',
    },
    canActivate: [UserRouteAccessService]
  },
  {
    path: 'editEmailTemplate',
    component: EditEmailTemplateComponent
    ,
    data: {
      authorities: [],
      pageTitle: 'Edit Email Template',
    },
    canActivate: [UserRouteAccessService]
  },
  {
    path: 'viewhostattributeHierarchy',
    component: ViewHostHierarchyAttributesComponent
    ,
    data: {
      authorities: [],
      pageTitle: 'view hierarchy attribute',
    },
    canActivate: [UserRouteAccessService]
  },
];
