import { NgModule } from '@angular/core';
import { CiamSharedLibsModule } from './shared-libs.module';
import { FindLanguageFromKeyPipe } from './language/find-language-from-key.pipe';
import { AlertComponent } from './alert/alert.component';
import { AlertErrorComponent } from './alert/alert-error.component';
import { HasAnyAuthorityDirective } from './auth/has-any-authority.directive';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CheckboxModule } from 'primeng/checkbox';
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect';
import { AccordionModule } from 'primeng/accordion';
import { SplitButtonModule } from 'primeng/splitbutton';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { TabViewModule } from 'primeng/tabview';
import { CardModule } from 'primeng/card';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ToastModule } from 'primeng/toast';
import { PanelMenuModule } from 'primeng/panelmenu';
import { ChipsModule } from 'primeng/chips';
import { PickListModule } from 'primeng/picklist'
import { ChipModule } from 'primeng/chip';
import { PasswordModule } from 'primeng/password';
import { BadgeModule } from 'primeng/badge';
import { FileUploadModule } from 'primeng/fileupload';
import { HttpClientModule } from '@angular/common/http';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TreeSelectModule } from 'primeng/treeselect';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { RadioButtonModule } from 'primeng/radiobutton';
import { PaginatorModule } from "primeng/paginator";
import { DialogModule } from 'primeng/dialog';
import { SidebarModule } from 'primeng/sidebar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DividerModule } from 'primeng/divider';
import { TagModule } from 'primeng/tag';
import { EditorModule } from 'primeng/editor';
import { OrderListModule } from 'primeng/orderlist';
import { TreeTableModule } from 'primeng/treetable';

@NgModule({
    imports: [
        MessageModule,
        InputTextModule,
        DropdownModule,
        ButtonModule,
        TooltipModule,
        InputTextareaModule,
        CheckboxModule,
        TableModule,
        CalendarModule,
        MultiSelectModule,
        AccordionModule,
        SplitButtonModule,
        AutoCompleteModule,
        BreadcrumbModule,
        ConfirmDialogModule,
        MessagesModule,
        TabViewModule,
        CardModule,
        SelectButtonModule,
        CiamSharedLibsModule,
        TieredMenuModule,
        DynamicDialogModule,
        ToastModule,
        PanelMenuModule,
        ChipsModule,
        PickListModule,
        ChipModule,
        PasswordModule,
        BadgeModule,
        FileUploadModule,
        HttpClientModule,
        InputSwitchModule,
        TreeSelectModule,
        AvatarModule,
        AvatarGroupModule,
        RadioButtonModule,
        PaginatorModule,
        DialogModule,
        SidebarModule,
        OverlayPanelModule,
        DividerModule,
        TagModule,
        EditorModule,
        OrderListModule,
        TreeTableModule
    ],
    declarations: [FindLanguageFromKeyPipe, AlertComponent, AlertErrorComponent, HasAnyAuthorityDirective],
    exports: [
        MessageModule,
        CiamSharedLibsModule,
        FindLanguageFromKeyPipe,
        AlertComponent,
        AlertErrorComponent,
        InputTextModule,
        DropdownModule,
        ButtonModule,
        TooltipModule,
        InputTextareaModule,
        CheckboxModule,
        TableModule,
        CalendarModule,
        MultiSelectModule,
        AccordionModule,
        SplitButtonModule,
        AutoCompleteModule,
        BreadcrumbModule,
        ConfirmDialogModule,
        MessagesModule,
        TabViewModule,
        CardModule,
        SelectButtonModule,
        HasAnyAuthorityDirective,
        TieredMenuModule,
        DynamicDialogModule,
        PanelMenuModule,
        ToastModule,
        ChipsModule,
        PickListModule,
        ChipModule,
        PasswordModule,
        BadgeModule,
        FileUploadModule,
        HttpClientModule,
        InputSwitchModule,
        TreeSelectModule,
        AvatarModule,
        AvatarGroupModule,
        RadioButtonModule,
        PaginatorModule,
        DialogModule,
        SidebarModule,
        OverlayPanelModule,
        DividerModule,
        TagModule,
        EditorModule,
        OrderListModule,
        TreeTableModule
    ]
})
export class CiamSharedModule { }
