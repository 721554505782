<div class="layout-content custom-container">
    <div class="p-d-flex p-jc-end">
        <p-messages key="myKey2" class="custom-alert-box custom-msg" *ngIf="this.messageFlag1">
        </p-messages>
    </div>
    <form [formGroup]="ViewTransactionDetailsForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                <div class=" p-fluid   p-field    " style="margin-top: 10px;">
                    <p>Transaction ID : <span class="attriColor">{{this.transactionId}}</span></p>
                    <span class="attriColor">
                        <h6>Attributes to approve</h6>
                    </span>
                    <div *ngFor="let attr of attributeList;">
                        <span style="color:#505870 ;font-size: 13px; font-weight: 400;">{{attr[0]}} : </span>
                        <span style="font-size: 13px; font-weight: 500;" *ngIf="checkArray(attr[1])">
                            <span *ngIf="attr[1].length==0">-</span>
                            <span *ngIf="attr[1].length>0">
                                <span class="userColor" *ngFor="let val of attr[1];let last=last">
                                    {{val.label}}<span *ngIf="!last">{{', '}}</span>
                                </span>
                            </span>
                        </span>
                        <span style="font-size: 13px; font-weight: 500;" class="userColor"
                            *ngIf="checkArray(attr[1])==false">
                            {{attr[1]}} </span>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right"
                *ngIf="this.action=='Bulk Action-Import User'">
                <button pButton type="button" icon="pi pi-download" iconPos="left" label="User Imported File"
                    class="p-button-secondary p-mr-2" (click)="downloadReport()"></button>
            </div>
        </div>
    </form>
</div>