import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PagesService } from '../pages.service';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'jhi-EditWorkflowConfiguration',
  templateUrl: './EditWorkflowConfiguration.component.html',
  styleUrls: ['./EditWorkflowConfiguration.component.scss'],
  providers: [MessageService, DialogService],
})
export class EditWorkflowConfigurationComponent implements OnInit {
  EditWorkflowConfigurationForm = this.fb.group({
    select: [null, [Validators.required]],
    select1: [null, [Validators.required]],
    select2: [null, [Validators.required]],
    autoRejectDays: [null, [Validators.required]],
  });
  pageloadvalues: any;
  selectList: any[] = [];
  select1List: any[] = [];
  select2List: any[] = [];
  appId: any;
  componentId: any;
  dropdown: boolean;
  app: any;
  com: any;
  enableBtn = false;
  nameFlag = false;
  roleFlag = false;
  approverNum = 0;
  approvalRole: any[] = [];
  flag = 0;
  invalidAt = 1;
  approvalSeq: number;
  wfApprovalStageDto: any[] = [];
  configData: any;
  ciamActionLabel: any;
  ciamActionId: any;
  noOfApproversLabel: any;
  noOfApproversId: number;
  approvalType: any;
  approvalStage: any[] = [];
  enableBtn1 = true;
  autoRejectDaysModel: any;
  oldApprovalType: any;
  emptyFlag = false;
  roleMigrationFlag: string;
  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    private messageService: MessageService,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.EditWorkflowConfigurationForm.patchValue(JSON.parse(data));
    }
  }

  ngOnInit(): void {
    this.roleMigrationFlag = environment.CIAM_ROLE_MIGRATION_FLAG + "";
    this.configData = this.config?.data?.activeData;
    this.ciamActionLabel = this.config?.data?.activeData?.ciamAction;
    this.noOfApproversLabel = this.config?.data?.activeData?.noOfApprovers;
    this.autoRejectDaysModel = this.config?.data?.activeData?.autoRejectDays;
    this.getCiamAction();
    this.getNumberOfApprover();
    this.getApprovalType();
    this.getroleList();
    this.getApprovalStage();
    this.approvalType = this.config?.data?.activeData?.approvalType;
    if (this.noOfApproversLabel == "1") {
      this.approvalType = "Sequential";
      this.EditWorkflowConfigurationForm.get('select2').disable();
    }
    this.oldApprovalType = this.approvalType;
  }

  onClose($event: UntypedFormGroup): void {
    this.ref.close();
  }


  getApprovalTypeSelection() {
    if (this.EditWorkflowConfigurationForm.value.select1 == "1") {
      this.approvalType = "Sequential";
      this.EditWorkflowConfigurationForm.get('select2').disable();
    }
    else {
      this.approvalType = this.oldApprovalType;
      this.EditWorkflowConfigurationForm.get('select2').enable();
    }
  }

  getCiamAction(): void {
    this.pagesService.getAllCiamAction().subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.selectList.push(data);
          if (this.ciamActionLabel == data.label) {
            this.ciamActionId = data.value;
          }
        });
        this.selectList = [...this.selectList];
      }
    });
  }

  getApprovalType() {
    this.select2List.push({ label: "Parallel", value: "Parallel" }, { label: "Sequential", value: "Sequential" });
  }

  getNumberOfApprover(): void {
    this.select1List = [];
    for (let i = 1; i <= 5; i++) {
      this.select1List.push({ label: i, value: i });
      if (this.noOfApproversLabel == i) {
        this.noOfApproversId = i;
      }
    }
    this.addApprover();
  }

  addAppSo(newNum: any) {
    for (let i = this.approverNum; i <= newNum; i++) {
      if (this.EditWorkflowConfigurationForm.controls["Approver" + i]) {
        this.EditWorkflowConfigurationForm.controls["Approver" + i].setValidators(Validators.required);
        this.EditWorkflowConfigurationForm.controls["Approver" + i].updateValueAndValidity();
      }
      else {
        this.EditWorkflowConfigurationForm.addControl("Approver" + i,
          this.fb.control('', Validators.required));
        this.EditWorkflowConfigurationForm.controls["Approver" + i].updateValueAndValidity();
      }
    }
  }

  addApprover() {
    if (this.approverNum > 0) {
      const newNum = this.noOfApproversId;
      if (newNum < this.approverNum) {  //if changed number is less than the previous number
        this.enableBtn = false;
        this["roleFlag" + this.invalidAt] = false;
        for (let j = newNum + 1; j <= this.approverNum; j++) {
          this.EditWorkflowConfigurationForm.controls["Approver" + j].clearValidators();
          this.EditWorkflowConfigurationForm.controls["Approver" + j].reset();
          this.EditWorkflowConfigurationForm.controls["Approver" + j].updateValueAndValidity();
        }
        this.approverNum = this.EditWorkflowConfigurationForm.value.select1;
      }
      else if (newNum > this.approverNum) { //if changed number is greater than the previous number

        this.addAppSo(newNum);

        this.approverNum = this.EditWorkflowConfigurationForm.value.select1;
      }
    }
    else {  //selecting the number first time
      this.approverNum = this.noOfApproversId;
      for (let i = 1; i <= this.approverNum; i++) {
        this.EditWorkflowConfigurationForm.addControl("Approver" + i,
          this.fb.control('', Validators.required));
      }
    }

  }
  createRange(number) {
    // create range for approver
    return new Array(number).fill(0)
      .map((n, index) => index + 1);
  }

  getApprovalStage(): void {
    let reqBody = {};
    reqBody['workflowAppConfigId'] = this.config?.data?.activeData?.wfId;
    reqBody['tenantId'] = this.pagesService.tenantId;
    const formValues = reqBody;
    this.pagesService.getWorkflowApprovalStage(formValues).subscribe(results => {
      const responseData = results?.success?.result;
      let count = 0;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
            seq: null,
          };
          count = count + 1;
          this['stage' + element.approvalSeq] = element.approvalRole;
          data.label = element.approvalRole;
          data.value = element.id;
          data.seq = element.approvalSeq;
          this.approvalStage.push(data);
        });
        this.approvalStage = [...this.approvalStage];
      }
    });
  }

  getroleList(): void {
    if (this.roleMigrationFlag == "true") {
      const reqBody = {};
      reqBody['tenantId'] = this.pagesService.tenantId;
      const formData = reqBody;
      this.pagesService.getAppRolesByTenantID(formData).subscribe(results => {
        const responseData = results?.success?.result;
        if (responseData && responseData.length > 0) {
          responseData.forEach((element: any = {}) => {
            const data = {
              label: null,
              value: null,
            };
            if (element.label == null || element.label == "" || element.label == "empty") {
              this.emptyFlag = true;
            }
            else {
              data.label = element.label;
              data.value = element.label;
              this.approvalRole.push(data);
            }
          });
          this.approvalRole = [...this.approvalRole];
        }
      });
    }
    else {
      this.pagesService.gettableDataRoles().subscribe(results => {
        const responseData = results?.success?.result?.records;
        if (responseData && responseData.length > 0) {
          responseData.forEach((element: any = {}) => {
            const data = {
              label: null,
              value: null,
            };
            if (element.displayName == null || element.displayName == "" || element.displayName == "empty") {
              this.emptyFlag = true;
            }
            else {
              data.label = element.displayName;
              data.value = element.displayName;
              this.approvalRole.push(data);
            }
          });
          this.approvalRole = [...this.approvalRole];
        }
      });
    }
  }

  onCheckAvailabilityCiamAction($event: UntypedFormGroup): void {
    const reqBody = {};
    reqBody['tenantId'] = this.pagesService.tenantId;
    reqBody['ciamAction'] = this.EditWorkflowConfigurationForm.value?.select;
    const formData = reqBody;
    this.pagesService.checkAvailabilityCiamAction(formData).subscribe(
      results => {
        if (results?.error?.code == 400 || results?.success?.code == 400) {
          this.nameFlag = true;
          document.getElementById('selectid')!.style.borderColor = 'red';
          document.getElementById('selectid')!.classList.add('ng-invalid');
          document.getElementById('selectid')!.classList.add('ng-dirty');
        } else if (results.success.code == 200) {
          this.nameFlag = false;
          document.getElementById('selectid')!.style.borderColor = 'grey';
          document.getElementById('selectid')!.classList.remove('ng-invalid');
          document.getElementById('selectid')!.classList.remove('ng-dirty');
        }
      },
    );
  }

  checkApproverRole(index: any) {
    this.enableBtn = false;
    this["roleFlag" + this.invalidAt] = false;
    document.getElementById('select_Approver' + this.invalidAt)!.classList.remove('ng-invalid');
    document.getElementById('select_Approver' + this.invalidAt)!.classList.remove('ng-dirty');
    this.invalidAt = 1;
    for (let i = 1; i <= this.approverNum; i++) {
      this.flag = 0;
      this.invalidAt = 1;
      for (let j = 1; j <= this.approverNum; j++) {
        if (i != j) {
          if ((this.EditWorkflowConfigurationForm.value["Approver" + j] == null || this.EditWorkflowConfigurationForm.value["Approver" + j] == "" || this.EditWorkflowConfigurationForm.value["Approver" + i] == "empty") && (this.EditWorkflowConfigurationForm.value["Approver" + j] == null || this.EditWorkflowConfigurationForm.value["Approver" + i] == "" || this.EditWorkflowConfigurationForm.value["Approver" + i] == "empty")) {
            this.emptyFlag = true;
          }
          else if (this.EditWorkflowConfigurationForm.value["Approver" + i] == this.EditWorkflowConfigurationForm.value["Approver" + j]) {
            this.flag = 1;
            this.invalidAt = j;
            break;
          }

        }
      }
      if (this.flag == 1) {
        this.emptyFlag = true;
        break;
      }
      else {
        this.flag = 0;
      }
    }

    if (this.flag == 1) {
      this.enableBtn = true;
      this.enableBtn1 = true;
      this["roleFlag" + this.invalidAt] = true;
      document.getElementById('select_Approver' + this.invalidAt)!.classList.add('ng-touched');
      document.getElementById('select_Approver' + this.invalidAt)!.classList.add('ng-invalid');
      document.getElementById('select_Approver' + this.invalidAt)!.classList.add('ng-dirty');
    }
    else {
      this.enableBtn = false;
      this.enableBtn1 = false;
      this["roleFlag" + this.invalidAt] = false;
      document.getElementById('select_Approver' + this.invalidAt)!.classList.remove('ng-invalid');
      document.getElementById('select_Approver' + this.invalidAt)!.classList.remove('ng-dirty');
    }
  }

  onSave() {
    let role = [];
    this.wfApprovalStageDto = [];
    for (let i = 1; i <= this.approverNum; i++) {
      role.push(this.EditWorkflowConfigurationForm.value["Approver" + i]);
    }
    for (let j = 1; j <= role.length; j++) {
      for (let k = 0; k < this.approvalStage.length; k++) {
        const data = {
          approvalSeq: null,
          approvalRole: null,
          approvalType: null,
          minApprovals: "1",
          id: null,
          workflowAppConfigId: null,
        };
        if (j == this.approvalStage[k].seq) {
          data.id = this.approvalStage[k].value;
          data.workflowAppConfigId = this.config?.data?.activeData.wfId;
          data.approvalSeq = j;
          data.approvalRole = role[j - 1];
          data.approvalType = this.EditWorkflowConfigurationForm.value.select2;
          this.wfApprovalStageDto.push(data);
        }


      }

    }
    const diff = role.length - this.approvalStage.length;
    if (diff > 0 && this.approvalStage.length > 0) {
      for (let j = this.noOfApproversLabel; j < role.length; j++) {
        const data = {
          approvalSeq: null,
          approvalRole: null,
          approvalType: null,
          minApprovals: "1",
          workflowAppConfigId: null
        };
        data.approvalSeq = parseInt(j) + 1;
        data.approvalRole = role[j];
        data.workflowAppConfigId = this.config?.data?.activeData.wfId;
        data.approvalType = this.EditWorkflowConfigurationForm.value.select2;
        this.wfApprovalStageDto.push(data);
      }
    }
    else if (diff > 0 && this.approvalStage.length == 0) {
      for (let j = 1; j <= role.length; j++) {
        const data = {
          approvalSeq: null,
          approvalRole: null,
          approvalType: null,
          minApprovals: "1",
          workflowAppConfigId: null
        };
        data.approvalSeq = j;
        data.approvalRole = role[j - 1];
        data.workflowAppConfigId = this.config?.data?.activeData.wfId;
        data.approvalType = this.approvalType;
        this.wfApprovalStageDto.push(data);
      }
    }
    this.wfApprovalStageDto = [...this.wfApprovalStageDto];
    const reqBody = {};
    reqBody['id'] = this.config?.data?.activeData.wfId;
    reqBody['modulesId'] = this.EditWorkflowConfigurationForm.value.select;
    reqBody['approvalNo'] = this.EditWorkflowConfigurationForm.value.select1;
    reqBody['approvalType'] = this.approvalType;
    reqBody['tenantId'] = this.pagesService.tenantId;
    reqBody['wfApprovalStageDto'] = this.wfApprovalStageDto;
    reqBody['autoRejectDays'] = this.EditWorkflowConfigurationForm.value.autoRejectDays;
    const formValues = reqBody;
    this.pagesService.createWorkflowConfiguration(formValues).subscribe(
      {
        next: results => {
          this.ref.close("Workflow of " + this.ciamActionLabel + " saved successfully ");
        },
        error: error => {

          this.ref.close("Workflow configuration Edit Failed.");

        }
      });
  }
}