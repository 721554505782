import { Component, OnInit, Input, ViewChild, ElementRef, ContentChildren, EventEmitter, Output, QueryList, TemplateRef, ViewChildren } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PagesService } from '../pages.service';
import { Message, MessageService, PrimeTemplate } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { DomHandler } from 'primeng/dom';

@Component({
  selector: 'jhi-plandetails',
  templateUrl: './EditSubscription.component.html',
  styleUrls: ['./EditSubscription.component.scss'],
  providers: [MessageService, DatePipe]
})
export class EditSubscriptionComponent implements OnInit {
  defaultValue: boolean = false;
  pageloadvalues: any;
  paperColor: any;
  plotColor: any;
  layoutTextColor: any;
  agTheme: any;
  tab1: boolean = false;
  tab2: boolean = false;
  tab3: boolean = false;
  activeIndex1: number = 0;
  activeIndex2: number = 0;
  clicked1 = false;
  clicked2 = false;
  enablePlanButton: boolean = true;
  messageFlag1 = false;
  names = ["Jack", "Jim", "jaggi", "John"];

  ccprimebreadcrumbList = [
    { label: `Manage Subsrciptions `, routerLink: ['/pages/manageSubscription'] },
    { label: `Edit Subscription`, routerLink: ['/pages/editSubscription'] },
  ]
  homeiconccprimebreadcrumb = { icon: 'pi pi-home', routerLink: ['/'] };


  SubscriptionDetailsForm = this.fb.group({
    columns_2_1_ccprimeheading_1: [null],
    subscriptionName: [null, [Validators.required]],
    startDate: [null, [Validators.required]],
    endDate: [null, [Validators.required]],
    account: [null, [Validators.required]],
  }
  );
  SubscriptionMappingForm = this.fb.group({
    tabview: [0],
    autocomplete: [null, []],
    select2: [null, []],
    select1: [null, []],
    pickList1: [null, []],
    pickList2: [null, []],
    sourceFilter: [null, []],
  }
  );
  AddOffersForm = this.fb.group({
    tabview: [0],
    grace_period: [null, []],
    additional_user_count: [null, []],
    gracePeriodStartingDate: [null, []],
    gracePeriodEndingDate: [null, []],
    addiotionalUserStartingDate: [null, []],
    addiotionalUserEndingDate: [null, []],
  }
  );
  clicked: boolean;
  selectList: any[] = [];
  currentIndex: number = -1;
  common: any = [];
  firstSave: boolean = false;
  enableBtn1 = true;
  changedValueFlag = false;
  invalidDates: Array<Date>;
  minimumDate = new Date();
  minEndDate = new Date();
  nameFlag: boolean = false;
  messageFlag: boolean = false;
  wfFlag = false;
  navigateData: any;
  subscriptionId: any;
  subscriptionName: any;
  startingDate: any;
  endingDate: any;
  accountId: any;
  maxStartDate: Date;
  firstSaveEnablebtn = false;
  firstSaveMapping: boolean = false;
  state: string = 'InActive';
  checked: boolean = false;
  limit: number;
  select3List: any;
  totalTargetValue: number;
  autocompleteList: any[] = [];
  select1List: any[] = [];
  select2List: any[] = [];
  pickList1List: any[] = [];
  pickList1List1: any[] = [];
  pickList1targetList: any[] = [];
  pickList1targetList1: any[] = [];
  pickList2List: any[] = [];
  pickList2targetList: any[] = [];
  text: any[] = [];
  results: string[] = [];
  options: any;
  tableList: any[] = [];
  appId: any;
  attId: any;
  groupId: any;
  flag1 = true;
  mapList: any[] = [];
  mapList1: any[] = [];
  mList: any[] = [];
  mList1: any[] = [];
  umList: any[] = [];
  umList1: any[] = [];
  exMapList: any[] = [];
  GroupExMapList: any[] = [];
  btn = true;
  btn1 = true;
  dropdown = true;
  msgs1: Message[];
  oldStartDate = "";
  oldEndDate = "";
  oldAccName = "";
  enableSubSaveBtn = true;
  //new picklist
  isChecked: boolean = false;
  @ViewChildren("picklistCheckboxes") checkboxes: QueryList<ElementRef>;
  @Input() sourceHeader: string;
  @Input() targetHeader: string;
  @Input() responsive: boolean;
  @Input() filterBy: string = "brand";
  @Input() trackBy: Function = (index: number, item: any) => item;
  @Input() sourceTrackBy: Function;
  @Input() targetTrackBy: Function;
  @Input() showSourceFilter: boolean = true;
  @Input() showTargetFilter: boolean = true;
  @Input() metaKeySelection: boolean = true;
  @Input() style: any;
  @Input() styleClass: string;
  @Input() sourceStyle: any;
  @Input() targetStyle: any;
  @Input() showSourceControls: boolean = true;
  @Input() showTargetControls: boolean = true;
  @Input() sourceFilterPlaceholder: string;
  @Input() targetFilterPlaceholder: string;
  @Input() disabled: boolean = false;
  @Output() onMoveToSource: EventEmitter<any> = new EventEmitter();
  @Output() onMoveAllToSource: EventEmitter<any> = new EventEmitter();
  @Output() onMoveAllToTarget: EventEmitter<any> = new EventEmitter();
  @Output() onMoveToTarget: EventEmitter<any> = new EventEmitter();
  @Output() onSourceSelect: EventEmitter<any> = new EventEmitter();
  @Output() onTargetSelect: EventEmitter<any> = new EventEmitter();
  @ViewChild('sourcelist') listViewSourceChild: ElementRef;
  @ViewChild('targetlist') listViewTargetChild: ElementRef;
  @ViewChild('sourceFilter') sourceFilterViewChild: ElementRef;
  @ViewChild('targetFilter') targetFilterViewChild: ElementRef;
  @ContentChildren(PrimeTemplate) templates: QueryList<any>;
  public itemTemplate: TemplateRef<any>;
  public visibleOptionsSource: any[];
  public visibleOptionsTarget: any[];
  selectedItemsSource: any[] = [];
  selectedItemsTarget: any[] = [];
  reorderedListElement: any;
  movedUp: boolean;
  movedDown: boolean;
  itemTouched: boolean;
  filterValueSource: string;
  filterValueTarget: string;
  listHighlightTarget: boolean;
  listHighlightSource: boolean;
  readonly SOURCE_LIST = -1;
  readonly TARGET_LIST = 1;
  checked1: boolean = false;
  status: any;
  firstSaveSubscriptionId: any;
  mapUserFlag: false;
  infoFlag: boolean = false;
  messageFlag2: boolean = false;
  warnFlag: boolean;
  oldSubscriptionName = "";
  select5List: any[] = [];
  applicationList: any[] = [];
  errorFlag = true;
  offerId: any;
  grace_period: any;
  additional_user_count: any;
  gracePeriodStartingDate: any;
  gracePeriodEndingDate: any;
  addiotionalUserStartingDate: any;
  addiotionalUserEndingDate: any;
  gracePeriodMinStartDate: any;
  gracePriodMinEndDate: any;
  gracePeriodMaxStartDate: any;
  gracePriodMaxEndDate: any;
  addiotionalUserMinStartDate: any;
  addiotionalUserMinEndDate: any;
  addiotionalUserMaxStartDate: any;
  addiotionalUserMaxEndDate: any;
  planId: any;
  graceLimitDate: any;
  attributeList: any[] = [];
  attributeGroupList: any[] = [];
  applicationRoleList: any[] = [];
  graceFlag: boolean = false;
  clicked3: boolean = false;
  saveFlag: boolean = false;
  disableMappings: boolean = false;

  constructor(private router: Router, protected activatedRoute: ActivatedRoute, private fb: UntypedFormBuilder, public pagesService: PagesService, private messageService: MessageService, public datepipe: DatePipe) {
    const navigation = this.router.getCurrentNavigation();
    this.navigateData = navigation?.extras?.state?.data;
    this.mapUserFlag = navigation?.extras?.state?.mapUserFlag;
  }

  ngOnInit(): void {
    this.onAccountsdropdown();
    if (this.navigateData.createdBy == "SYSTEM") {
      this.disableMappings = true;
    }
    else {
      this.disableMappings = false;
    }
    this.subscriptionName = this.navigateData.name;
    this.startingDate = new Date(this.navigateData.startDate);
    this.endingDate = new Date(this.navigateData.endDate);
    let temp3 = new Date(this.endingDate);
    temp3.setDate(temp3.getDate() + 30)
    this.graceLimitDate = temp3;
    this.accountId = this.navigateData.accountId;
    let temp1 = new Date(this.startingDate);
    temp1.setDate(temp1.getDate() + 1)
    this.minEndDate = temp1;
    this.maxStartDate = new Date();
    let temp2 = new Date(this.endingDate);
    temp2.setDate(temp2.getDate() - 1);
    this.maxStartDate = temp2;
    this.oldSubscriptionName = this.navigateData.name;
    this.oldAccName = this.navigateData.accountId;
    this.oldStartDate = this.datepipe.transform(this.navigateData.startDate, 'dd-MM-yyyy');
    this.oldEndDate = this.datepipe.transform(this.navigateData.endDate, 'dd-MM-yyyy');
    this.firstSaveSubscriptionId = this.navigateData.id;
    this.firstSaveEnablebtn = true;
    this.status = this.navigateData.status;
    if (this.status == 'Active') {
      this.checked = true;
      this.checked1 = false;
      this.clicked3 = false;
    } else if (this.status == 'Inactive') {
      this.checked = true;
      this.checked1 = false;
      this.clicked3 = true;
    } else if (this.status == 'Completed') {
      this.checked = false;
      this.checked1 = false;
    }
    this.getGroupList();
    this.getMappedDetails();
    if (this.mapUserFlag) {
      this.clicked = true;
      this.activeIndex1 = 1;
      this.userLimit();
    }
    this.AddOffersForm.get('gracePeriodStartingDate').disable();
    this.AddOffersForm.get('gracePeriodEndingDate').disable();
    this.gracePeriodStartingDate = this.endingDate;
    this.gracePriodMinEndDate = this.graceLimitDate;
    this.addiotionalUserStartingDate = this.startingDate;
    this.addiotionalUserEndingDate = this.endingDate;
    this.getOfferDetails();
    this.getPlanDetailsBySubId();
  }

  getPlanDetailsBySubId(): void {
    const reqBody = {};
    reqBody['subscriptionId'] = this.firstSaveSubscriptionId;
    this.pagesService.getPlanDetailsBySubscriptionId(reqBody).subscribe(results => {
      const responseData = results?.success?.result;
      this.attributeList = responseData.attributes;
      this.attributeGroupList = responseData.attribute_groups;
      this.applicationRoleList = responseData.application_roles;
    });
  }

  getOfferDetails(): void {
    const reqBody = {};
    reqBody['subscriptionId'] = this.firstSaveSubscriptionId;
    this.pagesService.getOffersBySubscriptionId(reqBody).subscribe(results => {
      const responseData = results?.success?.result;
      if (responseData != null) {
        console.log("res " + responseData.grace_period);
        this.offerId = responseData.id;
        this.grace_period = responseData.grace_period;
        if (this.grace_period > 0) {
          this.AddOffersForm.get('grace_period').disable();
        }
        this.additional_user_count = responseData.additional_user_count;
        this.gracePeriodStartingDate = new Date(responseData.grace_period_start_date);
        if (responseData.grace_period_end_date != null) {
          this.gracePeriodEndingDate = new Date(responseData.grace_period_end_date);
        }
        this.addiotionalUserStartingDate = new Date(responseData.additional_user_start_date);
        this.addiotionalUserEndingDate = new Date(responseData.additional_user_end_date);
        if (this.grace_period > 0) {
          this.graceFlag = true;
        }
      }
    });
  }

  changeEndDate() {
    if (this.grace_period > 0) {
      this.graceFlag = true;
      this.saveFlag = true;
      let temp4 = new Date(this.gracePeriodStartingDate);
      temp4.setDate(temp4.getDate() + Number(this.grace_period));
      this.gracePeriodEndingDate = temp4;
    }
    else {
      this.AddOffersForm.controls['gracePeriodEndingDate'].reset();
      if (this.saveFlag && this.additional_user_count > 0) {
        this.saveFlag = true;
      }
      else {
        this.saveFlag = false;
      }
    }
  }

  changeAddUser() {
    if (this.additional_user_count > 0) {
      this.saveFlag = true;
    }
    else {
      if (this.saveFlag && this.grace_period > 0) {
        this.saveFlag = true;
      }
      else {
        this.saveFlag = false;
      }
    }
  }

  changeGracePeriod() {
    if (this.gracePeriodEndingDate > this.gracePeriodStartingDate) {
      this.graceFlag = true;
      this.grace_period = Math.floor((this.gracePeriodEndingDate - this.gracePeriodStartingDate) / (1000 * 60 * 60 * 24));
    }
    else {
      this.graceFlag = false;
    }
  }

  onClearButton($event: UntypedFormGroup): void {
    this.SubscriptionDetailsForm.reset();
  }
  onstartDateChange() {
    let temp = new Date(this.SubscriptionDetailsForm.value.startDate);
    temp.setDate(temp.getDate() + 1);
    this.minEndDate = temp;
    if (this.datepipe.transform(this.SubscriptionDetailsForm.value.startDate) < this.endingDate) {
      this.SubscriptionDetailsForm.controls.endDate.reset();
    }
  }
  onendDateChange() {
    let temp = new Date(this.SubscriptionDetailsForm.value.endDate);
    temp.setDate(temp.getDate() - 1);
    this.maxStartDate = temp;

  }
  onColorchange() {
    document.getElementById('subscriptionName')!.style.borderColor = 'grey';
  }
  onfirstSaveSubscription($event: UntypedFormGroup) {
    if (!this.firstSave) {
      this.onEditSubscription($event);
    }
  }

  onAccountsdropdown() {
    this.pagesService.getAccountsdropdown().subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.selectList.push(data);
        });
        this.selectList = [...this.selectList];
      }
    });
  }
  getGroupList(): void {
    const reqBody = {};
    reqBody['subscriptionId'] = this.firstSaveSubscriptionId;
    this.pagesService.getGroupbySubscription(reqBody).subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.select2List.push(data);
        });
        this.select2List = [...this.select2List];
      }
    });

  }
  userLimit(): void {
    if (!this.infoFlag) {
      this.messageFlag1 = true;
      const reqBody = {};
      reqBody['subscriptionId'] = this.firstSaveSubscriptionId;
      this.pagesService.getUserSubscriptionLimit(reqBody).subscribe(results => {
        const responseData = results.success.result;
        this.limit = responseData.value;
        this.messageFlag1 = true;
        this.infoFlag = true;
        this.messageService.add({ key: 'myKey2', severity: 'info', detail: 'According to subscription plan, you can map maximum ' + this.limit + ' users.' });
        this.btn = true;



      });
    }
  }
  ngAfterContentInit() {
    this.templates.forEach((item) => {
      this.itemTemplate = item.template;

    });
  }

  ngAfterViewChecked() {
    if (this.movedUp || this.movedDown) {
      let listItems = DomHandler.find(this.reorderedListElement, 'li.p-state-highlight');
      let listItem;
      if (this.movedUp)
        listItem = listItems[0];
      else
        listItem = listItems[listItems.length - 1];

      DomHandler.scrollInView(this.reorderedListElement, listItem);
      this.movedUp = false;
      this.movedDown = false;
      this.reorderedListElement = null;
    }
  }

  onItemClick(event, item: any, selectedItems: any[], callback: EventEmitter<any>) {
    if (this.disabled) {
      return;
    }

    let index = this.findIndexInSelection(item, selectedItems);
    let selected = (index != -1);
    if (selected) {
      selectedItems.splice(index, 1);
    }
    else {
      selectedItems.push(item);
    }

    callback.emit({ originalEvent: event, items: selectedItems });

    this.itemTouched = false;
  }

  filterMethodPick(event: any, list: any, pickFlag: any) {
    let filtered: any[] = [];
    let items: any;
    let query = event;
    if (query.length > 0) {
      list.forEach(element => {
        items = element;
        if (items.label?.toLowerCase().includes(query.toLowerCase())) {
          filtered.push(items);
        }
      });
      if (pickFlag == 'pick1source') {
        this.pickList1List = filtered;
      }
      else if (pickFlag == 'pick1target') {
        this.pickList1targetList = filtered;
      }

    }
    else if (pickFlag == 'pick1source') {
      this.pickList1List = [];
      this.pickList1List = [...this.pickList1List1];
    }
    else if (pickFlag == 'pick1target') {
      this.pickList1targetList = [];
      this.pickList1targetList = [...this.pickList1targetList1];
    }
  }

  isItemVisible(item: any, listType: number): boolean {
    if (listType == this.SOURCE_LIST) {
      return this.isVisibleInList(this.visibleOptionsSource, item, this.filterValueSource);
    }
    else {
      return this.isVisibleInList(this.visibleOptionsTarget, item, this.filterValueTarget);
    }
  }

  isVisibleInList(data: any[], item: any, filterValue: string): boolean {
    if (filterValue?.trim().length) {
      data.forEach(element => {
        if (item == element) {
          return true;
        }
      });
    }
    else {
      return true;
    }
  }

  limitCheck() {
    this.msgs1 = [];
    this.messageFlag2 = false;
    this.totalTargetValue = this.pickList1targetList?.length + this.selectedItemsSource?.length;
    if (this.totalTargetValue <= this.limit) {
      this.moveRight();
    } else {
      this.messageFlag1 = false;
      this.messageFlag2 = true;
      this.msgs1.push({ severity: 'warn', summary: '', detail: 'You are trying to exceed the maximum ' + this.limit + ' user mapping limit as per subscription plan.' });
      setTimeout(() => {
        this.messageFlag2 = false;
      }, 3500);

    }

  }
  limitCheckAllRight() {
    this.msgs1 = [];
    this.messageFlag2 = false;
    this.totalTargetValue = this.pickList1targetList?.length + this.pickList1List?.length;

    if (this.totalTargetValue <= this.limit) {
      this.moveAllRight();
    } else {
      this.messageFlag1 = false;
      this.messageFlag2 = true;
      this.msgs1.push({ severity: 'warn', summary: '', detail: 'You are trying to exceed the maximum ' + this.limit + ' user mapping limit as per subscription plan.' });
      setTimeout(() => {
        this.messageFlag2 = false;
      }, 3500);
    }

  }
  moveRight() {
    if (this.selectedItemsSource?.length) {
      this.selectedItemsSource.forEach(element => {
        let selectedItem = element;
        if (this.findIndexInList(selectedItem, this.pickList1targetList) == -1) {
          this.pickList1targetList.push(this.pickList1List.splice(this.findIndexInList(selectedItem, this.pickList1List), 1)[0]);
          this.pickList1targetList1.push(this.pickList1List1.splice(this.findIndexInList(selectedItem, this.pickList1List1), 1)[0]);
          this.btn = false;
        }
      });
      this.isChecked = false;
      this.onMoveToTarget.emit({
        items: this.selectedItemsSource
      });
      this.selectedItemsSource = [];
    }
    this.checkboxes.forEach((element) => {
      this.pickList1targetList.filter(z => z).forEach((x) => (x.isChecked = false));
    });
  }

  moveAllRight() {
    if (this.pickList1List) {
      let movedItems = [];
      this.pickList1targetList1 = [];
      for (let i = 0; i < this.pickList1List.length && this.isItemVisible(this.pickList1List[i], this.SOURCE_LIST); +i) {
        let removedItem = this.pickList1List.splice(i, 1)[0];
        this.pickList1List1.splice(this.findIndexInList(removedItem, this.pickList1List1), 1);
        this.pickList1targetList.push(removedItem);
        movedItems.push(removedItem);
        this.btn = false;
      }

      this.onMoveToTarget.emit({
        items: movedItems
      });

      this.onMoveAllToTarget.emit({
        items: movedItems
      });

      this.selectedItemsSource = [];
      this.pickList1targetList1 = [...this.pickList1targetList];
    }
    this.checkboxes.forEach((element) => {
      this.pickList1targetList.filter(z => z).forEach((x) => (x.isChecked = false));
    });
  }

  moveLeft() {
    if (this.selectedItemsTarget?.length) {
      this.selectedItemsTarget.forEach(element => {
        let selectedItem = element;
        if (this.findIndexInList(selectedItem, this.pickList1List) == -1) {
          this.pickList1List.push(this.pickList1targetList.splice(this.findIndexInList(selectedItem, this.pickList1targetList), 1)[0]);
          this.pickList1List1.push(this.pickList1targetList1.splice(this.findIndexInList(selectedItem, this.pickList1targetList1), 1)[0]);
          this.btn = false;
        }
      });
      this.isChecked = false;
      this.onMoveToSource.emit({
        items: this.selectedItemsTarget
      });

      this.selectedItemsTarget = [];
    }
    this.checkboxes.forEach((element) => {
      this.pickList1List.filter(z => z).forEach((x) => (x.isChecked = false));
    });
  }

  moveAllLeft() {
    if (this.pickList1targetList) {
      let movedItems = [];
      this.pickList1List1 = [];
      for (let i = 0; i < this.pickList1targetList.length && this.isItemVisible(this.pickList1targetList[i], this.TARGET_LIST); +i) {
        let removedItem = this.pickList1targetList.splice(i, 1)[0];
        this.pickList1targetList1.splice(this.findIndexInList(removedItem, this.pickList1targetList1), 1);
        this.pickList1List.push(removedItem);
        movedItems.push(removedItem);
        this.btn = false;
      }

      this.onMoveToSource.emit({
        items: movedItems
      });

      this.onMoveAllToSource.emit({
        items: movedItems
      });

      this.selectedItemsTarget = [];
      this.pickList1List1 = [...this.pickList1List];
    }
    this.checkboxes.forEach((element) => {
      this.pickList1List.filter(z => z).forEach((x) => (x.isChecked = false));
    });
  }

  isSelected(item: any, selectedItems: any[]) {
    return this.findIndexInSelection(item, selectedItems) != -1;
  }

  findIndexInSelection(item: any, selectedItems: any[]): number {
    return this.findIndexInList(item, selectedItems);
  }

  findIndexInList(item: any, list: any): number {
    let index: number = -1;

    if (list) {
      for (let i = 0; i < list.length; i++) {
        if (list[i] == item) {
          index = i;
          break;
        }
      }
    }

    return index;
  }

  handleChangetabview(e: any): void {
    this.SubscriptionMappingForm.patchValue({ "tabview": e.index });
  }
  checkOldNewValues() {
    let startDate1 = this.SubscriptionDetailsForm.value.startDate;
    let endDate1 = this.SubscriptionDetailsForm.value.endDate;
    if (this.oldSubscriptionName != this.SubscriptionDetailsForm.value.subscriptionName) {
      this.enableSubSaveBtn = false;
    }
    else if (this.oldStartDate != this.datepipe.transform(startDate1, 'dd-MM-yyyy')) {
      this.enableSubSaveBtn = false;
    }
    else if (this.oldEndDate != this.datepipe.transform(endDate1, 'dd-MM-yyyy')) {
      this.enableSubSaveBtn = false;
    }
    else if (this.oldAccName != this.SubscriptionDetailsForm.value.account) {
      this.enableSubSaveBtn = false;
    }
    else {
      this.enableSubSaveBtn = true;
    }
  }
  onEditSubscription($event: UntypedFormGroup): void {
    const reqBody = {};
    reqBody['id'] = this.navigateData.id;
    if (this.navigateData.name != this.SubscriptionDetailsForm.value.subscriptionName) {
      reqBody['subscriptionName'] = this.SubscriptionDetailsForm.value.subscriptionName;
    }
    reqBody['accountId'] = this.SubscriptionDetailsForm.value.account;
    reqBody['planId'] = this.navigateData.planId;
    if (this.datepipe.transform(this.navigateData.startDate, 'dd-MM-yyyy') != this.SubscriptionDetailsForm.value.startDate) {
      let startDate1 = this.SubscriptionDetailsForm.value.startDate;
      reqBody['startDate'] = this.datepipe.transform(startDate1, 'dd-MM-yyyy');
    }
    if (this.datepipe.transform(this.navigateData.endDate, 'dd-MM-yyyy') != this.SubscriptionDetailsForm.value.endDate) {
      let endDate1 = this.SubscriptionDetailsForm.value.endDate;
      reqBody['endDate'] = this.datepipe.transform(endDate1, 'dd-MM-yyyy');
    }
    const formValues = reqBody;
    this.messageFlag = true;
    this.pagesService.editSubscription(formValues).subscribe(
      {
        next: results => {
          if (results?.error?.messageCode == "CIAM 113") {
            this.nameFlag = true;
            document.getElementById('subscriptionName')!.style.borderColor = 'red';
          }
          else if (results.success.code == 200) {
            if (results.success.messageCode == "CIAM 154") {
              this.wfFlag = true;
              this.navigateToMyReq('Edit Susbcription request has been sent for approval');
            }
            else {
              this.enableBtn1 = true;
              this.nameFlag = false;
              this.messageFlag = true;
              this.tab1 = true;
              this.oldSubscriptionName = this.SubscriptionDetailsForm.value.subscriptionName;
              this.oldAccName = this.SubscriptionDetailsForm.value.account;
              this.oldStartDate = this.datepipe.transform(this.SubscriptionDetailsForm.value.startDate, 'dd-MM-yyyy');
              this.oldEndDate = this.datepipe.transform(this.SubscriptionDetailsForm.value.endDate, 'dd-MM-yyyy');
              // this.getOfferDetails();
              let temp4 = new Date(this.oldEndDate);
              temp4.setDate(temp4.getDate() + 30)
              this.gracePriodMinEndDate = temp4;
              this.addiotionalUserStartingDate = this.SubscriptionDetailsForm.value.startDate;
              this.addiotionalUserEndingDate = this.SubscriptionDetailsForm.value.endDate;
              this.gracePeriodStartingDate = this.SubscriptionDetailsForm.value.endDate;
              this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'Subscription details saved successfully.' });
              setTimeout(() => {
                this.messageFlag = false;
              }, 3000);

            }

          }
        },
        error: error => {
          this.messageFlag = true;
          this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Failed to save susbscription.' });
          setTimeout(() => {
            this.messageFlag = false;
          }, 3000);
        }
      });
  }
  getMappedDetails() {
    this.flag1 = true;
    this.SubscriptionMappingForm.controls.sourceFilter.reset();
    this.pickList1targetList = [];
    this.pickList1List = [];
    this.pickList1List1 = [];
    this.pickList1targetList1 = [];

    const reqBody = {};
    reqBody['subscriptionId'] = this.firstSaveSubscriptionId;
    this.groupId = this.SubscriptionMappingForm.value.select2;
    this.pagesService.getMappedSubscriptionUser(reqBody).subscribe(results => {
      let responseData = [];
      if (results.hasOwnProperty("success")) {
        responseData = results.success.result;
      }
      else {
        responseData = [];
      }
      this.pickList1targetList = [];
      this.exMapList = [];
      if (responseData && responseData.length > 0) {
        this.flag1 = false;
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
            checked: true
          };
          data.label = element.label;
          data.value = element.value;
          data.checked = this.checked;
          this.pickList1targetList.push(data);
          this.exMapList.push(data.value);
        });
        this.pickList1targetList = [...this.pickList1targetList];
        this.pickList1targetList1 = [...this.pickList1targetList];
        this.exMapList = [...this.exMapList];
      }

    });
    const reqBody1 = {};
    reqBody1['subscriptionId'] = this.firstSaveSubscriptionId;
    reqBody1['groupIds'] = this.groupId;
    this.pagesService.getUnMappedSubscriptionUser(reqBody1).subscribe(results => {
      const responseData = results?.success?.result;
      this.pickList1List = [];
      if (responseData && responseData.length > 0) {
        this.flag1 = false;
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
            checked: false
          };
          data.label = element.label;
          data.value = element.value;
          data.checked = this.checked1;
          this.pickList1List.push(data);
        });
        this.pickList1List = [...this.pickList1List];
        this.pickList1List1 = [...this.pickList1List];
      }
    });
  }

  getExMapUnMapList() {
    this.exMapList = [];
    this.pickList1targetList = [];
    this.pickList1targetList1 = [];
    const reqBody = {};
    reqBody['subscriptionId'] = this.firstSaveSubscriptionId;
    this.groupId = this.SubscriptionMappingForm.value.select2;

    this.pagesService.getMappedSubscriptionUser(reqBody).subscribe(results => {
      const responseData = results?.success?.result;
      if (responseData && responseData.length > 0) {
        this.exMapList = [];
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
            checked: true
          };
          data.label = element.label;
          data.value = element.value;
          data.checked = this.checked;
          this.pickList1targetList.push(data);
          this.exMapList.push(data.value);
        });
        this.exMapList = [...this.exMapList];
        this.pickList1targetList = [...this.pickList1targetList];
        this.pickList1targetList1 = [...this.pickList1targetList];
      }
    });
  }

  onSave() {
    this.messageFlag = true;
    this.messageFlag1 = false;
    this.appId = this.SubscriptionMappingForm.value.select;
    this.groupId = this.SubscriptionMappingForm.value.select2;
    this.mapList = this.pickList1targetList1;
    this.mList = [];
    this.mapList.forEach((element: any = {}) => {
      const data = {
        label: null,
        value: null,
      };
      data.label = element.label;
      data.value = element.value;
      this.mList.push(data.value);
    });
    this.mList = [...this.mList];
    const assignList = this.mList.filter(x => !this.exMapList.includes(x));
    const deassignList = this.exMapList.filter(x => !this.mList.includes(x));
    if (assignList.length == 0 && deassignList.length == 0) {
      this.messageFlag = true;
      this.messageFlag1 = false;
      this.messageService.add({ key: 'myKey2', severity: 'info', detail: 'No User To Map.' });
      this.btn = true;
      setTimeout(() => {
        this.messageFlag = false;

      }, 3000);

    }
    else {
      const reqBody = {};
      reqBody['subscriptionId'] = this.firstSaveSubscriptionId;
      reqBody['mapList'] = assignList;
      reqBody['unmapList'] = deassignList;
      const formValues = reqBody;
      this.pagesService.onSaveMapUnmapSubscription(formValues).subscribe(
        {
          next: results => {
            this.messageFlag1 = false;
            this.messageFlag = true;
            this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'Users mapping/unmapping to the subscription successfully.' });
            this.btn = true;
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
            this.getExMapUnMapList();
            this.firstSaveMapping = true;
            this.tab2 = true;
            if (this.status == 'Active') {
              this.activeIndex1 = 2;
            }
          },
          error: error => {
            this.messageFlag = true;
            this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Subscription User mapping/unmapping failed.' });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
            // }
          }
        });
    }
  }
  navigateToMyReq(msg: any): void {
    const navigationExtras: NavigationExtras = {
      state: {
        msg: msg,
      },
    };
    this.router.navigate(['/pages/myrequestslisting'], navigationExtras);
  }

  saveOffers(): void {
    const reqBody = {};
    reqBody["id"] = this.offerId;
    reqBody['subscription_id'] = this.firstSaveSubscriptionId;
    reqBody['grace_period'] = this.AddOffersForm.value.grace_period;
    reqBody['additional_user_count'] = this.AddOffersForm.value.additional_user_count;
    reqBody['grace_period_start_date'] = this.datepipe.transform(this.gracePeriodStartingDate, 'dd-MM-yyyy');
    if (this.AddOffersForm.value.grace_period > 0) {
      reqBody['grace_period_end_date'] = this.datepipe.transform(this.gracePeriodEndingDate, 'dd-MM-yyyy');
    }
    reqBody['additional_user_start_date'] = this.datepipe.transform(this.AddOffersForm.value.addiotionalUserStartingDate, 'dd-MM-yyyy');
    reqBody['additional_user_end_date'] = this.datepipe.transform(this.AddOffersForm.value.addiotionalUserEndingDate, 'dd-MM-yyyy');
    const formValues = reqBody;
    this.messageFlag = true;
    this.pagesService.updateOffersForSubscription(formValues).subscribe(
      {
        next: results => {
          if (results.success.code == 200) {
            if (results.success.messageCode == 'CIAM 154') {
              this.wfFlag = true;
              this.navigateToMyReq('Add New Susbcription request has been sent for approval');
            }
            else {
              this.offerId = results.success.result.id;
              this.firstSaveEnablebtn = true;

              this.nameFlag = false;
              this.messageFlag = true;
              this.tab3 = true;
              this.clicked2 = false;
              this.infoFlag = false;
              this.saveFlag = false;
              if (this.AddOffersForm.value.grace_period > 0) {
                this.AddOffersForm.get('grace_period').disable();
              }
              this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'Offers added in the subscription successfully.' });
              setTimeout(() => {
                this.messageFlag = false;
              }, 3000);


            }
          }
        },
        error: error => {
          if (error.error?.error?.code == 409) {
            this.nameFlag = true;
            document.getElementById('subscriptionName')!.style.borderColor = 'red';
          }
          else {
            this.messageFlag = true;
            this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Failed to add Offers in the subscription.' });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
          }
        }
      });
  }
}


