import { ViewWorkflowConfigurationDetailsComponent } from './../ViewWorkflowConfigurationDetails/ViewWorkflowConfigurationDetails.component';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { PagesService } from '../pages.service';
import { MenuItem, MessageService } from 'primeng/api';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { CreateWorkflowConfigurationComponent } from '../CreateWorkflowConfiguration/CreateWorkflowConfiguration.component';
import { EditWorkflowConfigurationComponent } from '../EditWorkflowConfiguration/EditWorkflowConfiguration.component';
@Component({
  selector: 'jhi-WorkflowConfigurationListing',
  templateUrl: './WorkflowConfigurationListing.component.html',
  styleUrls: ['./WorkflowConfigurationListing.component.scss'],
  providers: [MessageService, DialogService],
})
export class WorkflowConfigurationListingComponent implements OnInit {
  ref!: DynamicDialogRef;
  WorkflowConfigurationListingForm = this.fb.group({
    textField: [null, []],
    inputswitch: [null, []],
  });

  first = 0;
  rows = 10;
  messageFlag = true;
  isVisible = false;
  activeItem: any;
  GroupCount: any;
  UserCount: any;
  pageloadvalues: any;
  ccprimebreadcrumbList = [{ label: `Tenant  Management` }, { label: `Workflow Configuration`, routerLink: ['/pages/WorkflowConfigurationListing'] }];
  homeiconccprimebreadcrumb = { icon: 'pi pi-home', routerLink: ['/'] };
  htmlSrc: any;
  searchList: any[] = [];
  tableList: any[] = [];
  tableList1: any[] = [];
  errorFlag: boolean = true;
  tablecols: any[] = [
    { field: 'name', header: 'Name' },
    { field: 'description', header: 'Description' },
    { field: 'users', header: 'Users' },
    { field: 'groups', header: 'Groups' },
    { field: 'policies', header: 'Policies' },
    { field: 'status', header: 'Status' },
  ];
  table: any[] = [];
  Hoveritems!: MenuItem[];
  selectedIndex: any;
  hoverIndex: any;
  deletemsg: any;
  checked1: boolean = false;
  workflowNotEditText = "Workflow Configuration cannot be edited due to pending transaction";
  action: string;
  ciamaction: any;

  constructor(
    public dialogService: DialogService,
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    private messageService: MessageService
  ) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    this.deletemsg = navigation?.extras?.state?.msg;
    if (this.deletemsg) {
      this.messageFlag = true;
    }
    if (data) {
      this.WorkflowConfigurationListingForm.patchValue(JSON.parse(data));
    }
  }

  ngOnInit(): void {
    this.htmlSrc = '';
    this.Hoveritems = [
      {
        label: 'Edit',
        command: e => {
        },
        disabled: true,
      },
      {
        label: 'Delete',
        command: () => {
        },
        disabled: true,
      },
    ];
    this.getWorkflowconfiguration();
  }

  getWorkflowconfiguration(): void {
    const reqBody = {}
    reqBody['tenantId'] = this.pagesService.tenantId;
    const formValues = reqBody;
    this.pagesService.getWorkflowConfiguration(formValues).subscribe(
      results => {
        const responseData = results.success.result;
        if (responseData && responseData.length > 0) {
          this.tableList = responseData;
          this.tableList1 = responseData;
          for (let i = 0; i < this.tableList.length; i++) {
            this.WorkflowConfigurationListingForm.addControl("inputswitch" + i, this.fb.control(null, []));
            if (this.tableList[i].isDeleted) {
              this["checked1" + i] = false;
              if (this.pagesService.tenantManagementReadOnlyFlag) {
                this.WorkflowConfigurationListingForm.get("inputswitch" + i).disable();
              }
            } else if (!this.tableList[i].isDeleted) {
              this["checked1" + i] = true;
              if (this.tableList[i].numOfPendingTrans > 0 || this.pagesService.tenantManagementReadOnlyFlag) {
                this.WorkflowConfigurationListingForm.get("inputswitch" + i).disable();
              }
            }
          }
          this.errorFlag = false;
        } else {
          this.errorFlag = true;
        }
      }
    );
  }

  EnableDisableWorkflow(index: any): void {
    const reqBody = {}
    reqBody['tenantId'] = this.pagesService.tenantId;
    reqBody['workflowId'] = this.activeItem.wfId;
    this.ciamaction = this.activeItem.ciamAction;
    if (this["checked1" + index]) {
      this.action = 'enable';
    } else {
      this.action = 'disable';
    }
    reqBody['action'] = this.action;
    const formValues = reqBody;

    this.messageFlag = true;
    this.pagesService.EnableDisableWorkflowConfiguration(formValues).subscribe(
      {
        next: results => {
          this.messageFlag = true;
          if (this.action == 'enable') {
            this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'Workflow of ' + this.ciamaction + ' enabled sucessfully.' });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
          } else if (this.action == 'disable') {
            this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'Workflow of ' + this.ciamaction + ' disabled sucessfully.' });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
          }
          this.getWorkflowconfiguration();
        },
      }
    );
  }

  WorkFlowFilterMethod(event: any) {
    let filtered: any[] = [];
    let items: any;
    let cc;
    let query = event;
    if (query.length > 0) {
      this.tableList1.forEach(element => {
        items = element;
        if (items.ciamAction?.toLowerCase().includes(query.toLowerCase()) || items.approvalType?.toLowerCase().includes(query.toLowerCase()) || items.noOfApprovers.includes(query) || items.numOfPendingTrans.includes(query)) {
          filtered.push(items);
        }
      });
      this.tableList = filtered;
    }
    else {
      this.tableList = this.tableList1;
    }
  }

  highlight(index: any) {
    this.selectedIndex = index;
  }

  mouseLeave() {
    this.hoverIndex = null;
    this.selectedIndex = null;
  }

  showWorkflow() {
    this.ref = this.dialogService.open(CreateWorkflowConfigurationComponent, {
      data: {},
      header: 'Add New Workflow',
      width: '93%',
      contentStyle: { 'border-radius': '4px', overflow: 'visible' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Workflow configuration added successfully.') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.getWorkflowconfiguration();
      }
      else if (msg == 'Workflow configuration Creation Failed.' || msg == 'Workflow configuration name already exist.') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
    });
  }

  editWorkflowConfiguration() {
    this.ref = this.dialogService.open(EditWorkflowConfigurationComponent, {
      data: { activeData: this.activeItem },
      header: 'Edit Workflow',
      width: '93%',
      contentStyle: { 'border-radius': '4px', overflow: 'visible' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Workflow configuration Edit Failed.') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
      else if (msg) {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.getWorkflowconfiguration();
      }
    });
  }

  viewWorkflow() {
    this.ref = this.dialogService.open(ViewWorkflowConfigurationDetailsComponent, {
      header: 'Workflow Configuration Details',
      data: { activeData: this.activeItem },
      width: '45%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.ref.onClose.subscribe(msg => {
    });
  }
}
