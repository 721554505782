<div>
    <form class="layout-content custom-form-layout" style="padding-left: 0px !important;padding-right: 0px !important;"
        [formGroup]="AddNextLevelForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class="p-col-8 p-sm-8 p-md-8 p-lg-8 p-xl-8 p-offset-undefined left p-mt-3 p-mb-3">
                <div class="ui-fluid p-formgrid p-grid">
                    <!-- <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                        <div class=" p-fluid   p-field    ">
                            <label [ngClass]="{'field-required': AddNextLevelForm.get('select')?.errors?.required}">
                                Type
                                <span class='invalid-feedback-astrik'>*</span></label>
                            <div class="p-fluid">
                                <p-dropdown [filter]="true" filterBy="label" id="selectid" name="select"
                                    placeholder="Select" [options]="selectList" [autoDisplayFirst]="false"
                                    formControlName="select">
                                    <ng-template let-items pTemplate="item">
                                        <div *ngIf="items?.label?.length &lt;= 35;">
                                            {{items.label}}
                                        </div>
                                        <div *ngIf="items?.label?.length > 35;" pTooltip="{{items.label}}"
                                            tooltipPosition="bottom">
                                            {{items.label.substring(0,35) + "..." }}
                                        </div>
                                    </ng-template>
                                </p-dropdown>
                            </div>
                            <div class="p-error"
                                *ngIf="AddNextLevelForm.get('select')!.invalid && (AddNextLevelForm.get('select')!.dirty || AddNextLevelForm.get('select')!.touched)">
                                <div *ngIf="AddNextLevelForm.get('select')?.errors?.required">
                                    <label class="alert-icon alert-circle-icon"></label>
                                    <medium class="p-error">Type is required.</medium>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                        <div class=" p-fluid   p-field    ">
                            <label
                                [ngClass]="{'field-required': AddNextLevelForm.get('AttributeId')?.errors?.required}">
                                Attribute Id
                                <span class='invalid-feedback-astrik'>*</span></label>
                            <input type="text" id="AttributeId_id1" name="AttributeId_name"
                                formControlName="AttributeId" pInputText placeholder="Enter name"
                                class="p-inputtext inputtextHeight  " maxlength="255"
                                (keyup)="this.idFlag=false;this.validationFlag=false;colorAttributeChange();" />
                            <div class="p-error"
                                *ngIf="AddNextLevelForm.get('AttributeId')!.invalid && (AddNextLevelForm.get('AttributeId')!.dirty || AddNextLevelForm.get('AttributeId')!.touched)">
                                <div *ngIf="AddNextLevelForm.get('AttributeId')?.errors?.required">
                                    <label class="alert-icon alert-circle-icon"></label>
                                    <medium class="p-error">AttributeId is required.</medium>
                                </div>
                            </div>
                            <div class="invalid-feedback">
                                <label *ngIf="this.idFlag">
                                    <div>
                                        <label class="alert-icon alert-circle-icon"></label>
                                        <medium class="p-error">Attribute Id already exists.
                                        </medium>
                                    </div>
                                </label>
                            </div>
                            <div class="invalid-feedback">
                                <label *ngIf="this.validationFlag">
                                    <div>
                                        <label class="alert-icon alert-circle-icon"></label>
                                        <medium class="p-error">{{errorMessage.substring(1,errorMessage.length-1)}}
                                        </medium>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                        <div class=" p-fluid   p-field    ">
                            <label [ngClass]="{'field-required': AddNextLevelForm.get('textField')?.errors?.required}">
                                Name
                                <span class='invalid-feedback-astrik'>*</span></label>
                            <input type="text" id="textField_id1" name="textField_name" formControlName="textField"
                                pInputText placeholder="Enter name" class="p-inputtext inputtextHeight  "
                                maxlength="255" (keyup)="this.nameFlag=false;this.validationFlag=false;colorChange();"
                                pattern="^[a-zA-Z]+([ ]*[)a-zA-Z0-9-_(])*$" />
                            <div class="p-error"
                                *ngIf="AddNextLevelForm.get('textField')!.invalid && (AddNextLevelForm.get('textField')!.dirty || AddNextLevelForm.get('textField')!.touched)">
                                <div *ngIf="AddNextLevelForm.get('textField')?.errors?.required">
                                    <label class="alert-icon alert-circle-icon"></label>
                                    <medium class="p-error">Name is required.</medium>
                                </div>
                                <div *ngIf="AddNextLevelForm.get('textField').errors.pattern">
                                    <label class="alert-icon alert-circle-icon"></label>
                                    <span class="p-error">Name should not start with special characters and Only Dash,
                                        Underscore,space, open and close bracket are allowed as special
                                        characters.</span>
                                </div>
                            </div>
                            <div class="invalid-feedback">
                                <label *ngIf="this.nameFlag">
                                    <div>
                                        <label class="alert-icon alert-circle-icon"></label>
                                        <medium class="p-error">Attribute Name already exists.
                                        </medium>
                                    </div>
                                </label>
                            </div>
                            <div class="invalid-feedback">
                                <label *ngIf="this.validationFlag">
                                    <div>
                                        <label class="alert-icon alert-circle-icon"></label>
                                        <medium class="p-error">{{errorMessage.substring(1,errorMessage.length-1)}}
                                        </medium>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                        <div class=" p-fluid   p-field    ">
                            <label>
                                Group
                            </label>
                            <div class="p-fluid  p-field">
                                <p-multiSelect [filter]="true" autoZIndex="true" baseZIndex="1" filterBy="label"
                                    id="select1id" name="select1" placeholder="Select" filterPlaceHolder="Select Groups"
                                    [options]="select1List" formControlName="select1">
                                    <ng-template let-each pTemplate="selectedItem">
                                        {{each.label}}
                                    </ng-template>
                                    <ng-template let-items pTemplate="item">
                                        <div *ngIf="items?.label?.length <= 20;">
                                            {{items.label}}
                                        </div>
                                        <div *ngIf="items?.label?.length > 20;" pTooltip="{{items.label}}"
                                            tooltipPosition="bottom">
                                            {{items.label.substring(0,20) + "..." }}
                                        </div>
                                    </ng-template>
                                </p-multiSelect>
                            </div>
                        </div>
                    </div>

                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">

                        <div class="p-fluid">
                            <label>
                                Access Type
                            </label>
                            <label tooltipPosition="right"
                                pTooltip="Public: Open access to application attributes (Assigned)<br> Private: Exclusive/Self access"
                                class="alert-icon alert-info-icon" [escape]="false"></label>
                            <div class="p-field p-fluid">
                                <p-selectButton id="selectSwitch_id" name="selectSwitch_name"
                                    [(ngModel)]="defaultselect_selectSwitch" [options]="selectSwitchList"
                                    formControlName="selectSwitch" styleClass="default">
                                    <ng-template let-item>
                                        <span class="p-button-label">{{item.label}}</span>
                                    </ng-template>
                                </p-selectButton>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined p-left">
                        <div class="p-field-checkbox" style="margin-top: 32px;">
                            <p-checkbox [(ngModel)]="checked" binary="true" inputId="binary"
                                formControlName="checkbox"></p-checkbox>
                            <label for="binary" style="font-size: 0.9rem;">Default Attribute</label>
                        </div>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                        <div class=" p-fluid   p-field    ">
                            <label>
                                Description
                            </label>
                            <textarea pInputTextarea id="textArea_id" name="textArea_name" formControlName="description"
                                rows="3" placeholder="Add description" maxlength="200">
                                            </textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 p-offset-undefined left p-mt-4 p-mb-4">
                <p-card style="overflow: auto;">
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left p-mt-3">
                        <div style="display: inline-flex;">
                            <label class="edit-icon" style="width: 28px;height: 28px;"></label>
                            <p style="margin-top: 4px;font-weight: 600;margin-left: 4px;">Hierarchy</p>
                        </div>
                    </div>
                    <p-tree [value]="attributeList" scrollHeight="250px" (onNodeExpand)="nodeExpand($event)"
                        [loading]="loading"></p-tree>
                </p-card>
            </div>
            <div
                class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right custom-btn-height p-mt-1 p-mb-2">
                <div class="btnwrap p-mr-3">
                    <button pButton type="button" class="p-button-brand2 p-button" (click)="onClose(AddNextLevelForm);"
                        label="Close">
                    </button>
                </div>
                <div class="btnwrap">
                    <button pButton class="p-button-primary p-button" type="button"
                        [disabled]="validationFlag  || nameFlag || !AddNextLevelForm.valid"
                        (click)="CreatehierarchyHostAttributeResource();" label="Save">
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>