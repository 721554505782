<div style="background-color: #F6F8FA;" (click)="mouseLeave()">
    <div class="p-d-flex p-jc-end">
        <p-messages key="myKey1" class="custom-alert-box" *ngIf="this.messageFlag"></p-messages>
    </div>
    <div class="p-col-fixed p-mt-1 p-ml-1 p-pr-0 custom-breadcrumb">
        <p-breadcrumb [model]="ccprimebreadcrumbList" [home]="homeiconccprimebreadcrumb">
        </p-breadcrumb>
    </div>
    <form class="layout-content custom-form-layout" [formGroup]="TenantSettingsListingForm" autocomplete="off"
        novalidate>
        <div class="p-formgrid p-grid">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left p-mt-3">
                <div>
                    <h5 class="custom-head1">Tenant Settings</h5>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left custom-tab-nav">
                <p-tabView [(activeIndex)]="TenantSettingsListingForm.value.tabview"
                    (onChange)="handleChangetabview($event)">
                    <p-tabPanel header="Manage Custom field">
                        <div class="card" style="padding-top: 2px;">
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-p-0 p-offset-undefined left">
                                <div class=" p-fluid   p-field    ">
                                    <div class="p-fluid p-formgrid p-grid">
                                        <div class="p-col-12 p-p-0">
                                            <div *ngIf="this.errorFlag" class="custom-errorFlagDiv">
                                                <label class="d-icons appempty-icon"></label><br>
                                                <label style="margin-left: 12px;">No Data Available</label><br>
                                                <div class="btnwrap">
                                                    <button pButton type="button" pButton label="Add Custom Field"
                                                        (click)="showAddCustomField()"
                                                        class="p-button-outlined p-button-danger p-button-sm"
                                                        style="width:150px;margin-left: 10px;"
                                                        [disabled]="this.pagesService.tenantManagementReadOnlyFlag"></button>
                                                </div>
                                            </div>
                                            <div class="ui-fluid p-formgrid" *ngIf="!this.errorFlag">
                                                <div class="p-grid p-mt-0">
                                                    <div class="p-col p-p-0">
                                                        <span class=" p-input-icon-left">
                                                            <input type="text" (keydown.enter)="$event.preventDefault()"
                                                                id="applicationSearch_id" name="applicationSearch_name"
                                                                formControlName="applicationSearch" pInputText
                                                                placeholder="Search"
                                                                class="p-inputtext inputtextHeight  "
                                                                (input)="filterMethod($event.target.value)"
                                                                style="width: 304px;" />
                                                            <i aria-hidden="true" class="pi pi-search"></i>
                                                        </span>
                                                    </div>
                                                    <div class="p-col-fixed p-p-0 p-right p-mb-3">
                                                        <div class="btnwrap">
                                                            <button pButton class="p-button-primary p-button"
                                                                type="button" (click)="showAddCustomField()"
                                                                label="Add Custom Field"
                                                                [disabled]="this.pagesService.tenantManagementReadOnlyFlag">
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left p-p-0 p-mb-6">
                                                    <div class="  p-field    ">
                                                        <div class="mobilefriendlytable" responsiveLayout="stack"
                                                            [ngClass]="{'content-section implementation': true}">

                                                            <p-table #tableid [value]="tableList" name="table"
                                                                [columns]="tablecols"
                                                                styleClass="p-datatable-customers custom-p-datatable-customers"
                                                                [rows]="10" [rowsPerPageOptions]="[10,25,50]"
                                                                selectionMode="Multiple" [paginator]="true"
                                                                sortMode="single"
                                                                [globalFilterFields]="['entityName','fieldName','fieldInputType','inputValueFormat','mandatoryField','editableField']">
                                                                <ng-template pTemplate="header">
                                                                    <tr>
                                                                        <th style="max-width: 10%;"
                                                                            pSortableColumn="entityName" scope="col">
                                                                            <div
                                                                                class="p-d-flex p-jc-between p-ai-center">
                                                                                Entity
                                                                                <i class="p-sortable-column-icon icon-sort"
                                                                                    ariaLabel="Activate to sort"
                                                                                    ariaLabelDesc="Activate to sort in descending order"
                                                                                    ariaLabelAsc="Activate to sort in ascending order"
                                                                                    field="entityName"></i>
                                                                                <p-columnFilter type="text"
                                                                                    field="entityName" display="menu"
                                                                                    class="p-ml-auto"></p-columnFilter>
                                                                            </div>
                                                                        </th>
                                                                        <th style="max-width: 13%;"
                                                                            pSortableColumn="fieldName" scope="col">
                                                                            <div
                                                                                class="p-d-flex p-jc-between p-ai-center">
                                                                                Field Name
                                                                                <i class="p-sortable-column-icon icon-sort"
                                                                                    ariaLabel="Activate to sort"
                                                                                    ariaLabelDesc="Activate to sort in descending order"
                                                                                    ariaLabelAsc="Activate to sort in ascending order"
                                                                                    field="fieldName"></i>
                                                                                <p-columnFilter type="text"
                                                                                    field="fieldName" display="menu"
                                                                                    class="p-ml-auto"></p-columnFilter>
                                                                            </div>
                                                                        </th>
                                                                        <th style="max-width: 15%;"
                                                                            pSortableColumn="fieldInputType"
                                                                            scope="col">
                                                                            <div
                                                                                class="p-d-flex p-jc-between p-ai-center">
                                                                                Field Input Type
                                                                                <i class="p-sortable-column-icon icon-sort"
                                                                                    ariaLabel="Activate to sort"
                                                                                    ariaLabelDesc="Activate to sort in descending order"
                                                                                    ariaLabelAsc="Activate to sort in ascending order"
                                                                                    field="fieldInputType"></i>
                                                                                <p-columnFilter type="text"
                                                                                    field="fieldInputType"
                                                                                    display="menu"
                                                                                    class="p-ml-auto"></p-columnFilter>
                                                                            </div>
                                                                        </th>
                                                                        <th style="max-width: 30%;"
                                                                            pSortableColumn="inputValueFormat"
                                                                            scope="col">
                                                                            <div
                                                                                class="p-d-flex p-jc-between p-ai-center">
                                                                                Input Values/Format
                                                                                <i class="p-sortable-column-icon icon-sort"
                                                                                    ariaLabel="Activate to sort"
                                                                                    ariaLabelDesc="Activate to sort in descending order"
                                                                                    ariaLabelAsc="Activate to sort in ascending order"
                                                                                    field="inputValueFormat"></i>
                                                                                <p-columnFilter type="text"
                                                                                    field="inputValueFormat"
                                                                                    display="menu"
                                                                                    class="p-ml-auto"></p-columnFilter>
                                                                            </div>
                                                                        </th>
                                                                        <th style="max-width: 12%;"
                                                                            pSortableColumn="mandatoryField"
                                                                            scope="col">
                                                                            <div
                                                                                class="p-d-flex p-jc-between p-ai-center">
                                                                                Mandatory Field
                                                                                <i class="p-sortable-column-icon icon-sort"
                                                                                    ariaLabel="Activate to sort"
                                                                                    ariaLabelDesc="Activate to sort in descending order"
                                                                                    ariaLabelAsc="Activate to sort in ascending order"
                                                                                    field="mandatoryField"></i>
                                                                                <p-columnFilter type="text"
                                                                                    field="mandatoryField"
                                                                                    display="menu"
                                                                                    class="p-ml-auto"></p-columnFilter>
                                                                            </div>
                                                                        </th>
                                                                        <th style="max-width: 12%;"
                                                                            pSortableColumn="editableField" scope="col">
                                                                            <div
                                                                                class="p-d-flex p-jc-between p-ai-center">
                                                                                Editable Field
                                                                                <i class="p-sortable-column-icon icon-sort"
                                                                                    ariaLabel="Activate to sort"
                                                                                    ariaLabelDesc="Activate to sort in descending order"
                                                                                    ariaLabelAsc="Activate to sort in ascending order"
                                                                                    field="editableField"></i>
                                                                                <p-columnFilter type="text"
                                                                                    field="editableField" display="menu"
                                                                                    class="p-ml-auto"></p-columnFilter>
                                                                            </div>
                                                                        </th>
                                                                        <th id="actionButton" style="max-width: 6%;">
                                                                            <div></div>
                                                                        </th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-rowData
                                                                    let-index="rowIndex">
                                                                    <tr (mouseenter)="hoverIndex=index;highlight(index)"
                                                                        (mouseleave)="hoverIndex=index;highlight(index)"
                                                                        [pSelectableRow]="rowData">
                                                                        <td style="max-width: 10%;"
                                                                            class="custom-displayName">
                                                                            {{rowData.entityName}}
                                                                        </td>
                                                                        <td style="max-width: 13%;"
                                                                            class="custom-Attrname-td">
                                                                            {{rowData.fieldName}}</td>
                                                                        <td style="max-width: 15%;"
                                                                            class="custom-description">
                                                                            {{rowData.fieldInputType}}
                                                                        </td>
                                                                        <td style="max-width:30%;"
                                                                            class="custom-displayName">
                                                                            <span
                                                                                *ngIf="rowData.inputFormatStr != null">
                                                                                <span
                                                                                    pTooltip="{{rowData.inputFormatStr}}"
                                                                                    *ngIf="rowData.inputFormatStr?.length >= 15"
                                                                                    tooltipPosition="bottom">
                                                                                    {{rowData.inputFormatStr?.substring(0,15)
                                                                                    +
                                                                                    "..." }}
                                                                                </span>
                                                                                <span
                                                                                    *ngIf="rowData.inputFormatStr?.length < 15">
                                                                                    {{rowData.inputFormatStr}}
                                                                                </span>
                                                                            </span>
                                                                            <span
                                                                                *ngIf="rowData?.fieldInputType=='Checkbox'">-</span>
                                                                        </td>
                                                                        <td style="max-width: 12%;"
                                                                            class="custom-displayName">
                                                                            <span
                                                                                *ngIf="rowData?.fieldInputType=='Checkbox'">-</span>
                                                                            <span>{{rowData.mandatoryField}}</span>

                                                                        </td>
                                                                        <td style="max-width: 12%;"
                                                                            class="custom-displayName">
                                                                            <span
                                                                                *ngIf="rowData?.fieldInputType=='Checkbox'">-</span>
                                                                            <span>{{rowData.editableField}}</span>
                                                                        </td>
                                                                        <td style="width: 10%;">
                                                                            <div
                                                                                class="button-details custom-button-Details">
                                                                                <button pButton tooltipPosition="bottom"
                                                                                    style="width: 24px;height: 24px;padding: 0px;"
                                                                                    icon="g-icons view-icon"
                                                                                    pTooltip="Edit"
                                                                                    class="background-transparent"
                                                                                    (click)="activeItem=rowData;EditCustomField()"
                                                                                    [disabled]="this.pagesService.tenantManagementReadOnlyFlag">
                                                                                </button>
                                                                                <button pButton tooltipPosition="bottom"
                                                                                    style="width: 24px;height: 24px;padding: 0px;"
                                                                                    icon="g-icons delete-icon"
                                                                                    pTooltip="Delete"
                                                                                    class="background-transparent"
                                                                                    (click)="activeItem=rowData;DeleteCustomField()"
                                                                                    [disabled]="this.pagesService.tenantManagementReadOnlyFlag">
                                                                                </button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="paginatorright" let-state>
                                                                    <span *ngIf="filterFlag">Total Records:
                                                                        {{this.tableList.length}}</span>
                                                                    <span *ngIf="!filterFlag" class="p-pl-3">Total
                                                                        Records:
                                                                        {{state.totalRecords}}</span>
                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage">
                                                                    <tr>
                                                                        <td colspan="7" class="p-p-0 p-text-center">
                                                                            <p-card>
                                                                                <div
                                                                                    style="margin-top: 5%;height: 70px;">
                                                                                    No
                                                                                    matching records found</div>
                                                                            </p-card>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </p-tabPanel>
                    <p-tabPanel header="User Configuration">
                        <p-card>
                            <form [formGroup]="UserConfigurationForm" autocomplete="off" novalidate>

                                <div class="p-fluid p-formgrid p-grid">
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined">

                                        <div class="p-text-left">
                                            <label
                                                style="font-size: 14px;color:#242E4C;font-weight: 600;line-height: 0.5%;">
                                                Define user configuration for your tenant

                                            </label>
                                        </div>

                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 undefined">

                                        <div class="p-text-left">
                                            <label
                                                style="font-size: 14px;color:#505870;font-weight: 400;line-height: 31px;">
                                                <em>Note: Keep the input field empty, if the policy is not
                                                    applicable</em>
                                            </label>
                                        </div>

                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 undefined">
                                        <br>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 undefined p-mb-2">
                                        <div class="p-fluid">
                                            <span class="d-block label-top">
                                                <label
                                                    [ngClass]="{'field-required': UserConfigurationForm.get('userType')?.errors?.required}">
                                                    Type of user allowed
                                                    <span class='invalid-feedback-astrik'>*</span>
                                                    <label tooltipPosition="top"
                                                        pTooltip="Before disabling any type of user, ensure that the user is removed/deleted from the Tenant. Additionally, transfer the admin rights to the available users."
                                                        class="alert-icon alert-info-circle-icon p-ml-1"
                                                        tooltipStyleClass="customTooltip"
                                                        style="vertical-align: text-top;" [escape]="false"></label>
                                                </label>

                                            </span>
                                            <div class=" p-formgroup-inline">
                                                <div class="p-field-checkbox normal" *ngFor="let item of userTypeList">
                                                    <p-checkbox id="userType_id" [label]=" item.label " inputId="_id"
                                                        name="userType_name" [value]="item.value" icon="icon-checkbox"
                                                        formControlName="userType" [(ngModel)]="userTypeselectedValues"
                                                        (onChange)="this.enableBtn1=false;">
                                                    </p-checkbox>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="invalid-feedback"
                                            *ngIf="UserConfigurationForm.get('userType')!.dirty || UserConfigurationForm.get('userType')!.touched">
                                            <div *ngIf="!userTypeselectedValues?.length">
                                                <label class="alert-icon alert-circle-icon"></label>
                                                <medium class="p-error">Select a type of user allowed.
                                                </medium>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6 p-offset-0 undefined p-mb-4"
                                        *ngIf="userStorageFlag">
                                        <div class="p-fluid p-field">
                                            <label>
                                                External user identity provider <span
                                                    class='invalid-feedback-astrik'>*</span>
                                                <label tooltipPosition="top" pTooltip="Please contact or get 'Tenant_Admin' role for any changes.
                                            Configurations and data migration to be done manually."
                                                    class="alert-icon alert-info-circle-icon p-ml-1"
                                                    tooltipStyleClass="customTooltip" style="vertical-align: text-top;"
                                                    [escape]="false" *ngIf="userStorageDisableFlag"></label>
                                            </label>
                                            <p-dropdown id="select1id" name="userStorage" placeholder="Select"
                                                [options]="userStorageList" [filter]="true" filterBy="label"
                                                [autoDisplayFirst]="false" formControlName="userStorage"
                                                [(ngModel)]="this.pagesService.userStorage"
                                                (onChange)="enableBtn1 = false">
                                            </p-dropdown>
                                        </div>
                                        <div class="invalid-feedback"
                                            *ngIf="UserConfigurationForm.get('userStorage')!.invalid && (UserConfigurationForm.get('userStorage')!.dirty || UserConfigurationForm.get('userStorage')!.touched)">
                                            <div *ngIf="UserConfigurationForm.get('userStorage')?.errors?.required">
                                                <label class="alert-icon alert-circle-icon"></label>
                                                <medium class="p-error">Select type of external user identity provider.
                                                </medium>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6 p-offset-0 undefined p-mb-4"
                                        [ngStyle]="{'width': ''}">
                                        <div class="p-fluid">
                                            <label
                                                [ngClass]="{'field-required': UserConfigurationForm.get('moduleDropdown')?.errors?.required}">
                                                Module
                                                <span class='invalid-feedback-astrik'>*</span></label>

                                            <p-dropdown id="module_id" name="module_name" placeholder="select"
                                                [options]="selectList" [autoDisplayFirst]="false" [filter]="true"
                                                filterBy="label" optionDisabled="inactive"
                                                formControlName="moduleDropdown"
                                                (onChange)="getFeatureByModuleIdList();enableBtn1=false;">
                                                <ng-template let-item pTemplate="item">
                                                    <div *ngIf="item.label.length <= 35;">
                                                        {{item.label}}
                                                    </div>
                                                    <div *ngIf="item.label.length > 35;" pTooltip="{{item.label}}"
                                                        tooltipPosition="bottom">
                                                        {{item.label.substring(0,35) + "..." }}
                                                    </div>
                                                </ng-template>
                                            </p-dropdown>

                                        </div>
                                        <div class="invalid-feedback"
                                            *ngIf="UserConfigurationForm.get('moduleDropdown')!.invalid && (UserConfigurationForm.get('moduleDropdown')!.dirty || UserConfigurationForm.get('moduleDropdown')!.touched)">
                                            <div *ngIf="UserConfigurationForm.get('moduleDropdown')?.errors?.required">
                                                <label class="alert-icon alert-circle-icon"></label>
                                                <medium class="p-error">Select type of module.
                                                </medium>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6 p-offset-0 undefined  "
                                        [ngStyle]="{'width': ''}">
                                        <div class="p-fluid ">
                                            <label
                                                [ngClass]="{'field-required': UserConfigurationForm.get('featureDropdown')?.errors?.required}">
                                                Feature / Action to be allowed
                                                <span class='invalid-feedback-astrik'>*</span></label>


                                            <p-multiSelect id="featureList_id" name="featureList_name"
                                                class="custom-chips custom-check" filterPlaceHolder="Search"
                                                [options]="featureList" [filter]="true"
                                                formControlName="featureDropdown" optionLabel="label"
                                                optionValue="value" selectedItemsLabel="{0} features selected"
                                                display="chip" resetFilterOnHide="true" placeholder="Select"
                                                (onChange)="enableBtn1 = false" [(ngModel)]="this.selectFeatureList">
                                                <ng-template let-each pTemplate="selectedItem">
                                                    {{each.label}}
                                                </ng-template>
                                                <ng-template let-items pTemplate="item">
                                                    <div *ngIf="items?.label?.length <= 35;">
                                                        {{items.label}}
                                                    </div>
                                                    <div *ngIf="items?.label?.length > 35;" pTooltip="{{items.label}}"
                                                        tooltipPosition="bottom">
                                                        {{items?.label?.substring(0,35) + "..." }}
                                                    </div>
                                                </ng-template>
                                            </p-multiSelect>
                                        </div>
                                        <div class="invalid-feedback"
                                            *ngIf="UserConfigurationForm.get('featureDropdown')!.invalid && (UserConfigurationForm.get('featureDropdown')!.dirty || UserConfigurationForm.get('featureDropdown')!.touched)">
                                            <div *ngIf="UserConfigurationForm.get('featureDropdown')?.errors?.required">
                                                <label class="alert-icon alert-circle-icon"></label>
                                                <medium class="p-error">Select type of Feature / Action to be allowed.
                                                </medium>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 undefined"
                                        [ngStyle]="{'width': ''}">

                                        <div class="p-text-left">
                                            <label style="font-size: 13px;font-weight: 500;color:#242E4C;">
                                                Account inactivity / Dormancy configuration
                                            </label>
                                            <label tooltipPosition="top"
                                                pTooltip="User's account would be suspended & login would not be <br> allowed on exceeding allowed Dormancy configuration."
                                                class="alert-icon alert-info-circle-icon" [escape]="false"
                                                tooltipStyleClass="customTooltipAcc"></label>
                                        </div>

                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6 p-offset-0 undefined  "
                                        [ngStyle]="{'width': ''}">
                                        <div class="p-fluid ">
                                            <span class="d-block label-top"> <label>
                                                    Allowed limit of account inactivity of duration (in days)
                                                </label>
                                            </span>

                                            <div class="inputtext-medium">
                                                <input id="textfield1_id" type="text" name="textfield1_name"
                                                    formControlName="textfield1" pInputText
                                                    placeholder="Enter number of days"
                                                    class="p-inputtext inputtextHeight" pattern="^[1-9]\d*$"
                                                    (keyup)="enableBtn1 = false">
                                            </div>
                                            <div class="p-error"
                                                *ngIf="UserConfigurationForm.get('textfield1')!.invalid && (UserConfigurationForm.get('textfield1')!.dirty || UserConfigurationForm.get('textfield1')!.touched) ">
                                                <div *ngIf="UserConfigurationForm.get('textfield1')?.errors?.pattern">
                                                    <label class="alert-icon alert-circle-icon"></label>
                                                    <medium class="p-error">
                                                        Field input value not in number format
                                                    </medium>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6 p-offset-0 undefined"
                                        [ngStyle]="{'width': ''}">
                                        <div class="p-fluid ">
                                            <span class="d-block label-top"> <label>
                                                    Allowed limit of account inactivity of duration after last login (in
                                                    days)
                                                </label>
                                            </span>

                                            <div class="inputtext-medium">
                                                <input id="textfield2_id" type="text" name="textfield2_name"
                                                    formControlName="textfield2" pInputText
                                                    placeholder="Enter number of days"
                                                    class="p-inputtext inputtextHeight" pattern="^[1-9]\d*$"
                                                    (keyup)="enableBtn1 = false">
                                            </div>
                                            <div class="p-error"
                                                *ngIf="UserConfigurationForm.get('textfield2')!.invalid && (UserConfigurationForm.get('textfield2')!.dirty || UserConfigurationForm.get('textfield2')!.touched) ">
                                                <div *ngIf="UserConfigurationForm.get('textfield2')?.errors?.pattern">
                                                    <label class="alert-icon alert-circle-icon"></label>
                                                    <medium class="p-error">
                                                        Field input value not in number format
                                                    </medium>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-0 p-mt-3 undefined"
                                        [ngStyle]="{'width': ''}" *ngIf="this.pagesService.pageRebacFlag=='false'">
                                        <div class="p-fluid">
                                            <span class="d-block label-top"> <label>
                                                    Welcome Email Type
                                                </label>
                                            </span>
                                            <div class="p-fieldp-fluid">
                                                <p-selectButton id="selectSwitch_id" name="selectSwitch_name"
                                                    [(ngModel)]="defaultselect_selectSwitch"
                                                    [options]="selectSwitchList" formControlName="selectSwitch"
                                                    styleClass="default" (onChange)="enableBtn1=false">
                                                    <ng-template let-item>
                                                        <span class="p-button-label">{{item.label}}</span>
                                                    </ng-template>
                                                </p-selectButton>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 p-mb-3 undefined">
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 p-d-flex p-jc-end p-mb-2"
                                        [ngStyle]="{'width': ''}">

                                        <div class="btnwrap">
                                            <button pButton class="p-button" type="button"
                                                [disabled]="!UserConfigurationForm.valid || enableBtn1 || this.pagesService.tenantManagementReadOnlyFlag"
                                                label="Save" (click)="createTenantConfiguration()">
                                            </button>
                                        </div>

                                    </div>
                                </div>

                            </form>
                        </p-card>
                    </p-tabPanel>
                    <p-tabPanel header="SSO configuration" disabled="true"></p-tabPanel>
                    <p-tabPanel header="Themes" disabled="true"></p-tabPanel>
                    <p-tabPanel header="Preferences" disabled="true"></p-tabPanel>
                    <p-tabPanel header="Rules" disabled="true"></p-tabPanel>
                </p-tabView>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
            </div>
        </div>
    </form>
</div>