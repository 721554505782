<div style="background-color:#F6F8FA;margin-bottom: 25px;">
    <div class="p-d-flex p-jc-end">
        <p-messages key="myKey1" class="custom-alert-box" *ngIf="this.messageFlag"></p-messages>
    </div>
    <div class="p-col-fixed p-mt-1 p-ml-1 p-pr-0 custom-breadcrumb">
        <p-breadcrumb [model]="ccprimebreadcrumbList" [home]="homeiconccprimebreadcrumb">
        </p-breadcrumb>
    </div>
    <div style="padding:15px ;">
        <div class="col-md-12">
            <div class="alert alert-success alert-dismissible fade show succes-msg" role="alert"
                *ngIf="isSuccess==true">
                <i class="pi pi-check" style="font-size: 1em"></i> {{successMessage}}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="isSuccess=false">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
        <div class="col-md-12">
            <div class="alert alert-danger alert-dismissible fade show succes-msg" role="alert" *ngIf="isFailure==true">
                <i class="pi pi-pi-exclamation-triangle" style="font-size: 1em"></i> {{failureMessage}}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="isFailure=false">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>

        <div class="user-header-details">
            <div class="p-col p-p-0 p-m-0 p-text-left">
                <span class="userDetailsFormat">
                    {{entitlement.ENTITLEMENT_NAME}}
                </span>
                <span class="changeUserTitle changeUser">
                    <p-dropdown class="d-inline-block mr-2" [(ngModel)]="selectedEntitlement"
                        [options]="entitlementList" optionLabel="NAME" placeholder="Change entitlement"
                        pTooltip="Change entitlement" [filter]="true" appendTo="body"
                        [style]="{ border: 'none', 'text-decoration': 'underline', color: '#375889' }"
                        [dropdownIcon]="false" filterPlaceholder="Search Entitlement" tooltipPosition="top"
                        (onChange)="unSaveChangesPopUp()">
                    </p-dropdown>
                </span>
                <div class="p-grid p-ai-center">
                    <a>
                        <div pTooltip="Entitlement ID" class="user-id-details">
                            {{entitlement.COMPONENT_ID}}-{{entitlement.ENTITLEMENT_ID}}</div>
                    </a>
                    <p-divider layout="vertical"></p-divider> <span
                        class="user-header-txt">{{entitlement.STATUS}}</span>
                    <p-divider layout="vertical"></p-divider> <span
                        class="user-header-txt">{{entitlement.COMPONENT_NAME}}</span>
                </div>
            </div>
        </div>

        <div class="row mb-1" *ngIf="appliedGlobalRoleFilter.length > 0 || appliedGlobalUserFilter.length > 0 ">
            <div class="col" style="    top: 25px;
            left: 387px;
            position: absolute;
            max-width: 650px">
                <div>
                    <span>Filters Applied: </span>
                    <span class="myRoundedLabel" *ngFor="let user of appliedGlobalUserFilter"
                        (click)="setGlobalActiveTab('user')">
                        {{user.label}}
                    </span>
                    <span class="myRoundedLabel" *ngFor="let role of appliedGlobalRoleFilter"
                        (click)="setGlobalActiveTab('role')">
                        {{role.label}}
                    </span>
                    <span class="myRoundedLabel" style="background-color: red;" (click)="clearAllGlobalFilters()">
                        x Clear All Filters
                    </span>
                </div>
            </div>
        </div>

        <div>


            <div class="p-grid">
                <div class="p-col-6 p-mt-2 p-pl-0 p-pr-0 p-d-flex user-entitltment-tab">
                    <p class="p-mr-2 p-mt-1 p-mb-0 entilt-positon">Users</p>
                    <a [routerLink]="" class="add-box-input" (click)="addUser()">Add New </a>
                </div>
                <div class="p-col-6 p-mt-2 p-pl-0 p-pr-0 p-text-right">
                    <div class="p-d-flex p-jc-end p-mb-0 p-mr-2">
                        <div *ngIf="this.selectedUserRecords.length > 0" class="userSelectedCount p-ml-2">
                            Users selected {{
                            this.selectedUserRecords.length
                            }}
                        </div>

                        <button pButton class="p-button-brand2 p-ml-2" pTooltip="Delete" tooltipPosition="top"
                            icon="icon-trash" (click)="openDeleteMultipleUsersDialog()"
                            *ngIf="this.selectedUserRecords.length > 0"></button>
                        <div class="space_before_filter">
                            <button pButton type="button" icon="icon-filter" class="p-button-brand2"
                                (click)="userFilter.toggle($event);openUserFilterSideBar()">
                            </button>
                        </div>
                    </div>
                </div>

                <div class="p-col p-pl-0 p-ai-center"
                    *ngIf="appliedStatusFilter.length > 0 || appliedEntilementStatusFilter.length > 0">
                    <label>Filters Applied: </label>
                    <p-chip *ngIf="appliedSearch" class="p-mr-2" styleClass="p-chip-lg p-chip-blue medium-text"
                        label="{{ appliedSearch }}">
                    </p-chip>
                    <p-chip *ngFor="let element of appliedStatusFilter"
                        (click)="setUserActiveTab('user'); userFilter.toggle($event)" class="p-mr-2"
                        styleClass="p-chip-lg p-chip-blue medium-text" label="{{ element.label }}">
                    </p-chip>
                    <p-chip *ngFor="let element of appliedEntilementStatusFilter"
                        (click)="setUserActiveTab('entitlementStatus'); userFilter.toggle($event)" class="p-mr-2"
                        styleClass="p-chip-lg p-chip-blue medium-text" label="{{ element.label }}">
                    </p-chip>
                    <span *ngIf="appliedStatusFilter.length > 0 || appliedEntilementStatusFilter.length > 0"><label
                            class="p-mb-0" (click)="clearEntUserFilters()"><a>Clear all</a></label></span>

                </div>

                <div class="p-col-12 p-pl-0" style="padding-top:0.1rem !important">
                    <div class="custom-table ciam overflow-table-scroll">
                        <p-table #dt [columns]="userColumns" [value]="usersList" [rowHover]="true"
                            styleClass="p-datatable-gridlines" [scrollable]="true" scrollHeight="241px"
                            [resizableColumns]="true" [responsive]="true" [(selection)]="selectedUserRecords">
                            <ng-template pTemplate="header" let-userColumns>
                                <tr>
                                    <th scope="col" class="p-d-flex">
                                        <span class="p-d-inline-block"
                                            style="height: 20px;padding-top: 2px;padding-right: 5px">
                                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                        </span>
                                        UM User ID
                                    </th>
                                    <th scope="col" *ngFor="let col of userColumns">
                                        {{col.header}}
                                    </th>
                                </tr>
                                <tr *ngIf="isAddNewUser" class="role-bg">
                                    <td style="width:28%">
                                        <div class="p-fluid p-mr-2">
                                            <label for="component" class="p-col-12 p-mb-0 p-mr-0 p-p-0 p-ml-3">User<span
                                                    class="text-danger"> * </span></label>
                                            <div class="p-ml-3 p-mb-3">
                                                <p-multiSelect name="user" [options]="addUserDropdown"
                                                    [(ngModel)]="selectedUser" defaultLabel="Select User"
                                                    class="custom-chips custom-check" appendTo="body"
                                                    optionLabel="NAME_WITH_ID" maxSelectedLabels=0 [filter]="true"
                                                    filterPlaceholder="Select User" (onChange)="isUserExist=false;">
                                                    <ng-template let-each pTemplate="selectedItem">
                                                        {{each.NAME_WITH_ID}}
                                                    </ng-template>
                                                    <ng-template let-items pTemplate="item">
                                                        <div *ngIf="items?.NAME_WITH_ID?.length <= 35;">
                                                            {{items.NAME_WITH_ID}}
                                                        </div>
                                                        <div *ngIf="items?.NAME_WITH_ID?.length > 35;"
                                                            pTooltip="{{items.NAME_WITH_ID}}" tooltipPosition="bottom">
                                                            {{items?.NAME_WITH_ID?.substring(0,35) + "..." }}
                                                        </div>
                                                    </ng-template>
                                                </p-multiSelect>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="group-button-col p-ml-3 p-mt-3">

                                        <button pButton label="Add" class="p-mt-2" (click)="addUsersToEntTable()"
                                            [ngClass]="{ 'anchor-disabled': !selectedUser.length!=0 }">
                                        </button>
                                        <button type="button" pButton
                                            class="p-element p-button-brand2 p-button p-component p-ml-2"
                                            label="Discard" (click)="discardUser()"></button>

                                    </td>

                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </ng-template>
                            <br>
                            <ng-template pTemplate="body" let-user let-rowIndex="rowIndex">
                                <tr>
                                    <td class="p-d-inline-flex" style="padding-top: 10px;">
                                        <span class="p-d-inline-block"
                                            style="height: 20px;padding-top: 4px;padding-right: 5px">
                                            <p-tableCheckbox [value]="user"></p-tableCheckbox>
                                        </span>
                                        <a routerLink="" (click)="navigateFirstName(user)">{{user.USER_ID}}</a>

                                    </td>
                                    <td>{{user.USER_NAME}}</td>
                                    <td>
                                        {{user.USER_STATUS}}
                                    </td>
                                    <td>{{user.ENT_STATUS === "Pending" ? "Deny" : user.ENT_STATUS}}</td>
                                    <td class="p-d-flex">
                                        {{user.UPDATED_ON | date: 'MMM d, y, h:mm:ss
                                        a'}}
                                        <div class="desktop">
                                            <div class="button-details custom-hover-button">
                                                <button pButton type="button"
                                                    class="p-button-strict p-button-info p-ml-5 p-pt-0"
                                                    pTooltip="Delete" icon="icon-trash" tooltipPosition="bottom"
                                                    (click)="deleteUserEntitlement(user,rowIndex)">
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="7" class="p-p-0 p-text-center">
                                        <p-card>
                                            <div style="margin-top: 5%;height: 70px;">
                                                No records found</div>
                                        </p-card>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>

            <div class="p-col-md-12">
                <div class="p-grid">
                    <div class="p-col-6 p-mt-2 p-pl-0 p-pr-0 p-d-flex user-entitltment-tab">
                        <p class="p-mr-2 p-mt-1 p-mb-0 entilt-positon">Roles</p>
                        <a [routerLink]="" class="add-box-input" (click)="addRole()">Add Role
                        </a>
                    </div>
                    <div class="p-col-6 p-mt-2 p-pr-0 p-text-right">
                        <div class="p-d-flex p-jc-end p-mr-2 space_before_filter">
                            <div *ngIf="this.selectedRolesRecords.length > 0" class="userSelectedCount p-ml-2">
                                Roles selected {{
                                this.selectedRolesRecords.length
                                }}
                            </div>

                            <button pButton class="p-button-brand2 p-ml-2" pTooltip="Delete" tooltipPosition="top"
                                icon="icon-trash" (click)="openDeleteMultipleRolesDialog()"
                                *ngIf="this.selectedRolesRecords.length > 0" style="height: 33px;"></button>
                            <div class="inputtext-normal" style="position: relative;margin-left: 8px;">
                                <span class="p-input-icon-left">
                                    <i class="icon-search"></i>
                                    <input type="text" id="search-ent-bar" pInputText
                                        placeholder="Search by Role Id, Name" (keyup)="loadRolesData()"
                                        [(ngModel)]="search" />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>



                <div class="row mb-1" *ngIf="appliedRoleFilter.length > 0">
                    <div class="col">
                        <div>
                            <span>Filters Applied: </span>
                            <span class="myRoundedLabel" *ngFor="let element of appliedRoleFilter"
                                (click)="setRoleActiveTab('role')">
                                {{element.label}}
                            </span>
                            <span class="myRoundedLabel" *ngFor="let element of appliedEntilementStatusFilter"
                                (click)="setRoleActiveTab('entitlementStatus')">
                                {{element.label}}
                            </span>
                            <span class="myRoundedLabel" style="background-color: red;" (click)="clearAllRoleFilters()">
                                x Clear All Filters
                            </span>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-pl-0 " style="padding-top:0.1rem !important">
                    <div class="custom-table ciam overflow-table-scroll">
                        <p-table #dt [columns]="roleColumns" [value]="roleListForEnt" [rowHover]="true"
                            styleClass="p-datatable-gridlines" [scrollable]="true" scrollHeight="241px"
                            [resizableColumns]="true" [responsive]="true" [(selection)]="selectedRolesRecords">
                            <ng-template pTemplate="header" let-roleColumns>
                                <tr>
                                    <th scope="col" class="p-d-flex">
                                        <span class="p-d-inline-block"
                                            style="height: 20px;padding-top: 2px;padding-right: 5px">
                                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                        </span>
                                        UM Role Id
                                    </th>
                                    <th scope="col" *ngFor="let col of roleColumns">
                                        {{col.header}}
                                    </th>
                                </tr>
                                <tr *ngIf="isAddNewRole" class="role-bg">
                                    <td style="width:28%">
                                        <div class="p-fluid p-mr-2">
                                            <label for="component" class="p-col-12 p-mb-0 p-mr-0 p-p-0 p-ml-3">Role<span
                                                    class="text-danger"> * </span></label>
                                            <div class="p-ml-3 p-mb-3">
                                                <p-multiSelect name="role" [options]="roleDropdown"
                                                    class="custom-chips custom-check" [(ngModel)]="selectedRole"
                                                    defaultLabel="Select Role" appendTo="body" optionLabel="ROLE_NAME"
                                                    maxSelectedLabels=0 [filter]="true" filterPlaceholder="Select Role">
                                                    <ng-template let-each pTemplate="selectedItem">
                                                        {{each.ROLE_NAME}}
                                                    </ng-template>
                                                    <ng-template let-items pTemplate="item">
                                                        <div *ngIf="items?.ROLE_NAME?.length <= 35;">
                                                            {{items.ROLE_NAME}}
                                                        </div>
                                                        <div *ngIf="items?.ROLE_NAME?.length > 35;"
                                                            pTooltip="{{items.ROLE_NAME}}" tooltipPosition="bottom">
                                                            {{items?.ROLE_NAME?.substring(0,35) + "..." }}
                                                        </div>
                                                    </ng-template>
                                                </p-multiSelect>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="group-button-col p-mt-3 p-ml-2">
                                        <button pButton label="Add"
                                            [ngClass]="{ 'anchor-disabled': !selectedRole.length!=0 }"
                                            (click)="addRoleToTable()" class="p-mt-2"></button>

                                        <button type="button" pButton
                                            class="p-element p-button-brand2 p-button p-component p-ml-2"
                                            label="Discard" (click)="discardRole()"></button>

                                    </td>
                                    <td></td>
                                    <td></td>

                                </tr>
                            </ng-template>
                            <br>
                            <ng-template pTemplate="body" let-role let-rowIndex="rowIndex">
                                <tr>
                                    <td class="p-d-inline-flex" style="padding-top: 10px;">
                                        <span class="p-d-inline-block"
                                            style="height: 20px;padding-top: 4px;padding-right: 5px">
                                            <p-tableCheckbox [value]="role"></p-tableCheckbox>
                                        </span>
                                        <span><a routerLink="/pages/roledetails"
                                                (click)="navigateToRoleDetailScreen(role)"
                                                style="text-decoration: none !important;">{{role.ROLE_ID}}
                                            </a></span>
                                    </td>
                                    <td>
                                        {{role.ROLE_NAME}}

                                    </td>
                                    <td>{{role.ROLE_STATUS}}</td>
                                    <td class="p-d-flex">{{role.UPDATED_ON | date: 'MMM d, y, h:mm:ss
                                        a'}}
                                        <div class="desktop">
                                            <div class="button-details custom-hover-button">
                                                <button pButton type="button"
                                                    class="p-button-strict p-button-info p-ml-5 p-pt-0"
                                                    pTooltip="Delete" icon="icon-trash" tooltipPosition="bottom"
                                                    (click)="deleteRoleEntitlement(role,rowIndex)">
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="7" class="p-p-0 p-text-center">
                                        <p-card>
                                            <div style="margin-top: 5%;height: 70px;">
                                                No records found</div>
                                        </p-card>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>

            <div class="user-details-footer-wrapper p-mb-0 p-mt-6">
                <div></div>
                <div class="p-grid p-mt-2">
                    <div class="p-d-flex">
                        <div class="p-mt-3">
                            <label class="l-icons last-saved" style="margin: 0px;"></label>

                        </div>
                        <div class="p-p-2 p-mt-2">
                            <p class="saveDetails">Last Saved at {{lastModifiedDate | date: 'MMM d, y,
                                hh:mm:ss
                                a'}}</p>
                        </div>
                        <div class="p-mt-2">
                            <button pButton type="submit" label="Back" class="p-button-secondary"
                                (click)="navigateToEntitlementListingScreen()"></button>
                        </div>
                        <div class="p-pl-2 p-pt-2 p-pb-2">
                            <button type="submit" pButton label="Save & Apply"
                                [ngClass]="{'anchor-disabled':isSaveAndApplyBtnDisabled}"
                                (click)="updateEntitlementDetails(true)"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <p-dialog header="Confirm?" [modal]="true" [(visible)]="isConfirmDialog" [style]="{width: '30vw'}"
            [baseZIndex]="10000">
            <div class="p-grid p-fluid p-0">
                <div class="p-col-12 p-md-12 text-center">

                    <label class="customlabel mb-0">Override Warning!</label>
                    <p>{{confirmMessage}}</p>
                </div>
            </div>
            <p-footer>
                <button pButton type="button" label="Cancel" (click)="cancelConfimDialog()"
                    class="ui-button-raised ui-button-danger btnCancel"></button>
                <button pButton type="button" label="Continue" class="ui-button-raised btnDelete"
                    (click)="onActionConfirmationClick()"></button>

            </p-footer>
        </p-dialog>

        <p-dialog header="Confirm?" [modal]="true" [(visible)]="isImportConfirmDialog" [style]="{width: '30vw'}"
            [baseZIndex]="10000">

            <div class="p-grid p-fluid p-0">
                <div class="p-col-12 p-md-12 text-center">

                    <br>
                    <label class="customlabel mb-0">Override Warning!</label>
                    <p>{{confirmMessage}}</p>
                </div>
            </div>

            <p-footer>
                <button pButton type="button" label="Cancel" (click)="cancelConfimDialog()"
                    class="ui-button-raised ui-button-danger btnCancel"></button>
                <button pButton type="button" label="Continue" class="ui-button-raised btnDelete"
                    (click)="onActionConfirmationClick()"></button>

            </p-footer>
        </p-dialog>

        <p-dialog header="You have unsaved changes" [modal]="true" [(visible)]="onTabChange" [style]="{width: '44vw'}"
            [baseZIndex]="10000">

            <div class="p-grid p-fluid p-0">
                <div class="p-col-12 p-md-12 text-center">

                    <p class="discard-btn">{{navigationMessage}}</p>

                </div>
            </div>
            <p-footer>

                <button pButton type="button" (click)="onDiscardChange()" label="Discard Changes" class="p-button-info"
                    style="float: left;right: 18px;"></button>
                <button type="button" pButton label="Cancel" (click)="onCancel()"
                    class="p-element p-button-brand2 p-button p-component p-mr-0"></button>
                <button pButton type="button" label="Save & Apply" (click)="onSaveAndApply()"></button>
            </p-footer>
        </p-dialog>

        <p-sidebar [(visible)]="isRoleFilterSelection" [baseZIndex]="10000" [showCloseIcon]="false" position="right"
            [style]="{width:'400px', overflowY: 'scroll', overflowX: 'hidden'}" (onHide)="sidebarCloseEvent('role')">
            <br>

            <div class="row mb-1">
                <div class="col-auto filter-icon" style="vertical-align: middle;">
                    <i class="pi pi-filter"></i>
                </div>

                <div class="col" style="vertical-align: middle;">
                    <h6 class="filter-title">Filters</h6>
                </div>

                <div class="col-auto" style="vertical-align: middle;">
                    <button pButton type="button" icon="pi pi-times" (click)="isRoleFilterSelection = false"
                        styleClass="p-button-sm"></button>
                </div>
            </div>

            <div class="row mb-1">
                <div class="col-md-12">
                    <div class="cri-border-bottom"></div>
                </div>
            </div>

            <div class="row mb-1" *ngIf="filterMessage">
                <div class="col-md-12">
                    <div class="alert alert-danger alert-dismissible fade show" role="alert">
                        <i class="fa fa-info-circle"></i> {{ filterMessage }}

                        <button type="button" class="close" data-dismiss="alert" aria-label="Close"
                            (click)="filterMessage = ''">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
            </div>

            <div class="row mb-1">
                <div class="col-md-12">
                    <p-accordion [multiple]="true">
                        <p-accordionTab header="Role" [(selected)]="roleFilterTab.isRoleTab">
                            <p-tree [value]="roleDropdown" selectionMode="checkbox" [filter]="true"
                                [(selection)]="selectedRoleFilter" [style]="{'width':'100%'}">
                            </p-tree>
                        </p-accordionTab>

                        <p-accordionTab header="Entitlement Status" [(selected)]="roleFilterTab.isEntitlementStatusTab">
                            <p-tree [value]="entitlementStatusDropdown" selectionMode="checkbox" [filter]="true"
                                [(selection)]="selectedEntilementStatusFilter" [style]="{'width':'100%'}"></p-tree>
                        </p-accordionTab>
                    </p-accordion>
                </div>
            </div>

            <br>

            <div class="row mb-1">
                <div class="col-md-12">
                    <button type="button" pButton (click)="applyRoleFilter($event)" icon="pi pi-check" label="Apply"
                        class="ui-button-raised ui-button-success pull-right"></button>
                </div>
            </div>

            <br>
            <br>
        </p-sidebar>

        <p-sidebar [(visible)]="isUserFilterSelection" [baseZIndex]="10000" [showCloseIcon]="false" position="right"
            [style]="{width:'400px', overflowY: 'scroll', overflowX: 'hidden'}" (onHide)="sidebarCloseEvent('user')">
            <br>

            <div class="row mb-1">
                <div class="col-auto filter-icon" style="vertical-align: middle;">
                    <i class="pi pi-filter"></i>
                </div>

                <div class="col" style="vertical-align: middle;">
                    <h6 class="filter-title">Filters</h6>
                </div>

                <div class="col-auto" style="vertical-align: middle;">
                    <button pButton type="button" icon="pi pi-times" (click)="isUserFilterSelection = false"
                        styleClass="p-button-sm"></button>
                </div>
            </div>

            <div class="row mb-1">
                <div class="col-md-12">
                    <div class="cri-border-bottom"></div>
                </div>
            </div>

            <div class="row mb-1" *ngIf="filterMessage">
                <div class="col-md-12">
                    <div class="alert alert-danger alert-dismissible fade show" role="alert">
                        <i class="fa fa-info-circle"></i> {{ filterMessage }}

                        <button type="button" class="close" data-dismiss="alert" aria-label="Close"
                            (click)="filterMessage = ''">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
            </div>

            <div class="row mb-1">
                <div class="col-md-12">
                    <p-accordion [multiple]="true">
                        <p-accordionTab header="User" [(selected)]="userFilterTab.isUserTab">
                            <p-tree [value]="userDropdown" selectionMode="checkbox" [filter]="true"
                                [(selection)]="selectedUserFilter" [style]="{'width':'100%'}">
                            </p-tree>
                        </p-accordionTab>

                        <p-accordionTab header="Bank" [(selected)]="userFilterTab.isBankTab">
                            <p-tree [value]="bankDropdown" selectionMode="checkbox" [filter]="true"
                                [(selection)]="selectedBankFilter" [style]="{'width':'100%'}">
                            </p-tree>
                        </p-accordionTab>

                        <p-accordionTab header="service Line" [(selected)]="userFilterTab.isServiceLineTab">
                            <p-tree [value]="serviceLineDropdown" selectionMode="checkbox" [filter]="true"
                                [(selection)]="selectedserviceLineFilter" [style]="{'width':'100%'}">
                            </p-tree>
                        </p-accordionTab>

                        <p-accordionTab header="Entitlement Status" [(selected)]="userFilterTab.isEntitlementStatusTab">
                            <p-tree [value]="userEntitlementStatusDropdown" selectionMode="checkbox" [filter]="true"
                                [(selection)]="selectedEntilementStatusFilter" [style]="{'width':'100%'}"></p-tree>
                        </p-accordionTab>
                    </p-accordion>
                </div>
            </div>

            <br>

            <div class="row mb-1">
                <div class="col-md-12">
                    <button type="button" pButton (click)="applyUserFilter($event)" icon="pi pi-check" label="Apply"
                        class="ui-button-raised ui-button-success pull-right"></button>
                </div>
            </div>
            <br>
        </p-sidebar>
        <p-sidebar [(visible)]="isGlobalFilterSelection" [baseZIndex]="10000" [showCloseIcon]="false" position="right"
            [style]="{width:'400px', overflowY: 'scroll', overflowX: 'hidden'}" (onHide)="sidebarCloseEvent('global')">
            <br>

            <div class="row mb-1">
                <div class="col-auto filter-icon" style="vertical-align: middle;">
                    <i class="pi pi-filter"></i>
                </div>

                <div class="col" style="vertical-align: middle;">
                    <h6 class="filter-title">Filters</h6>
                </div>
            </div>

            <div class="row mb-1">
                <div class="col-md-12">
                    <div class="cri-border-bottom"></div>
                </div>
            </div>

            <div class="row mb-1" *ngIf="filterMessage">
                <div class="col-md-12">
                    <div class="alert alert-danger alert-dismissible fade show" role="alert">
                        <i class="fa fa-info-circle"></i> {{ filterMessage }}

                        <button type="button" class="close" data-dismiss="alert" aria-label="Close"
                            (click)="filterMessage = ''">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
            </div>

            <div class="row mb-1">
                <div class="col-md-12">
                    <p-accordion [multiple]="true">
                        <p-accordionTab header="ROLES" [(selected)]="activeGlobalTab.isGlobalRoleTab">
                            <p-tree [value]="roleDropdown" selectionMode="checkbox" [filter]="true"
                                [(selection)]="selectedGlobalRoleFilter" [style]="{'width':'100%'}">
                            </p-tree>
                        </p-accordionTab>

                        <p-accordionTab header="USERS" [(selected)]="activeGlobalTab.isGlobalUserTab">
                            <p-tree [value]="userDropdown" selectionMode="checkbox" [filter]="true"
                                [(selection)]="selectedGlobalUserFilter" [style]="{'width':'100%'}"></p-tree>
                        </p-accordionTab>

                    </p-accordion>
                </div>
            </div>

            <br>

            <div class="row mb-1">
                <div class="col-md-12">
                    <button type="button" pButton (click)="applyGlobalFilter(true)" icon="pi pi-check" label="Apply"
                        class="ui-button-raised ui-button-success pull-right"></button>
                </div>
            </div>

            <br>
            <br>
        </p-sidebar>
        <div>
            <p-dialog header="Remove User" styleClass="md" [modal]="true" [(visible)]="isDeleteEntUserDialog"
                [baseZIndex]="10000">
                <p style="color: red;" class="subheading-text">Remove User '{{userName}}-{{userId}}' from the
                    Entitlement
                    '{{entitlementName}}-{{componentId}}-{{entitlementId}}'</p>
                <p class="dialog-Content text-content-center">This action will remove the access of this entitlement for
                    the
                    user. Do you want to continue?</p>
                <p-footer>

                    <button type="button" pButton label="Cancel" (click)="isDeleteEntUserDialog = false"
                        class="p-element p-button-brand2 p-button p-component p-mr-0"></button>
                    <button pButton type="button" label="Remove" (click)="userDeleteconfirmationPopupForEnt()"></button>
                </p-footer>
            </p-dialog>
        </div>

        <div>
            <p-dialog header="Remove Users" styleClass="md" [modal]="true" [(visible)]="isMultipleDeleteEntUserDialog"
                [baseZIndex]="10000">
                <p style="color: red;" class="subheading-text">Remove Users from the Entitlement
                    '{{entitlementName}}-{{componentId}}-{{entitlementId}}'</p>
                <p class="dialog-Content text-content-center">You have selected {{this.selectedUserRecords.length > 9 ?
                    this.selectedUserRecords.length : '0' + this.selectedUserRecords.length}} users to be removed.</p>
                <p class="dialog-Content">This action will remove the access of this entitlement for the users. Do you
                    want
                    to continue?</p>
                <p-footer>

                    <button type="button" pButton label="Cancel" (click)="isMultipleDeleteEntUserDialog = false"
                        class="p-element p-button-brand2 p-button p-component p-mr-0"></button>

                    <button pButton type="button" label="Remove" (click)="deleteMultipleUsers()"></button>
                </p-footer>
            </p-dialog>
        </div>

        <div>
            <p-dialog header="Remove Entitlement" styleClass="md" [modal]="true"
                [(visible)]="isMultipleDeleteEntRoleDialog" [baseZIndex]="10000">
                <p style="color: red;" class="subheading-text">Remove Entitlement
                    '{{entitlementName}}-{{componentId}}-{{entitlementId}}' from the selected Roles.</p>

                <p class="dialog-Content">This action will remove the access of this entitlement for the selected
                    {{this.selectedRolesRecords.length > 9 ? this.selectedRolesRecords.length : '0' +
                    this.selectedRolesRecords.length}} roles. Do you <br> want to continue?</p>
                <p-footer>

                    <button type="button" pButton label="Cancel" (click)="isMultipleDeleteEntRoleDialog = false"
                        class="p-element p-button-brand2 p-button p-component p-mr-0"></button>
                    <button pButton type="button" label="Remove" (click)="deleteMultipleRoles()"></button>
                </p-footer>
            </p-dialog>
        </div>
        <div>
            <p-dialog header="Remove Entitlement" styleClass="md" [modal]="true" [(visible)]="isDeleteEntRoleDialog"
                [baseZIndex]="10000">
                <p style="color: red;" class="subheading-text">Remove Entitlement
                    '{{entitlementName}}-{{componentId}}-{{entitlementId}}' from Role '{{roleName}}-{{roleId}}'.</p>
                <p class="dialog-Content text-content-center">This action will remove the access to the entitlement for
                    the
                    role. Do you want to continue?</p>
                <p-footer>

                    <button type="button" pButton label="Cancel" (click)="isDeleteEntRoleDialog = false"
                        class="p-element p-button-brand2 p-button p-component p-mr-0"></button>
                    <button pButton type="button" label="Remove" (click)="deleteRoleConfirmEntitlement()"></button>
                </p-footer>
            </p-dialog>
        </div>
        <p-overlayPanel #userFilter class="overlayactionpart userfilter entilement-panel-content"
            (onHide)="sidebarCloseUserEvents()">
            <ng-template pTemplate>
                <div class="p-col-12 p-md-12 filtercard p-p-0">
                    <div class="addOrgAttrs">
                        <div class="p-d-flex filter-size p-ai-baseline">
                            <span class="filter-txt">Filters</span>
                            <span class="clear-all-text" style="margin-left:auto !important"
                                *ngIf="selectedUserStatus.length > 0 || selectedEntilementStatusFilter.length > 0"
                                (click)="clearEntUserFilters();discardUserFilter($event, userFilter)">
                                <a>Clear All</a>
                            </span>
                        </div>

                        <div class="row mb-1 user-panel">
                            <div class="col-md-12 accd">
                                <p-accordion expandIcon="pi pi-plus" collapseIcon="pi pi-minus">
                                    <p-accordionTab header="User Status" [(selected)]="activeUserTab.isStatusTab">
                                        <p-tree [value]="userStatusFilterDropdown" selectionMode="checkbox"
                                            [filter]="true" [(selection)]="selectedUserStatus"
                                            [style]="{'width':'100%'}">
                                        </p-tree>
                                    </p-accordionTab>
                                    <p-accordionTab header="Approval Status"
                                        [(selected)]="activeUserTab.isEntitlementStatusTab">
                                        <p-tree [value]="entStatusFilterDropdown" selectionMode="checkbox"
                                            [filter]="true" [(selection)]="selectedEntilementStatusFilter"
                                            [style]="{'width':'100%'}"></p-tree>
                                    </p-accordionTab>
                                </p-accordion>
                            </div>
                        </div>

                        <div class="p-d-flex p-mt-5 p-jc-between p-mb-2" style="width: 100%">
                            <button type="button" pButton class="p-element p-button-brand2 p-button p-component"
                                label="Cancel"
                                (click)="discardUserFilter($event, userFilter); cancelSelectedUserFilter()"></button>
                            <button type="button" pButton
                                (click)="applyEntUserFilter();discardUserFilter($event, userFilter)" label="Apply"
                                class="p-button-primary p-button p-component"
                                [disabled]="!areFiltersSelected()"></button>
                        </div>
                    </div>
                </div>
            </ng-template>
        </p-overlayPanel>
    </div>