import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PagesService } from '../pages.service';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
@Component({
  selector: 'jhi-deactiverole',
  templateUrl: './RejectTransaction.component.html',
  styleUrls: ['./RejectTransaction.component.scss'],
})
export class RejectTransactionComponent implements OnInit {
  RejectTransactionForm = this.fb.group({
    textarea: [null, [Validators.required]],
  });

  pageloadvalues: any;
  transactionId: any;
  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.RejectTransactionForm.patchValue(JSON.parse(data));
    }
  }
  ngOnInit(): void {
    this.transactionId = this.config?.data?.id;
  }
  onClose($event: UntypedFormGroup): void {
    this.ref.close();
  }

  //Activate user API function
  onSaveRejectRequest($event: UntypedFormGroup): void {
    const reqBody = {};
    reqBody['tenantId'] = this.pagesService.tenantId;
    reqBody['transactionId'] = this.transactionId;
    reqBody['status'] = "Rejected";
    reqBody['comments'] = this.RejectTransactionForm.value.textarea;
    const formValues = reqBody;
    this.pagesService.saveApproveRejectRequest(formValues).subscribe(
      {
        next: results => {
          if (results.success?.messageCode == "CIAM 154") {
            this.ref.close("success");
          }
          else if (results.error?.messageCode == "CIAM 113" || results.success?.messageCode == "CIAM 113") {
            this.ref.close("invalid request");
          }
          else {
            this.ref.close("failed");
          }
        },
        error: error => {
          this.ref.close("failed");
        }
      });
  }
}
