import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
    name: 'dateFormatter'
})
export class DateFormatterPipe implements PipeTransform {
    transform(value: unknown, ...args: unknown[]): unknown {
        if (value == undefined) {
            return ('');
        } else {
            return (moment
                .utc(value)
                .local()
                .format('ll LTS'));
        }
    }
}
