import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { SessionStorageService } from 'ngx-webstorage';

import { VERSION } from 'app/app.constants';
import { LANGUAGES } from 'app/core/language/language.constants';
import { AccountService } from 'app/core/auth/account.service';
import { LoginService } from 'app/core/login/login.service';
import { ProfileService } from 'app/layouts/profiles/profile.service';
@Component({
  selector: 'jhi-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  inProduction?: boolean;
  isNavbarCollapsed = true;
  languages = LANGUAGES;
  swaggerEnabled?: boolean;
  version: string;
  userName: string | null = null;
  shortName: string | null = null;
  items: any = [];
  userItems: any = [];
  checked1 = false;
  lightLogo = true;
  webView: boolean = true;
  crossBtn: any = false;
  sublogo: boolean = true;
  public screenWidth: any;
  public screenHeight: any;

  constructor(
    private loginService: LoginService,
    private sessionStorage: SessionStorageService,
    private accountService: AccountService,
    private profileService: ProfileService,
    private router: Router
  ) {
    let result = VERSION.toLowerCase().startsWith('v') ? VERSION : 'v' + VERSION;
    this.version = VERSION ? result : '';
  }

  @HostListener('window:resize', ['$event'])
  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    this.items = [
      {
        label: 'Home',
        visible: true,
        routerLink: '/',
      },
    ];

    this.userItems = [
      {
        label: 'Sign Out',
        command: () => {
          this.logout();
        },
      },
    ];
    localStorage.setItem('theme', 'light');
    this.accountService.identity().subscribe(account => {
      if (account) {
        this.userName = account.firstName + ' ' + account.lastName;
        this.shortName = account.firstName.charAt(0) + account.lastName.charAt(0);
      }
    });
    this.profileService.getProfileInfo().subscribe(profileInfo => {
      this.inProduction = profileInfo.inProduction;
      this.swaggerEnabled = profileInfo.swaggerEnabled;
    });
    this.onResize();
  }

  onResize() {
    if (window.innerWidth <= 960) {
      this.webView = false;

    } else {
      this.webView = true;
    }
  }

  changeLanguage(languageKey: string): void {
    this.sessionStorage.store('locale', languageKey);
  }

  collapseNavbar(): void {
    this.isNavbarCollapsed = true;
  }

  isAuthenticated(): boolean {
    return this.accountService.isAuthenticated();
  }

  login(): void {
    this.loginService.login();
  }

  logout(): void {
    this.collapseNavbar();
    this.loginService.logout();
    this.router.navigate(['']);
  }

  toggleNavbar(): void {
    this.isNavbarCollapsed = !this.isNavbarCollapsed;
  }

  getImageUrl(): string {
    return this.isAuthenticated() ? this.accountService.getImageUrl() : '';
  }
  switchTheme(): void {
    if (this.checked1) {
      const themer = document.getElementById('theme');
      if (themer) {
        themer.classList.add('dark');
        themer.classList.remove('light');
      }
      localStorage.setItem('theme', 'dark');
      this.lightLogo = false;
    } else {
      const themer = document.getElementById('theme');
      if (themer) {
        themer.classList.remove('dark');
        themer.classList.add('light');
      }
      localStorage.setItem('theme', 'light');
      this.lightLogo = true;
    }
  }

  enableCrossBtn() {
    this.crossBtn = true;
  }

  submenucheck() {
    setTimeout(() => {
      const elArr = document.querySelector('.p-slidemenu-wrapper'); // all
      if (!elArr) {
        this.crossBtn = false;
      }
      const submenu = document.querySelector('.p-slidemenu-rootlist .p-active-submenu'); // all
      if (!submenu) {
        this.sublogo = true;
      } else {
        this.sublogo = false;
      }
    }, 150);
  }

  menuclose() {
    this.crossBtn = false;
  }
}
