import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { PagesService } from '../pages.service';
@Component({
  selector: 'jhi-CreateApplication',
  templateUrl: './CreateApplication.component.html',
  styleUrls: ['./CreateApplication.component.scss'],
  providers: [DialogService],
})
export class CreateApplicationComponent implements OnInit {
  CreateApplicationForm = this.fb.group({
    select: [null, []],
    textField1: [null, [Validators.required, Validators.maxLength(255)]],
    textarea: [null, []],
    externalStorage: [null, []],
    saasApplication: [null, []],
    externalStorageType: [null, []],
  });
  pageloadvalues: any;
  selectList: any[] = [];
  nameFlag: boolean = false;
  enabledbtn1: boolean = false;
  checked: boolean = false;
  applicationId: any;
  versionId: any;
  appId: any;
  tenantLogo: File;
  imageFileFlag: boolean;
  default: boolean;
  solImage: boolean;
  solImageFileSize: boolean;
  productPrimaryImage: any;
  formValidFlag: boolean = false;

  imagevalid: boolean;
  imageURL = '';
  checkedSaas: boolean = false;
  SaasEnabled: boolean = false;
  checkedStorage: boolean = false;
  StorageEnabled: boolean = false;
  logo: File;
  video: File;
  videoFileSize: boolean = false;
  productPrimaryVideo: any;
  validVideo: boolean;
  externalFlag: boolean = false;
  placeHolderFlag: boolean = true;
  imagePlacheholder: boolean = true;
  storageList: any[] = [];
  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public pagesService: PagesService) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.CreateApplicationForm.patchValue(JSON.parse(data));
    }
  }

  ngOnInit(): void {
    this.getApplicationVersionList();
    this.storageTypeList();
  }

  onClose($event: UntypedFormGroup): void {
    this.ref.close();
  }

  saasCheck() {
    if (this.checkedSaas) {
      this.SaasEnabled = true;
      this.formValidFlag = true;
    } else {
      this.externalFlag = false
      this.SaasEnabled = false;
      this.checkedStorage = false;
      this.formValidFlag = false;
      this.productPrimaryImage = "";
      this.CreateApplicationForm.controls['externalStorageType'].clearValidators();
      this.CreateApplicationForm.controls['externalStorage'].clearValidators();
      this.CreateApplicationForm.controls['externalStorage'].reset();
      this.CreateApplicationForm.controls['externalStorage'].updateValueAndValidity();
      this.CreateApplicationForm.controls['externalStorageType'].reset();
      this.CreateApplicationForm.controls['externalStorageType'].updateValueAndValidity();
      this.StorageEnabled = false;

    }
  }
  externalStorageCheck() {
    if (this.checkedStorage) {
      this.externalFlag = true;
      this.StorageEnabled = true;
      this.CreateApplicationForm.controls['externalStorageType'].setValidators(Validators.required);
      this.CreateApplicationForm.controls['externalStorageType'].updateValueAndValidity();
    } else {
      this.checkedStorage = false;
      this.externalFlag = false;
      this.StorageEnabled = false;
      this.CreateApplicationForm.controls['externalStorageType'].clearValidators();
      this.CreateApplicationForm.controls['externalStorageType'].reset();
      this.CreateApplicationForm.controls['externalStorageType'].updateValueAndValidity();
    }
  }
  //getting version list for dropdown
  getApplicationVersionList(): void {
    this.pagesService.getHostApplicationVersion().subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.selectList.push(data);
        });
        this.selectList = [...this.selectList];
      }
    });
  }

  storageTypeList(): void {
    this.pagesService.storageTypeList().subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.storageTypeName;
          data.value = element.id;
          this.storageList.push(data);
        });
        this.storageList = [...this.storageList];
      }
    });
  }
  colorChange() {
    document.getElementById('textField1_id')!.style.borderColor = 'grey';
  }


  onSelectSolutionIcon(event: any): void {
    this.solImageFileSize = false;
    this.imagePlacheholder = false;
    this.imagevalid = false;
    this.formValidFlag = false;
    this.logo = new File([event.files[0]], event.files[0].name, {
      type: event.files[0].type
    });
    this.imageFileFlag = true;
    const isvalid = /(\.jpg|\.jpeg|\.png|\.svg)$/i;
    if (isvalid.test(this.logo.name)) {
      this.default = true;
      this.solImage = false;
      this.solImageFileSize = false;
      const regex = /[~#%&*{}:<>?/|'"]/g;
      this.productPrimaryImage = event.currentFiles[0].objectURL;
      let res = regex.test(this.logo.name);
      if (res) {
        this.solImage = true;
      }
      else if (event.currentFiles[0].size > 5000000) {
        this.solImageFileSize = true;
        this.formValidFlag = true;
      }
      else {
        setTimeout(() => {
        }, 1000);

      }
    } else {
      this.imagevalid = true;
      this.formValidFlag = true;
    }
  }


  onSelectVideo(event: any): void {
    this.formValidFlag = false;
    this.videoFileSize = false;
    this.validVideo = false;
    this.video = new File([event.files[0]], event.files[0].name, {
      type: event.files[0].type
    });
    this.imageFileFlag = true;
    const isvalid = /(\.mp4)$/i;
    if (isvalid.test(this.video.name)) {
      this.default = true;
      this.videoFileSize = false;
      const regex = /[~#%&*{}:<>?/|'"]/g;
      this.productPrimaryVideo = event.currentFiles[0].name;
      this.placeHolderFlag = false;
      let res = regex.test(this.video.name);
      if (res) {
        this.solImage = true;
        this.placeHolderFlag = true;
      }
      else if (event.currentFiles[0].size > 50000000) {
        this.videoFileSize = true;
        this.formValidFlag = true;
        this.placeHolderFlag = false;
      }
      else {
        setTimeout(() => {
        }, 1000);
      }
    } else {
      this.validVideo = true;
      this.formValidFlag = true;
      this.placeHolderFlag = false;
    }
  }

  onRemoveIcon(event: any, fileUploadPageIconChooseFilebtn): void {
    const index = fileUploadPageIconChooseFilebtn.files.indexOf(
      event
    );
    fileUploadPageIconChooseFilebtn.files.splice(index, 1);
    this.productPrimaryImage = "";
    this.imagevalid = false;
    this.formValidFlag = true;
    this.solImageFileSize = false;
    setTimeout(() => {
    }, 1000);
  }

  onRemoveVideo(event: any, fileUploadPageIconChooseFilebtn1): void {
    const index = fileUploadPageIconChooseFilebtn1.files.indexOf(
      event
    );
    fileUploadPageIconChooseFilebtn1.files.splice(index, 1);
    this.productPrimaryVideo = "";
    this.validVideo = false;
    this.formValidFlag = false;
    this.videoFileSize = false;
    this.placeHolderFlag = true;
    setTimeout(() => {
    }, 1000);
  }

  //Check Availability of Application Name
  Application($event: UntypedFormGroup) {
    this.hostAppNameCheckAvailbility($event);
  }

  // create application API
  CreateApplication(): void {
    const reqBody = {};
    const formdata = new FormData();
    formdata.append('applicationName', this.CreateApplicationForm.value.textField1);
    if (this.CreateApplicationForm.value.select != null) {
      formdata.append('applicationVersionId', this.CreateApplicationForm.value.select);
    }
    if (this.CreateApplicationForm.value.textarea != null) {
      formdata.append('description', this.CreateApplicationForm.value.textarea);
    }

    formdata.append('saasApplication', JSON.stringify(this.checkedSaas));
    formdata.append('externalStorageRequired', JSON.stringify(this.checkedStorage));

    if (this.checkedSaas) {
      formdata.append('logoFile', this.logo);
      if (this.video != undefined) {
        formdata.append('videoFile', this.video);
      }
      if (this.checkedStorage) {
        formdata.append('storageTypeId', this.CreateApplicationForm.value.externalStorageType);
      }
    }
    this.pagesService.onSaveCreateHostApplication(formdata).subscribe(
      {
        next: results => {
          this.ref.close(" Application Created Successfully");
        },
        error: error => {
          if (error.error.error?.messageCode == "CIAM 105") {
            this.ref.close("Application already exist");
          }
          else {
            this.ref.close("Application Creation Failed");
          }
        }
      });
  }
  hostAppNameCheckAvailbility($event: UntypedFormGroup): void {

    this.appId = this.CreateApplicationForm.value?.textField1;

    if (this.CreateApplicationForm.value.select == null) {
      const reqBodyObj = { applicationName: this.appId };

      this.pagesService.checkHostApplicationNameAvailability(reqBodyObj).subscribe(
        results => {
          if (results?.error?.code == 400 || results?.success?.code == 400) {
            this.nameFlag = true;
            document.getElementById('textField1_id')!.style.borderColor = 'red';
          } else if (results.success.code == 200) {
            this.nameFlag = false;
            document.getElementById('textField1_id')!.style.borderColor = 'grey';
            this.CreateApplication();
            this.enabledbtn1 = true;
          }
        },
      );
    }
    else {
      this.versionId = this.CreateApplicationForm.value.select;
      const reqBodyObj = { applicationName: this.appId, versionId: this.versionId };

      this.pagesService.checkHostApplicationNameAvailability(reqBodyObj).subscribe(
        results => {
          if (results?.error?.code == 400 || results?.success?.code == 400) {
            this.nameFlag = true;
            document.getElementById('textField1_id')!.style.borderColor = 'red';
          } else if (results.success.code == 200) {
            this.nameFlag = false;
            document.getElementById('textField1_id')!.style.borderColor = 'grey';
            this.CreateApplication();
            this.enabledbtn1 = true;
          }
        },
      );
    }
  }

}