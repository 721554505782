import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { PagesService } from '../pages.service';
import { Fulkrum_Workspace_URL } from 'app/app.constants';
import { MessageService } from 'primeng/api';
import { EMAIL_REGEXP } from '../../constants';

@Component({
  selector: 'jhi-EditTenant',
  templateUrl: './EditTenant.component.html',
  styleUrls: ['./EditTenant.component.scss'],
  providers: [DialogService, MessageService],
})
export class EditTenantComponent implements OnInit {
  EditTenantForm = this.fb.group({
    tenantName: [null, []],
    domainName: [null, []],
    newSolutionPageIconChooseFilebtn: [null, []],
  });
  EditTenantOwnerForm = this.fb.group({

    firstName: [null, [Validators.required]],
    middleName: ['', []],
    lastName: [null, [Validators.required]],
    emailAddress: [null, [Validators.required,Validators.pattern(EMAIL_REGEXP.source)]],
    phoneNumbers: [null, []],

  });
  pageloadvalues: any;
  nameFlag: boolean = false;
  defaultFlag: boolean = true;
  form: UntypedFormGroup | undefined;
  enabledbtn1: boolean = true;
  activeIndex1: number = 0;
  enablebtn1: boolean = true;
  clicked1 = false;
  clicked = true;
  tab1 = false;
  tab2 = false;
  activeIndex2: number = 0;
  uploadedFiles: any[] = [];
  tenantname: any;
  domain: string;
  productPrimaryImage: any;
  tenantLogo: File;
  solImage: boolean;
  formValidFlag: boolean;
  editTenantName: any;
  editUserId: any;
  editEmail: any;
  editTenantDomain: any;
  oldFirstName: any
  oldMiddleName: any;
  oldLastName: any;
  fullNameFlag = false;
  editTenantId: any;
  oldPhoneNumbers: any;
  imageURL: any;
  BASE_URL: string = Fulkrum_Workspace_URL;
  changeFlag = true;
  noOwner = true;
  oldEmail: any;
  editChangeFlag = true;
  isValidEmail = false;
  closeFlag: boolean;
  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public pagesService: PagesService,
    private messageService: MessageService) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.EditTenantForm.patchValue(JSON.parse(data));
    }

  }

  ngOnInit(): void {
    this.editTenantName = this.config?.data?.editTenantName;
    this.editUserId = this.config?.data?.editUserId;
    this.editEmail = this.config?.data?.editEmail;
    this.editTenantDomain = this.config?.data?.editTenantDomain;
    this.editTenantId = this.config?.data?.editTenantId;
    this.onEditOwnerPageLoad();
    this.getTenantDetails();
    this.EditTenantForm.get('tenantName').disable();
    this.EditTenantForm.get('domainName').disable();
  }

  getTenantDetails() {
    this.pagesService.onTenantByTenantId(this.editTenantId).subscribe((results: any) => {
      this.tenantLogo = results.logo;
      this.imageURL = results.logo;
    });
  }

  onClose($event: UntypedFormGroup): void {
    this.ref.close();
  }

  onCcprimebutton1($event: UntypedFormGroup): void {
    if (this.closeFlag)
      this.ref.close("Tenant Details Saved Successfully");
    else
      this.ref.close();
  }

  domainname() {
    this.tenantname = this.EditTenantForm.value.tenantName;
    this.domain = this.tenantname + '.ciam.com';
  }

  onEditOwnerPageLoad() {
    this.pagesService.onEditUserPageLoad(this.editUserId).subscribe((results: any) => {
      let pagePropBind = {
        firstName: results.success.result.first_name,
        middleName: results.success.result.middle_name,
        lastName: results.success.result.last_name,
        emailAddress: results.success.result.emails[0]?.value,
        phoneNumbers: results.success.result.phoneNumbers,
      };
      this.oldFirstName = pagePropBind.firstName;
      this.oldMiddleName = pagePropBind.middleName;
      this.oldLastName = pagePropBind.lastName;
      this.oldPhoneNumbers = pagePropBind.phoneNumbers;
      this.oldEmail = pagePropBind.emailAddress
      this.pageloadvalues = pagePropBind;
      this.EditTenantForm.patchValue(pagePropBind);
      this.EditTenantOwnerForm.patchValue(pagePropBind);


    });
  }

  onSaveUser($event: UntypedFormGroup): void {
    if (this.oldEmail == this.EditTenantOwnerForm.value.emailAddress) {
      this.onEditUser($event);
    }
    else {
      this.onEditTenantOwnerDetails();
    }
  }

  valid() {
    if (this.oldFirstName != this.EditTenantOwnerForm.value.firstName) {
      this.enablebtn1 = false;
    }
    else if (this.oldMiddleName != this.EditTenantOwnerForm.value.middleName) {
      this.enablebtn1 = false;
    }
    else if (this.oldLastName != this.EditTenantOwnerForm.value.lastName) {
      this.enablebtn1 = false;
    }
    else if (this.oldEmail != this.EditTenantOwnerForm.value.emailAddress) {
      this.enablebtn1 = false;
    }
    else {
      this.enablebtn1 = true;
    }
  }

  onEditUser($event: UntypedFormGroup): void {
    const reqBody = {};
    reqBody['tenantId'] = this.editTenantId;
    reqBody['id'] = this.editUserId;
    reqBody['phoneNumbers'] = this.oldPhoneNumbers;
    if (this.oldFirstName != this.EditTenantOwnerForm.value.firstName) {
      this.fullNameFlag = true;
      reqBody['first_name'] = this.EditTenantOwnerForm.value.firstName;
    }
    if (this.oldMiddleName != this.EditTenantOwnerForm.value.middleName) {
      this.fullNameFlag = true;
      if (this.EditTenantOwnerForm.value.middleName == null) {
        reqBody['middle_name'] = " ";
        this.EditTenantOwnerForm.value.middleName = " ";
      }
      else {
        reqBody['middle_name'] = this.EditTenantOwnerForm.value.middleName;
      }
    }
    if (this.oldLastName != this.EditTenantOwnerForm.value.lastName) {
      this.fullNameFlag = true;
      reqBody['last_name'] = this.EditTenantOwnerForm.value.lastName;
    }
    if (this.fullNameFlag) {
      const fname = this.EditTenantOwnerForm.value.firstName + ' ' + this.EditTenantOwnerForm.value.middleName + ' ' + this.EditTenantOwnerForm.value.lastName;
      reqBody['full_name'] = fname.replace(/\s+/g, ' ').trim();

    }
    const formData = reqBody;
    this.pagesService.onEditUser(formData).subscribe(
      {
        next: results => {
          if (results?.success?.messageCode == "CIAM 154") {
            this.navigateToMyReq('Edit User request has been sent for approval');
          }
          else {
            this.oldFirstName = results.success.result.first_name;
            this.oldMiddleName = results.success.result.middle_name;
            this.oldLastName = results.success.result.last_name;
            this.ref.close("Tenant Details Saved Successfully");
            this.tab1 = true;
          }
        },
        error: () => {
          this.ref.close("Tenant Edit Failed");
        }
      });
  }

  EditTenant(): void {
    const reqBody = {};
    const formdata = new FormData();
    const formData = formdata;
    formdata.append('logoFile', this.tenantLogo ?? null);
    formdata.append('id', this.editTenantId);
    if (this.imageURL != this.tenantLogo) {
      this.closeFlag = true;
    }
    this.pagesService.onSaveEditTenant(formData).subscribe(
      {
        next: results => {
          this.tab1 = true;
          this.showTab();
          this.pagesService.createTenantSave = true;
        },
        error: error => {
          this.ref.close("Tenant Edit Failed");
        }
      });
  }

  onEditTenantOwnerDetails() {
    const reqBody = {};
    reqBody['firstName'] = this.EditTenantOwnerForm.value.firstName;
    reqBody['middleName'] = this.EditTenantOwnerForm.value.middleName;
    reqBody['lastName'] = this.EditTenantOwnerForm.value.lastName;
    reqBody['email'] = this.EditTenantOwnerForm.value.emailAddress.toLowerCase();
    const formValues = reqBody;
    const data = { tenantId: this.editTenantId, ownerId: this.editUserId };
    this.pagesService.onSaveEditTenantOwnerDetails(formValues, data).subscribe(
      {
        next: results => {
          this.tab2 = true;
          this.ref.close("Tenant Details Saved Successfully");
        },
        error: error => {
          this.ref.close("Tenant Edit Failed");
        }
      });
  }

  onSaveTenantOwnerDetails() {
    const reqBody = {};
    reqBody['firstName'] = this.EditTenantOwnerForm.value.firstName;
    reqBody['middleName'] = this.EditTenantOwnerForm.value.middleName;
    reqBody['lastName'] = this.EditTenantOwnerForm.value.lastName;
    reqBody['email'] = this.EditTenantOwnerForm.value.emailAddress;
    const formValues = reqBody;
    const data = this.editTenantId;
    this.pagesService.onSaveTenantOwnerDetails(formValues, data).subscribe(
      {
        next: results => {
          this.tab2 = true;
          this.ref.close("Tenant Details Saved Successfully");
        },
        error: error => {
          this.ref.close("Tenant Creation Failed");
        }
      });
  }

  showTab() {
    if (this.activeIndex1 === 0 && this.pagesService.pageTenantAdminFlag) {
      this.activeIndex1 = 1;
    }
  }

  onUpload(event) {
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }
  }
  imageFileFlag: boolean = false;
  pdfFileFlag: boolean = false;
  solImageFileSize: boolean = false;
  imagevalid: boolean = false;

  onSelectSolutionIcon(event: any): void {
    this.changeFlag = false;
    this.tenantLogo = new File([event.files[0]], event.files[0].name, {
      type: event.files[0].type,
    });
    this.imageFileFlag = true;
    const isvalid = /(\.jpg|\.jpeg|\.png|\.svg)$/i;
    if (isvalid.test(this.tenantLogo.name)) {
      this.solImage = false;
      this.solImageFileSize = false;
      const regex = /[~#%&*{}:<>?/|'"]/g;
      this.productPrimaryImage = event.currentFiles[0].objectURL;
      let res = regex.test(this.tenantLogo.name);
      if (res) {
        this.solImage = true;
        this.formValidFlag = false;
      }
      else if (event.currentFiles[0].size > 1000000) {
        this.solImageFileSize = true;
        this.formValidFlag = false;
      }
      else {
        setTimeout(() => {
          this.checkValidation();
        }, 1000);
      }
    } else {
      this.imagevalid = true;
    }
  }

  onRemoveIcon(event: any, fileUploadPageIconChooseFilebtn): void {
    this.changeFlag = true;
    const index = fileUploadPageIconChooseFilebtn.files.indexOf(
      event
    );
    fileUploadPageIconChooseFilebtn.files.splice(index, 1);
    this.productPrimaryImage = "";
    this.solImageFileSize = false;

    this.imagevalid = false;
    setTimeout(() => {
      this.checkValidation();
    }, 1000);
  }

  checkValidation() {
    throw new Error('Method not implemented.');
  }

  navigateToMyReq(msg: any): void {
    const navigationExtras: NavigationExtras = {
      state: {
        msg: msg,
      },
    };
    this.router.navigate(['/pages/myrequestslisting'], navigationExtras);
  }
}