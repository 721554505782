import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PagesService } from '../pages.service';
import { MessageService, SortEvent } from 'primeng/api';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { Paginator } from 'primeng/paginator';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'jhi-ManageSubscription',
  templateUrl: './ManageSubscription.component.html',
  styleUrls: ['./ManageSubscription.component.scss'],
  providers: [MessageService, DialogService, DatePipe],
})
export class ManageSubscriptionComponent implements OnInit {
  ref!: DynamicDialogRef;
  ManageSubscriptionForm = this.fb.group({
    textField: [null, []],
    select: [null, [Validators.required]],
    select1: [null, [Validators.required]]
  });

  rows = 10;
  messageFlag = true;
  isVisible = false;
  GroupCount: any;
  UserCount: any;
  pageloadvalues: any;
  ccprimebreadcrumbList = [{ label: `Plans & Subscription` }, { label: `Manage Subscriptions`, routerLink: ['/pages/manageSubscription'] }];
  homeiconccprimebreadcrumb = { icon: 'pi pi-home', routerLink: ['/'] };
  htmlSrc: any;
  searchList: any[] = [];
  tableList: any[] = [];
  tableList1: any[] = [];
  errorFlag: boolean = true;
  emptyFlag: boolean = true;
  tablecols: any[] = [
    { field: 'name', header: 'Name' },
    { field: 'description', header: 'Description' },
    { field: 'users', header: 'Users' },
    { field: 'groups', header: 'Groups' },
    { field: 'policies', header: 'Policies' },
    { field: 'status', header: 'Status' },
  ];
  table: any[] = [];
  selectedIndex: any;
  hoverIndex: any;
  deletemsg: any;
  totalRecords: number = 0;
  selectList: any[];
  pageSize: number = 10; //default pageSize 10
  pageNumber: number = 1;
  searchStr = '';
  selectList1: any[] = [];
  dropdown = true;
  planAttrFlag = true;
  planId = '';
  planName = '';
  oldTotalRec = 0;
  @ViewChild('pp') paginator: Paginator;
  addMsg: any;
  myDate = new Date();
  test: any;
  sortByColumn = '';
  sorting = '';
  activeItemEdit: any;
  customFilterFlag = false;
  filterReqObj = {};
  callApi: boolean = false;
  callApiPaginate: boolean = true;
  constructor(
    public dialogService: DialogService,
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    private messageService: MessageService,
    private datePipe: DatePipe
  ) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    this.addMsg = navigation?.extras?.state?.msg;
    if (data) {
      this.ManageSubscriptionForm.patchValue(JSON.parse(data));
    }

  }
  ngOnInit(): void {
    this.getApplicationVersionList();
    this.ManageSubscriptionForm.get('select1').disable();
    this.htmlSrc = '';
  }
  clearmsg() {
    this.planAttrFlag = true;
  }

  getApplicationVersionList(): void {
    this.selectList = [];
    this.pagesService.getApplicationVersion().subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null
          };
          data.label = element.label;
          data.value = element.value;
          this.selectList.push(data);
        });
        this.selectList = [...this.selectList];
      }
    });
  }

  //get plan dropdown
  getPlanList(): void {
    this.tableList = [];
    this.tableList1 = [];
    this.ManageSubscriptionForm.get('select1').enable();
    this.dropdown = false;
    this.ManageSubscriptionForm.controls['select1'].reset();
    this.selectList1 = [];
    this.emptyFlag = true;
    this.planAttrFlag = true;
    const reqBody = {}
    reqBody['applications'] = this.ManageSubscriptionForm.value.select;
    const formValues = reqBody;
    this.pagesService.getPlanListByApplicationIds(formValues).subscribe(results => {

      const responseData = results?.success?.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            planId: null,
            planName: null
          };
          data.planId = element.value;
          data.planName = element.label;
          this.selectList1.push(data);
        });
        this.selectList1 = [...this.selectList1];
      }
    });
  }
  //get subscription list API
  gettableList(): void {
    this.planAttrFlag = true;
    this.planName = this.ManageSubscriptionForm.value.select1.planName;
    this.planId = this.ManageSubscriptionForm.value.select1.planId;
    this.emptyFlag = false;
    const str = this.ManageSubscriptionForm.value.textField;
    if (str == null) {
      this.searchStr = '';
    }
    else {
      this.searchStr = str;
    }

    let reqBody = {};
    reqBody['planId'] = this.planId;
    reqBody['page'] = 1;
    reqBody['rows'] = this.pageSize;
    reqBody['serchString'] = this.searchStr;
    reqBody['sortByColumn'] = this.sortByColumn;
    reqBody['storting'] = this.sorting;
    const data = reqBody;
    this.pagesService.getSubscriptionList(data).subscribe(results => {
      const responseData = results.success.result.records;
      this.totalRecords = results.success.result.recordsCount;
      this.oldTotalRec = results.success.result.recordsCount;
      responseData.forEach(
        customer => (customer.startDate = new Date(customer.startDate))
      );
      if (responseData && responseData.length > 0) {
        this.tableList = responseData;
        this.tableList1 = responseData;
        this.errorFlag = false;
      } else {
        this.errorFlag = true;
      }
    });
  }

  //search functionality
  SubcriptionFilterMethod(event: any) {
    let filtered: any[] = [];
    let items: any;
    let cc;
    let query = event.target.value;
    this.tableList = [];
    this.tableList1 = [];
    this.paginator.changePage(0);
    let reqBody = {};
    reqBody['planId'] = this.planId;
    reqBody['page'] = 1;
    reqBody['rows'] = this.pageSize;
    reqBody['serchString'] = query;
    const data = reqBody;
    this.pagesService.getSubscriptionList(data).subscribe(results => {
      const responseData = results.success.result.records;
      this.totalRecords = results.success.result.recordsCount;
      this.oldTotalRec = results.success.result.recordsCount;
      this.tableList = responseData;
      this.tableList1 = responseData;
    });
  }


  onCcprimebutton($event: UntypedFormGroup): void { }
  onAddSubscription($event: UntypedFormGroup): void {
    const navigationExtras: NavigationExtras = {
      state: {
        planData: this.planId,
      },
    };
    this.router.navigate(['/pages/addSubscription'], navigationExtras);
  }
  navigateEditSubscription(rowData: any): void {
    const navigationExtras: NavigationExtras = {
      state: {
        data: rowData
      },
    };
    this.router.navigate(['/pages/editSubscription'], navigationExtras);
  }
  navigateToMap(rowData: any): void {
    const navigationExtras: NavigationExtras = {
      state: {
        data: rowData,
        mapUserFlag: true,
      },
    };
    this.router.navigate(['/pages/editSubscription'], navigationExtras);
  }
  highlight(index: any) {
    this.selectedIndex = index;
  }
  resetinviteUserccprimesplitbutton(): void {
    this.ManageSubscriptionForm.reset();
  }

  mouseLeave() {
    this.hoverIndex = null;
    this.selectedIndex = null;
  }
  paginate(event, search) {
    let str = '';
    if (search != null) {
      str = search;
    }
    this.pageSize = event.rows;
    this.pageNumber = event.page + 1;
    let reqBody = {};
    reqBody['planId'] = this.planId;
    reqBody['page'] = this.pageNumber;
    reqBody['rows'] = this.pageSize;
    reqBody['serchString'] = str;
    reqBody['sortByColumn'] = this.sortByColumn;
    reqBody['storting'] = this.sorting;
    const data = reqBody;
    this.pagesService.getSubscriptionList(data).subscribe(results => {
      const responseData = results.success.result.records;
      this.totalRecords = results.success.result.recordsCount;
      this.oldTotalRec = results.success.result.recordsCount;
      if (responseData && responseData.length > 0) {
        this.tableList = responseData;
        this.tableList1 = responseData;
      }
    });
  }

  sortData(event: SortEvent) {
    let fieldName = event.field;
    let orderNum = event.order;
    let orderBy;
    if (orderNum == 1) {
      orderBy = "ascending";
    }
    else {
      orderBy = "descending";
    }
    let str = '';
    const search = this.ManageSubscriptionForm.value.textField;
    if (search != null) {
      str = search;
    }

    if (fieldName == 'subscriptionName') {
      fieldName = 'name';
    }
    if (fieldName == 'subsStartDate') {
      fieldName = 'start';
    }
    else if (fieldName == 'subsEndDate') {
      fieldName = 'end';
    }
    else if (fieldName == 'subStatus') {
      fieldName = 'status';
    }
    else if (fieldName == 'accName') {
      fieldName = 'account';
    }
    if (this.sortByColumn != fieldName || this.sorting != orderBy) {
      this.sortByColumn = fieldName;
      this.sorting = orderBy;
      let reqBody = {};
      reqBody['planId'] = this.planId;
      reqBody['page'] = 1;
      reqBody['rows'] = this.pageSize;
      reqBody['serchString'] = str;
      reqBody['sortByColumn'] = fieldName;
      reqBody['storting'] = orderBy;
      const data = reqBody;
      this.pagesService.getSubscriptionList(data).subscribe(results => {
        const responseData = results.success.result.records;
        this.totalRecords = results.success.result.recordsCount;
        this.oldTotalRec = results.success.result.recordsCount;
        this.tableList = responseData;
        this.customSort(event);
      });
    }


  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else {
        let res = (value1 > value2) ? 1 : 0;
        result = (value1 < value2) ? -1 : res;
      }


      return (event.order * result);
    });
  }
  columnFilter(event: any) {
    let oldFilter = event.filters;
    let newFilter = event.filters;
    let filterEvent = [...Object.entries(newFilter)];
    let filterBody = [];
    let finalObj = {};
    let cnt = 0;
    filterEvent.forEach((element: any = {}) => {
      let filterValue = element[1];
      filterBody = [];
      if (filterValue.length > 1) {
        if (filterValue[1]?.value == '') {
          filterValue[1].value = null;
        }
      }
      if (filterValue[0]?.value == '') {
        filterValue[0].value = null;
      }
      if (filterValue[0]?.value != null) {
        filterBody.push(filterValue[0]);
        if (element[0] == 'accName') {
          element[0] = 'accountName';
        }
        if (element[0] == 'subStatus') {
          element[0] = 'status';
        }
        if (filterValue[0].matchMode.includes('notEquals') && element[0] == 'subscriptionName') {
          this.customFilterFlag = true;
        }
        if (filterValue[0].matchMode.includes('date')) {
          filterValue[0].value = this.datePipe.transform(filterValue[0].value, 'yyyy-MM-dd 00:00:00');
        }
      }
      if (filterValue[1]?.value != null) {
        if (filterValue[1].matchMode.includes('date')) {
          filterValue[1].value = this.datePipe.transform(filterValue[1].value, 'yyyy-MM-dd 00:00:00');
        }
        filterBody.push(filterValue[1]);
      }
      if (filterBody.length > 0) {
        finalObj[element[0]] = filterBody;
      }
    });
    let compareObj = JSON.stringify(this.filterReqObj) === JSON.stringify(finalObj);

    let str = '';
    const search = this.ManageSubscriptionForm.value.textField;
    if (search != null) {
      str = search;
    }
    let reqBody = {};
    if (!compareObj) {
      reqBody['planId'] = this.planId;
      reqBody['page'] = 1;
      reqBody['rows'] = this.pageSize;
      reqBody['serchString'] = str;
      reqBody['filters'] = finalObj;
      reqBody['sortByColumn'] = this.sortByColumn;
      reqBody['storting'] = this.sorting;
    }
    else {
      reqBody['planId'] = this.planId;
      reqBody['page'] = this.pageNumber;
      reqBody['rows'] = this.pageSize;
      reqBody['serchString'] = str;
      reqBody['filters'] = finalObj;
      reqBody['sortByColumn'] = this.sortByColumn;
      reqBody['storting'] = this.sorting;
    }

    const data = reqBody;
    if (Object.keys(finalObj).length > 0) {
      this.filterReqObj = finalObj;
      this.pagesService.getSubscriptionList(data).subscribe(results => {
        this.oldTotalRec = this.totalRecords;
        const responseData = results.success.result.records;
        responseData.forEach(
          customer => (customer.startDate = new Date(customer.startDate))
        );
        this.totalRecords = results.success.result.recordsCount;
        event.filteredValue.splice(0, event.filteredValue.length);
        if (responseData && responseData.length > 0) {
          responseData.forEach(element => {
            element.startDate = new Date(element.startDate);
            event.filteredValue.push(element);
          });
        } else {
          responseData.forEach(element => {
            event.filteredValue.push(element);
          });
        }
        filterEvent.forEach((element: any = {}) => {
          let filterValue = element[1];
          if (filterValue[0]?.value != null) {
            if (filterValue[0].matchMode.includes('date')) {
              filterValue[0].value = new Date(filterValue[0].value);
            }
          }
          if (filterValue[1]?.value != null) {
            if (filterValue[1].matchMode.includes('date')) {

              filterValue[1].value = new Date(filterValue[1].value);
            }
            filterBody.push(filterValue[1]);
          }
        });
      });
      event.filters = oldFilter;
    }
    else {
      this.totalRecords = this.oldTotalRec;
      if (this.customFilterFlag) {
        this.customFilterFlag = false;
        reqBody['planId'] = this.planId;
        reqBody['page'] = 1;
        reqBody['rows'] = this.pageSize;
        reqBody['serchString'] = str;
        const data1 = reqBody;
        this.pagesService.getSubscriptionList(data1).subscribe(results => {
          this.oldTotalRec = this.totalRecords;
          const responseData = results.success.result.records;
          responseData.forEach(
            customer => (customer.startDate = new Date(customer.startDate))
          );
          this.totalRecords = results.success.result.recordsCount;
          event.filteredValue.splice(0, event.filteredValue.length);
          if (responseData && responseData.length > 0) {
            responseData.forEach(element => {
              element.startDate = new Date(element.startDate);
              event.filteredValue.push(element);
            });
          } else {
            responseData.forEach(element => {
              event.filteredValue.push(element);
            });
          }
          filterEvent.forEach((element: any = {}) => {
            let filterValue = element[1];
            if (filterValue[0]?.value != null) {
              if (filterValue[0].matchMode.includes('date')) {
                filterValue[0].value = new Date(filterValue[0].value);
              }
            }
            if (filterValue[1]?.value != null) {
              if (filterValue[1].matchMode.includes('date')) {

                filterValue[1].value = new Date(filterValue[1].value);
              }
              filterBody.push(filterValue[1]);
            }
          });
        });
      }
    }

  }
  getPlanAttributeValueList(): void {
    const reqBody: any = {};
    this.planId = this.ManageSubscriptionForm.value.select1.planId;
    reqBody["planid"] = this.planId;
    const formData = reqBody;
    this.pagesService.getPlanAttributeValue(formData).subscribe(results => {
      const responseData = results.success.result;
      if (responseData.length > 0) {
        this.planAttrFlag = true;
        this.onAddSubscription(this.ManageSubscriptionForm);
      }
      else {
        this.planAttrFlag = false;
      }
    });
  }
}
