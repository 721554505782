import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { DatePipe } from '@angular/common';
import { EntitlementDetailsService } from './EntitlementsDetails.service';
import { assetUrl } from 'src/single-spa/asset-url';
import { ACTIVE, ALLOW, BANK, DENY, ENTITLEMENTSTATUS, GLOBAL, IMPORT, INACTIVE, INTERNAL, ONE, PENDING, ROLE, TEN, USER } from 'app/constants';
import { RoleDetailsService } from '../ManageRolesDetails/ManageRolesDetails.service';
@Component({
  selector: 'jhi-entitlementsdetails',
  templateUrl: './EntitlementsDetails.component.html',
  styleUrls: ['./EntitlementsDetails.component.scss'],
  providers: [MessageService, ConfirmationService, DialogService, DatePipe],
})
export class EntitlementsDetailsComponent implements OnInit {

  ccprimebreadcrumbList = [];
  homeiconccprimebreadcrumb = { icon: 'pi pi-home', routerLink: ['/'] };

  disableButtons: any;
  filterMessage: any;
  entitlement: any;
  successMessage: string = '';
  failureMessage: string = '';
  isSuccess = false;
  isFailure = false;
  entitlementList = [];
  selectedEntitlement: any;
  roleColumns: any[] = [];
  userColumns: any[] = [];
  roleList: any[] = [];
  usersList: any[] = [];
  entitlementStatusDropdownList: any[] = [];
  entitlementStatusResponse: any = [];
  bankServiceLineList: any = [];
  bankDropdownList: any[] = [];
  confirmMessage: string;
  isConfirmDialog: boolean = false;
  isImportConfirmDialog: boolean = false;
  actionTable: string;
  unSavedChanges: boolean = false;
  originalRoleList: any[] = [];
  updateRoleId: any;
  selectedRowUserId: any;
  selectedRowBankId: any;
  selectedRowServiceLineId: any;
  selectedEntitlementToImport: any;
  changeEntitlementDropdown: any = [];
  importEntitlementList: any[] = [];
  lastModifiedDate: string;
  timeStamp: boolean = false;
  onTabChange: boolean = false;
  navigationMessage: string;
  nextRouteInfo: any;
  originalUsersList: any[] = [];
  isRoleFilterSelection: boolean = false;
  isUserFilterSelection: boolean = false;
  selectedRoleFilter: any = [];
  selectedEntilementStatusFilter: any = [];
  selectedUserFilter: any = [];
  selectedBankFilter: any = [];
  selectedserviceLineFilter: any = [];
  appliedRoleFilter: any = [];
  appliedEntilementStatusFilter: any = [];
  appliedUserFilter: any = [];
  appliedUserBankFilter: any = [];
  appliedUserServiceFilter: any = [];
  appliedUserEntitlementStatusFilter: any = [];
  roleDropdown: any = [];
  entitlementStatusDropdown: any = [];
  userDropdown: any = [];
  bankDropdown: any = [];
  serviceLineDropdown: any = [];
  userEntitlementStatusDropdown: any = [];
  roleFilterSidebarCount: number = 0;
  userFilterSidebarCount: number = 0;
  userBankList: { bankId: number; bankName: string }[];
  selectedServiceFilter: any = [];
  globalFilterSidebarCount: number = 0;
  selectedGlobalRoleFilter: any = [];
  selectedGlobalUserFilter: any = [];
  appliedGlobalRoleFilter: any = [];
  appliedGlobalUserFilter: any = [];
  usersBankServiceLineList: any[] = [];
  currententitlement: any = [];
  isGlobalFilterSelection: boolean = false;
  activeGlobalTab: any = {
    isGlobalUserTab: false,
    isGlobalRoleTab: false
  };

  roleFilterTab: any = {
    isRoleTab: false,
    isEntitlementStatusTab: false
  };

  userFilterTab: any = {
    isUserTab: false,
    isBankTab: false,
    isServiceLineTab: false,
    isEntitlementStatusTab: false
  };
  isAddNewUser: boolean = false;
  selectedUser: any[];
  isUserDropdown: boolean;
  isUserExist: boolean;
  previousEntitlement: any;
  isEntitlementChanged: boolean = false;
  roleListForEnt: any = [];
  userListForEnt: any = [];
  addUserDropdown: any = [];
  existinguserIds: any = [];
  originalUserList: any = [];
  selectedEntUserData: any;
  entitlementName: string;
  rowIndexForUserDeleteOfEnt: any;
  isDeleteEntUserDialog: boolean;
  userName: any;
  isSaveAndApplyBtnDisabled: boolean = true;
  entitlementId: any;
  componentId: any;
  userId: any;
  deletedUsers: any = [];
  userdeleted: any;
  isAddNewRole: boolean = false;
  selectedRole: any;
  existngRoleIds: any = [];
  entitlementRole: any;
  roleEntList: any[] = [];
  roleStatusList: any = [];
  selectedUserRecords: any[] = [];
  isMultipleDeleteEntUserDialog: boolean;
  orignalRoleListForEnt: any = [];
  search: any;
  searchArray: any[] = [];
  roleName: any;
  roleId: any;
  roledeleted: any = [];
  isDeleteEntRoleDialog: boolean;
  deletedRoles: any = [];
  roleDropdownList: any = [];
  selectedRolesRecords: any = [];
  isMultipleDeleteEntRoleDialog: boolean = false;
  selectedUserStatus: any = [];
  appliedStatusFilter: any = [];
  preFilledUserStatus: any[] = [];
  preFilledEntitlementStatus: any[] = [];
  activeUserTab: any = {
    isStatusTab: false,
    isEntitlementStatusTab: false
  };
  showUserFilter: boolean = false;
  approvalStatusData: any = [];
  usersGridList: any[] = [];
  userStatusFilterDropdown: any = [];
  entStatusFilterDropdown: any = [];
  messageFlag = true;
  todayy = new Date();
  transform(value: string): string {
    return assetUrl(value);
  }

  assetUrl = assetUrl;
  changeEntitlementFlag: boolean = false;
  isSaveAndApplySuccess = false;
  deleteEntList: any = [];
  addRoleImpactUsersList : any =[];
  rmRoleImpactUsersList: any =[];


  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    public dialogService: DialogService,
    public datepipe: DatePipe,
    private entitlementDetailsService: EntitlementDetailsService,
    private roleDetailsService: RoleDetailsService,

  ) {
    if (this.router.getCurrentNavigation()?.extras?.state) {
      this.entitlement = this.router.getCurrentNavigation()?.extras?.state?.entitlement;
    }
  }
  ngOnInit(): void {

    this.userColumns = [{ field: 'USER_NAME', header: 'User Name', filterMatchMode: 'contains', display: true },
    { field: 'USER_STATUS', header: 'User Status', filterMatchMode: 'contains', display: true },
    { field: 'ENT_STATUS', header: 'Approval Status', filterMatchMode: 'contains', display: true },
    { field: 'UPDATED_ON', header: 'Updated On', filterMatchMode: 'contains', display: true }];
    this.roleColumns = [
      { field: 'ROLE_NAME', header: 'Role Name', filterMatchMode: 'contains', display: true },
      { field: 'ROLE_STATUS', header: 'Role Status', filterMatchMode: 'contains', display: true },
      { field: 'UPDATED_ON', header: 'Updated On', filterMatchMode: 'contains', display: true }
    ];
    this.refreshEntitlementDetails(null);
  }

  navigateFirstName(rowData: any): void {
    const navigationExtras: NavigationExtras = {
      state: {
        routeFlag: true,
        data: rowData,
      },
    };
    this.router.navigate(['/pages/viewuser'], navigationExtras);
  }

  showSuccessValidations(message: string) {
    this.messageFlag = true;
    this.messageService.add({ key: 'myKey1', severity: 'success', detail: message });
    setTimeout(() => {
      this.messageFlag = false;
    }, 3000);

  }

  showErrorValidations(message: string) {
    this.messageFlag = true;
    this.messageService.add({ key: 'myKey1', severity: 'error', detail: message });
    setTimeout(() => {
      this.messageFlag = false;
    }, 3000);

  }

  refreshEntitlementDetails(varEntitlement) {
    this.selectedUser = [];
    this.selectedRole = [];
    this.originalUserList = [];
    this.selectedUserRecords = [];
    this.selectedRolesRecords = [];
    this.existinguserIds = [];
    this.isSaveAndApplyBtnDisabled = true;
    this.deletedUsers = [];
    this.deletedRoles = [];
    this.isEntitlementChanged = false;
    this.deleteEntList =[];
    if (varEntitlement != null) {
      this.entitlement = varEntitlement;
    }
    this.ccprimebreadcrumbList = [{ label: `Authorization` }, { label: `Manage Entitlements`, routerLink: ['/pages/manageentitlements'] }, { label: this.entitlement.ENTITLEMENT_NAME }];
    this.getEntitlementsForRole();
    this.getEntitlementList();
    this.getEntitlementUserList();
    if (this.isSaveAndApplySuccess) {
      this.getEntitlementList();
    }
    else {
      this.getUserByEntitlement();
    }
    this.getRoleList();
    this.clearEntUserFilters();
    this.addRoleImpactUsersList=[];
    this.rmRoleImpactUsersList=[];
  }

  getBankList() {
    this.entitlementDetailsService.getBankList().subscribe({
      next:
        response => {
          this.bankServiceLineList = response;
          let bankDropdownList = [];
          this.bankServiceLineList.forEach(element => {
            if (bankDropdownList.filter(b => b.bankId == element.bankId).length == 0) {
              bankDropdownList.push({
                bankId: element.bankId,
                bankName: element.bankName
              });
            }
          });
          this.bankDropdownList = bankDropdownList;
          this.getEntitlementDetails();
          this.getEntitlementStatusList();
        },
      error: error => {
        this.showErrorValidations(error.error);
      }
    });
  }

  getEntitlementDetails() {
    const formData: FormData = new FormData();
    this.usersList = [];
    this.originalUsersList = [];
    this.usersGridList = [];
    formData.append('id', this.entitlement.ID.toString());
    formData.append('componentId', this.entitlement.COMPONENT_ID.toString());
    formData.append('entitlementId', this.entitlement.ENTITLEMENT_ID.toString());

    this.entitlementDetailsService.getEntitlementDetails(formData).subscribe(response => {
      this.roleList = response['ENTITLEMENT_ROLES'];
      this.originalRoleList = this.roleList;

    });
  }

  getEntitlementStatusList() {
    this.entitlementStatusDropdownList = [];
    this.entitlementDetailsService.getEntitlementStatusList().subscribe(
      {
        next: response => {
          let statusRes = response;
          this.entitlementStatusResponse = statusRes;
          for (let i = 0; i < 2; i++) {
            let res = statusRes[i];
            this.entitlementStatusDropdownList.push({ label: res.NAME, value: res.ID });
          }
        },
        error: err => {
          this.showErrorValidations(err.error);
        }
      });
  }

  openStatusUpdateModal(section, updateId, user) {
    this.confirmMessage = '';
    this.isConfirmDialog = true;
    this.actionTable = section;
    if (section == ROLE) {
      this.confirmMessage = 'Do you want to delete the role for selected entitlement?';
      this.updateRoleId = updateId;
    } else if (section == USER) {
      this.confirmMessage = 'Do you want to delete the user for selected entitlement?';
      this.selectedRowUserId = updateId;
      this.selectedRowBankId = user.BANK_ID;
      this.selectedRowServiceLineId = user.SERVICE_LINE_ID;
    } else if (section == IMPORT) {
      this.confirmMessage = 'Existing entitlement data will be deleted before the new data imported. Please confirm.';
    }
  }

  onActionConfirmationClick() {
    this.isConfirmDialog = false;
    this.isImportConfirmDialog = false;
    this.unSavedChanges = true;
    if (this.actionTable == ROLE) {
      this.unSavedChanges = true;
      this.roleList = this.roleList.filter((b, i) => b.ROLE_ID != this.updateRoleId);
      this.originalRoleList = this.originalRoleList.filter(b => b.ROLE_ID != this.updateRoleId);
      if (this.roleFilterSidebarCount > 0) {
        this.loadRoleFilterData(true);
      }
    } else if (this.actionTable == USER) {
      this.unSavedChanges = true;
      this.usersList = this.usersList.filter(
        i =>
          !(
            i.USER_ID == this.selectedRowUserId &&
            i.BANK_ID == this.selectedRowBankId &&
            i.SERVICE_LINE_ID == this.selectedRowServiceLineId
          )
      );
      this.originalUsersList = this.originalUsersList.filter(
        i =>
          !(
            i.USER_ID == this.selectedRowUserId &&
            i.BANK_ID == this.selectedRowBankId &&
            i.SERVICE_LINE_ID == this.selectedRowServiceLineId
          )
      );
      if (this.userFilterSidebarCount > 0) {
        this.loadUserFilterData(true, true, true);
      }
    } else if (this.actionTable == IMPORT) {
      this.importEntitlementData();
    }
  }

  getEntitlementList() {
    let filter = {};

    filter['SORT_BY'] = 'ENTITLEMENT_NAME';

    this.entitlementDetailsService.getEntitlementList(filter).subscribe(
      {
        next: resource => {
          this.entitlementList = resource['RESULT'];
          this.entitlementList.forEach(el => {
            let entitlementName = [];
            entitlementName.push(el.ENTITLEMENT_NAME);
            entitlementName.push(el.COMPONENT_ID);
            entitlementName.push(el.ENTITLEMENT_ID);

            let obj = el;
            obj['NAME'] = entitlementName.join('-');
          });
          this.currententitlement = this.entitlementList.filter(u => u.ID == this.entitlement.ID)[0];
          let lastDate = this.currententitlement.LAST_MODIFIED_DATE;
          this.lastModifiedDate = lastDate;
          this.entitlement = this.currententitlement;
          this.selectedEntitlement = this.currententitlement;
        },
        error: error => {
          this.showErrorValidations(error.error);
        }
      });
  }
  importEntitlementData() {
    let entitlementDetails = {
      ID: this.entitlement.ID,
      COMPONENT_ID: this.entitlement.COMPONENT_ID,
      ENTITLEMENT_ID: this.entitlement.ENTITLEMENT_ID,
      IMPORT_COMPONENT_ID: this.selectedEntitlementToImport.COMPONENT_ID,
      IMPORT_ENTITLEMENT_ID: this.selectedEntitlementToImport.ENTITLEMENT_ID,
      IMPORT_ID: this.selectedEntitlementToImport.ID
    };
    this.messageFlag = true;
    this.entitlementDetailsService.importEntitlementData(entitlementDetails).subscribe(
      {
        next: response => {
          this.messageFlag = true;
          this.refreshEntitlementDetails(this.entitlement);
          this.selectedEntitlementToImport = {};
          this.showSuccessValidations('Entitlement data imported successfully.');
        },
        error: error => {
          this.messageFlag = true;
          this.showErrorValidations(error.error);
        }
      });
  }

  checkEntitlementData() {
    if (this.selectedEntitlementToImport) {
      const formData: FormData = new FormData();
      formData.append('id', this.entitlement.ID.toString());
      formData.append('componentId', this.entitlement.COMPONENT_ID.toString());
      formData.append('entitlementId', this.entitlement.ENTITLEMENT_ID.toString());

      this.entitlementDetailsService.checkEntitlementData(formData).subscribe(
        {
          next: response => {
            if (response == 'true') {
              this.openImportModal(IMPORT);
            } else {
              this.importEntitlementData();
            }
          },
          error: error => {
            this.showErrorValidations(error.error);
          }
        });
    }
  }

  cancelImport(event, element) {
    element.hide(event);
    this.selectedEntitlementToImport = '';
  }

  openImportModal(section) {
    this.confirmMessage = '';
    this.isImportConfirmDialog = true;
    this.actionTable = section;
    this.confirmMessage =
      'The data for the existing entitlement will be replaced by the new data based on the entitlement selected. Please confirm.';
  }

  loadImportEntitlement() {
    this.importEntitlementList = this.entitlementList.filter(ent => ent.ID !== this.entitlement.ID);
  }

  navigateToEntitlementListingScreen() {
    this.deletedUsers = [];
    this.unSavedChanges = false;
    this.router.navigate(['/pages/manageentitlements'], { skipLocationChange: false });
  }

  cancelConfimDialog() {
    this.isConfirmDialog = false;
    this.isImportConfirmDialog = false;
  }

  async updateEntitlementDetails(isUpdateFlag) {

    const userRolesResultList = this.roleListForEnt
      .filter(x => [0, 1].includes(x.ACTION_TYPE));

    const entUsersList = this.originalUserList
      .filter(x => [0, 1].includes(x.ACTION_TYPE));

    let entitlementDetail = {
      ID: this.entitlement.ID,
      IS_ADMS_ENTITLEMENT: this.entitlement.IS_ADMS_ENTITLEMENT,
      ENTITLEMENT_NAME: this.entitlement.ENTITLEMENT_NAME,
      COMPONENT_ID: this.entitlement.COMPONENT_ID,
      ENTITLEMENT_ID: this.entitlement.ENTITLEMENT_ID,
      ENTITLEMENT_ROLE_LIST: userRolesResultList,
      ENTITLEMENT_USER_LIST: entUsersList,
      DELETE_ENTITLEMENT: this.entitlement,
      DELETED_USERS: this.deletedUsers,
      DELETED_ROLES: this.deletedRoles,
      DELETE_ROLE_IMPACT : this.deleteEntList,
      ADD_ROLE_IMPACT : this.addRoleImpactUsersList
    };
    this.messageFlag = true;
    this.entitlementDetailsService.updateEntitlementDetails(entitlementDetail).subscribe(
      {
        next: response => {
          this.timeStamp = true;
          this.isSaveAndApplyBtnDisabled = true;
          this.lastModifiedDate = response.toString();
          let count = 0;
          if (this.deletedRoles.length > 0) count++;
          if (this.deletedUsers.length > 0) count++;
          if (userRolesResultList.length > 0) count++;
          if (entUsersList.length > 0) count++;

          if (count > 1) {
            this.messageFlag = true;
            this.showSuccessValidations("Entitlement details updated successfully");
          } else {
            this.messageFlag = true;
            this.showSuccessValidations(this.successMessage);
          }
          this.deletedUsers = [];
          if (isUpdateFlag) {
            this.unSavedChanges = false;
            this.router.navigate(['/pages/entitlementsdetails'], {
              state: { entitlement: this.selectedEntUserData },
              skipLocationChange: false
            });
          }
          this.refreshEntitlementDetails(this.entitlement);
        },
        error: error => {
          this.showErrorValidations(error.error);
        }
      });
  }

  canDeactivate(nextState?: any) {
    if (this.unSavedChanges) {
      this.navigationMessage = 'There is unsaved data in the current page. Please confirm the changes.';
      this.nextRouteInfo = nextState.url;
      this.onTabChange = true;
    }
    return !this.onTabChange;
  }

  onSaveAndApply() {
    this.onTabChange = false;
    this.unSavedChanges = false;
    this.updateEntitlementDetails(false);
    this.router.navigate([this.nextRouteInfo], { state: { role: this.selectedRole }, skipLocationChange: false });
  }

  onDiscardChange() {
    this.onTabChange = false;
    this.unSavedChanges = false;
    this.router.navigate([this.nextRouteInfo], { state: { user: this.selectedEntUserData, role: this.selectedRole }, skipLocationChange: false });
  }

  onCancel() {
    this.onTabChange = false;
    this.entitlementDetailsService.sendMessage(2);
    return false;
  }

  openRoleFilterSideBar() {
    this.isRoleFilterSelection = true;
    if (this.roleFilterSidebarCount == 0) {
      this.loadRoleFilterData(false);
    }
  }

  openUserFilterSideBar() {
    this.showUserFilter = true;
    this.loadEntFilterData();
  }

  sidebarCloseEvent(section) {
    if (section == ROLE) {
      if (
        this.appliedRoleFilter.length != this.selectedRoleFilter.length ||
        this.appliedEntilementStatusFilter.length != this.selectedEntilementStatusFilter.length
      ) {
        this.selectedRoleFilter = this.appliedRoleFilter;
        this.selectedEntilementStatusFilter = this.appliedEntilementStatusFilter;
      }
      if (this.selectedRoleFilter.length == 0) {
        this.roleFilterTab.isRoleTab = false;
      }
      if (this.selectedEntilementStatusFilter.length == 0) {
        this.roleFilterTab.isEntitlementStatusTab = false;
      }
    } else if (section == USER) {
      if (
        this.appliedUserFilter.length != this.selectedUserFilter.length ||
        this.appliedUserEntitlementStatusFilter.length != this.selectedEntilementStatusFilter.length
      ) {
        this.selectedUserFilter = this.appliedUserFilter;
        this.selectedEntilementStatusFilter = this.appliedUserEntitlementStatusFilter;
      }
      if (this.selectedUserFilter.length == 0) {
        this.userFilterTab.isUserTab = false;
      }
      if (this.selectedEntilementStatusFilter.length == 0) {
        this.userFilterTab.isEntitlementStatusTab = false;
      }
    } else if (section == GLOBAL) {
      if (
        this.appliedGlobalUserFilter.length != this.selectedGlobalUserFilter.length ||
        this.appliedGlobalRoleFilter.length != this.selectedGlobalRoleFilter.length
      ) {
        this.selectedGlobalRoleFilter = this.appliedGlobalRoleFilter;
        this.selectedGlobalUserFilter = this.appliedGlobalUserFilter;
      }
      if (this.selectedGlobalRoleFilter.length == 0) {
        this.activeGlobalTab.isGlobalRoleTab = false;
      }
      if (this.selectedGlobalUserFilter.length == 0) {
        this.activeGlobalTab.isGlobalUserTab = false;
      }
    }
  }

  loadUserFilterData(isOnlyUser, isOnlyEntitlementStatus, isApplyFilter) {
    if (isOnlyUser) this.userDropdown = [];
    if (isOnlyEntitlementStatus) this.userEntitlementStatusDropdown = [];

    this.appliedUserFilter = this.selectedUserFilter;
    this.appliedUserEntitlementStatusFilter = this.selectedEntilementStatusFilter;

    this.originalUsersList.forEach(element => {
      if (isOnlyUser) {
        const userParentNode = {
          label: element.USER_NAME + '-' + element.USER_ID,
          data: element,
          children: [],
          selectable: true,
          expanded: true,
          key: element.USER_ID
        };
        if (this.userDropdown.filter(user => user.key == userParentNode.key).length == 0) {
          this.userDropdown.push(userParentNode);
        }
      }

      if (isOnlyEntitlementStatus) {
        const entitlementStatusParentNode = {
          label: element.ENTITLEMENT_STATUS_NAME,
          data: element,
          children: [],
          selectable: true,
          expanded: true,
          key: element.ENTITLEMENT_STATUS_ID
        };

        if (this.userEntitlementStatusDropdown.filter(bank => bank.key == entitlementStatusParentNode.key).length == 0) {
          this.userEntitlementStatusDropdown.push(entitlementStatusParentNode);
        }
      }
    });

    if (isOnlyUser) {
      this.selectedUserFilter = [];
      this.appliedUserFilter.forEach(element => {
        if (this.userDropdown.filter(r => r.key == element.key).length > 0) {
          this.selectedUserFilter.push(element);
        }
      });
    }

    if (isOnlyEntitlementStatus) {
      this.selectedEntilementStatusFilter = [];
      this.appliedUserEntitlementStatusFilter.forEach(element => {
        if (this.userEntitlementStatusDropdown.filter(r => r.key == element.key).length > 0) {
          this.selectedEntilementStatusFilter.push(element);
        }
      });
    }

    if (isApplyFilter) {
      this.applyUserFilter(false);
    }
  }

  loadRoleFilterData(isApplyFilter) {
    this.roleDropdown = [];
    this.entitlementStatusDropdown = [];

    this.appliedRoleFilter = this.selectedRoleFilter;
    this.appliedEntilementStatusFilter = this.selectedEntilementStatusFilter;
    this.originalRoleList.forEach(element => {
      const roleParentNode = {
        label: element.ROLE_ID,
        data: element,
        children: [],
        selectable: true,
        expanded: true,
        key: element.ROLE_ID
      };

      const entitlementStatusParentNode = {
        label: element.ROLE_ENTITLEMENT_STATUS,
        data: element,
        children: [],
        selectable: true,
        expanded: true,
        key: element.ENTITLEMENT_STATUS_ID
      };

      if (this.roleDropdown.filter(c => c.key.toString() == roleParentNode.key.toString()).length == 0) {
        this.roleDropdown.push(roleParentNode);
      }

      if (this.entitlementStatusDropdown.filter(c => c.key.toString() == entitlementStatusParentNode.key.toString()).length == 0) {
        this.entitlementStatusDropdown.push(entitlementStatusParentNode);
      }
    });

    this.selectedRoleFilter = [];
    this.selectedEntilementStatusFilter = [];
    this.appliedRoleFilter.forEach(element => {
      if (this.roleDropdown.filter(r => r.key == element.key).length > 0) {
        this.selectedRoleFilter.push(element);
      }
    });
    this.appliedEntilementStatusFilter.forEach(element => {
      if (this.entitlementStatusDropdown.filter(r => r.key == element.key).length > 0) {
        this.selectedEntilementStatusFilter.push(element);
      }
    });

    if (isApplyFilter) {
      this.applyRoleFilter(false);
    }
  }

  applyRoleFilter(isCloseSidebar): void {
    this.roleFilterSidebarCount++;

    this.appliedRoleFilter = this.selectedRoleFilter;
    this.appliedEntilementStatusFilter = this.selectedEntilementStatusFilter;

    if (this.selectedRoleFilter.length > 0 || this.selectedEntilementStatusFilter.length > 0) {
      this.roleList = this.originalRoleList.filter(b => {
        if (
          this.selectedRoleFilter &&
          this.selectedRoleFilter.length > 0 &&
          !this.selectedRoleFilter.some(bf => bf.key === b.ROLE_ID)
        ) {
          return false;
        }
        if (
          this.selectedEntilementStatusFilter &&
          this.selectedEntilementStatusFilter.length > 0 &&
          !this.selectedEntilementStatusFilter.some(bf => bf.key === b.ENTITLEMENT_STATUS_ID)
        ) {
          return false;
        }
        return true;
      });
    } else {
      this.clearAllRoleFilters();
    }
    this.isRoleFilterSelection = isCloseSidebar;
  }

  applyUserFilter(isCloseSidebar): void {
    this.userFilterSidebarCount++;

    this.appliedUserFilter = this.selectedUserFilter;
    this.appliedUserEntitlementStatusFilter = this.selectedEntilementStatusFilter;

    if (
      this.selectedUserFilter.length > 0 ||
      this.selectedEntilementStatusFilter.length > 0
    ) {
      this.usersList = this.originalUsersList.filter(b => {
        if (
          this.selectedUserFilter &&
          this.selectedUserFilter.length > 0 &&
          !this.selectedUserFilter.some(bf => bf.key === b.USER_ID)
        ) {
          return false;
        }
        if (
          this.selectedEntilementStatusFilter &&
          this.selectedEntilementStatusFilter.length > 0 &&
          !this.selectedEntilementStatusFilter.some(bf => bf.key === b.ENTITLEMENT_STATUS_ID)
        ) {
          return false;
        }
        return true;
      });
    } else {
      this.clearAllUserFilters();
    }
    this.showUserFilter = isCloseSidebar;
  }

  setRoleActiveTab(activeTabName: any) {
    this.roleFilterTab.isRoleTab = false;
    this.roleFilterTab.isEntitlementStatusTab = false;

    if (activeTabName == ROLE) {
      this.roleFilterTab.isRoleTab = true;
    } else if (activeTabName == ENTITLEMENTSTATUS) {
      this.roleFilterTab.isEntitlementStatusTab = true;
    }

    this.openRoleFilterSideBar();
  }

  setUserActiveTab(activeTabName: any) {
    this.userFilterTab.isUserTab = false;
    this.userFilterTab.isEntitlementStatusTab = false;

    if (activeTabName == USER) {
      this.userFilterTab.isUserTab = true;
    }
    else if (activeTabName == ENTITLEMENTSTATUS) {
      this.userFilterTab.isEntitlementStatusTab = true;
    }

    this.openUserFilterSideBar();
  }

  clearAllRoleFilters() {
    this.selectedRoleFilter = [];
    this.selectedEntilementStatusFilter = [];
    this.appliedEntilementStatusFilter = [];
    this.appliedRoleFilter = [];
    this.roleFilterTab = {
      isRoleTab: false,
      isEntitlementStatusTab: false
    };
    this.roleList = this.originalRoleList;
  }

  clearAllUserFilters() {
    this.selectedUserFilter = [];
    this.selectedEntilementStatusFilter = [];
    this.appliedUserFilter = [];
    this.appliedUserEntitlementStatusFilter = [];
    this.userFilterTab = {
      isUserTab: false,
      isEntitlementStatusTab: false
    };
    this.usersList = this.originalUsersList;
  }

  openGlobalFilterSideBar() {
    this.isGlobalFilterSelection = true;
    this.loadUserFilterData(true, false, false);
    this.loadRoleFilterData(false);
  }

  applyGlobalFilter(isClearSectionFilters): void {
    if (isClearSectionFilters) {
      this.clearAllUserFilters();
      this.clearAllRoleFilters();
    }
    this.isGlobalFilterSelection = false;
    this.globalFilterSidebarCount++;
    this.appliedGlobalRoleFilter = this.selectedGlobalRoleFilter;
    this.appliedGlobalUserFilter = this.selectedGlobalUserFilter;

    let anyMatch = false;

    if (this.selectedGlobalRoleFilter.length > 0) {
      this.roleList = this.originalRoleList.filter(c => {
        if (
          this.selectedGlobalRoleFilter &&
          this.selectedGlobalRoleFilter.length > 0 &&
          this.selectedGlobalRoleFilter.some(bf => bf.key === c.ROLE_ID)
        ) {
          return true;
        }
        return false;
      });
      anyMatch = true;
    }

    if (this.selectedGlobalUserFilter.length > 0) {
      this.usersBankServiceLineList = this.usersBankServiceLineList.filter(c => {
        if (
          this.selectedGlobalUserFilter &&
          this.selectedGlobalUserFilter.length > 0 &&
          this.selectedGlobalUserFilter.some(bf => bf.key === c.USER_ID)
        ) {
          return true;
        }
        return false;
      });
      anyMatch = true;
    }

    if (!anyMatch && isClearSectionFilters) {
      this.clearAllGlobalFilters();
    }
  }

  clearAllGlobalFilters() {
    this.bankDropdown = [];
    this.userDropdown = [];
    this.appliedGlobalRoleFilter = [];
    this.appliedGlobalUserFilter = [];
    this.selectedGlobalRoleFilter = [];
    this.selectedGlobalUserFilter = [];

    this.globalFilterSidebarCount = 0;
    this.activeGlobalTab = {
      isGlobalRoleTab: false,
      isGlobalUserTab: false
    };
  }

  setGlobalActiveTab(activeTabName: any) {
    this.activeGlobalTab.isGlobalBankTab = false;
    this.activeGlobalTab.isGlobalRoleTab = false;

    if (activeTabName == BANK) {
      this.activeGlobalTab.isGlobalBankTab = true;
    } else if (activeTabName == ROLE) {
      this.activeGlobalTab.isGlobalRoleTab = true;
    }

    this.openGlobalFilterSideBar();
  }

  getUserByEntitlement() {
    this.usersGridList = [];
    let filter = {};
    this.isSaveAndApplySuccess = false;
    filter['COMPONENT_ID'] = this.entitlement.COMPONENT_ID.toString();
    filter['changeEntitlementFlag'] = this.changeEntitlementFlag.toString();
    filter['ENTITLEMENT_ID'] = this.entitlement.ENTITLEMENT_ID.toString();
    filter['ENTITLEMENT_NAME'] = this.entitlement.ENTITLEMENT_NAME.toString();
    this.entitlementDetailsService.getUserByEntitlement(filter).subscribe({
      next: response => {
        this.usersList = response['USERS_LIST'];
        this.usersGridList = this.usersList;
      },
      error: err => {
        this.showErrorValidations(err.error);
      }
    });
  }
  getEntitlementsForRole() {
    let filter = {};
    filter['COMPONENT_ID'] = this.entitlement.COMPONENT_ID.toString();
    filter['ENTITLEMENT_ID'] = this.entitlement.ENTITLEMENT_ID.toString();
    filter['ENTITLEMENT_NAME'] = this.entitlement.ENTITLEMENT_NAME.toString();
    this.entitlementDetailsService.getEntitlementsForRole(filter).subscribe(
      {
        next: response => {
          this.orignalRoleListForEnt = response['ENTITLEMENT_ROLES'];
          this.roleListForEnt = this.orignalRoleListForEnt;
        },
        error: err => {
          this.showErrorValidations(err.error);
        }
      });
  }

  unSaveChangesPopUp() {
    this.changeEntitlementFlag = true;
    this.isSaveAndApplySuccess = false;
    if (this.unSavedChanges) {
      this.navigationMessage = 'There is unsaved data in the current page. Please confirm the changes.';
      this.previousEntitlement = JSON.parse(JSON.stringify(this.currententitlement));
      this.currententitlement = JSON.parse(JSON.stringify(this.selectedEntitlement));
      this.onTabChange = true;
    } else {
      this.refreshEntitlementDetails(this.selectedEntitlement);
    }
    this.isEntitlementChanged = true;
  }

  navigateToUserDetailScreens(user) {
    user['ID'] = user.USER_ID;
    this.selectedEntUserData = user;
    this.entitlementDetailsService.sendMessage(0);
    this.router.navigateByUrl('/user-details', {
      state: { user: user },
      skipLocationChange: false
    });
  }

  deleteUserEntitlement(user, rowIndex) {
    this.rowIndexForUserDeleteOfEnt = rowIndex;
    this.userName = user.USER_NAME;
    this.userId = user.USER_ID;
    this.userdeleted = user;
    this.entitlementName = this.entitlement.ENTITLEMENT_NAME;
    this.entitlementId = this.entitlement.ENTITLEMENT_ID;
    this.componentId = this.entitlement.COMPONENT_ID;
    this.isDeleteEntUserDialog = true;
  }

  userDeleteconfirmationPopupForEnt() {
    this.unSavedChanges = true;
    if (this.userdeleted.USER_ENT_ID) {
      this.deletedUsers.push(this.userdeleted);
    }
    this.usersList.splice(this.rowIndexForUserDeleteOfEnt, 1);
    this.originalUserList = this.usersList;
    if (this.selectedUserRecords.length > 0) {
      this.selectedUserRecords = this.selectedUserRecords.filter(e => e.USER_ID != this.userdeleted.USER_ID);
    }
    let existingRoleUserIds = this.userListForEnt.map(e => e.USER_ID);
    if (!existingRoleUserIds.includes(this.userdeleted.USER_ID)) {
      let existingUserIds = this.usersList.map(e => e.USER_ID);
      this.userdeleted['NAME_WITH_ID'] = this.userdeleted.USER_NAME + "-" + this.userdeleted.USER_ID; this.userListForEnt.push(this.userdeleted);
      this.addUserDropdown = this.userListForEnt.filter(e => !existingUserIds.includes(e.USER_ID)).slice(0);
    }
    this.isDeleteEntUserDialog = false;
    this.isSaveAndApplyBtnDisabled = false;
    this.successMessage = (this.deletedUsers.length > 1 ? "Users" : "User") + " successfully deleted  from " + "\`" + this.entitlementName + "\`" + " entitlement.";

  }

  addRole() {
    this.isAddNewRole = true;
    this.selectedRole = [];
  }

  getRoleList() {
    let filter = {};
    this.roleDropdown = [];
    this.existngRoleIds = [];
    this.entitlementRole = [];
    this.roleEntList = [];
    let isDefault: boolean = true;
    const formData: FormData = new FormData();
    formData.append('isDefault', isDefault ? '1' : '0');

    this.entitlementDetailsService.getRoleStatusList(formData).subscribe(
      {
        next: response => {
          this.roleStatusList = response;
          filter['SORT_BY'] = 'NAME';
          filter['ROLE_STATUS_ID'] = [this.roleStatusList.filter(b => b.NAME == ACTIVE)[0].ID];
          
          this.entitlementDetailsService.getRoleList(filter).subscribe(
            {
              next: resource => {
                this.roleEntList = resource['RESULT'];
                this.roleEntList.forEach(rl => {
                  let roleName = [];
                  roleName.push(rl.PRIME_ROLE_ID);
                  roleName.push(rl.ROLE_NAME);

                  let obj = rl;
                  obj['ROLE_NAME'] = roleName.join('-');

                  this.roleDropdown.push(obj);
                });
                this.existngRoleIds = this.roleListForEnt.map(r => r.ROLE_ID);
                this.roleDropdown = this.roleDropdown.filter(e => !this.existngRoleIds.includes(e.PRIME_ROLE_ID));
                this.roleDropdownList = this.roleDropdown;
              },
              error: error => {
                this.showErrorValidations(error.error);
              }
            });
        },
        error: error => {
          this.showErrorValidations(error.error);
        }
      });
  }

  addRoleToTable() {
    this.unSavedChanges = true;
    this.existngRoleIds = [];
    this.selectedRole.forEach(ele => {
      let obj = {
        ID: ele.PRIME_ROLE_ID,
        ROLE_ID: ele.PRIME_ROLE_ID,
        ROLE_NAME: ele.ROLE_NAME.substr(ele.ROLE_NAME.indexOf('-') + 1, ele.ROLE_NAME.length),
        ROLE_STATUS: ele.ROLE_STATUS,
        UPDATED_ON: new Date(),
        ACTION_TYPE: 1
      };
      this.existngRoleIds.push(ele.PRIME_ROLE_ID);
      this.orignalRoleListForEnt.unshift(obj);
      this.roleListForEnt = this.orignalRoleListForEnt;


      let filter = {};
        filter['roleId'] = ele.PRIME_ROLE_ID.toString();
        filter['changeRoleFlag'] =false;
        filter['roleName'] =ele.ROLE_NAME.substr(ele.ROLE_NAME.indexOf('-') + 1, ele.ROLE_NAME.length);
        this.roleDetailsService.getAllUserList(filter).subscribe(
            response => {
                let existingUserIds = this.usersList.map(u => u.USER_ID);
                this.addRoleImpactUsersList = response['USERS'].filter(e => !existingUserIds.includes(e.USER_ID));

            },
            err => {    
                this.showErrorValidations(err.error);
            }
        ); 
    });
    for (let i = 0; i < this.roleDropdown.length && this.existngRoleIds.includes(this.roleDropdown[i].PRIME_ROLE_ID); +i) {
      this.roleDropdown.splice(i, 1);
    }
    this.isAddNewRole = false;
    this.isSaveAndApplyBtnDisabled = false;

    this.successMessage = (this.selectedRole.length < TEN ? "0" : "") + this.selectedRole.length + (this.selectedRole.length > 1 ? " Roles" : " Role") + " added successfully";
  }

  discardRole() {
    this.isAddNewRole = false;
    this.selectedRole = [];
  }

  navigateToRoleDetailScreen(role) {
    role['PRIME_ROLE_ID'] = role.ROLE_ID;
    this.selectedRole = role;
    this.entitlementDetailsService.sendMessage(1);
    this.router.navigateByUrl('/pages/roledetails', {
      state: { role: role },
      skipLocationChange: false
    });
  }

  addUser() {
    this.isAddNewUser = true;
    this.isUserDropdown = false;
    this.selectedUser = [];
  }
  discardUser() {
    this.isAddNewUser = false;
    this.isUserExist = false;
    this.selectedUser = [];
  }

  getEntitlementUserList() {
    let filter = {};
    filter['COMPONENT_ID'] = this.entitlement.COMPONENT_ID.toString();
    filter['ENTITLEMENT_ID'] = this.entitlement.ENTITLEMENT_ID.toString();

    this.entitlementDetailsService.getEntitlementUserList(filter).subscribe(
      {
        next: response => {
          this.userListForEnt = response;
          this.userListForEnt.forEach(rl => {
            let userName = [];
            userName.push(rl.USER_NAME);
            userName.push(rl.USER_ID);

            let obj = rl;
            obj['NAME_WITH_ID'] = userName.join('-');

            this.addUserDropdown.push(obj);
            let existingRoleUserIds = this.usersList.map(e => e.USER_ID);
            this.addUserDropdown = this.userListForEnt.filter(e => !existingRoleUserIds.includes(e.ID)).slice(0);

          });
        },
        error: err => {
          this.showErrorValidations(err.error);
        }
      });
  }

  addUsersToEntTable() {
    this.unSavedChanges = true;
    this.isUserExist = false;
    this.isUserDropdown = true;
    this.existinguserIds = [];
    this.selectedUser.forEach(ele => {

      if (ele.USER_TYPE.toString().toLowerCase() == INTERNAL.toString().toLowerCase() &&
        ele.hasOwnProperty('ENT_OVERALL_STATUS') &&
        ele.ENT_OVERALL_STATUS == ALLOW &&
        ele.hasOwnProperty('IS_ADMS_APPROVED') &&
        ele.IS_ADMS_APPROVED == ONE) {

        ele.ENT_OVERALL_STATUS = ALLOW;

      } else if ((ele.hasOwnProperty('ENT_OVERALL_STATUS') &&
        ele.ENT_OVERALL_STATUS == PENDING ||
        ele.ENT_OVERALL_STATUS == DENY) ||
        (ele.USER_TYPE.toString().toLowerCase() == INTERNAL.toString().toLowerCase()
          && this.entitlement.IS_ADMS_ENTITLEMENT == ONE && ele.IS_ADMS_APPROVED != ONE)
        || (!ele.hasOwnProperty('ENT_OVERALL_STATUS')) && this.entitlement.IS_APPROVAL_REQUIRED == ONE) {

        ele.ENT_OVERALL_STATUS = DENY;

      } else {

        ele.ENT_OVERALL_STATUS = ALLOW;
      }

      let obj = {
        USER_ID: ele.USER_ID,
        USER_NAME: ele.USER_NAME,
        USER_STATUS: ele.USER_STATUS,
        ENT_STATUS: ele.ENT_OVERALL_STATUS,
        UPDATED_ON: new Date(),
        ACTION_TYPE: 1,
        LOGIN_ID: ele.LOGIN_ID,
        USER_TYPE: ele.USER_TYPE

      };
      this.existinguserIds.push(ele.USER_ID);
      this.usersList.unshift(obj);
      this.originalUserList = this.usersList;
    });

    for (let i = 0; i < this.addUserDropdown.length && this.existinguserIds.includes(this.addUserDropdown[i].USER_ID); +i) {
      this.addUserDropdown.splice(i, 1);
    }
    this.isAddNewUser = false;
    this.isSaveAndApplyBtnDisabled = false;
    if (this.entitlement.IS_APPROVAL_REQUIRED == ONE) {
      this.successMessage = "Assigning user to entitlement request has sent for Approval";
    }
    else {
      this.successMessage = (this.selectedUser.length < TEN ? "0" : "") + this.selectedUser.length + (this.selectedUser.length > 1 ? " Users" : " User") + " added successfully";
    }


  }

  openDeleteMultipleUsersDialog() {
    this.entitlementName = this.entitlement.ENTITLEMENT_NAME;
    this.entitlementId = this.entitlement.ENTITLEMENT_ID;
    this.componentId = this.entitlement.COMPONENT_ID;
    this.isMultipleDeleteEntUserDialog = true;
  }


  deleteMultipleUsers() {
    this.unSavedChanges = true;
    this.selectedUserRecords.forEach(element => {
      if (element.USER_ENT_ID) {
        this.deletedUsers.push(element);
      }
      this.usersList.forEach((ele, index) => {

        if (element.USER_ID == ele.USER_ID && element.USER_NAME == ele.USER_NAME) {
          this.usersList.splice(index, 1);
          let existingRoleUserIds = this.userListForEnt.map(e => e.USER_ID);
          if (!existingRoleUserIds.includes(element.USER_ID)) {
            let existingUserIds = this.usersList.map(e => e.USER_ID);
            element['NAME_WITH_ID'] = element.USER_NAME + "-" + element.USER_ID;
            this.userListForEnt.push(element);
            this.addUserDropdown = this.userListForEnt.filter(e => !existingUserIds.includes(e.USER_ID)).slice(0);
          }
        }

      });

    });
    this.originalUsersList = this.usersList;
    this.isMultipleDeleteEntUserDialog = false;
    this.selectedUserRecords = [];
    this.isSaveAndApplyBtnDisabled = false;
    this.successMessage = (this.deletedUsers.length > 1 ? "Users" : "User") + " successfully deleted  from " + "\`" + this.entitlementName + "\`" + " entitlement.";

  }

  loadRolesData() {
    this.searchArray = [];
    if (this.search) {
      this.searchArray = this.search.split(',').map(x => x.trim());
      this.roleListForEnt = this.orignalRoleListForEnt.filter(b => {
        if (
          this.searchArray &&
          this.searchArray.length > 0 &&
          !this.searchArray.some(bf => {
            const bfString = bf.toString();
            return (
              b.ROLE_NAME.toLowerCase().includes(bfString.toLowerCase()) ||
              b.ROLE_ID.toString().includes(bfString)
            );
          })
        ) {
          return false;
        }

        return true;
      });
    } else {
      this.roleListForEnt = this.orignalRoleListForEnt;
    }
  }

  areFiltersSelected() {
    return (
      this.selectedUserStatus.length > 0 || this.selectedEntilementStatusFilter.length > 0 ||
      this.preFilledUserStatus.length > 0 || this.preFilledEntitlementStatus.length > 0
    );
  }

  applyEntUserFilter(): void {
    this.preFilledUserStatus = this.selectedUserStatus.slice();
    this.preFilledEntitlementStatus = this.selectedEntilementStatusFilter.slice();
    this.showUserFilter = false;
    this.appliedStatusFilter = this.selectedUserStatus;
    this.appliedEntilementStatusFilter = this.selectedEntilementStatusFilter;
    if (this.selectedUserStatus.length > 0 || this.selectedEntilementStatusFilter.length > 0) {

      this.usersList = this.usersGridList.filter(b => {
        if (
          this.selectedUserStatus &&
          this.selectedUserStatus.length > 0 &&
          !this.selectedUserStatus.some(bf => bf.label.toLowerCase().toString() == b.USER_STATUS.toLowerCase().toString())
        ) {
          return false;
        }
        if (
          this.selectedEntilementStatusFilter &&
          this.selectedEntilementStatusFilter.length > 0 &&
          !this.selectedEntilementStatusFilter.some(bf => bf.label.toLowerCase() === b.ENT_STATUS.toLowerCase() ||
            bf.label === DENY && b.ENT_STATUS == PENDING)
        ) {
          return false;
        }

        return true;
      });

    }
  }

  clearEntUserFilters() {
    this.selectedUserStatus = [];
    this.appliedStatusFilter = [];
    this.preFilledUserStatus = [];
    this.preFilledEntitlementStatus = [];
    this.appliedEntilementStatusFilter = [];
    this.selectedEntilementStatusFilter = [];
    this.activeUserTab = {
      isUserTab: false,
      isEntitlementStatusTab: false
    };
    this.usersList = this.usersGridList;
    this.userStatusFilterDropdown = [];
    this.entStatusFilterDropdown = [];
  }

  sidebarCloseUserEvents() {
    if (this.appliedStatusFilter.length != this.selectedUserStatus.length) {
      this.selectedUserStatus = this.appliedStatusFilter;
    }
    if (this.appliedEntilementStatusFilter.length != this.selectedEntilementStatusFilter.length) {
      this.selectedEntilementStatusFilter = this.appliedEntilementStatusFilter;
    }
    if (this.selectedUserStatus.length == 0) {
      this.activeUserTab.isStatusTab = false;
    }
    if (this.selectedEntilementStatusFilter.length == 0) {
      this.activeUserTab.isEntitlementStatusTab = false;
    }
  }

  setActiveUserTab(activeTabName: any) {
    this.activeUserTab.isStatusTab = false;
    this.activeUserTab.isEntitlementStatusTab = false;

    if (activeTabName == USER) {
      this.activeUserTab.isStatusTab = true;
    }
    else if (activeTabName == ENTITLEMENTSTATUS) {
      this.activeUserTab.isEntitlementStatusTab = true;
    }

    this.openUserFilterSideBar();
  }

  discardUserFilter(event, element) {
    element.hide(event);
  }

  cancelSelectedUserFilter() {
    this.selectedUserStatus = [];
    this.selectedEntilementStatusFilter = [];
    this.selectedUserStatus = this.appliedStatusFilter;
    this.selectedEntilementStatusFilter = this.appliedEntilementStatusFilter;
  }

  loadEntFilterData() {
    this.usersGridList.forEach(element => {
      let userStatusListObj: any;
      if (element.USER_STATUS == ACTIVE.toUpperCase()) {
        userStatusListObj = {
          label: ACTIVE,
          data: element,
          children: [],
          selectable: true,
          expanded: true,
          Key: element.ID
        };
      } else {
        userStatusListObj = {
          label: INACTIVE,
          data: element,
          children: [],
          selectable: true,
          expanded: true,
          key: element.ID
        };
      }

      let entStatusListObj: any;
      if (element.ENT_STATUS == ALLOW) {
        entStatusListObj = {
          label: ALLOW,
          data: element,
          children: [],
          selectable: true,
          expanded: true,
          Key: element.ID
        };
      } else {
        entStatusListObj = {
          label: DENY,
          data: element,
          children: [],
          selectable: true,
          expanded: true,
          key: element.ID
        };
      }
      if (this.userStatusFilterDropdown.filter(c => c.label.toString() == userStatusListObj.label.toString()).length == 0) {
        this.userStatusFilterDropdown.push(userStatusListObj);
        this.userStatusFilterDropdown.sort((a, b) => a.label.localeCompare(b.label));
      }
      if (this.entStatusFilterDropdown.filter(c => c.label.toString() == entStatusListObj.label.toString()).length == 0) {
        this.entStatusFilterDropdown.push(entStatusListObj);
        this.entStatusFilterDropdown.sort((a, b) => a.label.localeCompare(b.label));
      }
    });

  }

  deleteMultipleRoles() {
    this.unSavedChanges = true;
    this.selectedRolesRecords.forEach(element => {
      if (element.RID) {
        this.deletedRoles.push(element);
      }
      this.roleListForEnt.forEach((ele, index) => {
        if (element.ROLE_ID == ele.ROLE_ID && element.ROLE_NAME == ele.ROLE_NAME) {
          this.roleListForEnt.splice(index, 1);
          let existingRoleUserIds = this.roleEntList.map(e => e.ID);
          if (existingRoleUserIds.includes(element.ROLE_ID)) {
            let existingRoleIds = this.roleListForEnt.map(e => e.ROLE_ID);
            this.roleDropdown = this.roleEntList.filter(e => !existingRoleIds.includes(e.ID)).slice(0);
          }
        }
      });

    });
    this.originalRoleList = this.roleListForEnt;
    this.isMultipleDeleteEntRoleDialog = false;
    this.selectedRolesRecords = [];
    this.isSaveAndApplyBtnDisabled = false;
    this.successMessage = (this.deletedRoles.length > 1 ? "Roles" : "Role") + " successfully deleted  from " + "\`" + this.entitlementName + "\`" + " entitlement.";

  }

  openDeleteMultipleRolesDialog() {
    this.entitlementName = this.entitlement.ENTITLEMENT_NAME;
    this.entitlementId = this.entitlement.ENTITLEMENT_ID;
    this.componentId = this.entitlement.COMPONENT_ID;
    this.isMultipleDeleteEntRoleDialog = true;
  }

  deleteRoleEntitlement(role, rowIndex) {
    this.rowIndexForUserDeleteOfEnt = rowIndex;
    this.roleName = role.ROLE_NAME;
    this.roleId = role.ROLE_ID;
    this.roledeleted = role;
    this.entitlementName = this.entitlement.ENTITLEMENT_NAME;
    this.entitlementId = this.entitlement.ENTITLEMENT_ID;
    this.componentId = this.entitlement.COMPONENT_ID;
    this.isDeleteEntRoleDialog = true;

    let roleEntitlementDetails = {
      ENTITLEMENT_ID: this.entitlementId,
      COMPONENT_ID: this.componentId,
      ROLE_ID: this.roleId
  };

  console.log(roleEntitlementDetails,"roleEntitlementDetails");
  this.roleDetailsService.getEntitlementWiseUsers(roleEntitlementDetails).subscribe(
      response => {
          this.rmRoleImpactUsersList=response['ENTITLEMENT_USERS']?.filter(e=>e.is_default_checked == ONE);
          this.isDeleteEntRoleDialog = true;
      },
      error => {
          this.showErrorValidations(error.error);
      }
    );
  }

  deleteRoleConfirmEntitlement() {
    this.unSavedChanges = true;
    this.roleListForEnt.splice(this.rowIndexForUserDeleteOfEnt, 1);
    this.deleteEntList.push(...this.rmRoleImpactUsersList);
    if (this.roledeleted.RID) {
      this.deletedRoles.push(this.roledeleted);
    }
    if (this.selectedRolesRecords.length > 0) {
      this.selectedRolesRecords = this.selectedRolesRecords.filter(e => e.ROLE_ID != this.roledeleted.ROLE_ID);
    }
    this.isDeleteEntRoleDialog = false;
    this.isSaveAndApplyBtnDisabled = false;
    let existingRoleUserIds = this.roleEntList.map(e => e.ID);
    if (existingRoleUserIds.includes(this.roledeleted.ROLE_ID)) {
      let existingRoleIds = this.roleListForEnt.map(e => e.ROLE_ID);
      this.roleDropdown = this.roleEntList.filter(e => !existingRoleIds.includes(e.ID)).slice(0);
    }
    this.successMessage = (this.deletedRoles.length > 1 ? "Roles" : "Role") + " successfully deleted  from " + "\`" + this.entitlementName + "\`" + " entitlement.";

  }


}
