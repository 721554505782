import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CIAM_UI_SERVICE_API_URL, CIAM_UM_SERVER_API_URL } from 'app/app.constants';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ManageEntitlementsService {
    constructor(private http: HttpClient) { }
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',

        })
    };

    getEntitlementList(data): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
        })
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageEntitlements/getEntitlementList', data, { headers: headers });
    }
    getEntitlementStatusList(): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageEntitlements/getEntitlementStatusList', {});
    }

    updateEntitlementStatus(data: any): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageEntitlements/updateEntitlementStatus', data);
    }

    getEntitlementActivityLog(data: any): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageEntitlements/getEntitlementActivityLog', data);
    }

    createNewEntitlement(data: any): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageEntitlements/createEntitlement', data);
    }

    assignNotificationEntitlementInfo(data: any): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageEntitlements/assignNotificationEntitlementInfo', data);
    }

    assignOwnerEntitlementInfo(data: any): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageEntitlements/assignOwnerEntitlementInfo', data);
    }

    addApprovalsForEntitlement(data: any): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageEntitlements/addApprovals', data);
    }
    getViewApprovalList(data: any): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageEntitlements/getViewApprovalList', data);
    }

    getDefaultOwnerListByComponentId(componentId) {
        return this.http.get(CIAM_UM_SERVER_API_URL + 'umManageEntitlements/getDefaultOwnerByComponentId?componentId=' + componentId);
    }

    getComponentsOfUserWithOwnerShip() {
        return this.http.get(CIAM_UM_SERVER_API_URL + 'umManageEntitlements/getComponentsOfUserWithOwnerShip');
    }

    verifyComponentAndEntitlementCombination(componentId, entitlementId) {
        return this.http.get(
            CIAM_UM_SERVER_API_URL +
            'umManageEntitlements/verifyComponentAndEntitlementCombination?componentId=' +
            componentId +
            '&entitlementId=' +
            entitlementId
        );
    }

    getAllActiveComponents(data: any) {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageEntitlements/getAllActiveComponents', data);
    }
    getActiveEntitlementsByComponentId(componentId) {
        return this.http.get(CIAM_UM_SERVER_API_URL + 'umManageEntitlements/getActiveEntitlementsByComponentId?componentId=' + componentId);
    }

    registerEntitlement(data) {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageEntitlements/registerEntitlement', data);
    }

    updateEntitlement(data: any): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageEntitlements/updateEntitlement', data);
    }

    getDeletedEntitlementInfo(data: any): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageEntitlements/getDeletedEntitlementInfo', data);
    }

    checkEntitlementsIsDeleted(data: any): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageEntitlements/checkEntitlementsIsDeleted', data);
    }

    deleteEntitlements(data: any): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageEntitlements/deleteEntitlements', data);
    }
    getComponentList(): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageEntitlements/getComponentList_New', {});
    }
    getEntitlementData(data): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageEntitlements/getEntitlement', data);
    }
    uploadEntitlements(data): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageEntitlements/uploadEntitlements', data);
    }

    downloadRegisteredEntitlements(data): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageEntitlements/downloadRegisteredEntitlements', data, {
            observe: 'response',
            responseType: 'blob'
        });
    }
    uploadUserEntitlements(data): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'usermanagement/save-user-entitlements', data);
    }

    exportUserEntitlements(data): Observable<any> {

        return this.http.post(CIAM_UM_SERVER_API_URL + 'usermanagement/export-user-entitlements', data, {
            observe: 'response',
            responseType: 'blob'
        });
    }

    getAllEntitlementList(data): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageEntitlements/getAllEntitlementList', data);
    }

    getEntitlementTypeList(): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageEntitlements/getEntitlementTypeList', {});
    }
    checkEntitlementOwnershipsInfo(data: any): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageEntitlements/checkEntitlementOwnershipsInfo', data);
    }

    getUserAccountInfo(data: any): Observable<any> {
        return this.http.post(CIAM_UI_SERVICE_API_URL + '/user-service/get-user-account-info',data);
    }

}
