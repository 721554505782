<div class="layout-content custom-container">
    <form [formGroup]="DeleteTokenForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class=" p-col-12">
                <div class="ui-fluid p-formgrid p-grid">
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                        <div class=" p-fluid   p-field    ">
                            <p>The selected token would be removed from usage,<br>
                                Are you sure you want to delete it?
                            </p>
                        </div>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right">
                        <div class="btnwrap">
                            <button pButton class="p-button-secondary" type="button"
                                (click)="onClickNo(DeleteTokenForm);" label="No">
                            </button>
                        </div>
                        <div class="btnwrap">
                            <button pButton class="p-button-primary" type="button" (click)="DeleteToken();" label="Yes">
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>