import { Injectable } from "@angular/core";
import { IEventContext, IEventData, IEventSubject } from "app/shared/interface/event.interface";

@Injectable({
    'providedIn': 'root'
})
export class LoggingEventService {
    eventTypes: { [name: string]: number } = {};
    contextList: { [name: string]: number } = {};
    eventItems: { [name: string]: number } = {};
    constructor() {
        console.log("logging event");
    }

    async getEventId(eventName: string) {
        const eventTypes: { [name: string]: number } = JSON.parse(localStorage.getItem('EVENT_TYPE_LIST') || '{}');
        return eventTypes[eventName.toLowerCase().trim()] || null;
    }

    async getContextId(contextName: string) {
        const contextList: { [name: string]: number } = JSON.parse(localStorage.getItem('CONTEXT_LIST') || '{}');
        return contextList[contextName.toLowerCase().trim()] || null;
    }

    async getItemId(itemName: string) {
        const eventItems: { [name: string]: number } = JSON.parse(localStorage.getItem('ITEM_TYPE_LIST') || '{}');;
        return eventItems[itemName.toLowerCase().trim()] || null;
    }

    async getEventSubjectData(itemName: string, newValue: string, oldValue?: string) {
        let eventSubject: IEventSubject = {
            itemId: await this.getItemId(itemName),
            newValue: newValue,
            oldValue: oldValue
        };
        return eventSubject;
    }

    async getEventContextData(contextName: string, value: string) {
        let eventContext: IEventContext = {
            contextId: await this.getContextId(contextName),
            value: value,
            parentId: +localStorage.getItem("PARENT_ID")
        };
        return eventContext;
    }

    async getEventContextDataWithName(contextName: string, value: string) {
        let eventContext: IEventContext = {
            contextId: await this.getContextId(contextName),
            value: value,
            parentId: +localStorage.getItem("PARENT_ID"),
            contextName:contextName
        };
        return eventContext;
    }

    async getEventData(eventName: string, isEventSubect: boolean, isEventContext: boolean, componentId: number,
        contextName: string, contextValue?: string) {
        let eventData: IEventData = {
            eventTypeId: await this.getEventId(eventName),
            eventName: eventName,
            componentId: componentId,
            isEventSubject: isEventSubect,
            isEventContext: isEventContext,
            eventContextList: [await this.getEventContextData(contextName, contextValue)]
        };
        return eventData;
    }
}