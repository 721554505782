import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Message, MessageService, FilterService } from 'primeng/api';
import { PagesService } from '../pages.service';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { AddCustomField } from '../AddCustomField/AddCustomField.component';
import { DeleteCustomFieldComponent } from '../DeleteCustomField/DeleteCustomField.component';
import { EditCustomField } from '../EditCustomField/EditCustomField.component';
@Component({
  selector: 'jhi-TenantSettingsListing',
  templateUrl: './TenantSettingsListing.component.html',
  styleUrls: ['./TenantSettingsListing.component.scss'],
  providers: [MessageService, DialogService, FilterService],
})
export class TenantSettingsListingComponent implements OnInit {
  ref!: DynamicDialogRef;
  TenantSettingsListingForm = this.fb.group({
    tabview: [0],
    select: [null, [Validators.required]],
    applicationSearch: [null, []],
    textField1: [null, []],
    componentSearch: [null, []],
    select1: [null, [Validators.required]],
    search1: [null, []],
    select2: [null, [Validators.required]],
    select3: [null, [Validators.required]],
    pickList: [null, []],
    textField2: [null, []],
    inputswitch: [null, []],
  });
  UserConfigurationForm = this.fb.group({
    ccprimeheading_10: <any>[''],
    userType: [null, [Validators.required]],
    moduleDropdown: [null, [Validators.required]],
    featureDropdown: [null, [Validators.required]],
    textfield1: [null, []],
    textfield2: [null, []],
    selectSwitch: [null],
    userStorage:[null,[]]

  }
  );
  pageloadvalues: any;
  ccprimebreadcrumbList = [{ label: `Tenant Management` },
  { label: `Tenant Settings` },
  ]
  homeiconccprimebreadcrumb = { icon: 'pi pi-home', routerLink: ['/'] };
  selectList: any[] = [];
  searchList: any[] = [];
  tableList: any[] = [];
  tableList2: any[] = [];
  tableList3: any[] = [];
  tableList4: any[] = [];
  tableList5: any[] = [];
  tableList6: any[] = [];
  tableList7: any[] = [];
  tablecols: any[] = [{ field: "type", header: "Type" },
  { field: "name", header: "Name" },
  { field: "group", header: "Group" },
  ];
  select1List: any[] = [];
  search1List: any[] = [];
  table1List: any[] = [];
  table1cols: any[] = [{ field: "type", header: "Type" },
  { field: "name", header: "Name" },
  { field: "group", header: "Group" },
  ];
  select2List: any[] = [];
  select3List: any[] = [];
  pickListList: any[] = [];
  pickListList1: any[] = [];
  messageFlag = true;
  applicationId: any;
  resourceApplicationId: any;
  msgs: Message[] = [];

  pickListtargetList: any[] = [];
  pickListtargetList1: any[] = [];
  tableList1: any[] = [];
  btn = true;
  dropdown = true;
  activeItem: any;
  activeItem1: any;
  activeItem2: any;
  selectedIndex: any;
  flag2 = true;
  flag1 = true;
  flag3 = true;
  groupID: any[] = [];
  appId: any[] = [];
  exMapList: any[] = [];
  mapList: any[] = [];
  mList: any[] = [];
  groupArr: [];
  errorFlag = true;
  varDefault = "Default";
  checked1: boolean = false;
  someArr: any = [];
  flag = 1;
  filterFlag = false;
  specialFlag: boolean = false;
  userTypeselectedValues: any[] = [];
  userTypeList: any[] = [];
  featureList: any[] = [];
  SelectedEntity: string;
  selectFeatureList: any[] = [];
  enableBtn1: boolean = true;
  firstSave = false;
  tenantConfigFlag = false;
  tenantConfigId: any;
  existingFeatureList: any[] = [];
  existingUserTypeList: any[] = [];
  newFeatureList: any;
  newUserTypeList: any;
  getFeatureList: any[] = [];
  defaultselect_selectSwitch: any;
  selectSwitchList: any[] = [{ 'label': "Auto trigger", 'value': 'true' }, { 'label': "Manual", 'value': 'false' },];
  userStorageList: any[]=[{'label': "External AD", 'value': 'externalAD'},{'label': "Database", 'value': 'database' },{'label': "Azure B2C", 'value': 'azureB2C' }];
  selectUserTypeLabel: any[]=[];
  userStorageFlag: boolean=false;
  userStorageDisableFlag: boolean=false;
  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public dialogService: DialogService,
    public pagesService: PagesService,
    private messageService: MessageService) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.TenantSettingsListingForm.patchValue(JSON.parse(data));
      this.UserConfigurationForm.patchValue(JSON.parse(data));
      this.setPageProperties(JSON.parse(data));
    }

  }
  ngOnInit(): void {
    this.setDefaultValue_selectSwitch('test');
    this.gettableList();
    this.getUserTypeList();
    this.getEntityList();
    this.UserConfigurationForm.get('featureDropdown').disable();
    this.UserConfigurationForm.get('moduleDropdown').disable();
  }

  highlight(index: any) {
    this.selectedIndex = index;
  }
  mouseLeave() {
    this.selectedIndex = null;
  }

  handleChangetabview(e: any): void {
    this.TenantSettingsListingForm.patchValue({ "tabview": e.index });
    this.TenantSettingsListingForm.controls.applicationSearch.reset();
    this.TenantSettingsListingForm.controls.textField1.reset();
  }


  //Application Tab
  gettableList(): void {
    this.tableList = [];
    this.tableList1 = [];
    this.pagesService.getManageCustomFieldListing().subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            entityId: null,
            entityName: null,
            fieldName: null,
            fieldInputType: null,
            inputFormat: null,
            mandatoryField: null,
            editableField: null,
            configJson: null,
            fieldId: null,
            inputtype_id: null,
            inputFormatid: null,
            MaxCharacters: null,
            specialCharacter: [],
            specialChar: [],
            inputValueFormat: [],
            inputFormatStr: null
          };
          data.fieldName = element.fieldName;
          data.configJson = JSON.parse(element.fieldConfigJason);
          data.editableField = data.configJson.editable;
          data.fieldInputType = data.configJson.fieldLabel;
          data.mandatoryField = data.configJson.mandatory;
          data.entityId = data.configJson.entityId;
          data.entityName = data.configJson.entityName;
          data.inputtype_id = data.configJson.inputtype_id;
          data.MaxCharacters = data.configJson.MaxCharacters;
          data.specialCharacter = data.configJson.specialCharacter;
          data.inputFormat = data.configJson?.inputvalue_format;
          data.specialChar = data.configJson?.specialCharacter;
          for (let j = 0; j < data.inputFormat?.length; j++) {
            if (data.inputFormat[j] == "Special charecters") {
              this.specialFlag = true;
              break;
            }
            else {
              this.specialFlag = false;
            }
          }
          if ((data.specialChar != null || data.specialChar != undefined)) {
            data.specialChar.forEach(element => {
              data.inputValueFormat.push(element.label);
            });
            data.inputFormat.forEach(element => {
              if (element != "Special charecters") {
                data.inputValueFormat.push(element);
              }
            });
          }

          else if (data.fieldInputType == 'Input field in range') {
            data.inputValueFormat.push(data.inputFormat[0]);
          }
          else if (data.fieldInputType == 'Dropdown' || data.fieldInputType == 'Checkbox') {
            data.inputFormat?.forEach(element => {
              data.inputValueFormat.push(element.label);
            });
          }
          else if (!this.specialFlag && (data.fieldInputType == 'Text' || data.fieldInputType == 'Text area')) {
            data.inputValueFormat.push(data.inputFormat);
          }

          data.fieldId = element.id;
          data.inputFormatStr = data.inputValueFormat.toString();
          this.tableList.push(data);
          this.tableList1.push(data);
        });
        this.tableList = [...this.tableList];
        this.tableList1 = [...this.tableList1];
        this.errorFlag = false;
      }
      else {
        this.errorFlag = true;
      }
    });
  }


  showAddCustomField() {
    this.ref = this.dialogService.open(AddCustomField, {
      data: {},
      header: 'Add Custom Field',
      width: '1000px',
      contentStyle: { overflow: 'visible' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Custom field added successfully') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.gettableList();

      }
      else if (msg == 'Custom field Creation Failed' || msg == 'Custom field Creation already exist' || msg == 'Exceeded maximum configuration limit') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
    });

  }
  EditCustomField() {
    this.ref = this.dialogService.open(EditCustomField, {
      data: { id: this.activeItem.fieldId, moduleid: this.activeItem.entityId, name: this.activeItem.fieldName, entityName: this.activeItem.entityName, fieldtype: this.activeItem.fieldInputType, inputtype_id: this.activeItem.inputtype_id, inputFormatData: this.activeItem.inputFormat, editableField: this.activeItem.editableField, mandatoryField: this.activeItem.mandatoryField, MaxCharacters: this.activeItem.MaxCharacters, specialCharacter: this.activeItem.specialCharacter, dialogFlag: 'roles' },
      header: 'Edit Custom Field',
      width: '1000px',
      contentStyle: { overflow: 'visible' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Custom field added successfully') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.gettableList();

      }
      else if (msg == 'Custom field Creation Failed' || msg == 'Custom field Creation already exist') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
    });

  }
  DeleteCustomField() {
    this.ref = this.dialogService.open(DeleteCustomFieldComponent, {
      data: { id: this.activeItem.fieldId, moduleid: this.activeItem.entityId, name: this.activeItem.fieldName, dialogFlag: 'roles' },
      header: 'Delete Custom field',
      width: '36%',
      contentStyle: { 'max-height': '200px', overflow: 'visible', 'border-radius': '4px', },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'failed') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Failed to delete custom field.' });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
      else if (msg == 'success') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'Custom field deleted successfully.' });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.gettableList();
      }
    });

  }
  filterMethod(event: any) {
    let filtered: any[] = [];
    let items: any;
    let cc;
    let query = event;
    if (query?.length > 0) {
      this.tableList1?.forEach(element => {
        items = element;
        cc = items.inputValueFormat?.length
        if (items?.entityName?.toString().toLowerCase().includes(query.toLowerCase()) || items?.fieldName?.toString().toLowerCase().includes(query.toLowerCase()) || items?.fieldInputType?.toString().toLowerCase().includes(query.toLowerCase()) || items?.mandatoryField?.toString().toLowerCase().includes(query.toLowerCase()) || items?.editableField?.toString().toLowerCase().includes(query.toLowerCase())) {
          filtered.push(items);
        }
        else {
          for (let j = 0; j < cc; j++) {
            if (items.inputValueFormat[j]?.toString().toLowerCase().includes(query.toLowerCase())) {
              filtered.push(items);
            }
          }
        }
      });
      this.tableList = filtered;
    }
    else {
      this.tableList = this.tableList1;
    }
  }

  getEntityList(): void {
    this.selectList = [];
    this.pagesService.getEntity().subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
            inactive: true
          };
          data.label = element.label;
          data.value = element.value;
          if (data.label == 'Users') {
            data.inactive = false;
            this.SelectedEntity = data.value;
          }
          this.selectList.push(data);
        });
        this.selectList = [...this.selectList];
      }
      this.UserConfigurationForm.controls['moduleDropdown'].setValue(this.SelectedEntity);
      this.UserConfigurationForm.controls['moduleDropdown'].updateValueAndValidity();
      this.getTenantModuleConfigByTenantId();
    });

  }

  getUserTypeList(): void {
    this.pagesService.userTypeList().subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
            id: null,
            isDeleted: false
          };
          data.label = element.label;
          data.value = element.value;
          if (!this.tenantConfigFlag) {
            this.userTypeselectedValues.push(data.value);
          }
          this.userTypeList.push(data);
        });
        this.userTypeList = [...this.userTypeList];
      }
    });
  }

  getFeatureByModuleIdList(): void {
    this.UserConfigurationForm.get('featureDropdown').enable();
    this.pagesService.getFeatureByModuleId(this.SelectedEntity).subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
            id: null,
            isDeleted: false
          };
          data.label = element.label;
          data.value = element.value;
          if (!this.tenantConfigFlag) {
            this.selectFeatureList.push(data.value);
          }
          this.featureList.push(data);
        });
        this.featureList = [...this.featureList];
        if (!this.tenantConfigFlag) {
          this.selectFeatureList = [...this.selectFeatureList];
          this.enableBtn1 = false;
        }

        if (this.tenantConfigFlag) {
          let featureEntity = this.getFeatureList;
          this.featureList.forEach(element1 => {
            featureEntity.forEach(element2 => {
              if (element1.value == element2.value) {
                element1.id = element2.id;
                element1.isDeleted = element2.isDeleted;
              }
            });
          });
        }
      }
    });
  }
  getSelectFeature(): void {
    this.selectFeatureList = [];
    const responseData = this.UserConfigurationForm.value.featureDropdown;
    if (responseData && responseData.length > 0) {
      responseData.forEach((element: any = {}) => {
        const data = {
          label: null,
          isDeleted: null,
          value: null,
          id: null,
        };
        data.isDeleted = element.isDeleted;
        data.value = element.featureId;
        data.id = element.id;
        if (element.isDeleted == "false") {
          this.getFeatureList.push(data);
          this.existingFeatureList.push(data.value);
          this.selectFeatureList.push(data.value);
        }

      });
      this.selectFeatureList = [...this.selectFeatureList];
    }
  }

  getTenantModuleConfigByTenantId(): void {
    this.pagesService.getTenantModuleConfigByTenantId(this.pagesService.tenantId).subscribe(results => {
      const responseData = results?.success?.result;
      this.pagesService.isMailAutoTrigger = results?.success?.result[0]?.isMailAutoTrigger;
      if (this.pagesService.isMailAutoTrigger == 'true') {
        this.defaultselect_selectSwitch = this.selectSwitchList[0]?.value;
      } else if (this.pagesService.isMailAutoTrigger == 'false') {
        this.defaultselect_selectSwitch = this.selectSwitchList[1]?.value;
      }
      if (responseData && responseData.length > 0) {
        this.userTypeselectedValues = [];
        this.tenantConfigFlag = true;
        this.firstSave = true;
        let pagePropBind = {
          moduleDropdown: results?.success?.result[0]?.moduleId,
          featureDropdown: results?.success?.result[0]?.featureList,
          textfield1: results?.success?.result[0]?.limitOfAccountInactivity,
          textfield2: results?.success?.result[0]?.limitOfAccountInactivityFromLastLogin,
        };
        this.tenantConfigId = results?.success?.result[0]?.tenantConfigId;
        let userTypeList1 = results?.success?.result[0]?.usertypeList;
        this.pagesService.userStorage=results?.success?.result[0]?.userStorage;
        if(this.pagesService.userStorage=='externalAD' || this.pagesService.userStorage=='database' ||  this.pagesService.userStorage=='azureB2C'){
          //do nothing
        }else{
          this.pagesService.userStorage=null;
        }
        if((this.pagesService.userStorage!=null) && !this.pagesService.hasTenantAdminRole){
          this.UserConfigurationForm.get('userStorage').disable();
          this.userStorageDisableFlag=true;
        }
        this.userTypeList.forEach(element1 => {
          userTypeList1.forEach(element2 => {
            if (element1.value == element2.userTypeId) {
              element1.id = element2.id;
              element1.isDeleted = element2.isDeleted;
             this.selectUserTypeLabel?.push(element1?.label);
            }
          });
        });
        userTypeList1.forEach(element => {
          if (element.isDeleted == "false") {
            this.userTypeselectedValues.push(element.userTypeId);
            this.existingUserTypeList.push(element.userTypeId);
          }
        });
        this.selectUserTypeLabel.forEach(element=>{
            if(element=='DB User' || element=='External'){
                   this.userStorageFlag=true;
                   this.UserConfigurationForm.controls['userStorage'].setValidators(Validators.required);
            }
        });
        this.pageloadvalues = pagePropBind;
        this.UserConfigurationForm.patchValue(pagePropBind);
        this.getFeatureByModuleIdList();
        this.getSelectFeature();
      }
      if (results?.error?.messageCode == 'CIAM 803') {
        if (!this.tenantConfigFlag) {
          this.getFeatureByModuleIdList();
        }
      }

    });
  }
  setPageProperties(props: any): void {
    const pageData: any = {};
    const calendarControls = ['createdOn'];
    Object.keys(props).forEach(key => {
      if (calendarControls.includes(key)) {
        pageData[key] = new Date(props[key]);
      } else {
        pageData[key] = props[key];
      }
    });
    this.UserConfigurationForm.patchValue(pageData);
  }
  createTenantConfiguration() {
    if (this.firstSave) {
      this.editTenantModuleConfiguration();
    }
    else {
      this.saveTenantModuleConfiguration();
    }
  }
  saveTenantModuleConfiguration() {
    const reqBody = {};
    reqBody['tenantId'] = this.pagesService.tenantId;
    reqBody['moduleId'] = this.SelectedEntity;
    if (this.UserConfigurationForm.value.textfield1 == null || this.UserConfigurationForm.value.textfield1 == "") {
      //limit inactivity
    }
    else if (this.UserConfigurationForm.value.textfield1 != null || this.UserConfigurationForm.value.textfield1 != "") {
      reqBody['limitOfAccountInactivity'] = this.UserConfigurationForm.value.textfield1;
    }
    if (this.UserConfigurationForm.value.textfield2 == null || this.UserConfigurationForm.value.textfield2 == "") {
      // limit last login
    }
    else if (this.UserConfigurationForm.value.textfield2 != null || this.UserConfigurationForm.value.textfield2 != "") {
      reqBody['limitOfAccountInactivityFromLastLogin'] = this.UserConfigurationForm.value.textfield2;
    }
    reqBody['tenantModuleFeature'] = [];
    reqBody['tenantUserType'] = [];
    this.featureList.forEach(element => {
      if (this.selectFeatureList.includes(element.value)) {
        const reqBody1 = {};
        reqBody1['featureId'] = element.value;
        reqBody1['deleted'] = false;
        reqBody['tenantModuleFeature'].push(reqBody1);
      }
    });

    this.userTypeList.forEach(element => {
      const reqBody2 = {};
      if (this.userTypeselectedValues.includes(element.value)) {
        reqBody2['userTypeId'] = element.value;
        reqBody2['deleted'] = false;
        reqBody['tenantUserType'].push(reqBody2);
      }
    });
    reqBody['isMailAutoTrigger'] = this.UserConfigurationForm.value.selectSwitch;
    reqBody['userStorage'] = this.UserConfigurationForm.value.userStorage;
    this.pagesService.userStorage = this.UserConfigurationForm.value.userStorage;
    if(this.pagesService.userStorage=='externalAD' || this.pagesService.userStorage=='database' ||  this.pagesService.userStorage=='azureB2C'){
      //do nothing
    }else{
      this.pagesService.userStorage=null;
    }
    if((this.pagesService.userStorage!=null) && !this.pagesService.hasTenantAdminRole){
      this.UserConfigurationForm.get('userStorage').disable();
      this.userStorageDisableFlag=true;
    }
    console.log(reqBody,"Reqbody");
    this.existingFeatureList = [];
    this.existingUserTypeList = [];
    this.messageFlag = true;
    this.pagesService.saveTenantModuleConfiguration(reqBody).subscribe(
      {
        next: results => {
          if (results.success.code == 200) {
            this.enableBtn1 = true;
            this.messageFlag = true;
            this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'User Configuration saved successfully.' });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
            this.existingFeatureList = [...this.selectFeatureList];
            this.existingUserTypeList = [...this.userTypeselectedValues];
            this.firstSave = true;
            this.tenantConfigId = results.success.result.id;
            let featureEntity = results.success.result.tenantModuleFeatureEntity;
            this.featureList.forEach(element1 => {
              featureEntity.forEach(element2 => {
                if (element1.value == element2.featureId) {
                  element1.id = element2.id;
                  element1.isDeleted = element2.deleted;
                }
              });
            });
            let userTypeEntity = results.success.result.tenantUserTypeEntity;
            this.userTypeList.forEach(element1 => {
              userTypeEntity.forEach(element2 => {
                if (element1.value == element2.userTypeId) {
                  element1.id = element2.id;
                  element1.isDeleted = element2.deleted;
                }
              });
            });
          }
        },
        error: error => {
          this.messageFlag = true;
          this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Failed to save user configuration.' });
          setTimeout(() => {
            this.messageFlag = false;
          }, 3000);
        }
      });


  }

  editTenantModuleConfiguration() {
    const reqBody = {};
    reqBody['id'] = this.tenantConfigId;
    reqBody['tenantId'] = this.pagesService.tenantId;
    reqBody['moduleId'] = this.SelectedEntity;
    if (this.UserConfigurationForm.value.textfield1 == null || this.UserConfigurationForm.value.textfield1 == "") {
      // limit inactivity
    }
    else if (this.UserConfigurationForm.value.textfield1 != null || this.UserConfigurationForm.value.textfield1 != "") {
      reqBody['limitOfAccountInactivity'] = this.UserConfigurationForm.value.textfield1;
    }
    if (this.UserConfigurationForm.value.textfield2 == null || this.UserConfigurationForm.value.textfield2 == "") {
      // limit last login
    }
    else if (this.UserConfigurationForm.value.textfield2 != null || this.UserConfigurationForm.value.textfield2 != "") {
      reqBody['limitOfAccountInactivityFromLastLogin'] = this.UserConfigurationForm.value.textfield2;
    }

    reqBody['tenantModuleFeature'] = [];
    reqBody['tenantUserType'] = [];
    this.newFeatureList = this.UserConfigurationForm.value.featureDropdown;
    const assignList = this.newFeatureList.filter(x => !this.existingFeatureList.includes(x));
    const sameFeatureList = this.newFeatureList.filter(x => !assignList.includes(x));
    this.featureList.forEach(element => {
      const reqBody1 = {};
      if (assignList.includes(element.value)) {  //new feature to add
        reqBody1['featureId'] = element.value;
        reqBody1['deleted'] = false;
        reqBody['tenantModuleFeature'].push(reqBody1);
      }
      if (sameFeatureList.includes(element.value)) { //same feature
        reqBody1['id'] = element.id;
        reqBody1['featureId'] = element.value;
        reqBody['tenantModuleFeature'].push(reqBody1);
      }
    });
    this.newUserTypeList = this.userTypeselectedValues;
    const assignUserTypeList = this.newUserTypeList.filter(x => !this.existingUserTypeList.includes(x));
    const sameUserTypeList = this.newUserTypeList.filter(x => !assignUserTypeList.includes(x));
    this.userTypeList.forEach(element => {
      const reqBody2 = {};
      if (assignUserTypeList.includes(element.value)) {
        reqBody2['userTypeId'] = element.value;
        reqBody2['deleted'] = false;
        reqBody['tenantUserType'].push(reqBody2);
      }
      if (sameUserTypeList.includes(element.value)) {
        reqBody2['id'] = element.id;
        reqBody2['userTypeId'] = element.value;
        reqBody['tenantUserType'].push(reqBody2);
      }
    });
    reqBody['isMailAutoTrigger'] = this.UserConfigurationForm.value.selectSwitch;
    reqBody['userStorage'] = this.UserConfigurationForm.value.userStorage;
    this.pagesService.userStorage = this.UserConfigurationForm.value.userStorage;
    if(this.pagesService.userStorage=='externalAD' || this.pagesService.userStorage=='database' ||  this.pagesService.userStorage=='azureB2C'){
      //do nothing
    }else{
      this.pagesService.userStorage=null;
    }
    if((this.pagesService.userStorage!=null) && !this.pagesService.hasTenantAdminRole){
      this.UserConfigurationForm.get('userStorage').disable();
      this.userStorageDisableFlag=true;
    }
    this.messageFlag = true;
    this.pagesService.saveTenantModuleConfiguration(reqBody).subscribe(
      {
        next: results => {
          if (results.success.code == 200) {
            this.enableBtn1 = true;
            this.messageFlag = true;
            this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'User configuration edited successfully.' });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
            this.existingFeatureList = [...this.selectFeatureList];
            this.existingUserTypeList = [...this.userTypeselectedValues];
            this.firstSave = true;
            this.tenantConfigId = results.success.result.id;
            let featureEntity = results.success.result.tenantModuleFeatureEntity;
            this.featureList.forEach(element1 => {
              featureEntity.forEach(element2 => {
                if (element1.value == element2.featureId) {
                  element1.id = element2.id;
                  element1.isDeleted = element2.deleted;
                }
              });
            });
            let userTypeEntity = results.success.result.tenantUserTypeEntity;
            this.userTypeList.forEach(element1 => {
              userTypeEntity.forEach(element2 => {
                if (element1.value == element2.userTypeId) {
                  element1.id = element2.id;
                  element1.isDeleted = element2.deleted;
                }
              });
            });
          }
        },
        error: error => {
          this.messageFlag = true;
          if (error.error?.error?.messageCode == 'CIAM 113') {
            this.messageFlag = true;
            this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Before disabling, Please delete/remove the users from the Tenant for the requested user type.' });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
          }
          else {
            this.messageFlag = true;
            this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Failed to save user configuration.' });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
          }

        }
      });


  }
  setDefaultValue_selectSwitch(value?: any) {
    if (this.selectSwitchList.length && value) {
      this.defaultselect_selectSwitch = value;
      return this.defaultselect_selectSwitch
    }
    this.defaultselect_selectSwitch = this.selectSwitchList[0]?.value;
    return this.defaultselect_selectSwitch;
  }
}
