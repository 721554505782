<div class="layout-content custom-container">
    <form [formGroup]="CreateAttributeTypeForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 left">
            </div>
            <div class="p-col-6 p-sm-6 p-md-6 p-lg-6 p-xl-6 p-offset-undefined left">
                <div class=" p-fluid   p-field    ">
                    <label [ngClass]="{'field-required': CreateAttributeTypeForm.get('textField2')?.errors?.required}">
                        Code
                        <span class='invalid-feedback-astrik'>*</span></label>
                    <input type="text" id="textField_id2" name="textField_name" formControlName="textField2"
                        placeholder="Code" class="p-inputtext" pattern="^[A-Z_]+$" maxlength="20"
                        (keyup)="enabledbtn1=false;this.nameFlag= false;defaultFlag=false;colorChangeCode()" />

                    <div *ngIf="this.defaultFlag && this.CreateAttributeTypeForm.value.textField2==null"><label
                            class="alert-icon1 alert-circle-icon1"></label>
                        <span style="font-size: 12px;font-weight: 400;color: #505870;vertical-align: middle;">Only
                            uppercase & underscore characters are allowed</span>
                    </div>
                    <div class="p-error"
                        *ngIf="CreateAttributeTypeForm.get('textField2')!.invalid && (CreateAttributeTypeForm.get('textField2')!.dirty || CreateAttributeTypeForm.get('textField2')!.touched)">
                        <div
                            *ngIf="this.defaultFlag==false && (CreateAttributeTypeForm.get('textField2')?.errors?.required || this.CreateAttributeTypeForm.value.textField2==null)">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium class="p-error"> Code is required.</medium>
                        </div>
                        <div
                            *ngIf="CreateAttributeTypeForm.get('textField2').errors.pattern && this.CreateAttributeTypeForm.value.textField2!=null ">
                            <label class="alert-icon alert-circle-icon"></label>
                            <span class="p-error">Only uppercase & underscore characters are allowed</span>
                        </div>
                        <div *ngIf="CreateAttributeTypeForm.get('textField2').errors.maxlength">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium class="p-error">Code should not exceed 20 characters. </medium>
                        </div>
                    </div>
                    <div class="invalid-feedback">
                        <label *ngIf="this.nameFlag">
                            <div>
                                <label class="alert-icon alert-circle-icon"></label>
                                <medium class="p-error">Attribute Type Code already exists.
                                </medium>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
            <div class="p-col-6 p-sm-6 p-md-6 p-lg-6 p-xl-6 p-offset-undefined left">
                <div class=" p-fluid   p-field    ">
                    <label [ngClass]="{'field-required': CreateAttributeTypeForm.get('textField1')?.errors?.required}">
                        Attribute Type Name
                        <span class='invalid-feedback-astrik'>*</span></label>
                    <input type="text" id="textField_id1" (keyup)="this.nameFlag1=false;colorChangeName()"
                        name="textField_name" formControlName="textField1" placeholder="Attribute Type Name"
                        class="p-inputtext" pattern="^([a-zA-Z0-9+]+[\s_-]+)*[a-zA-Z0-9]+$" maxlength="50"
                        (keyup)="enabledbtn1=false" />
                    <div class="p-error"
                        *ngIf="CreateAttributeTypeForm.get('textField1')!.invalid && (CreateAttributeTypeForm.get('textField1')!.dirty || CreateAttributeTypeForm.get('textField1')!.touched)">
                        <div *ngIf="CreateAttributeTypeForm.get('textField1')?.errors?.required">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium class="p-error">Attribute Type Name is required.</medium>
                        </div>
                        <div *ngIf="CreateAttributeTypeForm.get('textField1').errors.pattern">
                            <label class="alert-icon alert-circle-icon"></label>
                            <span class="p-error"> Only Dash, Underscore & space are allowed as special characters in
                                between.</span>
                        </div>
                    </div>
                    <div class="invalid-feedback">
                        <label *ngIf="this.nameFlag1">
                            <div>
                                <label class="alert-icon alert-circle-icon"></label>
                                <medium class="p-error">Attribute Type Name already exists.
                                </medium>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                <div class=" p-fluid   p-field    ">
                    <label>
                        Description
                    </label>
                    <textarea pInputTextarea id="textArea_id" name="textArea_name" formControlName="textarea" rows="3"
                        placeholder="Add description" maxlength="200">
                        </textarea>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right p-mb-2">
                <div class="btnwrap p-mr-3">
                    <button pButton type="button" class="p-button-brand2" (click)="onClose(CreateAttributeTypeForm);"
                        label="Close">
                    </button>
                </div>
                <div class="btnwrap">
                    <button pButton class="p-button-primary" id="save" type="button"
                        (click)="AttributeTypeName(this.CreateAttributeTypeForm.value);"
                        [disabled]="enabledbtn1 || nameFlag || nameFlag1 || !CreateAttributeTypeForm.valid"
                        label="Save">
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>