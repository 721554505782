import { MainComponent } from 'app/layouts/main/main.component';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PagesService } from '../pages.service';
import { ConfirmationService, MessageService, Message, MenuItem } from 'primeng/api';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { KEY_CLOAK_REALM_NAME, REBAC_UM_ENTITLEMENT_FLAG } from "app/app.constants";
import { DateFormatterPipe } from 'app/pipes/date-formatter.pipe';
import { MyAccountService } from './MyAccount.service';
import {
  ALLOW,
  PENDING,
  DENY,
  ONE,
  ZERO,
  APPROVED,
  INTERNAL,
  NOT_APPROVED,
  AD_USER,
  DB_USER
} from 'app/constants';

@Component({
  selector: 'jhi-myaccount',
  templateUrl: './MyAccount.component.html',
  styleUrls: ['./MyAccount.component.scss'],
  providers: [MessageService, ConfirmationService, DialogService, DatePipe, DateFormatterPipe],
})
export class MyAccountComponent implements OnInit {
  MyAccountForm = this.fb.group({
    viewUserID: [null, []],
    userName: [null, []],
    createdOn: [null, []],
    statusMode: [null, []],
    primaryEmail: [null, []],
    contactNumber: [null, []],
    countryCode: [null, []],
    role: [null, []],
    groups: [null, []],
    ccprimesplitbutton: [null, []],
    firstName: [null, []],
    middleName: [null, []],
    lastName: [null, []],
    mirrorFlag: [null, []],
    oldPassword: [null, [Validators.required]],
    newPassword: [null, [Validators.required]],
    confirmPassword: [null, [Validators.required]],
    dormancyReason: [null, []],
    userIdentity: [null, []]
  });
  activeId: any[] = [];
  pageloadvalues: any;
  userID = '';
  userName: any;
  data: any;
  navigation: any;
  ccprimesplitbuttonList: any[] = [];
  rolecount: any;
  groupcount: any;
  items!: MenuItem[];
  ref!: DynamicDialogRef;
  msgs: Message[] = [];
  policyarr: any[] = [];
  validateArr: any[] = [];
  messageFlag = true;
  ccprimebreadcrumbList = [{ label: `My Account` }, { label: `Settings` }];
  homeiconccprimebreadcrumb = { icon: 'pi pi-home', routerLink: ['/'] };
  policyFlag = false;
  errorFlag = false;
  changePwd: any;
  index = 0;
  newPwdSimilar = false;
  confirmPwdFlag = true;
  ldapFlag: any;
  setPwdFlag = false;
  oldPwdCheck = false;
  confMsgFlag = false;
  newPwdFlag = true;
  data1 = {
    statusFlag: null,
    value: null,
  };
  redirect: any;
  fullname: string;
  tenantList: any[] = [];
  navProfile: any;
  moduleID = "";
  checkboxArr: any = [];
  customFielDto = [];
  viewMore: boolean = true;
  viewToggleName: string;
  viewToggleIcon: string;
  otherDetails: any = [];
  noDetails: any = [];
  someArr: any = [];
  customJSON: any;
  customList: any = [];
  fieldTypeList: any = [];
  userType: any;
  effectiveRoleListFlag = false;
  tableList1: any[] = [];
  tableList2: any[] = [];
  tableList: any[] = [];
  applicationCount: any;
  tablelistFlag = false;
  List1: any[] = [];
  List2: any[] = [];
  respCustomList: any[] = [];
  uname: any;
  attributeList: any = [];
  userParameterList: any[] = [];
  attributeTableList: any[] = [];
  userParamJson: any = [];
  rebacFlag: string;
  isExternal: any;
  //entitlement
  umPkId: any = null;
  entitlementStatusDropdownList: any = [];
  entitlementStatusResponse: any = [];
  allowStatusId: any;
  pendingStatusId: any;
  componentDropdownList: any[] = [];
  bankList: any[] = [];
  originalBankList: any = [];
  entitlementList: any[] = [];
  originalEntitlementList: any[] = [];
  totalDependentCount: number = 0;
  deletedEntOfUser: any[] = [];
  isEmailSendFlag: any[] = [];
  loginUserName: string;
  userIdentity: string;
  entitlementCount: number = 0;
  emptyFlag: number = 0;
  userRoleList: any[] = [];
  originalUserRoleList: any[] = [];
  entRoleCount: number = 0;
  userRoleColumns: { field: string; header: string; filterMatchMode: string; display: boolean; }[];
  selectedUserRoleData: any;
  multiTenancyFlag: string;
  realm: string;
  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    private messageService: MessageService,
    public dialogService: DialogService,
    public main: MainComponent,
    public datepipe: DatePipe,
    private myAccountServcie: MyAccountService,
    private dateFormatterPipe: DateFormatterPipe
  ) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    this.changePwd = navigation?.extras?.state?.changePassword;
    this.navProfile = navigation?.extras?.state?.navigateToProfile;
    if (this.changePwd) {
      this.index = 2;
      this.handleChange(2);
    }
    if (data) {
      this.MyAccountForm.patchValue(JSON.parse(data));
    }
    let userName: string;
    userName = localStorage.getItem('username');
    if (userName.length > 0) {
      this.loginUserName = userName;
    } else {
      this.loginUserName = 'anonymousUser';
    }
  }

  ngOnInit(): void {
    this.multiTenancyFlag = environment.MULTI_TENANCY_ENABLE + "";
    if (this.multiTenancyFlag == "true") {
      this.realm = location?.hostname.slice(0, location?.hostname.indexOf('.'));
      this.pagesService.realm = this.realm;
      environment.KEY_CLOAK_REALM_NAME = this.realm;
      MainComponent.changedRealm = this.realm;
    }
    else {
      this.realm = KEY_CLOAK_REALM_NAME;
      this.pagesService.realm = this.realm;
      MainComponent.changedRealm = this.realm;
    }
    this.redirect = environment.IMAGE_PATH;
    this.rebacFlag = REBAC_UM_ENTITLEMENT_FLAG + "";
    if (localStorage?.getItem("navbarFlag") == null) {
      this.getTenantList();
    }
    if (localStorage?.getItem("navbarFlag") == 'Yes') {
      this.pagesService.tenantId = localStorage.getItem("tenant");
      this.pagesService.tenantName = localStorage.getItem("tenantName");
      this.main.items = [];
      const migrationRoleFlag = environment.CIAM_ROLE_MIGRATION_FLAG + "";
      if (migrationRoleFlag == "true") {
        this.main.getCiamApplicationDetails();
      }
      else {
        this.main.getRoles();
      }
    }
    this.userName = this.getValue();
    if (this.pagesService.tenantId == null || this.pagesService.tenantId == undefined) {
      this.getTenantList();
    }
    else {
      this.getUserID();
      this.viewToggle();
      this.ShowPasswordPolicy();
    }
    if (this.MyAccountForm.value.middleName == null) {
      this.fullname = this.MyAccountForm.value.firstName + " " + this.MyAccountForm.value.lastName;
    }
    else {
      this.fullname = this.MyAccountForm.value.firstName + " " + this.MyAccountForm.value.middleName + " " + this.MyAccountForm.value.lastName;
    }

  }
  getEntitlementStatusList() {
    this.entitlementStatusDropdownList = [];
    this.myAccountServcie.getEntitlementStatusList().subscribe(
      {
        next: response => {
          let statusRes = response;
          this.entitlementStatusResponse = statusRes;
          let namesArray: string[] = [ALLOW, DENY];
          for (let i = 0; i < this.entitlementStatusResponse.length; i++) {
            if (namesArray.includes(this.entitlementStatusResponse[i].NAME)) {
              let res = statusRes[i];
              this.entitlementStatusDropdownList.push({ label: res.NAME, value: res.ID });
            }
          }

          this.allowStatusId = this.entitlementStatusDropdownList.filter(status => status.label == ALLOW)[0].value;
          this.pendingStatusId = this.entitlementStatusResponse.filter(status => status.NAME == PENDING)[0].ID;

          this.getComponentList();
        },
        error: err => {
          this.showErrorValidations(err.error);
        }
      });
  }

  showErrorValidations(message: string) {
    this.messageFlag = true;
    this.messageService.add({ key: 'myKey1', severity: 'error', detail: message });
    setTimeout(() => {
      this.messageFlag = false;
    }, 3000);
  }

  getComponentList() {
    this.myAccountServcie.getComponentList().subscribe(
      {
        next: response => {
          this.componentDropdownList = response;
          this.getUserEntitlementList();
        },
        error: err => {
          this.showErrorValidations(err.error);
        }
      });
  }

  async getUserEntitlementList() {
    this.bankList = [];
    this.originalBankList = [];
    this.entitlementList = [];
    this.originalEntitlementList = [];
    this.totalDependentCount = 0;
    this.deletedEntOfUser = [];
    this.isEmailSendFlag = [];
    const formData: FormData = new FormData();
    formData.append('userId', this.umPkId);
    if (this.umPkId == null) {
      //dont do anything
    }
    else {

      await this.myAccountServcie.getUserEntitlementList(formData).subscribe(//NOSONAR
        {
          next: async response => { //NOSONAR
            let nonDeletedEntOfUser: any = [];
            nonDeletedEntOfUser = response['USER_ENTITLEMENTS'].filter(data => data['IS_DELETED'] == 'N');
            this.deletedEntOfUser = response['USER_ENTITLEMENTS'].filter(data => data['IS_DELETED'] == 'Y');
            let entitlementResponse = nonDeletedEntOfUser;
            for (let i = 0; i < entitlementResponse.length; i++) {
              entitlementResponse[i]['USER_TYPE_ID'] = response['USER_TYPE_ID'];
              entitlementResponse[i]['COMPONENT_NAME'] = this.componentDropdownList.filter(
                c => c.PRIME_COMPONENT_ID == entitlementResponse[i].COMPONENT_ID
              )[0].COMPONENT_NAME;

              if (
                entitlementResponse[i]['OVERALL_STATUS'] === PENDING ||
                (this.isExternal?.toString().toLowerCase() == INTERNAL?.toString().toLowerCase() &&
                  entitlementResponse[i].IS_ADMS_ENTITLEMENT == ONE &&
                  entitlementResponse[i].IS_ADMS_APPROVED == ZERO)
              ) {
                entitlementResponse[i]['OVERALL_STATUS'] = DENY;
              }

              if (entitlementResponse[i].DEPENDENT_LIST && entitlementResponse[i].DEPENDENT_LIST.length > 0) {
                this.totalDependentCount++;
                let dependenList = [];
                let dependenData = entitlementResponse[i].DEPENDENT_LIST.toString().split(',');
                for (let j = 0; j < dependenData.length; j++) {
                  let rowData = dependenData[j].toString().split(':');
                  let data = {
                    ENTITLEMENT_NAME: rowData[1]
                  };
                  dependenList.push(data);
                }
                entitlementResponse[i]['DEPENDENT_LIST'] = dependenList;
              }

              if (entitlementResponse[i].hasOwnProperty('SOURCE')) {
                entitlementResponse[i]['SOURCE'] = entitlementResponse[i]['SOURCE'].toString().replaceAll(',', ' \n ');
              }
              let approvalLevels = [];
              if (entitlementResponse[i].APPROVAL_LEVELS) {
                let approvalList = entitlementResponse[i].APPROVAL_LEVELS.toString().split(';');
                for (let k = 0; k < approvalList.length; k++) {
                  let rowData = approvalList[k].toString().split('|');
                  let data = {
                    USER_NAME: rowData[0],
                    LEVEL_NO: rowData[1],
                    STATUS: rowData[2],
                    ACTION_DATE: this.dateFormatterPipe.transform(rowData[3])
                  };
                  approvalLevels.push(data);
                }
                entitlementResponse[i]['APPROVAL_LEVELS'] = approvalLevels;
              }

              let rejectedApproval = approvalLevels.filter(app => app.STATUS == NOT_APPROVED);
              if (rejectedApproval.length > 0) {
                rejectedApproval[0]['COLOR'] = '#D6002A';
                rejectedApproval = approvalLevels
                  .filter(app => app.STATUS == NOT_APPROVED)
                  .map(a => {
                    if (this.loginUserName.toLowerCase() === approvalLevels[0]['USER_NAME'].toLowerCase()) {
                      (a['COLOR'] = '#e91224'); (a['TOOLTIP'] = 'Not Approved by ' + 'you' + ' on ' + a['ACTION_DATE']);
                    } else {
                      (a['COLOR'] = '#e91224'); (a['TOOLTIP'] = 'Not Approved by ' + a.USER_NAME + ' on ' + a['ACTION_DATE']);
                    }
                  });
                approvalLevels  //NOSONAR
                  .filter(app => app.STATUS != NOT_APPROVED)
                  .map(a => {
                    (a['COLOR'] = '#6e706e'); (a['TOOLTIP'] = 'Approval process is disabled.');
                  });
              } else {
                let approvedApproval = approvalLevels
                  .filter(app => app.STATUS == APPROVED)
                  .map(a => {
                    if (this.loginUserName.toLowerCase() === a['USER_NAME'].toLowerCase()) {
                      (a['COLOR'] = '#9AE198'); (a['TOOLTIP'] = 'Approved by ' + 'you' + ' on ' + a['ACTION_DATE']);
                    } else {
                      (a['COLOR'] = '#9AE198'); (a['TOOLTIP'] = 'Approved by ' + a.USER_NAME + ' on ' + a['ACTION_DATE']);
                    }
                  });
                if (approvedApproval.length > 0) {
                  approvalLevels  //NOSONAR
                    .filter(app => app.STATUS != APPROVED)
                    .map(a => {
                      if (this.loginUserName.toLowerCase() === approvalLevels[0]['USER_NAME'].toLowerCase()) {
                        (a['COLOR'] = '#e91224'); (a['TOOLTIP'] = 'Not Approved by ' + 'you' + ' on ' + a['ACTION_DATE']);
                      } else {
                        (a['COLOR'] = '#e91224'); (a['TOOLTIP'] = 'Not Approved by ' + a.USER_NAME + ' on ' + a['ACTION_DATE']);
                      }
                    });
                  approvalLevels //NOSONAR
                    .filter(app => app.STATUS != NOT_APPROVED)
                    .map(a => {
                      (a['COLOR'] = '#6e706e'); (a['TOOLTIP'] = 'Approval process is disabled.');
                    });
                } else {
                  let pendingApproval = approvalLevels.filter(app => app.STATUS == PENDING);
                  if (pendingApproval.length > 0) {
                    approvalLevels.map(a => { //NOSONAR
                      if (this.loginUserName.toLowerCase() === approvalLevels[0]['USER_NAME'].toLowerCase()) {
                        (a['COLOR'] = '#F4B40F');
                        (a['TOOLTIP'] = ' Raised by ' + 'you' + ' on ' + pendingApproval[0].ACTION_DATE);
                      } else {
                        (a['COLOR'] = '#9AE198'); (a['TOOLTIP'] = 'Approved by ' + a.USER_NAME + ' on ' + a['ACTION_DATE']);
                      }
                    });
                    if (approvedApproval.length > 0) {
                      approvalLevels //NOSONAR
                        .filter(app => app.STATUS != APPROVED)
                        .map(a => {
                          if (this.loginUserName.toLowerCase() === approvalLevels[0]['USER_NAME'].toLowerCase()) {
                            (a['COLOR'] = '#F4B40F'); (a['TOOLTIP'] = ' Raised by ' + 'you' + ' on ' + a['ACTION_DATE']);
                          } else {
                            (a['COLOR'] = '#F4B40F'); (a['TOOLTIP'] = ' Raised by ' + a.USER_NAME + ' on ' + a['ACTION_DATE']);
                          }
                        });
                    } else {
                      let pendingApproval = approvalLevels.filter(app => app.STATUS == PENDING);
                      if (pendingApproval.length > 0) {
                        approvalLevels.map(a => { //NOSONAR
                          if (this.loginUserName.toLowerCase() === approvalLevels[0]['USER_NAME'].toLowerCase()) {
                            (a['COLOR'] = '#F4B40F');
                            (a['TOOLTIP'] = ' Raised by ' + 'you' + ' on ' + pendingApproval[0].ACTION_DATE);
                          } else {
                            (a['COLOR'] = '#F4B40F');
                            (a['TOOLTIP'] =
                              ' Raised by ' + pendingApproval[0].USER_NAME + ' on ' + pendingApproval[0].ACTION_DATE);
                          }
                        });
                      }
                    }
                  }
                }
                this.entitlementList = entitlementResponse;
                this.entitlementCount = this.entitlementList.length;
                this.originalEntitlementList = this.entitlementList;
                let localTime;
                if (this.entitlementList.length > 0) {
                  this.emptyFlag = 1;
                }
                else {
                  this.emptyFlag = 0;
                }

              }
            }
          },
          error: error => {
            this.showErrorValidations(error.error);
          }
        });
    }
    this.getUserRoleList();
  }
  getEntityList(): void {
    this.pagesService.getEntity().subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData?.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          if (data.label == 'Users') {
            this.moduleID = data.value;
            this.fetchCustomField();
          }
        });
      }
    });
  }
  getValue() {
    const uname = JSON.parse(localStorage.getItem('profile'));
    this.ldapFlag = uname.attributes?.LDAP_ENTRY_DN;
    this.MyAccountForm.get('newPassword').disable();
    this.MyAccountForm.get('confirmPassword').disable();
    if (this.userIdentity == "AD") {
      this.setPwdFlag = false;
    }
    else {
      this.setPwdFlag = true;
    }
    return uname.username;
  }

  //getting user details 
  getUserID() {
    this.pagesService.getUserDetailsByUserName(this.userName).subscribe((results: any) => {
      this.userID = results.success.result.id;
      this.pagesService.userId = this.userID;
      let pagePropBind = {
        viewUserID: results.success.result.id,
        userName: results.success.result.user_name,
        createdOn: results.success.result.createdDate,
        primaryEmail: results.success.result.emails[0]?.value,
        contactNumber: results.success.result.phoneNumbers[0]?.value,
        countryCode: results.success.result.phoneNumbers[0]?.countryCode,
        statusMode: results.success.result.user_status,
        middleName: results.success.result.middle_name,
        dormancyReason: results.success.result.dormancy_reason,
        firstName: results.success.result.first_name,
        lastName: results.success.result.last_name,
        mirrorFlag: 'viewMirror',
        umPkId: results.success.result.um_pk_id,
        userIdentity: results.success.result.userType
      };
      this.respCustomList = results.success.result.customFieldDataDtoList;
      this.umPkId = results.success.result.um_pk_id;
      if (results.success.result.userType == AD_USER) {
        this.userIdentity = "AD";
      }
      else if (results.success.result.userType == DB_USER) {
        this.userIdentity = "DB";
      }
      else {
        this.userIdentity = null;
      }
      this.userParamJson = JSON.parse(results.success.result.userParamJson);
      this.userType = results.success.result.isExternal;
      this.pageloadvalues = pagePropBind;
      this.setPageProperties(pagePropBind);
      this.otherDetails = [];

      if (this.rebacFlag == "true") {
        this.userRoleColumns = [
          { field: 'Role ID', header: 'Role ID', filterMatchMode: 'contains', display: true },
          { field: 'Role Name', header: 'Role Name', filterMatchMode: 'contains', display: true },
          { field: 'Updated On', header: 'Updated On', filterMatchMode: 'contains', display: true }
        ];
        this.getEntitlementStatusList();
        this.fetchAttributeList();
      }
      if (this.rebacFlag == "false") {
        this.accessDetails();
        this.onMyAccountPageLoad();
      }
      if (this.MyAccountForm.value.middleName == null) {
        this.fullname = this.MyAccountForm.value.firstName + " " + this.MyAccountForm.value.lastName;
      }
      else {
        this.fullname = this.MyAccountForm.value.firstName + " " + this.MyAccountForm.value.middleName + " " + this.MyAccountForm.value.lastName;
      }
      this.getEntityList();
    });
  }

  navigateToDetailScreen(entitlementData) {
    this.router.navigateByUrl('/pages/entitlementsdetails', {
      state: { entitlement: entitlementData },
      skipLocationChange: false
    });
    let count = 2;
    this.main.items[count].expanded = true;
    this.main.items[this.main.items.length - 1].expanded = false;
  }
  //getting user role on page load
  onMyAccountPageLoad() {
    this.rolecount = 0;
    this.groupcount = 0;
    const reqBody = {};
    reqBody['tenantId'] = this.pagesService.tenantId;
    reqBody['userId'] = this.userID;
    const formValues = reqBody;
    this.pagesService.getViewUserEffectiveRoles(formValues).subscribe((results: any) => {
      let responseData = [];
      if (results.hasOwnProperty("success")) {
        responseData = results.success.result;
      }
      else {
        responseData = [];
      }
      if (responseData && responseData?.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            roleId: null,
            roleName: null,
            groupList: null,
            newGroupList: null,
            tempGroupList: []
          };
          data.roleId = element.roleId;
          data.roleName = element.roleName;
          data.groupList = element.groupList;
          element.groupList.forEach((element1: any = {}) => {
            if (element1.groupName != null) {
              data.tempGroupList.push(element1.groupName);
            }
          });
          data.newGroupList = data.tempGroupList.toString();
          this.tableList1.push(data);
          this.tableList2.push(data);
        });
        this.tableList1 = [...this.tableList1];
        this.tableList2 = [...this.tableList2];
        this.effectiveRoleListFlag = true;
      } else {
        this.effectiveRoleListFlag = false;
      }
      this.rolecount = 0;
      if (this.tableList1?.length >= 1) {
        this.rolecount = this.tableList1?.length;
      }
    });
    this.pagesService.getViewUserGroups(this.userID).subscribe((results: any) => {
      let pagePropBind = {
        groups: results?.success?.result,
      };
      this.groupcount = 0;
      if (pagePropBind?.groups?.length >= 1) {
        this.groupcount = pagePropBind?.groups?.length;
      }
      this.pageloadvalues = pagePropBind;
      this.setPageProperties(pagePropBind);
    });

  }

  setPageProperties(props: any): void {
    const pageData: any = {};
    const calendarControls = ['createdOn'];
    Object.keys(props).forEach(key => {
      if (calendarControls.includes(key)) {
        pageData[key] = new Date(props[key]);
      } else {
        pageData[key] = props[key];
      }
    });
    this.userID = pageData.viewUserID;
    this.MyAccountForm.patchValue(pageData);
  }
  /**
     * Application tab for access details
     */

  // getting application and roles of the user
  accessDetails(): void {
    const reqBody = {};
    reqBody['tenantId'] = this.pagesService.tenantId;
    reqBody['userId'] = this.userID;
    const formValues = reqBody;
    this.tableList = [];
    this.applicationCount = 0;
    this.pagesService.accessDetailUserApplicationRoles(formValues).subscribe({
      next: results => {
        const responseData = results?.success?.result;
        this.applicationCount = responseData?.length;
        if (responseData == null || responseData == undefined) {
          this.tablelistFlag = true;
          this.applicationCount = 0;
        }
        this.tableList = [...responseData];
        for (let j = 0; j < responseData?.length; j++) {
          this['len' + j] = responseData[j]?.applicationRoles?.length - 3;
          this['attrLen' + j] = responseData[j]?.attributes?.length - 3;
          this['tableListdata' + j] = [];
          //for application roles
          if (responseData[j]?.applicationRoles && responseData[j]?.applicationRoles?.length > 0) {
            responseData[j]?.applicationRoles.forEach((element: any = {}) => {
              const data = {
                label: null,
                value: null,
              };
              data.label = element.label;
              data.value = element.value;
              this['tableListdata' + j].push(data);
            });

            this['items1' + j] = [];
            if (this['tableListdata' + j]?.length > 0) {
              for (let i = 0; i < responseData[j]?.applicationRoles?.length; i++) {
                if (i > 2) {
                  this['items1' + j].push({
                    label: this['tableListdata' + j][i]?.label,
                  })
                }
              }
            }
            this.List1 = [...this.List1];
          }
        }

        //for attributes
        for (let j = 0; j < responseData?.length; j++) {
          this['attrLen' + j] = responseData[j]?.attributes?.length - 3;
          this['tableListdata' + j] = [];
          //for application roles
          if (responseData[j]?.attributes && responseData[j]?.attributes?.length > 0) {
            responseData[j]?.attributes.forEach((element: any = {}) => {
              const data = {
                label: null,
                value: null,
              };
              data.label = element.label;
              data.value = element.value;
              this['tableListdata' + j].push(data);
            });

            this['items2' + j] = [];
            if (this['tableListdata' + j]?.length > 0) {
              for (let i = 0; i < responseData[j]?.attributes?.length; i++) {
                if (i > 2) {
                  this['items2' + j].push({
                    label: this['tableListdata' + j][i]?.label,
                  })
                }
              }
            }
            this.List2 = [...this.List2];
          }
        }
      },
      error: error => {
        this.tablelistFlag = true;
      }
    });
  }

  //validating password
  ValidatePasswordPolicy(event: any) {
    this.newPwdSimilar = false;
    this.confMsgFlag = false;
    this.MyAccountForm.controls.confirmPassword.reset();
    if (event?.length > 0) {
      this.validateArr = [];
      this.policyFlag = true;
      const reqBody = {};
      reqBody['userName'] = this.userName;
      reqBody['password'] = this.MyAccountForm.value.newPassword;
      const formValues = reqBody;
      this.pagesService.validatePasswordPolicy(formValues).subscribe((results: any) => {
        if (results.success.result.hasOwnProperty("success")) {
          for (let i = 0; i < this.policyarr?.length; i++) {
            this.validateArr.push(true);
          }
          this.validateArr = [...this.validateArr];
          this.MyAccountForm.get('confirmPassword').enable();
        }
        else if (results.success.result.hasOwnProperty("Errors")) {
          for (let i = 0; i < this.policyarr?.length; i++) {
            this.validateArr.push(true);
          }
          this.newPwdSimilar = true;
          this.validateArr = [...this.validateArr];
          this.MyAccountForm.get('confirmPassword').disable();
        }
        else if (results.success.result.hasOwnProperty("data")) {
          let responseData = results.success.result.data;
          responseData.sort((a, b) => a.id - b.id);
          if (responseData && responseData?.length > 0) {
            responseData.forEach((element: any = {}) => {
              const data = {
                label: null,
                value: null,
              };
              data.label = element.status;
              data.value = element.id;
              this.validateArr.push(data.label);
            });
            this.validateArr = [...this.validateArr];
          }
          this.errorFlag = true;
          this.MyAccountForm.get('confirmPassword').disable();
        }
      });
    }
    else {
      this.policyFlag = false;
    }
  }

  //getting password policy API
  ShowPasswordPolicy() {
    this.pagesService.getPasswordPolicy().subscribe((results: any) => {
      const responseData = results?.success?.result;
      if (responseData && responseData?.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.message;
          data.value = element.id;
          this.policyarr.push(data);
        });
        this.policyarr = [...this.policyarr];
      }
    });
  }

  // matching password functionality
  MatchPassword(event: any) {
    this.policyFlag = false;
    const newPassword = this.MyAccountForm.value.newPassword;
    if (event == newPassword || event?.length == 0) {
      this.confMsgFlag = false;
      this.errorFlag = false;
    }
    else {
      document.getElementById('confirm_id').classList.add('ng-dirty');
      document.getElementById('confirm_id')!.style.borderColor = 'red';
      this.confMsgFlag = true;
      this.errorFlag = true;
    }
  }
  disablePwd() {
    this.MyAccountForm.get('newPassword').enable();

  }
  handleChange(e) {
    let index = e;
    if (index == 0) {
      this.newPwdSimilar = false;
      this.confirmPwdFlag = true;
      this.MyAccountForm.controls.oldPassword.reset();
      this.MyAccountForm.controls.newPassword.reset();
      this.MyAccountForm.controls.confirmPassword.reset();
      this.errorFlag = true;
      this.validateArr = [];
      this.policyFlag = false;
      this.newPwdFlag = true;
      this.confMsgFlag = false;
      this.oldPwdCheck = false;
      this.ccprimebreadcrumbList = [{ label: `My Account` }, { label: `Profile` }];
    }
    else if (index == 1) {
      this.ccprimebreadcrumbList = [{ label: `My Account` }, { label: `Preferences` }];
    }
    else if (index == 2) {
      this.ccprimebreadcrumbList = [{ label: `My Account` }, { label: `Change Password` }];
    }
    else if (index == 3) {
      this.ccprimebreadcrumbList = [{ label: `My Account` }, { label: `Set Prefered MFA` }];
    }
    else if (index == 4) {
      this.ccprimebreadcrumbList = [{ label: `My Account` }, { label: `Logs` }];
    }
  }

  onCcprimebutton($event: UntypedFormGroup): void {
    //empty
  }

  //saving password API
  SavePassword($event: UntypedFormGroup): void {
    const reqBody = {};
    reqBody['userName'] = this.userName;
    reqBody['oldPassword'] = this.MyAccountForm.value.oldPassword;
    reqBody['newPassword'] = this.MyAccountForm.value.newPassword;
    reqBody['confPassword'] = this.MyAccountForm.value.confirmPassword;
    const formValues = reqBody;
    this.pagesService.setNewPassword(formValues).subscribe(
      {
        next: results => {
          if (results.success.result.hasOwnProperty("data")) {
            // ignore
          }
          else if (results.success.result.hasOwnProperty("Errors")) {
            this.messageFlag = true;
            this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'New password Cannot be similar to last 3 passwords.' });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
            this.newPwdFlag = true;
            this.confirmPwdFlag = false;
            this.MyAccountForm.controls.oldPassword.reset();
            this.MyAccountForm.controls.newPassword.reset();
            this.MyAccountForm.controls.confirmPassword.reset();
            this.errorFlag = true;

          }
          else {
            this.messageFlag = true;
            this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'Password saved Successfully.' });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
            this.newPwdSimilar = false;
            this.confirmPwdFlag = true;
            this.MyAccountForm.controls.oldPassword.reset();
            this.MyAccountForm.controls.newPassword.reset();
            this.MyAccountForm.controls.confirmPassword.reset();
            this.errorFlag = true;
            this.validateArr = [];
            this.policyFlag = false;
            this.newPwdFlag = true;
            window.location.href = environment.KEY_CLOAK_URL + "/realms/" + this.realm + "/protocol/openid-connect/auth?client_id=" + environment.KEY_CLOAK_CLIENT_ID + "&redirect_uri=" + location.origin;
          }
        },
        error: error => {
          if (error.error.error?.reason == "Existing password mismatch") {
            document.getElementById('oldPwd_id')!.style.borderColor = 'red';
            this.oldPwdCheck = true;
            this.MyAccountForm.controls.newPassword.reset();
            this.MyAccountForm.controls.confirmPassword.reset();
            this.confirmPwdFlag = true;
            this.confMsgFlag = false;
            this.validateArr = [];
            this.policyFlag = false;
            this.newPwdFlag = true;
          }
          else if (error.error.error?.reason == "Confirm password mismatch") {
            this.confMsgFlag = true;
          }
        }
      });
  }

  getTenantList() {
    this.userName = localStorage.getItem("username");
    const uName = this.userName;
    this.pagesService.getTenantListByUsername(uName).subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData?.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          if (element.label == this.realm) {
            this.pagesService.tenantId = element.value;
          }
          this.tenantList.push(data);
        });
        this.tenantList = [...this.tenantList];
        this.getUserID();
        this.ShowPasswordPolicy();
        this.main.items = [];
        const migrationRoleFlag = environment.CIAM_ROLE_MIGRATION_FLAG + "";
        if (migrationRoleFlag == "true") {
          this.main.getCiamApplicationDetails();
        }
        else {
          this.main.getRoles();
        }
      }
    });
  }
  viewToggle() {
    this.viewMore = !this.viewMore;
    if (this.viewMore) {
      this.viewToggleName = "View Less";
      this.viewToggleIcon = "pi pi-chevron-up";
    }
    else {
      this.viewToggleName = "View More";
      this.viewToggleIcon = "icon-arrowDown";
    }
  }

  fetchCustomField() {
    this.customList = [];
    this.pagesService.getFetchCustomFieldListing(this.moduleID).subscribe(results => {
      const responseData = results?.success?.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            id: null,
            fieldName: null,
            fieldInputtype: null,
            inputFormat: null,
            mandatoryField: null,
            editableField: null,
            configJson: null,
            maxCharacters: null,
            pattern: null,
            dropdownValu: null,
          };
          data.id = element.id;
          data.fieldName = element.fieldName;
          this.customJSON = JSON.parse(element.fieldConfigJason);
          data.configJson = this.customJSON;
          data.fieldInputtype = data.configJson.fieldLabel;
          data.inputFormat = data.configJson.inputvalue_format;
          data.mandatoryField = data.configJson.mandatory;
          data.editableField = data.configJson.editable;
          data.maxCharacters = data.configJson.MaxCharacters;
          this.customList.push(data);
        });
        this.customList = [...this.customList];
        let cc = this.respCustomList;
        this.otherDetails = [];
        this.someArr = [];
        if (cc == null) {
          this.customList.forEach(element => {
            this.otherDetails.push({ label: element.fieldName, value: '-' });
          });
        }
        else {
          cc.forEach(element => {
            this.someArr.push(element.customFieldId);
          });
          this.customList.forEach(element1 => {
            if (this.someArr.includes(element1.id)) {
              cc.forEach(element2 => {
                if (element2.moduleId == element1.id) {
                  if (element1.fieldInputtype == "Checkbox") {
                    let valCheck = element2.customFieldValue
                    valCheck = valCheck[0][0].toUpperCase() + valCheck.substr(1);
                    this.otherDetails.push({ label: element1.fieldName, value: valCheck });
                  }
                  else if (element1?.inputFormat[0] == 'Date range') {
                    let dd = JSON.parse(element2.customFieldValue);
                    let startDate = this.datepipe.transform(dd[0], 'dd-MM-yyyy');
                    let endDate = this.datepipe.transform(dd[1], 'dd-MM-yyyy');
                    this.otherDetails.push({ label: element1.fieldName, value: startDate + " to " + endDate });
                  }
                  else if (element1?.inputFormat[0] == 'Text/Number range') {
                    let dd = JSON.parse(element2.customFieldValue);
                    let startRange = dd.start;
                    let endRange = dd.last;
                    this.otherDetails.push({ label: element1.fieldName, value: startRange + " to " + endRange });
                  }
                  else {
                    this.otherDetails.push({ label: element1.fieldName, value: element2.customFieldValue });
                  }
                }
              });
            }
            else {
              this.otherDetails.push({ label: element1.fieldName, value: '-' });
            }
          });
        }
      }
    });
  }

  fetchAttributeList() {
    let reqBody = {};
    reqBody["tenantId"] = this.pagesService.tenantId;
    reqBody["userId"] = this.userID;
    const formValues = reqBody;
    this.pagesService.getAccountParameter(formValues).subscribe(results => {
      const responseData = results?.success?.result;
      let accountParamJson = JSON.parse(responseData?.accountParamJson);
      if (accountParamJson && accountParamJson.length > 0) {
        accountParamJson.forEach((element: any = {}) => {
          if (element.isConfigurable) {
            const data = {
              id: null,
              fieldName: null,
              fieldValue: null,
              fieldInputtype: null
            }
            data.id = element.id;
            data.fieldName = element.fieldName;

            data.fieldInputtype = element.fieldInputtype;
            if (data.fieldInputtype == "Dropdown") {
              data.fieldValue = element.fieldValue.value;
            }
            else {
              data.fieldValue = element.fieldValue;
            }
            this.attributeList.push(data);

          }
        });
      }
      this.getUserParameter();
    });
  }

  getUserParameter(): void {

    if (this.userParamJson && this.userParamJson.length > 0) {
      this.userParamJson.forEach((element: any = {}) => {
        const data = {
          id: null,
          fieldName: null,
          fieldValue: null,
          fieldInputtype: null
        }
        data.id = element.id;
        data.fieldName = element.fieldName;

        data.fieldInputtype = element.fieldInputtype;
        if (data.fieldInputtype == "Dropdown") {
          data.fieldValue = element.fieldValue.value;
        }
        else {
          data.fieldValue = element.fieldValue;
        }
        this.userParameterList.push(data);
      });
      this.checkOveriddenParam();
    }
    else {
      this.attributeTableList = this.attributeList;
    }
  }

  checkOveriddenParam() {
    let removeAccData = [];
    this.userParameterList.forEach(
      (element1, index) => {
        this.attributeList.forEach(
          (element2, index2) => {
            if (element1.id == element2.id) {
              if (element1.fieldInputtype == "Dropdown") {
                if (element1.fieldValue != element2.fieldValue) {
                  removeAccData.push(element1.id);
                  this.attributeTableList.push(element1);
                }
                else if (element1.fieldValue == element2.fieldValue) {
                  removeAccData.push(element1.id);
                  this.attributeTableList.push(element2);
                }
              }
              else {
                removeAccData.push(element1.id);
                this.attributeTableList.push(element1);
              }
            }
          }
        );
      }
    );
    if (removeAccData.length > 0) {
      let myNewArray;
      let myNewArray2;
      for (let i = 0; i < removeAccData.length; i++) {
        myNewArray = this.userParameterList.filter(function (item) { return item.id !== removeAccData[i] });

        myNewArray2 = this.attributeList.filter(function (item) { return item.id !== removeAccData[i] });

        this.userParameterList = myNewArray;
        this.attributeList = myNewArray2;
      }
      this.attributeTableList.push(...myNewArray2);
      this.attributeTableList.push(...myNewArray);

    }
    else {
      this.attributeTableList.push(...this.attributeList);
      this.attributeTableList.push(...this.userParameterList);
    }

  }

  getUserRoleList() {
    this.originalUserRoleList = [];
    this.userRoleList = [];
    const formData: FormData = new FormData();
    formData.append('userId', this.umPkId);
    this.myAccountServcie.getUserRoleList(formData).subscribe(
      {
        next: response => {
          response.forEach(
            res => (res.CREATED_DATE = this.datepipe.transform(res.CREATED_DATE, 'MMM d, y, h:mm:ss a'))
          );
          this.userRoleList = response;
          this.entRoleCount = response.length;
          this.originalUserRoleList = JSON.parse(JSON.stringify(this.userRoleList));
        },
        error: err => {
          this.showErrorValidations(err.error);
        }
      });
  }

  navigateToRoleDetailScreen(roleData) {
    this.selectedUserRoleData = roleData;
    this.selectedUserRoleData['PRIME_ROLE_ID'] = roleData.ID;
    this.selectedUserRoleData['ROLE_NAME'] = roleData.NAME;
    this.router.navigateByUrl('/pages/roledetails', {
      state: { role: roleData },
      skipLocationChange: false
    });
  }
}
