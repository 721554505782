<div style="background-color: #F6F8FA;">
    <div class="p-d-flex p-jc-end">
        <p-messages key="myKey1" class="custom-alert-box" *ngIf="this.messageFlag"></p-messages>
        <p-messages key="myKey2" class="custom-alert-box" *ngIf="this.messageFlag1"></p-messages>
    </div>
    <div class="p-col-fixed p-mt-1 p-ml-1 p-pr-0 custom-breadcrumb">
        <p-breadcrumb [model]="ccprimebreadcrumbList" [home]="homeiconccprimebreadcrumb">
        </p-breadcrumb>
    </div>
    <form class="layout-content custom-form-layout" [formGroup]="UserGroupMappingForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                <h5 class="custom-head1">User Group Mapping</h5>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                <div class=" p-fluid   p-field    ">
                    <label [ngClass]="{'field-required': UserGroupMappingForm.get('select2')?.errors?.required}">
                        User Group
                    </label> <span class='invalid-feedback-astrik'>*</span>
                    <div class="p-fluid">
                        <p-dropdown id="select2id" name="select2" placeholder="Select" [options]="select2List"
                            [filter]="true" [autoDisplayFirst]="false" formControlName="select2" filterBy="label"
                            [showClear]="true" (onChange)="dropdownDis();getApplicationList();">
                            <ng-template let-item pTemplate="item">
                                <div *ngIf="item.label.length &lt;= 35;">
                                    {{item.label}}
                                </div>
                                <div *ngIf="item.label.length > 35;" pTooltip="{{item.label}}" tooltipPosition="bottom">
                                    {{item.label.substring(0,35) + "..." }}
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                    <div class="invalid-feedback"
                        *ngIf="UserGroupMappingForm.get('select2')!.invalid && (UserGroupMappingForm.get('select2')!.dirty || UserGroupMappingForm.get('select2')!.touched)">
                        <div *ngIf="UserGroupMappingForm.get('select2')?.errors?.required">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium class="p-error">User group is required.</medium>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                <div class=" p-fluid   p-field    ">
                    <label [ngClass]="{'field-required': UserGroupMappingForm.get('select')?.errors?.required}">
                        Application
                    </label> <span class='invalid-feedback-astrik'>*</span>
                    <div class="p-fluid">
                        <p-dropdown id="selectid" name="select" placeholder="Select" [options]="selectList"
                            [(ngModel)]="this.application" [filter]="true" [autoDisplayFirst]="false"
                            formControlName="select" filterBy="label" [showClear]="true" (onChange)="getAttribute()">
                            <ng-template let-item pTemplate="item">
                                <div *ngIf="item.label.length &lt;= 35;">
                                    {{item.label}}
                                </div>
                                <div *ngIf="item.label.length > 35;" pTooltip="{{item.label}}" tooltipPosition="bottom">
                                    {{item.label.substring(0,35) + "..." }}
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                    <div class="invalid-feedback"
                        *ngIf="UserGroupMappingForm.get('select')!.invalid && (UserGroupMappingForm.get('select')!.dirty || UserGroupMappingForm.get('select')!.touched)">
                        <div *ngIf="UserGroupMappingForm.get('select')?.errors?.required">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium class="p-error">Application is required.</medium>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left" *ngIf="hideType">
                <div class=" p-fluid   p-field    ">
                    <label [ngClass]="{'field-required': UserGroupMappingForm.get('select1')?.errors?.required}">
                        Attribute Type
                    </label> <span class='invalid-feedback-astrik'>*</span>
                    <div class="p-fluid">
                        <p-dropdown id="select1id" name="select1" placeholder="Select" [options]="select1List"
                            [filter]="true" [autoDisplayFirst]="false" formControlName="select1"
                            (onChange)="getMappedDetails();getGroupMappedDetails()">
                            <ng-template let-items pTemplate="item">
                                <div *ngIf="items?.label?.length &lt;= 35;">
                                    {{items.label}}
                                </div>
                                <div *ngIf="items?.label?.length > 35;" pTooltip="{{items.label}}"
                                    tooltipPosition="bottom">
                                    {{items.label.substring(0,35) + "..." }}
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>

                    <div class="invalid-feedback"
                        *ngIf="UserGroupMappingForm.get('select1')!.invalid && (UserGroupMappingForm.get('select1')!.dirty || UserGroupMappingForm.get('select1')!.touched)">
                        <div *ngIf="UserGroupMappingForm.get('select1')?.errors?.required">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium class="p-error">Attribute Type is required.</medium>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                <label *ngIf="flag1" class="d-icons group-icon"></label>
                <p-tabView *ngIf="!flag1" (onChange)="handleChange($event)">
                    <p-tabPanel header="Attribute Mapping">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left p-p-0">
                                <div class=" p-fluid   p-field    ">
                                    <div [class]="styleClass" [ngStyle]="style"
                                        class="p-grid custom-picklist-with-checkbox"
                                        [ngClass]="{'p-picklist p-component p-widget p-helper-clearfix': true,'p-picklist-responsive': responsive}">
                                        <div class="p-col-fixed p-picklist-list-wrapper p-picklist-source-wrapper"
                                            [ngClass]="{'p-picklist-listwrapper-nocontrols':!showSourceControls}">
                                            <div class="p-picklist-header">
                                                <div class="p-picklist-title">Attributes
                                                    <span class="p-picklist-count">{{pickList1List.length}}</span>
                                                </div>
                                            </div>
                                            <div class="p-picklist-filter-container"
                                                *ngIf="filterBy && showSourceFilter !== false">
                                                <div class="p-picklist-filter">
                                                    <span class="p-input-icon-left" style="margin: 0px;">
                                                        <input #sourceFilter type="text" role="textbox"
                                                            (keydown.enter)="$event.preventDefault()"
                                                            class="p-picklist-filter-input p-inputtext inputtextHeight p-component"
                                                            placeholder="Search By Name"
                                                            (input)="filterMethodPick($event.target.value,pickList1List1,'pick1source')"
                                                            [disabled]="disabled" formControlName="sourceFilter">
                                                        <i aria-hidden="true" class="pi pi-search"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <ul #sourcelist class="cdk-drop-list p-picklist-list p-picklist-source"
                                                [ngClass]="{'p-picklist-highlight': listHighlightSource}"
                                                [ngStyle]="sourceStyle">
                                                <ng-template ngFor let-item [ngForOf]="pickList1List"
                                                    [ngForTrackBy]="sourceTrackBy || trackBy" let-i="index"
                                                    let-l="last">
                                                    <li [ngClass]="{'cdk-drag p-picklist-item p-ripple':true,'p-state-highlight':isSelected(item,selectedItemsSource), 'p-state-disabled': disabled}"
                                                        [style.display]="isItemVisible(item, SOURCE_LIST) ? 'block' : 'none'"
                                                        style="margin-right: 4px;">
                                                        <div class="product-item"
                                                            style="padding-top: 0rem;padding-bottom: 0rem;height: 25px;">
                                                            <span class="list-check image-container p-mr-2 p-mt-0">
                                                                <p-checkbox name="groupname" [value]="isChecked"
                                                                    #picklistCheckboxes [(ngModel)]="item.isChecked"
                                                                    binary="true"
                                                                    (ngModelChange)="onItemClick($event,item,selectedItemsSource,onSourceSelect)"
                                                                    [ngModelOptions]="{standalone: true}">
                                                                </p-checkbox>
                                                            </span>
                                                            <div class="product-list-detail p-d-flex">
                                                                <div class="p-col p-pl-0 pClass"
                                                                    pTooltip="{{item.label}}"
                                                                    *ngIf="item?.label?.length >= 32"
                                                                    tooltipPosition="bottom">
                                                                    {{item?.label?.substring(0,32) +
                                                                    "..."}}</div>
                                                                <div class="p-col p-pl-0 pClass"
                                                                    *ngIf="item?.label?.length < 32">
                                                                    {{item?.label}}</div>
                                                                <div class="p-col-fixed pClass">{{item.isPrivate}}</div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li class="p-picklist-droppoint" *ngIf="dragdrop&&l"
                                                        (dragover)="onDragOver($event, i + 1, SOURCE_LIST)"
                                                        (drop)="onDrop($event, i + 1, SOURCE_LIST)"
                                                        (dragleave)="onDragLeave($event, SOURCE_LIST)"
                                                        [ngClass]="{'p-picklist-droppoint-highlight': (i + 1 === dragOverItemIndexSource)}">
                                                    </li>
                                                </ng-template>
                                            </ul>
                                        </div>
                                        <div class="p-col-fixed p-picklist-buttons p-picklist-transfer-buttons">
                                            <button type="button" pButton pRipple icon="pi pi-angle-right"
                                                [disabled]="disabled"
                                                class="p-ripple p-button p-component p-button-icon-only"
                                                (click)="moveRight()"
                                                [disabled]="this.pagesService.authorizationReadOnlyFlag">

                                                <span class="p-ink"></span>
                                            </button>
                                            <button type="button" pButton icon="pi pi-angle-double-right"
                                                [disabled]="disabled"
                                                class="p-ripple p-button p-component p-button-icon-only"
                                                (click)="moveAllRight()"
                                                [disabled]="this.pagesService.authorizationReadOnlyFlag">

                                                <span class="p-ink"></span>
                                            </button>
                                            <button type="button" pButton icon="pi pi-angle-left" [disabled]="disabled"
                                                class="p-ripple p-button p-component p-button-icon-only"
                                                (click)="moveLeft()"
                                                [disabled]="this.pagesService.authorizationReadOnlyFlag">

                                                <span class="p-ink"></span>
                                            </button>
                                            <button type="button" pButton icon="pi pi-angle-double-left"
                                                [disabled]="disabled"
                                                class="p-ripple p-button p-component p-button-icon-only"
                                                (click)="moveAllLeft()"
                                                [disabled]="this.pagesService.authorizationReadOnlyFlag">

                                                <span class="p-ink"></span>
                                            </button>
                                        </div>
                                        <div class=" p-col-fixed p-picklist-list-wrapper p-picklist-target-wrapper"
                                            [ngClass]="{'p-picklist-listwrapper-nocontrols':!showTargetControls}">
                                            <div class="p-picklist-header">
                                                <div class="p-picklist-title">Mapped Attributes
                                                    <span class="p-picklist-count">{{pickList1targetList.length}}</span>
                                                </div>
                                            </div>
                                            <div class="p-picklist-filter-container"
                                                *ngIf="filterBy && showSourceFilter !== false">
                                                <div class="p-picklist-filter">
                                                    <span class="p-input-icon-left" style="margin: 0px;">
                                                        <input #sourceFilter type="text" role="textbox"
                                                            (keydown.enter)="$event.preventDefault()"
                                                            class="p-picklist-filter-input p-inputtext inputtextHeight p-component"
                                                            placeholder="Search By Name"
                                                            (input)="filterMethodPick($event.target.value,pickList1targetList1,'pick1target')"
                                                            [disabled]="disabled" formControlName="sourceFilter">
                                                        <i aria-hidden="true" class="pi pi-search"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <ul #targetlist cdkDropList
                                                class="cdk-drop-list p-picklist-list p-picklist-target"
                                                [ngClass]="{'p-picklist-highlight': listHighlightTarget}"
                                                [ngStyle]="targetStyle">
                                                <ng-template ngFor let-item [ngForOf]="pickList1targetList"
                                                    [ngForTrackBy]="targetTrackBy || trackBy" let-i="index"
                                                    let-l="last">
                                                    <li pRipple cdkDrag role="option"
                                                        [ngClass]="{'cdk-drag p-picklist-item p-ripple':true,'p-state-highlight':isSelected(item,selectedItemsTarget), 'p-state-disabled': disabled}"
                                                        [style.display]="isItemVisible(item, TARGET_LIST) ? 'flex' : 'none'"
                                                        style="margin-right: 4px;">
                                                        <div class="product-item"
                                                            style="padding-top: 0rem;padding-bottom: 0rem;height: 25px;">
                                                            <span class="list-check image-container p-mr-2 p-mt-0">
                                                                <p-checkbox name="groupname" [value]="isChecked"
                                                                    #picklistCheckboxes [(ngModel)]="item.isChecked"
                                                                    binary="true"
                                                                    (ngModelChange)="onItemClick($event,item,selectedItemsTarget,onTargetSelect)"
                                                                    [ngModelOptions]="{standalone: true}">
                                                                </p-checkbox>
                                                            </span>
                                                            <div class="product-list-detail p-d-flex">
                                                                <div class="p-col p-pl-0 pClass"
                                                                    pTooltip="{{item.label}}"
                                                                    *ngIf="item?.label?.length >= 32"
                                                                    tooltipPosition="bottom">
                                                                    {{item?.label?.substring(0,32) +
                                                                    "..."}}</div>
                                                                <div class="p-col p-pl-0 pClass"
                                                                    *ngIf="item?.label?.length < 32">
                                                                    {{item?.label}}</div>
                                                                <div class="p-col-fixed pClass">{{item.isPrivate}}</div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li class="p-picklist-droppoint" *ngIf="dragdrop&&l"
                                                        (dragover)="onDragOver($event, i + 1, TARGET_LIST)"
                                                        (drop)="onDrop($event, i + 1, TARGET_LIST)"
                                                        (dragleave)="onDragLeave($event, TARGET_LIST)"
                                                        [ngClass]="{'p-picklist-droppoint-highlight': (i + 1 === dragOverItemIndexTarget)}">
                                                    </li>
                                                </ng-template>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-p-0 p-offset-undefined p-right p-mb-4">
                                <div class="btnwrap custom-picklist-btn">
                                    <button pButton class="p-button-primary p-button" type="button" [disabled]="btn"
                                        (click)="onSave($event)" label="Save"
                                        [disabled]="this.pagesService.authorizationReadOnlyFlag">
                                    </button>
                                </div>
                            </div>
                        </div>
                    </p-tabPanel>
                    <p-tabPanel header="Attribute Group Mapping">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-p-0 p-offset-undefined left">
                                <div class=" p-fluid   p-field    ">
                                    <div [class]="styleClass" [ngStyle]="style"
                                        class="p-grid custom-picklist-with-checkbox"
                                        [ngClass]="{'p-picklist p-component p-widget p-helper-clearfix': true,'p-picklist-responsive': responsive}">
                                        <div class="p-col-fixed p-picklist-list-wrapper p-picklist-source-wrapper"
                                            [ngClass]="{'p-picklist-listwrapper-nocontrols':!showSourceControls}">
                                            <div class="p-picklist-header">
                                                <div class="p-picklist-title">Attribute Groups
                                                    <span class="p-picklist-count">{{pickList2List.length}}</span>
                                                </div>
                                            </div>
                                            <div class="p-picklist-filter-container"
                                                *ngIf="filterBy && showSourceFilter !== false">
                                                <div class="p-picklist-filter">
                                                    <span class="p-input-icon-left" style="margin: 0px;">
                                                        <input #sourceFilter type="text" role="textbox"
                                                            (keydown.enter)="$event.preventDefault()"
                                                            class="p-picklist-filter-input p-inputtext inputtextHeight p-component"
                                                            placeholder="Search By Name"
                                                            (input)="filterMethodPick($event.target.value,pickList2List2,'pick2source')"
                                                            [disabled]="disabled" formControlName="sourceFilter">
                                                        <i aria-hidden="true" class="pi pi-search"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <ul #sourcelist class="cdk-drop-list p-picklist-list p-picklist-source"
                                                [ngClass]="{'p-picklist-highlight': listHighlightSource}"
                                                [ngStyle]="sourceStyle">
                                                <ng-template ngFor let-item [ngForOf]="pickList2List"
                                                    [ngForTrackBy]="sourceTrackBy || trackBy" let-i="index"
                                                    let-l="last">
                                                    <li [ngClass]="{'cdk-drag p-picklist-item p-ripple':true,'p-state-highlight':isSelected(item,selectedItemsSource), 'p-state-disabled': disabled}"
                                                        [style.display]="isItemVisible(item, SOURCE_LIST) ? 'block' : 'none'">
                                                        <div class="product-item"
                                                            style="padding-top: 0rem;padding-bottom: 0rem;height: 25px;">
                                                            <span class="list-check image-container p-mr-2 p-mt-0">
                                                                <p-checkbox name="groupname" [value]="isChecked"
                                                                    #picklistCheckboxes [(ngModel)]="item.isChecked"
                                                                    binary="true"
                                                                    (ngModelChange)="onItemClick($event,item,selectedItemsSource,onSourceSelect)"
                                                                    [ngModelOptions]="{standalone: true}">
                                                                </p-checkbox>
                                                            </span>

                                                            <div class="product-list-detail">
                                                                <div class="pClass">{{item.label}}</div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li class="p-picklist-droppoint" *ngIf="dragdrop&&l"
                                                        (dragover)="onDragOver($event, i + 1, SOURCE_LIST)"
                                                        (drop)="onDrop($event, i + 1, SOURCE_LIST)"
                                                        (dragleave)="onDragLeave($event, SOURCE_LIST)"
                                                        [ngClass]="{'p-picklist-droppoint-highlight': (i + 1 === dragOverItemIndexSource)}">

                                                    </li>
                                                </ng-template>
                                            </ul>
                                        </div>
                                        <div class="p-col-fixed p-picklist-buttons p-picklist-transfer-buttons">
                                            <button type="button" pButton pRipple icon="pi pi-angle-right"
                                                [disabled]="disabled"
                                                class="p-ripple p-button p-component p-button-icon-only"
                                                (click)="moveRight2()"
                                                [disabled]="this.pagesService.authorizationReadOnlyFlag">

                                                <span class="p-ink"></span>
                                            </button>
                                            <button type="button" pButton icon="pi pi-angle-double-right"
                                                [disabled]="disabled"
                                                class="p-ripple p-button p-component p-button-icon-only"
                                                (click)="moveAllRight2()"
                                                [disabled]="this.pagesService.authorizationReadOnlyFlag">

                                                <span class="p-ink"></span>
                                            </button>
                                            <button type="button" pButton icon="pi pi-angle-left" [disabled]="disabled"
                                                class="p-ripple p-button p-component p-button-icon-only"
                                                (click)="moveLeft2()"
                                                [disabled]="this.pagesService.authorizationReadOnlyFlag">

                                                <span class="p-ink"></span>
                                            </button>
                                            <button type="button" pButton icon="pi pi-angle-double-left"
                                                [disabled]="disabled"
                                                class="p-ripple p-button p-component p-button-icon-only"
                                                (click)="moveAllLeft2()"
                                                [disabled]="this.pagesService.authorizationReadOnlyFlag">

                                                <span class="p-ink"></span>
                                            </button>
                                        </div>
                                        <div class="p-col-fixed p-picklist-list-wrapper p-picklist-target-wrapper"
                                            [ngClass]="{'p-picklist-listwrapper-nocontrols':!showTargetControls}">
                                            <div class="p-picklist-header">
                                                <div class="p-picklist-title">Mapped Attribute Groups
                                                    <span class="p-picklist-count">{{pickList2targetList.length}}</span>
                                                </div>
                                            </div>
                                            <div class="p-picklist-filter-container"
                                                *ngIf="filterBy && showSourceFilter !== false">
                                                <div class="p-picklist-filter">
                                                    <span class="p-input-icon-left" style="margin: 0px;">
                                                        <input #sourceFilter type="text" role="textbox"
                                                            (keydown.enter)="$event.preventDefault()"
                                                            class="p-picklist-filter-input p-inputtext inputtextHeight p-component"
                                                            placeholder="Search By Name"
                                                            (input)="filterMethodPick($event.target.value,pickList2targetList2,'pick2target')"
                                                            [disabled]="disabled" formControlName="sourceFilter">
                                                        <i aria-hidden="true" class="pi pi-search"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <ul #targetlist cdkDropList
                                                class="cdk-drop-list p-picklist-list p-picklist-target"
                                                [ngClass]="{'p-picklist-highlight': listHighlightTarget}"
                                                [ngStyle]="targetStyle">
                                                <ng-template ngFor let-item [ngForOf]="pickList2targetList"
                                                    [ngForTrackBy]="targetTrackBy || trackBy" let-i="index"
                                                    let-l="last">
                                                    <li pRipple cdkDrag role="option"
                                                        [ngClass]="{'cdk-drag p-picklist-item p-ripple':true,'p-state-highlight':isSelected(item,selectedItemsTarget), 'p-state-disabled': disabled}"
                                                        [style.display]="isItemVisible(item, TARGET_LIST) ? 'flex' : 'none'">
                                                        <div class="product-item"
                                                            style="padding-top: 0rem;padding-bottom: 0rem;height: 25px;">
                                                            <span class="list-check image-container p-mr-2 p-mt-0">
                                                                <p-checkbox name="groupname" [value]="isChecked"
                                                                    #picklistCheckboxes [(ngModel)]="item.isChecked"
                                                                    binary="true"
                                                                    (ngModelChange)="onItemClick($event,item,selectedItemsTarget,onTargetSelect)"
                                                                    [ngModelOptions]="{standalone: true}">
                                                                </p-checkbox>
                                                            </span>
                                                            <div class="product-list-detail">
                                                                <div class="pClass">{{item.label}}</div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li class="p-picklist-droppoint" *ngIf="dragdrop&&l"
                                                        (dragover)="onDragOver($event, i + 1, TARGET_LIST)"
                                                        (drop)="onDrop($event, i + 1, TARGET_LIST)"
                                                        (dragleave)="onDragLeave($event, TARGET_LIST)"
                                                        [ngClass]="{'p-picklist-droppoint-highlight': (i + 1 === dragOverItemIndexTarget)}">
                                                    </li>
                                                </ng-template>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-p-0 p-offset-undefined p-right p-mb-4">
                                <div class="btnwrap custom-picklist-btn">
                                    <button pButton class="p-button-primary p-button" type="button" [disabled]="btn1"
                                        (click)="onSave1($event);" label="Save"
                                        [disabled]="this.pagesService.authorizationReadOnlyFlag">
                                    </button>
                                </div>
                            </div>
                        </div>
                    </p-tabPanel>
                </p-tabView>
            </div>
            <div class="p-col-12 p-mb-5"></div>
        </div>
    </form>
</div>