import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CIAM_UM_SERVER_API_URL } from 'app/app.constants';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class EntitlementDetailsService {
    private cancelStatus = new BehaviorSubject<any>('0');
    constructor(private http: HttpClient) { }

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    getEntitlementDetails(data) {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageEntitlementDetails/getEntitlementDetails', data);
    }

    checkEntitlementData(data) {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageEntitlementDetails/checkEntitlementData', data);
    }

    getEntitlementList(data): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageEntitlements/getEntitlementList', data);
    }

    importEntitlementData(data): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageEntitlementDetails/importEntitlementData', data);
    }

    updateEntitlementDetails(data): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageEntitlementDetails/updateEntitlementDetails', data);
    }
    getUserByEntitlement(filter: any): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageEntitlementDetails/getUserByEntitlement', filter);
    }

    getEntitlementsForRole(data: any): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umRoleDetails/getEntitlementsForRole', data);
    }

    getEntitlementUserList(filter: any): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageEntitlementDetails/getEntitlementUserList', filter);
    }

    sendMessage(message: any) {

        this.cancelStatus.next(message);
    }

    getBankList(): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umUserDetails/getBankServiceLineList', {});
    }

    getEntitlementStatusList(): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageEntitlements/getEntitlementStatusList', {});
    }

    getRoleList(filter: any): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageRole/getRoleList', filter);
    }

    getRoleListByAccName(filter: any): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageRole/getRoleListByAccName', filter);
    }

    getRoleStatusList(formData): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageRole/getRoleStatusList', formData);
    }
}
