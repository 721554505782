import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { PagesService } from '../pages.service';
import { MenuItem, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AssignUsersComponent } from '../AssignUsers/AssignUsers.component';
import { DeleteRoleComponent } from '../DeleteRole/DeleteRole.component';

@Component({
  selector: 'jhi-viewrole',
  templateUrl: './ViewRole.component.html',
  styleUrls: ['./ViewRole.component.scss'],
  providers: [MessageService, DialogService],
})
export class ViewRoleComponent implements OnInit {
  ref!: DynamicDialogRef;
  ViewRoleForm = this.fb.group({
    viewRoleID: [null, []],
    roleName: [null, []],
    createdOn: [null, []],
    roleType: [null, []],
    roleStatus: [null, []],
    roleDescription: [null, []],
    ccprimesplitbutton: [null, []],
    accessDetails: [null, []],
  });
  pageloadvalues: any;
  messageFlag = true;
  ccprimebreadcrumbList = [{ label: 'User Management' }, { label: `Roles`, routerLink: ['/pages/roles'] }, { label: `View Role` }];
  homeiconccprimebreadcrumb = { icon: 'pi pi-home', routerLink: ['/'] };
  htmlSrc: any;
  ccprimesplitbuttonList: any[] = [];
  html1Src: any;
  userTableList: any[] = [];
  userTablecols: any[] = [
    { field: 'firstName', header: 'First Name' },
    { field: 'lastName', header: 'Last Name' },
    { field: 'emailAddress', header: 'Email Address' },
  ];
  html2Src: any;
  html3Src: any;
  data: any;
  navigation: any;
  roleID = '';
  groupDetails: any[] = [];
  groupcount: number;
  usercount: string;
  groupcounts: string;
  selectedIndex: any;
  hoverIndex: any;
  constructor(
    public dialogService: DialogService,
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    private messageService: MessageService
  ) {
    this.navigation = this.router.getCurrentNavigation();
    this.data = this.navigation?.extras?.state?.data;
    if (this.data) {
      this.setPageProperties(JSON.parse(this.data));
    }
  }
  items!: MenuItem[];
  ngOnInit(): void {
    this.items = [
      {
        label: 'Edit',
        command: e => {
          this.navigateToEdit(this.ViewRoleForm.value);
        },
      },
      {
        label: 'Delete',
        command: () => {
          this.DeleteRole();
        },
      },
      {
        label: 'Assign User',
        command: () => {
          this.show();
        },
      },
    ];
    if (!this.pagesService.pageTenantAdminFlag) {
      this.items[2].disabled = true;
    }
    this.onViewRolePageLoad();
    this.htmlSrc = '';
    this.html1Src = '';
    this.getuserTableList();
    this.html2Src = '';
    this.html3Src = '';
    this.getGroupList();
  }

  onViewRolePageLoad() {
    this.pagesService.onViewRolePageLoad(this.roleID).subscribe((results: any) => {
      let pagePropBind = {
        viewRoleID: results.success.result.id,
        roleType: results.success.result.type,
        roleName: results.success.result.displayName,
        createdOn: results.success.result.createdDate,
        roleDescription: results.success.result.description,
        groupDetails: results.success.result.groups,
      };

      this.pageloadvalues = pagePropBind;
      this.setPageProperties(pagePropBind);
    });
  }

  highlight(index: any) {
    this.selectedIndex = index;
  }

  setPageProperties(props: any): void {
    const pageData: any = {};
    const calendarControls = ['createdOn'];
    Object.keys(props).forEach(key => {
      if (calendarControls.includes(key)) {
        pageData[key] = new Date(props[key]);
      } else {
        pageData[key] = props[key];
      }
    });
    this.roleID = pageData.id;
    this.ViewRoleForm.patchValue(pageData);
  }

  //getting no of users
  getuserTableList(): void {
    this.usercount = "0";
    this.pagesService.getuserTableDataViewRole(this.roleID).subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        this.userTableList = responseData;
      }
      this.usercount = "0";
      if (this.userTableList.length > 9) {
        this.usercount = "" + this.userTableList.length;
      }
      else if (this.userTableList.length >= 1) {
        this.usercount = "0" + this.userTableList.length;
      }
    });
  }

  //getting no of groups
  getGroupList(): void {
    this.groupDetails = []
    this.groupcounts = "0";
    this.pagesService.getGroupByRole(this.roleID).subscribe(results => {
      if (results?.error?.messageCode == "CIAM 400") {
        this.groupcounts = "0";
      }
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.groupDetails.push(data);
          this.groupcounts = "0";
          if (this.groupDetails.length > 9) {
            this.groupcounts = "" + this.groupDetails.length;
          }
          else if (this.groupDetails.length >= 1) {
            this.groupcounts = "0" + this.groupDetails.length;
          }
        });
        this.groupDetails = [...this.groupDetails];
      }
    });
  }
  reseteditccprimesplitbutton(): void {
    this.ViewRoleForm.reset();
  }

  resetdeactivateccprimesplitbutton(): void {
    this.ViewRoleForm.reset();
  }

  resetassignUsersccprimesplitbutton(): void {
    this.ViewRoleForm.reset();
  }

  navigateToEdit(rowData: any): void {
    const navigationExtras: NavigationExtras = {
      state: {
        data: JSON.stringify(rowData),
      },
    };
    this.router.navigate(['/pages/editrole'], navigationExtras);
  }

  navigateTorolelist(): void {
    const navigationExtras: NavigationExtras = {
      state: {
        msg: 'Role Deleted Sucessfully',
      },
    };
    this.router.navigate(['/pages/roles'], navigationExtras);
  }

  //Assign users dialog box function
  show() {
    this.ref = this.dialogService.open(AssignUsersComponent, {
      data: { id: this.ViewRoleForm.value, dialogFlag: 'viewroles' },
      header: 'Assign Users',
      width: '50%',
      contentStyle: { 'max-height': '160px', overflow: 'visible' },
      baseZIndex: 10000,
    });
    this.ref.onClose.subscribe(msg => {
      if (msg == 'error') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Failed to assign users.' });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
      else if (msg) {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
      this.roleID = this.ViewRoleForm.value.viewRoleID;
      if (msg) {
        this.getuserTableList();
      }
    });
  }

  //delete role dialog box function
  DeleteRole() {
    this.ref = this.dialogService.open(DeleteRoleComponent, {
      data: { id: this.ViewRoleForm.value.viewRoleID, name: this.ViewRoleForm.value.roleName, dialogFlag: 'roles' },
      header: 'Delete Role',
      width: '400px',
      contentStyle: { 'max-height': '160px', overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'error') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Failed to delete user.' });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);

      }
      else if (msg) {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.navigateTorolelist();
      }
    });
  }

  mouseLeave() {
    this.hoverIndex = null;
    this.selectedIndex = null;
  }
}
