<div style="background-color:#F6F8FA;" (click)="mouseLeave()">
    <div class="p-d-flex p-jc-end">
        <p-messages key="myKey1" class="custom-alert-box" *ngIf="this.messageFlag"></p-messages>
        <p-messages severity="warn" *ngIf="!this.planAttrFlag" class="custom-alert-box">
            <ng-template pTemplate>
                <span class="p-message-icon pi pi-info-circle"></span>
                <span class="p-message-detail">Plan attributes are missing from selected Plan '{{this.planName}}'.
                    Kindly <br>
                    configure from 'Application Plan' in order to create Subscription.
                </span>
                <button (click)="clearmsg()" type="button" pripple="" class="p-message-close p-link">
                    <i aria-hidden="true" class="p-message-close-icon pi pi-times"></i>
                </button>
            </ng-template>
        </p-messages>
    </div>
    <div class="p-col-fixed p-mt-1 p-ml-1 p-pr-0 custom-breadcrumb">
        <p-breadcrumb [model]="ccprimebreadcrumbList" [home]="homeiconccprimebreadcrumb">
        </p-breadcrumb>
    </div>
    <form class="layout-content custom-form-layout" [formGroup]="ManageSubscriptionForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">

            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                <h5 class="custom-head1">Manage Subscriptions</h5>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                <div class=" p-fluid   p-field    ">
                    <label [ngClass]="{'field-required': ManageSubscriptionForm.get('select')?.errors?.required}">
                        Application
                    </label> <span class='invalid-feedback-astrik'>*</span>
                    <p-multiSelect id="selectid" name="select" placeholder="Select a Application" placeholder="Select"
                        [options]="selectList" [filter]="true" filterBy="label" formControlName="select" display="chip"
                        class="custom-chips" (onChange)="getPlanList()">
                        <ng-template let-items pTemplate="item">
                            <div *ngIf="items?.label?.length &lt;=37;">
                                {{items.label}}
                            </div>
                            <div *ngIf="items?.label?.length > 37;" pTooltip="{{items.label}}" tooltipPosition="bottom">
                                {{items.label.substring(0,37) + "..." }}
                            </div>
                        </ng-template>
                    </p-multiSelect>
                    <div class="invalid-feedback"
                        *ngIf="ManageSubscriptionForm.get('select')!.invalid && (ManageSubscriptionForm.get('select')!.dirty || ManageSubscriptionForm.get('select')!.touched)">
                        <div *ngIf="ManageSubscriptionForm.get('select')?.errors?.required">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium class="p-error"> Application is required.</medium>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                <div class=" p-fluid   p-field    ">
                    <label [ngClass]="{'field-required': ManageSubscriptionForm.get('select1')?.errors?.required}">
                        Plan
                    </label> <span class='invalid-feedback-astrik'>*</span>
                    <label tooltipPosition="right"
                        pTooltip="Plans available for selection are the union of selected Applications"
                        class="alert-icon alert-circle-info-icon p-ml-1"></label>
                    <div class="p-fluid">
                        <p-dropdown [disabled]="dropdown" id="select1id" name="select1" filterPlaceHolder="Select Users"
                            placeholder="Select" [options]="selectList1" [filter]="true" filterBy="planName"
                            [autoDisplayFirst]="false" formControlName="select1" (onChange)="gettableList()"
                            optionLabel="planName">
                            <ng-template let-item pTemplate="item">
                                <div *ngIf="item.planName.length &lt;= 35;">
                                    {{item.planName}}
                                </div>
                                <div *ngIf="item.planName.length > 35;" pTooltip="{{item.planName}}"
                                    tooltipPosition="bottom">
                                    {{item.planName.substring(0,35) + "..." }}
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                    <div class="invalid-feedback"
                        *ngIf="ManageSubscriptionForm.get('select1')!.invalid && (ManageSubscriptionForm.get('select1')!.dirty || ManageSubscriptionForm.get('select1')!.touched)">
                        <div *ngIf="ManageSubscriptionForm.get('select1')?.errors?.required">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium class="p-error"> Plan is required.</medium>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left" style="margin-bottom: 10px;">
                <label *ngIf="this.emptyFlag" class="d1-icons error-icon"></label><br *ngIf="this.emptyFlag">
                <label *ngIf="this.emptyFlag" class="e-error">Please fill the above
                    <span class="p-pl-1" style="font-weight: 500;">
                        mandatory field</span> </label>
                <div class="card custom-card p-mb-6" *ngIf="!this.emptyFlag">
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                        <div class=" p-fluid   p-field    ">
                            <div class="p-fluid p-formgrid p-grid" style="padding-top:8px ;">
                                <div class="p-col-12 p-p-0">
                                    <div *ngIf="this.errorFlag" class="custom-Attrtable-div p-pb-4">
                                        <label class="c-icons noSub-icon"></label>
                                        <label class="e-error">No Subscription added to this plan, </label>
                                        <label class="e-error">Once added as subscription, you won't be able to modify
                                            the plan</label>

                                        <br>
                                        <div class="btnwrap">
                                            <button pButton type="button" pButton label="Add New Subscription"
                                                (click)="getPlanAttributeValueList();"
                                                class="p-button-outlined p-button-danger custom-button-padding"
                                                [disabled]="this.pagesService.planAdminReadOnlyFlag"></button>
                                        </div>
                                        <br>
                                        <br>
                                    </div>
                                    <div class="ui-fluid p-formgrid" *ngIf="!this.errorFlag">
                                        <div class=" p-grid p-mt-0">
                                            <div class="p-col-fixed p-p-0 p-col-4">
                                                <span class=" p-input-icon-left">
                                                    <input type="text" (keydown.enter)="$event.preventDefault()"
                                                        id="textField_id" name="textField_name"
                                                        formControlName="textField" pInputText placeholder="Search"
                                                        class="p-inputtext inputtextHeight"
                                                        (keyup)="SubcriptionFilterMethod($event)"
                                                        style="height: 28px;" />
                                                    <i aria-hidden="true" class="pi pi-search"></i>
                                                </span>
                                            </div>
                                            <div class="p-col p-p-0 p-text-right custom-btn">
                                                <span class="btnwrap" *ngIf="!this.errorFlag">
                                                    <button pButton
                                                        class="p-button-primary p-button-sm custom-button-padding"
                                                        type="button"
                                                        (click)="onAddSubscription(ManageSubscriptionForm);"
                                                        label="Add New Subscription"
                                                        [disabled]="this.pagesService.planAdminReadOnlyFlag">
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left p-p-0">
                                            <div class="  p-field    ">
                                                <div class="mobilefriendlytable" responsiveLayout="stack"
                                                    [ngClass]="{'content-section implementation': true}">
                                                    <p-table #tableid [value]="tableList" name="table"
                                                        [columns]="tablecols"
                                                        styleClass="p-datatable-customers custom-p-datatable-customers"
                                                        selectionMode="Multiple" sortMode="single" [scrollable]="true"
                                                        [(selection)]="selectedRecords"
                                                        [globalFilterFields]="['subscriptionName','startDate','endDate','renewalVersion','AccountName','subStatus']"
                                                        (onRowSelect)="onRowSelect($event)"
                                                        (sortFunction)="sortData($event)" [customSort]="true"
                                                        (onFilter)="columnFilter($event)"
                                                        (onRowUnselect)="onRowUnselect($event)"
                                                        (onHeaderCheckboxToggle)="onSelectAllUsers();">
                                                        <ng-template pTemplate="header">
                                                            <tr>
                                                                <th pSortableColumn="subscriptionName" scope="col"
                                                                    style="min-width: 200px;max-width: 200px;width: 200px;"
                                                                    pFrozenColumn>
                                                                    <div class="p-d-flex p-jc-between p-ai-center">
                                                                        Subscription Name
                                                                        <i class="p-sortable-column-icon icon-sort"
                                                                            ariaLabel="Activate to sort"
                                                                            ariaLabelDesc="Activate to sort in descending order"
                                                                            ariaLabelAsc="Activate to sort in ascending order"
                                                                            field="subscriptionName"></i>
                                                                        <p-columnFilter type="text"
                                                                            field="subscriptionName" display="menu"
                                                                            class="p-ml-auto"></p-columnFilter>
                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="subsStartDate" scope="col"
                                                                    style="min-width: 170px;max-width: 170px;width: 170px;">
                                                                    <div class="p-d-flex p-jc-between p-ai-center">
                                                                        Subs. Start Date
                                                                        <i class="p-sortable-column-icon icon-sort"
                                                                            ariaLabel="Activate to sort"
                                                                            ariaLabelDesc="Activate to sort in descending order"
                                                                            ariaLabelAsc="Activate to sort in ascending order"
                                                                            field="subsStartDate"></i>
                                                                        <p-columnFilter type="date"
                                                                            field="subsStartDate" display="menu"
                                                                            class="p-ml-auto"></p-columnFilter>
                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="subsEndDate" scope="col"
                                                                    style="min-width: 150px;max-width: 150px;width: 150px;">
                                                                    <div class="p-d-flex p-jc-between p-ai-center">
                                                                        Subs. End Date
                                                                        <i class="p-sortable-column-icon icon-sort"
                                                                            ariaLabel="Activate to sort"
                                                                            ariaLabelDesc="Activate to sort in descending order"
                                                                            ariaLabelAsc="Activate to sort in ascending order"
                                                                            field="subsEndDate"></i>
                                                                        <p-columnFilter type="date" field="subsEndDate"
                                                                            display="menu"
                                                                            class="p-ml-auto"></p-columnFilter>
                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="graceEndDate" scope="col"
                                                                    style="min-width: 200px;max-width: 200px;width: 200px;">
                                                                    <div class="p-d-flex p-jc-between p-ai-center">
                                                                        Grace Period End Date
                                                                        <!-- <i class="p-sortable-column-icon icon-sort"
                                                                            ariaLabel="Activate to sort"
                                                                            ariaLabelDesc="Activate to sort in descending order"
                                                                            ariaLabelAsc="Activate to sort in ascending order"
                                                                            field="graceEndDate"></i>
                                                                        <p-columnFilter type="date" field="graceEndDate"
                                                                            display="menu"
                                                                            class="p-ml-auto"></p-columnFilter> -->
                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="renewalVersion" scope="col"
                                                                    style="min-width: 170px;max-width: 170px;width: 170px;">
                                                                    <div class="p-d-flex p-jc-between p-ai-center">
                                                                        Renewal Version
                                                                        <i class="p-sortable-column-icon icon-sort"
                                                                            ariaLabel="Activate to sort"
                                                                            ariaLabelDesc="Activate to sort in descending order"
                                                                            ariaLabelAsc="Activate to sort in ascending order"
                                                                            field="renewalVersion"></i>
                                                                        <p-columnFilter type="text"
                                                                            field="renewalVersion" display="menu"
                                                                            class="p-ml-auto"></p-columnFilter>
                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="accName" scope="col"
                                                                    style="min-width: 150px;max-width: 150px;width: 150px;">
                                                                    <div class="p-d-flex p-jc-between p-ai-center">
                                                                        Account Name
                                                                        <i class="p-sortable-column-icon icon-sort"
                                                                            ariaLabel="Activate to sort"
                                                                            ariaLabelDesc="Activate to sort in descending order"
                                                                            ariaLabelAsc="Activate to sort in ascending order"
                                                                            field="accName"></i>
                                                                        <p-columnFilter field="accName" display="menu"
                                                                            class="p-ml-auto"></p-columnFilter>
                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="subStatus" scope="col"
                                                                    style="min-width: 150px;max-width: 150px;width: 150px;">
                                                                    <div class="p-d-flex p-jc-between p-ai-center">
                                                                        Status
                                                                        <i class="p-sortable-column-icon icon-sort"
                                                                            ariaLabel="Activate to sort"
                                                                            ariaLabelDesc="Activate to sort in descending order"
                                                                            ariaLabelAsc="Activate to sort in ascending order"
                                                                            field="subStatus"></i>
                                                                        <p-columnFilter field="subStatus" display="menu"
                                                                            class="p-ml-auto"></p-columnFilter>
                                                                    </div>
                                                                </th>
                                                                <th id="actionButton"
                                                                    style="width: 50px;min-width: 50px;">
                                                                    <div></div>
                                                                </th>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-rowData let-index="rowIndex">
                                                            <tr (mouseenter)="hoverIndex=index;highlight(index)"
                                                                (mouseleave)="hoverIndex=index;highlight(index)"
                                                                [pSelectableRow]="rowData">
                                                                <td class="middleNameRow"
                                                                    style="min-width: 200px;max-width: 200px;width: 200px;"
                                                                    alignFrozen="left" pFrozenColumn>
                                                                    <span pTooltip="{{rowData.name}}"
                                                                        *ngIf="rowData.name?.length >= 18"
                                                                        tooltipPosition="bottom">
                                                                        {{rowData.name?.substring(0,18) + "..." }}
                                                                    </span>
                                                                    <span *ngIf="rowData.name?.length < 18">
                                                                        {{rowData.name}}
                                                                    </span>
                                                                </td>
                                                                <td class="middleNameRow"
                                                                    style="min-width: 170px;max-width: 170px;width: 170px;">
                                                                    {{rowData.startDate | date: 'MMM d, y'}}

                                                                </td>
                                                                <td
                                                                    style="min-width: 150px;max-width: 150px;width: 150px;">
                                                                    {{rowData.endDate | date:
                                                                    'MMM d, y'}}</td>
                                                                <td
                                                                    style="min-width: 200px;max-width: 200px;width: 200px;">
                                                                    <span
                                                                        *ngIf="rowData.gracePeriodEndDate!=null">{{rowData.gracePeriodEndDate
                                                                        | date:
                                                                        'MMM d, y'}}</span>
                                                                    <span
                                                                        *ngIf="rowData.gracePeriodEndDate==null">-</span>
                                                                </td>
                                                                <td class="emailRow"
                                                                    style="min-width: 170px;max-width: 170px;width: 170px;">
                                                                    {{rowData.renewalVersion}}
                                                                </td>
                                                                <td class="emailRow"
                                                                    style="min-width: 150px;max-width: 150px;width: 150px;">
                                                                    <span pTooltip="{{rowData.name}}"
                                                                        *ngIf="rowData.accountName?.length >= 18"
                                                                        tooltipPosition="bottom">
                                                                        {{rowData.accountName?.substring(0,18) + "..."
                                                                        }}
                                                                    </span>
                                                                    <span *ngIf="rowData.accountName?.length < 18">
                                                                        {{rowData.accountName}}
                                                                    </span>
                                                                </td>
                                                                <td class="button-status"
                                                                    style="min-width: 150px;max-width: 150px;width: 150px;">
                                                                    <div class="btnwrap">
                                                                        <button pButton type="button"
                                                                            label="{{rowData.status}}"
                                                                            [style.color]="rowData.status === 'Active' ? '#12813F' :  rowData.status === 'Completed' ? '#3A435E':  rowData.status === 'Inactive' ? '#FF2953': 'black'"
                                                                            [style.border]="rowData.status === 'Active' ? '1px solid #CFE6D8' :  rowData.status === 'Completed' ? '1px solid #D3D5DB':  rowData.status === 'Inactive' ? '1px solid #F7CCD4': '1px solid black'"
                                                                            class=" p-button-outlined  statusRow">
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                                <td pFrozenColumn [frozen]="true" alignFrozen="right"
                                                                    [ngClass]="{'frozen' : true}"
                                                                    style="padding:0px ;background: transparent;width: 140px;min-width: 140px;padding-top: 4px;">
                                                                    <div class="desktop p-pl-2"
                                                                        style="background: #F5F9FF;">
                                                                        <div class="button-details">
                                                                            <button pButton tooltipPosition="top"
                                                                                *ngIf="rowData.status=='Inactive'"
                                                                                style="width: 24px;height: 24px;padding: 0px;"
                                                                                icon="g-icons edit-icon"
                                                                                pTooltip="Edit Subscription"
                                                                                class="background-transparent"
                                                                                (click)="navigateEditSubscription(rowData);"
                                                                                [disabled]="this.pagesService.planAdminReadOnlyFlag">
                                                                            </button>
                                                                            <button pButton tooltipPosition="top"
                                                                                *ngIf="rowData.status!='Inactive'"
                                                                                style="width: 24px;height: 24px;padding: 0px;"
                                                                                icon="g-icons edit-icon"
                                                                                pTooltip="Edit Subscription Details"
                                                                                class="background-transparent"
                                                                                (click)="navigateToMap(rowData);"
                                                                                [disabled]="rowData.status=='Completed' || this.pagesService.planAdminReadOnlyFlag">
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="emptymessage">
                                                            <tr>
                                                                <td colspan="7" class="p-p-0 p-text-center"> <p-card>
                                                                        <div style="margin-top: 5%;height: 70px;">No
                                                                            matching records found</div>
                                                                    </p-card> </td>
                                                            </tr>
                                                        </ng-template>
                                                    </p-table>
                                                    <div style="position: relative;display: inline-flex;">
                                                        <p-paginator #pp [rows]="10" [totalRecords]="this.totalRecords"
                                                            [rowsPerPageOptions]="[10,25,50]"
                                                            (onPageChange)="paginate($event,this.ManageSubscriptionForm.value.textField)"></p-paginator>
                                                        <div class="p-pl-3 p-pt-2"
                                                            style="font-size: 13px;color: #505870;">
                                                            Total Records: {{this.totalRecords}}</div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>