<div class="layout-content custom-container">
    <form [formGroup]="ReorderPlanForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                <div class=" p-fluid   p-field ">
                    <label class="alert-icon alert-info-circle-icon p-mb-0"></label>
                    <span style="font-size: 14px;">Shift the blocks and re-order the plans. Plans will be displayed in
                        selected order in
                        marketplace.
                    </span>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left p-mb-3">
                <p-orderList [value]="tableList" (onReorder)="checkChange()"
                    [listStyle]="{ 'max-height': '90px','max-width': '802px','min-height': '90px','display': 'inline-flex' }">
                    <ng-template let-product pTemplate="item" let-index="rowIndex">

                        <p-card styleClass="p-custom-card-selection">
                            <ng-template pTemplate="body" style="height: 180px;">
                                <div class="p-pt-2">
                                    <span pTooltip="{{product.planName}}" *ngIf="product.planName?.length >= 15"
                                        tooltipPosition="bottom">
                                        <b>{{product?.planName?.substring(0,15) +
                                            "..." }}</b>
                                    </span>
                                    <span *ngIf="product.planName?.length < 15">
                                        <b>{{product.planName}}</b>
                                    </span>
                                </div>
                            </ng-template>
                        </p-card>
                    </ng-template>
                </p-orderList>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right p-mb-2">
                <div class="btnwrap custom-save">
                    <button pButton type="button" class="p-button-brand2" (click)="onClickNo(ReorderPlanForm);"
                        label="Cancel">
                    </button>
                </div>
                <div class="btnwrap">
                    <button pButton class="p-button-primary" type="button" (click)="reOrderPlan();" label="Save"
                        [disabled]="this.reorder">
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>