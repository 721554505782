<div>
	<div class="p-d-flex p-jc-end">
		<p-messages key="myKey1" class="custom-alert-box" *ngIf="this.messageFlag"></p-messages>
	</div>
	<div class="p-col-fixed p-mt-1 p-ml-1 p-pr-0 custom-breadcrumb">
		<p-breadcrumb *ngIf="!firstSave" [model]="ccprimebreadcrumbList" [home]="homeiconccprimebreadcrumb">
		</p-breadcrumb>
		<p-breadcrumb *ngIf="firstSave" [model]="ccprimebreadcrumbList1" [home]="homeiconccprimebreadcrumb">
		</p-breadcrumb>
	</div>
	<form class="layout-content custom-form-layout" [formGroup]="AddUserForm" autocomplete="off" novalidate>
		<div class="p-formgrid p-grid">
			<div class="p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6 p-offset-0 left">
				<h5 class="custom-head1" *ngIf="!firstSave">Add New User</h5>
				<h5 class="custom-head1" *ngIf="firstSave">Edit User</h5>
			</div>
			<div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 left" style="margin-bottom: 32px;">
				<p-card>
					<!-- TAB VIEW -->
					<div class="p-pl-2 p-pr-2 p-mt-2">
						<button pButton type="button" (click)="activeIndex1 = 0"
							class="p-button-outlined p-button-lg tabButton custom-size" label="Personal Details"
							[style.box-shadow]="activeIndex1 == 0? '1px 5px 20px #bdc0c9': '0px 1px 4px #bdc0c9'"
							[style.border]="activeIndex1 == 0 ? '1px solid #396FC5' : '#add8e6'"
							[style.color]="activeIndex1 == 0 ? '#396FC5' : '#6C7387'">
							<div class="fa-2x">
								<span class="fa-layers fa-fw">
									<label class="c-icons personal-icon"></label>
								</span>
							</div>
							<span class="p-button-icon p-button-icon-left reshot-icon-business-team"
								aria-hidden="true"></span>
							<label class="d-icons check-icon custom-label" *ngIf="tab1"></label>
						</button>
						<button pButton type="button" (click)="activeIndex1 = 1" [disabled]="clicked" label="Roles"
							id="panelbtn2" class="p-button-outlined p-button-lg tabButton custom-roles"
							[style.box-shadow]="activeIndex1 == 1? '1px 5px 20px #bdc0c9': '0px 1px 4px #bdc0c9'"
							[style.border]="activeIndex1 == 1 ? '1px solid #396FC5' : '#add8e6'"
							[style.color]="activeIndex1 == 1 ? '#396FC5' : '#6C7387'"
							*ngIf="this.pagesService.pageRebacFlag=='false'">
							<div class="fa-2x">
								<span class="fa-layers fa-fw">
									<label class="c-icons role-icon"></label>
								</span>
							</div>
							<span class="p-button-icon p-button-icon-left reshot-icon-business-team"
								aria-hidden="true"></span>
							<label class="d-icons check-icon custom-rolelabel" *ngIf="tab2"></label>
						</button>
						<button pButton type="button" (click)="activeIndex1 = 2" label="Group" id="panelbtn3"
							class="p-button-outlined p-button-lg tabButton custom-group" [disabled]="clicked"
							[style.box-shadow]="activeIndex1 == 2? '1px 5px 20px #bdc0c9': '0px 1px 4px #bdc0c9'"
							[style.border]="activeIndex1 == 2 ? '1px solid #396FC5' : '#add8e6'"
							[style.color]="activeIndex1 == 2 ? '#396FC5' : '#6C7387'"
							*ngIf="this.pagesService.pageRebacFlag=='false'">
							<div class="fa-2x">
								<span class="fa-layers fa-fw">
									<label class="c-icons group-icon"></label>
								</span>
							</div>
							<span class="p-button-icon p-button-icon-left reshot-icon-business-team"
								aria-hidden="true"></span>
							<label class="d-icons check-icon custom-grouplabel" *ngIf="tab3"></label>
						</button>
					</div>
					<div class="tabview-custom">
						<p-tabView [(activeIndex)]="activeIndex1" styleClass="tabview-custom">
							<p-tabPanel>
								<div class="p-fluid p-formgrid p-grid p-mt-3">
									<div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left">
										<div class="p-fluid p-field">
											<label
												[ngClass]="{'field-required': AddUserForm.get('firstName')?.errors?.required}">
												First Name
											</label>
											<span class="invalid-feedback-astrik">*</span>
											<input type="text" id="firstName_id" name="firstName_name"
												formControlName="firstName" pInputText placeholder="First name"
												class="p-inputtext inputtextHeight"
												pattern="^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$" maxlength="50"
												(keyup)="enableBtn1 = false" />

											<div class="invalid-feedback"
												*ngIf="AddUserForm.get('firstName')!.invalid &&(AddUserForm.get('firstName')!.dirty ||AddUserForm.get('firstName')!.touched)">
												<medium *ngIf="AddUserForm.get('firstName')?.errors?.required"
													class="p-error">
													<label class="alert-icon alert-circle-icon"></label>
													First name is required!
												</medium>
												<div *ngIf="AddUserForm.get('firstName').errors.pattern">
													<label class="alert-icon alert-circle-icon"></label>
													<medium class="p-error">First Name should not have
														Special
														Character
													</medium>
												</div>
											</div>
										</div>
									</div>
									<div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
										<div class="p-fluid p-field">
											<label>
												Middle Name
											</label>
											<input type="text" id="middleName_id" name="middleName_name"
												formControlName="middleName" pInputText placeholder="Middle name"
												class="p-inputtext inputtextHeight"
												pattern="^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$" maxlength="50"
												(keyup)="enableBtn1 = false" />
											<div class="invalid-feedback"
												*ngIf="AddUserForm.get('middleName')!.invalid &&(AddUserForm.get('middleName')!.dirty ||AddUserForm.get('middleName')!.touched)">
												<div *ngIf="AddUserForm.get('middleName').errors.pattern">
													<label class="alert-icon alert-circle-icon"></label>
													<medium class="p-error">Middle Name should not have Special
														Character</medium>
												</div>
											</div>
										</div>
									</div>
									<div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
										<div class="p-fluid p-field">
											<label
												[ngClass]="{'field-required': AddUserForm.get('lastName')?.errors?.required}">
												Last Name
											</label>
											<span class="invalid-feedback-astrik">*</span>
											<input type="text" id="lastName_id" name="lastName_name"
												formControlName="lastName" pInputText placeholder="Last name"
												class="p-inputtext inputtextHeight"
												pattern="^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$" maxlength="50"
												(keyup)="enableBtn1 = false" />
											<div class="invalid-feedback"
												*ngIf="AddUserForm.get('lastName')!.invalid &&(AddUserForm.get('lastName')!.dirty ||AddUserForm.get('lastName')!.touched)">
												<medium *ngIf="AddUserForm.get('lastName')?.errors?.required"
													class="p-error">
													<label class="alert-icon alert-circle-icon"></label>
													Last name is required!
												</medium>
												<div *ngIf="AddUserForm.get('lastName').errors.pattern">
													<label class="alert-icon alert-circle-icon"></label>
													<medium class="p-error">Last Name should not have
														Special
														Character </medium>
												</div>
											</div>
										</div>
									</div>
									<div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
										<div class="p-fluid p-field">
											<label
												[ngClass]="{'field-required': AddUserForm.get('emailAddress')?.errors?.required}">
												Email Address
											</label>
											<span class="invalid-feedback-astrik">*</span>
											<span class="p-input-icon-right p-mb-0">
												<i aria-hidden="true" class="d-icons disable-icon"
													style="margin-right: 4px;margin-top: -9px;"
													*ngIf="this.firstSave"></i>
												<!-- <input pInputText type="email" id="emailAddress_id"
													*ngIf="this.rebacFlag=='false'" style="margin-bottom: 0px;"
													name="emailAddress_name" formControlName="emailAddress" pInputText
													placeholder="Email address" class="p-inputtext inputtextHeight"
													(keyup)="this.emailFlag = false;this.emailInvalidFlag = false;enableBtn1=false;colorChange();"
													pattern="(?=.{1,64}@)[A-Za-z0-9_-]+(\.[A-Za-z0-9_-]+)*@[^-][A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,})$" /> -->
													<input pInputText type="email" id="emailAddress_id"
													*ngIf="this.rebacFlag=='false'" style="margin-bottom: 0px;"
													name="emailAddress_name" formControlName="emailAddress" pInputText
													placeholder="Email address" class="p-inputtext inputtextHeight"
													(keyup)="this.emailFlag = false;this.emailInvalidFlag = false;enableBtn1=false;colorChange();"
													 />
												<span *ngIf="this.rebacFlag=='true'" class="p-d-flex">
													<input pInputText type="email" id="emailAddress_id"
														style="margin-bottom: 0px;" name="emailAddress_name"
														formControlName="emailAddress" pInputText
														placeholder="Email address" class="p-inputtext inputtextHeight"
														(keyup)="this.emailFlag = false;this.emailInvalidFlag = false;enableBtn1=false;this.noUserFound=false;this.userFound=false;"
														 />
													<button type="button" pButton icon="pi pi-search"
														*ngIf="this.firstSave!=true"
														[disabled]="AddUserForm.get('emailAddress')?.errors?.pattern || AddUserForm.get('emailAddress')?.errors?.required"
														class="p-button-secondary" style="border: 1px solid #6D6D6D"
														(click)="getUsersByEmail();this.emailFlag = false;this.emailInvalidFlag = false;enableBtn1=false;colorChange();"></button>
												</span>
											</span>

											<div class="invalid-feedback"
												*ngIf="AddUserForm.get('emailAddress')!.invalid &&(AddUserForm.get('emailAddress')!.dirty ||AddUserForm.get('emailAddress')!.touched)">
												<label *ngIf="AddUserForm.get('emailAddress')?.errors?.required">
													<div class="custom-font">
														<label class="alert-icon alert-circle-icon"
															for="rolename"></label>
														<medium id="rolename"
															style="display: inline-block;vertical-align: bottom;"
															class="p-error">
															Email address is required!
														</medium>
													</div>
												</label>
												<label
													*ngIf="AddUserForm.get('emailAddress')?.errors?.pattern && this.emailInvalidFlag==false"
													class="p-error">
													<div class="custom-font">
														<label class="alert-icon alert-circle-icon"
															for="rolename"></label>
														<medium id="rolename"
															style="display: inline-block;vertical-align: bottom;"
															class="p-error">Email ID input is not in Email ID format
														</medium>
													</div>
												</label>
											</div>
											<div class="invalid-feedback">
												<label *ngIf="this.emailFlag" class="p-error">
													<div class="custom-font">
														<label class="alert-icon alert-circle-icon"
															for="rolename"></label>
														<medium id="email" class="p-error">
															Email address already exists!
														</medium>
													</div>
												</label>
												<label *ngIf="this.emailInvalidFlag" class="p-error">
													<div class="custom-font">
														<label class="alert-icon alert-circle-icon"
															for="rolename"></label>
														<medium id="email" class="p-error">
															Email ID input is not in Email ID format
														</medium>
													</div>
												</label>
											</div>
											<div>
												<label *ngIf="this.noUserFound" class="p-error">
													<div class="custom-font">
														<label class="alert-icon alert-circle-icon"
															for="rolename"></label>
														<medium id="email" class="p-error">
															User Not Found
														</medium>
													</div>
												</label>
												<label *ngIf="this.userFound" class="p-error">
													<div class="custom-font">
														<label class="d-icons check-icon custom-label"
															style="position: relative;left: 0;"></label>
														<medium id="email" style="color: green;">
															User Found
														</medium>
													</div>
												</label>
											</div>
										</div>
									</div>
									<div
										class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left custom-user-input">
										<div class="p-fluid p-field">
											<label>
												Mobile Number
											</label>
											<div class="inputtext-medium custom-drop">
												<p-dropdown [filter]="true" filterBy="name,label" id="codeid"
													name="code" placeholder="code" [options]="codes"
													[autoDisplayFirst]="false" formControlName="countryCode"
													(onChange)="CheckNumber('setCode')" [showClear]="true"
													class="dropdownHeight">
													<ng-template let-item pTemplate="item" style="height: 32px;">
														<div class="drop-item" style="display: inline;height: 32px;"
															*ngIf="item.name.length <= 35;">
															{{item.label}} {{ item.name }}
														</div>
														<div class="drop-item" style="display: inline;height: 32px;"
															*ngIf="item.name.length > 35;" pTooltip="{{item.name}}"
															tooltipPosition="bottom">
															{{item.label}} {{ item.name.substring(0,35)
															+ "..." }}
														</div>
													</ng-template>
												</p-dropdown>
												<span style="width:195%">
													<input type="text" id="phoneNumbers_id" name="phoneNumbers_name"
														formControlName="phoneNumbers" pInputText
														placeholder="Mobile number" class="p-inputtext inputtextHeight"
														pattern="[0-9]{1,}" minLength="1"
														(keyup)="CheckNumber('setNumber')" />
												</span>
											</div>
											<div class="invalid-feedback"
												*ngIf="AddUserForm.get('phoneNumbers')!.invalid &&(AddUserForm.get('phoneNumbers')!.dirty ||AddUserForm.get('phoneNumbers')!.touched)">
												<div *ngIf="AddUserForm.get('phoneNumbers').errors.pattern">
													<label class="alert-icon alert-circle-icon"></label>
													<medium class="p-error">Phone Number should not have
														special characters &
														alphabets</medium>
												</div>
											</div>
											<div class="invalid-feedback">
												<div *ngIf="this.codeFlag">
													<label class="alert-icon alert-circle-icon"></label>
													<medium class="p-error">Country code is required
													</medium>
												</div>
												<div *ngIf="this.phoneNumberFlag">
													<label class="alert-icon alert-circle-icon"></label>
													<medium class="p-error">Phone Number is required
													</medium>
												</div>
											</div>
										</div>
									</div>
									<div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left"
										*ngIf="this.rebacFlag=='false'">

										<div class="p-fluid">
											<span class="d-block label-top"> <label>
													User Type
												</label>
											</span>
											<div class="p-fieldp-fluid">
												<p-selectButton id="selectSwitch_id" name="selectSwitch_name"
													[(ngModel)]="defaultselect_selectSwitch"
													[options]="selectSwitchList" formControlName="selectSwitch"
													styleClass="default" (onChange)="enableBtn1=false">
													<ng-template let-item>
														<span class="p-button-label">{{item.label}}</span>
													</ng-template>
												</p-selectButton>
											</div>
										</div>

									</div>
									<div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left">
										<div class="p-fluid p-field">
											<label>
												Username
											</label>
											<input type="text" id="userName_id" name="userName_name" maxlength="50"
												formControlName="userName" pInputText placeholder="Username"
												class="p-inputtext inputtextHeight" (keyup)="enableBtn1 = false ; 
												checkUserName();" />
											<div class="invalid-feedback">
												<div *ngIf="this.userNameCheckError">
													<label class="alert-icon alert-circle-icon"></label>
													<medium class="p-error">Invalid field format
													</medium>
												</div>
											</div>
											<div class="invalid-feedback">
												<div *ngIf="this.userNameExternalLdap">
													<label class="alert-icon alert-circle-icon"></label>
													<medium class="p-error">Username must not exceed 20 characters
													</medium>
												</div>
											</div>
										</div>
									</div>
									<!-- <div *ngIf="isIdentityProviderEnabled && this.firstSave==false"
										class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
										<div class="p-fluid">
											<span class="d-block label-top"> <label>
													Create In Identity Provider
												</label>
											</span>
											<div class="p-fieldp-fluid">
												<p-inputSwitch [(ngModel)]="this.createInIdentityProvider"
													formControlName="createInIdentityProvider"
													styleClass="p-inputswitch-md" class="p-mr-2 custom-switch">
												</p-inputSwitch>
											</div>
										</div>

									</div>
									<div *ngIf="this.rebacFlag=='false' && this.firstSave==false"
										class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
										<div class="p-fluid">
											<span class="d-block label-top"> <label>
													Create In External Ldap
												</label>
											</span>
											<div class="p-fieldp-fluid">
												<p-inputSwitch [(ngModel)]="this.createInExternalLdap"
													formControlName="createInExternalLdap" styleClass="p-inputswitch-md"
													class="p-mr-2 custom-switch" [disabled]="firstSave"
													(onChange)="setUsernameExternalLdap();">
												</p-inputSwitch>
											</div>
										</div>

									</div> -->
									<div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
										<div class=" p-fluid   p-field    ">
											<label
												[ngClass]="{'field-required': AddUserForm.get('AddUserForm')?.errors?.required}">
												Application
											</label>
											<div class="p-fluid">
												<p-multiSelect id="selectid" name="select"
													placeholder="Select a Application" placeholder="Select"
													[options]="applicationList" [filter]="true" filterBy="label"
													formControlName="application" display="chip" class="custom-chips">
													<ng-template let-items pTemplate="item">
														<div *ngIf="items?.label?.length &lt;=37;">
															{{items.label}}
														</div>
														<div *ngIf="items?.label?.length > 37;"
															pTooltip="{{items.label}}" tooltipPosition="bottom">
															{{items.label.substring(0,37) + "..." }}
														</div>
													</ng-template>
												</p-multiSelect>
											</div>
											<div class="invalid-feedback"
												*ngIf="AddUserForm.get('application')!.invalid && (AddUserForm.get('application')!.dirty || AddUserForm.get('application')!.touched)">
												<div *ngIf="AddUserForm.get('application')?.errors?.required">
													<label class="alert-icon alert-circle-icon"></label>
													<medium class="p-error">Application is required.
													</medium>
												</div>
											</div>
										</div>
									</div>
									<!-- <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
										<div class=" p-fluid   p-field    ">
											<label
												[ngClass]="{'field-required': AddUserForm.get('account')?.errors?.required}">
												Account
											</label>
											<div class="p-fluid">
											<p-dropdown id="accountid" name="account"
											placeholder="Select account" [options]="accountList"
											[autoDisplayFirst]="false" formControlName="account" (onChange)="enableBtn1 = false;"
											optionLabel="displayName"
											optionValue="id">
										</p-dropdown>
										</div>
										</div>
									</div> -->
									<div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left"
										*ngIf="this.rebacFlag=='true'">
										<div class=" p-fluid   p-field    ">
											<label
												[ngClass]="{'field-required': AddUserForm.get('account')?.errors?.required}">
												Account
											</label>
											<span class="invalid-feedback-astrik">*</span>
											<div class="p-fluid">
												<p-dropdown id="selectid" name="select" placeholder="Select Account"
													[options]="accountList" [filter]="true" filterBy="label"
													[autoDisplayFirst]="false" formControlName="account"
													(onChange)="enableBtn1 = false ; ">
													<ng-template let-items pTemplate="item">
														<div *ngIf="items?.label?.length &lt;=37;">
															{{items.label}}
														</div>
														<div *ngIf="items?.label?.length > 37;"
															pTooltip="{{items.label}}" tooltipPosition="bottom">
															{{items.label.substring(0,37) + "..." }}
														</div>
													</ng-template>
												</p-dropdown>
												<div class="invalid-feedback"
													*ngIf="AddUserForm.get('account')!.invalid &&(AddUserForm.get('account')!.dirty ||AddUserForm.get('account')!.touched)">
													<medium *ngIf="AddUserForm.get('account')?.errors?.required"
														class="p-error">
														<label class="alert-icon alert-circle-icon"></label>
														Account name is required!
													</medium>
												</div>
											</div>
										</div>
									</div>
									<div *ngFor="let customField of customList;let index=index;let count=count"
										class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined">
										<div *ngIf="customField.fieldInputtype=='Dropdown'">
											<div class="p-fluid p-field">
												<label *ngIf="customField.mandatoryField==true"
													[ngClass]="{'field-required': AddUserForm.get(customField.id)?.errors?.required}">
													{{customField.fieldName}}
												</label>
												<label *ngIf="customField.mandatoryField==false">
													{{customField.fieldName}}
												</label>
												<span class="invalid-feedback-astrik"
													*ngIf="customField.mandatoryField==true">*</span>
												<div class="p-fluid p-field">
													<p-dropdown id="{{customField.id}}id" name="{{customField.id}}"
														placeholder="Select" (onChange)="this.enableBtn1=false;"
														[options]="customField.inputFormat"
														formControlName="{{customField.id}}" [autoDisplayFirst]="false"
														[required]="customField.mandatoryField==true">
													</p-dropdown>
													<div class="invalid-feedback"
														*ngIf="AddUserForm.get(customField.id)!.invalid && (AddUserForm.get(customField.id)!.dirty || AddUserForm.get(customField.id)!.touched) ">
														<div *ngIf="AddUserForm.get(customField.id)?.errors?.required">
															<label class="alert-icon alert-circle-icon"></label>
															<medium class="p-error">This field is required</medium>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div *ngIf="customField.fieldInputtype=='Text'">
											<div class="p-fluid p-field">
												<label *ngIf="customField.mandatoryField==true"
													[ngClass]="{'field-required': AddUserForm.get(customField.id)?.errors?.required}">
													{{customField.fieldName}}
												</label>
												<label *ngIf="customField.mandatoryField==false">
													{{customField.fieldName}}
												</label>
												<span class="invalid-feedback-astrik"
													*ngIf="customField.mandatoryField==true">*</span>
												<div class="p-fluid p-field">
													<input type="text" id="{{customField.id}}_id"
														name="{{customField.id}}_name" pInputText
														formControlName="{{customField.id}}"
														placeholder="Enter {{customField.fieldName}}"
														class="p-inputtext inputtextHeight"
														maxlength="{{customField.maxCharacters}}"
														[required]="customField.mandatoryField==true"
														[pattern]="customField.pattern" (keyup)="enableBtn1 = false" />
													<div class="invalid-feedback"
														*ngIf="AddUserForm.get(customField.id)!.invalid && (AddUserForm.get(customField.id)!.dirty || AddUserForm.get(customField.id)!.touched) ">
														<div *ngIf="AddUserForm.get(customField.id)?.errors?.required">
															<label class="alert-icon alert-circle-icon"></label>
															<medium class="p-error">This field is required</medium>
														</div>
														<div *ngIf="AddUserForm.get(customField.id)?.errors?.pattern">
															<label class="alert-icon alert-circle-icon"></label>
															<medium class="p-error">Invalid field format</medium>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div
											*ngIf="customField.fieldInputtype=='Input field in range' && customField.inputFormat[0]=='Date range'">
											<div class="p-fluid p-field">
												<label *ngIf="customField.mandatoryField==true"
													[ngClass]="{'field-required': AddUserForm.get(customField.id)?.errors?.required}">
													{{customField.fieldName}}
												</label>
												<label *ngIf="customField.mandatoryField==false">
													{{customField.fieldName}}
												</label>
												<span class="invalid-feedback-astrik"
													*ngIf="customField.mandatoryField==true">*</span>
												<div class="p-fluid p-field">
													<p-calendar class="custom-calender" selectionMode="range"
														dataString="string" [(ngModel)]="this[customField.id]"
														showButtonBar="true" [showIcon]="true" dateFormat="dd-mm-yy"
														inputId="dateformat" placeholder="Select date"
														formControlName="{{customField.id}}"
														[required]="customField.mandatoryField==true"
														(onSelect)="enableBtn1 = false">
													</p-calendar>
													<div class="invalid-feedback"
														*ngIf="AddUserForm.get(customField.id)!.invalid && (AddUserForm.get(customField.id)!.dirty || AddUserForm.get(customField.id)!.touched) ">
														<div *ngIf="AddUserForm.get(customField.id)?.errors?.required">
															<label class="alert-icon alert-circle-icon"></label>
															<medium class="p-error">This field is required</medium>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div *ngIf="customField.fieldInputtype=='Text area'">
											<div class="p-fluid p-field">
												<label *ngIf="customField.mandatoryField==true"
													[ngClass]="{'field-required': AddUserForm.get(customField.id)?.errors?.required}">
													{{customField.fieldName}}
												</label>
												<label *ngIf="customField.mandatoryField==false">
													{{customField.fieldName}}
												</label>
												<span class="invalid-feedback-astrik"
													*ngIf="customField.mandatoryField==true">*</span>
												<div class="p-fluid p-field">
													<textarea pInputTextarea id="{{customField.id}}_id"
														name="{{customField.id}}_name" rows="3"
														formControlName="{{customField.id}}"
														maxlength="{{customField.maxCharacters}}"
														[required]="customField.mandatoryField==true"
														[pattern]="customField.pattern"
														(keyup)="enableBtn1 = false"></textarea>
													<div class="invalid-feedback"
														*ngIf="AddUserForm.get(customField.id)!.invalid && (AddUserForm.get(customField.id)!.dirty || AddUserForm.get(customField.id)!.touched) ">
														<div *ngIf="AddUserForm.get(customField.id)?.errors?.required">
															<label class="alert-icon alert-circle-icon"></label>
															<medium class="p-error">This field is required</medium>
														</div>
														<div *ngIf="AddUserForm.get(customField.id)?.errors?.pattern">
															<label class="alert-icon alert-circle-icon"></label>
															<medium class="p-error">Invalid field format</medium>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div
											*ngIf="customField.fieldInputtype=='Input field in range' && customField.inputFormat[0]=='Text/Number range'">
											<div class="p-fluid p-field">
												<label *ngIf="customField.mandatoryField==true"
													[ngClass]="{'field-required': AddUserForm.get(customField.id+'_start')?.errors?.required || AddUserForm.get(customField.id+'_end')?.errors?.required}">
													{{customField.fieldName}}
												</label>
												<label *ngIf="customField.mandatoryField==false">
													{{customField.fieldName}}
												</label>
												<span class="invalid-feedback-astrik"
													*ngIf="customField.mandatoryField==true">*</span>
												<div class="inputtext-medium custom-drop">
													<span class="p-input-icon-right p-mr-2" style="width:70%">
														<input type="text" id="{{customField.id}}_id"
															name="{{customField.id}}_name"
															formControlName="{{customField.id}}_start" pInputText
															placeholder="Enter start range"
															class="p-inputtext inputtextHeight "
															[required]="customField.mandatoryField==true"
															(keyup)="enableBtn1 = false" />
													</span>
													<span>-</span>
													<span class="p-input-icon-right p-ml-2" style="width:70%">
														<input type="text" id="{{customField.id}}_id"
															name="{{customField.id}}_name"
															formControlName="{{customField.id}}_end" pInputText
															[required]="customField.mandatoryField==true"
															placeholder="Enter last range"
															class="p-inputtext inputtextHeight"
															(keyup)="enableBtn1 = false" />
													</span>
												</div>

												<div class="invalid-feedback"
													*ngIf="(AddUserForm.get(customField.id+'_start')!.invalid && (AddUserForm.get(customField.id+'_start')!.dirty || AddUserForm.get(customField.id+'_start')!.touched)) ||  (AddUserForm.get(customField.id+'_end')!.invalid && (AddUserForm.get(customField.id+'_end')!.dirty || AddUserForm.get(customField.id+'_end')!.touched))">
													<div
														*ngIf="(AddUserForm.get(customField.id+'_start')?.errors?.required) || (AddUserForm.get(customField.id+'_end')?.errors?.required)">
														<label class="alert-icon alert-circle-icon"></label>
														<medium class="p-error">This field is required</medium>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined">
										<div *ngFor="let customField of checkboxArr;let index=index;let count=count"
											class="p-col-12 p-lg-2 p-lg-4 p-xl-4 p-p-0">
											<div class="p-fluid p-formgrid p-grid"
												*ngIf="customField.fieldInputtype=='Checkbox'">
												<div class="p-field-checkbox p-mt-1">
													<p-checkbox [(ngModel)]="this[customField.id]" binary="true"
														inputId="binary" (onChange)="this.enableBtn1=false;"
														formControlName="{{customField.id}}">
													</p-checkbox>
													<label for="binary">{{customField.fieldName}}</label>
												</div>
											</div>
										</div>
									</div>
									<div
										class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right p-mb-3 p-mt-3">
										<div class="btnwrap p-mr-3" *ngIf="!this.firstSave">
											<button pButton type="button" label="B-2 Primary" class="p-button-brand2"
												(click)="onClearUser(AddUserForm)" label="Clear"></button>
										</div>
										<div class="btnwrap p-mr-3"
											*ngIf="this.pagesService.pageRebacFlag=='false' && this.pagesService.isMailAutoTrigger=='false'">

											<button pButton type="button" [pTooltip]="emailSendButtonTooltip"
												tooltipPosition="top" label={{emailSendButtonLabel}}
												class="p-mr-2 p-button-secondary p-button-sm" (click)="sendEmail()"
												[disabled]="!this.firstSave">
											</button>
										</div>
										<div class="btnwrap">
											<button pButton class="p-button-primary" type="submit"
												(click)="createUser(AddUserForm);this.userFound=false;this.noUserFound=false;"
												[disabled]="emailFlag || enableBtn1 || !AddUserForm.valid || this.userNameCheckError || userNameExternalLdap"
												label="Save"></button>
										</div>
									</div>
								</div>
							</p-tabPanel>
							<p-tabPanel>
								<div class="p-fluid p-formgrid p-grid  p-mt-3">
									<div class="p-col-12 p-sm-12 p-md-6 p-lg-4 p-xl-4 p-offset-0 left">
										<div class="p-fluid p-field">
											<label>
												Role
											</label>
											<div class="p-fluid p-field">
												<p-multiSelect id="roleList" name="roleList"
													class="custom-chips custom-check" filterPlaceHolder="Select Roles"
													[options]="roleListList" [filter]="true" formControlName="roleList"
													optionLabel="label" optionValue="value"
													selectedItemsLabel="{0} Roles selected" display="chip"
													resetFilterOnHide="true" placeholder="Select Roles"
													(onChange)="enableBtn2 = false">
													<ng-template let-each pTemplate="selectedItem">
														{{ each.label }}
													</ng-template>
													<ng-template let-item pTemplate="each">
														<div class="drop-item" style="display: inline;">
															{{ item.label }}
														</div>
													</ng-template>
												</p-multiSelect>
											</div>
										</div>
									</div>
									<div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
									</div>
									<div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
									</div>
									<div
										class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right p-mb-2">
										<div class="btnwrap">
											<button pButton class="p-button-primary" type="submit"
												(click)="assignRoles(AddUserForm)" [disabled]="enableBtn2"
												label="Save"></button>
										</div>
									</div>
								</div>
							</p-tabPanel>
							<p-tabPanel>
								<div class="p-fluid p-formgrid p-grid p-mt-3">
									<div class="p-col-12 p-sm-12 p-md-6 p-lg-4 p-xl-4 p-offset-0 left">
										<div class="p-fluid p-field">
											<label>
												Group
											</label>
											<div class="p-fluid p-field">
												<p-multiSelect id="groupList" name="groupList"
													class="custom-chips custom-check" filterPlaceHolder="Select Groups"
													[options]="groupListList" [filter]="true"
													formControlName="groupList" optionLabel="label" optionValue="value"
													selectedItemsLabel="{0} Groups selected" display="chip"
													resetFilterOnHide="true" placeholder="Select Groups"
													(onChange)="enableBtn3 = false">
													<ng-template let-each pTemplate="selectedItem">
														{{ each.label }}
													</ng-template>
													<ng-template let-item pTemplate="each">
														<div class="drop-item" style="display: inline;">
															{{ item.label }}
														</div>
													</ng-template>
												</p-multiSelect>
											</div>
										</div>
									</div>
									<div
										class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right p-mb-2">
										<div class="btnwrap">
											<button pButton class="p-button-primary" type="submit"
												[disabled]="enableBtn3" (click)="assignGroups(AddUserForm)"
												label="Save"></button>
										</div>
									</div>
								</div>
							</p-tabPanel>
						</p-tabView>
					</div>
					<!--Close Tab view-->
				</p-card>
			</div>
		</div>
	</form>
</div>