<div class="layout-content custom-container ">
    <form [formGroup]="CreateResourcesForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                <div class=" p-fluid   p-field    ">
                    <label [ngClass]="{'field-required': CreateResourcesForm.get('select')?.errors?.required}">
                        Application
                        <span class='invalid-feedback-astrik'>*</span></label>
                    <div class="p-fluid  p-field">
                        <p-dropdown [filter]="true" filterBy="label" [(ngModel)]="app" id="selectid" name="select"
                            placeholder="Application" [options]="selectList" [autoDisplayFirst]="false"
                            formControlName="select" filter="true" (onChange)="getComponent();">
                            <ng-template let-items pTemplate="item">
                                <div *ngIf="items?.label?.length &lt;= 35;">
                                    {{items.label}}
                                </div>
                                <div *ngIf="items?.label?.length > 35;" pTooltip="{{items.label}}"
                                    tooltipPosition="bottom">
                                    {{items.label.substring(0,35) + "..." }}
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                    <div class="p-error"
                        *ngIf="CreateResourcesForm.get('select')!.invalid && (CreateResourcesForm.get('select')!.dirty || CreateResourcesForm.get('select')!.touched)">
                        <div *ngIf="CreateResourcesForm.get('select')?.errors?.required">
                            Application is required.
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                <div class=" p-fluid   p-field    ">
                    <label [ngClass]="{'field-required': CreateResourcesForm.get('select1')?.errors?.required}">
                        Component
                        <span class='invalid-feedback-astrik'>*</span></label>
                    <div class="p-fluid  p-field">
                        <p-dropdown [filter]="true" filterBy="label" [(ngModel)]="com" filter="true" id="select1id"
                            name="select1" placeholder="Component" [options]="select1List" [autoDisplayFirst]="false"
                            formControlName="select1">
                        </p-dropdown>
                    </div>
                    <div class="p-error"
                        *ngIf="CreateResourcesForm.get('select1')!.invalid && (CreateResourcesForm.get('select1')!.dirty || CreateResourcesForm.get('select1')!.touched)">
                        <div *ngIf="CreateResourcesForm.get('select1')?.errors?.required">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium class="p-error"> Component is required.</medium>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                <div class=" p-fluid   p-field    " style="margin-bottom: 0px;">
                    <label [ngClass]="{'field-required': CreateResourcesForm.get('textField')?.errors?.required}">
                        Resource Name
                        <span class='invalid-feedback-astrik'>*</span></label>
                    <input type="text" id="textField_id1" name="textField_name" formControlName="textField" pInputText
                        placeholder="Enter resource name" pattern="^[^\s]+(\s+[^\s]+)*$" maxlength="50"
                        (keyup)="this.nameFlag=false;colorChange()" class="p-inputtext inputtextHeight  " />

                    <div class="p-error"
                        *ngIf="CreateResourcesForm.get('textField')!.invalid && (CreateResourcesForm.get('textField')!.dirty || CreateResourcesForm.get('textField')!.touched)">
                        <div *ngIf="CreateResourcesForm.get('textField')?.errors?.required">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium class="p-error">Resource name is required.</medium>
                        </div>
                        <div *ngIf="CreateResourcesForm.get('textField').errors.pattern">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium class="p-error">No space allowed</medium>
                        </div>
                    </div>
                    <div class="invalid-feedback">
                        <label *ngIf="this.nameFlag">
                            <div>
                                <label class="alert-icon alert-circle-icon"></label>
                                <medium class="p-error">Resource Name already exists.
                                </medium>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                <div class=" p-fluid">
                    <label [ngClass]="{'field-required': CreateResourcesForm.get('textField1')?.errors?.required}">
                        Endpoint URL
                        <span class='invalid-feedback-astrik'>*</span></label>
                    <input type="text" id="textField1_id" name="textField1_name" formControlName="textField1" pInputText
                        placeholder="Enter endpoint URL" class="p-inputtext inputtextHeight" (keyup)="enableBtn=false"
                        pattern="^[^\s]*$" />
                    <div class="p-error"
                        *ngIf="CreateResourcesForm.get('textField1')!.invalid && (CreateResourcesForm.get('textField1')!.dirty || CreateResourcesForm.get('textField1')!.touched)">
                        <div *ngIf="CreateResourcesForm.get('textField1')?.errors?.required">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium class="p-error">Endpoint URL is required.</medium>
                        </div>
                        <div *ngIf="CreateResourcesForm.get('textField1').errors.pattern">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium class="p-error">No space allowed</medium>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                <label [ngClass]="{'field-required': CreateResourcesForm.get('select2')?.errors?.required}">
                    Method
                    <span class='invalid-feedback-astrik'>*</span></label>
                <div class="p-fluid">
                    <p-dropdown [filter]="true" filterBy="label" id="select2id" name="select2" placeholder="Select"
                        [options]="select2List" [autoDisplayFirst]="false" formControlName="select2">
                    </p-dropdown>
                </div>
                <div class="p-error"
                    *ngIf="CreateResourcesForm.get('select2')!.invalid && (CreateResourcesForm.get('select2')!.dirty || CreateResourcesForm.get('select2')!.touched)">
                    <div *ngIf="CreateResourcesForm.get('select2')?.errors?.required">
                        <label class="alert-icon alert-circle-icon"></label>
                        <medium class="p-error">Method is required.</medium>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left" style="margin-top: 35px;">
                <div class="p-field-checkbox">
                    <p-checkbox name="applicationRoleRequired" binary="true" inputId="binary"
                        formControlName="applicationRoleRequired">
                    </p-checkbox>
                    <label for="sf">Application Role Required</label>
                </div>
            </div>


            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right p-mt-3 p-mb-2">
                <div class="btnwrap p-mr-3">
                    <button pButton type="button" class="p-button-brand2 p-button"
                        (click)="onClose(CreateResourcesForm);" label="Close">
                    </button>
                </div>
                <div class="btnwrap">
                    <button pButton class="p-button" type="button"
                        (click)="onSaveCheckResourceName(CreateResourcesForm);"
                        [disabled]="enableBtn || nameFlag || !CreateResourcesForm.valid" label="Save">
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>