<div>
    <div class="p-d-flex p-jc-end">
        <p-messages key="myKey1" class="custom-alert-box" *ngIf="this.messageFlag"></p-messages>
    </div>
    <div class="p-col-fixed p-mt-1 p-ml-1 p-pr-0 custom-breadcrumb">
        <p-breadcrumb *ngIf="!firstSave" [model]="ccprimebreadcrumbList" [home]="homeiconccprimebreadcrumb">
        </p-breadcrumb>
        <p-breadcrumb *ngIf="firstSave" [model]="ccprimebreadcrumbList1" [home]="homeiconccprimebreadcrumb">
        </p-breadcrumb>
    </div>
    <form class="layout-content custom-form-layout" [formGroup]="AddRolesForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6 p-offset-0 left">
                <h5 class="custom-head1" *ngIf="!firstSave">Add New Role</h5>
                <h5 class="custom-head1" *ngIf="firstSave">Edit Role</h5>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6 p-offset-0 left">
                <div class="   ">
                    <p-messages *ngIf="messageFlag"></p-messages>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                <p-card>
                    <div class="p-p-2">
                        <button pButton type="button" (click)="activeIndex1 = 0"
                            class="p-button-outlined p-button-lg tabButton role-identity" label="Role Identity"
                            [style.box-shadow]="activeIndex1 == 0 ? '1px 5px 20px #bdc0c9' :  '0px 1px 4px #bdc0c9'"
                            [style.border]="activeIndex1 == 0 ? '1px solid #396FC5' :  '#add8e6'"
                            [style.color]=" activeIndex1 == 0 ? '#396FC5' : '#6C7387'">
                            <div class="fa-2x">
                                <span class="fa-layers fa-fw">
                                    <label class="c-icons identity-icon"></label>
                                </span>
                            </div>
                            <span class="p-button-icon p-button-icon-left reshot-icon-business-team"
                                aria-hidden="true"></span>
                            <label class="d-icons check-icon role-identity-label" width="14" height="14"
                                *ngIf="tab1"></label>
                        </button>
                        <button pButton type="button" [disabled]="clicked2" (click)="activeIndex1 = 1"
                            label="Composite Role" id="panelbtn2"
                            class="p-button-outlined p-button-lg tabButton composite-role" *ngIf="false"
                            [style.box-shadow]="activeIndex1 == 1 ? '1px 5px 20px #bdc0c9' :  '0px 1px 4px #bdc0c9'"
                            [style.border]="activeIndex1 == 1 ? '1px solid #396FC5' :  '#add8e6'"
                            [style.color]=" activeIndex1 == 1 ? '#396FC5' : '#6C7387'">
                            <div class="fa-2x">
                                <span class="fa-layers fa-fw">
                                    <label class="c-icons composite-icon"></label>
                                </span>
                            </div>
                            <span class="p-button-icon p-button-icon-left reshot-icon-business-team"
                                aria-hidden="true"></span>
                            <label class="d-icons check-icon composite-role-label" width="14" height="14"
                                *ngIf="tab2"></label>
                        </button>
                        <button pButton type="button"
                            [disabled]="clicked || this.pagesService.pageTenantAdminFlag==false"
                            (click)="activeIndex1 = 2" label="Users" id="panelbtn3"
                            class="p-button-outlined p-button-lg tabButton users"
                            [style.box-shadow]="activeIndex1 == 2 ? '1px 5px 20px #bdc0c9' :  '0px 1px 4px #bdc0c9'"
                            [style.border]="activeIndex1 == 2 ? '1px solid  #396FC5' :  '#add8e6'"
                            [style.color]=" activeIndex1 == 2 ? '#396FC5' : '#6C7387'">
                            <div class="fa-2x">
                                <span class="fa-layers fa-fw">
                                    <label class="c-icons user-icon"></label>
                                </span>
                            </div>
                            <span class="p-button-icon p-button-icon-left reshot-icon-business-team"
                                aria-hidden="true"></span>
                            <label class="d-icons check-icon users-label" width="14" height="14" *ngIf="tab3"></label>
                        </button>
                        <button pButton type="button"
                            [disabled]="clicked || this.pagesService.pageTenantAdminFlag==false"
                            (click)="activeIndex1 = 3" label="Group" id="panelbtn4"
                            class="p-button-outlined p-button-lg tabButton group"
                            [style.box-shadow]="activeIndex1 == 3 ? '1px 5px 20px #bdc0c9' :  '0px 1px 4px #bdc0c9'"
                            [style.border]="activeIndex1 == 3 ? '1px solid  #396FC5' :  '#add8e6'"
                            [style.color]=" activeIndex1 == 3 ? '#396FC5' : '#6C7387'">
                            <div class="fa-2x">
                                <span class="fa-layers fa-fw">
                                    <label class="c-icons group-icon"></label>
                                </span>
                            </div>
                            <span class="p-button-icon p-button-icon-left reshot-icon-business-team"
                                aria-hidden="true"></span>
                            <label class="d-icons check-icon group-label" width="14" height="14" *ngIf="tab4"></label>
                        </button>
                        <button pButton type="button" [disabled]="true" *ngIf="false" (click)="activeIndex1 = 4"
                            label="Access policies" id="panelbtn5"
                            class="p-button-outlined p-button-lg tabButton access-policies"
                            [style.box-shadow]="activeIndex1 == 4 ? '1px 5px 20px #bdc0c9' :  '0px 1px 4px #bdc0c9'"
                            [style.border]="activeIndex1 == 4 ? '1px solid #396FC5' :  '#add8e6'"
                            [style.color]=" activeIndex1 == 4 ? '#396FC5' : '#6C7387'">
                            <div class="fa-2x">
                                <span class="fa-layers fa-fw">
                                    <label class="c-icons access-icon"></label>
                                </span>
                            </div>
                            <span class="p-button-icon p-button-icon-left reshot-icon-business-team"
                                aria-hidden="true"></span>
                            <label class="d-icons check-icon access-policies-label" width="14" height="14"
                                *ngIf="tab5"></label>
                        </button>
                    </div>
                    <div class="tabview-custom">
                        <p-tabView [(activeIndex)]="activeIndex1">
                            <p-tabPanel>
                                <div class="p-fluid p-formgrid p-grid p-mt-3">
                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-0 left">
                                        <div class=" p-fluid   p-field    ">
                                            <label
                                                [ngClass]="{'field-required': AddRolesForm.get('displayName')?.errors?.required}">
                                                Role Name
                                            </label> <span class='invalid-feedback-astrik'>*</span>
                                            <span class="">
                                                <i aria-hidden="true" class="d-icons disable-icon"
                                                    style="margin-right: 4px;margin-top: -6px;"
                                                    *ngIf="this.firstSave"></i>
                                                <input type="text" id="displayName_id" name="displayName_name"
                                                    formControlName="displayName" pInputText placeholder="Role name"
                                                    class="p-inputtext inputtextHeight  "
                                                    pattern="^([a-zA-Z0-9_@./#&+-]+\s)*[a-zA-Z0-9_@./#&+-]+$"
                                                    maxlength="50"
                                                    (keyup)="enableBtn1=false;this.nameFlag=false;colorChange();" />
                                            </span>
                                            <span id="lblError" class="p-error"></span>
                                            <div class="p-error"
                                                *ngIf="AddRolesForm.get('displayName')!.invalid && (AddRolesForm.get('displayName')!.dirty || AddRolesForm.get('displayName')!.touched)">
                                                <div *ngIf="AddRolesForm.get('displayName').errors.pattern">
                                                    <label class="alert-icon alert-circle-icon" for="rolename"></label>
                                                    <span class="p-error">Fill the Role Name properly</span>
                                                </div>
                                                <label *ngIf="AddRolesForm.get('displayName')?.errors?.required"
                                                    class="p-error">
                                                    <div>
                                                        <label class="alert-icon alert-circle-icon"
                                                            for="rolename"></label>
                                                        <span class="" id="rolename"
                                                            style="display:inline-block;vertical-align: bottom;">Role
                                                            Name is required. </span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div class="p-error">
                                                <label *ngIf="this.nameFlag" class="p-error">
                                                    <div>
                                                        <label class="alert-icon alert-circle-icon"
                                                            for="rolename"></label>
                                                        <span id="rolename"
                                                            style="display:inline-block;vertical-align: bottom;"
                                                            class="p-error">Role Name already exists.
                                                        </span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-undefined left"
                                        style="display: none;">
                                        <h6>Type</h6>
                                        <div class="prime-selectbutton-override">
                                            <p-selectButton id="roleType_id" name="roleType_name"
                                                formControlName="roleType" [options]="stateOptions" optionLabel="label"
                                                optionValue="value"></p-selectButton>
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-undefined left">
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 left">
                                        <div class=" p-fluid   p-field    ">
                                            <label>
                                                Description
                                            </label>
                                            <textarea pInputTextarea id="roleDescription_id" name="roleDescription_name"
                                                formControlName="roleDescription" rows="3" placeholder="Add description"
                                                maxlength="255" (keyup)="enableBtn1=false"></textarea>
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 p-right p-mb-2">
                                        <div class="btnwrap p-mr-3" *ngIf="!this.firstSave">
                                            <button pButton type="button" class="p-button-brand2"
                                                (click)="this.AddRolesForm.reset();" label="Clear">
                                            </button>
                                        </div>
                                        <div class="btnwrap">
                                            <button pButton class="p-button-primary" type="submit"
                                                [disabled]="nameFlag || enableBtn1 || !AddRolesForm.valid"
                                                (click)="createRole(AddRolesForm);" label="Save">
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </p-tabPanel>
                            <p-tabPanel>
                                <div class="p-fluid p-formgrid p-grid p-mt-3">
                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-0 left">
                                        <div class=" p-fluid   p-field    ">
                                            <label>
                                                Role
                                            </label>
                                            <div class="p-fluid  p-field">
                                                <p-dropdown id="selectRoleid" name="selectRole"
                                                    placeholder="Select role" [options]="selectRoleList"
                                                    [autoDisplayFirst]="false" formControlName="selectRole">
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-undefined left">
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-undefined left">
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 p-right p-mb-2">
                                        <div class="btnwrap">
                                            <button pButton class="p-button-primary" type="button"
                                                (click)="onCcprimebutton19(AddRolesForm);" label="Save">
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </p-tabPanel>
                            <p-tabPanel>
                                <div class="p-fluid p-formgrid p-grid p-mt-3">
                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-0 left">
                                        <div class=" p-fluid   p-field    ">
                                            <label>
                                                Users
                                            </label>
                                            <div class="p-fluid  p-field">
                                                <p-multiSelect id="selectUsersid" name="selectUsers"
                                                    filterPlaceHolder="Select Users" [options]="userListList"
                                                    [filter]="true" formControlName="userList" class="custom-chips"
                                                    optionLabel="label" optionValue="value"
                                                    selectedItemsLabel="{0} Users selected" display="chip"
                                                    resetFilterOnHide="true" placeholder="Select Users"
                                                    (onChange)="enableBtn2=false">
                                                    <ng-template let-each pTemplate="selectedItem">
                                                        {{each.label}}
                                                    </ng-template>
                                                    <ng-template let-items pTemplate="item">
                                                        <div *ngIf="items?.label?.length <= 35;">
                                                            {{items.label}}
                                                        </div>
                                                        <div *ngIf="items?.label?.length > 35;"
                                                            pTooltip="{{items.label}}" tooltipPosition="bottom">
                                                            {{items?.label?.substring(0,35) + "..." }}
                                                        </div>
                                                    </ng-template>
                                                </p-multiSelect>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-undefined left">
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-undefined left">
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 p-right p-mb-2">
                                        <div class="btnwrap">
                                            <button pButton class="p-button-primary" type="button"
                                                [disabled]="enableBtn2" (click)="onAssignUsersToRole(AddRolesForm);"
                                                label="Save">
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </p-tabPanel>
                            <p-tabPanel>
                                <div class="p-fluid p-formgrid p-grid p-mt-3">
                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-0 left">
                                        <div class=" p-fluid   p-field    ">
                                            <label>
                                                Group
                                            </label>
                                            <div class="p-fluid  p-field">
                                                <p-multiSelect id="selectGroupid" name="selectGroup"
                                                    filterPlaceHolder="Select UGroups" [options]="groupListList"
                                                    [filter]="true" formControlName="groupList" class="custom-chips"
                                                    optionLabel="label" optionValue="value"
                                                    selectedItemsLabel="{0} Groups selected" display="chip"
                                                    resetFilterOnHide="true" placeholder="Select Groups"
                                                    (onChange)="enableBtn3=false">
                                                    <ng-template let-each pTemplate="selectedItem">
                                                        {{each.label}}
                                                    </ng-template>
                                                    <ng-template let-items pTemplate="item">
                                                        <div *ngIf="items?.label?.length <= 35;">
                                                            {{items.label}}
                                                        </div>
                                                        <div *ngIf="items?.label?.length > 35;"
                                                            pTooltip="{{items.label}}" tooltipPosition="bottom">
                                                            {{items?.label?.substring(0,35) + "..." }}
                                                        </div>
                                                    </ng-template>
                                                </p-multiSelect>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-undefined left">
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-undefined left">
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 p-right p-mb-2">
                                        <div class="btnwrap">
                                            <button pButton class="p-button-primary" type="button"
                                                [disabled]="enableBtn3" (click)="onAssignGroupsToRole(AddRolesForm);"
                                                label="Save">
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </p-tabPanel>
                            <p-tabPanel>
                                <div class="p-fluid p-formgrid p-grid">
                                    <div class=" p-col-12">
                                        <div class="ui-fluid p-formgrid p-grid">
                                            <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-0 left">
                                                <div class=" p-fluid   p-field    ">
                                                    <label>
                                                        Access Policies
                                                    </label>
                                                    <div class="p-fluid  p-field">
                                                        <p-dropdown id="selectAccessPoliciesid"
                                                            name="selectAccessPolicies" placeholder="Select policies"
                                                            [options]="selectAccessPoliciesList"
                                                            [autoDisplayFirst]="false"
                                                            formControlName="selectAccessPolicies">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-undefined left">
                                            </div>
                                            <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-undefined left">
                                            </div>
                                        </div>
                                    </div>
                                    <div class=" p-col-12">
                                        <div class="ui-fluid p-formgrid p-grid">
                                            <div
                                                class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 p-right p-mb-2">

                                                <div class="btnwrap">
                                                    <button pButton class="p-button-primary" type="button"
                                                        (click)="onCcprimebutton11(AddRolesForm);" label="Save">
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </p-tabPanel>
                        </p-tabView>
                    </div>
                </p-card>
            </div>
        </div>
    </form>
</div>