import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PagesService } from '../pages.service';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
@Component({
  selector: 'jhi-deleteattribute',
  templateUrl: './DeleteGroup.component.html',
  styleUrls: ['./DeleteGroup.component.scss'],
})
export class DeleteGroupComponent implements OnInit {
  DeleteGroupForm = this.fb.group({
  });
  pageloadvalues: any;
  groupId: any;
  activeItem: any;
  dName: any;
  Ucount: any;
  Rcount: any;

  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    private messageService: MessageService,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.DeleteGroupForm.patchValue(JSON.parse(data));
    }
  }

  ngOnInit(): void {
    this.activeItem = this.config?.data?.id;
    this.dName = this.config?.data?.name;
    this.getuserTableList();
    this.getRoleList();
  }

  onClickNo($event: UntypedFormGroup): void {
    this.ref.close();
  }

  /**
   * delete  usergroup 
   */

  //get user list -display 
  getuserTableList(): void {
    this.pagesService.getuserTableDataViewGroup(this.activeItem).subscribe(results => {
      if (results?.error?.messageCode == "CIAM 200") {
        this.Ucount = 0;
      }
      else if (results?.success?.messageCode == "CIAM 250") {
        const responseData = results.success.result;
        this.Ucount = responseData.length;
      }
    });
  }

  //get role list -display
  getRoleList(): void {
    this.pagesService.getRoleByGroup(this.activeItem).subscribe(results => {
      if (results?.error?.messageCode == "CIAM 400") {
        this.Rcount = 0;
      }
      else if (results?.success?.messageCode == "CIAM 450") {
        const responseData = results.success.result;
        this.Rcount = responseData.length;
      }
    });
  }

  //delete group API
  deleteGroup() {
    const data: any = this.config?.data?.id;
    this.pagesService.deleteGroup(data).subscribe({
      next: results => {
        this.ref.close("Group Deleted Successfully");
      },
      error: error => {
        this.ref.close("Failed to Delete Group");
      }
    });
  }

}