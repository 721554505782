
export const ItemName = {
    USER_ID: 'User ID',
    SESSION_ID: 'Session ID',
    SESSION_START_TIME : 'Session Start Time',
    SESSION_END_TIME: 'Session End Time',
    IP_ADDRESS: 'IP Address',
    BROWSER: 'Browser',
    VIEW_MANAGE_USER_PAGE: 'View Manage User Page',
    EMAIL_ID: 'Email ID',
    NAME: 'Name', 
    USER_NAME: 'User Name',
    ORGANIZATION_NAME :'Organization Name',
    STATUS: 'Status',
    USER_TYPE: 'User Type',
    ORGANIZATION_ID: 'Organization ID',
    AD_SERVER: 'AD Server',
    IP_RANGE: 'IPRange',
    MULTI_FACTOR_AUTHENTICATION:'Multifactor Authentication',
    PHONE_NUMBER: 'Phone Number',
    AD_USER_NAME: 'ADUserName',
    USER_SALESFORCE_ID: 'UserSalesForceID',
    Firm_ID: 'Firm ID',
    ROLE_ID: 'Role ID',
    ROLE_NAME: 'Role Name',
    ENTITLEMENT_ID: 'Entitlement ID',
    COMPONENT_NAME: 'Component Name',
    ENTITLEMENT_NAME: 'Entitlement Name',
    FILTER_BY_COMPONENT: 'Filter by Component',
    FILTER_BY_ENTITLEMENT: 'Filter by Entitlement',
    FILTER_BY_SOURCE: 'Filter by Source',
    FILTER_BY_STATUS: 'Filter by Status',
    FILTER_BY_APPROVAL_REQUIRED: 'Filter by Approval Required',
    SEARCH_DATA: 'Search Data',
    FILTER_BY_DATE: 'Filter by Date',
    FILTER_BY_USER_STATUS: 'Filter by User Status',
    FILTER_BY_USER_TYPE: 'Filter by User Type',
    VIEW_USER_DETAILS_PAGE: 'View User Details Page',
    DEACTIVATE_USER: 'Deactivate User',
    DELETE_USER: 'Delete User',
    ACTIVATE_USER: 'Activate User',
    VIEW_ACTIVITY_LOG: 'View Activity Log',
    UPLOAD_FILE: 'Upload File',
    DOWNLOAD_FILE: 'Download File',
    VIEW_MANAGE_ROLE_PAGE: 'View Manage Role Page',
    VIEW_ROLE_DETAILS_PAGE: 'View Role Details Page',
    DELETE_ROLE: 'Delete Role',
    DEACTIVATE_ROLE: 'Deactivate Role',
    ACTIVATE_ROLE: 'Activate Role',
    VIEW_MANAGE_ORGANIZATION_PAGE: 'View Manage Organization Page',
    VIEW_ORGANIZATION_DETAILS_PAGE: 'View Organization Details Page',
    ORGANIZATION_TYPE: 'Organization Type',
    ORGANIZATION_STATUS: 'Organization Status',
    BANK_TAXONOMY_ITEM: 'Bank Taxonomy Item',
    SERVICE_LINE_TAXONOMY_ITEM: 'Service Line Taxonomy Item',
    ORGANIZATION_SALESFORCE_ID: 'OrganizationSalesForceID',
    DELETE_ORGANIZATION: 'Delete Organization',
    VIEW_MANAGE_ENTITLEMENT: 'View Manage Entitlement',
    OWNERSHIP_COMPONENT: 'Ownership Component',
    OWNERSHIP_ENTITLEMENT: 'Ownership Entitlement',
    DESCRIPTION: 'Description',
    VIEW_APPROVALS_PAGE: 'View Approvals Page',
    FILTER_BY_COMPONENT_NAME: 'Filter by Component Name',
    FILTER_BY_ENTITLEMENT_NAME: 'Filter by Entitlement Name',
    FILTER_BY_MY_OWNERSHIPS: 'Filter by My Ownerships',
    NO_APPROVER_REQUIRED: 'No Approver Required',
    VIEW_MANAGE_APPROVALS_PAGE: 'View Manage Approvals Page',
    VIEW_ENTITLEMENT_APPROVALS_PAGE: 'View Entitlement Approvals Page',
    APPROVE_ALL: 'Approve All',
    REJECT_ALL: 'Reject All',
    APPROVE: 'Approve',
    REJECT: 'Reject',
    REVOKE: 'Revoke',
    VIEW_OWNERSHIP_APPROVALS_PAGE: 'View Ownership Approvals Page',
    ACCEPT_REQUEST: 'Accept Request',
    CANCEL_REQUEST: 'Cancel Request',
    REJECT_REQUEST: 'Reject Request',
    VIEW_MANAGE_COMPONENTS: 'View Manage Components',
    VIEW_REGISTER_COMPONENTS: 'View Register Components',
    VIEW_CREATE_USER_PAGE: 'View Create User Page',
    VIEW_CREATE_ROLE_PAGE: 'View Create Role Page',
    VIEW_CREATE_ENTITLEMENT_PAGE: 'View Create Entitlement Page',
    VIEW_CREATE_COMPONENT_PAGE: 'View Create Component Page',  
    UNLOCK_USER: 'Unlock User',
    DELETE_USER_PERMANENTLY: 'Delete User Permanently',
    ADD_USER: 'Add User',
    ADD_ENTITLEMENT: 'Add Entitlement',
    REMOVE_ENTITLEMENT: 'Remove Entitlement',
    ADD_ROLE: 'Add Role',
    FILE_NAME: 'File Name',
    UPLOAD_USER_ORGANIZATION_FILE: 'Upload User/Organization File',
    REMOVE_LEVEL:'Remove Level',
    ADD_LEVEL:'Add Level',
    EDIT_LEVEL:'Edit Level',
    REMOVE_ENTITLEMENT_ACCESS:'Remove Entitlement Access',
    ADD_ENTITLEMENT_ACCESS: 'Add Entitlement Access',
    MAP_USER:'Map User',
    UNMAP_USER:'UnMap User',
    MAP_UNMAP_USER: 'Map/UnMap User',
    DEACTIVATE_ACCOUNT:'Deactivate Account',
    ACTIVATE_ACCOUNT:'Deactivate Account',
    MAP_UNMAP_ENDPOINT: 'Map/UnMap EndPoints',
    MAP_UNMAP_ACCOUNT: 'Map/UnMap Account',
    EDIT_DESCRIPTION: 'Edit Description',
    EDIT_DEFAULT_ROLE: 'Edit Default Role',
    EDIT_RESOURCENAME: 'Edit ResourceName',
    EDIT_COMPONENT: 'Edit Component',
    EDIT_ENDPOINT_URL: 'Edit Endpoint Url',
    EDIT_METHOD: 'Edit Method',
    EDIT_APPLICATION_ROLE_REQUIRED:'Edit ApplicationRoleRequired',
    TENANT_ID:'Tenant Id'

};

export const EventName = {
    LOGIN: 'Login',
    MANAGE_USERS: 'Manage Users',
    CREATE_USER: 'Create User',
    EDIT_USER: 'Edit User',
    SEARCH_USER: 'Search User',
    FILTER_MANAGE_USER: 'Filter - Manage User',
    DEACTIVATE_USER: 'Deactivate User',
    DELETE_USER: 'Delete User',
    DELETE_USER_PERMANENTLY: 'Delete Permanently',
    ACTIVATE_USER: 'Activate User',
    ACTIVITY_LOG_MANAGE_USERS: 'Activity Log - Manage Users',
    TAXONOMY_UPLOAD: 'Taxonomy Upload',
    TAXONOMY_DOWNLOAD: 'Taxonomy Download',
    USER_ORGANIZATION_UPLOAD: 'User/Organization Upload',
    ENTITLEMENT_UPLOAD_MAKER: 'Entitlement Upload - Maker',
    ENTITLEMENT_UPLOAD_CHECKER: 'Entitlement Upload - Checker',
    ENTITLEMENT_DOWNLOAD: 'Entitlement Download',
    REGISTER_ENTITLEMENT_UPLOAD: 'Register Entitlement Upload',
    REGISTER_ENTITLEMENT_DOWNLOAD: 'Register Entitlement Download',
    CHANGE_USER: 'Change User',
    MANAGE_ROLE: 'Manage Role',
    CREATE_NEW_ROLE: 'Create New Role',
    EDIT_ROLE: 'Edit Role',
    SEARCH_ROLE: 'Search Role',
    DEACTIVATE_ROLE: 'Deactivate Role',
    DELETE_ROLE: 'Delete Role',
    ACTIVATE_ROLE: 'Activate Role',
    CHANGE_ROLE: 'Change Role',
    MANAGE_ORGANIZATION: 'Manage Organization',
    NEW_ORGANIZATION: 'New Organization',
    CREATE_NEW_ORGANIZATION: 'Create New Organization',
    ORGANIZATION_ID_LINK: 'Organization ID Link',
    SEARCH_ORGANIZATION: 'Search Organization',
    DELETE_ORGANIZATION: 'Delete Organization',
    EDIT_ORGANIZATION: 'Edit Organization',
    MANAGE_ENTITLEMENT: 'Manage Entitlement',
    REGISTER_ENTITLEMENT: 'Register Entitlement',
    VIEW_APPROVERS: 'View Approvers',
    EDIT_APPROVER: 'Edit Approver',
    SEARCH_ENTITLEMENT: 'Search Entitlement',
    DELETE_ENTITLEMENT: 'Delete Entitlement',
    DEACTIVATE_ENTITLEMENT: 'Deactivate Entitlement',
    FILTER_MANAGE_ENTITLEMENT: 'Filter - Manage Entitlement',
    EDIT_ENTITLEMENT: 'Edit Entitlement',
    MANAGE_APPROVALS: 'Manage Approvals',
    ENTITLEMENT_APPROVALS: 'Entitlement Approvals',
    OWNERSHIP_APPROVALS: 'Ownership Approvals',
    SEARCH_ENTITLEMENT_APPROVALS: 'Search Entitlement Approvals',
    EXPAND_USER_FOR_APPROVAL: 'Expand User for Approval',
    MANAGE_COMPONENTS: 'Manage Components',
    REGISTER_COMPONENTS: 'Register Components',
    UNLOCK_USER: 'Unlock User',
    PAGINATION_MANAGE_USERS: 'Pagination - Manage Users',
    PAGINATION_MANAGE_ROLES: 'Pagination - Manage Roles',
    CREATION_PERIOD_MANAGE_USER: 'Creation Period - Manage Users',
    ACTIVITY_LOG_MANAGE_ENTITLEMENT: 'Activity Log - Manage Entitlement',
    CREATION_PERIOD_MANAGE_ENTTITLEMENT: 'Creation Period - Manage Entitlement',
    PAGINATION_MANAGE_ENTITLEMENT: 'Pagination - Manage Entitlement',
    CHANGE_ENTITLEMENT :'Change Entitlement',
    PAGINATION_MANAGE_ORGANIZATION: 'Pagination - Manage Organization',
    ACCEPT_OWNERSHIP_REQUEST: 'Accept Ownership Request',
    REJECT_OWNERSHIP_REQUEST: 'Reject Ownership Request',
    CANCEL_OWNERSHIP_REQUEST: 'Cancel Ownership Request',
    APPROVE_ENTITLEMENT_ACCESS:'Approve Entitlement Access',
    PAGINATION_MANAGE_ARROVALS: 'Pagination - Manage Approvals',
    REJECT_ENTITLEMENT_ACCESS:'Reject Entitlement Access',
    APPROVE_ALL: 'Approve All',
    EDIT_ENTITLEMENT_DETAILS: 'Edit Entitlement Details page',
    SEARCH_OWNERSHIP_APPROVALS: 'Search Ownership Approvals',
    REJECT_ALL: 'Reject All',
    REVOKE_ENTITLEMENT_ACCESS:'Revoke Access',
    DEACTIVATE_MULTIPLE_USER: 'Deactivate Multiple Users',
    REACTIVATE_MULTIPLE_USER: 'Reactivate Multiple Users',
    MAP_UNMAP_USER: 'Map/UnMap User',
    DEACTIVATE_ACCOUNT:'Deactivate Account',
    ACTIVATE_ACCOUNT:'Deactivate Account',
    MAP_UNMAP_ENDPOINT: 'Map/UnMap EndPoints',
    MAP_UNMAP_ACCOUNT: 'Map/UnMap Account',
    EDIT_APPLICATION_ROLE: 'Edit Application Role',
    EDIT_RESOURCE_ENDPOINT: 'Edit Resource Endpoint',
    SUSPEND_USER: 'Suspend User',
    FORCE_LOG_OFF_USER:'Force Log Off User',
}

export const ContextName = {
    USER_ID: 'User ID',
    SESSION_ID: 'Session ID',
    SESSION_START_TIME: 'Session Start Time',
    SESSION_END_TIME: 'Session End Time',
    IP_ADDRESS: 'IP Address',
    BROWSER: 'Browser',
    USER_NAME: 'User Name',
    USER_TYPE: 'User Type',
    EMAIL_ID: 'Email ID',
    ORGANIZATION: 'Organization',
    ORGANIZATION_ID: 'Organization ID',
    NAME: 'Name',
    STATUS: 'Status',
    ROLE_ID: 'Role ID',
    ROLE_NAME: 'Role Name',
    ENTITLEMENT_ID: 'Entitlement ID',
    COMPONENT_NAME: 'Component Name',
    ENTITLEMENT_NAME: 'Entitlement Name',
    VIEW_MANAGE_USERS_PAGE: 'View Manage Users Page',
    VIEW_USER_DETAILS_PAGE: 'View User Details Page',
    DEACTIVATE_USER: 'Deactivate User',
    DELETE_USER: 'Delete User',
    ACTIVATE_USER: 'Activate User',
    VIEW_ACTIVITY_LOG: 'View Activity Log',
    UPLOAD_FILE: 'Upload File',
    VIEW_MANAGE_ROLE_PAGE: 'View Manage Role Page',
    VIEW_ROLE_DETAILS_PAGE: 'View Role Details Page',
    DELETE_ROLE: 'Delete Role',
    DEACTIVATE_ROLE: 'Deactivate Role',
    ACTIVATE_ROLE: 'Activate Role',
    VIEW_MANAGE_ORGANIZATION_PAGE: 'View Manage Organization Page',
    VIEW_ORGANIZATION_DETAILS_PAGE: 'View Organization Details Page',
    DELETE_ORGANIZATION: 'Delete Organization',
    VIEW_MANAGE_ENTITLEMENT: 'View Manage Entitlement Page',
    VIEW_ENTITLEMENT_DETAILS_PAGE: 'View Entitlement Details Page',
    VIEW_APPROVALS_PAGE: 'View Approvals Page',
    VIEW_MANAGE_APPROVALS_PAGE: 'View Manage Approvals Page',
    VIEW_ENTITLEMENT_APPROVALS_PAGE: 'View Entitlement Approvals Page',
    VIEW_OWNERSHIP_APPROVALS_PAGE: 'View Ownership Approvals Page',
    VIEW_MANAGE_COMPONENTS: 'View Manage Components',
    VIEW_REGISTER_COMPONENTS: 'View Register Components',
    VIEW_CREATE_USER_PAGE: 'View Create User Page',
    VIEW_CREATE_ROLE_PAGE: 'View Create Role Page',
    VIEW_CREATE_ENTITLEMENT_PAGE: 'View Create Entitlement Page',
    VIEW_CREATE_COMPONENT_PAGE: 'View Create Component Page',
    UPLOAD_USERS_ORG_FILE:'Upload User/Organization File',
    DOWNLOAD_REGISTERED_ENTITLEMENTS_FILE: 'Download Register Entitlements File',
    DOWNLOAD_ENTITLEMENTS_FILE: 'Download Entitlements File',
    UNLOCK_USER: 'Unlock User',
    DELETE_USER_PERMANENTLY: 'Delete Permanently',
    UPLOAD_ENTITLEMENTS_FILE:'Upload Entitlements File',
    UPLOAD_REGISTER_ENTITLEMENTS_FILE: 'Upload Register Entitlements File',
    EDIT_USER : 'Edit User',
    UPLOAD_TAXONOMY_FILE: 'Upload Taxonomy File',
    DOWNLOAD_TAXONOMY_FILE: 'Download Taxonomy File',
    EDIT_ROLE: 'Edit Role',
    SEARCH_DATA: 'Search Data',
    PAGE_SIZE: 'Page Size',
    PAGE_NUMBER: 'Page Number',
    EXPAND_USER_FOR_APPROVAL: 'Expand',
    FILTER_BY_USER_STATUS: 'Filter by User Status',
    FILTER_BY_USER_TYPE: 'Filter by User Type',
    CREATION_PERIOD_CUSTOM_DATE:'Creation Period - Custom Date',
    CREATION_PERIOD_PAST_WEEK: 'Creation Period - Past Week',
    CREATION_PERIOD_PAST_MONTH: 'Creation Period - Past Month',
    CREATION_PERIOD_PAST_3_MONTHS: 'Creation Period - Past 3 Months',
    CREATION_PERIOD_PAST_6_MONTHS: 'Creation Period - Past 6 Months',
    CREATION_PERIOD_PAST_YEAR: 'Creation Period - Past Year',
    CREATION_PERIOD_CLEAR_ALL: 'Creation Period - Clear All',
    EDIT_APPROVERS:'Edit Approvers',
    VIEW_EDIT_ENTITLEMENT_PAGE: 'View Edit Entitlement Page',
    ACCEPT_REQUEST: 'Accept Request',
    REJECT_REQUEST: 'Reject Request',
    CANCEL_REQUEST: 'Cancel Request',
    APPROVE:'Approve',
    FILTER_BY_ENTITLEMENT_NAME : 'Filter by Entitlement Name',
    REJECT:'Reject',
    APPROVE_ALL: 'Approve All',
    EDIT_ENTITLEMENT_DETAILS_PAGE: 'Edit Entitlement Details',
    REJECT_ALL: 'Reject All',
    FILTER_BY_COMPONENT_NAME : 'Filter by Component Name',
    FILTER_BY_MY_OWNERSHIPS :'Filter by My Ownerships',
    FILTER_BY_ENTITLEMENT_STATUS : 'Filter by Entitlement Status',
    REVOKE:'Revoke Access',
    CLEAR_ALL_FILTER: 'Clear All Filter',
    MAP_UNMAP_USER: 'Map/UnMap User',
    DEACTIVATE_ACCOUNT:'Deactivate Account',
    ACTIVATE_ACCOUNT:'Deactivate Account',
    MAP_UNMAP_ENDPOINT: 'Map/UnMap EndPoints',
    MAP_UNMAP_ACCOUNT: 'Map/UnMap Account',
    EDIT_APPLICATION_ROLE: 'Edit Application Role',
    EDIT_RESOURCE_ENDPOINT: 'Edit Resource Endpoint',
    SUSPEND_USER: 'Suspend User',
    FORCE_LOG_OFF_USER:'Force Log Off User',

}

export const attributeName={
    "ADServer": ItemName.AD_SERVER,
    "Multifactor Authentication": ItemName.MULTI_FACTOR_AUTHENTICATION,
    "OrganizationStatus": ItemName.ORGANIZATION_STATUS,
    "Organization Type": ItemName.ORGANIZATION_TYPE,
    "OrganizationSalesforceID": ItemName.ORGANIZATION_SALESFORCE_ID,
    "Service Line Taxonomy Item":ItemName.SERVICE_LINE_TAXONOMY_ITEM,
    "Bank Taxonomy Item":ItemName.BANK_TAXONOMY_ITEM,
    "IPRange":ItemName.IP_RANGE,
    "Phone Number":ItemName.PHONE_NUMBER,
    "ADUserName":ItemName.AD_USER_NAME,
    "UserSalesForceID":ItemName.USER_SALESFORCE_ID,
    "Firm ID":ItemName.Firm_ID
}

export const Operations = {
    ADD : "Add",
    EDIT : "Edit",
    DELETE : "Delete"
}