<div>
    <div class="p-d-flex p-jc-end">
        <p-messages key="myKey1" class="custom-alert-box" *ngIf="this.messageFlag"></p-messages>
        <p-messages key="myKey2" class="custom-alert-box" *ngIf="this.messageFlag1"></p-messages>
    </div>
    <p-breadcrumb id="columns_2_1_columns_2_0_ccprimebreadcrumb_1_2_id"
        name="columns_2_1_columns_2_0_ccprimebreadcrumb_1_2_name" [model]="ccprimebreadcrumbList"
        [home]="homeiconccprimebreadcrumb">
    </p-breadcrumb>
    <div class="layout-content">
        <div id="columns_2_id" name="columns_2_name" class="p-grid   " [ngStyle]="{'height': ''}">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 undefined none p-pt-0 p-pb-0"
                [ngStyle]="{'width': ''}">
                <h6 id="columns_2_1_ccprimeheading_1_id" name="columns_2_1_ccprimeheading_1_name">
                    Edit Plan
                </h6>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 undefined" [ngStyle]="{'width': ''}">
                <div class="p-cardpadding-xs">
                    <div class="d-flex flex-wrap" style=" padding:4px; height:'auto'">
                        <p-card id="columns_2_2_card_2_id" name="columns_2_2_card_2_name" class="p-pb-5"
                            styleClass=" p-cardpadding-xs">
                            <div class="p-p-2 ">
                                <button pButton type="button" (click)="activeIndex1 = 0"
                                    class="p-button-outlined p-button-lg tabButton group-identity" label="Plan Details"
                                    [style.box-shadow]="activeIndex1 == 0 ? '1px 5px 20px #bdc0c9' :  '0px 1px 4px #bdc0c9'"
                                    [style.border]="activeIndex1 == 0 ? '1px solid  #396FC5' :  '#add8e6'"
                                    [style.color]=" activeIndex1 == 0 ? '#396FC5' : '#6C7387'">
                                    <div class="fa-2x">
                                        <span class="fa-layers fa-fw">
                                            <label class="c-icons plan-details"></label>
                                        </span>
                                    </div>
                                    <span class="p-button-icon p-button-icon-left reshot-icon-business-team"
                                        aria-hidden="true"></span>
                                    <label class="d-icons check-icon group-identity-label" width="14" height="14"
                                        *ngIf="tab1"></label>
                                </button>
                                <button pButton type="button" (click)="activeIndex1 = 1" label="Plan Atrributes"
                                    id="panelbtn2" class="p-button-outlined p-button-lg tabButton composite-role"
                                    [style.box-shadow]="activeIndex1 == 1 ? '1px 5px 20px #bdc0c9' :  '0px 1px 4px #bdc0c9'"
                                    [style.border]="activeIndex1 == 1 ? '1px solid  #396FC5' :  '#add8e6'"
                                    [style.color]=" activeIndex1 == 1 ? '#396FC5' : '#6C7387'">
                                    <div class="fa-2x">
                                        <span class="fa-layers fa-fw">
                                            <label class="c-icons plan-attributes-icon"></label>
                                        </span>
                                    </div>
                                    <span class="p-button-icon p-button-icon-left reshot-icon-business-team"
                                        aria-hidden="true"></span>
                                    <label class="d-icons check-icon composite-role-label" width="14" height="14"
                                        *ngIf="tab2"></label>
                                </button>
                            </div>


                            <div class="tabview-custom">
                                <p-tabView [(activeIndex)]="activeIndex1" styleClass="tabview-custom">
                                    <p-tabPanel header="Plan Details">
                                        <form [formGroup]="PlanDetailsForm" autocomplete="off" novalidate>
                                            <div class="">
                                                <div id="columns_2_1_0_tabs_2_0_columns_2_1_0_columns_2_0_columns_2_0_columns_1_1_2_1_1_id"
                                                    name="columns_2_1_0_tabs_2_0_columns_2_1_0_columns_2_0_columns_2_0_columns_1_1_2_1_1_name"
                                                    class="p-grid   " [ngStyle]="{'height': ''}">
                                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 undefined"
                                                        [ngStyle]="{'width': ''}">
                                                        <div class="p-fluid">
                                                            <span class="d-block label-top"> <label
                                                                    [ngClass]="{'field-required': PlanDetailsForm.get('planName')?.errors?.required}">
                                                                    Plan Name
                                                                    <span class='invalid-feedback-astrik'>*</span>
                                                                </label>
                                                            </span>
                                                            <input id="planName_id" type="text" name="planName_name"
                                                                formControlName="planName" pInputText
                                                                pattern="^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$" maxlength="64"
                                                                placeholder="Plan name"
                                                                (keyup)="defaultFlag=false;this.nameFlag=false"
                                                                id="planName" disabled>
                                                            <div
                                                                *ngIf="this.defaultFlag && this.PlanDetailsForm.value.planName==null">
                                                                <label class="alert-icon alert-circle-icon1"></label>
                                                                <span
                                                                    style="font-size: 12px;font-weight: 400;color: #505870;vertical-align: middle;">
                                                                    Plan name should not start/end with special
                                                                    character.</span>
                                                            </div>
                                                        </div>
                                                        <div class="p-error"
                                                            *ngIf="PlanDetailsForm.get('planName')!.invalid && (PlanDetailsForm.get('planName')!.dirty || PlanDetailsForm.get('planName')!.touched)">
                                                            <div
                                                                *ngIf="this.defaultFlag==false && PlanDetailsForm.get('planName')?.errors?.required">
                                                                <label class="alert-icon alert-circle-icon"></label>
                                                                Plan name is required.
                                                            </div>
                                                            <div
                                                                *ngIf="PlanDetailsForm.get('planName')?.errors?.pattern">
                                                                <label class="alert-icon alert-circle-icon"></label>
                                                                Plan name should not start/end with special
                                                                character.
                                                            </div>

                                                        </div>
                                                        <div class="p-error" *ngIf="this.nameFlag">
                                                            <label class="alert-icon alert-circle-icon"></label>
                                                            <span id="rolename"
                                                                style="display:inline-block;vertical-align: bottom;"
                                                                class="p-error">Plan
                                                                name already exists!
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 undefined"
                                                        [ngStyle]="{'width': ''}">
                                                        <div class="p-fluid  ">
                                                            <span class="d-block label-top"> <label>
                                                                    Plan Description
                                                                </label>
                                                            </span>
                                                            <textarea pInputTextarea id="planDescription_id"
                                                                name="planDescription_name"
                                                                formControlName="planDescription" rows="3"
                                                                placeholder="Plan Description" maxlength="250"
                                                                (keyup)="enablePlanButton = false">
                                                                </textarea>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-0 undefined"
                                                        [ngStyle]="{'width': ''}">
                                                        <div class="p-fluid ">
                                                            <span class="d-block label-top"> <label
                                                                    [ngClass]="{'field-required': PlanDetailsForm.get('application')?.errors?.required}">
                                                                    Application
                                                                    <span class='invalid-feedback-astrik'>*</span>
                                                                </label>
                                                            </span>
                                                            <p-dropdown id="application_id" #dd name="application_name"
                                                                placeholder="Select" [options]="selectList"
                                                                [autoDisplayFirst]="false" formControlName="application"
                                                                (onChange)="onSelect();getApplicationRoleList(this.PlanDetailsForm.value.application.applicationId);getApplicationAttributeGroupList(this.PlanDetailsForm.value.application.applicationId);checkStepValidations();"
                                                                optionLabel="applicationName" [filter]="true">
                                                            </p-dropdown>
                                                        </div>
                                                        <div class="p-error"
                                                            *ngIf="PlanDetailsForm.get('application')!.invalid && (PlanDetailsForm.get('application')!.dirty || PlanDetailsForm.get('application')!.touched)">
                                                            <div
                                                                *ngIf="PlanDetailsForm.get('application')?.errors?.required">
                                                                <label class="alert-icon alert-circle-icon"></label>
                                                                Application is required.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-0  "
                                                        [ngStyle]="{'width': ''}">
                                                        <div class="p-fluid  ">
                                                            <span class="d-block label-top"> <label>
                                                                    Application roles
                                                                </label>
                                                            </span>
                                                            <p-multiSelect filterPlaceHolder="select"
                                                                placeholder="Select" #applicationRoleLabelValue
                                                                [options]="applicationRoleList"
                                                                formControlName="applicationRoles"
                                                                optionLabel="applicationRoleName" display="chip"
                                                                (onChange)="onSelect();this.duplicityFlagApplicationRole=false; ;"
                                                                class="custom-chips">
                                                                <ng-template let-each pTemplate="selectedItem">
                                                                    {{each.applicationRoleName}}
                                                                </ng-template>
                                                            </p-multiSelect>
                                                        </div>
                                                        <div class="p-error"
                                                            *ngIf="this.duplicityFlagApplicationRole==true">
                                                            <label class="alert-icon alert-circle-icon"></label>
                                                            Application roles already added.
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-6 p-lg-4 p-xl-4 p-offset-0 undefined"
                                                        [ngStyle]="{'width': ''}">
                                                        <div class="p-fluid">
                                                            <span class="d-block label-top"> <label>
                                                                    Select
                                                                </label>
                                                            </span>
                                                            <div class="p-fieldp-fluid">
                                                                <p-selectButton id="selectSwitch_id"
                                                                    name="selectSwitch_name"
                                                                    [(ngModel)]="defaultselect_selectSwitch"
                                                                    [options]="selectSwitchList"
                                                                    formControlName="selectSwitch" styleClass="default"
                                                                    (onChange)="selectAttributeTypeGroup()">
                                                                    <ng-template let-item>
                                                                        <span
                                                                            class="p-button-label">{{item.label}}</span>
                                                                    </ng-template>
                                                                </p-selectButton>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 p-offset-0 undefined"
                                                        [ngStyle]="{'width': ''}">
                                                        <div class=" p-fluid  "
                                                            *ngIf="conditionalLogicCheck(PlanDetailsForm.value.selectSwitch,'attributeGroup')">
                                                            <span class="d-block label-top"> <label>
                                                                    Attribute group
                                                                </label>
                                                            </span>
                                                            <p-multiSelect filterPlaceHolder="select"
                                                                placeholder="Select" [options]="attributeGroupList"
                                                                formControlName="attributeGroup"
                                                                optionLabel="attributeGroupName" display="chip"
                                                                class="custom-chips"
                                                                (onChange)="onSelect();this.duplicityFlagAttributeGroup=false">
                                                                <ng-template let-each pTemplate="selectedItem">
                                                                    {{each.attributeGroupName}}
                                                                </ng-template>
                                                            </p-multiSelect>
                                                        </div>
                                                        <div class="p-error"
                                                            *ngIf="this.duplicityFlagAttributeGroup==true">
                                                            <label class="alert-icon alert-circle-icon"></label>
                                                            Attribute group is already added.
                                                        </div>
                                                        <div class="p-fluid  "
                                                            *ngIf="conditionalLogicCheck(PlanDetailsForm.value.selectSwitch,'attributeType')">
                                                            <span class="d-block label-top"> <label>
                                                                    Attribute type
                                                                </label>
                                                            </span>
                                                            <p-dropdown [(ngModel)]="attributeTypeValue"
                                                                id="attributeType_id" name="attributeType_name"
                                                                placeholder="Select" [options]="attributeTypeList"
                                                                [autoDisplayFirst]="false"
                                                                formControlName="attributeType"
                                                                optionLabel="attributeTypeName"
                                                                (onChange)="onSelect();getApplicationAttributeList(this.PlanDetailsForm.value.application.applicationId, this.PlanDetailsForm.value.attributeType.attributeTypeId);checkStepValidations()"
                                                                [filter]="true">
                                                            </p-dropdown>
                                                        </div>

                                                    </div>
                                                    <div *ngIf="conditionalLogicCheck(PlanDetailsForm.value.selectSwitch,'attributeType')"
                                                        class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-0 undefined"
                                                        [ngStyle]="{'width': ''}">
                                                        <div class="p-fluid  "
                                                            *ngIf="conditionalLogicCheck(PlanDetailsForm.value.selectSwitch,'attributeType')">
                                                            <span class="d-block label-top"> <label>
                                                                    Attribute
                                                                </label>
                                                            </span>
                                                            <p-multiSelect filterPlaceHolder="select"
                                                                placeholder="Select" name="attribute_name"
                                                                [options]="attributeList" formControlName="attribute"
                                                                optionLabel="attributeName" display="chip"
                                                                class="custom-chips"
                                                                (onChange)="onSelect();this.duplicityFlagAttribute=false;">
                                                            </p-multiSelect>

                                                        </div>
                                                        <div class="p-error" *ngIf="this.duplicityFlagAttribute==true">
                                                            <label class="alert-icon alert-circle-icon"></label>
                                                            Attribute is already added.
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-0 undefined"
                                                        [ngStyle]="{'width': ''}">
                                                        <div class="p-fluid  ">
                                                            <span class="d-block label-top"> <label>
                                                                    Feature Name
                                                                </label>
                                                            </span>
                                                            <input id="featureName_id" type="text" name="planName_name"
                                                                formControlName="featureName" pInputText maxlength="64"
                                                                placeholder="Enter feature name" id="featureName"
                                                                (keyup)="this.duplicityFlagfeatureName=false;onSelect();">
                                                        </div>
                                                        <div class="p-error"
                                                            *ngIf="this.duplicityFlagfeatureName==true">
                                                            <label class="alert-icon alert-circle-icon"></label>
                                                            Feature name is already added.
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 undefined"
                                                        [ngStyle]="{'width': ''}">
                                                        <div class="p-fluid ">
                                                            <span class="d-block label-top"> <label>
                                                                    Feature Description
                                                                </label>
                                                            </span>
                                                            <textarea pInputTextarea id="featureDescription_id"
                                                                name="featureDescription_name" (keyup)="onSelect();"
                                                                formControlName="featureDescription" rows="3"
                                                                placeholder="Feature Description">
                                                              </textarea>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 p-d-flex p-jc-end"
                                                        [ngStyle]="{'width': ''}">
                                                        <div class="btnwrap"> <button pButton id="addButton_id"
                                                                name="addButton_name" class="p-button-secondary"
                                                                type="button" icon="icon-plus" iconPos="left"
                                                                (click)="onAddButton(PlanDetailsForm);" label="Add"
                                                                [disabled]="!this.PlanDetailsForm.valid|| this.editDisable">
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 undefined"
                                                        [ngStyle]="{'width': ''}">
                                                        <div class="p-fluid ">
                                                            <p-table #tableid [value]="planList" name="table"
                                                                styleClass="p-datatable-customers custom-p-datatable-customers"
                                                                [rows]="10" [rowsPerPageOptions]="[10,25,50]"
                                                                selectionMode="Multiple" [paginator]="true"
                                                                sortMode="single" [scrollable]="true"
                                                                (onFilter)="customFilter($event)"
                                                                [globalFilterFields]="['application?.applicationName','applicationRoles','attributeType?.attributeTypeName','attribute','attributeGroup','featureDescription','featureName']">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="width: 110%;">
                                                                        <th pSortableColumn="application?.applicationName"
                                                                            scope="col">
                                                                            <div
                                                                                class="p-d-flex p-jc-between p-ai-center">
                                                                                Application
                                                                                <i class="p-sortable-column-icon icon-sort"
                                                                                    ariaLabel="Activate to sort"
                                                                                    ariaLabelDesc="Activate to sort in descending order"
                                                                                    ariaLabelAsc="Activate to sort in ascending order"
                                                                                    field="application?.applicationName"></i>
                                                                                <p-columnFilter type="text"
                                                                                    field="application?.applicationName"
                                                                                    display="menu"
                                                                                    class="p-ml-auto"></p-columnFilter>
                                                                            </div>
                                                                        </th>
                                                                        <th pSortableColumn="applicationRoles"
                                                                            scope="col">
                                                                            <div
                                                                                class="p-d-flex p-jc-between p-ai-center">
                                                                                Application Roles
                                                                                <i class="p-sortable-column-icon icon-sort"
                                                                                    ariaLabel="Activate to sort"
                                                                                    ariaLabelDesc="Activate to sort in descending order"
                                                                                    ariaLabelAsc="Activate to sort in ascending order"
                                                                                    field="applicationRoles"></i>
                                                                                <p-columnFilter type="text"
                                                                                    field="applicationRoles"
                                                                                    display="menu"
                                                                                    class="p-ml-auto"></p-columnFilter>
                                                                            </div>
                                                                        </th>
                                                                        <th pSortableColumn="attributeType" scope="col">
                                                                            <div
                                                                                class="p-d-flex p-jc-between p-ai-center">
                                                                                Attribute type
                                                                                <i class="p-sortable-column-icon icon-sort"
                                                                                    ariaLabel="Activate to sort"
                                                                                    ariaLabelDesc="Activate to sort in descending order"
                                                                                    ariaLabelAsc="Activate to sort in ascending order"
                                                                                    field="attributeType?.attributeTypeName"></i>
                                                                                <p-columnFilter type="text"
                                                                                    field="attributeType?.attributeTypeName"
                                                                                    display="menu"
                                                                                    class="p-ml-auto"></p-columnFilter>
                                                                            </div>
                                                                        </th>
                                                                        <th pSortableColumn="attribute" scope="col">
                                                                            <div
                                                                                class="p-d-flex p-jc-between p-ai-center">
                                                                                Attribute
                                                                                <i class="p-sortable-column-icon icon-sort"
                                                                                    ariaLabel="Activate to sort"
                                                                                    ariaLabelDesc="Activate to sort in descending order"
                                                                                    ariaLabelAsc="Activate to sort in ascending order"
                                                                                    field="attribute"></i>
                                                                                <p-columnFilter type="text"
                                                                                    field="attribute" display="menu"
                                                                                    class="p-ml-auto"></p-columnFilter>
                                                                            </div>
                                                                        </th>
                                                                        <th pSortableColumn="attributeGroup"
                                                                            scope="col">
                                                                            <div
                                                                                class="p-d-flex p-jc-between p-ai-center">
                                                                                Attribute Group
                                                                                <i class="p-sortable-column-icon icon-sort"
                                                                                    ariaLabel="Activate to sort"
                                                                                    ariaLabelDesc="Activate to sort in descending order"
                                                                                    ariaLabelAsc="Activate to sort in ascending order"
                                                                                    field="attributeGroup"></i>
                                                                                <p-columnFilter type="text"
                                                                                    field="attributeGroup"
                                                                                    display="menu"
                                                                                    class="p-ml-auto"></p-columnFilter>
                                                                            </div>
                                                                        </th>
                                                                        <th pSortableColumn="featureName" scope="col">
                                                                            <div
                                                                                class="p-d-flex p-jc-between p-ai-center">
                                                                                Feature Name
                                                                                <i class="p-sortable-column-icon icon-sort"
                                                                                    ariaLabel="Activate to sort"
                                                                                    ariaLabelDesc="Activate to sort in descending order"
                                                                                    ariaLabelAsc="Activate to sort in ascending order"
                                                                                    field="featureName"></i>
                                                                                <p-columnFilter type="text"
                                                                                    field="featureName" display="menu"
                                                                                    class="p-ml-auto"></p-columnFilter>
                                                                            </div>
                                                                        </th>
                                                                        <th pSortableColumn="featureDescription"
                                                                            scope="col">
                                                                            <div
                                                                                class="p-d-flex p-jc-between p-ai-center">
                                                                                Feature Description
                                                                                <i class="p-sortable-column-icon icon-sort"
                                                                                    ariaLabel="Activate to sort"
                                                                                    ariaLabelDesc="Activate to sort in descending order"
                                                                                    ariaLabelAsc="Activate to sort in ascending order"
                                                                                    field="featureDescription"></i>
                                                                                <p-columnFilter type="text"
                                                                                    field="featureDescription"
                                                                                    display="menu"
                                                                                    class="p-ml-auto"></p-columnFilter>
                                                                            </div>
                                                                        </th>

                                                                        <th id="actionButton">
                                                                            Actions
                                                                        </th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-rowData
                                                                    let-index="rowIndex">
                                                                    <tr [pSelectableRow]="rowData" style="width: 110%;">
                                                                        <td class="custom-displayName">
                                                                            <div pTooltip="{{rowData?.application?.applicationName >= 15 ? rowData?.application?.applicationName : ''}}"
                                                                                tooltipPosition="bottom">
                                                                                <ng-container
                                                                                    *ngIf="rowData.displayName?.length > 15; else tooltip">
                                                                                    {{rowData?.application?.applicationName?.substring(0,30)
                                                                                    +
                                                                                    "..." }}
                                                                                </ng-container>
                                                                                <ng-template #tooltip>
                                                                                    {{rowData?.application?.applicationName}}
                                                                                </ng-template>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <span
                                                                                *ngFor="let role of rowData?.applicationRole; let last=last;">
                                                                                {{role?.applicationRoleName}}
                                                                                <span *ngIf="!last">{{', '}}</span>
                                                                            </span>
                                                                        </td>
                                                                        <td><span>
                                                                                {{rowData?.attributeTypeName}}
                                                                            </span>
                                                                            <span
                                                                                *ngIf="rowData?.attributeTypeName==null || rowData?.attributeTypeName==''">-</span>
                                                                        </td>
                                                                        <td>
                                                                            <span
                                                                                *ngFor="let attributes of rowData?.attribute; let last=last;">
                                                                                {{attributes?.attributeName}}
                                                                                <span *ngIf="!last">{{', '}}</span>
                                                                            </span>
                                                                            <span
                                                                                *ngIf="rowData?.attribute==null || rowData?.attribute==''">-</span>
                                                                        </td>
                                                                        <td>
                                                                            <span
                                                                                *ngFor="let attributeGroups of rowData?.attributeGroup; let last=last;">
                                                                                {{attributeGroups?.attributeGroupName}}
                                                                                <span *ngIf="!last">{{', '}}</span>
                                                                            </span>
                                                                            <span
                                                                                *ngIf="rowData?.attributeGroup==null || rowData?.attributeGroup==''">-</span>
                                                                        </td>

                                                                        <td class="custom-description"
                                                                            style="margin-left: 5%;">

                                                                            <span pTooltip="{{rowData.featureName}}"
                                                                                tooltipPosition="bottom"
                                                                                *ngIf="rowData.featureName?.length>=10">
                                                                                {{rowData.featureName?.substring(0,10)+"..."
                                                                                }}</span>
                                                                            <span
                                                                                *ngIf="rowData.featureName?.length<10">{{rowData.featureName}}</span>
                                                                        </td>
                                                                        <td class="custom-description">
                                                                            <span
                                                                                pTooltip="{{rowData.featureDescription}}"
                                                                                tooltipPosition="bottom"
                                                                                *ngIf="rowData.featureDescription?.length>=10">
                                                                                {{rowData.featureDescription?.substring(0,10)+"..."
                                                                                }}</span>
                                                                            <span
                                                                                *ngIf="rowData.featureDescription?.length<10">{{rowData.featureDescription}}</span>

                                                                        </td>
                                                                        <td pFrozenColumn [frozen]="true"
                                                                            alignFrozen="right"
                                                                            [ngClass]="{'frozen' : true}"
                                                                            style="padding:0px ;background: transparent;width: 110px;min-width: 110px;padding-top: 4px;">
                                                                            <div class="desktop p-pl-2"
                                                                                style="background: #F5F9FF;">
                                                                                <div class="button-details">
                                                                                    <span>
                                                                                        <button pButton
                                                                                            tooltipPosition="bottom"
                                                                                            style="width: 24px;height: 24px;padding: 0px;"
                                                                                            icon="g-icons edit-icon"
                                                                                            pTooltip="Edit"
                                                                                            class="background-transparent"
                                                                                            (click)="editPlan(rowData);">
                                                                                        </button>

                                                                                        <button pButton
                                                                                            tooltipPosition="bottom"
                                                                                            icon="g-icons trash-icon"
                                                                                            style="width: 24px;height: 24px;padding: 0px;padding-bottom: 2px;"
                                                                                            pTooltip="Delete"
                                                                                            class="background-transparent"
                                                                                            (click)="deletePlan(index);">
                                                                                        </button>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </td>

                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="paginatorright" let-state>
                                                                    Total
                                                                    Records: {{this.planList.length}} </ng-template>
                                                                <ng-template pTemplate="emptymessage">
                                                                    <tr>
                                                                        <td colspan="8" class="p-p-0 p-text-center">
                                                                            <p-card>
                                                                                <div
                                                                                    style="margin-top: 5%;height: 70px;">
                                                                                    No
                                                                                    matching records found</div>
                                                                            </p-card>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                        </div>
                                                    </div>

                                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 p-d-flex p-jc-end"
                                                        [ngStyle]="{'width': ''}">
                                                        <div class="btnwrap">
                                                            <button pButton id="saveButton_id" name="saveButton_name"
                                                                [disabled]="this.planList.length == 0 || enablePlanButton"
                                                                class="p-button p-button" type="button"
                                                                (click)="onSaveButton(PlanDetailsForm);" label="Save">
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </p-tabPanel>
                                    <p-tabPanel>
                                        <form [formGroup]="PlanAttributeForm" autocomplete="off" novalidate>
                                            <div class="">
                                                <div class="p-grid   " [ngStyle]="{'height': ''}">
                                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined undefined none"
                                                        [ngStyle]="{'width': ''}">
                                                        <div class="">
                                                            <div class="p-grid   " [ngStyle]="{'height': ''}">
                                                                <div class="p-col-12 p-sm-12 p-md-3 p-lg-3 p-xl-3 p-offset-undefined undefined none"
                                                                    [ngStyle]="{'width': ''}">
                                                                    <div class="">
                                                                        <p id="planAtrributes"
                                                                            name="planAtrributes_name">
                                                                            Plan Attributes
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div class="p-col-12 p-sm-12 p-md-2 p-lg-2 p-xl-2 p-offset-0 undefined none"
                                                                    [ngStyle]="{'width': ''}">
                                                                    <div class="">
                                                                        <p id="priceUnitValue"
                                                                            name="priceUnitValue_name">
                                                                            Value
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-0 undefined"
                                                                    [ngStyle]="{'width': ''}">
                                                                    <div class="">
                                                                        <p id="perUnitType" name="perUnitType_name">
                                                                            Per Unit Type
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div class="p-col-12 p-sm-12 p-md-3 p-lg-3 p-xl-3 p-offset-0 undefined"
                                                                    [ngStyle]="{'width': ''}">
                                                                </div>

                                                                <div *ngFor="let planAttributeList of planAttributeList;let index=index;let count=count"
                                                                    class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12"
                                                                    style="padding-left: 0px;">
                                                                    <div class="p-grid   " [ngStyle]="{'height': ''}">
                                                                        <div class="p-sm-12 p-md-3 p-lg-3 p-xl-3 p-offset-0 undefined"
                                                                            [ngStyle]="{'width': ''}">

                                                                            <label
                                                                                [ngClass]="{'field-required': PlanAttributeForm.get(planAttributeList.id+'_input')?.errors?.required}">{{planAttributeList.planAttributeName}}</label>
                                                                            <span
                                                                                class="invalid-feedback-astrik">*</span>

                                                                        </div>
                                                                        <div class="p-sm-12 p-md-2 p-lg-2 p-xl-2 p-offset-0 undefined"
                                                                            [ngStyle]="{'width': ''}"
                                                                            *ngIf="planAttributeList.fieldType=='number'">
                                                                            <div class="p-fluid">
                                                                                <input
                                                                                    id="{{planAttributeList.id}}_inputid"
                                                                                    type="text"
                                                                                    name="{{planAttributeList.id}}_inputname"
                                                                                    formControlName="{{planAttributeList.id}}_input"
                                                                                    pInputText placeholder="Enter Price"
                                                                                    [required]="true"
                                                                                    class="p-inputtext inputtextHeight"
                                                                                    (keyup)="enableBtn1 = false;checkOldNewValue()"
                                                                                    pattern="^[0-9]\d*$"
                                                                                    *ngIf="planAttributeList.planAttributeName=='Price per user in ($)'">
                                                                                <input
                                                                                    id="{{planAttributeList.id}}_inputid"
                                                                                    type="text"
                                                                                    name="{{planAttributeList.id}}_inputname"
                                                                                    formControlName="{{planAttributeList.id}}_input"
                                                                                    pInputText placeholder="Enter"
                                                                                    [required]="true"
                                                                                    class="p-inputtext inputtextHeight"
                                                                                    (keyup)="enableBtn1 = false;checkOldNewValue()"
                                                                                    pattern="^[1-9]\d*$"
                                                                                    *ngIf="planAttributeList.planAttributeName!='Price per user in ($)'">
                                                                                <div class="p-error"
                                                                                    *ngIf="PlanAttributeForm.get(planAttributeList.id+'_input')!.invalid && (PlanAttributeForm.get(planAttributeList.id+'_input')!.dirty || PlanAttributeForm.get(planAttributeList.id+'_input')!.touched) ">
                                                                                    <div
                                                                                        *ngIf="PlanAttributeForm.get(planAttributeList.id+'_input')?.errors?.required">
                                                                                        <label
                                                                                            class="alert-icon alert-circle-icon"></label>
                                                                                        <medium class="p-error">This
                                                                                            field is required
                                                                                        </medium>
                                                                                    </div>
                                                                                    <div
                                                                                        *ngIf="PlanAttributeForm.get(planAttributeList.id+'_input')?.errors?.pattern">
                                                                                        <label
                                                                                            class="alert-icon alert-circle-icon"></label>
                                                                                        <medium class="p-error">
                                                                                            Invalid field format
                                                                                        </medium>
                                                                                    </div>

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                        <div class="p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-0 undefined"
                                                                            [ngStyle]="{'width': ''}"
                                                                            *ngIf="planAttributeList.fieldType=='number'">
                                                                            <div class="p-fluid">
                                                                                <p-dropdown
                                                                                    id="{{planAttributeList.id}}_dropdownid"
                                                                                    name="{{planAttributeList.id}}_dropdownname"
                                                                                    placeholder="select"
                                                                                    [options]="planAttributeList.optionValueList"
                                                                                    [required]="true"
                                                                                    [autoDisplayFirst]="false"
                                                                                    (onChange)="this.enableBtn1=false;checkOldNewValue();"
                                                                                    formControlName="{{planAttributeList.id}}_dropdown">
                                                                                </p-dropdown>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-sm-12 p-md-3 p-lg-3 p-xl-3 p-offset-0 undefined"
                                                                            [ngStyle]="{'width': ''}"
                                                                            *ngIf="planAttributeList.fieldType=='number'">
                                                                        </div>
                                                                        <div class="p-col-12 p-sm-12 p-md-9 p-lg-9 p-xl-9 p-offset-0 undefined"
                                                                            [ngStyle]="{'width': ''}"
                                                                            *ngIf="planAttributeList.fieldType=='checkbox'">
                                                                            <div class="">
                                                                                <div class=" p-formgroup-inline">
                                                                                    <div class=" p-field-checkbox normal"
                                                                                        *ngFor="let item of planAttributeList.optionValueList">
                                                                                        <p-checkbox
                                                                                            id="{{planAttributeList.id}}_id"
                                                                                            [label]=" item.label "
                                                                                            [(ngModel)]="this[planAttributeList.id]"
                                                                                            [inputId]="item.value"
                                                                                            name="{{planAttributeList.id}}_name"
                                                                                            [value]="item.value"
                                                                                            icon="icon-checkbox"
                                                                                            (onChange)="this.enableBtn1=false;checkValidation(item.value);checkOldNewValue();"
                                                                                            formControlName="{{planAttributeList.id}}">
                                                                                        </p-checkbox>

                                                                                    </div>

                                                                                </div>
                                                                                <div class="p-error"
                                                                                    *ngIf="PlanAttributeForm.get(planAttributeList.id)!.invalid && (PlanAttributeForm.get(planAttributeList.id)!.dirty || PlanAttributeForm.get(planAttributeList.id)!.touched) ">
                                                                                    <div
                                                                                        *ngIf="PlanAttributeForm.get(planAttributeList.id)?.errors?.required">
                                                                                        <label
                                                                                            class="alert-icon alert-circle-icon"></label>
                                                                                        <medium class="p-error">This
                                                                                            field is required
                                                                                        </medium>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-col-12 p-sm-12 p-md-9 p-lg-9 p-xl-9 p-offset-0 undefined"
                                                                            [ngStyle]="{'width': ''}"
                                                                            *ngIf="planAttributeList.fieldType=='radiobutton'">
                                                                            <div class="">
                                                                                <div class=" p-formgroup-inline">
                                                                                    <div class=" field-radiobutton p-radiobutton-sm"
                                                                                        *ngFor="let item of planAttributeList.optionValueList"
                                                                                        style="margin-bottom: 0px !important;padding-bottom: 0px;">
                                                                                        <p-radioButton
                                                                                            id="{{planAttributeList.id}}_id"
                                                                                            [(ngModel)]="this[planAttributeList.id]"
                                                                                            [label]=" item.label "
                                                                                            [inputId]="item.value"
                                                                                            class="p-mr-2"
                                                                                            (onClick)="this.enableBtn1=false;checkOldNewValue();"
                                                                                            name="{{planAttributeList.id}}"
                                                                                            [value]="item.value"
                                                                                            formControlName="{{planAttributeList.id}}">
                                                                                        </p-radioButton>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-col-12 p-sm-12 p-md-9 p-lg-9 p-xl-9 p-offset-0 undefined"
                                                                            [ngStyle]="{'width': ''}"
                                                                            *ngIf="planAttributeList.fieldType=='boolean'">
                                                                            <div class="">
                                                                                <p-inputSwitch
                                                                                    [(ngModel)]="this[planAttributeList.id]"
                                                                                    (onChange)="this.enableBtn1=false;checkOldNewValue();"
                                                                                    formControlName="{{planAttributeList.id}}"
                                                                                    styleClass="p-inputswitch-md"
                                                                                    class="p-mr-2 custom-switch">
                                                                                </p-inputSwitch>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <!-- //// -->
                                                                <div *ngIf="this.saasEnable=='true'"
                                                                    class="p-col p-fluid p-formgrid p-grid p-pl-0 p-pr-0 p-pt-0">
                                                                    <div id="line_div"
                                                                        style=" border-bottom: 1px solid #E9EAED;"
                                                                        class="p-col p-m-2">
                                                                    </div>
                                                                    <div id='saas_heading_div'
                                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 left">
                                                                        <h6>Provisioning Details For SaaS Application
                                                                        </h6>
                                                                    </div>

                                                                    <!-- dropdown -->
                                                                    <div
                                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left">
                                                                        <div class="p-fluid p-field">
                                                                            <label
                                                                                [ngClass]="{'field-required': PlanAttributeForm.get('deploymentType')?.errors?.required}">
                                                                                Deployment Type
                                                                                <span
                                                                                    class='invalid-feedback-astrik'>*</span></label>

                                                                            <p-dropdown [(ngModel)]="deploymentType"
                                                                                id="deploymentType_id"
                                                                                name="deploymentType"
                                                                                placeholder="Select"
                                                                                [options]="deploymentTypeList"
                                                                                [filter]="true" filterBy="label"
                                                                                [autoDisplayFirst]="false"
                                                                                formControlName="deploymentType"
                                                                                (onChange)="enableBtn1=false">
                                                                                <ng-template let-item pTemplate="item">
                                                                                    <div
                                                                                        *ngIf="item.label.length <= 35;">
                                                                                        {{item.label}}
                                                                                    </div>
                                                                                    <div *ngIf="item.label.length > 35;"
                                                                                        pTooltip="{{item.label}}"
                                                                                        tooltipPosition="bottom">
                                                                                        {{item.label.substring(0,35) +
                                                                                        "..." }}
                                                                                    </div>
                                                                                </ng-template>
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>

                                                                    <!-- dropdown -->
                                                                    <!-- <div
                                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left">
                                                                        <div class="p-fluid p-field">
                                                                            <label
                                                                                [ngClass]="{'field-required': PlanAttributeForm.get('storageType')?.errors?.required}">
                                                                                Storage Type</label>

                                                                            <p-dropdown [(ngModel)]="storageType"
                                                                                id="storageType_id" name="storageType"
                                                                                placeholder="Select"
                                                                                [options]="storageTypeList"
                                                                                [filter]="true" filterBy="label"
                                                                                [autoDisplayFirst]="false"
                                                                                formControlName="storageType"
                                                                                (onChange)="enableBtn1=false">
                                                                                <ng-template let-item pTemplate="item">
                                                                                    <div
                                                                                        *ngIf="item.label.length <= 35;">
                                                                                        {{item.label}}
                                                                                    </div>
                                                                                    <div *ngIf="item.label.length > 35;"
                                                                                        pTooltip="{{item.label}}"
                                                                                        tooltipPosition="bottom">
                                                                                        {{item.label.substring(0,35) +
                                                                                        "..." }}
                                                                                    </div>
                                                                                </ng-template>
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>


                                                                    <div
                                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left">
                                                                        <div class="p-fluid p-field">
                                                                            <label
                                                                                [ngClass]="{'field-required': PlanAttributeForm.get('tenantType')?.errors?.required}">
                                                                                Tenant Type</label>

                                                                            <p-dropdown [(ngModel)]="tenantType"
                                                                                id="tenantType_id" name="tenantType"
                                                                                placeholder="Select"
                                                                                [options]="tenantTypeList"
                                                                                [filter]="true" filterBy="label"
                                                                                [autoDisplayFirst]="false"
                                                                                formControlName="tenantType"
                                                                                (onChange)="enableBtn1=false">
                                                                                <ng-template let-item pTemplate="item">
                                                                                    <div
                                                                                        *ngIf="item.label.length <= 35;">
                                                                                        {{item.label}}
                                                                                    </div>
                                                                                    <div *ngIf="item.label.length > 35;"
                                                                                        pTooltip="{{item.label}}"
                                                                                        tooltipPosition="bottom">
                                                                                        {{item.label.substring(0,35) +
                                                                                        "..." }}
                                                                                    </div>
                                                                                </ng-template>
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div> -->

                                                                    <!-- dropdown -->
                                                                    <div
                                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left">
                                                                        <div class="p-fluid p-field">
                                                                            <label
                                                                                [ngClass]="{'field-required': PlanAttributeForm.get('deploymentSubType')?.errors?.required}">
                                                                                Deployment Sub Type
                                                                                <span
                                                                                    class='invalid-feedback-astrik'>*</span></label>

                                                                            <p-dropdown [(ngModel)]="deploymentSubType"
                                                                                id="deploymentSubType_id"
                                                                                name="deploymentSubType"
                                                                                placeholder="Select"
                                                                                [options]="deploymentSubTypeList"
                                                                                [filter]="true" filterBy="label"
                                                                                [autoDisplayFirst]="false"
                                                                                formControlName="deploymentSubType"
                                                                                (onChange)="enableBtn1=false">
                                                                                <ng-template let-item pTemplate="item">
                                                                                    <div
                                                                                        *ngIf="item.label.length <= 35;">
                                                                                        {{item.label}}
                                                                                    </div>
                                                                                    <div *ngIf="item.label.length > 35;"
                                                                                        pTooltip="{{item.label}}"
                                                                                        tooltipPosition="bottom">
                                                                                        {{item.label.substring(0,35) +
                                                                                        "..." }}
                                                                                    </div>
                                                                                </ng-template>
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>

                                                                    <!-- textbox -->
                                                                    <div id='saas_heading_div'
                                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 left">
                                                                        <span style="font-size: 16px;">Account Details
                                                                        </span>
                                                                    </div>

                                                                    <div
                                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left ">
                                                                        <div class="p-fluid p-field">
                                                                            <label
                                                                                [ngClass]="{'field-required': PlanAttributeForm.get('accountNumber')?.errors?.required}">
                                                                                Account Number</label>
                                                                            <input [(ngModel)]="accountNumber"
                                                                                type="text" id="accountNumber_id"
                                                                                name="component_name"
                                                                                formControlName="accountNumber"
                                                                                pInputText
                                                                                placeholder="Enter Account Number"
                                                                                class="p-inputtext inputtextHeight"
                                                                                maxlength="50"
                                                                                (keyup)="enableBtn1=false" />
                                                                            <div class="p-error"
                                                                                *ngIf="PlanAttributeForm.get('accountNumber')!.invalid && (PlanAttributeForm.get('accountNumber')!.dirty || PlanAttributeForm.get('accountNumber')!.touched)">
                                                                                <div
                                                                                    *ngIf="PlanAttributeForm.get('accountNumber')?.errors?.required">
                                                                                    <label
                                                                                        class="alert-icon alert-circle-icon"></label>
                                                                                    <medium class="p-error">
                                                                                        Account Number is required.
                                                                                    </medium>
                                                                                </div>
                                                                                <div
                                                                                    *ngIf="PlanAttributeForm.get('accountNumber')?.errors?.pattern">
                                                                                    <label
                                                                                        class="alert-icon alert-circle-icon"></label>
                                                                                    Please enter valid account number.
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                    <!-- dropdown -->
                                                                    <div
                                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left">
                                                                        <div class="p-fluid p-field">
                                                                            <label
                                                                                [ngClass]="{'field-required': PlanAttributeForm.get('region')?.errors?.required}">
                                                                                Region</label>

                                                                            <p-dropdown [(ngModel)]="region"
                                                                                id="region_id" name="region"
                                                                                placeholder="Select"
                                                                                [options]="regionList" [filter]="true"
                                                                                filterBy="label"
                                                                                [autoDisplayFirst]="false"
                                                                                formControlName="region"
                                                                                (onChange)="enableBtn1=false">
                                                                                <ng-template let-item pTemplate="item">
                                                                                    <div
                                                                                        *ngIf="item.label.length <= 35;">
                                                                                        {{item.label}}
                                                                                    </div>
                                                                                    <div *ngIf="item.label.length > 35;"
                                                                                        pTooltip="{{item.label}}"
                                                                                        tooltipPosition="bottom">
                                                                                        {{item.label.substring(0,35) +
                                                                                        "..." }}
                                                                                    </div>
                                                                                </ng-template>
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>


                                                                    <!-- textbox -->
                                                                    <div
                                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left ">
                                                                        <div class="p-fluid p-field">
                                                                            <label
                                                                                [ngClass]="{'field-required': PlanAttributeForm.get('role')?.errors?.required}">
                                                                                Role</label>
                                                                            <input [(ngModel)]="role" type="text"
                                                                                id="role_id" name="component_name"
                                                                                formControlName="role" pInputText
                                                                                placeholder="Enter Role"
                                                                                class="p-inputtext inputtextHeight"
                                                                                maxlength="50"
                                                                                (keyup)="enableBtn1=false" />
                                                                            <div class="p-error"
                                                                                *ngIf="PlanAttributeForm.get('role')!.invalid && (PlanAttributeForm.get('role')!.dirty || PlanAttributeForm.get('role')!.touched)">
                                                                                <div
                                                                                    *ngIf="PlanAttributeForm.get('role')?.errors?.required">
                                                                                    <label
                                                                                        class="alert-icon alert-circle-icon"></label>
                                                                                    <medium class="p-error">
                                                                                        Role is required.
                                                                                    </medium>
                                                                                </div>
                                                                                <div
                                                                                    *ngIf="PlanAttributeForm.get('role')?.errors?.pattern">
                                                                                    <label
                                                                                        class="alert-icon alert-circle-icon"></label>
                                                                                    Please enter valid role.
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>


                                                                    <div id='saas_heading_div'
                                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 left">
                                                                        <span style="font-size: 16px;">VPC Details
                                                                        </span>
                                                                    </div>

                                                                    <!-- textbox -->
                                                                    <div
                                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left ">
                                                                        <div class="p-fluid p-field">
                                                                            <label
                                                                                [ngClass]="{'field-required': PlanAttributeForm.get('vpcName')?.errors?.required}">
                                                                                VPC name
                                                                            </label>
                                                                            <input [(ngModel)]="vpcName" type="text"
                                                                                id="vpcName_id" name="component_name"
                                                                                formControlName="vpcName" pInputText
                                                                                placeholder="Enter VPC Name"
                                                                                class="p-inputtext inputtextHeight"
                                                                                maxlength="50"
                                                                                (keyup)="enableBtn1=false" />
                                                                            <div class="p-error"
                                                                                *ngIf="PlanAttributeForm.get('vpcName')!.invalid && (PlanAttributeForm.get('vpcName')!.dirty || PlanAttributeForm.get('vpcName')!.touched)">
                                                                                <div
                                                                                    *ngIf="PlanAttributeForm.get('vpcName')?.errors?.required">
                                                                                    <label
                                                                                        class="alert-icon alert-circle-icon"></label>
                                                                                    <medium class="p-error">
                                                                                        VPC Name is required.
                                                                                    </medium>
                                                                                </div>
                                                                                <div
                                                                                    *ngIf="PlanAttributeForm.get('vpcName')?.errors?.pattern">
                                                                                    <label
                                                                                        class="alert-icon alert-circle-icon"></label>
                                                                                    Please enter valid VPC Name.
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>


                                                                    <!-- textbox -->
                                                                    <div
                                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left ">
                                                                        <div class="p-fluid p-field">
                                                                            <label
                                                                                [ngClass]="{'field-required': PlanAttributeForm.get('subnet')?.errors?.required}">
                                                                                Subnet
                                                                            </label>
                                                                            <input [(ngModel)]="subnet" type="text"
                                                                                id="subnet_id" name="component_name"
                                                                                formControlName="subnet" pInputText
                                                                                placeholder="Enter subnet"
                                                                                class="p-inputtext inputtextHeight"
                                                                                maxlength="50"
                                                                                (keyup)="enableBtn1=false" />
                                                                            <div class="p-error"
                                                                                *ngIf="PlanAttributeForm.get('subnet')!.invalid && (PlanAttributeForm.get('subnet')!.dirty || PlanAttributeForm.get('subnet')!.touched)">
                                                                                <div
                                                                                    *ngIf="PlanAttributeForm.get('subnet')?.errors?.required">
                                                                                    <label
                                                                                        class="alert-icon alert-circle-icon"></label>
                                                                                    <medium class="p-error">
                                                                                        Subnet is required.
                                                                                    </medium>
                                                                                </div>
                                                                                <div
                                                                                    *ngIf="PlanAttributeForm.get('subnet')?.errors?.pattern">
                                                                                    <label
                                                                                        class="alert-icon alert-circle-icon"></label>
                                                                                    Please enter valid subnet.
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                    <div id='saas_heading_div'
                                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 left">
                                                                        <span style="font-size: 16px;">Cluster Details
                                                                        </span>
                                                                    </div>

                                                                    <!-- textbox -->
                                                                    <div
                                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left ">
                                                                        <div class="p-fluid p-field">
                                                                            <label
                                                                                [ngClass]="{'field-required': PlanAttributeForm.get('clusterName')?.errors?.required}">
                                                                                Cluster Name
                                                                            </label>
                                                                            <input [(ngModel)]="clusterName" type="text"
                                                                                id="clusterName_id"
                                                                                name="component_name"
                                                                                formControlName="clusterName" pInputText
                                                                                placeholder="Enter Cluster Name"
                                                                                class="p-inputtext inputtextHeight"
                                                                                maxlength="50"
                                                                                (keyup)="enableBtn1=false" />
                                                                            <div class="p-error"
                                                                                *ngIf="PlanAttributeForm.get('clusterName')!.invalid && (PlanAttributeForm.get('clusterName')!.dirty || PlanAttributeForm.get('clusterName')!.touched)">
                                                                                <div
                                                                                    *ngIf="PlanAttributeForm.get('clusterName')?.errors?.required">
                                                                                    <label
                                                                                        class="alert-icon alert-circle-icon"></label>
                                                                                    <medium class="p-error">
                                                                                        Cluster Name is required.
                                                                                    </medium>
                                                                                </div>
                                                                                <div
                                                                                    *ngIf="PlanAttributeForm.get('clusterName')?.errors?.pattern">
                                                                                    <label
                                                                                        class="alert-icon alert-circle-icon"></label>
                                                                                    Please enter valid cluster name.
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>


                                                                    <!-- dropdown -->
                                                                    <div
                                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left">
                                                                        <div class="p-fluid p-field">
                                                                            <label
                                                                                [ngClass]="{'field-required': PlanAttributeForm.get('nodeFamily')?.errors?.required}">
                                                                                Node Family
                                                                                <span
                                                                                    class='invalid-feedback-astrik'>*</span></label>

                                                                            <p-dropdown [(ngModel)]="nodeFamily"
                                                                                id="nodeFamily_id" name="nodeFamily"
                                                                                placeholder="Select"
                                                                                [options]="nodeFamilyList"
                                                                                [filter]="true" filterBy="label"
                                                                                [autoDisplayFirst]="false"
                                                                                formControlName="nodeFamily"
                                                                                (onChange)="enableBtn1=false">
                                                                                <ng-template let-item pTemplate="item">
                                                                                    <div
                                                                                        *ngIf="item.label.length <= 35;">
                                                                                        {{item.label}}
                                                                                    </div>
                                                                                    <div *ngIf="item.label.length > 35;"
                                                                                        pTooltip="{{item.label}}"
                                                                                        tooltipPosition="bottom">
                                                                                        {{item.label.substring(0,35) +
                                                                                        "..." }}
                                                                                    </div>
                                                                                </ng-template>
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>

                                                                    <!-- input switch -->
                                                                    <div
                                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                                                                        <div class="p-fluid">
                                                                            <span class="d-block label-top"> <label>
                                                                                    Enable pod auto-scalling
                                                                                </label>
                                                                            </span>
                                                                            <div class="p-fieldp-fluid">
                                                                                <p-inputSwitch
                                                                                    [(ngModel)]="this.enablePodAutoScalling"
                                                                                    formControlName="enablePodAutoScalling"
                                                                                    styleClass="p-inputswitch-md"
                                                                                    (onChange)="enableBtn1=false"
                                                                                    class="p-mr-2 custom-switch">
                                                                                </p-inputSwitch>
                                                                            </div>
                                                                        </div>

                                                                    </div>


                                                                    <div id='saas_heading_div'
                                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 left">
                                                                        <span style="font-size: 16px;">External Storage
                                                                            Details
                                                                        </span>
                                                                    </div>

                                                                    <!-- textbox -->

                                                                    <div
                                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left">
                                                                        <div class="p-fluid p-field">
                                                                            <label
                                                                                [ngClass]="{'field-required': PlanAttributeForm.get('efsStyle')?.errors?.required}">
                                                                                EFS Style
                                                                                <span
                                                                                    class='invalid-feedback-astrik'>*</span></label>

                                                                            <p-dropdown [(ngModel)]="efsStyle"
                                                                                id="efsStyle_id" name="efsStyle"
                                                                                placeholder="Select"
                                                                                [options]="styleList" [filter]="true"
                                                                                filterBy="label"
                                                                                [autoDisplayFirst]="false"
                                                                                formControlName="efsStyle"
                                                                                (onChange)="enableBtn1=false">
                                                                                <ng-template let-item pTemplate="item">
                                                                                    <div
                                                                                        *ngIf="item.label.length <= 35;">
                                                                                        {{item.label}}
                                                                                    </div>
                                                                                    <div *ngIf="item.label.length > 35;"
                                                                                        pTooltip="{{item.label}}"
                                                                                        tooltipPosition="bottom">
                                                                                        {{item.label.substring(0,35) +
                                                                                        "..." }}
                                                                                    </div>
                                                                                </ng-template>
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left ">
                                                                        <div class="p-fluid p-field">
                                                                            <label
                                                                                [ngClass]="{'field-required': PlanAttributeForm.get('efsPath')?.errors?.required}">
                                                                                EFS Path
                                                                            </label>
                                                                            <input [(ngModel)]="efsPath" type="text"
                                                                                id="efsPath_id" name="component_name"
                                                                                formControlName="efsPath" pInputText
                                                                                placeholder="Enter EFS Path"
                                                                                class="p-inputtext inputtextHeight"
                                                                                maxlength="50"
                                                                                (keyup)="enableBtn1=false" />
                                                                            <div class="p-error"
                                                                                *ngIf="PlanAttributeForm.get('efsPath')!.invalid && (PlanAttributeForm.get('efsPath')!.dirty || PlanAttributeForm.get('efsPath')!.touched)">
                                                                                <div
                                                                                    *ngIf="PlanAttributeForm.get('efsPath')?.errors?.required">
                                                                                    <label
                                                                                        class="alert-icon alert-circle-icon"></label>
                                                                                    <medium class="p-error">
                                                                                        EFS Path is required.
                                                                                    </medium>
                                                                                </div>
                                                                                <div
                                                                                    *ngIf="PlanAttributeForm.get('efsPath')?.errors?.pattern">
                                                                                    <label
                                                                                        class="alert-icon alert-circle-icon"></label>
                                                                                    Please enter valid EFS Path.
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>


                                                                    <div
                                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left">
                                                                        <div class="p-fluid p-field">
                                                                            <label
                                                                                [ngClass]="{'field-required': PlanAttributeForm.get('s3Style')?.errors?.required}">
                                                                                S3 Style
                                                                                <span
                                                                                    class='invalid-feedback-astrik'>*</span></label>

                                                                            <p-dropdown [(ngModel)]="s3Style"
                                                                                id="s3Style_id" name="s3Style"
                                                                                placeholder="Select"
                                                                                [options]="styleList" [filter]="true"
                                                                                filterBy="label"
                                                                                [autoDisplayFirst]="false"
                                                                                formControlName="s3Style"
                                                                                (onChange)="enableBtn1=false">
                                                                                <ng-template let-item pTemplate="item">
                                                                                    <div
                                                                                        *ngIf="item.label.length <= 35;">
                                                                                        {{item.label}}
                                                                                    </div>
                                                                                    <div *ngIf="item.label.length > 35;"
                                                                                        pTooltip="{{item.label}}"
                                                                                        tooltipPosition="bottom">
                                                                                        {{item.label.substring(0,35) +
                                                                                        "..." }}
                                                                                    </div>
                                                                                </ng-template>
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                    <!-- textbox -->
                                                                    <div
                                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left ">
                                                                        <div class="p-fluid p-field">
                                                                            <label
                                                                                [ngClass]="{'field-required': PlanAttributeForm.get('s3ArnRole')?.errors?.required}">
                                                                                S3 ARN Role
                                                                            </label>
                                                                            <input [(ngModel)]="s3ArnRole" type="text"
                                                                                id="s3ArnRole_id" name="component_name"
                                                                                formControlName="s3ArnRole" pInputText
                                                                                placeholder="Enter S3 ARN role"
                                                                                class="p-inputtext inputtextHeight"
                                                                                maxlength="50"
                                                                                (keyup)="enableBtn1=false" />
                                                                            <div class="p-error"
                                                                                *ngIf="PlanAttributeForm.get('s3ArnRole')!.invalid && (PlanAttributeForm.get('s3ArnRole')!.dirty || PlanAttributeForm.get('s3ArnRole')!.touched)">
                                                                                <div
                                                                                    *ngIf="PlanAttributeForm.get('s3ArnRole')?.errors?.required">
                                                                                    <label
                                                                                        class="alert-icon alert-circle-icon"></label>
                                                                                    <medium class="p-error">
                                                                                        S3 ARN Role is required.
                                                                                    </medium>
                                                                                </div>
                                                                                <div
                                                                                    *ngIf="PlanAttributeForm.get('s3ArnRole')?.errors?.pattern">
                                                                                    <label
                                                                                        class="alert-icon alert-circle-icon"></label>
                                                                                    Please enter valid role.
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>


                                                                    <!-- textbox -->
                                                                    <div
                                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left ">
                                                                        <div class="p-fluid p-field">
                                                                            <label
                                                                                [ngClass]="{'field-required': PlanAttributeForm.get('s3Secret')?.errors?.required}">
                                                                                S3 Secret
                                                                            </label>
                                                                            <input [(ngModel)]="s3Secret" type="text"
                                                                                id="s3Secret_id" name="component_name"
                                                                                formControlName="s3Secret" pInputText
                                                                                placeholder="Enter S3 secret string"
                                                                                class="p-inputtext inputtextHeight"
                                                                                maxlength="50"
                                                                                (keyup)="enableBtn1=false" />
                                                                            <div class="p-error"
                                                                                *ngIf="PlanAttributeForm.get('s3Secret')!.invalid && (PlanAttributeForm.get('s3Secret')!.dirty || PlanAttributeForm.get('s3Secret')!.touched)">
                                                                                <div
                                                                                    *ngIf="PlanAttributeForm.get('s3Secret')?.errors?.required">
                                                                                    <label
                                                                                        class="alert-icon alert-circle-icon"></label>
                                                                                    <medium class="p-error">
                                                                                        S3 secret is required.
                                                                                    </medium>
                                                                                </div>
                                                                                <div
                                                                                    *ngIf="PlanAttributeForm.get('s3Secret')?.errors?.pattern">
                                                                                    <label
                                                                                        class="alert-icon alert-circle-icon"></label>
                                                                                    Please enter valid secret.
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>


                                                                    <!-- textbox -->
                                                                    <div
                                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left ">
                                                                        <div class="p-fluid p-field">
                                                                            <label
                                                                                [ngClass]="{'field-required': PlanAttributeForm.get('s3Key')?.errors?.required}">
                                                                                S3 Key
                                                                            </label>
                                                                            <input [(ngModel)]="s3Key" type="text"
                                                                                id="s3Key_id" name="component_name"
                                                                                formControlName="s3Key" pInputText
                                                                                placeholder="Enter S3 key string"
                                                                                class="p-inputtext inputtextHeight"
                                                                                maxlength="50"
                                                                                (keyup)="enableBtn1=false" />
                                                                            <div class="p-error"
                                                                                *ngIf="PlanAttributeForm.get('s3Key')!.invalid && (PlanAttributeForm.get('s3Key')!.dirty || PlanAttributeForm.get('s3Key')!.touched)">
                                                                                <div
                                                                                    *ngIf="PlanAttributeForm.get('s3Key')?.errors?.required">
                                                                                    <label
                                                                                        class="alert-icon alert-circle-icon"></label>
                                                                                    <medium class="p-error">
                                                                                        S3 Key is required.
                                                                                    </medium>
                                                                                </div>
                                                                                <div
                                                                                    *ngIf="PlanAttributeForm.get('s3Key')?.errors?.pattern">
                                                                                    <label
                                                                                        class="alert-icon alert-circle-icon"></label>
                                                                                    Please enter valid key.
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>


                                                                    <!-- textbox -->
                                                                    <div
                                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left ">
                                                                        <div class="p-fluid p-field">
                                                                            <label
                                                                                [ngClass]="{'field-required': PlanAttributeForm.get('s3SecretVaultPath')?.errors?.required}">
                                                                                S3 Secret Vault Path
                                                                            </label>
                                                                            <input [(ngModel)]="s3SecretVaultPath"
                                                                                type="text" id="s3SecretVaultPath_id"
                                                                                name="component_name"
                                                                                formControlName="s3SecretVaultPath"
                                                                                pInputText
                                                                                placeholder="Enter S3 secret vault path"
                                                                                class="p-inputtext inputtextHeight"
                                                                                maxlength="50"
                                                                                (keyup)="enableBtn1=false" />
                                                                            <div class="p-error"
                                                                                *ngIf="PlanAttributeForm.get('s3SecretVaultPath')!.invalid && (PlanAttributeForm.get('s3SecretVaultPath')!.dirty || PlanAttributeForm.get('s3SecretVaultPath')!.touched)">
                                                                                <div
                                                                                    *ngIf="PlanAttributeForm.get('s3SecretVaultPath')?.errors?.required">
                                                                                    <label
                                                                                        class="alert-icon alert-circle-icon"></label>
                                                                                    <medium class="p-error">
                                                                                        Path is required.
                                                                                    </medium>
                                                                                </div>
                                                                                <div
                                                                                    *ngIf="PlanAttributeForm.get('s3SecretVaultPath')?.errors?.pattern">
                                                                                    <label
                                                                                        class="alert-icon alert-circle-icon"></label>
                                                                                    Please enter valid path.
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>


                                                                    <div id='saas_heading_div'
                                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 left">
                                                                        <span style="font-size: 16px;">Cache Storage
                                                                            Details
                                                                        </span>
                                                                    </div>


                                                                    <div
                                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left">
                                                                        <div class="p-fluid p-field">
                                                                            <label
                                                                                [ngClass]="{'field-required': PlanAttributeForm.get('redisStyle')?.errors?.required}">
                                                                                REDIS Style
                                                                                <span
                                                                                    class='invalid-feedback-astrik'>*</span></label>

                                                                            <p-dropdown [(ngModel)]="redisStyle"
                                                                                id="redisStyle_id" name="redisStyle"
                                                                                placeholder="Select"
                                                                                [options]="styleList" [filter]="true"
                                                                                filterBy="label"
                                                                                [autoDisplayFirst]="false"
                                                                                formControlName="redisStyle"
                                                                                (onChange)="enableBtn1=false">
                                                                                <ng-template let-item pTemplate="item">
                                                                                    <div
                                                                                        *ngIf="item.label.length <= 35;">
                                                                                        {{item.label}}
                                                                                    </div>
                                                                                    <div *ngIf="item.label.length > 35;"
                                                                                        pTooltip="{{item.label}}"
                                                                                        tooltipPosition="bottom">
                                                                                        {{item.label.substring(0,35) +
                                                                                        "..." }}
                                                                                    </div>
                                                                                </ng-template>
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                    <!-- textbox -->
                                                                    <div
                                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left ">
                                                                        <div class="p-fluid p-field">
                                                                            <label
                                                                                [ngClass]="{'field-required': PlanAttributeForm.get('redisUrl')?.errors?.required}">
                                                                                REDIS URL
                                                                            </label>
                                                                            <input [(ngModel)]="redisUrl" type="text"
                                                                                id="redisUrl_id" name="component_name"
                                                                                formControlName="redisUrl" pInputText
                                                                                placeholder="Enter REDIS URL"
                                                                                class="p-inputtext inputtextHeight"
                                                                                maxlength="50"
                                                                                (keyup)="enableBtn1=false" />
                                                                            <div class="p-error"
                                                                                *ngIf="PlanAttributeForm.get('redisUrl')!.invalid && (PlanAttributeForm.get('redisUrl')!.dirty || PlanAttributeForm.get('redisUrl')!.touched)">
                                                                                <div
                                                                                    *ngIf="PlanAttributeForm.get('redisUrl')?.errors?.required">
                                                                                    <label
                                                                                        class="alert-icon alert-circle-icon"></label>
                                                                                    <medium class="p-error">
                                                                                        URl is required.
                                                                                    </medium>
                                                                                </div>
                                                                                <div
                                                                                    *ngIf="PlanAttributeForm.get('redisUrl')?.errors?.pattern">
                                                                                    <label
                                                                                        class="alert-icon alert-circle-icon"></label>
                                                                                    Please enter valid URL.
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>


                                                                    <!-- textbox -->
                                                                    <div
                                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left ">
                                                                        <div class="p-fluid p-field">
                                                                            <label
                                                                                [ngClass]="{'field-required': PlanAttributeForm.get('redisVaultPath')?.errors?.required}">
                                                                                REDIS Vault Path
                                                                            </label>
                                                                            <input [(ngModel)]="redisVaultPath"
                                                                                type="text" id="redisVaultPath_id"
                                                                                name="component_name"
                                                                                formControlName="redisVaultPath"
                                                                                pInputText
                                                                                placeholder="Enter REDIS vault path"
                                                                                class="p-inputtext inputtextHeight"
                                                                                maxlength="50"
                                                                                (keyup)="enableBtn1=false" />
                                                                            <div class="p-error"
                                                                                *ngIf="PlanAttributeForm.get('redisVaultPath')!.invalid && (PlanAttributeForm.get('redisVaultPath')!.dirty || PlanAttributeForm.get('redisVaultPath')!.touched)">
                                                                                <div
                                                                                    *ngIf="PlanAttributeForm.get('redisVaultPath')?.errors?.required">
                                                                                    <label
                                                                                        class="alert-icon alert-circle-icon"></label>
                                                                                    <medium class="p-error">
                                                                                        Path is required.
                                                                                    </medium>
                                                                                </div>
                                                                                <div
                                                                                    *ngIf="PlanAttributeForm.get('redisVaultPath')?.errors?.pattern">
                                                                                    <label
                                                                                        class="alert-icon alert-circle-icon"></label>
                                                                                    Please enter valid path.
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>


                                                                    <!-- textbox -->
                                                                    <div
                                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left ">
                                                                        <div class="p-fluid p-field">
                                                                            <label
                                                                                [ngClass]="{'field-required': PlanAttributeForm.get('redisUsername')?.errors?.required}">
                                                                                REDIS Username
                                                                            </label>
                                                                            <input [(ngModel)]="redisUsername"
                                                                                type="text" id="redisUsername_id"
                                                                                name="component_name"
                                                                                formControlName="redisUsername"
                                                                                pInputText
                                                                                placeholder="Enter REDIS username"
                                                                                class="p-inputtext inputtextHeight"
                                                                                maxlength="50"
                                                                                (keyup)="enableBtn1=false" />
                                                                            <div class="p-error"
                                                                                *ngIf="PlanAttributeForm.get('redisUsername')!.invalid && (PlanAttributeForm.get('redisUsername')!.dirty || PlanAttributeForm.get('redisUsername')!.touched)">
                                                                                <div
                                                                                    *ngIf="PlanAttributeForm.get('redisUsername')?.errors?.required">
                                                                                    <label
                                                                                        class="alert-icon alert-circle-icon"></label>
                                                                                    <medium class="p-error">
                                                                                        Username is required.
                                                                                    </medium>
                                                                                </div>
                                                                                <div
                                                                                    *ngIf="PlanAttributeForm.get('redisUsername')?.errors?.pattern">
                                                                                    <label
                                                                                        class="alert-icon alert-circle-icon"></label>
                                                                                    Please enter valid username.
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>


                                                                    <!-- textbox -->
                                                                    <div
                                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left ">
                                                                        <div class="p-fluid p-field">
                                                                            <label
                                                                                [ngClass]="{'field-required': PlanAttributeForm.get('redisPassword')?.errors?.required}">
                                                                                REDIS Password
                                                                            </label>
                                                                            <div class="p-fluid  p-field">
                                                                                <p-password [feedback]="false"
                                                                                    [toggleMask]="true" id="oldPwd_id"
                                                                                    name="component_name"
                                                                                    formControlName="redisPassword"
                                                                                    [(ngModel)]="redisPassword"
                                                                                    placeholder="Enter REDIS password"
                                                                                    (keyup)="enableBtn1=false"></p-password>
                                                                            </div>
                                                                            <div class="p-error"
                                                                                *ngIf="PlanAttributeForm.get('redisPassword')!.invalid && (PlanAttributeForm.get('redisPassword')!.dirty || PlanAttributeForm.get('redisPassword')!.touched)">
                                                                                <div
                                                                                    *ngIf="PlanAttributeForm.get('redisPassword')?.errors?.required">
                                                                                    <label
                                                                                        class="alert-icon alert-circle-icon"></label>
                                                                                    <medium class="p-error">
                                                                                        Password is required.
                                                                                    </medium>
                                                                                </div>
                                                                                <div
                                                                                    *ngIf="PlanAttributeForm.get('redisPassword')?.errors?.pattern">
                                                                                    <label
                                                                                        class="alert-icon alert-circle-icon"></label>
                                                                                    Please enter valid password.
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>



                                                                    <div id='saas_heading_div'
                                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 left">
                                                                        <span style="font-size: 16px;">Database Details
                                                                        </span>
                                                                    </div>


                                                                    <div
                                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left">
                                                                        <div class="p-fluid p-field">
                                                                            <label
                                                                                [ngClass]="{'field-required': PlanAttributeForm.get('rdsStyle')?.errors?.required}">
                                                                                RDS Style
                                                                                <span
                                                                                    class='invalid-feedback-astrik'>*</span></label>

                                                                            <p-dropdown [(ngModel)]="rdsStyle"
                                                                                id="rdsStyle_id" name="rdsStyle"
                                                                                placeholder="Select"
                                                                                [options]="styleList" [filter]="true"
                                                                                filterBy="label"
                                                                                [autoDisplayFirst]="false"
                                                                                formControlName="rdsStyle"
                                                                                (onChange)="enableBtn1=false">
                                                                                <ng-template let-item pTemplate="item">
                                                                                    <div
                                                                                        *ngIf="item.label.length <= 35;">
                                                                                        {{item.label}}
                                                                                    </div>
                                                                                    <div *ngIf="item.label.length > 35;"
                                                                                        pTooltip="{{item.label}}"
                                                                                        tooltipPosition="bottom">
                                                                                        {{item.label.substring(0,35) +
                                                                                        "..." }}
                                                                                    </div>
                                                                                </ng-template>
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                    <!-- dropdown -->
                                                                    <div
                                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left">
                                                                        <div class="p-fluid p-field">
                                                                            <label
                                                                                [ngClass]="{'field-required': PlanAttributeForm.get('rdsType')?.errors?.required}">
                                                                                RDS type
                                                                                <span
                                                                                    class='invalid-feedback-astrik'>*</span></label>

                                                                            <p-dropdown [(ngModel)]="rdsType"
                                                                                id="rdsType_id" name="rdsType"
                                                                                placeholder="Select"
                                                                                [options]="rdsTypeList" [filter]="true"
                                                                                filterBy="label"
                                                                                [autoDisplayFirst]="false"
                                                                                formControlName="rdsType"
                                                                                (onChange)="enableBtn1=false">
                                                                                <ng-template let-item pTemplate="item">
                                                                                    <div
                                                                                        *ngIf="item.label.length <= 35;">
                                                                                        {{item.label}}
                                                                                    </div>
                                                                                    <div *ngIf="item.label.length > 35;"
                                                                                        pTooltip="{{item.label}}"
                                                                                        tooltipPosition="bottom">
                                                                                        {{item.label.substring(0,35) +
                                                                                        "..." }}
                                                                                    </div>
                                                                                </ng-template>
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>


                                                                    <!-- textbox -->
                                                                    <div
                                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left ">
                                                                        <div class="p-fluid p-field">
                                                                            <label
                                                                                [ngClass]="{'field-required': PlanAttributeForm.get('rdsHost')?.errors?.required}">
                                                                                RDS Host
                                                                                <span
                                                                                    class='invalid-feedback-astrik'>*</span></label>
                                                                            <input [(ngModel)]="rdsHost" type="text"
                                                                                id="rdsHost_id" name="component_name"
                                                                                formControlName="rdsHost" pInputText
                                                                                placeholder="Enter IP address/DNS format"
                                                                                class="p-inputtext inputtextHeight"
                                                                                maxlength="50"
                                                                                (keyup)="enableBtn1=false" />
                                                                            <div class="p-error"
                                                                                *ngIf="PlanAttributeForm.get('rdsHost')!.invalid && (PlanAttributeForm.get('rdsHost')!.dirty || PlanAttributeForm.get('rdsHost')!.touched)">
                                                                                <div
                                                                                    *ngIf="PlanAttributeForm.get('rdsHost')?.errors?.required">
                                                                                    <label
                                                                                        class="alert-icon alert-circle-icon"></label>
                                                                                    <medium class="p-error">
                                                                                        RDS Host is required.
                                                                                    </medium>
                                                                                </div>
                                                                                <div
                                                                                    *ngIf="PlanAttributeForm.get('rdsHost')?.errors?.pattern">
                                                                                    <label
                                                                                        class="alert-icon alert-circle-icon"></label>
                                                                                    Please enter valid host.
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>


                                                                    <!-- textbox -->
                                                                    <div
                                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left ">
                                                                        <div class="p-fluid p-field">
                                                                            <label
                                                                                [ngClass]="{'field-required': PlanAttributeForm.get('rdsPort')?.errors?.required}">
                                                                                RDS Port
                                                                                <span
                                                                                    class='invalid-feedback-astrik'>*</span></label>
                                                                            <input [(ngModel)]="rdsPort" type="text"
                                                                                id="rdsPort_id" name="component_name"
                                                                                formControlName="rdsPort" pInputText
                                                                                placeholder="Enter number"
                                                                                class="p-inputtext inputtextHeight"
                                                                                maxlength="50"
                                                                                (keyup)="enableBtn1=false" />
                                                                            <div class="p-error"
                                                                                *ngIf="PlanAttributeForm.get('rdsPort')!.invalid && (PlanAttributeForm.get('rdsPort')!.dirty || PlanAttributeForm.get('rdsPort')!.touched)">
                                                                                <div
                                                                                    *ngIf="PlanAttributeForm.get('rdsPort')?.errors?.required">
                                                                                    <label
                                                                                        class="alert-icon alert-circle-icon"></label>
                                                                                    <medium class="p-error">
                                                                                        Port is required.
                                                                                    </medium>
                                                                                </div>
                                                                                <div
                                                                                    *ngIf="PlanAttributeForm.get('rdsPort')?.errors?.pattern">
                                                                                    <label
                                                                                        class="alert-icon alert-circle-icon"></label>
                                                                                    Please enter valid port.
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>


                                                                    <!-- textbox -->
                                                                    <div
                                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left ">
                                                                        <div class="p-fluid p-field">
                                                                            <label
                                                                                [ngClass]="{'field-required': PlanAttributeForm.get('rdsUsername')?.errors?.required}">
                                                                                RDS Username
                                                                                <span
                                                                                    class='invalid-feedback-astrik'>*</span></label>
                                                                            <input [(ngModel)]="rdsUsername" type="text"
                                                                                id="rdsUsername_id"
                                                                                name="component_name"
                                                                                formControlName="rdsUsername" pInputText
                                                                                placeholder="Enter RDS username"
                                                                                class="p-inputtext inputtextHeight"
                                                                                maxlength="50"
                                                                                (keyup)="enableBtn1=false" />
                                                                            <div class="p-error"
                                                                                *ngIf="PlanAttributeForm.get('rdsUsername')!.invalid && (PlanAttributeForm.get('rdsUsername')!.dirty || PlanAttributeForm.get('rdsUsername')!.touched)">
                                                                                <div
                                                                                    *ngIf="PlanAttributeForm.get('rdsUsername')?.errors?.required">
                                                                                    <label
                                                                                        class="alert-icon alert-circle-icon"></label>
                                                                                    <medium class="p-error">
                                                                                        Username is required.
                                                                                    </medium>
                                                                                </div>
                                                                                <div
                                                                                    *ngIf="PlanAttributeForm.get('rdsUsername')?.errors?.pattern">
                                                                                    <label
                                                                                        class="alert-icon alert-circle-icon"></label>
                                                                                    Please enter valid username.
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>


                                                                    <!-- textbox -->
                                                                    <div
                                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left ">
                                                                        <div class="p-fluid p-field">
                                                                            <label
                                                                                [ngClass]="{'field-required': PlanAttributeForm.get('rdsPassword')?.errors?.required}">
                                                                                RDS Password
                                                                                <span
                                                                                    class='invalid-feedback-astrik'>*</span></label>
                                                                            <div class="p-fluid  p-field">
                                                                                <p-password [feedback]="false"
                                                                                    [toggleMask]="true" id="oldPwd_id"
                                                                                    name="rdsPassword_name"
                                                                                    formControlName="rdsPassword"
                                                                                    [(ngModel)]="rdsPassword"
                                                                                    placeholder="Enter RDS password"
                                                                                    (keyup)="enableBtn1=false"></p-password>
                                                                            </div>
                                                                            <div class="p-error"
                                                                                *ngIf="PlanAttributeForm.get('rdsPassword')!.invalid && (PlanAttributeForm.get('rdsPassword')!.dirty || PlanAttributeForm.get('rdsPassword')!.touched)">
                                                                                <div
                                                                                    *ngIf="PlanAttributeForm.get('rdsPassword')?.errors?.required">
                                                                                    <label
                                                                                        class="alert-icon alert-circle-icon"></label>
                                                                                    <medium class="p-error">
                                                                                        Password is required.
                                                                                    </medium>
                                                                                </div>
                                                                                <div
                                                                                    *ngIf="PlanAttributeForm.get('rdsPassword')?.errors?.pattern">
                                                                                    <label
                                                                                        class="alert-icon alert-circle-icon"></label>
                                                                                    Please enter valid password.
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>


                                                                    <div
                                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left ">
                                                                        <div class="p-fluid p-field">
                                                                            <label
                                                                                [ngClass]="{'field-required': PlanAttributeForm.get('rdsDBName')?.errors?.required}">
                                                                                RDS DB Name
                                                                                <span
                                                                                    class='invalid-feedback-astrik'>*</span></label>
                                                                            <input [(ngModel)]="rdsDBName" type="text"
                                                                                id="rdsDBName_id" name="rdsDBName_name"
                                                                                formControlName="rdsDBName" pInputText
                                                                                placeholder="Enter RDS DB Name"
                                                                                class="p-inputtext inputtextHeight"
                                                                                maxlength="50"
                                                                                (keyup)="enableBtn1=false" />
                                                                            <div class="p-error"
                                                                                *ngIf="PlanAttributeForm.get('rdsDBName')!.invalid && (PlanAttributeForm.get('rdsDBName')!.dirty || PlanAttributeForm.get('rdsDBName')!.touched)">
                                                                                <div
                                                                                    *ngIf="PlanAttributeForm.get('rdsDBName')?.errors?.required">
                                                                                    <label
                                                                                        class="alert-icon alert-circle-icon"></label>
                                                                                    <medium class="p-error">
                                                                                        RDS DB Name is required.
                                                                                    </medium>
                                                                                </div>
                                                                                <div
                                                                                    *ngIf="PlanAttributeForm.get('rdsDBName')?.errors?.pattern">
                                                                                    <label
                                                                                        class="alert-icon alert-circle-icon"></label>
                                                                                    Please enter valid Name.
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    <!-- textbox -->
                                                                    <div
                                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left ">
                                                                        <div class="p-fluid p-field">
                                                                            <label
                                                                                [ngClass]="{'field-required': PlanAttributeForm.get('rdsVaultPath')?.errors?.required}">
                                                                                RDS Vault Path
                                                                            </label>
                                                                            <input [(ngModel)]="rdsVaultPath"
                                                                                type="text" id="rdsVaultPath_id"
                                                                                name="component_name"
                                                                                formControlName="rdsVaultPath"
                                                                                pInputText placeholder="Enter number"
                                                                                class="p-inputtext inputtextHeight"
                                                                                maxlength="50"
                                                                                (keyup)="enableBtn1=false" />
                                                                            <div class="p-error"
                                                                                *ngIf="PlanAttributeForm.get('rdsVaultPath')!.invalid && (PlanAttributeForm.get('rdsVaultPath')!.dirty || PlanAttributeForm.get('rdsVaultPath')!.touched)">
                                                                                <div
                                                                                    *ngIf="PlanAttributeForm.get('rdsVaultPath')?.errors?.required">
                                                                                    <label
                                                                                        class="alert-icon alert-circle-icon"></label>
                                                                                    <medium class="p-error">
                                                                                        Path is required.
                                                                                    </medium>
                                                                                </div>
                                                                                <div
                                                                                    *ngIf="PlanAttributeForm.get('rdsVaultPath')?.errors?.pattern">
                                                                                    <label
                                                                                        class="alert-icon alert-circle-icon"></label>
                                                                                    Please enter valid path.
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                    <!-- dropdown -->
                                                                    <div
                                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left">
                                                                        <div class="p-fluid p-field">
                                                                            <label
                                                                                [ngClass]="{'field-required': PlanAttributeForm.get('rdsFamily')?.errors?.required}">
                                                                                RDS Family
                                                                                <span
                                                                                    class='invalid-feedback-astrik'>*</span></label>

                                                                            <p-dropdown [(ngModel)]="rdsFamily"
                                                                                id="rdsFamily_id" name="rdsFamily"
                                                                                placeholder="Select"
                                                                                [options]="rdsFamilyList"
                                                                                [filter]="true" filterBy="label"
                                                                                [autoDisplayFirst]="false"
                                                                                formControlName="rdsFamily"
                                                                                (onChange)="enableBtn1=false">
                                                                                <ng-template let-item pTemplate="item">
                                                                                    <div
                                                                                        *ngIf="item.label.length <= 35;">
                                                                                        {{item.label}}
                                                                                    </div>
                                                                                    <div *ngIf="item.label.length > 35;"
                                                                                        pTooltip="{{item.label}}"
                                                                                        tooltipPosition="bottom">
                                                                                        {{item.label.substring(0,35) +
                                                                                        "..." }}
                                                                                    </div>
                                                                                </ng-template>
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>



                                                                    <div
                                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left">
                                                                    </div>
                                                                    <!-- key -->
                                                                </div>

                                                                <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 p-d-flex p-jc-end"
                                                                    [ngStyle]="{'width': ''}">
                                                                    <div class="p-mr-1">
                                                                        <button pButton
                                                                            class="p-button-secondary p-mr-1"
                                                                            type="button"
                                                                            [disabled]="enableAddSusbcriptionBtn || !PlanAttributeForm.valid"
                                                                            label="Add as Subscription"
                                                                            (click)="addAsSubscription()">
                                                                        </button>
                                                                    </div>
                                                                    <div class="btnwrap">
                                                                        <button pButton class="p-button" type="button"
                                                                            [disabled]="enableBtn1 || !PlanAttributeForm.valid"
                                                                            label="Save"
                                                                            (click)="createPlanAttribute();onAddDeployModel();">
                                                                        </button>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </p-tabPanel>
                                </p-tabView>
                            </div>
                        </p-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>