<div style="background-color:#F6F8FA;" (click)="mouseLeave()">
    <div class="p-d-flex p-jc-end">
        <p-messages key="myKey1" class="custom-alert-box" *ngIf="this.messageFlag"></p-messages>
    </div>
    <div class="p-col-fixed p-mt-1 p-ml-1 p-pr-0 custom-breadcrumb">
        <p-breadcrumb [model]="ccprimebreadcrumbList" [home]="homeiconccprimebreadcrumb">
        </p-breadcrumb>
    </div>
    <form class="layout-content custom-form-layout" [formGroup]="ViewGroupForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                <h5 class="custom-head1">View Group</h5>
                <div style="height:auto;">
                    <p-card class="cardBoxShadow">
                        <div class="p-grid p-mt-0">
                            <div class="p-col-3">
                                <span class="group-style"
                                    style="font-size:16px;word-break: break-word;"><strong>{{this.ViewGroupForm.value.displayName}}</strong></span><br>
                                <span style="font-size:13px;">Created on:{{this.ViewGroupForm.value.createdOn
                                    | date:'d MMM y'}}</span><br>
                                <span style="font-size:13px;" *ngIf="this.ViewGroupForm.value.groupType != null">Type:
                                    <div class="btnwrap">
                                        <button style="font-size:13px;" pButton type="button"
                                            style="border-radius:32px;font-size: 11px;font-weight: 500;padding: 2px 8px;height:25px;box-shadow: none;"
                                            [style.color]=" '#12813F' " [style.border]=" '1px solid #CFE6D8' "
                                            class=" p-button-outlined  ">{{this.ViewGroupForm.value.groupType}}</button>
                                    </div>
                                </span>
                            </div>
                            <div class="p-col">
                                <span><strong>About</strong></span><br>
                                <span
                                    style="word-break: break-word;">{{this.ViewGroupForm.value.groupDescription}}</span>
                            </div>

                            <div class="p-col-fixed p-text-right p-p-0" style="width:50px;">
                                <button #btn type="button" pButton icon="pi pi-ellipsis-v" (click)="menu.toggle($event)"
                                    style="margin-left:60px;" appendTo="body"
                                    style="background-color: #ffffff;border-color: #D3D5DB;border-radius: 4px;width:30px;height:30px;color: #3A435E;box-shadow: none;" [disabled]="this.pagesService.userManagementReadOnlyFlag"></button>
                                <p-tieredMenu #menu [model]="items" [popup]="true" appendTo="body">
                                </p-tieredMenu>
                                <p-confirmDialog [style]="{width: '45vw'}" [baseZIndex]="10000"
                                    acceptButtonStyleClass='p-button-brand2'
                                    rejectButtonStyleClass='p-button-secondary p-button-brand2' [baseZIndex]="10000">
                                </p-confirmDialog>
                            </div>
                        </div>
                    </p-card>
                </div>
                <div style="margin-top: 10px;margin-bottom: 60px;">
                    <p-card>
                        <span class="group-style" style="font-size:14px;">Group Details</span>
                        <p-tabView>
                            <p-tabPanel>
                                <ng-template pTemplate="header">
                                    <span class="label-badge">Users Assigned</span>
                                    <p-badge [value]="this.userTableListcount" styleClass="mr-2"></p-badge>
                                </ng-template>
                                <label *ngIf="userTableListcount==0" style="margin-top: 14px;font-size: 15px;">
                                    No Users Assigned!
                                </label>
                                <p-table #tableid [value]="tableList" name="userTable" *ngIf="userTableList.length > 0"
                                    [(selection)]="table" [columns]="userTablecols" [value]="userTableList"
                                    styleClass="p-datatable-customers" [rows]="5" [rowsPerPageOptions]="[5,10,15]"
                                    [paginator]="true" sortMode="single" [scrollable]="true">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th pSortableColumn='firstName' scope="col">
                                                First Name
                                                <i class="p-sortable-column-icon icon-sort" [field]='firstName'
                                                    ariaLabel="Activate to sort"
                                                    ariaLabelDesc="Activate to sort in descending order"
                                                    ariaLabelAsc="Activate to sort in ascending order"></i>
                                                <p-columnFilter type="text" field="firstName" display="menu"
                                                    class="p-ml-auto"></p-columnFilter>
                                            </th>
                                            <th pSortableColumn='lastName' scope="col">
                                                Last Name
                                                <i class="p-sortable-column-icon icon-sort" field='lastName'
                                                    ariaLabel="Activate to sort"
                                                    ariaLabelDesc="Activate to sort in descending order"
                                                    ariaLabelAsc="Activate to sort in ascending order"></i>
                                                <p-columnFilter type="text" field="lastName" display="menu"
                                                    class="p-ml-auto"></p-columnFilter>
                                            </th>
                                            <th pSortableColumn='email' scope="col">
                                                Email Address
                                                <i class="p-sortable-column-icon icon-sort" field='email'
                                                    ariaLabel="Activate to sort"
                                                    ariaLabelDesc="Activate to sort in descending order"
                                                    ariaLabelAsc="Activate to sort in ascending order"></i>
                                                <p-columnFilter type="text" field="email" display="menu"
                                                    class="p-ml-auto"></p-columnFilter>
                                            </th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-rowData let-index="rowIndex">
                                        <tr style="height:40px" (mouseenter)="hoverIndex=index;highlight(index)"
                                            (mouseleave)="hoverIndex=index;highlight(index)" [pSelectableRow]="rowData">
                                            <td>
                                                {{rowData.firstName}}
                                            </td>
                                            <td style="color: rgba(41, 41, 41, 0.8);font-weight: 400;">

                                                {{rowData.lastName}}
                                            </td>
                                            <td>
                                                {{rowData.email}}
                                            </td>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="paginatorright" let-state> Total Records:
                                        {{state.totalRecords}} </ng-template>
                                </p-table>
                            </p-tabPanel>
                            <p-tabPanel *ngIf="this.pagesService.pageRebacFlag=='false'">
                                <ng-template pTemplate="header">
                                    <span class="label-badge">Roles Assigned</span>
                                    <p-badge [value]="this.rolecount" styleClass="mr-2"></p-badge>
                                </ng-template>
                                <p-chip *ngFor="let role of this.roleDetails;" styleClass="mr-2 mb-2"
                                    class="custom-view-chip p-pr-2">{{role.label}}</p-chip>
                                <label *ngIf="this.rolecount==0" style="margin-top: 14px;font-size: 15px;">
                                    No Roles Assigned!
                                </label>
                            </p-tabPanel>
                        </p-tabView>
                    </p-card>
                </div>
            </div>
        </div>
    </form>
</div>