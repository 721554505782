import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { PagesService } from '../pages.service';
import { LazyLoadEvent, MenuItem, MessageService } from 'primeng/api';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { DateFormatterPipe } from 'app/pipes/date-formatter.pipe';
import { Table } from 'primeng/table';
import {
  COURSE_GRAINED,
  FINE_GRAINED,
  ALERT_MSG_TIME_OUT,
  INACTIVE,
  DEACTIVATE,
  ACTIVATE,
  LOCKED,
  DELETE,
  ENTITLEMENT_COMPONENT,
  ENTITLEMENT_DEPENDENT,
  ENTITLEMENT_NOTIFICATIION,
  OWNER_ENTITLEMENT,
  ENTITLEMENT_NAME,
  ENTITLEMENT_STATUS,
  ENTER_KEY_CODE,
  ZERO,
  ENTITLEMENT_ID_REGEXP,
  SUCCESS,
  SINGLE,
  ONE,
  ACTIVE,
  DATAMART,
  TAXONOMY,
  MNS
} from 'app/constants';
import { Calendar } from 'primeng/calendar';
import { assetUrl } from 'src/single-spa/asset-url';
import { ManageEntitlementsService } from './ManageEntitlements.service';
import { DatePipe } from '@angular/common';
import { ActionType, IEventData, IEventSubject, IEditEntEvent, IEditEntitlementApprover } from 'app/shared/interface/event.interface';
import { LoggingEventService } from 'app/logging-event.service';
import { ContextName } from 'app/shared/constants/logging-event.constants';
import { EntitlementEndpointMappingComponent } from '../EntitlementEndpointMapping/EntitlementEndpointMapping.component';
import { PickList } from 'primeng/picklist';
@Component({
  selector: 'jhi-manageentitlements',
  templateUrl: './ManageEntitlements.component.html',
  styleUrls: ['./ManageEntitlements.component.scss'],
  providers: [MessageService, DialogService, DateFormatterPipe],
})
export class ManageEntitlementsComponent implements OnInit {
  ref!: DynamicDialogRef;
  ManageEntitlementsForm = this.fb.group({
    textField: [null, []],
  });
  first = 0;
  messageFlag = true;
  hoverIndex: any;
  isVisible = false;
  activeItem: any;
  pageloadvalues: any;
  ccprimebreadcrumbList = [{ label: `Authorization` }, { label: `Manage Entitlements` }];
  homeiconccprimebreadcrumb = { icon: 'pi pi-home', routerLink: ['/'] };
  htmlSrc: any;
  groupcount: any;
  errorFlag: boolean = true;
  searchList: any[] = [];
  tableList: any[] = [];
  tableList1: any[] = [];
  Hoveritems!: MenuItem[];
  deletemsg: any;
  //manage entitlement
  disableButtons: any;
  disabled: any;
  isSuccess = false;
  ImageAlt: boolean;
  @ViewChild('dt') table: Table;
  isFailure = false;
  successMessage: string = '';
  failureMessage: string = '';
  columns = [];
  entitlements: any = [];
  totalRecords: number = 0;
  rows = 10;
  pageIndex: number = 1;
  pageSize: number = 10;
  rowPerPageOptions: any[];
  entitlementStatusDropdownList: any = [];
  selectedStatus: any = [];
  search: any;
  creationPeriodRange: Date[];
  filteredStatusList: any = [];
  totalEntitlementCount: number = 0;
  componentDropdownList: any = [];
  componentIdsSearch: any = [];
  totalInactiveEntitlementCount: number = 0;
  confirmMessage: any;
  actionMessage: any;
  mode: any;
  isActivateConfirmDialog: any;
  isDeactivateConfirmDialog: any;
  activityEntitlementId: any;
  activityComponentId: any;
  activityEntitlementName: any;
  activityEntitlementStatus: any;
  isActivityLog: boolean = false;
  selectedRecords: any[] = [];
  activityLogList: any[];
  isConfirmDialog: any;
  isBulkActivate: boolean = false;
  isBulkDeactivate: boolean = false;
  isBulkDelete: boolean = false;
  isBulkUnlock: boolean = false;
  activateLabel: any;
  quickFilterCalendarIdx: number = -1;
  closeFilter: boolean = false;
  deleteLabel = DELETE;
  entitlementName: string = '';
  isActiveEntitlementExist: boolean = false;
  isAddNewEntitlement: boolean = false;
  componentName: any = [];
  notifictionEntitlementList: any = [];
  notifictionEntitlementColumns = [];
  isNotificationEntitlementDialog: boolean;
  notificationEntitlementColumns = [];
  searchNotificationEntitlement: any;
  entitlementsForNotification: any = [];
  totalRecordsForNotification: number = 0;
  selectedRecordsForNotification: any[] = [];
  notificationEntitlementId: number = 0;
  isOwnerEntitlementDialog: boolean;
  ownerEntitlementColumns = [];
  searchOwnerEntitlement: any = '';
  entitlementsForOwner: any = [];
  selectedRecordsForOwner: any = [];
  ownerEntitlementId: number = 0;

  isFilterSelection: boolean = false;
  selectedUserStatus: any = [];
  selectedEntilement: any = [];
  selectedOwnerEntitlement: any = [];
  selectedNotification: any = [];
  selectedDependent: any = '';
  selectedComponent: any = '';

  appliedUserStatus: any = '';
  appliedEntilement: any = '';
  appliedOwnerEntitlement: any = [];
  appliedNotification: any = '';
  appliedDependent: any = '';
  appliedComponent: any = '';

  entitlementData: any = [];
  ownerEntitlementData: any = [];
  ownerNotifData: any = [];
  dependentEntitlement: any = [];
  status: any = [];
  componentData: any = [];
  entitlementDropdownList: any[] = [];
  ownerEntDropdownList: any[] = [];
  filterMessage = '';
  appliedSearch: any;
  ownerEntSelected: any;
  ownerEntData: any = [];
  userCreationErrorMessage = null;

  activeTab: any = {
    isComponentTab: false,
    isEntitlementTab: false,
    isOwnerEntitlementTab: false,
    isNotificationEntTab: false,
    isDependenetEntitlementTab: false,
    isStatusTab: false
  };
  isAddApproverDialog: boolean = false;
  sourceEntitlement: any[] = [];
  targetEntitlement: any[] = [];
  addLevelTab: any[] = [];
  allEntitlements: any[] = [];
  sourceEnt: any[] = [];
  allActiveEntitlements: any = [];
  isNoApprovalRequired: boolean = false;
  selectedEntilementToApprove: any = {};
  isApprovalLevelDelete: boolean = false;
  approvalEvent: any;
  approvalMessage: string;

  approvalEntitlementId: any;
  approvalComponentId: any;
  approvalEntitlementName: any;
  approvalEntitlementStatus: any;
  isviewApprovalsDialog: boolean = false;
  viewApprovalList: any[] = [];
  approvalListResponse: any[] = [];
  viewApprovals = [];
  levelTab: any = [];
  levelId: any;
  ownerEntitlement: any = [];
  selectedIndex = 0;
  isApprovalLastItemDelete: boolean = false;
  addApprovalTabIndex: number;
  lastDeletedItemsInLevel: any;
  currentPage: number = 1;
  isOwnerFlag: boolean = false;
  isEditApproverDialog: boolean = false;
  levelWiseResponse: any[] = [];
  levelWiseList: any[] = [];
  tarID: any = [];
  @ViewChild('dateFilter') dateFilter: Calendar;
  approvalComponentName: any = '';
  selectedComponentName: any = '';
  selectedEntitlementStatus: any = '';
  notificationForOwner: any = [];
  appliedOwnerEntilement: any = [];
  appliedOwnerComponent: any = [];
  isSubmitBtnDisabled: boolean = false;
  isEditEntitlement: boolean = false;
  editComponentId: any = '';
  allActiveComponents: any = [];
  entitlementDescription: any = '';
  editEntId: any;
  ownershipEntitlementId: any;
  editComponentDropdownList: any = [];
  ownershipComponent: any;
  ownershipComponentList: any;
  ownershipEntitlement: any;
  activeEntitlementsOfComponent: any = [];
  defaultOwner: any = [];
  user: any;
  isValidEntitlementId: any = false;
  entitlementErrorMessage = null;
  entitlementId_input: any;
  username: any;
  userId: any;
  ownershipEntitlementObj: any = [];
  editDescription: any;
  IS_APPROVAL_PENDING: any;
  isCompPresent: boolean = false;
  isAdmsEntitlement: any;
  componentDetails: any = [];

  isDeleteEntitlementPopup: boolean = false;
  selectedEntitlementForDelete: any;
  approverEntList: any[] = [];
  OwnershipPendingcount: number = 0;
  ownershipDependentEntList: any[] = [];
  isMultipleDeleteEntitlementPopup: boolean = false;
  nonDeletedEntitlementsList: any;
  deletedEntitlementsList: any = [];
  sizeSelectedData: any;
  sizeDeletedSelectedData: any;
  sizeNonDeletedSelectedData: any;
  isEntitlementsDeleted: boolean = false;

  deleteEntList: any = [];

  transform(value: string): string {
    return assetUrl(value);
  }
  assetUrl = assetUrl;
  entitlementDescriptionOldVal: any;
  entitlementNameOldVal: any;
  ownershipComponentNameOldVal: any;
  ownershipEntitlementNameOldVal: any;
  entitlementCurrStatus: string;
  minimumDate = new Date();
  maxDateValue = new Date();
  invalidDates: Array<Date>;
  oldOwnershipComponent: any;
  oldOwnershipEntitlement: any;
  ownershipComponentApprovalFlag = 0;
  ownershipEntitlementApprovalFlag = 0;
  selectedIndexHover: any;

  levelEventData: IEditEntitlementApprover[] = [];
  isNoApprovalRequiredPrevVal: boolean = false;
  isClearSelected: boolean = false;
  isClearAllSelected: boolean = false;
  isEntPageChanged: boolean = false;
  entTypeList: any = [];
  selectedEntType: string;
  isDisableEntType: boolean = true;
  isCourceGrainedEnt: boolean = false;
  activeItem1: any;
  InactiveStatus = INACTIVE;
  activeStatusId: number;

  selectedEntitlementForDeactivate: any;
  deactivateEntList: any[];
  entOwnerComponentNames: string = '';
  @ViewChild('pl') pl: PickList;
  constructor(
    public dialogService: DialogService,
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    private messageService: MessageService,
    private entitlementService: ManageEntitlementsService,
    private dateFormatter: DateFormatterPipe,
    private loggingService: LoggingEventService,
    private cdr: ChangeDetectorRef
  ) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    this.maxDateValue.setDate(this.maxDateValue.getDate());
    this.deletemsg = navigation?.extras?.state?.msg;
    if (this.deletemsg) {
      this.messageFlag = true;

    }
    if (data) {
      this.ManageEntitlementsForm.patchValue(JSON.parse(data));
    }

    if (this.router?.getCurrentNavigation().extras?.state?.componentData != undefined) {
      this.componentDetails.push(this.router.getCurrentNavigation().extras.state.componentData);
    }
    let loginUserName: string;
    loginUserName = localStorage.getItem('username');
    if (loginUserName.length > 0) {
      this.username = loginUserName;
    } else {
      this.username = 'anonymousUser';
    }
  }

  ngOnInit(): void {

    this.columns = [
      { field: 'COMPONENT_NAME', header: 'Component', filterMatchMode: 'contains', display: true },
      { field: 'ENTITLEMENT_NAME', header: 'Entitlement Name', filterMatchMode: 'contains', display: true },
      { field: 'OWNER_ENTITLEMENT_ID', header: 'Ownership', filterMatchMode: 'contains', display: true },
      { field: 'DESCRIPTION', header: 'Description', filterMatchMode: 'contains', display: true },
      { field: 'STATUS', header: 'Entitlement Status', filterMatchMode: 'contains', display: true },
      { field: 'CREATED_DATE', header: 'Created On', filterMatchMode: 'contains', display: true }
    ];
    this.ownerEntitlementColumns = [
      { field: 'ID', header: 'Entitlement Id', filterMatchMode: 'contains', display: true, width: '199px' },
      { field: 'COMPONENT_NAME', header: 'Component Name', filterMatchMode: 'contains', display: true, width: '183px' },
      { field: 'ENTITLEMENT_NAME', header: 'Entitlement Name', filterMatchMode: 'contains', display: true, width: '196px' }
    ];

    this.notificationEntitlementColumns = [
      { field: 'COMPONENT_NAME', header: 'Component Name', filterMatchMode: 'contains', display: true, width: '197px' },
      { field: 'ENTITLEMENT_NAME', header: 'Entitlement Name', filterMatchMode: 'contains', display: true, width: '207px' }
    ];

    this.viewApprovals = [
      { field: 'ID', header: 'Entitlement Id.', filterMatchMode: 'contains', display: true, width: '15%' },
      { field: 'ENTITLEMENT_NAME', header: 'Entitlement', filterMatchMode: 'contains', display: true, width: '15%' },
      { field: 'COMPONENT_NAME', header: 'Component', filterMatchMode: 'contains', display: true, width: '15%' }
    ];

    this.loadEntitlementStatusList();
    this.getComponentList();
    this.getAllActiveComponents();
    this.getEntitlementTypeList();
  }


  imageUrl(url) {
    return assetUrl(url);
  }

  async loadEntitlementList(notificationIds, isNotification) {
    this.appliedSearch = null;
    let filter: any = {};
    this.selectedRecords = [];
    if (this.search || this.searchNotificationEntitlement || this.searchOwnerEntitlement) {
      if (this.isNotificationEntitlementDialog) {
        filter['SEARCH_TEXT'] = this.searchNotificationEntitlement;
      } else if (this.isOwnerEntitlementDialog) {
        filter['SEARCH_TEXT'] = this.searchOwnerEntitlement;
        filter['OWNER_POPUP_ENABLED'] = true;
      } else {
        filter['SEARCH_TEXT'] = this.search;
      }

      if (this.componentIdsSearch && this.componentIdsSearch.length > 0) {
        filter['COMPONENT_IDS'] = this.componentIdsSearch;
      }
    }

    if (!this.isOwnerEntitlementDialog && !this.isNotificationEntitlementDialog) {
      filter['pageIndex'] = this.pageIndex;
      filter['pageSize'] = this.pageSize;

      if (this.componentDetails.length != ZERO) {
        this.selectedComponent = this.componentDetails;
        this.appliedComponent = this.selectedComponent;
        this.activeTab.isComponentTab = true;
      }

      if (this.selectedComponent && this.selectedComponent.length > 0) {
        let filterComponentNames = [];
        let componentIds = [];
        this.selectedComponent.forEach(element => {
          if (element.data.hasOwnProperty('PRIME_COMPONENT_ID')) {
            componentIds.push(element.data.PRIME_COMPONENT_ID);
          } else {
            componentIds.push(element.data.prime_component_id);
          }
          filterComponentNames.push(element.data.NAME);
        });
        filter['FILTER_COMPONENT_NAMES'] = filterComponentNames;
        filter['COMPONENT_ID_FILTER'] = componentIds;
      }

      if (this.selectedDependent && this.selectedDependent.length > 0) {
        let entIds = [];
        this.selectedDependent.forEach(element => {
          entIds.push(element.data['ENTITLEMENT_ID']);
        });
        filter['DEPENDENT_ID_FILTER'] = entIds;
      }

      if (this.selectedNotification && this.selectedNotification.length > 0) {
        let entIds = [];
        this.selectedNotification.forEach(element => {
          entIds.push(element.key);
        });
        filter['NOTIFICATION_ID_FILTER'] = entIds;
      }

      if (this.selectedOwnerEntitlement && this.selectedOwnerEntitlement.length > 0) {
        filter['OWNER_ID_FILTER'] = 1;
        let filterOwnerNames = ['My Ownerships'];
        filter['FILTER_OWNER_NAMES'] = filterOwnerNames;
      }
      if (this.selectedEntilement && this.selectedEntilement.length > 0) {
        let entIds = [];
        let filterEntitlementNames = [];
        this.selectedEntilement.forEach(element => {
          entIds.push(element.data['ENTITLEMENT_ID']);
          filterEntitlementNames.push(element.data['COMPONENT_ID'] + '-' + element.data['ENTITLEMENT_ID'] + ' - ' + element.data['ENTITLEMENT_NAME']);
        });
        filter['FILTER_ENTITLEMENT_NAMES'] = filterEntitlementNames;
        filter['ENTITLEMENT_ID_FILTER'] = entIds;
      }

      if (this.selectedStatus && this.selectedStatus.length > 0) {
        let statusIds = [];
        let filterStatusNames = [];
        this.selectedStatus.forEach(element => {
          statusIds.push(element.data['ID']);
          filterStatusNames.push(element.data['NAME']);
        });
        filter['FILTER_STATUS_NAMES'] = filterStatusNames;
        filter['STATUS_IDS'] = statusIds;
      }

      if (this.creationPeriodRange?.values) {
        filter['FROM_DATE'] = this.creationPeriodRange[0];
        filter['TO_DATE'] = this.creationPeriodRange[1];
      }
    }
    filter['isClearSelected'] = this.isClearSelected.toString();
    filter['isClearAllSelected'] = this.isClearAllSelected.toString();
    filter['isEntPageChanged'] = this.isEntPageChanged.toString();

    if (filter.hasOwnProperty('SEARCH_TEXT') ||
      (filter.hasOwnProperty('COMPONENT_ID_FILTER')) ||
      (filter.hasOwnProperty('COMPONENT_NAME_FILTER')) ||
      (filter.hasOwnProperty('ENTITLEMENT_ID_FILTER')) ||
      (filter.hasOwnProperty('OWNER_ID_FILTER')) ||
      (filter.hasOwnProperty('STATUS_IDS')) ||
      (this.creationPeriodRange != undefined) ||
      (filter['isClearSelected'] == 'true') ||
      (filter['isClearAllSelected'] == 'true') ||
      (filter.isEntPageChanged == 'true') ||
      (filter.pageIndex != 1)) {
      this.entitlementService.getAllEntitlementList(filter).subscribe(
        {
          next: response => {
            this.handleGetEntListResponse(response, notificationIds, isNotification);
          }
          , error: error => {
            this.showErrorValidations(error.error);
          }
        });
    }
    else {
      await this.entitlementService.getEntitlementList(filter).subscribe(//NOSONAR
        {
          next: response => {
            this.handleGetEntListResponse(response, notificationIds, isNotification);
          },
          error: err => {
            this.showErrorValidations(err.error);
          }
        });
    }
  }
  handleGetEntListResponse(response, notificationIds, isNotification) {
    if (this.isNotificationEntitlementDialog) {
      this.entitlementsForNotification = response['RESULT'];
      let notificationIdsArray = [];
      let notificationsIds = [];
      let notificationsIdsList = [];
      if (isNotification) {
        if (notificationIds && notificationIds.length > 0) {
          notificationIdsArray = notificationIds.split(',');
          for (let i = 0; i < notificationIdsArray.length; i++) {
            notificationsIds = notificationIdsArray[i].substring(0, notificationIdsArray[i].indexOf('(')).trim();
            notificationsIdsList.push(notificationsIds);
          }

          this.selectedRecordsForNotification = this.entitlementsForNotification.filter(e =>
            notificationsIdsList.includes(e.ID)
          );
        }
      }
    } else if (this.isOwnerEntitlementDialog) {
      this.entitlementsForOwner = response['RESULT'];
      this.selectedRecordsForOwner = this.entitlementsForOwner.filter(p => p.ID == this.ownerEntSelected);
    } else {
      this.entitlements = response['RESULT'];
      this.totalEntitlementCount = response['TOTAL_ENTITLEMENT_COUNT'];
      this.totalInactiveEntitlementCount = response['TOTAL_INACTIVE_COUNT'];
      this.totalRecords = response['TOTAL_RECORDS'];
      let count = 0;

      if (this.pageSize > 10) {
        this.rowPerPageOptions = [];
      }
      else {
        this.rowPerPageOptions = [10];
      }

      if (this.totalRecords % 10 == 0) {
        count = this.totalRecords / 10;
      } else {
        count = Math.floor(this.totalRecords / 10) + 1;
      }

      if (this.rowPerPageOptions.length > 0) {
        for (let index = 2; index <= count; index++) {
          this.rowPerPageOptions.push(index * 10);
        }
      }
      else {
        for (let index = 1; index <= count; index++) {
          this.rowPerPageOptions.push(index * 10);
        }
      }
    }
  }
  loadEntitlementStatusList() {
    this.entitlementStatusDropdownList = [];
    this.status = [];
    this.entitlementService.getEntitlementStatusList().subscribe(
      {
        next: response => {
          let enttilementStatusRes = response;
          this.entitlementStatusDropdownList = enttilementStatusRes.filter(r => r.NAME == ACTIVE || r.NAME == INACTIVE);
          this.loadFilterData(this.status, this.entitlementStatusDropdownList);
          this.getAllActiveEntitlements();
        },
        error: err => {
          this.showErrorValidations(err.error);
        }
      });
  }

  getEntitlementListWithPagination(event: LazyLoadEvent) {
    this.pageIndex = event.first / event.rows + 1;
    this.pageSize = event.rows;
    this.loadEntitlementList(null, false);
  }

  statusFilterSelect() {
    this.filteredStatusList = [];
    if (this.selectedStatus != null) {
      this.selectedStatus.forEach(status => {
        this.filteredStatusList.push(status.ID);
      });
      this.loadEntitlementList(null, false);
    }
  }

  filterOnDateRange() {
    if (this.creationPeriodRange?.[0] != null && this.creationPeriodRange?.[1] != null) {
      this.loadEntitlementList(null, false);
    }
  }

  getComponentList() {
    this.entitlementService.getComponentList().subscribe(
      {
        next: response => {
          this.componentDropdownList = response;
        },
        error: err => {
          this.showErrorValidations(err.error);
        }
      });
  }

  searchEntitlement() {
    this.componentIdsSearch = [];
    let searchArray = [];

    if (this.isNotificationEntitlementDialog) {
      searchArray = this.searchNotificationEntitlement.split(',');
    } else if (this.isOwnerEntitlementDialog) {
      searchArray = this.searchOwnerEntitlement.split(',');
    } else {
      searchArray = this.search.split(',');
    }

    for (let i = 0; i < searchArray.length; i++) {
      let compIds = this.componentDropdownList
        .filter(comp => comp.COMPONENT_NAME.toLowerCase().includes(searchArray[i].toLowerCase()))
        .map(cid => cid.PRIME_COMPONENT_ID);
      if (compIds.length > 0) {
        this.componentIdsSearch.push(...compIds);
      }
    }
    this.loadEntitlementList(null, false);
  }

  navigateToDetailScreen(entitlementData) {
    this.router.navigateByUrl('/pages/entitlementsdetails', {
      state: { entitlement: entitlementData },
      skipLocationChange: false
    });
  }


  openActiveStatusUpdateModal(id, mode, entitlementName) {
    this.actionMessage = 'Activating this entitlement will enable the access to this entitlement to the users based on its current approval status for the users.';
    this.confirmMessage = 'Are you sure you want to activate this entitlement ?';
    this.isActivateConfirmDialog = true;
    this.activityEntitlementName = entitlementName;
    this.activityEntitlementId = id;
    this.mode = mode;
  }

  async onActionConfirmationClick() {
    let entitlementIdList = [];
    this.isConfirmDialog = false;
    this.isActivateConfirmDialog = false;
    this.isDeactivateConfirmDialog = false;

    if (this.selectedRecords.length > 0) {
      let recordsSelected: any = [];

      if (this.mode == ACTIVE) {
        this.activateLabel = ACTIVATE;
        recordsSelected = this.selectedRecords.filter(entitlement => entitlement.STATUS.toString() == INACTIVE);
      } else if (this.mode == INACTIVE) {
        this.activateLabel = DEACTIVATE;
        recordsSelected = this.selectedRecords.filter(entitlement => entitlement.STATUS.toString() == ACTIVE);
      }
      recordsSelected.forEach(element => {
        entitlementIdList.push(element.ID);
      });
    } else {
      entitlementIdList.push(this.activityEntitlementId);
    }

    const formData: FormData = new FormData();

    formData.append('EntitlementIds', entitlementIdList.toString());
    formData.append('EntitlementStatus', this.mode.toString());
    this.messageFlag = true;
    this.entitlementService.updateEntitlementStatus(formData).subscribe(
      {
        next: response => {
          if (response != '0') {
            this.messageFlag = true;
            this.showSuccessValidations('Entitlement status updated successfully!');
            this.loadEntitlementList(null, false);
            this.getAllActiveEntitlements(false);
            this.confirmMessage = '';
          } else {
            this.showErrorValidations('Something went wrong, please try again!');
          }
        },
        error: error => {
          this.showErrorValidations(error);
        }
      });
  }

  cancelConfimDialog() {
    this.isActivateConfirmDialog = false;
    this.isDeactivateConfirmDialog = false;
    this.isConfirmDialog = false;
  }

  loadActivityLog(entitlementId: any, entitlementName: any, entitlementStatus: any, eventContextValue: any, entitlement: any) {
    this.activityEntitlementName = entitlementName;
    this.activityEntitlementStatus = entitlementStatus;
    this.isActivityLog = true;

    const formData: FormData = new FormData();
    formData.append('entitlementId', entitlementId);
    formData.append('contextValue', eventContextValue);
    this.activityLogList = [];
    this.activityEntitlementId = entitlement.ENTITLEMENT_ID;
    this.activityComponentId = entitlement.COMPONENT_ID;
    this.entitlementService.getEntitlementActivityLog(formData).subscribe(
      {
        next: response => {
          this.activityLogList = response;
        },
        error: error => {
          this.showErrorValidations(error.error);
        }
      });
  }
  onQuickFilterClick(day, month, year, filterIdx) {
    this.quickFilterCalendarIdx = filterIdx;
    this.setActiveFilterClass();
    const todayDate = new Date();
    const formatedDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate());
    if (this.creationPeriodRange != undefined) {
      this.creationPeriodRange[0] = this.getFilterRange(day, month, year);
      this.creationPeriodRange[1] = formatedDate;
    } else {
      this.creationPeriodRange = [];
      this.creationPeriodRange.push(this.getFilterRange(day, month, year));
      this.creationPeriodRange.push(formatedDate);
    }
    this.filterOnDateRange();
    this.dateFilter.updateInputfield();
    this.dateFilter.updateUI();
  }
  getFilterRange(day, month, year) {
    let today = new Date();
    let lastWeek = new Date(today.getFullYear() - year, today.getMonth() - month, today.getDate() - day);
    return lastWeek;
  }
  onDateRangeClear() {
    this.creationPeriodRange = null;
    this.quickFilterCalendarIdx = -1;
    this.closeFilter = true;
    this.clearSelectedList();
  }
  clearSelectedList() {
    this.isClearSelected = true;
    this.loadEntitlementList(null, false);
    this.isClearSelected = false;
  }
  addSelectedClass() {
    setTimeout(() => {
      const domNodes = document.querySelectorAll('.ui-datepicker-calendar .ui-state-highlight');
      domNodes.forEach(node => node.classList.remove('range-highlight'));
      if (this.creationPeriodRange.length === 2 && domNodes.length) {
        domNodes[0].classList.add('range-highlight');
        domNodes[domNodes.length - 1].classList.add('range-highlight');
      }
    }, 0);
  }
  removeActiveClass() {
    this.quickFilterCalendarIdx = -1;
    const allFilters: any = document.querySelectorAll('.document-library-wrapper .quick-filters-container > div');
    allFilters.forEach(element => {
      if (element.classList.contains('active')) {
        element.classList.remove('active');
      }
    });
  }
  setActiveFilterClass() {
    if (this.quickFilterCalendarIdx >= 0) {
      const allFilters: any = document.querySelectorAll('.document-library-wrapper .quick-filters-container > div');
      allFilters.forEach(element => {
        if (element.classList.contains('active')) {
          element.classList.remove('active');
        }
      });
      allFilters[this.quickFilterCalendarIdx].classList.add('active');
    } else {
      this.removeActiveClass();
    }
  }
  onRowSelectionChange(event) {
    this.isBulkActivate = false;
    this.isBulkDeactivate = false;
    this.isBulkDelete = false;
    this.isBulkUnlock = false;

    if (this.selectedRecords.length > 0) {
      this.selectedRecords = this.selectedRecords.filter((e) => {
        if (e.IS_ADMS_ENTITLEMENT != 1) {
          return true;
        }
      });
      let activeCount = this.selectedRecords.filter(entitlement => entitlement.STATUS.toString() == INACTIVE).length;
      this.isBulkActivate = activeCount > 0;

      let InActiveCount = this.selectedRecords.filter(
        entitlement => entitlement.STATUS.toString() == ACTIVE || entitlement.STATUS.toString() == LOCKED
      ).length;
      this.isBulkDeactivate = InActiveCount > 0;

      this.isBulkDelete = this.selectedRecords.length > 0;
    }
  }

  openBulkstatusUpdateModal(statusMode) {
    this.confirmMessage = '';
    this.isConfirmDialog = true;
    this.mode = statusMode;

    if (statusMode == ACTIVE) {
      this.activateLabel = ACTIVATE;
      this.ImageAlt = true;
      this.actionMessage = 'This Entitlement  will be Activated';
      this.confirmMessage = this.getConfirmationMessage(statusMode);
    } else if (statusMode == INACTIVE) {
      this.activateLabel = DEACTIVATE;
      this.ImageAlt = false;
      this.actionMessage = 'This Entitlement  will be Deactivated';
      this.confirmMessage = 'Do you want to deactivate all the selected Entitlements?';
    }
  }

  getConfirmationMessage(mode: any): string {
    let message = '';

    if (mode == ACTIVE) {
      let lockedRecords = this.selectedRecords.filter(entitlement => entitlement.STATUS.toString() == LOCKED);

      if (lockedRecords.length > 0) {
        message = 'This action will only impact the user records with ‘Inactive’ status.\n';
      }

      message += 'Do you want to activate all the selected entitlements?';
    }

    return message;
  }
  openEntitlementSidebar() {
    this.entitlementName = '';
    this.isCompPresent = true;
    this.isActiveEntitlementExist = false;
    this.isAddNewEntitlement = true;
    this.getComponentsOfUserWithOwnerShip();
    this.entitlementErrorMessage = null;
    this.clearRegisterEntitlementFields();
    this.selectedEntType = null;
    this.isDisableEntType = true;
    this.isCourceGrainedEnt = false;
  }

  async createNewEntitlement() {
    this.userCreationErrorMessage = null;
    let data: any = {};
    data['entitlementName'] = this.entitlementName.replace(/ +(?= )/g, '').trim();
    data['componentId'] = this.componentName.component_id;
    data['entitlementId'] = this.entitlementId_input;
    data['entitlementDescription'] = this.entitlementDescription.trim();
    data['ownerComponentId'] = this.ownershipComponent.PRIME_COMPONENT_ID;
    data['ownerEntitlementId'] = this.ownershipEntitlement.ENTITLEMENT_ID;
    data['ownerEntitlementName'] = this.ownershipEntitlement.master_entitlementName_withId.split("-")[1];
    data['ownerComponentName'] = this.ownershipComponent.master_componentName_withId.split("-")[1];
    data['componentName'] = this.componentName.component_name;
    data['admsEnt'] = this.selectedEntType['NAME'] == COURSE_GRAINED ? 1 : 0;
    this.messageFlag = true;
    this.entitlementService.registerEntitlement(data).subscribe(
      {
        next: (response: any) => {
          if (response.StatusCode == 0) {
            this.isActiveEntitlementExist = true;
            this.userCreationErrorMessage = 'Entitlement name already exists. Please enter a different entitlement name.';
            setTimeout(() => {
              this.userCreationErrorMessage = '';
            }, ALERT_MSG_TIME_OUT);
          } else if (response.StatusCode == 2) {
            this.isActiveEntitlementExist = false;
            this.userCreationErrorMessage = response.MESSAGE;
            setTimeout(() => {
              this.userCreationErrorMessage = '';
            }, ALERT_MSG_TIME_OUT);
          } else if (response.StatusCode == -1) {
            this.isActiveEntitlementExist = true;
            this.userCreationErrorMessage = response.MESSAGE;
            setTimeout(() => {
              this.userCreationErrorMessage = '';
            }, ALERT_MSG_TIME_OUT);
          } else {
            this.messageFlag = true;
            this.showSuccessValidations('Entitlement created successfully!');
            this.isActiveEntitlementExist = false;
            this.isAddNewEntitlement = false;
            this.clearRegisterEntitlementFields();
            this.loadEntitlementList(null, false);
            this.getAllActiveEntitlements(false);
          }
        },
        error: error => {
          this.showErrorValidations(error.error);
        }
      });
  }

  clearRegisterEntitlementFields() {
    this.entitlementName = '';
    this.componentName = '';
    this.entitlementId_input = '';
    this.ownershipComponent = '';
    this.ownershipEntitlement = '';
    this.isValidEntitlementId = false;
    this.entitlementDescription = '';
  }

  async onNotificationComponentClicked(id, notificationIds) {
    this.selectedRecordsForNotification = [];
    this.notificationEntitlementId = id;
    this.isNotificationEntitlementDialog = true;
    await this.loadEntitlementList(notificationIds, true);
  }

  OnNotificationEntitlementDialogClose() {
    this.searchNotificationEntitlement = '';
    this.isNotificationEntitlementDialog = false;
  }

  onNotificationSearchComponentClicked() {
    this.searchEntitlement();
  }

  onNotificationEntitlementSelected() {
    this.searchNotificationEntitlement = '';
    let notificationIdList = [];
    this.isNotificationEntitlementDialog = false;
    let filtered = this.entitlements.filter(e => e.ID == this.notificationEntitlementId)[0];

    if (this.selectedRecordsForNotification.length > 0) {
      this.selectedRecordsForNotification.forEach(element => {
        notificationIdList.push(element.ID.split('-')[1]);
      });
    }
    filtered['NOTIFICATION_ENTITLEMENT_ID'] = notificationIdList;
    this.messageFlag = true;
    this.entitlementService.assignNotificationEntitlementInfo(filtered).subscribe(
      {
        next: data => {
          this.loadEntitlementList(null, false);
        },
        error: err => {
          this.showErrorValidations(err.error);
        }
      });
  }

  onOwnerComponentClicked(id, ownerEntId) {
    this.searchOwnerEntitlement = '';
    this.ownerEntitlementId = id;
    this.isOwnerEntitlementDialog = true;
    this.ownerEntSelected = ownerEntId;

    if (this.searchOwnerEntitlement.length == 0) {
      this.loadEntitlementList(null, false);
    }
  }

  OnOwnerEntitlementDialogClose() {
    this.isOwnerEntitlementDialog = false;
  }

  onOwnerSearchComponentClicked() {
    this.searchEntitlement();
  }

  onOwnerEntitlementSelected() {
    this.isOwnerEntitlementDialog = false;
    let filtered = this.entitlements.filter(e => e.ID == this.ownerEntitlementId)[0];
    if (this.selectedRecordsForOwner.length > 0 && this.selectedRecordsForOwner[0] !== undefined) {
      let filteredRecord = JSON.parse(JSON.stringify(filtered));
      filteredRecord['OWNER_ENTITLEMENT_ID'] = this.selectedRecordsForOwner[0].ID;
      this.entitlementService.assignOwnerEntitlementInfo(filteredRecord).subscribe(
        {
          next: data => {
            if (this.selectedRecordsForOwner.length > 0 && this.selectedRecordsForOwner[0] !== undefined) {
              filtered['IS_APPROVAL_PENDING'] = 1;
            }
          },
          error: err => {
            this.showErrorValidations(err.error);
          }
        });
    }
  }

  openFilterSideBar() {
    if (this.status.length == 0) {
      this.loadEntitlementStatusList();
    }
    if (this.componentData.length == 0) {
      this.loadComponentList();
    }
    if (this.entitlementData.length == 0) {
      this.loadEntitlementsList();
    }
  }

  loadComponentList() {
    this.loadFilterData(this.componentData, this.componentDropdownList);
  }

  loadEntitlementsList() {
    this.entitlementDropdownList = [];
    let data = {};
    this.ownerEntData = [];
    const parentNode = {};

    this.entitlementService.getEntitlementData(data).subscribe(
      {
        next: response => {
          this.entitlementDropdownList = response['entitlementDataList'];
          this.ownerEntDropdownList = [];

          if (this.ownerEntData.length == 0) {
            parentNode['label'] = 'My Ownerships';
            this.ownerEntData.push(parentNode);
          }

          this.loadFilterData(this.entitlementData, this.entitlementDropdownList);
          if (this.componentDetails.length > ZERO) {
            this.selectedComponent = this.componentData.filter(a => a['data']['NAME'] == this.componentDetails[0].data['NAME']);
            this.componentDetails = [];
          }
        },
        error: err => {
          this.showErrorValidations(err.error);
        }
      });
  }

  loadFilterData(mainFilterList: any, dimensionList: any) {
    this.appliedOwnerEntitlement = this.selectedOwnerEntitlement;

    dimensionList.forEach(element => {
      const parentNode = {
        data: element,
        children: [],
        selectable: true,
        expanded: true,
        key: element.ID == 0 ? '0' : element.ID
      };
      if (element.hasOwnProperty('COMP_NAME_WITH_ID')) {
        parentNode['label'] = element.COMP_NAME_WITH_ID;
        parentNode['key'] = element.COMPONENT_ID;
      } else if (element.hasOwnProperty('ENTITLEMENT_NAME')) {
        parentNode['label'] = element.ENTITLEMENT_ID + '-' + element.NAME_WITH_ADMS;
      } else {
        parentNode['label'] = element.NAME;
        parentNode['key'] = element.ID == 0 ? '0' : element.ID;
      }
      mainFilterList.push(parentNode);
    });
  }

  applyFilter() {
    this.isFilterSelection = false;

    this.appliedUserStatus = this.selectedStatus;
    this.appliedEntilement = this.selectedEntilement;
    this.appliedOwnerEntitlement = this.selectedOwnerEntitlement;
    this.appliedNotification = this.selectedNotification;
    this.appliedDependent = this.selectedDependent;
    this.appliedComponent = this.selectedComponent;

    this.loadEntitlementList(null, false);
  }

  setActiveTab(activeTabName: any) {
    this.activeTab.isComponentTab = false;
    this.activeTab.isEntitlementTab = false;
    this.activeTab.isOwnerEntitlementTab = false;
    this.activeTab.isNotificationEntTab = false;
    this.activeTab.isDependenetEntitlementTab = false;
    this.activeTab.isStatusTab = false;

    if (activeTabName == ENTITLEMENT_STATUS) {
      this.activeTab.isStatusTab = true;
    } else if (activeTabName == ENTITLEMENT_NAME) {
      this.activeTab.isEntitlementTab = true;
    } else if (activeTabName == OWNER_ENTITLEMENT) {
      this.activeTab.isOwnerEntitlementTab = true;
    } else if (activeTabName == ENTITLEMENT_NOTIFICATIION) {
      this.activeTab.isNotificationEntTab = true;
    } else if (activeTabName == ENTITLEMENT_DEPENDENT) {
      this.activeTab.isDependenetEntitlementTab = true;
    } else if (activeTabName == ENTITLEMENT_COMPONENT) {
      this.activeTab.isComponentTab = true;
    }

    this.openFilterSideBar();
  }

  clearAllFilters() {
    this.isClearAllSelected = true;
    this.selectedStatus = [];
    this.selectedEntilement = [];
    this.selectedOwnerEntitlement = [];
    this.selectedNotification = [];
    this.selectedDependent = [];
    this.selectedComponent = [];
    this.search = null;
    this.appliedSearch = null;
    this.componentDetails = [];
    this.activeTab = {
      isComponentTab: false,
      isEntitlementTab: false,
      isOwnerEntitlementTab: false,
      isNotificationEntTab: false,
      isDependenetEntitlementTab: false,
      isStatusTab: false
    };

    this.applyFilter();
    this.isClearAllSelected = false;
  }

  cancelFilterSection() {
    this.isFilterSelection = false;

    this.selectedStatus = [];
    this.selectedEntilement = [];
    this.selectedOwnerEntitlement = [];
    this.selectedNotification = [];
    this.selectedDependent = [];
    this.selectedComponent = [];

    this.activeTab = {
      isComponentTab: false,
      isEntitlementTab: false,
      isOwnerEntitlementTab: false,
      isNotificationEntTab: false,
      isDependenetEntitlementTab: false,
      isStatusTab: false
    };
  }

  onOwnerSelectionChange() {
    const choosen = this.selectedRecordsForOwner[this.selectedRecordsForOwner.length - 1];
    this.selectedRecordsForOwner = [];
    if (choosen != undefined) {
      this.selectedRecordsForOwner.push(choosen);
    }
  }

  getAllActiveEntitlements(callOpenEditApprovers?: boolean) {
    this.activeStatusId = this.entitlementStatusDropdownList.filter(t => t.NAME == ACTIVE)[0].ID;
    let filter: any = {};
    filter['STATUS_IDS'] = [this.activeStatusId];
    this.entitlementService.getEntitlementList(filter).subscribe(
      {
        next: response => {
          this.allEntitlements = response['RESULT'];
          this.sourceEnt = this.allEntitlements;
          this.allActiveEntitlements = response['RESULT'].slice(0);
          if (callOpenEditApprovers) {
            this.openEditApproversDialog(this.selectedEntilementToApprove);
          }
        },
        error: err => {
          this.showErrorValidations(err.error);
        }
      });
  }

  openAddApproversDialog(entitlement) {
    this.selectedIndex = 0;
    this.isNoApprovalRequired = false;
    this.addLevelTab = [];
    this.selectedEntilementToApprove = [];
    this.sourceEntitlement = [];
    this.targetEntitlement = [];
    this.addLevelTab.push(1);
    this.sourceEntitlement[0] = this.allEntitlements;
    this.targetEntitlement[0] = [];

    this.selectedEntilementToApprove = entitlement;
    this.isAddApproverDialog = true;
    this.isSubmitBtnDisabled = false;
    this.isEntitlementsDeleted = false;
  }

  addLevel() {
    let insertedLevelIndex = this.addLevelTab.length;
    let insertedLevel = insertedLevelIndex + 1;
    this.addLevelTab.push(insertedLevel);
    this.sourceEntitlement[insertedLevelIndex] = this.sourceEnt;
    this.targetEntitlement[insertedLevelIndex] = [];
    this.selectedIndex = insertedLevelIndex;
  }

  submitApprovals() {
    this.isSubmitBtnDisabled = true;
    let isApprovalRequired=0;
    if(this.targetEntitlement[0].length>0){
      isApprovalRequired = 1;
    }
    if (!this.isNoApprovalRequired) {
      this.targetEntitlement = [];
      this.addLevelTab = [];
      this.sourceEntitlement = [];
      isApprovalRequired = 0;
      this.isSubmitBtnDisabled = false;
      this.levelEventData = [];
    }
    let obj = {
      COMPONENT_ID: this.selectedEntilementToApprove.COMPONENT_ID,
      ENTITLEMENT_ID: this.selectedEntilementToApprove.ENTITLEMENT_ID,
      ENTITLEMENT_NAME: this.selectedEntilementToApprove.ENTITLEMENT_NAME,
      ID: this.selectedEntilementToApprove.ID,
      APPROVAL_ENTITLEMENTS: this.targetEntitlement,
      IS_APPROVAL_REQUIRED: isApprovalRequired
    };
    let ids: any[] = [];
    for (const entitlements of this.targetEntitlement) {
      for (const e of entitlements) {
        ids.push(e.ID);
      }
    }
    if (this.isNoApprovalRequired && ids.length > 0) {
      this.checkIfEntitlementsIsDeleted(ids, obj, isApprovalRequired);
    } else {
      this.saveEntitlementApprovals(obj, isApprovalRequired);
    }
  }

  private checkIfEntitlementsIsDeleted(ids: any, obj: any, isApprovalRequired: any) {
    this.entitlementService.checkEntitlementsIsDeleted(ids).subscribe(
      {
        next: response => {
          if (response) {
            this.isEntitlementsDeleted = true;
          } else {
            this.saveEntitlementApprovals(obj, isApprovalRequired);
          }
        },
        error: err => {
          this.showErrorValidations(err.error);
        }
      });
  }

  private saveEntitlementApprovals(obj: any, isApprovalRequired: number) {
    obj.levelEventData = this.levelEventData;
    obj.isNoApprovalRequiredPrevVal = this.isNoApprovalRequiredPrevVal;
    this.messageFlag = true;
    this.entitlementService.addApprovalsForEntitlement(obj).subscribe(
      {
        next: response => {
          this.selectedEntilementToApprove.IS_APPROVAL_REQUIRED = isApprovalRequired;
          this.isAddApproverDialog = false;
          this.isEditApproverDialog = false;
          this.isSubmitBtnDisabled = true;
          if (isApprovalRequired == 1) {
            this.selectedEntilementToApprove.IS_APPROVAL_TREE = 1;
            this.messageFlag = true;
            this.showSuccessValidations('Approval levels updated successfully');
          } else {
            this.messageFlag = true;
            this.showSuccessValidations('No Approvals required is set successfully');
          }
        },
        error: err => {
          this.showErrorValidations(err.error);
        }
      });
  }

  OnAddApprovalDialogClose() {
    this.targetEntitlement = [];
    this.sourceEntitlement = [];
    this.addLevelTab = [];
    this.isApprovalLevelDelete = false;
    this.isNoApprovalRequired = false;
    this.isAddApproverDialog = false;
    this.isSubmitBtnDisabled = false;
  }

  confirmApprovalLevelDelete(event) {
    if (this.addLevelTab.length > 1) {
      this.approvalEvent = event;

      if (this.targetEntitlement[event.index].length > 0) {
        this.isApprovalLevelDelete = true;
        this.approvalMessage = 'Are you sure you want to delete this level?';
      } else {
        this.deleteApprovalTab();
      }
    }
    this.isSubmitBtnDisabled = false;
  }

  deleteApprovalTab() {
    let index = this.approvalEvent.index;
    if (this.addLevelTab.length > 1) {
      this.approvalEvent.close();
      this.allEntitlements.push(...this.targetEntitlement[index]);
      let entitlements = this.targetEntitlement[index].filter(ent => ent.ENTITLEMENT_STATUS_ID === this.activeStatusId);
      this.sourceEnt.push(...entitlements);
      this.sourceEntitlement[index == 0 ? index + 1 : index - 1] = this.sourceEnt;
      this.sourceEntitlement.splice(index, 1);
      this.targetEntitlement.splice(index, 1);
      this.addLevelTab.splice(index, 1);
      if (index != 0) {
        this.selectedIndex = index - 1;
      } else {
        this.selectedIndex = 0;
      }
    }
    this.isApprovalLevelDelete = false;
  }

  deleteEditApprovalTab() {
    let index = this.approvalEvent.index;
    if (this.addLevelTab.length > 1) {
      this.approvalEvent.close();
      let removeLevelVal = 'Level ' + (index + 1) + '-(' + this.addLevelTab.length + ')'
      this.addLevelTab.splice(index, 1);
      this.sourceEnt.push(...this.targetEntitlement[index]);
      let entitlements = this.targetEntitlement[index].filter(ent => ent.ENTITLEMENT_STATUS_ID === this.activeStatusId);
      this.sourceEnt.push(...entitlements);
      this.sourceEntitlement[index == 0 ? index + 1 : index - 1] = this.sourceEnt;
      this.sourceEntitlement.splice(index, 1);
      this.targetEntitlement.splice(index, 1);
      this.levelEventData.push({ action: ActionType.REMOVE, LevelNo: index + 1, value: removeLevelVal, levelActions: [] })

      this.selectedIndex = index != 0 ? index - 1 : 0;
    }
    this.isApprovalLevelDelete = false;
    if (this.targetEntitlement.length == ONE && this.targetEntitlement[0].length == ZERO) {
      this.isNoApprovalRequired = true;
    }
  }

  discardAddApproval() {
    this.sourceEntitlement = [];
    this.targetEntitlement = [];
    this.isNoApprovalRequired = false;
    this.isAddApproverDialog = false;
    this.isSubmitBtnDisabled = false;
  }

  getLevelViewApprovals(event) {
    this.levelId = event.index + 1;
    this.viewApprovalList = this.approvalListResponse.filter(app => app.LEVEL_NO == this.levelId);
  }

  onViewApprovalClicked(
    id: any,
    entitlementId: any,
    entitlementName: any,
    isAdmsEntitlement: any,
    componentId: any,
    entitlementStatus: any,
    componentName
  ) {
    
    this.approvalEntitlementId = entitlementId;
    this.approvalComponentId = componentId;
    this.approvalEntitlementName = entitlementName;
    (this.isAdmsEntitlement = isAdmsEntitlement); (this.approvalEntitlementStatus = entitlementStatus);
    this.approvalComponentName = componentName;
    this.isviewApprovalsDialog = true;
    this.viewApprovalList = [];
    this.isNoApprovalRequired = false;
    this.isSubmitBtnDisabled = true;
    const formData: FormData = new FormData();

    formData.append('entitlementId', entitlementId);
    formData.append('componentId', componentId);
    formData.append('entitlementName', entitlementName);
    this.entitlementService.getViewApprovalList(formData).subscribe(
      {
        next: response => {
          this.levelId = 1;
          this.approvalListResponse = response;
          this.viewApprovalList = this.approvalListResponse.filter(app => app.LEVEL_NO == this.levelId);

          this.levelTab = [];
          this.approvalListResponse.forEach(l => {
            if (!this.levelTab.includes('Level' + ' ' + l.LEVEL_NO)) {
              this.levelTab.push('Level' + ' ' + l.LEVEL_NO);
            }
          });

          if (this.viewApprovalList.length == 0) {
            this.isNoApprovalRequired = true;
            this.isSubmitBtnDisabled = true;
          }
          this.isviewApprovalsDialog = true;
        },
        error: error => {
          this.showErrorValidations(error.error);
        }
      });
  }

  OnViewApprovalDialogClose() {
    this.isviewApprovalsDialog = false;
  }

  validateLastItemInTarget(index, event) {
    this.isApprovalLevelDelete = false;
    this.addApprovalTabIndex = index;
    this.lastDeletedItemsInLevel = event.items;
    if (this.targetEntitlement[index].length == 0) {
      this.approvalMessage =
        'You are deleting the last item in the level ' +
        (index + 1) +
        '. This will delete the level ' +
        (index + 1) +
        ', Do you want to proceed?';
      if (this.addLevelTab.length > 1) {
        this.isApprovalLastItemDelete = true;
      }
    }
    this.isSubmitBtnDisabled = false;

    if (this.targetEntitlement.length == ONE && this.targetEntitlement[0].length == ZERO) {
      this.isNoApprovalRequired = true;
    }
  }

  deleteEditApprovalNo() {
    this.isApprovalLevelDelete = false;
    let tarID = this.lastDeletedItemsInLevel.map(app => app.ID);
    this.sourceEnt = this.sourceEnt.filter(ent => !tarID.includes(ent.ID));
  }

  deleteLevelOnLastItemConfirmation() {
    this.levelEventData.push({ action: ActionType.REMOVE, LevelNo: this.addApprovalTabIndex + 1, value: 'Level ' + (this.addApprovalTabIndex + 1) + '-(' + this.addLevelTab.length + ')', levelActions: [] })
    this.allEntitlements.push(...this.targetEntitlement[this.addApprovalTabIndex]);
    let entitlements = this.targetEntitlement[this.addApprovalTabIndex].filter(ent => ent.ENTITLEMENT_STATUS_ID === this.activeStatusId);
    this.sourceEnt.push(...entitlements);
    this.sourceEntitlement.splice(this.addApprovalTabIndex, 1);
    this.targetEntitlement.splice(this.addApprovalTabIndex, 1);
    this.addLevelTab.splice(this.addApprovalTabIndex, 1);
    this.sourceEntitlement[0] = this.sourceEnt;
    this.isSubmitBtnDisabled = false;
    setTimeout(() => {
      this.selectedIndex = 0;
    });
    this.isApprovalLastItemDelete = false;
  }

  retrieveTheLevelItems() {
    this.isApprovalLevelDelete = false;
    let tarID = this.lastDeletedItemsInLevel.map(app => app.ID);
    this.sourceEnt = this.sourceEnt.filter(ent => !tarID.includes(ent.ID));
    this.sourceEntitlement[this.addApprovalTabIndex] = this.sourceEnt;
    this.isApprovalLastItemDelete = false;
    this.targetEntitlement[this.addApprovalTabIndex] = this.lastDeletedItemsInLevel.slice(0);
    this.lastDeletedItemsInLevel = [];
    this.isSubmitBtnDisabled = false;
  }
  loadNextPaginatorGrid(event) {
    this.isEntPageChanged = true;
    this.pageIndex = event.first / event.rows + 1;
    this.currentPage = event.first / event.rows + 1;
    this.pageSize = event.rows;
  }
  onPageInputChange(event) {
    if (this.currentPage && this.currentPage != this.pageIndex) {
      if (this.currentPage > event.pageCount) {
        this.currentPage = Math.floor(this.currentPage / 10);
        return;
      }

      this.pageSize = event.rows;
      this.pageIndex = this.currentPage;
      this.table.first = Math.ceil(event.totalRecords / event.pageCount) * (this.currentPage - 1);
      this.table.firstChange.emit(this.table.first);
      this.table.onLazyLoad.emit(this.table.createLazyLoadMetadata());
      this.loadEntitlementList(null, null);
    } else {
      return;
    }
  }

  discardOwnerFilter(event, element) {
    element.hide(event);
  }

  async openEditApproversDialog(entitlement) {
    this.selectedIndex = 0;
    this.addLevelTab = [];
    this.levelEventData = [];
    this.selectedEntilementToApprove = [];
    this.sourceEntitlement = [];
    this.targetEntitlement = [];
    //this.isNoApprovalRequired = false;
    this.isNoApprovalRequiredPrevVal = false;
    this.addLevelTab.push(1);
    await this.getLevelData(entitlement.ENTITLEMENT_ID, entitlement.COMPONENT_ID, entitlement.ID, entitlement.ENTITLEMENT_NAME);
    this.selectedEntilementToApprove = entitlement;
    this.isEditApproverDialog = true;
    this.isSubmitBtnDisabled = true;
    this.isEntitlementsDeleted = false;
  }

  editAddLevel() {
    let insertedLevelIndex = this.addLevelTab.length;
    this.addLevelTab.push(this.addLevelTab[this.addLevelTab.length - 1] + 1);
    this.levelEventData.push({ action: ActionType.ADD, LevelNo: insertedLevelIndex + 1, value: 'Level ' + (insertedLevelIndex + 1), levelActions: [] });
    this.sourceEntitlement[insertedLevelIndex] = this.sourceEnt;
    this.targetEntitlement[insertedLevelIndex] = [];
    this.selectedIndex = insertedLevelIndex;
    this.isSubmitBtnDisabled = false;
  }

  getLevelApprovals(event) {
    this.sourceEntitlement[event.index] = this.sourceEnt;
    this.levelId = event.index + 1;
    this.levelWiseList = this.levelWiseResponse.filter(app => app.LEVEL_NO == this.levelId);
  }

  async getLevelData(entitlementId: any, componentId: any, entId: any, entitlementName: any) {
    this.levelWiseList = [];
    this.tarID = [];
    this.isSubmitBtnDisabled = true;
    const formData: FormData = new FormData();

    formData.append('entitlementId', entitlementId);
    formData.append('componentId', componentId);
    formData.append('entitlementName', entitlementName);
    this.entitlementService.getViewApprovalList(formData).subscribe(
      {
        next: response => {
          this.isNoApprovalRequired = true;
          if (response.length > 0) {
            this.levelId = 1;
            this.levelWiseResponse = response;
            this.levelWiseList = this.levelWiseResponse.filter(app => app.LEVEL_NO == this.levelId);
            this.targetEntitlement[0] = this.levelWiseList;

            this.addLevelTab = [];
            this.levelWiseResponse.forEach(l => {
              if (!this.addLevelTab.includes(l.LEVEL_NO)) {
                this.addLevelTab.push(l.LEVEL_NO);
              }
            });

            this.sourceEnt = [];
            this.sourceEntitlement = [];

            this.tarID = this.levelWiseResponse.map(app => app.ID);

            if (this.tarID.length > 0) {
              this.sourceEnt = this.allEntitlements.filter(ent => !this.tarID.includes(ent.ID) && ent.ID != entId);
            }

            for (let i = 0; i < this.addLevelTab.length; i++) {
              this.targetEntitlement[i] = this.levelWiseResponse.filter(app => app.LEVEL_NO == this.addLevelTab[i]);
              this.sourceEntitlement[i] = this.sourceEnt;
            }
          } else {
            this.sourceEnt = this.allEntitlements.slice(0);
            this.sourceEnt = this.sourceEnt.filter(ent => ent.ID != entId);
            this.sourceEntitlement[0] = this.sourceEnt;
            this.targetEntitlement[0] = [];
            this.isNoApprovalRequired = false;
            this.isSubmitBtnDisabled = true;
            this.isNoApprovalRequiredPrevVal = true;
          }
        },
        error: error => {
          this.messageFlag = true;
          this.showErrorValidations(error.error);
        }
      });
  }

  OnEditApprovalDialogClose() {
    this.targetEntitlement = [];
    this.sourceEntitlement = [];
    this.addLevelTab = [];
    this.isApprovalLevelDelete = false;
    this.isEditApproverDialog = false;
  }

  discardEditApproval() {
    this.isEditApproverDialog = false;
    this.isApprovalLevelDelete = false;
    this.isApprovalLastItemDelete = false;
  }

  openOwnerEntitlementFilterSideBar() {
    if (this.componentData.length == 0) {
      this.loadComponentList();
    }
    if (this.entitlementData.length == 0) {
      this.loadEntitlementsList();
    }
  }

  applyOwnerFilterFilter() {
    this.isFilterSelection = false;

    this.appliedOwnerEntilement = this.selectedEntilement;
    this.appliedOwnerComponent = this.selectedComponent;

    this.loadOwnerEntitlementList();
  }

  async loadOwnerEntitlementList() {
    let filter: any = {};
    this.entitlementsForOwner = [];
    if (this.selectedComponent && this.selectedComponent.length > 0) {
      let componentIds = [];
      this.selectedComponent.forEach(element => {
        componentIds.push(element.data.COMPONENT_ID);
      });
      filter['COMPONENT_ID_FILTER'] = componentIds;
    }

    if (this.selectedEntilement && this.selectedEntilement.length > 0) {
      let entIds = [];
      this.selectedEntilement.forEach(element => {
        entIds.push(element.data['ENTITLEMENT_ID']);
      });
      filter['ENTITLEMENT_ID_FILTER'] = entIds;
    }

    this.entitlementService.getEntitlementList(filter).subscribe(
      {
        next: response => {
          this.entitlementsForOwner = response['RESULT'];
        },
        error: err => {
          this.showErrorValidations(err.error);
        }
      });
  }
  setOwnerEntitlementActiveTab(activeTabName: any) {
    this.activeTab.isComponentTab = false;
    this.activeTab.isEntitlementTab = false;

    if (activeTabName == ENTITLEMENT_NAME) {
      this.activeTab.isEntitlementTab = true;
    } else if (activeTabName == ENTITLEMENT_COMPONENT) {
      this.activeTab.isComponentTab = true;
    }

    this.openOwnerEntitlementFilterSideBar();
  }

  clearAllOwnerEntitlementFilters() {
    this.selectedEntilement = [];
    this.selectedComponent = [];

    this.activeTab = {
      isComponentTab: false,
      isEntitlementTab: false
    };

    this.applyOwnerFilterFilter();
  }

  onChangeEvent() {
    if (this.isNoApprovalRequired) {
      this.isSubmitBtnDisabled = false;
    } else {
      this.isSubmitBtnDisabled = true;
    }
  }

  sidebarCloseEvent() {
    if (
      this.appliedComponent.length != this.selectedComponent.length ||
      this.appliedEntilement.length != this.selectedEntilement.length ||
      this.appliedOwnerEntitlement.length != this.selectedOwnerEntitlement.length ||
      this.appliedUserStatus.length != this.selectedStatus.length
    ) {
      this.selectedComponent = this.appliedComponent;
      this.selectedEntilement = this.appliedEntilement;
      this.selectedOwnerEntitlement = this.appliedOwnerEntitlement;
      this.selectedStatus = this.appliedUserStatus;
    }
    if (this.selectedComponent.length == 0) {
      this.activeTab.isComponentTab = false;
    }
    if (this.selectedEntilement.length == 0) {
      this.activeTab.isEntitlementTab = false;
    }
    if (this.selectedOwnerEntitlement.length == 0) {
      this.activeTab.isOwnerEntitlementTab = false;
    }
    if (this.selectedStatus.length == 0) {
      this.activeTab.isStatusTab = false;
    }
  }

  getDefaultOwnersByComponentId() {
    this.isCompPresent = true;
    this.selectedEntType = null;
    this.isCourceGrainedEnt = false
    this.isDisableEntType = this.componentName['courseGrainedEnt'] != 0 ? true : false;//NOSONAR
    if (this.isDisableEntType) {
      this.isCompPresent = false;
      this.selectedEntType = this.entTypeList.filter(p => p.NAME == FINE_GRAINED)[0];
    }
    this.entitlementService.getDefaultOwnerListByComponentId(this.componentName.component_id).subscribe(
      {
        next: response => {
          this.defaultOwner = response[0];
          this.ownershipComponent = this.allActiveComponents.filter(p => p.PRIME_COMPONENT_ID == this.defaultOwner.OWNER_COMPONENT_ID)[0];
          this.getActiveEntitlementsByComponentId(this.defaultOwner.OWNER_COMPONENT_ID);
          this.getActiveEditEntitlementsByComponentId(this.defaultOwner.OWNER_COMPONENT_ID);
          this.entitlementId_input = '';
          this.entitlementName = '';
          this.entitlementDescription = '';
        },
        error: error => {
          this.showErrorValidations(error.error);
        }
      });
  }

  getComponentsOfUserWithOwnerShip() {
    this.entitlementService.getComponentsOfUserWithOwnerShip().subscribe(
      {
        next: response => {
          this.componentDropdownList = response;
        },
        error: err => {
          this.showErrorValidations(err.error);
        }
      });
  }

  verifyComponentAndEntitlementCombination() {
    this.entitlementService
      .verifyComponentAndEntitlementCombination(this.componentName.component_id, this.entitlementId_input)
      .subscribe(
        {
          next: response => {
            this.isValidEntitlementId = response;
            if (!this.isValidEntitlementId) {
              this.entitlementErrorMessage = 'Entitlement ID is already exists';
              setTimeout(() => {
                this.entitlementErrorMessage = null;
              }, ALERT_MSG_TIME_OUT);
            } else {
              this.entitlementErrorMessage = null;
            }
          },
          error: err => {
            this.showErrorValidations(err.error);
          }
        });
  }

  enttitlement_inputBox = document.getElementById('ent_id');

  if(enttitlement_inputBox) {
    enttitlement_inputBox.addEventListener('click', this.onKeySearch, false);
  }

  onKeySearch(event) {
    let inputBox = document.getElementById('ent_id');
    inputBox.addEventListener('keydown', function (e) {
      if (ENTITLEMENT_ID_REGEXP.includes(e.key)) {
        e.preventDefault();
      }
    });
    if (event.keyCode === ENTER_KEY_CODE && this.entitlementId_input != null) {
      this.verifyComponentAndEntitlementCombination();
    }
  }

  onBlurEventCalled() {
    if (this.entitlementId_input) this.verifyComponentAndEntitlementCombination();
  }

  getAllActiveComponents() {
    let data = {};
    this.entitlementService.getAllActiveComponents(data).subscribe(
      response => {
        this.allActiveComponents = response;
      }
    );
  }

  getActiveEditEntitlementsByComponentId(componentId) {
    this.entitlementService.getActiveEntitlementsByComponentId(componentId).subscribe(
      {
        next: response => {
          this.activeEntitlementsOfComponent = response;
          this.ownershipEntitlement = this.activeEntitlementsOfComponent.filter(
            x => x.ID.toString() == this.ownershipEntitlementId.toString()
          )[0];

          this.activeEntitlementsOfComponent = this.activeEntitlementsOfComponent.filter(
            x => x.ENTITLEMENT_ID.toString() !== this.entitlementId_input.toString()
          );

          this.oldOwnershipEntitlement = this.ownershipEntitlement.master_entitlementName_withId;
          this.ownershipEntitlementNameOldVal = this.ownershipEntitlement.master_entitlementName_withId;
          this.ownershipComponentNameOldVal = this.ownershipComponent.master_componentName_withId;
        },
        error: err => {
          this.showErrorValidations(err.error);
        }
      });
  }

  getActiveEntitlementsByComponentId(componentId) {
    this.entitlementService.getActiveEntitlementsByComponentId(componentId).subscribe(
      {
        next: response => {
          this.activeEntitlementsOfComponent = response;
          this.ownershipEntitlement = this.activeEntitlementsOfComponent.filter(
            p => p.ID === this.defaultOwner.OWNER_ENTITLEMENT_ID
          )[0];
        },
        error: err => {
          this.showErrorValidations(err.error);
        }
      });
  }

  onChangeCompoent(event) {
    this.ownershipComponent = event.value;
    if (this.oldOwnershipComponent != this.ownershipComponent.master_componentName_withId) {
      this.ownershipComponentApprovalFlag = 1;
    }
    else {
      this.ownershipComponentApprovalFlag = 0;
    }
    this.getActiveEntitlementsByComponentId(this.ownershipComponent.PRIME_COMPONENT_ID);
  }

  onChangeEditCompoent(event) {
    this.ownershipComponent = event.value;
    this.getActiveEditEntitlementsByComponentId(this.ownershipComponent.COMPONENT_ID);
  }

  onChangeEntitlement(event) {
    this.ownershipEntitlement = event.value;
    if (this.oldOwnershipEntitlement != this.ownershipEntitlement.master_entitlementName_withId) {
      this.ownershipEntitlementApprovalFlag = 1;
    }
    else {
      this.ownershipEntitlementApprovalFlag = 0;
    }
  }

  getDefaultOwnersByComponentIdForEditEnt(editComponentId) {
    this.entitlementService.getDefaultOwnerListByComponentId(editComponentId).subscribe(
      {
        next: response => {
          this.defaultOwner = response[0];
          this.getActiveEditEntitlementsByComponentId(this.defaultOwner.COMPONENT_ID);
        },
        error: error => {
          this.showErrorValidations(error.error);
        }
      });
  }

  openEditEntitlementDialog(entitlement) {
    this.selectedEntType = null;
    this.editComponentDropdownList = [];
    this.ownershipEntitlement = [];
    this.ownershipComponent = [];
    this.isEditEntitlement = true;
    let obj = {
      component_id: entitlement.COMPONENT_ID,
      NAME: entitlement.COMPONENT_ID + '-' + entitlement.COMPONENT_NAME
    };
    this.editComponentDropdownList.push(obj);
    this.entitlementId_input = entitlement.ENTITLEMENT_ID;
    this.editComponentId = entitlement.COMPONENT_ID;
    this.ownershipEntitlementId = entitlement.OWNER_ENTITLEMENT_ID;
    this.entitlementDescription = entitlement.DESCRIPTION;
    this.IS_APPROVAL_PENDING = entitlement.IS_APPROVAL_PENDING;
    this.isAdmsEntitlement = entitlement.IS_ADMS_ENTITLEMENT;
    this.entitlementName = entitlement.ENTITLEMENT_NAME;
    this.editEntId = entitlement.ID;

    this.selectedEntType = this.isAdmsEntitlement == 1 ? this.entTypeList.filter(p => p.NAME == COURSE_GRAINED)[0] : this.entTypeList.filter(p => p.NAME == FINE_GRAINED)[0];
    this.entitlementNameOldVal = this.entitlementName;
    this.entitlementDescriptionOldVal = this.entitlementDescription;

    if (entitlement.hasOwnProperty('OWNER_ENTITLEMENT_NAME')) {
      this.ownershipComponent = this.allActiveComponents.filter(
        x =>
          x.PRIME_COMPONENT_ID.toString() ==
          entitlement.OWNER_ENTITLEMENT_NAME.substring(0, entitlement.OWNER_ENTITLEMENT_NAME.indexOf('-'))
            .trim()
            .toString()
      )[0];
      this.getDefaultOwnersByComponentIdForEditEnt(this.ownershipComponent.PRIME_COMPONENT_ID);
    } else {
      this.ownershipComponent = [];
      this.ownershipComponentList = this.allActiveComponents.filter(x => x.PRIME_COMPONENT_ID)[0];
      this.getDefaultOwnersByComponentIdForEditEnt(this.ownershipComponentList.PRIME_COMPONENT_ID);
    }
    this.oldOwnershipComponent = this.ownershipComponent.master_componentName_withId;
  }

  updateEntitlement() {
    let data: any = {};
    data['entitlementName'] = this.entitlementName.replace(/ +(?= )/g, '').trim();
    data['componentId'] = this.editComponentId;
    data['entitlementId'] = this.entitlementId_input;
    data['entitlementDescription'] = this.entitlementDescription;
    data['ownerEntitlementId'] = this.ownershipEntitlement.ID;
    data['entId'] = this.editEntId;
    data['entitlementNameOldVal'] = this.entitlementNameOldVal;
    data['entitlementDescriptionOldVal'] = this.entitlementDescriptionOldVal;
    data['ownershipEntitlementNameOldVal'] = this.ownershipEntitlementNameOldVal;
    data['ownershipComponentNameOldVal'] = this.ownershipComponentNameOldVal;
    data['ownershipEntitlementName'] = this.ownershipEntitlement.master_entitlementName_withId.split("-")[1];
    data['ownershipComponentName'] = this.ownershipComponent.master_componentName_withId.split("-")[1];
    data['componentName'] = this.componentName?.NAME?.NAME?.split("-")[1];
    data['ownershipEntitlementNewName'] = this.ownershipEntitlement.master_entitlementName_withId;
    data['ownershipComponentNewName'] = this.ownershipComponent.master_componentName_withId;
    this.messageFlag = true;
    this.entitlementService.updateEntitlement(data).subscribe(
      {
        next: (response: any) => {
          if (response.StatusCode == 1) {
            this.showErrorValidations(response.MESSAGE);
          } else {
            this.showSuccessValidations('Entitlement changes are saved successfully!');
            this.loadEntitlementList(null, false);
            this.getAllActiveEntitlements(false);
          }
        },
        error: error => {
          this.showErrorValidations(error.error);
        }
      });
  }

  showSuccessValidations(message: string) {
    this.messageFlag = true;
    this.messageService.add({ key: 'myKey1', severity: 'success', detail: message });
    setTimeout(() => {
      this.messageFlag = false;
    }, 3000);

  }

  showErrorValidations(message: string) {
    this.messageFlag = true;
    this.messageService.add({ key: 'myKey1', severity: 'error', detail: message });
    setTimeout(() => {
      this.messageFlag = false;
    }, 3000);
  }

  checkEntitlementOwnershipsInfo(Id, compId, entId, entName, status, currStatus) {
    this.deactivateEntList = [];
    this.entOwnerComponentNames = '';
    this.approverEntList = [];
    this.OwnershipPendingcount = 0;
    this.ownershipDependentEntList = [];
    this.mode = 'INACTIVE';
    this.activityEntitlementId = Id;

    this.deactivateEntList.push({ "ID": Id, "DEACTIVATED_ENT_ID": entId, "DEACTIVATED_COMP_ID": compId, "CURRENT_STATUS": currStatus });

    let requestObj = {
      TYPE: SINGLE,
      ENTITLEMENT_LIST: this.deactivateEntList
    }
    this.selectedEntitlementForDeactivate = entName + " - " + compId + " - " + entId;
    this.messageFlag = true;
    this.entitlementService.checkEntitlementOwnershipsInfo(requestObj).subscribe(
      {
        next: (response: any) => {
          this.approverEntList = response['ONLY_APPROVER_ENTS']
          this.OwnershipPendingcount = response['OWNER_REQUEST_PENDING_COUNT'];
          this.ownershipDependentEntList = response['ONLY_OWNERSHIP_DEPENDENT_ENTS'];

          this.fetchOwnerComponentNames(response.hasOwnProperty('canDeactiveEntOwnerInTM'), TAXONOMY);
          this.fetchOwnerComponentNames(response.hasOwnProperty('canDeactiveEntOwnerInDM'), DATAMART);
          this.fetchOwnerComponentNames(response.hasOwnProperty('canDeactiveEntOwnerInMNS'), MNS);

          this.isDeactivateConfirmDialog = true;

        },
        error: error => {
          this.messageFlag = true;
          this.showErrorValidations(error.error);
        }
      });
  }




  refreshNow() {
    this.isEntitlementsDeleted = false;
    this.allEntitlements = [];
    this.getAllActiveEntitlements(true);
  }
  cancelRefresh() {
    this.isEntitlementsDeleted = false;
  }

  async confirmDeleteEntitlement() {
    let data: any = {};

    this.deletedEntitlementsList.forEach(element => {
      this.deleteEntList.push({ "ID": element.ID, "ENTITLEMENT_ID": element.ENTITLEMENT_ID, "COMPONENT_ID": element.COMPONENT_ID });
    });

    data['deleteEntList'] = this.deleteEntList;
    this.entitlementService.deleteEntitlements(data).subscribe({
      next: (response: any) => {
        if (response.message == SUCCESS) {
          this.deleteEntList = [];
          this.allEntitlements = [];
          this.allActiveEntitlements = [];
          this.isDeleteEntitlementPopup = false;
          this.isDeactivateConfirmDialog = false;
          this.isMultipleDeleteEntitlementPopup = false;
          this.messageFlag = true;
          this.showSuccessValidations("Entitlement deleted successfully");
          this.loadEntitlementList(null, false);
          this.getAllActiveEntitlements(false);
        }
      },
      error: error => {
        this.showErrorValidations(error.error);
      }
    });

  }
  onItemMoveToTarget(event: any, levelNo: number) {
    let tarID = event.items.map(app => app.ID);
    this.sourceEnt = this.sourceEnt.filter(ent => !tarID.includes(ent.ID));
    const lastItemWithLevel2 = this.levelEventData[this.levelEventData.length - 1];
    if (lastItemWithLevel2 && lastItemWithLevel2.LevelNo === levelNo && lastItemWithLevel2.action != ActionType.REMOVE) {
      event.items.forEach(e => {
        lastItemWithLevel2.levelActions.push({ action: ActionType.ADD, LevelNo: null, value: e.COMPONENT_ID + '-' + e.ENTITLEMENT_ID + ' - ' + e.ENTITLEMENT_NAME });
      })
    } else {
      let levelData: IEditEntitlementApprover = {
        action: ActionType.EDIT,
        LevelNo: levelNo,
        levelActions: [],
        value: 'Level ' + levelNo,
      }
      let levelActions: IEditEntEvent[] = [];
      event.items.forEach(e => {
        levelActions.push({ action: ActionType.ADD, LevelNo: null, value: e.COMPONENT_ID + e.ENTITLEMENT_ID + ' - ' + e.ENTITLEMENT_NAME });
      })
      levelData.levelActions = levelActions;
      this.levelEventData.push(levelData);
    }
  }

  onItemMoveToSource(event: any, levelNo: number) {
    this.sourceEnt = this.sourceEntitlement[levelNo - 1].filter(ent => ent.ENTITLEMENT_STATUS_ID === this.activeStatusId);
    this.sourceEntitlement[levelNo - 1] = this.sourceEnt;
    const lastItemWithLevel2 = this.levelEventData[this.levelEventData.length - 1];
    if (lastItemWithLevel2 && lastItemWithLevel2.LevelNo === levelNo && lastItemWithLevel2.action != ActionType.REMOVE) {
      event.items.forEach(e => {
        lastItemWithLevel2.levelActions.push({ action: ActionType.REMOVE, LevelNo: null, value: e.COMPONENT_ID + '-' + e.ENTITLEMENT_ID + ' - ' + e.ENTITLEMENT_NAME });
      })
    } else {
      let levelData: IEditEntitlementApprover = {
        action: ActionType.EDIT,
        LevelNo: levelNo,
        levelActions: [],
        value: 'Level ' + levelNo,
      }
      let levelActions: IEditEntEvent[] = [];
      event.items.forEach(e => {
        levelActions.push({ action: ActionType.REMOVE, LevelNo: levelNo, value: e.COMPONENT_ID + '-' + e.ENTITLEMENT_ID + ' - ' + e.ENTITLEMENT_NAME });
      })
      levelData.levelActions = levelActions;
      this.levelEventData.push(levelData);
    }
  }
  uploadEntitlementFile(event, uploadForm) {
    this.messageFlag = true;
    if (event.files.length > 0) {
      const file = event.files[0];
      if (!file.name.endsWith('.csv')) {
        this.messageFlag = true;
        this.showErrorValidations('XLSX format is not allowed');
        uploadForm.clear();
        return;
      }
      const formData = new FormData();
      formData.append('file', file);
      this.entitlementService.uploadUserEntitlements(formData).subscribe(
        {
          next: response => {
            this.messageFlag = true;
            if (response.message == 'FAILURE') {
              this.messageFlag = true;
              this.showErrorValidations(response.messageDescription);
            } else {
              this.messageFlag = true;
              this.showSuccessValidations(response.messageDescription);
              this.loadEntitlementList(null, false);
            }
          },
          error: response => {
            this.messageFlag = true;
            if (response?.error?.status == 500 || response?.error?.status == 401 || response?.error?.status == 400) {
              this.showErrorValidations('Something went wrong due to network / conection issue, Please try after few seconds.');
            } else {
              this.showErrorValidations(response.error);
            }
          }
        });
      uploadForm.clear();
    }
  }

  downloadUserEntitlements() {
    this.messageFlag = true;
    const fileName = "UserEntitlements_" + ((new DatePipe('en-US')).transform(new Date(), 'dd_MM_yyyy_HH_mm_ss')) + ".csv";

    const formData: FormData = new FormData();
    formData.append('fileName', fileName);

    this.entitlementService.exportUserEntitlements(formData).subscribe(
      {
        next: response => {
          this.messageFlag = true;
          const blob = new Blob([response.body]);
          const downloadURL = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = downloadURL;
          const contentDisposition = response.headers.get('content-disposition');
          const filename = contentDisposition
            .split(';')[1]
            .split('filename')[1]
            .split('=')[1]
            .trim();
          link.download = filename;
          link.click();
          this.messageFlag = true;
          this.showSuccessValidations('Successfully downloaded');
        },

        error: response => {
          this.messageFlag = true;
          this.showErrorValidations(response.error);
        }
      });
  }
  uploadRegisterEnt(event, uploadForm) {
    if (event.files.length > 0) {
      const file = event.files[0];
      this.messageFlag = true;
      if (!file.name.endsWith('.csv')) {
        this.messageFlag = true;
        this.showErrorValidations('XLSX format is not allowed');
        return;
      }
      const formData = new FormData();
      formData.append('file', file);
      this.messageFlag = true;
      this.entitlementService.uploadEntitlements(formData).subscribe(
        {
          next: response => {
            this.getAllActiveEntitlements();
            if (response.message == 'FAILURE') {
              this.messageFlag = true;
              this.showErrorValidations(response.messageDescription);
            } else {
              this.messageFlag = true;
              this.showSuccessValidations(response.messageDescription);
              this.loadEntitlementList(null, false);
              this.getAllActiveEntitlements();
            }
          },
          error: response => {
            if (response?.error?.status == 500 || response?.error?.status == 401 || response?.error?.status == 400) {
              this.showErrorValidations('Something went wrong due to network / conection issue, Please try after few seconds.');
            } else {
              this.showErrorValidations(response.error);
            }
          }
        });
      uploadForm.clear();
    }
  }

  downloadRegisteredEnt() {
    const fileName = "UserEntitlements_" + ((new DatePipe('en-US')).transform(new Date(), 'dd_MM_yyyy_HH_mm_ss')) + ".csv";
    const formData: FormData = new FormData();
    formData.append('fileName', fileName);
    this.messageFlag = true;
    this.entitlementService.downloadRegisteredEntitlements(formData).subscribe(
      {
        next: response => {
          const blob = new Blob([response.body]);
          const downloadURL = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = downloadURL;
          const contentDisposition = response.headers.get('content-disposition');
          const filename = contentDisposition
            .split(';')[1]
            .split('filename')[1]
            .split('=')[1]
            .trim();
          link.download = filename;
          link.click();
          this.messageFlag = true;
          this.showSuccessValidations('Successfully downloaded');
        },

        error: response => {
          this.messageFlag = true;
          this.showErrorValidations(response.error);
        }
      });
  }
  highlight(index: any) {
    this.selectedIndexHover = index;
  }
  mouseLeave() {
    this.hoverIndex = null;
    this.selectedIndexHover = null;
  }
  private async prepareMultipleLogData(eventName: string, status: string) {
    let eventData: IEventData = await this.loggingService.getEventData(eventName, true, true, 1, ContextName.VIEW_MANAGE_ENTITLEMENT, this.activityComponentId + '-' + this.activityEntitlementId);
    let eventSubjects: IEventSubject[] = [
      await this.loggingService.getEventSubjectData(eventName, status, this.entitlementCurrStatus),
    ];
    eventData.eventSubjects = eventSubjects;
  }
  onChangeEntType(event) {
    this.isCompPresent = false;
    this.isCourceGrainedEnt = false;
    this.isValidEntitlementId = false;
    this.entitlementId_input = '';
    this.entitlementName = '';
    if (event['value']['NAME'] == COURSE_GRAINED) {
      this.isCompPresent = true;
      this.isCourceGrainedEnt = true;
      this.isValidEntitlementId = true;
      this.entitlementId_input = 0;
      this.entitlementName = this.componentName['NAME'];
    }
  }

  getEntitlementTypeList() {
    this.entitlementService.getEntitlementTypeList().subscribe((response: any) => {
      this.entTypeList = response;
    });
  }
  mapUnmapEndpoint() {
    this.messageFlag = true;
    this.ref = this.dialogService.open(EntitlementEndpointMappingComponent, {
      data: this.activeItem1,
      header: 'Map/ Unmap Endpoints',
      width: '65%',
      contentStyle: { 'max-height': '516px', overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'success') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'Endpoints mapping/unmapping saved successfully.' });
        setTimeout(() => {
          this.messageFlag = false;
        }, 1000);
        this.loadEntitlementStatusList();
        this.getComponentList();
        this.getAllActiveComponents();
      }
      else if (msg == 'failed') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Endpoints mapping/unmapping failed.' });
        setTimeout(() => {
          this.messageFlag = false;
        }, 1000);
      }
    });
  }

  private fetchOwnerComponentNames(isPresent: boolean, name: string) {
    if (isPresent) {
      this.entOwnerComponentNames += this.entOwnerComponentNames.length > 0 ? (', ' + name) : name;
      console.log(this.entOwnerComponentNames, "this.entOwnerComponentNames");
    }
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

}
