import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PagesService } from '../pages.service';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { TokenGeneratedComponent } from '../TokenGenerated/TokenGenerated.component';


@Component({
  selector: 'jhi-generatetoken',
  templateUrl: './GenerateToken.component.html',
  styleUrls: ['./GenerateToken.component.scss'],
  providers: [DialogService],
})
export class GenerateTokenComponent implements OnInit {
  GenerateTokenForm = this.fb.group({
    select: [null, [Validators.required]],
    textField: [null, [Validators.required]],
  });
  pageloadvalues: any;
  selectList: any[] = [];
  select1List: any[] = [];
  appId: any;
  constructor(private router: Router, protected activatedRoute: ActivatedRoute, private fb: UntypedFormBuilder, public pagesService: PagesService, public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.GenerateTokenForm.patchValue(JSON.parse(data));
    }

  }
  ngOnInit(): void {
    this.appId = this.config?.data?.id;
  }
  onCcprimebutton1($event: UntypedFormGroup): void {
    this.ref.close(this.dialogService.open(TokenGeneratedComponent, {
      header: 'Token Generated',
      width: '38%',
      contentStyle: { 'max-height': '160px', overflow: 'visible' },
      baseZIndex: 10000,
    }));
  }
  onCcprimebutton2($event: UntypedFormGroup): void {
  }
  GenerateTokenResource(): void {
    const reqBody = {};
    reqBody['attributeId'] = this.GenerateTokenForm.value.select;
    reqBody['attributeResourceName'] = this.GenerateTokenForm.value.textField;
    reqBody['attributeGroupList'] = this.GenerateTokenForm.value.select1;
    const formValues = reqBody;
    this.pagesService.onSaveGenerateToken(formValues, this.appId).subscribe({
      next:
        results => {
          this.ref.close("Attribute Created Successfully");
        },
      error: error => {
        if (error.error.error?.messageCode == "CIAM 105") {
          this.ref.close("Attribute resource name already exist");
        }
        else {
          this.ref.close("Attribute Creation Failed");
        }
      }
    });
  }
}