import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PagesService } from '../pages.service';
import { Message, MessageService, SortEvent } from 'primeng/api';
import { MultiSelect } from 'primeng/multiselect';
import { Dropdown } from 'primeng/dropdown';
import { AddAsSubscriptionComponent } from '../AddAsSubscription/AddAsSubscription.component';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'jhi-plandetails',
  templateUrl: './AddPlan.component.html',
  styleUrls: ['./AddPlan.component.scss'],
  providers: [MessageService, DialogService]
})
export class AddPlanComponent implements OnInit {
  defaultValue: boolean = false;
  pageloadvalues: any;
  paperColor: any;
  plotColor: any;
  layoutTextColor: any;
  agTheme: any;
  tab1: boolean = false;
  tab2: boolean = false;
  activeIndex1: number = 0;
  activeIndex2: number = 0;
  clicked1 = false;
  clicked2 = true;
  planAttributeList: any[] = [];
  planId: any;
  enablePlanButton: boolean = true;
  ref!: DynamicDialogRef;
  ccprimebreadcrumbList = [
    { label: `Application Plans`, routerLink: ['/pages/planListing'] },
    { label: `Add New Plan`, routerLink: ['/pages/addPlan'] },
  ]
  homeiconccprimebreadcrumb = { icon: 'pi pi-home', routerLink: ['/'] };
  clipPropertycolumns_2_1_ccprimeheading_1: string = '';
  selectSwitchList: any[] = [{ 'label': "Attribute Type", 'value': 'attributeType' }, { 'label': "Attribute Group", 'value': 'attributeGroup' },];
  defaultselect_selectSwitch: any;
  attributeTypeList: any[];
  clipPropertyplanAtrributes: string = '';
  clipPropertypriceUnitValue: string = '';

  clipPropertyperUnitType: string = '';
  messageFlag: boolean;
  messageFlag1: boolean = false;
  nameFlag: boolean = false;
  planAttributeDto = [];
  applicationRoleLabelValue: MultiSelect;
  dd: Dropdown;
  PlanDetailsForm = this.fb.group({
    columns_2_1_ccprimeheading_1: [null],
    planName: [null, [Validators.required]],
    planDescription: [null],
    application: [null, [Validators.required]],
    selectSwitch: [null],
    attributeGroup: [null, []],
    attributeType: [null, []],
    attribute: [null, []],
    featureDescription: [null],
    applicationRoles: [null,],
    featureName: [null],
  }
  );
  PlanAttributeForm = this.fb.group({
    deploymentType: [null, []],
    storageType: [null, []],
    tenantType: [null, []],
    deploymentSubType: [null, []],
    accountNumber: [null, []],
    region: [null, []],
    role: [null, []],
    vpcName: [null, []],
    subnet: [null, []],
    clusterName: [null, []],
    nodeFamily: [null, []],
    efsPath: [null, []],
    s3ArnRole: [null, []],
    s3Secret: [null, []],
    s3Key: [null, []],
    s3SecretVaultPath: [null, []],
    redisUrl: [null, []],
    redisVaultPath: [null, []],
    redisUsername: [null, []],
    redisPassword: [null, []],
    rdsType: [null, []],
    rdsHost: [null, []],
    rdsPort: [null, []],
    rdsUsername: [null, []],
    rdsPassword: [null, []],
    rdsVaultPath: [null, []],
    rdsFamily: [null, []],
    enablePodAutoScalling: [null, []],
    efsStyle: [null, []],
    redisStyle: [null, []],
    s3Style: [null, []],
    rdsStyle: [null, []],
    rdsDBName: [null, []]
  }
  );
  clicked: boolean;
  selectList: any[];
  applicationId: any;
  applicationRoleList: any[];
  applicationLabel: any;
  applicationValue: any;
  attributeTypeValue: any;
  attributeTypeLabel: any;
  applicationRoles: any;
  applicationRole: any[];
  applicationRoleLabel: any[];
  attributeList: any[];
  attributeGroupList: any[];
  planList: {
    id: any;
    selectType: any;
    application: any;
    applicationRole: any;
    attributeType: any;
    attributeTypeName: any;
    attribute: any; attributeGroup: any; featureDescription: any;
    featureName: any;
  }[] = [];
  currentIndex: number = -1;
  common: any = [];
  duplicityFlagApplicationRole: boolean = false;
  duplicityFlagAttributeGroup: boolean = false;
  duplicityFlagAttribute: boolean = false;
  featureReqBody: any;
  defaultFlag: boolean = true;
  firstSave: boolean = false;
  enableBtn1 = false;
  msgs1: Message[];
  firstSavePlan: boolean = false;
  oldfeatures: any;
  featureReqBody1: any;
  changedValueFlag = false;
  enableAddSusbcriptionBtn = true;
  duplicityFlagfeatureName: boolean = false;
  planList1: { id: any; selectType: any; application: any; applicationRole: any; attributeType: any; attributeTypeName: any; attribute: any; attributeGroup: any; featureDescription: any; featureName: any; }[];
  deploymentTypeList: any[] = [{ 'label': "Bridge", 'value': 'Bridge' }, { 'label': "Full Stack Pool", 'value': 'FULLSTACK_POOL' }, { 'label': "Full Stack Silo", 'value': 'FULLSTACK_SILO' }];
  deploymentSubTypeList: any[] = [{ 'label': "Siloed (Dedicated)", 'value': 'SILOED_VPC_CLUSTER' }, { 'label': "Pooled (Shared)", 'value': 'POOLED_VPC_CLUSTER' }];
  tenantTypeList: any[] = [{ 'label': "Siloed (Dedicated)", 'value': 'Siloed' }, { 'label': "Pooled (Shared)", 'value': 'Pooled' }];
  storageTypeList: any[] = [{ 'label': "Cluster Per Tenant", 'value': 'Cluster Per Tenant' }, { 'label': "Name Space Per Tenant", 'value': 'Name Space Per Tenant' }];
  regionList: any[] = [{ 'label': "Cluster Per Tenant", 'value': 'Cluster Per Tenant' }, { 'label': "Name Space Per Tenant", 'value': 'Name Space Per Tenant' }];
  nodeFamilyList: any[] = [{ 'label': "C5 Large", 'value': 'c5.large' }];
  selectSwitchListSaas: any[] = [{ 'label': "Internal", 'value': 'false' }, { 'label': "External", 'value': 'true' },];
  rdsTypeList: any[] = [{ 'label': "My SQL", 'value': 'mysql' }, { 'label': "Oracle", 'value': 'oracle' },];
  rdsFamilyList: any[] = [{ 'label': "DB M5 Large", 'value': 'db.m5.large' }];
  styleList: any[] = [{ 'label': "Shared", 'value': 'shared' }, { 'label': "Dedicated", 'value': 'dedicated' },];
  deploymentType: any;
  deploymentSubType: any;
  accountNumber: any;
  region: any;
  role: any;
  vpcName: any;
  efsPath: any;
  subnet: any;
  clusterName: any;
  nodeFamily: any;
  enablePodAutoScalling: any;
  efsStyle: any;
  s3Style: any;
  s3ArnRole: any;
  s3Secret: any;
  s3Key: any;
  s3SecretVaultPath: any;
  redisStyle: any;
  redisUrl: any;
  redisVaultPath: any;
  redisUsername: any;
  redisPassword: any;
  rdsStyle: any;
  rdsType: any;
  rdsHost: any;
  rdsPort: any;
  rdsUsername: any;
  rdsPassword: any;
  rdsDBName: any;
  rdsVaultPath: any;
  rdsFamily: any;
  saasEnable: any;

  constructor(private router: Router, protected activatedRoute: ActivatedRoute, private fb: UntypedFormBuilder, public pagesService: PagesService, private messageService: MessageService, public dialogService: DialogService) {


    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.PlanDetailsForm.patchValue(JSON.parse(data));
      this.PlanAttributeForm.patchValue(JSON.parse(data));
    }

    this.setDefaultValue_selectSwitch('attributeType');

  }

  ngOnInit(): void {
    this.getApplicationVersionList();
    this.fetchPlanAttribute();
    this.saasEnable = environment.IS_SAAS_ENABLED + "";
    if (this.saasEnable == 'true') {
      this.PlanAttributeForm.controls['deploymentType'].setValidators(Validators.required);
      this.PlanAttributeForm.controls['deploymentSubType'].setValidators(Validators.required);
      this.PlanAttributeForm.controls['nodeFamily'].setValidators(Validators.required);
      this.PlanAttributeForm.controls['rdsType'].setValidators(Validators.required);
      this.PlanAttributeForm.controls['rdsHost'].setValidators(Validators.required);
      this.PlanAttributeForm.controls['rdsPort'].setValidators(Validators.required);
      this.PlanAttributeForm.controls['rdsUsername'].setValidators(Validators.required);
      this.PlanAttributeForm.controls['rdsPassword'].setValidators(Validators.required);
      this.PlanAttributeForm.controls['rdsFamily'].setValidators(Validators.required);
      this.PlanAttributeForm.controls['efsStyle'].setValidators(Validators.required);
      this.PlanAttributeForm.controls['redisStyle'].setValidators(Validators.required);
      this.PlanAttributeForm.controls['s3Style'].setValidators(Validators.required);
      this.PlanAttributeForm.controls['rdsStyle'].setValidators(Validators.required);
      this.PlanAttributeForm.controls['rdsDBName'].setValidators(Validators.required);
      console.log(this.PlanAttributeForm);
    }
    else {
      this.enableBtn1 = true;
    }
    console.log(this.PlanAttributeForm);
    console.log(this.enableBtn1);
  }

  checkStepValidations() {
    console.log(this.PlanDetailsForm.controls['attributeType']);
    if (this.PlanDetailsForm.value.application != null) {
      this.PlanDetailsForm.get('applicationRoles').enable();
      this.PlanDetailsForm.get('attributeType').enable();
      this.PlanDetailsForm.get('attributeGroup').enable();
    }
    if (this.PlanDetailsForm.value.attributeType != null) {
      this.PlanDetailsForm.get('attribute').enable();
    }
  }

  getApplicationVersionList(): void {
    this.selectList = [];
    this.PlanDetailsForm.controls['applicationRoles'].reset();
    this.applicationRoleList = [];
    this.PlanDetailsForm.controls['applicationRoles'].updateValueAndValidity();
    this.pagesService.getApplicationVersion().subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            applicationName: null,
            applicationId: null
          };
          data.applicationName = element.label;
          data.applicationId = element.value;
          this.selectList.push(data);
        });
        this.selectList = [...this.selectList];
      }
    });
  }

  onAddButton($event: UntypedFormGroup): void {
    let reqBody: any = {};
    this.duplicityFlagApplicationRole = false;
    this.duplicityFlagAttributeGroup = false;
    this.duplicityFlagAttribute = false;
    this.duplicityFlagfeatureName = false;
    reqBody = {
      selectType: this.PlanDetailsForm?.value?.selectSwitch,
      application: this.PlanDetailsForm?.value?.application,
      applicationRole: this.PlanDetailsForm?.value?.applicationRoles,
      attributeType: this.PlanDetailsForm?.value?.attributeType?.attributeTypeId,
      attributeTypeName: this.PlanDetailsForm?.value?.attributeType?.attributeTypeName,
      attribute: this.PlanDetailsForm?.value?.attribute,
      attributeGroup: this.PlanDetailsForm?.value?.attributeGroup,
      featureDescription: this.PlanDetailsForm?.value?.featureDescription,
      featureName: this.PlanDetailsForm?.value?.featureName
    };
    this.applicationRoles = this.PlanDetailsForm?.value?.applicationRoles;
    if (this.planList.length > 0) {
      this.planList.forEach(element => {
        if (element?.featureName == reqBody?.featureName && element?.featureName != null) {
          this.common.push(reqBody?.featureName);
          this.duplicityFlagfeatureName = true;
        }
        if (element?.application?.applicationName == reqBody.application.applicationName) {
          element?.applicationRole?.forEach((array1: any) => {
            reqBody?.applicationRole?.forEach((array2: any) => {
              if (array1.applicationRoleName == array2.applicationRoleName) {
                this.common.push(array1.applicationRoleName);
                this.duplicityFlagApplicationRole = true;
              }
            })
          });
          element?.attributeGroup?.forEach((array1: any) => {
            reqBody?.attributeGroup?.forEach((array2: any) => {
              if (array1.attributeGroupName == array2.attributeGroupName) {
                this.common.push(array1.attributeGroupName);
                this.duplicityFlagAttributeGroup = true;
              }
            })
          });
          element?.attribute?.forEach((array1: any) => {
            reqBody?.attribute?.forEach((array2: any) => {
              if (array1.attributeName == array2.attributeName) {
                this.common.push(array1.attributeName);
                this.duplicityFlagAttribute = true;
              }
            })
          });
        }
      });
    }
    if (!this.duplicityFlagApplicationRole && !this.duplicityFlagAttribute && !this.duplicityFlagAttributeGroup && !this.duplicityFlagfeatureName) {
      this.planList.push(reqBody);
      this.planList1 = this.planList
      this.enablePlanButton = false;
      this.setDefaultValue_selectSwitch();
      this.PlanDetailsForm.controls.application.reset();
      this.PlanDetailsForm.controls.applicationRoles.reset();
      this.PlanDetailsForm.controls.attributeType.reset();
      this.PlanDetailsForm.controls.attribute.reset();
      this.PlanDetailsForm.controls.attributeGroup.reset();
      this.PlanDetailsForm.controls.featureDescription.reset();
      this.PlanDetailsForm.controls.featureName.reset();
      this.duplicityFlagAttribute = false;
      this.PlanDetailsForm.controls['attributeGroup'].clearValidators();
      this.PlanDetailsForm.controls['attributeGroup'].reset();
      this.PlanDetailsForm.controls['attributeGroup'].updateValueAndValidity();
      this.PlanDetailsForm.controls['attributeType'].updateValueAndValidity();
    }
  }

  deletePlan(index: any) {
    this.currentIndex = index;
    this.planList.splice(index, 1);
    if (this.planList.length > 0) {
      this.enablePlanButton = false;
    }
    else {
      this.enablePlanButton = true;
    }
    this.planList1 = this.planList;
  }

  selectAttributeTypeGroup() {
    if (this.PlanDetailsForm.value.selectSwitch == 'attributeType') {
      this.duplicityFlagAttributeGroup = false;
      this.PlanDetailsForm.controls['attributeGroup'].clearValidators();
      this.PlanDetailsForm.controls['attributeGroup'].reset();
      this.PlanDetailsForm.controls['attributeGroup'].updateValueAndValidity();
      this.PlanDetailsForm.controls['attributeType'].updateValueAndValidity();

    } else if (this.PlanDetailsForm.value.selectSwitch == 'attributeGroup') {
      this.duplicityFlagAttribute = false;
      this.PlanDetailsForm.controls['attributeType'].clearValidators();
      this.PlanDetailsForm.controls['attributeType'].reset();
      this.PlanDetailsForm.controls['attributeType'].updateValueAndValidity();
      this.PlanDetailsForm.controls['attribute'].reset();
      this.PlanDetailsForm.controls['attribute'].updateValueAndValidity();
    }
  }

  getAttributeTypeList(): void {
    this.attributeTypeList = [];
    this.applicationId = this.PlanDetailsForm.value.application.applicationId;
    this.pagesService.getAttributeTypeListing(this.applicationId).subscribe((results: any) => {
      const responseData = results?.success?.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            attributeTypeName: null,
            attributeTypeId: null,
          };
          data.attributeTypeName = element.attributeTypeName;
          data.attributeTypeId = element.id;
          this.attributeTypeList.push(data);
        });
        this.attributeTypeList = [...this.attributeTypeList];
      }
    });
  }
  customFilter(event: any) {
    if (event.filters?.attributeGroup[0].value?.length > 0) {
      this.groupFilterMethod(event);

    } else if (event.filters?.attribute[0].value?.length > 0) {
      this.attributeFilterMethod(event);
    }
    else if (event.filters?.applicationName[0].value?.length > 0) {
      this.applicationNameFilterMethod(event);
    }
    else if (event.filters?.applicationRole[0]?.value?.length > 0) {
      this.applicationRoleFilterMethod(event);
    }

  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let value1: any;
      let value2: any;
      if (event.field == 'applicationName') {
        value1 = data1.application?.applicationName;
        value2 = data2.application?.applicationName;
      }
      else if (event.field == 'applicationRole') {
        value1 = data1.applicationRole[0]?.applicationRoleName;
        value2 = data2.applicationRole[1]?.applicationRoleName;
      }
      else if (event.field == 'attributeGroup') {
        if (data2.attributeGroup == null) {
          value2 = '';
        } else if (data1.attributeGroup == null) {
          value1 = '';
        } else {
          value1 = data1.attributeGroup[0]?.attributeGroupName;
          value2 = data2.attributeGroup[1]?.attributeGroupName;
        }

      }
      else if (event.field == 'attribute') {
        if (data1.attribute == null) {
          value1 = '';
        } else if (data2.attribute == null) {
          value2 = '';
        }
        else {
          value1 = data1.attribute[0]?.attributeName;
          value2 = data2.attribute[1]?.attributeName;
        }
      }
      else {
        value1 = data1[event.field];
        value2 = data2[event.field];
      }
      let result = null;
      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else {
        let value = value1 > value2 ? 1 : 0;
        result = (value1 < value2) ? -1 : value;
      }

      return (event.order * result);
    });
  }
  // filter for column Group
  groupFilterMethod(event: any) {
    let filtered: any[] = [];
    const matMode = event.filters.attributeGroup[0].matchMode;
    let items: any;
    let cc;
    let query = event.filters.attributeGroup[0].value;
    if (query?.length > 0) {
      if (matMode == "startsWith") {
        this.planList.forEach(element => {
          items = element;
          cc = items.attributeGroup?.length;
          //due to break foreach not used
          for (let j = 0; j < cc; j++) {
            if (items.attributeGroup[j].attributeGroupName?.toLowerCase().startsWith(query.toLowerCase())) {
              event.filteredValue.push(items);
              break;
            }
          }
        });
      }
      else if (matMode == "contains") {
        this.planList.forEach(element => {
          items = element;
          cc = items.attributeGroup?.length;
          //due to break foreach not used
          for (let j = 0; j < cc; j++) {
            if (items.attributeGroup[j].attributeGroupName?.toLowerCase().includes(query.toLowerCase())) {
              event.filteredValue.push(items);
              break;
            }
          }
        });
      }
      else if (matMode == "endsWith") {
        this.planList.forEach(element => {
          items = element;
          cc = items.attributeGroup?.length;
          //due to break foreach not used
          for (let j = 0; j < cc; j++) {
            if (items.attributeGroup[j].attributeGroupName?.toLowerCase().indexOf(query.toLowerCase(), items.attributeGroup[j].attributeGroupName?.length - query.length) !== -1) {
              event.filteredValue.push(items);
              break;
            }
          }
        });
      }
      else if (matMode == "equals") {
        this.planList.forEach(element => {
          items = element;
          cc = items.attributeGroup?.length;
          //due to break foreach not used
          for (let j = 0; j < cc; j++) {
            if (items.attributeGroup[j].attributeGroupName?.toLowerCase() == query.toLowerCase()) {
              event.filteredValue.push(items);
              break;
            }
          }
        });
      }
      else {
        this.planList = this.planList1;
      }
    }
  }
  attributeFilterMethod(event: any) {
    let filtered: any[] = [];
    const matMode = event.filters.attribute[0].matchMode;
    let items: any;
    let cc;
    let query = event.filters.attribute[0].value;
    if (query?.length > 0) {
      if (matMode == "startsWith") {
        this.planList.forEach(element => {
          items = element;
          cc = items.attributeGroup?.length;
          //due to break foreach not used
          for (let j = 0; j < cc; j++) {
            if (items.attribute[j].attributeName?.toLowerCase().startsWith(query.toLowerCase())) {
              event.filteredValue.push(items);
              break;
            }
          }
        });
      }
      else if (matMode == "contains") {
        this.planList.forEach(element => {
          items = element;
          cc = items.attributeGroup?.length;
          //due to break foreach not used
          for (let j = 0; j < cc; j++) {
            if (items.attribute[j].attributeName?.toLowerCase().includes(query.toLowerCase())) {
              event.filteredValue.push(items);
              break;
            }
          }
        });
      }
      else if (matMode == "endsWith") {
        this.planList.forEach(element => {
          items = element;
          cc = items.attributeGroup?.length;
          //due to break foreach not used
          for (let j = 0; j < cc; j++) {
            if (items.attribute[j].attributeName?.toLowerCase().indexOf(query.toLowerCase(), items.attribute[j].attributeName?.length - query.length) !== -1) {
              event.filteredValue.push(items);
              break;
            }
          }
        });
      }
      else if (matMode == "equals") {
        this.planList.forEach(element => {
          items = element;
          cc = items.attributeGroup?.length;
          //due to break foreach not used
          for (let j = 0; j < cc; j++) {
            if (items.attribute[j].attributeName?.toLowerCase() == query.toLowerCase()) {
              event.filteredValue.push(items);
              break;
            }
          }
        });
      }
      else {
        this.planList = this.planList1;
      }
    }
  }
  applicationNameFilterMethod(event: any) {
    let filtered: any[] = [];
    const matMode = event.filters.applicationName[0].matchMode;
    let items: any;
    let cc;
    let query = event.filters.applicationName[0].value;
    if (query?.length > 0) {
      if (matMode == "startsWith") {
        this.planList.forEach(element => {
          items = element;
          if (items.application?.applicationName?.toLowerCase().startsWith(query.toLowerCase())) {
            event.filteredValue.push(items);
          }
        });
      }
      else if (matMode == "contains") {
        this.planList.forEach(element => {
          items = element;
          if (items.application?.applicationName?.toLowerCase().includes(query.toLowerCase())) {
            event.filteredValue.push(items);
          }
        });
      }
      else if (matMode == "endsWith") {
        this.planList.forEach(element => {
          items = element;
          if (items.application?.applicationName?.toLowerCase().indexOf(query.toLowerCase(), items.application?.applicationName?.length - query.length) !== -1) {
            event.filteredValue.push(items);
          }
        });
      }
      else if (matMode == "equals") {
        this.planList.forEach(element => {
          items = element;
          if (items.application?.applicationName?.toLowerCase() == query.toLowerCase()) {
            event.filteredValue.push(items);
          }
        });
      }
      else {
        this.planList = this.planList1;
      }
    }
  }
  applicationRoleFilterMethod(event: any) {
    let filtered: any[] = [];
    const matMode = event.filters.applicationRole[0].matchMode;
    let items: any;
    let cc;
    let query = event.filters.applicationRole[0].value;
    if (query?.length > 0) {
      if (matMode == "startsWith") {
        this.planList.forEach(element => {
          items = element;
          cc = items.applicationRole.length;
          for (let j = 0; j < cc; j++) {
            if (items.applicationRole[j].applicationRoleName?.toLowerCase().startsWith(query.toLowerCase())) {
              event.filteredValue.push(items);
              break;
            }
          }
        });
      }
      else if (matMode == "contains") {
        this.planList.forEach(element => {
          items = element;
          cc = items.applicationRole.length;
          for (let j = 0; j < cc; j++) {
            if (items.applicationRole[j].applicationRoleName?.toLowerCase().includes(query.toLowerCase())) {
              event.filteredValue.push(items);
              break;
            }
          }
        });
      }
      else if (matMode == "endsWith") {
        this.planList.forEach(element => {
          items = element;
          cc = items.applicationRole.length;
          for (let j = 0; j < cc; j++) {
            if (items.applicationRole[j].applicationRoleName?.toLowerCase().indexOf(query.toLowerCase(), items.applicationRole[j].applicationRoleName?.length - query.length) !== -1) {
              event.filteredValue.push(items);
              break;
            }
          }
        });
      }
      else if (matMode == "equals") {
        this.planList.forEach(element => {
          items = element;
          cc = items.applicationRole.length;
          for (let j = 0; j < cc; j++) {
            if (items.applicationRole[j].applicationRoleName?.toLowerCase() == query.toLowerCase()) {
              event.filteredValue.push(items);
              break;
            }
          }
        });
      }
      else {
        this.planList = this.planList1;
      }
    }
  }
  getApplicationRoleList(): void {
    this.PlanDetailsForm.controls.applicationRoles.reset();
    this.PlanDetailsForm.controls.applicationRoles.updateValueAndValidity();
    this.applicationRoleList = [];
    this.PlanDetailsForm.controls.attribute.reset();
    this.PlanDetailsForm.controls.attribute.updateValueAndValidity();
    this.attributeList = [];
    this.PlanDetailsForm.get('attribute').disable();
    this.applicationId = this.PlanDetailsForm.value.application.applicationId;
    this.pagesService.getApplicationRoleListing(this.applicationId).subscribe((results: any) => {
      const responseData = results?.success?.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            applicationRoleName: null,
            applicationRoleId: null,
          };
          data.applicationRoleName = element.applicationRoleName;
          data.applicationRoleId = element.id;
          this.applicationRoleList.push(data);
        });
        this.applicationRoleList = [...this.applicationRoleList];
      }
    });
  }

  getApplicationAttributeList(): void {
    this.attributeList = [];
    const reqBody = {};
    reqBody["applicationId"] = this.PlanDetailsForm?.value?.application?.applicationId;
    reqBody["attributeId"] = this.PlanDetailsForm?.value?.attributeType?.attributeTypeId;
    this.pagesService.getAttributeByHostApplication(reqBody).subscribe((results: any) => {
      const responseData = results?.success?.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            attributeName: null,
            attributeId: null,
          };
          data.attributeName = element.label;
          data.attributeId = element.value;
          this.attributeList.push(data);
        });
        this.attributeList = [...this.attributeList];
      }
    });
  }

  getApplicationAttributeGroupList(): void {
    this.PlanDetailsForm.controls.attributeGroup.reset();
    this.PlanDetailsForm.controls.attributeGroup.updateValueAndValidity();
    this.attributeGroupList = [];
    const reqBody = {};
    reqBody["applicationId"] = this.PlanDetailsForm.value.application.applicationId;
    this.pagesService.getAttributeGroupByHostApplication(reqBody).subscribe((results: any) => {
      const responseData = results?.success?.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            attributeGroupName: null,
            attributeGroupId: null,
          };
          data.attributeGroupName = element.label;
          data.attributeGroupId = element.value;
          this.attributeGroupList.push(data);
        });
        this.attributeGroupList = [...this.attributeGroupList];
      }
    });
  }
  onSaveCheck($event: UntypedFormGroup) {
    if (this.firstSavePlan) {
      this.onSaveEditPlan($event);
    } else {
      this.onSaveButton($event);
    }
  }
  getPlanList(): void {
    this.planList = [];
    const planFeature = this.oldfeatures;

    if (planFeature && planFeature.length > 0) {
      planFeature.forEach((element: any = {}) => {
        const data = {
          id: null,
          selectType: null,
          application: null,
          applicationRole: [],
          attributeType: null,
          attributeTypeName: null,
          attribute: [],
          attributeGroup: [],
          featureDescription: null,
          featureName: null
        };

        const applicationconst = {
          applicationName: element?.applicationName,
          applicationId: element?.applicationId
        }
        data.id = element?.id;
        data.application = applicationconst;

        data.attributeTypeName = element?.attributeTypeName;
        data.attributeType = element?.attributeType;
        data.featureDescription = element?.featureDescription;
        data.featureName = element?.featureName;
        const attributeGroupConst = element?.attributeGroups;

        if (attributeGroupConst?.length > 0) {
          attributeGroupConst.forEach((element1: any = {}) => {
            const data1 =
            {
              attributeGroupName: null,
              attributeGroupId: null,
              featureAttributeGroupId: null
            }
            data1.featureAttributeGroupId = element1?.featureAttributeGroupId;
            data1.attributeGroupName = element1?.attributeGroupName;
            data1.attributeGroupId = element1?.attributeGroupId;
            data?.attributeGroup.push(data1);

          });
          data.attributeGroup = [...data?.attributeGroup]; //NOSONAR

        }
        const attributeConst = element?.attributes;
        if (attributeConst?.length > 0) {
          attributeConst.forEach((element2: any = {}) => {
            const data2 =
            {
              featureAttributeId: null,
              attributeName: null,
              attributeId: null
            }
            data2.featureAttributeId = element2?.featureAttributeId;
            data2.attributeName = element2?.attributeName;
            data2.attributeId = element2?.attributeId;

            data.attribute.push(data2);
          });
          data.attribute = [...data.attribute];
        }
        const applicationRoleConst = element?.applicationRoles;

        if (applicationRoleConst != null) {
          applicationRoleConst.forEach((element3: any = {}) => {
            const data3 =
            {
              applicationRoleName: null,
              applicationRoleId: null,
              featureApplicationRoleId: null
            }
            data3.featureApplicationRoleId = element3?.featureApplicationRoleId;
            data3.applicationRoleName = element3?.applicationRoleName;
            data3.applicationRoleId = element3?.applicationRoleId;
            data.applicationRole.push(data3);
          });
        }

        data.applicationRole = [...data.applicationRole];
        this.planList.push(data);
      });
      this.planList = [...this.planList];
    }
    console.log("HIiiiiiiiiii i am here");
    console.log(this.planList);
  }

  onClearButton($event: UntypedFormGroup): void {
    if (!this.firstSavePlan) {
      this.PlanDetailsForm.reset();
    }
    else {
      this.PlanDetailsForm.controls.application.reset();
      this.PlanDetailsForm.controls.applicationRoles.reset();
      this.PlanDetailsForm.controls.attributeType.reset();
      this.PlanDetailsForm.controls.attribute.reset();
      this.PlanDetailsForm.controls.attributeGroup.reset();
      this.PlanDetailsForm.controls.featureDescription.reset();
      this.PlanDetailsForm.controls.featureName.reset();
      this.PlanDetailsForm.controls.planDescription.reset();
    }
    this.planList = [];
    const defaultSwitch = this.defaultselect_selectSwitch;
    this.duplicityFlagApplicationRole = false;
    this.duplicityFlagfeatureName = false;
    this.duplicityFlagAttributeGroup = false;
    this.duplicityFlagAttribute = false;
    if (defaultSwitch == "attributeType") {
      this.setDefaultValue_selectSwitch('attributeType');
      this.PlanDetailsForm.controls['selectSwitch'].setValue("attributeType");
      this.PlanDetailsForm.controls['selectSwitch'].updateValueAndValidity();
    }
    else {
      this.setDefaultValue_selectSwitch();
    }
    this.applicationRoleList = [];
    this.attributeGroupList = [];
    this.attributeTypeList = [];
    this.attributeList = [];
  }

  onSaveButton($event: UntypedFormGroup): void {
    if (this.planList.length > 0) {
      this.featureReqBody = [];
      const reqBody = {};
      this.planList.forEach(element => {
        const features = {};
        if (element.selectType == 'attributeType') {
          features["applicationId"] = element.application.applicationId;
          features["applicationName"] = element.application.applicationName;
          features["attributeType"] = element.attributeType;
          features["attributeTypeName"] = element.attributeTypeName;
          if (element?.attribute?.length == 0) {
            features["attributes"] = [];
          }
          else {
            features["attributes"] = element.attribute;
          }

          features["applicationRoles"] = element.applicationRole;
          features["featureDescription"] = element.featureDescription;
          if (element.featureName != null) {
            features["featureName"] = element.featureName;
          }
          this.featureReqBody.push(features);
        } else if (element.selectType == 'attributeGroup') {
          features["applicationId"] = element.application.applicationId;
          features["applicationName"] = element.application.applicationName;
          if (element?.attributeGroup?.length == 0) {
            features["attributeGroups"] = [];
          }
          else {
            features["attributeGroups"] = element.attributeGroup;
          }
          features["attributeGroups"] = element.attributeGroup;
          features["applicationRoles"] = element.applicationRole;
          features["featureDescription"] = element.featureDescription;
          if (element.featureName != null) {
            features["featureName"] = element.featureName;
          }

          this.featureReqBody.push(features);
        }
        this.featureReqBody = [...this.featureReqBody];
      });

      reqBody["planName"] = this.PlanDetailsForm.value.planName;
      reqBody["planDescription"] = this.PlanDetailsForm.value.planDescription;
      reqBody["features"] = this.featureReqBody;
      this.messageFlag = true;
      this.pagesService.addPlan(reqBody).subscribe({
        next:
          results => {
            if (results.success.code == 200) {
              this.nameFlag = false;
              this.messageFlag = true;
              this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'Application plan details saved successfully.' });
              setTimeout(() => {
                this.messageFlag = false;
              }, 3000);
              this.enablePlanButton = true;
              this.PlanDetailsForm.controls.application.reset();
              this.PlanDetailsForm.controls.application.updateValueAndValidity();
              this.PlanDetailsForm.get('applicationRoles').disable();
              this.PlanDetailsForm.get('attributeGroup').disable();
              this.PlanDetailsForm.get('attribute').disable();
              this.PlanDetailsForm.get('attributeType').disable();
              this.PlanDetailsForm.get('planName').disable();

              this.planId = results.success.result.id;
              this.oldfeatures = results.success.result.features;
              this.getPlanList();
              this.firstSavePlan = true;

              this.clicked2 = false;
              this.tab1 = true;
              this.showTab();
            }

          },

        error: error => {
          if (error.error?.error?.code == 409 && error.error?.error?.reason == "Plan name already exist") {
            this.nameFlag = true;
            document.getElementById('planName')!.style.borderColor = 'red';
            this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Plan name already exists.' });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
          }
          if (error.error?.error?.code == 409 && error.error?.error?.reason == "Feature name already added in Plan") {
            this.nameFlag = true;
            document.getElementById('featureName')!.style.borderColor = 'red';
            this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Feature name already exists.' });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
          }
          if (error.error?.error?.code == 409 && error.error?.error?.reason == "Application already added in Plan") {
            this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Feature with same detail already exists.' });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
          }
          else {
            this.messageFlag = true;
            this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Failed to save Application plan details.' });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
          }

        },
      });
    }
    document.getElementById('planName')!.style.borderColor = 'grey';
  }

  onSaveEditPlan($event: UntypedFormGroup): void {

    if (this.planList.length > 0) {
      this.featureReqBody1 = [];
      const reqBody1 = {};
      this.planList.forEach(element => {
        const features1 = {};
        features1["id"] = element?.id;
        features1["applicationId"] = element.application.applicationId;
        features1["applicationName"] = element.application.applicationName;
        if (element?.attribute?.length > 0) {
          features1["attributeType"] = element.attributeType;
          features1["attributeTypeName"] = element.attributeTypeName;
          features1["attributes"] = element.attribute;
        }
        if (element?.attributeGroup?.length > 0) {
          features1["attributeGroups"] = element.attributeGroup;
        }
        if (element?.applicationRole?.length > 0) {
          features1["applicationRoles"] = element.applicationRole;
        }

        features1["featureDescription"] = element.featureDescription;
        features1["featureName"] = element.featureName;
        this.featureReqBody1.push(features1);
        this.featureReqBody1 = [...this.featureReqBody1];
      });

      reqBody1["id"] = this.planId;
      reqBody1["planName"] = this.PlanDetailsForm.value.planName;
      reqBody1["planDescription"] = this.PlanDetailsForm.value.planDescription;
      reqBody1["features"] = this.featureReqBody1;
      this.messageFlag = true;
      this.pagesService.editPlan(reqBody1).subscribe({
        next:
          results => {
            if (results.success.code == 200) {
              this.nameFlag = false;
              this.messageFlag = true;
              this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'Application plan details saved successfully.' });
              setTimeout(() => {
                this.messageFlag = false;
              }, 3000);
              this.enablePlanButton = true;
              this.PlanDetailsForm.get('applicationRoles').disable();
              this.PlanDetailsForm.get('attributeGroup').disable();
              this.PlanDetailsForm.get('attribute').disable();
              this.PlanDetailsForm.get('attributeType').disable();
              this.planId = results.success.result.id;
              this.clicked2 = false;
              this.tab1 = true;
              this.showTab();
            }

          },
        error: error => {
          if (error.error?.error?.code == 409 && error.error?.error?.reason == "Application already added in Plan") {
            this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Feaure already exists.' });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
          }
          else {
            this.messageFlag = true;
            this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Failed to save Application plan details.' });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
          }
        }
      });
    }
  }
  handleChangecolumns_2_2_card_2_columns_2_2_card_2_columns_2_2_card_2_columns_2_2_tabview_1_1_1_1_1(e: any): void {
    this.PlanDetailsForm.patchValue({ "columns_2_2_card_2_columns_2_2_card_2_columns_2_2_card_2_columns_2_2_tabview_1_1_1_1_1": e.index });
  }

  onClipConvertingcolumns_2_1_ccprimeheading_1() {
    let title = 'Add New Plan'
    this.clipPropertycolumns_2_1_ccprimeheading_1 = title.substring(0, +1) + '...';
  }


  onClipConvertingplanAtrributes() {
    let title = 'Plan Attributes'
    this.clipPropertyplanAtrributes = title.substring(0, +1) + '...';
  }

  onClipConvertingpriceUnitValue() {
    let title = 'Value'
    this.clipPropertypriceUnitValue = title.substring(0, +1) + '...';
  }

  onClipConvertingperUnitType() {
    let title = 'Per Unit Type'
    this.clipPropertyperUnitType = title.substring(0, +1) + '...';
  }

  /*
  * Default Select select button
  */

  setDefaultValue_selectSwitch(value?: any) {
    if (this.selectSwitchList.length && value) {
      this.defaultselect_selectSwitch = value;
      return this.defaultselect_selectSwitch
    }
    this.defaultselect_selectSwitch = this.selectSwitchList[0]?.value;
    return this.defaultselect_selectSwitch;
  }

  conditionalLogicCheck(actualValue: any, checkValue: any): boolean {
    if (actualValue && Array.isArray(actualValue)) {
      if (checkValue && typeof checkValue === 'string') {
        const array = checkValue.toString().split(',');
        let valuePresent = true;
        array.forEach((element: any) => {
          if (!actualValue.includes(element)) {
            valuePresent = false;
          }
        });
        return valuePresent;
      } else {
        return actualValue.includes(checkValue);
      }
    }
    return actualValue === checkValue;
  }

  showTab() {
    if (this.activeIndex1 === 0) {
      this.activeIndex1 = 1;
    }
  }

  fetchPlanAttribute() {

    this.pagesService.getAllPlanAttributeConfig().subscribe(results => {
      const responseData = results?.success?.result;
      let pagePropBind = {};
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            id: null,
            fieldType: null,
            typeName: null,
            symbol: null,
            typeId: null,
            defaultOptionValue: null,
            unitId: null,
            planAttributeId: null,
            orderBy: null,
            perUnitType: null,
            fieldTypeId: null,
            optionsValue: null,
            showLimitedOption: null,
            planAttributeName: null,
            unit: null,
            defaultValue: null,
            optionValueList: [],
            planValueId: null
          };
          data.id = element.planAttributeConfigId;
          data.typeName = element.typeName;
          data.symbol = element.symbol;
          data.fieldType = element.fieldType;
          data.defaultValue = element.defaultValue;
          if (data.typeName == 'currency' || data.typeName == 'memory') {
            data.planAttributeName = element.planAttributeName + " (" + data.symbol + ")";
          }
          else {
            data.planAttributeName = element.planAttributeName;
          }


          if (data.fieldType == 'number') {
            data.perUnitType = element.perUnitType;
            let unitTypeList = data.perUnitType.split(",");
            if (unitTypeList && unitTypeList.length > 0) {
              unitTypeList.forEach(element1 => {
                const data1 = { "label": element1, "value": element1 }
                data.optionValueList.push(data1);
                if (element.defaultOptionValue == element1) {
                  data.defaultOptionValue = element.defaultOptionValue;
                }
              });
            }
            this.PlanAttributeForm.addControl(data.id + "_input", this.fb.control('', Validators.required));
            this.PlanAttributeForm.addControl(data.id + "_dropdown", this.fb.control('', Validators.required));
            pagePropBind[data.id + "_input"] = data.defaultValue;
            pagePropBind[data.id + "_dropdown"] = data.defaultOptionValue;
          }
          else if (data.fieldType == 'checkbox') {
            data.optionsValue = element.optionsValue;
            let checkBoxList = data.optionsValue.split(",");
            checkBoxList.forEach(element2 => {
              const data1 = { "label": element2, "value": element2 }
              data.optionValueList.push(data1);
            });
            this.PlanAttributeForm.addControl(data.id, this.fb.control('', Validators.required));
            data.defaultOptionValue = element.defaultOptionValue;
            this[data.id] = [data.defaultOptionValue];
          }
          else if (data.fieldType == 'radiobutton') {
            data.optionsValue = element.optionsValue;
            let radioButtonList = data.optionsValue.split(",");
            radioButtonList.forEach(element3 => {
              const data1 = { "label": element3, "value": element3 }
              data.optionValueList.push(data1);
            });
            this.PlanAttributeForm.addControl(data.id, this.fb.control('', Validators.required));
            this[data.id] = element.defaultOptionValue;
          }
          else if (data.fieldType == 'boolean') {

            this.PlanAttributeForm.addControl(data.id, this.fb.control('', Validators.required));
            if (element.defaultOptionValue == "true") {
              this[data.id] = true;
            }
            else if (element.defaultOptionValue == "false") {
              this[data.id] = false;
            }

          }
          this.planAttributeList.push(data);
        });

      }
      this.PlanAttributeForm.patchValue(pagePropBind);
      this.planAttributeList = [...this.planAttributeList];

    });
  }
  checkValidation(item: any) {
    this.planAttributeList.forEach(element4 => {
      if (element4.fieldType == 'checkbox') {
        if (item == 'None') {
          if (this[element4.id].includes('None') && this[element4.id].length > 0) {
            this[element4.id].splice(0, this[element4.id].length - 1);
          }
        }
        else {
          element4 = this.checkChange(element4, item);//NOSONAR
        }
      }
    });
  }
  public checkChange(element4: any, item: any) {
    if (this[element4.id].length == 0) {
      this[element4.id].push('None');
    }
    else if (this[element4.id].length > 0) {
      if (item != 'None') {
        let index = this[element4.id].indexOf('None');
        if (index == 0) {
          this[element4.id].splice(index, 1);
        }
      }
    }
    return element4;
  }

  createPlanAttribute() {
    if (this.firstSave) {
      this.onEditPlanAttribute();
    } else {
      this.onSavePlanAttribute();
    }
  }
  onSavePlanAttribute(): void {
    this.planAttributeDto = [];
    const reqBody = {};
    if (this.planAttributeList && this.planAttributeList.length > 0) {
      this.planAttributeList.forEach((element: any = {}) => {
        const data = {
          planId: null,
          planAttributeConfigId: null,
          value: null,
          perUnitType: null,
        };
        data.planAttributeConfigId = element.id;
        data.planId = this.planId;
        if (element.fieldType == 'number') {
          data.value = this.PlanAttributeForm.value[element.id + "_input"];
          data.perUnitType = this.PlanAttributeForm.value[element.id + "_dropdown"];
        }

        else if (element.fieldType == 'checkbox') {
          data.value = this[element.id].toString();
        }
        else if (element.fieldType == 'radiobutton') {
          data.value = this[element.id];
        }
        else if (element.fieldType == 'boolean') {
          data.value = this[element.id].toString();

        }
        this.planAttributeDto.push(data);
      });
      this.planAttributeDto = [...this.planAttributeDto];
    }
    reqBody['planAttributeValueDto'] = this.planAttributeDto;
    const formValues = reqBody;
    this.messageFlag1 = true;
    this.pagesService.savePlanAttribute(formValues).subscribe({
      next: results => {
        const responseData = results?.success?.result;
        this.messageFlag1 = true;
        this.messageService.add({ key: 'myKey2', severity: 'success', detail: 'Plan attribute saved successfully.' });
        setTimeout(() => {
          this.messageFlag1 = false;
        }, 3000);
        this.planAttributeList.forEach(element1 => {
          responseData.forEach(element2 => {
            if (element1.id == element2.planAttributeConfigId) {
              element1.planValueId = element2.id;
            }
          });
        });
        this.setNewPlanAttributeValues(responseData);
        this.firstSave = true;
        this.enableBtn1 = true;
        this.tab2 = true;
        this.enableAddSusbcriptionBtn = false;
      },
      error: () => {
        this.messageFlag1 = true;
        this.messageService.add({ key: 'myKey2', severity: 'error', detail: 'Failed to save plan attribute.' });
        setTimeout(() => {
          this.messageFlag1 = false;
        }, 3000);
      }
    });

  }
  onEditPlanAttribute(): void {
    this.planAttributeDto = [];
    const reqBody = {};
    if (this.planAttributeList && this.planAttributeList.length > 0) {
      this.planAttributeList.forEach((element: any = {}) => {
        const data = {
          id: null,
          planId: null,
          planAttributeConfigId: null,
          value: null,
          perUnitType: null,
        };
        data.id = element.planValueId;
        data.planAttributeConfigId = element.id;
        data.planId = this.planId;
        if (element.fieldType == 'number') {
          data.value = this.PlanAttributeForm.value[element.id + "_input"];
          data.perUnitType = this.PlanAttributeForm.value[element.id + "_dropdown"];
        }

        else if (element.fieldType == 'checkbox') {
          data.value = this[element.id].toString();
        }
        else if (element.fieldType == 'radiobutton') {
          data.value = this[element.id];
        }
        else if (element.fieldType == 'boolean') {
          data.value = this[element.id].toString();

        }
        this.planAttributeDto.push(data);
      });
      this.planAttributeDto = [...this.planAttributeDto];
    }
    reqBody['editPlanAttributeValueDto'] = this.planAttributeDto;
    const formValues = reqBody;
    this.messageFlag1 = true;
    this.pagesService.editPlanAttribute(formValues).subscribe({
      next: results => {
        const responseData = results?.success?.result;
        this.messageFlag1 = true;
        this.messageService.add({ key: 'myKey2', severity: 'success', detail: 'Plan attribute saved successfully.' });
        setTimeout(() => {
          this.messageFlag1 = false;
        }, 3000);
        this.firstSave = true;
        this.enableBtn1 = true;
        this.tab2 = true;
        this.enableAddSusbcriptionBtn = false;
        this.setNewPlanAttributeValues(responseData);
      },
      error: () => {
        this.messageFlag1 = true;
        this.messageService.add({ key: 'myKey2', severity: 'error', detail: 'Failed to save plan attribute.' });
        setTimeout(() => {
          this.messageFlag1 = false;
        }, 3000);
      }
    });

  }
  checkOldNewValue() {
    if (this.firstSave) {
      if (this.planAttributeList && this.planAttributeList.length > 0) {
        this.changedValueFlag = true;
        this.planAttributeList.forEach((element: any = {}) => {
          if (element.fieldType == 'number') {

            if ((this[element.id + 'oldValueInput'] != this.PlanAttributeForm.value[element.id + "_input"]) || (this[element.id + 'oldValueDropdown'] != this.PlanAttributeForm.value[element.id + "_dropdown"])) {
              this.changedValueFlag = false;
            }

          }

          else if (element.fieldType == 'checkbox') {
            const assignList = this[element.id].filter(x => !this[element.id + '_oldCheckbox'].includes(x));
            if (assignList.length > 0) {
              this.changedValueFlag = false;
            }
          }
          else if (element.fieldType == 'radiobutton') {
            if (this[element.id + '_oldRadiobutton'] != this[element.id]) {
              this.changedValueFlag = false;
            }
          }
          else if (element.fieldType == 'boolean') {

            if (this[element.id + '_oldBoolean'] != this[element.id]) {
              this.changedValueFlag = false;
            }

          }

        });

        if (this.changedValueFlag) {
          this.enableBtn1 = true;
          this.enableAddSusbcriptionBtn = false;
        }
        else {
          this.enableBtn1 = false;
          this.enableAddSusbcriptionBtn = true;
        }

      }
    }


  }
  setNewPlanAttributeValues(responseData: any) {
    this.planAttributeList.forEach(element1 => {
      responseData.forEach(element2 => {
        if (element1.fieldType == 'number') {
          if (element1.id == element2.planAttributeConfigId) {
            this[element1.id + 'oldValueInput'] = element2.value;
            this[element1.id + 'oldValueDropdown'] = element2.perUnitType;
          }
        }
        else if (element1.fieldType == 'checkbox') {
          if (element1.id == element2.planAttributeConfigId) {
            this[element1.id + '_oldCheckbox'] = element2.value.split(",");
          }
        }
        else if (element1.fieldType == 'radiobutton') {
          if (element1.id == element2.planAttributeConfigId) {
            this[element1.id + '_oldRadiobutton'] = element2.value;
          }
        }
        if (element1.fieldType == 'boolean') {
          if (element1.id == element2.planAttributeConfigId) {
            if (element2.value == "false") {
              this[element1.id + '_oldBoolean'] = false;
            }
            else {
              this[element1.id + '_oldBoolean'] = true;
            }

          }
        }
      });
    });
  }
  addAsSubscription() {
    this.ref = this.dialogService.open(AddAsSubscriptionComponent, {
      header: 'Add As Subscription',
      data: { planId: this.planId },
      width: '38%',
      contentStyle: { 'max-height': '160px', 'border-radius': '4px', overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Failed') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Failed to delete user.' });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);

      }
      else if (msg) {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);

      }

    });
  }
  onAddDeployModel(): void {
    const reqBody = {};
    const accountDetails = {};
    const vpcDetails = {};
    const clusterDetails = {};
    const clusterRequirment = {};
    const externalStorage = {};
    const cacheStorage = {};
    const database = {};
    accountDetails['accountNumber'] = this.PlanAttributeForm.value.accountNumber;
    accountDetails['region'] = this.PlanAttributeForm.value.region;
    accountDetails['role'] = this.PlanAttributeForm.value.role;
    vpcDetails['vpcName'] = this.PlanAttributeForm.value.vpcName;
    vpcDetails['subnet'] = this.PlanAttributeForm.value.subnet;
    vpcDetails['vpcName'] = this.PlanAttributeForm.value.vpcName;
    clusterDetails['cluterName'] = this.PlanAttributeForm.value.clusterName;
    clusterRequirment['nodeFamily'] = this.PlanAttributeForm.value.nodeFamily;
    clusterRequirment['podautoscaling'] = this.PlanAttributeForm.value.enablePodAutoScalling;
    externalStorage['EFSStyle'] = this.PlanAttributeForm.value.efsStyle;
    externalStorage['EFSPath'] = this.PlanAttributeForm.value.efsPath;
    externalStorage['S3Style'] = this.PlanAttributeForm.value.s3Style;
    externalStorage['S3ARNRole'] = this.PlanAttributeForm.value.s3ArnRole;
    externalStorage['S3Secrect'] = this.PlanAttributeForm.value.s3Secret;
    externalStorage['S3Key'] = this.PlanAttributeForm.value.s3Key;
    externalStorage['S3SecrectVaultPath'] = this.PlanAttributeForm.value.s3SecretVaultPath;
    cacheStorage['REDISStyle'] = this.PlanAttributeForm.value.redisStyle;
    cacheStorage['REDISUrl'] = this.PlanAttributeForm.value.redisUrl;
    cacheStorage['REDISVaultPath'] = this.PlanAttributeForm.value.redisVaultPath;
    cacheStorage['REDISUsername'] = this.PlanAttributeForm.value.redisUsername;
    cacheStorage['REDISPassword'] = this.PlanAttributeForm.value.redisPassword;
    database['RDSStyle'] = this.PlanAttributeForm.value.rdsStyle;
    database['RDSHOST'] = this.PlanAttributeForm.value.rdsHost;
    database['RDSPORT'] = this.PlanAttributeForm.value.rdsPort;
    database['RDSUsername'] = this.PlanAttributeForm.value.rdsUsername;
    database['RDSPassword'] = this.PlanAttributeForm.value.rdsPassword;
    database['RDSType'] = this.PlanAttributeForm.value.rdsType;
    database['RDSDBName'] = this.PlanAttributeForm.value.rdsDBName;
    database['RDSVaultPath'] = this.PlanAttributeForm.value.rdsVaultPath;
    database['RDSFamily'] = this.PlanAttributeForm.value.rdsFamily;

    reqBody['type'] = this.PlanAttributeForm.value.deploymentType;
    reqBody['subType'] = this.PlanAttributeForm.value.deploymentSubType;

    reqBody['accountDetails'] = accountDetails;
    reqBody['vpcDetails'] = vpcDetails;
    reqBody['clusterDetails'] = clusterDetails;
    reqBody['clusterRequirment'] = clusterRequirment;
    reqBody['externalStorage'] = externalStorage;
    reqBody['cacheStorage'] = cacheStorage;
    reqBody['database'] = database;

    const reqJson = JSON.stringify(reqBody);
    const mainReq = {};
    mainReq['planId'] = this.planId;
    mainReq['json'] = reqJson;
    // console.log(mainReq);
    const formValues = mainReq;
    this.messageFlag1 = true;
    this.pagesService.addDeploymentModel(formValues).subscribe({
      next: results => {
        const responseData = results?.success?.result;
        // this.messageFlag1 = true;
        // this.messageService.add({ key: 'myKey2', severity: 'success', detail: 'Plan attribute saved successfully.' });
        // setTimeout(() => {
        //   this.messageFlag1 = false;
        // }, 3000);
        // this.planAttributeList.forEach(element1 => {
        //   responseData.forEach(element2 => {
        //     if (element1.id == element2.planAttributeConfigId) {
        //       element1.planValueId = element2.id;
        //     }
        //   });
        // });
        // this.setNewPlanAttributeValues(responseData);
      },
      error: () => {
        this.messageFlag1 = true;
        this.messageService.add({ key: 'myKey2', severity: 'error', detail: 'Failed to save deployment model.' });
        setTimeout(() => {
          this.messageFlag1 = false;
        }, 3000);
      }
    });
  }
}