<div>
    <div class="p-d-flex p-jc-end">
        <p-messages key="myKey1" class="custom-alert-box" *ngIf="this.messageFlag"></p-messages>
    </div>
    <div class="p-col-fixed p-mt-1 p-ml-1 p-pr-0 custom-breadcrumb">
        <p-breadcrumb *ngIf="!firstSave" [model]="ccprimebreadcrumb1List" [home]="homeiconccprimebreadcrumb1">
        </p-breadcrumb>
        <p-breadcrumb *ngIf="firstSave" [model]="ccprimebreadcrumb1List1" [home]="homeiconccprimebreadcrumb1">
        </p-breadcrumb>
    </div>
    <form class="layout-content custom-form-layout" [formGroup]="AddUserGroupForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6 p-offset-0 left">
                <h5 class="custom-head1" *ngIf="!firstSave">Add New Group</h5>
                <h5 class="custom-head1" *ngIf="firstSave">Edit Group</h5>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6 p-offset-0 left">
                <div class="   ">
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                <p-card>
                    <div class="p-p-2 ">
                        <button pButton type="button" (click)="activeIndex1 = 0"
                            class="p-button-outlined p-button-lg tabButton group-identity" label="Group Identity"
                            [style.box-shadow]="activeIndex1 == 0 ? '1px 5px 20px #bdc0c9' :  '0px 1px 4px #bdc0c9'"
                            [style.border]="activeIndex1 == 0 ? '1px solid  #396FC5' :  '#add8e6'"
                            [style.color]=" activeIndex1 == 0 ? '#396FC5' : '#6C7387'">
                            <div class="fa-2x">
                                <span class="fa-layers fa-fw">
                                    <label class="c-icons identity-icon"></label>
                                </span>
                            </div>
                            <span class="p-button-icon p-button-icon-left reshot-icon-business-team"
                                aria-hidden="true"></span>
                            <label class="d-icons check-icon group-identity-label" width="14" height="14"
                                *ngIf="tab1"></label>
                        </button>
                        <button pButton type="button" [disabled]="clicked2" (click)="activeIndex1 = 1"
                            label="Composite Role" id="panelbtn2"
                            class="p-button-outlined p-button-lg tabButton composite-role" *ngIf="false"
                            [style.box-shadow]="activeIndex1 == 1 ? '1px 5px 20px #bdc0c9' :  '0px 1px 4px #bdc0c9'"
                            [style.border]="activeIndex1 == 1 ? '1px solid  #396FC5' :  '#add8e6'"
                            [style.color]=" activeIndex1 == 1 ? '#396FC5' : '#6C7387'">
                            <div class="fa-2x">
                                <span class="fa-layers fa-fw">
                                    <label class="c-icons composite-icon"></label>
                                </span>
                            </div>
                            <span class="p-button-icon p-button-icon-left reshot-icon-business-team"
                                aria-hidden="true"></span>
                            <label class="d-icons check-icon composite-role-label" width="14" height="14"
                                *ngIf="tab2"></label>
                        </button>
                        <button pButton type="button" [disabled]="clicked" (click)="activeIndex1 = 2" label="Users"
                            id="panelbtn" class="p-button-outlined p-button-lg tabButton Users"
                            [style.box-shadow]="activeIndex1 == 2 ? '1px 5px 20px #bdc0c9' :  '0px 1px 4px #bdc0c9'"
                            [style.border]="activeIndex1 == 2 ? '1px solid  #396FC5' :  '#add8e6'"
                            [style.color]=" activeIndex1 == 2 ? '#396FC5' : '#6C7387'">
                            <div class="fa-2x">
                                <span class="fa-layers fa-fw">
                                    <label class="c-icons user-icon"></label>
                                </span>
                            </div>
                            <span class="p-button-icon p-button-icon-left reshot-icon-business-team"
                                aria-hidden="true"></span>
                            <label class="d-icons check-icon users-label" width="14" height="14" *ngIf="tab3"></label>
                        </button>
                        <button pButton type="button" [disabled]="clicked" (click)="activeIndex1 = 3" label="Roles"
                            id="panelbtn" class="p-button-outlined p-button-lg tabButton roles"
                            [style.box-shadow]="activeIndex1 == 3 ? '1px 5px 20px #bdc0c9' :  '0px 1px 4px #bdc0c9'"
                            [style.border]="activeIndex1 == 3 ? '1px solid  #396FC5' :  '#add8e6'"
                            [style.color]=" activeIndex1 == 3 ? '#396FC5' : '#6C7387'">
                            <div class="fa-2x">
                                <span class="fa-layers fa-fw">
                                    <label class="c-icons role-icon"></label>
                                </span>
                            </div>
                            <span class="p-button-icon p-button-icon-left reshot-icon-business-team"
                                aria-hidden="true"></span>
                            <label class="d-icons check-icon roles-label" width="14" height="14" *ngIf="tab4"></label>
                        </button>
                    </div>
                    <div class="tabview-custom">
                        <p-tabView [(activeIndex)]="activeIndex1" styleClass="tabview-custom">
                            <p-tabPanel>
                                <div class="p-fluid p-formgrid p-grid p-mt-3">
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left">
                                        <div class=" p-fluid   p-field    ">
                                            <label
                                                [ngClass]="{'field-required': AddUserGroupForm.get('displayName')?.errors?.required}">
                                                Group Name
                                            </label> <span class='invalid-feedback-astrik'>*</span>
                                            <input type="text" id="displayName_id" name="displayName_name"
                                                formControlName="displayName" pInputText placeholder="Group name"
                                                class="p-inputtext inputtextHeight"
                                                pattern="^([a-zA-Z0-9_@./#&+-]+\s)*[a-zA-Z0-9_@./#&+-]+$" maxlength="50"
                                                (keyup)="this.nameFlag=false;enableBtn1=false;colorChange();" />
                                            <span id="lblError" class="p-error"></span>
                                            <div class="p-error"
                                                *ngIf="AddUserGroupForm.get('displayName')!.invalid && (AddUserGroupForm.get('displayName')!.dirty || AddUserGroupForm.get('displayName')!.touched)">
                                                <div *ngIf="AddUserGroupForm.get('displayName').errors.pattern">
                                                    <label class="alert-icon alert-circle-icon" for="rolename"></label>
                                                    <span class="p-error">Fill the Group Name properly</span>
                                                </div>
                                                <label *ngIf="AddUserGroupForm.get('displayName')?.errors?.required"
                                                    class="p-error">
                                                    <div>
                                                        <label class="alert-icon alert-circle-icon"
                                                            for="rolename"></label>
                                                        <span id="rolename"
                                                            style="display:inline-block;vertical-align: bottom;"> Group
                                                            name is required!
                                                        </span>
                                                    </div>
                                                </label>
                                            </div>
                                            <div class="p-error">
                                                <label *ngIf="this.nameFlag">
                                                    <div>
                                                        <label class="alert-icon alert-circle-icon"
                                                            for="rolename"></label>
                                                        <span id="rolename"
                                                            style="display:inline-block;vertical-align: bottom;"
                                                            class="p-error"> Group name already exists!
                                                        </span>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left"
                                        style="display: none;">
                                        <h6>Type</h6>
                                        <div class="prime-selectbutton-override">
                                            <p-selectButton id="groupType" name="groupType" formControlName="groupType"
                                                [options]="stateOptions" optionLabel="label"
                                                optionValue="value"></p-selectButton>
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                                        <div class=" p-fluid   p-field    ">
                                            <label>
                                                Description
                                            </label>
                                            <textarea pInputTextarea id="description_id" name="description_name"
                                                formControlName="description" rows="3" placeholder="Add description"
                                                maxlength="200" (keyup)="enableBtn1=false"></textarea>
                                        </div>
                                    </div>
                                    <div
                                        class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right p-mb-2">
                                        <div class="btnwrap p-mr-3" *ngIf="!this.firstSave">
                                            <button pButton type="button" class="p-button-brand2"
                                                (click)="this.AddUserGroupForm.reset();" label="Clear">
                                            </button>
                                        </div>
                                        <div class="btnwrap">
                                            <button pButton class="p-button-primary" type="submit"
                                                (click)="createGroup(AddUserGroupForm);" label="Save"
                                                [disabled]="nameFlag ||enableBtn1 || !AddUserGroupForm.valid">
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </p-tabPanel>
                            <p-tabPanel>
                                <div class="p-fluid p-formgrid p-grid p-mt-3">
                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-0 left">
                                        <div class=" p-fluid   p-field    ">
                                            <label>
                                                Group
                                            </label>
                                            <div class="p-fluid  p-field">
                                                <p-dropdown id="selectRoleid" name="selectRole"
                                                    placeholder="Select role" [options]="selectRoleList"
                                                    [autoDisplayFirst]="false" formControlName="selectRole">
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-undefined left">
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-undefined left">
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 p-right p-mb-2">
                                        <div class="btnwrap">
                                            <button pButton class="p-button-primary" type="button"
                                                (click)="onCcprimebutton19(AddUserGroupForm);" label="Save">
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </p-tabPanel>
                            <p-tabPanel>
                                <div class="p-fluid p-formgrid p-grid p-mt-3">
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left">
                                        <div class=" p-fluid   p-field    ">
                                            <label>
                                                Users
                                            </label>
                                            <div class="p-fluid  p-field">
                                                <p-multiSelect id="selectUsersid" name="selectUsers"
                                                    filterPlaceHolder="Select Users" [options]="userListList"
                                                    [filter]="true" formControlName="userList" class="custom-chips"
                                                    optionLabel="label" optionValue="value"
                                                    selectedItemsLabel="{0} Users selected" display="chip"
                                                    resetFilterOnHide="true" placeholder="Select Users"
                                                    (onChange)="enableBtn2=false">
                                                    <ng-template let-each pTemplate="selectedItem">
                                                        {{each.label}}
                                                    </ng-template>
                                                    <ng-template let-items pTemplate="item">
                                                        <div *ngIf="items?.label?.length <= 35;">
                                                            {{items.label}}
                                                        </div>
                                                        <div *ngIf="items?.label?.length > 35;"
                                                            pTooltip="{{items.label}}" tooltipPosition="bottom">
                                                            {{items.label.substring(0,35) + "..." }}
                                                        </div>
                                                    </ng-template>
                                                </p-multiSelect>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                                    </div>
                                    <div
                                        class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right p-mb-2">
                                        <div class="btnwrap">
                                            <button pButton class="p-button-primary" type="button"
                                                [disabled]="enableBtn2"
                                                (click)="onAssignUsersToGroup(AddUserGroupForm);" label="Save">
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </p-tabPanel>
                            <p-tabPanel>
                                <div class="p-fluid p-formgrid p-grid p-mt-3">
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left">
                                        <div class=" p-fluid   p-field    ">
                                            <label>
                                                Roles
                                            </label>
                                            <div class="p-fluid  p-field">
                                                <p-multiSelect id="roleList" name="roleList"
                                                    filterPlaceHolder="Select Roles" [options]="roleListList"
                                                    [filter]="true" formControlName="roleList" class="custom-chips"
                                                    optionLabel="label" optionValue="value"
                                                    selectedItemsLabel="{0} Roles selected" display="chip"
                                                    resetFilterOnHide="true" placeholder="Select Roles"
                                                    (onChange)="enableBtn3=false">
                                                    <ng-template let-each pTemplate="selectedItem">
                                                        {{each.label}}
                                                    </ng-template>
                                                    <ng-template let-items pTemplate="item">
                                                        <div *ngIf="items?.label?.length <= 35;">
                                                            {{items.label}}
                                                        </div>
                                                        <div *ngIf="items?.label?.length > 35;"
                                                            pTooltip="{{items.label}}" tooltipPosition="bottom">
                                                            {{items.label.substring(0,35) + "..." }}
                                                        </div>
                                                    </ng-template>
                                                </p-multiSelect>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                                    </div>
                                    <div
                                        class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right p-mb-2">
                                        <div class="btnwrap">
                                            <button pButton class="p-button-primary" type="button"
                                                [disabled]="enableBtn3" (click)="assignRolesToGroup(AddUserGroupForm);"
                                                label="Save">
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </p-tabPanel>
                        </p-tabView>
                    </div>
                </p-card>
            </div>
        </div>
    </form>
</div>