import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PagesService } from '../pages.service';
import { validateAllFormFields } from 'app/shared/util/reactiveform-util';
import { MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
interface City {
  code: string;
}
@Component({
  selector: 'jhi-mirroruser',
  templateUrl: './MirrorUser.component.html',
  styleUrls: ['./MirrorUser.component.scss'],
  providers: [MessageService, DatePipe],
})
export class MirrorUserComponent implements OnInit {
  MirrorUserForm = this.fb.group({
    uid: [null, []],
    firstName: [null, [Validators.required]],
    middleName: ['', []],
    lastName: [null, [Validators.required]],
    emailAddress: [null, [Validators.required, Validators.email, Validators.required]],
    phoneNumbers: [null, []],
    select2: [null, []],
    select4: [null, []],
    roleList: [null, []],
    groupList: [null, []],
    countryCode: [null, []],
    selectSwitch: [null, []]
  });
  form: UntypedFormGroup | undefined;
  pageloadvalues: any;
  ccprimebreadcrumbList1 = [{ label: `User Management` }, { label: `Users`, routerLink: ['/pages/userlisting'] }, { label: `Edit User` }];
  ccprimebreadcrumbList = [{ label: `User Management` }, { label: `Users`, routerLink: ['/pages/userlisting'] }, { label: `Mirror User` }];
  homeiconccprimebreadcrumb1 = { icon: 'pi pi-home', routerLink: ['/'] };
  select2List: any[] = [];
  select4List: any[] = [];
  messageFlag = true;
  cities: City[];
  activeIndex1: number = 0;
  clicked1 = false;
  clicked = true;
  activeIndex2: number = 0;
  roleListList: any[] = [];
  groupListList: any[] = [];
  selectedRole: any[] = [];
  selectedGroup: any[] = [];
  exRoleList: any[] = [];
  newRoleList: any[] = [];
  exGroupList: any[] = [];
  newGroupList: any[] = [];
  savedId: any;
  roleID: any;
  data: any;
  navigation: any;
  userID = '';
  tab1 = false;
  tab2 = false;
  tab3 = false;
  firstSave = false;
  emailFlag: boolean = false;
  assignGroupCount = 0;
  deAssignGroupCount = 0;
  assignRoleCount = 0;
  deAssignRoleCount = 0;
  enableBtn1 = true;
  enableBtn2 = true;
  enableBtn3 = true;
  msg: string[] = [];
  msg1: string[] = [];
  codes: any[] = [];
  phoneID: any;
  emailInvalidFlag: boolean = false;
  codeFlag = false;
  phoneNumberFlag = false;
  selectSwitchList: any[] = [{ 'label': "Internal", 'value': 'false' }, { 'label': "External", 'value': 'true' },];
  //new
  focusDirectClicked = false;
  customJSON: any;
  customList: any = [];
  fieldTypeList: any = [];
  cnt = 0;
  checked = false;
  value4: string;
  moduleID = "4824dd4c-3b3f-11ed-a261-0242ac120002";
  checkboxArr: any = [];
  customFieldDto = [];
  date3: any;
  customFieldValues = [];
  someArr: any = [];
  oldFirstName: any
  oldMiddleName: any;
  oldLastName: any;
  oldPhoneId: any;
  oldCountryCode: any;
  fullNameFlag = false;
  oldPhoneNumber: any;
  wfFlag = false;
  defaultselect_selectSwitch: any;
  userType: any;
  isExternal: any;
  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    private messageService: MessageService,
    public datepipe: DatePipe
  ) {
    this.navigation = this.router.getCurrentNavigation();
    this.data = this.navigation?.extras?.state?.data;
    if (this.data) {
      this.setPageProperties(JSON.parse(this.data));
    }
    this.setDefaultValue_selectSwitch('false');
  }

  ngOnInit(): void {
    this.onMirrorUserPageLoad();
    this.getroleList();
    this.getgroupList();
    this.getCountryCode();
    this.fetchCustomField();
  }

  //country code API
  getCountryCode() {
    this.pagesService.getCountryCode().subscribe(
      results => {
        const responseData = results.success.result;
        if (responseData && responseData.length > 0) {
          responseData.forEach((element: any = {}) => {
            const data = {
              label: null,
              value: null,
              name: null,
            };
            data.label = element.countryCode;
            data.value = element.countryCode;
            data.name = element.countryName;
            this.codes.push(data);
          });
          this.codes = [...this.codes];
        }
      });
  }
  setDefaultValue_selectSwitch(value?: any) {
    if (this.selectSwitchList.length && value) {
      this.defaultselect_selectSwitch = value;
      return this.defaultselect_selectSwitch;
    }
    this.defaultselect_selectSwitch = this.selectSwitchList[0]?.value;
    return this.defaultselect_selectSwitch
  }

  createUser($event: UntypedFormGroup) {
    if (this.firstSave) {
      this.onEditUser($event);
    } else {
      this.onCheckEmail($event);
    }
  }

  onClear($event: UntypedFormGroup): void {
    $event.reset();
    document.getElementById('emailAddress_id')!.style.borderColor = 'grey';
  }

  onCheckEmail($event: UntypedFormGroup): void {
    const reqBody = this.MirrorUserForm.value.emailAddress;
    const formData = reqBody;
    const formValues = this.MirrorUserForm.value.emailAddress;
    this.pagesService.onCheckEmailId(formData, formValues).subscribe(
      results => {
        if (results?.error?.messageCode == 'CIAM 105' || results?.success?.messageCode == 'CIAM 105') {
          this.emailFlag = true;
          this.enableBtn1 = true;
          document.getElementById('emailAddress_id')!.style.borderColor = 'red';
        }
        if (results?.error?.messageCode == 'CIAM 201' || results?.success?.messageCode == 'CIAM 201') {
          this.emailInvalidFlag = true;
          this.enableBtn1 = true;
          document.getElementById('emailAddress_id')!.style.borderColor = 'red';
        }
        else if (results.success.code == 200) {
          this.emailFlag = false;
          this.enableBtn1 = false;
          document.getElementById('emailAddress_id')!.style.borderColor = 'grey';
          this.onMirrorUser($event);
        }
      },
    );
  }

  CheckNumber(codeFlag: any) {
    this.codeFlag = false;
    this.phoneNumberFlag = false;
    if (codeFlag == "setCode" && (this.MirrorUserForm.value.phoneNumbers == null || this.MirrorUserForm.value.phoneNumbers == "") && (this.MirrorUserForm.value.countryCode == "" || this.MirrorUserForm.value.countryCode == null)) {
      this.enableBtn1 = false;
    }
    else if (codeFlag == "setNumber" && (this.MirrorUserForm.value.countryCode == null || this.MirrorUserForm.value.countryCode == "") && (this.MirrorUserForm.value.phoneNumbers == null || this.MirrorUserForm.value.phoneNumbers == "")) {
      this.enableBtn1 = false;
    }
    else if (codeFlag == "setCode" && this.MirrorUserForm.value.phoneNumbers == null || this.MirrorUserForm.value.phoneNumbers == "") {
      this.enableBtn1 = true;
      this.phoneNumberFlag = true;
    }

    else if ((codeFlag == "setNumber" && this.MirrorUserForm.value.countryCode == null || this.MirrorUserForm.value.countryCode == "") || (this.MirrorUserForm.value.countryCode == null && (this.MirrorUserForm.value.phoneNumbers != null || this.MirrorUserForm.value.phoneNumbers != ""))) {
      this.enableBtn1 = true;
      this.codeFlag = true;
    }
    else if (this.MirrorUserForm.value.countryCode != null && (this.MirrorUserForm.value.phoneNumbers != null || this.MirrorUserForm.value.phoneNumbers != "")) {
      this.enableBtn1 = false;
    }

    if (this.MirrorUserForm.get('phoneNumbers').errors?.pattern) {
      this.codeFlag = false;
      this.phoneNumberFlag = false;
    }
  }

  //creating mirror user API
  onMirrorUser($event: UntypedFormGroup): void {
    $event.updateValueAndValidity();
    if (!$event.valid) {
      validateAllFormFields($event);
      return;
    }
    const reqBody = {};
    reqBody['tenantId'] = this.pagesService.tenantId;
    if (this.MirrorUserForm.value.phoneNumbers == null || this.MirrorUserForm.value.phoneNumbers == "" && this.MirrorUserForm.value.countryCode == null) {
      reqBody['phoneNumbers'] = [];
    }
    else {
      reqBody['phoneNumbers'] = [{ value: this.MirrorUserForm.value.phoneNumbers, countryCode: this.MirrorUserForm.value.countryCode, primary: true }];
    }
    reqBody['first_name'] = this.MirrorUserForm.value.firstName;
    reqBody['middle_name'] = this.MirrorUserForm.value.middleName;
    reqBody['last_name'] = this.MirrorUserForm.value.lastName;
    reqBody['user_name'] = this.MirrorUserForm.value.emailAddress;
    const fname = this.MirrorUserForm.value.firstName + ' ' + this.MirrorUserForm.value.middleName + ' ' + this.MirrorUserForm.value.lastName;
    reqBody['full_name'] = fname.replace(/\s+/g, ' ').trim();
    reqBody['emails'] = [{ value: this.MirrorUserForm.value.emailAddress, type: 'work', primary: true }];
    reqBody['isExternal'] = this.MirrorUserForm.value.selectSwitch;
    reqBody['customFieldDataDtoList'] = [];
    if (this.customList && this.customList.length > 0) {
      this.customList.forEach((element: any = {}) => {
        const data = {
          customFieldId: null,
          customFieldLabel: null,
          customFieldValue: null,
        };
        data.customFieldId = element.id;
        data.customFieldLabel = element.fieldName;
        if (element.fieldInputtype == 'Dropdown') {
          let cc = this.MirrorUserForm.value[element.id];
          if (cc == "" || cc == null) {
            //  dont send
          }
          else {
            data.customFieldValue = cc.label;
          }

        }
        else if (element.inputFormat[0] == 'Date range') {
          data.customFieldValue = JSON.stringify(this.MirrorUserForm.value[element.id]);
        }
        else if (element.inputFormat[0] == 'Text/Number range') {
          const reqRange = {};
          reqRange['start'] = this.MirrorUserForm.value[element.id + "_start"];
          reqRange['last'] = this.MirrorUserForm.value[element.id + "_end"];
          if (reqRange['start'] == null || reqRange['last'] == null) {

            data.customFieldValue = null;
          }
          else {
            data.customFieldValue = JSON.stringify(reqRange);
          }
        }
        else {
          data.customFieldValue = this.MirrorUserForm.value[element.id];
        }
        if (data.customFieldValue == null || data.customFieldValue == "") {
          //dont push
        }
        else {
          this.customFieldDto.push(data);
        }
      });
      this.customFieldDto = [...this.customFieldDto];
    }

    if (this.checkboxArr && this.checkboxArr.length > 0) {
      this.checkboxArr.forEach((element: any = {}) => {
        const data = {
          customFieldId: null,
          customFieldLabel: null,
          customFieldValue: null,
        };
        data.customFieldId = element.id;
        data.customFieldLabel = element.fieldName;
        if (data.customFieldValue == null || !data.customFieldValue) {
          data.customFieldValue = "false";
        }
        else {
          data.customFieldValue = "true";
        }
        this.customFieldDto.push(data);
      });
      this.customFieldDto = [...this.customFieldDto];
    }
    reqBody['customFieldDataDtoList'] = this.customFieldDto;
    this.newRoleList = this.MirrorUserForm.value.roleList;
    const deassignList = [];

    const formData = reqBody;
    this.newGroupList = this.MirrorUserForm.value.groupList;
    const greqBody = {};
    greqBody['assignList'] = this.newGroupList;
    greqBody['deAssignList'] = [];
    this.messageFlag = true;
    this.pagesService.onAddUser(formData).subscribe(
      {
        next: results => {
          this.oldFirstName = results?.success?.result?.first_name;
          this.oldMiddleName = results?.success?.result?.middle_name;
          this.oldLastName = results?.success?.result?.last_name;
          this.oldPhoneNumber = results?.success?.result?.phoneNumbers[0]?.value
          this.oldPhoneId = results?.success?.result?.phoneNumbers[0]?.id;
          this.oldCountryCode = results?.success?.result?.phoneNumbers[0]?.countryCode;
          this.savedId = results.success.result.id;
          this.phoneID = results.success.result.phoneNumbers[0]?.id;
          this.messageFlag = true;
          this.clicked1 = false;
          this.MirrorUserForm.controls['emailAddress'].disable();
          this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'User Mirror Sucessfully.' });
          setTimeout(() => {
            this.messageFlag = false;
          }, 3000);

          this.clicked1 = true;
          this.firstSave = true;
          this.clicked = false;
          this.tab1 = true;
          this.enableBtn1 = true;
          this.pagesService.onSaveAssignGroup(greqBody, this.savedId).subscribe(
            resultss => {
              this.tab3 = true;
              this.getExGroupList();
            }
          );
          const reqsBody = {};
          reqsBody['tenantId'] = this.pagesService.tenantId;
          reqsBody['userId'] = this.savedId;
          reqsBody['assignList'] = this.newRoleList;
          reqsBody['deAssignList'] = deassignList;
          this.pagesService.onSaveAssignRolesToUser(reqsBody).subscribe(
            resultss => {
              this.tab2 = true;
              this.getExRoleList();
            }
          );
        },
        error: error => {
          if (error.error?.error?.messageCode == "CIAM 105") {
            this.messageService.add({ key: 'myKey1', severity: 'error', detail: "Request already available for approval" });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
          }
          else {
            this.messageFlag = true;
            this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Failed to create user.' });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
          }
        }
      });
  }

  // edit existing user
  onEditUser($event: UntypedFormGroup): void {
    this.customFieldDto = [];
    this.someArr = [];
    this.customFieldValues = [];
    $event.updateValueAndValidity();
    if (!$event.valid) {
      validateAllFormFields($event);
      return;
    }
    const reqBody = {};
    reqBody['tenantId'] = this.pagesService.tenantId;
    if ((this.MirrorUserForm.value.phoneNumbers == null || this.MirrorUserForm.value.phoneNumbers == "") && this.MirrorUserForm.value.countryCode == null) {
      reqBody['phoneNumbers'] = [];
    }
    else if (this.oldPhoneNumber != this.MirrorUserForm.value.phoneNumbers) {
      reqBody['phoneNumbers'] = [{ countryCode: this.MirrorUserForm.value.countryCode, id: this.MirrorUserForm.value.phoneID, value: this.MirrorUserForm.value.phoneNumbers }];
    }
    if ((this.MirrorUserForm.value.phoneNumbers == null || this.MirrorUserForm.value.phoneNumbers == "") && this.MirrorUserForm.value.countryCode == null) {
      reqBody['phoneNumbers'] = [];
    }
    else if (this.oldCountryCode != this.MirrorUserForm.value.countryCode) {
      reqBody['phoneNumbers'] = [{ countryCode: this.MirrorUserForm.value.countryCode, id: this.MirrorUserForm.value.phoneID, value: this.MirrorUserForm.value.phoneNumbers }];
    }
    if (this.oldFirstName != this.MirrorUserForm.value.firstName) {
      this.fullNameFlag = true;
      reqBody['first_name'] = this.MirrorUserForm.value.firstName;
    }
    if (this.oldMiddleName != this.MirrorUserForm.value.middleName) {
      this.fullNameFlag = true;
      if (this.MirrorUserForm.value.middleName == null) {
        reqBody['middle_name'] = " ";
        this.MirrorUserForm.value.middleName = " ";
      }
      else {
        reqBody['middle_name'] = this.MirrorUserForm.value.middleName;
      }
    }
    if (this.oldLastName != this.MirrorUserForm.value.lastName) {
      this.fullNameFlag = true;
      reqBody['last_name'] = this.MirrorUserForm.value.lastName;
    }
    if (this.fullNameFlag) {
      const fname = this.MirrorUserForm.value.firstName + ' ' + this.MirrorUserForm.value.middleName + ' ' + this.MirrorUserForm.value.lastName;
      reqBody['full_name'] = fname.replace(/\s+/g, ' ').trim();
    }
    reqBody['isExternal'] = this.MirrorUserForm.value.selectSwitch;
    reqBody['customFieldDataDtoList'] = [];
    if (this.customList && this.customList.length > 0) {
      this.customList.forEach((element: any = {}) => {
        const data = {
          customFieldId: null,
          customFieldLabel: null,
          customFieldValue: null,
        };
        data.customFieldId = element.id;
        data.customFieldLabel = element.fieldName;
        if (element.fieldInputtype == 'Dropdown') {
          let cc = this.MirrorUserForm.value[element.id];
          if (cc == "" || cc == null) {
            //  dont send
          }
          else {
            data.customFieldValue = cc.label;
          }

        }
        else if (element.inputFormat[0] == 'Date range') {
          data.customFieldValue = JSON.stringify(this.MirrorUserForm.value[element.id]);
        }
        else if (element.inputFormat[0] == 'Text/Number range') {
          const reqRange = {};
          reqRange['start'] = this.MirrorUserForm.value[element.id + "_start"];
          reqRange['last'] = this.MirrorUserForm.value[element.id + "_end"];
          if (reqRange['start'] == null || reqRange['last'] == null) {

            data.customFieldValue = null;
          }
          else {
            data.customFieldValue = JSON.stringify(reqRange);
          }
        }
        else {
          data.customFieldValue = this.MirrorUserForm.value[element.id];
        }
        if (data.customFieldValue == null || data.customFieldValue == "") {
          // dont push
        }
        else {
          this.customFieldDto.push(data);
        }
      });
      this.customFieldDto = [...this.customFieldDto];
    }

    if (this.checkboxArr && this.checkboxArr.length > 0) {
      this.checkboxArr.forEach((element: any = {}) => {
        const data = {
          customFieldId: null,
          customFieldLabel: null,
          customFieldValue: null,
        };
        data.customFieldId = element.id;
        data.customFieldLabel = element.fieldName;
        if (data.customFieldValue == null || !data.customFieldValue) {
          data.customFieldValue = "false";
        }
        else {
          data.customFieldValue = "true";
        }
        this.customFieldDto.push(data);
      });
      this.customFieldDto = [...this.customFieldDto];
    }
    reqBody['customFieldDataDtoList'] = this.customFieldDto;
    const formData = reqBody;
    this.messageFlag = true;
    this.pagesService.onEditUser(formData).subscribe(
      {
        next: results => {
          this.messageFlag = true;
          this.clicked1 = false;
          this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'User edited successfully.' });
          setTimeout(() => {
            this.messageFlag = false;
          }, 3000);
          this.tab1 = true;
          this.enableBtn1 = true;
        },
        error: () => {
          this.messageFlag = true;
          this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Failed to Edit User.' });
          setTimeout(() => {
            this.messageFlag = false;
          }, 3000);
        }
      });
  }

  onMirrorUserPageLoad() {
    this.pagesService.onMirrorUserPageLoad(this.userID).subscribe((results: any) => {
      let pagePropBind = {

        roleList: results.success.result.roles,
        groupList: results.success.result.groups,
      };
      this.pageloadvalues = pagePropBind;
      this.MirrorUserForm.patchValue(pagePropBind);
      this.getSelectRole();
      this.getSelectGroup();
    });
  }

  setPageProperties(props: any): void {
    const pageData: any = {};
    const calendarControls = ['createdOn'];
    Object.keys(props).forEach(key => {
      if (calendarControls.includes(key)) {
        pageData[key] = new Date(props[key]);
      } else {
        pageData[key] = props[key];
      }
    });
    if (pageData.mirrorFlag == 'viewMirror') {
      this.userID = pageData.viewUserID;
    }
    else {
      this.userID = pageData.id;
    }
    pageData.phoneNumbers = null;
    pageData.firstName = "";
    pageData.middleName = "";
    pageData.lastName = "";
    this.MirrorUserForm.patchValue(pageData);
  }

  onCcprimebutton2($event: UntypedFormGroup): void { }
  onCcprimebutton4($event: UntypedFormGroup): void {
    this.router.navigate(['/pages/addroles']);
  }
  onCcprimebutton10($event: UntypedFormGroup): void { }
  onCcprimebutton11($event: UntypedFormGroup): void { }
  onCcprimebutton14($event: UntypedFormGroup): void { }
  onCcprimebutton15($event: UntypedFormGroup): void { }

  getroleList(): void {
    this.pagesService.gettableDataRoles().subscribe(results => {
      const responseData = results.success.result.records;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.displayName;
          data.value = element.id;
          this.roleListList.push(data);
        });
        this.roleListList = [...this.roleListList];
      }
    });
  }
  getgroupList(): void {
    this.pagesService.gettableDataListGroups().subscribe(results => {
      const responseData = results.success.result.records;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.displayName;
          data.value = element.id;
          this.groupListList.push(data);
        });
        this.groupListList = [...this.groupListList];
      }
    });
  }
  getSelectRole(): void {
    this.selectedRole = [];
    const responseData = this.MirrorUserForm.value.roleList;
    if (responseData && responseData.length > 0) {
      responseData.forEach((element: any = {}) => {
        const data = {
          label: null,
          value: null,
        };
        data.label = element.displayName;
        data.value = element.id;
        this.selectedRole.push(data.value);
      });
      this.selectedRole = [...this.selectedRole];
    }
  }
  getSelectGroup(): void {
    this.selectedGroup = [];
    const responseData = this.MirrorUserForm.value.groupList;
    if (responseData && responseData.length > 0) {
      responseData.forEach((element: any = {}) => {
        const data = {
          label: null,
          value: null,
        };
        data.label = element.displayName;
        data.value = element.id;
        this.selectedGroup.push(data.value);
      });
      this.selectedGroup = [...this.selectedGroup];
    }
  }
  getExRoleList() {
    this.pagesService.getViewUserRoles(this.savedId).subscribe((results: any) => {
      const responseData = results.success.result;
      this.roleID = results.success.result.roles.id;
      this.exRoleList = [];
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.displayName;
          data.value = element.id;
          this.exRoleList.push(data.value);
        });
        this.exRoleList = [...this.exRoleList];
      }
    });
  }
  getNewRoleList() {
    const responseData = this.MirrorUserForm.value.roleList;
    this.newRoleList = [];
    if (responseData && responseData.length > 0) {
      responseData.forEach((element: any = {}) => {
        const data = {
          label: null,
          value: null,
        };
        data.label = element.displayName;
        data.value = element.id;
        this.newRoleList.push(data.value);
      });
      this.newRoleList = [...this.newRoleList];
    }
  }
  assignRoles($event: UntypedFormGroup): void {
    $event.updateValueAndValidity();
    this.newRoleList = this.MirrorUserForm.value.roleList;
    const assignList = this.newRoleList.filter(x => !this.exRoleList.includes(x));
    const deassignList = this.exRoleList.filter(x => !this.newRoleList.includes(x));
    const reqBody = {};
    reqBody['tenantId'] = this.pagesService.tenantId;
    reqBody['userId'] = this.savedId;
    reqBody['assignList'] = assignList;
    reqBody['deAssignList'] = deassignList;
    const formValues = reqBody;
    this.messageFlag = true;
    this.pagesService.onSaveAssignRolesToUser(formValues).subscribe(
      {
        next: results => {
          for (let i = 0; i < assignList.length; i++) {
            if (results.success.result.AssignRolesToUser[i]?.messageCode == 'CIAM 151') {
              this.assignRoleCount = this.assignRoleCount + 1;
            }
          }
          for (let i = 0; i < deassignList.length; i++) {
            if (results.success.result["De-AssignRolesFromUser"][i]?.messageCode == 'CIAM 152') {
              this.deAssignRoleCount = this.deAssignRoleCount + 1;
            }
          }
          if (this.deAssignRoleCount == 0 && this.assignRoleCount > 0) {
            this.msg.push(this.assignRoleCount + ' Role Assigned successfully');
          } else if (this.deAssignRoleCount > 0 && this.assignRoleCount > 0) {
            this.msg.push(this.assignRoleCount + ' Role Assigned successfully');

            this.msg.push(this.deAssignRoleCount + ' Role De-assigned successfully');
          } else if (this.deAssignRoleCount > 0 && this.assignRoleCount == 0) {
            this.msg.push(this.deAssignRoleCount + ' Role De-assigned successfully')
          }

          this.messageFlag = true;
          this.messageService.add({ key: 'myKey1', severity: 'success', detail: this.msg.join('\n') });
          setTimeout(() => {
            this.messageFlag = false;
            this.assignRoleCount = 0;
            this.deAssignRoleCount = 0;
            this.msg = [];
          }, 3000);
          this.tab2 = true;
          this.enableBtn2 = true;
          this.clicked = false;
          this.getExRoleList();
        },
        error: () => {
          this.messageFlag = true;
          this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Failed to Assign Roles.' });
          setTimeout(() => {
            this.messageFlag = false;
          }, 3000);
        }
      });
  }
  getExGroupList() {
    this.pagesService.getViewUserGroups(this.savedId).subscribe((results: any) => {
      const responseData = results.success.result;
      this.exGroupList = [];
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.displayName;
          data.value = element.id;
          this.exGroupList.push(data.value);
        });
        this.exGroupList = [...this.exGroupList];
      }
    });
  }
  getNewGroupList() {
    const responseData = this.MirrorUserForm.value.groupList;
    this.newGroupList = [];
    if (responseData && responseData.length > 0) {
      responseData.forEach((element: any = {}) => {
        const data = {
          label: null,
          value: null,
        };
        data.label = element.displayName;
        data.value = element.id;
        this.newGroupList.push(data.value);
      });
      this.newGroupList = [...this.newGroupList];
    }
  }
  assignGroups($event: UntypedFormGroup): void {
    $event.updateValueAndValidity();
    this.newGroupList = this.MirrorUserForm.value.groupList;
    const assignList = this.newGroupList.filter(x => !this.exGroupList.includes(x));
    const deassignList = this.exGroupList.filter(x => !this.newGroupList.includes(x));
    const reqBody = {};
    reqBody['assignList'] = assignList;
    reqBody['deAssignList'] = deassignList;
    const formValues = reqBody;
    this.messageFlag = true;
    this.pagesService.onSaveAssignGroup(formValues, this.savedId).subscribe(
      {
        next: results => {
          for (let i = 0; i < assignList.length; i++) {
            if (results.success.result.AssingGroupsToUser[i]?.messageCode == 'CIAM 151') {
              this.assignGroupCount = this.assignGroupCount + 1;
            }
          }
          for (let i = 0; i < deassignList.length; i++) {
            if (results.success.result["De-AssingGroupsFromUser"][i]?.messageCode == 'CIAM 152') {
              this.deAssignGroupCount = this.deAssignGroupCount + 1;
            }
          }
          if (this.deAssignGroupCount == 0 && this.assignGroupCount > 0) {
            this.msg1.push(this.assignGroupCount + ' Group Assigned successfully');
          } else if (this.deAssignGroupCount > 0 && this.assignGroupCount > 0) {
            this.msg1.push(this.assignGroupCount + ' Group Assigned successfully');

            this.msg1.push(this.deAssignGroupCount + ' Group De-assigned successfully');
          } else if (this.deAssignGroupCount > 0 && this.assignGroupCount == 0) {
            this.msg1.push(this.deAssignGroupCount + ' Group De-assigned successfully')
          }
          this.messageFlag = true;
          this.messageService.add({ key: 'myKey1', severity: 'success', detail: this.msg1.join('\n') });
          setTimeout(() => {
            this.messageFlag = false;
            this.assignGroupCount = 0;
            this.deAssignGroupCount = 0;
            this.msg1 = [];
          }, 3000);
          this.tab3 = true;
          this.enableBtn3 = true;
          this.getExGroupList();
        },
        error: () => {
          this.messageFlag = true;
          this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Failed to Assign Groups.' });
          setTimeout(() => {
            this.messageFlag = false;
          }, 3000);
        }
      });
  }
  showTab() {
    if (this.activeIndex1 === 0) {
      this.activeIndex1 = 1;
    } else if (this.activeIndex1 === 1) {
      this.activeIndex1 = 2;
    }
  }

  fetchCustomField() {

    this.pagesService.getFetchCustomFieldListing(this.moduleID).subscribe(results => {
      const responseData = results?.success?.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            id: null,
            fieldName: null,
            fieldInputtype: null,
            inputFormat: null,
            mandatoryField: null,
            editableField: null,
            configJson: null,
            maxCharacters: null,
            pattern: null,
            specialChar: null
          };
          data.id = element.id;
          data.fieldName = element.fieldName;
          this.customJSON = JSON.parse(element.fieldConfigJason);
          data.configJson = this.customJSON;
          data.fieldInputtype = data.configJson.fieldLabel;
          data.inputFormat = data.configJson.inputvalue_format;
          data.mandatoryField = data.configJson.mandatory;
          data.editableField = data.configJson.editable;
          data.maxCharacters = data.configJson.MaxCharacters;
          data.specialChar = data.configJson?.specialCharacter;
          if ((data.fieldInputtype == 'Checkbox') || (data.inputFormat[0] == 'Date range' && data.fieldInputtype == 'Input field in range')) {
            this.MirrorUserForm.addControl(data.id,
              this.fb.control(null, []));
          }
          else if (data.fieldInputtype == 'Input field in range' && data.inputFormat[0] == 'Text/Number range') {
            this.MirrorUserForm.addControl(data.id + "_start",
              this.fb.control(null, []));
            this.MirrorUserForm.addControl(data.id + "_end",
              this.fb.control(null, []));
          }
          else {
            if (data.fieldInputtype == 'Text' || data.fieldInputtype == 'Text area') {
              data.pattern = this.setValidationsPatterns(data.inputFormat, data.specialChar);
            }
            this.MirrorUserForm.addControl(data.id,
              this.fb.control(null, []));
          }
          if (data.fieldInputtype == 'Checkbox') {
            this.checkboxArr.push(data);
          }
          else {
            this.customList.push(data);
          }
        });
        this.customList = [...this.customList];
      }
    });
  }

  setValidationsPatterns(format: any, specialChar: any): string {
    let upper = "";
    let lower = "";
    let num = "";
    let spCh = "";
    let pattern = "";
    format.forEach(element => {
      if (element == "Uppercase alphabet") {
        upper = "A-Z";
      }
      else if (element == "Lowercase alphabet") {
        lower = "a-z";
      }
      else if (element == "Numbers") {
        num = "0-9";
      }
    });
    specialChar.forEach(element => {
      spCh = spCh + "\\" + element?.value;
    });
    pattern = "^([" + lower + "" + upper + "" + num + "" + spCh + "]+\\s)*[" + lower + "" + upper + "" + num + "" + spCh + "]+$";
    return pattern;

  }
  navigateTouserlist(): void {
    const navigationExtras: NavigationExtras = {
      state: {
        msg: 'User Creation request has been sent for approval',
      },
    };
    this.router.navigate(['/pages/userlisting'], navigationExtras);
  }
  colorChange() {
    document.getElementById('emailAddress_id')!.style.borderColor = 'grey';
  }
}
