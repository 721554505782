<div style="background-color: #F6F8FA;" *ngIf="this.pagesService.ciamHomeFlag==false">
    <form class="layout-content custom-form-layout" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class=" p-col-12">
                <div class="ui-fluid p-formgrid p-grid">
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 left p-pt-2">
                        <div class=" p-fluid   p-field    ">
                            <h5 class="custom-head1"> CIAM Home</h5>
                        </div>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 left" *ngIf="this.hideFlag"
                        style="margin-top:-3px">
                        <div class="p-card p-p-3">
                            <div class="p-fluid p-formgrid p-grid">
                                <div class=" p-col-12 p-p-0">
                                    <div class="ui-fluid p-formgrid p-grid">
                                        <div class="" style="width: 100%;">
                                            <div class="p-col-11 p-p-0 p-d-inline-block">
                                                <strong>Welcome {{account? account.firstName+' '+account.lastName: ''}},
                                                    glad
                                                    to see you onboard!</strong>
                                            </div>
                                            <div class="p-col-1 p-p-0 p-d-inline-block p-icon-right">
                                                <i aria-hidden="true" class="icon-cancel"
                                                    (click)="this.hideFlag=false"></i>
                                            </div>
                                            <p class="p-mt-2">
                                                We will populate this section with helpful insights as you use the
                                                platform. For now, below
                                                <br>
                                                are some things you can do to get started.
                                            </p>
                                            <div class="p-right custom-login" *ngIf="this.lastLoggedDate!=null">

                                                <label class="l-icons lastLoogedIn-icon"
                                                    style="vertical-align:middle;"></label>
                                                <span>Last Login: {{this.lastLoggedDate}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="left p-col-12 p-p-0 p-mt-2">
                        <div class="p-col-12 p-sm-12 p-md-4 p-lg-3 p-xl-3 p-d-inline-block p-pt-2 p-pb-2 hoverCard"
                            *ngIf="this.pagesService.pageTenantAdminFlag || this.pagesService.pageSuperAdminFlag || this.pagesService.userManagementReadOnlyFlag || this.pagesService.roleUserManagementAccess">
                            <p-card styleClass="p-custom-card-selection" (click)="routeToPage('userlisting',1)">
                                <ng-template pTemplate="body">
                                    <div class="p-grid">
                                        <div class="p-col-fixed p-p-0" style="width: 50px;">
                                            <span class="fa-layers fa-fw">
                                                <label class="c-icons user-icon"></label>
                                            </span>
                                        </div>
                                        <div class="p-col p-d-flex p-ai-center customCardLabel p-p-0">
                                            User Management
                                        </div>
                                    </div>
                                </ng-template>
                            </p-card>
                        </div>
                        <div class="p-col-12 p-sm-12 p-md-4 p-lg-3 p-xl-3 p-d-inline-block p-pt-2 p-pb-2 hoverCard"
                            *ngIf="false">
                            <p-card styleClass="p-custom-card-selection">
                                <ng-template pTemplate="body">
                                    <div class="p-grid">
                                        <div class="p-col-fixed p-p-0" style="width: 54px;">
                                            <span class="fa-layers fa-fw">
                                                <label class="c-icons identity-icon"></label>
                                            </span>
                                        </div>
                                        <div class="p-col p-d-flex p-ai-center customCardLabel p-p-0">
                                            Identity Provider
                                        </div>
                                    </div>
                                </ng-template>
                            </p-card>
                        </div>
                        <div class="p-col-12 p-sm-12 p-md-4 p-lg-3 p-xl-3 p-d-inline-block p-pt-2 p-pb-2 hoverCard"
                            *ngIf="this.pagesService.pageTenantAdminFlag || this.pagesService.authorizationReadOnlyFlag || this.pagesService.authorizationAccess"
                            (click)="routeToPage('attribute&group',2)">
                            <p-card styleClass="p-custom-card-selection">
                                <ng-template pTemplate="body">
                                    <div class="p-grid">
                                        <div class="p-col-fixed p-p-0" style="width: 54px;">
                                            <span class="fa-layers fa-fw">
                                                <label class="c-icons authorization-icon"></label>
                                            </span>
                                        </div>
                                        <div class="p-col p-d-flex p-ai-center customCardLabel p-p-0">
                                            Authorization
                                        </div>
                                    </div>
                                </ng-template>
                            </p-card>
                        </div>
                        <div class="p-col-12 p-sm-12 p-md-4 p-lg-3 p-xl-3 p-d-inline-block p-pt-2 p-pb-2 hoverCard"
                            *ngIf="this.pagesService.pageAppAdminFlag || this.pagesService.applicationOnboardingReadOnlyFlag">
                            <p-card styleClass="p-custom-card-selection"
                                (click)="routeToPage('applicationonboarding',3)">
                                <ng-template pTemplate="body">
                                    <div class="p-grid">
                                        <div class="p-col-fixed p-p-0" style="width: 54px;">
                                            <span class="fa-layers fa-fw">
                                                <label class="c-icons application-icon"></label>
                                            </span>
                                        </div>
                                        <div class="p-col p-d-flex p-ai-center customCardLabel p-p-0">
                                            Applications
                                        </div>
                                    </div>
                                </ng-template>
                            </p-card>
                        </div>
                        <div class="p-col-12 p-sm-12 p-md-4 p-lg-3 p-xl-3 p-d-inline-block p-pt-2 p-pb-2 hoverCard"
                            *ngIf="false">
                            <p-card styleClass="p-custom-card-selection">
                                <ng-template pTemplate="body">
                                    <div class="p-grid">
                                        <div class="p-col-fixed p-p-0" style="width: 54px;">
                                            <span class="fa-layers fa-fw">
                                                <label class="c-icons organization-icon"></label>
                                            </span>
                                        </div>
                                        <div class="p-col p-d-flex p-ai-center customCardLabel p-p-0">
                                            Organization
                                        </div>
                                    </div>
                                </ng-template>
                            </p-card>
                        </div>
                        <div class="p-col-12 p-sm-12 p-md-4 p-lg-3 p-xl-3 p-d-inline-block hoverCard"
                            *ngIf="this.pagesService.pageAppAdminFlag || this.pagesService.pageTenantAdminFlag || this.pagesService.accountManagementReadOnlyFlag">
                            <p-card styleClass="p-custom-card-selection" (click)="routeToPage('accountManagement',6)">
                                <ng-template pTemplate="body">
                                    <div class="p-grid">
                                        <div class="p-col-fixed p-p-0" style="width: 54px;">
                                            <span class="fa-layers fa-fw">
                                                <label class="c-icons account-icon"></label>
                                            </span>
                                        </div>
                                        <div class="p-col p-d-flex p-ai-center customCardLabel p-p-0">
                                            Account Management
                                        </div>
                                    </div>
                                </ng-template>
                            </p-card>
                        </div>
                        <div class="p-col-12 p-sm-12 p-md-4 p-lg-3 p-xl-3 p-d-inline-block p-pt-2 p-pb-2 hoverCard"
                            *ngIf="this.pagesService.pageCiamAdminFlag || this.pagesService.pageTenantAdminFlag || this.pagesService.tenantManagementReadOnlyFlag">
                            <p-card styleClass="p-custom-card-selection" (click)="routeToPage('tenantlisting',4)">
                                <ng-template pTemplate="body">
                                    <div class="p-grid">
                                        <div class="p-col-fixed p-p-0" style="width: 54px;">
                                            <span class="fa-layers fa-fw">
                                                <label class="c-icons tenant-icon"></label>
                                            </span>
                                        </div>
                                        <div class="p-col p-d-flex p-ai-center customCardLabel p-p-0">
                                            Tenant Management
                                        </div>
                                    </div>
                                </ng-template>
                            </p-card>
                        </div>
                        <div class="p-col-12 p-sm-12 p-md-4 p-lg-3 p-xl-3 p-d-inline-block p-pt-2 p-pb-2 hoverCard"
                            *ngIf="this.pagesService.pagePlanAdminFlag || this.pagesService.planAdminReadOnlyFlag">
                            <p-card styleClass="p-custom-card-selection" (click)="routeToPage('planListing',5)">
                                <ng-template pTemplate="body">
                                    <div class="p-grid">
                                        <div class="p-col-fixed p-p-0" style="width: 54px;">
                                            <span class="fa-layers fa-fw">
                                                <label class="c-icons subscription-icon"></label>
                                            </span>
                                        </div>
                                        <div class="p-col p-d-flex p-ai-center customCardLabel p-p-0">
                                            Plans & Subscription
                                        </div>
                                    </div>
                                </ng-template>
                            </p-card>
                        </div>
                        <div class="p-col-12 p-sm-12 p-md-4 p-lg-3 p-xl-3 p-d-inline-block p-pt-2 p-pb-2 hoverCard"
                            *ngIf="false">
                            <p-card styleClass="p-custom-card-selection">
                                <ng-template pTemplate="body">
                                    <div class="p-grid">
                                        <div class="p-col-fixed p-p-0" style="width: 54px;">
                                            <span class="fa-layers fa-fw">
                                                <label class="c-icons security-icon"></label>
                                            </span>
                                        </div>
                                        <div class="p-col p-d-flex p-ai-center customCardLabel p-p-0">
                                            Security
                                        </div>
                                    </div>
                                </ng-template>
                            </p-card>
                        </div>
                        <div class="p-col-12 p-sm-12 p-md-4 p-lg-3 p-xl-3 p-d-inline-block hoverCard" *ngIf="false">
                            <p-card styleClass="p-custom-card-selection">
                                <ng-template pTemplate="body">
                                    <div class="p-grid">
                                        <div class="p-col-fixed p-p-0" style="width: 54px;">
                                            <span class="fa-layers fa-fw">
                                                <label class="c-icons reports-icon"></label>
                                            </span>
                                        </div>
                                        <div class="p-col p-d-flex p-ai-center customCardLabel p-p-0">
                                            Reports
                                        </div>
                                    </div>
                                </ng-template>
                            </p-card>
                        </div>
                        <div class="p-col-12 p-sm-12 p-md-4 p-lg-3 p-xl-3 p-d-inline-block p-pt-2 p-pb-2 hoverCard"
                            *ngIf="this.pagesService.pageCiamAdminFlag || this.pagesService.pageAppAdminFlag || this.pagesService.pageCiamAuthFlag || this.pagesService.pageSuperAdminFlag || this.pagesService.pageTenantAdminFlag || this.pagesService.pageWfApproverFlag">
                            <p-card styleClass="p-custom-card-selection" (click)="routeToPage('myaccount',7)">
                                <ng-template pTemplate="body">
                                    <div class="p-grid">
                                        <div class="p-col-fixed p-p-0" style="width: 54px;">
                                            <span class="fa-layers fa-fw">
                                                <label class="c-icons myaccount-icon"></label>
                                            </span>
                                        </div>
                                        <div class="p-col p-d-flex p-ai-center customCardLabel p-p-0">
                                            My Account
                                        </div>
                                    </div>
                                </ng-template>
                            </p-card>
                        </div>
                    </div>
                    <div class="left" style="margin-bottom:50px ;">
                        <div style="padding:1em .5em 0.5em 0.5em">
                            <label class="l-icons video-icon" style="vertical-align:middle;"></label>
                            <span style="color:#3168DC;font-size: 13px;">View product videos</span>
                            <span style="color:#E9EAED"> | </span>
                            <label class="l-icons message-icon" style="vertical-align:middle;"></label>
                            <span style="color:#3168DC;font-size: 13px;">Browse FAQs</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div style="background-color: #F6F8FA;" *ngIf="this.pagesService.ciamHomeFlag==true">
    <form class="layout-content custom-form-layout" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class=" p-col-12">
                <div class="p-grid p-pt-3">
                    <div class="p-col p-pl-0">
                        <div class="box">
                            <h4>WORKSPACE</h4>
                        </div>
                    </div>
                    <div class="p-col-fixed p-pr-0">
                        <div class="box">
                            <div class="p-d-flex p-jc-end">
                                <p-messages key="myKey1" class="custom-alert-box" *ngIf="this.messageFlag"></p-messages>
                                <p-messages severity="info" class="custom-info-msg-status"
                                    *ngIf="this.messageFlag1 && this.pagesService.ciamHomeFlag==true">
                                    <ng-template pTemplate>
                                        <span class="p-message-icon pi pi-info-circle"></span>
                                        <span class="p-message-detail"
                                            style="padding-right: 18px;">{{this.infoMessage}}</span>
                                        <button (click)="clearmsg()" type="button" pripple=""
                                            class="p-message-close p-link">
                                            <i aria-hidden="true" class="p-message-close-icon pi pi-times"></i>
                                        </button>
                                    </ng-template>
                                </p-messages>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="width:100%">
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-p-0 p-offset-undefined left">
                        <div class=" p-fluid   p-field    ">
                            <div class="p-fluid p-formgrid p-grid" *ngIf="this.tableList1.length>0">
                                <div class="p-col-12 p-p-0">
                                    <div class="ui-fluid p-formgrid p-grid">
                                        <div
                                            class="p-col-12 p-sm-12 p-md-12 p-lg-9 p-xl-9 p-offset-undefined left p-pl-0">
                                            <div class=" p-fluid   p-field    ">
                                                <h6
                                                    style="padding-top: 6px;display: inline;font-size:14px;font-weight: 600;">
                                                    Onboarding status</h6>
                                                <div class="btnwrap p-ml-3" style="display: inline;">
                                                    <button pButton type="button" pButton icon="pi pi-sync"
                                                        style="width: 16px;background: transparent;color: grey;border: none;box-shadow: none;"
                                                        pTooltip="Click 'Refresh' to view updated status"
                                                        class="background-transparent" (click)="getTenantSaasStatus();"
                                                        class="background-transparent">
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3 p-offset-undefined left p-pr-0">
                                            <div class=" p-fluid   p-field    ">
                                                <span class=" p-input-icon-left">
                                                    <input type="text" (keydown.enter)="$event.preventDefault()"
                                                        id="textField_id" name="textField_name"
                                                        formControlName="textField" pInputText placeholder="Search"
                                                        class="p-inputtext inputtextHeight  "
                                                        (input)="AppFilterMethod($event.target.value)"
                                                        style="width: 100%;" />
                                                    <i aria-hidden="true" class="pi pi-search"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left p-p-0">
                                            <div class="  p-field    ">
                                                <div class="mobilefriendlytable" responsiveLayout="stack"
                                                    [ngClass]="{'content-section implementation': true}">
                                                    <p-table #tableid [value]="tableList" name="table"
                                                        [columns]="tablecols" [rows]="5"
                                                        [rowsPerPageOptions]="[5,10,15,20]" selectionMode="Multiple"
                                                        [scrollable]="true" [paginator]="true" sortMode="single"
                                                        styleClass="p-datatable-gridlines" class="card-grid-table">
                                                        <ng-template pTemplate="header">
                                                            <tr>
                                                                <th id="orgName" style="max-width:21%">
                                                                    Organization Name
                                                                </th>
                                                                <th id="appName" style="max-width:21%">
                                                                    App Name
                                                                </th>
                                                                <th id="tenant"
                                                                    style="max-width:10%;justify-content: center;">
                                                                    <div class="p-d-flex p-jc-between p-ai-center">
                                                                        Tenant
                                                                    </div>
                                                                </th>
                                                                <th id="user"
                                                                    style="max-width:9%;justify-content: center;">
                                                                    <div class="p-d-flex p-jc-between p-ai-center">
                                                                        RDS
                                                                    </div>
                                                                </th>
                                                                <th id="app"
                                                                    style="max-width:11%;justify-content: center;">
                                                                    <div class="p-d-flex p-jc-between p-ai-center">
                                                                        Namespace
                                                                    </div>
                                                                </th>
                                                                <th id="app"
                                                                    style="max-width:11%;justify-content: center;">
                                                                    <div class="p-d-flex p-jc-between p-ai-center">
                                                                        Vault
                                                                    </div>
                                                                </th>
                                                                <th id="deployment"
                                                                    style="max-width:10%;justify-content: center;">
                                                                    <div class="p-d-flex p-jc-between p-ai-center">
                                                                        Deployment
                                                                    </div>
                                                                </th>
                                                                <th id="actionButton"
                                                                    style="max-width:18%;justify-content: center;">
                                                                    <div class="p-d-flex p-jc-between p-ai-center">
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-rowData let-index="rowIndex">
                                                            <tr (mouseenter)="hoverIndex=index;highlight(index)"
                                                                (mouseleave)="hoverIndex=index;highlight(index)"
                                                                [pSelectableRow]="rowData">
                                                                <td style="max-width:21%">
                                                                    <span pTooltip="{{rowData.tenantName}}"
                                                                        tooltipPosition="bottom"
                                                                        *ngIf="rowData.tenantName?.length>=21">{{rowData.tenantName?.substring(0,21)
                                                                        +
                                                                        "..." }}</span>
                                                                    <span
                                                                        *ngIf="rowData.tenantName?.length<21">{{rowData.featureName}}</span>
                                                                </td>
                                                                <td class="custom-description" style="max-width:21%">
                                                                    <span pTooltip="{{rowData.applicationName}}"
                                                                        *ngIf="rowData.applicationName?.length >= 15"
                                                                        tooltipPosition="bottom">
                                                                        {{rowData.applicationName?.substring(0,15) +
                                                                        "..." }}
                                                                    </span>
                                                                    <span *ngIf="rowData.applicationName?.length < 15">
                                                                        {{rowData.applicationName}}
                                                                    </span>
                                                                </td>
                                                                <td style="max-width:10%;justify-content: center;">
                                                                    <span
                                                                        *ngIf="rowData.statusList[0]?.status?.includes('in_progress')"
                                                                        style="padding-left: 10px;"
                                                                        tooltipPosition="top" pTooltip="Pending">
                                                                        <i aria-hidden="true" class="pi pi-clock"
                                                                            style="color:#F59E0B;"></i>
                                                                    </span>
                                                                    <span
                                                                        *ngIf="rowData.statusList[0]?.status?.includes('success')"
                                                                        style="padding-left: 10px;"
                                                                        tooltipPosition="top" pTooltip="Completed">
                                                                        <label class="cc-icons completed-icon"></label>
                                                                    </span>
                                                                    <span
                                                                        *ngIf="rowData.statusList[0]?.status?.includes('failed')"
                                                                        style="padding-left: 10px;"
                                                                        tooltipPosition="top" pTooltip="Failed">
                                                                        <i aria-hidden="true"
                                                                            class="pi pi-exclamation-circle"
                                                                            style="color:#D6002A;"></i>
                                                                    </span>
                                                                </td>
                                                                <td style="max-width:9%;justify-content: center;">
                                                                    <span
                                                                        *ngIf="rowData.statusList[1]?.status?.includes('in_progress')"
                                                                        style="padding-left: 7px;" tooltipPosition="top"
                                                                        pTooltip="Pending">
                                                                        <i aria-hidden="true" class="pi pi-clock"
                                                                            style="color:#F59E0B;"></i>
                                                                    </span>
                                                                    <span
                                                                        *ngIf="rowData.statusList[1]?.status?.includes('success')"
                                                                        style="padding-left: 7px;" tooltipPosition="top"
                                                                        pTooltip="Completed">
                                                                        <label class="cc-icons completed-icon"></label>
                                                                    </span>
                                                                    <span
                                                                        *ngIf="rowData.statusList[1]?.status?.includes('failed')"
                                                                        style="padding-left: 7px;" tooltipPosition="top"
                                                                        pTooltip="Failed">
                                                                        <i aria-hidden="true"
                                                                            class="pi pi-exclamation-circle"
                                                                            style="color:#D6002A;"></i>
                                                                    </span>
                                                                </td>
                                                                <td style="max-width:11%;justify-content: center;">
                                                                    <span
                                                                        *ngIf="rowData.statusList[2]?.status?.includes('in_progress')"
                                                                        style="padding-left: 10px;"
                                                                        tooltipPosition="top" pTooltip="Pending">
                                                                        <i aria-hidden="true" class="pi pi-clock"
                                                                            style="color:#F59E0B;"></i>
                                                                    </span>
                                                                    <span
                                                                        *ngIf="rowData.statusList[2]?.status?.includes('success')"
                                                                        style="padding-left: 10px;"
                                                                        tooltipPosition="top" pTooltip="Completed">
                                                                        <label class="cc-icons completed-icon"></label>
                                                                    </span>
                                                                    <span
                                                                        *ngIf="rowData.statusList[2]?.status?.includes('failed')"
                                                                        style="padding-left: 10px;"
                                                                        tooltipPosition="top" pTooltip="Failed">
                                                                        <i aria-hidden="true"
                                                                            class="pi pi-exclamation-circle"
                                                                            style="color:#D6002A;"></i>
                                                                    </span>
                                                                </td>
                                                                <td style="max-width:11%;justify-content: center;">
                                                                    <span
                                                                        *ngIf="rowData.statusList[3]?.status?.includes('in_progress')"
                                                                        style="padding-left: 10px;"
                                                                        tooltipPosition="top" pTooltip="Pending">
                                                                        <i aria-hidden="true" class="pi pi-clock"
                                                                            style="color:#F59E0B;"></i>
                                                                    </span>
                                                                    <span
                                                                        *ngIf="rowData.statusList[3]?.status?.includes('success')"
                                                                        style="padding-left: 10px;"
                                                                        tooltipPosition="top" pTooltip="Completed">
                                                                        <label class="cc-icons completed-icon"></label>
                                                                    </span>
                                                                    <span
                                                                        *ngIf="rowData.statusList[3]?.status?.includes('failed')"
                                                                        style="padding-left: 10px;"
                                                                        tooltipPosition="top" pTooltip="Failed">
                                                                        <i aria-hidden="true"
                                                                            class="pi pi-exclamation-circle"
                                                                            style="color:#D6002A;"></i>
                                                                    </span>
                                                                </td>
                                                                <td style="max-width:11%;justify-content: center;">
                                                                    <span
                                                                        *ngIf="rowData.statusList[4]?.status?.includes('in_progress')"
                                                                        style="padding-left: 10px;"
                                                                        tooltipPosition="top" pTooltip="Pending">
                                                                        <i aria-hidden="true" class="pi pi-clock"
                                                                            style="color:#F59E0B;"></i>
                                                                    </span>
                                                                    <span
                                                                        *ngIf="rowData.statusList[4]?.status?.includes('success')"
                                                                        style="padding-left: 10px;"
                                                                        tooltipPosition="top" pTooltip="Completed">
                                                                        <label class="cc-icons completed-icon"></label>
                                                                    </span>
                                                                    <span
                                                                        *ngIf="rowData.statusList[4]?.status?.includes('failed')"
                                                                        style="padding-left: 10px;"
                                                                        tooltipPosition="top" pTooltip="Failed">
                                                                        <i aria-hidden="true"
                                                                            class="pi pi-exclamation-circle"
                                                                            style="color:#D6002A;"></i>
                                                                    </span>
                                                                </td>



                                                                <td style="max-width:18%;justify-content: center;">
                                                                    <div class="p-pl-5">
                                                                        <button pButton tooltipPosition="top"
                                                                            *ngIf="false" icon="pi pi-refresh"
                                                                            style="width: 16px;height: 22px;background: transparent;color: grey;border: none;box-shadow: none;"
                                                                            pTooltip="Retry"
                                                                            class="background-transparent"
                                                                            (click)="RetryInfraProvisioning(rowData);">
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="paginatorright" let-state> Total
                                                            Records: {{this.tableList.length}} </ng-template>
                                                        <ng-template pTemplate="emptymessage">
                                                            <tr>
                                                                <td colspan="7" class="p-p-0 p-text-center"> <p-card>
                                                                        <div style="margin-top: 5%;height: 70px;">No
                                                                            matching records found</div>
                                                                    </p-card> </td>
                                                            </tr>
                                                        </ng-template>
                                                    </p-table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left p-pl-0 p-pt-3"
                                *ngIf="this.appCount>0">
                                <div class=" p-fluid   p-field    ">
                                    <span style="padding-top: 6px;font-size:14px;font-weight: 500;">Onboarded apps
                                    </span>
                                    <p-badge [value]="this.appCount" styleClass="mr-2"></p-badge>
                                </div>
                            </div>
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-pt-2 p-pl-0 p-offset-undefined left p-pt-3"
                                style="margin-left: -8px;">
                                <div id="cardHeight" style="margin-top: -20px;">
                                    <p-table [rowHover]="true" id="serviceTable_id" #serviceTableid name="serviceTable"
                                        styleClass="p-datatable-gridlines" [value]="statusArrList"
                                        class="card-mode card-grid-table desktop tableBorder"
                                        [globalFilterFields]="['applicationName','description']"
                                        style="display: block;">
                                        <ng-template pTemplate="caption">
                                        </ng-template>
                                        <ng-template pTemplate="body" let-rowData let-index="rowIndex">
                                            <div class="p-col-12 p-xl-3 p-lg-3 p-md-3 p-sm-3"
                                                style="display: inline-grid;max-height: 135px;min-height: 135px;">
                                                <tr>
                                                    <p-card (click)="routeToApp(rowData.rootUrl)">
                                                        <ng-template pTemplate="body" style="height: 180px;">
                                                            <div class="avatar-header">
                                                                <p-avatarGroup>
                                                                    <p-avatar shape="circle"
                                                                        [ngClass]='getColor(index)'>
                                                                        {{rowData.applicationName?.substring(0,1) |
                                                                        titlecase}}
                                                                    </p-avatar>
                                                                    <span style="font-weight: 500;color:#242E4C;"
                                                                        class="avatar-name p-pl-2">
                                                                        <span pTooltip="{{rowData.applicationName}}"
                                                                            *ngIf="rowData.applicationName?.length >= 15"
                                                                            tooltipPosition="bottom">
                                                                            {{rowData.applicationName?.substring(0,15) +
                                                                            "..." }}
                                                                        </span>
                                                                        <span
                                                                            *ngIf="rowData.applicationName?.length < 15">
                                                                            {{rowData.applicationName}}
                                                                        </span>
                                                                    </span>
                                                                </p-avatarGroup>
                                                                <div class="p-pt-3"
                                                                    pTooltip="{{rowData.appDescription?.length >= 50 ? rowData.appDescription : ''}}"
                                                                    tooltipPosition="bottom">
                                                                    <span *ngIf="rowData.appDescription?.length > 50">
                                                                        {{rowData.appDescription?.substring(0,50) +
                                                                        "..." }}
                                                                    </span>
                                                                    <span *ngIf="rowData.appDescription?.length < 50;">
                                                                        {{rowData.appDescription}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </ng-template>
                                                    </p-card>
                                                </tr>
                                            </div>
                                        </ng-template>
                                    </p-table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>