import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CIAM_UM_SERVER_API_URL } from 'app/app.constants';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RoleDetailsService {
    private cancelStatus = new BehaviorSubject<any>('0');
    private loaderStatus = new Subject<any>();
    constructor(private http: HttpClient) { }

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    getRoleBankServiceLineInfo(data) {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umRoleDetails/getRoleBankServiceLineInfo', data);
    }

    updateRoleDetails(userDetails) {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umRoleDetails/updateRoleDetails', userDetails);
    }

    getRoleList(filter: any): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageRole/getRoleList', filter);
    }

    importRoleData(roleDetails) {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umRoleDetails/importRoleData', roleDetails);
    }

    checkRoleData(roleId) {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umRoleDetails/checkRoleData', roleId);
    }

    getUserList(filter: any): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umRoleDetails/getUserListForRole', filter);
    }

    getRoleUserBankServiceLineMapping(data: any): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umUserDetails/getRoleUserBankServiceLineMapping', data);
    }

    getEntitlementsForRole(data: any): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umRoleDetails/getEntitlementsForRole', data);
    }

    getEntitlementWiseUsers(roleDetails) {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umRoleDetails/getEntitlementWiseUsers', roleDetails);
    }

    getRoleUserWiseEntitlementCount(roleDetails) {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umRoleDetails/getRoleUserWiseEntitlementCount', roleDetails);
    }

    getRoleStatusList(formData): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageRole/getRoleStatusList', formData);
    }

    getUserStatusList(data: any): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageUser/getUserStatusList', data);
    }

    checkForChanges(details) {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umRoleDetails/checkForChanges', details);
    }

    getAllUserList(filter: any): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umRoleDetails/getAllUserListForRole', filter);
    }

    getUserRoleList(data): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umUserDetails/getUserRoleList', data);
    }

    getUsersList(filter: any): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageUser/getUserList', filter);
    }

    sendMessage(message: any) {
        console.log('SendMessage called----------------');

        this.cancelStatus.next(message);
    }

    getMessage(): Observable<any> {
        console.log('getMessage called----------------');

        return this.cancelStatus.asObservable();
    }
    setIsLoader(message: boolean) {
        this.loaderStatus.next(message);
    }

    getIsLoader(): Observable<any> {
        return this.loaderStatus.asObservable();
    }

    getRoleListBasedOnApp(filter: any): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageRole/getAllRoleList', filter);
    }
}
