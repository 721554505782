<div style="background-color: #F6F8FA;" (click)="mouseLeave()">
    <div class="p-d-flex p-jc-end">
        <p-messages key="myKey1" class="custom-alert-box" *ngIf="this.messageFlag"></p-messages>
        <p-messages key="myKey2" class="custom-alert-box" *ngIf="this.messageFlag1"></p-messages>
    </div>
    <div class="p-col-fixed p-mt-1 p-ml-1 p-pr-0 custom-breadcrumb">
        <p-breadcrumb [model]="ccprimebreadcrumbList" [home]="homeiconccprimebreadcrumb">
        </p-breadcrumb>
    </div>
    <form class="layout-content custom-form-layout" [formGroup]="AuthenticationForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                <div>
                    <h5 class="custom-head1">Authentication</h5>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left custom-tab-nav">
                <p-tabView [(activeIndex)]="AuthenticationForm.value.tabview" (onChange)="handleChangetabview($event)">
                    <p-tabPanel header="Contextual Authentication">
                        <div class="p-fluid p-formgrid p-grid p-mb-2">
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left p-p-0">
                                <div class=" p-fluid   p-field    ">
                                    <label
                                        [ngClass]="{'field-required': AuthenticationForm.get('application')?.errors?.required}">
                                        Application
                                    </label> <span class='invalid-feedback-astrik'>*</span>
                                    <div class="p-fluid">
                                        <p-dropdown id="applicationid" name="application" placeholder="Select"
                                            [options]="applicationList" [filter]="true" filterBy="label"
                                            [autoDisplayFirst]="false" formControlName="application"
                                            (onChange)="resetValue();getContextualAuthentication();">
                                            <ng-template let-item pTemplate="item">
                                                <div *ngIf="item.label.length &lt;= 35;">
                                                    {{item.label}}
                                                </div>
                                                <div *ngIf="item.label.length > 35;" pTooltip="{{item.label}}"
                                                    tooltipPosition="bottom">
                                                    {{item.label.substring(0,35) + "..." }}
                                                </div>
                                            </ng-template>
                                        </p-dropdown>
                                    </div>
                                    <div class="invalid-feedback"
                                        *ngIf="AuthenticationForm.get('application')!.invalid && (AuthenticationForm.get('application')!.dirty || AuthenticationForm.get('application')!.touched)">
                                        <div *ngIf="AuthenticationForm.get('application')?.errors?.required">
                                            <label class="alert-icon alert-circle-icon"></label>
                                            <medium class="p-error">Application is required.</medium>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!flag1"
                                class="p-col-12 p-sm-12 p-md-12 p-lg-8 p-xl-8 p-offset-undefined p-left p-p-0">
                                <div class="p-field label-left" style="margin-left: 20px;margin-top: 34px;">
                                    <label class="p-regular" style="font-size: 13px;">Evaluate Contextual
                                        Authentication</label>
                                    <p-inputSwitch [(ngModel)]="enableValue" formControlName="enableSwitch"
                                        (onChange)="enableDisableEvaluateAuthentication();" [disabled]="this.pagesService.tenantManagementReadOnlyFlag"></p-inputSwitch>
                                </div>

                            </div>
                            <div class="" *ngIf="flag1" class="custom-Attrtable-div">
                                <label class="d-icons group-icon"></label>
                            </div>
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-p-0 p-offset-undefined left">
                                <div class="  p-field">
                                    <div [ngClass]="{'content-section implementation': true}" *ngIf="!flag1">

                                        <p-accordion [activeIndex]="0" expandIcon="pi pi-plus"
                                            collapseIcon="pi pi-minus">
                                            <p-accordionTab class="p-mb-2">
                                                <ng-template pTemplate="header">
                                                    <span class="vertical-align-middle p-col">Add IP/IP Range
                                                        Configuration</span>
                                                    <span class="p-pt-1" style="margin-left: 72%;">
                                                        <label class="check-icons check-icon" *ngIf="firstSaveForCheck"
                                                            style="margin: 0px !important;"></label>
                                                    </span>
                                                </ng-template>
                                                <form [formGroup]="IPRangeConfigurationForm" autocomplete="off"
                                                    novalidate>
                                                    <div id="repeatParent_div"
                                                        *ngFor="let num of createRange(defaultNum);let last=last;"
                                                        class="p-p-2 p-mb-3" style="background-color: #F6F8FA;">
                                                        <div class="p-formgrid p-grid">
                                                            <div id="condition_div"
                                                                class="p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3 p-offset-undefined left">
                                                                <div class=" p-fluid   p-field    ">
                                                                    <label
                                                                        [ngClass]="{'field-required': IPRangeConfigurationForm.get('condition'+num)?.errors?.required}">
                                                                        Condition
                                                                        <span
                                                                            class='invalid-feedback-astrik'>*</span></label>
                                                                    <div class="p-fluid">
                                                                        <p-dropdown [filter]="true" filterBy="label"
                                                                            id="condition{{num}}"
                                                                            name="condition{{num}}"
                                                                            placeholder="Condition"
                                                                            [options]="this['conditionList'+num]"
                                                                            optionDisabled="inactive"
                                                                            [autoDisplayFirst]="false"
                                                                            formControlName="condition{{num}}"
                                                                            (onChange)="disablingConditions();onFirstchange();deleteControlsOnDropdownChange(num);"
                                                                            filter="true">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="p-error p-d-flex"
                                                                        *ngIf="IPRangeConfigurationForm.get('condition'+num)!.invalid && (IPRangeConfigurationForm.get('condition'+num)!.dirty || IPRangeConfigurationForm.get('condition'+num)!.touched)">
                                                                        <label
                                                                            class="alert-icon alert-circle-icon"></label>
                                                                        <div
                                                                            *ngIf="IPRangeConfigurationForm.get('condition'+num)?.errors?.required">
                                                                            Condition is required.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="empty div p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6 p-offset-undefined p-right">
                                                            </div>
                                                            <div id="empty_div"
                                                                class="p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3 p-offset-undefined p-right">
                                                                <button *ngIf="num>1" pButton
                                                                    style="box-shadow:none;border:none; background-color: transparent;margin-top: 27px;width: 24px;height: 24px;padding: 0px;"
                                                                    icon="g-icons delete-icon"
                                                                    tooltipStyleClass="customTooltip"
                                                                    pTooltip="Delete Condition" tooltipPosition="top"
                                                                    [escape]="false" class="background-transparent"
                                                                    (click)="deleteCondition(num);" [disabled]="this.pagesService.tenantManagementReadOnlyFlag">
                                                                </button>
                                                            </div>

                                                            <!-- startingdiv -->

                                                            <div id="repeatChild_div"
                                                                class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                                                                <form [formGroup]="IPversionForm" autocomplete="off"
                                                                    novalidate>
                                                                    <div id="ngForIPRange_div"
                                                                        *ngFor="let inum of createIPversionRange(defaultIPnum);let last=last;">
                                                                        <div id="IpRange_div"
                                                                            *ngIf="this.IPRangeConfigurationForm.get('condition'+num).value=='ADD_IP_IP_RANGE' "
                                                                            class="p-grid p-mb-3">
                                                                            <div id="ipVersion_div"
                                                                                class="p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3 p-offset-undefined left p-pl-0">
                                                                                <label
                                                                                    [ngClass]="{'field-required': IPversionForm.get('ipVersion'+inum)?.errors?.required}">
                                                                                    IP Version
                                                                                    <span
                                                                                        class='invalid-feedback-astrik'>*</span></label>
                                                                                <div class="p-fluid">
                                                                                    <p-dropdown [filter]="true"
                                                                                        filterBy="label"
                                                                                        id="ipVersion{{inum}}"
                                                                                        name="ipVersion{{inum}}"
                                                                                        placeholder="Select"
                                                                                        [options]="staticIPversion"
                                                                                        [autoDisplayFirst]="false"
                                                                                        formControlName="ipVersion{{inum}}"
                                                                                        (onChange)="enablingStartEnd(inum);">
                                                                                    </p-dropdown>
                                                                                </div>
                                                                                <div class="p-error"
                                                                                    *ngIf="IPversionForm.get('ipVersion'+inum)!.invalid && (IPversionForm.get('ipVersion'+inum)!.dirty || IPversionForm.get('ipVersion'+inum)!.touched)">
                                                                                    <div
                                                                                        *ngIf="IPversionForm.get('ipVersion'+inum)?.errors?.required">
                                                                                        <label
                                                                                            class="alert-icon alert-circle-icon"></label>
                                                                                        <medium class="p-error">IP
                                                                                            Version
                                                                                            is required.</medium>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div id="startIP_div"
                                                                                class="p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3 p-offset-undefined left">
                                                                                <div class=" p-fluid ">
                                                                                    <label
                                                                                        [ngClass]="{'field-required': IPversionForm.get('startIP'+inum)?.errors?.required}">
                                                                                        Start IP
                                                                                        <span
                                                                                            class='invalid-feedback-astrik'>*</span></label>
                                                                                    <input type="text"
                                                                                        id="startIP{{inum}}"
                                                                                        name="startIP{{inum}}"
                                                                                        formControlName="startIP{{inum}}"
                                                                                        pInputText
                                                                                        placeholder="Enter Start IP"
                                                                                        (keyup)="enableBtn=false;addressStartIpCheck(inum);duplicityCheckIPrange(inum);comparingIP(inum);"
                                                                                        maxlength="50"
                                                                                        class="p-inputtext inputtextHeight  " />

                                                                                    <div class="p-error"
                                                                                        *ngIf="IPversionForm.get('startIP'+inum)!.invalid && (IPversionForm.get('startIP'+inum)!.dirty || IPversionForm.get('startIP'+inum)!.touched)">
                                                                                        <div
                                                                                            *ngIf="IPversionForm.get('startIP'+inum)?.errors?.required">
                                                                                            <label
                                                                                                class="alert-icon alert-circle-icon"></label>
                                                                                            <medium class="p-error">
                                                                                                Start IP
                                                                                                is required.</medium>
                                                                                        </div>
                                                                                        <div
                                                                                            *ngIf="IPversionForm.get('startIP'+inum).errors.pattern">
                                                                                            <label
                                                                                                class="alert-icon alert-circle-icon"></label>
                                                                                            <medium class="p-error">No
                                                                                                space
                                                                                                allowed</medium>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="invalid-feedback">
                                                                                        <label
                                                                                            *ngIf="this['startIPPattern'+inum]">
                                                                                            <div>
                                                                                                <label
                                                                                                    class="alert-icon alert-circle-icon"></label>
                                                                                                <medium class="p-error">
                                                                                                    Invalid Ip format
                                                                                                </medium>
                                                                                            </div>
                                                                                        </label>
                                                                                    </div>
                                                                                    <div class="invalid-feedback">
                                                                                        <label
                                                                                            *ngIf="this['startDuplicity'+inum]">
                                                                                            <div>
                                                                                                <label
                                                                                                    class="alert-icon alert-circle-icon"></label>
                                                                                                <medium class="p-error">
                                                                                                    StartIP cannot be
                                                                                                    same
                                                                                                </medium>
                                                                                            </div>
                                                                                        </label>
                                                                                    </div>

                                                                                    <div class="invalid-feedback">
                                                                                        <label *ngIf="this.nameFlag">
                                                                                            <div>
                                                                                                <label
                                                                                                    class="alert-icon alert-circle-icon"></label>
                                                                                                <medium class="p-error">
                                                                                                    Start IP already
                                                                                                    exists.
                                                                                                </medium>
                                                                                            </div>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div id="endIP_div"
                                                                                class="p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3 p-offset-undefined left">
                                                                                <div class=" p-fluid">
                                                                                    <label
                                                                                        [ngClass]="{'field-required': IPversionForm.get('endIP'+inum)?.errors?.required}">
                                                                                        End IP
                                                                                        <span
                                                                                            class='invalid-feedback-astrik'>*</span></label>
                                                                                    <input type="text"
                                                                                        id="endIP{{inum}}"
                                                                                        name="endIP{{inum}}"
                                                                                        formControlName="endIP{{inum}}"
                                                                                        pInputText
                                                                                        placeholder="Enter End IP"
                                                                                        class="p-inputtext inputtextHeight"
                                                                                        (keyup)="enableBtn=false;addressEndIpCheck(inum);duplicityCheckIPrange(inum);this.apiDuplicity=false;comparingIP(inum);" />
                                                                                    <div class="p-error"
                                                                                        *ngIf="IPversionForm.get('endIP'+inum)!.invalid && (IPversionForm.get('endIP'+inum)!.dirty || IPversionForm.get('endIP'+inum)!.touched)">
                                                                                        <div
                                                                                            *ngIf="IPversionForm.get('endIP'+inum)?.errors?.required">
                                                                                            <label
                                                                                                class="alert-icon alert-circle-icon"></label>
                                                                                            <medium class="p-error">End
                                                                                                IP
                                                                                                is required.</medium>
                                                                                        </div>
                                                                                        <div
                                                                                            *ngIf="IPversionForm.get('endIP'+inum).errors.pattern">
                                                                                            <label
                                                                                                class="alert-icon alert-circle-icon"></label>
                                                                                            <medium class="p-error">No
                                                                                                space
                                                                                                allowed</medium>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div>
                                                                                        <label
                                                                                            *ngIf="this['endIPPattern'+inum]">
                                                                                            <div>
                                                                                                <label
                                                                                                    class="alert-icon alert-circle-icon"></label>
                                                                                                <medium class="p-error">
                                                                                                    Invalid Ip format
                                                                                                </medium>
                                                                                            </div>
                                                                                        </label>
                                                                                    </div>
                                                                                    <div class="invalid-feedback">
                                                                                        <label
                                                                                            *ngIf="this['ipDuplicity'+inum]">
                                                                                            <div>
                                                                                                <label
                                                                                                    class="alert-icon alert-circle-icon"></label>
                                                                                                <medium class="p-error">
                                                                                                    Start and End
                                                                                                    Address cannot be
                                                                                                    same.
                                                                                                </medium>
                                                                                            </div>
                                                                                        </label>
                                                                                    </div>
                                                                                    <div class="invalid-feedback">
                                                                                        <label
                                                                                            *ngIf="this['comparingIP' + inum]">
                                                                                            <div>
                                                                                                <label
                                                                                                    class="alert-icon alert-circle-icon"></label>
                                                                                                <medium class="p-error">
                                                                                                    End Address cannot
                                                                                                    be less than Start
                                                                                                    Address.
                                                                                                </medium>
                                                                                            </div>
                                                                                        </label>
                                                                                    </div>
                                                                                    <div class="invalid-feedback">
                                                                                        <label
                                                                                            *ngIf="this.apiDuplicity">
                                                                                            <div>
                                                                                                <label
                                                                                                    class="alert-icon alert-circle-icon"></label>
                                                                                                <medium class="p-error">
                                                                                                    Start ip or end ip
                                                                                                    already used
                                                                                                </medium>
                                                                                            </div>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div id="Add_div"
                                                                                class="p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3 p-offset-undefined left">
                                                                                <button *ngIf="last"
                                                                                    style="margin-top: 25px;width: 32px;height: 32px;"
                                                                                    pButton type="button"
                                                                                    icon="pi pi-plus"
                                                                                    pTooltip="Add IP/IP Range"
                                                                                    tooltipPosition="top"
                                                                                    class="p-button-brand2 p-mr-2"
                                                                                    (click)="addIPVersion();" [disabled]="this.pagesService.tenantManagementReadOnlyFlag">
                                                                                </button>
                                                                                <button *ngIf="inum > 1"
                                                                                    style="margin-top: 25px;width: 32px;height: 32px;"
                                                                                    pButton type="button"
                                                                                    icon="g-icons delete-icon"
                                                                                    pTooltip="Delete IP/IP Range"
                                                                                    tooltipPosition="top"
                                                                                    class="p-button-brand2 p-mr-2"
                                                                                    (click)="deleteIPVersion(inum);" [disabled]="this.pagesService.tenantManagementReadOnlyFlag">
                                                                                </button>
                                                                            </div>
                                                                            <div id="IPActionTaken_div"
                                                                                *ngIf="this.IPRangeConfigurationForm.get('condition'+num).value=='ADD_IP_IP_RANGE' "
                                                                                class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left p-pl-0"
                                                                                style="margin-top: 10px;">
                                                                                <div class="p-formgroup-inline">
                                                                                    <label>Action Taken:</label>
                                                                                    <div *ngFor="let category of actionTakenArray"
                                                                                        class="field-radiobutton p-radiobutton-sm">
                                                                                        <p-radioButton
                                                                                            *ngIf="!radioFlag"
                                                                                            id="actionTakenIP{{inum}}"
                                                                                            [label]=" category.label "
                                                                                            [inputId]="category.value"
                                                                                            class="p-mr-2"
                                                                                            name="actionTakenIP{{inum}}"
                                                                                            [value]="category.value"
                                                                                            (onClick)="enableBtn=false;cosoleCheckForm();"
                                                                                            formControlName="actionTakenIP{{inum}}">
                                                                                        </p-radioButton>
                                                                                        <p-radioButton *ngIf="radioFlag"
                                                                                            id="actionTakenIP{{inum}}"
                                                                                            (onClick)="enableBtn=false;cosoleCheckForm();"
                                                                                            [(ngModel)]="this['Ip_radiobuttondefaultvalue'+inum]"
                                                                                            [label]=" category.label "
                                                                                            [inputId]="category.value"
                                                                                            class="p-mr-2"
                                                                                            name="actionTakenIP{{inum}}"
                                                                                            [value]="category.value"
                                                                                            formControlName="actionTakenIP{{inum}}">
                                                                                        </p-radioButton>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div id="IPActionTakenEmpty_div"
                                                                                style="display: none;"
                                                                                *ngIf="this.IPRangeConfigurationForm.get('condition'+num).value=='ADD_IP_IP_RANGE' "
                                                                                class="p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6 p-offset-undefined left">
                                                                            </div>
                                                                            <div id="IPNotify_div"
                                                                                style="pointer-events: none;"
                                                                                *ngIf="this.IPRangeConfigurationForm.get('condition'+num).value=='ADD_IP_IP_RANGE' "
                                                                                class="p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6 p-offset-undefined left p-pl-0">
                                                                                <div class="p-formgroup-inline">
                                                                                    <div
                                                                                        class=" p-field-checkbox normal">
                                                                                        <p-checkbox disabled="true"
                                                                                            [(ngModel)]="this['Ip_checked'+inum]"
                                                                                            *ngIf="!radioFlag"
                                                                                            id="notifyMailIP{{inum}}"
                                                                                            binary="true"
                                                                                            inputId="binary"
                                                                                            formControlName="notifyMailIP{{inum}}">
                                                                                        </p-checkbox>
                                                                                        <p-checkbox [disabled]="true"
                                                                                            *ngIf="radioFlag"
                                                                                            id="notifyMailIP{{inum}}"
                                                                                            binary="true"
                                                                                            inputId="binary"
                                                                                            formControlName="notifyMailIP{{inum}}">
                                                                                        </p-checkbox>
                                                                                        <label
                                                                                            style="font-size: 0.87rem;"
                                                                                            for="binary">Notify user
                                                                                            through
                                                                                            e-mail</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                class="account_div p-col-12 p-pl-0 p-d-flex">
                                                                                <div
                                                                                    class="p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3 p-offset-undefined p-pl-0">
                                                                                    <div class="p-mt-4 p-pt-1"
                                                                                        *ngIf="this.IPversionForm.value['linkAccountIP'+inum]">
                                                                                    </div>
                                                                                    <p-checkbox
                                                                                        id="linkAccountIP{{inum}}"
                                                                                        binary="true" inputId="binary"
                                                                                        (onChange)="changeLinkAccount(inum,IPversionForm);enableBtn=false;"
                                                                                        formControlName="linkAccountIP{{inum}}">
                                                                                    </p-checkbox>
                                                                                    <label style="font-size: 0.87rem;"
                                                                                        for="binary"
                                                                                        class="p-m-0 p-pl-2"
                                                                                        (onClick)="changeLinkAccount(inum);enableBtn=false;">Link
                                                                                        Account</label>
                                                                                </div>
                                                                                <div id="drop_div"
                                                                                    class="p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3 p-offset-undefined ">
                                                                                    <div class=" p-fluid   p-field    "
                                                                                        *ngIf="this.IPversionForm.value['linkAccountIP'+inum]">
                                                                                        <label
                                                                                            [ngClass]="{'field-required': IPversionForm.get('accountIP'+inum)?.errors?.required}">
                                                                                            Account
                                                                                            <span
                                                                                                class='invalid-feedback-astrik'>*</span></label>
                                                                                        <div class="p-fluid">
                                                                                            <p-dropdown [filter]="true"
                                                                                                filterBy="displayName"
                                                                                                id="accountIP{{inum}}"
                                                                                                name="accountIP{{inum}}"
                                                                                                placeholder="Select Account"
                                                                                                [options]="this.accountList"
                                                                                                [autoDisplayFirst]="false"
                                                                                                formControlName="accountIP{{inum}}"
                                                                                                (onChange)="enableBtn=false;console(inum);"
                                                                                                optionLabel="displayName"
                                                                                                optionValue="id">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                        <div class="p-error p-d-flex"
                                                                                            *ngIf="IPversionForm.get('accountIP'+inum)!.invalid && (IPversionForm.get('accountIP'+inum)!.dirty || IPversionForm.get('accountIP'+inum)!.touched)">
                                                                                            <label
                                                                                                class="alert-icon alert-circle-icon"></label>
                                                                                            <div
                                                                                                *ngIf="IPversionForm.get('accountIP'+inum)?.errors?.required">
                                                                                                Account is required.
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div id="line_div"
                                                                                style=" border-bottom: 1px solid #E9EAED;"
                                                                                *ngIf="this.IPRangeConfigurationForm.get('condition'+num).value=='ADD_IP_IP_RANGE' && !last"
                                                                                class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left p-pl-0">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                                <form [formGroup]="CIDRForm" autocomplete="off"
                                                                    novalidate>
                                                                    <div id="ngForCIDRrange_div" class="p-mb-3"
                                                                        *ngFor="let cnum of createCIDRRange(defaultCIDRnum);let last=last;let index=index">
                                                                        <div id="CIDR_div"
                                                                            *ngIf="this.IPRangeConfigurationForm.get('condition'+num).value=='ADD_CIDR_RANGE'  "
                                                                            class="p-grid">
                                                                            <div id="CIDR_drop_div"
                                                                                class="p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3 p-offset-undefined left p-pl-0">
                                                                                <div class=" p-fluid ">
                                                                                    <label
                                                                                        [ngClass]="{'field-required': CIDRForm.get('rangeCIDR'+cnum)?.errors?.required}">
                                                                                        CIDR Range
                                                                                        <span
                                                                                            class='invalid-feedback-astrik'>*</span></label>
                                                                                    <input type="text"
                                                                                        id="rangeCIDR{{cnum}}"
                                                                                        name="rangeCIDR{{cnum}}"
                                                                                        formControlName="rangeCIDR{{cnum}}"
                                                                                        pInputText
                                                                                        placeholder="Enter CIDR range"
                                                                                        pattern="^[^\s]+(\s+[^\s]+)*$"
                                                                                        maxlength="50"
                                                                                        (keyup)="enableBtn=false;addressCIDRCheck(this.CIDRForm.get('rangeCIDR'+cnum).value,cnum);
                                                                                        duplicityCheckCIDR(this.CIDRForm.get('rangeCIDR'+cnum).value,cnum);"
                                                                                        class="p-inputtext inputtextHeight  " />

                                                                                    <div class="p-error"
                                                                                        *ngIf="CIDRForm.get('rangeCIDR'+cnum)!.invalid && (CIDRForm.get('rangeCIDR'+cnum)!.dirty || CIDRForm.get('rangeCIDR'+cnum)!.touched)">
                                                                                        <div
                                                                                            *ngIf="CIDRForm.get('rangeCIDR'+cnum)?.errors?.required">
                                                                                            <label
                                                                                                class="alert-icon alert-circle-icon"></label>
                                                                                            <medium class="p-error">CIDR
                                                                                                Range is required.
                                                                                            </medium>
                                                                                        </div>
                                                                                        <div
                                                                                            *ngIf="CIDRForm.get('rangeCIDR'+cnum).errors.pattern">
                                                                                            <label
                                                                                                class="alert-icon alert-circle-icon"></label>
                                                                                            <medium class="p-error">No
                                                                                                space
                                                                                                allowed</medium>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="invalid-feedback">
                                                                                        <label
                                                                                            *ngIf="this['cidrRangePattern'+cnum]">
                                                                                            <div>
                                                                                                <label
                                                                                                    class="alert-icon alert-circle-icon"></label>
                                                                                                <medium class="p-error">
                                                                                                    Invalid field format
                                                                                                </medium>
                                                                                            </div>
                                                                                        </label>
                                                                                    </div>
                                                                                    <div class="invalid-feedback">
                                                                                        <label
                                                                                            *ngIf="this['cidrDuplicity'+cnum]">
                                                                                            <div>
                                                                                                <label
                                                                                                    class="alert-icon alert-circle-icon"></label>
                                                                                                <medium class="p-error">
                                                                                                    Please check the
                                                                                                    range Duplicity.
                                                                                                </medium>
                                                                                            </div>
                                                                                        </label>
                                                                                    </div>
                                                                                    <div class="invalid-feedback">
                                                                                        <label *ngIf="this.nameFlag">
                                                                                            <div>
                                                                                                <label
                                                                                                    class="alert-icon alert-circle-icon"></label>
                                                                                                <medium class="p-error">
                                                                                                    CIDR
                                                                                                    Range already
                                                                                                    exists.
                                                                                                </medium>
                                                                                            </div>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div id="Add_div"
                                                                                class="p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3 p-offset-undefined left">
                                                                                <button *ngIf="last"
                                                                                    [disabled]="this['disableCIDRAdd'+cnum] || this.pagesService.tenantManagementReadOnlyFlag"
                                                                                    style="margin-top: 25px;width: 32px;height: 32px;"
                                                                                    pButton type="button"
                                                                                    icon="pi pi-plus"
                                                                                    pTooltip="Add CIDR Range"
                                                                                    tooltipPosition="top"
                                                                                    class="p-button-brand2 p-mr-2"
                                                                                    (click)="addCIDR();">
                                                                                </button>
                                                                                <button *ngIf="cnum > 1"
                                                                                    style="margin-top: 25px;width: 32px;height: 32px;"
                                                                                    pButton type="button"
                                                                                    icon="g-icons delete-icon"
                                                                                    pTooltip="Delete CIDR Range"
                                                                                    tooltipPosition="top"
                                                                                    class="p-button-brand2 p-mr-2"
                                                                                    (click)="deleteCIDR(cnum,index);" [disabled]="this.pagesService.tenantManagementReadOnlyFlag">
                                                                                </button>
                                                                            </div>
                                                                            <div id="CIDR_empty_div"
                                                                                class="p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6 p-offset-undefined left">
                                                                            </div>
                                                                            <div id="CIDRActionTaken_div"
                                                                                *ngIf="this.IPRangeConfigurationForm.get('condition'+num).value=='ADD_CIDR_RANGE'  "
                                                                                class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left p-pl-0"
                                                                                style="margin-top: 10px;">
                                                                                <div class="p-formgroup-inline">
                                                                                    <label>Action Taken:</label>
                                                                                    <div *ngFor="let category of actionTakenArray"
                                                                                        class="field-radiobutton p-radiobutton-sm">
                                                                                        <p-radioButton
                                                                                            *ngIf="!radioFlag"
                                                                                            id="actionTakenCIDR{{cnum}}"
                                                                                            [label]=" category.label "
                                                                                            [inputId]="category.value"
                                                                                            class="p-mr-2"
                                                                                            name="actionTakenCIDR{{cnum}}"
                                                                                            [value]="category.value"
                                                                                            (onClick)="enableBtn=false;cosoleCheckForm();"
                                                                                            formControlName="actionTakenCIDR{{cnum}}">
                                                                                        </p-radioButton>
                                                                                        <p-radioButton *ngIf="radioFlag"
                                                                                            (onClick)="enableBtn=false;cosoleCheckForm();"
                                                                                            id="actionTakenCIDR{{cnum}}"
                                                                                            [(ngModel)]="this['Cidr_radiobuttondefaultvalue'+cnum]"
                                                                                            [label]=" category.label "
                                                                                            [inputId]="category.value"
                                                                                            class="p-mr-2"
                                                                                            name="actionTakenCIDR{{cnum}}"
                                                                                            [value]="category.value"
                                                                                            formControlName="actionTakenCIDR{{cnum}}">
                                                                                        </p-radioButton>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div id="CIDRActionTakenEmpty_div"
                                                                                style="display: none;"
                                                                                *ngIf="this.IPRangeConfigurationForm.get('condition'+num).value=='ADD_CIDR_RANGE'  "
                                                                                class="p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6 p-offset-undefined left">
                                                                            </div>
                                                                            <div id="CIDRNotify_div"
                                                                                style="pointer-events: none;"
                                                                                *ngIf="this.IPRangeConfigurationForm.get('condition'+num).value=='ADD_CIDR_RANGE'  "
                                                                                class="p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6 p-offset-undefined left p-pl-0">
                                                                                <div class="p-formgroup-inline">
                                                                                    <div
                                                                                        class=" p-field-checkbox normal">
                                                                                        <p-checkbox *ngIf="!radioFlag"
                                                                                            id="notifyMailCIDR{{cnum}}"
                                                                                            [(ngModel)]="this['Cidr_checked'+cnum]"
                                                                                            binary="true"
                                                                                            inputId="binary"
                                                                                            formControlName="notifyMailCIDR{{cnum}}">
                                                                                        </p-checkbox>
                                                                                        <p-checkbox *ngIf="radioFlag"
                                                                                            id="notifyMailCIDR{{cnum}}"
                                                                                            binary="true"
                                                                                            inputId="binary"
                                                                                            formControlName="notifyMailCIDR{{cnum}}">
                                                                                        </p-checkbox>
                                                                                        <label
                                                                                            style="font-size: 0.87rem;"
                                                                                            for="binary">Notify user
                                                                                            through
                                                                                            e-mail</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                class="account_div p-col-12 p-pl-0 p-d-flex">
                                                                                <div
                                                                                    class="p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3 p-offset-undefined p-pl-0">
                                                                                    <div class="p-mt-4 p-pt-1"
                                                                                        *ngIf="this.CIDRForm.value['linkAccountCIDR'+cnum]">
                                                                                    </div>
                                                                                    <p-checkbox
                                                                                        id="linkAccountCIDR{{cnum}}"
                                                                                        binary="true" inputId="binary"
                                                                                        (onChange)="changeLinkAccount(cnum,CIDRForm);enableBtn=false;"
                                                                                        formControlName="linkAccountCIDR{{cnum}}">
                                                                                    </p-checkbox>
                                                                                    <label style="font-size: 0.87rem;"
                                                                                        for="binary"
                                                                                        class="p-m-0 p-pl-2"
                                                                                        (onClick)="changeLinkAccount(cnum);enableBtn=false;">Link
                                                                                        Account</label>
                                                                                </div>
                                                                                <div id="drop_div"
                                                                                    class="p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3 p-offset-undefined ">
                                                                                    <div class=" p-fluid   p-field    "
                                                                                        *ngIf="this.CIDRForm.value['linkAccountCIDR'+cnum]">
                                                                                        <label
                                                                                            [ngClass]="{'field-required': CIDRForm.get('accountCIDR'+cnum)?.errors?.required}">
                                                                                            Account
                                                                                            <span
                                                                                                class='invalid-feedback-astrik'>*</span></label>
                                                                                        <div class="p-fluid">
                                                                                            <p-dropdown [filter]="true"
                                                                                                filterBy="displayName"
                                                                                                id="accountCIDR{{cnum}}"
                                                                                                name="accountCIDR{{cnum}}"
                                                                                                placeholder="Select Account"
                                                                                                [options]="this.accountList"
                                                                                                [autoDisplayFirst]="false"
                                                                                                formControlName="accountCIDR{{cnum}}"
                                                                                                (onChange)="enableBtn=false;console(cnum);"
                                                                                                optionLabel="displayName"
                                                                                                optionValue="id">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                        <div class="p-error p-d-flex"
                                                                                            *ngIf="CIDRForm.get('accountCIDR'+cnum)!.invalid && (CIDRForm.get('accountCIDR'+cnum)!.dirty || CIDRForm.get('accountCIDR'+cnum)!.touched)">
                                                                                            <label
                                                                                                class="alert-icon alert-circle-icon"></label>
                                                                                            <div
                                                                                                *ngIf="CIDRForm.get('accountCIDR'+cnum)?.errors?.required">
                                                                                                Account is required.
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div id="line_div"
                                                                                style=" border-bottom: 1px solid #E9EAED;"
                                                                                *ngIf="this.IPRangeConfigurationForm.get('condition'+num).value=='ADD_CIDR_RANGE' && !last"
                                                                                class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left p-pl-0">
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </form>
                                                                <div id="behaviorActionTaken_div"
                                                                    *ngIf="this.IPRangeConfigurationForm.get('condition'+num).value=='BEHAVIOUR_CHANGE' "
                                                                    class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left p-pl-0"
                                                                    style="margin-top: 10px;">
                                                                    <div class="p-formgroup-inline">
                                                                        <label>Action Taken:</label>
                                                                        <div *ngFor="let category of actionTakenArray"
                                                                            class="field-radiobutton p-radiobutton-sm">
                                                                            <p-radioButton *ngIf="radioFlag==false"
                                                                                id="actionTakenBehavior{{num}}"
                                                                                [label]=" category.label "
                                                                                [inputId]="category.value"
                                                                                class="p-mr-2"
                                                                                name="actionTakenBehavior{{num}}"
                                                                                (onClick)="enableBtn=false;cosoleCheckForm();"
                                                                                [value]="category.value"
                                                                                formControlName="actionTakenBehavior{{num}}">
                                                                            </p-radioButton>
                                                                            <p-radioButton *ngIf="radioFlag"
                                                                                [(ngModel)]="this['Behavior_radiobuttondefaultvalue'+num]"
                                                                                id="actionTakenBehavior{{num}}"
                                                                                [label]=" category.label "
                                                                                [inputId]="category.value"
                                                                                class="p-mr-2"
                                                                                (onClick)="enableBtn=false;cosoleCheckForm();"
                                                                                name="actionTakenBehavior{{num}}"
                                                                                [value]="category.value"
                                                                                formControlName="actionTakenBehavior{{num}}">
                                                                            </p-radioButton>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div id="behaviorActionTakenEmpty_div"
                                                                    style="display: none;"
                                                                    *ngIf="this.IPRangeConfigurationForm.get('condition'+num).value=='BEHAVIOUR_CHANGE' "
                                                                    class="p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6 p-offset-undefined left">
                                                                </div>
                                                                <div id="behaviorNotify_div"
                                                                    style="pointer-events: none;"
                                                                    *ngIf="this.IPRangeConfigurationForm.get('condition'+num).value =='BEHAVIOUR_CHANGE' "
                                                                    class="p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6 p-offset-undefined left p-pl-0">
                                                                    <div class="p-formgroup-inline">
                                                                        <div class=" p-field-checkbox normal">
                                                                            <p-checkbox disabled="true"
                                                                                *ngIf="!radioFlag"
                                                                                id="notifyMailBehavior{{num}}"
                                                                                [(ngModel)]="this['behavior_checked' +num]"
                                                                                binary="true" inputId="binary"
                                                                                formControlName="notifyMailBehavior{{num}}">
                                                                            </p-checkbox>
                                                                            <p-checkbox disabled="true"
                                                                                *ngIf="radioFlag"
                                                                                id="notifyMailBehavior{{num}}"
                                                                                binary="true" inputId="binary"
                                                                                formControlName="notifyMailBehavior{{num}}">
                                                                            </p-checkbox>
                                                                            <label style="font-size: 0.87rem;"
                                                                                for="binary">Notify user through
                                                                                e-mail</label>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div class="account_div p-col-12 p-pl-0 p-d-flex"
                                                                    *ngIf="this.IPRangeConfigurationForm.get('condition'+num).value =='BEHAVIOUR_CHANGE' ">
                                                                    <div
                                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3 p-offset-undefined p-pl-0">
                                                                        <div class="p-mt-4 p-pt-1"
                                                                            *ngIf="this.IPRangeConfigurationForm.value['linkAccountBH'+num]">
                                                                        </div>
                                                                        <p-checkbox id="linkAccountBH{{num}}"
                                                                            binary="true" inputId="binary"
                                                                            (onChange)="changeLinkAccount(num,IPRangeConfigurationForm);enableBtn=false;"
                                                                            formControlName="linkAccountBH{{num}}">
                                                                        </p-checkbox>
                                                                        <label style="font-size: 0.87rem;" for="binary"
                                                                            class="p-m-0 p-pl-2"
                                                                            (onClick)="changeLinkAccount(num);enableBtn=false;">Link
                                                                            Account</label>
                                                                    </div>
                                                                    <div id="drop_div"
                                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3 p-offset-undefined ">
                                                                        <div class=" p-fluid   p-field    "
                                                                            *ngIf="this.IPRangeConfigurationForm.value['linkAccountBH'+num]">
                                                                            <label
                                                                                [ngClass]="{'field-required': IPRangeConfigurationForm.get('accountBH'+num)?.errors?.required}">
                                                                                Account
                                                                                <span
                                                                                    class='invalid-feedback-astrik'>*</span></label>
                                                                            <div class="p-fluid">
                                                                                <p-dropdown [filter]="true"
                                                                                    filterBy="displayName"
                                                                                    id="accountBH{{num}}"
                                                                                    name="accountBH{{num}}"
                                                                                    placeholder="Select Account"
                                                                                    [options]="this.accountList"
                                                                                    [autoDisplayFirst]="false"
                                                                                    formControlName="accountBH{{num}}"
                                                                                    (onChange)="enableBtn=false;console(num);"
                                                                                    optionLabel="displayName"
                                                                                    optionValue="id">
                                                                                </p-dropdown>
                                                                            </div>
                                                                            <div class="p-error p-d-flex"
                                                                                *ngIf="IPRangeConfigurationForm.get('accountBH'+num)!.invalid && (IPRangeConfigurationForm.get('accountBH'+num)!.dirty || IPRangeConfigurationForm.get('accountBH'+num)!.touched)">
                                                                                <label
                                                                                    class="alert-icon alert-circle-icon"></label>
                                                                                <div
                                                                                    *ngIf="IPRangeConfigurationForm.get('accountBH'+num)?.errors?.required">
                                                                                    Account is required.
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div id="line_div"
                                                                    style=" border-bottom: 1px solid #E9EAED;display: none;"
                                                                    *ngIf="this.IPRangeConfigurationForm.get('condition'+num).value =='BEHAVIOUR_CHANGE' "
                                                                    class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left p-pl-0">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="p-grid p-mb-1">
                                                        <div
                                                            class="p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3 p-offset-undefined left p-pl-0">
                                                            <div class="btnwrap p-mr-3">
                                                                <button *ngIf="!conditionFlag" pButton type="button"
                                                                    icon="pi pi-plus"
                                                                    class="p-button-info p-mr-2 p-pl-0"
                                                                    (click)="addCondition()" label="Add Condition" [disabled]="this.pagesService.tenantManagementReadOnlyFlag">
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="p-col-12 p-sm-12 p-md-12 p-lg-9 p-xl-9 p-offset-undefined p-right p-pr-0">
                                                            <div class="btnwrap">
                                                                <button pButton class="p-button" type="button"
                                                                    id="saveButton" (click)="onSaveCheck();"
                                                                    [disabled]="!this.AuthenticationForm.valid || !this.IPRangeConfigurationForm.valid || !this.IPversionForm.valid || !this.CIDRForm.valid || enableBtn || this.pagesService.tenantManagementReadOnlyFlag"
                                                                    label="Save">
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </p-accordionTab>

                                        </p-accordion>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </p-tabPanel>
                    <p-tabPanel header=" Password Policy">
                        <p-card>
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 p-mb-4 undefined">

                                <label>Password Policy will be applicable for only non-AD user, please select the option
                                    to define password policy.</label>
                                <p><em><strong>Note :</strong>
                                        Keep the input field empty, if the policy is not applicable. </em></p>


                            </div>
                            <form [formGroup]="PasswordPolicyForm" autocomplete="off" novalidate>
                                <div class="p-fluid p-formgrid p-grid">
                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-0 undefined"
                                        [ngStyle]="{'width': ''}">
                                        <div class="p-fluid">
                                            <span class="d-block label-top"> <label
                                                    [ngClass]="{'field-required': PasswordPolicyForm.get('min_length')?.errors?.required}">
                                                    Minimum length of characters (Min:8)
                                                    <span class='invalid-feedback-astrik'>*</span>
                                                </label>
                                            </span>

                                            <input type="text" name="min_length_name" formControlName="min_length"
                                                pInputText pattern="^([8-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|26[0-4])$"
                                                placeholder="Enter number"
                                                (keyup)="this.passwordEnableBtn=false;minMaxCheck();">

                                        </div>
                                        <div class="invalid-feedback"
                                            *ngIf="PasswordPolicyForm.get('min_length')!.invalid && (PasswordPolicyForm.get('min_length')!.dirty || PasswordPolicyForm.get('min_length')!.touched)">
                                            <div *ngIf="PasswordPolicyForm.get('min_length')?.errors?.required">
                                                <label class="alert-icon alert-circle-icon"></label>
                                                Minimum length is required.
                                            </div>
                                            <div *ngIf="(PasswordPolicyForm.get('min_length')?.errors?.pattern)">
                                                <label class="alert-icon alert-circle-icon"></label>
                                                Minimum character limit should not be less then 8 and greater than
                                                maximum character limit,only numbers are allowed.
                                            </div>

                                        </div>
                                        <div class="p-error"
                                            *ngIf="this.minCheck && !(PasswordPolicyForm.get('min_length')?.errors?.pattern)">
                                            <label class="alert-icon alert-circle-icon"></label>
                                            <span style="vertical-align: bottom;" class="p-error">
                                                Minimum character limit should not be less then 8 and greater than
                                                maximum character limit,only numbers are allowed.
                                            </span>
                                        </div>

                                    </div>

                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-0 undefined"
                                        [ngStyle]="{'width': ''}">
                                        <div class="p-fluid ">
                                            <span class="d-block label-top"> <label
                                                    [ngClass]="{'field-required': PasswordPolicyForm.get('max_lenght')?.errors?.required}">
                                                    Maximum length of characters (Max: 265)
                                                    <span class='invalid-feedback-astrik'>*</span>
                                                </label>
                                            </span>
                                            <input type="text" name="max_lenght_name"
                                                pattern="^([8-9]|[1-9][0-9]|1[0-9]{2}|2[0-5][0-9]|26[0-5])$"
                                                formControlName="max_lenght" pInputText placeholder="Enter number"
                                                (keyup)="this.passwordEnableBtn=false;minMaxCheck();addCheck('maxLength')">
                                        </div>
                                        <div class="invalid-feedback"
                                            *ngIf="PasswordPolicyForm.get('max_lenght')!.invalid && (PasswordPolicyForm.get('max_lenght')!.dirty || PasswordPolicyForm.get('max_lenght')!.touched)">
                                            <div *ngIf="PasswordPolicyForm.get('max_lenght')?.errors?.required">
                                                <label class="alert-icon alert-circle-icon"></label>
                                                Maximum length is required.
                                            </div>
                                            <div *ngIf="PasswordPolicyForm.get('max_lenght')?.errors?.pattern">
                                                <label class="alert-icon alert-circle-icon"></label>
                                                Maximum character limit should not be less then minimum character limit
                                                and greater than 265,only numbers are allowed.
                                            </div>
                                        </div>
                                        <div class="invalid-feedback"
                                            *ngIf="this.additionCheck && this.maxAddCheck &&(!PasswordPolicyForm.get('max_lenght')?.errors?.pattern)">
                                            <label class="alert-icon alert-circle-icon"></label>
                                            <span style="vertical-align: bottom;" class="p-error">Total limit of special
                                                characters,uppercase charatcers and numbers should be less
                                                than provided max characters limit.
                                            </span>
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-0  "
                                        [ngStyle]="{'width': ''}">
                                        <div class="p-fluid">
                                            <span class="d-block label-top"> <label>
                                                    Password Expire (in days)
                                                </label>
                                            </span>
                                            <input type="text" name="expire_password_name" pattern="[1-9]+[0-9]{0,}"
                                                formControlName="expire_password" pInputText placeholder="Enter number"
                                                (keyup)="this.passwordEnableBtn=false" id="expire_password">
                                        </div>
                                        <div class="invalid-feedback"
                                            *ngIf="PasswordPolicyForm.get('expire_password')!.invalid && (PasswordPolicyForm.get('expire_password')!.dirty || PasswordPolicyForm.get('expire_password')!.touched)">
                                            <div
                                                *ngIf="PasswordPolicyForm.get('expire_password')?.errors?.pattern||PasswordPolicyForm.get('expire_password').value=='0' ">
                                                <label class="alert-icon alert-circle-icon"></label>
                                                Please enter only in numbers,zero not allowed.
                                            </div>

                                        </div>
                                    </div>

                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 p-mb-3 undefined">
                                    </div>

                                    <div class="p-col-12 p-sm-12 p-md-6 p-lg-4 p-xl-4 p-offset-0 undefined"
                                        [ngStyle]="{'width': ''}">
                                        <div class="p-fluid ">
                                            <span><label>
                                                    Minimum special characters
                                                </label>
                                            </span>
                                            <input type="text" name="min_special_char_name"
                                                formControlName="min_special_char" pattern="[1-9]+[0-9]{0,}" pInputText
                                                placeholder="Enter number" id="min_special_char"
                                                (keyup)="addCheck('special')">
                                        </div>
                                        <div class="invalid-feedback"
                                            *ngIf="this.additionCheck && this.maxSpecialCheck && (!PasswordPolicyForm.get('min_special_char')?.errors?.pattern)">
                                            <label class="alert-icon alert-circle-icon"></label>
                                            <span style="vertical-align: bottom;" class="p-error">Total limit of special
                                                characters,uppercase charatcers and numbers should be less
                                                than provided max characters limit.
                                            </span>
                                        </div>
                                        <div class="invalid-feedback"
                                            *ngIf="PasswordPolicyForm.get('min_special_char')?.errors?.pattern">
                                            <label class="alert-icon alert-circle-icon"></label>
                                            Please enter only in numbers,zero not allowed.
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-6 p-lg-4 p-xl-4 p-offset-0 undefined"
                                        [ngStyle]="{'width': ''}">
                                        <div class="p-fluid ">
                                            <span><label>
                                                    Minimum Uppercase characters
                                                </label>
                                            </span>
                                            <input type="text" name="min_uppercase_char_name"
                                                formControlName="min_uppercase_char" pInputText
                                                pattern="[1-9]+[0-9]{0,}" placeholder="Enter number"
                                                (keyup)="addCheck('upper');this.passwordEnableBtn=false;"
                                                id="min_uppercase_char">
                                        </div>

                                        <div class="invalid-feedback"
                                            *ngIf="this.additionCheck && this.maxUpperCheck && (!PasswordPolicyForm.get('min_uppercase_char')?.errors?.pattern)">
                                            <label class="alert-icon alert-circle-icon"></label>
                                            <span style="vertical-align: bottom;" class="p-error">Total limit of special
                                                characters,uppercase charatcers and numbers should be less
                                                than provided max characters limit.
                                            </span>
                                        </div>
                                        <div class="invalid-feedback"
                                            *ngIf="PasswordPolicyForm.get('min_uppercase_char')?.errors?.pattern">
                                            <label class="alert-icon alert-circle-icon"></label>
                                            Please enter only in numbers,zero not allowed.
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-6 p-lg-4 p-xl-4 p-offset-0 undefined"
                                        [ngStyle]="{'width': ''}">
                                        <div class="p-fluid ">
                                            <span><label>
                                                    Minimum Numbers
                                                </label>
                                            </span>
                                            <input type="text" name="min_numbers_name" formControlName="min_numbers"
                                                pInputText pattern="[1-9]+[0-9]{0,}" placeholder="Enter number"
                                                (keyup)="addCheck('numbers');this.passwordEnableBtn=false;"
                                                id="min_numbers">
                                        </div>

                                        <div class="invalid-feedback"
                                            *ngIf="PasswordPolicyForm.get('min_numbers')?.errors?.pattern">
                                            <label class="alert-icon alert-circle-icon"></label>
                                            Please enter only in numbers,zero not allowed.
                                        </div>
                                        <div class="invalid-feedback"
                                            *ngIf="this.additionCheck && this.maxNumbersCheck && (!PasswordPolicyForm.get('min_numbers')?.errors?.pattern)">
                                            <label class="alert-icon alert-circle-icon"></label>
                                            <span style="vertical-align: bottom;" class="p-error">Total limit of special
                                                characters,uppercase charatcers and numbers should be less
                                                than provided max characters limit.
                                            </span>
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 p-mb-3 undefined">
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-6 p-lg-4 p-xl-4 p-offset-0 undefined"
                                        [ngStyle]="{'width': ''}">
                                        <div class="p-fluid ">
                                            <span class="d-block label-top"> <label
                                                    [ngClass]="{'field-required': PasswordPolicyForm.get('wrong_password_attempts')?.errors?.required}">
                                                    Maximum wrong password attempts (Min:3)
                                                    <span class='invalid-feedback-astrik'>*</span><label
                                                        pTooltip="Exceeding maximum wrong password attempts will lock the user account.Admin is required to unlock."
                                                        tooltipPosition="top"
                                                        class="alert-icon alert-circle-info-icon"></label>
                                                </label>
                                            </span>
                                            <input type="text" name="wrong_password_attempts_name" pInputText
                                                formControlName="wrong_password_attempts" pattern="^[0-9]+"
                                                placeholder="Enter number"
                                                (keyup)="checkPassword();this.passwordEnableBtn=false;"
                                                id="wrong_password_attempts">
                                        </div>
                                        <div class="invalid-feedback"
                                            *ngIf="PasswordPolicyForm.get('wrong_password_attempts')!.invalid && (PasswordPolicyForm.get('wrong_password_attempts')!.dirty || PasswordPolicyForm.get('wrong_password_attempts')!.touched)">
                                            <div
                                                *ngIf="PasswordPolicyForm.get('wrong_password_attempts')?.errors?.required">
                                                <label class="alert-icon alert-circle-icon"></label>
                                                Maximum password attempts is required.
                                            </div>
                                            <div
                                                *ngIf="PasswordPolicyForm.get('wrong_password_attempts')?.errors?.pattern && !this.passwordattemptsValueCheckFlag">
                                                <label class="alert-icon alert-circle-icon"></label>
                                                Password attempts should not be less than 3, only numbers are allowed.
                                            </div>
                                        </div>
                                        <div class="p-error"
                                            *ngIf="this.passwordattemptsValueCheckFlag &&!(PasswordPolicyForm.get('wrong_password_attempts')?.errors?.pattern)">
                                            <label class="alert-icon alert-circle-icon"></label>
                                            <span style="display:inline-block;vertical-align: bottom;"
                                                class="p-error">Password attempts should not be less than 3
                                            </span>
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-8 p-xl-8 p-offset-undefined p-mt-4 p-p-1">
                                        <div class="p-formgroup-inline">
                                            <div class=" p-field-checkbox normal">
                                                <p-checkbox (onChange)="this.passwordEnableBtn=false" binary="true"
                                                    inputId="binary" formControlName="required_special_char_starts">
                                                </p-checkbox>
                                                <label style="font-size: 13px" for="binary">Start with special
                                                    character
                                                </label>
                                            </div>
                                            <div class=" p-field-checkbox normal">
                                                <p-checkbox (onChange)="this.passwordEnableBtn=false" binary="true"
                                                    inputId="binary1" formControlName="required_uppercase_char_starts">
                                                </p-checkbox>
                                                <label style="font-size: 13px" for="binary1">Start with uppercase
                                                    character
                                                </label>
                                            </div>
                                            <div class=" p-field-checkbox normal">
                                                <p-checkbox (onChange)="this.passwordEnableBtn=false" binary="true"
                                                    inputId="binary2" formControlName="required_number_char_starts">
                                                </p-checkbox>
                                                <label style="font-size: 13px" for="binary2">Start with number
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 p-mb-3 undefined">
                                    </div>
                                </div>
                                <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 p-d-flex p-jc-end"
                                    [ngStyle]="{'width': ''}">
                                    <div class="btnwrap"> <button pButton id="addButton_id" name="addButton_name"
                                            [disabled]="!this.PasswordPolicyForm.valid ||this.minCheck || this.additionCheck || this.passwordattemptsValueCheckFlag|| this.passwordEnableBtn || this.pagesService.tenantManagementReadOnlyFlag"
                                            class="p-button-primary" type="button" (click)="savePasswordPolicy();"
                                            label="Save">
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </p-card>
                    </p-tabPanel>
                    <p-tabPanel [disabled]="true" header="Login Options">
                    </p-tabPanel>
                </p-tabView>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-mb-4 p-offset-undefined left">
            </div>
        </div>
    </form>
</div>