<div>
    <div class="p-d-flex p-jc-end">
        <p-messages key="myKey1" class="custom-alert-box" *ngIf="this.messageFlag"></p-messages>
        <p-messages key="myKey2" class="custom-alert-box" *ngIf="this.messageFlag1"></p-messages>
        <p-messages [(value)]="msgs1" class="custom-alert-box" *ngIf="this.messageFlag2"></p-messages>

    </div>
    <p-breadcrumb id="columns_2_1_columns_2_0_ccprimebreadcrumb_1_2_id"
        name="columns_2_1_columns_2_0_ccprimebreadcrumb_1_2_name" [model]="ccprimebreadcrumbList"
        [home]="homeiconccprimebreadcrumb">
    </p-breadcrumb>
    <div class="layout-content">

        <div id="columns_2_id" name="columns_2_name" class="p-grid   " [ngStyle]="{'height': ''}">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 undefined p-pt-0 p-pb-0"
                [ngStyle]="{'width': ''}">

                <h6 id="columns_2_1_ccprimeheading_1_id" name="columns_2_1_ccprimeheading_1_name">
                    Edit Subscription
                </h6>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 undefined" [ngStyle]="{'width': ''}">
                <div class="p-cardpadding-xs">
                    <div class="d-flex flex-wrap p-mb-6" style="height:'auto'">
                        <p-card id="columns_2_2_card_2_id" name="columns_2_2_card_2_name" class="p-pb-5"
                            styleClass=" p-cardpadding-xs">
                            <div class="p-p-2 ">
                                <button pButton type="button" [disabled]="clicked"
                                    (click)="activeIndex1 = 0;this.messageFlag1 = false;"
                                    class="p-button-outlined p-button-lg tabButton group-identity"
                                    label="Subscription Details"
                                    [style.box-shadow]="activeIndex1 == 0 ? '1px 5px 20px #bdc0c9' :  '0px 1px 4px #bdc0c9'"
                                    [style.border]="activeIndex1 == 0 ? '1px solid  #396FC5' :  '#add8e6'"
                                    [style.color]=" activeIndex1 == 0 ? '#396FC5' : '#6C7387'">
                                    <div class="fa-2x">
                                        <span class="fa-layers fa-fw">
                                            <label class="c-icons plan-details"></label>
                                        </span>
                                    </div>
                                    <span class="p-button-icon p-button-icon-left reshot-icon-business-team"
                                        aria-hidden="true"></span>
                                    <label class="d-icons check-icon group-identity-label" width="14" height="14"
                                        *ngIf="tab1"></label>
                                </button>
                                <button pButton type="button" [disabled]="clicked2"
                                    (click)="activeIndex1 = 1;this.userLimit()" label="Map User" id="panelbtn2"
                                    class="p-button-outlined p-button-lg tabButton composite-role"
                                    [style.box-shadow]="activeIndex1 == 1 ? '1px 5px 20px #bdc0c9' :  '0px 1px 4px #bdc0c9'"
                                    [style.border]="activeIndex1 == 1 ? '1px solid  #396FC5' :  '#add8e6'"
                                    [style.color]=" activeIndex1 == 1 ? '#396FC5' : '#6C7387'">
                                    <div class="fa-2x">
                                        <span class="fa-layers fa-fw">
                                            <label class="c-icons plan-attributes-icon"></label>
                                        </span>
                                    </div>
                                    <span class="p-button-icon p-button-icon-left reshot-icon-business-team"
                                        aria-hidden="true"></span>
                                    <label class="d-icons check-icon composite-role-label" width="24" height="24"
                                        *ngIf="tab2"></label>
                                </button>
                                <button pButton type="button" [disabled]="clicked3"
                                    (click)="activeIndex1 = 2;this.messageFlag1=false;" label="Add Offers"
                                    id="panelbtn2" class="p-button-outlined p-button-lg tabButton composite-role"
                                    [style.box-shadow]="activeIndex1 == 2 ? '1px 5px 20px #bdc0c9' :  '0px 1px 4px #bdc0c9'"
                                    [style.border]="activeIndex1 == 2 ? '1px solid  #396FC5' :  '#add8e6'"
                                    [style.color]=" activeIndex1 == 2 ? '#396FC5' : '#6C7387'">
                                    <div class="fa-2x">
                                        <span class="fa-layers fa-fw">
                                            <label class="c-icons offers-icon"></label>
                                        </span>
                                    </div>
                                    <span class="p-button-icon p-button-icon-left reshot-icon-business-team"
                                        aria-hidden="true"></span>
                                    <label class="d-icons check-icon composite-role-label" width="24" height="24"
                                        *ngIf="tab3"></label>
                                </button>
                            </div>


                            <div class="tabview-custom">
                                <p-tabView [(activeIndex)]="activeIndex1" styleClass="tabview-custom">
                                    <p-tabPanel header="Subscription Details">
                                        <form [formGroup]="SubscriptionDetailsForm" autocomplete="off" novalidate>
                                            <div class="">
                                                <div id="columns_2_1_0_tabs_2_0_columns_2_1_0_columns_2_0_columns_2_0_columns_1_1_2_1_1_id"
                                                    name="columns_2_1_0_tabs_2_0_columns_2_1_0_columns_2_0_columns_2_0_columns_1_1_2_1_1_name"
                                                    class="p-grid   " [ngStyle]="{'height': ''}">
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-0 undefined"
                                                        [ngStyle]="{'width': ''}">
                                                        <div class="p-fluid">
                                                            <span class="d-block label-top"> <label
                                                                    [ngClass]="{'field-required': SubscriptionDetailsForm.get('subscriptionName')?.errors?.required}">
                                                                    Subscription Name
                                                                    <span class='invalid-feedback-astrik'>*</span>
                                                                </label>
                                                            </span>
                                                            <input [(ngModel)]="subscriptionName" type="text"
                                                                formControlName="subscriptionName" pInputText
                                                                pattern="^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$" maxlength="64"
                                                                placeholder="Subscription name"
                                                                (keyup)="this.enableBtn1=false;this.nameFlag=false;onColorchange();checkOldNewValues();"
                                                                id="subscriptionName">

                                                        </div>
                                                        <div class="invalid-feedback"
                                                            *ngIf="SubscriptionDetailsForm.get('subscriptionName')!.invalid && (SubscriptionDetailsForm.get('subscriptionName')!.dirty || SubscriptionDetailsForm.get('subscriptionName')!.touched)">
                                                            <div
                                                                *ngIf="SubscriptionDetailsForm.get('subscriptionName')?.errors?.required">
                                                                <label class="alert-icon alert-circle-icon"></label>
                                                                Subscription name is required.
                                                            </div>
                                                            <div
                                                                *ngIf="SubscriptionDetailsForm.get('subscriptionName')?.errors?.pattern">
                                                                <label class="alert-icon alert-circle-icon"></label>
                                                                Subscription name should not contain special
                                                                character.
                                                            </div>

                                                        </div>
                                                        <div class="p-error" *ngIf="this.nameFlag">
                                                            <label class="alert-icon alert-circle-icon"></label>
                                                            <span style="display:inline-block;vertical-align: bottom;"
                                                                class="p-error">Subscription
                                                                name already exists!
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-0 undefined"
                                                        [ngStyle]="{'width': ''}">
                                                        <div class="p-fluid ">
                                                            <span class="d-block label-top"> <label
                                                                    [ngClass]="{'field-required': SubscriptionDetailsForm.get('startDate')?.errors?.required}">
                                                                    Subscription Start Date
                                                                    <span class='invalid-feedback-astrik'>*</span>
                                                                </label>
                                                            </span>
                                                            <p-calendar [(ngModel)]="this.startingDate"
                                                                class="custom-calender" selectionMode="single"
                                                                dataString="string" [minDate]="minimumDate"
                                                                [disabledDates]="invalidDates" [maxDate]="maxStartDate"
                                                                [showIcon]="true" dateFormat="dd-mm-yy" inputId="basic"
                                                                placeholder="Select date" formControlName="startDate"
                                                                (onSelect)="this.enableBtn1=false;onstartDateChange();checkOldNewValues();">
                                                            </p-calendar>
                                                        </div>
                                                        <div class="invalid-feedback"
                                                            *ngIf="SubscriptionDetailsForm.get('startDate')!.invalid && (SubscriptionDetailsForm.get('startDate')!.dirty || SubscriptionDetailsForm.get('startDate')!.touched)">
                                                            <div
                                                                *ngIf="SubscriptionDetailsForm.get('startDate')?.errors?.required">
                                                                <label class="alert-icon alert-circle-icon"></label>
                                                                Start Date is required.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-4 p-lg-4 p-xl-4 p-offset-0  "
                                                        [ngStyle]="{'width': ''}">
                                                        <div class="p-fluid">
                                                            <span class="d-block label-top"> <label
                                                                    [ngClass]="{'field-required': SubscriptionDetailsForm.get('endDate')?.errors?.required}">
                                                                    Subscription End Date
                                                                    <span class='invalid-feedback-astrik'>*</span>
                                                                </label>
                                                            </span>
                                                            <p-calendar [(ngModel)]="this.endingDate"
                                                                class="custom-calender" selectionMode="single"
                                                                dataString="string" [minDate]="minEndDate"
                                                                [disabledDates]="invalidDates" [showIcon]="true"
                                                                dateFormat="dd-mm-yy" inputId="dateformat"
                                                                placeholder="Select date" formControlName="endDate"
                                                                (onSelect)="enableBtn1=false;onendDateChange();checkOldNewValues();">
                                                            </p-calendar>

                                                        </div>
                                                        <div class="invalid-feedback"
                                                            *ngIf="SubscriptionDetailsForm.get('endDate')!.invalid && (SubscriptionDetailsForm.get('endDate')!.dirty || SubscriptionDetailsForm.get('endDate')!.touched)">
                                                            <div
                                                                *ngIf="SubscriptionDetailsForm.get('endDate')?.errors?.required">
                                                                <label class="alert-icon alert-circle-icon"></label>
                                                                End Date is required.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-6 p-lg-4 p-xl-4 p-offset-0 undefined"
                                                        [ngStyle]="{'width': ''}">
                                                        <div class="p-fluid ">
                                                            <span class="d-block label-top"> <label
                                                                    [ngClass]="{'field-required': SubscriptionDetailsForm.get('account')?.errors?.required}">
                                                                    Account Name
                                                                    <span class='invalid-feedback-astrik'>*</span>
                                                                </label>
                                                            </span>
                                                            <p-dropdown [(ngModel)]="this.accountId"
                                                                (onChange)="enableBtn1=false;checkOldNewValues();"
                                                                [options]="selectList" placeholder="Select"
                                                                [autoDisplayFirst]="false" formControlName="account"
                                                                [filter]="true">
                                                            </p-dropdown>
                                                        </div>
                                                        <div class="invalid-feedback"
                                                            *ngIf="SubscriptionDetailsForm.get('account')!.invalid && (SubscriptionDetailsForm.get('account')!.dirty || SubscriptionDetailsForm.get('account')!.touched)">
                                                            <div
                                                                *ngIf="SubscriptionDetailsForm.get('account')?.errors?.required">
                                                                <label class="alert-icon alert-circle-icon"></label>
                                                                Account is required.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 p-d-flex p-jc-end"
                                                        [ngStyle]="{'width': ''}">
                                                        <div class="btnwrap"> <button pButton id="addButton_id"
                                                                name="addButton_name" class="p-button-primary"
                                                                type="button"
                                                                (click)=" onEditSubscription(SubscriptionDetailsForm);"
                                                                label="Save"
                                                                [disabled]="enableBtn1 || !this.SubscriptionDetailsForm.valid || enableSubSaveBtn">
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </p-tabPanel>
                                    <p-tabPanel>
                                        <form [formGroup]="SubscriptionMappingForm" autocomplete="off" novalidate>
                                            <div class="p-formgrid p-grid">
                                                <div
                                                    class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                                                    <div class=" p-fluid   p-field    ">
                                                        <label>
                                                            User Group
                                                        </label>
                                                        <div class="p-fluid">

                                                            <p-multiSelect filterPlaceHolder="select"
                                                                placeholder="Select" [options]="select2List"
                                                                formControlName="select2" display="chip"
                                                                (onChange)="getMappedDetails()" class="custom-chips">
                                                            </p-multiSelect>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div
                                                    class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                                                    <label *ngIf="flag1" class="d-icons group-icon"></label>
                                                    <div class="p-fluid p-formgrid p-grid">
                                                        <div
                                                            class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-p-0 p-offset-undefined left">
                                                            <div class=" p-fluid   p-field    ">
                                                                <div style="display: flex;width:100%;"
                                                                    [class]="styleClass" [ngStyle]="style"
                                                                    class="custom-picklist-with-checkbox"
                                                                    [ngClass]="{'p-picklist p-component p-widget p-helper-clearfix': true,'p-picklist-responsive': responsive}"
                                                                    *ngIf="!flag1">
                                                                    <div class="p-picklist-list-wrapper p-picklist-source-wrapper"
                                                                        [ngClass]="{'p-picklist-listwrapper-nocontrols':!showSourceControls}"
                                                                        style="padding:0px;width:46.62%;">
                                                                        <div class="p-picklist-header">
                                                                            <div class="p-picklist-title">
                                                                                Unmapped Users
                                                                                <span
                                                                                    class="p-picklist-count">{{pickList1List.length}}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-picklist-filter-container"
                                                                            *ngIf="filterBy && showSourceFilter !== false">
                                                                            <div class="p-picklist-filter">
                                                                                <span class="p-input-icon-left"
                                                                                    style="margin: 0px;">
                                                                                    <input #sourceFilter type="text"
                                                                                        role="textbox"
                                                                                        (keydown.enter)="$event.preventDefault()"
                                                                                        class="p-picklist-filter-input p-inputtext inputtextHeight p-component"
                                                                                        placeholder="Search By Name"
                                                                                        (input)="filterMethodPick($event.target.value,pickList1List1,'pick1source')"
                                                                                        [disabled]="disabled"
                                                                                        formControlName="sourceFilter">
                                                                                    <i aria-hidden="true"
                                                                                        class="pi pi-search"></i>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <ul #sourcelist
                                                                            class="cdk-drop-list p-picklist-list p-picklist-source"
                                                                            [ngClass]="{'p-picklist-highlight': listHighlightSource}"
                                                                            [ngStyle]="sourceStyle">
                                                                            <ng-template ngFor let-item
                                                                                [ngForOf]="pickList1List"
                                                                                [ngForTrackBy]="sourceTrackBy || trackBy"
                                                                                let-i="index" let-l="last">
                                                                                <li [ngClass]="{'cdk-drag p-picklist-item p-ripple':true,'p-state-highlight':isSelected(item,selectedItemsSource), 'p-state-disabled': disabled}"
                                                                                    [style.display]="isItemVisible(item, SOURCE_LIST) ? 'block' : 'none'">
                                                                                    <div class="product-item"
                                                                                        style="padding-top: 0rem;padding-bottom: 0rem;height:25px;">
                                                                                        <span
                                                                                            class="list-check image-container p-mr-2 p-mt-0"
                                                                                            style="top: 0px !important;">
                                                                                            <p-checkbox name="groupname"
                                                                                                [value]="isChecked"
                                                                                                #picklistCheckboxes
                                                                                                [(ngModel)]="item.isChecked"
                                                                                                binary="true"
                                                                                                (ngModelChange)="onItemClick($event,item,selectedItemsSource,onSourceSelect)"
                                                                                                [ngModelOptions]="{standalone: true}"
                                                                                                [disabled]="item.checked">
                                                                                            </p-checkbox>
                                                                                        </span>
                                                                                        <div
                                                                                            class="product-list-detail">
                                                                                            <div class="pClass">
                                                                                                {{item.label}}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                                <li class="p-picklist-droppoint"
                                                                                    *ngIf="dragdrop&&l"
                                                                                    (dragover)="onDragOver($event, i + 1, SOURCE_LIST)"
                                                                                    (drop)="onDrop($event, i + 1, SOURCE_LIST)"
                                                                                    (dragleave)="onDragLeave($event, SOURCE_LIST)"
                                                                                    [ngClass]="{'p-picklist-droppoint-highlight': (i + 1 === dragOverItemIndexSource)}">
                                                                                </li>
                                                                            </ng-template>
                                                                        </ul>
                                                                    </div>
                                                                    <div class="p-picklist-buttons p-picklist-transfer-buttons"
                                                                        style="width:min-content;">
                                                                        <button type="button" pButton pRipple
                                                                            icon="pi pi-angle-right"
                                                                            class="p-ripple p-button p-component p-button-icon-only"
                                                                            (click)="this.messageFlag = true;limitCheck()"
                                                                            [disabled]="this.status == 'Completed' || this.disableMappings">

                                                                            <span class="p-ink"></span>
                                                                        </button>
                                                                        <button type="button" pButton
                                                                            icon="pi pi-angle-double-right"
                                                                            class="p-ripple p-button p-component p-button-icon-only"
                                                                            (click)="limitCheckAllRight()"
                                                                            [disabled]="this.status == 'Completed' || this.disableMappings">

                                                                            <span class="p-ink"></span>
                                                                        </button>
                                                                        <button type="button" pButton
                                                                            icon="pi pi-angle-left"
                                                                            class="p-ripple p-button p-component p-button-icon-only"
                                                                            (click)="moveLeft()"
                                                                            [disabled]="this.status == 'Completed' || this.disableMappings">

                                                                            <span class="p-ink"></span>
                                                                        </button>
                                                                        <button type="button" pButton
                                                                            icon="pi pi-angle-double-left"
                                                                            class="p-ripple p-button p-component p-button-icon-only"
                                                                            (click)="moveAllLeft()"
                                                                            [disabled]="this.status == 'Active' || this.status == 'Completed' || this.disableMappings">

                                                                            <span class="p-ink"></span>
                                                                        </button>
                                                                    </div>
                                                                    <div class="p-picklist-list-wrapper p-picklist-target-wrapper"
                                                                        [ngClass]="{'p-picklist-listwrapper-nocontrols':!showTargetControls}"
                                                                        style="padding:0px;width:46.62%;">
                                                                        <div class="p-picklist-header">
                                                                            <div class="p-picklist-title">Mapped
                                                                                User
                                                                                <span
                                                                                    class="p-picklist-count">{{pickList1targetList.length}}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="p-picklist-filter-container"
                                                                            *ngIf="filterBy && showSourceFilter !== false">
                                                                            <div class="p-picklist-filter">
                                                                                <span class="p-input-icon-left"
                                                                                    style="margin: 0px;">
                                                                                    <input #sourceFilter type="text"
                                                                                        role="textbox"
                                                                                        (keydown.enter)="$event.preventDefault()"
                                                                                        class="p-picklist-filter-input p-inputtext inputtextHeight p-component"
                                                                                        placeholder="Search By Name"
                                                                                        (input)="filterMethodPick($event.target.value,pickList1targetList1,'pick1target')"
                                                                                        [disabled]="disabled"
                                                                                        formControlName="sourceFilter">
                                                                                    <i aria-hidden="true"
                                                                                        class="pi pi-search"></i>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <ul #targetlist cdkDropList
                                                                            class="cdk-drop-list p-picklist-list p-picklist-target"
                                                                            [ngClass]="{'p-picklist-highlight': listHighlightTarget}"
                                                                            [ngStyle]="targetStyle">
                                                                            <ng-template ngFor let-item
                                                                                [ngForOf]="pickList1targetList"
                                                                                [ngForTrackBy]="targetTrackBy || trackBy"
                                                                                let-i="index" let-l="last">
                                                                                <li pRipple cdkDrag role="option"
                                                                                    [ngClass]="{'cdk-drag p-picklist-item p-ripple':true,'p-state-highlight':isSelected(item,selectedItemsTarget), 'p-state-disabled': disabled}"
                                                                                    [style.display]="isItemVisible(item, TARGET_LIST) ? 'flex' : 'none'">
                                                                                    <div class="product-item"
                                                                                        style="padding-top: 0rem;padding-bottom: 0rem;height:25px;">
                                                                                        <span
                                                                                            class="list-check image-container p-mr-2 p-mt-0"
                                                                                            style="top: 0px !important;">
                                                                                            <p-checkbox name="groupname"
                                                                                                [value]="isChecked"
                                                                                                #picklistCheckboxes
                                                                                                [(ngModel)]="item.isChecked"
                                                                                                binary="true"
                                                                                                (ngModelChange)="onItemClick($event,item,selectedItemsTarget,onTargetSelect)"
                                                                                                [ngModelOptions]="{standalone: true}"
                                                                                                [disabled]="item.checked">
                                                                                            </p-checkbox>
                                                                                        </span>
                                                                                        <div
                                                                                            class="product-list-detail">
                                                                                            <div class="pClass">
                                                                                                {{item.label}}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                                <li class="p-picklist-droppoint"
                                                                                    *ngIf="dragdrop&&l"
                                                                                    (dragover)="onDragOver($event, i + 1, TARGET_LIST)"
                                                                                    (drop)="onDrop($event, i + 1, TARGET_LIST)"
                                                                                    (dragleave)="onDragLeave($event, TARGET_LIST)"
                                                                                    [ngClass]="{'p-picklist-droppoint-highlight': (i + 1 === dragOverItemIndexTarget)}">
                                                                                </li>
                                                                            </ng-template>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-p-0 p-right"
                                                            *ngIf="!flag1">
                                                            <div class="btnwrap custom-picklist-btn">
                                                                <button pButton type="button" label="Secondary"
                                                                    class="p-button-primary p-button" [disabled]="btn"
                                                                    (click)="onSave($event)" label="Save"></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </p-tabPanel>
                                    <p-tabPanel>
                                        <form [formGroup]="AddOffersForm" autocomplete="off" novalidate>
                                            <div class="p-formgrid p-grid p-mb-6">
                                                <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left p-pt-3"
                                                    style="background: #F6F8FA;border-radius: 4px;cursor: not-allowed;">
                                                    <div class=" p-fluid   p-field    "
                                                        *ngIf="this.applicationRoleList?.length>0">
                                                        <b>
                                                            Application Roles
                                                        </b>
                                                        <div class="p-fluid p-pt-2">
                                                            <p-chip *ngFor="let group of this.applicationRoleList;"
                                                                styleClass="mr-2 mb-2"
                                                                class="custom-view-chip p-pr-2">{{group.name}}</p-chip>
                                                        </div>
                                                    </div>
                                                    <!-- <div class=" p-fluid   p-field    ">
                                                                                                                <label>
                                                                                                                    Attribute Type/Group:
                                                                                                                    <b>{{this.select5List[0].label}}</b>
                                                                                                                </label>
                                                                                                                <label class="p-pl-3">
                                                                                                                    Attribute Type: <b>{{this.select5List[0].label}}</b>
                                                                                                                </label>
                                                                                                                <label class="p-pl-3">
                                                                                                                    Attribute Group: <b>{{this.select5List[0].label}}</b>
                                                                                                                </label>
                                                                                                            </div> -->
                                                    <div class=" p-fluid   p-field    "
                                                        *ngIf="this.attributeList?.length>0">
                                                        <b>
                                                            Attributes
                                                        </b>
                                                        <div class="p-fluid p-pt-2">
                                                            <p-chip *ngFor="let group of this.attributeList;"
                                                                styleClass="mr-2 mb-2"
                                                                class="custom-view-chip p-pr-2">{{group.name}}</p-chip>
                                                        </div>
                                                    </div>
                                                    <div class=" p-fluid   p-field    "
                                                        *ngIf="this.attributeGroupList?.length>0">
                                                        <b>
                                                            Attributes Groups
                                                        </b>
                                                        <div class="p-fluid p-pt-2">
                                                            <p-chip *ngFor="let group of this.attributeGroupList;"
                                                                styleClass="mr-2 mb-2"
                                                                class="custom-view-chip p-pr-2">{{group.name}}</p-chip>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div
                                                    class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left p-mt-4">
                                                    <div class="p-fluid p-formgrid p-grid">
                                                        <div
                                                            class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-p-0 p-offset-undefined left">
                                                            <div class=" p-fluid   p-field    ">
                                                                <div class="p-grid   " [ngStyle]="{'height': ''}">
                                                                    <div class="p-col-12 p-sm-12 p-md-3 p-lg-3 p-xl-3 p-offset-undefined undefined none"
                                                                        [ngStyle]="{'width': ''}">
                                                                        <div class="">

                                                                            <p id="planAtrributes"
                                                                                name="planAtrributes_name">
                                                                                Offers
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-col-12 p-sm-12 p-md-3 p-lg-3 p-xl-3 p-offset-0 undefined none"
                                                                        [ngStyle]="{'width': ''}">
                                                                        <div class="">
                                                                            <p id="priceUnitValue"
                                                                                name="priceUnitValue_name">
                                                                                Value
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-col-12 p-sm-12 p-md-3 p-lg-3 p-xl-3 p-offset-0 undefined"
                                                                        [ngStyle]="{'width': ''}">
                                                                        <div class="">
                                                                            <p id="perUnitType" name="perUnitType_name">
                                                                                Start Date
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-col-12 p-sm-12 p-md-3 p-lg-3 p-xl-3 p-offset-0 undefined"
                                                                        [ngStyle]="{'width': ''}">
                                                                        <div class="">
                                                                            <p id="perUnitType" name="perUnitType_name">
                                                                                End Date
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-sm-12 p-md-3 p-lg-3 p-xl-3 p-offset-0 undefined"
                                                                        [ngStyle]="{'width': ''}">
                                                                        <label>Grace Period (Days)</label>
                                                                    </div>
                                                                    <div class="p-col-12 p-sm-12 p-md-3 p-lg-3 p-xl-3 p-offset-0 undefined"
                                                                        [ngStyle]="{'width': ''}">
                                                                        <div class="p-fluid">
                                                                            <input id="inputid" type="text"
                                                                                name="inputname"
                                                                                pattern="^([1-9]|1[0-9]|2[0-9]||3[0]{1})$"
                                                                                pInputText placeholder="Enter"
                                                                                formControlName="grace_period"
                                                                                [(ngModel)]="this.grace_period"
                                                                                (keyup)="changeEndDate()"
                                                                                class="p-inputtext inputtextHeight">
                                                                        </div>
                                                                        <div class="invalid-feedback"
                                                                            *ngIf="AddOffersForm.get('grace_period')!.invalid && (AddOffersForm.get('grace_period')!.dirty || AddOffersForm.get('grace_period')!.touched)">
                                                                            <div
                                                                                *ngIf="AddOffersForm.get('grace_period')?.errors?.pattern">
                                                                                <label
                                                                                    class="alert-icon alert-circle-icon"></label>
                                                                                Minimum 1 and Maximum 30 Days Grace
                                                                                Period allowed.
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-col-12 p-sm-12 p-md-3 p-lg-3 p-xl-3 p-offset-0 undefined"
                                                                        [ngStyle]="{'width': ''}">
                                                                        <div class="p-fluid ">
                                                                            <p-calendar class="custom-calender"
                                                                                selectionMode="single"
                                                                                dataString="string"
                                                                                [disabledDates]="invalidDates"
                                                                                [showIcon]="true" dateFormat="dd-mm-yy"
                                                                                inputId="basic"
                                                                                placeholder="Select date"
                                                                                formControlName="gracePeriodStartingDate"
                                                                                [(ngModel)]="this.gracePeriodStartingDate">
                                                                            </p-calendar>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-col-12 p-sm-12 p-md-3 p-lg-3 p-xl-3 p-offset-0 undefined"
                                                                        [ngStyle]="{'width': ''}">
                                                                        <div class="p-fluid">
                                                                            <p-calendar class="custom-calender"
                                                                                selectionMode="single"
                                                                                dataString="string"
                                                                                [minDate]="this.gracePeriodStartingDate"
                                                                                [maxDate]="this.gracePriodMinEndDate"
                                                                                [disabledDates]="invalidDates"
                                                                                [showIcon]="true" dateFormat="dd-mm-yy"
                                                                                inputId="dateformat"
                                                                                (onSelect)="changeGracePeriod()"
                                                                                placeholder="Enter Grace Period"
                                                                                [(ngModel)]="this.gracePeriodEndingDate"
                                                                                formControlName="gracePeriodEndingDate">
                                                                            </p-calendar>

                                                                        </div>
                                                                    </div>
                                                                    <div class="p-sm-12 p-md-3 p-lg-3 p-xl-3 p-offset-0 undefined"
                                                                        [ngStyle]="{'width': ''}">
                                                                        <label>Additional Users (No.)</label>
                                                                    </div>
                                                                    <div class="p-col-12 p-sm-12 p-md-3 p-lg-3 p-xl-3 p-offset-0 undefined"
                                                                        [ngStyle]="{'width': ''}">
                                                                        <div class="p-fluid">
                                                                            <input id="inputid" type="text"
                                                                                name="inputname" pattern="^[1-9]\d*"
                                                                                pInputText placeholder="Enter"
                                                                                [(ngModel)]="this.additional_user_count"
                                                                                formControlName="additional_user_count"
                                                                                (keyup)="changeAddUser()"
                                                                                class="p-inputtext inputtextHeight">
                                                                        </div>
                                                                        <div class="invalid-feedback"
                                                                            *ngIf="AddOffersForm.get('additional_user_count')!.invalid && (AddOffersForm.get('additional_user_count')!.dirty || AddOffersForm.get('additional_user_count')!.touched)">
                                                                            <div
                                                                                *ngIf="AddOffersForm.get('additional_user_count')?.errors?.pattern">
                                                                                <label
                                                                                    class="alert-icon alert-circle-icon"></label>
                                                                                Minimum 1 Additional User is required.
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-col-12 p-sm-12 p-md-3 p-lg-3 p-xl-3 p-offset-0 undefined"
                                                                        [ngStyle]="{'width': ''}">
                                                                        <div class="p-fluid ">
                                                                            <p-calendar class="custom-calender"
                                                                                selectionMode="single"
                                                                                dataString="string"
                                                                                [maxDate]="this.addiotionalUserEndingDate"
                                                                                [minDate]="this.addiotionalUserStartingDate"
                                                                                [disabledDates]="invalidDates"
                                                                                [showIcon]="true" dateFormat="dd-mm-yy"
                                                                                inputId="basic"
                                                                                placeholder="Select date"
                                                                                formControlName="addiotionalUserStartingDate"
                                                                                [(ngModel)]="this.addiotionalUserStartingDate">
                                                                            </p-calendar>
                                                                        </div>
                                                                    </div>
                                                                    <div class="p-col-12 p-sm-12 p-md-3 p-lg-3 p-xl-3 p-offset-0 undefined"
                                                                        [ngStyle]="{'width': ''}">
                                                                        <div class="p-fluid">
                                                                            <p-calendar class="custom-calender"
                                                                                selectionMode="single"
                                                                                dataString="string"
                                                                                [maxDate]="this.addiotionalUserEndingDate"
                                                                                [minDate]="this.addiotionalUserStartingDate"
                                                                                [disabledDates]="invalidDates"
                                                                                [showIcon]="true" dateFormat="dd-mm-yy"
                                                                                inputId="dateformat"
                                                                                placeholder="Select date"
                                                                                formControlName="addiotionalUserEndingDate"
                                                                                [(ngModel)]="this.addiotionalUserEndingDate">
                                                                            </p-calendar>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-p-0 p-right">
                                                            <div class="btnwrap custom-picklist-btn">
                                                                <button pButton type="button" label="Secondary"
                                                                    class="p-button-primary p-button"
                                                                    [disabled]="!this.AddOffersForm.valid || !saveFlag"
                                                                    (click)="saveOffers()" label="Save"></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </p-tabPanel>
                                </p-tabView>
                            </div>
                        </p-card>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>