<div class="layout-content custom-form-layout">
	<form [formGroup]="EditAccountForm" autocomplete="off" novalidate>
		<div class="p-formgrid p-grid">
			<div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 left">
				<div class="p-fluid p-formgrid p-grid p-mt-3">
					<div class="p-col-12 p-d-flex p-pb-2">
						<div style="background-color: #F4F8FF;"
							class="p-col-12 p-sm-12 p-md-12 p-lg-2 p-xl-2 p-offset-0 left">
							<div style="margin-top: 10px;margin-bottom: 10px;" class="p-fluid">
								<span class="d-block label-top"> <label>
										Account ID
									</label>
								</span>
								<span class="valueOfSaved" *ngIf="this.accountPkId!=null">{{this.accountPkId}}</span>
								<span class="valueOfSaved" *ngIf="this.accountPkId==null">-</span>
							</div>
						</div>
						<div style="background-color: #F4F8FF;"
							class="p-col-12 p-sm-12 p-md-12 p-lg-2 p-xl-2 p-offset-0 left">
							<div style="margin-top: 10px;margin-bottom: 10px;" class="p-fluid">
								<span class="d-block label-top"> <label>
										Account Type
									</label>
								</span>
								<span class="valueOfSaved">{{this.accountType}}</span>
							</div>
						</div>
						<div style="background-color: #F4F8FF;"
							class="p-col-12 p-sm-12 p-md-12 p-lg-2 p-xl-2 p-offset-0 left">
							<div *ngIf="this.personalFlag==true" style="margin-top: 10px;margin-bottom: 10px;"
								class="p-fluid">
								<span class="d-block label-top"> <label>
										Organization Type
									</label>
								</span>
								<span class="valueOfSaved">{{this.orgType}}</span>
							</div>
						</div>
						<div style="background-color: #F4F8FF;"
							class="p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3 p-offset-0 left">
							<div *ngIf="this.personalFlag==true" style="margin-top: 10px;margin-bottom: 10px;"
								class="p-fluid">
								<span class="d-block label-top"> <label>
										Organization name
									</label>
								</span>
								<span class="valueOfSaved" *ngIf="this.orgName!=null"><span pTooltip="{{this.orgName}}"
										*ngIf="this.orgName.length >= 18" tooltipPosition="top">
										{{this.orgName?.substring(0,18) + "..."
										}}
									</span>
									<span class="valueOfSaved" *ngIf="this.orgName?.length < 18">
										{{this.orgName}}
									</span>
								</span>
							</div>

						</div>
						<div style="background-color: #F4F8FF;"
							class="p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3 p-offset-0 p-right">
							<div class="btnwrap" style="margin-top: 20px;margin-bottom: 10px;">
								<button pButton class="p-button-secondary" type="p-splitbutton" icon="pi pi-plus"
									(click)="showCreateAccountParameter()" label="Add Parameter"></button>
							</div>
						</div>
					</div>
					<br>
					<br>
					<div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left">
						<div class="p-fluid p-field">
							<label
								[ngClass]="{'field-required':  EditAccountForm.get('accountNameInput')?.errors?.required}">
								Account Name
							</label>
							<span class="invalid-feedback-astrik">*</span>
							<input [(ngModel)]="this.accountName" type="text" id="accountNameInput_id"
								name="accountNameInput_name" formControlName="accountNameInput" pInputText
								placeholder="Enter account name" class="p-inputtext inputtextHeight"
								pattern="^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$" maxlength="50"
								(keyup)="enableBtn1 = false;" />
							<div class="invalid-feedback"
								*ngIf=" EditAccountForm.get('accountNameInput')!.invalid &&( EditAccountForm.get('accountNameInput')!.dirty || EditAccountForm.get('accountNameInput')!.touched)">
								<medium *ngIf=" EditAccountForm.get('accountNameInput')?.errors?.required"
									class="p-error">
									<label class="alert-icon alert-circle-icon"></label>
									Account name is required!
								</medium>
							</div>
							<div class="invalid-feedback"
								*ngIf=" EditAccountForm.get('accountNameInput')!.invalid &&( EditAccountForm.get('accountNameInput')!.dirty || EditAccountForm.get('accountNameInput')!.touched)">
								<div *ngIf=" EditAccountForm.get('accountNameInput').errors.pattern">
									<label class="alert-icon alert-circle-icon"></label>
									<medium class="p-error">Account Name should not have Special
										Character</medium>
								</div>
							</div>
						</div>
					</div>
					<div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
						<div class="p-fluid p-field">
							<label>
								Country
							</label>
							<p-dropdown [(ngModel)]="this.countryId" id="country_id" #dd name="country_name"
								placeholder="Select" [options]="codes" autoZIndex="true" baseZIndex="1"
								(onChange)="enableBtn1 = false" [autoDisplayFirst]="false"
								formControlName="countryDropdown" [filter]="true">
								<ng-template let-item pTemplate="item">
									<div class="drop-item" style="display: inline;" *ngIf="item.label.length <= 35;">
										{{ item.label }}
									</div>
									<div class="drop-item" style="display: inline;" *ngIf="item.label.length > 35;"
										pTooltip="{{item.label}}" tooltipPosition="bottom">
										{{ item.label.substring(0,35)
										+ "..." }}
									</div>
								</ng-template>
							</p-dropdown>
						</div>
					</div>
					<div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
						<div class="p-fluid p-field">
							<label>
								Pin / Zip Code
							</label>

							<input [(ngModel)]="pinCode" type="text" id="pinCodeInput_id" name="pinCodeInput_name"
								formControlName="pinCodeInput" pInputText placeholder="Enter pin / zip code"
								class="p-inputtext inputtextHeight" pattern="^[1-9]\d*$" maxlength="50"
								(keyup)="enableBtn1 = false" />
							<div class="invalid-feedback"
								*ngIf=" EditAccountForm.get('pinCodeInput')!.invalid &&( EditAccountForm.get('pinCodeInput')!.dirty || EditAccountForm.get('pinCodeInput')!.touched)">
								<div *ngIf=" EditAccountForm.get('pinCodeInput').errors.pattern">
									<label class="alert-icon alert-circle-icon"></label>
									<medium class="p-error"> Pin code should not have Special
										Character</medium>
								</div>
							</div>
						</div>
					</div>
					<div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
						<div class=" p-fluid   p-field    ">
							<label>
								Account address
							</label><textarea [(ngModel)]="accountAddress" pInputTextarea id="accountAddressArea_id"
								(keyup)="enableBtn1 = false" name="accountAddressArea_name"
								formControlName="accountAddressArea" rows="3" placeholder="Enter account address"
								maxlength="200" style="height:72px;"></textarea>
						</div>
					</div>
					<div *ngFor="let customField of configurableList;let index=index;let count=count"
						class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-d-flex p-offset-undefined p-pl-0 p-pr-0">
						<div class="p-col" *ngIf="customField.fieldInputtype=='Dropdown'">
							<div class="p-fluid p-field">
								<label *ngIf="customField.mandatoryField==true"
									[ngClass]="{'field-required': EditAccountForm.get(customField.id)?.errors?.required}">

									<span pTooltip="{{customField.fieldName}}"
										*ngIf="customField.fieldName.length >= 18" tooltipPosition="top">
										{{customField.fieldName?.substring(0,18) + "..."
										}}
									</span>
									<span *ngIf="customField.fieldName?.length < 18">
										{{customField.fieldName}}
									</span>

								</label>
								<label *ngIf="customField.mandatoryField==false">
									<span pTooltip="{{customField.fieldName}}"
										*ngIf="customField.fieldName.length >= 18" tooltipPosition="top">
										{{customField.fieldName?.substring(0,18) + "..."
										}}
									</span>
									<span *ngIf="customField.fieldName?.length < 18">
										{{customField.fieldName}}
									</span>

								</label>
								<span class="invalid-feedback-astrik" *ngIf="customField.mandatoryField==true">*</span>
								<div class="p-fluid p-field">
									<p-dropdown [(ngModel)]="customField.fieldValue" id="{{customField.id}}id"
										name="{{customField.id}}" placeholder="Select"
										(onChange)="this.enableBtn1=false;console();" optionLabel="label"
										[options]="customField.inputFormat" formControlName="{{customField.id}}"
										[autoDisplayFirst]="false" [required]="customField.mandatoryField==true">
									</p-dropdown>

									<div class="invalid-feedback"
										*ngIf="EditAccountForm.get(customField.id)!.invalid && (EditAccountForm.get(customField.id)!.dirty || EditAccountForm.get(customField.id)!.touched) ">
										<div *ngIf="EditAccountForm.get(customField.id)?.errors?.required">
											<label class="alert-icon alert-circle-icon"></label>
											<medium class="p-error">This field is required</medium>
										</div>
									</div>
								</div>

							</div>
						</div>
						<div class="p-col" *ngIf="customField.fieldInputtype=='Text'">
							<div class="p-fluid p-field">
								<label *ngIf="customField.mandatoryField==true"
									[ngClass]="{'field-required': EditAccountForm.get(customField.id)?.errors?.required}">
									<span pTooltip="{{customField.fieldName}}"
										*ngIf="customField.fieldName.length >= 18" tooltipPosition="top">
										{{customField.fieldName?.substring(0,18) + "..."
										}}
									</span>
									<span *ngIf="customField.fieldName?.length < 18">
										{{customField.fieldName}}
									</span>
								</label>
								<label *ngIf="customField.mandatoryField==false">
									<span pTooltip="{{customField.fieldName}}"
										*ngIf="customField.fieldName.length >= 18" tooltipPosition="top">
										{{customField.fieldName?.substring(0,18) + "..."
										}}
									</span>
									<span *ngIf="customField.fieldName?.length < 18">
										{{customField.fieldName}}
									</span>
								</label>
								<span class="invalid-feedback-astrik" *ngIf="customField.mandatoryField==true">*</span>
								<div class="p-fluid p-field">
									<input [(ngModel)]="customField.fieldValue" type="text" id="{{customField.id}}_id"
										name="{{customField.id}}_name" pInputText formControlName="{{customField.id}}"
										placeholder="Enter {{customField.fieldName}}"
										class="p-inputtext inputtextHeight" maxlength="{{customField.maxCharacters}}"
										[required]="customField.mandatoryField==true" [pattern]="customField.pattern"
										(keyup)="enableBtn1 = false" />
									<div class="invalid-feedback"
										*ngIf="EditAccountForm.get(customField.id)!.invalid && (EditAccountForm.get(customField.id)!.dirty || EditAccountForm.get(customField.id)!.touched) ">
										<div *ngIf="EditAccountForm.get(customField.id)?.errors?.required">
											<label class="alert-icon alert-circle-icon"></label>
											<medium class="p-error">This field is required</medium>
										</div>
										<div *ngIf="EditAccountForm.get(customField.id)?.errors?.pattern">
											<label class="alert-icon alert-circle-icon"></label>
											<medium class="p-error">Invalid field format.
											</medium>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div *ngFor="let customField of ipRangearr;let index=index;let count=count"
						class="p-col-12 p-lg-2 p-lg-4 p-xl-4 p-pl-0 p-d-flex">
						<div class="p-col" *ngIf="customField.fieldInputtype=='Text Range'">
							<div class="p-fluid p-field">
								<label *ngIf="customField.mandatoryField==true"
									[ngClass]="{'field-required': EditAccountForm.get(customField.id)?.errors?.required}">
									<span pTooltip="{{customField.fieldName}}"
										*ngIf="customField.fieldName.length >= 18" tooltipPosition="top">
										{{customField.fieldName?.substring(0,18) + "..."
										}}
									</span>
									<span *ngIf="customField.fieldName?.length < 18">
										{{customField.fieldName}}
									</span>
								</label>
								<label *ngIf="customField.mandatoryField==false">
									<span pTooltip="{{customField.fieldName}}"
										*ngIf="customField.fieldName.length >= 18" tooltipPosition="top">
										{{customField.fieldName?.substring(0,18) + "..."
										}}
									</span>
									<span *ngIf="customField.fieldName?.length < 18">
										{{customField.fieldName}}
									</span>
								</label>
								<span class="invalid-feedback-astrik" *ngIf="customField.mandatoryField==true">*</span>
								<div class="p-fluid p-field ">
									<input [(ngModel)]="customField.fieldValue" type="text" id="{{customField.id}}"
										name="{{customField.id}}_name" pInputText formControlName="{{customField.id}}"
										placeholder="Enter {{customField.fieldName}}"
										class="p-inputtext inputtextHeight" maxlength="{{customField.maxCharacters}}"
										[required]="customField.mandatoryField==true"
										(keyup)="enableBtn1 = false;addressStartIpCheck(customField.id);" />
									<div class="invalid-feedback"
										*ngIf="EditAccountForm.get(customField.id)!.invalid && (EditAccountForm.get(customField.id)!.dirty || EditAccountForm.get(customField.id)!.touched) ">
										<div *ngIf="EditAccountForm.get(customField.id)?.errors?.required">
											<label class="alert-icon alert-circle-icon"></label>
											<medium class="p-error">This field is required</medium>
										</div>


									</div>
									<div *ngIf="this['IpFlag' + customField.id]">
										<label class="alert-icon alert-circle-icon"></label>
										<medium class="p-error">Invalid field format.
										</medium>
									</div>
									<div *ngIf="this['duplicityFlag' + customField.id]">
										<label class="alert-icon alert-circle-icon"></label>
										<medium class="p-error">Duplicate IP Range.
										</medium>
									</div>
									<div *ngIf="this['greaterFlag' + customField.id]">
										<label class="alert-icon alert-circle-icon"></label>
										<medium class="p-error">Start IP cannot be greater than end IP.
										</medium>
									</div>
								</div>
							</div>
						</div>
						<button *ngIf="index==0" style="margin-top: 30px;width: 32px;height: 32px;" pButton
							type="button" icon="pi pi-plus" pTooltip="Add IP Range" tooltipPosition="top"
							class="p-button-brand2 p-mr-2" (click)="addIPrange()">
						</button>
						<button *ngIf="index>0" pButton
							style="box-shadow:none;border:none; background-color: transparent;margin-top: 35px;width: 35px;height: 24px;padding: 0px;"
							icon="g-icons delete-icon" tooltipStyleClass="customTooltip" pTooltip="Delete Parameter"
							tooltipPosition="top" [escape]="false" class="background-transparent "
							(click)="deleteParameter(customField.id);">
						</button>
					</div>
					<div *ngFor="let customField of customList;let index=index;let count=count"
						class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-d-flex p-offset-undefined p-pl-0 p-pr-0"
						[ngClass]="{'disabled-field': (customField.fieldName == SSO_ALIAS_NAME || customField.fieldName == IDENTITY_PROVIDER_USERNAME) && !ssoEnabled}">
						<div class="p-col" *ngIf="customField.fieldInputtype=='Dropdown'">
							<div class="p-fluid p-field">
								<label *ngIf="customField.mandatoryField==true"
									[ngClass]="{'field-required': EditAccountForm.get(customField.id)?.errors?.required}">
									<span pTooltip="{{customField.fieldName}}"
										*ngIf="customField.fieldName.length >= 18" tooltipPosition="top">
										{{customField.fieldName?.substring(0,18) + "..."
										}}
									</span>
									<span *ngIf="customField.fieldName?.length < 18">
										{{customField.fieldName}}
									</span>
								</label>
								<label *ngIf="customField.mandatoryField==false">
									<span pTooltip="{{customField.fieldName}}"
										*ngIf="customField.fieldName.length >= 18" tooltipPosition="top">
										{{customField.fieldName?.substring(0,18) + "..."
										}}
									</span>
									<span *ngIf="customField.fieldName?.length < 18">
										{{customField.fieldName}}
									</span>
								</label>
								<span class="invalid-feedback-astrik" *ngIf="customField.mandatoryField==true">*</span>
								<div class="p-fluid p-field">
									<p-dropdown [(ngModel)]="customField.fieldValue" id="{{customField.id}}id"
										name="{{customField.id}}" placeholder="Select"
										(onChange)="customField.fieldName == SSO ? onSSOChange($event,customField.fieldName) : null; this.enableBtn1=false;"
										optionLabel="label" [options]="customField.inputFormat"
										formControlName="{{customField.id}}" [autoDisplayFirst]="false"
										[required]="customField.mandatoryField==true">
									</p-dropdown>

									<div class="invalid-feedback"
										*ngIf="EditAccountForm.get(customField.id)!.invalid && (EditAccountForm.get(customField.id)!.dirty || EditAccountForm.get(customField.id)!.touched) ">
										<div *ngIf="EditAccountForm.get(customField.id)?.errors?.required">
											<label class="alert-icon alert-circle-icon"></label>
											<medium class="p-error">This field is required</medium>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="p-col" *ngIf="customField.fieldInputtype=='Multiselect'" style="width: inherit;">
							<div class="p-fluid p-field">
								<label *ngIf="customField.mandatoryField==true"
									[ngClass]="{'field-required': EditAccountForm.get(customField.id)?.errors?.required}">
									<span pTooltip="{{customField.fieldName}}"
										*ngIf="customField.fieldName.length >= 18" tooltipPosition="top">
										{{customField.fieldName?.substring(0,18) + "..."
										}}
									</span>
									<span *ngIf="customField.fieldName?.length < 18">
										{{customField.fieldName}}
									</span>
								</label>
								<label *ngIf="customField.mandatoryField==false">
									<span pTooltip="{{customField.fieldName}}"
										*ngIf="customField.fieldName.length >= 18" tooltipPosition="top">
										{{customField.fieldName?.substring(0,18) + "..."
										}}
									</span>
									<span *ngIf="customField.fieldName?.length < 18">
										{{customField.fieldName}}
									</span>
								</label>
								<span class="invalid-feedback-astrik" *ngIf="customField.mandatoryField==true">*</span>
								<div class="p-fluid p-field">
									<p-multiSelect [(ngModel)]="customField.fieldValue" id="{{customField.id}}id"
										name="{{customField.id}}" placeholder="Select"
										(onChange)="this.enableBtn1=false;" [options]="serviceTaxonomyList"
										formControlName="{{customField.id}}" [autoDisplayFirst]="false" display="chip"
										resetFilterOnHide="true" class="custom-chips"
										[required]="customField.mandatoryField==true">
									</p-multiSelect>
									<div class="invalid-feedback"
										*ngIf="EditAccountForm.get(customField.id)!.invalid && (EditAccountForm.get(customField.id)!.dirty || EditAccountForm.get(customField.id)!.touched) ">
										<div *ngIf="EditAccountForm.get(customField.id)?.errors?.required">
											<label class="alert-icon alert-circle-icon"></label>
											<medium class="p-error">This field is required</medium>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="p-col" *ngIf="customField.fieldInputtype=='Text'">
							<div class="p-fluid p-field">
								<label *ngIf="customField.mandatoryField==true"
									[ngClass]="{'field-required': EditAccountForm.get(customField.id)?.errors?.required}">
									<span pTooltip="{{customField.fieldName}}"
										*ngIf="customField.fieldName.length >= 18" tooltipPosition="top">
										{{customField.fieldName?.substring(0,18) + "..."
										}}
									</span>
									<span *ngIf="customField.fieldName?.length < 18">
										{{customField.fieldName}}
									</span>
								</label>
								<label *ngIf="customField.mandatoryField==false">
									<span pTooltip="{{customField.fieldName}}"
										*ngIf="customField.fieldName.length >= 18" tooltipPosition="top">
										{{customField.fieldName?.substring(0,18) + "..."
										}}
									</span>
									<span *ngIf="customField.fieldName?.length < 18">
										{{customField.fieldName}}
									</span>
								</label>
								<span class="invalid-feedback-astrik" *ngIf="customField.mandatoryField==true">*</span>
								<div class="p-fluid p-field">
									<input [(ngModel)]="customField.fieldValue" type="text" id="{{customField.id}}_id"
										name="{{customField.id}}_name" pInputText formControlName="{{customField.id}}"
										placeholder="Enter {{customField.fieldName}}"
										class="p-inputtext inputtextHeight" maxlength="{{customField.maxCharacters}}"
										[required]="customField.mandatoryField==true" [pattern]="customField.pattern"
										(keyup)="enableBtn1 = false" />
									<div class="invalid-feedback"
										*ngIf="EditAccountForm.get(customField.id)!.invalid && (EditAccountForm.get(customField.id)!.dirty || EditAccountForm.get(customField.id)!.touched) ">
										<div *ngIf="EditAccountForm.get(customField.id)?.errors?.required">
											<label class="alert-icon alert-circle-icon"></label>
											<medium class="p-error">This field is required</medium>
										</div>
										<div *ngIf="EditAccountForm.get(customField.id)?.errors?.pattern">
											<label class="alert-icon alert-circle-icon"></label>
											<medium class="p-error">Invalid field format.
											</medium>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="p-col"
							*ngIf="customField.fieldInputtype=='Input field in range' && customField.inputFormat[0]=='Date range'">
							<div class="p-fluid p-field">
								<label *ngIf="customField.mandatoryField==true"
									[ngClass]="{'field-required': EditAccountForm.get(customField.id)?.errors?.required}">
									<span pTooltip="{{customField.fieldName}}"
										*ngIf="customField.fieldName.length >= 18" tooltipPosition="top">
										{{customField.fieldName?.substring(0,18) + "..."
										}}
									</span>
									<span *ngIf="customField.fieldName?.length < 18">
										{{customField.fieldName}}
									</span>
								</label>
								<label *ngIf="customField.mandatoryField==false">
									<span pTooltip="{{customField.fieldName}}"
										*ngIf="customField.fieldName.length >= 18" tooltipPosition="top">
										{{customField.fieldName?.substring(0,18) + "..."
										}}
									</span>
									<span *ngIf="customField.fieldName?.length < 18">
										{{customField.fieldName}}
									</span>
								</label>
								<span class="invalid-feedback-astrik" *ngIf="customField.mandatoryField==true">*</span>
								<div class="p-fluid p-field">
									<p-calendar class="custom-calender" selectionMode="range" dataString="string"
										[(ngModel)]="this[customField.id]" showButtonBar="true" [showIcon]="true"
										dateFormat="dd-mm-yy" inputId="dateformat" placeholder="Select date"
										formControlName="{{customField.id}}"
										[required]="customField.mandatoryField==true"
										(onSelect)="enableBtn1 = false;console();">
									</p-calendar>
									<div class="invalid-feedback"
										*ngIf="EditAccountForm.get(customField.id)!.invalid && (EditAccountForm.get(customField.id)!.dirty || EditAccountForm.get(customField.id)!.touched) ">
										<div *ngIf="EditAccountForm.get(customField.id)?.errors?.required">
											<label class="alert-icon alert-circle-icon"></label>
											<medium class="p-error">This field is required</medium>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="p-col" *ngIf="customField.fieldInputtype=='Text area'">
							<div class="p-fluid p-field">
								<label *ngIf="customField.mandatoryField==true"
									[ngClass]="{'field-required': EditAccountForm.get(customField.id)?.errors?.required}">
									<span pTooltip="{{customField.fieldName}}"
										*ngIf="customField.fieldName.length >= 18" tooltipPosition="top">
										{{customField.fieldName?.substring(0,18) + "..."
										}}
									</span>
									<span *ngIf="customField.fieldName?.length < 18">
										{{customField.fieldName}}
									</span>
								</label>
								<label *ngIf="customField.mandatoryField==false">
									<span pTooltip="{{customField.fieldName}}"
										*ngIf="customField.fieldName.length >= 18" tooltipPosition="top">
										{{customField.fieldName?.substring(0,18) + "..."
										}}
									</span>
									<span *ngIf="customField.fieldName?.length < 18">
										{{customField.fieldName}}
									</span>
								</label>
								<span class="invalid-feedback-astrik" *ngIf="customField.mandatoryField==true">*</span>
								<div class="p-fluid p-field">
									<textarea [(ngModel)]="customField.fieldValue" pInputTextarea
										id="{{customField.id}}_id" name="{{customField.id}}_name" rows="3"
										formControlName="{{customField.id}}" maxlength="{{customField.maxCharacters}}"
										[required]="customField.mandatoryField==true" [pattern]="customField.pattern"
										(keyup)="enableBtn1 = false"></textarea>
									<div class="invalid-feedback"
										*ngIf="EditAccountForm.get(customField.id)!.invalid && (EditAccountForm.get(customField.id)!.dirty || EditAccountForm.get(customField.id)!.touched) ">
										<div *ngIf="EditAccountForm.get(customField.id)?.errors?.required">
											<label class="alert-icon alert-circle-icon"></label>
											<medium class="p-error">This field is required</medium>
										</div>
										<div *ngIf="EditAccountForm.get(customField.id)?.errors?.pattern">
											<label class="alert-icon alert-circle-icon"></label>
											<medium class="p-error">Invalid field format.
											</medium>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="p-col"
							*ngIf="customField.fieldInputtype=='Input field in range' && customField.inputFormat[0]=='Text/Number range'">
							<div class="p-fluid p-field">
								<label *ngIf="customField.mandatoryField==true"
									[ngClass]="{'field-required': EditAccountForm.get(customField.id+'_start')?.errors?.required || EditAccountForm.get(customField.id+'_end')?.errors?.required}">
									<span pTooltip="{{customField.fieldName}}"
										*ngIf="customField.fieldName.length >= 18" tooltipPosition="top">
										{{customField.fieldName?.substring(0,18) + "..."
										}}
									</span>
									<span *ngIf="customField.fieldName?.length < 18">
										{{customField.fieldName}}
									</span>
								</label>
								<label *ngIf="customField.mandatoryField==false">
									<span pTooltip="{{customField.fieldName}}"
										*ngIf="customField.fieldName.length >= 18" tooltipPosition="top">
										{{customField.fieldName?.substring(0,18) + "..."
										}}
									</span>
									<span *ngIf="customField.fieldName?.length < 18">
										{{customField.fieldName}}
									</span>
								</label>
								<span class="invalid-feedback-astrik" *ngIf="customField.mandatoryField==true">*</span>
								<div class="inputtext-medium custom-drop">
									<span class=" p-mr-2" style="width:70%">
										<input [(ngModel)]="this.range.start" type="text" id="{{customField.id}}_id"
											name="{{customField.id}}_name" formControlName="{{customField.id}}_start"
											pInputText placeholder="Enter start range"
											class="p-inputtext inputtextHeight "
											[required]="customField.mandatoryField==true"
											(keyup)="enableBtn1 = false" />
									</span>
									<span>-</span>
									<span class=" p-ml-2" style="width:70%">
										<input [(ngModel)]="this.range.last" type="text" id="{{customField.id}}_id"
											name="{{customField.id}}_name" formControlName="{{customField.id}}_end"
											pInputText [required]="customField.mandatoryField==true"
											placeholder="Enter last range" class="p-inputtext inputtextHeight"
											(keyup)="enableBtn1 = false" />
									</span>
								</div>

								<div class="invalid-feedback"
									*ngIf="(EditAccountForm.get(customField.id+'_start')!.invalid && (EditAccountForm.get(customField.id+'_start')!.dirty || EditAccountForm.get(customField.id+'_start')!.touched)) ||  (EditAccountForm.get(customField.id+'_end')!.invalid && (EditAccountForm.get(customField.id+'_end')!.dirty || EditAccountForm.get(customField.id+'_end')!.touched))">
									<div
										*ngIf="(EditAccountForm.get(customField.id+'_start')?.errors?.required) || (EditAccountForm.get(customField.id+'_end')?.errors?.required)">
										<label class="alert-icon alert-circle-icon"></label>
										<medium class="p-error">This field is required</medium>
									</div>
								</div>
							</div>
						</div>

						<button pButton
							[ngClass]="{'disabled-field': customField.fieldName == SSO || customField.fieldName == SSO_ALIAS_NAME || customField.fieldName == IDENTITY_PROVIDER_USERNAME}"
							style="box-shadow:none;border:none; background-color: transparent;margin-top: 32px;width: 30px;height: 30px;padding: 0px;"
							icon="g-icons delete-icon" tooltipStyleClass="customTooltip" pTooltip="Delete Parameter2"
							tooltipPosition="top" [escape]="false" class="background-transparent"
							(click)="deleteParameter(customField.id);">
						</button>
					</div>
					<div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-d-flex p-offset-undefined">
						<div *ngFor="let customField of checkboxArr;let index=index;let count=count"
							class="p-col-12 p-lg-2 p-lg-4 p-xl-4 p-pl-0 p-d-flex">
							<div class="p-fluid p-formgrid p-grid p-col" *ngIf="customField.fieldInputtype=='Checkbox'">
								<div class="p-field-checkbox p-mt-1">
									<p-checkbox id="customField.id" binary="true" inputId="binary"
										(onChange)="this.enableBtn1=false;" formControlName="{{customField.id}}">
									</p-checkbox>
									<label for="binary"><span pTooltip="{{customField.fieldName}}"
											*ngIf="customField.fieldName.length >= 18" tooltipPosition="top">
											{{customField.fieldName?.substring(0,18) + "..."
											}}
										</span>
										<span *ngIf="customField.fieldName?.length < 18">
											{{customField.fieldName}}
										</span></label>
								</div>
							</div>
							<button pButton
								style="box-shadow:none;border:none; background-color: transparent;margin-top: 6px;width: 35px;height: 24px;padding: 0px;"
								icon="g-icons delete-icon" tooltipStyleClass="customTooltip" pTooltip="Delete Parameter"
								tooltipPosition="top" [escape]="false" class="background-transparent"
								(click)="deleteParameter(customField.id);">
							</button>
						</div>

					</div>

					<div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left custom-user-input"
						*ngIf="this.rebacFlag=='false'">
						<h6 class="clr-shade">Account Owner Details</h6>
					</div>
					<div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left"
						*ngIf="this.rebacFlag=='false'">
						<div class="p-fluid">
							<span class="d-block label-top"> <label>
									Select account owner
								</label>
							</span>
							<div class="p-field p-fluid">
								<p-selectButton id="accountOwnerSwitch_id" name="accountOwnerSwitch_name"
									[(ngModel)]="defaultselect_accountOwnerSwitch" [options]="accountOwnerSwitch"
									formControlName="accountOwnerSwitch" styleClass="default"
									(onChange)="enableBtn1=false;valueChangeAccountOwner();">
									<ng-template let-item>
										<span class="p-button-label">{{item.label}}</span>
									</ng-template>
								</p-selectButton>
							</div>
						</div>
					</div>

					<div *ngIf="this.accountOwnerSwitchFlag==false && this.rebacFlag=='false'"
						class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
						<div class=" p-fluid   p-field    ">
							<label>
								Users
							</label>
							<span class=" p-input-icon-left">
								<p-dropdown [(ngModel)]="accountOwnerId" id="usersDropdown_id" #dd
									name="usersDropdown_name" placeholder="Select" [options]="autocompleteList"
									autoZIndex="true" baseZIndex="1" appendTo="body" [autoDisplayFirst]="false"
									formControlName="usersDropdown" (onChange)="formvalidity();" [filter]="true">
									<ng-template let-item pTemplate="item">
										<div class="drop-item" style="display: inline;"
											*ngIf="item.label.length <= 35;">
											{{ item.label }}
										</div>
										<div class="drop-item" style="display: inline;" *ngIf="item.label.length > 35;"
											pTooltip="{{item.label}}" tooltipPosition="bottom">
											{{ item.label.substring(0,35)
											+ "..." }}
										</div>
									</ng-template>
								</p-dropdown>
							</span>
						</div>
					</div>



					<div *ngIf="this.accountOwnerSwitchFlag==true"
						class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left custom-user-input">
						<div class="p-fluid p-field">
							<label [ngClass]="{'field-required':  EditAccountForm.get('firstName')?.errors?.required}">
								First Name
							</label>
							<span class="invalid-feedback-astrik">*</span>
							<input type="text" id="firstName_id" name="firstName_name" formControlName="firstName"
								pInputText placeholder="Enter first name" class="p-inputtext inputtextHeight"
								pattern="^([a-zA-Z]+\s)*[a-zA-Z]+$" maxlength="50" (keyup)="enableBtn1 = false" />

							<div class="invalid-feedback"
								*ngIf=" EditAccountForm.get('firstName')!.invalid &&( EditAccountForm.get('firstName')!.dirty || EditAccountForm.get('firstName')!.touched)">
								<medium *ngIf=" EditAccountForm.get('firstName')?.errors?.required" class="p-error">
									<label class="alert-icon alert-circle-icon"></label>
									First name is required!
								</medium>
								<div *ngIf=" EditAccountForm.get('firstName').errors.pattern">
									<label class="alert-icon alert-circle-icon"></label>
									<medium class="p-error">First Name should not have
										Special
										Character, number
									</medium>
								</div>
							</div>
						</div>
					</div>
					<div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
						<div *ngIf="this.accountOwnerSwitchFlag==true" class="p-fluid p-field">
							<label>
								Middle Name
							</label>
							<input type="text" id="middleName_id" name="middleName_name" formControlName="middleName"
								pInputText placeholder="Enter middle name" class="p-inputtext inputtextHeight"
								pattern="^([a-zA-Z]+\s)*[a-zA-Z]+$" maxlength="50" (keyup)="enableBtn1 = false" />
							<div class="invalid-feedback"
								*ngIf=" EditAccountForm.get('middleName')!.invalid &&( EditAccountForm.get('middleName')!.dirty || EditAccountForm.get('middleName')!.touched)">
								<div *ngIf=" EditAccountForm.get('middleName').errors.pattern">
									<label class="alert-icon alert-circle-icon"></label>
									<medium class="p-error">Middle Name should not have Special
										Character, Number</medium>
								</div>
							</div>
						</div>

					</div>
					<div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left custom-user-input">
						<div *ngIf="this.accountOwnerSwitchFlag==true" class="p-fluid p-field">
							<label [ngClass]="{'field-required':  EditAccountForm.get('lastName')?.errors?.required}">
								Last name
							</label>
							<span class="invalid-feedback-astrik">*</span>
							<input type="text" id="lastName_id" name="lastName_name" formControlName="lastName"
								pInputText placeholder="Enter last name" class="p-inputtext inputtextHeight"
								pattern="^([a-zA-Z]+\s)*[a-zA-Z]+$" maxlength="50" (keyup)="enableBtn1 = false" />

							<div class="invalid-feedback"
								*ngIf=" EditAccountForm.get('lastName')!.invalid &&( EditAccountForm.get('lastName')!.dirty || EditAccountForm.get('lastName')!.touched)">
								<medium *ngIf=" EditAccountForm.get('lastName')?.errors?.required" class="p-error">
									<label class="alert-icon alert-circle-icon"></label>
									First name is required!
								</medium>
								<div *ngIf=" EditAccountForm.get('lastName').errors.pattern">
									<label class="alert-icon alert-circle-icon"></label>
									<medium class="p-error">First Name should not have
										Special
										Character, number
									</medium>
								</div>
							</div>
						</div>
					</div>
					<div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
						<div *ngIf="this.accountOwnerSwitchFlag==true" class="p-fluid p-field">
							<label
								[ngClass]="{'field-required':  EditAccountForm.get('emailAddress')?.errors?.required}">
								Email Address
							</label>
							<span class="invalid-feedback-astrik">*</span>
							<span class="p-input-icon-right p-mb-0">
								<i aria-hidden="true" class="d-icons disable-icon"
									style="margin-right: 4px;margin-top: -6px;" *ngIf="this.firstSave"></i>
								<input pInputText type="email" id="emailAddress_id" name="emailAddress_name"
									formControlName="emailAddress" pInputText placeholder="Enter email address"
									class="p-inputtext inputtextHeight"
									(keyup)="this.emailFlag = false;this.emailInvalidFlag = false;enableBtn1=false;colorChange();this.fullNameFlag=true"
									pattern="(?=.{1,64}@)[A-Za-z0-9_-]+(\.[A-Za-z0-9_-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,})$" />
							</span>

							<div class="invalid-feedback"
								*ngIf=" EditAccountForm.get('emailAddress')!.invalid &&( EditAccountForm.get('emailAddress')!.dirty || EditAccountForm.get('emailAddress')!.touched)">
								<label *ngIf=" EditAccountForm.get('emailAddress')?.errors?.required">
									<div class="custom-font">
										<label class="alert-icon alert-circle-icon" for="rolename"></label>
										<medium id="rolename" style="display: inline-block;vertical-align: bottom;"
											class="p-error">
											Email address is required!
										</medium>
									</div>
								</label>
								<label
									*ngIf=" EditAccountForm.get('emailAddress')?.errors?.pattern && this.emailInvalidFlag==false"
									class="p-error">
									<div class="custom-font">
										<label class="alert-icon alert-circle-icon" for="rolename"></label>
										<medium id="rolename" style="display: inline-block;vertical-align: bottom;"
											class="p-error">Email ID
											input is not in Email ID format
										</medium>
									</div>
								</label>
							</div>
							<div class="invalid-feedback">
								<label *ngIf="this.emailFlag" class="p-error">
									<div class="custom-font">
										<label class="alert-icon alert-circle-icon" for="rolename"></label>
										<medium id="email" class="p-error">
											Email address already exists!
										</medium>
									</div>
								</label>
							</div>
							<div class="invalid-feedback">
								<label *ngIf="this.emailInvalidFlag" class="p-error">
									<div class="custom-font">
										<label class="alert-icon alert-circle-icon" for="rolename"></label>
										<medium id="email" class="p-error">
											Email ID input is not in Email ID format
										</medium>
									</div>
								</label>
							</div>
							<div *ngIf="fullNameFlag==false">
								<label class="alert-icon alert-circle-icon"></label>
								<medium class="p-error">User name already exists
								</medium>
							</div>
						</div>

					</div>
					<div *ngIf="this.accountOwnerSwitchFlag==true"
						class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
					</div>




					<div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right p-mb-2">
						<div class="btnwrap p-mr-3" *ngIf="!this.firstSave">
							<button pButton type="button" label="B-2 Primary" class="p-button-brand2"
								(click)="onClose()" label="Close"></button>
						</div>
						<div class="btnwrap">
							<button pButton class="p-button-primary" type="submit" id="savebutton"
								[disabled]="!EditAccountForm.valid || isValidIP || enableBtn1 "
								(click)="onEditAccount( EditAccountForm)" label="Save"></button>
						</div>
					</div>
				</div>



			</div>
		</div>
	</form>
</div>