<div style="background-color: #F6F8FA;" (click)="mouseLeave()">
    <div class="p-d-flex p-jc-end">
        <p-messages key="myKey1" class="custom-alert-box" *ngIf="this.messageFlag"></p-messages>
    </div>
    <div class="p-col-fixed p-mt-1 p-ml-1 p-pr-0 custom-breadcrumb">
        <p-breadcrumb [model]="ccprimebreadcrumbList" [home]="homeiconccprimebreadcrumb">
        </p-breadcrumb>
    </div>

    <div class="p-formgrid p-grid custom-checkbox-color">
        <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
            <div class="p-grid p-mb-1 p-ml-2">
                <span class="p-col p-p-0">
                    <h5 class="custom-head1">My Approvals</h5>
                </span>
            </div>
            <div
                class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left custom-tab-nav layout-content custom-form-layout">
                <p-tabView [(activeIndex)]="ApprovalListingForm.value.tabview" (onChange)="handleChangetabview($event)">
                    <p-tabPanel header="CIAM Action Approval">
                        <form [formGroup]="ApprovalListingForm" autocomplete="off" novalidate>
                            <div class="card custom-card p-mb-6">
                                <div
                                    class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left p-mt-2 p-p-0">
                                    <div class=" p-fluid   p-field    ">
                                        <div class="p-fluid p-formgrid p-grid" style="padding-top:8px ;">
                                            <div class="p-col-12 p-p-0">
                                                <div *ngIf="this.errorFlag" class="errorFlag">
                                                    <label class="c-icons error-icon"></label><br>
                                                    <label class="e-error">No Transactions Found</label><br>
                                                    <br>
                                                </div>
                                                <div class="ui-fluid p-formgrid p-grid" *ngIf="!this.errorFlag">
                                                    <div
                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                                                        <div class=" p-fluid   p-field    ">
                                                            <span class=" p-input-icon-left">
                                                                <input type="text"
                                                                    (keydown.enter)="$event.preventDefault()"
                                                                    id="textField_id" name="textField_name"
                                                                    formControlName="textField" pInputText
                                                                    placeholder="Search"
                                                                    class="p-inputtext inputtextHeight custom-listings custom-inputtextHeight"
                                                                    (input)="filterMethod($event.target.value)" />
                                                                <i aria-hidden="true" class="pi pi-search"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-2 p-xl-2 p-offset-undefined left">
                                                    </div>
                                                    <div
                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6 p-offset-undefined p-right">
                                                    </div>
                                                    <div
                                                        class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                                                        <div class="  p-field    ">
                                                            <div class="mobilefriendlytable" responsiveLayout="stack"
                                                                [ngClass]="{'content-section implementation': true}">
                                                                <p-table #tableid [value]="tableList" name="table"
                                                                    [columns]="tablecols"
                                                                    styleClass="p-datatable-customers custom-p-datatable-customers"
                                                                    [rows]="10" [rowsPerPageOptions]="[10,25,50]"
                                                                    selectionMode="Multiple" [paginator]="true"
                                                                    sortMode="single" [scrollable]="true"
                                                                    [globalFilterFields]="['ciamAction','noOfApprovers','approvalType','numOfPendingTrans']">
                                                                    <ng-template pTemplate="header">
                                                                        <tr>
                                                                            <th pSortableColumn="transactionId"
                                                                                scope="col"
                                                                                style="min-width: 250px;max-width: 250px;width: 250px;"
                                                                                pFrozenColumn>
                                                                                <div
                                                                                    class="p-d-flex p-jc-between p-ai-center">
                                                                                    Transaction ID
                                                                                    <i class="p-sortable-column-icon icon-sort"
                                                                                        ariaLabel="Activate to sort"
                                                                                        ariaLabelDesc="Activate to sort in descending order"
                                                                                        ariaLabelAsc="Activate to sort in ascending order"
                                                                                        field="transactionId"></i>
                                                                                    <p-columnFilter type="text"
                                                                                        field="transactionId"
                                                                                        display="menu"
                                                                                        class="p-ml-auto"></p-columnFilter>
                                                                                </div>
                                                                            </th>
                                                                            <th pSortableColumn="ciamAction" scope="col"
                                                                                style="min-width: 180px;width: 180px;"
                                                                                pFrozenColumn>
                                                                                <div
                                                                                    class="p-d-flex p-jc-between p-ai-center">
                                                                                    CIAM Action
                                                                                    <i class="p-sortable-column-icon icon-sort"
                                                                                        ariaLabel="Activate to sort"
                                                                                        ariaLabelDesc="Activate to sort in descending order"
                                                                                        ariaLabelAsc="Activate to sort in ascending order"
                                                                                        field="ciamAction"></i>
                                                                                    <p-columnFilter type="text"
                                                                                        field="ciamAction"
                                                                                        display="menu"
                                                                                        class="p-ml-auto"></p-columnFilter>
                                                                                </div>
                                                                            </th>
                                                                            <th pSortableColumn="approvalStage"
                                                                                scope="col"
                                                                                style="min-width: 165px;width: 165px;">
                                                                                <div
                                                                                    class="p-d-flex p-jc-between p-ai-center">
                                                                                    Approval Stage
                                                                                    <i class="p-sortable-column-icon icon-sort"
                                                                                        ariaLabel="Activate to sort"
                                                                                        ariaLabelDesc="Activate to sort in descending order"
                                                                                        ariaLabelAsc="Activate to sort in ascending order"
                                                                                        field="approvalStage"></i>
                                                                                    <p-columnFilter type="text"
                                                                                        field="approvalStage"
                                                                                        display="menu"
                                                                                        class="p-ml-auto"></p-columnFilter>
                                                                                </div>
                                                                            </th>
                                                                            <th pSortableColumn="approvalStatus"
                                                                                scope="col"
                                                                                style="min-width: 170px;width: 170px;">
                                                                                <div
                                                                                    class="p-d-flex p-jc-between p-ai-center">
                                                                                    Approval Status
                                                                                    <i class="p-sortable-column-icon icon-sort"
                                                                                        ariaLabel="Activate to sort"
                                                                                        ariaLabelDesc="Activate to sort in descending order"
                                                                                        ariaLabelAsc="Activate to sort in ascending order"
                                                                                        field="approvalStatus"></i>
                                                                                    <p-columnFilter
                                                                                        field="approvalStatus"
                                                                                        display="menu"
                                                                                        class="p-ml-auto"></p-columnFilter>
                                                                                </div>
                                                                            </th>
                                                                            <th pSortableColumn="createdBy" scope="col"
                                                                                style="min-width:220px;width:220px;">
                                                                                <div
                                                                                    class="p-d-flex p-jc-between p-ai-center">
                                                                                    Created By
                                                                                    <i class="p-sortable-column-icon icon-sort"
                                                                                        ariaLabel="Activate to sort"
                                                                                        ariaLabelDesc="Activate to sort in descending order"
                                                                                        ariaLabelAsc="Activate to sort in ascending order"
                                                                                        field="createdBy"></i>
                                                                                    <p-columnFilter type="text"
                                                                                        field="createdBy" display="menu"
                                                                                        class="p-ml-auto"></p-columnFilter>
                                                                                </div>
                                                                            </th>
                                                                            <th pSortableColumn="createdOn" scope="col"
                                                                                style="min-width: 200px;width: 200px;">
                                                                                <div
                                                                                    class="p-d-flex p-jc-between p-ai-center">
                                                                                    Created On
                                                                                    <i class="p-sortable-column-icon icon-sort"
                                                                                        ariaLabel="Activate to sort"
                                                                                        ariaLabelDesc="Activate to sort in descending order"
                                                                                        ariaLabelAsc="Activate to sort in ascending order"
                                                                                        field="createdOn"></i>
                                                                                    <p-columnFilter field="createdOn"
                                                                                        display="menu"
                                                                                        class="p-ml-auto"></p-columnFilter>
                                                                                </div>
                                                                            </th>
                                                                            <th id="actionButton"
                                                                                style="min-width: 140px;width: 140px;">
                                                                                <div
                                                                                    class="p-d-flex p-jc-between p-ai-center">
                                                                                </div>
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-rowData
                                                                        let-index="rowIndex">
                                                                        <tr (mouseenter)="hoverIndex=index;highlight(index)"
                                                                            (mouseleave)="hoverIndex=index;highlight(index)"
                                                                            [pSelectableRow]="rowData"
                                                                            [pSelectableRow]="rowData">
                                                                            <td alignFrozen="left" pFrozenColumn
                                                                                style="z-index: 1;">
                                                                                <span
                                                                                    pTooltip="{{rowData.transactionId}}"
                                                                                    tooltipPosition="bottom"
                                                                                    *ngIf="rowData.transactionId?.length>=25">
                                                                                    {{rowData.transactionId?.substring(0,25)+"..."
                                                                                    }}</span>
                                                                                <span
                                                                                    *ngIf="rowData.transactionId?.length<25">{{rowData.transactionId}}</span>
                                                                                <span
                                                                                    *ngIf="rowData.transactionId==null">-</span>
                                                                            </td>
                                                                            <td class="emailRow" alignFrozen="left"
                                                                                pFrozenColumn style="z-index: 1;">
                                                                                <span>
                                                                                    {{rowData.ciamAction}}
                                                                                </span>
                                                                                <span
                                                                                    *ngIf="rowData.ciamAction==null">-</span>
                                                                            </td>
                                                                            <td class="emailRow">
                                                                                {{rowData.approvalStage}}
                                                                            </td>
                                                                            <td class="button-status">
                                                                                <div class="btnwrap">
                                                                                    <button pButton type="button"
                                                                                        label="{{rowData.approvalStatus }}"
                                                                                        style="z-index: 0;"
                                                                                        [style.color]=" rowData.approvalStatus === 'Rejected' ? '#D6002A' :  rowData.approvalStatus === 'Approved' ? '#12813F' : rowData.approvalStatus === 'Pending'  ? '#CA8208' : 'black'"
                                                                                        [style.border]="rowData.approvalStatus === 'Rejected' ? '1px solid #F7CCD4' : rowData.approvalStatus === 'Approved' ? '1px solid #CFE6D8' : rowData.approvalStatus === 'Pending' ? '1px solid #FDECCE' : '1px solid black'"
                                                                                        class=" p-button-outlined  statusRow">
                                                                                    </button>
                                                                                </div>
                                                                            </td>
                                                                            <td class="middleNameRow">
                                                                                <span pTooltip="{{rowData.createdBy}}"
                                                                                    tooltipPosition="bottom"
                                                                                    *ngIf="rowData.createdBy?.length>=20">
                                                                                    {{rowData.createdBy?.substring(0,20)+"..."
                                                                                    }}</span>
                                                                                <span
                                                                                    *ngIf="rowData.createdBy?.length<20">{{rowData.createdBy}}</span>
                                                                                <span
                                                                                    *ngIf="rowData.createdBy==null ">-</span>
                                                                            </td>
                                                                            <td>
                                                                                <span>{{rowData.createdOn
                                                                                    | dateFormatter | date: 'MMM
                                                                                    d, y, h:mm:ss a'}}</span>
                                                                            </td>
                                                                            <td pFrozenColumn [frozen]="true"
                                                                                alignFrozen="right"
                                                                                [ngClass]="{'frozen' : true}"
                                                                                style="background: transparent;padding-right:0px">
                                                                                <div class="desktop p-pl-2"
                                                                                    style="background: #F5F9FF;">
                                                                                    <div class="button-details">
                                                                                        <button pButton
                                                                                            tooltipPosition="top"
                                                                                            pTooltip="User Imported File"
                                                                                            class="p-button-strict p-button-info p-button-brand2"
                                                                                            (click)="activeItem=rowData;downloadReport(rowData);"
                                                                                            *ngIf="rowData.ciamAction=='Bulk Action-Import User'">
                                                                                            <i
                                                                                                class="icon-download"></i>
                                                                                        </button>
                                                                                        <button pButton
                                                                                            tooltipPosition="bottom"
                                                                                            pTooltip="View transaction details"
                                                                                            style="width: 24px;height: 24px;padding: 0px;"
                                                                                            icon="d-icons view-icon"
                                                                                            class="background-transparent"
                                                                                            (click)="activeItem=rowData;viewTransactions();">
                                                                                        </button>
                                                                                        <button pButton
                                                                                            tooltipPosition="bottom"
                                                                                            pTooltip="Approve"
                                                                                            style="width: 24px;height: 24px;padding: 0px;"
                                                                                            icon="d-icons approve-icon"
                                                                                            class="background-transparent"
                                                                                            (click)="activeItem=rowData;approveTransaction();"
                                                                                            [disabled]="this.pagesService.aprrovalReadOnlyFlag">
                                                                                        </button>
                                                                                        <button pButton
                                                                                            tooltipPosition="bottom"
                                                                                            pTooltip="Reject"
                                                                                            style="width: 24px;height: 24px;padding: 0px;"
                                                                                            icon="d-icons reject-icon"
                                                                                            class="background-transparent"
                                                                                            (click)="activeItem=rowData;rejectTransaction();"
                                                                                            [disabled]="this.pagesService.aprrovalReadOnlyFlag">
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="paginatorright" let-state>
                                                                        <span class="p-pl-4">Total
                                                                            Records: {{state.totalRecords}}</span>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="emptymessage">
                                                                        <tr>
                                                                            <td colspan="7" class="p-p-0 p-text-center">
                                                                                <p-card>
                                                                                    <div
                                                                                        style="margin-top: 5%;height: 70px;">
                                                                                        No
                                                                                        matching records found</div>
                                                                                </p-card>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </p-tabPanel>
                    <p-tabPanel header="Entitlement Approvals" *ngIf="this.rebacFlag=='true'">

                        <div class="p-mt-2">
                            <span class="p-col-3 ent-search p-input-icon-left p-pl-0">
                                <i class="icon-search approval-search"></i>
                                <input type="text" pInputText class="search-text p-mb-1" id="ent_search1"
                                    placeholder="Search by User Name, Component Name" (keyup)="searchApprovalData();"
                                    [(ngModel)]="this.search1" style="width: 122%;" />

                            </span>

                        </div>
                        <div class="col-md-12  paginate-user container-css">
                            <div class="p-grid p-mb-5">
                                <div class="p-col-12 p-p-0 p-mb-5">
                                    <p-table #dt [value]="approvals" dataKey="userId" rowExpandMode="single"
                                        styleClass="p-datatable-gridlines" responsiveLayout="scroll" [scrollable]="true"
                                        [lazy]="true"
                                        currentPageReportTemplate="{first}-{last} of {totalRecords} Records"
                                        [rowHover]="true" [rows]="10" [showCurrentPageReport]="true"
                                        [rowsPerPageOptions]="rowPerPageOptions1" [totalRecords]="totalRecords1"
                                        (onPage)="loadNextPaginatorGrid($event)" class="approvalTable custom-table ciam"
                                        [paginator]="true">
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th class="" aria-hidden="true" id="userDetails">User Details</th>
                                                <th class="" aria-hidden="true" id="compName">Component Name</th>
                                                <th aria-hidden="true" id="actionHeader"></th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-approval let-expanded="expanded">
                                            <tr>
                                                <td style="width:37%">
                                                    <span style="vertical-align: text-bottom;">
                                                        <button pButton type="button" aria-label="false"
                                                            [pRowToggler]="approval"
                                                            [icon]="expanded ? 'pi pi-minus' : 'pi pi-plus'"
                                                            class="p-button-secondary p-button-brand2 p-ml-0 ent-appr-btn"
                                                            (click)="expanded ? '' : getEntApproval(approval)"></button>
                                                    </span>

                                                    <span class="p-pl-1">
                                                        <span tooltipStyleClass="customTooltipEnt"
                                                            pTooltip="{{approval?.userName}}" tooltipPosition="bottom"
                                                            *ngIf="approval?.userName?.length>=40">
                                                            {{approval?.userName?.substring(0,40)+"..."
                                                            }}</span>
                                                        <span
                                                            *ngIf="approval?.userName?.length<40">{{approval?.userName}}</span>
                                                        <!-- {{ approval?.userName }} -->
                                                    </span>
                                                </td>
                                                <td>
                                                    <span class="p-pl-1">
                                                        {{ approval?.componentCount }}
                                                    </span>
                                                </td>
                                                <td style="width:28%">
                                                    <span class="p-text-right Approval-width"
                                                        style="display: inline-flex;">
                                                        <span class="p-mr-2" *ngIf="approval?.isPending == 1">
                                                            <button pButton type="button"
                                                                class="p-button-xs approveAll-btn"
                                                                style="width:100px;border-radius: 4px;font-size: 10px;height: 22px;padding: 5px 8px; "
                                                                label="Approve All" (click)="approveAll(approval)"
                                                                icon="ownerBtn-icons acceptOwner-icon">

                                                            </button>

                                                        </span>

                                                        <span *ngIf="approval?.isPending == 1">
                                                            <button pButton type="button" label="Reject All"
                                                                class="p-button-xs rejectAll-btn"
                                                                style="width:90px;border-radius: 4px;font-size: 10px;height: 22px;padding: 5px 8px; "
                                                                (click)="rejectAll(approval)"
                                                                icon="ownerBtn-icons rejectOwner-icon">

                                                            </button>

                                                        </span>
                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="rowexpansion" let-approval>
                                            <tr>
                                                <td colspan="3" class="approval-background p-p-0">
                                                    <div class="ent-approval">
                                                        <p-table [value]="approval.ENTITLEMENT_LIST"
                                                            class="Entt-secondary-table custom-table ciam"
                                                            dataKey="userId">
                                                            <ng-template pTemplate="header">
                                            <tr class="thead-bg-color">
                                                <th id="secondaryTag" class="ent_approve_secondary tag"></th>
                                                <th id="entitlementId" class="ent_approve_secondary"
                                                    style="word-break: break-word;">
                                                    Entitlement Id</th>
                                                <th id="componentName" class="ent_approve_secondary"
                                                    style="word-break: break-word;">
                                                    Component Name</th>
                                                <th id="entitlementName" class="ent_approve_secondary"
                                                    style="word-break: break-word;">
                                                    Entitlement Name</th>
                                                <th id="sourceData" class="ent_approve_secondary"
                                                    style="word-break: break-word;">Source
                                                </th>
                                                <th id="approvalLevels" class="ent_approve_secondary"
                                                    style="word-break: break-word;">
                                                    Approval Levels</th>
                                                <th id="statusData" class="ent_approve_secondary"
                                                    style="word-break: break-word;">Status
                                                </th>
                                                <th id="updatedOn" class="ent_approve_secondary"
                                                    style="word-break: break-word;">
                                                    Updated On</th>
                                                <th id="action" class="ent_approve_secondary"
                                                    style="word-break: break-word;">
                                                    Actions</th>
                                                <th id="approvalTree" class="ent_approve_secondary"
                                                    style="word-break: break-word;">
                                                    Approval Tree</th>
                                                <th id="actionButtons">
                                                    <button pButton type="button" icon="icon-filter"
                                                        class="filter-icon-size p-button-strict p-button-info p-button-brand2 ent-secondary-filter"
                                                        (click)="entApprFilter.toggle($event,entApprovalDetails); openFilterSideBar()"></button>

                                                </th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-approval>
                                            <tr *ngIf="approval && approval.levelStatus"
                                                [ngClass]="{'bg-grey': approval.entitlementStatusName==InactiveStatus}">
                                                <td class="tag" style="width:6% !important;">
                                                    <p-tag *ngIf="approval.isDeleted=='Y'" styleClass="p-regular"
                                                        severity="danger" value="No" [rounded]="true">
                                                    </p-tag>
                                                    <span class="info-ent"
                                                        *ngIf="(approval.entitlementStatusName==InactiveStatus)"
                                                        pTooltip="Entitlement was deactivated by {{approval.updatedby}} on {{approval.updatedOn}}"
                                                        tooltipPosition="top">
                                                        <label class="codi-icons codiconInfo-icon"></label>
                                                    </span>
                                                </td>
                                                <td style="width:10% !important"><span><a routerLink=""
                                                            (click)="navigateToDetailScreen1(approval)">{{approval.componentId
                                                            + '-' + approval.entitlementId}}
                                                        </a></span></td>
                                                <td style="width:9% !important;word-break: break-word;">{{
                                                    approval.componentName }}
                                                </td>
                                                <td>
                                                    <span pTooltip="{{ approval.entitlementName }}"
                                                        tooltipPosition="top" class="clipTxt">
                                                        {{ approval.entitlementName }}
                                                    </span>
                                                </td>
                                                <td style="width:6% !important">
                                                    <div>
                                                        <button pButton
                                                            class="action-img p-button-strict p-button-info p-button-brand2"
                                                            [ngClass]="{'anchor-disabled' : approval.IS_USER_DEFINED==0}"
                                                            pTooltip="Raised independently" tooltipPosition="top"
                                                            icon="icon-raisedIndependently">

                                                        </button>&nbsp;
                                                        <button pButton
                                                            class="action-img p-button-strict p-button-info p-button-brand2"
                                                            [ngClass]="{'anchor-disabled' :!approval.SOURCE}"
                                                            [pTooltip]=approval.SOURCE tooltipPosition="top"
                                                            icon="icon-multipleRoles">

                                                        </button>
                                                    </div>
                                                </td>
                                                <td style="width:11% !important;">
                                                    <ng-container *ngFor="let level of approval.LEVEL" class="">
                                                        <span [pTooltip]="level.TOOLTIP" tooltipPosition="bottom"
                                                            class="approval-level p-mr-1">
                                                            {{ level.lable }}

                                                        </span>
                                                    </ng-container>
                                                </td>
                                                <td style="width:11% !important">
                                                    <span
                                                        *ngIf="approval.status=='Allow' && isApprovedFilter && appliedEntitlementStatusFilter?.length>0">Approved</span>
                                                    <span *ngIf="approval.status!='Allow'">Not Approved</span>
                                                </td>
                                                <td class="approval-update-width">
                                                    {{ approval.updatedOn | date:
                                                    'MMM d, y, h:mm:ss
                                                    a' }}
                                                </td>
                                                <td style="display: flex;align-items: center;" class="p-mt-1">
                                                    <span class="p-p-0">
                                                        <button pButton (click)="approve(approval)"
                                                            class="action-img p-p-0 p-m-0 p-button-brand2"
                                                            [pTooltip]="checkLevelStatus(approval.levelStatus) ? 'Approver entitlement is deactivated, hence you will not be able to take action on this request. However, other approver entitlement will be able to take action on it.' : 'Approve'"
                                                            tooltipPosition="top" icon="icon-check"
                                                            [disabled]="checkLevelStatus(approval.levelStatus)"></button></span>
                                                    <span class="p-p-0">
                                                        <button pButton (click)="reject(approval)"
                                                            class="action-img p-p-0 p-m-0 p-button-brand2"
                                                            [pTooltip]="checkLevelStatus(approval.levelStatus) ? 'Approver entitlement is deactivated, hence you will not be able to take action on this request. However, other approver entitlement will be able to take action on it.' : 'Reject'"
                                                            tooltipPosition="top" icon="icon-cancel"
                                                            [disabled]="checkLevelStatus(approval.levelStatus)"></button>
                                                    </span>
                                                    <span class="p-p-0 p-m-0">
                                                        <button pButton (click)="revoke(approval)"
                                                            *ngIf="approval.status == 'Allow' && isApprovedFilter && appliedEntitlementStatusFilter?.length > 0"
                                                            style="width:20px;height: 20px; "
                                                            class="revoke-img p-p-0 p-m-0 " pTooltip="Revoke"
                                                            tooltipPosition="top" icon="entBtn-icons revokeApp-icon">
                                                        </button>
                                                    </span>
                                                </td>
                                                <td>
                                                    <div>
                                                        <span *ngFor="let record of approval.DATA">
                                                            <span class="icon-circle p-mr-1"
                                                                [ngStyle]="{ 'background-color': record?.COLOR }"
                                                                [pTooltip]="record.TOOLTIP"></span>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td style="width:5% !important"></td>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="emptymessage">
                                            <tr>
                                                <td colspan="11"
                                                    *ngIf="appliedEntitlementStatusFilter?.length > 0 || appliedComponentFilter1?.length > 0"
                                                    style="text-decoration-line: none;">
                                                    No records found for applied filter.
                                                </td>
                                                <td colspan="11"
                                                    *ngIf="appliedEntitlementStatusFilter?.length == 0 && appliedComponentFilter1?.length == 0"
                                                    style="text-decoration-line: none;">
                                                    No pending records.
                                                </td>
                                            </tr>
                                        </ng-template>

                                    </p-table>
                                </div>
                                </td>
                                </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="3" class="p-p-0 p-text-center">
                                            <p-card>
                                                <div style="margin-top: 5%;height: 70px;">
                                                    No records found</div>
                                            </p-card>
                                        </td>
                                    </tr>
                                </ng-template>
                                </p-table>
                            </div>
                        </div>


                        <div class="Approval-dialog">
                            <p-dialog header="Approve All" [modal]="true" styleClass="sm" [(visible)]="approveAllFlag"
                                [baseZIndex]="10000">
                                <div class="mt-4 delete-org-content">
                                    <p>{{ confirmationMsg }}</p>
                                    <p><span class="approve-content">for <b>{{ currentUserName }} ?</b></span>
                                    </p>
                                </div>
                                <p-footer>
                                    <button pButton type="button" label="Cancel" class="p-button-secondary"
                                        (click)="approveAllFlag = false"></button>
                                    <button pButton type="button" label="Approve All" (click)="onApproveAll()"></button>
                                </p-footer>
                            </p-dialog>
                        </div>



                        <div class="dialog-confirm">
                            <p-dialog header="Reject Entitlements" [modal]="true" [(visible)]="rejectAllFlag"
                                styleClass="sm" [baseZIndex]="10000">
                                <p>{{ confirmationMsg }}</p>
                                <p>for <span class="del-atr-b"><b>{{ currentUserName }}</b></span>?</p>
                                <p-footer>
                                    <button pButton type="button" label="Cancel" (click)="rejectAllFlag = false"
                                        class="p-button-secondary"></button>
                                    <button pButton type="button" label="Reject All" class="p-button-primary p-mr-0"
                                        (click)="onRejectAll()"></button>
                                </p-footer>
                            </p-dialog>
                        </div>

                        <p-overlayPanel #entApprFilter styleClass="overlayactionpart " (onHide)="sidebarCloseEvent()">
                            <ng-template pTemplate>
                                <div class="p-col-12 p-md-12 filtercard  p-p-0 ">
                                    <div class="addOrgAttrs">

                                        <div class="p-grid">
                                            <div class="p-col-auto filter-size">
                                                <i class="pi pi-filter"></i>
                                            </div>
                                            <div class="p-col p-mt-1 p-ml-2 p-p-0">
                                                <h6>Filters</h6>
                                            </div>
                                        </div>
                                        <div class="p-grid p-fluid" *ngIf="filterMessage">
                                            <div class="p-col-12">
                                                <div class="alert alert-danger alert-dismissible fade show"
                                                    role="alert">
                                                    <i class="fa fa-info-circle"></i> {{ filterMessage }}
                                                    <button type="button" class="close" data-dismiss="alert"
                                                        aria-label="Close" (click)="filterMessage = ''">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="p-grid p-mt-2 ent-appr-overlay">
                                            <div class="p-col-12 p-p-0 accd">
                                                <p-accordion [multiple]="true" styleClass="accord_texts"
                                                    expandIcon="pi pi-plus" collapseIcon="pi pi-minus">
                                                    <p-accordionTab header="Available Components">
                                                        <p-tree [value]="componentData" selectionMode="checkbox"
                                                            [(selection)]="selectedComponent"
                                                            [style]="{ width: '100%' }" class="tree_btn" filter="true">
                                                        </p-tree>
                                                    </p-accordionTab>

                                                    <p-accordionTab header="Approval Tree">
                                                        <p-tree [value]="status" selectionMode="checkbox"
                                                            [(selection)]="selectedEntitlementStatus"
                                                            [style]="{ width: '100%' }" class="tree_btn"
                                                            (onNodeSelect)="nodeSelect($event)"
                                                            (onNodeUnselect)="nodeUnselect($event)"></p-tree>
                                                    </p-accordionTab>
                                                    <p-accordionTab header="Grant">
                                                        <p-tree [value]="grantTree" selectionMode="checkbox"
                                                            [(selection)]="selectedGrant" [style]="{'width':'100%'}"
                                                            class="tree_btn" (onNodeSelect)="grantNodeSelect()"
                                                            (onNodeUnselect)="grantNodeUnselect()">
                                                        </p-tree>
                                                    </p-accordionTab>
                                                </p-accordion>
                                            </div>
                                        </div>

                                        <div class="footer-over p-text-left p-mt-3">


                                            <button type="button" pButton
                                                (click)="applyFilter1(); discardApprovalFilter($event, entApprFilter)"
                                                label="Apply" class="p-button-sm p-button-secondary"></button>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </p-overlayPanel>
            </div>

            </p-tabPanel>

            <p-tabPanel header="Ownership Approvals" *ngIf="this.rebacFlag=='true'">
                <div class="p-fluid p-formgrid p-grid p-mb-2">
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left p-p-0">
                        <div class=" p-fluid   p-field    ">

                            <div>
                                <span class="p-input-icon-left p-0">
                                    <i class="icon-search"></i>
                                    <input type="text" pInputText id="ent_search"
                                        placeholder="Search by Entitlement ID, Name, Component Name"
                                        (keyup)="loadApprovalData()" [(ngModel)]="search" class="search-text" />

                                </span>
                            </div>
                        </div>
                    </div>


                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-8 p-xl-8 p-offset-undefined p-right p-p-0">
                        <div class=" p-fluid   p-field    ">
                            <button pButton type="button" icon="icon-filter" class="p-button-brand2"
                                (click)="ownerFilter.toggle($event)"></button>
                        </div>
                    </div>


                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 left p-p-0">
                        <div class="p-grid ownership-approval">

                            <div class="p-grid">
                                <div class="p-col p-pl-0 p-pb-2">
                                    <div *ngIf="appliedComponentFilter.length > 0 || appliedEntitlementFilter.length > 0"
                                        class="p-ai-center">
                                        <label>Filters Applied: </label>

                                        <p-chip *ngFor="let comp of appliedComponentFilter"
                                            (click)="setActiveTab('component'); ownerFilter.toggle($event)"
                                            class="p-mr-2" styleClass="p-chip-lg p-chip-blue medium-text"
                                            label="{{ comp.label }}">
                                        </p-chip>

                                        <p-chip *ngFor="let ent of appliedEntitlementFilter"
                                            (click)="setActiveTab('entitlement'); ownerFilter.toggle($event)"
                                            class="p-mr-2" styleClass="p-chip-lg p-chip-blue medium-text"
                                            label="{{ ent.label }}">
                                        </p-chip>

                                        <span
                                            *ngIf="appliedComponentFilter.length > 0 || appliedEntitlementFilter.length > 0"><label
                                                class="p-mb-0" (click)="clearAllFilters()"
                                                style="color:#3168dc;"><a>Clear
                                                    all</a></label></span>
                                    </div>
                                </div>
                            </div>
                            <div class=" p-mb-3 ownership-approval">
                                <div class="p-col-12 user-header-align p-pl-0 p-pr-0">
                                    <p-table #dt [columns]="columns" [value]="approvalList"
                                        styleClass="p-datatable-gridlines approval-table-width" [responsive]="true"
                                        [lazy]="true" class="ownwer-table" [rowHover]="true">

                                        <ng-template pTemplate="header" let-columns>
                                            <tr>
                                                <th id="ownerApprovalHeader" aria-hidden="true"
                                                    *ngFor="let col of columns" [style.width]="col.width">
                                                    {{ col.header }}
                                                </th>

                                                <th id="updatedOn" aria-hidden="true">Updated On</th>

                                            </tr>
                                        </ng-template>

                                        <ng-template pTemplate="body" let-record>
                                            <tr>
                                                <td style="width: 11%;"> <span><a routerLink=""
                                                            (click)="navigateToDetailScreen(record)">{{record.COMPONENT_ID
                                                            + '-'
                                                            + record.ENTITLEMENT_ID}}
                                                        </a></span>
                                                </td>
                                                <td style="width: 16%;">{{ record.COMPONENT_NAME }}</td>
                                                <td class="ent_name">
                                                    <span appClipText pTooltip="{{ record.ENTITLEMENT_NAME }}"
                                                        tooltipPosition="top" [clipLength]="12">{{
                                                        record.ENTITLEMENT_NAME }}</span>
                                                    <span *ngIf="record.IS_ADMS_ENTITLEMENT == 1"><b><i>
                                                                ADMS</i></b>
                                                    </span>

                                                </td>
                                                <td class="ownership-width">
                                                    <span style="min-width: 131px;" appClipText
                                                        pTooltip="{{ record.OWNERSHIP_REQUEST_FROM}}"
                                                        tooltipPosition="top" [clipLength]="12">{{
                                                        record.OWNERSHIP_REQUEST_FROM }}</span>
                                                    <span>

                                                        <label class="da-icons p-mr-5 doubleArrow-icon"
                                                            style="vertical-align: bottom;"></label>
                                                        <span appClipText pTooltip="{{ record.OWNERSHIP_REQUEST_TO}}"
                                                            tooltipPosition="top" [clipLength]="12">{{
                                                            record.OWNERSHIP_REQUEST_TO }} </span>
                                                        <span *ngIf="record.ADMS_ENT == 1"><b><i>
                                                                    ADMS</i></b>
                                                        </span>
                                                    </span>

                                                </td>


                                                <td class="ownership-update">
                                                    <span class="custom-date"> {{ record.updatedOn | date:
                                                        'MMM d, y, h:mm:ss
                                                        a'
                                                        }}</span>
                                                    <div class="button-details">
                                                        <div class="action-button">
                                                            <span class="action-buttons-table">
                                                                <div class="btn-group">

                                                                    <button pButton type="button"
                                                                        class="p-button-xs approveAll-btn p-ml-0"
                                                                        label="Accept"
                                                                        style="width:81px;border-radius: 4px;font-size: 10px;height: 20px;padding: 5px 8px; "
                                                                        (click)="updateStatus(record, 'Approved')"
                                                                        icon="ownerBtn-icons acceptOwner-icon"
                                                                        *ngIf="record.USER_TYPE.includes('RECEPIENT')">


                                                                    </button>

                                                                    <button pButton type="button" class="p-button-xs"
                                                                        label="Reject"
                                                                        (click)="updateStatus(record, 'Rejected')"
                                                                        style="width: 77px;border-radius: 4px;font-size: 10px; height: 20px;padding: 5px 8px;"
                                                                        icon="ownerBtn-icons rejectOwner-icon"
                                                                        *ngIf="record.USER_TYPE.includes('RECEPIENT')">

                                                                    </button>

                                                                    <button pButton type="button"
                                                                        class="p-button-xs p-button-secondary"
                                                                        label="Cancel Request"
                                                                        (click)="updateStatus(record, 'Cancelled')"
                                                                        style="width: 115px;border-radius: 4px;font-size: 10px;height: 20px;padding: 5px 8px;"
                                                                        icon="ownerBtn-icons cancelOwner-icon"
                                                                        *ngIf="record.USER_TYPE.includes('SENDER')">


                                                                    </button>

                                                                </div>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="emptymessage">
                                            <tr>
                                                <td colspan="7" class="p-p-0 p-text-center">
                                                    <p-card>
                                                        <div style="margin-top: 5%;height: 70px;">
                                                            No records found</div>
                                                    </p-card>
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Filter Overlay Start-->
                    <p-overlayPanel #ownerFilter styleClass="overlayactionpart owner_userfilters owner-approval"
                        (onHide)="sidebarCloseEvent()">
                        <ng-template pTemplate>
                            <div class="p-col-12 p-md-12 filtercard p-p-0 ">
                                <div class="addOrgAttrs">
                                    <div class="p-col-auto filter-size p-d-flex p-ai-baseline">
                                        <i class="icon-filter p-mr-2"></i>
                                        <h6>Filters</h6>
                                    </div>

                                    <div class="p-grid mb-1" *ngIf="filterMessage">
                                        <div class="P-col-12">
                                            <div class="alert alert-danger alert-dismissible fade show" role="alert">
                                                <i class="fa fa-info-circle"></i> {{ filterMessage }}
                                                <button type="button" class="close" data-dismiss="alert"
                                                    aria-label="Close" (click)="filterMessage = ''">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="p-grid mb-1 owenership-panel">
                                        <div class="p-col-12 p-p-0 accd">
                                            <p-accordion styleClass="accord_texts" expandIcon="pi pi-plus"
                                                collapseIcon="pi pi-minus">
                                                <p-accordionTab header="Component Names"
                                                    [(selected)]="activeTab.isComponentTab">
                                                    <p-tree [value]="componentFilterData" selectionMode="checkbox"
                                                        [(selection)]="selectedComponentFilter"
                                                        [style]="{ width: '100%' }" class="tree_btn" filter="true">
                                                    </p-tree>
                                                </p-accordionTab>

                                                <p-accordionTab header="Entitlement Names"
                                                    [(selected)]="activeTab.isEntitlementTab">
                                                    <p-tree [value]="entitlementFilterData" selectionMode="checkbox"
                                                        [(selection)]="selectedEntitlementFilter"
                                                        [style]="{ width: '100%' }" class="tree_btn"
                                                        filter="true"></p-tree>
                                                </p-accordionTab>
                                            </p-accordion>
                                        </div>
                                    </div>

                                    <div class="footer-over p-text-left p-mt-3">
                                        <button type="button" pButton
                                            (click)="applyFilter(); discardOwnerFilter($event, ownerFilter)"
                                            label="Apply" class="p-button-secondary p-button-sm"></button>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </p-overlayPanel>
                </div>
            </p-tabPanel>
            </p-tabView>

        </div>
    </div>
</div>
</div>