import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PagesService } from '../pages.service';
import { validateAllFormFields } from 'app/shared/util/reactiveform-util';
import { MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { IDENTITY_PROVIDER, CONFIG_LAVEL_GLOBAL } from '../../constants'
import { REBAC_UM_ENTITLEMENT_FLAG } from 'app/app.constants';
import { UserDetailsService } from '../EditUser/EditUser.service';
import { ContextName, EventName } from 'app/shared/constants/logging-event.constants';
import { EMAIL_REGEXP } from '../../constants';
@Component({
  selector: 'jhi-adduser',
  templateUrl: './AddUser.component.html',
  styleUrls: ['./AddUser.component.scss'],
  providers: [MessageService, DatePipe],
})
export class AddUserComponent implements OnInit {
  AddUserForm = this.fb.group({
    firstName: [null, [Validators.required]],
    middleName: ['', []],
    lastName: [null, [Validators.required]],
    emailAddress: [null, [Validators.required, Validators.email,Validators.pattern(EMAIL_REGEXP.source)]],
    phoneNumbers: [null, []],
    userName: [null, []],
    roleList: [null, []],
    groupList: [null, []],
    countryCode: [null, []],
    selectSwitch: [null, []],
    createInIdentityProvider: [null, []],
    createInExternalLdap: [null, []],
    application: [null, []],
    account: [null, []]
  });

  createInIdentityProvider: boolean = false;
  isIdentityProviderEnabled: boolean;
  messageFlag = true;
  activeIndex1: number = 0;
  clicked1 = false;
  clicked = true;
  tab1 = false;
  tab2 = false;
  tab3 = false;
  activeIndex2: number = 0;
  pageloadvalues: any;
  ccprimebreadcrumbList1 = [{ label: `User Management` }, { label: `Users`, routerLink: ['/pages/userlisting'] }, { label: `Edit User` }];
  ccprimebreadcrumbList = [{ label: `User Management` }, { label: `Users`, routerLink: ['/pages/userlisting'] }, { label: `Add New User` }];
  homeiconccprimebreadcrumb = { icon: 'pi pi-home', routerLink: ['/'] };
  roleListList: any[] = [];
  groupListList: any[] = [];
  exRoleList: any[] = [];
  newRoleList: any[] = [];
  exGroupList: any[] = [];
  newGroupList: any[] = [];
  selectSwitchList: any[] = [{ 'label': "Internal", 'value': 'false' }, { 'label': "External", 'value': 'true' },];
  firstSave = false;
  savedId: any;
  emailFlag: boolean = false;
  assignGroupCount = 0;
  deAssignGroupCount = 0;
  assignRoleCount = 0;
  deAssignRoleCount = 0;
  enableBtn1 = true;
  enableBtn2 = true;
  enableBtn3 = true;
  msg: string[] = [];
  msg1: string[] = [];
  emailInvalidFlag: boolean = false;
  codes: any[] = [];
  phoneID: any;
  clickedFlag = false;
  focusDirectClicked = false;
  codeFlag = false;
  phoneNumberFlag = false;
  customJSON: any;
  customList: any = [];
  fieldTypeList: any = [];
  cnt = 0;
  checked = false;
  value4: string;
  moduleID = "";
  checkboxArr: any = [];
  customFieldDto = [];
  date3: any;
  customFieldValues = [];
  someArr: any = [];
  oldFirstName: any
  oldMiddleName: any;
  oldLastName: any;
  oldPhoneId: any;
  oldCountryCode: any;
  fullNameFlag = false;
  oldPhoneNumber: any;
  wfFlag = false;
  defaultselect_selectSwitch: any;
  userNameCheckError: boolean = false;
  createInExternalLdap: boolean = false;
  rebacFlag: any;
  userNameExternalLdap: boolean = false;
  emptyFlag = 0;
  oldUserName: any;
  applicationList: any[] = [];
  emailSendButtonLabel: string = "Send E-mail";
  emailSendButtonTooltip: string = "";
  mappedApplicationList: any[] = [];
  autocompleteList: any[] = [];
  noUserFound: boolean = false;
  userFound: boolean = false;
  accountList: any[] = [];
  emailRegex:any;
  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    private messageService: MessageService,
    private userDetailsService: UserDetailsService,
    public datepipe: DatePipe
  ) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.AddUserForm.patchValue(JSON.parse(data));
    }

    this.setDefaultValue_selectSwitch('false');


  }
  toggle = true;
  status = 'Enable';
  enableDisableRule() {
    this.toggle = !this.toggle;
    this.status = this.toggle ? 'Enable' : 'Disable';
  }

  ngOnInit(): void {
    this.rebacFlag = REBAC_UM_ENTITLEMENT_FLAG + "";
    this.emailRegex=EMAIL_REGEXP.source;
    console.log('Email Regex===',this.emailRegex);
    if (this.rebacFlag == "true") {
      // this.createInExternalLdap = true;
      // this.AddUserForm.controls['createInExternalLdap'].setValue(true);
      this.defaultselect_selectSwitch = this.selectSwitchList[1]?.value;
      this.AddUserForm.controls['selectSwitch'].setValue(this.defaultselect_selectSwitch);
      this.AddUserForm.controls['account'].setValidators(Validators.required);
      this.onAccountsdropdown();
    }
    this.getApplicationList();
    this.getEntityList();
    this.getroleList();
    this.getgroupList();
    this.getCountryCode();
    this.getConfigurations();
    this.setUserStorage();
  }

  setDefaultValue_selectSwitchRebac() {
    if (this.defaultselect_selectSwitch) {
      this.defaultselect_selectSwitch = this.selectSwitchList[1]?.value;
      return this.defaultselect_selectSwitch;
    } else {
      this.defaultselect_selectSwitch = this.selectSwitchList[0]?.value;
      return this.defaultselect_selectSwitch;
    }
  }
  checkUserName() {
    let regularExpressionUsername: any;
    regularExpressionUsername = /^[A-Za-z0-9._-]*$/;
    const userNameValue = this.AddUserForm.value.userName;
    const validateEnd = regularExpressionUsername.test(String(userNameValue).toLowerCase());
    if (!validateEnd) {
      if (this.AddUserForm.value.emailAddress == this.AddUserForm.value.userName) {
        this.userNameCheckError = false;
        document.getElementById('userName_id')!.style.borderColor = 'grey';
      }
      else {
        this.userNameCheckError = true;
        document.getElementById('userName_id')!.style.borderColor = 'red';
      }
    }
    else {
      this.userNameCheckError = false;
      document.getElementById('userName_id')!.style.borderColor = 'grey';
    }
    if (this.createInExternalLdap) {
      if (userNameValue.length > 20) {
        this.userNameExternalLdap = true;
        document.getElementById('userName_id')!.style.borderColor = 'red';
      }
      else {
        this.userNameExternalLdap = false;
        document.getElementById('userName_id')!.style.borderColor = 'grey';
      }
    }
  }

  onClearUser($event: UntypedFormGroup): void {
    $event.reset();
    document.getElementById('emailAddress_id')!.style.borderColor = 'grey';
  }
  // get entity list
  getEntityList(): void {
    this.pagesService.getEntity().subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          if (data.label == 'Users') {
            this.moduleID = data.value;
            this.fetchCustomField();
          }
        });
      }
    });
  }
  // get country code from API
  getCountryCode() {
    this.pagesService.getCountryCode().subscribe(
      results => {
        const responseData = results?.success?.result;
        if (responseData && responseData?.length > 0) {
          responseData.forEach((element: any = {}) => {
            const data = {
              label: null,
              value: null,
              name: null,
            };
            data.label = element.countryCode;
            data.value = element.countryCode;
            data.name = element.countryName;
            this.codes.push(data);
          });
          this.codes = [...this.codes];
        }
      });
  }

  //First Save email check
  createUser($event: UntypedFormGroup) {
    if (this.firstSave) {
      this.onEditUser($event);
    } else {
      this.onCheckEmail($event);
    }
  }

  colorChange() {
    document.getElementById('emailAddress_id')!.style.borderColor = 'grey';
  }

  //email availability function
  onCheckEmail($event: UntypedFormGroup): void {
    const reqBody = this.AddUserForm.value.emailAddress.toLowerCase();
    const formData = reqBody;
    const formValues = this.AddUserForm.value.emailAddress.toLowerCase();
    this.pagesService.onCheckEmailId(formData, formValues).subscribe(
      {
        next: results => {
          if (results?.error?.messageCode == 'CIAM 105' || results?.success?.messageCode == 'CIAM 105') {
            this.emailFlag = true;
            this.enableBtn1 = true;
            document.getElementById('emailAddress_id')!.style.borderColor = 'red';
          }
          if (results?.error?.messageCode == 'CIAM 201' || results?.success?.messageCode == 'CIAM 201') {
            this.emailInvalidFlag = true;
            this.enableBtn1 = true;
            document.getElementById('emailAddress_id')!.style.borderColor = 'red';
          }
          else if (results.success.code == 200) {
            this.emailFlag = false;
            this.enableBtn1 = false;
            document.getElementById('emailAddress_id')!.style.borderColor = 'grey';
            if (!this.createInExternalLdap) {
              this.onFirstCreateUser();
            }
            else if (this.createInExternalLdap) {
              this.setUsernameExternalLdap();
            }
            else {
              this.onFirstCreateUser();
            }

          }
        },

      });
  }



  CheckNumber(codeFlag: any) {
    this.codeFlag = false;
    this.phoneNumberFlag = false;
    if (codeFlag == "setCode" && (this.AddUserForm.value.phoneNumbers == null || this.AddUserForm.value.phoneNumbers == "") && (this.AddUserForm.value.countryCode == "" || this.AddUserForm.value.countryCode == null)) {
      this.enableBtn1 = false;
    }
    else if (codeFlag == "setNumber" && (this.AddUserForm.value.countryCode == null || this.AddUserForm.value.countryCode == "") && (this.AddUserForm.value.phoneNumbers == null || this.AddUserForm.value.phoneNumbers == "")) {
      this.enableBtn1 = false;
    }
    else if (codeFlag == "setCode" && this.AddUserForm.value.phoneNumbers == null || this.AddUserForm.value.phoneNumbers == "") {
      this.enableBtn1 = true;
      this.phoneNumberFlag = true;
    }

    else if ((codeFlag == "setNumber" && this.AddUserForm.value.countryCode == null || this.AddUserForm.value.countryCode == "") || (this.AddUserForm.value.countryCode == null && (this.AddUserForm.value.phoneNumbers != null || this.AddUserForm.value.phoneNumbers != ""))) {
      this.enableBtn1 = true;
      this.codeFlag = true;
    }
    else if (this.AddUserForm.value.countryCode != null && (this.AddUserForm.value.phoneNumbers != null || this.AddUserForm.value.phoneNumbers != "")) {
      this.enableBtn1 = false;
    }

    if (this.AddUserForm.get('phoneNumbers').errors?.pattern) {
      this.codeFlag = false;
      this.phoneNumberFlag = false;
    }
  }


  setDefaultValue_selectSwitch(value?: any) {
    if (this.selectSwitchList.length && value) {
      this.defaultselect_selectSwitch = value;
      return this.defaultselect_selectSwitch;
    }
    this.defaultselect_selectSwitch = this.selectSwitchList[0]?.value;
    return this.defaultselect_selectSwitch;
  }

  getApplicationList(): void {
    this.pagesService.getApplicationListMapping().subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.applicationList.push(data);
        });
        this.applicationList = [...this.applicationList];
      }
    });
  }
  //Create User Functionality

  onFirstCreateUser(): void {
    this.AddUserForm.controls['lastName'].enable();
    this.AddUserForm.controls['firstName'].enable();
    this.AddUserForm.controls['userName'].enable();
    this.customFieldDto = [];
    this.someArr = [];
    this.customFieldValues = [];
    const reqBody = {};
    reqBody['tenantId'] = this.pagesService.tenantId;
    if (this.AddUserForm.value.phoneNumbers == null || this.AddUserForm.value.phoneNumbers == "" && this.AddUserForm.value.countryCode == null) {
      reqBody['phoneNumbers'] = [];
    }
    else {
      reqBody['phoneNumbers'] = [{ value: this.AddUserForm.value.phoneNumbers, countryCode: this.AddUserForm.value.countryCode, primary: true }];
    }
    reqBody['first_name'] = this.AddUserForm.value.firstName;
    reqBody['middle_name'] = this.AddUserForm.value.middleName;
    reqBody['last_name'] = this.AddUserForm.value.lastName;
    if (this.AddUserForm.value.userName == "" || this.AddUserForm.value.userName == null || this.AddUserForm.value.userName == undefined) {
      reqBody['user_name'] = this.AddUserForm.value.emailAddress.toLowerCase();
    }
    else {
      reqBody['user_name'] = this.AddUserForm.value.userName.toLowerCase();
    }
    const fname = this.AddUserForm.value.firstName + ' ' + this.AddUserForm.value.middleName + ' ' + this.AddUserForm.value.lastName;
    reqBody['full_name'] = fname.replace(/\s+/g, ' ').trim();
    reqBody['emails'] = [{ value: this.AddUserForm.value.emailAddress.toLowerCase(), type: 'work', primary: true }];
    reqBody['isExternal'] = this.AddUserForm.value.selectSwitch;
    reqBody['applicationId'] = this.AddUserForm.value.application;
    reqBody['createInIdentityProvider'] = this.createInIdentityProvider;
    reqBody['createInExternalLdap'] = this.createInExternalLdap;
    reqBody['userIdentityProvider'] = this.pagesService.userStorage;
    if (this.pagesService.rebacFlag == 'true') {
      reqBody['accountId'] = this.AddUserForm.value.account;

    }
    reqBody['customFieldDataDtoList'] = [];
    if (this.customList && this.customList.length > 0) {
      this.customList.forEach((element: any = {}) => {
        const data = {
          customFieldId: null,
          customFieldLabel: null,
          customFieldValue: null,
        };
        data.customFieldId = element.id;
        data.customFieldLabel = element.fieldName;
        if (element.fieldInputtype == 'Dropdown') {
          let cc = this.AddUserForm.value[element.id];
          if (cc == "" || cc == null) {
            // empty
          }
          else {
            data.customFieldValue = cc.label;
          }

        }
        else if (element.inputFormat[0] == 'Date range') {
          data.customFieldValue = JSON.stringify(this.AddUserForm.value[element.id]);
        }
        else if (element.inputFormat[0] == 'Text/Number range') {
          const reqRange = {};
          reqRange['start'] = this.AddUserForm.value[element.id + "_start"];
          reqRange['last'] = this.AddUserForm.value[element.id + "_end"];
          if (reqRange['start'] == null || reqRange['last'] == null) {

            data.customFieldValue = null;
          }
          else {
            data.customFieldValue = JSON.stringify(reqRange);
          }
        }
        else {
          data.customFieldValue = this.AddUserForm.value[element.id];
        }
        if (data.customFieldValue == null || data.customFieldValue == "") {
          this.emptyFlag = 1;
        }
        else {
          this.customFieldDto.push(data);
        }
      });
      this.customFieldDto = [...this.customFieldDto];
    }

    if (this.checkboxArr && this.checkboxArr.length > 0) {
      this.checkboxArr.forEach((element: any = {}) => {
        const data = {
          customFieldId: null,
          customFieldLabel: null,
          customFieldValue: null,
        };
        data.customFieldId = element.id;
        data.customFieldLabel = element.fieldName;
        if (data.customFieldValue == null || !data.customFieldValue) {
          data.customFieldValue = "false";
        }
        else {
          data.customFieldValue = "true";
        }
        this.customFieldDto.push(data);
      });
      this.customFieldDto = [...this.customFieldDto];
    }
    reqBody['customFieldDataDtoList'] = this.customFieldDto;
    if (this.rebacFlag == "true") {
      reqBody['org_name'] = this.accountList.filter(a => a.value.includes(this.AddUserForm.value.account))[0].label;
    }

    const formData = reqBody;
    this.messageFlag = true;
    this.pagesService.onAddUser(formData).subscribe(
      {
        next: results => {
          if (results?.success?.messageCode == "CIAM 154") {
            this.navigateToMyReq('User Creation request has been sent for approval');
          }
          else if (results?.error?.messageCode == "CIAM 113") {
            this.messageService.add({ key: 'myKey1', severity: 'error', detail: results?.error?.reason });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
          }
          else {

            this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'User created successfully.' });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);

            this.oldFirstName = results?.success?.result?.first_name;
            this.oldMiddleName = results?.success?.result?.middle_name;
            this.oldLastName = results?.success?.result?.last_name;
            if (results?.success?.result?.phoneNumbers != null) {
              this.oldPhoneNumber = results?.success?.result?.phoneNumbers[0]?.value
              this.oldPhoneId = results?.success?.result?.phoneNumbers[0]?.id;
              this.oldCountryCode = results?.success?.result?.phoneNumbers[0]?.countryCode;
            }
            this.savedId = results?.success?.result?.id;
            this.phoneID = results?.success?.result?.phoneNumbers[0]?.id;
            this.oldUserName = results?.success?.result?.user_name;
            this.AddUserForm.controls.userName.setValue(this.oldUserName);
            this.messageFlag = true;
            this.clicked1 = false;
            this.AddUserForm.get('account').disable();
            this.clicked1 = true;
            this.firstSave = true;
            this.clicked = false;
            this.tab1 = true;
            this.enableBtn1 = true;
            if (this.pagesService.pageRebacFlag == 'false') {
              this.AddUserForm.controls['emailAddress'].disable();
              this.showTab();
              this.getExRoleList();
              this.getExGroupList();
            }

            this.logEvent(EventName.CREATE_USER, ContextName.VIEW_CREATE_USER_PAGE, formData);
          }


        },
        error: error => {
          if (error.error?.error?.messageCode == "CIAM 105" && error.error?.error?.reason == "User already exists/Username used with another user email") {
            this.messageService.add({ key: 'myKey1', severity: 'error', detail: "User already exists/Username used with another user email" });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
          }
          else if (error.error?.error?.messageCode == "CIAM 105") {
            this.messageService.add({ key: 'myKey1', severity: 'error', detail: "Request already available for approval" });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
          }
          else {
            this.messageFlag = true;
            this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Failed to create user.' });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
          }
        }
      });
  }

  //Edit User Functionality
  onEditUser($event: UntypedFormGroup): void {
    this.customFieldDto = [];
    this.someArr = [];
    this.customFieldValues = [];
    $event.updateValueAndValidity();
    if (!$event.valid) {
      validateAllFormFields($event);
      return;
    }
    const reqBody = {};
    reqBody['tenantId'] = this.pagesService.tenantId;
    reqBody['id'] = this.savedId;
    if ((this.AddUserForm.value.phoneNumbers == null || this.AddUserForm.value.phoneNumbers == "") && this.AddUserForm.value.countryCode == null) {
      reqBody['phoneNumbers'] = [];
    }
    else if (this.oldPhoneNumber != this.AddUserForm.value.phoneNumbers) {
      reqBody['phoneNumbers'] = [{ countryCode: this.AddUserForm.value.countryCode, id: this.AddUserForm.value.phoneID, value: this.AddUserForm.value.phoneNumbers }];
    }
    if ((this.AddUserForm.value.phoneNumbers == null || this.AddUserForm.value.phoneNumbers == "") && this.AddUserForm.value.countryCode == null) {
      reqBody['phoneNumbers'] = [];
    }
    else if (this.oldCountryCode != this.AddUserForm.value.countryCode) {
      reqBody['phoneNumbers'] = [{ countryCode: this.AddUserForm.value.countryCode, id: this.AddUserForm.value.phoneID, value: this.AddUserForm.value.phoneNumbers }];
    }
    if (this.oldFirstName != this.AddUserForm.value.firstName) {
      this.fullNameFlag = true;
      reqBody['first_name'] = this.AddUserForm.value.firstName;
    }
    if (this.oldMiddleName != this.AddUserForm.value.middleName) {
      this.fullNameFlag = true;
      if (this.AddUserForm.value.middleName == null) {
        reqBody['middle_name'] = " ";
        this.AddUserForm.value.middleName = " ";
      }
      else {
        reqBody['middle_name'] = this.AddUserForm.value.middleName;
      }
    }
    if (this.oldLastName != this.AddUserForm.value.lastName) {
      this.fullNameFlag = true;
      reqBody['last_name'] = this.AddUserForm.value.lastName;
    }
    if (this.fullNameFlag) {
      if (this.AddUserForm.value.middleName == null) {
        const fname = this.AddUserForm.value.firstName + ' ' + this.AddUserForm.value.lastName;
        reqBody['full_name'] = fname.replace(/\s+/g, ' ').trim();
      }
      else {
        const fname = this.AddUserForm.value.firstName + ' ' + this.AddUserForm.value.middleName + ' ' + this.AddUserForm.value.lastName;
        reqBody['full_name'] = fname.replace(/\s+/g, ' ').trim();
      }
    }
    reqBody['isExternal'] = this.AddUserForm.value.selectSwitch;
    if (this.oldUserName != this.AddUserForm.value.userName) {
      reqBody['user_name'] = this.AddUserForm.value.userName.toLowerCase();
    }

    reqBody['customFieldDataDtoList'] = [];
    if (this.customList && this.customList.length > 0) {
      this.customList.forEach((element: any = {}) => {
        const data = {
          customFieldId: null,
          customFieldLabel: null,
          customFieldValue: null,
        };
        data.customFieldId = element.id;
        data.customFieldLabel = element.fieldName;
        if (element.fieldInputtype == 'Dropdown') {
          let cc = this.AddUserForm.value[element.id];
          if (cc == "" || cc == null) {
            this.emptyFlag = 2;
          }
          else {
            data.customFieldValue = cc.label;
          }

        }
        else if (element.inputFormat[0] == 'Date range') {
          data.customFieldValue = JSON.stringify(this.AddUserForm.value[element.id]);
        }
        else if (element.inputFormat[0] == 'Text/Number range') {
          const reqRange = {};
          reqRange['start'] = this.AddUserForm.value[element.id + "_start"];
          reqRange['last'] = this.AddUserForm.value[element.id + "_end"];
          if (reqRange['start'] == null || reqRange['last'] == null) {

            data.customFieldValue = null;
          }
          else {
            data.customFieldValue = JSON.stringify(reqRange);
          }
        }
        else {
          data.customFieldValue = this.AddUserForm.value[element.id];
        }
        if (data.customFieldValue == null || data.customFieldValue == "") {
          this.emptyFlag = 3;
        }
        else {
          this.customFieldDto.push(data);
        }
      });
      this.customFieldDto = [...this.customFieldDto];
    }

    if (this.checkboxArr && this.checkboxArr.length > 0) {
      this.checkboxArr.forEach((element: any = {}) => {
        const data = {
          customFieldId: null,
          customFieldLabel: null,
          customFieldValue: null,
        };
        data.customFieldId = element.id;
        data.customFieldLabel = element.fieldName;
        if (data.customFieldValue == null || !data.customFieldValue) {
          data.customFieldValue = "false";
        }
        else {
          data.customFieldValue = "true";
        }
        this.customFieldDto.push(data);
      });
      this.customFieldDto = [...this.customFieldDto];
    }
    reqBody['customFieldDataDtoList'] = this.customFieldDto;
    const formData = reqBody;
    this.messageFlag = true;
    this.pagesService.onEditUser(formData).subscribe(
      {
        next: results => {
          if (results?.success?.messageCode == "CIAM 154") {
            this.navigateToMyReq('Edit User request has been sent for approval');
          }
          else if (results?.error?.messageCode == "CIAM 113") {
            this.messageService.add({ key: 'myKey1', severity: 'error', detail: results?.error?.reason });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
          }
          else {
            this.messageFlag = true;
            this.clicked1 = false;
            this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'User edited successfully.' });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
            this.tab1 = true;
            this.enableBtn1 = true;
            if (this.pagesService.pageRebacFlag == 'false') {
              this.showTab();
            }
          }


        },
        error: error => {
          if (error.error?.error?.messageCode == "CIAM 105") {
            this.messageService.add({ key: 'myKey1', severity: 'error', detail: "Request already available for approval" });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
          }
          else {
            this.messageFlag = true;
            this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Failed to Edit User.' });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
          }
        }
      });
  }

  //getting existing role list of user
  getExRoleList() {
    this.pagesService.getViewUserRoles(this.savedId).subscribe((results: any) => {
      const responseData = results?.success?.result;
      this.exRoleList = [];
      if (responseData && responseData?.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.displayName;
          data.value = element.id;
          this.exRoleList.push(data.value);
        });
        this.exRoleList = [...this.exRoleList];
      }
    });
  }

  getNewRoleList() {
    const responseData = this.AddUserForm.value.roleList;
    this.newRoleList = [];
    if (responseData && responseData.length > 0) {
      responseData.forEach((element: any = {}) => {
        const data = {
          label: null,
          value: null,
        };
        data.label = element.label;
        data.value = element.value;
        this.newRoleList.push(data.value);
      });
      this.newRoleList = [...this.newRoleList];
    }
  }

  assignFor(assignList: any, results: any) {
    for (let i = 0; i < assignList.length; i++) {
      if (results?.success?.result?.AssignRolesToUser[i]?.messageCode == 'CIAM 151') {
        this.assignRoleCount = this.assignRoleCount + 1;
        this.wfFlag = false;
      }
      else if (results?.success?.result?.AssignRolesToUser[i]?.messageCode == 'CIAM 154') {
        this.wfFlag = true;
      }
    }
  }

  //Assigning roles to user
  assignRoles($event: UntypedFormGroup): void {
    $event.updateValueAndValidity();
    this.newRoleList = this.AddUserForm.value.roleList;
    const assignList = this.newRoleList.filter(x => !this.exRoleList.includes(x));
    const deassignList = this.exRoleList.filter(x => !this.newRoleList.includes(x));
    const reqBody = {};
    reqBody['tenantId'] = this.pagesService.tenantId;
    reqBody['userId'] = this.savedId;
    reqBody['assignList'] = assignList;
    reqBody['deAssignList'] = deassignList;
    this.messageFlag = true;
    const formValues = reqBody;
    this.pagesService.onSaveAssignRolesToUser(formValues).subscribe(
      {
        next: results => {
          this.assignFor(assignList, results);

          for (let i = 0; i < deassignList.length; i++) {
            if (results?.success?.result["De-AssignRolesFromUser"][i]?.messageCode == 'CIAM 152') {
              this.deAssignRoleCount = this.deAssignRoleCount + 1;
              this.wfFlag = false;
            }
            else if (results?.success?.result["De-AssignRolesFromUser"][i]?.messageCode == 'CIAM 154') {
              this.wfFlag = true;
            }
          }
          if (this.wfFlag) {
            this.navigateToMyReq('Assign/Deassign Role to User request has been sent for approval');
          }
          else {
            if (this.deAssignRoleCount == 0 && this.assignRoleCount > 0) {
              this.msg.push(this.assignRoleCount + ' Role Assigned successfully');
            } else if (this.deAssignRoleCount > 0 && this.assignRoleCount > 0) {
              this.msg.push(this.assignRoleCount + ' Role Assigned successfully');

              this.msg.push(this.deAssignRoleCount + ' Role De-assigned successfully');
            } else if (this.deAssignRoleCount > 0 && this.assignRoleCount == 0) {
              this.msg.push(this.deAssignRoleCount + ' Role De-assigned successfully')
            }
            this.messageFlag = true;
            this.clicked1 = false;
            this.messageService.add({ key: 'myKey1', severity: 'success', detail: this.msg.join('\n') });
            setTimeout(() => {
              this.messageFlag = false;
              this.assignRoleCount = 0;
              this.deAssignRoleCount = 0;
              this.msg = [];
            }, 3000);
            this.tab2 = true;
            this.enableBtn2 = true;
            this.showTab();
            this.getExRoleList();
          }
        },
        error: error => {
          if (error.error?.error?.messageCode == "CIAM 105") {
            this.messageService.add({ key: 'myKey1', severity: 'error', detail: "Request already available for approval" });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
          }
          else {
            this.messageFlag = true;
            this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Failed to Assign Roles.' });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
          }
        }
      });
  }

  //getting existing group list of users
  getExGroupList() {
    this.pagesService.getViewUserGroups(this.savedId).subscribe((results: any) => {
      const responseData = results?.success?.result;
      this.exGroupList = [];
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.displayName;
          data.value = element.id;
          this.exGroupList.push(data.value);
        });
        this.exGroupList = [...this.exGroupList];
      }
    });
  }

  getNewGroupList() {
    const responseData = this.AddUserForm.value.groupList;
    this.newGroupList = [];
    if (responseData && responseData.length > 0) {
      responseData.forEach((element: any = {}) => {
        const data = {
          label: null,
          value: null,
        };
        data.label = element.label;
        data.value = element.value;
        this.newGroupList.push(data.value);
      });
      this.newGroupList = [...this.newGroupList];
    }
  }

  // assiging user group to user
  assignGroups($event: UntypedFormGroup): void {
    $event.updateValueAndValidity();
    this.newGroupList = this.AddUserForm.value.groupList;
    const assignList = this.newGroupList.filter(x => !this.exGroupList.includes(x));
    const deassignList = this.exGroupList.filter(x => !this.newGroupList.includes(x));
    const reqBody = {};
    reqBody['assignList'] = assignList;
    reqBody['deAssignList'] = deassignList;
    const ID = this.savedId;
    const formValues = reqBody;
    this.messageFlag = true;
    this.pagesService.onSaveAssignGroup(formValues, ID).subscribe(
      {
        next: results => {
          for (let i = 0; i < assignList.length; i++) {
            if (results?.success?.result?.AssingGroupsToUser[i]?.messageCode == 'CIAM 151') {
              this.assignGroupCount = this.assignGroupCount + 1;
            }
          }
          for (let i = 0; i < deassignList.length; i++) {
            if (results?.success?.result["De-AssingGroupsFromUser"][i]?.messageCode == 'CIAM 152') {
              this.deAssignGroupCount = this.deAssignGroupCount + 1;
            }
          }
          if (this.deAssignGroupCount == 0 && this.assignGroupCount > 0) {
            this.msg1.push(this.assignGroupCount + ' Group Assigned successfully');
          } else if (this.deAssignGroupCount > 0 && this.assignGroupCount > 0) {
            this.msg1.push(this.assignGroupCount + ' Group Assigned successfully');

            this.msg1.push(this.deAssignGroupCount + ' Group De-assigned successfully');
          } else if (this.deAssignGroupCount > 0 && this.assignGroupCount == 0) {
            this.msg1.push(this.deAssignGroupCount + ' Group De-assigned successfully')
          }
          this.messageFlag = true;
          this.clicked1 = false;
          this.messageService.add({ key: 'myKey1', severity: 'success', detail: this.msg1.join('\n') });
          setTimeout(() => {
            this.messageFlag = false;
            this.assignGroupCount = 0;
            this.deAssignGroupCount = 0;
            this.msg1 = [];
          }, 3000);
          this.showTab();
          this.tab3 = true;
          this.enableBtn3 = true;
          this.getExGroupList();
        },
        error: () => {
          this.messageFlag = true;
          this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Failed to Assign Groups.' });
          setTimeout(() => {
            this.messageFlag = false;
          }, 3000);
        }
      });
  }

  //getting role list API
  getroleList(): void {
    this.pagesService.gettableDataRoles().subscribe(results => {
      const responseData = results?.success?.result?.records;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.displayName;
          data.value = element.id;
          this.roleListList.push(data);
        });
        this.roleListList = [...this.roleListList];
      }
    });
  }

  //getting user group list from API
  getgroupList(): void {
    this.pagesService.gettableDataListGroups().subscribe(results => {
      const responseData = results?.success?.result?.records;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.displayName;
          data.value = element.id;
          this.groupListList.push(data);
        });
        this.groupListList = [...this.groupListList];
      }
    });
  }

  showTab() {
    if (this.pagesService.pageRebacFlag == "false") {
      if (this.activeIndex1 === 0) {
        this.activeIndex1 = 1;
      } else if (this.activeIndex1 === 1) {
        this.activeIndex1 = 2;
      }
    }
    else if (this.activeIndex1 === 0 || this.activeIndex1 === 2) {
      this.activeIndex1 = 2;
    }
  }


  fetchCustomField() {
    this.pagesService.getFetchCustomFieldListing(this.moduleID).subscribe(results => {
      const responseData = results?.success?.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            id: null,
            fieldName: null,
            fieldInputtype: null,
            inputFormat: null,
            mandatoryField: null,
            editableField: null,
            configJson: null,
            maxCharacters: null,
            pattern: null,
            specialChar: null
          };
          data.id = element.id;
          data.fieldName = element.fieldName;
          this.customJSON = JSON.parse(element.fieldConfigJason);
          data.configJson = this.customJSON;
          data.fieldInputtype = data.configJson.fieldLabel;
          data.inputFormat = data.configJson.inputvalue_format;
          data.mandatoryField = data.configJson.mandatory;
          data.editableField = data.configJson.editable;
          data.maxCharacters = data.configJson.MaxCharacters;
          data.specialChar = data.configJson?.specialCharacter;
          this.dataFor(data);
          if (data.fieldInputtype == 'Checkbox') {
            this.checkboxArr.push(data);
          }
          else {
            this.customList.push(data);
          }
        });
        this.customList = [...this.customList];
      }
    });
  }

  dataFor(data: any) {
    if ((data.fieldInputtype == 'Checkbox') || (data.inputFormat[0] == 'Date range' && data.fieldInputtype == 'Input field in range')) {
      this.AddUserForm.addControl(data.id,
        this.fb.control(null, []));
    }
    else if (data.fieldInputtype == 'Input field in range' && data.inputFormat[0] == 'Text/Number range') {
      this.AddUserForm.addControl(data.id + "_start",
        this.fb.control(null, []));
      this.AddUserForm.addControl(data.id + "_end",
        this.fb.control(null, []));
    }
    else {
      if (data.fieldInputtype == 'Text' || data.fieldInputtype == 'Text area') {
        data.pattern = this.setValidationsPatterns(data.inputFormat, data.specialChar);
      }
      this.AddUserForm.addControl(data.id,
        this.fb.control(null, []));
    }
  }

  setValidationsPatterns(format: any, specialChar: any): string {
    let upper = "";
    let lower = "";
    let num = "";
    let spCh = "";
    let pattern = "";
    format.forEach(element => {
      if (element == "Uppercase alphabet") {
        upper = "A-Z";
      }
      else if (element == "Lowercase alphabet") {
        lower = "a-z";
      }
      else if (element == "Numbers") {
        num = "0-9";
      }
    });
    specialChar?.forEach(element => {
      spCh = spCh + "\\" + element?.value;
    });
    pattern = "^([" + lower + "" + upper + "" + num + "" + spCh + "]+\\s)*[" + lower + "" + upper + "" + num + "" + spCh + "]+$";
    return pattern;

  }

  navigateToMyReq(msg: any): void {
    const navigationExtras: NavigationExtras = {
      state: {
        msg: msg,
      },
    };
    this.router.navigate(['/pages/myrequestslisting'], navigationExtras);
  }


  getConfigurations() {

    this.pagesService.getConfigurations().subscribe(
      results => {
        const arrData: { configType: string, displayName: string, configName: string, isEnabled: boolean, configLevel: string, tenantId: string, applicationId: string }[] = results?.success?.result;

        this.isIdentityProviderEnabled = arrData.find(c => c.configType == IDENTITY_PROVIDER && c.configLevel == CONFIG_LAVEL_GLOBAL)?.isEnabled;
      });
  }
  setUsernameExternalLdap() {
    this.userNameExternalLdap = false;
    document.getElementById('userName_id')!.style.borderColor = 'grey';
    const chUsername = this.AddUserForm.value.userName?.replace(/\s/g, "");
    if (chUsername == null || chUsername == "") {
      if (this.createInExternalLdap) {
        let ldapUsername = this.AddUserForm.value.emailAddress?.split("@")[0];
        this.AddUserForm.controls["userName"].setValue(ldapUsername);
        this.AddUserForm.controls["userName"].updateValueAndValidity();
      }
    }
    if (this.createInExternalLdap) {
      const un = this.AddUserForm.value.userName?.replace(/\s/g, "");
      if (un?.length > 20) {
        this.userNameExternalLdap = true;
        document.getElementById('userName_id')!.style.borderColor = 'red';
      }
      else {
        this.userNameExternalLdap = false;
        document.getElementById('userName_id')!.style.borderColor = 'grey';
        this.onFirstCreateUser();
      }
    }

  }
  sendEmail() {
    const reqBody = {};
    reqBody['userId'] = this.savedId;
    reqBody['tenantId'] = this.pagesService.tenantId;
    reqBody['applicationIds'] = this.AddUserForm.value.application;
    const data = reqBody;
    this.pagesService.sendEmail(data).subscribe({
      next: (results: any) => {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'Email send successfully.' });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      },
    });
  }

  getUsersByEmail(): void {
    this.autocompleteList = [];
    if (this.AddUserForm.value.emailAddress.length > 1) {
      this.userDetailsService.searchUsersByEmail(this.AddUserForm.value.emailAddress).subscribe(results => {
        const responseData = results;
        console.log(responseData);
        if (responseData && responseData.length > 0) {
          this.userFound = true;

          document.getElementById('emailAddress_id')!.style.borderColor = 'grey';
          responseData.forEach((element: any = {}) => {
            const data = {
              label: null,
              value: null,
              firstName: null,
              lastName: null,
              userType: null,
              username: null
            };
            data.label = element.email;
            data.value = element.username;
            data.firstName = element.firstName;
            data.lastName = element.lastName;
            data.userType = element.userType;
            data.username = element.username;
            this.autocompleteList.push(data);
          });
          this.autocompleteList = [...this.autocompleteList];
          if (this.autocompleteList.length == 1) {
            this.AddUserForm.controls['lastName'].setValue(this.autocompleteList[0].lastName);
            this.AddUserForm.controls['firstName'].setValue(this.autocompleteList[0].firstName);
            this.AddUserForm.controls['userName'].setValue(this.autocompleteList[0].username);
            this.AddUserForm.controls['emailAddress'].setValue(this.autocompleteList[0].label);
            this.AddUserForm.controls['lastName'].disable();
            this.AddUserForm.controls['firstName'].disable();
            this.AddUserForm.controls['userName'].disable();
          }
        }
        else {
          this.noUserFound = true;
          document.getElementById('emailAddress_id')!.style.borderColor = 'grey';

          this.AddUserForm.controls['lastName'].reset();
          this.AddUserForm.controls['firstName'].reset();
          this.AddUserForm.controls['userName'].reset();
          this.AddUserForm.controls['lastName'].enable();
          this.AddUserForm.controls['firstName'].enable();
          this.AddUserForm.controls['userName'].enable();
          const data = {
            label: null,
            value: null,
            firstName: null,
            lastName: null,
            userType: null,
            username: null
          };
          data.label = "No results found";
          data.value = "No results found";
          data.firstName = null;
          data.lastName = null;
          data.userType = null;
          data.username = null;
          this.autocompleteList.push(data);
          this.autocompleteList = [...this.autocompleteList];
        }
      });
    }
  }

  setUserStorage() {
    if (this.pagesService.userStorage == 'externalAD') {
      this.createInExternalLdap = true;
    } else if (this.pagesService.userStorage == 'azureB2C') {
      this.createInIdentityProvider = true;
    }
  }
  onAccountsdropdown() {
    this.pagesService.getAccountsdropdown().subscribe(results => {
      const responseData = results.success?.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.accountList.push(data);
        });
        this.accountList = [...this.accountList];
      }
    });
  }

  logEvent(eventName, eventContextName, value) {
    const data = {
      "ContextName": eventContextName,
      "value": value,
      "EventName": eventName
    };
    this.pagesService.logUserEvent(data).subscribe(resp => {
      //console.log(resp);

    },
      error => {
        console.log("Error >> ", ContextName.VIEW_CREATE_USER_PAGE, ":", error);
      });
  }
}
