<div class="layout-content custom-container">
    <form [formGroup]="CancelRequestForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                <div class=" p-fluid   p-field    ">
                    <p>Are you sure you want to cancel the transaction ID '{{transactionId}}'?<br>
                        <em> <strong>Note:</strong> The action will remove the record from the table.</em>
                    </p>
                </div>
            </div><br>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right">
                <div class="btnwrap custom-save">
                    <button pButton type="button" class="p-button-brand2" (click)="onClickNo(CancelRequestForm);"
                        label="No">
                    </button>
                </div>
                <div class="btnwrap">
                    <button pButton class="p-button-primary" type="button" (click)="deleteRole();" label="Yes">
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>