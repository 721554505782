<div class="layout-content custom-container">
    <div class="p-d-flex p-jc-end">
        <p-messages key="myKey1" class="custom-alert-box custom-msg" *ngIf="this.messageFlag">
        </p-messages>
    </div>
    <form [formGroup]="ImportUserDetailsForm" autocomplete="off" novalidate>
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                <div class="p-fluid p-field">
                    <span class="d-block label-top"> <label>
                            Select User type<span class='invalid-feedback-astrik'> *</span>
                        </label>
                    </span>
                    <p-selectButton id="selectSwitch_id" name="selectSwitch_name"
                        [(ngModel)]="defaultselect_selectSwitch" [options]="selectSwitchList"
                        formControlName="selectSwitch" styleClass="default" (onChange)="this.invalidTemplate=false">
                        <ng-template let-item>
                            <span class="p-button-label">{{item.label}}</span>
                        </ng-template>
                    </p-selectButton>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left">
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left">
                <div class="p-fluid p-field">
                    <button pButton type="button" label="Download Template" icon="pi pi-download"
                        class="p-button-info p-mr-2 custom-color-download" iconPos="left"
                        style="width: max-content;margin-top: 14%;font-weight: 500;"
                        (click)="downloadTemplate() "></button>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 p-pl-1 left disable">
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-10 p-xl-10 p-offset-0 left">
                    <div class="p-col p-p-0 p-mt-4 p-mr-3 p-p-0 custom_small_file">
                        <label>Upload file <span class='invalid-feedback-astrik'>*</span></label>
                        <div class="custom_choose_file custom-tenant-file">
                            <p-fileUpload id="newSolutionPageIconChooseFilebtn" name="newSolutionPageIconChooseFilebtn"
                                #fileUploadPageIconChooseFilebtn chooseLabel="Choose File" customUpload="true"
                                cancelLabel="Reset" [showUploadButton]="false" [showCancelButton]="false"
                                (onSelect)="onSelectSolutionIcon($event)">
                                <ng-template pTemplate="content">
                                    <ng-container
                                        *ngIf="imageURL && (productPrimaryImage == '' || productPrimaryImage == undefined);then previewText1; else previewText">
                                    </ng-container>
                                    <ng-template #previewText1>
                                        <label class="label_content">
                                            <span pTooltip="{{imageForToolTip.length > 28 ? imageForToolTip : ''}}"
                                                tooltipPosition="left">
                                                <ng-container *ngIf="imageForToolTip.length > 28; else tooltip">
                                                    {{
                                                    imageURL.split("/")[4].substring(0,
                                                    30) +
                                                    "..." }}
                                                </ng-container>
                                                <ng-template #tooltip>
                                                    {{ imageURL.split("/")[4] }}
                                                </ng-template>
                                            </span>
                                        </label>
                                    </ng-template>
                                    <ng-template #previewText>
                                        <label
                                            *ngIf="(productPrimaryImage == '' || productPrimaryImage == undefined) && !imageFileFlag"
                                            class="label_content">
                                            &nbsp;Choose .xlsx file,Max size 5MB.
                                        </label>
                                    </ng-template>
                                </ng-template>
                                <ng-template let-file pTemplate="file">
                                    <div class="file">
                                        <ng-container
                                            *ngIf="imageURL && (productPrimaryImage == '' || productPrimaryImage == undefined);then previewdata1; else previewdata">
                                        </ng-container>
                                        <ng-template #previewdata>
                                            <span class="ml-2 fileName"
                                                pTooltip="{{file.name.length >28 ? file.name : ''}}"
                                                tooltipPosition="left">
                                                <ng-container *ngIf="file.name.length > 28; else tooltip">
                                                    {{ file.name.substring(0,28) +
                                                    "..." }}
                                                </ng-container>
                                                <ng-template #tooltip>
                                                    {{ file.name }}
                                                </ng-template>
                                            </span>
                                            <span class="delete-action"
                                                (click)="onRemoveIcon(event,fileUploadPageIconChooseFilebtn)">
                                                <i aria-hidden="true" class="pi pi-times"></i>
                                            </span>
                                        </ng-template>
                                        <ng-template #previewdata1></ng-template>
                                    </div>
                                </ng-template>
                            </p-fileUpload>
                        </div>

                    </div>

                </div>
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-2 p-xl-2 p-offset-0" style="margin-top: -32px;float:right;">
                    <button pButton type="button" label="Import" class="p-button-primary"
                        [disabled]="!imageFileFlag || solImageFileSize || imagevalid ||invalidTemplate"
                        (click)="importUsers()">
                    </button>
                </div>
                <div class="p-error p-ml-2" *ngIf="solImageFileSize">
                    <label class="alert-icon alert-circle-icon"></label>
                    <span>File size is more than 5 MB.</span>
                </div>
                <div class="p-error p-ml-2" *ngIf="imagevalid">
                    <label class="alert-icon alert-circle-icon"></label>
                    <span>Please upload .xlsx file format.</span>
                </div>
                <div class="p-error p-ml-2" *ngIf="invalidTemplate">
                    <label class="alert-icon alert-circle-icon"></label>
                    <span>Please use
                        latest template as per user type.</span>
                </div>
                <div class="p-ml-2" *ngIf="this.defaultFlag && !imageFileFlag">
                    <label class="alert-icon alert-circle-icon1"></label>
                    <span style="font-size: 12px;font-weight: 400;color: #505870;vertical-align: middle;">Please use
                        latest template as per user type.</span>
                </div>
            </div>
        </div>
    </form>
</div>