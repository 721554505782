import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PagesService } from '../pages.service';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { ContextName, EventName } from 'app/shared/constants/logging-event.constants';
import { eventNames } from 'process';

@Component({
  selector: 'jhi-deactiverole',
  templateUrl: './ActivateUser.component.html',
  styleUrls: ['./ActivateUser.component.scss'],
})

export class ActivateUserComponent implements OnInit {
  ActivateUserForm = this.fb.group({});
  activeId: any[] = [];
  activeUserData:any[]=[];
  pageloadvalues: any;
  wfFlag = false;
  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    // private messageService: MessageService,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.ActivateUserForm?.patchValue(JSON.parse(data));
    }
  }

  ngOnInit(): void {
    this.activeId = this.config?.data?.id;
    this.activeUserData = this.config?.data?.rowData;
  }

  onClickNo($event: UntypedFormGroup): void {
    this.ref.close();
  }

  //Activate user API function
  onClickYes($event: UntypedFormGroup): void {
    const reqBody = {};
    reqBody["tenantId"] = this.pagesService?.tenantId;
    reqBody["userIds"] = this.activeId;
    const formData = reqBody;
    this.pagesService.ActivateUser(formData).subscribe({
      next: results => {
        let cnt = 0;
        let kk = results?.success?.result;

        for (let i = 0; i < kk?.length; i++) {
          if (kk[i].messageCode == 'CIAM 154') {
            cnt = cnt + 1;
            this.wfFlag = true;
          }
          else if (kk[i].messageCode == 'CIAM 254') {
            this.wfFlag = false;
          }
        }

        if (this.wfFlag) {
          this.ref.close();
          this.navigateToMyReq('Activate user request has been sent for approval');
        }
        else if (!this.wfFlag) {
          if (this.activeId.length == 1) {
            this.ref.close('User Activated Succesfully');
          } else {
            this.ref.close('Users Activated Succesfully');
          }
        }
        this.logEvent(EventName.ACTIVATE_USER,ContextName.ACTIVATE_USER,this.activeUserData);
      },
      error: error => {
        if (error.error?.error?.messageCode == "CIAM 105") {
          this.ref.close('Request already available for approval');
        }
        else {
          this.ref.close('Failed to activate user');
        }
      }
    });
  }

  navigateToMyReq(msg: any): void {
    const navigationExtras: NavigationExtras = {
      state: {
        msg: msg,
      },
    };
    this.router.navigate(['/pages/myrequestslisting'], navigationExtras);
  }

  logEvent(eventName,eventContextName,value){
    const data={
      "ContextName":eventContextName,
      "value":value,
      "EventName":eventName,
      "newValue":'ACTIVE USER'
    };
    this.pagesService.logUserEvent(data).subscribe(resp =>{
      //console.log(resp);
      
    },
            error => {
              console.log("Error >> ",ContextName.VIEW_MANAGE_USERS_PAGE,":", error);
            });
  }
}
