<div class="layout-content custom-container"
    style="min-height:0vh;box-shadow: none;border:none;padding-bottom: 0px;margin-bottom: auto;">
    <form [formGroup]="UnlockUserForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class=" p-col-12">
                <div class="ui-fluid p-formgrid p-grid">
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                        <div class=" p-fluid   p-field    ">
                            <p>Are you sure you want to
                                Unlock the selected User?</p>
                        </div>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right">
                        <div class="btnwrap">
                            <button pButton class="p-button-secondary p-mr-2" type="button"
                                (click)="onClickNo(UnlockUserForm);" label="No">
                            </button>
                        </div>
                        <div class="btnwrap">
                            <button pButton class="p-button-primary" type="button"
                                (click)="onCcprimebutton1(UnlockUserForm);" label="Yes">
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>