<div class="layout-content custom-container">
    <form [formGroup]="HostAttributeGroupMappingForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class=" p-col-12 p-pl-0 p-pr-0">
                <div class="ui-fluid p-formgrid p-grid">
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                        <div class=" p-fluid   p-field    ">
                            <label>
                                Attribute GroupName:
                                <span><b>{{this.groupName}}</b></span>
                            </label>

                        </div>
                    </div>
                    <div class="p-col-8 p-sm-12 p-md-8 p-lg-8 p-xl-8 p-offset-undefined left"></div>
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left"
                        *ngIf="!this.pagesService.attributeTreeEnable">
                        <div class=" p-fluid   p-field    ">
                            <label
                                [ngClass]="{'field-required': HostAttributeGroupMappingForm.get('select')?.errors?.required}">
                                Attribute Type
                                <span class='invalid-feedback-astrik'>*</span></label>
                            <div class="p-fluid">
                                <p-dropdown [filter]="true" filterBy="label" id="selectid" name="select"
                                    placeholder="Select" [options]="selectList" [autoDisplayFirst]="false"
                                    formControlName="select" (onChange)="getMappedDetails();this.flag3=true;">
                                    <ng-template let-items pTemplate="item">
                                        <div *ngIf="items?.label?.length &lt;= 35;">
                                            {{items.label}}
                                        </div>
                                        <div *ngIf="items?.label?.length > 35;" pTooltip="{{items.label}}"
                                            tooltipPosition="bottom">
                                            {{items.label.substring(0,35) + "..." }}
                                        </div>
                                    </ng-template>
                                </p-dropdown>
                            </div>
                            <div class="p-error"
                                *ngIf="HostAttributeGroupMappingForm.get('select')!.invalid && (HostAttributeGroupMappingForm.get('select')!.dirty || HostAttributeGroupMappingForm.get('select')!.touched)">
                                <div *ngIf="HostAttributeGroupMappingForm.get('select')?.errors?.required">
                                    <label class="alert-icon alert-circle-icon"></label>
                                    <medium class="p-error">Attribute Type is required.</medium>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class=" p-col-12 p-p-0">
                                <div class="ui-fluid p-formgrid p-grid">
                                    <div
                                        class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left p-pl-0 p-pr-0">
                                        <div class=" p-fluid   p-field    ">
                                            <div class="p-col-12 custom-emptyImage-size"
                                                *ngIf="!this.pagesService.attributeTreeEnable && !this.flag3">
                                                <label class="d1-icons group1-icon"></label>
                                                <label class="p-d-flex p-jc-center">Please select <span class="p-pl-1"
                                                        style="font-weight: 500;">Attribute Type</span></label>
                                            </div>
                                            <div class=" p-col-12 p-pl-0 p-pr-0"
                                                *ngIf="!this.pagesService.attributeTreeEnable && this.flag3">
                                                <div style="display: flex;width:100%;" [class]="styleClass"
                                                    [ngStyle]="style" class="custom-picklist-with-checkbox"
                                                    [ngClass]="{'p-picklist p-component p-widget p-helper-clearfix': true,'p-picklist-responsive': responsive}">
                                                    <div class="p-picklist-list-wrapper p-picklist-source-wrapper"
                                                        [ngClass]="{'p-picklist-listwrapper-nocontrols':!showSourceControls}"
                                                        style="padding:0px;">
                                                        <div class="p-picklist-header">
                                                            <div class="p-picklist-title">Unmapped Attributes
                                                                <span
                                                                    class="p-picklist-count">{{pickList1List.length}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="p-picklist-filter-container"
                                                            *ngIf="filterBy && showSourceFilter !== false">
                                                            <div class="p-picklist-filter">
                                                                <span class="p-input-icon-left" style="margin: 0px;">
                                                                    <input #sourceFilter type="text" role="textbox"
                                                                        (keydown.enter)="$event.preventDefault()"
                                                                        class="p-picklist-filter-input p-inputtext inputtextHeight p-component"
                                                                        placeholder="Search"
                                                                        (input)="filterMethodPick($event.target.value,pickList1List1,'pick1source')"
                                                                        [disabled]="disabled"
                                                                        formControlName="sourceFilter">
                                                                    <i aria-hidden="true" class="pi pi-search"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <ul #sourcelist
                                                            class="cdk-drop-list p-picklist-list p-picklist-source"
                                                            [ngClass]="{'p-picklist-highlight': listHighlightSource}"
                                                            [ngStyle]="sourceStyle">
                                                            <ng-template ngFor let-item [ngForOf]="pickList1List"
                                                                [ngForTrackBy]="sourceTrackBy || trackBy" let-i="index"
                                                                let-l="last">
                                                                <li [ngClass]="{'cdk-drag p-picklist-item p-ripple':true,'p-state-highlight':isSelected(item,selectedItemsSource), 'p-state-disabled': disabled}"
                                                                    [style.display]="isItemVisible(item, SOURCE_LIST) ? 'block' : 'none'">
                                                                    <div class="product-item"
                                                                        style="padding-top: 0rem;padding-bottom: 0rem;">
                                                                        <span class="list-check image-container"
                                                                            style="top: 0px !important;">
                                                                            <p-checkbox name="groupname"
                                                                                [value]="isChecked" #picklistCheckboxes
                                                                                [(ngModel)]="item.isChecked"
                                                                                binary="true"
                                                                                (ngModelChange)="onItemClick($event,item,selectedItemsSource,onSourceSelect)"
                                                                                [ngModelOptions]="{standalone: true}">
                                                                            </p-checkbox>
                                                                        </span>
                                                                        <div class="product-list-detail">
                                                                            <h6>{{item.label}}</h6>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li class="p-picklist-droppoint" *ngIf="dragdrop&&l"
                                                                    (dragover)="onDragOver($event, i + 1, SOURCE_LIST)"
                                                                    (drop)="onDrop($event, i + 1, SOURCE_LIST)"
                                                                    (dragleave)="onDragLeave($event, SOURCE_LIST)"
                                                                    [ngClass]="{'p-picklist-droppoint-highlight': (i + 1 === dragOverItemIndexSource)}">
                                                                </li>
                                                            </ng-template>
                                                        </ul>
                                                    </div>
                                                    <div class="p-picklist-buttons p-picklist-transfer-buttons"
                                                        style="width:min-content;">
                                                        <button type="button" pButton pRipple icon="pi pi-angle-right"
                                                            [disabled]="disabled"
                                                            class="p-ripple p-button p-component p-button-icon-only"
                                                            (click)="moveRight()"
                                                            [disabled]="this.pagesService.applicationOnboardingReadOnlyFlag">

                                                            <span class="p-ink"></span>
                                                        </button>
                                                        <button type="button" pButton icon="pi pi-angle-double-right"
                                                            [disabled]="disabled"
                                                            class="p-ripple p-button p-component p-button-icon-only"
                                                            (click)="moveAllRight()"
                                                            [disabled]="this.pagesService.applicationOnboardingReadOnlyFlag">

                                                            <span class="p-ink"></span>
                                                        </button>
                                                        <button type="button" pButton icon="pi pi-angle-left"
                                                            [disabled]="disabled"
                                                            class="p-ripple p-button p-component p-button-icon-only"
                                                            (click)="moveLeft()"
                                                            [disabled]="this.pagesService.applicationOnboardingReadOnlyFlag">

                                                            <span class="p-ink"></span>
                                                        </button>
                                                        <button type="button" pButton icon="pi pi-angle-double-left"
                                                            [disabled]="disabled"
                                                            class="p-ripple p-button p-component p-button-icon-only"
                                                            (click)="moveAllLeft()"
                                                            [disabled]="this.pagesService.applicationOnboardingReadOnlyFlag">

                                                            <span class="p-ink"></span>
                                                        </button>
                                                    </div>
                                                    <div class="p-picklist-list-wrapper p-picklist-target-wrapper"
                                                        [ngClass]="{'p-picklist-listwrapper-nocontrols':!showTargetControls}"
                                                        style="padding:0px;">
                                                        <div class="p-picklist-header">
                                                            <div class="p-picklist-title">Mapped Attributes
                                                                <span
                                                                    class="p-picklist-count">{{pickList1targetList.length}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="p-picklist-filter-container"
                                                            *ngIf="filterBy && showSourceFilter !== false">
                                                            <div class="p-picklist-filter">
                                                                <span class="p-input-icon-left" style="margin: 0px;">
                                                                    <input #sourceFilter type="text" role="textbox"
                                                                        (keydown.enter)="$event.preventDefault()"
                                                                        class="p-picklist-filter-input p-inputtext inputtextHeight p-component"
                                                                        placeholder="Search"
                                                                        (input)="filterMethodPick($event.target.value,pickList1targetList1,'pick1target')"
                                                                        [disabled]="disabled"
                                                                        formControlName="sourceFilter">
                                                                    <i aria-hidden="true" class="pi pi-search"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <ul #targetlist cdkDropList
                                                            class="cdk-drop-list p-picklist-list p-picklist-target"
                                                            [ngClass]="{'p-picklist-highlight': listHighlightTarget}"
                                                            [ngStyle]="targetStyle">
                                                            <ng-template ngFor let-item [ngForOf]="pickList1targetList"
                                                                [ngForTrackBy]="targetTrackBy || trackBy" let-i="index"
                                                                let-l="last">
                                                                <li pRipple cdkDrag role="option"
                                                                    [ngClass]="{'cdk-drag p-picklist-item p-ripple':true,'p-state-highlight':isSelected(item,selectedItemsTarget), 'p-state-disabled': disabled}"
                                                                    [style.display]="isItemVisible(item, TARGET_LIST) ? 'flex' : 'none'">
                                                                    <div class="product-item"
                                                                        style="padding-top: 0rem;padding-bottom: 0rem;">
                                                                        <span class="list-check image-container"
                                                                            style="top: 0px !important;">
                                                                            <p-checkbox name="groupname"
                                                                                [value]="isChecked" #picklistCheckboxes
                                                                                [(ngModel)]="item.isChecked"
                                                                                binary="true"
                                                                                (ngModelChange)="onItemClick($event,item,selectedItemsTarget,onTargetSelect)"
                                                                                [ngModelOptions]="{standalone: true}">
                                                                            </p-checkbox>
                                                                        </span>
                                                                        <div class="product-list-detail">
                                                                            <h6>{{item.label}}</h6>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li class="p-picklist-droppoint" *ngIf="dragdrop&&l"
                                                                    (dragover)="onDragOver($event, i + 1, TARGET_LIST)"
                                                                    (drop)="onDrop($event, i + 1, TARGET_LIST)"
                                                                    (dragleave)="onDragLeave($event, TARGET_LIST)"
                                                                    [ngClass]="{'p-picklist-droppoint-highlight': (i + 1 === dragOverItemIndexTarget)}">
                                                                </li>
                                                            </ng-template>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="this.pagesService.attributeTreeEnable"
                                                class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left p-pl-0 p-pr-0">
                                                <p-card styleClass="p-cardshadow-c0 tree_selection_card">
                                                    <div class="p-grid p-jc-between p-ai-center">
                                                        <div class="p-col header p-d-flex">
                                                            <h1 class=" display-4 p-m-2" style="width: auto;">Attribute
                                                                list</h1>
                                                            <p-badge *ngIf="this.alreadyMappedTreeList?.length > 0"
                                                                [value]="this.alreadyMappedTreeList?.length"
                                                                class="padd"></p-badge>
                                                        </div>
                                                        <div class="p-col header">
                                                            <div class="p-d-flex p-jc-end options">
                                                                <span (click)="resetTree()" pTooltip="Reset"
                                                                    tooltipPosition="top"
                                                                    class="option-btn icon-rotate-right"></span>
                                                                <span (click)="multipleSelectionExpandAll()"
                                                                    pTooltip="Expand All" tooltipPosition="top"
                                                                    class="option-btn icon-plus-square"></span>
                                                                <span (click)="multipleSelectionCollapseAll()"
                                                                    pTooltip="Collapse All" tooltipPosition="top"
                                                                    class="option-btn icon-minus-square p-mr-2"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p-tree [value]="treeList1List" [filter]="true"
                                                        selectionMode="checkbox" scrollHeight="250px"
                                                        [(selection)]="this.alreadyMappedTreeList"
                                                        (onNodeSelect)="nodeSelect($event)"
                                                        (onNodeUnselect)="nodeUnselect($event)"></p-tree>
                                                </p-card>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right p-pl-0 p-pr-0">
                                        <div class="btnwrap">
                                            <button pButton type="button" class="p-button-brand2 p-button p-mr-2"
                                                (click)="onClose(HostAttributeGroupMappingForm);" label="Close">
                                            </button>
                                        </div>
                                        <div class="btnwrap">
                                            <button pButton type="button" label="Secondary"
                                                *ngIf="!this.pagesService.attributeTreeEnable"
                                                class="p-button-primary p-button" [disabled]="btn" (click)="onSave()"
                                                label="Save"
                                                [disabled]="this.pagesService.applicationOnboardingReadOnlyFlag"></button>
                                        </div>
                                        <div class="btnwrap" *ngIf="this.pagesService.attributeTreeEnable">
                                            <button pButton type="button" label="Secondary"
                                                class="p-button-primary p-button" [disabled]="btn"
                                                (click)="onSaveTree()" label="Save"
                                                [disabled]="this.pagesService.applicationOnboardingReadOnlyFlag"></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>