<div>
    <form class="layout-content custom-form-layout" style="padding-left: 0px !important;padding-right: 0px !important;"
        [formGroup]="ViewHostHierarchyAttributesForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">

            <div class="p-col-8 p-sm-8 p-md-8 p-lg-8 p-xl-8 p-offset-undefined left p-mt-3 p-mb-3">
                    <p-card style="border-radius: 2px;" >
                        <div class="p-formgrid p-grid">
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left p-mt-3 p-mb-3">
                                <div style="display: inline-flex;">
                                    <label class="view-icon" style="width: 28px;height: 28px;"></label>
                                    <p style="margin-top: 4px;font-weight: 600;margin-left: 4px;">Details</p>
                                </div>
                            </div>
                            <div class="p-col-3 p-sm-3 p-md-3 p-lg-3 p-xl-3 p-offset-undefined left p-mt-3 p-mb-3">
                                Type: <b>{{this.attributeType}}</b>
                            </div>
                            <div class="p-col-3 p-sm-3 p-md-3 p-lg-3 p-xl-3 p-offset-undefined left p-mt-3 p-mb-3">
                                ID: <b>{{this.attributeID}}</b>
                            </div>
                            <div class="p-col-3 p-sm-3 p-md-3 p-lg-3 p-xl-3 p-offset-undefined left p-mt-3 p-mb-3">
                                Name: <b>{{this.resourceName}}</b>
                            </div>
                            <div class="p-col-3 p-sm-3 p-md-3 p-lg-3 p-xl-3 p-offset-undefined left p-mt-3 p-mb-3">
                                Access Type: <b>{{this.accessType}}</b>
                            </div>
                            <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 p-offset-undefined left p-mt-4 p-mb-4">
                                Default Attribute: <b>{{this.isDefault}}</b>
                            </div>
                            <div class="p-col-8 p-sm-8 p-md-4 p-lg-8 p-xl-8 p-offset-undefined left p-mt-4 p-mb-4">
                                Description: <b> {{this.description}} </b>
                            </div>
                            
                        </div>
                        
                    </p-card>
                
            </div>
            <div class="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 p-offset-undefined left p-mt-4 p-mb-4">
                <p-card style="overflow: auto;">
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left p-mt-3">
                        <div style="display: inline-flex;">
                            <label class="hierarchy-icon" style="width: 28px;height: 28px;"></label>
                            <p style="margin-top: 4px;font-weight: 600;margin-left: 4px;">Hierarchy</p>
                        </div>
                    </div>
                    <p-tree [value]="attributeList" scrollHeight="250px" (onNodeExpand)="nodeExpand($event)"
                        [loading]="loading"></p-tree>
                </p-card>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right p-mt-1 p-mb-2">
                <div class="btnwrap p-mr-3">
                    <button pButton type="button" class="p-button-brand2"
                        (click)="onClose(ViewHostHierarchyAttributesForm);" label="Close">
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>