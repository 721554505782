export const environment =
{
  production: 'CIAM_UI_production_VALUE',
  KEY_CLOAK_URL: 'CIAM_UI_KEY_CLOAK_URL_VALUE',
  KEY_CLOAK_CLIENT_ID: 'CIAM_UI_KEY_CLOAK_CLIENT_ID_VALUE',
  profile: 'CIAM_UI_PROFILE_VALUE',
  KEY_CLOAK_REALM_NAME: 'CIAM_UI_KEY_CLOAK_REALM_NAME_VALUE',
  IMAGE_PATH: 'CIAM_UI_IMAGE_PATH_VALUE',
  CIAM_UI_SERVICE_API_URL: 'CIAM_UI_SERVICE_API_URL_VALUE',
  Fulkrum_Workspace_URL: 'Fulkrum_Workspace_URL_VALUE',
  CIAM_ROLE_MIGRATION_FLAG: 'CIAM_ROLE_MIGRATION_FLAG_VALUE',
  CIAM_UM_SERVER_API_URL: 'CIAM_UM_SERVER_API_URL_VALUE',
  REBAC_UM_ENTITLEMENT_FLAG: 'REBAC_UM_ENTITLEMENT_FLAG_VALUE',
  MULTI_TENANCY_ENABLE: 'MULTI_TENANCY_ENABLE_VALUE',
  CIAM_UI_TENANT_ID: 'CIAM_UI_TENANT_ID_VALUE',
  IS_SAAS_ENABLED: 'IS_SAAS_ENABLED_VALUE',
};