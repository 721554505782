<div class="layout-content custom-container ">
    <form [formGroup]="CreateWorkflowConfigurationForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3 p-offset-undefined left">
                <div class=" p-fluid   p-field    ">
                    <label
                        [ngClass]="{'field-required': CreateWorkflowConfigurationForm.get('select')?.errors?.required}">
                        CIAM Action
                        <span class='invalid-feedback-astrik'>*</span></label>
                    <p-dropdown [filter]="true" filterBy="label" id="selectid" name="select" placeholder="Select"
                        [options]="selectList" [autoDisplayFirst]="false" formControlName="select" filter="true"
                        (onChange)="onCheckAvailabilityCiamAction();">
                        <ng-template let-items pTemplate="item">
                            <div *ngIf="items?.label?.length &lt;= 35;">
                                {{items.label}}
                            </div>
                            <div *ngIf="items?.label?.length > 35;" pTooltip="{{items.label}}" tooltipPosition="bottom">
                                {{items.label.substring(0,35) + "..." }}
                            </div>
                        </ng-template>
                    </p-dropdown>
                    <div class="p-error"
                        *ngIf="CreateWorkflowConfigurationForm.get('select')!.invalid && (CreateWorkflowConfigurationForm.get('select')!.dirty || CreateWorkflowConfigurationForm.get('select')!.touched)">
                        <div *ngIf="CreateWorkflowConfigurationForm.get('select')?.errors?.required">
                            <label class="alert-icon alert-red-icon"></label>
                            This field is required
                        </div>
                    </div>
                    <div class="invalid-feedback">
                        <label *ngIf="this.nameFlag">
                            <div>
                                <label class="alert-icon alert-red-icon"></label>
                                <medium class="p-error">Workflow of selected action already exists.
                                </medium>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3 p-offset-undefined left">
                <div class=" p-fluid   p-field    ">
                    <label
                        [ngClass]="{'field-required': CreateWorkflowConfigurationForm.get('select1')?.errors?.required}">
                        No Of Approvers
                        <span class='invalid-feedback-astrik'>*</span></label>
                    <p-dropdown id="select1id" name="select1" placeholder="Select" [options]="select1List"
                        [autoDisplayFirst]="false" formControlName="select1"
                        (onChange)="addApprover();getApprovalTypeSelection();">
                    </p-dropdown>
                    <div class="p-error"
                        *ngIf="CreateWorkflowConfigurationForm.get('select1')!.invalid && (CreateWorkflowConfigurationForm.get('select1')!.dirty || CreateWorkflowConfigurationForm.get('select1')!.touched)">
                        <div *ngIf="CreateWorkflowConfigurationForm.get('select1')?.errors?.required">
                            <label class="alert-icon alert-red-icon"></label>
                            <medium class="p-error"> This field is required.</medium>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3 p-offset-undefined left">
                <div class=" p-fluid   p-field    " style="margin-bottom: 0px;">
                    <label
                        [ngClass]="{'field-required': CreateWorkflowConfigurationForm.get('select2')?.errors?.required}">
                        Approval Type
                        <span class='invalid-feedback-astrik'>*</span></label>
                    <label tooltipPosition="right"
                        pTooltip="<ul style='padding-left: 12px;margin-bottom: 3px;' ><li style='color:white'>Parallel would trigger all the <br> approvals at the same time.</li><li style='color:white'>Sequential allow a sequential <br> trigger of approvals.</li></ul>"
                        class="alert-icon alert-circle-icon" [escape]="false"></label>
                    <p-dropdown [filter]="true" filterBy="label" id="select2id" name="select2" placeholder="Select"
                        [(ngModel)]="approvalTypeSelected" [options]="select2List" [autoDisplayFirst]="false"
                        formControlName="select2">
                    </p-dropdown>
                    <div class="p-error"
                        *ngIf="CreateWorkflowConfigurationForm.get('select2')!.invalid && (CreateWorkflowConfigurationForm.get('select2')!.dirty || CreateWorkflowConfigurationForm.get('select2')!.touched)">
                        <div *ngIf="CreateWorkflowConfigurationForm.get('select2')?.errors?.required">
                            <label class="alert-icon alert-red-icon"></label>
                            <medium class="p-error">This field is required</medium>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3 p-offset-undefined left">
                <div class="p-fluid p-field">
                    <label
                        [ngClass]="{'field-required': CreateWorkflowConfigurationForm.get('autoRejectDays')?.errors?.required}">
                        No. of days for auto-reject transaction
                        <span class='invalid-feedback-astrik'>*</span></label>
                    <input type="text" id="autoRejectDays_id" pattern="^[0-9]*$" name="autoRejectDays"
                        [(ngModel)]="autoRejectDaysModel" formControlName="autoRejectDays" pInputText
                        placeholder="Enter No of Days" class="p-inputtext inputtextHeight" maxlength="5" />
                    <div class="p-error"
                        *ngIf="CreateWorkflowConfigurationForm.get('autoRejectDays')!.invalid && (CreateWorkflowConfigurationForm.get('autoRejectDays')!.dirty || CreateWorkflowConfigurationForm.get('autoRejectDays')!.touched)">
                        <div *ngIf="CreateWorkflowConfigurationForm.get('autoRejectDays')?.errors?.required">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium class="p-error">
                                No. of days for Auto-Reject transaction are required.</medium>
                        </div>
                        <div *ngIf="CreateWorkflowConfigurationForm.get('autoRejectDays').errors.pattern">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium class="p-error">
                                No. of days for Auto-Reject transaction should contain only numbers.
                            </medium>
                        </div>
                    </div>

                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 left" *ngIf="approverNum>0">
                <div class="   ">
                    <h6><strong>Add approver</strong></h6>
                </div>
            </div>
            <div *ngFor="let num of createRange(approverNum);let index=index;let count=count"
                class="p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3 p-offset-undefined left">
                <div class=" p-fluid   p-field    ">
                    <label
                        [ngClass]="{'field-required': CreateWorkflowConfigurationForm.get('Approver'+num)?.errors?.required}">
                        Approver Role {{num}}
                        <span class='invalid-feedback-astrik'>*</span></label>

                    <p-dropdown optionLabel="label" [autoDisplayFirst]="false" optionValue="value" [filter]="true"
                        filterBy="label" id="select_Approver{{num}}" name="select_Approver{{num}}" placeholder="Select"
                        [options]="approvalRole" formControlName="Approver{{num}}" (onChange)="checkApproverRole(num)">
                    </p-dropdown>
                    <div class="p-error"
                        *ngIf="CreateWorkflowConfigurationForm.get('Approver'+num)!.invalid && (CreateWorkflowConfigurationForm.get('Approver'+num)!.dirty || CreateWorkflowConfigurationForm.get('Approver'+num)!.touched)">
                        <div *ngIf="CreateWorkflowConfigurationForm.get('Approver'+num)?.errors?.required">
                            <label class="alert-icon alert-red-icon"></label>
                            <medium class="p-error">This field is required</medium>
                        </div>
                    </div>
                    <div class="invalid-feedback">
                        <label *ngIf="this['roleFlag'+num]">
                            <div>
                                <label class="alert-icon alert-red-icon"></label>
                                <medium class="p-error">Approver role already selected.
                                </medium>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right p-mt-3 p-mb-2">
                <div class="btnwrap p-mr-3">
                    <button pButton type="button" class="p-button-brand2 p-button"
                        (click)="onClose(CreateWorkflowConfigurationForm);" label="Close">
                    </button>
                </div>
                <div class="btnwrap">
                    <button pButton class="p-button" type="button" (click)="onSave();"
                        [disabled]="enableBtn || nameFlag || !CreateWorkflowConfigurationForm.valid" label="Save">
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>