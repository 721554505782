import { Component, ContentChildren, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MessageService, PrimeTemplate, FilterService } from 'primeng/api';
import { PagesService } from '../pages.service';
import { DomHandler } from "primeng/dom";
import { ObjectUtils } from '../object-utlis.service';
@Component({
  selector: 'jhi-usermapping',
  templateUrl: './UserMapping.component.html',
  styleUrls: ['./UserMapping.component.scss'],
  providers: [MessageService, FilterService, ObjectUtils]
})
export class UserMappingComponent implements OnInit {
  @ViewChild('customId', { static: false }) auto: any;
  UserMappingForm = this.fb.group({
    tabview: [0],
    autocomplete: [null, [Validators.required]],
    select: [null, [Validators.required]],
    select1: [null, [Validators.required]],
    pickList1: [null, []],
    pickList2: [null, []],
    sourceFilter: [null, []],
  });
  pageloadvalues: any;
  ccprimebreadcrumbList = [
    { label: `Authorization` },
    { label: `Application Attribute` },
    { label: `User Mapping` },
  ]
  homeiconccprimebreadcrumb = { icon: 'pi pi-home', routerLink: ['/'] };
  htmlSrc: any;
  autocompleteList: any[] = [];
  selectList: any[] = [];
  select1List: any[] = [];
  pickList1List: any[] = [];
  pickList1List1: any[] = [];
  pickList1targetList: any[] = [];
  pickList1targetList1: any[] = [];
  pickList2List: any[] = [];
  pickList2List2: any[] = [];
  pickList2targetList: any[] = [];
  pickList2targetList2: any[] = [];
  text: any[] = [];
  btn = true;
  messageFlag = true;
  messageFlag1 = true;
  results: string[] = [];
  options: any;
  tableList: any[] = [];
  appId: any;
  attId: any;
  uId: any;
  flag1 = true;
  selectedData: any[] = [];
  mapList: any[] = [];
  printList: any[] = [];
  mapList1: any[] = [];
  unmapList: any[] = [];
  mList: any[] = [];
  mList1: any[] = [];
  exMapList: any[] = [];
  GroupExMapList: any[] = [];
  btn1 = true;
  uList: any[];
  hideType = true;
  value: any;
  wfFlag = false;
  isChecked: boolean = false;
  @ViewChildren("picklistCheckboxes") checkboxes: QueryList<ElementRef>;
  @Input() sourceHeader: string;
  @Input() targetHeader: string;
  @Input() responsive: boolean;
  @Input() filterBy: string = "brand";
  @Input() trackBy: Function = (index: number, item: any) => item;
  @Input() sourceTrackBy: Function;
  @Input() targetTrackBy: Function;
  @Input() showSourceFilter: boolean = true;
  @Input() showTargetFilter: boolean = true;
  @Input() metaKeySelection: boolean = true;
  @Input() style: any;
  @Input() styleClass: string;
  @Input() sourceStyle: any;
  @Input() targetStyle: any;
  @Input() showSourceControls: boolean = true;
  @Input() showTargetControls: boolean = true;
  @Input() sourceFilterPlaceholder: string;
  @Input() targetFilterPlaceholder: string;
  @Input() disabled: boolean = false;
  @Output() onMoveToSource: EventEmitter<any> = new EventEmitter();
  @Output() onMoveAllToSource: EventEmitter<any> = new EventEmitter();
  @Output() onMoveAllToTarget: EventEmitter<any> = new EventEmitter();
  @Output() onMoveToTarget: EventEmitter<any> = new EventEmitter();
  @Output() onSourceSelect: EventEmitter<any> = new EventEmitter();
  @Output() onTargetSelect: EventEmitter<any> = new EventEmitter();
  @ViewChild('sourcelist') listViewSourceChild: ElementRef;
  @ViewChild('targetlist') listViewTargetChild: ElementRef;
  @ViewChild('sourceFilter') sourceFilterViewChild: ElementRef;
  @ViewChild('targetFilter') targetFilterViewChild: ElementRef;
  @ContentChildren(PrimeTemplate) templates: QueryList<any>;
  public itemTemplate: TemplateRef<any>;
  public visibleOptionsSource: any[];
  public visibleOptionsTarget: any[];
  selectedItemsSource: any[] = [];
  selectedItemsTarget: any[] = [];
  reorderedListElement: any;
  movedUp: boolean;
  movedDown: boolean;
  itemTouched: boolean;
  filterValueSource: string;
  filterValueTarget: string;
  listHighlightTarget: boolean;
  listHighlightSource: boolean;
  readonly SOURCE_LIST = -1;
  readonly TARGET_LIST = 1;
  application: any;
  attributeValue: any;
  constructor(private router: Router, protected activatedRoute: ActivatedRoute, private fb: UntypedFormBuilder, private messageService: MessageService, public pagesService: PagesService, private filterService: FilterService, private objectUtils: ObjectUtils) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.UserMappingForm.patchValue(JSON.parse(data));
    }

  }
  ngOnInit(): void {
    this.htmlSrc = '';
    this.gettableList();
    this.UserMappingForm.get('select').disable();
    this.UserMappingForm.get('select1').disable();
  }
  //new picklist
  ngAfterContentInit() {
    this.templates.forEach((item) => {
      this.itemTemplate = item.template;
    });
  }

  ngAfterViewChecked() {
    if (this.movedUp || this.movedDown) {
      let listItems = DomHandler.find(this.reorderedListElement, 'li.p-state-highlight');
      let listItem;
      if (this.movedUp)
        listItem = listItems[0];
      else
        listItem = listItems[listItems.length - 1];

      DomHandler.scrollInView(this.reorderedListElement, listItem);
      this.movedUp = false;
      this.movedDown = false;
      this.reorderedListElement = null;
    }
  }

  onItemClick(event, item: any, selectedItems: any[], callback: EventEmitter<any>) {
    if (this.disabled) {
      return;
    }

    let index = this.findIndexInSelection(item, selectedItems);
    let selected = (index != -1);
    if (selected) {
      selectedItems.splice(index, 1);
    }
    else {
      selectedItems.push(item);
    }
    callback.emit({ originalEvent: event, items: selectedItems });
    this.itemTouched = false;
  }

  filterMethodPick(event: any, list: any, pickFlag: any) {
    let filtered: any[] = [];
    let items: any;
    let query = event;
    if (query.length > 0) {
      list.forEach(element => {
        items = element;
        if (items.label?.toLowerCase().includes(query.toLowerCase()) || items.isPrivate?.toLowerCase().includes(query.toLowerCase())) {
          filtered.push(items);
        }
      });
      if (pickFlag == 'pick1source') {
        this.pickList1List = filtered;
      }
      else if (pickFlag == 'pick1target') {
        this.pickList1targetList = filtered;
      }
      else if (pickFlag == 'pick2source') {
        this.pickList2List = filtered;
      }
      else if (pickFlag == 'pick2target') {
        this.pickList2targetList = filtered;
      }

    }
    else if (pickFlag == 'pick1source') {
      this.pickList1List = [];
      this.pickList1List = [...this.pickList1List1];
    }
    else if (pickFlag == 'pick1target') {
      this.pickList1targetList = [];
      this.pickList1targetList = [...this.pickList1targetList1];
    }
    else if (pickFlag == 'pick2source') {
      this.pickList2List = [];
      this.pickList2List = [...this.pickList2List2];
    }
    else if (pickFlag == 'pick2target') {
      this.pickList2targetList = [];
      this.pickList2targetList = [...this.pickList2targetList2];
    }
  }

  isItemVisible(item: any, listType: number): boolean {
    if (listType == this.SOURCE_LIST) {
      return this.isVisibleInList(this.visibleOptionsSource, item, this.filterValueSource);
    }
    else {
      return this.isVisibleInList(this.visibleOptionsTarget, item, this.filterValueTarget);
    }
  }

  isVisibleInList(data: any[], item: any, filterValue: string): boolean {
    if (filterValue?.trim().length) {
      data.forEach(element => {
        if (item == element) {
          return true;
        }
      });
    }
    else {
      return true;
    }
  }

  moveRight() {
    if (this.selectedItemsSource?.length) {
      this.selectedItemsSource.forEach(element => {
        let selectedItem = element;
        if (this.findIndexInList(selectedItem, this.pickList1targetList) == -1) {
          this.pickList1targetList.push(this.pickList1List.splice(this.findIndexInList(selectedItem, this.pickList1List), 1)[0]);
          this.pickList1targetList1.push(this.pickList1List1.splice(this.findIndexInList(selectedItem, this.pickList1List1), 1)[0]);
          this.btn = false;
        }
      });
      this.isChecked = false;
      this.onMoveToTarget.emit({
        items: this.selectedItemsSource
      });
      this.selectedItemsSource = [];
    }
    this.checkboxes.forEach((element) => {
      this.pickList1targetList.filter(z => z).forEach((x) => (x.isChecked = false));
    });
  }

  moveRight2() {
    if (this.selectedItemsSource?.length) {
      this.selectedItemsSource.forEach(element => {
        let selectedItem = element;
        if (this.findIndexInList(selectedItem, this.pickList2targetList) == -1) {
          this.pickList2targetList.push(this.pickList2List.splice(this.findIndexInList(selectedItem, this.pickList2List), 1)[0]);
          this.pickList2targetList2.push(this.pickList2List2.splice(this.findIndexInList(selectedItem, this.pickList2List2), 1)[0]);
          this.btn1 = false;
        }
      });
      this.isChecked = false;
      this.onMoveToTarget.emit({
        items: this.selectedItemsSource
      });
      this.selectedItemsSource = [];
    }
    this.checkboxes.forEach((element) => {
      this.pickList2targetList.filter(z => z).forEach((x) => (x.isChecked = false));
    });
  }

  moveAllRight() {
    if (this.pickList1List) {
      let movedItems = [];
      this.pickList1targetList1 = [];
      for (let i = 0; i < this.pickList1List.length && this.isItemVisible(this.pickList1List[i], this.SOURCE_LIST); +i) {
        let removedItem = this.pickList1List.splice(i, 1)[0];
        this.pickList1List1.splice(this.findIndexInList(removedItem, this.pickList1List1), 1);
        this.pickList1targetList.push(removedItem);
        movedItems.push(removedItem);
        this.btn = false;
      }
      this.onMoveToTarget.emit({
        items: movedItems
      });
      this.onMoveAllToTarget.emit({
        items: movedItems
      });
      this.selectedItemsSource = [];
      this.pickList1targetList1 = [...this.pickList1targetList];
    }
    this.checkboxes.forEach((element) => {
      this.pickList1targetList.filter(z => z).forEach((x) => (x.isChecked = false));
    });
  }

  moveAllRight2() {
    if (this.pickList2List) {
      let movedItems = [];
      this.pickList2targetList2 = [];
      for (let i = 0; i < this.pickList2List.length && this.isItemVisible(this.pickList2List[i], this.SOURCE_LIST); +i) {
        let removedItem = this.pickList2List.splice(i, 1)[0];
        this.pickList2List2.splice(this.findIndexInList(removedItem, this.pickList2List2), 1);
        this.pickList2targetList.push(removedItem);
        movedItems.push(removedItem);
        this.btn1 = false;
      }
      this.onMoveToTarget.emit({
        items: movedItems
      });

      this.onMoveAllToTarget.emit({
        items: movedItems
      });

      this.selectedItemsSource = [];
      this.pickList2targetList2 = [...this.pickList2targetList];
    }
    this.checkboxes.forEach((element) => {
      this.pickList2targetList.filter(z => z).forEach((x) => (x.isChecked = false));
    });
  }

  /**
   * MOVE LEFT
   */
  moveLeft() {
    if (this.selectedItemsTarget?.length) {
      for (let i = 0; i < this.selectedItemsTarget.length; i++) {
        let selectedItem = this.selectedItemsTarget[i];
        if (this.findIndexInList(selectedItem, this.pickList1List) == -1) {
          this.pickList1List.push(this.pickList1targetList.splice(this.findIndexInList(selectedItem, this.pickList1targetList), 1)[0]);
          this.pickList1List1.push(this.pickList1targetList1.splice(this.findIndexInList(selectedItem, this.pickList1targetList1), 1)[0]);
          this.btn = false;
        }
      }
      this.isChecked = false;
      this.onMoveToSource.emit({
        items: this.selectedItemsTarget
      });
      this.selectedItemsTarget = [];
    }
    this.checkboxes.forEach((element) => {
      this.pickList1List.filter(z => z).forEach((x) => (x.isChecked = false));
    });
  }

  moveLeft2() {
    if (this.selectedItemsTarget?.length) {
      for (let i = 0; i < this.selectedItemsTarget.length; i++) {
        let selectedItem = this.selectedItemsTarget[i];
        if (this.findIndexInList(selectedItem, this.pickList2List) == -1) {
          this.pickList2List.push(this.pickList2targetList.splice(this.findIndexInList(selectedItem, this.pickList2targetList), 1)[0]);
          this.pickList2List2.push(this.pickList2targetList2.splice(this.findIndexInList(selectedItem, this.pickList2targetList2), 1)[0]);
          this.btn1 = false;
        }
      }
      this.isChecked = false;
      this.onMoveToSource.emit({
        items: this.selectedItemsTarget
      });

      this.selectedItemsTarget = [];
    }
    this.checkboxes.forEach((element) => {
      this.pickList2List.filter(z => z).forEach((x) => (x.isChecked = false));
    });
  }

  moveAllLeft() {
    if (this.pickList1targetList) {
      let movedItems = [];
      this.pickList1List1 = [];
      for (let i = 0; i < this.pickList1targetList.length && this.isItemVisible(this.pickList1targetList[i], this.TARGET_LIST); +i) {
        let removedItem = this.pickList1targetList.splice(i, 1)[0];
        this.pickList1targetList1.splice(this.findIndexInList(removedItem, this.pickList1targetList1), 1);
        this.pickList1List.push(removedItem);
        movedItems.push(removedItem);
        this.btn = false;
      }

      this.onMoveToSource.emit({
        items: movedItems
      });

      this.onMoveAllToSource.emit({
        items: movedItems
      });

      this.selectedItemsTarget = [];
      this.pickList1List1 = [...this.pickList1List];
    }
    this.checkboxes.forEach((element) => {
      this.pickList1List.filter(z => z).forEach((x) => (x.isChecked = false));
    });
  }

  moveAllLeft2() {
    if (this.pickList2targetList) {
      let movedItems = [];
      this.pickList2List2 = [];
      for (let i = 0; i < this.pickList2targetList.length && this.isItemVisible(this.pickList2targetList[i], this.TARGET_LIST); +i) {
        let removedItem = this.pickList2targetList.splice(i, 1)[0];
        this.pickList2targetList2.splice(this.findIndexInList(removedItem, this.pickList2targetList2), 1);
        this.pickList2List.push(removedItem);
        movedItems.push(removedItem);
        this.btn1 = false;
      }

      this.onMoveToSource.emit({
        items: movedItems
      });

      this.onMoveAllToSource.emit({
        items: movedItems
      });

      this.selectedItemsTarget = [];
      this.pickList2List2 = [...this.pickList2List];
    }
    this.checkboxes.forEach((element) => {
      this.pickList2List.filter(z => z).forEach((x) => (x.isChecked = false));
    });
  }

  isSelected(item: any, selectedItems: any[]) {
    return this.findIndexInSelection(item, selectedItems) != -1;
  }

  findIndexInSelection(item: any, selectedItems: any[]): number {
    return this.findIndexInList(item, selectedItems);
  }

  findIndexInList(item: any, list: any): number {
    let index: number = -1;

    if (list) {
      for (let i = 0; i < list.length; i++) {
        if (list[i] == item) {
          index = i;
          break;
        }
      }
    }

    return index;
  }

  handleChangetabview(e: any): void {
    this.UserMappingForm.patchValue({ "tabview": e.index });
  }

  /**
   * MAP/UNMAP functionality
   */

  //getting user list
  gettableList(): void {
    this.autocompleteList = [];
    this.pagesService.getUserListing().subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.autocompleteList.push(data);
        });
        this.autocompleteList = [...this.autocompleteList];
      }
    });
  }

  //filtering user list
  filterMethod(event: any) {
    let filtered: any[] = [];
    let items: any;
    let query = event.query;
    this.autocompleteList.forEach(element => {
      items = element;
      if (items.label?.toLowerCase().includes(query.toLowerCase())) {
        filtered.push(items);
      }
    });
    this.options = filtered;
  }

  select(v: any) {
    this.UserMappingForm.get('select').enable();
    this.uId = this.UserMappingForm.value.autocomplete.value;
    this.UserMappingForm.controls.select.reset();
    this.UserMappingForm.controls.select1.reset();
    this.flag1 = true;
    this.hideType = true;
  }

  //getting application list -dropdown
  getApplicationList(): void {
    this.selectList = []
    if (this.pagesService.userId == this.UserMappingForm.value.autocomplete.value) {
      this.uId = this.pagesService.userId;
    }
    else
      this.uId = this.UserMappingForm.value.autocomplete.value;
    console.log(this.uId);
    this.pagesService.getApplicationListMappingbyUserId(this.uId).subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.selectList.push(data);
        });
        this.selectList = [...this.selectList];
      }
      this.application = this.selectList[0].value;
      this.UserMappingForm.controls['select'].setValue(this.application);
      this.getAttribute();
    });

  }

  //get attribute list by application-dropdown
  getAttribute() {
    this.UserMappingForm.get('select1').enable();
    this.appId = this.UserMappingForm.value.select;
    console.log(this.appId);
    this.flag1 = true;
    this.UserMappingForm.controls.select1.reset();
    this.select1List = [];
    this.hideType = true;
    this.pagesService.getAttributeByApplication(this.appId).subscribe(results => {
      this.select1List = [];
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.select1List.push(data);
        });
        this.select1List = [...this.select1List];
        this.attributeValue = this.select1List[0].value;
      }
      this.UserMappingForm.controls['select1'].setValue(this.attributeValue);
      this.getMappedDetails();
    });
  }

  getMappedDetails() {
    this.UserMappingForm.controls.sourceFilter.reset();
    this.pickList1targetList = [];
    this.exMapList = [];
    this.pickList1List = [];
    this.pickList1targetList1 = [];
    this.pickList1List1 = [];
    this.appId = this.UserMappingForm.value.select;
    this.attId = this.UserMappingForm.value.select1;
    this.uId = this.UserMappingForm.value.autocomplete.value;
    const reqData = { appId: this.appId, attId: this.attId, uId: this.uId };
    this.pagesService.getUserMappedDetails(reqData).subscribe(results => {
      const responseData = results?.success?.result;
      this.pickList1targetList = [];
      this.exMapList = [];
      if (responseData && responseData.length > 0) {
        this.flag1 = false;
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
            isPrivate: null
          };
          data.label = element.label;
          data.value = element.value;
          if (element.isPrivate) {
            data.isPrivate = "Private";
          }
          else {
            data.isPrivate = "Public";
          }
          this.pickList1targetList.push(data);
          this.exMapList.push(data.value);
        });
        this.pickList1targetList = [...this.pickList1targetList];
        this.pickList1targetList1 = [...this.pickList1targetList];
        this.exMapList = [...this.exMapList];
      }

    });

    this.pagesService.getUserUnMappedDetails(reqData).subscribe(results => {
      const responseData = results?.success?.result;
      this.pickList1List = [];
      if (responseData && responseData.length > 0) {
        this.flag1 = false;
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
            isPrivate: null
          };
          data.label = element.label;
          data.value = element.value;
          if (element.isPrivate) {
            data.isPrivate = "Private";
          }
          else {
            data.isPrivate = "Public";
          }
          this.pickList1List.push(data);
        });
        this.pickList1List = [...this.pickList1List];
        this.pickList1List1 = [...this.pickList1List];
      }
    });
    this.flag1 = false;
  }

  getExMapUnMapList() {
    this.exMapList = [];
    this.appId = this.UserMappingForm.value.select;
    this.attId = this.UserMappingForm.value.select1;
    this.uId = this.UserMappingForm.value.autocomplete.value;
    const reqData = { appId: this.appId, attId: this.attId, uId: this.uId };
    this.pagesService.getUserMappedDetails(reqData).subscribe(results => {
      const responseData = results?.success?.result;
      if (responseData && responseData.length > 0) {
        this.exMapList = [];
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
            isPrivate: null
          };
          data.label = element.label;
          data.value = element.value;
          if (element.isPrivate) {
            data.isPrivate = "Private";
          }
          else {
            data.isPrivate = "Public";
          }
          this.exMapList.push(data.value);
        });
        this.exMapList = [...this.exMapList];
      }
    });
  }

  onSave() {
    this.messageFlag = true;
    this.messageFlag1 = true;
    this.appId = this.UserMappingForm.value.select;
    this.attId = this.UserMappingForm.value.select1;
    this.uId = this.UserMappingForm.value.autocomplete.value;
    this.mapList = this.pickList1targetList1;
    this.mList = [];
    this.mapList.forEach((element: any = {}) => {
      const data = {
        label: null,
        value: null,
      };
      data.label = element.label;
      data.value = element.value;
      this.mList.push(data.value);
    });
    this.mList = [...this.mList];
    const assignList = this.mList.filter(x => !this.exMapList.includes(x));
    const deassignList = this.exMapList.filter(x => !this.mList.includes(x));
    this.messageFlag = true;
    this.messageFlag1 = true;
    if (assignList.length == 0 && deassignList.length == 0) {
      this.messageService.add({ key: 'myKey2', severity: 'info', detail: 'No Attributes To Map.' });
      this.btn = true;
      setTimeout(() => {
        this.messageFlag1 = false;
      }, 3000);
    }
    else {
      const reqBody = {};
      reqBody['tenantId'] = this.pagesService.tenantId;
      reqBody['applicationId'] = this.appId;
      reqBody['userId'] = this.uId;
      const mapUnmap = { mapList: assignList, unmapList: deassignList };
      reqBody['mapUnmapDto'] = mapUnmap;
      const formValues = reqBody;
      this.pagesService.onSaveUserAttributeMapping(formValues).subscribe(
        {
          next: results => {
            results?.success?.result?.MapAttributeToUser?.forEach(element => {
              if (element?.messageCode == 'CIAM 654') {
                this.wfFlag = false;
              }
              else if (element?.messageCode == 'CIAM 154') {
                this.wfFlag = true;
              }
            });
            results?.success?.result?.UnmapAttributeToUser?.forEach(element => {
              if (element?.messageCode == 'CIAM 656') {
                this.wfFlag = false;
              }
              else if (element?.messageCode == 'CIAM 154') {
                this.wfFlag = true;
              }
            });
            if (this.wfFlag) {
              this.navigateToMyReq("Map/unmap attributes to User request has been sent for approval");
            }
            else {
              this.messageFlag = true;
              this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'Attribute mapping/unmapping saved successfully.' });
              this.btn = true;
              setTimeout(() => {
                this.messageFlag = false;
              }, 3000);
              this.messageFlag = true;
              this.getExMapUnMapList();
            }
          },
          error: error => {
            if (error.error?.error?.messageCode == "CIAM 105") {
              this.messageService.add({ key: 'myKey1', severity: 'error', detail: "Request already available for approval" });
              setTimeout(() => {
                this.messageFlag = false;
              }, 3000);
            }
            else {
              this.messageFlag = true;
              this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Attribute mapping/unmapping failed.' });
              setTimeout(() => {
                this.messageFlag = false;
              }, 3000);
            }
          }
        });
    }
  }

  getGroupMappedDetails() {
    this.pickList2targetList = [];
    this.pickList2List = [];
    this.pickList2targetList2 = [];
    this.pickList2List2 = [];
    this.GroupExMapList = [];
    this.appId = this.UserMappingForm.value.select;
    this.uId = this.UserMappingForm.value.autocomplete.value;
    const reqData = { appId: this.appId, uId: this.uId };
    this.pagesService.getUserGroupMappedDetails(reqData).subscribe(results => {
      const responseData = results?.success?.result;
      this.pickList2targetList = [];
      if (responseData && responseData.length > 0) {
        this.flag1 = false;
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.pickList2targetList.push(data);
          this.GroupExMapList.push(data.value);
        });
        this.pickList2targetList = [...this.pickList2targetList];
        this.pickList2targetList2 = [...this.pickList2targetList];
        this.GroupExMapList = [...this.GroupExMapList];
      }
    });

    this.pagesService.getUserGroupUnMappedDetails(reqData).subscribe(results => {
      const responseData = results?.success?.result;
      this.pickList2List = [];
      if (responseData && responseData.length > 0) {
        this.flag1 = false;
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.pickList2List.push(data);
        });
        this.pickList2List = [...this.pickList2List];
        this.pickList2List2 = [...this.pickList2List];

      }
    });
  }

  getGroupExMapUnMapList() {
    this.GroupExMapList = [];
    this.appId = this.UserMappingForm.value.select;
    this.uId = this.UserMappingForm.value.autocomplete.value;
    const reqData = { appId: this.appId, uId: this.uId };
    this.pagesService.getUserGroupMappedDetails(reqData).subscribe(results => {
      const responseData = results?.success?.result;
      if (responseData && responseData.length > 0) {
        this.GroupExMapList = [];
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.GroupExMapList.push(data.value);
        });
        this.GroupExMapList = [...this.GroupExMapList];
      }
    });
  }

  onSave1() {
    this.messageFlag = true;
    this.messageFlag1 = true;
    this.appId = this.UserMappingForm.value.select;
    this.uId = this.UserMappingForm.value.autocomplete.value;
    const saveData = { appId: this.appId, uId: this.uId };

    this.mapList1 = this.pickList2targetList2;
    this.mList1 = [];
    this.mapList1.forEach((element: any = {}) => {
      const data = {
        label: null,
        value: null,
      };
      data.label = element.label;
      data.value = element.value;
      this.mList1.push(data.value);
    });
    this.mList1 = [...this.mList1];
    const assignList = this.mList1.filter(x => !this.GroupExMapList.includes(x));
    const deassignList = this.GroupExMapList.filter(x => !this.mList1.includes(x));
    if (assignList.length == 0 && deassignList.length == 0) {
      this.messageFlag = true;
      this.messageFlag1 = true;
      this.messageService.add({ key: 'myKey2', severity: 'info', detail: 'No Attributes To Map.' });
      this.btn1 = true;
      setTimeout(() => {
        this.messageFlag1 = false;
      }, 3000);

    }
    else {
      const reqBody = {};
      reqBody['mapList'] = assignList;
      reqBody['unmapList'] = deassignList;
      const formValues = reqBody;
      this.pagesService.onSaveUserAttributeGroupMapping(formValues, saveData).subscribe(
        {
          next: results => {
            this.messageFlag = true;
            this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'Attribute Group mapping/unmapping saved successfully.' });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
            this.btn1 = true;
            this.getGroupExMapUnMapList();
          },
          error: () => {
            this.messageFlag = true;
            this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Attribute Group mapping/unmapping failed.' });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
          }
        });
    }
  }

  handleChange(e) {
    let index = e.index;
    if (index == 1) {
      this.hideType = false;
    }
    else {
      this.hideType = true;
    }
  }

  checkInput(event: any) {
    if (this.UserMappingForm.value.autocomplete == "") {
      this.UserMappingForm.controls.select1.reset();
      this.UserMappingForm.controls.select.reset();
      this.flag1 = true;
    }
  }

  navigateToMyReq(msg: any): void {
    const navigationExtras: NavigationExtras = {
      state: {
        msg: msg,
      },
    };
    this.router.navigate(['/pages/myrequestslisting'], navigationExtras);
  }
}