import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { PagesService } from '../pages.service';
import { MessageService } from 'primeng/api';
interface City {
  name: string,
  code: string
}
@Component({
  selector: 'jhi-InviteEmail',
  templateUrl: './InviteEmail.component.html',
  styleUrls: ['./InviteEmail.component.scss'],
  providers: [MessageService, DialogService],
})
export class InviteEmailComponent implements OnInit {
  InviteEmailForm = this.fb.group({
    subject: [null, [Validators.required]],
    appLink: [null, [Validators.required]],
    templateName: [null, [Validators.required]],
    sign: [null, [Validators.required]],
    emailBody: [null, [Validators.required]],
    checkbox: [null, []],
  });
  pageloadvalues: any;
  fonts: City[];
  Sizes: City[];
  editorContent: string = '';
  checked: boolean = false;
  solImageFileSize: boolean;
  imagePlacheholder: boolean;
  imagevalid: boolean;
  default: boolean;
  solImage: boolean;
  imageFileFlag: boolean;
  logo: File;
  formValidFlag: boolean;
  productPrimaryImage: any;
  imageFileFlag1: boolean;
  solImageFileSize1: boolean;
  imagePlacheholder1: boolean;
  imagevalid1: boolean;
  formValidFlag1: boolean;
  solImage1: boolean;
  productPrimaryImage1: any;
  baner: string | Blob;
  appId: any;
  isDefault: string;
  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private fb: UntypedFormBuilder,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public pagesService: PagesService) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.InviteEmailForm.patchValue(JSON.parse(data));
    }
  }

  ngOnInit(): void {
    this.appId = this.config?.data?.id;
    this.fonts = [
      { name: 'Arial', code: 'NY' },
      { name: 'Roboto', code: 'RM' },
      { name: 'sans-serif', code: 'LDN' },
      { name: 'Segoe UI', code: 'PRS' },
      { name: 'Verdana', code: 'IST' }
    ];
    this.Sizes = [
      { name: 'Small', code: 'NY' },
      { name: 'Medium', code: 'RM' },
      { name: 'large', code: 'LDN' },
      { name: 'x-large', code: 'PRS' },
      { name: 'x-large', code: 'IST' }
    ];

  }

  onClose($event: UntypedFormGroup): void {
    this.ref.close();
  }

  onUpload(event) {
    this.messageService.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
  }
  removeFiles(file, uploaders) {
    const index = uploaders._files.indexOf(file);
    uploaders._files.splice(index, 1);

  }
  clearEditor() {
    this.editorContent = '';
  }
  CreateTemplate(): void {
    const reqBody = {};
    const formdata = new FormData();
    formdata.append('hostApplicationId', this.appId);
    formdata.append('templateName', this.InviteEmailForm.value.templateName);
    formdata.append('subject', this.InviteEmailForm.value.subject);

    formdata.append('emailBody', this.InviteEmailForm.value.emailBody);
    formdata.append('signature', this.InviteEmailForm.value.sign);
    if (this.logo != undefined || this.logo != null) {
      formdata.append('logo', this.logo);
    }
    if (this.baner != undefined || this.baner != null) {
      formdata.append('banner', this.baner);
    }
    this.isDefault = this.checked + " ";
    formdata.append('applicationLink', this.InviteEmailForm.value.appLink);
    formdata.append('isDefault', this.isDefault);
    this.pagesService.createTemplate(formdata).subscribe(
      {
        next: results => {
          this.ref.close("Email template details saved successfully");
        },
        error: error => {
          this.ref.close("Template Creation Failed");
        }
      });
  }
  onSelectLogo(event: any): void {
    this.solImageFileSize = false;
    this.imagePlacheholder = false;
    this.imagevalid = false;
    this.formValidFlag = false;
    this.logo = new File([event.files[0]], event.files[0].name, {
      type: event.files[0].type
    });
    this.imageFileFlag = true;
    const isvalid = /(\.jpg|\.jpeg|\.png|\.svg)$/i;
    if (isvalid.test(this.logo.name)) {
      this.default = true;
      this.solImageFileSize = false;
      const regex = /[~#%&*{}:<>?/|'"]/g;
      this.productPrimaryImage = event.currentFiles[0].objectURL;
      let res = regex.test(this.logo.name);
      if (res) {
        this.solImage = true;
      }
      else if (event.currentFiles[0].size > 5000000) {
        this.solImageFileSize = true;
        this.formValidFlag = true;
      }
      else {
        setTimeout(() => {
        }, 1000);

      }
    } else {
      this.imagevalid = true;
      this.formValidFlag = true;
    }
  }
  onRemoveIcon(event: any, fileUploadPageIconChooseFilebtn): void {
    const index = fileUploadPageIconChooseFilebtn.files.indexOf(
      event
    );
    fileUploadPageIconChooseFilebtn.files.splice(index, 1);
    this.productPrimaryImage = "";
    this.imagevalid = false;
    this.solImageFileSize = false;
    setTimeout(() => {
    }, 1000);
  }
  onSelectBaner(event: any): void {
    this.solImageFileSize1 = false;
    this.imagePlacheholder1 = false;
    this.imagevalid1 = false;
    this.formValidFlag1 = false;
    this.baner = new File([event.files[0]], event.files[0].name, {
      type: event.files[0].type
    });
    this.imageFileFlag1 = true;
    const isvalid = /(\.jpg|\.jpeg|\.png|\.svg)$/i;
    if (isvalid.test(this.logo.name)) {
      this.default = true;
      this.solImage1 = false;
      this.solImageFileSize1 = false;
      const regex = /[~#%&*{}:<>?/|'"]/g;
      this.productPrimaryImage1 = event.currentFiles[0].objectURL;
      let res = regex.test(this.logo.name);
      if (res) {
        this.solImage = true;
      }
      else if (event.currentFiles[0].size > 5000000) {
        this.solImageFileSize1 = true;
        this.formValidFlag1 = true;
      }
      else {
        setTimeout(() => {
        }, 1000);

      }
    } else {
      this.imagevalid1 = true;
      this.formValidFlag1 = true;
    }
  }
  onRemoveBaner(event: any, fileUploadPageIconChooseFilebtn1): void {
    const index = fileUploadPageIconChooseFilebtn1.files.indexOf(
      event
    );
    fileUploadPageIconChooseFilebtn1.files.splice(index, 1);
    this.productPrimaryImage1 = "";
    this.imagevalid1 = false;
    this.solImageFileSize1 = false;
    setTimeout(() => {
    }, 1000);
  }
}