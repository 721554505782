import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ACTIVE, ACTIVATE, INACTIVE, DEACTIVATE, ARCHIVED, DELETE, ROLE_STATUS } from 'app/constants';
import { assetUrl } from 'src/single-spa/asset-url';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { RoleManagementService } from './ManageRoles.service';
import { Calendar } from 'primeng/calendar';
import { Table } from 'primeng/table';
import { LoggingEventService } from "app/logging-event.service";
import { PagesService } from '../pages.service';

@Component({
    selector: 'jhi-ManageRoles',
    templateUrl: './ManageRoles.component.html',
    styleUrls: ['./ManageRoles.component.scss'],
    providers: [MessageService]
})
export class RoleManagementComponent implements OnInit {
    ccprimebreadcrumbList = [{ label: `Authorization` }, { label: `Manage Roles` }];
    homeiconccprimebreadcrumb = { icon: 'pi pi-home', routerLink: ['/'] };
    disableButtons: any;
    @ViewChild('dt') table: Table;
    roles: any = [];
    isSuccess = false;
    isFailure = false;
    successMessage: string = '';
    failureMessage: string = '';
    columns = [];
    isAddNewRole: boolean = false;
    name: any;
    roleName: any;
    roleStatusList: any = [];
    search: any;
    creationPeriodRange: Date[];
    selectedRoles: any;
    filteredRoleList = [];
    isActiveRoleExist: boolean = false;
    isConfirmDialog: any;
    confirmMessage: any;
    updateId: any;
    updateRoleName: any;
    mode: any;
    totalRecords: number = 0;
    totalRoleCount: any = 0;
    totalInactiveRoleCount: any = 0;
    isActivityLog: boolean = false;
    rows = 10;
    pageIndex: number = 1;
    pageSize: number = 10;
    activityRoleId: any;
    activityRoleName: any;
    activityRoleStatus: any;
    activityLogList: any[];
    actionMessage: any;

    rowPerPageOptions: any[];

    selectedRecords: any[] = [];
    isBulkActivate: boolean = false;
    isBulkDeactivate: boolean = false;
    isBulkDelete: boolean = false;
    activateLabel: any;

    quickFilterCalendarIdx: number = -1;
    closeFilter: boolean = false;
    roleStatus: any = [];
    isFilterSelection: boolean = false;
    selectedRoleStatus: any = '';
    filterMessage = '';
    appliedRoleStatus: any = [];
    appliedSearch: any;
    currentPage: number = 1;
    isRolesPageChanged: boolean = false;

    activeTab: any = {
        isRoleTypeTab: false
    };
    breadcrumb = [{ label: `User Management` }, { label: `Manage Roles` }];
    breadcrumbHome = { icon: 'icon-home' };

    messageFlag = true;
    select1List: any[] = [];

    @ViewChild('dateFilter') dateFilter: Calendar;
    selectedApp: any;
    disableAppDropdown = false;
    roleListFlag = false;
    checkTotalRecords: boolean = false;
    ciamaction: any;
    secondCondition: boolean;
    transform(value: string): string {
        return assetUrl(value);
    }
    assetUrl = assetUrl;

    constructor(private roleManagementService: RoleManagementService, private router: Router, private messageService: MessageService,
        private loggingService: LoggingEventService, public pagesService: PagesService,) { }


    ngOnInit() {
        this.columns = [
            { field: 'ID', header: 'Role Id', filterMatchMode: 'contains', display: true },
            { field: 'NAME', header: 'Role Name', filterMatchMode: 'contains', display: true },
            { field: 'ROLE_STATUS', header: 'Status', filterMatchMode: 'contains', display: true },
            { field: 'SELF_SERVICE', header: 'Self Service', filterMatchMode: 'contains', display: true },
            { field: 'CREATED_DATE', header: 'Updated On', filterMatchMode: 'contains', display: true }
        ];
        this.getApplicationList();
    }

    filterOnDateRange() {
        if (this.creationPeriodRange?.values) {
            this.loadRoleData();
        }
    }
    imageUrl(url) {
        return assetUrl(url);
    }

    loadRoleData(): void {
        this.roleListFlag = true;
        let filter: any = {};
        this.selectedRecords = [];
        this.appliedSearch = null;

        filter['pageIndex'] = this.pageIndex;
        filter['pageSize'] = this.pageSize;

        if (this.search) {
            filter['SEARCH_TEXT'] = this.search;
            this.appliedSearch = this.search;
        }
        if (this.selectedRoleStatus && this.selectedRoleStatus.length > 0) {
            let statusIds = [];
            this.selectedRoleStatus.forEach(element => {
                statusIds.push(element.key);
            });
            filter['ROLE_STATUS_ID'] = statusIds;
        }

        if (this.creationPeriodRange?.values) {
            filter['FROM_DATE'] = this.creationPeriodRange[0];
            filter['TO_DATE'] = this.creationPeriodRange[1];
        }
        filter['isRolesPageChanged'] = this.isRolesPageChanged.toString();
        filter['APPLICATION_ID'] = this.selectedApp.id;
        this.roleManagementService.getRoleList(filter).subscribe(
            {
                next: response => {
                    this.roles = response['RESULT'];
                    this.totalRecords = response['TOTAL_RECORDS'];
                    this.totalRoleCount = response['TOTAL_ROLE_COUNT'];
                    this.totalInactiveRoleCount = response['TOTAL_INACTIVE_COUNT'];

                    if (this.totalRecords == 0 && this.checkTotalRecords) {
                        this.roleListFlag = false;
                    }
                    let count = 0;
                    if (this.pageSize > 10) {
                        this.rowPerPageOptions = [];
                    }
                    else {
                        this.rowPerPageOptions = [10];
                    }

                    if (this.totalRecords % 10 == 0) {
                        count = this.totalRecords / 10;
                    } else {
                        count = Math.floor(this.totalRecords / 10) + 1;
                    }

                    if (this.rowPerPageOptions.length > 0) {
                        for (let index = 2; index <= count; index++) {
                            this.rowPerPageOptions.push(index * 10);
                        }
                    }
                    else {
                        for (let index = 1; index <= count; index++) {
                            this.rowPerPageOptions.push(index * 10);
                        }
                    }
                    this.checkTotalRecords = false;
                },
                error: error => {
                    this.showErrorValidations(error.error);
                }
            });
    }

    loadRoleStatus(isDefault: boolean) {
        const formData: FormData = new FormData();
        formData.append('isDefault', isDefault ? '1' : '0');

        this.roleManagementService.getRoleStatusList(formData).subscribe(
            {
                next: response => {
                    if (isDefault) {
                        this.roleStatusList = response;
                    } else {
                        this.loadFilterData(this.roleStatus, response);
                    }
                },
                error: error => {
                    this.showErrorValidations(error.error);
                }
            });
    }

    loadFilterData(mainFilterList: any, dimensionList: any) {
        for (let parentIndex = 0; parentIndex < dimensionList.length; parentIndex++) {
            const parentNode = {
                label: dimensionList[parentIndex].NAME,
                data: dimensionList[parentIndex],
                children: [],
                selectable: true,
                expanded: true,
                key: dimensionList[parentIndex].ID
            };

            mainFilterList.push(parentNode);
        }
    }

    async createNewRole() {
        const formData: FormData = new FormData();
        formData.append('roleName', this.roleName.replace(/ +(?= )/g, '').trim());
        this.roleManagementService.createNewRole(formData).subscribe(
            {
                next: response => {
                    if (response.STATUS == 0) {
                        this.isActiveRoleExist = true;
                    } else {
                        this.showSuccessValidations('Role created successfully!');
                        this.isActiveRoleExist = false;
                        this.isAddNewRole = false;
                        this.roleName = null;

                        this.loadRoleData();
                    }
                },
                error: error => {
                    this.showErrorValidations(error.error);
                }
            });
    }

    openRoleSidebar() {
        this.roleName = null;
        this.isActiveRoleExist = false;
        this.isAddNewRole = true;
        this.isFilterSelection = false;
        if (!this.roleStatusList) this.loadRoleStatus(true);
    }

    openFilterSideBar() {
        this.isAddNewRole = false;
        this.isFilterSelection = true;

        if (this.roleStatus.length == 0) {
            this.loadRoleStatus(false);
        }
    }

    statusFilterSelect() {
        this.filteredRoleList = [];
        if (this.selectedRoles != null) {
            this.selectedRoles.forEach(role => {
                this.filteredRoleList.push(role.ID);
            });
            this.loadRoleData();
        }
    }

    clearSearchFilters() {
        this.selectedRoles = null;
        this.filteredRoleList = null;
        this.creationPeriodRange = null;
        this.search = null;
        this.loadRoleData();
    }

    onRowSelectionChange(event: any) {
        this.isBulkActivate = false;
        this.isBulkDeactivate = false;
        this.isBulkDelete = false;

        if (this.selectedRecords.length > 0) {
            this.selectedRecords = this.selectedRecords.filter(role => role.ROLE_STATUS.toString().toUpperCase() != 'ARCHIVED');

            let inActiveCount = this.selectedRecords.filter(role => role.ROLE_STATUS.toString() == INACTIVE).length;
            this.isBulkActivate = inActiveCount > 0;

            let activeCount = this.selectedRecords.filter(role => role.ROLE_STATUS.toString() == ACTIVE).length;
            this.isBulkDeactivate = activeCount > 0;

            let deleteCount = this.selectedRecords.filter(
                role => role.ROLE_STATUS.toString() == ACTIVE || role.ROLE_STATUS.toString() == INACTIVE
            ).length;
            this.isBulkDelete = deleteCount > 0;
        }
    }

    openBulkstatusUpdateModal(statusMode) {
        this.confirmMessage = '';
        this.isConfirmDialog = true;
        this.mode = statusMode;

        if (statusMode == ACTIVE) {
            this.activateLabel = ACTIVATE;
            this.confirmMessage = 'Do you want to activate all the selected roles?';
        } else if (statusMode == INACTIVE) {
            this.activateLabel = DEACTIVATE;
            this.confirmMessage = 'Do you want to deactivate all the selected role?';
        } else if (statusMode == ARCHIVED) {
            this.activateLabel = DELETE;
            this.confirmMessage = 'Do you want to delete all the selected roles as the deleted roles cannot be restored?';
        }
    }

    openStatusUpdateModal(id, roleName, mode) {
        this.confirmMessage = '';
        this.isConfirmDialog = true;
        this.updateId = id;
        this.updateRoleName = roleName;
        this.mode = mode;

        if (mode == ACTIVE) {
            this.activateLabel = ACTIVATE;
            this.actionMessage = 'This action will Activate the Role';
            this.confirmMessage = 'Do you want to activate the role?';
        } else if (mode == INACTIVE) {
            this.activateLabel = DEACTIVATE;
            this.actionMessage = 'This action will Deactivate the Role';
            this.confirmMessage = 'Do you want to deactivate the role?';
        } else if (mode == ARCHIVED) {
            this.activateLabel = DELETE;
            this.actionMessage = 'This action will Delete the Role';
            this.confirmMessage = 'Do you want to delete the selected role as deleted role cannot be restored?';
        }
    }

    onActionConfirmationClick() {
        let roleIdList = [];
        this.isConfirmDialog = false;

        if (this.selectedRecords.length > 0) {
            for (let index = 0; index < this.selectedRecords.length; index++) {
                if ((this.mode.toString() == ACTIVE && this.selectedRecords[index].ROLE_STATUS.toString() == INACTIVE) || (this.mode.toString() == INACTIVE && this.selectedRecords[index].ROLE_STATUS.toString() == ACTIVE)) {
                    roleIdList.push(this.selectedRecords[index].ID);
                } else if (
                    this.mode.toString() == ARCHIVED &&
                    (this.selectedRecords[index].ROLE_STATUS.toString() == ACTIVE ||
                        this.selectedRecords[index].ROLE_STATUS.toString() == INACTIVE)
                ) {
                    this.secondCondition = true;
                    roleIdList.push(this.selectedRecords[index].ID);
                }
            }
        } else {
            roleIdList.push(this.updateId);
        }

        const formData: FormData = new FormData();
        formData.append('roleIds', roleIdList.toString());
        formData.append('roleName', '');
        formData.append('roleStatus', this.mode.toString());

        this.roleManagementService.updateRoleStatus(formData).subscribe(
            {
                next: response => {
                    if (response != '0') {
                        this.showSuccessValidations('Role status updated successfully!');

                        this.loadRoleData();
                        this.confirmMessage = '';
                    } else {
                        this.showErrorValidations('Something went wrong, please try again!');
                    }
                },
                error: error => {
                    this.showErrorValidations(error);
                }
            });
    }

    cancelConfimDialog() {
        this.isConfirmDialog = false;
    }

    getRoleDataWithPagination(event: LazyLoadEvent) {
        this.pageIndex = event.first / event.rows + 1;
        this.pageSize = event.rows;
        this.loadRoleData();
    }

    loadActivityLog(roleId: any, roleName: any, roleStatus: any) {
        this.activityRoleId = roleId;
        this.activityRoleName = roleName;
        this.activityRoleStatus = roleStatus;
        this.isActivityLog = true;

        const formData: FormData = new FormData();

        formData.append('roleId', roleId);

        this.activityLogList = [];

        this.roleManagementService.getRoleActivityLog(formData).subscribe(
            {
                next: response => {
                    this.activityLogList = response;
                },
                error: error => {
                    this.showErrorValidations(error.error);
                }
            });
    }

    navigateToDetailScreen(roleData) {
        this.router.navigateByUrl('/pages/roledetails', {
            state: { role: roleData, appDetails: this.selectedApp },
            skipLocationChange: false
        });
    }
    onQuickFilterClick(day, month, year, filterIdx) {
        this.quickFilterCalendarIdx = filterIdx;
        this.setActiveFilterClass();
        const todayDate = new Date();
        const formatedDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate());
        if (this.creationPeriodRange != undefined) {
            this.creationPeriodRange[0] = this.getFilterRange(day, month, year);
            this.creationPeriodRange[1] = formatedDate;
        } else {
            this.creationPeriodRange = [];
            this.creationPeriodRange.push(this.getFilterRange(day, month, year));
            this.creationPeriodRange.push(formatedDate);
        }
        this.filterOnDateRange();
        this.dateFilter.updateInputfield();
        this.dateFilter.updateUI();
    }
    getFilterRange(day, month, year) {
        let today = new Date();
        let lastWeek = new Date(today.getFullYear() - year, today.getMonth() - month, today.getDate() - day);
        return lastWeek;
    }
    onDateRangeClear() {
        this.creationPeriodRange = null;
        this.quickFilterCalendarIdx = -1;
        this.closeFilter = true;
        this.clearSelectedList();
    }
    clearSelectedList() {
        this.loadRoleData();
    }
    addSelectedClass() {
        setTimeout(() => {
            const domNodes = document.querySelectorAll('.ui-datepicker-calendar .ui-state-highlight');
            domNodes.forEach(node => node.classList.remove('range-highlight'));
            if (this.creationPeriodRange.length === 2 && domNodes.length) {
                domNodes[0].classList.add('range-highlight');
                domNodes[domNodes.length - 1].classList.add('range-highlight');
            }
        }, 0);
    }
    removeActiveClass() {
        this.quickFilterCalendarIdx = -1;
        const allFilters: any = document.querySelectorAll('.document-library-wrapper .quick-filters-container > div');
        allFilters.forEach(element => {
            if (element.classList.contains('active')) {
                element.classList.remove('active');
            }
        });
    }
    setActiveFilterClass() {
        if (this.quickFilterCalendarIdx >= 0) {
            const allFilters: any = document.querySelectorAll('.document-library-wrapper .quick-filters-container > div');
            allFilters.forEach(element => {
                if (element.classList.contains('active')) {
                    element.classList.remove('active');
                }
            });
            allFilters[this.quickFilterCalendarIdx].classList.add('active');
        } else {
            this.removeActiveClass();
        }
    }

    applyFilter(): void {
        this.isFilterSelection = false;
        this.appliedRoleStatus = this.selectedRoleStatus;

        this.loadRoleData();
    }

    cancelFilterSection() {
        this.isFilterSelection = false;

        this.selectedRoleStatus = [];
        this.search = null;
        this.appliedSearch = null;

        this.activeTab = {
            isRoleTypeTab: false
        };
    }

    clearAllFilters() {
        this.selectedRoleStatus = [];
        this.search = null;
        this.appliedSearch = null;
        this.activeTab = {
            isRoleTypeTab: false
        };

        this.applyFilter();
    }

    setActiveTab(activeTabName: any) {
        this.activeTab.isRoleTypeTab = false;

        if (activeTabName == ROLE_STATUS) {
            this.activeTab.isRoleTypeTab = true;
        }
        this.openFilterSideBar();
    }
    loadNextPaginatorGrid(event) {
        this.isRolesPageChanged = true;
        this.pageIndex = event.first / event.rows + 1;
        this.currentPage = event.first / event.rows + 1;
        this.pageSize = event.rows;
    }
    onPageInputChange(event) {
        if (this.currentPage != null && this.currentPage != this.pageIndex) {
            if (this.currentPage > event.pageCount) {
                this.currentPage = 1;
            }
            this.pageIndex = this.currentPage;
            this.pageSize = event.rows;
            this.table.first = Math.ceil(event.totalRecords / event.pageCount) * (this.currentPage - 1);
            this.table.firstChange.emit(this.table.first);
            this.table.onLazyLoad.emit(this.table.createLazyLoadMetadata());
            this.loadRoleData();
        }
    }

    onChangeEvent() {
        if (this.isActiveRoleExist) {
            this.isActiveRoleExist = false;
        }
    }

    showSuccessValidations(message: string) {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: message });
        setTimeout(() => {
            this.messageFlag = false;
        }, 3000);

    }

    showErrorValidations(message: string) {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: message });
        setTimeout(() => {
            this.messageFlag = false;
        }, 3000);

    }
    getApplicationList(): void {

        this.pagesService.getApplicationListMapping().subscribe(results => {

            const responseData = results.success.result;

            if (responseData && responseData.length > 0) {
                responseData.forEach((element: any = {}) => {
                    const data = {
                        name: null,
                        id: null,
                    };
                    data.name = element.label;
                    data.id = element.value;
                    this.select1List.push(data);
                    if (responseData.length == 1) {
                        this.selectedApp = data;
                        this.disableAppDropdown = true;
                        this.loadRoleData();
                    }
                });
                this.select1List = [...this.select1List];
            }
        });
    }

}
