import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PagesService } from '../pages.service';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { DeactivateComponent } from '../Deactivate/Deactivate.component';
import { MenuItem, MessageService, ConfirmationService, PrimeNGConfig, Message, SortEvent } from 'primeng/api';
import { ViewTransactionDetailsComponent } from '../ViewTransactionDetails/ViewTransactionDetails.component';
import { ApproveTransactionComponent } from '../ApproveTransaction/ApproveTransaction.component';
import { RejectTransactionComponent } from '../RejectTransaction/RejectTransaction.component';
import { CancelRequestComponent } from '../CancelRequest/CancelRequest.component';
@Component({
  selector: 'jhi-MyRequestsListing',
  templateUrl: './MyRequestsListing.component.html',
  styleUrls: ['./MyRequestsListing.component.scss'],
  providers: [MessageService, DialogService, ConfirmationService],
})
export class MyRequestsListingComponent implements OnInit {
  ref!: DynamicDialogRef;
  MyRequestsListingForm = this.fb.group({
    textField: [null, []],
    ccprimesplitbutton: [null, []],
  });
  first = 0;

  rows = 10;
  isVisible = false;
  activeItem: any;
  activeStatus: any;
  showChip = false;
  arrayId: any[] = [];
  selectedItem!: any;
  id!: string;
  items!: MenuItem[];
  filterItems!: MenuItem[];
  msgs: Message[] = [];
  pageloadvalues: any;
  ccprimebreadcrumbList = [{ label: `My Account` }, { label: `My Request` }];
  homeiconccprimebreadcrumb = { icon: 'pi pi-home', routerLink: ['/'] };
  htmlSrc: any;
  searchList: any[] = [];
  ccprimesplitbuttonList: any[] = [];
  tableList: any[] = [];
  tableList1: any[] = [];
  tableList2: any[] = [];
  statusTable: any[] = [];
  tablecols: any[] = [
    { field: 'firstName', header: 'First Name' },
    { field: 'middleName', header: 'Middle Name' },
    { field: 'lastName', header: 'Last Name' },
    { field: 'emailAddress', header: 'Email Address' },
    { field: 'status', header: 'Status' },
  ];
  table: any[] = [];
  Hoveritems!: MenuItem[];
  selectedIndex: any;
  isDisplay: any;
  hoverIndex: any;
  selectedItems: any[] = [];
  messageFlag = true;
  activeId: any[] = [];
  selectedItems1: any[] = [];
  errorFlag: boolean = true;
  detail: string = '';
  actionDisable = true;
  checkDisable = false;
  uemail: any;
  roles: any;
  flagForceLogOff = 1;
  bulkActionItems: {}[];
  approvalMsg: any;
  offset: any;
  constructor(
    private primengConfig: PrimeNGConfig,
    public dialogService: DialogService,
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    private messageService: MessageService
  ) {
    const navigation = this.router.getCurrentNavigation();
    const data1 = navigation?.extras?.state?.data;
    if (data1) {
      this.MyRequestsListingForm.patchValue(JSON.parse(data1));
    }
    this.approvalMsg = navigation?.extras?.state?.msg;
  }
  ngOnInit(): void {
    this.offset = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.primengConfig.ripple = true;
    this.htmlSrc = '';
    if (this.approvalMsg) {
      setTimeout(() => {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: this.approvalMsg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }, 500);
    }
    this.gettableList();

  }

  gettableList(): void {
    const reqBody = {};
    reqBody['tenantId'] = this.pagesService.tenantId;
    const formValues = reqBody;
    this.tableList = [];
    this.pagesService.getRequestsListing(formValues).subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        this.tableList = responseData;
        this.tableList1 = responseData;
        this.errorFlag = false;
      } else {
        this.errorFlag = true;
      }
      if (this.MyRequestsListingForm.value.textField != null) {
        this.filterMethod(this.MyRequestsListingForm.value.textField);
      }
    });
  }

  showStatusFilter(status: any) {
    this.MyRequestsListingForm.controls.textField.reset();
    let filtered: any[] = [];
    let items: any;
    let query = status;
    if (status != null) {
      this.tableList1.forEach(element => {
        items = element;
        if (items.user_status?.toLowerCase().includes(query.toLowerCase())) {
          filtered.push(items);
        }
      });
      this.tableList = filtered;
      this.statusTable = filtered;
    }
    else {
      this.tableList = this.tableList1;
    }
  }


  resetinviteUserccprimesplitbutton(): void {
    this.MyRequestsListingForm.reset();
  }
  resetdeactivateccprimesplitbutton(): void {
    this.MyRequestsListingForm.reset();
  }
  resetccprimesplitbutton(): void {
    this.MyRequestsListingForm.reset();
  }
  onCcprimesplitbutton($event: UntypedFormGroup): void { }
  onCcprimebutton1($event: UntypedFormGroup): void {
    this.router.navigate(['/pages/adduser']);
  }

  viewTransactions() {
    this.ref = this.dialogService.open(ViewTransactionDetailsComponent, {
      header: 'Transaction Details',
      data: { id: this.activeItem.transactionId, action: this.activeItem.ciamAction },
      width: '50%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.ref.onClose.subscribe(msg => {

    });
  }
  approveTransaction(id: any) {
    this.ref = this.dialogService.open(ApproveTransactionComponent, {
      header: 'Approve Transaction Request ',
      data: { id: this.activeItem.transactionId },
      width: '40%',
      contentStyle: { 'max-height': '550px', overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg) {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
      if (msg) {
        this.gettableList();
      }
    });
  }
  rejectTransaction(id: any) {
    this.ref = this.dialogService.open(RejectTransactionComponent, {
      header: 'Reject Transaction Request',
      data: { id: this.activeItem.transactionId },
      width: '40%',
      contentStyle: { 'max-height': '550px', overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg) {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
      if (msg) {
        this.gettableList();
      }
    });
  }
  confirmMultiple1() {
    this.ref = this.dialogService.open(DeactivateComponent, {
      header: 'Deactivate User',
      data: { id: this.selectedItems },
      width: '35%',
      contentStyle: { 'max-height': '150px', overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg) {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
      this.selectedItems = [];
      if (msg) {
        this.gettableList();
      }
    });
  }
  //bulk action


  highlight(index: any) {
    this.selectedIndex = index;
  }
  mouseLeave() {
    this.hoverIndex = null;
    this.selectedIndex = null;
  }
  onRowSelect(event: any) {
    this.selectedItems.push(event.data.id);
    this.actionDisable = false;
  }
  onRowSelect1(event: any) {
    this.selectedItems1.push(event.data.id);
  }
  onRowUnselect(event: any) {
    const index: number = this.selectedItems.indexOf(event.data.id);
    if (index == 0) {
      this.actionDisable = true;
    }
    if (index !== -1) {
      this.selectedItems.splice(index, 1);
    }
  }

  filterMethod(event: any) {
    let filtered: any[] = [];
    let items: any;
    let cc;
    let query = event;
    if (query.length > 0) {
      this.tableList1.forEach(element => {
        items = element;
        if (items.transactionId?.toLowerCase().includes(query.toLowerCase()) || items.createdBy?.toLowerCase().includes(query.toLowerCase()) || items.createdOn?.toLowerCase().includes(query.toLowerCase()) || items.ciamAction?.toLowerCase().includes(query.toLowerCase()) || items.approvalStatus?.toLowerCase().includes(query.toLowerCase())) {
          filtered.push(items);
        }
      });
      this.tableList = filtered;
    }
    else {
      this.tableList = this.tableList1;
    }
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let value1: any;
      let value2: any;
      if (event.field == 'emails') {
        value1 = data1.emails[0]?.value;
        value2 = data2.emails[0]?.value;
      }
      else {
        value1 = data1[event.field];
        value2 = data2[event.field];
      }
      let result = null;
      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else {
        let res = (value1 > value2) ? 1 : 0
        result = (value1 < value2) ? -1 : res;
      }

      return (event.order * result);
    });
  }
  cancelRequest(rowData: any) {
    this.ref = this.dialogService.open(CancelRequestComponent, {
      header: 'Cancel Request',
      data: { id: this.activeItem.transactionId },
      width: '35%',
      contentStyle: { 'max-height': '150px', overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Failed') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Failed to cancel request.' });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);

      }
      else if (msg) {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'Request Canceled Successfully' });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.gettableList();
      }
    });
  }
  downloadReport(rowData: any) {
    const reqBody = {};
    reqBody["tenantId"] = this.pagesService.tenantId;
    reqBody["transactionId"] = rowData.transactionId;
    this.messageFlag = true;
    this.pagesService.downloadReport(reqBody).subscribe(
      (response: any) => {
        this.messageFlag = true;
        const blob = new Blob([response.body], { type: 'application/octet-stream' });
        let url = window.URL.createObjectURL(blob);
        let anchor = document.createElement("a");
        anchor.download = "userReport.xlsx";
        setTimeout(() => {
          this.messageFlag = true;
          this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'File Downloaded Successfully.' });
          setTimeout(() => {
            this.messageFlag = false;
          }, 3000);
        }, 6000);

        anchor.href = url;
        anchor.click();

      }
    );
  }

}
