import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PagesService } from '../pages.service';
import { MessageService, MenuItem, ConfirmationService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AssignUsersComponent } from '../AssignUsers/AssignUsers.component';
import { DeleteGroupComponent } from '../DeleteGroup/DeleteGroup.component';
@Component({
  selector: 'jhi-viewgroup',
  templateUrl: './ViewGroup.component.html',
  styleUrls: ['./ViewGroup.component.scss'],
  providers: [MessageService, DialogService, ConfirmationService],
})

export class ViewGroupComponent implements OnInit {
  ref!: DynamicDialogRef;
  ViewGroupForm = this.fb.group({
    viewGroupID: [null, []],
    displayName: [null, []],
    createdOn: [null, []],
    groupType: [null, []],
    groupStatus: [null, []],
    groupDescription: [null, []],
    ccprimesplitbutton: [null, []],
    accessDetails: [null, []],
  });

  data: any;
  messageFlag = true;
  navigation: any;
  pageloadvalues: any;
  ccprimebreadcrumbList = [{ label: `User Management` }, { label: `User Group`, routerLink: ['/pages/listgroups'] }, { label: 'View group' }];
  homeiconccprimebreadcrumb = { icon: 'pi pi-home', routerLink: ['/'] };
  htmlSrc: any;
  ccprimesplitbuttonList: any[] = [];
  html1Src: any;
  userTableList: any[] = [];
  userTablecols: any[] = [
    { field: 'firstName', header: 'First Name' },
    { field: 'lastName', header: 'Last Name' },
    { field: 'emailAddress', header: 'Email Address' },
  ];
  html2Src: any;
  html3Src: any;
  groupID = '';
  roleDetails: any[] = [];
  userTableListcount: any;
  rolecount: any;
  selectedIndex: any;
  hoverIndex: any;

  constructor(
    public dialogService: DialogService,
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    private messageService: MessageService
  ) {
    this.navigation = this.router.getCurrentNavigation();
    this.data = this.navigation?.extras?.state?.data;
    if (this.data) {
      this.setPageProperties(JSON.parse(this.data));
    }
  }

  items!: MenuItem[];
  ngOnInit(): void {
    this.onViewGroupPageLoad();
    this.getRoleList();
    this.htmlSrc = '';
    this.items = [
      {
        label: 'Edit',
        command: e => {
          this.navigateToEdit(this.ViewGroupForm.value);
        },
      },
      {
        label: 'Delete',
        command: () => {
          this.DeleteGroup();
        },
      },
      {
        label: 'Assign User',
        command: () => {
          this.show();
        },
      },
    ];
    this.html1Src = '';
    this.getuserTableList();
    this.html2Src = '';
    this.html3Src = '';
  }

  onViewGroupPageLoad() {
    this.pagesService.onViewGroupPageLoad(this.groupID).subscribe((results: any) => {
      let pagePropBind = {
        viewGroupID: results.success.result.id,
        displayName: results.success.result.displayName,
        groupType: results.success.result.type,
        createdOn: results.success.result.createdDate,
        groupDescription: results.success.result.description,
      };
      this.pageloadvalues = pagePropBind;
      this.setPageProperties(pagePropBind);
    });
  }

  highlight(index: any) {
    this.selectedIndex = index;
  }

  setPageProperties(props: any): void {
    const pageData: any = {};
    const calendarControls = ['createdOn'];
    Object.keys(props).forEach(key => {
      if (calendarControls.includes(key)) {
        pageData[key] = new Date(props[key]);
      } else {
        pageData[key] = props[key];
      }
    });
    this.groupID = pageData.id;
    this.ViewGroupForm.patchValue(pageData);
  }

  //getting users count
  getuserTableList(): void {
    this.userTableListcount = 0;
    this.pagesService.getuserTableDataViewGroup(this.groupID).subscribe(results => {
      if (results?.error?.messageCode == "CIAM 200") {
        this.userTableListcount = 0;
      }
      else if (results?.success?.messageCode == "CIAM 250") {
        const responseData = results.success.result;
        if (responseData && responseData.length > 0) {
          this.userTableList = responseData;
        }
        if (this.userTableList.length > 9) {
          this.userTableListcount = this.userTableList.length;
        }
        else if (this.userTableList.length >= 1) {
          this.userTableListcount = "0" + this.userTableList.length;
        }
      }
    });
  }

  //getting roles count
  getRoleList(): void {
    this.roleDetails = []
    this.pagesService.getRoleByGroup(this.groupID).subscribe(results => {
      if (results?.error?.messageCode == "CIAM 400") {
        this.rolecount = 0;
      }
      else if (results?.success?.messageCode == "CIAM 450") {
        const responseData = results.success.result;
        if (responseData && responseData.length > 0) {
          responseData.forEach((element: any = {}) => {
            const data = {
              label: null,
              value: null,
            };
            data.label = element.label;
            data.value = element.value;
            this.roleDetails.push(data);
          });
          this.roleDetails = [...this.roleDetails];
        }
        if (this.roleDetails.length > 9) {
          this.rolecount = this.roleDetails.length;
        }
        else if (this.roleDetails.length >= 1) {
          this.rolecount = "0" + this.roleDetails.length;
        }
      }
    });
  }

  reseteditccprimesplitbutton(): void {
    this.ViewGroupForm.reset();
  }

  resetdeactivateccprimesplitbutton(): void {
    this.ViewGroupForm.reset();
  }

  resetassignUsersccprimesplitbutton(): void {
    this.ViewGroupForm.reset();
  }

  onCcprimesplitbutton($event: UntypedFormGroup): void { }
  navigateToEdit(rowData: any): void {
    const navigationExtras: NavigationExtras = {
      state: {
        data: JSON.stringify(rowData),
      },
    };
    this.router.navigate(['/pages/editgroup'], navigationExtras);
  }

  navigateTolistgroup(): void {
    const navigationExtras: NavigationExtras = {
      state: {
        msg: 'Group Deleted Sucessfully',
      },
    };
    this.router.navigate(['/pages/listgroups'], navigationExtras);
  }

  //assign users dialog box
  show() {
    this.ref = this.dialogService.open(AssignUsersComponent, {
      data: { id: this.ViewGroupForm.value, dialogFlag: 'viewgroup' },
      header: 'Assign Users',
      width: '50%',
      contentStyle: { 'max-height': '160px', overflow: 'visible' },
      baseZIndex: 10000,
    });
    this.ref.onClose.subscribe(msg => {
      if (msg == 'error') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Failed to assign users.' });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
      else if (msg) {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
      this.groupID = this.ViewGroupForm.value.viewGroupID;
      if (msg) {
        this.getuserTableList();
      }
    });
  }

  //delete group dialog box
  DeleteGroup() {
    this.ref = this.dialogService.open(DeleteGroupComponent, {
      data: { id: this.ViewGroupForm.value.viewGroupID, name: this.ViewGroupForm.value.displayName, dialogFlag: 'group' },
      header: 'Delete Group',
      width: '345px',
      contentStyle: { 'max-height': '160px', overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Group Deleted Successfully') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.navigateTolistgroup();
      }
      else if (msg == 'Failed to Delete Group') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
    });
  }

  mouseLeave() {
    this.hoverIndex = null;
    this.selectedIndex = null;
  }
}
