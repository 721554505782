<div class="layout-content custom-container">
    <form [formGroup]="ApplicationTenantMappingForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class=" p-col-12 p-pl-0 p-pr-0">
                <div class="ui-fluid p-formgrid p-grid">
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                        <div class=" p-fluid" style="font-size: 14px;margin-bottom:10px ;">
                            Tenant Name: <span style="font-weight: 500;">{{this.tenantName}}</span>
                        </div>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                        <div class=" p-fluid   p-field    " style="font-size: 14px;">
                            <span style="font-weight: 500;">Application mapping</span>
                        </div>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class=" p-col-12 p-p-0">
                                <div class="ui-fluid p-formgrid p-grid">
                                    <div
                                        class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left p-pl-0 p-pr-0">
                                        <div class=" p-fluid   p-field    ">
                                            <div class=" p-col-12 p-pl-0 p-pr-0">
                                                <div style="display: flex;width:100%;" [class]="styleClass"
                                                    [ngStyle]="style" class="custom-picklist-with-checkbox"
                                                    [ngClass]="{'p-picklist p-component p-widget p-helper-clearfix': true,'p-picklist-responsive': responsive}">
                                                    <div class="p-picklist-list-wrapper p-picklist-source-wrapper"
                                                        [ngClass]="{'p-picklist-listwrapper-nocontrols':!showSourceControls}"
                                                        style="padding:0px;">
                                                        <div class="p-picklist-header">
                                                            <div class="p-picklist-title">Unmapped Applications
                                                                <span
                                                                    class="p-picklist-count">{{pickList1List.length}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="p-picklist-filter-container"
                                                            *ngIf="filterBy && showSourceFilter !== false">
                                                            <div class="p-picklist-filter">
                                                                <span class="p-input-icon-left" style="margin: 0px;">
                                                                    <input #sourceFilter type="text" role="textbox"
                                                                        (keydown.enter)="$event.preventDefault()"
                                                                        class="p-picklist-filter-input p-inputtext inputtextHeight p-component"
                                                                        placeholder="Search"
                                                                        (input)="filterMethodPick($event.target.value,pickList1List1,'pick1source')"
                                                                        [disabled]="disabled"
                                                                        formControlName="sourceFilter">
                                                                    <i aria-hidden="true" class="pi pi-search"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <ul #sourcelist
                                                            class="cdk-drop-list p-picklist-list p-picklist-source"
                                                            [ngClass]="{'p-picklist-highlight': listHighlightSource}"
                                                            [ngStyle]="sourceStyle">
                                                            <ng-template ngFor let-item [ngForOf]="pickList1List"
                                                                [ngForTrackBy]="sourceTrackBy || trackBy" let-i="index"
                                                                let-l="last">
                                                                <li [ngClass]="{'cdk-drag p-picklist-item p-ripple':true,'p-state-highlight':isSelected(item,selectedItemsSource), 'p-state-disabled': disabled}"
                                                                    [style.display]="isItemVisible(item, SOURCE_LIST) ? 'block' : 'none'">
                                                                    <div class="product-item"
                                                                        style="padding-top: 0rem;padding-bottom: 0rem;">
                                                                        <span class="list-check image-container"
                                                                            style="top: 0px !important;margin-top: 1px;">
                                                                            <p-checkbox name="groupname"
                                                                                [value]="isChecked" #picklistCheckboxes
                                                                                [(ngModel)]="item.isChecked"
                                                                                binary="true"
                                                                                (ngModelChange)="onItemClick($event,item,selectedItemsSource,onSourceSelect)"
                                                                                [ngModelOptions]="{standalone: true}">
                                                                            </p-checkbox>
                                                                        </span>
                                                                        <div class="product-list-detail">
                                                                            <h6>{{item.label}}</h6>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li class="p-picklist-droppoint" *ngIf="dragdrop&&l"
                                                                    (dragover)="onDragOver($event, i + 1, SOURCE_LIST)"
                                                                    (drop)="onDrop($event, i + 1, SOURCE_LIST)"
                                                                    (dragleave)="onDragLeave($event, SOURCE_LIST)"
                                                                    [ngClass]="{'p-picklist-droppoint-highlight': (i + 1 === dragOverItemIndexSource)}">
                                                                </li>
                                                            </ng-template>
                                                        </ul>
                                                    </div>
                                                    <div class="p-picklist-buttons p-picklist-transfer-buttons"
                                                        style="width:min-content;">
                                                        <button type="button" pButton pRipple icon="pi pi-angle-right"
                                                            [disabled]="disabled"
                                                            class="p-ripple p-button p-component p-button-icon-only"
                                                            (click)="moveRight()"
                                                            [disabled]="this.pagesService.tenantManagementReadOnlyFlag">

                                                            <span class="p-ink"></span>
                                                        </button>
                                                        <button type="button" pButton icon="pi pi-angle-double-right"
                                                            [disabled]="disabled"
                                                            class="p-ripple p-button p-component p-button-icon-only"
                                                            (click)="moveAllRight()"
                                                            [disabled]="this.pagesService.tenantManagementReadOnlyFlag">

                                                            <span class="p-ink"></span>
                                                        </button>
                                                        <button type="button" pButton icon="pi pi-angle-left"
                                                            [disabled]="disabled"
                                                            class="p-ripple p-button p-component p-button-icon-only"
                                                            (click)="moveLeft()"
                                                            [disabled]="this.pagesService.tenantManagementReadOnlyFlag">

                                                            <span class="p-ink"></span>
                                                        </button>
                                                        <button type="button" pButton icon="pi pi-angle-double-left"
                                                            [disabled]="disabled"
                                                            class="p-ripple p-button p-component p-button-icon-only"
                                                            (click)="moveAllLeft()"
                                                            [disabled]="this.pagesService.tenantManagementReadOnlyFlag">

                                                            <span class="p-ink"></span>
                                                        </button>
                                                    </div>
                                                    <div class="p-picklist-list-wrapper p-picklist-target-wrapper"
                                                        [ngClass]="{'p-picklist-listwrapper-nocontrols':!showTargetControls}"
                                                        style="padding:0px;">
                                                        <div class="p-picklist-header">
                                                            <div class="p-picklist-title">Mapped Applications
                                                                <span
                                                                    class="p-picklist-count">{{pickList1targetList.length}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="p-picklist-filter-container"
                                                            *ngIf="filterBy && showSourceFilter !== false">
                                                            <div class="p-picklist-filter">
                                                                <span class="p-input-icon-left" style="margin: 0px;">
                                                                    <input #sourceFilter type="text" role="textbox"
                                                                        (keydown.enter)="$event.preventDefault()"
                                                                        class="p-picklist-filter-input p-inputtext inputtextHeight p-component"
                                                                        placeholder="Search"
                                                                        (input)="filterMethodPick($event.target.value,pickList1targetList1,'pick1target')"
                                                                        [disabled]="disabled"
                                                                        formControlName="sourceFilter">
                                                                    <i aria-hidden="true" class="pi pi-search"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <ul #targetlist cdkDropList
                                                            class="cdk-drop-list p-picklist-list p-picklist-target"
                                                            [ngClass]="{'p-picklist-highlight': listHighlightTarget}"
                                                            [ngStyle]="targetStyle">
                                                            <ng-template ngFor let-item [ngForOf]="pickList1targetList"
                                                                [ngForTrackBy]="targetTrackBy || trackBy" let-i="index"
                                                                let-l="last">
                                                                <li pRipple cdkDrag role="option"
                                                                    [ngClass]="{'cdk-drag p-picklist-item p-ripple':true,'p-state-highlight':isSelected(item,selectedItemsTarget), 'p-state-disabled': disabled}"
                                                                    [style.display]="isItemVisible(item, TARGET_LIST) ? 'flex' : 'none'">
                                                                    <div class="product-item"
                                                                        style="padding-top: 0rem;padding-bottom: 0rem;">
                                                                        <span class="list-check image-container"
                                                                            style="top: 0px !important;margin-top: 1px;">
                                                                            <p-checkbox name="groupname"
                                                                                [value]="isChecked" #picklistCheckboxes
                                                                                [(ngModel)]="item.isChecked"
                                                                                binary="true"
                                                                                (ngModelChange)="onItemClick($event,item,selectedItemsTarget,onTargetSelect)"
                                                                                [ngModelOptions]="{standalone: true}">
                                                                            </p-checkbox>
                                                                        </span>
                                                                        <div class="product-list-detail">
                                                                            <h6>{{item.label}}</h6>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li class="p-picklist-droppoint" *ngIf="dragdrop&&l"
                                                                    (dragover)="onDragOver($event, i + 1, TARGET_LIST)"
                                                                    (drop)="onDrop($event, i + 1, TARGET_LIST)"
                                                                    (dragleave)="onDragLeave($event, TARGET_LIST)"
                                                                    [ngClass]="{'p-picklist-droppoint-highlight': (i + 1 === dragOverItemIndexTarget)}">
                                                                </li>
                                                            </ng-template>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right p-pl-0 p-pr-0 p-mb-1">
                                        <div class="btnwrap">
                                            <button pButton type="button" class="p-button-brand2 p-button p-mr-2"
                                                (click)="onClose(ApplicationTenantMappingForm);" label="Close">
                                            </button>
                                        </div>
                                        <div class="btnwrap">
                                            <button pButton type="button" label="Secondary"
                                                class="p-button-primary p-button" (click)="onSave()" label="Save"
                                                [disabled]="this.pagesService.tenantManagementReadOnlyFlag || btn"></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>