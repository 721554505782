import { NgModule, APP_INITIALIZER } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CiamSharedModule } from "app/shared/shared.module";
import { CiamCoreModule } from "app/core/core.module";
import { CiamAppRoutingModule } from "./app-routing.module";
import { CiamHomeModule } from "./home/home.module";
// jhipster-needle-angular-add-module-import JHipster will add new module here
import { MainComponent } from "./layouts/main/main.component";
import { NavbarComponent } from "./layouts/navbar/navbar.component";
import { HeaderComponent } from "./layouts/header/header.component";
import { FooterComponent } from "./layouts/footer/footer.component";
import { PageRibbonComponent } from "./layouts/profiles/page-ribbon.component";
import { ActiveMenuDirective } from "./layouts/navbar/active-menu.directive";
import { ErrorComponent } from "./layouts/error/error.component";
import { LocalStorageService, SessionStorageService } from "ngx-webstorage";
import { AuthInterceptor } from "./blocks/interceptor/auth.interceptor";
import { LoadingIndicatorService } from "./loading-indicator.service";
import { NgxSpinnerModule } from "ngx-spinner";
import { initializer } from "./keycloak-init";
import { KeycloakAngularModule, KeycloakService } from "keycloak-angular";
import { MenubarModule } from "primeng/menubar";
import { InputSwitchModule } from "primeng/inputswitch";
import { SplitButtonModule } from "primeng/splitbutton";
import { TooltipModule } from "primeng/tooltip";
import { SlideMenuModule } from "primeng/slidemenu";
import { EmptyRouteComponent } from "./empty-route/empty-route.component";
import { APP_BASE_HREF } from "@angular/common";
import { PagesModule } from "./pages/pages.module";
import { AsseturlpipePipe } from "./asseturlpipe.pipe";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { PaginatorModule } from "primeng/paginator";
import { TabViewModule } from "primeng/tabview";
import { CardModule } from "primeng/card";
import { SelectButtonModule } from "primeng/selectbutton";
import { TieredMenuModule } from "primeng/tieredmenu";
import { ButtonModule } from "primeng/button";
import { DynamicDialogModule } from "primeng/dynamicdialog";
import { TableModule } from "primeng/table";
import { PanelMenuModule } from "primeng/panelmenu";
import { ToastModule } from "primeng/toast";
import { ChipsModule } from "primeng/chips";
import { ChipModule } from "primeng/chip";
import { SidebarModule } from "primeng/sidebar";
import { BadgeModule } from 'primeng/badge';
import { BreadcrumbModule } from "primeng/breadcrumb";
import { PasswordModule } from 'primeng/password';
import { FileUploadModule } from 'primeng/fileupload';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TreeSelectModule } from 'primeng/treeselect';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DividerModule } from "primeng/divider";
import { TagModule } from 'primeng/tag';
import { CanDeactivateGuard } from "./guards/can-activate.guard";
import { EntitlementManagementLoggingService } from "./pages/ManageEntitlements/ManageEntitlement-logging.service";
import { EditorModule } from 'primeng/editor';
import { RoleManagementLoggingService } from "./pages/ManageRoles/ManageRoles-logging.service";
import { ApprovalManagementLoggingService } from "./pages/ApprovalListing/approval-management-logging.service";
import { UserManagementLoggingService } from "./pages/EditUser/user-management-logging.service";
import { AccountUserMappingLogging } from "./pages/AccountUserMapping/AccountUserMapping-logging";
import { TreeTableModule } from 'primeng/treetable';
@NgModule({
	imports: [
		NgxSpinnerModule,
		SidebarModule,
		BrowserAnimationsModule,
		CiamSharedModule,
		CiamCoreModule,
		CiamHomeModule,
		KeycloakAngularModule,
		MenubarModule,
		InputSwitchModule,
		SplitButtonModule,
		TooltipModule,
		SlideMenuModule,
		// jhipster-needle-angular-add-module JHipster will add new module here
		CiamAppRoutingModule,
		OverlayPanelModule,
		PaginatorModule,
		TabViewModule,
		CardModule,
		SelectButtonModule,
		PagesModule,
		TieredMenuModule,
		ButtonModule,
		TableModule,
		DynamicDialogModule,
		PanelMenuModule,
		ToastModule,
		ChipsModule,
		ChipModule,
		BreadcrumbModule,
		PasswordModule,
		BadgeModule,
		FileUploadModule,
		HttpClientModule,
		TreeSelectModule,
		AvatarModule,
		AvatarGroupModule,
		RadioButtonModule,
		DividerModule,
		TagModule,
		EditorModule,
		TreeTableModule
	],
	declarations: [
		MainComponent,
		NavbarComponent,
		ErrorComponent,
		PageRibbonComponent,
		ActiveMenuDirective,
		FooterComponent,
		HeaderComponent,
		EmptyRouteComponent,
		AsseturlpipePipe
	],
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: initializer,
			multi: true,
			deps: [KeycloakService],

		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true,
			deps: [
				LocalStorageService,
				SessionStorageService,
				LoadingIndicatorService,
				RoleManagementLoggingService,
				EntitlementManagementLoggingService,
				ApprovalManagementLoggingService,
				UserManagementLoggingService,
				AccountUserMappingLogging
			],
		},

		{ provide: APP_BASE_HREF, useValue: "/ciam/" },
		CanDeactivateGuard
	],
	bootstrap: [MainComponent],
})
export class CiamAppModule { }
