import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PagesService } from '../pages.service';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
@Component({
  selector: 'jhi-deleteapplicationresource',
  templateUrl: './DeleteHostAttributeGroup.component.html',
  styleUrls: ['./DeleteHostAttributeGroup.component.scss'],
})
export class DeleteHostAttributeGroupComponent implements OnInit {
  DeleteHostAttributeGroupForm = this.fb.group({
  });
  pageloadvalues: any;
  htmlSrc: any;
  groupId: any;
  appId: any;
  groupname: any;
  assignedAttributeCount: any;
  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    private messageService: MessageService,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.DeleteHostAttributeGroupForm.patchValue(JSON.parse(data));
    }
  }
  ngOnInit(): void {
    this.groupname = this.config?.data?.groupname;
    this.assignedAttributeCount = this.config?.data?.assignedAttributeCount;
  }

  onClickNo($event: UntypedFormGroup): void {
    this.ref.close();
  }

  /**
   * Delete attribute&group in Attribute&group tab  of attributes and group
   */
  DeleteHostAttributeGroup() {
    const reqBody = {};
    reqBody['attributeGroupId'] = this.config?.data?.grpid;
    reqBody['applicationId'] = this.config?.data?.id;
    const formValues = reqBody;
    this.pagesService.deleteHostAttributeGroup(formValues).subscribe({
      next: (results: any) => {
        this.ref.close("Attribute Group Deleted Successfully");
      },
      error: () => {
        this.ref.close("Failed to Delete Attribute Group");
      }
    });
  }

}