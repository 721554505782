import { Component, ContentChildren, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Message, MessageService, PrimeTemplate, FilterService } from 'primeng/api';
import { PagesService } from '../pages.service';
import { CreateResourcesComponent } from '../CreateResources/CreateResources.component';
import { EditApplicationResourceComponent } from '../EditApplicationResource/EditApplicationResource.component';
import { DeleteApplicationResourceComponent } from '../DeleteApplicationResource/DeleteApplicationResource.component';
import { DomHandler } from "primeng/dom";
import { ObjectUtils } from '../object-utlis.service';
@Component({
  selector: 'jhi-applicationresources',
  templateUrl: './ApplicationResources.component.html',
  styleUrls: ['./ApplicationResources.component.scss'],
  providers: [MessageService, DialogService, FilterService, ObjectUtils],
})
export class ApplicationResourcesComponent implements OnInit {
  ref!: DynamicDialogRef;
  ApplicationResourcesForm = this.fb.group({
    tabview: [0],
    select: [null, [Validators.required]],
    select1: [null, [Validators.required]],
    textField: [null, []],
    select2: [null, [Validators.required]],
    select3: [null, [Validators.required]],
    select4: [null, [Validators.required]],
    pickList: [null, []],
    user: [null, []],
    sourceFilter: [null, []],
  });
  msgs: Message[] = [];
  pageloadvalues: any;
  ccprimebreadcrumbList = [
    { label: `Authorization` },
    { label: `Application resources` },
  ]
  homeiconccprimebreadcrumb = { icon: 'pi pi-home', routerLink: ['/'] };
  htmlSrc: any;
  selectList: any[] = [];
  select1List: any[] = [];
  searchList: any[] = [];
  tableList: any[] = [];
  tableList1: any[] = [];
  table: any[] = [];
  tablecols: any[] = [{ field: "resourceName", header: "Resource name" },
  { field: "endpointUrl", header: "Endpoint URL" },
  { field: "method", header: "Method" },
  ];
  select2List: any[] = [];
  select3List: any[] = [];
  select4List: any[] = [];
  pickListList: any[] = [];
  pickListList1: any[] = [];
  errorFlag: boolean = true;
  pickListtargetList: any[] = [];
  pickListtargetList1: any[] = [];
  messageFlag = true;
  messageFlag1 = true;
  detail: string = '';
  flag1 = true;
  appId: any;
  compId: any;
  roleId: any;
  exMapList: any[] = [];
  btn = true;
  mapList: any[] = [];
  mList: any[] = [];
  activeItem: any;
  componentId: any;
  appId1: any;
  activeItem1: any;
  flag2 = true;
  selectedIndex: any;
  btn1 = true;
  //new picklist
  isChecked: boolean = false;
  @ViewChildren("picklistCheckboxes") checkboxes: QueryList<ElementRef>;
  @Input() sourceHeader: string;
  @Input() targetHeader: string;
  @Input() responsive: boolean;
  @Input() filterBy: string = "brand";
  @Input() trackBy: Function = (index: number, item: any) => item;
  @Input() sourceTrackBy: Function;
  @Input() targetTrackBy: Function;
  @Input() showSourceFilter: boolean = true;
  @Input() showTargetFilter: boolean = true;
  @Input() metaKeySelection: boolean = true;
  @Input() style: any;
  @Input() styleClass: string;
  @Input() sourceStyle: any;
  @Input() targetStyle: any;
  @Input() showSourceControls: boolean = true;
  @Input() showTargetControls: boolean = true;
  @Input() sourceFilterPlaceholder: string;
  @Input() targetFilterPlaceholder: string;
  @Input() disabled: boolean = false;
  @Output() onMoveToSource: EventEmitter<any> = new EventEmitter();
  @Output() onMoveAllToSource: EventEmitter<any> = new EventEmitter();
  @Output() onMoveAllToTarget: EventEmitter<any> = new EventEmitter();
  @Output() onMoveToTarget: EventEmitter<any> = new EventEmitter();
  @Output() onSourceSelect: EventEmitter<any> = new EventEmitter();
  @Output() onTargetSelect: EventEmitter<any> = new EventEmitter();
  @ViewChild('sourcelist') listViewSourceChild: ElementRef;
  @ViewChild('targetlist') listViewTargetChild: ElementRef;
  @ViewChild('sourceFilter') sourceFilterViewChild: ElementRef;
  @ViewChild('targetFilter') targetFilterViewChild: ElementRef;
  @ContentChildren(PrimeTemplate) templates: QueryList<any>;
  public itemTemplate: TemplateRef<any>;
  public visibleOptionsSource: any[];
  public visibleOptionsTarget: any[];
  selectedItemsSource: any[] = [];
  selectedItemsTarget: any[] = [];
  reorderedListElement: any;
  movedUp: boolean;
  movedDown: boolean;
  itemTouched: boolean;
  filterValueSource: string;
  filterValueTarget: string;
  listHighlightTarget: boolean;
  listHighlightSource: boolean;
  readonly SOURCE_LIST = -1;
  readonly TARGET_LIST = 1;
  //end
  constructor(private router: Router, protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public dialogService: DialogService,
    public pagesService: PagesService,
    private messageService: MessageService) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.ApplicationResourcesForm.patchValue(JSON.parse(data));
    }

  }

  ngOnInit(): void {
    this.getApplicationList();
    this.getMappingApplicationList();
    this.ApplicationResourcesForm.get('select1').disable();
    this.ApplicationResourcesForm.get('select3').disable();
    this.ApplicationResourcesForm.get('select4').disable();
  }

  //get Application list API -dropdown
  getApplicationList(): void {
    this.pagesService.getApplicationListMapping().subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.selectList.push(data);
        });
        this.selectList = [...this.selectList];
      }
    });
  }

  // get component list API- dropdown
  getComponent(): void {
    this.btn1 = true;
    this.ApplicationResourcesForm.get('select1').enable();
    this.appId = this.ApplicationResourcesForm.value.select;
    const compData = { appId: this.appId };
    this.pagesService.getComponentApplication(compData).subscribe(results => {
      this.ApplicationResourcesForm.controls.select1.reset();
      this.select1List = [];
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.select1List.push(data);
        });
        this.select1List = [...this.select1List];
      }
    });
  }

  /**
   * RESOURCE MAPPING
   */

  //get application roles list API - dropdown
  getRoles(): void {
    this.ApplicationResourcesForm.get('select4').enable();
    this.flag1 = true;
    const appRole = this.ApplicationResourcesForm.value.select2;
    this.pagesService.getApplicationRole(appRole).subscribe(results => {
      this.select4List = [];
      this.ApplicationResourcesForm.controls.select4.reset();
      const responseData = results.success.result;
      if (responseData.length == 0) {
        this.flag1 = true;
      }

      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.select4List.push(data);
        });
        this.select4List = [...this.select4List];
      }
    });
  }

  ngAfterContentInit() {
    this.templates.forEach((item) => {
      this.itemTemplate = item.template;
    });
  }

  ngAfterViewChecked() {
    if (this.movedUp || this.movedDown) {
      let listItems = DomHandler.find(this.reorderedListElement, 'li.p-state-highlight');
      let listItem;
      if (this.movedUp)
        listItem = listItems[0];
      else
        listItem = listItems[listItems.length - 1];

      DomHandler.scrollInView(this.reorderedListElement, listItem);
      this.movedUp = false;
      this.movedDown = false;
      this.reorderedListElement = null;
    }
  }

  onItemClick(event, item: any, selectedItems: any[], callback: EventEmitter<any>) {
    if (this.disabled) {
      return;
    }

    let index = this.findIndexInSelection(item, selectedItems);
    let selected = (index != -1);
    if (selected) {
      selectedItems.splice(index, 1);
    }
    else {
      selectedItems.push(item);
    }

    callback.emit({ originalEvent: event, items: selectedItems });

    this.itemTouched = false;
  }

  filterMethodPick(event: any, list: any, pickFlag: any) {
    let filtered: any[] = [];
    let items: any;
    let query = event;
    if (query.length > 0) {
      list.forEach(element => {
        items = element;
        if (items.label?.toLowerCase().includes(query.toLowerCase())) {
          filtered.push(items);
        }
      });
      if (pickFlag == 'pick1source') {
        this.pickListList = filtered;
      }
      else if (pickFlag == 'pick1target') {
        this.pickListtargetList = filtered;
      }

    }
    else if (pickFlag == 'pick1source') {
      this.pickListList = [];
      this.pickListList = [...this.pickListList1];
    }
    else if (pickFlag == 'pick1target') {
      this.pickListtargetList = [];
      this.pickListtargetList = [...this.pickListtargetList1];
    }
  }

  isItemVisible(item: any, listType: number): boolean {
    if (listType == this.SOURCE_LIST) {
      return this.isVisibleInList(this.visibleOptionsSource, item, this.filterValueSource);
    }
    else {
      return this.isVisibleInList(this.visibleOptionsTarget, item, this.filterValueTarget);
    }
  }

  isVisibleInList(data: any[], item: any, filterValue: string): boolean {
    if (filterValue?.trim().length) {
      data.forEach(element => {
        if (item == element) {
          return true;
        }
      });
    }
    else {
      return true;
    }
  }

  moveRight() {
    if (this.selectedItemsSource?.length) {
      this.selectedItemsSource.forEach(element => {
        let selectedItem = element;
        if (this.findIndexInList(selectedItem, this.pickListtargetList) == -1) {
          this.pickListtargetList.push(this.pickListList.splice(this.findIndexInList(selectedItem, this.pickListList), 1)[0]);
          this.pickListtargetList1.push(this.pickListList1.splice(this.findIndexInList(selectedItem, this.pickListList1), 1)[0]);
          this.btn = false;
        }
      });
      this.isChecked = false;
      this.onMoveToTarget.emit({
        items: this.selectedItemsSource
      });
      this.selectedItemsSource = [];
    }
    this.checkboxes.forEach((element) => {
      this.pickListtargetList.filter(z => z).forEach((x) => (x.isChecked = false));
    });
  }

  moveAllRight() {
    if (this.pickListList) {
      let movedItems = [];
      this.pickListtargetList1 = [];
      for (let i = 0; i < this.pickListList.length && this.isItemVisible(this.pickListList[i], this.SOURCE_LIST); +i) {
        let removedItem = this.pickListList.splice(i, 1)[0];
        this.pickListList1.splice(this.findIndexInList(removedItem, this.pickListList1), 1);
        this.pickListtargetList.push(removedItem);
        movedItems.push(removedItem);
        this.btn = false;
      }
      this.onMoveToTarget.emit({
        items: movedItems
      });

      this.onMoveAllToTarget.emit({
        items: movedItems
      });

      this.selectedItemsSource = [];
      this.pickListtargetList1 = [...this.pickListtargetList];
    }
    this.checkboxes.forEach((element) => {
      this.pickListtargetList.filter(z => z).forEach((x) => (x.isChecked = false));
    });
  }

  moveLeft() {
    if (this.selectedItemsTarget?.length) {
      this.selectedItemsTarget.forEach(element => {
        let selectedItem = element;
        if (this.findIndexInList(selectedItem, this.pickListList) == -1) {
          this.pickListList.push(this.pickListtargetList.splice(this.findIndexInList(selectedItem, this.pickListtargetList), 1)[0]);
          this.pickListList1.push(this.pickListtargetList1.splice(this.findIndexInList(selectedItem, this.pickListtargetList1), 1)[0]);
          this.btn = false;
        }
      });
      this.isChecked = false;
      this.onMoveToSource.emit({
        items: this.selectedItemsTarget
      });

      this.selectedItemsTarget = [];
    }
    this.checkboxes.forEach((element) => {
      this.pickListList.filter(z => z).forEach((x) => (x.isChecked = false));
    });
  }

  moveAllLeft() {
    if (this.pickListtargetList) {
      let movedItems = [];
      this.pickListList1 = [];
      for (let i = 0; i < this.pickListtargetList.length && this.isItemVisible(this.pickListtargetList[i], this.TARGET_LIST); +i) {
        let removedItem = this.pickListtargetList.splice(i, 1)[0];
        this.pickListtargetList1.splice(this.findIndexInList(removedItem, this.pickListtargetList1), 1);
        this.pickListList.push(removedItem);
        movedItems.push(removedItem);
        this.btn = false;
      }
      this.onMoveToSource.emit({
        items: movedItems
      });
      this.onMoveAllToSource.emit({
        items: movedItems
      });
      this.selectedItemsTarget = [];
      this.pickListList1 = [...this.pickListList];
    }
    this.checkboxes.forEach((element) => {
      this.pickListList.filter(z => z).forEach((x) => (x.isChecked = false));
    });
  }

  isSelected(item: any, selectedItems: any[]) {
    return this.findIndexInSelection(item, selectedItems) != -1;
  }

  findIndexInSelection(item: any, selectedItems: any[]): number {
    return this.findIndexInList(item, selectedItems);
  }

  findIndexInList(item: any, list: any): number {
    let index: number = -1;

    if (list) {
      for (let i = 0; i < list.length; i++) {
        if (list[i] == item) {
          index = i;
          break;
        }
      }
    }

    return index;
  }

  handleChangetabview(e: any): void {
    this.ApplicationResourcesForm.patchValue({ "tabview": e.index });
  }

  onCcprimebutton1($event: UntypedFormGroup): void {
  }

  select() {
    this.ApplicationResourcesForm.controls.select1.reset();
    this.flag2 = true;
  }

  getMappingApplicationList(): void {
    this.pagesService.getApplicationListMapping().subscribe(results => {
      this.flag1 = true;
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.select2List.push(data);
        });
        this.select2List = [...this.select2List];
      }
    });
  }

  getMappingComponent(): void {
    this.ApplicationResourcesForm.get('select3').enable();
    this.ApplicationResourcesForm.get('select4').disable();
    this.flag1 = true;
    this.select3List = [];
    this.select4List = [];
    this.ApplicationResourcesForm.controls.select3.reset();
    this.ApplicationResourcesForm.controls.select4.reset();
    this.appId = this.ApplicationResourcesForm.value.select2;
    const data1 = { appId: this.appId };
    this.pagesService.getComponentApplication(data1).subscribe(results => {
      this.select3List = [];
      const responseData = results.success.result;
      if (responseData.length == 0) {
        this.flag1 = true;
      }
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.select3List.push(data);
        });
        this.select3List = [...this.select3List];
      }
    });
  }

  getMappedDetails() {
    this.flag1 = true;
    this.ApplicationResourcesForm.controls.sourceFilter.reset();
    this.appId1 = this.ApplicationResourcesForm.value.select2;
    this.compId = this.ApplicationResourcesForm.value.select3;
    this.roleId = this.ApplicationResourcesForm.value.select4;
    const resData = { appId: this.appId1, compId: this.compId, roleId: this.roleId };

    this.pickListList1 = [];
    this.pickListtargetList1 = [];
    this.pagesService.getResourceMappedAttributes(resData).subscribe(results => {
      const responseData = results?.success?.result;
      this.pickListtargetList = [];
      this.exMapList = [];
      if (responseData && responseData.length > 0) {
        this.flag1 = false;
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.pickListtargetList.push(data);
          this.exMapList.push(data.value);
        });
        this.pickListtargetList = [...this.pickListtargetList];
        this.pickListtargetList1 = [...this.pickListtargetList];
        this.exMapList = [...this.exMapList];
      }

    });

    this.pagesService.getResourceUnMappedAttributes(resData).subscribe(results => {
      const responseData = results?.success?.result;
      this.pickListList = [];

      if (responseData && responseData.length > 0) {
        this.flag1 = false;
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.pickListList.push(data);
        });
        this.pickListList = [...this.pickListList];
        this.pickListList1 = [...this.pickListList];

      }
    });
  }

  getExMapUnMapList() {
    this.exMapList = [];
    this.appId1 = this.ApplicationResourcesForm.value.select2;
    this.compId = this.ApplicationResourcesForm.value.select3;
    this.roleId = this.ApplicationResourcesForm.value.select4;
    const resMappeddata = { appId: this.appId1, compId: this.compId, roleId: this.roleId };

    this.pagesService.getResourceMappedAttributes(resMappeddata).subscribe(results => {
      const responseData = results?.success?.result;
      if (responseData && responseData.length > 0) {
        this.exMapList = [];
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.exMapList.push(data.value);
        });
        this.exMapList = [...this.exMapList];
      }
    });
  }

  // saving the final map/unmap resource list
  onSave() {
    this.messageFlag = true;
    this.messageFlag1 = true;
    this.appId1 = this.ApplicationResourcesForm.value.select2;
    this.compId = this.ApplicationResourcesForm.value.select3;
    this.roleId = this.ApplicationResourcesForm.value.select4;
    const saveData = { appId: this.appId1, compId: this.compId, roleId: this.roleId };

    this.mapList = this.pickListtargetList1;
    this.mList = [];
    this.mapList.forEach((element: any = {}) => {
      const data = {
        label: null,
        value: null,
      };
      data.label = element.label;
      data.value = element.value;
      this.mList.push(data.value);
    });
    this.mList = [...this.mList];
    const assignList = this.mList.filter(x => !this.exMapList.includes(x));
    const deassignList = this.exMapList.filter(x => !this.mList.includes(x));
    if (assignList.length == 0 && deassignList.length == 0) {
      this.messageFlag = true;
      this.messageFlag1 = true;
      this.btn = true;
      this.messageService.add({ key: 'myKey2', severity: 'info', detail: 'No Resources To Map.' });
      setTimeout(() => {
        this.messageFlag1 = false;
      }, 3000);
    }
    else {
      const reqBody = {};
      reqBody['mapList'] = assignList;
      reqBody['unmapList'] = deassignList;
      const formValues = reqBody;
      this.pagesService.onSaveResourceMappingMapping(formValues, saveData).subscribe(
        {
          next: results => {
            this.messageFlag = true;
            this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'Application resource mapping/unmapping saved successfully.' });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
            this.btn = true;
            this.getExMapUnMapList();
          },
          error: () => {
            this.messageFlag = true;
            this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Application resource mapping/unmapping failed.' });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
          }
        });
    }
  }

  /**
   * Resource Listing Tab
   */

  //getting application resource list from the API
  ResourceListing(): void {
    this.btn1 = false;
    this.flag2 = false;
    this.appId = this.ApplicationResourcesForm.value.select;
    this.componentId = this.ApplicationResourcesForm.value.select1;
    const data = { appId: this.appId, componentId: this.componentId };
    this.pagesService.getResourceListing(data).subscribe(results => {
      this.tableList = [];
      this.tableList1 = [];
      const responseData = results.success.result.records;
      if (responseData && responseData.length > 0) {
        this.tableList = responseData;
        this.tableList1 = responseData;
        this.errorFlag = false;
      }
      else {
        this.errorFlag = true;
      }
    });
  }

  //Create resource functionality -dialog box
  createResource() {
    this.ref = this.dialogService.open(CreateResourcesComponent, {
      data: { appId: this.appId, comId: this.componentId },
      header: 'Create Resource',
      width: '70%',
      contentStyle: { 'max-height': '50%', overflow: 'visible', 'border-radius': '4px' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Resource Created Successfully') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.ResourceListing();
      }
      else if (msg == 'Resource Creation Failed' || msg == 'Resource name already exist') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
    });
  }

  // Edit resource functionality -Dialog box
  editResource() {
    this.ref = this.dialogService.open(EditApplicationResourceComponent, {
      data: { appId: this.appId, comId: this.componentId, ep: this.activeItem1.resourceName, desc: this.activeItem1.endPointUrl, method1: this.activeItem1.method, endID: this.activeItem1.endpointId, roleRequired: this.activeItem1.applicationRoleRequired },
      header: 'Edit Resource',
      width: '70%',
      contentStyle: { 'max-height': '300px', overflow: 'visible' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Resource Edited Successfully') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.ResourceListing();
      }
      else if (msg == 'Resource Edit Failed' || msg == 'Resource name already exist') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }

    });
  }

  // delete resource functionality -dialog box
  deleteResource() {
    this.ref = this.dialogService.open(DeleteApplicationResourceComponent, {
      data: { id: this.activeItem.resourceName, appId: this.appId, comId: this.componentId, eID: this.activeItem.endpointId },
      header: 'Delete Resource',
      width: '40%',
      contentStyle: { 'max-height': '300px', overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Resource Deleted Successfully') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        this.ResourceListing();
      }
      else if (msg == 'Resource Deletion Failed') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
    });
  }

  highlight(index: any) {
    this.selectedIndex = index;
  }

  mouseLeave() {
    this.selectedIndex = null;
  }

  filterMethod(event: any) {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: any[] = [];
    let items: any;
    let cc;
    let query = event;
    if (query.length > 0) {
      this.tableList1.forEach(element => {
        items = element;
        if (items.endPointUrl?.toLowerCase().includes(query.toLowerCase()) || items.resourceName?.toLowerCase().includes(query.toLowerCase()) || items.method?.toLowerCase().includes(query.toLowerCase())) {
          filtered.push(items);
        }
      });
      this.tableList = filtered;
    }
    else {
      this.tableList = this.tableList1;
    }
  }

}