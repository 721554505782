<div style="background-color: #F6F8FA;" (click)="mouseLeave()">
    <div class="p-d-flex p-jc-end">
        <p-messages key="myKey1" id="import" class="custom-alert-box" *ngIf="this.messageFlag"></p-messages>
        <p-messages severity="info" class="custom-msg" *ngIf="this.messageFlag1" style="position: fixed;
    width: 380px;
    margin-top: 5px;margin-right: 5px;">
            <ng-template pTemplate>
                <span class="p-message-icon pi pi-info-circle custom-invite-msg"></span>
                <span class="p-message-detail">Action successful, User Import is in progress,<br>Request Id:
                    {{this.reqId}},<br>Refer to 'Check Status' screens for the update.</span>
                <button (click)="clearmsg()" type="button" pripple="" class="p-message-close p-link">
                    <i aria-hidden="true" class="p-message-close-icon pi pi-times"></i>
                </button>
            </ng-template></p-messages>
    </div>

    <div class="p-col-fixed p-mt-1 p-ml-1 p-pr-0 custom-breadcrumb">
        <p-breadcrumb [model]="ccprimebreadcrumbList" [home]="homeiconccprimebreadcrumb">
        </p-breadcrumb>
    </div>
    <form class="layout-content custom-form-layout" [formGroup]="UserListingForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid custom-checkbox-color">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                <div class="p-grid p-mb-1">
                    <span class="p-col p-p-0">
                        <h5 class="custom-head1">Users</h5>
                    </span>
                    <div class="p-col-fixed p-text-right p-mt-2 p-mb-2 p-p-0">
                        <div class="btnwrap" *ngIf="!this.errorFlag" style="margin-right: 12px;">
                            <button pButton type="button" pButton label="Revoke User"
                                class="p-button-brand2 p-button-sm" (click)="showRevokeUser();"
                                [disabled]="!actionDisable" *ngIf="revokeUserBtnFlag">
                            </button>
                        </div>
                        <span class="btnwrap" *ngIf="!this.errorFlag" style="margin-right: 12px;">
                            <button pButton type="button" label="Bulk Action" class="p-button-secondary p-button-sm"
                                (click)="menu2.toggle($event);" [disabled]="!actionDisable" icon="pi pi-angle-down"
                                iconPos="right">
                            </button>
                            <p-overlayPanel #menu2 [dismissable]="true" [showCloseIcon]="false"
                                [style]="{width: '104px',height:'55px',top:'132px !important',overflow: 'hidden' }">
                                <ng-template pTemplate>

                                    <div class="custom-hover" style="width:106px; margin-left: -25%;margin-top: -14%;">
                                        <button pButton type="button" label="Import User"
                                            class="p-button-brand2 p-button-info p-mr-2 p-button-sm"
                                            style="width:104px;" (click)="showImportUser()"
                                            [disabled]="this.pagesService.userManagementReadOnlyFlag"></button>
                                    </div>
                                    <div class="custom-hover" style="width:104px; margin-left: -21%; ">
                                        <button pButton type="button" label="Check Status"
                                            class="p-button-brand2 p-button-info p-mr-2 p-button-sm"
                                            style="width:104px;" (click)="showBulkActionCheckStatus()"></button>
                                    </div>
                                </ng-template>
                            </p-overlayPanel>
                        </span>
                        <span class="btnwrap" *ngIf="!this.errorFlag" style="margin-right: 12px;">
                            <button pButton type="button" label="Invite User" class="p-button-secondary p-button-sm"
                                (click)="show();"
                                [disabled]="!actionDisable ||  this.pagesService.userManagementReadOnlyFlag"
                                *ngIf="inviteUserBtnFlag">
                            </button>
                        </span>
                        <span class="btnwrap" *ngIf="!this.errorFlag">
                            <button pButton type="button" label="Add New User" class="p-button-primary p-button-sm"
                                (click)="onCcprimebutton1(UserListingForm);"
                                [disabled]="!actionDisable || this.pagesService.userManagementReadOnlyFlag"
                                *ngIf="createUserBtnFlag">
                            </button>
                        </span>
                    </div>
                </div>
                <div class="card custom-card p-mb-6">
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left p-mt-2 p-p-0">
                        <div class=" p-fluid   p-field    ">
                            <div class="p-fluid p-formgrid p-grid">
                                <div class="p-col-12 p-p-0">
                                    <div *ngIf="this.errorFlag" class="errorFlag">
                                        <label class="c-icons error-icon"></label><br>
                                        <label class="e-error">No Users found</label><br>
                                        <br>
                                        <div class="btnwrap p-mr-2">
                                            <button pButton type="button" pButton label="Invite User"
                                                class="p-button-secondary" (click)="show();"
                                                [disabled]="!actionDisable || this.pagesService.userManagementReadOnlyFlag">
                                            </button>
                                        </div>
                                        <div class="btnwrap">
                                            <button pButton type="button" pButton label="Add New User"
                                                (click)="onCcprimebutton1(UserListingForm);"
                                                class="p-button-outlined p-button-danger custom-button-padding"
                                                [disabled]="this.pagesService.userManagementReadOnlyFlag">
                                            </button>
                                        </div>
                                    </div>
                                    <div class="ui-fluid p-formgrid p-grid" *ngIf="!this.errorFlag">
                                        <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                                            <div class=" p-fluid   p-field    ">
                                                <span class=" p-input-icon-left">
                                                    <input type="text" (keydown.enter)="$event.preventDefault()"
                                                        id="textField_id" name="textField_name"
                                                        formControlName="textField" pInputText placeholder="Search"
                                                        class="p-inputtext inputtextHeight custom-listings custom-inputtextHeight"
                                                        (input)="UserFilterMethod($event)" />
                                                    <i aria-hidden="true" class="pi pi-search"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-sm-12 p-md-12 p-lg-2 p-xl-2 p-offset-undefined left">
                                        </div>
                                        <div class="p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6 p-offset-undefined p-right">
                                            <p-chip class="custom-status-chip" *ngIf="showChip" [removable]="true"
                                                (onRemove)="removeFilter();callUserList();">{{activeStatus}}
                                            </p-chip>
                                            <div class="btnwrap customn-filter-text p-mr-2"
                                                style="vertical-align: bottom;">
                                                <button pButton type="button" pButton
                                                    class="p-button-secondary p-button-brand2 filterItemBtn custom-listing"
                                                    (click)="menu.toggle($event)" appendTo="body">
                                                    <i aria-hidden="true" class="e-icons status-icon"></i>
                                                </button>
                                                <p-tieredMenu #menu [model]="filterItems" [popup]="true"
                                                    appendTo="body"></p-tieredMenu>
                                            </div>
                                            <div class="btnwrap">
                                                <button pButton type="button" label="Action" iconPos="right"
                                                    icon="pi pi-angle-down" class="p-button-secondary action-btn"
                                                    (click)="menu1.toggle($event);checkItemOption()"
                                                    [disabled]="actionDisable || this.pagesService.userManagementReadOnlyFlag">
                                                </button>
                                                <p-tieredMenu #menu1 [model]="items" [popup]="true"
                                                    appendTo="body"></p-tieredMenu>
                                                <p-confirmDialog [style]="{width: '45vw'}" [baseZIndex]="10000"
                                                    acceptButtonStyleClass='p-button-brand2'
                                                    rejectButtonStyleClass='p-button-secondary p-button-brand2'
                                                    [baseZIndex]="10000">
                                                </p-confirmDialog>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                                            <div class="  p-field    ">
                                                <div class="mobilefriendlytable" responsiveLayout="stack"
                                                    [ngClass]="{'content-section implementation': true}">
                                                    <p-table #tableid [value]="tableList" name="table"
                                                        [columns]="tablecols" styleClass="p-datatable-gridlines"
                                                        [rows]="10" [rowsPerPageOptions]="[10,25,50]"
                                                        selectionMode="Multiple" sortMode="single" [scrollable]="true"
                                                        [(selection)]="selectedRecords"
                                                        (onRowSelect)="onRowSelect($event)"
                                                        (onRowUnselect)="onRowUnselect($event)"
                                                        (onHeaderCheckboxToggle)="onSelectAllUsers();"
                                                        (onFilter)="columnFilter($event)"
                                                        (sortFunction)="sortData($event)" [customSort]="true">
                                                        <ng-template pTemplate="header">
                                                            <tr>
                                                                <th id="headercheck" class="headerCheck"
                                                                    *ngIf="checkDisable" style="max-width: 4%;"
                                                                    pFrozenColumn>
                                                                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                                                </th>

                                                                <th pSortableColumn="fullName" scope="col"
                                                                    id="fullNamePosition_id"
                                                                    style="min-width: 250px;max-width: 250px;width: 250px;"
                                                                    pFrozenColumn>
                                                                    <div class="p-d-flex p-jc-between p-ai-center">
                                                                        Full Name
                                                                        <i class="p-sortable-column-icon icon-sort"
                                                                            ariaLabel="Activate to sort"
                                                                            ariaLabelDesc="Activate to sort in descending order"
                                                                            ariaLabelAsc="Activate to sort in ascending order"
                                                                            field="fullName"></i>
                                                                        <p-columnFilter type="text" field="fullName"
                                                                            display="menu"
                                                                            class="p-ml-auto"></p-columnFilter>
                                                                    </div>

                                                                </th>
                                                                <th pSortableColumn="email" scope="col"
                                                                    style="min-width: 170px;width: 170px;">
                                                                    <div class="p-d-flex p-jc-between p-ai-center">
                                                                        Email Address
                                                                        <i class="p-sortable-column-icon icon-sort"
                                                                            ariaLabel="Activate to sort"
                                                                            ariaLabelDesc="Activate to sort in descending order"
                                                                            ariaLabelAsc="Activate to sort in ascending order"
                                                                            field="email"></i>
                                                                        <p-columnFilter type="text" field="email"
                                                                            display="menu"
                                                                            class="p-ml-auto"></p-columnFilter>
                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="userName" scope="col"
                                                                    style="max-width: 190px;width: 190px;">
                                                                    <div class="p-d-flex p-jc-between p-ai-center">
                                                                        Username
                                                                        <i class="p-sortable-column-icon icon-sort"
                                                                            ariaLabel="Activate to sort"
                                                                            ariaLabelDesc="Activate to sort in descending order"
                                                                            ariaLabelAsc="Activate to sort in ascending order"
                                                                            field="userName"></i>
                                                                        <p-columnFilter type="text" field="userName"
                                                                            display="menu"
                                                                            class="p-ml-auto"></p-columnFilter>
                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="userType" scope="col"
                                                                    style="min-width: 132px;width: 132px;">
                                                                    <div class="p-d-flex p-jc-between p-ai-center">
                                                                        User Type
                                                                        <i class="p-sortable-column-icon icon-sort"
                                                                            ariaLabel="Activate to sort"
                                                                            ariaLabelDesc="Activate to sort in descending order"
                                                                            ariaLabelAsc="Activate to sort in ascending order"
                                                                            field="userType"></i>
                                                                        <p-columnFilter field="userType"
                                                                            matchMode="equals" display="menu"
                                                                            class="p-ml-auto">
                                                                            <ng-template pTemplate="filter" let-value
                                                                                let-filter="filterCallback">
                                                                                <p-dropdown [ngModel]="value"
                                                                                    [options]="userTypes"
                                                                                    (onChange)="filter($event.value)"
                                                                                    placeholder="Any">
                                                                                    <ng-template let-option
                                                                                        pTemplate="item">
                                                                                        <span
                                                                                            [class]="'customer-badge status-' + option.value">{{option.label}}</span>
                                                                                    </ng-template>
                                                                                </p-dropdown>
                                                                            </ng-template>
                                                                        </p-columnFilter>
                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="userIdentity" scope="col"
                                                                    style="min-width: 150px;width: 150px;">
                                                                    <div class="p-d-flex p-jc-between p-ai-center">
                                                                        User Identity
                                                                        <i class="p-sortable-column-icon icon-sort"
                                                                            ariaLabel="Activate to sort"
                                                                            ariaLabelDesc="Activate to sort in descending order"
                                                                            ariaLabelAsc="Activate to sort in ascending order"
                                                                            field="userIdentity"></i>
                                                                        <p-columnFilter field="userIdentity"
                                                                            matchMode="equals" display="menu"
                                                                            class="p-ml-auto">
                                                                            <ng-template pTemplate="filter" let-value
                                                                                let-filter="filterCallback">
                                                                                <p-dropdown [ngModel]="value"
                                                                                    [options]="dbType"
                                                                                    (onChange)="filter($event.value)"
                                                                                    placeholder="Any">
                                                                                    <ng-template let-option
                                                                                        pTemplate="item">
                                                                                        <span
                                                                                            [class]="'customer-badge status-' + option.value">{{option.label}}</span>
                                                                                    </ng-template>
                                                                                </p-dropdown>
                                                                            </ng-template>
                                                                        </p-columnFilter>
                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="umPkId" scope="col"
                                                                    style="min-width: 132px;width: 132px;"
                                                                    *ngIf="rebacFlag=='true'">
                                                                    <div class="p-d-flex p-jc-between p-ai-center">
                                                                        User ID
                                                                        <i class="p-sortable-column-icon icon-sort"
                                                                            ariaLabel="Activate to sort"
                                                                            ariaLabelDesc="Activate to sort in descending order"
                                                                            ariaLabelAsc="Activate to sort in ascending order"
                                                                            field="umPkId"></i>
                                                                        <p-columnFilter type="numeric" field="umPkId"
                                                                            display="menu"
                                                                            class="p-ml-auto"></p-columnFilter>
                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="userStatus" scope="col"
                                                                    style="min-width: 180px;width: 180px;">
                                                                    <div class="p-d-flex p-jc-between p-ai-center">
                                                                        Status
                                                                        <i class="p-sortable-column-icon icon-sort"
                                                                            ariaLabel="Activate to sort"
                                                                            ariaLabelDesc="Activate to sort in descending order"
                                                                            ariaLabelAsc="Activate to sort in ascending order"
                                                                            field="userStatus"></i>
                                                                        <p-columnFilter field="userStatus"
                                                                            display="menu"
                                                                            class="p-ml-auto"></p-columnFilter>
                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="updatedOn" scope="col"
                                                                    style="min-width:280px;width:280px;">
                                                                    <div class="p-d-flex p-jc-between p-ai-center">
                                                                        Updated On
                                                                        <i class="p-sortable-column-icon icon-sort"
                                                                            ariaLabel="Activate to sort"
                                                                            ariaLabelDesc="Activate to sort in descending order"
                                                                            ariaLabelAsc="Activate to sort in ascending order"
                                                                            field="updatedOn"></i>
                                                                        <p-columnFilter type="date" field="updatedOn"
                                                                            display="menu"
                                                                            [matchModeOptions]="matchModeOptions"
                                                                            class="p-ml-auto"></p-columnFilter>
                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="updatedBy" scope="col"
                                                                    style="min-width: 280px;width: 280px;">
                                                                    <div class="p-d-flex p-jc-between p-ai-center">
                                                                        Updated By
                                                                        <i class="p-sortable-column-icon icon-sort"
                                                                            ariaLabel="Activate to sort"
                                                                            ariaLabelDesc="Activate to sort in descending order"
                                                                            ariaLabelAsc="Activate to sort in ascending order"
                                                                            field="updatedBy"></i>
                                                                        <p-columnFilter type="text" field="updatedBy"
                                                                            display="menu"
                                                                            class="p-ml-auto"></p-columnFilter>
                                                                    </div>
                                                                </th>
                                                                <th style="min-width: 200px;width: 200px;" scope="col">

                                                                </th>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-rowData let-index="rowIndex">
                                                            <tr (mouseenter)="hoverIndex=index;highlight(index)"
                                                                (mouseleave)="hoverIndex=index;highlight(index)"
                                                                [pSelectableRow]="rowData">
                                                                <td class="rowCheck" *ngIf="checkDisable"
                                                                    style="max-width: 4%;z-index: 1;" alignFrozen="left"
                                                                    pFrozenColumn><p-tableCheckbox
                                                                        [value]="rowData"></p-tableCheckbox></td>
                                                                <td (click)="navigateFirstName(rowData)"
                                                                    class="firstNameRow"
                                                                    style="max-width: 250px;min-width: 250px;z-index:1;"
                                                                    alignFrozen="left" pFrozenColumn>
                                                                    <span pTooltip="{{rowData.fullName}}"
                                                                        *ngIf="rowData.fullName?.length >= 24"
                                                                        tooltipPosition="bottom">
                                                                        {{rowData.fullName?.substring(0,24) +
                                                                        "..." }}
                                                                    </span>
                                                                    <span *ngIf="rowData.fullName?.length < 24">
                                                                        {{rowData.fullName}}
                                                                    </span>
                                                                    <span *ngIf="rowData.fullName==null">-</span>
                                                                </td>
                                                                <td class="emailRow"
                                                                    style="max-width:170px;min-width:170px;">
                                                                    xxx&#64;{{rowData.email?.split('@')[1]}}
                                                                </td>
                                                                <td style="max-width: 190px;min-width: 190px;">
                                                                    <span pTooltip="{{rowData.userName}}"
                                                                        tooltipPosition="bottom"
                                                                        *ngIf="rowData.userName?.length>20">{{rowData.userName?.substring(0,20)
                                                                        +
                                                                        "..." }}</span>
                                                                    <span
                                                                        *ngIf="rowData.userName?.length<=20">{{rowData.userName}}</span>
                                                                </td>
                                                                <td class="emailRow"
                                                                    style="max-width: 150px;min-width: 150px;">
                                                                    <span
                                                                        *ngIf="rowData.isExternal!=null">{{rowData.isExternal}}</span>
                                                                    <span *ngIf="rowData.isExternal==null">-</span>
                                                                </td>
                                                                <td class="emailRow"
                                                                    style="max-width: 132px;min-width: 132px;">
                                                                    <span
                                                                        *ngIf="rowData.userType!=null">{{rowData.userType}}</span>
                                                                    <span *ngIf="rowData.userType==null">-</span>
                                                                </td>
                                                                <td class="emailRow"
                                                                    style="max-width: 132px;min-width: 132px;"
                                                                    *ngIf="rebacFlag=='true'">
                                                                    <span
                                                                        *ngIf="rowData.umPkId!=null">{{rowData.umPkId}}</span>
                                                                    <span *ngIf="rowData.umPkId==null">-</span>
                                                                </td>

                                                                <td class="button-status custom-overflow"
                                                                    style="max-width: 180px;min-width: 180px;">
                                                                    <div class="btnwrap"
                                                                        *ngIf="rowData.userStatus!=null">
                                                                        <button pButton type="button"
                                                                            label="{{rowData.userStatus}}"
                                                                            style="background: transparent;"
                                                                            [style.color]="rowData.userStatus === 'ACTIVE' ? '#12813F' : rowData.userStatus === 'LOCKED' ? '#3A435E' : rowData.userStatus === 'STAGED' ? '#3A435E': rowData.userStatus === 'SUSPENDED' ? '#CA8208': rowData.userStatus === 'DEACTIVATED' ? '#FF2953': 'black'"
                                                                            [style.border]="rowData.userStatus === 'ACTIVE' ? '1px solid #CFE6D8' : rowData.userStatus === 'LOCKED' ? '1px solid #D3D5DB' : rowData.userStatus === 'STAGED' ? '1px solid #D3D5DB': rowData.userStatus === 'SUSPENDED' ? '1px solid #FDECCE': rowData.userStatus === 'DEACTIVATED' ? '1px solid #F77CD4': '1px solid black'"
                                                                            class=" p-button-outlined  statusRow">
                                                                        </button>
                                                                    </div>
                                                                    <span *ngIf="rowData.userStatus==null">-</span>
                                                                </td>
                                                                <td class="emailRow"
                                                                    style="max-width:280px;min-width: 280px;">
                                                                    <span
                                                                        *ngIf="rowData.lastModifiedDate!=null">{{rowData.lastModifiedDate
                                                                        | date:'MMM d, y, h:mm:ss a'}}</span>
                                                                    <span
                                                                        *ngIf="rowData.lastModifiedDate==null">-</span>
                                                                </td>
                                                                <td class="emailRow"
                                                                    style="max-width:280px;min-width: 280px;">
                                                                    <span
                                                                        *ngIf="rowData.lastModifiedBy!=null">{{rowData.lastModifiedBy}}</span>
                                                                    <span
                                                                        *ngIf="rowData.lastModifiedBy==null || rowData.lastModifiedBy==''">-</span>
                                                                </td>

                                                                <td class="button-status custom-overflow" pFrozenColumn
                                                                    [frozen]="true" alignFrozen="right"
                                                                    [ngClass]="{'frozen' : true}"
                                                                    style="padding:0px ;background: transparent;">
                                                                    <div class="desktop p-pl-2"
                                                                        style="background: #F5F9FF;">
                                                                        <div class="button-details"
                                                                            style="padding: 5px 5px;">
                                                                            <button pButton tooltipPosition="bottom"
                                                                                icon="d-icons viewedit-icon"
                                                                                *ngIf="rowData.userStatus=='STAGED'||rowData.userStatus=='LOCKED'||rowData.userStatus=='ACTIVE'"
                                                                                pTooltip="Edit"
                                                                                style="width: 24px;height: 24px;padding: 5px 0px 5px 0px;"
                                                                                class="background-transparent"
                                                                                (click)=" navigateToEdit(rowData);"
                                                                                [disabled]="this.pagesService.userManagementReadOnlyFlag">
                                                                            </button>
                                                                            <button pButton tooltipPosition="bottom"
                                                                                *ngIf="rowData.userStatus=='DEACTIVATED'||rowData.userStatus=='SUSPENDED'"
                                                                                icon="d1-icons active-icon"
                                                                                style="width: 16px;height: 16px;padding: 5px 0px 5px 0px;"
                                                                                pTooltip="Activate User"
                                                                                class="background-transparent"
                                                                                (click)="confirmHover2(rowData.id,rowData);"
                                                                                [disabled]="this.pagesService.userManagementReadOnlyFlag">
                                                                            </button>
                                                                            <button pButton tooltipPosition="bottom"
                                                                                *ngIf="(rowData.userStatus=='LOCKED'||rowData.userStatus=='ACTIVE'||rowData.userStatus=='SUSPENDED') && deactivateUserBtnFlag"
                                                                                icon="d-icons deactive-icon"
                                                                                style="width: 24px;height: 24px;padding: 5px 0px 5px 0px;"
                                                                                pTooltip="Deactivate User"
                                                                                class="background-transparent"
                                                                                (click)="confirmHover1(rowData.id,rowData);"
                                                                                [disabled]="this.pagesService.userManagementReadOnlyFlag">
                                                                            </button>
                                                                            <button pButton tooltipPosition="bottom"
                                                                                *ngIf="(rowData.userStatus=='STAGED'||rowData.userStatus=='LOCKED'||rowData.userStatus=='ACTIVE') && this.pagesService.pageRebacFlag=='false'"
                                                                                icon="d-icons mirror-icon"
                                                                                style="width: 24px;height: 24px;padding: 5px 0px 5px 0px;"
                                                                                pTooltip="Mirror User"
                                                                                class="background-transparent"
                                                                                (click)="navigateMirrorUser(rowData);"
                                                                                [disabled]="this.pagesService.userManagementReadOnlyFlag">
                                                                            </button>
                                                                            <button pButton tooltipPosition="bottom"
                                                                                *ngIf="(rowData.userStatus=='LOCKED'||rowData.userStatus=='ACTIVE') && suspendUserBtnFlag"
                                                                                icon="d-icons suspend-icon"
                                                                                style="width: 24px;height: 24px;padding: 5px 0px 5px 0px;"
                                                                                pTooltip="Suspend User"
                                                                                class="background-transparent"
                                                                                (click)="SuspendUser(rowData.id,rowData);"
                                                                                [disabled]="rowData.email == this.uemail"
                                                                                [disabled]="this.pagesService.userManagementReadOnlyFlag">
                                                                            </button>
                                                                            <button pButton tooltipPosition="bottom"
                                                                                *ngIf="rowData.userStatus=='ACTIVE'"
                                                                                icon="d-icons force-icon"
                                                                                style="width: 24px;height: 24px;padding: 5px 0px 5px 0px;"
                                                                                pTooltip="Force Logoff"
                                                                                class="background-transparent"
                                                                                (click)="ForceLogOff(rowData.id,rowData);"
                                                                                [disabled]="rowData.email == this.uemail"
                                                                                [disabled]="this.pagesService.userManagementReadOnlyFlag">
                                                                            </button>
                                                                            <button pButton tooltipPosition="bottom"
                                                                                *ngIf="rowData.userStatus=='LOCKED'"
                                                                                icon="d-icons unlock-icon"
                                                                                style="width: 16px;height: 16px;padding: 5px 0px 5px 0px;"
                                                                                pTooltip="Unlock User"
                                                                                class="background-transparent"
                                                                                (click)="unlockUser(rowData.id,rowData);"
                                                                                [disabled]="this.pagesService.userManagementReadOnlyFlag">
                                                                            </button>
                                                                            <button pButton tooltipPosition="bottom"
                                                                                pTooltip="Delete User"
                                                                                style="width: 16px;height: 16px;padding: 5px 0px 4px 0px;"
                                                                                icon="d1-icons trash-icon"
                                                                                class="background-transparent"
                                                                                (click)="DeleteUser(rowData);"
                                                                                [disabled]="this.pagesService.userManagementReadOnlyFlag">
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </td>

                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="emptymessage">
                                                            <tr>
                                                                <td colspan="10" class="p-p-0 p-text-center"> <p-card>
                                                                        <div style="margin-top: 5%;height: 70px;">No
                                                                            matching records found</div>
                                                                    </p-card> </td>
                                                            </tr>
                                                        </ng-template>
                                                    </p-table>
                                                    <div style="position: relative;display: inline-flex;">
                                                        <p-paginator #pp [rows]="10" [totalRecords]="this.totalRecords"
                                                            [rowsPerPageOptions]="[10,25,50]"
                                                            (onPageChange)="paginate($event,this.UserListingForm.value.textField)"></p-paginator>
                                                        <div class="p-pl-3 p-pt-2"
                                                            style="font-size: 13px;color: #505870;">
                                                            <span>Total Records: {{this.totalRecords}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>