import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PagesService } from '../pages.service';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
@Component({
  selector: 'jhi-CreateHostResourcesEndpoint',
  templateUrl: './CreateHostResourcesEndpoint.component.html',
  styleUrls: ['./CreateHostResourcesEndpoint.component.scss'],
  providers: [MessageService, DialogService],
})
export class CreateHostResourcesEndpointComponent implements OnInit {
  CreateHostResourcesEndpointForm = this.fb.group({
    select: [null, [Validators.required]],
    select1: [null, [Validators.required]],
    textField: [null, [Validators.required]],
    textField1: [null, [Validators.required]],
    select2: [null, [Validators.required]],
    applicationRoleRequired: [null, []]
  });
  pageloadvalues: any;
  selectList: any[] = [];
  select1List: any[] = [];
  select2List: any[] = [];
  appId: any;
  componentId: any;
  dropdown: boolean;
  app: any;
  com: any;
  enableBtn = true;
  nameFlag = false;
  resourceName: any;
  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    private messageService: MessageService,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.CreateHostResourcesEndpointForm.patchValue(JSON.parse(data));
    }
  }

  ngOnInit(): void {
    this.getApplicationList();
    this.getMethod();
    this.app = this.config?.data?.appId;
    this.com = this.config?.data?.comId;
    this.getComponent();

  }

  onClose($event: UntypedFormGroup): void {
    this.ref.close();
  }

  /**
   * Create resource endpoint in application resources
   */

  //get application list API -dropdown
  getApplicationList(): void {
    this.pagesService.getApplicationVersion().subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.selectList.push(data);
        });
        this.selectList = [...this.selectList];
      }
    });
  }

  //get component list API -dropdown
  getComponent(): void {
    this.appId = this.app;
    const compData = { applicationId: this.appId };
    this.pagesService.getHostComponentEndpoint(compData).subscribe(results => {
      this.select1List = [];
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.select1List.push(data);
        });
        this.select1List = [...this.select1List];
      }
    });
  }

  colorChange() {
    document.getElementById('textField_id1')!.style.borderColor = 'grey';
  }

  // check availability for host resource name
  onSaveCheckResourceName($event: UntypedFormGroup): void {
    this.appId = this.CreateHostResourcesEndpointForm.value.select;
    this.resourceName = this.CreateHostResourcesEndpointForm.value?.textField;
    const data = { appId: this.appId, resourceName: this.resourceName };
    this.pagesService.checkAvailabilityHostAppResourceName(data).subscribe(
      results => {
        if (results?.error?.code == 400 || results?.success?.code == 400) {
          this.nameFlag = true;
          document.getElementById('textField_id1')!.style.borderColor = 'red';
        } else if (results.success.code == 200) {
          this.nameFlag = false;
          document.getElementById('textField_id1')!.style.borderColor = 'grey';
          this.onSave();
        }
      }
    );
  }


  getMethod(): void {
    this.pagesService.getMethodApplication().subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.label;
          this.select2List.push(data);
        });
        this.select2List = [...this.select2List];
      }
    });
  }

  //create host resource endpoint API
  onSave() {
    this.appId = this.CreateHostResourcesEndpointForm.value.select;
    this.componentId = this.CreateHostResourcesEndpointForm.value.select1;
    const reqBody = {};
    reqBody['applicationId'] = this.appId;
    reqBody['componentId'] = this.componentId;
    reqBody['resourceName'] = this.CreateHostResourcesEndpointForm.value.textField;
    reqBody['endPointUrl'] = this.CreateHostResourcesEndpointForm.value.textField1;
    reqBody['method'] = this.CreateHostResourcesEndpointForm.value.select2;
    if (this.CreateHostResourcesEndpointForm.value.applicationRoleRequired != null) {
      reqBody['applicationRoleRequired'] = this.CreateHostResourcesEndpointForm.value.applicationRoleRequired
    }
    else {
      reqBody['applicationRoleRequired'] = false;
    }
    const formValues = reqBody;
    this.pagesService.createHostApplicationEndpoint(formValues).subscribe(
      {
        next: results => {
          this.ref.close("Resource Created Successfully");
        },
        error: error => {
          if (error.error.error?.messageCode == "CIAM 105") {
            this.ref.close("Resource name already exist");
          }
          else {
            this.ref.close("Resource Creation Failed");
          }
        }
      });
  }

}