<div style="background-color: #F6F8FA;" (click)="mouseLeave()">
    <div class="p-d-flex p-jc-end">
        <p-messages key="myKey1" class="custom-alert-box" *ngIf="this.messageFlag"></p-messages>
    </div>
    <div class="p-col-fixed p-mt-1 p-ml-1 p-pr-0 custom-breadcrumb">
        <p-breadcrumb [model]="ccprimebreadcrumbList" [home]="homeiconccprimebreadcrumb">
        </p-breadcrumb>
    </div>
    <form class="layout-content custom-form-layout p-mt-4" [formGroup]="ApplicationOnboardingForm" autocomplete="off"
        novalidate>
        <div class="ui-fluid p-formgrid p-grid p-mt-3">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                <div>
                    <h5 class="custom-head1">Application Onboarding</h5>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left custom-tab-nav">
                <p-tabView [(activeIndex)]="ApplicationOnboardingForm?.value.tabview"
                    (onChange)="handleChangetabview($event)">
                    <p-tabPanel header="Application">
                        <div class="card">
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-p-0 p-xl-12 p-offset-undefined left p-mb-5">
                                <div class=" p-fluid   p-field    ">
                                    <div class="p-fluid p-formgrid p-grid" style="padding-top:8px ;">
                                        <div class="p-col-12 p-p-0">
                                            <div *ngIf="this.errorFlag" class="custom-errorFlagDiv">
                                                <label class="d-icons appempty-icon"></label><br>
                                                <label>No Application Found</label><br>
                                                <div class="btnwrap">
                                                    <button pButton type="button" pButton label="Create"
                                                        (click)="showCreateApplication()"
                                                        class="p-button-outlined p-button-danger p-button-sm"
                                                        style="width:80px;"
                                                        [disabled]="this.pagesService.applicationOnboardingReadOnlyFlag"></button>
                                                </div>
                                            </div>
                                            <div class="ui-fluid p-formgrid" *ngIf="!this.errorFlag">
                                                <div class="p-grid p-mt-0 p-p-0">
                                                    <div class="p-col p-p-0">
                                                        <span class=" p-input-icon-left">
                                                            <input type="text" (keydown.enter)="$event.preventDefault()"
                                                                id="applicationSearch_id" name="applicationSearch_name"
                                                                formControlName="applicationSearch" pInputText
                                                                placeholder="Search"
                                                                class="p-inputtext inputtextHeight  "
                                                                (input)="HostApplicationFilterMethod($event.target?.value)"
                                                                style="width: 304px;" />
                                                            <i aria-hidden="true" class="pi pi-search"></i>
                                                        </span>
                                                    </div>
                                                    <div class="p-col-fixed p-p-0 p-text-right">
                                                        <div class="btnwrap">
                                                            <button pButton class="p-button-primary p-button"
                                                                type="button" (click)="showCreateApplication()"
                                                                label="Create"
                                                                [disabled]="this.pagesService.applicationOnboardingReadOnlyFlag">
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-p-0 p-offset-undefined left">
                                                    <div class="  p-field    ">
                                                        <div class="mobilefriendlytable" responsiveLayout="stack"
                                                            [ngClass]="{'content-section implementation': true}">
                                                            <p-table #tableid [value]="tableList" name="table"
                                                                [columns]="tablecols" styleClass="p-datatable-customers"
                                                                [rows]="10" [rowsPerPageOptions]="[10,25,50]"
                                                                selectionMode="Multiple" [paginator]="true"
                                                                sortMode="single" [scrollable]="true"
                                                                [globalFilterFields]="['applicationVersion.version','description','applicationName']">
                                                                <ng-template pTemplate="header">
                                                                    <tr>
                                                                        <th pSortableColumn="applicationName"
                                                                            scope="col" style="width: 30%;">
                                                                            <div
                                                                                class="p-d-flex p-jc-between p-ai-center">
                                                                                Application Name
                                                                                <i class="p-sortable-column-icon icon-sort"
                                                                                    ariaLabel="Activate to sort"
                                                                                    ariaLabelDesc="Activate to sort in descending order"
                                                                                    ariaLabelAsc="Activate to sort in ascending order"
                                                                                    field="applicationName"></i>
                                                                                <p-columnFilter type="text"
                                                                                    field="applicationName"
                                                                                    display="menu"
                                                                                    class="p-ml-auto"></p-columnFilter>
                                                                            </div>
                                                                        </th>
                                                                        <th pSortableColumn="applicationVersion.version"
                                                                            scope="col" style="width: 20%;">
                                                                            <div
                                                                                class="p-d-flex p-jc-between p-ai-center">
                                                                                Application Version
                                                                                <i class="p-sortable-column-icon icon-sort"
                                                                                    ariaLabel="Activate to sort"
                                                                                    ariaLabelDesc="Activate to sort in descending order"
                                                                                    ariaLabelAsc="Activate to sort in ascending order"
                                                                                    field="applicationVersion.version"></i>
                                                                                <p-columnFilter type="text"
                                                                                    field="applicationVersion.version"
                                                                                    display="menu"
                                                                                    class="p-ml-auto"></p-columnFilter>
                                                                            </div>
                                                                        </th>
                                                                        <th pSortableColumn="description" scope="col"
                                                                            style="width: 35%;">
                                                                            <div
                                                                                class="p-d-flex p-jc-between p-ai-center">
                                                                                Application Description
                                                                                <i class="p-sortable-column-icon icon-sort"
                                                                                    ariaLabel="Activate to sort"
                                                                                    ariaLabelDesc="Activate to sort in descending order"
                                                                                    ariaLabelAsc="Activate to sort in ascending order"
                                                                                    field="description"></i>
                                                                                <p-columnFilter type="text"
                                                                                    field="description" display="menu"
                                                                                    class="p-ml-auto"></p-columnFilter>
                                                                            </div>
                                                                        </th>
                                                                        <th id="actionButton" style="width: 10%;">
                                                                            <div>
                                                                            </div>
                                                                        </th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-rowData
                                                                    let-index="rowIndex">
                                                                    <tr (mouseenter)="hoverIndex=index;highlight(index)"
                                                                        (mouseleave)="hoverIndex=index;highlight(index)"
                                                                        [pSelectableRow]="rowData">
                                                                        <td (click)="navigateName(rowData)"
                                                                            style="width: 30%;">
                                                                            {{rowData.applicationName}}
                                                                            <p-badge [value]="varSaas" styleClass="mr-2"
                                                                                class="custom-appbadge"
                                                                                *ngIf="rowData.saasApplication"></p-badge>
                                                                        </td>
                                                                        <td (click)="navigateName(rowData)"
                                                                            class="custom-Attrname-td"
                                                                            style="width: 20%;">
                                                                            {{rowData.applicationVersion?.version}}</td>
                                                                        <td class="custom-description"
                                                                            style="width: 35%;">
                                                                            <div *ngIf="rowData.description == null">
                                                                                <span>-</span>
                                                                            </div>
                                                                            <div *ngIf="rowData.description != null">
                                                                                <span pTooltip="{{rowData.description}}"
                                                                                    tooltipPosition="bottom"
                                                                                    *ngIf="rowData.description?.length>=35">
                                                                                    {{rowData.description?.substring(0,35)+"..."
                                                                                    }}</span>
                                                                                <span
                                                                                    *ngIf="rowData.description?.length<35">{{rowData.description}}</span>
                                                                            </div>
                                                                        </td>
                                                                        <td style="width: 10%;">
                                                                            <div
                                                                                class="button-details custom-button-Details">
                                                                                <button pButton tooltipPosition="bottom"
                                                                                    style="width: 24px;height: 22px;padding: 0px;margin-top: 2px;"
                                                                                    icon="g-icons view-icon"
                                                                                    pTooltip="Edit"
                                                                                    class="background-transparent"
                                                                                    (click)="activeItem=rowData;EditCreateApplication()"
                                                                                    [disabled]="this.pagesService.applicationOnboardingReadOnlyFlag">
                                                                                </button>

                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="paginatorright" let-state> <span
                                                                        class="p-pl-4">Total
                                                                        Records: {{state.totalRecords}}</span>
                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage">
                                                                    <tr>
                                                                        <td colspan="7" class="p-p-0 p-text-center">
                                                                            <p-card>
                                                                                <div
                                                                                    style="margin-top: 5%;height: 70px;">
                                                                                    No
                                                                                    matching records found</div>
                                                                            </p-card>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </p-tabPanel>
                    <p-tabPanel header="Components">
                        <div class="p-fluid p-formgrid p-grid" style="margin-bottom:40px;">
                            <div class=" p-col-12 p-p-0">
                                <div class="ui-fluid p-formgrid p-grid">
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-p-0 p-offset-0 left">
                                        <div class=" p-fluid   p-field    ">
                                            <label
                                                [ngClass]="{'field-required': ApplicationOnboardingForm.get('select')?.errors?.required}">
                                                Application
                                            </label> <span class='invalid-feedback-astrik'>*</span>
                                            <div class="p-fluid">
                                                <p-dropdown id="selectid" name="select"
                                                    placeholder="Select a Application" [options]="selectList"
                                                    [filter]="true" filterBy="label" [autoDisplayFirst]="false"
                                                    formControlName="select" (onChange)="ComponentListing();">
                                                    <ng-template let-items pTemplate="item">
                                                        <div *ngIf="items?.label?.length &lt;=37;">
                                                            {{items.label}}
                                                        </div>
                                                        <div *ngIf="items?.label?.length > 37;"
                                                            pTooltip="{{items.label}}" tooltipPosition="bottom">
                                                            {{items.label.substring(0,37) + "..." }}
                                                        </div>
                                                    </ng-template>
                                                </p-dropdown>
                                            </div>
                                            <div class="invalid-feedback"
                                                *ngIf="ApplicationOnboardingForm.get('select')!.invalid && (ApplicationOnboardingForm.get('select')!.dirty || ApplicationOnboardingForm.get('select')!.touched)">
                                                <div *ngIf="ApplicationOnboardingForm.get('select')?.errors?.required">
                                                    <label class="alert-icon alert-circle-icon"></label>
                                                    <medium class="p-error">Application is required.</medium>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="!flag2"
                                        class="p-col-12 p-sm-12 p-md-12 p-lg-8 p-xl-8 p-p-0 p-offset-undefined p-right">
                                        <div class=" p-fluid   p-field    " class="custom-attr-fluid">
                                            <span class=" p-input-icon-left custom-search-span p-mr-3">
                                                <input type="text" (keydown.enter)="$event.preventDefault()"
                                                    id="textField_id1" name="textField_name1"
                                                    formControlName="textField1" pInputText placeholder="Search"
                                                    class="p-inputtext inputtextHeight"
                                                    (input)="ComponentFilterMethod($event)" />
                                                <i aria-hidden="true" class="pi pi-search"></i>
                                            </span>
                                            <div class="btnwrap custom-btn-height">
                                                <button pButton class="p-button-primary p-button" type="button"
                                                    (click)="showCreateComponent();" label="Create"
                                                    [disabled]="this.pagesService.applicationOnboardingReadOnlyFlag">
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="flag2" class="custom-emptyImage-size">
                                        <label class="d-icons emptyApp-icon"></label>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-p-0 p-offset-undefined left">
                                        <div class="  p-field    ">
                                            <div [ngClass]="{'content-section implementation': true}" *ngIf="!flag2">
                                                <p-table #tableid2 name="table" [columns]="tablecols"
                                                    [value]="tableList2" [rows]="10"
                                                    [globalFilterFields]="['componentName','validRedirectUrl','type','ownership','componentId','updatedOn','updatedBy','totalEntitlement']"
                                                    selectionMode="Multiple" [scrollable]="true" sortMode="single"
                                                    [rowsPerPageOptions]="[10,25,50]"
                                                    styleClass="p-datatable-customers custom-p-datatable-customers"
                                                    [customSort]="true" (onFilter)="componentColumnFilter($event)"
                                                    (sortFunction)="componentSortData($event)">
                                                    <ng-template pTemplate="header">
                                                        <tr>
                                                            <th pSortableColumn='componentName' scope="col"
                                                                style="min-width: 250px;max-width: 250px;width: 250px;"
                                                                pFrozenColumn>
                                                                Component name
                                                                <i class="p-sortable-column-icon icon-sort"
                                                                    ariaLabel="Activate to sort"
                                                                    ariaLabelDesc="Activate to sort in descending order"
                                                                    ariaLabelAsc="Activate to sort in ascending order"
                                                                    field='componentName'></i>
                                                                <p-columnFilter type="text" field="componentName"
                                                                    display="menu" class="p-ml-auto"></p-columnFilter>
                                                            </th>
                                                            <th pSortableColumn="componentId" scope="col"
                                                                style="min-width: 180px;width: 180px;"
                                                                *ngIf="rebacFlag=='true'">
                                                                <div class="p-d-flex p-jc-between p-ai-center">
                                                                    Component ID
                                                                    <i class="p-sortable-column-icon icon-sort"
                                                                        ariaLabel="Activate to sort"
                                                                        ariaLabelDesc="Activate to sort in descending order"
                                                                        ariaLabelAsc="Activate to sort in ascending order"
                                                                        field="componentId"></i>
                                                                    <p-columnFilter type="numeric" field="componentId"
                                                                        display="menu"
                                                                        class="p-ml-auto"></p-columnFilter>
                                                                </div>
                                                            </th>
                                                            <th pSortableColumn='type' scope="col"
                                                                style="min-width: 180px;width: 180px;">
                                                                Type
                                                                <i class="p-sortable-column-icon icon-sort"
                                                                    ariaLabel="Activate to sort"
                                                                    ariaLabelDesc="Activate to sort in descending order"
                                                                    ariaLabelAsc="Activate to sort in ascending order"
                                                                    field='type'></i>
                                                                <p-columnFilter type="text" field="type" display="menu"
                                                                    class="p-ml-auto"></p-columnFilter>
                                                            </th>

                                                            <th pSortableColumn='validRedirectURL' scope="col"
                                                                style="min-width: 250px;width: 250px;">
                                                                Valid redirect URL
                                                                <i class="p-sortable-column-icon icon-sort"
                                                                    ariaLabel="Activate to sort"
                                                                    ariaLabelDesc="Activate to sort in descending order"
                                                                    ariaLabelAsc="Activate to sort in ascending order"
                                                                    field='validRedirectURL'></i>
                                                                <p-columnFilter type="text" field="validRedirectURL"
                                                                    display="menu" class="p-ml-auto"></p-columnFilter>
                                                            </th>
                                                            <th pSortableColumn="ownership" scope="col"
                                                                style="min-width:280px;width:280px;"
                                                                *ngIf="rebacFlag=='true'">
                                                                <div class="p-d-flex p-jc-between p-ai-center">
                                                                    Ownership
                                                                    <i class="p-sortable-column-icon icon-sort"
                                                                        ariaLabel="Activate to sort"
                                                                        ariaLabelDesc="Activate to sort in descending order"
                                                                        ariaLabelAsc="Activate to sort in ascending order"
                                                                        field="ownership"></i>
                                                                    <p-columnFilter type="text" field="ownership"
                                                                        display="menu"
                                                                        class="p-ml-auto"></p-columnFilter>
                                                                </div>
                                                            </th>
                                                            <th pSortableColumn="totalEntitlement" scope="col"
                                                                style="min-width: 180px;width: 180px;"
                                                                *ngIf="rebacFlag=='true'">
                                                                <div class="p-d-flex p-jc-between p-ai-center">
                                                                    Total Entitlements
                                                                    <i class="p-sortable-column-icon icon-sort"
                                                                        ariaLabel="Activate to sort"
                                                                        ariaLabelDesc="Activate to sort in descending order"
                                                                        ariaLabelAsc="Activate to sort in ascending order"
                                                                        field="totalEntitlement"></i>
                                                                    <p-columnFilter type="numeric"
                                                                        field="totalEntitlement" display="menu"
                                                                        class="p-ml-auto"></p-columnFilter>
                                                                </div>
                                                            </th>
                                                            <th pSortableColumn="updatedOn" scope="col"
                                                                style="min-width:180px;width:180px;">
                                                                <div class="p-d-flex p-jc-between p-ai-center">
                                                                    Updated On
                                                                    <i class="p-sortable-column-icon icon-sort"
                                                                        ariaLabel="Activate to sort"
                                                                        ariaLabelDesc="Activate to sort in descending order"
                                                                        ariaLabelAsc="Activate to sort in ascending order"
                                                                        field="updatedOn"></i>
                                                                    <p-columnFilter type="date" field="updatedOn"
                                                                        display="menu"
                                                                        [matchModeOptions]="matchModeOptions"
                                                                        class="p-ml-auto"></p-columnFilter>
                                                                </div>
                                                            </th>
                                                            <th pSortableColumn="updatedBy" scope="col"
                                                                style="min-width: 100px;width: 100px;">
                                                                <div class="p-d-flex p-jc-between p-ai-center">
                                                                    Updated By
                                                                    <i class="p-sortable-column-icon icon-sort"
                                                                        ariaLabel="Activate to sort"
                                                                        ariaLabelDesc="Activate to sort in descending order"
                                                                        ariaLabelAsc="Activate to sort in ascending order"
                                                                        field="updatedBy"></i>
                                                                    <p-columnFilter type="text" field="updatedBy"
                                                                        display="menu"
                                                                        class="p-ml-auto"></p-columnFilter>
                                                                </div>
                                                            </th>
                                                            <th id="actionButton" style="width: 80px;min-width: 80px;">
                                                                <div></div>
                                                            </th>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-rowData let-index="rowIndex">
                                                        <tr (mouseenter)="hoverIndex=index;highlight(index)"
                                                            (mouseleave)="hoverIndex=index;highlight(index)">
                                                            <td style="min-width: 250px;max-width: 250px;width: 250px;"
                                                                alignFrozen="left" pFrozenColumn>
                                                                {{rowData.componentName}}</td>
                                                            <td class="emailRow"
                                                                style="max-width:180px;min-width: 180px;"
                                                                *ngIf="rebacFlag=='true'">
                                                                <span
                                                                    *ngIf="rowData.componentId!=null">{{rowData.componentId}}</span>
                                                                <span *ngIf="rowData.componentId==null">-</span>
                                                            </td>
                                                            <td class="custom-Attrname-td"
                                                                style="min-width: 180px;width: 180px;">
                                                                <span *ngIf="rowData.type!=null">{{rowData.type}}</span>
                                                                <span *ngIf="rowData.type==null">-</span>
                                                            </td>
                                                            <td class="custom-description"
                                                                style="min-width: 250px;width: 250px;">
                                                                <span pTooltip="{{rowData.validRedirectUrl}}"
                                                                    tooltipPosition="bottom"
                                                                    *ngIf="rowData.validRedirectUrl?.length>=35 && rowData.validRedirectUrl!=null">
                                                                    {{rowData.validRedirectUrl?.substring(0,35)+"..."
                                                                    }}</span>
                                                                <span
                                                                    *ngIf="rowData.validRedirectUrl?.length<35 && rowData.validRedirectUrl!=null">{{rowData.validRedirectUrl}}</span>
                                                                <span *ngIf="rowData.validRedirectUrl==null">-</span>
                                                            </td>
                                                            <td class="emailRow"
                                                                style="max-width:180px;min-width: 180px;"
                                                                *ngIf="rebacFlag=='true'">
                                                                <span
                                                                    *ngIf="rowData.ownership!=null">{{rowData.ownership}}
                                                                    <span><label tooltipPosition="right"
                                                                            pTooltip="{{rowData.ownershipComponentName}}"
                                                                            class="alert-icon alert-info-icon"
                                                                            [escape]="false"
                                                                            style="margin-top: 2px;"></label></span></span>
                                                                <span *ngIf="rowData.ownership==null">-</span>
                                                            </td>
                                                            <td class="emailRow"
                                                                style="max-width:180px;min-width: 180px;"
                                                                *ngIf="rebacFlag=='true'">
                                                                <span
                                                                    *ngIf="rowData.totalEntitlement!=null">{{rowData.totalEntitlement}}</span>
                                                                <span *ngIf="rowData.totalEntitlement==null">-</span>
                                                            </td>
                                                            <td class="emailRow"
                                                                style="max-width:280px;min-width: 280px;">
                                                                <span
                                                                    *ngIf="rowData.updatedOn!=null">{{rowData.updatedOn
                                                                    | date:'MMM d, y, h:mm:ss a'}}</span>
                                                                <span *ngIf="rowData.updatedOn==null">-</span>
                                                            </td>
                                                            <td class="emailRow"
                                                                style="max-width:280px;min-width: 280px;">
                                                                <span
                                                                    *ngIf="rowData.updatedBy!=null">{{rowData.updatedBy}}</span>
                                                                <span *ngIf="rowData.updatedBy==null">-</span>
                                                            </td>
                                                            <td pFrozenColumn [frozen]="true" alignFrozen="right"
                                                                [ngClass]="{'frozen' : true}"
                                                                style="padding:0px ;background: transparent;width: 80px;min-width: 80px;">
                                                                <div class="desktop" style="background: #F5F9FF;">
                                                                    <div class="button-details">
                                                                        <button pButton tooltipPosition="bottom"
                                                                            style="width: 24px;height: 24px;padding: 0px;"
                                                                            icon="g-icons viewedit-icon" pTooltip="Edit"
                                                                            class="background-transparent"
                                                                            (click)="activeItem=rowData;showEditComponent();"
                                                                            [disabled]="this.pagesService.applicationOnboardingReadOnlyFlag">
                                                                        </button>
                                                                        <button pButton tooltipPosition="bottom"
                                                                            style="width: 24px;height: 24px;padding: 0px;display: none;"
                                                                            icon="g-icons delete-icon" pTooltip="Delete"
                                                                            class="background-transparent" disabled>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="emptymessage">
                                                        <tr>
                                                            <td *ngIf="rebacFlag=='true'" colspan="9"
                                                                class="p-p-0 p-text-center"> <p-card>
                                                                    <div style="margin-top: 5%;height: 70px;">No
                                                                        matching records found</div>
                                                                </p-card> </td>
                                                            <td *ngIf="rebacFlag!='true'" colspan="7"
                                                                class="p-p-0 p-text-center"> <p-card>
                                                                    <div style="margin-top: 5%;height: 70px;">No
                                                                        matching records found</div>
                                                                </p-card> </td>
                                                        </tr>
                                                    </ng-template>
                                                </p-table>
                                                <div class="p-col-12"
                                                    style="position: relative;background: white;padding: 10px;display: inline-flex;">
                                                    <p-paginator #ppC [rows]="10"
                                                        [totalRecords]="this.totalRecordsComponent"
                                                        [rowsPerPageOptions]="[10,25,50]"
                                                        (onPageChange)="componentPaginate($event,this.ApplicationOnboardingForm.value.textField1)"></p-paginator>
                                                    <div class="p-pl-3 p-pt-2" style="font-size: 13px;color: #505870;">
                                                        Total Records: {{this.totalRecordsComponent}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </p-tabPanel>
                    <p-tabPanel header="Resource Endpoint">
                        <div class="p-fluid p-formgrid p-grid" style="margin-bottom:40px;">
                            <div class=" p-col-12 p-p-0">
                                <div class="ui-fluid p-formgrid p-grid">
                                    <div class="p-col-12 p-sm-12 p-md-3 p-lg-3 p-xl-3 p-offset-0 left p-pl-0">
                                        <div class=" p-fluid   p-field ">
                                            <label
                                                [ngClass]="{'field-required': ApplicationOnboardingForm.get('select4')?.errors?.required}">
                                                Application
                                            </label> <span class='invalid-feedback-astrik'>*</span>
                                            <div class="p-fluid">
                                                <p-dropdown [filter]="true" filterBy="label" id="selectid" name="select"
                                                    placeholder="Select" [options]="selectList"
                                                    [autoDisplayFirst]="false" formControlName="select4"
                                                    (onChange)="select();getComponentDropdown();">
                                                    <ng-template let-items pTemplate="item">
                                                        <div *ngIf="items?.label?.length &lt;= 35;">
                                                            {{items.label}}
                                                        </div>
                                                        <div *ngIf="items?.label?.length > 35;"
                                                            pTooltip="{{items.label}}" tooltipPosition="bottom">
                                                            {{items.label.substring(0,35) + "..." }}
                                                        </div>
                                                    </ng-template>
                                                </p-dropdown>
                                            </div>
                                            <div class="invalid-feedback"
                                                *ngIf="ApplicationOnboardingForm.get('select4')!.invalid && (ApplicationOnboardingForm.get('select4')!.dirty || ApplicationOnboardingForm.get('select4')!.touched)">
                                                <div *ngIf="ApplicationOnboardingForm.get('select4')?.errors?.required">
                                                    <label class="alert-icon alert-circle-icon"></label>
                                                    <medium class="p-error">Application is required.</medium>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-3 p-lg-3 p-xl-3 p-offset-undefined left">
                                        <div class=" p-fluid   p-field    ">
                                            <label
                                                [ngClass]="{'field-required': ApplicationOnboardingForm.get('select5')?.errors?.required}">
                                                Component
                                            </label> <span class='invalid-feedback-astrik'>*</span>
                                            <div class="p-fluid">
                                                <p-dropdown [filter]="true" filterBy="label" id="select5id"
                                                    name="select5" placeholder="Select" [options]="select5List"
                                                    [autoDisplayFirst]="false" formControlName="select5"
                                                    (onChange)="ResourceEnpointListing();">
                                                    <ng-template let-items pTemplate="item">
                                                        <div *ngIf="items?.label?.length &lt;= 35;">
                                                            {{items.label}}
                                                        </div>
                                                        <div *ngIf="items?.label?.length > 35;"
                                                            pTooltip="{{items.label}}" tooltipPosition="bottom">
                                                            {{items.label.substring(0,35) + "..." }}
                                                        </div>
                                                    </ng-template>
                                                </p-dropdown>
                                            </div>
                                            <div class="invalid-feedback"
                                                *ngIf="ApplicationOnboardingForm.get('select5')!.invalid && (ApplicationOnboardingForm.get('select5')!.dirty || ApplicationOnboardingForm.get('select5')!.touched)">
                                                <div *ngIf="ApplicationOnboardingForm.get('select5')?.errors?.required">
                                                    <label class="alert-icon alert-circle-icon"></label>
                                                    <medium class="p-error">Component is required.</medium>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="!resourceFlag"
                                        class="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6 p-offset-undefined p-right p-p-0">
                                        <div class=" p-fluid   p-field custom-attr-fluid">
                                            <span class=" p-input-icon-left custom-search-span p-mr-3">
                                                <input type="text" (keydown.enter)="$event.preventDefault()"
                                                    id="textField_id" name="textField_name"
                                                    formControlName="textFieldEndpoint" pInputText placeholder="Search"
                                                    class="p-inputtext inputtextHeight custom-search-width "
                                                    (input)="resourceEndpointFilterMethod($event)" />
                                                <i aria-hidden="true" class="pi pi-search"></i>
                                            </span>
                                            <div class="btnwrap custom-btn-height">
                                                <button pButton class="p-button-primary p-button" type="button"
                                                    (click)="createResourceEndpoint();" label="Create"
                                                    [disabled]="this.pagesService.applicationOnboardingReadOnlyFlag">
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="custom-emptyImage-size" *ngIf="resourceFlag">
                                        <label class="d1-icons group1-icon"
                                            style="background-position: center;"></label>
                                        <label class="p-d-flex p-jc-center">Please select <span class="p-pl-1"
                                                style="font-weight: 500;">
                                                Application & Component</span></label>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                                        <div class="  p-field    ">
                                            <div class="mobilefriendlytable" responsiveLayout="stack"
                                                [ngClass]="{'content-section implementation': true}">
                                                <p-table #tid *ngIf="!resourceFlag" name="table" [columns]="tablecols"
                                                    [value]="tableList8" [rows]="10"
                                                    [globalFilterFields]="['resourceName','endPointUrl','method']"
                                                    selectionMode="Multiple" [scrollable]="true" sortMode="single"
                                                    [rowsPerPageOptions]="[10,25,50]"
                                                    styleClass="p-datatable-customers custom-p-datatable-customers"
                                                    [customSort]="true"
                                                    (onFilter)="resourceEndpointcolumnFilter($event)"
                                                    (sortFunction)="resourceEndpointsortData($event)"
                                                    [customSort]="true">
                                                    <ng-template pTemplate="header">
                                                        <tr>
                                                            <th pSortableColumn='resourceName' scope="col"
                                                                style="width: 20%;">
                                                                Resource Name
                                                                <i class="p-sortable-column-icon icon-sort"
                                                                    ariaLabel="Activate to sort"
                                                                    ariaLabelDesc="Activate to sort in descending order"
                                                                    ariaLabelAsc="Activate to sort in ascending order"
                                                                    field='resourceName'></i>
                                                                <p-columnFilter type="text" field="resourceName"
                                                                    display="menu" class="p-ml-auto"></p-columnFilter>
                                                            </th>
                                                            <th pSortableColumn='endPointUrl' scope="col"
                                                                style="width: 30%;">
                                                                Endpoint URL
                                                                <i class="p-sortable-column-icon icon-sort"
                                                                    ariaLabel="Activate to sort"
                                                                    ariaLabelDesc="Activate to sort in descending order"
                                                                    ariaLabelAsc="Activate to sort in ascending order"
                                                                    field='endPointUrl'></i>
                                                                <p-columnFilter type="text" field="endPointUrl"
                                                                    display="menu" class="p-ml-auto"></p-columnFilter>
                                                            </th>
                                                            <th pSortableColumn='method' scope="col"
                                                                style="width: 20%;">
                                                                Method
                                                                <i class="p-sortable-column-icon icon-sort"
                                                                    ariaLabel="Activate to sort"
                                                                    ariaLabelDesc="Activate to sort in descending order"
                                                                    ariaLabelAsc="Activate to sort in ascending order"
                                                                    field='method'></i>
                                                                <p-columnFilter type="text" field="method"
                                                                    display="menu" class="p-ml-auto"></p-columnFilter>
                                                            </th>
                                                            <th pSortableColumn='applicationRoleRequired' scope="col"
                                                                style="width: 22%;">
                                                                Application Role Required
                                                                <i class="p-sortable-column-icon icon-sort"
                                                                    ariaLabel="Activate to sort"
                                                                    ariaLabelDesc="Activate to sort in descending order"
                                                                    ariaLabelAsc="Activate to sort in ascending order"
                                                                    field='applicationRoleRequired'></i>
                                                            </th>
                                                            <th id="actionButton"
                                                                style="min-width: 110px;width: 110px; max-width: 110px;">
                                                                <div class="p-d-flex p-jc-between p-ai-center"></div>
                                                            </th>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-rowData let-index="rowIndex">
                                                        <tr (mouseenter)="hoverIndex=index;highlight(index)"
                                                            (mouseleave)="hoverIndex=index;highlight(index)">
                                                            <td>
                                                                <div *ngIf="rowData.resourceName != null">
                                                                    <span pTooltip="{{rowData.resourceName}}"
                                                                        tooltipPosition="bottom"
                                                                        *ngIf="rowData.resourceName?.length>=20">
                                                                        {{rowData.resourceName?.substring(0,20)+"..."
                                                                        }}</span>
                                                                    <span
                                                                        *ngIf="rowData.resourceName?.length<20">{{rowData.resourceName}}</span>
                                                                </div>
                                                            </td>
                                                            <td class="custom-table-td">
                                                                <span pTooltip="{{rowData.endPointUrl}}"
                                                                    tooltipPosition="bottom"
                                                                    *ngIf="rowData.endPointUrl?.length>=35">
                                                                    {{rowData.endPointUrl?.substring(0,35)+"..."
                                                                    }}</span>
                                                                <span
                                                                    *ngIf="rowData.endPointUrl?.length<35">{{rowData.endPointUrl}}</span>
                                                            </td>
                                                            <td>{{rowData.method}}</td>
                                                            <td><span
                                                                    *ngIf="rowData.applicationRoleRequired==true">Yes</span>
                                                                <span
                                                                    *ngIf="rowData.applicationRoleRequired==false">-</span>
                                                            </td>
                                                            <td>
                                                                <div class="button-details">
                                                                    <button pButton tooltipPosition="bottom"
                                                                        pTooltip="Edit"
                                                                        style="width: 24px;height: 24px;padding: 0px;"
                                                                        icon="g-icons viewedit-icon"
                                                                        class="background-transparent"
                                                                        (click)="activeItem1=rowData;editResource();"
                                                                        [disabled]="this.pagesService.applicationOnboardingReadOnlyFlag">
                                                                    </button>
                                                                    <button pButton tooltipPosition="bottom"
                                                                        pTooltip="Delete"
                                                                        style="width: 24px;height: 24px;padding: 0px;"
                                                                        icon="g-icons delete-icon"
                                                                        class="background-transparent"
                                                                        (click)="activeItem=rowData;deleteResource();"
                                                                        [disabled]="this.pagesService.applicationOnboardingReadOnlyFlag">
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </ng-template>

                                                    <ng-template pTemplate="emptymessage">
                                                        <tr>
                                                            <td colspan="7" class="p-p-0 p-text-center"> <p-card>
                                                                    <div style="margin-top: 5%;height: 70px;">No
                                                                        matching records found</div>
                                                                </p-card> </td>
                                                        </tr>
                                                    </ng-template>
                                                </p-table>
                                                <div class="p-col-12"
                                                    style="position: relative;background: white;padding: 10px;display: inline-flex;"
                                                    *ngIf="!resourceFlag">
                                                    <p-paginator #pp [rows]="10" [totalRecords]="this.totalRecords"
                                                        [rowsPerPageOptions]="[10,25,50]"
                                                        (onPageChange)="resourceEndpointPaginate($event,this.ApplicationOnboardingForm.value.textFieldEndpoint)"></p-paginator>
                                                    <div class="p-pt-2 p-pl-3" style="font-size: 13px;color: #505870;">
                                                        Total Records: {{this.totalRecords}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </p-tabPanel>
                    <p-tabPanel header="Roles">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class=" p-col-12 p-p-0">
                                <div class="ui-fluid p-formgrid p-grid">
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-p-0 p-offset-0 left">
                                        <div class=" p-fluid   p-field    ">
                                            <label
                                                [ngClass]="{'field-required': ApplicationOnboardingForm.get('select')?.errors?.required}">
                                                Application
                                            </label> <span class='invalid-feedback-astrik'>*</span>
                                            <div class="p-fluid">
                                                <p-dropdown id="selectid" name="select"
                                                    placeholder="Select a Application" placeholder="Select"
                                                    [options]="selectList" [filter]="true" filterBy="label"
                                                    [autoDisplayFirst]="false" formControlName="select3"
                                                    (onChange)="ApplicationRoleListing();">
                                                    <ng-template let-items pTemplate="item">
                                                        <div *ngIf="items?.label?.length &lt;= 37;">
                                                            {{items.label}}
                                                        </div>
                                                        <div *ngIf="items?.label?.length > 37;"
                                                            pTooltip="{{items.label}}" tooltipPosition="bottom">
                                                            {{items.label.substring(0,37) + "..." }}
                                                        </div>
                                                    </ng-template>
                                                </p-dropdown>
                                            </div>
                                            <div class="invalid-feedback"
                                                *ngIf="ApplicationOnboardingForm.get('select3')!.invalid && (ApplicationOnboardingForm.get('select3')!.dirty || ApplicationOnboardingForm.get('select3')!.touched)">
                                                <div *ngIf="ApplicationOnboardingForm.get('select3')?.errors?.required">
                                                    <label class="alert-icon alert-circle-icon"></label>
                                                    <medium class="p-error">
                                                        Application is required.</medium>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="!flag3"
                                        class="p-col-12 p-sm-12 p-md-12 p-lg-8 p-xl-8 p-p-0 p-offset-undefined p-right">
                                        <div class=" p-fluid   p-field    " class="custom-attr-fluid">
                                            <span class=" p-input-icon-left custom-search-span p-mr-3">
                                                <input type="text" (keydown.enter)="$event.preventDefault()"
                                                    id="textField_id1" name="textField_name1"
                                                    formControlName="textField1" pInputText placeholder="Search"
                                                    class="p-inputtext inputtextHeight custom-search-width "
                                                    (input)="ApplicationRoleFilterMethod($event.target?.value)" />
                                                <i aria-hidden="true" class="pi pi-search"></i>
                                            </span>
                                            <div class="btnwrap custom-btn-height">
                                                <button pButton class="p-button-primary p-button" type="button"
                                                    (click)="showCreateApplicationRole()" label="Create"
                                                    [disabled]="this.pagesService.applicationOnboardingReadOnlyFlag">
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="" *ngIf="flag3" class="custom-emptyImage-size">
                                        <label class="d-icons emptyApp-icon"></label>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-p-0 p-offset-undefined left">
                                        <div class="  p-field    ">
                                            <div [ngClass]="{'content-section implementation': true}" *ngIf="!flag3">
                                                <p-table #tableids name="table" [columns]="tablecols"
                                                    [value]="tableList6" [rows]="10" [scrollable]="true"
                                                    sortMode="single" [rowsPerPageOptions]="[10,25,50]"
                                                    [paginator]="true" selectionMode="Multiple"
                                                    [globalFilterFields]="['applicationRoleName','description','mappedEndPointCount']">
                                                    <ng-template pTemplate="header">
                                                        <tr>
                                                            <th pSortableColumn='applicationRoleName' scope="col"
                                                                style="width: 222px;min-width: 222px;">
                                                                Application role name
                                                                <i class="p-sortable-column-icon icon-sort"
                                                                    ariaLabel="Activate to sort"
                                                                    ariaLabelDesc="Activate to sort in descending order"
                                                                    ariaLabelAsc="Activate to sort in ascending order"
                                                                    field='applicationRoleName'></i>
                                                                <p-columnFilter type="text" field="applicationRoleName"
                                                                    display="menu" class="p-ml-auto"></p-columnFilter>
                                                            </th>
                                                            <th pSortableColumn='primeRoleID' scope="col"
                                                                style="width: 130px;min-width: 130px;"
                                                                *ngIf="rebacFlag=='true'">
                                                                UM Role ID
                                                                <i class="p-sortable-column-icon icon-sort"
                                                                    ariaLabel="Activate to sort"
                                                                    ariaLabelDesc="Activate to sort in descending order"
                                                                    ariaLabelAsc="Activate to sort in ascending order"
                                                                    field='primeRoleID'></i>
                                                                <p-columnFilter type="numeric" field="primeRoleID"
                                                                    display="menu" class="p-ml-auto"></p-columnFilter>
                                                            </th>
                                                            <th pSortableColumn='mappedEndPointCount' scope="col"
                                                                style="width: 175px;min-width: 175px;">
                                                                Mapped Endpoints
                                                                <i class="p-sortable-column-icon icon-sort"
                                                                    ariaLabel="Activate to sort"
                                                                    ariaLabelDesc="Activate to sort in descending order"
                                                                    ariaLabelAsc="Activate to sort in ascending order"
                                                                    field='mappedEndPointCount'></i>
                                                                <p-columnFilter type="text" field="mappedEndPointCount"
                                                                    display="menu" class="p-ml-auto"></p-columnFilter>
                                                            </th>
                                                            <th pSortableColumn='description' scope="col"
                                                                style="width: 230px;min-width: 230px;">
                                                                Description
                                                                <i class="p-sortable-column-icon icon-sort"
                                                                    ariaLabel="Activate to sort"
                                                                    ariaLabelDesc="Activate to sort in descending order"
                                                                    ariaLabelAsc="Activate to sort in ascending order"
                                                                    field='description'></i>
                                                                <p-columnFilter type="text" field="description"
                                                                    display="menu" class="p-ml-auto"></p-columnFilter>
                                                            </th>
                                                            <th scope="col" style="width: 120px;min-width: 120px;"
                                                                *ngIf="rebacFlag=='true'">
                                                                Self Service Flag
                                                            </th>
                                                            <th id="actionButton"
                                                                style="width: 100px;min-width: 100px;">
                                                                <div></div>
                                                            </th>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-rowData let-index="rowIndex">
                                                        <tr (mouseenter)="hoverIndex=index;highlight(index)"
                                                            (mouseleave)="hoverIndex=index;highlight(index)">
                                                            <td>{{rowData.applicationRoleName}}
                                                                <p-badge [value]="varDefault" styleClass="mr-2"
                                                                    class="custom-appbadge"
                                                                    *ngIf="rowData.default"></p-badge>
                                                            </td>
                                                            <td *ngIf="rebacFlag=='true'">
                                                                <span
                                                                    *ngIf="rowData.primeRoleID!=null">{{rowData.primeRoleID}}</span>
                                                                <span *ngIf="rowData.primeRoleID==null">-</span>
                                                            </td>
                                                            <td>{{rowData.mappedEndPointCount}}
                                                            </td>
                                                            <td class="custom-Attrname-td">
                                                                <span pTooltip="{{rowData.description}}"
                                                                    tooltipPosition="bottom"
                                                                    *ngIf="rowData.description?.length>=45">
                                                                    {{rowData.description?.substring(0,45)+"..."
                                                                    }}</span>
                                                                <span
                                                                    *ngIf="rowData.description?.length<45">{{rowData.description}}</span>
                                                            </td>
                                                            <td *ngIf="rebacFlag=='true'">
                                                                <div
                                                                    style="height: 20px;width: 20px;padding: 0px;margin-left: 5px;margin-top: 3px;">

                                                                    <p-inputSwitch
                                                                        [(ngModel)]="this['selfServiceValue'+index]"
                                                                        pTooltip="Enable/Disable" tooltipPosition="top"
                                                                        [escape]="false"
                                                                        formControlName="selfServiceSwitch{{index}}"
                                                                        styleClass="p-inputswitch-sm"
                                                                        class="p-mr-2 custom-switch"
                                                                        (onChange)="EnableDisableSelfService(rowData,index)">
                                                                    </p-inputSwitch>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="button-details">
                                                                    <button pButton tooltipPosition="bottom"
                                                                        style="width: 24px;height: 24px;padding: 0px;"
                                                                        icon="g-icons viewedit-icon" pTooltip="Edit"
                                                                        class="background-transparent"
                                                                        (click)="activeItem2=rowData;showEditApplicationRole();"
                                                                        [disabled]="this.pagesService.applicationOnboardingReadOnlyFlag">
                                                                    </button>
                                                                    <button pButton tooltipPosition="bottom"
                                                                        style="width: 24px;height: 24px;padding: 0px;display: none;"
                                                                        icon="g-icons delete-icon" pTooltip="Delete"
                                                                        class="background-transparent" disabled>
                                                                    </button>
                                                                    <button pButton tooltipPosition="bottom"
                                                                        pTooltip="Map/ Unmap"
                                                                        style="width: 24px;height: 24px;padding: 0px;"
                                                                        icon="g-icons mapunmap-icon"
                                                                        class="background-transparent"
                                                                        (click)="activeItem1=rowData;mapUnmapEndpoint();">
                                                                    </button>

                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="paginatorright" let-state> <span
                                                            class="p-pl-4">Total
                                                            Records: {{state.totalRecords}}</span></ng-template>
                                                    <ng-template pTemplate="emptymessage">
                                                        <tr>
                                                            <td colspan="7" class="p-p-0 p-text-center"> <p-card>
                                                                    <div style="margin-top: 5%;height: 70px;">No
                                                                        matching records found</div>
                                                                </p-card> </td>
                                                        </tr>
                                                    </ng-template>
                                                </p-table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </p-tabPanel>
                    <p-tabPanel header="Attribute Type">
                        <div class="p-fluid p-formgrid p-grid" style="margin-bottom:40px;">
                            <div class=" p-col-12 p-p-0">
                                <div class="ui-fluid p-formgrid p-grid">
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-p-0 p-offset-0 left">
                                        <div class=" p-fluid   p-field    ">
                                            <label
                                                [ngClass]="{'field-required': ApplicationOnboardingForm.get('select2')?.errors?.required}">
                                                Application
                                            </label> <span class='invalid-feedback-astrik'>*</span>
                                            <div class="p-fluid">
                                                <p-dropdown id="select2id" name="select2" placeholder="Select"
                                                    [options]="selectList" [filter]="true" filterBy="label"
                                                    [autoDisplayFirst]="false" formControlName="select2"
                                                    (onChange)=" AttributeTypeListing();">
                                                    <ng-template let-items pTemplate="item">
                                                        <div *ngIf="items?.label?.length &lt;= 35;">
                                                            {{items.label}}
                                                        </div>
                                                        <div *ngIf="items?.label?.length > 35;"
                                                            pTooltip="{{items.label}}" tooltipPosition="bottom">
                                                            {{items.label.substring(0,35) + "..." }}
                                                        </div>
                                                    </ng-template>
                                                </p-dropdown>
                                            </div>
                                            <div class="invalid-feedback"
                                                *ngIf="ApplicationOnboardingForm.get('select2')!.invalid && (ApplicationOnboardingForm.get('select2')!.dirty || ApplicationOnboardingForm.get('select2')!.touched)">
                                                <div *ngIf="ApplicationOnboardingForm.get('select2')?.errors?.required">
                                                    <label class="alert-icon alert-circle-icon"></label>
                                                    <medium class="p-error">
                                                        Application is required.</medium>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="!flag1"
                                        class="p-col-12 p-sm-12 p-md-12 p-lg-8 p-xl-8 p-p-0 p-offset-undefined p-right">
                                        <div class=" p-fluid   p-field    " class="custom-attr-fluid">
                                            <span class=" p-input-icon-left custom-search-span p-mr-3">
                                                <input type="text" (keydown.enter)="$event.preventDefault()"
                                                    id="textField2_id" name="textField2_name"
                                                    formControlName="textField" pInputText placeholder="Search"
                                                    class="p-inputtext inputtextHeight custom-search-width "
                                                    (input)="AttributeTypeFilterMethod($event.target?.value)" />
                                                <i aria-hidden="true" class="pi pi-search"></i>
                                            </span>
                                            <div class="btnwrap custom-btn-height">
                                                <button pButton class="p-button-primary p-button" type="button"
                                                    (click)="showCreateAttributeType()" label="Create"
                                                    [disabled]="this.pagesService.applicationOnboardingReadOnlyFlag">
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="flag1" class="custom-emptyImage-size">
                                        <label class="d-icons emptyApp-icon"></label>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-p-0 p-offset-undefined left">
                                        <div class="  p-field    ">
                                            <div [ngClass]="{'content-section implementation': true}" *ngIf="!flag1">
                                                <p-table #tableid1 name="table1" styleClass="p-datatable-gridlines"
                                                    [columns]="tablecols" [value]="tableList4" [paginator]="true"
                                                    [rows]="10"
                                                    [globalFilterFields]="['code','attributeTypeName','description']"
                                                    [scrollable]="true" sortMode="single"
                                                    [rowsPerPageOptions]="[10,25,50]" [paginator]="true"
                                                    selectionMode="Multiple">
                                                    <ng-template pTemplate="header">
                                                        <tr>
                                                            <th pSortableColumn='code' scope="col" style="width:25% ;">
                                                                Code
                                                                <i class="p-sortable-column-icon icon-sort"
                                                                    ariaLabel="Activate to sort"
                                                                    ariaLabelDesc="Activate to sort in descending order"
                                                                    ariaLabelAsc="Activate to sort in ascending order"
                                                                    field='code'></i>
                                                                <p-columnFilter type="text" field="code" display="menu"
                                                                    class="p-ml-auto"></p-columnFilter>
                                                            </th>
                                                            <th pSortableColumn='attributeTypeName' scope="col"
                                                                style="width:25% ;">
                                                                Attribute Type Name
                                                                <i class="p-sortable-column-icon icon-sort"
                                                                    ariaLabel="Activate to sort"
                                                                    ariaLabelDesc="Activate to sort in descending order"
                                                                    ariaLabelAsc="Activate to sort in ascending order"
                                                                    field='attributeTypeName'></i>
                                                                <p-columnFilter type="text" field="attributeTypeName"
                                                                    display="menu" class="p-ml-auto"></p-columnFilter>
                                                            </th>
                                                            <th pSortableColumn='description' scope="col"
                                                                style="width: 35%;">
                                                                Description
                                                                <i class="p-sortable-column-icon icon-sort"
                                                                    ariaLabel="Activate to sort"
                                                                    ariaLabelDesc="Activate to sort in descending order"
                                                                    ariaLabelAsc="Activate to sort in ascending order"
                                                                    field='description'></i>
                                                                <p-columnFilter type="text" field="description"
                                                                    display="menu" class="p-ml-auto">
                                                                </p-columnFilter>
                                                            </th>
                                                            <th id="actionButton" style="width:10% ;">
                                                                <div></div>
                                                            </th>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-rowData let-index="rowIndex">
                                                        <tr (mouseenter)="hoverIndex=index;highlight(index)"
                                                            (mouseleave)="hoverIndex=index;highlight(index)">
                                                            <td style="width:25% ; line-height: 1.5rem;">
                                                                {{rowData.code}}
                                                            </td>
                                                            <td class="custom-Attrname-td" style="width:25% ;">
                                                                <span pTooltip="{{rowData.attributeTypeName}}"
                                                                    tooltipPosition="bottom"
                                                                    *ngIf="rowData.attributeTypeName?.length>=25">
                                                                    {{rowData.attributeTypeName?.substring(0,25)+"..."
                                                                    }}</span>
                                                                <span
                                                                    *ngIf="rowData.attributeTypeName?.length<25">{{rowData.attributeTypeName}}</span>
                                                            </td>
                                                            <td class="custom-description" style="width: 35%;">
                                                                <span pTooltip="{{rowData.description}}"
                                                                    tooltipPosition="bottom"
                                                                    *ngIf="rowData.description?.length>=35">
                                                                    {{rowData.description?.substring(0,35)+"..."
                                                                    }}</span>
                                                                <span
                                                                    *ngIf="rowData.description?.length<35">{{rowData.description}}</span>
                                                            </td>
                                                            <td style="width: 10%;">
                                                                <div class="button-details">
                                                                    <button pButton tooltipPosition="bottom"
                                                                        style="width: 24px;height: 24px;padding: 0px;"
                                                                        icon="g-icons view-icon" pTooltip="Edit"
                                                                        class="background-transparent"
                                                                        (click)="activeItem1=rowData;EditAttributeType();"
                                                                        [disabled]="this.pagesService.applicationOnboardingReadOnlyFlag">
                                                                    </button>
                                                                    <button pButton tooltipPosition="bottom"
                                                                        style="width: 24px;height: 24px;padding: 0px;display: none;"
                                                                        icon="g-icons delete-icon" pTooltip="Delete"
                                                                        class="background-transparent"
                                                                        (click)="activeItem1=rowData;DeleteAttribute();"
                                                                        disabled>
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="paginatorright" let-state> <span
                                                            class="p-pl-4">Total
                                                            Records: {{state.totalRecords}}</span></ng-template>
                                                    <ng-template pTemplate="emptymessage">
                                                        <tr>
                                                            <td colspan="7" class="p-p-0 p-text-center"> <p-card>
                                                                    <div style="margin-top: 5%;height: 70px;">No
                                                                        matching records found</div>
                                                                </p-card> </td>
                                                        </tr>
                                                    </ng-template>
                                                </p-table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </p-tabPanel>
                    <p-tabPanel header="Attributes">
                        <div class="p-fluid p-formgrid p-grid" style="margin-bottom:40px;">
                            <div class=" p-col-12 p-p-0">
                                <div class="ui-fluid p-formgrid p-grid">
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-p-0 p-offset-0 left">
                                        <div class=" p-fluid   p-field    ">
                                            <label
                                                [ngClass]="{'field-required': ApplicationOnboardingForm.get('select6')?.errors?.required}">
                                                Application
                                            </label> <span class='invalid-feedback-astrik'>*</span>
                                            <div class="p-fluid">
                                                <p-dropdown id="select6id" name="select6" placeholder="Select"
                                                    [options]="selectList" [filter]="true" filterBy="label"
                                                    [autoDisplayFirst]="false" formControlName="select6"
                                                    (onChange)="treeCheck();">
                                                    <ng-template let-items pTemplate="item">
                                                        <div *ngIf="items?.label?.length &lt;= 35;">
                                                            {{items.label}}
                                                        </div>
                                                        <div *ngIf="items?.label?.length > 35;"
                                                            pTooltip="{{items.label}}" tooltipPosition="bottom">
                                                            {{items.label.substring(0,35) + "..." }}
                                                        </div>
                                                    </ng-template>
                                                </p-dropdown>
                                            </div>
                                            <div class="invalid-feedback"
                                                *ngIf="ApplicationOnboardingForm.get('select6')!.invalid && (ApplicationOnboardingForm.get('select6')!.dirty || ApplicationOnboardingForm.get('select6')!.touched)">
                                                <div *ngIf="ApplicationOnboardingForm.get('select6')?.errors?.required">
                                                    <label class="alert-icon alert-circle-icon"></label>
                                                    <medium class="p-error">
                                                        Application is required.</medium>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="!attributeFlag"
                                        class="p-col-12 p-sm-12 p-md-12 p-lg-8 p-xl-8 p-p-0 p-offset-undefined p-right">
                                        <div class=" p-fluid   p-field    " class="custom-attr-fluid">
                                            <span class=" p-input-icon-left custom-search-span p-mr-3">
                                                <input type="text" (keydown.enter)="$event.preventDefault()"
                                                    id="textFieldAttribute_id" name="textFieldAttribute_name"
                                                    formControlName="textFieldAttribute" pInputText placeholder="Search"
                                                    class="p-inputtext inputtextHeight custom-search-width "
                                                    (input)="attributeSearchFilterMethod($event)" />
                                                <i aria-hidden="true" class="pi pi-search"></i>
                                            </span>
                                            <div class="btnwrap custom-btn-height p-mr-3">
                                                <button pButton class="p-button-primary p-button" type="button"
                                                    (click)="createHostAttribute()" label="Create"
                                                    [disabled]="this.pagesService.applicationOnboardingReadOnlyFlag">
                                                </button>
                                            </div>

                                            <!--  Added for download attributes -->
                                            <div class="btnwrap custom-btn-height">
                                                <button pButton tooltipPosition="bottom" class=" p-button-secondary  "
                                                    label="Export" (click)="openDownloadAttributeDialog()"
                                                    pTooltip="Export attributes"
                                                    [disabled]="this.pagesService.applicationOnboardingReadOnlyFlag"
                                                    *ngIf="this.pagesService.attributeTreeEnable">
                                                </button>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div *ngIf="attributeFlag" class="custom-emptyImage-size">
                                    <label class="d-icons emptyApp-icon"></label>
                                </div>
                                <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-p-0 p-offset-undefined left">
                                    <div class="  p-field    ">
                                        <div [ngClass]="{'content-section implementation': true}"
                                            *ngIf="!attributeFlag">
                                            <p-table *ngIf="!this.pagesService.attributeTreeEnable" #tableid1
                                                name="table1" styleClass="p-datatable-gridlines"
                                                [columns]="tablecolsAttribute" [value]="tableListAttribute" [rows]="10"
                                                [scrollable]="true" sortMode="single" [rowsPerPageOptions]="[10,25,50]"
                                                selectionMode="Multiple" [customSort]="true"
                                                (onFilter)="attributeColumnFilter($event)"
                                                (sortFunction)="attributeSortData($event)" [customSort]="true">
                                                <ng-template pTemplate="header">
                                                    <tr>
                                                        <th pSortableColumn='attributeType' scope="col"
                                                            style="width:15% ;">
                                                            Type
                                                            <i class="p-sortable-column-icon icon-sort"
                                                                ariaLabel="Activate to sort"
                                                                ariaLabelDesc="Activate to sort in descending order"
                                                                ariaLabelAsc="Activate to sort in ascending order"
                                                                field='attributeType'></i>
                                                            <p-columnFilter type="text" field="attributeType"
                                                                display="menu" class="p-ml-auto"></p-columnFilter>
                                                        </th>
                                                        <th pSortableColumn='attributeResourceName' scope="col"
                                                            style="width:20% ;">
                                                            Name
                                                            <i class="p-sortable-column-icon icon-sort"
                                                                ariaLabel="Activate to sort"
                                                                ariaLabelDesc="Activate to sort in descending order"
                                                                ariaLabelAsc="Activate to sort in ascending order"
                                                                field='attributeResourceName'></i>
                                                            <p-columnFilter type="text" field="attributeResourceName"
                                                                display="menu" class="p-ml-auto"></p-columnFilter>
                                                        </th>
                                                        <th pSortableColumn='attributeGroupsList' scope="col">
                                                            Group
                                                            <i class="p-sortable-column-icon icon-sort"
                                                                ariaLabel="Activate to sort"
                                                                ariaLabelDesc="Activate to sort in descending order"
                                                                ariaLabelAsc="Activate to sort in ascending order"
                                                                field='attributeGroupsList'></i>
                                                            <p-columnFilter type="text" field="attributeGroupsList"
                                                                display="menu" class="p-ml-auto">
                                                            </p-columnFilter>
                                                        </th>
                                                        <th pSortableColumn='isPrivate' scope="col">
                                                            Private
                                                            <i class="p-sortable-column-icon icon-sort"
                                                                ariaLabel="Activate to sort"
                                                                ariaLabelDesc="Activate to sort in descending order"
                                                                ariaLabelAsc="Activate to sort in ascending order"
                                                                field='isPrivate'></i>
                                                            <p-columnFilter type="text" field="isPrivate" display="menu"
                                                                class="p-ml-auto">
                                                            </p-columnFilter>
                                                        </th>
                                                        <th pSortableColumn='isDefault' scope="col">
                                                            Default
                                                            <i class="p-sortable-column-icon icon-sort"
                                                                ariaLabel="Activate to sort"
                                                                ariaLabelDesc="Activate to sort in descending order"
                                                                ariaLabelAsc="Activate to sort in ascending order"
                                                                field='isDefault'></i>
                                                            <p-columnFilter type="text" field="isDefault" display="menu"
                                                                class="p-ml-auto">
                                                            </p-columnFilter>
                                                        </th>
                                                        <th id="actionButton" style="width:10% ;">
                                                            <div></div>
                                                        </th>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-rowData let-index="rowIndex">
                                                    <tr (mouseenter)="hoverIndex=index;highlight(index)"
                                                        (mouseleave)="hoverIndex=index;highlight(index)">
                                                        <td style="width:15% ;">
                                                            {{rowData.attributeTypeName}}
                                                        </td>
                                                        <td class="custom-Attrname-td" style="width:20% ;">
                                                            <span pTooltip="{{rowData.attributeName}}"
                                                                tooltipPosition="bottom"
                                                                *ngIf="rowData.attributeName?.length>=25">
                                                                {{rowData.attributeName?.substring(0,25)+"..."
                                                                }}</span>
                                                            <span
                                                                *ngIf="rowData.attributeName?.length<25">{{rowData.attributeName}}</span>
                                                        </td>
                                                        <td>
                                                            <span
                                                                *ngIf="rowData.attributeGroups.length>0">{{rowData.attributeGroups?.toString()}}</span>
                                                            <span *ngIf="rowData.attributeGroups.length==0">-</span>
                                                        </td>
                                                        <td class="custom-Attrname-td">
                                                            {{rowData.isPrivate}}
                                                        </td>
                                                        <td class="custom-Attrname-td">
                                                            {{rowData.isDefault}}
                                                        </td>
                                                        <td style="width: 10%;">
                                                            <div class="button-details">
                                                                <button pButton tooltipPosition="bottom"
                                                                    style="width: 24px;height: 24px;padding: 0px;"
                                                                    icon="g-icons view-icon" pTooltip="Edit"
                                                                    class="background-transparent"
                                                                    (click)="activeItemAttribute=rowData;EditAttribute();"
                                                                    [disabled]="this.pagesService.applicationOnboardingReadOnlyFlag">
                                                                </button>
                                                                <button pButton tooltipPosition="bottom"
                                                                    style="width: 24px;height: 24px;padding: 0px;"
                                                                    icon="g-icons delete-icon" pTooltip="Delete"
                                                                    class="background-transparent"
                                                                    (click)="activeItemAttribute=rowData;DeleteAttribute();"
                                                                    [disabled]="this.pagesService.applicationOnboardingReadOnlyFlag">
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </ng-template>

                                                <ng-template pTemplate="emptymessage">
                                                    <tr>
                                                        <td colspan="7" class="p-p-0 p-text-center"> <p-card>
                                                                <div style="margin-top: 5%;height: 70px;">No
                                                                    matching records found</div>
                                                            </p-card> </td>
                                                    </tr>
                                                </ng-template>
                                            </p-table>
                                            <div style="position: relative;background: white;padding: 10px;display: inline-flex"
                                                class="p-col-12"
                                                *ngIf="!attributeFlag && !this.pagesService.attributeTreeEnable">
                                                <p-paginator #ppa [rows]="10"
                                                    [totalRecords]="this.totalRecordsAttribute"
                                                    [rowsPerPageOptions]="[10,25,50]"
                                                    (onPageChange)="attributePaginate($event,this.ApplicationOnboardingForm.value.textFieldAttribute)"></p-paginator>
                                                <div class="p-pl-3 p-pt-2"
                                                    style="font-size: 13px;color: #505870;font-weight: 400;">
                                                    Total Records: {{this.totalRecordsAttribute}}</div>
                                            </div>
                                            <!-- <div class="scrollable-wrapper">
                                                <div class="scrollable-container"> -->
                                            <p-treeTable [value]="filesFilter.length>0 ? filesFilter:files"
                                                filteredValue="#{bean.filteredData}" #tt [columns]="cols"
                                                [scrollable]="true" sortMode="single" (sortFunction)="sortData($event)"
                                                [customSort]="true" [filterMode]="filterMode"
                                                (onFilter)="columnFilter($event)" (onNodeExpand)="onNodeExpand($event)"
                                                [resizableColumns]="true" columnResizeMode="expand"
                                                *ngIf="this.pagesService.attributeTreeEnable"
                                                [tableStyle]="{'min-width':'140%'}">
                                                <ng-template pTemplate="header" let-columns>
                                                    <tr>
                                                        <th *ngFor="let col of columns" [ttSortableColumn]="col.field"
                                                            ttResizableColumn>
                                                            {{ col.header }}
                                                            <p-treeTableSortIcon [field]="col.field"
                                                                style="height: 10px;"></p-treeTableSortIcon>
                                                        </th>
                                                        <th></th>
                                                    </tr>
                                                    <tr>
                                                        <th *ngFor="let col of cols">
                                                            <input pInputText type="text"
                                                                (input)="tt.filter($event.target.value, col.field, col.filterMatchMode)" />
                                                        </th>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-rowNode let-rowData="rowData"
                                                    let-columns="columns" let-index="rowIndex">
                                                    <tr [ttRow]="rowNode"
                                                        (mouseenter)="hoverIndex=index;highlight(index)"
                                                        (mouseleave)="hoverIndex=index;highlight(index)">
                                                        <td *ngFor="let col of columns; let i = index">
                                                            <p-treeTableToggler [rowNode]="rowNode"
                                                                *ngIf="i === 0"></p-treeTableToggler>
                                                            {{ rowData[col.field] }}
                                                        </td>

                                                        <td>
                                                            <div class="button-details">
                                                                <button *ngIf="this.pagesService.attributeTreeEnable"
                                                                    pButton tooltipPosition="bottom"
                                                                    style="width: 20px;height: 20px;padding: 0px;"
                                                                    icon="gv-icons viewAttribute-icon" pTooltip="View"
                                                                    class="background-transparent"
                                                                    (click)="activeItemAttribute=rowData;ViewAttributeHierarchy();"
                                                                    [disabled]="this.pagesService.applicationOnboardingReadOnlyFlag">
                                                                </button>
                                                                <button pButton tooltipPosition="bottom"
                                                                    style="width: 24px;height: 24px;padding: 0px;"
                                                                    icon="g-icons view-icon" pTooltip="Edit"
                                                                    class="background-transparent"
                                                                    (click)="activeItemAttribute=rowData;EditAttribute();"
                                                                    [disabled]="this.pagesService.applicationOnboardingReadOnlyFlag">
                                                                </button>
                                                                <button pButton tooltipPosition="bottom"
                                                                    style="width: 24px;height: 24px;padding: 0px;"
                                                                    icon="g-icons delete-icon" pTooltip="Delete"
                                                                    class="background-transparent"
                                                                    (click)="activeItemAttribute=rowData;DeleteAttribute();"
                                                                    [disabled]="this.pagesService.applicationOnboardingReadOnlyFlag">
                                                                </button>
                                                                <button pButton tooltipPosition="bottom"
                                                                    style="width: 24px;height: 24px;padding: 0px;"
                                                                    icon="g-icons addNextLevel-icon"
                                                                    pTooltip="Add Next Level"
                                                                    class="background-transparent"
                                                                    (click)="activeItemAttribute=rowData;AddNewNextLevel();"
                                                                    [disabled]="this.pagesService.applicationOnboardingReadOnlyFlag">
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="emptymessage">
                                                    <tr>
                                                        <td colspan="7" class="p-p-0 p-text-center">
                                                            <p-card>
                                                                <div style="margin-top: 5%;height: 70px;">
                                                                    No
                                                                    matching records found</div>
                                                            </p-card>
                                                        </td>
                                                    </tr>
                                                </ng-template>
                                            </p-treeTable>
                                            <!-- <p-treeTable [tableStyle]="{'min-width':'100%'}"
                                            [value]="filesFilter.length>0 ? filesFilter:files"
                                            filteredValue="#{bean.filteredData}" #tt [columns]="cols"
                                            [scrollable]="true" sortMode="single" (sortFunction)="sortData($event)"
                                            [customSort]="true" [filterMode]="filterMode"
                                            (onFilter)="columnFilter($event)" (onNodeExpand)="onNodeExpand($event)">
                                                <ng-template pTemplate="header" let-columns>
                                                    <tr>
                                                        <th  [ttSortableColumn]="attributeResourceName" class="attributeName">
                                                            Attribute Name
                                                            <p-treeTableSortIcon [field]="attributeResourceName" class="attributeId"></p-treeTableSortIcon>
                                                        </th>
                                                        <th  [ttSortableColumn]="attributeResourceId" class="attributeId">
                                                            Attribute Id
                                                            <p-treeTableSortIcon [field]="attributeResourceId"></p-treeTableSortIcon>
                                                        </th>
                                                        <th  [ttSortableColumn]="attributeTypeName" class="attributeTypeName">
                                                            Type
                                                            <p-treeTableSortIcon [field]="attributeTypeName"></p-treeTableSortIcon>
                                                        </th>
                                                        <th  [ttSortableColumn]="attributeGroupName" class="attributeGroupName">
                                                            Group
                                                            <p-treeTableSortIcon [field]="attributeGroupName"></p-treeTableSortIcon>
                                                        </th>
                                                        <th  [ttSortableColumn]="isDefault" class="isDefault">
                                                            Default
                                                            <p-treeTableSortIcon [field]="isDefault"></p-treeTableSortIcon>
                                                        </th>
                                                        <th  [ttSortableColumn]="isPrivate" class="isPrivate">
                                                            Private
                                                            <p-treeTableSortIcon [field]="isPrivate"></p-treeTableSortIcon>
                                                        </th>
                                                        <th class="i-button">

                                                        </th>
                                                    </tr>
                                                   <tr>
                                                        <th>
                                                            <span class="p-input-icon-left">
                                                                <i class="pi pi-search"></i>
                                                            <input pInputText type="text" placeholder="Search" (input)="tt.filter($event.target.value, attributeResourceName, filterMatchMode)" />
                                                            </span>
                                                        </th>
                                                        <th>
                                                            <span class="p-input-icon-left">
                                                                <i class="pi pi-search"></i>
                                                            <input pInputText type="text" placeholder="Search" (input)="tt.filter($event.target.value, attributeResourceId, filterMatchMode)" />
                                                            </span>
                                                    </th>
                                                        <th >
                                                            <span class="p-input-icon-left">
                                                                <i class="pi pi-search"></i>
                                                            <input pInputText type="text" placeholder="Search" (input)="tt.filter($event.target.value, attributeTypeName, filterMatchMode)" />
                                                            </span>
                                                        </th>
                                                        <th >
                                                            <span class="p-input-icon-left">
                                                                <i class="pi pi-search"></i>
                                                            <input pInputText type="text" placeholder="Search" (input)="tt.filter($event.target.value, attributeGroupName, filterMatchMode)" />
                                                            </span>
                                                        </th>
                                                        <th >
                                                            <span class="p-input-icon-left">
                                                                <i class="pi pi-search"></i>
                                                            <input pInputText type="text" placeholder="Search" (input)="tt.filter($event.target.value, isDefault, filterMatchMode)" />
                                                            </span>
                                                        </th>
                                                        <th >
                                                            <span class="p-input-icon-left">
                                                                <i class="pi pi-search"></i>
                                                            <input pInputText type="text" placeholder="Search" (input)="tt.filter($event.target.value, isPrivate, filterMatchMode)" />
                                                            </span>
                                                        </th>
                                                        <th></th>
                                                    </tr> 
                                                    <tr>
                                                        <th *ngFor="let col of cols">
                                                            <span class="p-input-icon-left">
                                                                <i class="pi pi-search"></i>
                                                            <input pInputText type="text" placeholder="Search"
                                                                (input)="tt.filter($event.target.value, col.field, col.filterMatchMode)" />
                                                            </span>
                                                            </th>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns" let-index="rowIndex">
                                                    <tr [ttRow]="rowNode" (mouseenter)="hoverIndex=index;highlight(index)"
                                                    (mouseleave)="hoverIndex=index;highlight(index)">
                                                        <td class="attributeName">
                                                            <p-treeTableToggler [rowNode]="rowNode" ></p-treeTableToggler>
                                                            {{ rowData.attributeResourceName }}
                                                        </td>
                                                        <td class="attributeId">
                                                            {{ rowData.attributeResourceId }}
                                                        </td>
                                                        <td class="attributeTypeName">
                                                            {{ rowData.attributeTypeName }}
                                                        </td>
                                                        <td class="attributeGroupName">
                                                            {{ rowData.attributeGroupName }}
                                                        </td>
                                                        <td class="isDefault">
                                                            {{ rowData.isDefault }}
                                                        </td>
                                                        <td class="isPrivate">
                                                            {{ rowData.isPrivate }}
                                                        </td>
                                                        
                                                        <td class="i-button">
                                                            <div class="button-details">
                                                                <button pButton tooltipPosition="bottom"
                                                                    style="width: 20px;height: 20px;padding: 0px;"
                                                                    icon="gv-icons viewAttribute-icon" pTooltip="View"
                                                                    class="background-transparent"
                                                                    (click)="activeItemAttribute=rowData;ViewAttributeHierarchy();"
                                                                    [disabled]="this.pagesService.applicationOnboardingReadOnlyFlag">
                                                                </button>
                                                                <button pButton tooltipPosition="bottom"
                                                                    style="width: 24px;height: 24px;padding: 0px;"
                                                                    icon="g-icons view-icon" pTooltip="Edit"
                                                                    class="background-transparent"
                                                                    (click)="activeItemAttribute=rowData;EditAttribute();"
                                                                    [disabled]="this.pagesService.applicationOnboardingReadOnlyFlag">
                                                                </button>
                                                                <button pButton tooltipPosition="bottom"
                                                                    style="width: 24px;height: 24px;padding: 0px;"
                                                                    icon="g-icons delete-icon" pTooltip="Delete"
                                                                    class="background-transparent"
                                                                    (click)="activeItemAttribute=rowData;DeleteAttribute();"
                                                                    [disabled]="this.pagesService.applicationOnboardingReadOnlyFlag">
                                                                </button>
                                                                <button pButton tooltipPosition="bottom"
                                                                    style="width: 24px;height: 24px;padding: 0px;"
                                                                    icon="g-icons addNextLevel-icon"
                                                                    pTooltip="Add Next Level"
                                                                    class="background-transparent"
                                                                    (click)="activeItemAttribute=rowData;AddNewNextLevel();"
                                                                    [disabled]="this.pagesService.applicationOnboardingReadOnlyFlag">
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="emptymessage">
                                                    <tr>
                                                        <td colspan="7" class="p-p-0 p-text-center">
                                                            <p-card>
                                                                <div style="margin-top: 5%;height: 70px;">
                                                                    No
                                                                    matching records found</div>
                                                            </p-card>
                                                        </td>
                                                    </tr>
                                                </ng-template>
                                            </p-treeTable> -->


                                            <div style="position: relative;background: white;padding: 10px;"
                                                *ngIf="this.pagesService.attributeTreeEnable">
                                                <p-paginator #ppa [rows]="10"
                                                    [totalRecords]="this.attributeTotalRecords"
                                                    [rowsPerPageOptions]="[10,25,50]" class="p-d-inline-block"
                                                    (onPageChange)="paginate($event,this.ApplicationOnboardingForm.value.textFieldAttribute)"></p-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </p-tabPanel>
                    <p-tabPanel header="Attribute group">
                        <div class="p-fluid p-formgrid p-grid p-mb-2">
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left p-p-0">
                                <div class=" p-fluid   p-field    ">
                                    <label
                                        [ngClass]="{'field-required': ApplicationOnboardingForm.get('applicationAttrgroupdropdown')?.errors?.required}">
                                        Application
                                    </label> <span class='invalid-feedback-astrik'>*</span>
                                    <div class="p-fluid">
                                        <p-dropdown id="applicationAttrgroupdropdownid"
                                            name="applicationAttrgroupdropdown" placeholder="Select a Application"
                                            placeholder="Select" [options]="selectList" [filter]="true" filterBy="label"
                                            [autoDisplayFirst]="false" formControlName="applicationAttrgroupdropdown"
                                            (onChange)="HostAttributeGroupListing();">
                                            <ng-template let-item pTemplate="item">
                                                <div *ngIf="item.label.length &lt;= 35;">
                                                    {{item.label}}
                                                </div>
                                                <div *ngIf="item.label.length > 35;" pTooltip="{{item.label}}"
                                                    tooltipPosition="top">
                                                    {{item.label.substring(0,35) + "..." }}
                                                </div>
                                            </ng-template>
                                        </p-dropdown>
                                    </div>
                                    <div class="invalid-feedback"
                                        *ngIf="ApplicationOnboardingForm.get('applicationAttrgroupdropdown')!.invalid && (ApplicationOnboardingForm.get('applicationAttrgroupdropdown')!.dirty || ApplicationOnboardingForm.get('applicationAttrgroupdropdown')!.touched)">
                                        <div
                                            *ngIf="ApplicationOnboardingForm.get('applicationAttrgroupdropdown')?.errors?.required">
                                            <label class="alert-icon alert-circle-icon"></label>
                                            <medium class="p-error">Application is required.</medium>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!attributeGroupFlag"
                                class="p-col-12 p-sm-12 p-md-12 p-lg-8 p-xl-8 p-p-0 p-offset-undefined p-right">
                                <div class=" p-fluid   p-field    " class="custom-attr-fluid">
                                    <span class=" p-input-icon-left custom-search-span p-mr-3">
                                        <input type="text" (keydown.enter)="$event.preventDefault()" id="textField_id1"
                                            name="textField_name1" formControlName="searchAttributeGroupList" pInputText
                                            placeholder="Search"
                                            class="p-inputtext inputtextHeight custom-search-width "
                                            (input)="attributeGroupFilterMethod($event)" />
                                        <i aria-hidden="true" class="pi pi-search"></i>
                                    </span>
                                    <div class="btnwrap custom-btn-height">
                                        <button pButton class="p-button-primary p-button" type="button"
                                            (click)="createHostAttributeGroup()" label="Create"
                                            [disabled]="this.pagesService.applicationOnboardingReadOnlyFlag">
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="" *ngIf="attributeGroupFlag" class="custom-emptyImage-size">
                                <label class="d-icons emptyApp-icon"></label>
                            </div>
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-p-0 p-offset-undefined left">
                                <div class="p-field">
                                    <div [ngClass]="{'content-section implementation': true}"
                                        *ngIf="!attributeGroupFlag">
                                        <p-table #tableid1 name="table1" styleClass="p-datatable-gridlines"
                                            [columns]="tablecolsAttribute" [value]="tableListAttributeGroup" [rows]="10"
                                            [scrollable]="true" sortMode="single" [rowsPerPageOptions]="[10,25,50]"
                                            selectionMode="Multiple" [customSort]="true"
                                            (onFilter)="attributeGroupColumnFilter($event)"
                                            (sortFunction)="attributeGroupSortData($event)" [customSort]="true">
                                            <ng-template pTemplate="header">
                                                <tr>
                                                    <th pSortableColumn='attributeGroupName' scope="col"
                                                        style="width:30% ;">
                                                        Attribute Group Name
                                                        <i class="p-sortable-column-icon icon-sort"
                                                            ariaLabel="Activate to sort"
                                                            ariaLabelDesc="Activate to sort in descending order"
                                                            ariaLabelAsc="Activate to sort in ascending order"
                                                            field='attributeGroupName'></i>
                                                        <p-columnFilter type="text" field="attributeGroupName"
                                                            display="menu" class="p-ml-auto"></p-columnFilter>
                                                    </th>
                                                    <th pSortableColumn='assignedAttributeCount' scope="col"
                                                        style="width:20% ;">
                                                        Attribute Assigned
                                                        <i class="p-sortable-column-icon icon-sort"
                                                            ariaLabel="Activate to sort"
                                                            ariaLabelDesc="Activate to sort in descending order"
                                                            ariaLabelAsc="Activate to sort in ascending order"
                                                            field='assignedAttributeCount'></i>
                                                        <p-columnFilter type="text" field="assignedAttributeCount"
                                                            display="menu" class="p-ml-auto"></p-columnFilter>
                                                    </th>
                                                    <th pSortableColumn='description' scope="col" style="width:35% ;">
                                                        Description
                                                        <i class="p-sortable-column-icon icon-sort"
                                                            ariaLabel="Activate to sort"
                                                            ariaLabelDesc="Activate to sort in descending order"
                                                            ariaLabelAsc="Activate to sort in ascending order"
                                                            field='description'></i>
                                                        <p-columnFilter type="text" field="description" display="menu"
                                                            class="p-ml-auto"></p-columnFilter>
                                                    </th>
                                                    <th id="actionButton2" style="width:20% ;">
                                                        <div class="p-d-flex p-jc-between p-ai-center"></div>
                                                    </th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-rowData let-index="rowIndex">
                                                <tr (mouseenter)="hoverIndex=index;highlight(index)"
                                                    (mouseleave)="hoverIndex=index;highlight(index)">
                                                    <td class="custom-Attrname-td" style="width:20% ;">
                                                        <span pTooltip="{{rowData.attributeGroupName}}"
                                                            tooltipPosition="bottom"
                                                            *ngIf="rowData.attributeGroupName?.length>=25">
                                                            {{rowData.attributeGroupName?.substring(0,25)+"..."
                                                            }}</span>
                                                        <span
                                                            *ngIf="rowData.attributeGroupName?.length<25">{{rowData.attributeGroupName}}</span>
                                                    </td>

                                                    <td class="custom-Attrname-td" style="width:20% ;">
                                                        <div *ngIf="rowData.mappedAttributes == '0' ">
                                                            <span>-</span>
                                                        </div>
                                                        <div *ngIf="rowData.mappedAttributes != '0' ">
                                                            <span>{{rowData.mappedAttributes}}</span>
                                                        </div>

                                                    </td>
                                                    <td class="custom-description" style="width:35% ;">
                                                        <div *ngIf="rowData.description == 'null' ">
                                                            <span>-</span>
                                                        </div>
                                                        <div *ngIf="rowData.description != 'null' ">
                                                            <span pTooltip="{{rowData.description}}"
                                                                *ngIf="rowData.description?.length >= 35"
                                                                tooltipPosition="top">
                                                                {{rowData.description?.substring(0,35) +
                                                                "..." }}
                                                            </span>
                                                            <span *ngIf="rowData.description?.length < 35">
                                                                {{rowData.description}}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td class="p-jc-center" style="width:20% ;">
                                                        <div class="button-details custom-button-Details">
                                                            <button pButton tooltipPosition="top"
                                                                style="width: 24px;height: 24px;padding: 0px;"
                                                                icon="g-icons viewedit-icon" pTooltip="Edit"
                                                                class="background-transparent"
                                                                (click)="activeItem=rowData;editHostAttributeGroup()"
                                                                [disabled]="this.pagesService.applicationOnboardingReadOnlyFlag">
                                                            </button>
                                                            <button pButton tooltipPosition="bottom"
                                                                style="width: 24px;height: 24px;padding: 0px;"
                                                                icon="g-icons mapunmap-icon"
                                                                pTooltip="Map/Unmap Attributes"
                                                                class="background-transparent"
                                                                (click)="activeItem=rowData;mapUnmapHostAttributeGroup();">
                                                            </button>
                                                            <button pButton tooltipPosition="top"
                                                                style="width: 24px;height: 24px;padding: 0px;"
                                                                icon="g-icons delete-icon" pTooltip="Delete"
                                                                class="background-transparent"
                                                                (click)="activeItem=rowData;deleteHostAttributeGroup()"
                                                                [disabled]="this.pagesService.applicationOnboardingReadOnlyFlag">
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="emptymessage">
                                                <tr>
                                                    <td colspan="7" class="p-p-0 p-text-center"> <p-card>
                                                            <div style="margin-top: 5%;height: 70px;">No
                                                                matching records found</div>
                                                        </p-card> </td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                        <div style="position: relative;background: white;padding: 10px;display: inline-flex;"
                                            *ngIf="!attributeGroupFlag" class="p-col-12">
                                            <p-paginator #ppa [rows]="10"
                                                [totalRecords]="this.totalRecordsAttributeGroup"
                                                [rowsPerPageOptions]="[10,25,50]"
                                                (onPageChange)="attributeGroupPaginate($event,this.ApplicationOnboardingForm.value.searchAttributeGroupList)"></p-paginator>
                                            <div class="p-pl-3 p-pt-2"
                                                style="font-size: 13px;color: #505870;font-weight: 400;">
                                                Total Records: {{this.totalRecordsAttributeGroup}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </p-tabPanel>
                    <p-tabPanel header="Email Configuration">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class=" p-col-12 p-p-0">
                                <div class="ui-fluid p-formgrid p-grid">
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-p-0 p-offset-0 left">
                                        <div class=" p-fluid   p-field    ">
                                            <label
                                                [ngClass]="{'field-required': ApplicationOnboardingForm.get('emailAppDropdown')?.errors?.required}">
                                                Application
                                            </label> <span class='invalid-feedback-astrik'>*</span>
                                            <div class="p-fluid">
                                                <p-dropdown id="emailAppDropdownid" name="emailAppDropdown"
                                                    placeholder="Select a Application" placeholder="Select"
                                                    [options]="selectList" [filter]="true" filterBy="label"
                                                    [autoDisplayFirst]="false" formControlName="emailAppDropdown"
                                                    (onChange)="EmailTabListing();">
                                                    <ng-template let-items pTemplate="item">
                                                        <div *ngIf="items?.label?.length &lt;= 37;">
                                                            {{items.label}}
                                                        </div>
                                                        <div *ngIf="items?.label?.length > 37;"
                                                            pTooltip="{{items.label}}" tooltipPosition="bottom">
                                                            {{items.label.substring(0,37) + "..." }}
                                                        </div>
                                                    </ng-template>
                                                </p-dropdown>
                                            </div>
                                            <div class="invalid-feedback"
                                                *ngIf="ApplicationOnboardingForm.get('emailAppDropdown')!.invalid && (ApplicationOnboardingForm.get('emailAppDropdown')!.dirty || ApplicationOnboardingForm.get('emailAppDropdown')!.touched)">
                                                <div
                                                    *ngIf="ApplicationOnboardingForm.get('emailAppDropdown')?.errors?.required">
                                                    <label class="alert-icon alert-circle-icon"></label>
                                                    <medium class="p-error">
                                                        Application is required.</medium>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="!emailListFlag"
                                        class="p-col-12 p-sm-12 p-md-12 p-lg-8 p-xl-8 p-p-0 p-offset-undefined p-right">
                                        <div class=" p-fluid   p-field    " class="custom-attr-fluid">
                                            <span *ngIf="emailsearchflag"
                                                class=" p-input-icon-left custom-search-span p-mr-3">
                                                <input type="text" (keydown.enter)="$event.preventDefault()"
                                                    id="textField_id1" name="textField_name1"
                                                    formControlName="emailsearch" pInputText placeholder="Search"
                                                    class="p-inputtext inputtextHeight custom-search-width "
                                                    (input)="emailTemplateSearch($event)" />
                                                <i aria-hidden="true" class="pi pi-search"></i>
                                            </span>
                                            <div class="btnwrap custom-btn-height">
                                                <button pButton class="p-button-primary p-button" type="button"
                                                    (click)="emailComponent()" label="Create"
                                                    [disabled]="this.pagesService.applicationOnboardingReadOnlyFlag">
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="" *ngIf="emailListFlag" class="custom-emptyImage-size">
                                        <label class="d-icons emptyApp-icon"></label>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-p-0 p-offset-undefined left">
                                        <div class="  p-field    ">
                                            <div [ngClass]="{'content-section implementation': true}"
                                                *ngIf="!emailListFlag">
                                                <p-table #emailtableid name="table" [columns]="tablecols"
                                                    [value]="emailtable" [rows]="10" [scrollable]="true" [rows]="10"
                                                    [scrollable]="true" sortMode="single"
                                                    [rowsPerPageOptions]="[10,25,50]" selectionMode="Multiple"
                                                    [customSort]="true" (onFilter)="emailTemplateColumnFilter($event)"
                                                    (sortFunction)="emailTemplateSortData($event)" [customSort]="true">
                                                    <ng-template pTemplate="header">
                                                        <tr>
                                                            <th pSortableColumn='emailtemplateName' scope="col"
                                                                style="width: 40%;">
                                                                Template Name
                                                                <i class="p-sortable-column-icon icon-sort"
                                                                    ariaLabel="Activate to sort"
                                                                    ariaLabelDesc="Activate to sort in descending order"
                                                                    ariaLabelAsc="Activate to sort in ascending order"
                                                                    field='emailtemplateName'></i>
                                                                <p-columnFilter type="text" field="emailtemplateName"
                                                                    display="menu" class="p-ml-auto"></p-columnFilter>
                                                            </th>

                                                            <th pSortableColumn='components' scope="col"
                                                                style="width: 33%;">
                                                                Components
                                                                <i class="p-sortable-column-icon icon-sort"
                                                                    ariaLabel="Activate to sort"
                                                                    ariaLabelDesc="Activate to sort in descending order"
                                                                    ariaLabelAsc="Activate to sort in ascending order"
                                                                    field='components'></i>
                                                                <p-columnFilter type="text" field="components"
                                                                    display="menu" class="p-ml-auto"></p-columnFilter>
                                                            </th>
                                                            <th id="defaultButton" style="width: 20%;">
                                                                Default
                                                            </th>
                                                            <th id="actionButton" style="width: 20%;">
                                                                <div></div>
                                                            </th>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-rowData let-index="rowIndex">
                                                        <tr (mouseenter)="hoverIndex=index;highlight(index)"
                                                            (mouseleave)="hoverIndex=index;highlight(index)">
                                                            <td style="width: 40%;">{{rowData.templateName}}
                                                                <p-badge [value]="varDefault" styleClass="mr-2"
                                                                    class="custom-appbadge"
                                                                    *ngIf="rowData.default"></p-badge>
                                                            </td>
                                                            <td class="custom-Attrname-td" style="width: 33%;">
                                                                <span pTooltip="{{rowData.componentName}}"
                                                                    tooltipPosition="bottom"
                                                                    *ngIf="rowData.componentName?.length>=45">
                                                                    {{rowData.componentName?.substring(0,45)+"..."
                                                                    }}</span>
                                                                <span
                                                                    *ngIf="rowData.componentName?.length<45">{{rowData.componentName}}</span>
                                                            </td>
                                                            <td style="padding-top: 7px;padding-bottom: 0px;">

                                                                <p-inputSwitch
                                                                    [(ngModel)]="this['templateIsDefault' + index]"
                                                                    pTooltip="{{rowData.isDefault==true ?  'This template is default template' : rowData.isDefault==false ? 'This is not a default template' :''}}"
                                                                    tooltipPosition="top" [escape]="false"
                                                                    formControlName="inputswitch{{index}}"
                                                                    styleClass="p-inputswitch-sm"
                                                                    class="p-mr-2 custom-switch"
                                                                    (onChange)="setDefaultTemplate(rowData,index)"
                                                                    [disabled]="this.pagesService.applicationOnboardingReadOnlyFlag">
                                                                </p-inputSwitch>

                                                            </td>
                                                            <td style="width: 20%;">
                                                                <div class="button-details">
                                                                    <button pButton tooltipPosition="bottom"
                                                                        style="width: 24px;height: 24px;padding: 0px;"
                                                                        icon="g-icons viewedit-icon"
                                                                        pTooltip="Edit Template"
                                                                        class="background-transparent"
                                                                        (click)="activeItem2=rowData;editEmailComponent();"
                                                                        [disabled]="this.pagesService.applicationOnboardingReadOnlyFlag">
                                                                    </button>

                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </ng-template>

                                                    <ng-template pTemplate="emptymessage">
                                                        <tr>
                                                            <td colspan="7" class="p-p-0 p-text-center"> <p-card>
                                                                    <div style="margin-top: 5%;height: 70px;">No
                                                                        matching records found</div>
                                                                </p-card> </td>
                                                        </tr>
                                                    </ng-template>
                                                </p-table>
                                                <div style="position: relative;background: white;padding: 10px;display: inline-flex;"
                                                    class="p-col-12">
                                                    <p-paginator #ppe [rows]="10"
                                                        [totalRecords]="this.totalRecordsEmail"
                                                        [rowsPerPageOptions]="[10,25,50]"
                                                        (onPageChange)="emailtemplate($event,this.ApplicationOnboardingForm.value.emailsearch)"></p-paginator>
                                                    <div class="p-pl-3 p-pt-2"
                                                        style="font-size: 13px;color: #505870;font-weight: 400;">
                                                        Total Records: {{this.totalRecordsEmail}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </p-tabPanel>
                </p-tabView>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
            </div>
        </div>
    </form>
</div>