import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { PagesService } from '../pages.service';
import { MessageService } from 'primeng/api';
@Component({
    selector: 'jhi-ImportUser',
    templateUrl: './ImportUser.component.html',
    styleUrls: ['./ImportUser.component.scss'],
    providers: [DialogService, MessageService],
})
export class ImportUserComponent implements OnInit {

    ImportUserDetailsForm = this.fb.group({
        selectSwitch: [null],
    });
    enabledbtn1: boolean = false;
    selectSwitchList: any[] = [{ 'label': "AD User", 'value': 'AD' }, { 'label': "DB User", 'value': 'DB' }];
    defaultselect_selectSwitch: any;
    tenantLogo: File;
    imageFileFlag: boolean;
    default: boolean;
    solImage: boolean;
    solImageFileSize: boolean = false;
    productPrimaryImage: any;
    formValidFlag: boolean;
    imagevalid: boolean;
    value: string;
    messageFlag: boolean = false;
    fileName: any;
    defaultFlag: boolean = true;
    exelFile: File;
    msg: any[] = [];
    invalidTemplate: boolean = false;
    constructor(private router: Router, protected activatedRoute: ActivatedRoute, private fb: FormBuilder,
        public pagesService: PagesService, public dialogService: DialogService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private messageService: MessageService) {
        const navigation = this.router.getCurrentNavigation();
        const data = navigation?.extras?.state?.data;
        if (data) {
            this.ImportUserDetailsForm.patchValue(JSON.parse(data));
        }
        this.setDefaultValue_selectSwitch('AD');
    }
    ngOnInit(): void {
        this.enabledbtn1 = false;

    }


    setDefaultValue_selectSwitch(value?: any) {
        if (this.selectSwitchList.length && value) {
            this.defaultselect_selectSwitch = value;
            return this.defaultselect_selectSwitch
        }
        this.defaultselect_selectSwitch = this.selectSwitchList[0]?.value;
        return this.defaultselect_selectSwitch;
    }
    onSelectSolutionIcon(event: any): void {
        this.defaultFlag = false;
        this.invalidTemplate = false;
        this.exelFile = new File([event.files[0]], event.files[0].name, {
            type: event.files[0].type
        });
        this.imageFileFlag = true;
        const isvalid = /(\.xlsx)$/i;
        if (isvalid.test(this.exelFile.name)) {
            this.default = true;
            this.solImage = false;
            this.solImageFileSize = false;
            this.productPrimaryImage = event.currentFiles[0].objectURL;
            if (event.currentFiles[0].size > 5000000) {
                this.solImageFileSize = true;
                this.formValidFlag = false;
            }
            else {
                setTimeout(() => {
                }, 1000);
            }
        } else {
            this.imagevalid = true;
        }
    }

    onRemoveIcon(event: any, fileUploadPageIconChooseFilebtn): void {
        const index = fileUploadPageIconChooseFilebtn.files.indexOf(
            event
        );
        fileUploadPageIconChooseFilebtn.files.splice(index, 1);
        this.productPrimaryImage = "";
        this.imagevalid = false;
        this.imageFileFlag = false;
        this.solImageFileSize = false;
        this.invalidTemplate = false;
        this.defaultFlag = true;
        setTimeout(() => {
        }, 1000);
    }

    downloadTemplate() {
        const reqBody = {};
        reqBody['templateType'] = this.defaultselect_selectSwitch;
        this.messageFlag = true;
        this.pagesService.downloadTemplate(reqBody).subscribe(
            (response: any) => {
                this.messageFlag = true;
                const blob = new Blob([response.body], { type: 'application/octet-stream' });
                let url = window.URL.createObjectURL(blob);
                let anchor = document.createElement("a");
                if (reqBody['templateType'] == 'AD') {
                    anchor.download = "AdUserTemplate.xlsx";
                    setTimeout(() => {
                        this.messageFlag = true;
                        this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'Template Downloaded Successfully.' });
                        setTimeout(() => {
                            this.messageFlag = false;
                        }, 3000);
                    }, 6000);
                } else {
                    anchor.download = "DbUserTemplate.xlsx";
                    setTimeout(() => {
                        this.messageFlag = true;
                        this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'Template Downloaded Successfully.' });
                        setTimeout(() => {
                            this.messageFlag = false;
                        }, 3000);
                    }, 6000);
                }
                anchor.href = url;
                anchor.click();

            }
        );
    }
    importUsers() {
        this.msg = [];
        const formData = new FormData();
        formData.append('fileName', this.exelFile.name);
        formData.append('excelFile', this.exelFile);
        formData.append('tenantId', this.pagesService?.tenantId);
        formData.append('adDbFlag', this.defaultselect_selectSwitch);
        this.messageFlag = true;
        this.pagesService.importUsers(formData).subscribe(
            results => {

                if (results?.success?.message == "Action Successfull") {
                    this.ref.close(results?.success?.requestId);
                } else if (results?.success?.message == "Import request has been sent for approval") {
                    this.ref.close();
                    this.navigateToMyReq('Import user request has been sent for approval');
                } else if (results?.error?.messageCode == "CIAM 103") {
                    this.invalidTemplate = true;
                }

            }
        );

    }
    navigateToMyReq(msg: any): void {
        const navigationExtras: NavigationExtras = {
            state: {
                msg: msg,
            },
        };
        this.router.navigate(['/pages/myrequestslisting'], navigationExtras);
    }


}

