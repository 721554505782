import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PagesService } from '../pages.service';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
@Component({
  selector: 'jhi-deactiverole',
  templateUrl: './ViewTransactionDetails.component.html',
  styleUrls: ['./ViewTransactionDetails.component.scss'],
})
export class ViewTransactionDetailsComponent implements OnInit {
  ViewTransactionDetailsForm = this.fb.group({});
  activeId: any[] = [];
  pageloadvalues: any;
  transactionId: any;
  firstName: any;
  lastName: any;
  username: any;
  attributeList: any[] = [];
  messageFlag1: boolean = false;
  action: any;
  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    private messageService: MessageService,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.ViewTransactionDetailsForm.patchValue(JSON.parse(data));
    }
  }

  ngOnInit(): void {
    this.transactionId = this.config?.data?.id;
    this.action = this.config?.data?.action;
    this.getDetails();
  }

  onCcprimebutton($event: UntypedFormGroup): void {
    this.ref.close();
  }

  checkArray(varObj: any) {
    if (varObj instanceof Array) {
      return true;
    }
    else {
      return false;
    }
  }

  getDetails(): void {
    const reqBody = {};
    reqBody['tenantId'] = this.pagesService.tenantId;
    reqBody['transactionId'] = this.transactionId;
    const formValues = reqBody;
    this.pagesService.getTransactionDetails(formValues).subscribe(results => {
      const responseData = results?.success?.result;
      let obj = JSON.parse(responseData);

      this.attributeList = [...Object.entries(obj)];

    });
  }

  downloadReport() {
    const reqBody = {};
    reqBody["tenantId"] = this.pagesService.tenantId;
    reqBody["transactionId"] = this.transactionId;
    this.messageFlag1 = true;
    this.pagesService.downloadReport(reqBody).subscribe(
      (response: any) => {
        this.messageFlag1 = true;
        const blob = new Blob([response.body], { type: 'application/octet-stream' });
        let url = window.URL.createObjectURL(blob);
        let anchor = document.createElement("a");
        anchor.download = "userReport.xlsx";
        setTimeout(() => {
          this.messageFlag1 = true;
          this.messageService.add({ key: 'myKey2', severity: 'success', detail: 'File Downloaded Successfully.' });
          setTimeout(() => {
            this.messageFlag1 = false;
          }, 3000);
        }, 6000);

        anchor.href = url;
        anchor.click();

      }
    );
  }
}
