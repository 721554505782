import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Message, MessageService, PrimeNGConfig, SortEvent, FilterService, SelectItem, FilterMatchMode, TreeNode } from 'primeng/api';
import { PagesService } from '../pages.service';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { CreateComponent } from '../CreateComponent/CreateComponent.component';
import { EditComponent } from '../EditComponent/EditComponent.component';
import { CreateAttributeTypeComponent } from '../CreateAttributeType/CreateAttributeType.component';
import { EditApplicationComponent } from '../EditApplication/EditApplication.component';
import { CreateApplicationComponent } from '../CreateApplication/CreateApplication.component';
import { EditAttributeTypeComponent } from '../EditAttributeType/EditAttributeType.component';
import { CreateApplicationRoleComponent } from '../CreateApplicationRole/CreateApplicationRole.component';
import { EditApplicationRoleComponent } from '../EditApplicationRole/EditApplicationRole.component';
import { CreateHostResourcesEndpointComponent } from '../CreateHostResourcesEndpoint/CreateHostResourcesEndpoint.component';
import { EditHostApplicationResourceComponent } from '../EditHostApplicationResource/EditHostApplicationResource.component';
import { DeleteHostApplicationEndpointComponent } from '../DeleteHostApplicationEndpoint/DeleteHostApplicationEndpoint.component';
import { Paginator } from 'primeng/paginator';
import { EndpointMappingComponent } from '../EndpointMapping/EndpointMapping.component';
import { REBAC_UM_ENTITLEMENT_FLAG } from "app/app.constants";
import { DatePipe } from '@angular/common';
import { InviteEmailComponent } from '../InviteEmail/InviteEmail.component';
import { CreateHostAttributeComponent } from '../CreateHostAttribute/CreateHostAttribute.component';
import { EditHostAttributeComponent } from '../EditHostAttribute/EditHostAttribute.component';
import { DeleteHostAttributeComponent } from '../DeleteHostAttribute/DeleteHostAttribute.component';
import { EmailTemplateMappingComponent } from '../EmailTemplateMapping/EmailTemplateMapping.component';
import { CreateHostAttributeGroupComponent } from '../CreateHostAttributeGroup/CreateHostAttributeGroup.component';
import { EditHostAttributeGroupComponent } from '../EditHostAttributeGroup/EditHostAttributeGroup.component';
import { DeleteHostAttributeGroupComponent } from '../DeleteHostAttributeGroup/DeleteHostAttributeGroup.component';
import { HostAttributeGroupMappingComponent } from '../HostAttributeGroupMapping/HostAttributeGroupMapping.component';
import { EditEmailTemplateComponent } from '../EditEmailTemplate/EditEmailTemplate.component';
import { TreeTableModule } from 'primeng/treetable';
import { ViewHostHierarchyAttributesComponent } from '../ViewHostHierarchyAttributes/ViewHostHierarchyAttributes.component';
import { DownloadAttributeComponent } from './DownloadAttribute/DownloadAttribute.component';
import { AddNextLevelHierarchyAttributesComponent } from '../AddNextLevelHierarchyAttributes/AddNextLevelHierarchyAttributes.component';

interface Column {
  field: string;
  header: string;
}
@Component({
  selector: 'jhi-applicationonboarding',
  templateUrl: './ApplicationOnboarding.component.html',
  styleUrls: ['./ApplicationOnboarding.component.scss'],
  providers: [MessageService, DialogService, FilterService],
})
export class ApplicationOnboardingComponent implements OnInit {
  ref!: DynamicDialogRef;
  ApplicationOnboardingForm = this.fb.group({
    tabview: [0],
    select: [null, [Validators.required]],
    emailAppDropdown: [null, [Validators.required]],
    applicationSearch: [null, []],
    textField1: [null, []],
    searchAttributeGroupList: [null, []],
    emailsearch: [null, []],
    componentSearch: [null, []],
    select1: [null, [Validators.required]],
    search1: [null, []],
    select2: [null, [Validators.required]],
    select3: [null, [Validators.required]],
    pickList: [null, []],
    textField2: [null, []],
    inputswitch: [null, []],
    select4: [null, [Validators.required]],
    select5: [null, [Validators.required]],
    textFieldEndpoint: [null, []],
    select6: [null, [Validators.required]],
    textFieldAttribute: [null, []],
    applicationAttrgroupdropdown: [null, [Validators.required]],
    selfServiceSwitch: [null, []],
  });
  pageloadvalues: any;
  ccprimebreadcrumbList = [{ label: `Applications` },
  { label: `Application Onboarding` },
  ]
  homeiconccprimebreadcrumb = { icon: 'pi pi-home', routerLink: ['/'] };
  selectList: any[] = [];
  searchList: any[] = [];
  tableList: any[] = [];
  tableList2: any[] = [];
  tableList3: any[] = [];
  tableList4: any[] = [];
  tableList5: any[] = [];
  tableList6: any[] = [];
  tableList7: any[] = [];
  tableList8: any[] = [];
  tableList9: any[] = [];
  tableListAttribute: any[] = [];
  tablecolsAttribute: any[] = [{ field: "type", header: "Type" },
  { field: "name", header: "Name" },
  { field: "group", header: "Group" },
  { field: "access", header: "Access Type" },
  { field: "default", header: "Default Attribute" }
  ];
  tablecols: any[] = [{ field: "type", header: "Type" },
  { field: "name", header: "Name" },
  { field: "group", header: "Group" },];
  select1List: any[] = [];
  search1List: any[] = [];
  table1List: any[] = [];
  table1cols: any[] = [{ field: "type", header: "Type" },
  { field: "name", header: "Name" },
  { field: "group", header: "Group" },];
  select2List: any[] = [];
  select3List: any[] = [];
  select5List: any[] = [];
  pickListList: any[] = [];
  pickListList1: any[] = [];
  messageFlag = true;
  applicationId: any;
  resourceApplicationId: any;
  msgs: Message[] = [];
  pickListtargetList: any[] = [];
  pickListtargetList1: any[] = [];
  tableList1: any[] = [];
  btn = true;
  dropdown = true;
  activeItem: any;
  activeItem1: any;
  activeItem2: any;
  selectedIndex: any;
  flag2 = true;
  flag1 = true;
  flag3 = true;
  flag4 = true;
  groupID: any[] = [];
  appId: any;
  exMapList: any[] = [];
  mapList: any[] = [];
  mList: any[] = [];
  groupArr: [];
  errorFlag = true;
  varDefault = "Default";
  varSaas = "SaaS";
  switchValue: boolean = false;
  resourceFlag = true;
  componentId: any;
  resourceErrorFlag = true;
  pageSize: number = 10; //default pageSize 10
  pageNumber: number = 1;
  sortByColumn = '';
  sorting = '';
  totalRecords: number = 0;
  oldTotalRec = 0;
  pageSizeComponent: number = 10; //default pageSize 10
  pageNumberComponent: number = 1;
  sortByColumnComponent = '';
  sortingComponent = '';
  totalRecordsComponent: number = 0;
  oldTotalRecComponent = 0;
  @ViewChild('pp') paginator: Paginator;
  @ViewChild('ppC') paginatorComponent: Paginator;
  @ViewChild('ppe') paginatorEmail: Paginator;
  customFilterFlag = false;
  filterReqObj = {};
  searchStr: string;
  customFilterFlagComponent = false;
  filterReqObjComponent = {};
  searchStrComponent: string;
  rebacFlag: string;
  matchModeOptions: SelectItem[];
  attributeFlag = true;
  attrApplicationId: any;
  pageSizeAttribute: number = 10; //default pageSize 10
  pageNumberAttribute: number = 1;
  sortByColumnAttribute = '';
  sortingAttribute = '';
  totalRecordsAttribute: number = 0;
  oldTotalRecAttribute = 0;
  @ViewChild('ppa') paginatorAttribute: Paginator;
  customFilterFlagAttribute = false;
  filterReqObjAttribute = {};
  searchStrAttribute: string;
  emailtable: any[];
  emailListFlag = true;
  emailFilterTable: any[];
  activeItemAttribute: any;
  pageNumberEmail: number = 1;
  sortByColumnEmail = '';
  sortingEmail = '';
  totalRecordsEmail: number = 0;
  oldTotalRecEmail = 0;
  searchStrEmail: string;
  pageSizeEmail: number = 10;
  emaiApplicationId: any;
  filterReqObjEmail: {};
  customFilterFlagEmail = false;
  emailsearchflag = false;
  tableListAttributeGroup: any[];
  pageNumberAttributeGroup: number;
  attributeGroupFlag = true;
  pageSizeAttributeGroup: number = 10;
  sortByColumnAttributeGroup: string;
  oldTotalRecAttributeGroup: number;
  sortingAttributeGroup: string;
  totalRecordsAttributeGroup: number;
  searchStrAttributeGroup: string;
  ciamaction: any;
  action: string;
  selfServiceValue: boolean = false;
  files!: TreeNode[];

  cols!: Column[];
  loading: boolean = false;
  filterMode = 'lenient';
  attributeTotalRecords: any;
  attributeList: any[] = [];
  response: ({ data: { attributeId: string; attributeResourceName: string; ciamhostApplicationId: string; code: any; createdBy: string; createdDate: string; lastModifiedBy: string; lastModifiedDate: string; attributeTypeId: string; isDefault: string; isPrivate: string; attributeTypeName: string; attributeResourceId: string; children: any[]; levelId: number; orderId: number; parentId: string; attributeGroups: any[]; deleted: boolean; }; children?: undefined; } | { data: { attributeId: string; attributeResourceName: string; ciamhostApplicationId: string; code: any; createdBy: string; createdDate: string; lastModifiedBy: string; lastModifiedDate: string; attributeTypeId: string; isDefault: string; isPrivate: string; attributeTypeName: string; attributeResourceId: string; children?: undefined; levelId?: undefined; orderId?: undefined; parentId?: undefined; attributeGroups?: undefined; deleted?: undefined; }; children: { data: { attributeId: string; attributeResourceName: string; ciamhostApplicationId: string; code: any; createdBy: string; createdDate: string; lastModifiedBy: string; lastModifiedDate: string; attributeTypeId: string; isDefault: string; isPrivate: string; attributeTypeName: string; attributeResourceId: string; children: any[]; levelId: number; orderId: number; parentId: string; attributeGroups: { attributeGroupId: string; applicationId: string; attributeGroupName: string; attributeGroupType: any; description: any; createdBy: string; createdDate: string; lastModifiedBy: string; lastModifiedDate: string; deleted: boolean; }[]; }; }[]; })[];
  childList: any[] = [];
  filtersData = {};
  filesPaginate: any[] = [];
  filesFilter: any[] = [];
  responseDataSearch: any[] = [];
  tempChild: any;
  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public dialogService: DialogService,
    public pagesService: PagesService,
    private messageService: MessageService,
    private config: PrimeNGConfig,
    private datePipe: DatePipe,
    private cd: ChangeDetectorRef
  ) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.ApplicationOnboardingForm.patchValue(JSON.parse(data));
    }
  }

  ngOnInit(): void {
    this.files = [];
    // console.log(this.ApplicationOnboardingForm.value.selfServiceSwitch);
    this.matchModeOptions = [
      {
        label: 'Date Before',
        value: FilterMatchMode.DATE_BEFORE
      },
      {
        label: 'Date After',
        value: FilterMatchMode.DATE_AFTER
      },
    ];
    this.rebacFlag = REBAC_UM_ENTITLEMENT_FLAG + "";
    this.ApplicationOnboardingForm.get('select5').disable();
    this.getApplicationVersionList();
    this.gettableList();

    this.cols = [
      { field: 'attributeResourceName', header: 'Attribute Name' },
      { field: 'attributeResourceId', header: 'Attribute Id' },
      { field: 'attributeTypeName', header: 'Type' },
      { field: 'attributeGroupName', header: 'Group' },
      { field: 'isPrivate', header: 'Access Type' },
      { field: 'isDefault', header: 'Default Attribute' }
    ];
    //   this.files= [
    //     {
    //         "data": {
    //             "code": null,
    //             "lastModifiedDate": 1715755050499,
    //             "orderId": 0,
    //             "lastModifiedBy": "c-tejasj",
    //             "isPrivate": "Private",
    //             "ciamhostApplicationId": "25e6a6c0-0466-11ee-be56-0242ac120002",
    //             "parentId": "0",
    //             "attributeId": "f1fa83b6-d8c0-4fe7-9b6f-b5d514254634",
    //             "attributeTypeId": "e30f48fa-56bc-41cd-952b-dd6ed0778c86",
    //             "attributeResourceId": "1",
    //             "isDefault": "No",
    //             "createdDate": 1715754912324,
    //             "isDeleted": false,
    //             "createdBy": "c-tejasj",
    //             "levelId": 0,
    //             "attributeTypeName": "Attribute One",
    //             "attributeResourceName": "AttrO",
    //             "attributeGroups": []
    //         },
    //         "children": [
    //             {
    //                 "data": {
    //                     "code": null,
    //                     "lastModifiedDate": 1716298168228,
    //                     "orderId": 0,
    //                     "lastModifiedBy": "c-tejasj",
    //                     "isPrivate": "Private",
    //                     "ciamhostApplicationId": "25e6a6c0-0466-11ee-be56-0242ac120002",
    //                     "parentId": "1",
    //                     "attributeId": "4379b463-5472-44c3-8882-b04f50fd97bb",
    //                     "attributeTypeId": "e30f48fa-56bc-41cd-952b-dd6ed0778c86",
    //                     "attributeResourceId": "2",
    //                     "isDefault": "No",
    //                     "createdDate": 1716298168228,
    //                     "isDeleted": false,
    //                     "createdBy": "c-tejasj",
    //                     "levelId": 0,
    //                     "attributeTypeName": "Attribute One",
    //                     "attributeResourceName": "Attr2",
    //                     "attributeGroups": [
    //                         {
    //                             "attributeGroupId": "6b9403d2-3b0f-4233-819e-d2fdd2d65cea",
    //                             "applicationId": "25e6a6c0-0466-11ee-be56-0242ac120002",
    //                             "attributeGroupName": "Attr Group 2",
    //                             "attributeGroupType": null,
    //                             "description": null,
    //                             "createdBy": "c-tejasj",
    //                             "createdDate": "2024-05-15 06:43:25",
    //                             "lastModifiedBy": "c-tejasj",
    //                             "lastModifiedDate": "2024-05-15 06:43:25",
    //                             "deleted": false
    //                         }
    //                     ]
    //                 },
    //                 "children": [
    //                     {
    //                         "data": {
    //                             "code": null,
    //                             "lastModifiedDate": 1724067046207,
    //                             "orderId": 0,
    //                             "lastModifiedBy": "simranc",
    //                             "isPrivate": "Public",
    //                             "ciamhostApplicationId": "25e6a6c0-0466-11ee-be56-0242ac120002",
    //                             "parentId": "2",
    //                             "attributeId": "c4af6e51-490b-4c93-9a89-8eebd0025eaf",
    //                             "attributeTypeId": "129c49f1-1ed1-49d9-8548-8591a315d6fd",
    //                             "attributeResourceId": "3",
    //                             "isDefault": "No",
    //                             "createdDate": 1724067046207,
    //                             "isDeleted": false,
    //                             "createdBy": "simranc",
    //                             "levelId": 0,
    //                             "attributeTypeName": "Attr2",
    //                             "attributeResourceName": "test",
    //                             "attributeGroups": []
    //                         },
    //                         "children": []
    //                     }
    //                 ]
    //             }
    //         ]
    //     }
    // ];
    this.loading = true;
  }

  highlight(index: any) {
    this.selectedIndex = index;
  }

  mouseLeave() {
    this.selectedIndex = null;
  }

  handleChangetabview(e: any): void {
    this.ApplicationOnboardingForm.patchValue({ "tabview": e.index });
    this.ApplicationOnboardingForm.controls.applicationSearch.reset();
    this.ApplicationOnboardingForm.controls.textField1.reset();
    this.tableList = this.tableList1;
    this.tableList2 = this.tableList3;
    this.tableList6 = this.tableList7;
    this.tableList4 = this.tableList5;
  }

  //APPLICATION TAB
  // getting application version list for add application form
  getApplicationVersionList(): void {
    this.selectList = [];
    this.pagesService.getApplicationVersion().subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.selectList.push(data);
        });
        this.selectList = [...this.selectList];
      }
    });
  }

  //get application list API in application tab
  gettableList(): void {
    this.tableList = [];
    this.tableList1 = [];
    this.pagesService.getHostApplicationlist().subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        this.tableList = responseData;
        this.tableList1 = responseData;
        this.errorFlag = false;
      } else {
        this.errorFlag = true;
      }
    });
  }

  // filter method for application list
  HostApplicationFilterMethod(event: any) {
    let filtered: any[] = [];
    let items: any;
    let cc;
    let query = event;
    if (query.length > 0) {
      this.tableList1.forEach(element => {
        items = element;
        if (items.applicationName?.toLowerCase().includes(query.toLowerCase()) || items.applicationVersion?.version.toLowerCase().includes(query.toLowerCase()) || items.description?.toLowerCase().includes(query.toLowerCase())) {
          filtered.push(items);
        }
      });
      this.tableList = filtered;
    }
    else {
      console.log("i am in else");
      this.tableList = this.tableList1;
    }
  }

  //Create Application fucntionality- dialaog box
  showCreateApplication() {
    this.ref = this.dialogService.open(CreateApplicationComponent, {
      data: { id: this.ApplicationOnboardingForm.value.select1 },
      header: 'Create Application',
      width: '50%',
      contentStyle: { 'border-radius': '4px' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == ' Application Created Successfully') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.gettableList();
        this.getApplicationVersionList();
      }
      else if (msg == 'Application Creation Failed' || msg == 'Application already exist') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
    });

  }

  // edit application functionality- dialog box
  EditCreateApplication() {
    this.ref = this.dialogService.open(EditApplicationComponent, {
      data: { saasApp: this.activeItem?.saasApplication, appName: this.activeItem.applicationName, appversion: this.activeItem.applicationVersion?.id, appdesc: this.activeItem?.description, id: this.activeItem?.id, logoFilePath: this.activeItem?.logoFilePath, videoFilePath: this.activeItem?.videoFilePath, externalStorageRequired: this.activeItem?.externalStorageRequired, storageType: this.activeItem?.storageType?.id },
      header: 'Edit Application',
      width: '50%',
      contentStyle: {},
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Application Details Saved Successfully') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.gettableList();
        this.getApplicationVersionList();
      }
      else if (msg == 'Application Name already exist' || msg == 'Application Editing Failed') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
    });
  }


  //ATTRIBUTE TYPE TAB
  // get attribute list API
  AttributeTypeListing(): void {
    this.tableList4 = [];
    this.tableList5 = [];
    this.flag1 = false;
    this.applicationId = this.ApplicationOnboardingForm.value.select2;
    this.pagesService.getAttributeTypeListing(this.applicationId).subscribe((results: any) => {
      this.tableList4 = [];
      this.tableList5 = [];
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        this.tableList4 = responseData;
        this.tableList5 = responseData;
      }
    });

  }

  //Attribute search filter functionality
  AttributeTypeFilterMethod(event: any) {
    let filtered: any[] = [];
    let items: any;
    let cc;
    let query = event;
    if (query.length > 0) {
      this.tableList5.forEach(element => {
        items = element;
        if (items.code?.toLowerCase().includes(query.toLowerCase()) || items.attributeTypeName?.toLowerCase().includes(query.toLowerCase()) || items.description?.toLowerCase().includes(query.toLowerCase())) {
          filtered.push(items);
        }
      });
      this.tableList4 = filtered;
    }
    else {
      this.tableList4 = this.tableList5;
    }
  }

  // Create Attribute type functionality -dialog box
  showCreateAttributeType() {
    this.ref = this.dialogService.open(CreateAttributeTypeComponent, {
      data: { id: this.ApplicationOnboardingForm.value.select2 },
      header: 'Create Attribute Type',
      width: '60%',
      contentStyle: { 'max-height': '400px', overflow: 'visible' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Attribute Type Created Successfully') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.AttributeTypeListing();
      }
      else if (msg == 'Attribute type Creation Failed' || msg == 'Attribute type name already exist' || msg == 'Attribute code name already exist') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
    });
  }

  // edit attribute type functionality- dialog box
  EditAttributeType() {
    this.ref = this.dialogService.open(EditAttributeTypeComponent, {
      data: {
        applicationid: this.ApplicationOnboardingForm.value.select2,
        attributeid: this.activeItem1.id, attributeName: this.activeItem1.attributeTypeName,
        code: this.activeItem1.code, description: this.activeItem1.description
      },
      header: 'Edit Attribute Type',
      width: '60%',
      contentStyle: { 'max-height': '350px', overflow: 'visible' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Attribute Type Details Saved Successfully') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.AttributeTypeListing();
      }
      else if (msg == 'Attribute type Creation Failed' || msg == 'Attribute type name already exist' || msg == 'Attribute code name already exist') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
    });
  }

  //COMPONENT TAB
  // Component tab listing API
  ComponentListing(): void {
    this.tableList2 = [];
    this.tableList3 = [];
    this.flag2 = false;
    this.pageNumberComponent = 1;
    this.sortByColumnComponent = '';
    this.sortingComponent = '';
    this.totalRecordsComponent = 0;
    this.oldTotalRecComponent = 0;
    this.applicationId = this.ApplicationOnboardingForm.value.select;
    const str = this.ApplicationOnboardingForm.value.textFieldEndpoint;
    if (str == null) {
      this.searchStrComponent = '';
    }
    else {
      this.searchStrComponent = str;
    }
    let reqBody = {};
    reqBody['applicationId'] = this.ApplicationOnboardingForm.value.select;
    reqBody['page'] = 1;
    reqBody['rows'] = this.pageSizeComponent;
    reqBody['searchString'] = this.searchStrComponent;
    reqBody['sorting'] = "descending";
    const formValues = reqBody;
    this.pagesService.getComponentListingPagination(formValues).subscribe((results: any) => {
      this.tableList2 = [];
      this.tableList3 = [];
      const responseData = results?.success?.result?.records;
      this.totalRecordsComponent = results?.success?.result?.recordsCount;
      this.oldTotalRecComponent = results?.success?.result?.recordsCount;
      if (responseData && responseData.length > 0) {
        responseData.forEach(
          customer => (customer.updatedOn = new Date(customer.updatedOn))
        );
        this.tableList2 = responseData;
        this.tableList3 = responseData;
      }
    });
  }

  select() {
    this.ApplicationOnboardingForm.controls.select5.reset();
    this.resourceFlag = true;
  }
  getComponentDropdown(): void {
    this.pageSize = 10; //default pageSize 10
    this.pageNumber = 1;
    this.sortByColumn = '';
    this.sorting = '';
    this.totalRecords = 0;
    this.oldTotalRec = 0;
    this.ApplicationOnboardingForm.get('select5').enable();
    this.appId = this.ApplicationOnboardingForm.value.select4;
    const compData = { applicationId: this.appId };
    this.pagesService.getHostComponentEndpoint(compData).subscribe(results => {
      this.ApplicationOnboardingForm.controls.select5.reset();
      this.select5List = [];
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.select5List.push(data);
        });
        this.select5List = [...this.select5List];
      }
    });
  }
  //getting application resource list from the API
  ResourceEnpointListing(): void {
    this.pageNumber = 1;
    this.sortByColumn = '';
    this.sorting = '';
    this.totalRecords = 0;
    this.oldTotalRec = 0;
    this.resourceFlag = false;
    this.resourceErrorFlag = false;
    this.appId = this.ApplicationOnboardingForm.value.select4;
    this.componentId = this.ApplicationOnboardingForm.value.select5;
    const str = this.ApplicationOnboardingForm.value.textFieldEndpoint;
    if (str == null) {
      this.searchStr = '';
    }
    else {
      this.searchStr = str;
    }
    let reqBody = {};
    reqBody['applicationId'] = this.ApplicationOnboardingForm.value.select4;
    reqBody['componentId'] = this.ApplicationOnboardingForm.value.select5;
    reqBody['page'] = 1;
    reqBody['rows'] = this.pageSize;
    reqBody['serchString'] = this.searchStr;
    reqBody['sortByColumn'] = this.sortByColumn;
    reqBody['sorting'] = "descending";
    const formValues = reqBody;
    this.pagesService.getHostApplicationEndpointPagination(formValues).subscribe(results => {
      this.tableList8 = [];
      this.tableList9 = [];
      const responseData = results.success.result.records;
      if (responseData && responseData.length > 0) {
        this.totalRecords = results.success.result.recordsCount;
        this.oldTotalRec = results.success.result.recordsCount;
        this.tableList8 = responseData;
        this.tableList9 = responseData;
        this.resourceErrorFlag = false;
      }
      else {
        this.resourceErrorFlag = true;
      }
    });
  }
  //Search Filter for component listing
  ComponentFilterMethod(event: any) {
    let filtered: any[] = [];
    let items: any;
    let cc;
    let query = event.target.value;
    this.tableList2 = [];
    this.tableList3 = [];
    this.paginatorComponent.changePage(0);
    let reqBody = {};
    reqBody['applicationId'] = this.ApplicationOnboardingForm.value.select;
    reqBody['page'] = 1;
    reqBody['rows'] = this.pageSizeComponent;
    reqBody['searchString'] = query;
    if (query == "") {
      reqBody['sorting'] = "descending";
    }
    const data = reqBody;
    this.pagesService.getComponentListingPagination(data).subscribe({
      next: (results: any) => {
        this.tableList2 = [];
        this.tableList3 = [];
        const responseData = results?.success?.result?.records;
        this.totalRecordsComponent = results.success.result.recordsCount;
        this.oldTotalRecComponent = results.success.result.recordsCount;
        if (responseData && responseData.length > 0) {
          responseData.forEach(
            customer => (customer.updatedOn = new Date(customer.updatedOn))
          );
          this.tableList2 = responseData;
          this.tableList3 = responseData;
        }
      },
      error: error => {
        this.totalRecordsComponent = 0;
        this.oldTotalRecComponent = 0;
      }
    });
  }

  // create component functionality -dialog box
  showCreateComponent() {
    this.ref = this.dialogService.open(CreateComponent, {
      data: { id: this.ApplicationOnboardingForm.value.select },
      header: 'Create Application Component',
      width: '992px',
      contentStyle: { 'max-height': 'auto', overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Application Component Created Successfully') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.ApplicationOnboardingForm.controls.select3.reset();
        this.flag3 = true;
        this.ComponentListing();
      }
      else if (msg == 'Application Component Creation Failed' || msg == 'Application Component Name already exist') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
    });

  }

  //edit component functionality -dialog box
  showEditComponent() {
    this.ref = this.dialogService.open(EditComponent, {
      data: { id: this.ApplicationOnboardingForm.value.select, activeData: this.activeItem },
      header: 'Edit Application Component',
      width: '992px',
      contentStyle: { 'max-height': 'auto', overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Application Component Details Saved Sucessfully') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.ComponentListing();
      }
      else if (msg == 'Failed to Edit Application Component' || msg == 'Application Component Name already exist') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
    });
  }

  //APPLICATION ROLE TAB
  //get application role list API
  ApplicationRoleListing(): void {
    this.tableList6 = [];
    this.tableList7 = [];
    this.flag3 = false;
    this.applicationId = this.ApplicationOnboardingForm.value.select3;
    this.pagesService.getApplicationRoleListing(this.applicationId).subscribe((results: any) => {
      this.tableList6 = [];
      this.tableList7 = [];
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        this.tableList6 = responseData;
        this.tableList7 = responseData;
        for (let i = 0; i < this.tableList6.length; i++) {
          this.ApplicationOnboardingForm.addControl("selfServiceSwitch" + i, this.fb.control(null, []));
          console.log(this.tableList6[i].applicationRoleName);
          if (this.tableList6[i].selfService == "true") {
            console.log(this.tableList6[i].selfService);
            console.log("i am in true");
            this["selfServiceValue" + i] = true;
          }
          else if (this.tableList6[i].selfService == "false") {
            console.log("i am in false");
            this["selfServiceValue" + i] = false;
          }
        }
      }
    });
  }

  //application role search filter functionality
  ApplicationRoleFilterMethod(event: any) {
    let filtered: any[] = [];
    let items: any;
    let cc;
    let query = event;
    let def: any;
    if (query.length > 0) {
      this.tableList7.forEach(element => {
        items = element;
        if (items.default) {
          def = "default";
        }
        if (items.applicationRoleName?.toLowerCase().includes(query.toLowerCase()) || items.description?.toLowerCase().includes(query.toLowerCase()) || def?.includes(query.toLowerCase()) || items?.mappedEndPointCount.includes(query.toLowerCase())) {
          filtered.push(items);
        }
        def = "";
      });
      this.tableList6 = filtered;
    }
    else {
      this.tableList6 = this.tableList7;
    }
  }

  // create application role functionality -dialog box
  showCreateApplicationRole() {
    this.ref = this.dialogService.open(CreateApplicationRoleComponent, {
      data: { id: this.ApplicationOnboardingForm.value.select3 },
      header: 'Create Application Role',
      width: '672px',
      contentStyle: { 'max-height': 'auto', overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Application Role Created Successfully') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.ApplicationRoleListing();
      }
      else if (msg == 'Application Role Creation Failed' || msg == 'Application Role already exist') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
    });
  }

  // edit application role functionality -dialog box
  showEditApplicationRole() {
    this.ref = this.dialogService.open(EditApplicationRoleComponent, {
      data: { id: this.ApplicationOnboardingForm.value.select3, activeData: this.activeItem2 },
      header: 'Edit Application Role',
      width: '600px',
      contentStyle: { 'max-height': 'auto', overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'success') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'Application role details saved successfully.' });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.ApplicationRoleListing();
      }
      else if (msg == 'failed') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Application role failed to saved' });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
    });
  }
  //Create resource functionality -dialog box
  createResourceEndpoint() {
    this.ref = this.dialogService.open(CreateHostResourcesEndpointComponent, {
      data: { appId: this.appId, comId: this.ApplicationOnboardingForm.value.select5 },
      header: 'Create Resource',
      width: '70%',
      contentStyle: { 'max-height': '50%', overflow: 'visible', 'border-radius': '4px' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Resource Created Successfully') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.ResourceEnpointListing();
      }
      else if (msg == 'Resource Creation Failed' || msg == 'Resource name already exist') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
    });
  }
  // Edit resource functionality -Dialog box
  editResource() {
    this.ref = this.dialogService.open(EditHostApplicationResourceComponent, {
      data: { appId: this.appId, comId: this.ApplicationOnboardingForm.value.select5, ep: this.activeItem1.resourceName, desc: this.activeItem1.endPointUrl, method1: this.activeItem1.method, endID: this.activeItem1.endpointId, roleRequired: this.activeItem1.applicationRoleRequired },
      header: 'Edit Resource',
      width: '70%',
      contentStyle: { 'max-height': '350px', overflow: 'visible' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Resource Edited Successfully') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.ResourceEnpointListing();
      }
      else if (msg == 'Resource Edit Failed' || msg == 'Resource name already exist') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }

    });
  }
  // delete resource functionality -dialog box
  deleteResource() {
    this.ref = this.dialogService.open(DeleteHostApplicationEndpointComponent, {
      data: { id: this.activeItem.resourceName, appId: this.appId, comId: this.ApplicationOnboardingForm.value.select5, eID: this.activeItem.endpointId },
      header: 'Delete Resource',
      width: '40%',
      contentStyle: { 'max-height': '300px', overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Resource Deleted Successfully') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.ResourceEnpointListing();
      }
      else if (msg == 'Resource Deletion Failed') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
    });
  }
  resourceEndpointPaginate(event, search) {
    let str = '';
    if (search != null) {
      str = search;
    }
    this.pageSize = event.rows;
    this.pageNumber = event.page + 1;
    let reqBody = {};
    reqBody['applicationId'] = this.ApplicationOnboardingForm.value.select4;
    reqBody['componentId'] = this.ApplicationOnboardingForm.value.select5;
    reqBody['page'] = this.pageNumber;
    reqBody['rows'] = this.pageSize;
    reqBody['serchString'] = str;
    reqBody['sortByColumn'] = this.sortByColumn;
    if (this.sorting == "") {
      reqBody['sorting'] = "descending";
    }
    else {
      reqBody['sorting'] = this.sorting;
    }
    const data = reqBody;
    this.pagesService.getHostApplicationEndpointPagination(data).subscribe(results => {
      const responseData = results.success.result.records;
      this.totalRecords = results.success.result.recordsCount;
      this.oldTotalRec = results.success.result.recordsCount;
      if (responseData && responseData.length > 0) {
        this.tableList8 = responseData;
        this.tableList9 = responseData;
      } else {
        //else error flag
      }
    });

  }
  resourceEndpointFilterMethod(event: any) {
    let filtered: any[] = [];
    let items: any;
    let cc;
    let query = event.target.value;
    this.tableList8 = [];
    this.tableList9 = [];
    this.paginator.changePage(0);
    let reqBody = {};
    reqBody['applicationId'] = this.ApplicationOnboardingForm.value.select4;
    reqBody['componentId'] = this.ApplicationOnboardingForm.value.select5;
    reqBody['page'] = 1;
    reqBody['rows'] = this.pageSize;
    reqBody['serchString'] = query;
    if (query == "") {
      reqBody['sorting'] = "descending";
    }
    const data = reqBody;
    this.pagesService.getHostApplicationEndpointPagination(data).subscribe({
      next: results => {
        const responseData = results.success.result.records;
        this.totalRecords = results.success.result.recordsCount;
        this.oldTotalRec = results.success.result.recordsCount;
        this.tableList8 = responseData;
        this.tableList9 = responseData;

      },
      error: error => {
        this.totalRecords = 0;
        this.oldTotalRec = 0;
      }
    });
  }
  resourceEndpointsortData(event: SortEvent) {
    let fieldName = event.field;
    let orderNum = event.order;
    let orderBy;
    if (orderNum == 1) {
      orderBy = "ascending";
    }
    else {
      orderBy = "descending";
    }
    let str = '';
    const search = this.ApplicationOnboardingForm.value.textFieldEndpoint;
    if (search != null) {
      str = search;
    }

    if (fieldName == 'resourceName') {
      fieldName = 'resourceName';
    }
    if (fieldName == 'endPointUrl') {
      fieldName = 'endPointUrl';
    }
    else if (fieldName == 'method') {
      fieldName = 'method';
    }
    else if (fieldName == 'applicationRoleRequired') {
      fieldName = 'applicationRoleRequired';
    }

    if (this.sortByColumn != fieldName || this.sorting != orderBy) {
      this.sortByColumn = fieldName;
      this.sorting = orderBy;
      let reqBody = {};
      reqBody['applicationId'] = this.ApplicationOnboardingForm.value.select4;
      reqBody['componentId'] = this.ApplicationOnboardingForm.value.select5;
      reqBody['page'] = 1;
      reqBody['rows'] = this.pageSize;
      reqBody['serchString'] = str;
      reqBody['sortByColumn'] = fieldName;
      reqBody['sorting'] = orderBy;
      reqBody['filters'] = {};
      const data = reqBody;
      this.pagesService.getHostApplicationEndpointPagination(data).subscribe(results => {
        const responseData = results.success.result.records;
        this.totalRecords = results.success.result.recordsCount;
        this.oldTotalRec = results.success.result.recordsCount;
        this.tableList8 = responseData;
        this.resourceEndpointcustomSort(event);
      });
    }


  }
  resourceEndpointcustomSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else {
        let res = (value1 > value2) ? 1 : 0;
        result = (value1 < value2) ? -1 : res;
      }


      return (event.order * result);
    });
  }

  public filterFor(filterValue: any) {
    if (filterValue.length > 1) {
      if (filterValue[1]?.value == '') {
        filterValue[1].value = null;
      }
    }
    if (filterValue[0]?.value == '') {
      filterValue[0].value = null;
    }
    return filterValue;
  }

  resourceEndpointcolumnFilter(event: any) {
    let oldFilter = event.filters;
    let newFilter = event.filters;
    let filterEvent = [...Object.entries(newFilter)];
    let filterBody = [];
    let finalObj = {};
    let cnt = 0;
    filterEvent.forEach((element: any = {}) => {
      let filterValue = element[1];
      filterBody = [];
      filterValue = this.filterFor(filterValue);

      if (filterValue[0]?.value != null) {
        filterBody.push(filterValue[0]);
      }
      if (filterValue[1]?.value != null) {
        filterBody.push(filterValue[1]);
      }
      if (filterBody.length > 0) {
        finalObj[element[0]] = filterBody;
      }
    });
    let compareObj = JSON.stringify(this.filterReqObj) === JSON.stringify(finalObj);

    let str = '';
    const search = this.ApplicationOnboardingForm.value.textFieldEndpoint;
    if (search != null) {
      str = search;
    }
    let reqBody = {};
    if (!compareObj) {
      reqBody['applicationId'] = this.ApplicationOnboardingForm.value.select4;
      reqBody['componentId'] = this.ApplicationOnboardingForm.value.select5;
      reqBody['page'] = 1;
      reqBody['rows'] = this.pageSize;
      reqBody['serchString'] = str;
      reqBody['filters'] = finalObj;
      reqBody['sortByColumn'] = this.sortByColumn;
      reqBody['sorting'] = this.sorting;
    }
    else {
      reqBody['applicationId'] = this.ApplicationOnboardingForm.value.select4;
      reqBody['componentId'] = this.ApplicationOnboardingForm.value.select5;
      reqBody['page'] = this.pageNumber;
      reqBody['rows'] = this.pageSize;
      reqBody['serchString'] = str;
      reqBody['filters'] = finalObj;
      reqBody['sortByColumn'] = this.sortByColumn;
      reqBody['sorting'] = this.sorting;
    }

    const data = reqBody;
    if (Object.keys(finalObj).length > 0) {
      this.filterReqObj = finalObj;
      this.customFilterFlag = true;
      this.pagesService.getHostApplicationEndpointPagination(data).subscribe(results => {
        this.oldTotalRec = this.totalRecords;
        const responseData = results.success.result.records;
        this.totalRecords = results.success.result.recordsCount;
        event.filteredValue.splice(0, event.filteredValue.length);
        responseData.forEach(element => {
          event.filteredValue.push(element);
        });

      });
      event.filters = oldFilter;
    }
    else {
      this.totalRecords = this.oldTotalRec;
      if (this.customFilterFlag) {
        this.customFilterFlag = false;
        let reqBody = {};
        reqBody['applicationId'] = this.ApplicationOnboardingForm.value.select4;
        reqBody['componentId'] = this.ApplicationOnboardingForm.value.select5;
        reqBody['page'] = 1;
        reqBody['rows'] = this.pageSize;
        reqBody['serchString'] = str;
        const data1 = reqBody;
        this.pagesService.getHostApplicationEndpointPagination(data1).subscribe(results => {
          this.oldTotalRec = this.totalRecords;
          const responseData = results.success.result.records;
          this.totalRecords = results.success.result.recordsCount;
          event.filteredValue.splice(0, event.filteredValue.length);
          responseData.forEach(element => {
            event.filteredValue.push(element);
          });
        });
      }
    }
  }
  mapUnmapEndpoint() {
    this.messageFlag = true;
    this.ref = this.dialogService.open(EndpointMappingComponent, {
      data: { mapApplicationRoleName: this.activeItem1.applicationRoleName, mapRoleId: this.activeItem1.id, appId: this.ApplicationOnboardingForm.value.select3 },
      header: 'Map/ Unmap Endpoints',
      width: '65%',
      contentStyle: { 'max-height': '516px', overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'success') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'Endpoints mapping/unmapping saved successfully.' });
        setTimeout(() => {
          this.messageFlag = false;
        }, 1000);
        this.ApplicationRoleListing();
      }
      else if (msg == 'failed') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Endpoints mapping/unmapping failed.' });
        setTimeout(() => {
          this.messageFlag = false;
        }, 1000);
      }
    });
  }

  componentColumnFilter(event: any) {
    let oldFilter = event.filters;
    let newFilter = event.filters;
    let filterEvent = [...Object.entries(newFilter)];
    let filterBody = [];
    let finalObj = {};
    let cnt = 0;
    filterEvent.forEach((element: any = {}) => {
      let filterValue = element[1];
      filterBody = [];
      if (filterValue.length > 1) {
        if (filterValue[1]?.value == '') {
          filterValue[1].value = null;
        }
      }
      if (filterValue[0]?.value == '') {
        filterValue[0].value = null;
      }
      if (filterValue[0]?.value != null) {

        filterBody.push(filterValue[0]);
        if (element[0] == "componentId" || element[0] == "totalEntitlement") {
          filterValue[0].value = filterValue[0].value.toString();
        }
        if (filterValue[0].matchMode.includes('date')) {
          filterValue[0].value = this.datePipe.transform(filterValue[0].value, 'yyyy-MM-dd 00:00:00');
        }
      }
      if (filterValue[1]?.value != null) {
        if (filterValue[1].matchMode.includes('date')) {
          filterValue[1].value = this.datePipe.transform(filterValue[1].value, 'yyyy-MM-dd 00:00:00');
        }
        filterBody.push(filterValue[1]);
      }
      if (filterBody.length > 0) {
        finalObj[element[0]] = filterBody;
      }
    });
    let compareObj = JSON.stringify(this.filterReqObjComponent) === JSON.stringify(finalObj);

    let str = '';
    const search = this.ApplicationOnboardingForm.value.textField1;
    if (search != null) {
      str = search;
    }
    let reqBody = {};
    if (!compareObj) {
      reqBody['applicationId'] = this.ApplicationOnboardingForm.value.select;
      reqBody['page'] = 1;
      reqBody['rows'] = this.pageSizeComponent;
      reqBody['searchString'] = str;
      reqBody['filters'] = finalObj;
      reqBody['sortByColumn'] = this.sortByColumnComponent;
      reqBody['sorting'] = this.sortingComponent;
    }
    else {
      reqBody['applicationId'] = this.ApplicationOnboardingForm.value.select;
      reqBody['page'] = this.pageNumberComponent;
      reqBody['rows'] = this.pageSizeComponent;
      reqBody['searchString'] = str;
      reqBody['filters'] = finalObj;
      reqBody['sortByColumn'] = this.sortByColumnComponent;
      reqBody['sorting'] = this.sortingComponent;
    }

    const data = reqBody;
    if (Object.keys(finalObj).length > 0) {
      this.filterReqObjComponent = finalObj;
      this.customFilterFlagComponent = true;
      this.pagesService.getComponentListingPagination(data).subscribe((results: any) => {
        this.oldTotalRecComponent = this.totalRecordsComponent;
        const responseData = results?.success?.result?.records;
        this.totalRecordsComponent = results.success.result.recordsCount;
        event.filteredValue.splice(0, event.filteredValue.length);
        responseData.forEach(
          customer => (customer.updatedOn = new Date(customer.updatedOn))
        );
        responseData.forEach(element => {
          event.filteredValue.push(element);
        });
      });
      event.filters = oldFilter;
    }
    else {
      this.totalRecordsComponent = this.oldTotalRecComponent;
      if (this.customFilterFlagComponent) {
        this.customFilterFlagComponent = false;
        let reqBody = {};
        reqBody['applicationId'] = this.ApplicationOnboardingForm.value.select;
        reqBody['page'] = 1;
        reqBody['rows'] = this.pageSizeComponent;
        reqBody['searchString'] = str;
        const data1 = reqBody;
        this.pagesService.getComponentListingPagination(data1).subscribe((results: any) => {
          this.oldTotalRecComponent = this.totalRecordsComponent;
          const responseData = results?.success?.result?.records;
          this.totalRecordsComponent = results.success.result.recordsCount;
          responseData.forEach(
            customer => (customer.updatedOn = new Date(customer.updatedOn))
          );
          event.filteredValue.splice(0, event.filteredValue.length);
          responseData.forEach(element => {
            event.filteredValue.push(element);
          });
        });
      }
    }
  }

  componentSortData(event: SortEvent) {
    let fieldName = event.field;
    let orderNum = event.order;
    let orderBy;
    if (orderNum == 1) {
      orderBy = "ascending";
    }
    else {
      orderBy = "descending";
    }
    let str = '';
    const search = this.ApplicationOnboardingForm.value.textField1;
    if (search != null) {
      str = search;
    }

    if (fieldName == 'componentName') {
      fieldName = 'componentName';
    }
    else if (fieldName == 'validRedirectUrl') {
      fieldName = 'validRedirectUrl';
    }
    else if (fieldName == 'componentTypeName') {
      fieldName = 'componentTypeName';
    }
    else if (fieldName == 'ownership') {
      fieldName = 'ownership';
    }
    else if (fieldName == 'totalEntitlement') {
      fieldName = 'totalEntitlement';
    }
    else if (fieldName == 'type') {
      fieldName = 'type';
    }

    if (this.sortByColumnComponent != fieldName || this.sortingComponent != orderBy) {
      this.sortByColumnComponent = fieldName;
      this.sortingComponent = orderBy;
      let reqBody = {};
      reqBody['applicationId'] = this.ApplicationOnboardingForm.value.select;
      reqBody['page'] = 1;
      reqBody['rows'] = this.pageSizeComponent;
      reqBody['searchString'] = str;
      reqBody['sortByColumn'] = fieldName;
      reqBody['sorting'] = orderBy;
      reqBody['filters'] = {};
      const data = reqBody;
      this.pagesService.getComponentListingPagination(data).subscribe((results: any) => {
        const responseData = results?.success?.result?.records;
        this.totalRecordsComponent = results.success.result.recordsCount;
        this.oldTotalRecComponent = results.success.result.recordsCount;
        responseData.forEach(
          customer => (customer.updatedOn = new Date(customer.updatedOn))
        );
        this.tableList2 = responseData;
        this.componentCustomSort(event);
      });
    }
  }

  componentCustomSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else {
        let res = value1 > value2 ? 1 : 0;
        result = (value1 < value2) ? -1 : res;
      }


      return (event.order * result);
    });
  }

  componentPaginate(event, search) {
    let str = '';
    if (search != null) {
      str = search;
    }
    this.pageSizeComponent = event.rows;
    this.pageNumberComponent = event.page + 1;
    let reqBody = {};
    reqBody['applicationId'] = this.ApplicationOnboardingForm.value.select;
    reqBody['page'] = this.pageNumberComponent;
    reqBody['rows'] = this.pageSizeComponent;
    reqBody['searchString'] = str;
    reqBody['sortByColumn'] = this.sortByColumnComponent;
    if (this.sortingComponent == "") {
      reqBody['sorting'] = "descending";
    }
    else {
      reqBody['sorting'] = this.sortingComponent;
    }
    const data = reqBody;
    this.pagesService.getComponentListingPagination(data).subscribe((results: any) => {
      const responseData = results?.success?.result?.records;
      this.totalRecordsComponent = results.success.result.recordsCount;
      this.oldTotalRecComponent = results.success.result.recordsCount;
      if (responseData && responseData.length > 0) {
        responseData.forEach(
          customer => (customer.updatedOn = new Date(customer.updatedOn))
        );
        this.tableList2 = responseData;
        this.tableList3 = responseData;
      }
      else {
        //else error flag
      }
    });

  }
  emailComponent() {
    this.ref = this.dialogService.open(InviteEmailComponent, {
      data: { id: this.ApplicationOnboardingForm.value.emailAppDropdown, activeData: this.activeItem },
      header: 'Welcome Email',
      width: '800px',
      contentStyle: { 'max-height': 'auto', overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Email template details saved successfully') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.EmailTabListing();
      }
      else if (msg == 'Template Creation Failed') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
    });
  }
  AttributeListing(): void {
    this.tableListAttribute = [];
    this.attributeFlag = false;
    this.pageNumberAttribute = 1;
    this.sortByColumnAttribute = '';
    this.sortingAttribute = '';
    this.totalRecordsAttribute = 0;
    this.oldTotalRecAttribute = 0;
    this.attrApplicationId = this.ApplicationOnboardingForm.value.select6;
    const str = this.ApplicationOnboardingForm.value.textFieldAttribute;
    if (str == null) {
      this.searchStrAttribute = '';
    }
    else {
      this.searchStrAttribute = str;
    }
    let reqBody = {};
    reqBody['applicationId'] = this.ApplicationOnboardingForm.value.select6;
    reqBody['page'] = 1;
    reqBody['rows'] = this.pageSizeAttribute;
    reqBody['searchString'] = this.searchStrAttribute;
    reqBody['sorting'] = "descending";
    const formValues = reqBody;
    this.pagesService.getHostAttributePagination(formValues).subscribe((results: any) => {
      this.tableListAttribute = [];
      const responseData = results?.success?.result?.records;
      this.totalRecordsAttribute = results?.success?.result?.recordsCount;
      this.oldTotalRecAttribute = results?.success?.result?.recordsCount;
      if (responseData && responseData.length > 0) {
        responseData.forEach(
          customer => (customer.updatedOn = new Date(customer.updatedOn))
        );
        this.tableListAttribute = responseData;
      }
    });
  }

  attributeColumnFilter(event: any) {
    let oldFilter = event.filters;
    let newFilter = event.filters;
    let filterEvent = [...Object.entries(newFilter)];
    let filterBody = [];
    let finalObj = {};
    let cnt = 0;
    filterEvent.forEach((element: any = {}) => {
      let filterValue = element[1];
      filterBody = [];
      if (filterValue.length > 1) {
        if (filterValue[1]?.value == '') {
          filterValue[1].value = null;
        }
      }
      if (filterValue[0]?.value == '') {
        filterValue[0].value = null;
      }
      if (filterValue[0]?.value != null) {

        filterBody.push(filterValue[0]);
        if (element[0] == 'attributeType') {
          element[0] = 'attributeTypeName';
        }
        else if (element[0] == 'attributeGroupsList') {
          element[0] = 'attributeGroups';
        }
      }
      if (filterValue[1]?.value != null) {
        filterBody.push(filterValue[1]);
        if (element[0] == 'attributeType') {
          element[0] = 'attributeTypeName';
        }
        else if (element[0] == 'attributeGroupsList') {
          element[0] = 'attributeGroups';
        }
      }
      if (filterBody.length > 0) {
        finalObj[element[0]] = filterBody;
      }
    });
    let compareObj = JSON.stringify(this.filterReqObjComponent) === JSON.stringify(finalObj);

    let str = '';
    const search = this.ApplicationOnboardingForm.value.textFieldAttribute;
    if (search != null) {
      str = search;
    }
    let reqBody = {};
    if (!compareObj) {
      reqBody['applicationId'] = this.ApplicationOnboardingForm.value.select6;
      reqBody['page'] = 1;
      reqBody['rows'] = this.pageSizeAttribute;
      reqBody['searchString'] = str;
      reqBody['filters'] = finalObj;
      reqBody['sortByColumn'] = this.sortByColumnAttribute;
      reqBody['sorting'] = this.sortingAttribute;
    }
    else {
      reqBody['applicationId'] = this.ApplicationOnboardingForm.value.select6;
      reqBody['page'] = this.pageNumberAttribute;
      reqBody['rows'] = this.pageSizeAttribute;
      reqBody['searchString'] = str;
      reqBody['filters'] = finalObj;
      reqBody['sortByColumn'] = this.sortByColumnAttribute;
      reqBody['sorting'] = this.sortingAttribute;
    }

    const data = reqBody;
    if (Object.keys(finalObj).length > 0) {
      this.filterReqObjAttribute = finalObj;
      this.customFilterFlagAttribute = true;
      this.pagesService.getHostAttributePagination(data).subscribe((results: any) => {
        this.oldTotalRecAttribute = this.totalRecordsAttribute;
        const responseData = results?.success?.result?.records;
        this.totalRecordsAttribute = results.success.result.recordsCount;
        event.filteredValue.splice(0, event.filteredValue.length);
        responseData.forEach(element => {
          event.filteredValue.push(element);
        });
      });
      event.filters = oldFilter;
    }
    else {
      this.totalRecordsAttribute = this.oldTotalRecAttribute;
      if (this.customFilterFlagAttribute) {
        this.customFilterFlagAttribute = false;
        let reqBody = {};
        reqBody['applicationId'] = this.ApplicationOnboardingForm.value.select6;
        reqBody['page'] = 1;
        reqBody['rows'] = this.pageSizeAttribute;
        reqBody['searchString'] = str;
        reqBody['sortByColumn'] = this.sortByColumnAttribute;
        reqBody['sorting'] = this.sortingAttribute;
        const data1 = reqBody;
        this.pagesService.getHostAttributePagination(data1).subscribe((results: any) => {
          this.oldTotalRecAttribute = this.totalRecordsAttribute;
          const responseData = results?.success?.result?.records;
          this.totalRecordsAttribute = results.success.result.recordsCount;
          event.filteredValue.splice(0, event.filteredValue.length);
          responseData.forEach(element => {
            event.filteredValue.push(element);
          });
        });
      }
    }
  }

  attributeSortData(event: SortEvent) {
    let fieldName = event.field;
    let orderNum = event.order;
    let orderBy;
    if (orderNum == 1) {
      orderBy = "ascending";
    }
    else {
      orderBy = "descending";
    }
    let str = '';
    const search = this.ApplicationOnboardingForm.value.textField1;
    if (search != null) {
      str = search;
    }

    if (fieldName == 'attributeType') {
      fieldName = 'attributeTypeName';
    }
    else if (fieldName == 'attributeGroupsList') {
      fieldName = 'attributeGroups';
    }

    if (this.sortByColumnAttribute != fieldName || this.sortingAttribute != orderBy) {
      this.sortByColumnAttribute = fieldName;
      this.sortingAttribute = orderBy;
      let reqBody = {};
      reqBody['applicationId'] = this.ApplicationOnboardingForm.value.select6;
      reqBody['page'] = 1;
      reqBody['rows'] = this.pageSizeAttribute;
      reqBody['searchString'] = str;
      reqBody['sortByColumn'] = fieldName;
      reqBody['sorting'] = orderBy;
      reqBody['filters'] = {};
      const data = reqBody;
      this.pagesService.getHostAttributePagination(data).subscribe((results: any) => {
        const responseData = results?.success?.result?.records;
        this.totalRecordsAttribute = results.success.result.recordsCount;
        this.oldTotalRecAttribute = results.success.result.recordsCount;
        this.tableListAttribute = responseData;
        this.attributeCustomSort(event);
      });
    }
  }

  attributeCustomSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let value3 = data1[event.field];
      let value4 = data2[event.field];
      let result = null;

      if (value3 == null && value4 != null)
        result = -1;
      else if (value3 != null && value4 == null)
        result = 1;
      else if (value3 == null && value4 == null)
        result = 0;
      else if (typeof value3 === 'string' && typeof value4 === 'string')
        result = value3.localeCompare(value4);
      else {
        let res = value3 > value4 ? 1 : 0;
        result = (value3 < value4) ? -1 : res;
      }


      return (event.order * result);
    });
  }

  attributePaginate(event, search) {
    let str = '';
    if (search != null) {
      str = search;
    }
    this.pageSizeAttribute = event.rows;
    this.pageNumberAttribute = event.page + 1;
    let reqBody = {};
    reqBody['applicationId'] = this.ApplicationOnboardingForm.value.select6;
    reqBody['page'] = this.pageNumberAttribute;
    reqBody['rows'] = this.pageSizeAttribute;
    reqBody['searchString'] = str;
    reqBody['sortByColumn'] = this.sortByColumnAttribute;
    if (this.sortingAttribute == "") {
      reqBody['sorting'] = "descending";
    }
    else {
      reqBody['sorting'] = this.sortingAttribute;
    }
    const data = reqBody;
    this.pagesService.getHostAttributePagination(data).subscribe((results: any) => {
      const responseData = results?.success?.result?.records;
      this.totalRecordsAttribute = results.success.result.recordsCount;
      this.oldTotalRecAttribute = results.success.result.recordsCount;
      if (responseData && responseData.length > 0) {
        this.tableListAttribute = responseData;
      }
      else {
        //else error flag
      }
    });

  }
  attributeFilterMethod(event: any) {
    let filtered: any[] = [];
    let items: any;
    let cc;
    let query = event.target.value;
    this.tableListAttribute = [];
    this.paginatorAttribute.changePage(0);
    let reqBody = {};
    reqBody['applicationId'] = this.ApplicationOnboardingForm.value.select6;
    reqBody['page'] = 1;
    reqBody['rows'] = this.pageSizeAttribute;
    reqBody['searchString'] = query;
    if (query == "") {
      reqBody['sorting'] = "descending";
    }
    const data = reqBody;
    this.pagesService.getHostAttributePagination(data).subscribe({
      next: results => {
        const responseData = results.success.result.records;
        this.totalRecordsAttribute = results.success.result.recordsCount;
        this.oldTotalRecAttribute = results.success.result.recordsCount;
        this.tableListAttribute = responseData;

      },
      error: error => {
        this.totalRecordsAttribute = 0;
        this.oldTotalRecAttribute = 0;
      }
    });
  }

  //create host attribute -dialog box
  createHostAttribute() {
    this.ref = this.dialogService.open(CreateHostAttributeComponent, {
      data: { id: this.ApplicationOnboardingForm.value.select6 },
      header: 'Create Attribute',
      width: '60%',
      contentStyle: { 'max-height': '400px', overflow: 'visible' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Attribute Created Successfully') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        if (this.pagesService.attributeTreeEnable) {
          this.loadNodes();
        }
        else {
          this.AttributeListing();
        }

      }
      else if (msg == 'Attribute Creation Failed' || msg == 'Attribute resource name already exist') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
    });
  }

  EmailTabListing(): void {
    this.emailtable = [];
    this.emailListFlag = false;
    this.pageNumberEmail = 1;
    this.sortByColumnEmail = '';
    this.sortingEmail = '';
    this.totalRecordsEmail = 0;
    this.oldTotalRecEmail = 0;
    this.emaiApplicationId = this.ApplicationOnboardingForm.value.emailAppDropdown;
    const str = this.ApplicationOnboardingForm.value.emailsearch;
    if (str == null) {
      this.searchStrEmail = '';
    }
    else {
      this.searchStrEmail = str;
    }
    let reqBody = {};
    reqBody['applicationId'] = this.ApplicationOnboardingForm.value.emailAppDropdown;
    reqBody['page'] = 1;
    reqBody['rows'] = this.pageSizeEmail;
    reqBody['searchString'] = this.searchStrEmail;
    reqBody['sorting'] = "descending";
    const formValues = reqBody;
    this.pagesService.getEmailconfiglist(formValues).subscribe((results: any) => {
      this.emailtable = [];
      const responseData = results?.success?.result?.records;
      this.totalRecordsEmail = results?.success?.result?.recordsCount;
      this.oldTotalRecEmail = results?.success?.result?.recordsCount;
      if (responseData && responseData.length > 0) {

        this.emailtable = responseData;
        this.emailFilterTable = responseData;
        this.forChange();
      }
      if (this.totalRecordsEmail == 0) {
        this.emailsearchflag = false;
      }
      else
        this.emailsearchflag = true;
    });

  }

  forChange() {
    for (let i = 0; i < this.emailtable.length; i++) {
      this.ApplicationOnboardingForm.addControl("inputswitch" + i, this.fb.control(null, []));
      if (this.emailtable[i].isDefault) {
        this['templateIsDefault' + i] = true;
        this.ApplicationOnboardingForm.get("inputswitch" + i).disable();
      } else if (this.pagesService.applicationOnboardingReadOnlyFlag) {
        this.ApplicationOnboardingForm.get("inputswitch" + i).disable();
      }
      else if (!this.emailtable[i].isDefault || this.emailtable[i]?.isDefault == null) {
        this['templateIsDefault' + i] = false;
        this.ApplicationOnboardingForm.get("inputswitch" + i).enable();
      }
    }
  }

  emailTemplateColumnFilter(event: any) {
    let oldFilter = event.filters;
    let newFilter = event.filters;
    let filterEvent = [...Object.entries(newFilter)];
    let filterBody = [];
    let finalObj = {};
    let cnt = 0;
    filterEvent.forEach((element: any = {}) => {
      let filterValue = element[1];
      filterBody = [];
      if (filterValue.length > 1) {
        if (filterValue[1]?.value == '') {
          filterValue[1].value = null;
        }
      }
      if (filterValue[0]?.value == '') {
        filterValue[0].value = null;
      }
      if (filterValue[0]?.value != null) {

        filterBody.push(filterValue[0]);
        if (element[0] == 'emailtemplateName') {
          element[0] = 'templateName';
        }
      }
      if (filterValue[1]?.value != null) {
        filterBody.push(filterValue[1]);
        if (element[0] == 'emailtemplateName') {
          element[0] = 'templateName';
        }
      }
      if (filterBody.length > 0) {
        finalObj[element[0]] = filterBody;
      }
    });
    let compareObj = JSON.stringify(this.filterReqObjComponent) === JSON.stringify(finalObj);

    let str = '';
    const search = this.ApplicationOnboardingForm.value.emailsearch;
    if (search != null) {
      str = search;
    }
    let reqBody = {};
    if (!compareObj) {
      reqBody['applicationId'] = this.ApplicationOnboardingForm.value.emailAppDropdown;
      reqBody['page'] = 1;
      reqBody['rows'] = this.pageSizeEmail;
      reqBody['searchString'] = str;
      reqBody['filters'] = finalObj;
      reqBody['sortByColumn'] = this.sortByColumnEmail;
      reqBody['sorting'] = this.sortingEmail;
    }
    else {
      reqBody['applicationId'] = this.ApplicationOnboardingForm.value.emailAppDropdown;
      reqBody['page'] = this.pageNumberEmail;
      reqBody['rows'] = this.pageSizeEmail;
      reqBody['searchString'] = str;
      reqBody['filters'] = finalObj;
      reqBody['sortByColumn'] = this.sortByColumnEmail;
      reqBody['sorting'] = this.sortingEmail;
    }

    const data = reqBody;
    if (Object.keys(finalObj).length > 0) {
      this.filterReqObjEmail = finalObj;
      this.customFilterFlagEmail = true;
      this.pagesService.getEmailconfiglist(data).subscribe((results: any) => {
        this.oldTotalRecEmail = this.totalRecordsEmail;
        const responseData = results?.success?.result?.records;
        this.totalRecordsEmail = results.success.result.recordsCount;
        event.filteredValue.splice(0, event.filteredValue.length);
        responseData.forEach(element => {
          event.filteredValue.push(element);
        });
        for (let i = 0; i < responseData.length; i++) {

          if (responseData[i].isDefault) {
            this['templateIsDefault' + i] = true;
            this.ApplicationOnboardingForm.get("inputswitch" + i).disable();
          }
          else if (!responseData[i].isDefault || responseData[i]?.isDefault == null) {
            this['templateIsDefault' + i] = false;
            this.ApplicationOnboardingForm.get("inputswitch" + i).enable();
          }
        }
      });
      event.filters = oldFilter;
    }
    else {
      this.totalRecordsEmail = this.oldTotalRecEmail;
      if (this.customFilterFlagEmail) {
        this.customFilterFlagEmail = false;
        let reqBody = {};
        reqBody['applicationId'] = this.ApplicationOnboardingForm.value.emailAppDropdown;
        reqBody['page'] = 1;
        reqBody['rows'] = this.pageSizeEmail;
        reqBody['searchString'] = str;
        reqBody['sortByColumn'] = this.sortByColumnEmail;
        reqBody['sorting'] = this.sortingEmail;
        const data1 = reqBody;
        this.pagesService.getEmailconfiglist(data1).subscribe((results: any) => {
          this.oldTotalRecEmail = this.totalRecordsEmail;
          const responseData = results?.success?.result?.records;
          this.totalRecordsEmail = results.success.result.recordsCount;
          event.filteredValue.splice(0, event.filteredValue.length);
          responseData.forEach(element => {
            event.filteredValue.push(element);
          });
          for (let i = 0; i < responseData.length; i++) {

            if (responseData[i].isDefault) {
              this['templateIsDefault' + i] = true;
              this.ApplicationOnboardingForm.get("inputswitch" + i).disable();
            }
            else if (!responseData[i].isDefault || responseData[i]?.isDefault == null) {
              this['templateIsDefault' + i] = false;
              this.ApplicationOnboardingForm.get("inputswitch" + i).enable();
            }
          }
        });
      }
    }
  }
  emailTemplateSortData(event: SortEvent) {
    let fieldName = event.field;
    let orderNum = event.order;
    let orderBy;
    if (orderNum == 1) {
      orderBy = "ascending";
    }
    else {
      orderBy = "descending";
    }
    let str = '';
    const search = this.ApplicationOnboardingForm.value.emailsearch;
    if (search != null) {
      str = search;
    }

    if (fieldName == 'emailtemplateName') {
      fieldName = 'templateName';
    }

    if (this.sortByColumnEmail != fieldName || this.sortingEmail != orderBy) {
      this.sortByColumnEmail = fieldName;
      this.sortingEmail = orderBy;
      let reqBody = {};
      reqBody['applicationId'] = this.ApplicationOnboardingForm.value.emailAppDropdown;
      reqBody['page'] = 1;
      reqBody['rows'] = this.pageSizeEmail;
      reqBody['searchString'] = str;
      reqBody['sortByColumn'] = fieldName;
      reqBody['sorting'] = orderBy;
      reqBody['filters'] = {};
      const data = reqBody;
      this.pagesService.getEmailconfiglist(data).subscribe((results: any) => {
        const responseData = results?.success?.result?.records;
        this.totalRecordsEmail = results.success.result.recordsCount;
        this.oldTotalRecEmail = results.success.result.recordsCount;
        this.emailtable = responseData;
        for (let i = 0; i < this.emailtable.length; i++) {

          if (this.emailtable[i].isDefault) {
            this['templateIsDefault' + i] = true;
            this.ApplicationOnboardingForm.get("inputswitch" + i).disable();
          }
          else if (!this.emailtable[i].isDefault || this.emailtable[i]?.isDefault == null) {
            this['templateIsDefault' + i] = false;
            this.ApplicationOnboardingForm.get("inputswitch" + i).enable();
          }
        }
        this.emailTemplateCustomSort(event);
      });
    }
  }
  emailTemplateCustomSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let value5 = data1[event.field];
      let value6 = data2[event.field];
      let result = null;

      if (value5 == null && value6 != null)
        result = -1;
      else if (value5 != null && value6 == null)
        result = 1;
      else if (value5 == null && value6 == null)
        result = 0;
      else if (typeof value5 === 'string' && typeof value6 === 'string')
        result = value5.localeCompare(value6);
      else {
        let res = value5 > value6 ? 1 : 0;
        result = (value5 < value6) ? -1 : res;
      }


      return (event.order * result);
    });
  }
  emailtemplatePaginate(event, search) {
    let str = '';
    if (search != null) {
      str = search;
    }
    this.pageSizeEmail = event.rows;
    this.pageNumberEmail = event.page + 1;
    let reqBody = {};
    reqBody['applicationId'] = this.ApplicationOnboardingForm.value.emailAppDropdown;
    reqBody['page'] = this.pageNumberEmail;
    reqBody['rows'] = this.pageSizeEmail;
    reqBody['searchString'] = str;
    reqBody['sortByColumn'] = this.sortByColumnEmail;
    if (this.sortingEmail == "") {
      reqBody['sorting'] = "descending";
    }
    else {
      reqBody['sorting'] = this.sortingEmail;
    }
    const data = reqBody;
    this.pagesService.getEmailconfiglist(data).subscribe((results: any) => {
      const responseData = results?.success?.result?.records;
      this.totalRecordsEmail = results.success.result.recordsCount;
      this.oldTotalRecEmail = results.success.result.recordsCount;
      if (responseData && responseData.length > 0) {
        this.emailTemplatePaginateResponse(responseData);
      }
      else {
        //else error flag
      }
    });

  }
  emailTemplatePaginateResponse(responseData: any) {
    responseData.forEach(
      customer => (customer.updatedOn = new Date(customer.updatedOn))
    );
    for (let i = 0; i < responseData.length; i++) {

      if (responseData[i].isDefault) {
        this['templateIsDefault' + i] = true;
        this.ApplicationOnboardingForm.get("inputswitch" + i).disable();
      }
      else if (!responseData[i].isDefault || responseData[i]?.isDefault == null) {
        this['templateIsDefault' + i] = false;
        this.ApplicationOnboardingForm.get("inputswitch" + i).enable();
      }
    }
    this.emailtable = responseData;
  }
  emailTemplateSearch(event: any) {
    let filtered: any[] = [];
    let items: any;
    let cc;
    let query = event.target.value;
    this.emailtable = [];
    this.emailFilterTable = [];
    let reqBody = {};
    reqBody['applicationId'] = this.ApplicationOnboardingForm.value.emailAppDropdown;
    reqBody['page'] = 1;
    reqBody['rows'] = this.pageSizeEmail;
    reqBody['searchString'] = query;
    if (query == "") {
      reqBody['sorting'] = "descending";
    }
    const data = reqBody;
    console.log("this is", reqBody);
    this.pagesService.getEmailconfiglist(data).subscribe({
      next: (results: any) => {
        this.emailtable = [];
        this.emailFilterTable = [];
        const responseData = results?.success?.result?.records;
        this.totalRecordsEmail = results.success.result.recordsCount;
        this.oldTotalRecEmail = results.success.result.recordsCount;
        if (responseData && responseData.length > 0) {
          responseData.forEach(
            customer => (customer.updatedOn = new Date(customer.updatedOn))
          );
          for (let i = 0; i < responseData.length; i++) {

            if (responseData[i].isDefault) {
              this['templateIsDefault' + i] = true;
              this.ApplicationOnboardingForm.get("inputswitch" + i).disable();
            }
            else if (responseData[i].isDefault || responseData[i]?.isDefault == null) {
              this['templateIsDefault' + i] = false;
              this.ApplicationOnboardingForm.get("inputswitch" + i).enable();
            }
          }
          this.emailtable = responseData;
          this.emailFilterTable = responseData;
        }
      },
      error: error => {
        this.totalRecordsEmail = 0;
        this.oldTotalRecEmail = 0;
      }
    });
  }
  //edit host attribute API -dialog box
  EditAttribute() {
    this.ref = this.dialogService.open(EditHostAttributeComponent, {
      data: { id: this.ApplicationOnboardingForm.value.select6, activeData: this.activeItemAttribute },
      header: 'Edit Attribute',
      width: '60%',
      contentStyle: { 'max-height': '350px', overflow: 'visible' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Attribute Edited Successfully') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        if (this.pagesService.attributeTreeEnable) {
          this.loadNodes();
          this.attributeFlag = false;
        }
        else {
          this.AttributeListing();
          this.attributeFlag = false;

        }
      }
      else if (msg == 'Failed to Edit Attribute') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
    });
  }

  //Delete Attribute API- dialog box
  DeleteAttribute() {
    this.ref = this.dialogService.open(DeleteHostAttributeComponent, {
      data: { id: this.ApplicationOnboardingForm.value.select6, activeData: this.activeItemAttribute },
      header: 'Delete Attribute',
      width: '30%',
      contentStyle: { 'max-height': '350px', overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Attribute Deleted Successfully') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.ApplicationOnboardingForm.controls.select3.reset();
        this.flag3 = true;
        if (this.pagesService.attributeTreeEnable) {
          this.loadNodes();
          this.attributeFlag = false;
        } {
          this.AttributeListing();
          this.attributeFlag = false;

        }
      }
      else if (msg == 'Failed to Delete Attribute') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
    });
  }
  mapUnmapTemplate() {

    this.ref = this.dialogService.open(EmailTemplateMappingComponent, {
      data: { id: this.ApplicationOnboardingForm.value.select, activeData: this.activeItem },
      header: 'Map Email Template',
      width: '364px',
      contentStyle: { 'max-height': 'auto', overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Application Component Details Saved Sucessfully') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.ComponentListing();
      }
      else if (msg == 'Failed to Edit Application Component' || msg == 'Application Component Name already exist') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
    });
  }

  setDefaultTemplate(rowData: any, index: any) {
    const reqBody = {};
    reqBody['emailTemplateId'] = rowData.id;
    reqBody['hostApplicationId'] = this.ApplicationOnboardingForm.value.emailAppDropdown;
    const formValues = reqBody;
    this.messageFlag = true;
    this.pagesService.setDefaultEmailTemplate(formValues).subscribe(
      {
        next: results => {
          this.messageFlag = true;
          if (results?.success?.messageCode == "CIAM 154") {
            this.messageFlag = true;
            this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'Default temaplate saved successfully' });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
            this.EmailTabListing();
          } else if (results?.success?.messageCode == "CIAM 160") {
            this.messageFlag = true;
            this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'Failed to save default template' });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
          }

        },
        error: error => {
          if (error.error?.error?.messageCode == "CIAM 160") {
            this.messageFlag = true;
            this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'Failed to save default template' });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
          }
        }
      });
  }


  //group listing
  HostAttributeGroupListing(): void {
    this.tableListAttributeGroup = [];
    this.attributeGroupFlag = false;
    this.pageNumberAttributeGroup = 1;
    this.sortByColumnAttributeGroup = '';
    this.sortingAttributeGroup = '';
    this.totalRecordsAttributeGroup = 0;
    this.oldTotalRecAttributeGroup = 0;
    this.attrApplicationId = this.ApplicationOnboardingForm.value.applicationAttrgroupdropdown;
    const str = this.ApplicationOnboardingForm.value.textFieldAttribute;
    if (str == null) {
      this.searchStrAttributeGroup = '';
    }
    else {
      this.searchStrAttributeGroup = str;
    }
    let reqBody = {};
    reqBody['applicationId'] = this.ApplicationOnboardingForm.value.applicationAttrgroupdropdown;
    reqBody['page'] = 1;
    reqBody['rows'] = this.pageSizeAttributeGroup;
    reqBody['searchString'] = this.searchStrAttributeGroup;
    reqBody['sorting'] = "descending";
    const formValues = reqBody;
    this.pagesService.getHostAttributeGroupPagination(formValues).subscribe((results: any) => {
      this.tableListAttributeGroup = [];
      const responseData = results?.success?.result?.records;
      this.totalRecordsAttributeGroup = results?.success?.result?.recordsCount;
      this.oldTotalRecAttributeGroup = results?.success?.result?.recordsCount;
      if (responseData && responseData.length > 0) {
        this.tableListAttributeGroup = responseData;
      }
    });
  }
  attributeGroupColumnFilter(event: any) {
    let oldFilter = event.filters;
    let newFilter = event.filters;
    let filterEvent = [...Object.entries(newFilter)];
    let filterBody = [];
    let finalObj = {};
    let cnt = 0;
    filterEvent.forEach((element: any = {}) => {
      let filterValue = element[1];
      filterBody = [];
      if (filterValue.length > 1) {
        if (filterValue[1]?.value == '') {
          filterValue[1].value = null;
        }
      }
      if (filterValue[0]?.value == '') {
        filterValue[0].value = null;
      }
      if (filterValue[0]?.value != null) {

        filterBody.push(filterValue[0]);
        if (element[0] == 'attributeGroupName') {
          element[0] = 'attributeGroupName';
        }
      }
      if (filterValue[1]?.value != null) {
        filterBody.push(filterValue[1]);
        if (element[0] == 'attributeGroupName') {
          element[0] = 'attributeGroupName';
        }
      }
      if (filterBody.length > 0) {
        finalObj[element[0]] = filterBody;
      }
    });
    let compareObj = JSON.stringify(this.filterReqObjComponent) === JSON.stringify(finalObj);

    let str = '';
    const search = this.ApplicationOnboardingForm.value.searchAttributeGroupList;
    if (search != null) {
      str = search;
    }
    let reqBody = {};
    if (!compareObj) {
      reqBody['applicationId'] = this.ApplicationOnboardingForm.value.applicationAttrgroupdropdown;
      reqBody['page'] = 1;
      reqBody['rows'] = this.pageSizeAttributeGroup;
      reqBody['searchString'] = str;
      reqBody['filters'] = finalObj;
      reqBody['sortByColumn'] = this.sortByColumnAttributeGroup;
      reqBody['sorting'] = this.sortingAttributeGroup;
    }
    else {
      reqBody['applicationId'] = this.ApplicationOnboardingForm.value.applicationAttrgroupdropdown;
      reqBody['page'] = this.pageNumberAttributeGroup;
      reqBody['rows'] = this.pageSizeAttributeGroup;
      reqBody['searchString'] = str;
      reqBody['filters'] = finalObj;
      reqBody['sortByColumn'] = this.sortByColumnAttributeGroup;
      reqBody['sorting'] = this.sortingAttributeGroup;
    }

    const data = reqBody;
    if (Object.keys(finalObj).length > 0) {
      this.filterReqObjAttribute = finalObj;
      this.customFilterFlagAttribute = true;
      this.pagesService.getHostAttributeGroupPagination(data).subscribe((results: any) => {
        this.oldTotalRecAttributeGroup = this.totalRecordsAttributeGroup;
        const responseData = results?.success?.result?.records;
        this.totalRecordsAttributeGroup = results.success.result.recordsCount;
        event.filteredValue.splice(0, event.filteredValue.length);
        responseData.forEach(element => {
          event.filteredValue.push(element);
        });
      });
      event.filters = oldFilter;
    }
    else {
      this.totalRecordsAttributeGroup = this.oldTotalRecAttributeGroup;
      if (this.customFilterFlagAttribute) {
        this.customFilterFlagAttribute = false;
        let reqBody = {};
        reqBody['applicationId'] = this.ApplicationOnboardingForm.value.applicationAttrgroupdropdown;
        reqBody['page'] = 1;
        reqBody['rows'] = this.pageSizeAttributeGroup;
        reqBody['searchString'] = str;
        reqBody['sortByColumn'] = this.sortByColumnAttributeGroup;
        reqBody['sorting'] = this.sortingAttributeGroup;
        const data1 = reqBody;
        this.pagesService.getHostAttributeGroupPagination(data1).subscribe((results: any) => {
          this.oldTotalRecAttributeGroup = this.totalRecordsAttributeGroup;
          const responseData = results?.success?.result?.records;
          this.totalRecordsAttributeGroup = results.success.result.recordsCount;
          event.filteredValue.splice(0, event.filteredValue.length);
          responseData.forEach(element => {
            event.filteredValue.push(element);
          });
        });
      }
    }
  }

  attributeGroupSortData(event: SortEvent) {
    let fieldName = event.field;
    let orderNum = event.order;
    let orderBy;
    if (orderNum == 1) {
      orderBy = "ascending";
    }
    else {
      orderBy = "descending";
    }
    let str = '';
    const search = this.ApplicationOnboardingForm.value.searchAttributeGroupList;
    if (search != null) {
      str = search;
    }

    if (fieldName == 'attributeGroupName') {
      fieldName = 'attributeGroupName';
    }

    if (this.sortByColumnAttributeGroup != fieldName || this.sortingAttributeGroup != orderBy) {
      this.sortByColumnAttributeGroup = fieldName;
      this.sortingAttributeGroup = orderBy;
      let reqBody = {};
      reqBody['applicationId'] = this.ApplicationOnboardingForm.value.applicationAttrgroupdropdown;
      reqBody['page'] = 1;
      reqBody['rows'] = this.pageSizeAttributeGroup;
      reqBody['searchString'] = str;
      reqBody['sortByColumn'] = fieldName;
      reqBody['sorting'] = orderBy;
      reqBody['filters'] = {};
      const data = reqBody;
      this.pagesService.getHostAttributeGroupPagination(data).subscribe((results: any) => {
        const responseData = results?.success?.result?.records;
        this.totalRecordsAttributeGroup = results.success.result.recordsCount;
        this.oldTotalRecAttributeGroup = results.success.result.recordsCount;
        this.tableListAttributeGroup = responseData;
        this.attributeGroupCustomSort(event);
      });
    }
  }

  attributeGroupCustomSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let value7 = data1[event.field];
      let value8 = data2[event.field];
      let result = null;

      if (value7 == null && value8 != null)
        result = -1;
      else if (value7 != null && value8 == null)
        result = 1;
      else if (value7 == null && value8 == null)
        result = 0;
      else if (typeof value7 === 'string' && typeof value8 === 'string')
        result = value7.localeCompare(value8);
      else {
        let res = value7 > value8 ? 1 : 0;
        result = (value7 < value8) ? -1 : res;
      }


      return (event.order * result);
    });
  }

  attributeGroupPaginate(event, search) {
    let str = '';
    if (search != null) {
      str = search;
    }
    this.pageSizeAttributeGroup = event.rows;
    this.pageNumberAttributeGroup = event.page + 1;
    let reqBody = {};
    reqBody['applicationId'] = this.ApplicationOnboardingForm.value.applicationAttrgroupdropdown;
    reqBody['page'] = this.pageNumberAttributeGroup;
    reqBody['rows'] = this.pageSizeAttributeGroup;
    reqBody['searchString'] = str;
    reqBody['sortByColumn'] = this.sortByColumnAttributeGroup;
    if (this.sortingAttributeGroup == "") {
      reqBody['sorting'] = "descending";
    }
    else {
      reqBody['sorting'] = this.sortingAttributeGroup;
    }
    const data = reqBody;
    this.pagesService.getHostAttributeGroupPagination(data).subscribe((results: any) => {
      const responseData = results?.success?.result?.records;
      this.totalRecordsAttributeGroup = results.success.result.recordsCount;
      this.oldTotalRecAttributeGroup = results.success.result.recordsCount;
      if (responseData && responseData.length > 0) {
        this.tableListAttributeGroup = responseData;
      }
      else {
        //else error flag
      }
    });

  }
  attributeGroupFilterMethod(event: any) {
    let filtered: any[] = [];
    let items: any;
    let cc;
    let query = event.target.value;
    this.tableListAttributeGroup = [];
    this.paginatorAttribute.changePage(0);
    let reqBody = {};
    reqBody['applicationId'] = this.ApplicationOnboardingForm.value.applicationAttrgroupdropdown;
    reqBody['page'] = 1;
    reqBody['rows'] = this.pageSizeAttribute;
    reqBody['searchString'] = query;
    if (query == "") {
      reqBody['sorting'] = "descending";
    }
    const data = reqBody;
    this.pagesService.getHostAttributeGroupPagination(data).subscribe({
      next: results => {
        const responseData = results.success.result.records;
        this.totalRecordsAttributeGroup = results.success.result.recordsCount;
        this.oldTotalRecAttributeGroup = results.success.result.recordsCount;
        this.tableListAttributeGroup = responseData;

      },
      error: error => {
        this.totalRecordsAttributeGroup = 0;
        this.oldTotalRecAttributeGroup = 0;
      }
    });
  }
  //create attributegroup
  createHostAttributeGroup() {
    this.ref = this.dialogService.open(CreateHostAttributeGroupComponent, {
      data: { id: this.ApplicationOnboardingForm.value.applicationAttrgroupdropdown },
      header: 'Create Attribute Group',
      width: '50%',
      contentStyle: { 'max-height': '350px', overflow: 'visible' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Attribute Group Created Successfully') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.HostAttributeGroupListing();
      }
      else if (msg == 'Attribute Group Creation Failed' || msg == 'Attribute group name already exist') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
    });

  }

  // edit attribute&group API - dialogbox
  editHostAttributeGroup() {
    this.ref = this.dialogService.open(EditHostAttributeGroupComponent, {
      data: { id: this.ApplicationOnboardingForm.value.applicationAttrgroupdropdown, grpId: this.activeItem.attributeGroupId, groupname: this.activeItem.attributeGroupName, description: this.activeItem.description },
      header: 'Edit Attribute Group',
      width: '50%',
      contentStyle: { 'max-height': '350px', overflow: 'visible' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Attribute Group Saved Successfully') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.HostAttributeGroupListing();
      }
      else if (msg == 'Failed to Edit Attribute Group') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
    });
  }

  // delete attribute&group API - dialogbox
  deleteHostAttributeGroup() {
    this.ref = this.dialogService.open(DeleteHostAttributeGroupComponent, {
      data: { id: this.ApplicationOnboardingForm.value.applicationAttrgroupdropdown, grpid: this.activeItem.attributeGroupId, groupname: this.activeItem.attributeGroupName, assignedAttributeCount: this.activeItem.mappedAttributes },
      header: 'Delete Attribute Group',
      width: '30%',
      contentStyle: { 'max-height': '350px', overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Attribute Group Deleted Successfully') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.ApplicationOnboardingForm.controls.select2.reset();
        this.ApplicationOnboardingForm.get('select3').disable();
        this.ApplicationOnboardingForm.controls.select3.reset();
        this.flag3 = true;
        this.HostAttributeGroupListing();
      }
      else if (msg == 'Failed to Delete Attribute Group') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
    });
  }

  mapUnmapHostAttributeGroup() {
    this.ref = this.dialogService.open(HostAttributeGroupMappingComponent, {
      data: { id: this.ApplicationOnboardingForm.value.applicationAttrgroupdropdown, grpid: this.activeItem.attributeGroupId, groupname: this.activeItem.attributeGroupName, assignedAttributeCount: this.activeItem.assignedAttributeCount },
      header: 'Map/Unmap Attributes',
      width: '70%',
      contentStyle: { 'max-height': '440px', overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Attributes mapping/unmapping saved successfully.') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.ApplicationOnboardingForm.controls.select2.reset();
        this.ApplicationOnboardingForm.get('select3').disable();
        this.ApplicationOnboardingForm.controls.select3.reset();
        this.flag3 = true;
        this.HostAttributeGroupListing();
      }
      else if (msg == 'Attributes mapping/unmapping  failed.') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
    });
  }

  editEmailComponent() {
    this.ref = this.dialogService.open(EditEmailTemplateComponent, {
      data: { id: this.ApplicationOnboardingForm.value.emailAppDropdown, activeData: this.activeItem2 },
      header: 'Edit Template',
      width: '800px',
      contentStyle: { 'max-height': 'auto', overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Email template details saved successfully') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.EmailTabListing();
      }
      else if (msg == 'Failed to Edit Template') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
    });
  }
  EnableDisableSelfService(rowdata: any, index: any): void {
    console.log(rowdata);
    const reqBody = {}
    reqBody['roleId'] = rowdata.id;
    this.ciamaction = rowdata.selfService;
    console.log(this.ApplicationOnboardingForm.value['selfServiceSwitch' + index]);
    if (this.ApplicationOnboardingForm.value['selfServiceSwitch' + index]) {
      this.action = 'enable';
    } else {
      this.action = 'disable';
    }
    reqBody['action'] = this.action;
    const formValues = reqBody;

    this.messageFlag = true;
    this.pagesService.manageRoleSelfServiceFlag(formValues).subscribe(
      {
        next: results => {
          this.messageFlag = true;
          if (results?.success?.messageCode == "CIAM 550") {
            this.messageFlag = true;
            this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'Self Service enabled successfully' });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
            this.EmailTabListing();
          } else if (results?.success?.messageCode == "CIAM 551") {
            this.messageFlag = true;
            this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'Self Service disabled successfully' });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
          }
          else if (results?.success?.messageCode == "CIAM 555") {
            this.messageFlag = true;
            this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'Self Service already enabled' });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
          }
          else if (results?.success?.messageCode == "CIAM 556") {
            this.messageFlag = true;
            this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'Self Service already disabled' });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
          }
        },
      }
    );
  }

  paginate(event, search) {
    let str = '';
    if (search != null) {
      str = search;
    }

    this.files = [];
    this.tableList1 = [];
    this.filesPaginate = [];
    this.pageSize = event.rows;
    this.pageNumber = event.page + 1;
    let reqBody = {};

    reqBody['applicationId'] = this.ApplicationOnboardingForm.value.select6;
    reqBody['page'] = this.pageNumber;
    reqBody['rows'] = this.pageSize;
    reqBody['searchString'] = str;
    reqBody['parentId'] = 0;
    // reqBody['filters'] = '';
    reqBody['sortByColumn'] = this.sortByColumn;
    if (this.sorting == "") {
      reqBody['sorting'] = "descending";
    }
    else {
      reqBody['sorting'] = this.sorting;
    }
    const data = reqBody;
    this.pagesService.getAttributeListByApplicationIdLazy(data).subscribe(results => {
      this.files = [];
      const responseData = results.success.result.records;
      this.attributeTotalRecords = results.success.result.recordsCount;
      this.oldTotalRec = results.success.result.recordsCount;
      if (responseData && responseData.length > 0) {
        this.tableList = responseData;
        // this.files=responseData;
        this.tableList1 = responseData;

        for (let i = 0; i < this.tableList1.length; i++) {
          let groupListArray = [];
          this.tableList1[i].data?.attributeGroups.forEach((element1: any = {}) => {
            groupListArray.push(element1.attributeGroupName);
          });
          let groupList = groupListArray.toString();
          let node = {
            data: {
              attributeId: this.tableList1[i]?.data?.attributeId,
              attributeResourceName: this.tableList1[i]?.data?.attributeResourceName,
              attributeResourceId: this.tableList1[i]?.data?.attributeResourceId,
              attributeTypeName: this.tableList1[i]?.data?.attributeTypeName,
              attributeGroupName: groupList,
              isDefault: this.tableList1[i]?.data?.isDefault,
              isPrivate: this.tableList1[i]?.data?.isPrivate,

            },
            leaf: false
          };
          this.filesPaginate.push(node);
          // if(!this.files.includes(this.tableList1[i]?.data?.attributeId)){
          //   if(this.files.length<1){
          //     this.files.push(node);
          //   }else{
          //   for (let j = 0; j<this.files.length; j++) {
          //     if(this.files[j].data?.attributeId!=this.tableList1[i]?.data?.attributeId){
          //          this.files.push(node);
          //         //  this.files=this.files.concat(node);
          //       }
          //   }
          // }
          // }
          // this.files=[...this.files,...node[i]];
        }
        this.filesPaginate = [...this.filesPaginate];
        this.files = this.filesPaginate;
      }
    });
  }
  columnFilter(event: any) {
    this.filesFilter = [];
    event.filteredValue = this.files;

    // let oldFilter = event.filters;
    let newFilter = event.filters;
    let filterEvent = [...Object.entries(newFilter)];
    let finalObj = {};
    let str = '';
    const search = this.ApplicationOnboardingForm.value.textFieldAttribute;
    if (search != null) {
      str = search;
    }
    console.log(this.oldFilter);
    console.log(newFilter);
    let compareObj = JSON.stringify(this.oldFilter) === JSON.stringify(newFilter);
    if (!compareObj) {
      this.oldFilter = newFilter;
      let filterKeys = Object.keys(event.filters);
      let filterObj = event.filters;
      console.log(Object.keys(event.filters), "keys filters");
      filterKeys.forEach((element: any = {}) => {
        this.filtersData[element] = [filterObj[element]];
      });
      let reqBody = {};
      reqBody['applicationId'] = this.ApplicationOnboardingForm.value.select6;
      reqBody['page'] = this.pageNumber;
      reqBody['rows'] = this.pageSize;
      reqBody['searchString'] = str;
      reqBody['filters'] = this.filtersData;
      reqBody['sortByColumn'] = this.sortByColumn;
      if (this.sorting == "") {
        reqBody['sorting'] = "descending";
      }
      else {
        reqBody['sorting'] = this.sorting;
      }


      const data = reqBody;
      // this.files=[];
      this.filterReqObj = finalObj;
      this.pagesService.getAttributeListByApplicationId(data).subscribe(results => {
        this.oldTotalRec = this.attributeTotalRecords;
        const responseData = results.success.result.records;
        // responseData.forEach(element => {
        //   event.filteredValue.push(element);
        //   this.files.push(element);
        // });
        if (responseData && responseData.length > 0) {
          this.tableList = responseData;
          // this.files=responseData;
          this.tableList1 = responseData;

          for (let i = 0; i < this.tableList1.length; i++) {
            let groupListArray = [];
            this.tableList1[i].data?.attributeGroups.forEach((element1: any = {}) => {
              groupListArray.push(element1.attributeGroupName);
            });
            let groupList = groupListArray.toString();
            let node = {
              data: {
                attributeId: this.tableList1[i]?.data?.attributeId,
                attributeResourceName: this.tableList1[i]?.data?.attributeResourceName,
                attributeResourceId: this.tableList1[i]?.data?.attributeResourceId,
                attributeTypeName: this.tableList1[i]?.data?.attributeTypeName,
                attributeGroupName: groupList,
                isDefault: this.tableList1[i]?.data?.isDefault,
                isPrivate: this.tableList1[i]?.data?.isPrivate,

              },
              leaf: false
            };
            this.filesFilter.push(node);
            event.filteredValue.push(node);
          }
          this.filesFilter = [...this.filesFilter];
          this.files = this.filesFilter;
        }
        console.log(event.filteredValue, "filtered value");
        console.log(this.filesFilter, "files filter=====");
        // this.files=event.filteredValue;
        console.log(this.files, "files value");
      });
      // event.filters = oldFilter;

    }
  }
  oldFilter(oldFilter: any) {
    throw new Error('Method not implemented.');
  }
  attributeSearchFilterMethod(event: any) {
    let filtered: any[] = [];
    let items: any;
    let cc;
    let query = event.target.value;
    this.tableList = [];
    this.tableList1 = [];
    this.paginator?.changePage(0);

    let reqBody = {};
    if (this.sorting == "") {
      reqBody['sorting'] = "descending";
    }
    else {
      reqBody['sorting'] = this.sorting;
    }
    reqBody['applicationId'] = this.ApplicationOnboardingForm.value.select6;
    reqBody['page'] = 1;
    reqBody['rows'] = this.pageSize;
    reqBody['searchString'] = this.ApplicationOnboardingForm.value.textFieldAttribute;
    reqBody['filters'] = {};
    reqBody['sortByColumn'] = this.sortByColumn;
    const data = reqBody;
    this.pagesService.getAttributeListByApplicationId(data).subscribe(results => {
      const responseData = results.success.result.records;
      this.tableList1 = responseData;
      this.files = [];
      for (let i = 0; i < this.tableList1.length; i++) {

        let groupListArray = [];
        this.tableList1[i].data?.attributeGroups.forEach((element1: any = {}) => {
          groupListArray.push(element1.attributeGroupName);
        });
        let node = {
          data: {
            attributeId: this.tableList1[i]?.data?.attributeId,
            attributeResourceName: this.tableList1[i]?.data?.attributeResourceName,
            attributeResourceId: this.tableList1[i]?.data?.attributeResourceId,
            attributeTypeName: this.tableList1[i]?.data?.attributeTypeName,
            attributeGroupName: groupListArray.toString(),
            isDefault: this.tableList1[i]?.data?.isDefault,
            isPrivate: this.tableList1[i]?.data?.isPrivate,

          },
          leaf: false
        };
        this.files.push(node);
        // if(!this.files.includes(this.tableList1[i]?.data?.attributeId)){
        //   if(this.files.length<1){
        //     this.files.push(node);
        //   }else{
        //   for (let j = 0; j<this.files.length; j++) {
        //     if(this.files[j].data?.attributeId!=this.tableList1[i]?.data?.attributeId){
        //          this.files.push(node);
        //         //  this.files=this.files.concat(node);
        //       }
        //   }
        // }
        // }
        // this.files=[...this.files,...node[i]];
      }
      this.files = [...this.files];
    });

  }
  sortData(event: SortEvent) {

    let fieldName = event.field;
    let orderNum = event.order;
    // let fieldName = event.multiSortMeta[0].field;
    // let orderNum = event.multiSortMeta[0].order;
    let orderBy;
    if (orderNum == -1) {
      orderBy = "ascending";
    }
    else {
      orderBy = "descending";
    }
    let str = '';
    const search = this.ApplicationOnboardingForm.value.textFieldAttribute;
    if (search != null) {
      str = search;
    }

    if (this.sortByColumn != fieldName || this.sorting != orderBy) {
      this.sortByColumn = fieldName;
      this.sorting = orderBy;
      let reqBody = {};
      reqBody['applicationId'] = this.ApplicationOnboardingForm.value.select6;
      reqBody['page'] = 1;
      reqBody['rows'] = this.pageSize;
      reqBody['rows'] = this.pageSize;
      reqBody['parentId'] = "0";
      reqBody['sortByColumn'] = fieldName;
      reqBody['sorting'] = orderBy;
      reqBody['parentId'] = "0";
      const data = reqBody;
      this.pagesService.getAttributeListByApplicationIdLazy(data).subscribe(results => {
        this.files = [];
        const responseData = results.success.result.records;
        this.attributeTotalRecords = results.success.result.recordsCount;
        this.oldTotalRec = results.success.result.recordsCount;
        this.tableList1 = responseData;
        this.customSort(event);
        for (let i = 0; i < this.tableList1.length; i++) {

          let groupListArray = [];
          this.tableList1[i].data?.attributeGroups.forEach((element1: any = {}) => {
            groupListArray.push(element1.attributeGroupName);
          });
          let node = {
            data: {
              attributeId: this.tableList1[i]?.data?.attributeId,
              attributeResourceName: this.tableList1[i]?.data?.attributeResourceName,
              attributeResourceId: this.tableList1[i]?.data?.attributeResourceId,
              attributeTypeName: this.tableList1[i]?.data?.attributeTypeName,
              attributeGroupName: groupListArray.toString(),
              isDefault: this.tableList1[i]?.data?.isDefault,
              isPrivate: this.tableList1[i]?.data?.isPrivate,

            },
            leaf: false
          };
          this.files.push(node);
          // if(!this.files.includes(this.tableList1[i]?.data?.attributeId)){
          //   if(this.files.length<1){
          //     this.files.push(node);
          //   }else{
          //   for (let j = 0; j<this.files.length; j++) {
          //     if(this.files[j].data?.attributeId!=this.tableList1[i]?.data?.attributeId){
          //          this.files.push(node);
          //         //  this.files=this.files.concat(node);
          //       }
          //   }
          // }
          // }
          // this.files=[...this.files,...node[i]];
        }
        this.files = [...this.files];
      });
    }


  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else {
        let res = (value1 > value2) ? 1 : 0;
        result = (value1 < value2) ? -1 : res;
      }


      return (event.order * result);
    });
  }
  gethostattributetableList(): void {
    this.attributeFlag = false;
    let str;
    if (str == null) {
      this.searchStr = '';
    }
    else {
      this.searchStr = str;
    }

    let reqBody = {};
    reqBody['applicationId'] = this.ApplicationOnboardingForm.value.select6;
    reqBody['page'] = 1;
    reqBody['rows'] = this.pageSize;
    reqBody['searchString'] = this.searchStr;
    reqBody['sortByColumn'] = this.sortByColumn;
    if (this.sorting == "") {
      reqBody['sorting'] = "descending";
    }
    else {
      reqBody['sorting'] = this.sorting;
    }
    const data = reqBody;
    this.pagesService.getAttributeListByApplicationId(data).subscribe(results => {
      const responseData = results.success.result.records;
      this.attributeTotalRecords = results.success.result.recordsCount;
      this.oldTotalRec = results.success.result.recordsCount;
      if (responseData && responseData.length > 0) {
        this.attributeList = responseData;
        this.files = this.attributeList;
        this.tableList1 = responseData;
        this.errorFlag = false;
      } else {
        this.errorFlag = true;
      }
    });
  }


  loadNodes() {
    this.files = [];
    this.loading = true;

    this.attributeFlag = false;

    let reqBody = {};
    reqBody['applicationId'] = this.ApplicationOnboardingForm.value.select6;
    reqBody['page'] = 1;
    reqBody['rows'] = this.pageSize;
    reqBody['searchString'] = this.searchStr;
    reqBody['sortByColumn'] = this.sortByColumn;
    reqBody['parentId'] = 0;
    if (this.sorting == "") {
      reqBody['sorting'] = "descending";
    }
    else {
      reqBody['sorting'] = this.sorting;
    }
    const data = reqBody;
    this.pagesService.getAttributeListByApplicationIdLazy(data).subscribe(results => {
      this.files = [];
      const responseData = results.success.result.records;
      this.attributeTotalRecords = results.success.result.recordsCount;
      this.oldTotalRec = results.success.result.recordsCount;
      if (responseData && responseData.length > 0) {
        this.attributeList = responseData;
        this.tableList1 = responseData;
        this.errorFlag = false;
        setTimeout(() => {
          // this.files = [];


          for (let i = 0; i < this.tableList1.length; i++) {

            let groupListArray = [];
            this.tableList1[i].data?.attributeGroups.forEach((element1: any = {}) => {
              groupListArray.push(element1.attributeGroupName);
            });
            let node = {
              data: {
                attributeId: this.tableList1[i]?.data?.attributeId,
                attributeResourceName: this.tableList1[i]?.data?.attributeResourceName,
                attributeResourceId: this.tableList1[i]?.data?.attributeResourceId,
                attributeTypeName: this.tableList1[i]?.data?.attributeTypeName,
                attributeGroupName: groupListArray.toString(),
                isDefault: this.tableList1[i]?.data?.isDefault,
                isPrivate: this.tableList1[i]?.data?.isPrivate,

              },
              leaf: false
            };
            this.files.push(node);
            // if(!this.files.includes(this.tableList1[i]?.data?.attributeId)){
            //   if(this.files.length<1){
            //     this.files.push(node);
            //   }else{
            //   for (let j = 0; j<this.files.length; j++) {
            //     if(this.files[j].data?.attributeId!=this.tableList1[i]?.data?.attributeId){
            //          this.files.push(node);
            //         //  this.files=this.files.concat(node);
            //       }
            //   }
            // }
            // }
            // this.files=[...this.files,...node[i]];
          }
          this.files = [...this.files];
          this.loading = false;
          this.cd.markForCheck();
        }, 1000);
      } else {
        this.errorFlag = true;
      }
    });

  }

  onNodeExpand(event: any) {
    this.loading = true;
    // const node = event.node;
    const node = event.node;
    let reqBody = {};
    reqBody['applicationId'] = this.ApplicationOnboardingForm.value.select6;
    reqBody['page'] = 1;
    reqBody['rows'] = this.pageSize;
    reqBody['searchString'] = this.searchStr;
    reqBody['sortByColumn'] = this.sortByColumn;
    reqBody['parentId'] = event.node.data.attributeResourceId;
    if (this.sorting == "") {
      reqBody['sorting'] = "descending";
    }
    else {
      reqBody['sorting'] = this.sorting;
    }
    const data = reqBody;
    this.pagesService.getAttributeListByApplicationIdLazy(data).subscribe(results => {
      const responseData = results?.success?.result?.records;
      // this.attributeTotalRecords = results.success.result.recordsCount;
      this.oldTotalRec = results?.success?.result?.recordsCount;
      if (responseData && responseData.length > 0) {
        this.attributeList = responseData;
        // this.files=this.attributeList;
        this.childList = responseData;
        this.treeLazy(event);
        this.errorFlag = false;
      } else {
        this.errorFlag = true;
        if (responseData == undefined) {
          node.leaf = true;
          node.expanded = false;
          for (let j = 0; j < this.files.length; j++) {
            if (this.files[j]?.data?.attributeId == event.node.data.attributeId) {
              this.files.splice(j, 1, node);
            }
          }

        }
      }
    });
  }
  treeLazy(event: any) {
    setTimeout(() => {
      this.tempChild = [];
      this.loading = false;
      const node = event.node;
      //  console.log('node data',node);
      node.children = [];
      let child: any = [];
      //  node.children  = this.childList;
      for (let i = 0; i < this.childList.length; i++) {
        let groupListArray = [];
        this.childList[i].data?.attributeGroups.forEach((element1: any = {}) => {
          groupListArray.push(element1.attributeGroupName);
        });
        let groupList = groupListArray.toString();
        child = {
          data: {
            attributeId: this.childList[i]?.data?.attributeId,
            attributeResourceName: this.childList[i]?.data?.attributeResourceName,
            attributeResourceId: this.childList[i]?.data?.attributeResourceId,
            attributeTypeName: this.childList[i]?.data?.attributeTypeName,
            attributeGroupName: groupList,
            isDefault: this.childList[i]?.data?.isDefault,
            isPrivate: this.childList[i]?.data?.isPrivate,

          },
          leaf: false
        };
        // for (let i = 0; i<this.childList.length; i++) {
        // this.files[i].data?.attributeId
        // if(!this.files.includes(this.childList[i]?.data?.attributeId)){
        //  this.files.push(node);
        //    this.files=this.files.concat(node);
        // // }
        this.tempChild.push(child);
      }
      node.children = this.tempChild;
      // for (let j = 0; j<this.files.length; j++) {
      //   if(this.files[j]?.data?.attributeId==event.node.data.attributeId){
      //   // if(event.node.data.attributeId!=this.childList[i]?.data?.attributeId){

      //       node.children=[child];
      //           //  this.files.push(node);
      //       node.leaf=false;
      //       //  this.files=this.files.concat(node);
      //       this.files.splice(j,1,node);
      //     // }
      //   }
      // }

      // for (let i = 0; i<this.childList.length; i++) {
      //       for (let j = 0; j<this.files.length; j++) {
      //         if(this.files[j]?.data?.attributeId==event.node.data.attributeId){
      //         // if(event.node.data.attributeId!=this.childList[i]?.data?.attributeId){
      //             //  this.files.push(node);
      //             node.children=this.childList;
      //             // node.children.leaf=false;
      //             // node.children.expanded=true;
      //             this.files.splice(j,1,node);
      //           // }
      //         }
      //       }}
      this.files = [...this.files];
    }, 500);
  }

  ViewAttributeHierarchy() {
    this.ref = this.dialogService.open(ViewHostHierarchyAttributesComponent, {
      data: { id: this.ApplicationOnboardingForm.value.select6, activeData: this.activeItemAttribute },
      header: 'View Attribute Details',
      width: '80%',
      contentStyle: { 'max-height': '350px', overflow: 'visible' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      this.ref.onClose.subscribe(msg => {
        this.messageFlag = true;
        if (msg == 'Attribute Edited Successfully') {
          this.messageFlag = true;
          this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
          setTimeout(() => {
            this.messageFlag = false;
          }, 3000);
          this.loadNodes();
        }
        else if (msg == 'Failed to Edit Attribute') {
          this.messageFlag = true;
          this.messageService.add({ key: 'myKey1', severity: 'error', detail: msg });
          setTimeout(() => {
            this.messageFlag = false;
          }, 3000);
        }
      });
    });
  }

  AddNewNextLevel() {
    console.log(this.activeItemAttribute);
    this.ref = this.dialogService.open(AddNextLevelHierarchyAttributesComponent, {
      data: { id: this.ApplicationOnboardingForm.value.select6, activeData: this.activeItemAttribute },
      header: 'Add Next Level',
      width: '80%',
      contentStyle: { 'max-height': '350px', overflow: 'scroll' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Attribute Added Successfully') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.loadNodes();
      }
      else if (msg == 'Attribute Addition Failed') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
    })
  }


  openDownloadAttributeDialog() {

    let selectedApplicationId = this.ApplicationOnboardingForm.value.select6;

    this.ref = this.dialogService.open(DownloadAttributeComponent, {
      data: { id: this.activeItem, dialogFlag: 'attributeTypes', applicationId: selectedApplicationId },
      header: 'Export Attributes',
      width: '50%',
      contentStyle: { 'max-height': '160px', overflow: 'visible' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;

    class apiResponse {
      type: string; message: string
    }
    this.ref.onClose.subscribe((apiResponse: apiResponse) => {
      this.messageFlag = true;
      console.log("apiResponse");
      console.log(apiResponse);


      if (apiResponse && apiResponse.type) {
        this.messageService.add({ key: 'myKey1', severity: apiResponse.type, detail: apiResponse.message });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }

    });
  }
  treeCheck() {
    if (this.pagesService.attributeTreeEnable) {
      this.loadNodes();
      this.attributeFlag = false;
    } {
      this.AttributeListing();
      this.attributeFlag = false;

    }
  }
}
