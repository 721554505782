import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PagesService } from '../pages.service';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
@Component({
  selector: 'jhi-deleteattribute',
  templateUrl: './DeleteRole.component.html',
  styleUrls: ['./DeleteRole.component.scss'],
})
export class DeleteRoleComponent implements OnInit {
  DeleteRoleForm = this.fb.group({
  });
  pageloadvalues: any;
  groupId: any;
  appId: any;
  resourceId: any;
  active: any;
  dName: any;
  Ucount: any;
  Gcount: any;
  flag1: boolean;

  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    private messageService: MessageService,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.DeleteRoleForm.patchValue(JSON.parse(data));
    }
  }

  ngOnInit(): void {
    this.active = this.config?.data?.id;
    this.dName = this.config?.data?.name;
    this.getuserTableList();
    this.getGroupList();
  }

  onClickNo($event: UntypedFormGroup): void {
    this.ref.close();
  }

  getuserTableList(): void {
    this.pagesService.getuserTableDataViewRole(this.active).subscribe(results => {
      if (results?.error?.messageCode == "CIAM 200") {
        this.Ucount = 0;
      }
      else if (results?.success?.messageCode == "CIAM 250") {
        const responseData = results.success.result;
        this.Ucount = responseData.length;
      }
    });
  }

  getGroupList(): void {
    this.pagesService.getGroupByRole(this.active).subscribe(results => {
      if (results?.error?.messageCode == "CIAM 300") {
        this.Gcount = 0;
      }
      else if (results?.success?.messageCode == "CIAM 350") {
        const responseData = results.success.result;
        this.Gcount = responseData.length;
      }
    });
  }

  deleteRole() {
    const data: any = this.config?.data?.id;
    this.pagesService.deleteRole(data).subscribe({
      next: (results: any) => {
        this.ref.close("success");
      },
      error: error => {
        this.ref.close("failed");
      }
    });
  }

}