import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PagesService } from '../pages.service';
import { validateAllFormFields } from 'app/shared/util/reactiveform-util';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'jhi-editgroup',
  templateUrl: './EditGroup.component.html',
  styleUrls: ['./EditGroup.component.scss'],
  providers: [MessageService],
})
export class EditGroupComponent implements OnInit {
  EditGroupForm = this.fb.group({
    viewGroupID: [null, []],
    displayName: [null, [Validators.required]],
    description: [null, []],
    selectGroup: [null, []],
    selectUsers: [null, []],
    selectRole: [null, []],
    userList: [null, []],
    roleList: [null, []],
    groupType: [null, []],
  });
  messageFlag = true;

  activeIndex1: number = 0;
  enableBtn1 = true;
  enableBtn2 = true;
  enableBtn3 = true;
  activeIndex2: number = 0;
  stateOptions: any[];
  value1: string = 'simple';
  groupID = '';
  data: any;
  navigation: any;
  clicked1 = false;
  clicked2 = true;
  savedId: any;
  clicked = true;
  tab1 = false;
  tab2 = false;
  tab3 = false;
  tab4 = false;
  pageloadvalues: any;
  ccprimebreadcrumb1List = [
    { label: `User Management` },
    { label: `User Group`, routerLink: ['/pages/listgroups'] },
    { label: `Edit user group` },
  ];
  homeiconccprimebreadcrumb1 = { icon: 'pi pi-home', routerLink: ['/'] };
  htmlSrc: any;
  selectGroupList: any[] = [];
  selectedUserList: any[] = [];
  selectedRoleList: any[] = [];
  userListList: any[] = [];
  roleListList: any[] = [];
  exUserList: any[] = [];
  newUserList: any[] = [];
  exRoleList: any[] = [];
  newRoleList: any[] = [];
  nameFlag: boolean = false;
  firstSave = false;
  selectButtonValue: any;
  oldGroupName: any;
  assignRoleCount = 0;
  deAssignRoleCount = 0;
  assignUserCount = 0;
  deAssignUserCount = 0;
  msg: string[] = [];
  msg1: string[] = [];
  oldDescription: any;
  wfFlag: boolean = false;
  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    private messageService: MessageService
  ) {
    this.navigation = this.router.getCurrentNavigation();
    this.data = this.navigation?.extras?.state?.data;
    if (this.data) {
      this.setPageProperties(JSON.parse(this.data));
    }
    this.stateOptions = [
      { label: 'Simple', value: 'Simple' },
      { label: 'Composite', value: 'Composite' },
    ];
  }

  ngOnInit(): void {
    this.onEditGroupPageLoad();
    this.getUserList();
    this.getroleList();
    this.getSelectedUserList();
    this.getSelectedRoleList();
    this.getExUserList();
    this.getExRoleList();
  }

  onEditGroupPageLoad() {
    this.pagesService.onEditGroupPageLoad(this.groupID).subscribe((results: any) => {
      let pagePropBind = {
        displayName: results.success.result.displayName,
        description: results.success.result.description,
        groupType: results.success.result.type,
      };
      this.pageloadvalues = pagePropBind;
      this.oldDescription = pagePropBind.description
      this.oldGroupName = pagePropBind.displayName;
      this.EditGroupForm.patchValue(pagePropBind);
    });
  }

  setPageProperties(props: any): void {
    const pageData: any = {};
    const calendarControls = ['createdOn'];
    Object.keys(props).forEach(key => {
      if (calendarControls.includes(key)) {
        pageData[key] = new Date(props[key]);
      } else {
        pageData[key] = props[key];
      }
    });
    this.groupID = pageData.viewGroupID;
    this.EditGroupForm.patchValue(pageData);
  }

  onCcprimebutton2($event: UntypedFormGroup): void { }

  colorChange() {
    document.getElementById('displayName_id')!.style.borderColor = 'grey';
  }

  onEditGroup($event: UntypedFormGroup): void {
    $event.updateValueAndValidity();
    if (!$event.valid) {
      validateAllFormFields($event);
      return;
    }

    const reqBody = {};
    if (this.oldGroupName != this.EditGroupForm.value?.displayName) {
      reqBody['displayName'] = this.EditGroupForm.value?.displayName;
    }
    if (this.oldDescription != this.EditGroupForm.value?.description) {
      reqBody['description'] = this.EditGroupForm.value?.description;
    }
    reqBody['type'] = 'Simple';
    const formData = reqBody;
    this.messageFlag = true;
    this.pagesService.onEditUserGroup(formData, this.groupID).subscribe(
      {
        next: results => {
          this.oldGroupName = results.success.result.displayName;
          this.oldDescription = results.success.result.description;
          this.savedId = results.success.result.id;
          this.messageFlag = true;
          this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'Group edited Successfully.' });
          setTimeout(() => {
            this.messageFlag = false;
          }, 3000);
          this.tab1 = true;
          this.firstSave = true;
          this.enableBtn1 = true;
          this.showTab();
          if (this.selectButtonValue == 'composite') {
            this.clicked2 = false;
          } else {
            this.clicked = false;
          }
          this.showSecondTab();
        },
        error: () => {
          this.messageFlag = true;
          this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Failed to edit group. Please Try again.' });
          setTimeout(() => {
            this.messageFlag = false;
          }, 3000);
        }
      });
  }

  CheckGroupName($event: UntypedFormGroup): void {
    if (this.oldGroupName != this.EditGroupForm.value?.displayName) {
      const reqBody = this.EditGroupForm.value?.displayName;
      const formData = reqBody;
      const formValues = this.EditGroupForm.value?.displayName;
      this.pagesService.onCheckGroupName(formData, formValues).subscribe(
        results => {
          if (results?.error?.code == 400 || results?.success?.code == 400) {
            this.nameFlag = true;
            this.enableBtn1 = true;
            document.getElementById('displayName_id')!.style.borderColor = 'red';
            this.enableBtn1 = true;
          } else if (results.success.code == 200) {
            this.nameFlag = false;
            document.getElementById('displayName_id')!.style.borderColor = 'grey';
            this.onEditGroup($event);

          }
        },
      );
    }
    else {
      this.onEditGroup($event);
    }
  }

  getUserList(): void {
    this.pagesService.getUserListingExD().subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.userListList.push(data);
        });
        this.userListList = [...this.userListList];
      }
    });
  }

  getroleList(): void {
    this.pagesService.gettableDataRoles().subscribe(results => {
      const responseData = results.success.result.records;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.displayName;
          data.value = element.id;
          this.roleListList.push(data);
        });
        this.roleListList = [...this.roleListList];
      }
    });
  }

  getSelectedUserList(): void {
    this.pagesService.getuserTableDataViewGroup(this.groupID).subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.firstName;
          data.value = element.id;
          this.selectedUserList.push(data.value);
        });
        this.selectedUserList = [...this.selectedUserList];
      }
    });
  }

  getSelectedRoleList(): void {
    this.pagesService.getRoleByGroup(this.groupID).subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.selectedRoleList.push(data.value);
        });
        this.selectedRoleList = [...this.selectedRoleList];
      }
    });
  }

  getExUserList() {
    this.pagesService.getuserTableDataViewGroup(this.groupID).subscribe(results => {
      const responseData = results.success.result;
      this.exUserList = [];
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.firstName;
          data.value = element.id;
          this.exUserList.push(data.value);
        });
        this.exUserList = [...this.exUserList];
      }
    });
  }

  getNewUserList() {
    const responseData = this.EditGroupForm.value.userList;
    this.newUserList = [];
    if (responseData && responseData.length > 0) {
      responseData.forEach((element: any = {}) => {
        const data = {
          label: null,
          value: null,
        };
        data.label = element.firstName;
        data.value = element.id;
        this.newUserList.push(data.value);
      });
      this.newUserList = [...this.newUserList];
    }
  }

  firstCheckSo(assignList: any, results: any) {
    for (let i = 0; i < assignList.length; i++) {
      if (results.success.result.AssingUserToGroup[i]?.messageCode == 'CIAM 151') {
        this.assignUserCount = this.assignUserCount + 1;
        this.wfFlag = false;
      } else if (results.success.result.AssingUserToGroup[i]?.messageCode == 'CIAM 154') {
        this.assignUserCount = this.assignUserCount + 1;
        this.wfFlag = true;
      }
    }
  }

  onAssignUsersToGroup($event: UntypedFormGroup): void {
    $event.updateValueAndValidity();
    this.newUserList = this.EditGroupForm.value.userList;
    const assignList = this.newUserList.filter(x => !this.exUserList.includes(x));
    const deassignList = this.exUserList.filter(x => !this.newUserList.includes(x));
    const reqBody = {};
    reqBody['tenantId'] = this.pagesService.tenantId;
    reqBody['groupId'] = this.groupID;
    reqBody['assignList'] = assignList;
    reqBody['deAssignList'] = deassignList;
    const formValues = reqBody;
    this.messageFlag = true;
    this.pagesService.AssignUsersToGroups(formValues).subscribe(
      {
        next: results => {
          this.firstCheckSo(assignList, results);
          for (let i = 0; i < deassignList.length; i++) {
            if (results.success.result["De-AssingUserFromGroup"][i]?.messageCode == 'CIAM 152') {
              this.deAssignUserCount = this.deAssignUserCount + 1;
              this.wfFlag = false;
            } else if (results.success.result["De-AssingUserFromGroup"][i]?.messageCode == 'CIAM 154') {
              this.deAssignUserCount = this.deAssignUserCount + 1;
              this.wfFlag = true;
            }
          }
          if (this.wfFlag) {
            this.navigateToMyReq('Assign/Deassign User to User Group request has been sent for approval');

          }
          else if (!this.wfFlag) {
            if (this.deAssignUserCount == 0 && this.assignUserCount > 0) {
              this.msg1.push(this.assignUserCount + ' User Assigned successfully');
            } else if (this.deAssignUserCount > 0 && this.assignUserCount > 0) {
              this.msg1.push(this.assignUserCount + ' User Assigned successfully');

              this.msg1.push(this.deAssignUserCount + ' User De-assigned successfully');
            } else if (this.deAssignUserCount > 0 && this.assignUserCount == 0) {
              this.msg1.push(this.deAssignUserCount + ' User De-assigned successfully');
            }
            this.messageFlag = true;

            this.messageService.add({ key: 'myKey1', severity: 'success', detail: this.msg1.join('\n') });
            setTimeout(() => {
              this.messageFlag = false;
              this.assignUserCount = 0;
              this.deAssignUserCount = 0;
              this.msg1 = [];
            }, 3000);
            this.tab3 = true;
            this.showTab();
            this.enableBtn2 = true;
            this.clicked2 = false;
            this.getExUserList();
          }
        },
        error: error => {
          if (error.error?.error?.messageCode == "CIAM 105") {
            this.messageFlag = true;
            this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Request already available for approval.' });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
          }
          else {
            this.messageFlag = true;
            this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Failed to Assign Users.' });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
          }
        }
      });
  }

  navigateToMyReq(msg: any): void {
    const navigationExtras: NavigationExtras = {
      state: {
        msg: msg,
      },
    };
    this.router.navigate(['/pages/myrequestslisting'], navigationExtras);
  }

  getExRoleList() {
    this.pagesService.getRoleByGroup(this.groupID).subscribe((results: any) => {
      const responseData = results.success.result;
      this.exRoleList = [];
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.exRoleList.push(data.value);
        });
        this.exRoleList = [...this.exRoleList];
      }
    });
  }

  getNewRoleList() {
    const responseData = this.EditGroupForm.value.roleList;
    this.newRoleList = [];
    if (responseData && responseData.length > 0) {
      responseData.forEach((element: any = {}) => {
        const data = {
          label: null,
          value: null,
        };
        data.label = element.first_name;
        data.value = element.id;
        this.newRoleList.push(data.value);
      });
      this.newRoleList = [...this.newRoleList];
    }
  }


  firstCheckRGSo(assignList: any, results: any) {
    for (let i = 0; i < assignList.length; i++) {
      if (results.success.result.AssingRolesToGroup[i]?.messageCode == 'CIAM 151') {
        this.assignRoleCount = this.assignRoleCount + 1;
        this.wfFlag = false;
      }
      else if (results?.success?.result?.AssingRolesToGroup[i]?.messageCode == 'CIAM 154') {
        this.wfFlag = true;
      }
    }
  }

  assignRolesToGroup($event: UntypedFormGroup): void {
    $event.updateValueAndValidity();
    this.newRoleList = this.EditGroupForm.value.roleList;
    const assignList = this.newRoleList.filter(x => !this.exRoleList.includes(x));
    const deassignList = this.exRoleList.filter(x => !this.newRoleList.includes(x));
    const reqBody = {};
    reqBody['tenantId'] = this.pagesService.tenantId;
    reqBody['groupId'] = this.groupID;
    reqBody['assignList'] = assignList;
    reqBody['deAssignList'] = deassignList;
    const formValues = reqBody;
    this.messageFlag = true;
    this.pagesService.onSaveAssignRolesToGroup(formValues).subscribe(
      {
        next: results => {
          this.firstCheckRGSo(assignList, results);
          for (let i = 0; i < deassignList.length; i++) {
            if (results.success.result["De-AssingRolesFromGroup"][i]?.messageCode == 'CIAM 152') {
              this.deAssignRoleCount = this.deAssignRoleCount + 1;
              this.wfFlag = false;
            }
            else if (results?.success?.result["De-AssingRolesFromGroup"][i]?.messageCode == 'CIAM 154') {
              this.wfFlag = true;
            }
          }
          if (this.wfFlag) {
            this.navigateToMyReq('Assign/Deassign Role to User Group request has been sent for approval');
          }
          else {
            if (this.deAssignRoleCount == 0 && this.assignRoleCount > 0) {
              this.msg.push(this.assignRoleCount + ' Role Assigned successfully');
            } else if (this.deAssignRoleCount > 0 && this.assignRoleCount > 0) {
              this.msg.push(this.assignRoleCount + ' Role Assigned successfully');

              this.msg.push(this.deAssignRoleCount + ' Role De-assigned successfully');
            } else if (this.deAssignRoleCount > 0 && this.assignRoleCount == 0) {
              this.msg.push(this.deAssignRoleCount + ' Role De-assigned successfully');
            }
            this.messageFlag = true;
            this.messageService.add({ key: 'myKey1', severity: 'success', detail: this.msg.join('\n') });
            setTimeout(() => {
              this.messageFlag = false;
              this.assignRoleCount = 0;
              this.deAssignRoleCount = 0;
              this.msg = [];
            }, 3000);
            this.tab4 = true;
            this.showTab();
            this.enableBtn3 = true;
            this.clicked2 = false;
            this.getExRoleList();
          }
        },
        error: error => {
          if (error.error?.error?.messageCode == "CIAM 105") {
            this.messageService.add({ key: 'myKey1', severity: 'error', detail: "Request already available for approval" });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
          }
          else {
            this.messageFlag = true;
            this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Failed to Assign Roles.' });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
          }
        }
      });
  }

  onCcprimebutton18($event: UntypedFormGroup): void { }
  onCcprimebutton19($event: UntypedFormGroup): void { }

  onCcprimebutton4($event: UntypedFormGroup): void {
    this.router.navigate(['/pages/addroles']);
  }

  showSecondTab() {
    this.activeIndex1 = this.activeIndex1 === 0 ? (this.activeIndex1 = 2) : this.activeIndex1;//NOSONAR
  }

  showTab() {
    if (this.pagesService.pageRebacFlag == "false") {
      if (this.activeIndex1 === 0) {
        this.activeIndex1 = 2; //show users tab since composite tab disabled
      } else if (this.activeIndex1 === 2) {
        this.activeIndex1 = 3;
      } else if (this.activeIndex1 === 3) {
        this.activeIndex1 = 4;
      }
    }
    else if (this.activeIndex1 === 0 || this.activeIndex1 === 2) {
      this.activeIndex1 = 2; //show users tab since composite tab disabled
    }
  }

}
