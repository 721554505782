<div class="layout-content custom-container">
    <form [formGroup]="DownloadAttributeForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                <div class=" p-fluid   p-field    ">
                    <label [ngClass]="{'field-required': DownloadAttributeForm.get('select')?.errors?.required}">
                        Select Attribute types
                    </label>
                    <div class="p-fluid  p-field custom-checkbox-color">
                        <p-multiSelect filterBy="attributeTypeName" id="attributeTypeList" name="attributeTypeList" filterPlaceHolder="Select attribute type"
                            [options]="attributeTypeList" [filter]="true" formControlName="selectedAttributeTypes" optionLabel="attributeTypeName"
                            optionValue="id" selectedItemsLabel="{0} attribute type selected" display="chip"
                            resetFilterOnHide="true" placeholder="Select attribute type" class="custom-chips"
                            (onChange)="enableSubmitButton()"
                             optionDisabled="inactive"
                             [maxSelectedLabels]="5">
                            <ng-template let-each pTemplate="selectedItem" style="z-index: -1;">
                                {{each.attributeTypeName}}
                            </ng-template>
                            <ng-template let-item pTemplate="each">
                                <div class="drop-item" style="display: inline;">
                                    {{item.attributeTypeName}}
                                </div>
                            </ng-template>
                        </p-multiSelect>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right p-mb-2">
                <div class="btnwrap">
                    <button pButton type="button" class="p-button-brand2 p-button p-mr-3"
                        (click)="onClose(DownloadAttributeForm);" label="Cancel">
                    </button>
                </div>
                <div class="btnwrap">
                    <button pButton class="p-button" type="button"
                        (click)=" downloadHostAttributeHierarchy();" label="Export"
                        [disabled]="disableSubmitButoon || this.DownloadAttributeForm?.value?.selectedAttributeTypes?.length==0">
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>