import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { ADD, CHECKED, REMOVE, SEPARATOR, UNCHECKED, USER_MANAGEMENT_COMPONENT_ID } from 'app/constants';
import { LoggingEventService } from "app/logging-event.service";
import { ContextName, EventName, ItemName } from "app/shared/constants/logging-event.constants";
import { IEventContext, IEventData, IEventSubject } from "app/shared/interface/event.interface";

@Injectable({
    'providedIn': 'root'
})
export class EntitlementManagementLoggingService {
    eventTypes: { [name: string]: number } = {};
    contextList: { [name: string]: number } = {};
    eventItems: { [name: string]: number } = {};
    constructor(
        private loggingService: LoggingEventService
    ) {
    }

    async prepareCreateEntEvtData(data: any) {
        const eventDataArray = [];
        let eventData: IEventData = await this.loggingService.getEventData(EventName.REGISTER_ENTITLEMENT,
            true, true, USER_MANAGEMENT_COMPONENT_ID, ContextName.VIEW_CREATE_ENTITLEMENT_PAGE);
        let eventSubjects: IEventSubject[] = [
            await this.loggingService.getEventSubjectData(ItemName.ENTITLEMENT_NAME, data.entitlementName),
            await this.loggingService.getEventSubjectData(ItemName.ENTITLEMENT_ID, data.entitlementId),
            await this.loggingService.getEventSubjectData(ItemName.OWNERSHIP_ENTITLEMENT, data.ownerEntitlementName),
            await this.loggingService.getEventSubjectData(ItemName.OWNERSHIP_COMPONENT, data.ownerComponentName),
            await this.loggingService.getEventSubjectData(ItemName.COMPONENT_NAME, data.componentName)
        ];
        if (data.entitlementDescription) {
            eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.DESCRIPTION, data.entitlementDescription));
        }
        eventData.eventSubjects = eventSubjects;
        eventDataArray.push(JSON.stringify(eventData));
        return JSON.stringify(eventDataArray);
    }

    async prepareEditApproverLogData(obj) {
        const eventDataArray = [];
        let eventData = await this.loggingService.getEventData(EventName.EDIT_APPROVER, true, true, USER_MANAGEMENT_COMPONENT_ID, ContextName.EDIT_APPROVERS, obj.COMPONENT_ID + '-' + obj.ENTITLEMENT_ID + ' - ' + obj.ENTITLEMENT_NAME);
        let eventSubjectsList: IEventSubject[] = [];
        if (obj.IS_APPROVAL_REQUIRED && obj.isNoApprovalRequiredPrevVal) {
            eventSubjectsList.push(await this.loggingService.getEventSubjectData(ItemName.NO_APPROVER_REQUIRED, UNCHECKED, CHECKED));
        }
        if (!obj.IS_APPROVAL_REQUIRED && !obj.isNoApprovalRequiredPrevVal) {
            eventSubjectsList.push(await this.loggingService.getEventSubjectData(ItemName.NO_APPROVER_REQUIRED, CHECKED, UNCHECKED));
        }
        for (const data of obj.levelEventData) {
            let res = data.action === REMOVE ? ItemName.REMOVE_LEVEL : ItemName.EDIT_LEVEL
            let itemName = data.action === ADD ? ItemName.ADD_LEVEL : res;
            let flag = data.action != REMOVE ? true : false;//NOSONAR
            eventSubjectsList.push(await this.loggingService.getEventSubjectData(itemName, flag ? data.value : null, !flag ? data.value : null));
            for (const acData of data.levelActions) {
                let acFlag = acData.action != REMOVE ? true : false;//NOSONAR
                eventSubjectsList.push(await this.loggingService.getEventSubjectData(acData.action === ADD ? ItemName.ADD_ENTITLEMENT : ItemName.REMOVE_ENTITLEMENT, acFlag ? acData.value : null, !acFlag ? acData.value : null));
            }
        }
        eventData.eventSubjects = eventSubjectsList;
        eventDataArray.push(JSON.stringify(eventData));
        return JSON.stringify(eventDataArray);
    }
    async prepareEntitlementActivityLog(formData: FormData) {
        const eventDataArray = [];
        let eventData: IEventData = await this.loggingService.getEventData(EventName.ACTIVITY_LOG_MANAGE_ENTITLEMENT, false, true, USER_MANAGEMENT_COMPONENT_ID, ContextName.VIEW_ACTIVITY_LOG, formData.get('contextValue').toString());//NOSONAR
        eventDataArray.push(JSON.stringify(eventData));
        return JSON.stringify(eventDataArray);
    }

    async prepareUpdateEntEvtData(data: any) {
        const eventDataArray = [];
        let eventData: IEventData = await this.loggingService.getEventData(EventName.EDIT_ENTITLEMENT, true, true, 1, ContextName.VIEW_EDIT_ENTITLEMENT_PAGE, data.componentId + SEPARATOR + data.entitlementId + SEPARATOR + data.entitlementName);
        const eventSubjects: IEventSubject[] = [];

        if (data['entitlementName'] !== data['entitlementNameOldVal']) {
            eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.ENTITLEMENT_NAME, data['entitlementName'], data['entitlementNameOldVal']));
        }
        if (data['ownershipComponentNewName'] !== data['ownershipComponentNameOldVal']) {
            eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.OWNERSHIP_COMPONENT, data['ownershipComponentNewName'], data['ownershipComponentNameOldVal']));
        }
        if (data['ownershipEntitlementNewName'] !== data['ownershipEntitlementNameOldVal']) {
            eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.OWNERSHIP_ENTITLEMENT, data['ownershipEntitlementNewName'], data['ownershipEntitlementNameOldVal']));
        }
        if (data['entitlementDescription'] !== data['entitlementDescriptionOldVal']) {
            eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.DESCRIPTION, data['entitlementDescription'], data['entitlementDescriptionOldVal']));
        }

        eventData.eventSubjects = eventSubjects;
        eventDataArray.push(JSON.stringify(eventData));
        return JSON.stringify(eventDataArray);
    }

    async prepareViewApprovalList(formData: FormData) {
        const eventDataArray = [];
        let eventData: IEventData = await this.loggingService.getEventData(EventName.VIEW_APPROVERS, false, true, USER_MANAGEMENT_COMPONENT_ID, ContextName.VIEW_APPROVALS_PAGE, formData.get('componentId')?.toString() + "-" + formData.get('entitlementId')?.toString() + " - " + formData.get('entitlementName')?.toString());//NOSONAR
        eventDataArray.push(JSON.stringify(eventData));
        return JSON.stringify(eventDataArray);
    }

    async prepareViewManageEntitlement(filter: any) {
        const eventDataArray = [];
        const eventData: IEventData = await this.loggingService.getEventData(EventName.MANAGE_ENTITLEMENT, false, true, USER_MANAGEMENT_COMPONENT_ID, ContextName.VIEW_MANAGE_ENTITLEMENT);
        eventDataArray.push(JSON.stringify(eventData));
        return JSON.stringify(eventDataArray);
    }

    async prepareEntPageEventsData(filter: any) {
        const eventDataArray = [];
        if (filter.hasOwnProperty('SEARCH_TEXT') || filter.SEARCH_TEXT != null || filter.SEARCH_TEXT != undefined) {
            let eventData: IEventData = await this.loggingService.getEventData(EventName.SEARCH_ENTITLEMENT, true, true, USER_MANAGEMENT_COMPONENT_ID, ContextName.SEARCH_DATA);
            let eventSubjects: IEventSubject[] = [
                await this.loggingService.getEventSubjectData(ItemName.SEARCH_DATA, filter.SEARCH_TEXT)
            ];
            eventData.eventSubjects = eventSubjects;
            eventDataArray.push(JSON.stringify(eventData));
        }

        if (filter.hasOwnProperty('COMPONENT_ID_FILTER') || filter.hasOwnProperty('ENTITLEMENT_ID_FILTER') || filter.hasOwnProperty('OWNER_ID_FILTER') || filter.hasOwnProperty('STATUS_IDS') || filter.isClearAllSelected == 'true') {
            let eventData: IEventData = {
                eventTypeId: await this.loggingService.getEventId(EventName.FILTER_MANAGE_ENTITLEMENT),
                componentId: USER_MANAGEMENT_COMPONENT_ID,
                isEventSubject: false,
                isEventContext: true,
                eventName: EventName.FILTER_MANAGE_ENTITLEMENT
            };
            let eventContextList: IEventContext[] = [];
            if (filter.FILTER_COMPONENT_NAMES) {
                for (const component of filter.FILTER_COMPONENT_NAMES) {
                    eventContextList.push(await this.loggingService.getEventContextData(ContextName.FILTER_BY_COMPONENT_NAME, component));
                }
            }
            if (filter.FILTER_ENTITLEMENT_NAMES) {
                for (const entitlement of filter.FILTER_ENTITLEMENT_NAMES) {
                    eventContextList.push(await this.loggingService.getEventContextData(ContextName.FILTER_BY_ENTITLEMENT_NAME, entitlement));
                }
            }
            if (filter.FILTER_OWNER_NAMES) {
                eventContextList.push(await this.loggingService.getEventContextData(ContextName.FILTER_BY_MY_OWNERSHIPS, filter.FILTER_OWNER_NAMES[0]));
            }
            if (filter.FILTER_STATUS_NAMES) {
                for (const status of filter.FILTER_STATUS_NAMES) {
                    eventContextList.push(await this.loggingService.getEventContextData(ContextName.FILTER_BY_ENTITLEMENT_STATUS, status));
                }
            }
            if (filter.isClearAllSelected == 'true') {
                eventContextList.push(await this.loggingService.getEventContextData(ContextName.CLEAR_ALL_FILTER, null));
            }
            eventData.eventContextList = eventContextList;
            eventDataArray.push(JSON.stringify(eventData));
        }


        if (filter.isEntPageChanged == 'true' || filter.pageIndex != 1) {
            let eventData: IEventData = {
                eventTypeId: await this.loggingService.getEventId(EventName.PAGINATION_MANAGE_ENTITLEMENT),
                componentId: USER_MANAGEMENT_COMPONENT_ID,
                isEventSubject: false,
                isEventContext: true,
                eventName: EventName.PAGINATION_MANAGE_ENTITLEMENT
            };
            let eventContextListData: IEventContext[] = [];
            eventContextListData.push(
                await this.loggingService.getEventContextData(ContextName.PAGE_NUMBER, filter.pageIndex),
                await this.loggingService.getEventContextData(ContextName.PAGE_SIZE, filter.pageSize));
            eventData.eventContextList = eventContextListData;
            eventDataArray.push(JSON.stringify(eventData));
        }

        if (filter.hasOwnProperty('FROM_DATE') || (filter.isClearSelected == 'true')) {
            const FROM_DATE = ((new DatePipe('en-US')).transform(filter.FROM_DATE, 'dd MMM yy'));
            const TO_DATE = ((new DatePipe('en-US')).transform(filter.TO_DATE, 'dd MMM yy'));
            let contextName = '';
            let contextValue = '';
            const currentDate = new Date();
            const formatedDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

            const pastWeek = new Date(formatedDate);
            pastWeek.setDate(formatedDate.getDate() - 7);

            const pastMonth = new Date(formatedDate);
            pastMonth.setMonth(formatedDate.getMonth() - 1);

            const past3Months = new Date(formatedDate);
            past3Months.setMonth(formatedDate.getMonth() - 3);

            const past6Months = new Date(formatedDate);
            past6Months.setMonth(formatedDate.getMonth() - 6);

            const pastYear = new Date(formatedDate);
            pastYear.setFullYear(formatedDate.getFullYear() - 1);

            switch (true) {
                case filter.isClearSelected === 'true':
                    contextName = ContextName.CREATION_PERIOD_CLEAR_ALL;
                    contextValue = 'Creation Period - Clear All';
                    break;
                case filter.FROM_DATE.getTime() === pastWeek.getTime():
                    contextName = ContextName.CREATION_PERIOD_PAST_WEEK;
                    contextValue = `Creation Period - Past Week(${FROM_DATE} - ${TO_DATE})`;
                    break;
                case filter.FROM_DATE.getTime() === pastMonth.getTime():
                    contextName = ContextName.CREATION_PERIOD_PAST_MONTH;
                    contextValue = `Creation Period - Past Month(${FROM_DATE} - ${TO_DATE})`;
                    break;
                case filter.FROM_DATE.getTime() === past3Months.getTime():
                    contextName = ContextName.CREATION_PERIOD_PAST_3_MONTHS;
                    contextValue = `Creation Period - Past 3 Months(${FROM_DATE} - ${TO_DATE})`;
                    break;
                case filter.FROM_DATE.getTime() === past6Months.getTime():
                    contextName = ContextName.CREATION_PERIOD_PAST_6_MONTHS;
                    contextValue = `Creation Period - Past 6 Months(${FROM_DATE} - ${TO_DATE})`;
                    break;
                case filter.FROM_DATE.getTime() === pastYear.getTime():
                    contextName = ContextName.CREATION_PERIOD_PAST_YEAR;
                    contextValue = `Creation Period - Past Year(${FROM_DATE} - ${TO_DATE})`;
                    break;
                default:
                    contextName = ContextName.CREATION_PERIOD_CUSTOM_DATE;
                    contextValue = `Creation Period - Custom Date(${FROM_DATE} - ${TO_DATE})`;
                    break;
            }
            const eventData: IEventData = await this.loggingService.getEventData(EventName.CREATION_PERIOD_MANAGE_ENTTITLEMENT, false, true, USER_MANAGEMENT_COMPONENT_ID, contextName, contextValue);
            eventDataArray.push(JSON.stringify(eventData));
        }
        return JSON.stringify(eventDataArray);
    }

    async prepareViewEntitlementdata(filter: any) {
        const eventDataArray = [];
        if (filter.changeEntitlementFlag.toString() == 'true') {
            let eventData: IEventData = await this.loggingService.getEventData(EventName.CHANGE_ENTITLEMENT, false, true, USER_MANAGEMENT_COMPONENT_ID, ContextName.VIEW_ENTITLEMENT_DETAILS_PAGE, filter.COMPONENT_ID + '-' + filter.ENTITLEMENT_ID + "-" + filter.ENTITLEMENT_NAME);
            eventDataArray.push(JSON.stringify(eventData));
            return JSON.stringify(eventDataArray);
        } else {
            let eventData: IEventData = await this.loggingService.getEventData(EventName.EDIT_ENTITLEMENT, false, true, USER_MANAGEMENT_COMPONENT_ID, ContextName.VIEW_ENTITLEMENT_DETAILS_PAGE, filter.COMPONENT_ID + '-' + filter.ENTITLEMENT_ID + "-" + filter.ENTITLEMENT_NAME);
            eventDataArray.push(JSON.stringify(eventData));
            return JSON.stringify(eventDataArray);

        }
    }

    async prepareUpdateEntitlementDetails(entDetails: any) {
        const eventDataArray = [];
        let eventData: IEventData = await this.loggingService.getEventData(EventName.EDIT_ENTITLEMENT_DETAILS, true, true, USER_MANAGEMENT_COMPONENT_ID, ContextName.EDIT_ENTITLEMENT_DETAILS_PAGE, entDetails.COMPONENT_ID + '-' + entDetails.ENTITLEMENT_ID + " - " + entDetails.ENTITLEMENT_NAME);
        let eventSubjects: IEventSubject[] = [];

        for (const e of entDetails.ENTITLEMENT_ROLE_LIST) {
            eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.ADD_ROLE, e.ROLE_ID + ' - ' + e.ROLE_NAME));
        }
        for (const e of entDetails.DELETED_ROLES) {
            eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.DELETE_ROLE, null, e.ROLE_ID + ' - ' + e.ROLE_NAME));
        }
        for (const e of entDetails.ENTITLEMENT_USER_LIST)  {
            eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.ADD_USER, e.USER_ID + ' - ' + e.LOGIN_ID));
            eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.ADD_ENTITLEMENT_ACCESS,e.USER_ID +'*'+ e.LOGIN_ID+'*'+entDetails.COMPONENT_ID+'*'+entDetails.ENTITLEMENT_ID+'*'+entDetails.ENTITLEMENT_NAME+'#'+e.USER_NAME+'-'+e.USER_TYPE+'-'+e.USER_STATUS, null));
        }
        for (const e of entDetails.DELETED_USERS) {
            let userType=e.USER_TYPE_ID==1?'Internal':'External';
            eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.DELETE_USER, null, e.USER_ID + ' - ' + e.LOGIN_ID));
            eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.REMOVE_ENTITLEMENT_ACCESS,null,e.USER_ID +'*'+ e.LOGIN_ID+'*'+entDetails.COMPONENT_ID+'*'+entDetails.ENTITLEMENT_ID+'*'+entDetails.ENTITLEMENT_NAME+'#'+e.USER_NAME+'-'+ userType +'-'+e.USER_STATUS));
        }

        for (const e of entDetails?.DELETE_ROLE_IMPACT) {
            eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.REMOVE_ENTITLEMENT_ACCESS,null,e.user_id +'*'+ e.loginId+'*'+entDetails.COMPONENT_ID+'*'+entDetails.ENTITLEMENT_ID+'*'+entDetails.ENTITLEMENT_NAME+'#'+e.FULL_NAME+'-'+e.USER_TYPE+'-'+e.USER_STATUS));
        }

        for (const e of entDetails?.ADD_ROLE_IMPACT) {
            let userType=e.USER_TYPE_ID==1?'Internal':'External';
            eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.ADD_ENTITLEMENT_ACCESS,e.USER_ID +'*'+ e.LOGIN_ID+'*'+entDetails.COMPONENT_ID+'*'+entDetails.ENTITLEMENT_ID+'*'+entDetails.ENTITLEMENT_NAME+'#'+e.USER_NAME+'-'+ userType +'-'+e.STATUS, null));
        }

        eventData.eventSubjects = eventSubjects;
        eventDataArray.push(JSON.stringify(eventData));
        return JSON.stringify(eventDataArray);
    }
    async prepareUploadEntitlements(formData: FormData) {
        const eventDataArray = [];
        let file = formData.get('file') as File;
        if (file) {
            let filename = file.name;
            let eventDataForMaker: IEventData = await this.loggingService.getEventData(EventName.ENTITLEMENT_UPLOAD_MAKER, true, true, USER_MANAGEMENT_COMPONENT_ID, ContextName.UPLOAD_ENTITLEMENTS_FILE);
            let eventDataForChecker: IEventData = await this.loggingService.getEventData(EventName.ENTITLEMENT_UPLOAD_CHECKER, true, true, USER_MANAGEMENT_COMPONENT_ID, ContextName.UPLOAD_ENTITLEMENTS_FILE);

            let eventSubjects: IEventSubject[] = [await this.loggingService.getEventSubjectData(ItemName.FILE_NAME, filename)];

            eventDataForMaker.eventSubjects = eventSubjects;
            eventDataArray.push(JSON.stringify(eventDataForMaker));

            eventDataForChecker.eventSubjects = eventSubjects;
            eventDataArray.push(JSON.stringify(eventDataForChecker));

            return JSON.stringify(eventDataArray);
        }
        else {
            return 'No file found in the FormData';
        }

    }
}
