<div style="background-color:#F6F8FA;margin-bottom: 25px;">
    <!--  Success Toast -->
    <div class="p-d-flex p-jc-end">
        <p-messages key="myKey1" class="custom-alert-box" *ngIf="this.messageFlag"></p-messages>
    </div>
    <div class="p-col-fixed p-mt-1 p-ml-1 p-pr-0 custom-breadcrumb">
        <p-breadcrumb [model]="ccprimebreadcrumbList" [home]="homeiconccprimebreadcrumb">
        </p-breadcrumb>
    </div>
    <div style="padding: 10px 24px;">
        <div class="p-grid">
            <span class="p-col p-p-0">
                <h5 class="custom-head1">Manage Roles</h5>
            </span>
        </div>

        <div class="p-col-12 p-p-0">
            <div class="header-tabview  p-d-flex p-flex-row">
                <div class="p-col-2 p-d-flex p-flex-column p-pl-0 p-pr-0">
                    <span class="statistics">
                        {{ totalRecords }}
                    </span>
                    <span class="statistics-label">Total Roles</span>
                </div>
                <div class="p-col-3 p-d-flex p-flex-column p-pl-0 p-pr-0">
                    <span class="statistics">
                        {{ totalInactiveRoleCount }}
                    </span>
                    <span class="statistics-label">Inactive Roles</span>
                </div>
            </div>
        </div>


        <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left p-pl-0 p-pr-0">
            <div class="p-fluid p-formgrid p-grid p-mb-2">
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left p-p-0">
                    <div class=" p-fluid ">
                        <label>
                            Application
                        </label> <span class='invalid-feedback-astrik'>*</span>
                        <div class="p-fluid">
                            <p-dropdown id="select1id" name="select1" placeholder="Select" [options]="select1List"
                                [filter]="true" filterBy="name" [autoDisplayFirst]="false" [(ngModel)]="selectedApp"
                                (onChange)="loadRoleData();checkTotalRecords=true" optionLabel="name" [required]="true"
                                [disabled]="disableAppDropdown">
                                <ng-template let-item pTemplate="item">
                                    <div *ngIf="item.name.length &lt;= 35;">
                                        {{item.name}}
                                    </div>
                                    <div *ngIf="item.name.length > 35;" pTooltip="{{item.name}}" tooltipPosition="top">
                                        {{item.name.substring(0,35) + "..." }}
                                    </div>
                                </ng-template>
                            </p-dropdown>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left p-p-0">
                </div>
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined p-right p-p-0"
                    *ngIf="this.roleListFlag">
                    <div class=" p-fluid   p-field    " class="custom-attr-fluid">
                        <div class="p-grid p-pb-1 p-pt-4 p-align-end p-mt-1">
                            <div class="p-col srch p-p-0">
                                <span class="p-input-icon-right p-0 p-mb-0">
                                    <i class="pi pi-search"></i>
                                    <input type="text" id="search-bar" pInputText placeholder="Search"
                                        class="p-inputtext inputtextHeight custom-search-width "
                                        (keyup)="loadRoleData()" [(ngModel)]="search" />
                                </span>
                            </div>

                            <div class="p-m-0 p-ml-auto p-p-0 p-pr-2 w-auto  document-library-wrapper">
                                <div
                                    class=" p-m-0 p-ml-auto p-p-0 p-pr-2 position-relative overView-calender-filter p-mt-0">
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <p-card *ngIf="isFilterSelection" class="filter-selection-role">
            <div class="p-grid p-mb-1" style="position: relative;top: 8px;">
                <div class="p-col-auto" style="vertical-align: middle;">
                    <i class="pi pi-filter"></i>
                </div>

                <div class="p-col" style="vertical-align: middle;">
                    <h6>Filters</h6>
                </div>

                <div class="p-col-auto" style="vertical-align: middle;">
                    <button pButton type="button" icon="pi pi-times" (click)="cancelFilterSection()"
                        styleClass="p-button-sm" class="filter-cancel-btn"></button>
                </div>
            </div>

            <div class="p-grid p-mb-1">
                <div class="p-col-12">
                    <div class="cri-border-bottom"></div>
                </div>
            </div>

            <div class="p-grid p-mb-1" *ngIf="filterMessage">
                <div class="p-col-12">
                    <div class="alert alert-danger alert-dismissible fade show" role="alert">
                        <i class="fa fa-info-circle"></i> {{ filterMessage }}

                        <button type="button" class="close" data-dismiss="alert" aria-label="Close"
                            (click)="filterMessage = ''">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
            </div>

            <div class="p-grid p-mb-1">
                <div class="p-col-12">
                    <p-accordion>
                        <p-accordionTab header="Status" [(selected)]="activeTab.isRoleTypeTab">
                            <p-tree [value]="roleStatus" selectionMode="checkbox" [filter]="true"
                                [(selection)]="selectedRoleStatus" [style]="{ width: '100%' }"></p-tree>
                        </p-accordionTab>
                    </p-accordion>
                </div>
            </div>

            <br />

            <div class="p-grid p-mb-1">
                <div class="p-col-12">
                    <button type="button" pButton (click)="applyFilter()" icon="pi pi-check" label="Apply"
                        class="ui-button-raised ui-button-success pull-right"></button>
                </div>
            </div>
            <br />
        </p-card>
        <div class="" *ngIf="this.selectedApp==null || this.selectedApp==undefined" class="custom-Attrtable-div">
            <label class="d-icons group-icon"></label>
        </div>
        <div class="p-grid" *ngIf="this.selectedApp!=null || this.selectedApp!=undefined ">
            <div class="p-col-12 p-p-0 p-mb-5">
                <p-table #dt [columns]="columns" [value]="roles" [paginator]="true" [rows]="10"
                    [showCurrentPageReport]="true"
                    currentPageReportTemplate="{first} - {last} of {totalRecords} Records"
                    [rowsPerPageOptions]="rowPerPageOptions" styleClass="p-datatable-gridlines"
                    (onPage)="loadNextPaginatorGrid($event)" [totalRecords]="totalRecords" [lazy]="true"
                    [scrollable]="true" [rowHover]="true" (onLazyLoad)="getRoleDataWithPagination($event)"
                    [(selection)]="selectedRecords" (onHeaderCheckboxToggle)="onRowSelectionChange($event)"
                    (onRowSelect)="onRowSelectionChange($event)" (onRowUnselect)="onRowSelectionChange($event)"
                    class="role-table custom-table ciam">
                    <ng-template pTemplate="header" let-columns>
                        <tr>

                            <th scope="col" class="role-id">
                                Role Id
                            </th>
                            <th scope="col">Role Name</th>
                            <th scope="col">Role Status</th>
                            <th scope="col">Self Service</th>
                            <th scope="col">Updated On</th>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-role>

                        <tr>
                            <td style="word-break: break-word;">
                                <span><a routerLink="/pages/roledetails" (click)="navigateToDetailScreen(role)"
                                        style="text-decoration: none !important;">{{role.PRIME_ROLE_ID}}
                                    </a></span>
                            </td>

                            <td>
                                <span>
                                    {{ role.ROLE_NAME }}
                                </span>
                            </td>
                            <td>
                                <div class="dot-role" [ngClass]="{
                                        activeTest: role.ROLE_STATUS === 'Active',
                                        inactiveTest: role.ROLE_STATUS === 'Inactive',
                                        lockedTest: role.ROLE_STATUS === 'Locked'
                                    }"></div>
                                {{ role.ROLE_STATUS }}
                            </td>
                            <td style="width: 20%;">
                                <div
                                style="height: 20px;width: 20px;padding: 0px;margin-left: 5px;margin-top: 3px;">

                                <p-inputSwitch 
                                    [(ngModel)]="role.SELF_SERVICE"
                                    [escape]="false"
                                    styleClass="p-inputswitch-sm"
                                    class="p-mr-2 custom-switch"
                                    disabled="true">
                                </p-inputSwitch>
                            </div>
                            </td>
                            <td>{{ role.CREATED_DATE | dateFormatter }}</td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="4" class="p-p-0 p-text-center"> <p-card>
                                    <div style="margin-top: 5%;height: 70px;">No
                                        matching records found</div>
                                </p-card> </td>
                        </tr>
                    </ng-template>
                    <!-- Pagination code-->
                    <div class="p-d-flex">
                        <ng-template pTemplate="paginatorleft" let-pageData>
                            <div class="p-d-flex p-ai-center p-px-2 ember-view">
                                <input pInputText class="paginator-input" type="number"
                                    [min]="pageData.totalRecords == 0 ? 0 : 1"
                                    [max]="pageData.totalRecords == 0 ? 0 : pageData.pageCount"
                                    [disabled]="pageData.totalRecords == 0" [(ngModel)]="currentPage"
                                    (keydown)="onPageInputChange(pageData)" (keyup)="onPageInputChange(pageData)"
                                    (onkeypress)="onPageInputChange(pageData)" (change)="onPageInputChange(pageData)" />
                                <span class="p-ml-1"> of {{ pageData.totalRecords != 0 ? pageData.pageCount : 0 }}
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="paginatorright" let-pageData>
                            <div class="p-d-flex p-ai-center p-px-2">
                                <span class="p-ml-1"> </span>
                            </div>
                        </ng-template>
                    </div>
                </p-table>
            </div>
        </div>


        <p-dialog header="Confirm" [modal]="true" [(visible)]="isConfirmDialog" [style]="{ width: '30vw' }"
            [baseZIndex]="10000">
            <div class="p-grid p-fluid p-0">
                <div class="p-col-12 p-md-12 text-center">
                    <label class="l-icons attention-img"></label>

                    <label class="customlabel mb-0">{{ actionMessage }}</label>
                    <p>{{ confirmMessage }}</p>
                </div>
            </div>

            <p-footer>
                <span class="btn-raised-label">
                    <button pButton type="button" label="{{ activateLabel }}" class="ui-button-raised"
                        (click)="onActionConfirmationClick()"></button>
                </span>
                <button pButton type="button" label="Cancel" (click)="cancelConfimDialog()"
                    class="ui-button-raised ui-button-danger btnCancel"></button>
            </p-footer>
        </p-dialog>

        <p-sidebar [(visible)]="isActivityLog" [baseZIndex]="10000" [showCloseIcon]="false" position="right"
            [style]="{ width: '500px', overflowY: 'scroll', overflowX: 'hidden' }">
            <br />

            <div class="row mb-1">
                <div class="col-md-12">
                    <div class="pb-3">
                        <label class="l-icons info-img"></label>
                        <span class="infoTitle">{{ activityRoleName }}</span>
                        <span class="pl-2 infoTitlelog">{{ activityRoleId }} | {{ activityRoleStatus }}</span>
                    </div>
                    <div class="table-header activityTitle">
                        Activity Log
                    </div>

                    <p-table [value]="activityLogList" scrollable="true" styleClass="tableContent">
                        <ng-template pTemplate="body" let-resultset>
                            <tr>
                                <td [style.width]="'75%'"
                                    style=" border-left: none !important; border-right-color: transparent !important; border-top: 1px solid gray !important">
                                    {{ resultset.CREATED_DATE | dateFormatter }}<br /><b><span>{{ resultset.USER_NAME }}
                                            {{
                                            resultset.DESCRIPTION }} R{{ resultset.ROLE_ID }}</span></b>
                                </td>
                                &nbsp;
                                <td [style.width]="'25%'" style="border-right-color: transparent !important;
                   border-left: none !important;border-top: 1px solid gray !important">
                                    <span [ngClass]="{
                                    activeTest1: resultset.ROLE_STATUS === 'Active',
                                    inactiveTest1: resultset.ROLE_STATUS === 'Inactive',
                                    lockedTest1: resultset.ROLE_STATUS === 'Locked'
                                }">{{ resultset.ROLE_STATUS }}</span>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>

            <br />
            <div class="p-pl-2 float-right">
                <button type="button" pButton (click)="isActivityLog = false" label="Close"
                    class="p-button-primary btnInfo"></button>
            </div>
        </p-sidebar>

    </div>
</div>