import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PagesService } from '../pages.service';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
@Component({
  selector: 'jhi-deleteattribute',
  templateUrl: './DeleteAttribute.component.html',
  styleUrls: ['./DeleteAttribute.component.scss'],
})
export class DeleteAttributeComponent implements OnInit {
  DeleteAttributeForm = this.fb.group({
  });
  pageloadvalues: any;
  groupId: any;
  appId: any;
  resourceId: any;

  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    private messageService: MessageService,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.DeleteAttributeForm.patchValue(JSON.parse(data));
    }
  }
  ngOnInit(): void {
    this.appId = this.config?.data?.id;
    this.resourceId = this.config?.data?.activeData.id;
  }

  onClickNo($event: UntypedFormGroup): void {
    this.ref.close();
  }

  /**
   * Delete Attribute in attribute tab of attribute&group
   */
  deleteAttributeResource() {
    const data = { appId: this.appId, resourceId: this.resourceId };
    this.pagesService.deleteAttributeResource(data).subscribe({
      next: (results: any) => {

        this.ref.close("Attribute Deleted Successfully");
      },
      error: () => {

        this.ref.close("Failed to Delete Attribute");
      }
    });

  }
}