import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PagesService } from '../pages.service';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { ContextName, EventName } from 'app/shared/constants/logging-event.constants';
@Component({
  selector: 'jhi-SuspendUser',
  templateUrl: './UnlockUser.component.html',
  styleUrls: ['./UnlockUser.component.scss'],
  providers: [MessageService, DialogService],
})
export class UnlockUserComponent implements OnInit {
  UnlockUserForm = this.fb.group({});
  arrayId: any[] = [];
  unLockUserData:any[]=[];
  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    private messageService: MessageService,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.UnlockUserForm.patchValue(JSON.parse(data));
    }
  }
  ngOnInit(): void {
    this.arrayId = this.config?.data?.id;
    this.unLockUserData = this.config?.data?.rowData;
  }

  onClickNo($event: UntypedFormGroup): void {
    this.ref.close();
  }

  onCcprimebutton1($event: UntypedFormGroup): void {
    this.pagesService.unlockUser(this.arrayId).subscribe({
      next: results => {
        this.ref.close("User unlocked successfully");
        this.logEvent(EventName.UNLOCK_USER,ContextName.UNLOCK_USER,this.unLockUserData);
      },
      error: error => {
        this.ref.close("User unlock failed");
      }
    });
  }

  logEvent(eventName,eventContextName,value){
    const data={
      "ContextName":eventContextName,
      "value":value,
      "EventName":eventName,
      "newValue":'UNLOCK USER'
    };
    this.pagesService.logUserEvent(data).subscribe(resp =>{
      //console.log(resp);
      
    },
            error => {
              console.log("Error >> ",ContextName.VIEW_MANAGE_USERS_PAGE,":", error);
            });
  }
}