// In single-spa, the assets need to be loaded from a dynamic location,
// instead of hard coded to `/assets`. We use webpack public path for this.

import { environment } from "src/environments/environment";

export function assetUrl(url: string): string {
  // @ts-ignore
  const urlPrefix = url.startsWith('/') ? '' : '/';

  let path = environment.IMAGE_PATH;

  return `${path}${urlPrefix}${url}`;
}
