<div class="layout-content custom-container">
    <form [formGroup]="EndpointMappingForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 p-left p-mt-2 p-pb-2">
                <span class="p-left" style="font-size: 13px;font-style: italic;"><strong>Note:</strong>
                    Endpoints that have "Application role required" enabled would be fetched for mapping table.</span>
            </div>
            <div class=" p-col-12 p-pl-0 p-pr-0">
                <div class="ui-fluid p-formgrid p-grid">
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                        <div class=" p-fluid" style="font-size: 14px;margin-bottom: 10px;">
                            Application Role Name: <span style="font-weight: 500;">{{this.applicationRoleName}}</span>
                        </div>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class=" p-col-12 p-p-0">
                                <div class="ui-fluid p-formgrid p-grid">
                                    <div class="left p-col-12 p-lg-4 p-md-4 p-offset-undefined p-p-0 p-sm-12 p-xl-4">
                                        <div class=" p-fluid   p-field    ">
                                            <label
                                                [ngClass]="{'field-required': EndpointMappingForm.get('select1')?.errors?.required}">
                                                Component
                                            </label> <span class='invalid-feedback-astrik'>*</span>
                                            <div class="p-fluid">
                                                <p-dropdown [filter]="true" filterBy="label" id="select1id"
                                                    name="select1" placeholder="Select" [options]="select1List"
                                                    [autoDisplayFirst]="false" formControlName="select1"
                                                    (onChange)="getMappedDetails()">
                                                    <ng-template let-items pTemplate="item">
                                                        <div *ngIf="items?.label?.length &lt;= 35;">
                                                            {{items.label}}
                                                        </div>
                                                        <div *ngIf="items?.label?.length > 35;"
                                                            pTooltip="{{items.label}}" tooltipPosition="bottom">
                                                            {{items.label.substring(0,35) + "..." }}
                                                        </div>
                                                    </ng-template>
                                                </p-dropdown>
                                            </div>
                                            <div class="invalid-feedback"
                                                *ngIf="EndpointMappingForm.get('select1')!.invalid && (EndpointMappingForm.get('select1')!.dirty || EndpointMappingForm.get('select1')!.touched)">
                                                <div *ngIf="EndpointMappingForm.get('select1')?.errors?.required">
                                                    <label class="alert-icon alert-circle-icon"></label>
                                                    <medium class="p-error">Component is required.</medium>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="left p-col-12 p-lg-8 p-md-8 p-offset-undefined p-p-0 p-sm-12 p-xl-8">
                                    </div>
                                    <div class="p-col-12 custom-emptyImage-size" *ngIf="flag3">
                                        <label class="d1-icons group1-icon"></label>
                                        <label class="p-d-flex p-jc-center">Please select <span class="p-pl-1"
                                                style="font-weight: 500;">Component</span></label>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left p-pl-0 p-pr-0"
                                        *ngIf="!flag3">
                                        <div class=" p-fluid   p-field    ">
                                            <div class=" p-col-12 p-pl-0 p-pr-0">
                                                <div style="display: flex;width:100%;" [class]="styleClass"
                                                    [ngStyle]="style" class="custom-picklist-with-checkbox"
                                                    [ngClass]="{'p-picklist p-component p-widget p-helper-clearfix': true,'p-picklist-responsive': responsive}">
                                                    <div class="p-col-fixed p-picklist-list-wrapper p-picklist-source-wrapper"
                                                        [ngClass]="{'p-picklist-listwrapper-nocontrols':!showSourceControls}"
                                                        style="padding:0px;">
                                                        <div class="p-picklist-header">
                                                            <div class="p-picklist-title">Unmapped Endpoints
                                                                <span
                                                                    class="p-picklist-count">{{pickList1List.length}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="p-picklist-filter-container"
                                                            *ngIf="filterBy && showSourceFilter !== false">
                                                            <div class="p-picklist-filter">
                                                                <span class="p-input-icon-left" style="margin: 0px;">
                                                                    <input #sourceFilter type="text" role="textbox"
                                                                        (keydown.enter)="$event.preventDefault()"
                                                                        class="p-picklist-filter-input p-inputtext inputtextHeight p-component"
                                                                        placeholder="Search"
                                                                        (input)="filterMethodPick($event.target.value,pickList1List1,'pick1source')"
                                                                        [disabled]="disabled"
                                                                        formControlName="sourceFilter">
                                                                    <i aria-hidden="true" class="pi pi-search"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <ul #sourcelist
                                                            class="cdk-drop-list p-picklist-list p-picklist-source"
                                                            [ngClass]="{'p-picklist-highlight': listHighlightSource}"
                                                            [ngStyle]="sourceStyle" style="height: 165px !important;">
                                                            <ng-template ngFor let-item [ngForOf]="pickList1List"
                                                                [ngForTrackBy]="sourceTrackBy || trackBy" let-i="index"
                                                                let-l="last">
                                                                <li [ngClass]="{'cdk-drag p-picklist-item p-ripple':true,'p-state-highlight':isSelected(item,selectedItemsSource), 'p-state-disabled': disabled}"
                                                                    [style.display]="isItemVisible(item, SOURCE_LIST) ? 'block' : 'none'"
                                                                    style="margin-right: 4px;">
                                                                    <div class="product-item"
                                                                        style="padding-top: 0rem;padding-bottom: 0rem;height: 25px;">
                                                                        <span class="list-check image-container p-mr-2"
                                                                            style="margin-top: -3px;">
                                                                            <p-checkbox name="groupname"
                                                                                [value]="isChecked" #picklistCheckboxes
                                                                                [(ngModel)]="item.isChecked"
                                                                                binary="true"
                                                                                (ngModelChange)="onItemClick($event,item,selectedItemsSource,onSourceSelect)"
                                                                                [ngModelOptions]="{standalone: true}">
                                                                            </p-checkbox>
                                                                        </span>
                                                                        <div class="product-list-detail p-d-flex">
                                                                            <div class="p-col p-pl-0 pClass"
                                                                                pTooltip="{{item.label}}"
                                                                                *ngIf="item?.label?.length >= 32"
                                                                                tooltipPosition="bottom">
                                                                                {{item?.label?.substring(0,32) +
                                                                                "..."}}</div>
                                                                            <div class="p-col p-pl-0 pClass"
                                                                                *ngIf="item?.label?.length < 32">
                                                                                {{item?.label}}</div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li class="p-picklist-droppoint" *ngIf="dragdrop&&l"
                                                                    (dragover)="onDragOver($event, i + 1, SOURCE_LIST)"
                                                                    (drop)="onDrop($event, i + 1, SOURCE_LIST)"
                                                                    (dragleave)="onDragLeave($event, SOURCE_LIST)"
                                                                    [ngClass]="{'p-picklist-droppoint-highlight': (i + 1 === dragOverItemIndexSource)}">
                                                                </li>
                                                            </ng-template>
                                                        </ul>
                                                    </div>
                                                    <div
                                                        class="p-col-fixed p-picklist-buttons p-picklist-transfer-buttons">
                                                        <button type="button" pButton pRipple icon="pi pi-angle-right"
                                                            [disabled]="disabled"
                                                            class="p-ripple p-button p-component p-button-icon-only"
                                                            (click)="moveRight()" [disabled]="this.pagesService.applicationOnboardingReadOnlyFlag">

                                                            <span class="p-ink"></span>
                                                        </button>
                                                        <button type="button" pButton icon="pi pi-angle-double-right"
                                                            [disabled]="disabled"
                                                            class="p-ripple p-button p-component p-button-icon-only"
                                                            (click)="moveAllRight()" [disabled]="this.pagesService.applicationOnboardingReadOnlyFlag">

                                                            <span class="p-ink"></span>
                                                        </button>
                                                        <button type="button" pButton icon="pi pi-angle-left"
                                                            [disabled]="disabled"
                                                            class="p-ripple p-button p-component p-button-icon-only"
                                                            (click)="moveLeft()" [disabled]="this.pagesService.applicationOnboardingReadOnlyFlag">

                                                            <span class="p-ink"></span>
                                                        </button>
                                                        <button type="button" pButton icon="pi pi-angle-double-left"
                                                            [disabled]="disabled"
                                                            class="p-ripple p-button p-component p-button-icon-only"
                                                            (click)="moveAllLeft()"  [disabled]="this.pagesService.applicationOnboardingReadOnlyFlag">

                                                            <span class="p-ink"></span>
                                                        </button>
                                                    </div>
                                                    <div class="p-col-fixed p-picklist-list-wrapper p-picklist-target-wrapper"
                                                        [ngClass]="{'p-picklist-listwrapper-nocontrols':!showTargetControls}">
                                                        <div class="p-picklist-header">
                                                            <div class="p-picklist-title">Mapped Endpoints
                                                                <span
                                                                    class="p-picklist-count">{{pickList1targetList.length}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="p-picklist-filter-container"
                                                            *ngIf="filterBy && showSourceFilter !== false">
                                                            <div class="p-picklist-filter">
                                                                <span class="p-input-icon-left" style="margin: 0px;">
                                                                    <input #sourceFilter type="text" role="textbox"
                                                                        (keydown.enter)="$event.preventDefault()"
                                                                        class="p-picklist-filter-input p-inputtext inputtextHeight p-component"
                                                                        placeholder="Search"
                                                                        (input)="filterMethodPick($event.target.value,pickList1targetList1,'pick1target')"
                                                                        [disabled]="disabled"
                                                                        formControlName="sourceFilter">
                                                                    <i aria-hidden="true" class="pi pi-search"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <ul #targetlist cdkDropList
                                                            class="cdk-drop-list p-picklist-list p-picklist-target"
                                                            [ngClass]="{'p-picklist-highlight': listHighlightTarget}"
                                                            [ngStyle]="targetStyle" style="height: 165px !important;">
                                                            <ng-template ngFor let-item [ngForOf]="pickList1targetList"
                                                                [ngForTrackBy]="targetTrackBy || trackBy" let-i="index"
                                                                let-l="last">
                                                                <li pRipple cdkDrag role="option"
                                                                    [ngClass]="{'cdk-drag p-picklist-item p-ripple':true,'p-state-highlight':isSelected(item,selectedItemsTarget), 'p-state-disabled': disabled}"
                                                                    [style.display]="isItemVisible(item, TARGET_LIST) ? 'flex' : 'none'"
                                                                    style="margin-right: 4px;">
                                                                    <div class="product-item"
                                                                        style="padding-top: 0rem;padding-bottom: 0rem;height: 25px;">
                                                                        <span class="list-check image-container p-mr-2"
                                                                            style="margin-top: -3px;">
                                                                            <p-checkbox name="groupname"
                                                                                [value]="isChecked" #picklistCheckboxes
                                                                                [(ngModel)]="item.isChecked"
                                                                                binary="true"
                                                                                (ngModelChange)="onItemClick($event,item,selectedItemsTarget,onTargetSelect)"
                                                                                [ngModelOptions]="{standalone: true}">
                                                                            </p-checkbox>
                                                                        </span>
                                                                        <div class="product-list-detail">
                                                                            <div class="p-col p-pl-0 pClass"
                                                                                pTooltip="{{item.label}}"
                                                                                *ngIf="item?.label?.length >= 32"
                                                                                tooltipPosition="bottom">
                                                                                {{item?.label?.substring(0,32) +
                                                                                "..."}}</div>
                                                                            <div class="p-col p-pl-0 pClass"
                                                                                *ngIf="item?.label?.length < 32">
                                                                                {{item?.label}}</div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li class="p-picklist-droppoint" *ngIf="dragdrop&&l"
                                                                    (dragover)="onDragOver($event, i + 1, TARGET_LIST)"
                                                                    (drop)="onDrop($event, i + 1, TARGET_LIST)"
                                                                    (dragleave)="onDragLeave($event, TARGET_LIST)"
                                                                    [ngClass]="{'p-picklist-droppoint-highlight': (i + 1 === dragOverItemIndexTarget)}">
                                                                </li>
                                                            </ng-template>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right p-pl-0 p-pr-0 p-mb-2"
                                        *ngIf="!flag3">
                                        <div class="btnwrap">
                                            <button pButton type="button" class="p-button-brand2 p-button p-mr-2"
                                                (click)="onClose(EndpointMappingForm);" label="Close">
                                            </button>
                                        </div>
                                        <div class="btnwrap">
                                            <button pButton type="button" label="Secondary"
                                                class="p-button-primary p-button" [disabled]="btn" (click)="onSave()"
                                                label="Save" [disabled]="this.pagesService.applicationOnboardingReadOnlyFlag"></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>