<div class="layout-content custom-container">
    <form [formGroup]="RevokeDeletedUserForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                <div class="p-grid">
                    <span class="p-col p-p-0">
                        <p style="color:#505870">
                            <em><span style="font-weight: 500;">Note:</span> Below are the deleted users. On revoke,
                                user will become active with no previous assignment of role, group & attribute.</em>
                        </p>
                    </span>
                </div>
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left p-pl-0">
                    <div class=" p-fluid   p-field   p-mb-0 ">
                        <div class="ui-fluid p-formgrid">
                            <div class=" p-grid p-mt-0" *ngIf="errorFlag==false">
                                <div class="p-col-fixed p-p-0 p-col-4">
                                    <span class=" p-input-icon-left">
                                        <input type="text" (keydown.enter)="$event.preventDefault()" id="textField_id"
                                            name="textField_name" formControlName="textField" pInputText
                                            placeholder="Search"
                                            class="p-inputtext inputtextHeight custom-listings custom-inputtextHeight"
                                            (input)="filterMethod($event.target.value)" />
                                        <i aria-hidden="true" class="pi pi-search"></i>
                                    </span>
                                </div>
                                <div class="p-col p-p-0 p-text-right custom-btn">
                                    <span class="btnwrap">
                                        <div pTooltip="{{actionDisable ? 'Please select at least one record' : ''}}"
                                            tooltipPosition="left">
                                            <button pButton class="p-button-primary p-button-sm" type="button"
                                                (click)="revokeUsers(RolesForm);" label="Revoke User"
                                                [disabled]="actionDisable || this.pagesService.userManagementReadOnlyFlag">
                                            </button>
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left p-p-0">
                                <div>
                                    <div class="mobilefriendlytable" responsiveLayout="stack"
                                        [ngClass]="{'content-section implementation': true}">
                                        <p-table #tableid [value]="tableList" name="table" [columns]="tablecols"
                                            styleClass="p-datatable-customers custom-p-datatable-customers" [rows]="10"
                                            [rowsPerPageOptions]="[10,25,50]" selectionMode="Multiple"
                                            [paginator]="true" sortMode="single" [scrollable]="true"
                                            [(selection)]="selectedRecords"
                                            [globalFilterFields]="['firstName','middleName','lastName','lastmodifiedBy','lastModifiedDate','userName']"
                                            (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
                                            (onHeaderCheckboxToggle)="onSelectAllUsers();">
                                            <ng-template pTemplate="header">
                                                <tr>
                                                    <th id="headercheck" class="headerCheck" style="max-width: 4%;">
                                                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                                    </th>
                                                    <th pSortableColumn="firstName" scope="col" style="max-width: 14%;">
                                                        <div class="p-d-flex p-jc-between p-ai-center">
                                                            First Name
                                                            <i class="p-sortable-column-icon icon-sort"
                                                                ariaLabel="Activate to sort"
                                                                ariaLabelDesc="Activate to sort in descending order"
                                                                ariaLabelAsc="Activate to sort in ascending order"
                                                                field="firstName"></i>
                                                            <p-columnFilter type="text" field="firstName" display="menu"
                                                                class="p-ml-auto"></p-columnFilter>
                                                        </div>
                                                    </th>
                                                    <th pSortableColumn="middleName" scope="col"
                                                        style="max-width: 16%;">
                                                        <div class="p-d-flex p-jc-between p-ai-center">
                                                            Middle Name
                                                            <i class="p-sortable-column-icon icon-sort"
                                                                ariaLabel="Activate to sort"
                                                                ariaLabelDesc="Activate to sort in descending order"
                                                                ariaLabelAsc="Activate to sort in ascending order"
                                                                field="middleName"></i>
                                                            <p-columnFilter type="text" field="middleName"
                                                                display="menu" class="p-ml-auto"></p-columnFilter>
                                                        </div>
                                                    </th>
                                                    <th pSortableColumn="lastName" scope="col" style="max-width: 14%;">
                                                        <div class="p-d-flex p-jc-between p-ai-center">
                                                            Last Name
                                                            <i class="p-sortable-column-icon icon-sort"
                                                                ariaLabel="Activate to sort"
                                                                ariaLabelDesc="Activate to sort in descending order"
                                                                ariaLabelAsc="Activate to sort in ascending order"
                                                                field="lastName"></i>
                                                            <p-columnFilter field="lastName" display="menu"
                                                                class="p-ml-auto"></p-columnFilter>
                                                        </div>
                                                    </th>
                                                    <th pSortableColumn="email" scope="col" style="max-width: 16%;">
                                                        <div class="p-d-flex p-jc-between p-ai-center">
                                                            Email Address
                                                            <i class="p-sortable-column-icon icon-sort"
                                                                ariaLabel="Activate to sort"
                                                                ariaLabelDesc="Activate to sort in descending order"
                                                                ariaLabelAsc="Activate to sort in ascending order"
                                                                field="email"></i>
                                                            <p-columnFilter type="text" field="email" display="menu"
                                                                class="p-ml-auto"></p-columnFilter>
                                                        </div>
                                                    </th>
                                                    <th pSortableColumn="userName" scope="col" style="max-width: 16%;">
                                                        <div class="p-d-flex p-jc-between p-ai-center">
                                                            Username
                                                            <i class="p-sortable-column-icon icon-sort"
                                                                ariaLabel="Activate to sort"
                                                                ariaLabelDesc="Activate to sort in descending order"
                                                                ariaLabelAsc="Activate to sort in ascending order"
                                                                field="userName"></i>
                                                            <p-columnFilter type="text" field="userName" display="menu"
                                                                class="p-ml-auto"></p-columnFilter>
                                                        </div>
                                                    </th>
                                                    <th pSortableColumn="lastmodifiedBy" scope="col"
                                                        style="max-width: 15%;">
                                                        <div class="p-d-flex p-jc-between p-ai-center">
                                                            Deleted By
                                                            <i class="p-sortable-column-icon icon-sort"
                                                                ariaLabel="Activate to sort"
                                                                ariaLabelDesc="Activate to sort in descending order"
                                                                ariaLabelAsc="Activate to sort in ascending order"
                                                                field="lastmodifiedBy"></i>
                                                            <p-columnFilter field="lastmodifiedBy" display="menu"
                                                                class="p-ml-auto"></p-columnFilter>
                                                        </div>
                                                    </th>
                                                    <th pSortableColumn="lastModifiedDate" scope="col">
                                                        <div class="p-d-flex p-jc-between p-ai-center">
                                                            Deleted On
                                                            <i class="p-sortable-column-icon icon-sort"
                                                                ariaLabel="Activate to sort"
                                                                ariaLabelDesc="Activate to sort in descending order"
                                                                ariaLabelAsc="Activate to sort in ascending order"
                                                                field="lastModifiedDate"></i>
                                                            <p-columnFilter field="lastModifiedDate" display="menu"
                                                                class="p-ml-auto"></p-columnFilter>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-rowData let-index="rowIndex">
                                                <tr [pSelectableRow]="rowData">
                                                    <td class="rowCheck" style="max-width: 4%;"><p-tableCheckbox
                                                            [value]="rowData"></p-tableCheckbox></td>
                                                    <td style="max-width: 14%;" class="middleNameRow">
                                                        <span *ngIf="rowData.firstName!=null">
                                                            <span pTooltip="{{rowData.firstName}}"
                                                                tooltipPosition="bottom"
                                                                *ngIf="rowData.firstName?.length>=10">{{rowData.firstName?.substring(0,10)
                                                                +
                                                                "..." }}</span>
                                                            <span
                                                                *ngIf="rowData.firstName?.length<10">{{rowData.firstName}}</span>
                                                        </span>
                                                        <span
                                                            *ngIf="rowData.firstName==null || rowData.firstName=='' || rowData.firstName==' '">-</span>
                                                    </td>
                                                    <td class="middleNameRow" style="max-width: 16%;">
                                                        <span *ngIf="rowData.middleName!=null">
                                                            <span pTooltip="{{rowData.middleName}}"
                                                                tooltipPosition="bottom"
                                                                *ngIf="rowData.middleName?.length>=10">{{rowData.middleName?.substring(0,10)
                                                                +
                                                                "..." }}</span>
                                                            <span
                                                                *ngIf="rowData.middleName?.length<10">{{rowData.middleName}}</span>
                                                        </span>
                                                        <span
                                                            *ngIf="rowData.middleName==null || rowData.middleName=='' || rowData.middleName==' '">-</span>
                                                    </td>
                                                    <td style="max-width: 14%;">
                                                        <span *ngIf="rowData.lastName!=null">
                                                            <span pTooltip="{{rowData.lastName}}"
                                                                tooltipPosition="bottom"
                                                                *ngIf="rowData.lastName?.length>=10">{{rowData.lastName?.substring(0,10)
                                                                +
                                                                "..." }}</span>
                                                            <span
                                                                *ngIf="rowData.lastName?.length<10">{{rowData.lastName}}</span>
                                                        </span>

                                                        <span
                                                            *ngIf="rowData.lastName==null || rowData.lastName=='' || rowData.lastName==' '">-</span>
                                                    </td>
                                                    <td class="emailRow" style="max-width: 16%;">
                                                        <span pTooltip="xxx@{{rowData.email?.split('@')[1]}}"
                                                            tooltipPosition="bottom"
                                                            *ngIf="rowData.email?.split('@')[1]?.length>=10">xxx&#64;{{rowData.email?.split('@')[1]?.substring(0,10)
                                                            +
                                                            "..." }}</span>
                                                        <span
                                                            *ngIf="rowData.email?.split('@')[1]?.length<10">xxx&#64;{{rowData.email?.split('@')[1]}}</span>

                                                    </td>
                                                    <td style="max-width: 16%;">
                                                        <span pTooltip="{{rowData.userName}}" tooltipPosition="bottom"
                                                            *ngIf="rowData.userName?.length>=10">{{rowData.userName?.substring(0,10)
                                                            +
                                                            "..." }}</span>
                                                        <span
                                                            *ngIf="rowData.userName?.length<10">{{rowData.userName}}</span>
                                                    </td>
                                                    <td style="max-width: 15%;">
                                                        <span
                                                            *ngIf="rowData.lastmodifiedBy==null || rowData.lastmodifiedBy=='' || rowData.lastmodifiedBy==' '">-</span>
                                                        <span *ngIf="rowData.lastmodifiedBy!=null">
                                                            <span pTooltip="{{rowData.lastmodifiedBy}}"
                                                                tooltipPosition="bottom"
                                                                *ngIf="rowData.lastmodifiedBy?.length>=10">{{rowData.lastmodifiedBy?.substring(0,10)
                                                                +
                                                                "..." }}</span>
                                                            <span
                                                                *ngIf="rowData.lastmodifiedBy?.length<10">{{rowData.lastmodifiedBy}}</span>
                                                        </span>

                                                    </td>
                                                    <td>
                                                        <span
                                                            *ngIf="rowData.lastModifiedDate==null || rowData.lastModifiedDate=='' || rowData.lastModifiedDate==' '">-</span>
                                                        <span *ngIf="rowData.lastModifiedDate!=null"></span>
                                                        <span pTooltip="{{rowData.lastModifiedDate | dateFormatter | date: 'MMM
                                                                        d, y, h:mm:ss a' }}" tooltipPosition="bottom"
                                                            *ngIf="rowData.lastModifiedDate?.length>=12">{{rowData.lastModifiedDate?.substring(0,12)
                                                            +
                                                            "..." }}</span>
                                                        <span
                                                            *ngIf="rowData.lastModifiedDate?.length<12">{{rowData.lastModifiedDate
                                                            | dateFormatter | date: 'MMM
                                                            d, y, h:mm:ss a' }}</span>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="paginatorright" let-state> Total
                                                Records: {{state.totalRecords}} </ng-template>
                                            <ng-template pTemplate="emptymessage">
                                                <tr>
                                                    <td colspan="7" class="p-p-0 p-text-center"> <p-card>
                                                            <div style="margin-top: 5%;height: 70px;">No
                                                                matching records found</div>
                                                        </p-card> </td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>