import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { USER_MANAGEMENT_SERVICE_URL } from "app/app.constants";
import { Observable } from "rxjs";

@Injectable({
    'providedIn': 'root'
})
export class MainComponentService{

    constructor(private http: HttpClient) { }
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',

        })
    };

    getEventData(object): Observable<any> {
        return this.http.post(USER_MANAGEMENT_SERVICE_URL + 'umManageUser/getEventData',object);
    }
    
    logUserLoginData(data): Observable<any> {
      return this.http.post(USER_MANAGEMENT_SERVICE_URL + 'umManageUser/loginEvent',data);
    }

    getUserDetailsForLogging(object): Observable<any> {
        return this.http.post(USER_MANAGEMENT_SERVICE_URL+ 'umManageUser/getUserInfoForLogging',object);
    }
}
