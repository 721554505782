import { Injectable } from "@angular/core";
import { ACCEPT, APPROVE, APPROVED, APPROVE_ALL, CANCEL, REJECT, REJECTED, REJECT_ALL, USER_MANAGEMENT_COMPONENT_ID } from "app/constants";
import { LoggingEventService } from "app/logging-event.service";
import { ContextName, EventName, ItemName } from "app/shared/constants/logging-event.constants";
import { IEventContext, IEventData, IEventSubject } from "app/shared/interface/event.interface";
@Injectable({
    'providedIn':'root'
})
export class ApprovalManagementLoggingService {
    eventTypes: { [name: string]: number } = {};
    contextList: { [name: string]: number } = {};
    eventItems: { [name: string]: number } = {};
    constructor(
        private loggingService:LoggingEventService
    ){
    }

    async prepareUpdateOwnershipStatus(data: any) {
        const eventDataArray = [];
        let status=data.STATUS;
        let contextValue=data.COMPONENT_ID+"-"+data.ENTITLEMENT_ID+" - "+data.ENTITLEMENT_NAME;

        if(status === APPROVED)
        {
            let eventData: IEventData = await this.loggingService.getEventData(EventName.ACCEPT_OWNERSHIP_REQUEST, true, true, USER_MANAGEMENT_COMPONENT_ID, ContextName.ACCEPT_REQUEST, contextValue);
            let eventSubjects:IEventSubject[]=[
                await this.loggingService.getEventSubjectData(ItemName.ACCEPT_REQUEST,ACCEPT)
            ];
            eventData.eventSubjects=eventSubjects;
            eventDataArray.push(JSON.stringify(eventData));
            return JSON.stringify(eventDataArray);
        }
        else if(status === REJECTED)
        {
            let eventData: IEventData = await this.loggingService.getEventData(EventName.REJECT_OWNERSHIP_REQUEST, true, true, USER_MANAGEMENT_COMPONENT_ID, ContextName.REJECT_REQUEST, contextValue);
            let eventSubjects:IEventSubject[]=[
                await this.loggingService.getEventSubjectData(ItemName.REJECT_REQUEST,REJECT)
            ];
            eventData.eventSubjects=eventSubjects;
            eventDataArray.push(JSON.stringify(eventData));
            return JSON.stringify(eventDataArray);
        }
        else 
        {
            let eventData: IEventData = await this.loggingService.getEventData(EventName.CANCEL_OWNERSHIP_REQUEST, true, true, USER_MANAGEMENT_COMPONENT_ID, ContextName.CANCEL_REQUEST, contextValue);
            let eventSubjects:IEventSubject[]=[
                await this.loggingService.getEventSubjectData(ItemName.CANCEL_REQUEST,CANCEL)
            ];
            eventData.eventSubjects=eventSubjects;
            eventDataArray.push(JSON.stringify(eventData));
            return JSON.stringify(eventDataArray);
        }
    }

    async prepareApproveEntitlementData(data: any) {

        const eventDataArray = [];

        let contextValue = data['levelNo'].trim().replaceAll(" ", ",") + " : " + data['compId'] + "-" + data['entId'] + " - " + data['entName'];
        let eventData: IEventData = await this.loggingService.getEventData(EventName.APPROVE_ENTITLEMENT_ACCESS, true, true, USER_MANAGEMENT_COMPONENT_ID, ContextName.APPROVE, contextValue);
        let eventSubjects: IEventSubject[] = [
            await this.loggingService.getEventSubjectData(ItemName.APPROVE, APPROVE)
        ];
        eventData.eventSubjects = eventSubjects;
        eventDataArray.push(JSON.stringify(eventData));

        return JSON.stringify(eventDataArray);

    } 
    async prepareRejectEntitlementData(data: any) {

        const eventDataArray = [];

        let contextValue = data['levelNo'].trim().replaceAll(" ", ",") + " : " + data['compId'] + "-" + data['entId'] + " - " + data['entName'];
        let eventData: IEventData = await this.loggingService.getEventData(EventName.REJECT_ENTITLEMENT_ACCESS, true, true, USER_MANAGEMENT_COMPONENT_ID, ContextName.REJECT, contextValue);
        let eventSubjects: IEventSubject[] = [
            await this.loggingService.getEventSubjectData(ItemName.REJECT, REJECT)
        ];
        eventData.eventSubjects = eventSubjects;
        eventDataArray.push(JSON.stringify(eventData));

        return JSON.stringify(eventDataArray);

    }
    async prepareApproveAllData(userEntMap: any){
        const entitlementList = userEntMap.compEntMap;
        const eventDataArray = [];
        let eventData: IEventData = {
            eventTypeId: await this.loggingService.getEventId(EventName.APPROVE_ALL),
            componentId: USER_MANAGEMENT_COMPONENT_ID,
            isEventSubject: true,
            isEventContext: true,
            eventName:EventName.APPROVE_ALL
        };
        let eventContextListData: IEventContext[] = [];
        let eventSubjects:IEventSubject[]=[]; 
        eventContextListData.push(await this.loggingService.getEventContextData(ContextName.APPROVE_ALL, userEntMap.userId + " - " + userEntMap.userLoginId));  
    
       
        for (const entitlement of entitlementList) {
            const { componentId, entitlementId, entitlementName,levelNo } = entitlement;
            eventContextListData.push(await this.loggingService.getEventContextData(ContextName.APPROVE_ALL,levelNo.trim().replaceAll(" ",",")+": "+ componentId + "-" + entitlementId + " - " + entitlementName));
            eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.APPROVE_ALL, APPROVE_ALL));
        }
        eventData.eventContextList = eventContextListData;
        eventData.eventSubjects=eventSubjects;
        eventDataArray.push(JSON.stringify(eventData));
        return JSON.stringify(eventDataArray);
    }    
    
    async prepareViewApprovalPageData(filter: any){
        const eventDataArray = [];
       let eventData: IEventData = {
           eventTypeId: await this.loggingService.getEventId(EventName.MANAGE_APPROVALS),
           componentId: USER_MANAGEMENT_COMPONENT_ID,
           isEventSubject: false,
           isEventContext: true,
           eventName:EventName.MANAGE_APPROVALS
       };
       let eventContextListData: IEventContext[] = [];
       eventContextListData.push(
           await this.loggingService.getEventContextData(ContextName.VIEW_MANAGE_APPROVALS_PAGE,null),
           await this.loggingService.getEventContextData(ContextName.VIEW_ENTITLEMENT_APPROVALS_PAGE,null));
       eventData.eventContextList = eventContextListData;
       eventDataArray.push(JSON.stringify(eventData));
       return JSON.stringify(eventDataArray);
   }

    async prepareSearchPaginationEntApprovalPageData(filter: any) {
        const eventDataArray = [];
        if (filter.hasOwnProperty('SEARCH_TEXT') || filter.SEARCH_TEXT != null || filter.SEARCH_TEXT != undefined) {
            let eventData: IEventData = await this.loggingService.getEventData(EventName.SEARCH_ENTITLEMENT_APPROVALS, true, true, 1, ContextName.SEARCH_DATA);
            let eventSubjects: IEventSubject[] = [
                await this.loggingService.getEventSubjectData(ItemName.SEARCH_DATA, filter.SEARCH_TEXT)
            ];
            eventData.eventSubjects = eventSubjects;
            eventDataArray.push(JSON.stringify(eventData));
        }

        if (filter.isEntAprvlPageChanged == 'true' || filter.pageIndex != 1) {
            let eventData: IEventData = {
                eventTypeId: await this.loggingService.getEventId(EventName.PAGINATION_MANAGE_ARROVALS),
                componentId: USER_MANAGEMENT_COMPONENT_ID,
                isEventSubject: false,
                isEventContext: true,
                eventName:EventName.PAGINATION_MANAGE_ARROVALS
            };
            let eventContextListData: IEventContext[] = [];
            eventContextListData.push(
                await this.loggingService.getEventContextData(ContextName.PAGE_NUMBER, filter.pageIndex),
                await this.loggingService.getEventContextData(ContextName.PAGE_SIZE, filter.pageSize));
            eventData.eventContextList = eventContextListData;
            eventDataArray.push(JSON.stringify(eventData));
        }
        return JSON.stringify(eventDataArray);

    }

    async prepareViewEntApprovalData(filter: any) {
        const eventDataArray = [];
        let eventData: IEventData = await this.loggingService.getEventData(EventName.ENTITLEMENT_APPROVALS, false, true, USER_MANAGEMENT_COMPONENT_ID, ContextName.VIEW_ENTITLEMENT_APPROVALS_PAGE);
        eventDataArray.push(JSON.stringify(eventData));
        return JSON.stringify(eventDataArray);
    }

    async prepareViewOwnrTrnsfrData(data: any) {
        const eventDataArray = [];
        let eventData: IEventData = await this.loggingService.getEventData(EventName.OWNERSHIP_APPROVALS, false, true, USER_MANAGEMENT_COMPONENT_ID, ContextName.VIEW_OWNERSHIP_APPROVALS_PAGE);
        eventDataArray.push(JSON.stringify(eventData));
        return JSON.stringify(eventDataArray);

    }

    async prepareSearchOwnrApprovalPageData(filter: any) {
        const eventDataArray = [];
        if (filter.hasOwnProperty('SEARCH_TEXT') || filter.SEARCH_TEXT != null || filter.SEARCH_TEXT != undefined) {
            let eventData: IEventData = await this.loggingService.getEventData(EventName.SEARCH_OWNERSHIP_APPROVALS, true, true, USER_MANAGEMENT_COMPONENT_ID, ContextName.SEARCH_DATA);
            let eventSubjects: IEventSubject[] = [
                await this.loggingService.getEventSubjectData(ItemName.SEARCH_DATA, filter.SEARCH_TEXT)
            ];
            eventData.eventSubjects = eventSubjects;
            eventDataArray.push(JSON.stringify(eventData));
        }
        return JSON.stringify(eventDataArray);
    }  
 
    async prepareRejectAllData(userEntMap: any){
        const entitlementList = userEntMap.compEntMap;
        const eventDataArray = [];
        let eventData: IEventData = {
            eventTypeId: await this.loggingService.getEventId(EventName.REJECT_ALL),
            componentId: USER_MANAGEMENT_COMPONENT_ID,
            isEventSubject: true,
            isEventContext: true,
            eventName:EventName.REJECT_ALL
        };
        let eventContextListData: IEventContext[] = [];
        let eventSubjects:IEventSubject[]=[]; 
        eventContextListData.push(await this.loggingService.getEventContextData(ContextName.REJECT_ALL, userEntMap.userId + " - " + userEntMap.userLoginId));  
    
       
        for (const entitlement of entitlementList) {
            const { componentId, entitlementId, entitlementName, levelNo } = entitlement;
            eventContextListData.push(await this.loggingService.getEventContextData(ContextName.REJECT_ALL,levelNo.trim().replaceAll(" ",",")+": "+ componentId + "-" + entitlementId + " - " + entitlementName));
            eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.REJECT_ALL, REJECT_ALL));
        }
        eventData.eventContextList = eventContextListData;
        eventData.eventSubjects=eventSubjects;
        eventDataArray.push(JSON.stringify(eventData));
        return JSON.stringify(eventDataArray);
    }

    async prepareRevokeEntitlementData(data: any) {

        const eventDataArray = [];
        let eventData: IEventData = {
            eventTypeId: await this.loggingService.getEventId(EventName.REVOKE_ENTITLEMENT_ACCESS),
            componentId: USER_MANAGEMENT_COMPONENT_ID,
            isEventSubject: false,
            isEventContext: true,
            eventName:EventName.REVOKE_ENTITLEMENT_ACCESS
        };
        let eventContextListData: IEventContext[] = [];
            eventContextListData.push(
                await this.loggingService.getEventContextData(ContextName.REVOKE, data['entId'] + " - " + data['entName']),
                await this.loggingService.getEventContextData(ContextName.REVOKE, data['userId'] + " - " + data['loginId']));
            eventData.eventContextList = eventContextListData;
        eventDataArray.push(JSON.stringify(eventData));

        return JSON.stringify(eventDataArray);

    } 

}