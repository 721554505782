import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CIAM_UM_SERVER_API_URL } from 'app/app.constants';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserDetailsService {
    constructor(private http: HttpClient) { }

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    getBankList(): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umUserDetails/getBankServiceLineList', {});
    }

    updateUserDetails(userDetails) {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umUserDetails/updateUserMappings', userDetails);
    }

    getUserEntitlementList(data) {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umUserDetails/getUserEntitlementList', data);
    }

    getUsersList(filter: any): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageUser/getUserList', filter);
    }

    getEntitlementData(data): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageEntitlements/getEntitlement', data);
    }

    getEntitlementStatusList(): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageEntitlements/getEntitlementStatusList', {});
    }

    getComponentList(): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageEntitlements/getComponentList_New', {});
    }

    getDependencyEntitlementData(): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageEntitlements/getDependencyEntitlementData', {});
    }

    getRoleEntitlementData(data: any): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageEntitlements/getEntitlementsForRole', data);
    }

    importUserData(userDetails) {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umUserDetails/importUserData', userDetails);
    }

    checkUserData(userId) {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umUserDetails/checkUserData', userId);
    }
    loadOwnerEntitlementsList(data: any): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageEntitlements/loadOwnerEntitlementsList', data);
    }

    getUserMappedAttributeList(data): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umUserDetails/getUserMappedAttributeList', data);
    }

    assignOrganization(data): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umUserDetails/assignOrganization', data);
    }
    getUserAttributeList(): Observable<any> {
        return this.http.get(CIAM_UM_SERVER_API_URL + 'umUserDetails/getUserAttributeList');
    }

    getOrganizationAttributes(obj): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umUserDetails/getOrganizationAttributes', obj);
    }

    getUserRoleList(data): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umUserDetails/getUserRoleList', data);
    }

    getRoleList(filter: any): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageRole/getRoleList', filter);
    }

    deleteRole(entitlementId: any): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umUserDetails/deleteEntitlement', entitlementId);
    }

    getUserDetails(object): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageUser/getUserDetails', object);
    }

    createUserSendEmail(data) {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umUserDetails/sendEmailToCreatedUser', data);
    }
    getOrganizationList(filter: any): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageOrganization/getOrganizationList', filter);
    }

    searchUsersByEmail(email: any): Observable<any> {
        return this.http.get(CIAM_UM_SERVER_API_URL + 'umManageUser/searchLDAPUsers?emailId=' + email);
    }
}
