<div class="layout-content custom-container">
    <form [formGroup]="ViewWorkflowConfigurationDetailsForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                <div class=" p-fluid   p-field    ">
                    <div class="fontRem p-mb-2"> <span class="viewLabel">CIAM Action: </span> <span
                            class="viewValue">{{this.ciamActionLabel}}</span><br></div>
                    <div class="fontRem p-mb-2"><span class="viewLabel">No. of approvers: </span> <span
                            class="viewValue">
                            {{this.noOfApproversLabel}}</span><br></div>
                    <div class="fontRem p-mb-2"><span class="viewLabel">Approval Type: </span> <span class="viewValue">
                            {{this.approvalType}}</span><br></div>
                    <div class="fontRem p-mb-4"><span class="viewLabel">No. of days for Auto-Reject Transaction: </span>
                        <span class="viewValue">
                            {{this.autoRejectDaysModel}}</span><br>
                    </div>
                    <div class="p-mb-3" style="font-size: 1.2rem;">
                        Approvals Role
                    </div>
                    <div *ngFor="let appstage of approvalStage;">
                        <div class="fontRem p-mb-2"><span class="viewLabel">Role {{appstage.approvalSeq}}: </span> <span
                                class="viewValue">
                                {{appstage.approvalRole}}</span></div>
                       
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right">
            </div>
        </div>
    </form>
</div>