import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import {
    ACTIVE,
    ALLOW,
    BANK,
    COMPONENT,
    DEFAULT_ONE,
    DENY,
    ENTITLEMENT,
    IMPORT,
    ONE,
    USER,
    INACTIVE
} from 'app/constants';
import { LoggingEventService } from 'app/logging-event.service';
import { MessageService } from 'primeng/api';
import { assetUrl } from 'src/single-spa/asset-url';
import { RoleDetailsService } from './ManageRolesDetails.service';
import { EntitlementDetailsService } from '../EntitlementDetails/EntitlementsDetails.service';
import { ManageEntitlementsService } from '../ManageEntitlements/ManageEntitlements.service';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'ManageRolesDetails',
    templateUrl: './ManageRolesDetails.component.html',
    styleUrls: ['./ManageRolesDetails.component.scss'],
    providers: [MessageService, DatePipe]
})
export class RoleDetailsComponent implements OnInit {
    ccprimebreadcrumbList = [{ label: `Authorization` }, { label: `Manage Roles` }, { label: `Role details` }];
    homeiconccprimebreadcrumb = { icon: 'pi pi-home', routerLink: ['/'] };
    disableButtons: any;
    isSuccess = false;
    isFailure = false;
    successMessage: string = '';
    failureMessage: string = '';
    role: any;
    bankColumns = [];
    userColumns = [];
    entitlementColumns = [];
    bankDropdownList: any = [];
    serviceLineDropdownList: any;
    selectedServiceFilter: any = [];
    selectedBankFilter: any = [];
    appliedServiceFilter: any = [];
    appliedBankFilter: any = [];
    appliedUserFilter: any = [];
    appliedUserBankFilter: any = [];
    appliedUserServiceFilter: any = [];
    updateBankId: any;
    isBankServiceLineExist: boolean = false;
    isBankFilterSelection: boolean = false;
    isAddNewEntitlement: boolean = false;
    selectedComponent: any;
    selectedEntitlement: any;
    isEntitlementDropdown: boolean = false;
    changeRoleFlag: boolean = false;
    isSavedAndAppliedSuccess = false;
    InactiveStatus = INACTIVE;



    activeTab: any = {
        isBankTab: false,
        isServiceLineTab: false
    };

    activeGlobalTab: any = {
        isGlobalBankTab: false,
        isGlobalServiceLineTab: false,
        isGlobalUserTab: false,
        isGlobalComponentTab: false,
        isGlobalEntitlementTab: false
    };
    selectedUser: any;
    isAddNewBank: boolean = false;
    selectedBank: any;
    selectedServiceLine: any;
    isServiceDropdown: boolean = false;

    bankList: any = [];
    originalBankList: any = [];
    isConfirmDialog: boolean = false;
    isImportConfirmDialog: boolean = false;
    actionTable: any;
    bankFilterSidebarCount: number = 0;
    bankFilterDropdown: any = [];
    serviceFilterDropdown: any = [];
    isServiceLineEmptyForBank: boolean = false;
    confirmMessage: any;
    entitlementList: any = [];
    selectedRoleToImport: any;
    importRoleList: any = [];
    roleList: any = [];
    changeRoleDropdown: any = [];

    isGlobalFilterSelection: boolean = false;
    appliedGlobleBankFilter: any = [];
    appliedGlobleServiceFilter: any = [];
    appliedGlobleUserNameFilter: any = [];
    appliedGlobleComponentNameFilter: any = [];
    appliedGlobleEntilementNameFilter: any = [];
    selectedGlobaleBankFilter: any = [];
    selectedGlobalServiceFilter: any = [];
    selectedGlobalUserFilter: any = [];
    selectedGlobalComponentFilter: any = [];
    selectedGlobalEntitlementFilter: any = [];
    bankServiceFilterDropdown: any = [];
    bankGlobalFilterDropdown: any = [];
    globalFilterSidebarCount: number = 0;
    userFilterDropdown: any = [];
    componentFilterDropdown: any = [];
    entitlementFilterDropdown: any = [];
    originalUserBankServiceLine: any = [];
    originalEntitlementList: any = [];

    isAddNewUser: boolean = false;
    selectedServiceLineForUser: any;
    isServiceDropdownForUser: boolean = false;
    userList = [];
    addUserDropdown: any = [];
    bankListForUserTemp = [];
    bankListForUser = [];
    selectedBankForUser: any;
    usersMap: any;
    userNameList: any = [];
    intersectingUserServiceRoleRecords: any[] = [];
    isUserBankServiceLineExist: boolean;
    isServiceLineEmptyForUser: boolean = false;
    isUserBankDropdown: boolean;
    userBankList: { bankId: number; bankName: string }[];
    selectedUserBank: any;
    isUserServiceLineDropdown: boolean;
    userServiceLineList: any[] = [];
    selectedUserServiceLine: any[] = [];
    usersBankServiceLineList: any[] = [];
    isUserServiceDropDown: boolean;
    isUserFilterSelection: boolean;
    splittedRoleBankServiceLineList: any = [];
    roleFilterSidebarCount: number;
    userFilterSidebarCount: number = 0;
    selectedUserFilter: any = [];
    selectedUserServiceFilter: any = [];
    selectedUserBankFilter: any = [];
    userServiceFilterDropdown: any = [];
    userBankFilterDropdown: any = [];
    splittedUserBankServiceLineList: any[] = [];
    selectedRowUserId: any;
    selectedRowBankId: any;
    selectedRowUserName: any;

    onUserServiceLineHover: string[] = [];
    onUserBankHover: string[] = [];
    intersectingBankServiceRoleRecords: any[] = [];
    entitlementStatusResponse: any = [];
    entitlementStatusDropdownList: any = [];
    allowStatusId: any;
    componentDropdownList: any[] = [];
    entitlementDropdownList: any[] = [];
    isEntitlementExist: boolean = false;
    overrideList: any = [];
    userFilterTab: any = {
        isUserTab: false,
        isBankTab: false,
        isServiceLineTab: false
    };
    bankServiceLineList: any[] = [];
    dependencyEntitlementList: any[] = [];
    totalDependentCount: number = 0;
    dependentColumns = [];
    dependentList: any[] = [];
    isDependentDialog: boolean = false;
    dependentComponentName: string;
    selectedComponentFilter: any = [];
    selectedEntitlementFilter: any = [];
    appliedComponentFilter: any = [];
    appliedEntitlementFilter: any = [];
    EntitlmentFilterSidebarCount: number = 0;
    isEntitlementFilterSelection: boolean = false;
    activeEntitlementTab: any = {
        isComponentTab: false,
        isEntitlementTab: false,
        isEntitlementStatusTab: false
    };
    entitlementStatusFilterDropdown: any = [];
    selectedEntitlementStatusFilter: any = [];
    appliedEntitlementStatusFilter: any = [];

    selectedStatus: any;
    componentId: any;
    entitlementStatusList: any = [];
    allowStatus: any;
    statusDropdownList: any[] = [];

    onTabChange: boolean = false;
    lastUpdatedTime: string;
    unSavedChanges: boolean = false;
    nextRouteInfo: any;
    navigationMessage: string;
    usersList: any = [];
    isSaveAndApplyBtnDisabled: boolean = true;
    currentRole: any;
    selectedEntitlementStatus: any;
    roleMap = new Map();
    isRoleChanged: boolean = false;
    currentUser: any;
    previousUser: any;
    isEntitlementDisabled: boolean;
    previousRole: any;
    selectedRole: any;
    roleComponentId: any;
    roleEntitlementId: any;
    isDeleteRoleEntitlementDialog: boolean = false;
    actionMessage: any;
    entitlementRoleId: any;
    roleEntId: any;
    currentRowIndex: number;
    deletedRoleEntitlement: any = [];
    roleEntitlementName: any;
    roleEntitlementUserCount: any;
    totalEntitlementUserCount: any;
    roleBaseEntitlementUserCount: any;
    entitlementRoleName: any;
    roleEntName: any;
    roleId: any;
    isUserDropdown: boolean = false;
    isUserExist: boolean;
    originalUserList: any = [];
    isUserChanged: boolean;
    user: any;
    userMap: any;
    roleUserList: any = [];
    existinguserIds: any;
    selectedRoleUserData: any;
    isDeleteRoleUserDialog: boolean = false;
    roleUserName: any;
    userRoleName: any;
    userRoleId: any;
    roleUserId: any;
    deletedRoleUser: any = [];
    currentRoleUserRowIndex: number;
    entitlementCountWithRole: number = 0;
    totalEntitlementCount: number = 0;
    totalRoleUserEntitlementCount: any;
    roleUserEntitlementCount: any;
    roleStatusList: any = [];
    isFilterSelection: boolean = false;
    userStatusData: any = [];
    userStatusList: any;
    userActiveTab: any = {
        isUserStatusTab: false
    };
    selectedUserStatus: any = '';
    showUserFilter: boolean = false;
    appliedStatusFilter: any = [];
    activeUserTab: any = {
        isStatusTab: false
    };
    userEntitlementColumns = [];
    userEntitlementList: any[] = [];
    selectedUserEntitlement: any = [];
    selectedUserEntitlementId: any[] = [];
    originalSelectedUserEntitlement: any[] = [];
    isRestButtonDisabled: boolean = false;

    entitlementUserList: any[] = [];
    selectedRecords: any[] = [];
    selectedUserEntitlementIdList: any[] = [];
    retainedUserEntitlementIdList: any[] = [];
    pageVisitingTime: Date;
    isDBChanges: boolean = false;
    userDeleteEntList = [];
    deletedUser: any;
    userImpactEntList = [];
    deletedEnt: any;
    roleDeletedUsersList: any = [];
    entImpactUsersList = [];
    messageFlag = true;
    selectedApp: any;
    transform(value: string): string {
        return assetUrl(value);
    }

    assetUrl = assetUrl;
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private roleDetailsService: RoleDetailsService,
        private messageService: MessageService,
        private loggingService: LoggingEventService,
        private entitlementDetailsService: EntitlementDetailsService,
        private entitlementService: ManageEntitlementsService,
        public datepipe: DatePipe
    ) {
        if (this.router.getCurrentNavigation().extras.state) {
            this.role = this.router.getCurrentNavigation().extras.state.role;
            this.selectedApp = this.router.getCurrentNavigation().extras.state.appDetails;
        }
        this.pageVisitingTime = new Date();
    }


    imageUrl(url) {
        return assetUrl(url);
    }

    ngOnInit(): void {
        this.userColumns = [
            { field: 'userName', header: 'User Id', filterMatchMode: 'contains', display: true, width: '10%' },
            { field: 'bankName', header: 'User Name', filterMatchMode: 'contains', display: true, width: '30%' },
            { field: 'name', header: 'Updated On', filterMatchMode: 'contains', display: true, width: '30%' },
            { field: 'ACTION', header: 'Status', filterMatchMode: 'contains', display: false, width: '30%' }
        ];

        this.dependentColumns = [
            { field: 'COMPONENT_NAME', header: 'COMPONENT NAME', filterMatchMode: 'contains', display: true, width: '50%' },
            { field: 'ENTITLEMENT', header: 'ENTITLEMENT NAME', filterMatchMode: 'contains', display: true, width: '50%' }
        ];

        this.userEntitlementColumns = [
            { field: '', header: 'Remove', filterMatchMode: 'contains', display: true, width: '30px' },
            { field: 'ENTITLEMENT_ID', header: 'Entitlement Id', filterMatchMode: 'contains', display: true, width: '199px' },
            { field: 'ENTITLEMENT_NAME', header: 'Entitlement Name', filterMatchMode: 'contains', display: true, width: '183px' },
            { field: 'COMPONENT_NAME', header: 'Component Name', filterMatchMode: 'contains', display: true, width: '196px' }
        ];
        this.refreshRoleDetails(null);
    }

    navigateFirstName(rowData: any): void {
        const navigationExtras: NavigationExtras = {
            state: {
                routeFlag: true,
                data: rowData,
            },
        };
        this.router.navigate(['/pages/viewuser'], navigationExtras);
    }

    addUsersToRoleTable() {
        this.unSavedChanges = true;
        this.isUserExist = false;
        this.isUserDropdown = true;
        this.existinguserIds = [];
        this.selectedUser.forEach(ele => {
            let obj = {
                USER_ID: ele.ID,
                USER_NAME: ele.NAME,
                UPDATED_ON: new Date(),
                STATUS: ele.USER_STATUS,
                ACTION_TYPE: 1,
                USER_TYPE: ele.USER_TYPE,
                LOGIN_ID: ele.LOGIN_ID
            };
            this.existinguserIds.push(ele.ID);
            this.usersList.unshift(obj);
            this.originalUserList = this.usersList;
        });

        this.addUserDropdown = this.addUserDropdown.filter(user => !this.existinguserIds.includes(user.ID));
        this.isAddNewUser = false;
        this.isSaveAndApplyBtnDisabled = false;
        this.unSavedChanges = true;
    }

    discardUser() {
        this.isAddNewUser = false;
        this.isUserExist = false;
        this.selectedUser = {};
        this.userList = [];
    }

    openUserFilterSideBar() {
        this.isUserFilterSelection = true;
        this.loadUserFilterData(true, true, true, false);
    }

    updateUserToolTip(index) {
        this.unSavedChanges = true;
        this.loadUserFilterData(false, false, true, true);
    }

    loadUserFilterData(isOnlyUser, isOnlyBank, isOnlyServiceLine, isApplyFilter) {
        if (isOnlyUser) this.userFilterDropdown = [];
        this.appliedGlobleUserNameFilter = this.selectedGlobalUserFilter;

        for (let parentIndex = 0; parentIndex < this.originalUserBankServiceLine.length; parentIndex++) {
            if (isOnlyUser) {
                const userParentNode = {
                    label: this.originalUserBankServiceLine[parentIndex].USER_NAME,
                    data: this.originalUserBankServiceLine[parentIndex],
                    children: [],
                    selectable: true,
                    expanded: true,
                    key: this.originalUserBankServiceLine[parentIndex].USER_ID
                };
                if (this.userFilterDropdown.filter(user => user.key == userParentNode.key).length == 0) {
                    this.userFilterDropdown.push(userParentNode);
                }
            }
        }

        if (isOnlyUser) {
            this.selectedUserFilter = [];

            for (let index = 0; index < this.appliedUserFilter.length; index++) {
                if (this.userFilterDropdown.filter(r => r.key == this.appliedUserFilter[index].key).length > 0) {
                    this.selectedUserFilter.push(this.appliedUserFilter[index]);
                }
            }

            this.selectedGlobalUserFilter = [];

            for (let index = 0; index < this.appliedGlobleUserNameFilter.length; index++) {
                if (this.userFilterDropdown.filter(r => r.key == this.appliedGlobleUserNameFilter[index].key).length > 0) {
                    this.selectedGlobalUserFilter.push(this.appliedGlobleUserNameFilter[index]);
                }
            }
        }

        if (isApplyFilter) {
            this.applyUserFilter(false);
            this.applyGlobalFilter(false);
        }
    }

    applyUserFilter(isCloseSidebar): void {
        this.userFilterSidebarCount++;

        this.appliedUserFilter = this.selectedUserStatus;
        this.isUserFilterSelection = isCloseSidebar;
    }

    setUserActiveTab(activeTabName: any) {
        this.activeUserTab.isStatusTab = false;
        if (activeTabName == USER) {
            this.activeUserTab.isStatusTab = true;
        }
        this.openUserFilterSideBar();
    }

    clearAllUserFilters() {
        this.selectedUserStatus = [];
        this.appliedStatusFilter = [];
        this.selectedUserFilter = [];
        this.userFilterSidebarCount = 0;
        this.userFilterDropdown = [];
        this.appliedUserFilter = [];
        this.userFilterTab = {
            isUserTab: false
        };
        this.usersList = this.roleUserList;
    }

    addUser() {
        this.isAddNewUser = true;
        this.selectedUser = null;
        this.isUserDropdown = false;
        this.selectedUser = [];
    }

    openStatusUpdateModal(section, updateId, user) {
        this.confirmMessage = '';
        this.isConfirmDialog = true;
        this.actionTable = section;
        if (section == BANK) {
            this.confirmMessage = 'Do you want to delete the bank and service line for selected role?';
            this.updateBankId = updateId;
        } else if (section == USER) {
            this.confirmMessage = 'Do you want to delete the role for selected user?';
            this.selectedRowUserId = updateId;
            this.selectedRowBankId = user.BANK_SELECTED.bankId;
            this.selectedRowUserName = user.ROLE_NAME;
        } else if (section == IMPORT) {
            this.confirmMessage =
                'The data for the existing role will be replaced by the new data based on the role selected. Please confirm.';
        }
    }

    openImportModal(section) {
        this.confirmMessage = '';
        this.isImportConfirmDialog = true;
        this.actionTable = section;
        this.confirmMessage = 'The data for the existing role will be replaced by the new data based on the role selected. Please confirm.';
    }

    onActionConfirmationClick() {
        this.isConfirmDialog = false;
        this.isImportConfirmDialog = false;

        if (this.actionTable == USER) {
            this.unSavedChanges = true;
            if (this.userFilterSidebarCount > 0 || this.globalFilterSidebarCount > 0) {
                this.loadUserFilterData(true, true, true, true);
            }
        } else if (this.actionTable == IMPORT) {
            this.importRoleData();
        }
    }

    cancelConfimDialog() {
        this.isConfirmDialog = false;
        this.isImportConfirmDialog = false;
    }

    clearAllBankFilters() {
        this.selectedBankFilter = [];
        this.selectedServiceFilter = [];
        this.appliedBankFilter = [];
        this.appliedServiceFilter = [];
        this.bankFilterSidebarCount = 0;
        this.bankList = this.originalBankList;
        this.bankFilterDropdown = [];
        this.serviceFilterDropdown = [];
        this.activeTab = {
            isBankTab: false,
            isServiceLineTab: false
        };
    }

    navigateToRoleListingScreen() {
        this.router.navigateByUrl('/pages/manageroles', {
            skipLocationChange: false
        });

    }

    onSelectStatus(data) {
        this.unSavedChanges = true;
        this.originalEntitlementList.forEach(element => {
            if (data.ID.toString() == element.ID.toString()) {
                element.OVERALL_STATUS = data.selectedEntitlement.label;
                element.OVERALL_STATUS_ID = data.selectedEntitlement.value;
            }
        });
    }

    async updateRoleDetails(isUpdateFlag) {
        this.isAddNewEntitlement = false;
        this.isEntitlementExist = false;
        this.isUserExist = false;
        this.isAddNewUser = false;
        if (this.globalFilterSidebarCount > 0) this.clearAllGlobalFilters();
        if (this.userFilterSidebarCount > 0) this.clearAllUserFilters();
        if (this.EntitlmentFilterSidebarCount > 0) this.clearAllEntitlementFilters();

        const entitlementResultList = this.originalEntitlementList
            .filter(x => x.ACTION_TYPE == ONE)
            .map(({ ROLE_LIST, ROLE_COUNT, ...rest }) => rest);

        const roleUserResultList = this.originalUserList.filter(x => x.ACTION_TYPE == ONE);

        let roleDetails = {
            ROLE_ID: this.role.PRIME_ROLE_ID,
            ROLE_NAME: this.role.ROLE_NAME,
            ENTITLEMENT_LIST: entitlementResultList,
            DELETE_ROLE_ENTITLEMENT: this.deletedRoleEntitlement,
            ROLE_USER_LIST: roleUserResultList,
            DELETE_ROLE_USER: this.deletedRoleUser,
            DELETE_ROLE_USER_ENTITLEMENT: this.selectedUserEntitlementId,
            ROLE_USER_ENTITLEMENT: this.originalSelectedUserEntitlement,
            SELECTED_USER_ENT_ID_LIST: this.selectedUserEntitlementIdList,
            RETAINED_USER_ENT_ID_LIST: this.retainedUserEntitlementIdList,
            ROLE_ENT_LIST: this.entitlementList,
            ROLE_DELETED_USERS_LIST: this.roleDeletedUsersList,
            ROLE_USERS_LIST: this.usersList
        };

        console.log(this.entitlementList,"this.entitlementList");
        let details = {
            ROLE_ID: this.role.PRIME_ROLE_ID,
            LOGIN_TIME: this.pageVisitingTime
        };
        this.roleDetailsService.checkForChanges(details).subscribe(
            {
                next: async res => { //NOSONAR
                    if (res && res['STATUS'] == 1) {
                        this.isDBChanges = true;
                    } else {
                        this.messageFlag = true;
                        this.roleDetailsService.updateRoleDetails(roleDetails).subscribe(
                            {
                                next: response => {
                                    this.changeRoleFlag = false;
                                    this.isSavedAndAppliedSuccess = true;
                                    this.messageFlag = true;
                                    this.showSuccessValidations('Role Details updated successfully!');
                                    this.isSaveAndApplyBtnDisabled = true;
                                    if (this.unSavedChanges) {
                                        if (!this.onTabChange) {
                                            this.refreshRoleDetails(this.selectedRole);
                                        } else if (this.isRoleChanged) {
                                            this.refreshRoleDetails(this.selectedRole);
                                        } else {
                                            this.onTabChange = false;
                                            this.router.navigate([this.nextRouteInfo], {
                                                state: { user: this.selectedRoleUserData },
                                                skipLocationChange: true
                                            });
                                        }
                                    }
                                    this.unSavedChanges = false;
                                    this.isRoleChanged = false;
                                    this.deletedRoleEntitlement = [];
                                },
                                error: error => {
                                    this.messageFlag = true;
                                    this.showErrorValidations(error.error);
                                }
                            });
                    }
                },
                error: err => {
                    this.messageFlag = true;
                    this.showErrorValidations(err.error);
                }
            });
    }

    getRoleUserList() {
        this.usersList = [];
        this.roleUserList = [];
        const formData: FormData = new FormData();
        formData.append('roleId', this.role.ID.toString());
        this.roleDetailsService.getUserRoleList(formData).subscribe(
            {
                next: response => {
                    this.roleUserList = response;
                    this.usersList = JSON.parse(JSON.stringify(this.roleUserList));
                },
                error: err => {
                    this.showErrorValidations(err.error);
                }
            });
    }

    getRoleList() {
        let filter = {};

        filter['SORT_BY'] = 'NAME';
        filter['ROLE_STATUS_ID'] = [this.roleStatusList.filter(b => b.NAME == ACTIVE)[0].ID];
        if (this.selectedApp == null) {
            filter['APPLICATION_ID'] = localStorage.getItem("applicationId");
        }
        else {
            filter['APPLICATION_ID'] = this.selectedApp.id;
        }

        //this.roleDetailsService.getRoleList(filter).subscribe(
        this.roleDetailsService.getRoleListBasedOnApp(filter).subscribe(
            {
                next: resource => {
                    this.roleList = resource['RESULT'];

                    this.roleList.forEach(rl => {
                        let roleName = [];
                        roleName.push(rl.ROLE_NAME);
                        roleName.push(rl.PRIME_ROLE_ID);

                        let obj = rl;
                        obj['NAME'] = roleName.join('-');

                        this.changeRoleDropdown.push(obj);
                        this.roleMap.set(rl.PRIME_ROLE_ID, rl.LAST_MODIFIED_DATE);
                    });
                    if (this.roleMap !== null && this.roleMap.size > 0) {
                        this.lastUpdatedTime = this.datepipe.transform(this.roleMap.get(this.role.PRIME_ROLE_ID), 'MMM d, y, hh:mm:ss a');
                    }
                    this.currentRole = JSON.parse(JSON.stringify(this.roleList.filter(u => u.PRIME_ROLE_ID == this.role.PRIME_ROLE_ID)[0]));

                    this.selectedRole = this.currentRole;
                },
                error: error => {
                    this.messageFlag = true;
                    this.showErrorValidations(error.error);
                }
            });
    }

    loadImportRole() {
        this.roleList = this.roleList.filter(role => role.PRIME_ROLE_ID !== this.role.PRIME_ROLE_ID);
    }

    importRoleData() {
        let roleDetails = {
            ROLE_ID: this.role.PRIME_ROLE_ID,
            IMPORT_ROLE_ID: this.selectedRoleToImport.ID
        };
        this.roleDetailsService.importRoleData(roleDetails).subscribe(
            {
                next: response => {
                    this.refreshRoleDetails(this.role);
                    this.selectedRoleToImport = {};
                    this.showSuccessValidations('Role data imported successfully.');
                },
                error: error => {
                    this.messageFlag = true;
                    this.showErrorValidations(error.error);
                }
            });
    }

    refreshRoleDetails(varRole) {
        this.isDBChanges = false;
        this.unSavedChanges = false;
        this.onTabChange = false;
        this.isRoleChanged = false;
        if (varRole != null) {
            varRole['ROLE_NAME'] = varRole['NAME'].toString().substring(0, varRole['NAME'].lastIndexOf("-"));

            this.role = varRole;
        }
        this.isUserExist = false;
        this.isEntitlementExist = false;
        this.pageVisitingTime = new Date();
        this.discardUser();
        this.loadRoleStatus();
        this.getComponentList();
        if (this.isSavedAndAppliedSuccess) {
            this.getUsersByRole();
        }
        else {
            this.getAllUsersByRole();
        }
        this.getEntitlementByRole();
        this.roleMap.clear();
        this.getUsersList();
        this.selectedUserEntitlementIdList = [];
        this.retainedUserEntitlementIdList = [];
        this.roleDeletedUsersList = [];
        this.entImpactUsersList=[];

    }

    checkRoleData() {
        if (this.selectedRoleToImport) {
            const formData: FormData = new FormData();
            formData.append('roleId', this.role.PRIME_ROLE_ID.toString());

            this.roleDetailsService.checkRoleData(formData).subscribe(
                {
                    next: response => {
                        if (response == 'true') {
                            this.openImportModal(IMPORT);
                        } else {
                            this.importRoleData();
                        }
                    },
                    error: error => {
                        this.messageFlag = true;
                        this.showErrorValidations(error.error);
                    }
                });
        }
    }

    openGlobalFilterSideBar() {
        this.isGlobalFilterSelection = true;
        this.loadUserFilterData(true, false, false, false);
        this.loadEntitlmentFilterData();
    }

    applyGlobalFilter(isClearSectionFilters): void {
        if (isClearSectionFilters) {
            this.clearAllUserFilters();
            this.clearAllEntitlementFilters();
        }
        this.isGlobalFilterSelection = false;
        this.globalFilterSidebarCount++;
        this.appliedGlobleUserNameFilter = this.selectedGlobalUserFilter;
        this.appliedGlobleComponentNameFilter = this.selectedGlobalComponentFilter;
        this.appliedGlobleEntilementNameFilter = this.selectedGlobalEntitlementFilter;
        let anyMatch = false;

        if (this.selectedGlobalComponentFilter.length > 0 || this.selectedGlobalEntitlementFilter.length > 0) {
            this.entitlementList = this.originalEntitlementList.filter(c => {
                if (
                    (this.selectedGlobalComponentFilter &&
                        this.selectedGlobalComponentFilter.length > 0 &&
                        this.selectedGlobalComponentFilter.some(bf => bf.label === c.COMPONENT_NAME)) ||
                    (this.selectedGlobalEntitlementFilter &&
                        this.selectedGlobalEntitlementFilter.length > 0 &&
                        this.selectedGlobalEntitlementFilter.some(bf => bf.label === c.ENTITLEMENT_NAME))
                ) {
                    return true;
                }
                return false;
            });
            anyMatch = true;
        }
        if (!anyMatch && isClearSectionFilters) {
            this.clearAllGlobalFilters();
        }
    }

    clearAllGlobalFilters() {
        this.selectedGlobalUserFilter = [];
        this.selectedGlobalComponentFilter = [];
        this.selectedGlobalEntitlementFilter = [];
        this.appliedGlobleUserNameFilter = [];
        this.appliedGlobleComponentNameFilter = [];
        this.appliedGlobleEntilementNameFilter = [];
        this.entitlementList = this.originalEntitlementList;
        this.userFilterDropdown = [];
        this.componentFilterDropdown = [];
        this.entitlementFilterDropdown = [];
        this.entitlementStatusFilterDropdown = [];
        this.globalFilterSidebarCount = 0;
        this.activeGlobalTab = {
            isGlobalUserTab: false,
            isGlobalComponentTab: false,
            isGlobalEntitlementTab: false
        };
    }

    setGlobalActiveTab(activeTabName: any) {
        this.activeGlobalTab.isGlobalRoleTab = false;
        this.activeGlobalTab.isGlobalComponentTab = false;
        this.activeGlobalTab.isGlobalEntitlementTab = false;
        if (activeTabName == USER) {
            this.activeGlobalTab.isGlobalUserTab = true;
        } else if (activeTabName == COMPONENT) {
            this.activeGlobalTab.isGlobalComponentTab = true;
        } else if (activeTabName == ENTITLEMENT) {
            this.activeGlobalTab.isGlobalEntitlementTab = true;
        }
        this.openGlobalFilterSideBar();
    }

    addEntitlement() {
        this.isAddNewEntitlement = true;
        this.selectedComponent = {};
        this.selectedEntitlement = [];
        this.isEntitlementDropdown = false;
    }

    getEntitlementStatusList() {
        this.entitlementStatusDropdownList = [];
        this.entitlementDetailsService.getEntitlementStatusList().subscribe(
            {
                next: response => {
                    let statusRes = response;
                    this.entitlementStatusResponse = statusRes;
                    let namesArray: string[] = [ALLOW, DENY];
                    for (let i = 0; i < this.entitlementStatusResponse.length; i++) {
                        if (namesArray.includes(this.entitlementStatusResponse[i].NAME)) {
                            let res = statusRes[i];
                            this.entitlementStatusDropdownList.push({ label: res.NAME, value: res.ID });
                        }
                    }

                    this.allowStatusId = this.entitlementStatusDropdownList.filter(status => status.label == ALLOW)[0].value;

                    this.getComponentList();
                },
                error: err => {
                    this.messageFlag = true;
                    this.showErrorValidations(err.error);
                }
            });
    }

    getComponentList() {
        this.entitlementService.getComponentList().subscribe(
            {
                next: response => {
                    this.componentDropdownList = response;
                },
                error: err => {
                    this.messageFlag = true;
                    this.showErrorValidations(err.error);
                }
            });
    }

    getEntitlementData() {
        this.entitlementDropdownList = [];
        this.isEntitlementDropdown = true;
        this.isEntitlementExist = false;
        this.entitlementList = this.originalEntitlementList;
        this.selectedEntitlement = [];
        let data = {
            componentId: this.selectedComponent.PRIME_COMPONENT_ID,
            isEligibleToAssignToUser: 1
        };
        this.entitlementService.getEntitlementData(data).subscribe(
            {
                next: response => {
                    let entitlementDropdownList = response['entitlementDataList'];

                    let existngEntIds = this.originalEntitlementList
                        .filter(e => e.COMPONENT_ID == this.selectedComponent.PRIME_COMPONENT_ID)
                        .map(e => e.ENTITLEMENT_ID)
                        .slice(0);

                    this.entitlementDropdownList = entitlementDropdownList.filter(e => !existngEntIds.includes(e.ENTITLEMENT_ID)).slice(0);
                },
                error: err => {
                    this.messageFlag = true;
                    this.showErrorValidations(err.error);
                }
            });
    }

    addEntitlementToTable() {
        this.unSavedChanges = true;
        this.isEntitlementExist = false;
        this.entitlementList = this.originalEntitlementList;
        let filteredEntitlementList: any[] = [];

        for (let i = 0; i < this.selectedEntitlement.length; i++) {
            let obj = {
                COMPONENT_ID: this.selectedComponent.PRIME_COMPONENT_ID,
                ENTITLEMENT_ID: this.selectedEntitlement[i].ENTITLEMENT_ID,
                ENTITLEMENT_NAME: this.selectedEntitlement[i].ENTITLEMENT_NAME,
                COMPONENT_NAME: this.selectedComponent.COMPONENT_NAME,
                ACTION_TYPE: 1,
                UPDATED_ON: new Date(),
                STATUS_NAME: this.selectedEntitlement[i].STATUS,
                ENTITLEMENT_STATUS_ID: this.selectedEntitlement[i].ENTITLEMENT_STATUS_ID,
                IS_ADMS_ENTITLEMENT: this.selectedEntitlement[i].IS_ADMS_ENTITLEMENT,
                UM_ENTITLEMENT_ID: this.selectedEntitlement[i].ID
            };
            this.originalEntitlementList.unshift(obj);
        }
        this.isAddNewEntitlement = false;
        this.entitlementList = this.originalEntitlementList;
        this.isSaveAndApplyBtnDisabled = false;
    }


    refreshDependencyList() {
        this.totalDependentCount = 0;
        if (this.dependencyEntitlementList.length > 0) {
            for (let i = 0; i < this.entitlementList.length; i++) {
                if (this.entitlementList[i].DEPENDENT_LIST) {
                    this.entitlementList[i].DEPENDENT_LIST = null;
                }
                let dependencyEntitlementList = this.dependencyEntitlementList.filter(
                    ent =>
                        ent.COMPONENT_ID == this.entitlementList[i].PRIME_COMPONENT_ID &&
                        ent.PARENT_ENTITLEMENT_ID == this.entitlementList[i].ENTITLEMENT_ID
                );

                if (dependencyEntitlementList.length > 0) {
                    for (let dependencyIndex = dependencyEntitlementList.length - 1; dependencyIndex >= 0; dependencyIndex--) {
                        if (
                            this.entitlementList.filter(
                                entitlement =>
                                    entitlement.COMPONENT_ID == this.entitlementList[i].COMPONENT_ID &&
                                    entitlement.ENTITLEMENT_ID == dependencyEntitlementList[dependencyIndex].ENTITLEMENT_ID &&
                                    entitlement.OVERALL_STATUS_ID == this.entitlementList[i].OVERALL_STATUS_ID
                            ).length > 0
                        ) {
                            dependencyEntitlementList.splice(dependencyIndex, 1);

                            if (dependencyEntitlementList.length == 0) break;
                        }
                    }

                    if (dependencyEntitlementList && dependencyEntitlementList.length > 0 && !this.isAddNewEntitlement) {
                        this.entitlementList[i].DEPENDENT_LIST = dependencyEntitlementList;
                        this.totalDependentCount++;
                    }
                }
            }
        }
    }

    openDependentEntitlementDialog(componentName, entitlementDependentList) {
        this.dependentList = [];
        this.isDependentDialog = true;
        this.dependentList = entitlementDependentList;
        this.dependentComponentName = componentName;
    }

    clearAllEntitlementFilters() {
        this.selectedComponentFilter = [];
        this.selectedEntitlementFilter = [];
        this.selectedEntitlementStatusFilter = [];
        this.appliedComponentFilter = [];
        this.appliedEntitlementFilter = [];
        this.appliedEntitlementStatusFilter = [];
        this.EntitlmentFilterSidebarCount = 0;
        this.entitlementList = this.originalEntitlementList;
        this.componentFilterDropdown = [];
        this.entitlementFilterDropdown = [];
        this.entitlementStatusFilterDropdown = [];
        this.activeEntitlementTab = {
            isComponentTab: false,
            isEntitlementTab: false,
            isEntitlementStatusTab: false
        };
    }

    openEntitlementFilterSideBar() {
        this.isEntitlementFilterSelection = true;
        if (this.EntitlmentFilterSidebarCount == 0) {
            this.loadEntitlmentFilterData();
        }
    }

    loadEntitlmentFilterData() {
        this.componentFilterDropdown = [];
        this.entitlementFilterDropdown = [];
        this.entitlementStatusFilterDropdown = [];

        for (let Index = 0; Index < this.originalEntitlementList.length; Index++) {
            const componentObj = {
                label: this.originalEntitlementList[Index].COMPONENT_NAME,
                data: this.originalEntitlementList[Index],
                children: [],
                selectable: true,
                expanded: true,
                key: this.originalEntitlementList[Index].COMPONENT_ID
            };

            const entitlementListObj = {
                label: this.originalEntitlementList[Index].ENTITLEMENT_NAME,
                data: this.originalEntitlementList[Index],
                children: [],
                selectable: true,
                expanded: true,
                key: this.originalEntitlementList[Index].ENTITLEMENT_ID
            };

            const entitlementStatusListObj = {
                label: this.originalEntitlementList[Index].OVERALL_STATUS,
                data: this.originalEntitlementList[Index],
                children: [],
                selectable: true,
                expanded: true,
                key: this.originalEntitlementList[Index].OVERALL_STATUS_ID
            };

            if (this.componentFilterDropdown.filter(c => c.key.toString() == componentObj.key.toString()).length == 0) {
                this.componentFilterDropdown.push(componentObj);
            }

            if (this.entitlementFilterDropdown.filter(c => c.key.toString() == entitlementListObj.key.toString()).length == 0) {
                this.entitlementFilterDropdown.push(entitlementListObj);
            }

            if (this.entitlementStatusFilterDropdown.filter(c => c.key.toString() == entitlementStatusListObj.key.toString()).length == 0) {
                this.entitlementStatusFilterDropdown.push(entitlementStatusListObj);
            }
        }
    }

    setActiveEntitTab(activeTabName: any) {
        this.activeEntitlementTab.isComponentTab = false;
        this.activeEntitlementTab.isEntitlementTab = false;

        if (activeTabName == 'COMPONENT') {
            this.activeEntitlementTab.isComponentTab = true;
        } else if (activeTabName == 'ENTITLEMENT') {
            this.activeEntitlementTab.isEntitlementTab = true;
        } else if (activeTabName == 'ENTITLEMENT_STATUS') {
            this.activeEntitlementTab.isEntitlementStatusTab = true;
        }

        this.openEntitlementFilterSideBar();
    }

    applyEntitlementFilter(): void {
        this.EntitlmentFilterSidebarCount++;

        this.appliedComponentFilter = this.selectedComponentFilter;
        this.appliedEntitlementFilter = this.selectedEntitlementFilter;
        this.appliedEntitlementStatusFilter = this.selectedEntitlementStatusFilter;

        if (
            this.selectedComponentFilter.length > 0 ||
            this.selectedEntitlementFilter.length > 0 ||
            this.selectedEntitlementStatusFilter.length > 0
        ) {
            this.entitlementList = this.originalEntitlementList.filter(b => {
                if (
                    this.selectedComponentFilter &&
                    this.selectedComponentFilter.length > 0 &&
                    !this.selectedComponentFilter.some(bf => bf.key === b.COMPONENT_ID)
                ) {
                    return false;
                }
                if (
                    this.selectedEntitlementFilter &&
                    this.selectedEntitlementFilter.length > 0 &&
                    !this.selectedEntitlementFilter.some(bf => b.ENTITLEMENT_NAME.includes(bf.label))
                ) {
                    return false;
                }
                if (
                    this.selectedEntitlementStatusFilter &&
                    this.selectedEntitlementStatusFilter.length > 0 &&
                    !this.selectedEntitlementStatusFilter.some(bf => bf.key === b.OVERALL_STATUS_ID)
                ) {
                    return false;
                }
                return true;
            });
        } else {
            this.clearAllEntitlementFilters();
        }
        this.isEntitlementFilterSelection = false;
    }

    discardEntitlement() {
        this.isAddNewEntitlement = false;
        this.selectedComponent = {};
        this.selectedEntitlement = [];
        this.isEntitlementDropdown = false;
        this.isEntitlementExist = false;
        this.entitlementList = this.originalEntitlementList;
    }

    canDeactivate(nextState?: any) {
        if (this.unSavedChanges) {
            this.navigationMessage = 'There is unsaved data in current page. Please confirm the changes.';
            this.nextRouteInfo = nextState.url;
            this.onTabChange = true;
        }
        return !this.onTabChange;
    }

    onSaveAndApply() {
        this.updateRoleDetails(false);
    }

    unSaveChangesPopUp() {
        this.isEntitlementDisabled = false;
        this.changeRoleFlag = true;
        this.isSavedAndAppliedSuccess = false;
        if (this.unSavedChanges) {
            this.previousRole = JSON.parse(JSON.stringify(this.currentRole));
            this.currentRole = JSON.parse(JSON.stringify(this.selectedRole));
            this.onTabChange = true;
        } else {
            this.refreshRoleDetails(this.selectedRole);
        }
        this.isRoleChanged = true;
        this.isAddNewEntitlement = false;
    }

    onDiscardChange() {
        this.onTabChange = false;
        this.unSavedChanges = false;
        this.isSaveAndApplyBtnDisabled = true;
        this.changeRoleFlag = true;
        this.isSavedAndAppliedSuccess = false;
        if (this.isRoleChanged) {
            this.refreshRoleDetails(this.selectedRole);
        } else {
            this.router.navigate([this.nextRouteInfo], { state: { user: this.selectedRoleUserData }, skipLocationChange: false });
        }
        this.onTabChange = false;
    }

    onCancel() {
        this.onTabChange = false;
        if (this.isRoleChanged) {
            this.selectedRole = this.previousRole;
        }
        this.roleDetailsService.sendMessage(1);
        return false;
    }

    onDiscard() {
        this.unSavedChanges = false;
    }

    cancelImport(event, element) {
        element.hide(event);
        this.selectedRoleToImport = '';
    }

    getUsersByRole() {
        let filter = {};

        filter['roleId'] = this.role.PRIME_ROLE_ID.toString();
        this.roleDetailsService.getUserList(filter).subscribe(
            {
                next: response => {
                    this.roleUserList = response['USERS'];
                    this.usersList = JSON.parse(JSON.stringify(this.roleUserList));
                },
                error: err => {
                    this.messageFlag = true;
                    this.showErrorValidations(err.error);
                }
            });
    }

    getAllUsersByRole() {
        let filter = {};
        filter['roleId'] = this.role.PRIME_ROLE_ID.toString();
        filter['changeRoleFlag'] = this.changeRoleFlag.toString();
        filter['roleName'] = this.role.ROLE_NAME;
        this.roleDetailsService.getAllUserList(filter).subscribe(
            {
                next: response => {
                    this.roleUserList = response['USERS'];
                    this.usersList = JSON.parse(JSON.stringify(this.roleUserList));
                },
                error: err => {
                    this.messageFlag = true;
                    this.showErrorValidations(err.error);
                }
            });
    }

    getEntitlementByRole() {
        let filter = {};
        filter['ROLE_ID'] = this.role.PRIME_ROLE_ID.toString();
        this.roleDetailsService.getEntitlementsForRole(filter).subscribe(
            {
                next: response => {
                    this.entitlementList = response['ROLE_ENTITLEMENTS'];
                    this.roleEntitlementUserCount = this.entitlementList.map(e => e.userCount)[0];
                    this.originalEntitlementList = this.entitlementList;
                },
                error: err => {
                    this.messageFlag = true;
                    this.showErrorValidations(err.error);
                }
            });
    }

    deleteRoleEntitlementPopUp(entitlement, rowIndex) {
        this.deletedEnt = entitlement
        this.entitlementUserList = [];
        this.selectedRecords = [];
        this.roleId = entitlement.ROLE_ID;
        if (this.roleId == null) {
            this.entitlementList.splice(this.currentRowIndex, 1);
        } else {
            this.isDeleteRoleEntitlementDialog = true;
            this.isRestButtonDisabled = true;
            this.currentRowIndex = rowIndex;
            this.roleEntId = entitlement.ID;
            this.roleEntName = entitlement.ENTITLEMENT_NAME;
            this.roleEntitlementName = entitlement.ENTITLEMENT_NAME + ' ' + entitlement.COMPONENT_ID + '-' + entitlement.ENTITLEMENT_ID;
            this.entitlementRoleName = entitlement.ROLE_NAME + '-' + entitlement.ROLE_ID;

            let roleEntitlementDetails = {
                ENTITLEMENT_ID: entitlement.ENTITLEMENT_ID,
                COMPONENT_ID: entitlement.COMPONENT_ID,
                ROLE_ID: this.roleId
            };

            this.roleDetailsService.getEntitlementWiseUsers(roleEntitlementDetails).subscribe(
                {
                    next: response => {
                        this.entitlementUserList = response['ENTITLEMENT_USERS']?.filter(e=>e.entStatus==ALLOW);
                        this.entImpactUsersList = response['ENTITLEMENT_USERS']?.filter(e=>e.entStatus!=ALLOW && e.is_default_checked == ONE)
                        let existingUserIds = [];
                        if (this.usersList.length > 0) {
                            existingUserIds = this.usersList.map(e => e.USER_ID);
                            this.entitlementUserList = this.entitlementUserList.filter(e => existingUserIds.includes(e.user_id));
                        } else {
                            this.entitlementUserList = [];
                        }

                        this.selectedRecords = this.entitlementUserList.filter(e => e.is_default_checked == 1);
                    },
                    error: error => {
                        this.messageFlag = true;
                        this.showErrorValidations(error.error);
                    }
                });
        }
    }

    onActionDeleteRoleEntitlement() {
        this.entImpactUsersList.push(...this.selectedRecords);

        for (const e of this.entImpactUsersList) {
            let userObj = {
                "LOGIN_ID": e['loginId'],
                "USER_ID": e['user_id'],
                "ENTITLEMENT_ID": this.deletedEnt['ENTITLEMENT_ID'],
                "ENTITLEMENT_NAME": this.deletedEnt['ENTITLEMENT_NAME'],
                "COMPONENT_ID": this.deletedEnt['COMPONENT_ID'],
                "COMPONENT_NAME": this.deletedEnt['COMPONENT_NAME']
            }
            this.roleDeletedUsersList.push(userObj);
        }
        let selectedIdList = this.selectedRecords.map(s => s.um_ent_id);
        this.selectedUserEntitlementIdList.push(...selectedIdList);

        let retainedlist = this.entitlementUserList
            .filter(e => e.is_default_checked == 1 && !selectedIdList.includes(e.um_ent_id))
            .map(e => e.um_ent_id);

        this.retainedUserEntitlementIdList.push(...retainedlist);

        this.deletedRoleEntitlement.push(this.entitlementList[this.currentRowIndex]);
        this.entitlementList.splice(this.currentRowIndex, 1);
        this.originalEntitlementList = this.entitlementList;
        this.deletedRoleEntitlement = this.deletedRoleEntitlement.map(
            ({ COMPONENT_NAME, ENTITLEMENT_STATUS_ID, ID, ROLE_ID, ROLE_NAME, STATUS_NAME, UPDATED_ON, ...rest }) => rest
        );
        this.isSaveAndApplyBtnDisabled = false;
        this.unSavedChanges = true;
        this.isAddNewEntitlement = false;
        this.isAddNewUser = false;
    }

    getUsersList() {
        this.isUserDropdown = true;
        this.isUserExist = false;
        this.existinguserIds = [];
        this.userList = [];
        let filter = {};
        let status = [];
        this.addUserDropdown = [];
        status.push('Active');
        filter['USER_STATUS'] = status;
        filter['SORT_BY'] = 'NAME';

        this.roleDetailsService.getUsersList(filter).subscribe(
            {
                next: resource => {
                    this.userList = resource['RESULT'];
                    this.existinguserIds = this.usersList.map(e => e.USER_ID);
                    this.userList.forEach(rl => {
                        let userName = [];
                        userName.push(rl.NAME);
                        userName.push(rl.ID);

                        let obj = rl;
                        obj['NAME_WITH_ID'] = userName.join('-');

                        this.addUserDropdown = this.userList.filter(e => !this.existinguserIds.includes(e.ID)).slice(0);
                    });
                },
                error: error => {
                    this.messageFlag = true;
                    this.showErrorValidations(error.error);
                }
            });
    }

    deleteRoleUserPopUp(usersList, rowIndex) {
        this.deletedUser = usersList;

        this.currentRoleUserRowIndex = rowIndex;

        let existingRoleUserIds = this.roleUserList.map(e => e.USER_ID);
        if (!existingRoleUserIds.includes(usersList.USER_ID)) {
            this.usersList.splice(this.currentRoleUserRowIndex, 1);
            this.originalUserList = this.usersList;
            let existingUserIds = this.usersList.map(e => e.USER_ID);
            this.addUserDropdown = this.userList.filter(e => !existingUserIds.includes(e.ID)).slice(0);
        } else {
            this.isRestButtonDisabled = true;
            this.roleUserName = usersList.USER_NAME;
            this.roleUserId = usersList.USER_ID;
            this.userRoleName = this.role.ROLE_NAME;
            this.userRoleId = this.role.PRIME_ROLE_ID;
            let roleUserDetails = {
                USER_ID: this.roleUserId,
                ROLE_ID: this.userRoleId
            };
            this.selectedUserEntitlement = [];
            this.userEntitlementList = [];
            this.roleDetailsService.getRoleUserWiseEntitlementCount(roleUserDetails).subscribe(
                {
                    next: response => {
                        existingRoleUserIds = [];
                        let existingUserEntIds = [];
                        this.userEntitlementList = response['USER_ENTITLEMENT'];
                        this.userImpactEntList = response['USER_ENTITLEMENT']?.filter(e => e.entStatus != ALLOW && e.roleUserEntitlement == ONE)
                        if (this.entitlementList.length > 0) {
                            existingUserEntIds = this.entitlementList.map(e => e.ID);
                            this.userEntitlementList = this.userEntitlementList.filter(e => existingUserEntIds.includes(e.eID));
                        } else {
                            this.userEntitlementList = [];
                        }

                        this.selectedUserEntitlement = this.userEntitlementList.filter(p => p.roleUserEntitlement == ONE);
                        this.isDeleteRoleUserDialog = true;
                    },
                    error: error => {
                        this.messageFlag = true;
                        this.showErrorValidations(error.error);
                    }
                });
        }
    }

    onActionDeleteRoleUser() {
        this.userImpactEntList.push(...this.selectedUserEntitlement);

        for (const e of this.userImpactEntList) {
            let userObj = {
                "LOGIN_ID": this.deletedUser['LOGIN_ID'],
                "USER_ID": this.deletedUser['USER_ID'],
                "ENTITLEMENT_ID": e['ENTITLEMENT_ID'],
                "ENTITLEMENT_NAME": e['entitlementName'],
                "COMPONENT_ID": e['COMPONENT_ID'],
                "COMPONENT_NAME": e['COMPONENT_NAME']
            }

            this.userDeleteEntList.push(userObj);
        }
        let entIds = this.selectedUserEntitlement.map(s => s.entID);
        this.selectedUserEntitlementId.push(...entIds);

        if (this.userEntitlementList.length > 0) {
            this.originalSelectedUserEntitlement = this.userEntitlementList
                .filter(e => e.roleUserEntitlement == ONE && !this.selectedUserEntitlementId.includes(e.entID))
                .map(e => e.entID);
        }

        this.deletedRoleUser.push(this.usersList[this.currentRoleUserRowIndex]);
        this.usersList.splice(this.currentRoleUserRowIndex, 1);
        this.originalUserList = this.usersList;
        let existingRoleUserIds = this.usersList.map(e => e.USER_ID);
        this.addUserDropdown = this.userList.filter(e => !existingRoleUserIds.includes(e.ID)).slice(0);
        this.deletedRoleUser = this.deletedRoleUser.map(({ STATUS, UPDATED_ON, ...rest }) => rest);
        this.isDeleteRoleUserDialog = false;
        this.isSaveAndApplyBtnDisabled = false;
        this.unSavedChanges = true;
        this.isAddNewUser = false;
    }

    loadRoleStatus() {
        let isDefault: boolean = true;
        const formData: FormData = new FormData();
        formData.append('isDefault', isDefault ? '1' : '0');

        this.roleDetailsService.getRoleStatusList(formData).subscribe(
            {
                next: response => {
                    this.roleStatusList = response;
                    this.getRoleList();
                },
                error: error => {
                    this.messageFlag = true;
                    this.showErrorValidations(error.error);
                }
            });
    }

    openFilterSideBar() {
        this.showUserFilter = true;

        if (this.userStatusData.length == 0) {
            this.loadUserStatus();
        }
    }

    loadUserStatus() {
        const formData: FormData = new FormData();
        formData.append('isDefault', DEFAULT_ONE);
        this.roleDetailsService.getUserStatusList(formData).subscribe(
            {
                next: response => {
                    this.userStatusList = response;
                    this.loadFilterData(this.userStatusData, response);
                },
                error: error => {
                    this.messageFlag = true;
                    this.showErrorValidations(error.error);
                }
            });
    }

    loadFilterData(mainFilterList: any, dimensionList: any) {
        for (let parentIndex = 0; parentIndex < dimensionList.length; parentIndex++) {
            const parentNode = {
                label: dimensionList[parentIndex].NAME,
                data: dimensionList[parentIndex],
                children: [],
                selectable: true,
                expanded: true,
                key: dimensionList[parentIndex].ID
            };

            mainFilterList.push(parentNode);
        }
    }

    applyRoleUserFilter(): void {
        this.showUserFilter = false;
        this.appliedStatusFilter = this.selectedUserStatus;
        if (this.selectedUserStatus.length > 0) {
            this.usersList = this.roleUserList.filter(b => {
                if (
                    this.selectedUserStatus &&
                    this.selectedUserStatus.length > 0 &&
                    !this.selectedUserStatus.some(bf => bf.label.toUpperCase().toString() == b.STATUS.toUpperCase().toString())
                ) {
                    return false;
                }

                return true;
            });
        } else {
            this.clearRoleUserFilters();
        }
        this.applyUserFilter(true);
    }

    clearRoleUserFilters() {
        this.selectedUserStatus = [];
        this.appliedStatusFilter = [];
        this.usersList = this.roleUserList;
    }

    sidebarCloseEvent() {
        if (this.appliedStatusFilter.length != this.selectedUserStatus.length) {
            this.selectedUserStatus = this.appliedStatusFilter;
        }
        if (this.selectedUserStatus.length == 0) {
            this.activeUserTab.isStatusTab = false;
        }
    }

    setActiveUserTab(activeTabName: any) {
        this.activeUserTab.isStatusTab = false;

        if (activeTabName == 'STATUS') {
            this.activeUserTab.isStatusTab = false;
        }

        this.openFilterSideBar();
    }

    discardUserFilter(event, element) {
        element.hide(event);
    }

    onResetRoleUser() {
        this.isRestButtonDisabled = true;
        this.selectedUserEntitlement = this.userEntitlementList.filter(p => p.roleUserEntitlement == ONE);
        this.roleUserEntitlementCount = this.selectedUserEntitlement.length;
    }

    onEntitlementSelectionChange() {
        this.isRestButtonDisabled = false;
        this.roleUserEntitlementCount = this.selectedUserEntitlement.length;
    }

    resetRoleEnt() {
        this.selectedRecords = this.entitlementUserList.filter(e => e.is_default_checked == 1);
        this.isRestButtonDisabled = true;
    }
    onRowSelect() {
        this.isRestButtonDisabled = false;
    }
    onAllRowSelection() {
        this.isRestButtonDisabled = false;
    }

    showSuccessValidations(message: string) {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: message });
        setTimeout(() => {
            this.messageFlag = false;
        }, 3000);

    }

    showErrorValidations(message: string) {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: message });
        setTimeout(() => {
            this.messageFlag = false;
        }, 3000);

    }

    navigateToEntDetailScreens(entitlementData) {
        this.roleDetailsService.sendMessage(2);
        this.router.navigateByUrl('/pages/entitlementsdetails', {
            state: { entitlement: entitlementData },
            skipLocationChange: false
        });
    }
}