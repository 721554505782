<div class="layout-content custom-container">
    <form [formGroup]="EmailTemplateMappingForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 left">
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-p-0 p-offset-0 left">
                <div class=" p-fluid   p-field    ">
                    <span>Component Name:</span>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-p-0 p-offset-0 left">
                <div class=" p-fluid   p-field    ">
                    <label [ngClass]="{'field-required': EmailTemplateMappingForm.get('select')?.errors?.required}">
                        Choose Template
                    </label> <span class='invalid-feedback-astrik'>*</span>
                    <div class="p-fluid">
                        <p-dropdown id="selectid" name="select" placeholder="Select a Application" placeholder="Select"
                            [options]="selectList" [filter]="true" filterBy="label" [autoDisplayFirst]="false"
                            formControlName="select">
                            <ng-template let-items pTemplate="item">
                                <div *ngIf="items?.label?.length &lt;=37;">
                                    {{items.label}}
                                </div>
                                <div *ngIf="items?.label?.length > 37;" pTooltip="{{items.label}}"
                                    tooltipPosition="bottom">
                                    {{items.label.substring(0,37) + "..." }}
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                    <div class="invalid-feedback"
                        *ngIf="EmailTemplateMappingForm.get('select')!.invalid && (EmailTemplateMappingForm.get('select')!.dirty || EmailTemplateMappingForm.get('select')!.touched)">
                        <div *ngIf="EmailTemplateMappingForm.get('select')?.errors?.required">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium class="p-error">Application is required.</medium>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                <div class="p-col-fixed p-right custom-btn-height p-pt-2">
                    <div class="btnwrap p-mr-3">
                        <button pButton type="button" class="p-button-brand2 p-button"
                            (click)="onClose(EmailTemplateMappingForm);" label="Close">
                        </button>
                    </div>
                    <div class="btnwrap">
                        <button pButton class="p-button" id="save" type="button"
                            [disabled]="!EmailTemplateMappingForm.valid" label="Save">
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>