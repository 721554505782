import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { PagesService } from '../pages.service';
@Component({
  selector: 'jhi-CreateApplicationRole',
  templateUrl: './CreateApplicationRole.component.html',
  styleUrls: ['./CreateApplicationRole.component.scss'],
  providers: [DialogService],
})
export class CreateApplicationRoleComponent implements OnInit {
  CreateApplicationRoleForm = this.fb.group({
    textField: [null, [Validators.required]],
    textarea: [null, []],
    checkbox: [null, []],
  });
  pageloadvalues: any;
  nameFlag: boolean = false;
  enabledbtn1: boolean = false;
  checked: boolean = false;
  appId: any;

  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public pagesService: PagesService) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.CreateApplicationRoleForm.patchValue(JSON.parse(data));
    }
  }
  ngOnInit(): void {
    this.appId = this.config?.data?.id;
  }

  onClose($event: UntypedFormGroup): void {
    this.ref.close();
  }

  colorChange() {
    document.getElementById('rolename_id')!.style.borderColor = 'grey';
  }

  /**
   * CREATE APPLICATION ROLE IN APPLICATION ONBOARDING IN ROLES TAB
   */

  // create role API
  CreateApplicationRole(): void {
    const reqBody = {};
    reqBody['applicationRoleName'] = this.CreateApplicationRoleForm.value.textField;
    reqBody['description'] = this.CreateApplicationRoleForm.value.textarea;
    if (this.checked) {
      reqBody['default'] = this.checked;
    }
    const formValues = reqBody;
    this.pagesService.onSaveCreateApplicationRole(formValues, this.appId).subscribe(
      {
        next: results => {
          this.ref.close("Application Role Created Successfully");
        },
        error: error => {
          if (error.error?.error?.messageCode == "CIAM 105") {
            this.ref.close("Application Role already exist");
          }
          else {
            this.ref.close("Application Role Creation Failed");
          }
        }
      });
  }

  //Check availability of role name
  ApplicationRole($event: UntypedFormGroup) {
    this.onCheckApplicationRoleName($event);
  }

  onCheckApplicationRoleName($event: UntypedFormGroup): void {
    const reqBody = this.CreateApplicationRoleForm.value?.textField;
    const data = { appId: this.appId, rolename: reqBody };
    this.pagesService.onCheckApplicationRoleName(data).subscribe(
      results => {
        if (results?.error?.code == 400 || results?.success?.code == 400) {
          this.nameFlag = true;
          document.getElementById('rolename_id')!.style.borderColor = 'red';
        } else if (results.success.code == 200) {
          this.nameFlag = false;
          document.getElementById('rolename_id')!.style.borderColor = 'grey';
          this.CreateApplicationRole();
          this.enabledbtn1 = true;
        }
      },
    );
  }


}