import { Component, ContentChildren, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MessageService, PrimeTemplate, FilterService, TreeNode } from 'primeng/api';
import { PagesService } from '../pages.service';
import { DomHandler } from "primeng/dom";
import { ObjectUtils } from '../object-utlis.service';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';

@Component({
  selector: 'jhi-usergroupmapping',
  templateUrl: './HostAttributeGroupMapping.component.html',
  styleUrls: ['./HostAttributeGroupMapping.component.scss'],
  providers: [MessageService, FilterService, ObjectUtils, DialogService]
})
export class HostAttributeGroupMappingComponent implements OnInit {
  HostAttributeGroupMappingForm = this.fb.group({
    tabview: [0],
    autocomplete: [null, [Validators.required]],
    select2: [null, [Validators.required]],
    select: [null, [Validators.required]],
    select1: [null, [Validators.required]],
    pickList1: [null, []],
    pickList2: [null, []],
    sourceFilter: [null, []],
  });
  pageloadvalues: any;
  ccprimebreadcrumbList = [
    { label: `Authorization` },
    { label: `Application roles` },
    { label: `User group mapping` },
  ]
  homeiconccprimebreadcrumb = { icon: 'pi pi-home', routerLink: ['/'] };
  htmlSrc: any;
  autocompleteList: any[] = [];
  selectList: any[] = [];
  select1List: any[] = [];
  select2List: any[] = [];
  pickList1List: any[] = [];
  pickList1List1: any[] = [];
  pickList1targetList: any[] = [];
  pickList1targetList1: any[] = [];
  pickList2List: any[] = [];
  pickList2targetList: any[] = [];
  text: any[] = [];
  messageFlag = true;
  results: string[] = [];
  options: any;
  tableList: any[] = [];
  appId: any;
  attId: any;
  groupId: any;
  flag1 = true;
  mapList: any[] = [];
  mapList1: any[] = [];
  mList: any[] = [];
  mList1: any[] = [];
  umList: any[] = [];
  umList1: any[] = [];
  exMapList: any[] = [];
  GroupExMapList: any[] = [];
  btn = true;
  btn1 = true;
  dropdown = true;
  treeList1List: any[] = [];
  treeList1List1: any[] = [];
  mListTree: any[] = [];
  exMapListTree: any[] = [];
  alreadyMappedTreeList: any[] = [];
  selectedTreeRes: any[] = [];

  //new picklist
  isChecked: boolean = false;
  @ViewChildren("picklistCheckboxes") checkboxes: QueryList<ElementRef>;
  @Input() sourceHeader: string;
  @Input() targetHeader: string;
  @Input() responsive: boolean;
  @Input() filterBy: string = "brand";
  @Input() trackBy: Function = (index: number, item: any) => item;
  @Input() sourceTrackBy: Function;
  @Input() targetTrackBy: Function;
  @Input() showSourceFilter: boolean = true;
  @Input() showTargetFilter: boolean = true;
  @Input() metaKeySelection: boolean = true;
  @Input() style: any;
  @Input() styleClass: string;
  @Input() sourceStyle: any;
  @Input() targetStyle: any;
  @Input() showSourceControls: boolean = true;
  @Input() showTargetControls: boolean = true;
  @Input() sourceFilterPlaceholder: string;
  @Input() targetFilterPlaceholder: string;
  @Input() disabled: boolean = false;
  @Output() onMoveToSource: EventEmitter<any> = new EventEmitter();
  @Output() onMoveAllToSource: EventEmitter<any> = new EventEmitter();
  @Output() onMoveAllToTarget: EventEmitter<any> = new EventEmitter();
  @Output() onMoveToTarget: EventEmitter<any> = new EventEmitter();
  @Output() onSourceSelect: EventEmitter<any> = new EventEmitter();
  @Output() onTargetSelect: EventEmitter<any> = new EventEmitter();
  @ViewChild('sourcelist') listViewSourceChild: ElementRef;
  @ViewChild('targetlist') listViewTargetChild: ElementRef;
  @ViewChild('sourceFilter') sourceFilterViewChild: ElementRef;
  @ViewChild('targetFilter') targetFilterViewChild: ElementRef;
  @ContentChildren(PrimeTemplate) templates: QueryList<any>;
  public itemTemplate: TemplateRef<any>;
  public visibleOptionsSource: any[];
  public visibleOptionsTarget: any[];
  selectedItemsSource: any[] = [];
  selectedItemsTarget: any[] = [];
  reorderedListElement: any;
  movedUp: boolean;
  movedDown: boolean;
  itemTouched: boolean;
  filterValueSource: string;
  filterValueTarget: string;
  listHighlightTarget: boolean;
  listHighlightSource: boolean;
  readonly SOURCE_LIST = -1;
  readonly TARGET_LIST = 1;
  messageFlag1 = true;
  tenantName: any;
  tenantId: any;
  chkTarget: any;
  chkSource: any;
  accountId: any;
  attributeGroupId: any;
  groupName: string;
  //end

  flag3: boolean = false;
  files: TreeNode[];
  selectedFiles: TreeNode[] = [];

  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    public pagesService: PagesService,
    private fb: UntypedFormBuilder,
    private messageService: MessageService,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) {
    const navigation = this.router.getCurrentNavigation();
    const data1 = navigation?.extras?.state?.data;
    if (data1) {
      this.HostAttributeGroupMappingForm.patchValue(JSON.parse(data1));
    }
  }

  ngOnInit(): void {
    this.appId = this.config?.data?.id;
    this.groupName = this.config?.data?.groupname;
    this.getTypeList();
    if (this.pagesService.attributeTreeEnable) {
      this.getMappedDetailsTree();
    }
    else {
      this.getMappedDetails();
    }

  }

  multipleSelectionExpandAll() {
    this.treeList1List.forEach(node => {
      this.expandRecursive(node, true);
    });
  }

  multipleSelectionCollapseAll() {
    this.treeList1List.forEach(node => {
      this.expandRecursive(node, false);
    });
  }
  resetTree() {
    this.alreadyMappedTreeList = [];
  }
  nodeSelect(event: any) {
    console.log(this.alreadyMappedTreeList);
  }

  nodeUnselect(event: any) {
    console.log(this.alreadyMappedTreeList);
  }

  expandAll() {
    this.files.forEach((node) => {
      this.expandRecursive(node, true);
    });
  }

  collapseAll() {
    this.files.forEach((node) => {
      this.expandRecursive(node, false);
    });
  }

  private expandRecursive(node: TreeNode, isExpand: boolean) {
    node.expanded = isExpand;
    if (node.children) {
      node.children.forEach((childNode) => {
        this.expandRecursive(childNode, isExpand);
      });
    }
  }

  ngAfterContentInit() {
    this.templates.forEach((item) => {
      this.itemTemplate = item.template;
    });
  }

  ngAfterViewChecked() {
    if (this.movedUp || this.movedDown) {
      let listItems = DomHandler.find(this.reorderedListElement, 'li.p-state-highlight');
      let listItem;
      if (this.movedUp)
        listItem = listItems[0];
      else
        listItem = listItems[listItems.length - 1];

      DomHandler.scrollInView(this.reorderedListElement, listItem);
      this.movedUp = false;
      this.movedDown = false;
      this.reorderedListElement = null;
    }
  }

  onItemClick(event, item: any, selectedItems: any[], callback: EventEmitter<any>) {
    if (this.disabled) {
      return;
    }

    let index = this.findIndexInSelection(item, selectedItems);
    let selected = (index != -1);
    if (selected) {
      selectedItems.splice(index, 1);
    }
    else {
      selectedItems.push(item);
    }

    callback.emit({ originalEvent: event, items: selectedItems });

    this.itemTouched = false;
  }

  filterMethodPick(event: any, list: any, pickFlag: any) {
    let filtered: any[] = [];
    let items: any;
    let query = event;
    if (query.length > 0) {
      list.forEach(element => {
        items = element;

        if (items.label?.toLowerCase().includes(query.toLowerCase())) {
          filtered.push(items);
        }
      });
      if (pickFlag == 'pick1source') {
        this.pickList1List = filtered;
      }
      else if (pickFlag == 'pick1target') {
        this.pickList1targetList = filtered;
      }

    }
    else if (pickFlag == 'pick1source') {
      this.pickList1List = [];
      this.pickList1List = [...this.pickList1List1];
    }
    else if (pickFlag == 'pick1target') {
      this.pickList1targetList = [];
      this.pickList1targetList = [...this.pickList1targetList1];
    }
  }

  checkMapping(list: any) {
    this.mapList = list;
    this.mList = [];
    this.mapList.forEach((element: any = {}) => {
      const data = {
        label: null,
        value: null,
      };
      data.label = element.label;
      data.value = element.value;
      this.mList.push(data.value);
    });
    this.mList = [...this.mList];
    this.chkTarget = this.mList.filter(x => !this.exMapList.includes(x));
    this.chkSource = this.exMapList.filter(x => !this.mList.includes(x));
    if (this.chkSource.length == 0 && this.chkTarget.length == 0) {
      this.btn = true;
    }
    else {
      this.btn = false;
    }
  }

  isItemVisible(item: any, listType: number): boolean {
    if (listType == this.SOURCE_LIST) {
      return this.isVisibleInList(this.visibleOptionsSource, item, this.filterValueSource);
    }
    else {
      return this.isVisibleInList(this.visibleOptionsTarget, item, this.filterValueTarget);
    }
  }

  isVisibleInList(data: any[], item: any, filterValue: string): boolean {
    if (filterValue?.trim().length) {
      data.forEach(element => {
        if (item == element) {
          return true;
        }
      });
    }
    else {
      return true;
    }
  }

  moveRight() {
    if (this.selectedItemsSource?.length) {
      this.selectedItemsSource.forEach(element => {
        let selectedItem = element;
        if (this.findIndexInList(selectedItem, this.pickList1targetList) == -1) {
          this.pickList1targetList.push(this.pickList1List.splice(this.findIndexInList(selectedItem, this.pickList1List), 1)[0]);
          this.pickList1targetList1.push(this.pickList1List1.splice(this.findIndexInList(selectedItem, this.pickList1List1), 1)[0]);
        }
      });
      this.isChecked = false;
      this.onMoveToTarget.emit({
        items: this.selectedItemsSource
      });
      this.selectedItemsSource = [];
    }
    this.checkboxes.forEach((element) => {
      this.pickList1targetList.filter(z => z).forEach((x) => (x.isChecked = false));
    });
    this.checkMapping(this.pickList1targetList);
  }

  moveAllRight() {
    if (this.pickList1List) {
      let movedItems = [];
      this.pickList1targetList1 = [];
      for (let i = 0; i < this.pickList1List.length && this.isItemVisible(this.pickList1List[i], this.SOURCE_LIST); +i) {
        let removedItem = this.pickList1List.splice(i, 1)[0];
        this.pickList1List1.splice(this.findIndexInList(removedItem, this.pickList1List1), 1);
        this.pickList1targetList.push(removedItem);
        movedItems.push(removedItem);
        this.btn = false;
      }

      this.onMoveToTarget.emit({
        items: movedItems
      });

      this.onMoveAllToTarget.emit({
        items: movedItems
      });

      this.selectedItemsSource = [];
      this.pickList1targetList1 = [...this.pickList1targetList];
    }
    this.checkboxes.forEach((element) => {
      this.pickList1targetList.filter(z => z).forEach((x) => (x.isChecked = false));
    });
    this.checkMapping(this.pickList1targetList);
  }

  moveLeft() {
    if (this.selectedItemsTarget?.length) {
      this.selectedItemsTarget.forEach(element => {
        let selectedItem = element;
        if (this.findIndexInList(selectedItem, this.pickList1List) == -1) {
          this.pickList1List.push(this.pickList1targetList.splice(this.findIndexInList(selectedItem, this.pickList1targetList), 1)[0]);
          this.pickList1List1.push(this.pickList1targetList1.splice(this.findIndexInList(selectedItem, this.pickList1targetList1), 1)[0]);
        }
      });
      this.isChecked = false;
      this.onMoveToSource.emit({
        items: this.selectedItemsTarget
      });

      this.selectedItemsTarget = [];
    }
    this.checkboxes.forEach((element) => {
      this.pickList1List.filter(z => z).forEach((x) => (x.isChecked = false));
    });
    this.checkMapping(this.pickList1targetList);
  }

  moveAllLeft() {
    if (this.pickList1targetList) {
      let movedItems = [];
      this.pickList1List1 = [];
      for (let i = 0; i < this.pickList1targetList.length && this.isItemVisible(this.pickList1targetList[i], this.TARGET_LIST); +i) {
        let removedItem = this.pickList1targetList.splice(i, 1)[0];
        this.pickList1targetList1.splice(this.findIndexInList(removedItem, this.pickList1targetList1), 1);
        this.pickList1List.push(removedItem);
        movedItems.push(removedItem);
        this.btn = false;
      }

      this.onMoveToSource.emit({
        items: movedItems
      });

      this.onMoveAllToSource.emit({
        items: movedItems
      });

      this.selectedItemsTarget = [];
      this.pickList1List1 = [...this.pickList1List];
    }
    this.checkboxes.forEach((element) => {
      this.pickList1List.filter(z => z).forEach((x) => (x.isChecked = false));
    });
    this.checkMapping(this.pickList1targetList);
  }

  isSelected(item: any, selectedItems: any[]) {
    return this.findIndexInSelection(item, selectedItems) != -1;
  }

  findIndexInSelection(item: any, selectedItems: any[]): number {
    return this.findIndexInList(item, selectedItems);
  }

  findIndexInList(item: any, list: any): number {
    let index: number = -1;

    if (list) {
      for (let i = 0; i < list.length; i++) {
        if (list[i] == item) {
          index = i;
          break;
        }
      }
    }

    return index;
  }

  handleChangetabview(e: any): void {
    this.HostAttributeGroupMappingForm.patchValue({ "tabview": e.index });
  }

  onClose($event: UntypedFormGroup): void {
    this.ref.close();
  }
  getTypeList(): void {
    this.selectList = [];
    this.pagesService.getAttributeTypeListing(this.appId).subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.attributeTypeName;
          data.value = element.id;
          this.selectList.push(data);
        });
        this.selectList = [...this.selectList];
      }
    });
  }

  getMappedDetails() {
    this.flag1 = true;
    this.HostAttributeGroupMappingForm.controls.sourceFilter.reset();
    this.pickList1targetList = [];
    this.pickList1List = [];
    this.pickList1List1 = [];
    this.pickList1targetList1 = [];
    const reqBody = {}
    reqBody['attributeTypeId'] = this.HostAttributeGroupMappingForm.value.select;
    reqBody['hostApplicationId'] = this.appId;
    reqBody['attributeGroupId'] = this.config?.data?.grpid;
    const formValues = reqBody;
    this.pagesService.getMappedHostAttributes(formValues).subscribe(results => {
      let responseData = [];
      if (results.hasOwnProperty("success")) {
        responseData = results.success.result;
      }
      else {
        responseData = [];
      }
      this.pickList1targetList = [];
      this.exMapList = [];
      if (responseData && responseData.length > 0) {
        this.flag1 = false;
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.pickList1targetList.push(data);
          this.exMapList.push(data.value);
        });
        this.pickList1targetList = [...this.pickList1targetList];
        this.pickList1targetList1 = [...this.pickList1targetList];
        this.exMapList = [...this.exMapList];
      }

    });


    this.pagesService.getUnmappedHostAttributes(formValues).subscribe(results => {
      const responseData = results?.success?.result;
      this.pickList1List = [];
      if (responseData && responseData.length > 0) {
        this.flag1 = false;
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.pickList1List.push(data);
        });
        this.pickList1List = [...this.pickList1List];
        this.pickList1List1 = [...this.pickList1List];
      }
    });
  }

  onSave() {
    this.mapList = this.pickList1targetList1;
    this.mList = [];
    this.mapList.forEach((element: any = {}) => {
      const data = {
        label: null,
        value: null,
      };
      data.label = element.label;
      data.value = element.value;
      this.mList.push(data.value);
    });
    this.mList = [...this.mList];
    const assignList = this.mList.filter(x => !this.exMapList.includes(x));
    const deassignList = this.exMapList.filter(x => !this.mList.includes(x));
    const reqBody = {};
    reqBody['applicationId'] = this.appId;
    reqBody['attributeGroupId'] = this.config?.data?.grpid;
    reqBody['mapList'] = assignList;
    reqBody['unmapList'] = deassignList;

    const formValues = reqBody;
    this.pagesService.onSaveMapUnmapHostAttributeGroup(formValues).subscribe(
      {
        next: results => {
          this.ref.close("Attributes mapping/unmapping saved successfully.");
        },
        error: () => {
          this.ref.close("Attributes mapping/unmapping  failed.");
        }
      });
  }

  getMappedDetailsTree() {
    // this.flag1 = true;
    // this.HostAttributeGroupMappingForm.controls.sourceFilter.reset();
    // this.pickList1targetList = [];
    // this.pickList1List = [];
    // this.pickList1List1 = [];
    // this.pickList1targetList1 = [];
    const reqBody = {}
    // reqBody['attributeTypeId'] = this.HostAttributeGroupMappingForm.value.select;
    reqBody['applicationId'] = this.appId;
    reqBody['attributeGroupId'] = this.config?.data?.grpid;
    const formValues = reqBody;
    this.pagesService.getMappedHostAttributesTree(formValues).subscribe(results => {
      let responseData = [];
      if (results.hasOwnProperty("success")) {
        responseData = results.success.result;
      }
      else {
        responseData = [];
      }
      console.log("Already maplist", responseData);
      this.selectedTreeRes = responseData;
      // this.pickList1targetList = [];
      // this.exMapList = [];
      if (responseData && responseData?.length > 0) {
        this.flag1 = false;
        responseData.forEach((element: any = {}) => {
          this.exMapListTree.push(element?.attributeId);
        });
        this.exMapListTree = [...this.exMapListTree];
      }

    });

    const reqBody2 = {};
    reqBody2['applicationId'] = this.appId;
    const formValues2 = reqBody2;
    this.pagesService.getUnmappedHostAttributesTree(formValues2).subscribe(results => {
      const responseData = results?.success?.result;
      this.treeList1List = [];
      var counter = 0;
      if (responseData && responseData?.length > 0) {
        this.flag1 = false;
        responseData.forEach((element: any = {}) => {
          counter = counter + 1;
          const treedata = {
            label: null,
            data: null,
            children: [],
            key: null
          };
          treedata.label = element.attributeResourceName;
          treedata.data = element.attributeId;
          treedata.key = counter;
          if (element?.children?.length > 0) {
            treedata.children = this.recursiveTreeData(element?.children, treedata.key);
          }
          console.log("703", treedata);
          this.treeList1List.push(treedata);
        });
        this.treeList1List = [...this.treeList1List];
        this.treeList1List1 = [...this.treeList1List];
        console.log(this.treeList1List);
        this.runForSelection(this.treeList1List);
        // this.multipleSelectionExpandAll();
      }
    });

  }

  recursiveTreeData(data: any, key: any): any {
    var treeResult = [];
    for (let i = 0; i < data?.length; i++) {
      const treedata = {
        label: null,
        data: null,
        children: null,
        key: null
      };
      treedata.label = data[i].attributeResourceName;
      treedata.data = data[i].attributeId;
      if (data[i].parentId == 0) {
        treedata.key = data[i].parentId;
      }
      else if (data[i].parentId > 0) {
        treedata.key = key + "-" + i;
      }
      if (data[i].children?.length > 0) {
        treedata.children = this.recursiveTreeData(data[i].children, treedata.key);
      }
      console.log(treedata);
      treeResult.push(treedata);
    };
    return treeResult;
  }


  onSaveTree() {
    // this.mapList = this.pickList1targetList1;
    this.mListTree = [];
    // this.exMapListTree = [];
    // this.mapList.forEach((element: any = {}) => {
    //   const data = {
    //     label: null,
    //     value: null,
    //   };
    //   data.label = element.label;
    //   data.value = element.value;
    //   this.mList.push(data.value);
    // });
    this.alreadyMappedTreeList.forEach(element => {
      // if (element?.children == null || element?.children?.length == 0) {
      this.mListTree.push(element.data);
      if (element?.parent?.partialSelected && !this.mListTree.includes(element?.parent?.data)) {
        this.mListTree.push(element?.parent?.data);
      }
      // }
    });
    this.mListTree = [...this.mListTree];
    const assignList = this.mListTree.filter(x => !this.exMapListTree.includes(x));
    const deassignList = this.exMapListTree.filter(x => !this.mListTree.includes(x));
    const reqBody = {};
    reqBody['applicationId'] = this.appId;
    reqBody['attributeGroupId'] = this.config?.data?.grpid;
    reqBody['mapList'] = assignList;
    reqBody['unmapList'] = deassignList;

    const formValues = reqBody;
    this.pagesService.onSaveMapUnmapHostAttributeGroup(formValues).subscribe(
      {
        next: results => {
          this.ref.close("Attributes mapping/unmapping saved successfully.");
          this.getMappedDetailsTree();
        },
        error: () => {
          this.ref.close("Attributes mapping/unmapping  failed.");
        }
      });
  }

  runForSelection(data: any) {
    data.forEach(element => {
      this.selectedTreeRes.forEach(element2 => {
        if (element?.label == element2?.attributeResourceName && element?.data == element2?.attributeId) {
          const treedata = {
            label: null,
            data: null,
            children: null,
            key: null
          };
          treedata.label = element.label;
          treedata.data = element.data;
          treedata.key = element.key;
          treedata.children = element.children;
          this.alreadyMappedTreeList.push(treedata);
        }
      });
      if (element?.children?.length > 0) {
        this.runForSelection(element?.children);
      }
    });
    console.log("already ammped tree ", this.alreadyMappedTreeList);
  }


}