<div style="background-color:#F6F8FA;">
    <div class="p-d-flex p-jc-end">
        <p-messages key="myKey1" class="custom-alert-box" *ngIf="this.messageFlag"></p-messages>
    </div>
    <div class="p-col-fixed p-mt-1 p-ml-1 p-pr-0 custom-breadcrumb">
        <p-breadcrumb [model]="ccprimebreadcrumbList" [home]="homeiconccprimebreadcrumb">
        </p-breadcrumb>
    </div>
    <form class="layout-content custom-form-layout" [formGroup]="ViewUserForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid p-mt-1">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left" style="position: relative;">
                <h5 class="custom-head1">View User</h5>
                <div style="height: auto;z-index: 1;position: absolute;width: 98%;">
                    <p-card class="cardBoxShadow">
                        <div class="p-grid p-mt-1" style="font-size:14px">
                            <div class="p-col-fixed p-p-0" style="width:80px">
                                <label class="i-icons userprofile-icon"></label>
                            </div>
                            <div class="p-col p-p-0" [style.width]="this.fullname.length > 30 ? 'auto' : '250px'">
                                <span style="font-size:16px;color: #3A435E;height:25px"
                                    pTooltip="{{this.fullname.length >= 30 ? this.fullname : ''}}"
                                    tooltipPosition="bottom" *ngIf="this.fullname!=null">
                                    <strong> <ng-container *ngIf="this.fullname.length > 30; else tooltip">
                                            {{this.fullname?.substring(0,30) +
                                            "..." }}
                                        </ng-container>
                                        <ng-template #tooltip>
                                            {{this.fullname}}
                                        </ng-template>
                                    </strong>
                                </span>
                                <span *ngIf="this.fullname==null">-</span>
                                <br>
                                <span style="line-height: 28px;">Created on: {{this.ViewUserForm.value.createdOn
                                    | date:'d MMM y'}}</span><br>
                                <span style="height:25px">Status:
                                    <div class="btnwrap">
                                        <button pButton type="button" label="{{this.ViewUserForm.value.statusMode}}"
                                            style="border-radius:32px;font-size: 11px;font-weight: 500;padding: 2px 8px;height:25px;box-shadow: none;"
                                            [style.color]="this.ViewUserForm.value.statusMode === 'ACTIVE' ? '#12813F' : this.ViewUserForm.value.statusMode === 'LOCKED' ? '#3A435E' : this.ViewUserForm.value.statusMode === 'STAGED' ? '#3A435E': this.ViewUserForm.value.statusMode === 'SUSPENDED' ? '#CA8208': this.ViewUserForm.value.statusMode === 'DEACTIVATED' ? '#D6002A': 'black'"
                                            [style.border]="this.ViewUserForm.value.statusMode === 'ACTIVE' ? '1px solid #CFE6D8' : this.ViewUserForm.value.statusMode === 'LOCKED' ? '1px solid #D3D5DB' : this.ViewUserForm.value.statusMode === 'STAGED' ? '1px solid #D3D5DB': this.ViewUserForm.value.statusMode === 'SUSPENDED' ? '1px solid #FDECCE': this.ViewUserForm.value.statusMode === 'DEACTIVATED' ? '1px solid #F7CCD4': '1px solid black'"
                                            class=" p-button-outlined  ">
                                        </button>
                                        <label tooltipPosition="right"
                                            *ngIf="this.ViewUserForm.value.statusMode=='SUSPENDED'"
                                            pTooltip="{{this.ViewUserForm.value.dormancyReason}}"
                                            class="alert-icon alert-circle-icon"></label>
                                    </div>
                                </span>
                            </div>
                            <div class="p-col p-p-0" style="min-width: 320px;">
                                <div>
                                    <div><label class="column v-icons mail-icon"></label>
                                    </div>
                                    <span class="custom-view">Email: </span>

                                    <span style="color: #3168DC;">{{this.ViewUserForm.value.primaryEmail}}</span>
                                </div>
                                <div>
                                    <div style="margin-top: 3px;"><label class="column v-icons userName-icon"
                                            style="margin-top: 3px"></label>
                                    </div>
                                    <span class="custom-view">Username:
                                    </span>

                                    <span class="usernameValues">{{this.ViewUserForm.value.userName}}</span>
                                </div>
                                <div style="height:25px;margin-top: 4px;">
                                    <div style="margin-top: -2px"><label class="column v1-icons userType-icon"
                                            style="margin-top: -2px;"></label>
                                    </div>
                                    <span class="custom-view" style="margin-left: -4px;">User type:
                                        <span class="custom-view usernameValues" *ngIf="userType==false">Internal</span>
                                        <span class="custom-view usernameValues" *ngIf="userType==true">External</span>
                                        <span class="custom-view" *ngIf="userType==null">-</span>
                                    </span>
                                </div>
                                <div *ngIf="this.pagesService.rebacFlag=='true'">
                                    <div style="float: inline-start;
                                    margin-right: 10px;">
                                        <!-- <label class="column v1-icons"
                                            style="margin-top: -2px;"></label>  -->
                                        <i class="icon-entity"></i>
                                    </div>
                                    <span class="custom-view">Account:
                                        <span
                                            class="custom-view usernameValues">{{this.ViewUserForm.value.account}}</span>
                                        <span class="custom-view" *ngIf="this.ViewUserForm.value.account==null">-</span>
                                    </span>
                                </div>
                            </div>
                            <div class="p-p-0" style="min-width: 200px;">
                                <div style="height:25px">
                                    <div class="column"><label class="v-icons contact-icon"></label>
                                    </div>
                                    <span class="custom-view">Contact number: </span>
                                    <span class="usernameValues">{{this.ViewUserForm.value.countryCode}}
                                        {{this.ViewUserForm.value.contactNumber}}</span>
                                    <span *ngIf="this.ViewUserForm.value.contactNumber==null">-</span>
                                </div>
                                <div style="height:25px">
                                    <div class="column" style="margin-left:-23px;margin-top: 3px;"><label
                                            class="v-icons userIdentity-icon"></label>
                                    </div>
                                    <span class="custom-view">User Identity: </span>
                                    <span class="usernameValues">{{this.userIdentity}}</span>
                                    <span class="usernameValues" *ngIf="this.userIdentity==null">-</span>

                                </div>
                                <div style="height:25px">
                                    <div class="column" *ngIf="rebacFlag=='true'"
                                        style="margin-left:-24px;margin-top: 2px;"><label
                                            class="v-icons userId-icon"></label>
                                    </div>
                                    <span class="custom-view" *ngIf="rebacFlag=='true'">User ID:
                                        <span class="custom-view usernameValues"
                                            *ngIf="this.umPkId!=null">{{this.umPkId}}</span>
                                        <span class="custom-view" *ngIf="this.umPkId==null">-</span>
                                    </span>
                                </div>
                            </div>
                            <div class="p-col-fixed p-text-right p-p-0" style="width:50px;">
                                <button #btn type="button" pButton icon="pi pi-ellipsis-v"
                                    (click)="menu.toggle($event);checkMenuOption(this.ViewUserForm.value.statusMode)"
                                    appendTo="body"
                                    style="background-color: #ffffff;border-color: #D3D5DB;border-radius: 4px;width:30px;height:30px;color: #3A435E;box-shadow: none;"
                                    [disabled]="this.pagesService.userManagementReadOnlyFlag"></button>
                                <p-tieredMenu #menu [model]="items" [popup]="true" appendTo="body">
                                </p-tieredMenu>
                                <p-confirmDialog [style]="{width: '45vw'}" [baseZIndex]="10000"
                                    acceptButtonStyleClass='p-button-brand2'
                                    rejectButtonStyleClass='p-button-secondary p-button-brand2' [baseZIndex]="10000">
                                </p-confirmDialog>
                            </div>
                        </div>
                        <div class="p-right" *ngIf="customList?.length>0 || attributeTableList?.length>0">
                            <button class=" p-button-outlined" pButton type="button" (click)="viewToggle()"
                                [icon]="viewToggleIcon" iconPos="right" label="{{viewToggleName}}"
                                style="font-size: 13px;height:25px;box-shadow: none; border:none;color: #3168DC;"></button>
                        </div>
                        <div [ngClass]="{'custom-details':true,'view-less':viewMore,'view-More':!viewMore}"
                            *ngIf="viewMore">
                            <div class="p-fluid p-formgrid p-grid p-mt-3">
                                <div class="p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6 p-offset-0 left"
                                    *ngIf="this.rebacFlag=='true'">
                                    <div>Configuration Details</div>
                                    <div class="mobilefriendlytable">
                                        <table aria-hidden="true" *ngIf="attributeTableList.length>0">
                                            <tbody>
                                                <tr
                                                    *ngFor="let field of attributeTableList;let index=index;let count=count">
                                                    <td style="font-size:13px;font-weight: 400;color: #505870;">
                                                        {{field.fieldName}}:</td>
                                                    <td style="width:15%"></td>
                                                    <td style="font-size:13px;font-weight: 500;color: #3A435E;">
                                                        {{field.fieldValue}}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <div *ngIf="attributeTableList.length==0">
                                            <span>No records found</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6 p-offset-0 left">
                                    <div>Custom Field Details</div>
                                    <div class="mobilefriendlytable">
                                        <table aria-hidden="true">
                                            <tbody>
                                                <tr
                                                    *ngFor="let customField of otherDetails;let index=index;let count=count">
                                                    <td style="font-size:13px;font-weight: 400;color: #505870;">
                                                        {{customField.label}}:</td>
                                                    <td style="width:15%"></td>
                                                    <td style="font-size:13px;font-weight: 500;color: #3A435E;">
                                                        {{customField.value}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </p-card>
                </div>
                <div style="margin-top: 35px;position: absolute;top: 179px;width: 98%;padding-bottom: 9%;">
                    <p-card class="cardBoxShadow">
                        <span style="font-size:14px;color: #3A435E;">Access Details</span>
                        <p-tabView>
                            <p-tabPanel *ngIf="this.pagesService.pageRebacFlag=='false'">
                                <ng-template pTemplate="header">
                                    <span class="label-badge">Role</span>
                                    <p-badge [value]="this.rolecount" styleClass="mr-2"></p-badge>
                                </ng-template>
                                <label *ngIf="effectiveRoleListFlag==false" style="margin-top: 14px;font-size: 15px;">
                                    No Roles Assigned!
                                </label>
                                <p-table *ngIf="effectiveRoleListFlag==true" #tableid [value]="tableList1" name="table"
                                    [columns]="tablecols" styleClass="p-datatable-customers" [rows]="5"
                                    [rowsPerPageOptions]="[5,10,15]" [paginator]="true" sortMode="single"
                                    [scrollable]="true">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th style="max-width:30%" pSortableColumn="roleName" scope="col">
                                                <div class="p-jc-between p-ai-center">
                                                    Role Name
                                                    <i class="p-sortable-column-icon icon-sort"
                                                        ariaLabel="Activate to sort"
                                                        ariaLabelDesc="Activate to sort in descending order"
                                                        ariaLabelAsc="Activate to sort in ascending order"
                                                        field="roleName"></i>
                                                    <p-columnFilter type="text" field="roleName" display="menu"
                                                        class="p-ml-auto"></p-columnFilter>
                                                </div>
                                            </th>
                                            <th pSortableColumn="newGroupList" scope="col">
                                                <div class="p-jc-between p-ai-center">
                                                    Roles Assigned From Group
                                                    <i class="p-sortable-column-icon icon-sort"
                                                        ariaLabel="Activate to sort"
                                                        ariaLabelDesc="Activate to sort in descending order"
                                                        ariaLabelAsc="Activate to sort in ascending order"
                                                        field="newGroupList"></i>
                                                    <p-columnFilter type="text" field="newGroupList" display="menu"
                                                        class="p-ml-auto"></p-columnFilter>
                                                </div>
                                            </th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-rowData let-index="rowIndex">
                                        <tr class="custom-td" [pSelectableRow]="rowData">
                                            <td style="max-width:30%; color:#3a435e;">
                                                <span *ngIf="rowData.roleName!=null">{{rowData.roleName}}</span>
                                                <span *ngIf="rowData.roleName==null">-</span>
                                            </td>
                                            <td style="color:#3a435e;">
                                                <span *ngIf="rowData.newGroupList!=null">{{rowData.newGroupList}}</span>
                                                <span *ngIf="rowData.newGroupList==''">-</span>
                                            </td>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="paginatorright" let-state>
                                        Total Records: {{state.totalRecords}}
                                    </ng-template>
                                    <ng-template pTemplate="emptymessage">
                                        <tr>
                                            <td colspan="7" class="p-p-0 p-text-center"> <p-card>
                                                    <div style="margin-top: 5%;height: 70px;">No
                                                        matching records found</div>
                                                </p-card> </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </p-tabPanel>
                            <p-tabPanel *ngIf="this.pagesService.pageRebacFlag=='false'">
                                <ng-template pTemplate="header">
                                    <span class="label-badge">Group</span>
                                    <p-badge [value]="this.groupcount" styleClass="mr-2"></p-badge>
                                </ng-template>
                                <p-chip *ngFor="let group of this.ViewUserForm.value.groups;" styleClass="mr-2 mb-2"
                                    class="custom-view-chip p-pr-2">{{group.displayName}}</p-chip>
                                <label *ngIf="this.ViewUserForm.value?.groups==null"
                                    style="margin-top: 14px;font-size: 15px;">
                                    No Groups Assigned!
                                </label>
                            </p-tabPanel>
                            <p-tabPanel *ngIf="this.pagesService.pageRebacFlag=='false'">
                                <ng-template pTemplate="header">
                                    <span class="label-badge">Application</span>
                                    <p-badge [value]="this.applicationCount" styleClass="mr-2"></p-badge>
                                </ng-template>
                                <label *ngIf="tablelistFlag==true" style="margin-top: 14px;font-size: 15px;">
                                    No Applications Assigned!
                                </label>
                                <p-table *ngIf="tablelistFlag==false" #tableid [value]="tableList" name="table"
                                    [columns]="tablecols" styleClass="p-datatable-customers" [rows]="10"
                                    [rowsPerPageOptions]="[10,25,50]" selectionMode="Multiple"
                                    (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
                                    [paginator]="true" sortMode="single" [scrollable]="true"
                                    [globalFilterFields]="['applicationName','applicationRoles',attributes]">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th id="headercheck" class="headerCheck" *ngIf="checkDisable">
                                                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                            </th>
                                            <th style="max-width:20%" pSortableColumn="applicationName" scope="col">
                                                <div class="p-jc-between p-ai-center">
                                                    Applications
                                                    <i class="p-sortable-column-icon icon-sort"
                                                        ariaLabel="Activate to sort"
                                                        ariaLabelDesc="Activate to sort in descending order"
                                                        ariaLabelAsc="Activate to sort in ascending order"
                                                        field="applicationName"></i>
                                                    <p-columnFilter type="text" field="applicationName" display="menu"
                                                        class="p-ml-auto"></p-columnFilter>
                                                </div>
                                            </th>
                                            <th style="max-width:40%" pSortableColumn="applicationRoles" scope="col">
                                                <div class="p-jc-between p-ai-center">
                                                    Effective Application Roles
                                                    <i class="p-sortable-column-icon icon-sort"
                                                        ariaLabel="Activate to sort"
                                                        ariaLabelDesc="Activate to sort in descending order"
                                                        ariaLabelAsc="Activate to sort in ascending order"
                                                        field="applicationRoles"></i>
                                                    <p-columnFilter type="text" field="applicationRoles" display="menu"
                                                        class="p-ml-auto"></p-columnFilter>
                                                </div>
                                            </th>
                                            <th style="max-width:40%" pSortableColumn="attributes" scope="col">
                                                <div class="p-jc-between p-ai-center">
                                                    Effective Attributes
                                                    <i class="p-sortable-column-icon icon-sort"
                                                        ariaLabel="Activate to sort"
                                                        ariaLabelDesc="Activate to sort in descending order"
                                                        ariaLabelAsc="Activate to sort in ascending order"
                                                        field="attributes"></i>
                                                    <p-columnFilter field="attributes" display="menu"
                                                        class="p-ml-auto"></p-columnFilter>
                                                </div>
                                            </th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-rowData let-index="rowIndex">
                                        <tr class="custom-td" [pSelectableRow]="rowData">
                                            <td style="max-width:20%"><strong>{{rowData.applicationName}}</strong></td>
                                            <td style="color:#3a435e;max-width:40%">
                                                <span
                                                    *ngFor="let c of rowData.applicationRoles;index as i;let last=last">
                                                    <span *ngIf="i<3">
                                                        {{c.label}}
                                                        <span *ngIf="!last">
                                                            {{', '}}
                                                        </span>
                                                    </span>
                                                </span>
                                                <p-tieredMenu #menu [model]="this['items1'+index]" [popup]="true"
                                                    appendTo="body" styleClass="p-tieredmenu-sm">
                                                </p-tieredMenu>
                                                <button *ngIf="this['len'+index]>0" (click)="menu.toggle($event);"
                                                    class=" p-button-outlined" pButton type="button"
                                                    label="+{{this['len'+index]}} more"
                                                    style="font-size: 13px;padding: 0px 8px;height:25px;box-shadow: none; border:none;color:#3168DC;"></button>
                                            </td>
                                            <td style="color:#3a435e;max-width:40%">
                                                <span *ngFor="let e of rowData.attributes;index as v;let last=last">
                                                    <span *ngIf="v<3">{{e.label}}<span *ngIf="!last">{{', '}}</span>
                                                    </span>
                                                </span>
                                                <span *ngIf="rowData.attributes=='' ">-</span>
                                                <p-tieredMenu #menu1 [model]="this['items2'+index]" [popup]="true"
                                                    appendTo="body" styleClass="p-tieredmenu-sm p-tieredMenu ul">
                                                </p-tieredMenu>
                                                <button *ngIf="this['attrLen'+index]>0" (click)="menu1.toggle($event);"
                                                    class=" p-button-outlined" pButton type="button"
                                                    label="+{{this['attrLen'+index]}} more"
                                                    style="font-size: 13px;padding: 0px 8px;height:25px;box-shadow: none; border:none;color:#3168DC;"></button>
                                            </td>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="emptymessage">
                                        <tr>
                                            <td colspan="7" class="p-p-0 p-text-center"> <p-card>
                                                    <div style="margin-top: 5%;height: 70px;">No
                                                        matching records found</div>
                                                </p-card> </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </p-tabPanel>
                            <p-tabPanel *ngIf="this.rebacFlag=='true'">
                                <ng-template pTemplate="header">
                                    <span class="label-badge">Entitlements</span>
                                    <p-badge [value]="this.entitlementCount" styleClass="mr-2"></p-badge>
                                </ng-template>

                                <div class="p-col-6 p-sm-6 p-md-6 p-lg-6 p-xl-6 p-offset-undefined left ">
                                    <!-- <div class="p-d-flex p-jc-end">
                                        <div class="inputtext-normal">
                                            <span class="p-input-icon-left">
                                                <i class="icon-search"></i>
                                                <input type="text" id="search-ent-bar" pInputText
                                                    placeholder="Search" (keyup.enter)="loadEntitlementData()"
                                                    [(ngModel)]="search" formControlName="entitlementSearch" />
                                            </span>
                                        </div>
                                        <button pButton type="button" icon="icon-filter"
                                            class="p-ml-2 p-button-brand2 p-mb-3" [disabled]="disableButtons"
                                            (click)="entilementfliter.toggle($event); openEntitlementFilterSideBar()"></button>
                                    </div> -->
                                    <!-- <div class="p-col p-p-0">
                                        <span class="p-input-icon-left p-mb-0">
                                            <i class="icon-search"></i>
                                            <input formControlName="entitlementSearch" pInputText id="ent-srch" type="text" placeholder="Search entitlement" (keyup)="searchEntitlement()"
                                              [(ngModel)]="search" class="search-text" />
                                          </span>
                                    </div> -->
                                </div>
                                <div class="p-col-6 p-sm-6 p-md-6 p-lg-6 p-xl-6 p-offset-undefined left">

                                </div>
                                <div class="p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3 p-offset-undefined left p-pr-0">
                                    <div class=" p-fluid   p-field    ">
                                        <span class=" p-input-icon-left">
                                            <input type="text" (keydown.enter)="$event.preventDefault()"
                                                id="textField_id" name="textField_name"
                                                formControlName="entitlementSearch" pInputText placeholder="Search"
                                                class="p-inputtext inputtextHeight  "
                                                (keyup)="AppFilterMethod($event.target.value)" style="width: 100%;" />
                                            <i aria-hidden="true" class="pi pi-search"></i>
                                        </span>
                                    </div>
                                </div>

                                <div class="p-grid">
                                    <div class="p-col-12 p-p-0 overflow-table-scroll">
                                        <p-table #dt1 [value]="entitlementList" [rowHover]="true" [scrollable]="true"
                                            styleClass="p-datatable-gridlines"
                                            [globalFilterFields]="['COMPONENT_NAME','ENTITLEMENT_NAME','OVERALL_STATUS','OWNER_ENTITLEMENT_NAME','COMPONENT_ID','ENTITLEMENT_ID','IS_ADMS_APPROVED']"
                                            class="entitlement-table custom-height custom-table ciam"
                                            [resizableColumns]="true" scrollHeight="240px">
                                            <!-- <ng-template pTemplate="caption">
                                                <div class="flex">
                                                    <span class="p-input-icon-left ml-auto">
                                                        <i class="pi pi-search"></i>
                                                        <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value,'contains')" placeholder="Search" />
                                                    </span>
                                                </div>
                                            </ng-template> -->
                                            <ng-template pTemplate="header" let-columns>
                                                <tr>
                                                    <th id="tableHeaderinfo" style="width: 33px;"></th>
                                                    <th scope="col" style="width: 100px;min-width: 100px;">Ent Id</th>
                                                    <th pSortableColumn="COMPONENT_NAME" scope="col"
                                                        style="width: 175px;min-width: 175px;">
                                                        <div class="p-jc-between p-ai-center">
                                                            Component Name
                                                            <i class="p-sortable-column-icon icon-sort"
                                                                ariaLabel="Activate to sort"
                                                                ariaLabelDesc="Activate to sort in descending order"
                                                                ariaLabelAsc="Activate to sort in ascending order"
                                                                field="COMPONENT_NAME"></i>
                                                            <p-columnFilter type="text" field="COMPONENT_NAME"
                                                                display="menu" class="p-ml-auto"></p-columnFilter>
                                                        </div>
                                                    </th>
                                                    <th pSortableColumn="ENTITLEMENT_NAME" scope="col"
                                                        style="width: 175px;min-width: 175px;">
                                                        <div class="p-jc-between p-ai-center">
                                                            Entitlement Name
                                                            <i class="p-sortable-column-icon icon-sort"
                                                                ariaLabel="Activate to sort"
                                                                ariaLabelDesc="Activate to sort in descending order"
                                                                ariaLabelAsc="Activate to sort in ascending order"
                                                                field="ENTITLEMENT_NAME"></i>
                                                            <p-columnFilter type="text" field="ENTITLEMENT_NAME"
                                                                display="menu" class="p-ml-auto"></p-columnFilter>
                                                        </div>
                                                    </th>
                                                    <th scope="col" style="width: 80px;min-width: 80px;">Source</th>
                                                    <th pSortableColumn="OVERALL_STATUS" scope="col"
                                                        style="width: 110px;min-width: 110px;">
                                                        <div class="p-jc-between p-ai-center">
                                                            Status
                                                            <i class="p-sortable-column-icon icon-sort"
                                                                ariaLabel="Activate to sort"
                                                                ariaLabelDesc="Activate to sort in descending order"
                                                                ariaLabelAsc="Activate to sort in ascending order"
                                                                field="OVERALL_STATUS"></i>
                                                            <p-columnFilter type="text" field="OVERALL_STATUS"
                                                                display="menu" class="p-ml-auto"></p-columnFilter>
                                                        </div>
                                                    </th>
                                                    <th pSortableColumn="OWNER_ENTITLEMENT_NAME" scope="col"
                                                        style="width: 150px;min-width: 150px;">
                                                        <div class="p-jc-between p-ai-center">
                                                            Ownership
                                                            <i class="p-sortable-column-icon icon-sort"
                                                                ariaLabel="Activate to sort"
                                                                ariaLabelDesc="Activate to sort in descending order"
                                                                ariaLabelAsc="Activate to sort in ascending order"
                                                                field="OWNER_ENTITLEMENT_NAME"></i>
                                                            <p-columnFilter type="text" field="OWNER_ENTITLEMENT_NAME"
                                                                display="menu" class="p-ml-auto"></p-columnFilter>
                                                        </div>
                                                    </th>
                                                    <th scope="col" style="width: 100px;min-width: 100px;">Approval Tree
                                                    </th>
                                                </tr>
                                                <tr *ngIf="!entitlementList.length > 0 && this.emptyFlag==0">
                                                    <td colspan="8" class="p-p-0 p-text-center">
                                                        <p-card>
                                                            <div style="margin-top: 6%;height: 70px;">
                                                                No
                                                                Entitlements added</div>
                                                        </p-card>
                                                    </td>
                                                </tr>
                                            </ng-template>

                                            <ng-template pTemplate="body" let-entitlement let-rowIndex="rowIndex">
                                                <tr [ngClass]="{'bg-grey': entitlement.ENTSTATUS == InactiveStatus}">
                                                    <td>
                                                        <span class="info-ent"
                                                            *ngIf="entitlement.ENTSTATUS == InactiveStatus"
                                                            pTooltip="Entitlement was deactivated by {{entitlement.LATEST_ACTION_BY}} on {{entitlement.LATEST_ACTION_DATE | dateFormatter}}"
                                                            tooltipPosition="top">
                                                            <label class="codi-icons codiconInfo-icon"></label>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span> <a routerLink=""
                                                                (click)="navigateToDetailScreen(entitlement)">{{entitlement.COMPONENT_ID
                                                                + '-' +
                                                                entitlement.ENTITLEMENT_ID }}</a>
                                                        </span>
                                                    </td>
                                                    <td>{{entitlement.COMPONENT_NAME}}</td>
                                                    <td class="att-values">
                                                        <span pTooltip="{{ entitlement.ENTITLEMENT_NAME }}"
                                                            class="clipTxt" style="vertical-align: bottom;"
                                                            tooltipPosition="top">
                                                            {{entitlement.ENTITLEMENT_NAME}}
                                                        </span>

                                                        <span
                                                            *ngIf="entitlement.IS_ADMS_ENTITLEMENT == 1"><b><i>&nbsp;ADMS</i></b>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <button pButton [disabled]="entitlement.IS_USER_DEFINED == 0"
                                                            [ngClass]="{'anchor-disabled': entitlement.IS_USER_DEFINED == 0  ? true : false }"
                                                            pTooltip="Raised independently"
                                                            class="action-img p-button-brand2 p-button-strict p-button-info p-ml-2 p-mr-2"
                                                            tooltipPosition="top" icon="icon-raisedIndependently">
                                                        </button>
                                                        <button pButton
                                                            class="action-img p-button-brand2 p-button-strict p-button-info"
                                                            icon="icon-multipleRoles" [pTooltip]="entitlement.SOURCE"
                                                            data-html="true" tooltipPosition="top"
                                                            [disabled]="!entitlement.SOURCE || entitlement.SOURCE.length == 0">
                                                        </button>
                                                    </td>
                                                    <td>
                                                        {{entitlement.OVERALL_STATUS}}
                                                    </td>
                                                    <td>
                                                        <span *ngIf="entitlement.OWNER_UM_ENTITLEMENT_ID">
                                                            {{entitlement.OWNER_ENTITLEMENT_NAME}}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            <span
                                                                *ngIf="entitlement.USER_TYPE_ID == 1 && entitlement.IS_ADMS_APPROVED == 1 && entitlement.IS_ADMS_ENTITLEMENT == 1"
                                                                tooltipPosition="top" pTooltip="ADMS Approved">
                                                                <label
                                                                    class="entApprovalTree-icons admsGreenIndi-icon"></label>
                                                            </span>
                                                            <span
                                                                *ngIf="entitlement.USER_TYPE_ID == 1 && entitlement.IS_ADMS_APPROVED == 0 && entitlement.IS_ADMS_ENTITLEMENT == 1"
                                                                tooltipPosition="top" pTooltip="ADMS Not Approved">
                                                                <label
                                                                    class="entApprovalTree-icons admsRedIndi-icon"></label>
                                                            </span>
                                                            &nbsp;
                                                            <span
                                                                *ngIf="(entitlement.IS_APPROVAL_REQUIRED && entitlement.IS_APPROVAL_REQUIRED===1) || (entitlement.APPROVAL_LEVELS)">
                                                                <span
                                                                    *ngFor="let approval of entitlement.APPROVAL_LEVELS">
                                                                    <span class="icon-circle p-mr-1"
                                                                        [ngStyle]="{'background-color': approval?.COLOR}"
                                                                        [pTooltip]=approval.TOOLTIP></span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </td>

                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="emptymessage">
                                                <tr *ngIf="this.emptyFlag==1">
                                                    <td colspan="8" class="p-p-0 p-text-center">
                                                        <p-card>
                                                            <div style="margin-top: 5%;height: 70px;">
                                                                No records found</div>
                                                        </p-card>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </div>
                                    <div class="legends p-mt-1">
                                        <span class="p-mr-3 legend-key">Key:</span>
                                        <span class="dot-for-approv p-mr-1"></span>
                                        <span class="p-mr-3" class="legend-title ml-1">Approved</span>
                                        <span class="dot-for-pending p-ml-3 p-mr-1"></span>
                                        <span class="p-mr-3" class="legend-title ml-1">Pending</span>
                                        <span class="dot-for-reject p-ml-3 p-mr-1"></span>
                                        <span class="p-mr-3" class="legend-title ml-1">Not Approved</span>
                                        <span class="dot-for-disable p-ml-3 p-mr-1"></span>
                                        <span class="p-mr-3" class="legend-title ml-1">Approval disabled</span>
                                        <span class="p-ml-3 icon-raisedIndependently"></span>
                                        <span class="p-mr-2" class="legend-title p-ml-1 source-img">Raised
                                            independently</span>
                                        <span class="p-ml-3 icon-multipleRoles"></span>
                                        <span class="p-ml-2" class="legend-title p-ml-1 source-img">Access through
                                            Roles</span>
                                    </div>
                                </div>
                            </p-tabPanel>
                            <p-tabPanel *ngIf="this.rebacFlag=='true'">
                                <ng-template pTemplate="header">
                                    <span class="label-badge">Application Roles</span>
                                    <p-badge [value]="this.entRoleCount" styleClass="mr-2"></p-badge>
                                </ng-template>

                                <div class="p-grid">
                                    <div class="p-col-12 p-p-0 overflow-table-scroll">
                                        <p-table #dt2 [columns]="userRoleColumns" [value]="userRoleList"
                                            [scrollable]="true" scrollHeight="240px" [rowHover]="true"
                                            [resizableColumns]="true"
                                            [globalFilterFields]="['ID','NAME', 'CREATED_DATE']"
                                            styleClass="p-datatable-gridlines" class="custom-height role-table"
                                            styleClass="p-datatable-gridlines">
                                            <ng-template pTemplate="caption">
                                                <div class="flex">
                                                    <span class="p-input-icon-left ml-auto">
                                                        <i class="pi pi-search"></i>
                                                        <input pInputText type="text"
                                                            (input)="dt2.filterGlobal($event.target.value, 'contains')"
                                                            placeholder="Search" />
                                                    </span>
                                                </div>
                                            </ng-template>
                                            <ng-template pTemplate="colgroup">
                                                <colgroup>
                                                    <col *ngFor="let col of userRoleColumns"
                                                        [style.width]="col.width" />
                                                </colgroup>
                                            </ng-template>

                                            <ng-template pTemplate="header" let-columns>
                                                <tr>
                                                    <th scope="col" *ngFor="let col of userRoleColumns"
                                                        [style.width]="col.width">
                                                        {{ col.header }}
                                                    </th>
                                                </tr>

                                            </ng-template>

                                            <ng-template pTemplate="body" let-role let-rowIndex="rowIndex">
                                                <tr>
                                                    <td>
                                                        <a routerLink=""
                                                            (click)="navigateToRoleDetailScreen(role)">{{role.ID}}</a>
                                                    </td>
                                                    <td class="att-values">{{ role.NAME }}</td>
                                                    <td style="position: relative;">

                                                        <span class="user-date">{{ role.CREATED_DATE
                                                            }}</span>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="emptymessage">
                                                <tr>
                                                    <td colspan="3" class="p-p-0 p-text-center">
                                                        <p-card>
                                                            <div style="margin-top: 5%;height: 70px;">
                                                                No records found</div>
                                                        </p-card>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </div>

                                </div>
                            </p-tabPanel>
                        </p-tabView>
                        <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right p-mb-3 p-mt-3"
                            *ngIf="this.pagesService.pageRebacFlag=='true' || this.pagesService.isMailAutoTrigger=='false'">
                            <div class="btnwrap">

                                <button pButton type="button" [pTooltip]="emailSendButtonTooltip" tooltipPosition="top"
                                    label={{emailSendButtonLabel}} class="p-mr-2 p-button-secondary p-button-sm"
                                    (click)="sendEmail()" [disabled]="this.pagesService.userManagementReadOnlyFlag">
                                </button>
                            </div>
                        </div>
                    </p-card>
                </div>
            </div>
        </div>
    </form>
</div>