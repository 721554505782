<div class="layout-content custom-container">
    <form [formGroup]="DeleteRoleForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                <div class=" p-fluid   p-field    ">
                    <p>Are you sure you want to delete the <strong>'{{fieldName}}'</strong> field?<br><br>
                        <em>Note: This field will be removed from entity screen and cannot be associated again.</em>
                    </p>
                </div>
            </div><br>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right custom-btn-height p-mb-2">
                <div class="btnwrap custom-save">
                    <button pButton type="button" class="p-button-brand2" (click)="onClickNo(DeleteRoleForm);"
                        label="No">
                    </button>
                </div>
                <div class="btnwrap">
                    <button pButton class="p-button-primary" type="button" (click)="deleteCustomField();" label="Yes">
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>