<div class="layout-content custom-container">
    <form [formGroup]="CreateApplicationRoleForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 left">
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                <div class=" p-fluid   p-field    ">
                    <label [ngClass]="{'field-required': CreateApplicationRoleForm.get('textField')?.errors?.required}">
                        Application Role Name
                        <span class='invalid-feedback-astrik'>*</span></label>
                    <input type="text" id="rolename_id" class="ng-invalid ng-dirty" name="textField_name"
                        formControlName="textField" placeholder="Application role name"
                        class="p-inputtext inputtextHeight" pattern="^([a-zA-Z0-9]+[-_\s])*[a-zA-Z0-9]+$" maxlength="50"
                        (keyup)="this.nameFlag=false;colorChange()" />
                    <div class="p-error"
                        *ngIf="CreateApplicationRoleForm.get('textField')!.invalid && (CreateApplicationRoleForm.get('textField')!.dirty || CreateApplicationRoleForm.get('textField')!.touched)">
                        <div *ngIf="CreateApplicationRoleForm.get('textField')?.errors?.required">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium class="p-error">Application Role name is required.</medium>
                        </div>
                        <div *ngIf="CreateApplicationRoleForm.get('textField').errors.pattern">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium class="p-error">Name should not start/end with special characters and Only Dash,
                                Underscore & space are allowed as special characters </medium>
                        </div>
                    </div>
                    <div class="p-error">
                        <label *ngIf="this.nameFlag" class="custom-codelabel">
                            <div>
                                <label class="alert-icon alert-circle-icon"></label>
                                <medium class="p-error">
                                    Application role name already exists!
                                </medium>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                <div class=" p-fluid   p-field    ">
                    <label>
                        Description
                    </label><textarea pInputTextarea id="textArea_id" name="textArea_name" formControlName="textarea"
                        rows="3" placeholder="Add description" maxlength="200"></textarea>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined p-left">
                <div class="p-field-checkbox p-mt-1">
                    <p-checkbox [(ngModel)]="checked" binary="true" inputId="binary"
                        formControlName="checkbox"></p-checkbox>
                    <label for="binary">Default Role</label>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-8 p-xl-8 p-offset-undefined p-right p-mb-2">
                <div class="btnwrap p-mr-3">
                    <button pButton type="button" class="p-button-brand2" (click)="onClose(CreateApplicationRoleForm);"
                        label="Close">
                    </button>
                </div>
                <div class="btnwrap">
                    <button pButton class="p-button-primary" id="save" type="button"
                        (click)="ApplicationRole(this.CreateApplicationRoleForm.value);"
                        [disabled]="this.nameFlag || !CreateApplicationRoleForm.valid" label="Save">
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>