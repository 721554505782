import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Message, MessageService, FilterService, TreeNode } from 'primeng/api';
import { PagesService } from '../pages.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { coerceStringArray } from '@angular/cdk/coercion';
@Component({
  selector: 'jhi-AddNextLevelHierarchyAttributes',
  templateUrl: './AddNextLevelHierarchyAttributes.component.html',
  styleUrls: ['./AddNextLevelHierarchyAttributes.component.scss'],
  providers: [MessageService, DialogService, FilterService],
})
export class AddNextLevelHierarchyAttributesComponent implements OnInit {
  AddNextLevelForm = this.fb.group({
    textField: [null, [Validators.required]],
    select1: [[], []],
    selectSwitch: [null, []],
    checkbox: [null, []],
    description: [null, []],
    AttributeId: [null, [Validators.required]],
  });
  pageloadvalues: any;
  ccprimebreadcrumbList = [{ label: `Tenant Management` },
  { label: `Tenant Settings` },
  ]
  homeiconccprimebreadcrumb = { icon: 'pi pi-home', routerLink: ['/'] };
  appId: any;
  resourceId: any;
  resourceName: any;
  attributeID: any;
  accessType: any;
  isDefault: any;
  description: any;
  attributeType: any;
  selectSwitchList: any[] = [{ 'label': "Public", 'value': 'false' }, { 'label': "Private", 'value': 'true' },];
  defaultselect_selectSwitch: any;

  selectList: any[] = [];
  select1List: any[] = [];

  enableBtn = false;
  nameFlag = false;
  errorMessage = "";
  validationFlag = false;
  checked: boolean = false;
  idFlag: boolean;
  searchStr: string;
  pageSize: number = 10; //default pageSize 10
  pageNumber: number = 1;
  sortByColumn = '';
  sorting = '';
  totalRecords: number = 0;
  oldTotalRec = 0;
  files!: TreeNode[];
  attributeList: any[] = [];
  childList: any[] = [];
  loading: boolean = false;
  parentId: any;
  trreKey: any = 0;
  attributeTypeName: any;
  attrTypeId: any;

  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public dialogService: DialogService,
    private cd: ChangeDetectorRef,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public pagesService: PagesService,
    private messageService: MessageService,
  ) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.AddNextLevelForm.patchValue(JSON.parse(data));
    }
    this.setDefaultValue_selectSwitch('true');

  }
  ngOnInit(): void {
    this.loading = true;
    this.resourceId = this.config?.data?.activeData.attributeId;
    this.parentId = this.config?.data?.activeData.attributeResourceId;
    this.appId = this.config?.data?.id;
    this.attributeTypeName = this.config?.data?.activeData.attributeTypeName;
    // console.log("config", this.config);
    this.getTypeList();
    this.getAttributeGroup();
    setTimeout(() => {
      this.onNodeExpandOnInit(this.parentId);
      this.loading = false;
      this.cd.markForCheck();
    }, 2000);
  }

  onClose($event: UntypedFormGroup): void {
    this.ref.close();
  }

  colorChange() {
    document.getElementById('textField_id1')!.style.borderColor = 'grey';
  }

  colorAttributeChange() {
    document.getElementById('AttributeId_id1')!.style.borderColor = 'grey';
  }


  //get type list API -dropdown
  getTypeList(): void {
    this.selectList = [];
    this.pagesService.getAttributeTypeListing(this.appId).subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          if (element.attributeTypeName == this.attributeTypeName) {
            this.attrTypeId = element.id;
          }
        });
      }
    });
  }

  //get attribute group list API -dropdown
  getAttributeGroup(): void {
    this.select1List = [];
    const reqBody = {};
    reqBody["applicationId"] = this.appId;
    this.pagesService.getAttributeGroupByHostApplication(reqBody).subscribe((results: any) => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.select1List.push(data);
        });
        this.select1List = [...this.select1List];
      }
    });
  }

  CreatehierarchyHostAttributeResource(): void {
    const reqBody = {};
    if (this.checked) {
      reqBody['isDefault'] = this.checked;
    }
    else {
      reqBody['isDefault'] = false;
    }
    let isPrivate = this.AddNextLevelForm.value.selectSwitch;
    if (isPrivate == "true") {
      reqBody['isPrivate'] = true;
    }
    else {
      reqBody['isPrivate'] = false;
    }
    reqBody['attributeTypeId'] = this.attrTypeId;
    reqBody['attributeResourceName'] = this.AddNextLevelForm.value.textField;
    reqBody['attributeGroupList'] = this.AddNextLevelForm.value.select1;
    reqBody['applicationId'] = this.appId;
    reqBody['attributeResourceId'] = this.AddNextLevelForm.value.AttributeId;
    reqBody['parentId'] = this.parentId;
    const formValues = reqBody;
    this.pagesService.onSaveCreateHostAttributeHierarchy(formValues).subscribe(
      {
        next: results => {
          this.ref.close("Attribute Added Successfully");
        },
        error: error => {
          if (error.error.error?.messageCode == "CIAM 105") {
            if (error.error?.error?.reason == "Attribute resource name already exist") {
              this.validationFlag = false;
              this.nameFlag = true;
              this.enableBtn = true;
              document.getElementById('textField_id1')!.style.borderColor = 'red';
            }
            else if (error.error?.error?.reason == "Attribute resource id already exist") {
              this.validationFlag = false;
              this.idFlag = true;
              this.enableBtn = true;
              document.getElementById('AttributeId_id1')!.style.borderColor = 'red';
            }
          }
          else if (error.error?.error?.messageCode == "[CIAM 115]") {
            this.validationFlag = true;
            this.nameFlag = false;
            this.enableBtn = true;
            document.getElementById('textField_id1')!.style.borderColor = 'red';
            this.errorMessage = error.error?.error?.reason;
          }
          else {
            this.ref.close("Attribute Addition Failed");
          }
        }
      });
  }

  setDefaultValue_selectSwitch(value?: any) {
    if (this.selectSwitchList.length && value) {
      this.defaultselect_selectSwitch = value;
      return this.defaultselect_selectSwitch;
    }
    this.defaultselect_selectSwitch = this.selectSwitchList[0]?.value
    return this.defaultselect_selectSwitch;
  }

  onNodeExpandOnInit(resourceId: any) {
    this.loading = true;
    // const node = event.node;
    // const node = event.node;
    let reqBody = {};
    reqBody['applicationId'] = this.appId;
    reqBody['page'] = 1;
    reqBody['rows'] = this.pageSize;
    reqBody['searchString'] = this.searchStr;
    reqBody['sortByColumn'] = this.sortByColumn;
    reqBody['parentId'] = resourceId;
    reqBody['sorting'] = this.sorting;
    const data = reqBody;
    this.pagesService.getAttributeListByApplicationIdLazy(data).subscribe(results => {
      const responseData = results?.success?.result?.records;
      // this.attributeTotalRecords = results.success.result.recordsCount;
      this.oldTotalRec = results?.success?.result?.recordsCount;
      // if (responseData && responseData.length > 0) {
      //   this.attributeList = responseData;
      //   // this.files=this.attributeList;
      //   this.childList = responseData;
      //   this.treeLazy(event);
      //   // this.errorFlag = false;
      // }
      // console.log("res", responseData);
      if (responseData && responseData?.length > 0) {
        responseData.forEach((element: any = {}) => {
          // console.log("element", element.data);
          const treedata = {
            label: null,
            data: null,
            key: null,
            leaf: false
          };
          treedata.label = element.data.attributeResourceName;
          treedata.data = element.data.attributeResourceId;
          treedata.key = this.trreKey;
          // console.log("703", treedata);
          this.attributeList.push(treedata);
        });
        this.attributeList = [...this.attributeList];
        this.attributeList = [...this.attributeList];
        // console.log(this.attributeList);
      }
    });
  }

  nodeExpand(event: any) {
    this.loading = true;
    // const node = event.node;
    // const node = event.node;
    // console.log(event);
    let reqBody = {};
    reqBody['applicationId'] = this.appId;
    reqBody['page'] = 1;
    reqBody['rows'] = this.pageSize;
    reqBody['searchString'] = this.searchStr;
    reqBody['sortByColumn'] = this.sortByColumn;
    reqBody['parentId'] = event.node.data;
    reqBody['sorting'] = this.sorting;
    const data = reqBody;
    if (!event.node.children) {
      this.loading = true;
      setTimeout(() => {
        event.node.children = [];
        this.pagesService.getAttributeListByApplicationIdLazy(data).subscribe(results => {
          const responseData = results?.success?.result?.records;
          // this.attributeTotalRecords = results.success.result.recordsCount;
          this.oldTotalRec = results?.success?.result?.recordsCount;
          // if (responseData && responseData.length > 0) {
          //   this.attributeList = responseData;
          //   // this.files=this.attributeList;
          //   this.childList = responseData;
          //   this.treeLazy(event);
          //   // this.errorFlag = false;
          // }
          // console.log("res", responseData);
          if (responseData && responseData?.length > 0) {
            for (let i = 0; i < responseData.length; i++) {
              // responseData.forEach((element: any = {}) => {
              // console.log("element", responseData[i].data);
              const treedata = {
                label: null,
                data: null,
                key: null,
                leaf: false
              };
              treedata.label = responseData[i].data.attributeResourceName;
              treedata.data = responseData[i].data.attributeResourceId;
              treedata.key = event.node.key;
              // console.log("703", treedata);
              event.node.children.push(treedata);
            };
            this.loading = false;
            // this.attributeList = [...this.attributeList];
            // this.attributeList = [...this.attributeList];
            // console.log(this.attributeList);
          }
          else {
            event.node.children = [];
            this.loading = false;
            event.node.leaf = true;
          }
        });
        // console.log(this.attributeList);
        this.cd.markForCheck();
      }, 500);
    }
  }


}
