import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { PagesService } from '../pages.service';
import { MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'jhi-AddAccountParameter',
  templateUrl: './AddAccountParameter.component.html',
  styleUrls: ['./AddAccountParameter.component.scss'],
  providers: [MessageService, DatePipe],
})
export class AddAccountParameterComponent implements OnInit {
  AddAccountParameterForm = this.fb.group({
    // orgNameInput: [null, [Validators.required]],
    fieldName: [null, [Validators.required]],
    fieldInputType: [null, [Validators.required]],
    inputValueFormat: [null, []],
    inputValueFormattext: [null, []],
    inputValueFormatDropdown: [null, []],
    MaxCharacters: [null, []],
    mandatory: [false, []],
    specialCharacter: [null, []],
    inputValueFormatdemo1: [null, []],

  });
  ExistingParameterForm = this.fb.group({
    existingParameter: [null, []],
  });
  pageloadvalues: any;
  nameFlag: boolean = false;
  enabledbtn1: boolean = false;
  inputFlag: boolean = false;
  maxlengthFlag: boolean = false;
  rangeFlag: boolean = false;
  selectList: any[] = [];
  selectList1: any[] = [];
  selectList2: any[] = [];
  SelectTreevalue: any[] = [];
  fieldtype: any[] = [];
  inputStep: boolean = true;
  inputformat: any[] = [];
  inputtypeId: any;
  checkedmandatory: boolean = false;
  inputType: any;
  checkbox: boolean = false;
  dropdownvalues: any[];
  entitylabel: any;
  fieldlabel: any;
  defaultFlag: boolean = true;
  treedata: any[] = [];
  input: any;
  specialFlag: boolean = false;
  formatData: any;
  SpecialCharacter: { label: string; value: string; }[];
  Selectspecial: any;
  specialcharlabel: any[] = [];
  SelectedEntity: string;
  errorFlag2: boolean = true;
  existingParameterList: any[] = [];
  existingParameter: any[] = [];
  customList: any[] = [];
  newParamterDuplicityarr: any[] = [];
  checkBoxArr: any;
  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public ref1: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public pagesService: PagesService,
    private messageService: MessageService,
    public datepipe: DatePipe
  ) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.AddAccountParameterForm.patchValue(JSON.parse(data));
    }

  }
  toggle = true;
  status = 'Enable';
  enableDisableRule() {
    this.toggle = !this.toggle;
    this.status = this.toggle ? 'Enable' : 'Disable';
  }

  ngOnInit(): void {
    this.customList = this.config.data.customList;
    this.checkBoxArr = this.config.data.checkBoxArr;
    this.newParamterDuplicityarr = [];
    this.customList.forEach(element => {
      if (element.isExisting) {
        const data = {
          fieldName: null,
          fieldLabel: null,
          mandatory: null,
          MaxCharacters: null,
          fieldValue: null,
          specialCharacter: null,
          inputvalue_format: null,
          isExisting: false,
        };

        data.fieldName = element.fieldName;
        data.fieldLabel = element.fieldInputtype;
        data.mandatory = element.mandatoryField;
        data.MaxCharacters = element.maxCharacters;
        data.specialCharacter = element?.specialChar;
        data.inputvalue_format = element?.inputFormat;
        data.fieldValue = "";
        data.isExisting = element.isExisting;
        this.existingParameter.push(data);
      }
      this.newParamterDuplicityarr.push(element.fieldName);
    });

    this.checkBoxArr.forEach(element => {
      this.newParamterDuplicityarr.push(element.fieldName);
    });

    this.getExistingParameter();
    this.getinputFieldTypeList();
    this.AddAccountParameterForm.get('inputValueFormat').disable();
  }
  onSaveExistingParameter() {
    this.ref.close(this.existingParameter);
  }
  getExistingParameter(): void {
    this.existingParameterList = [];
    this.pagesService.getExistingParamter().subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.fieldname;
          data.value = JSON.parse(element.jsonTemplate);
          if (!element.configurable && !element.userSpecific) {
            this.existingParameterList.push(data);
          }

        });
        this.existingParameterList = [...this.existingParameterList];
      }
    });
  }
  errorState() {
    if (this.AddAccountParameterForm.value.existingParameter != null || this.AddAccountParameterForm.value.existingParameter != "") {
      this.errorFlag2 = false;
    }
  }
  colorChange() {
    document.getElementById('fieldName_id')!.style.borderColor = 'grey';
  }

  specialcharacter() {
    this.formatData = this.AddAccountParameterForm.value.inputValueFormat;
    //cannot use foreach and break
    for (let i = 0; i < this.formatData?.length; i++) {
      if (this.formatData[i] == "Special charecters") {
        this.specialFlag = true;
        this.AddAccountParameterForm.controls['inputValueFormatdemo1'].setValidators(Validators.required);
        this.AddAccountParameterForm.controls['inputValueFormatdemo1'].updateValueAndValidity();
        break;
      }
      else {
        this.specialFlag = false;
        this.AddAccountParameterForm.controls['inputValueFormatdemo1'].clearValidators();
        this.AddAccountParameterForm.controls['inputValueFormatdemo1'].reset();
      }
    }
  }

  onClose(): void {
    this.ref.close(this.existingParameter);
  }

  onSaveAddCustomField() {
    const reqBody = {};
    reqBody['active'] = true;
    reqBody['fieldName'] = this.AddAccountParameterForm.value.fieldName;
    this.fieldtype.forEach(element => {
      if (element.value == this.AddAccountParameterForm.value.fieldInputType) {
        this.fieldlabel = element.label;
      }
    });
    reqBody['fieldLabel'] = this.fieldlabel;
    if (this.AddAccountParameterForm.value.fieldInputType == 'ec6becd6-3d6c-11ed-b878-0242ac120002') {
      //text
      this.treedata = this.SelectTreevalue;
      reqBody['inputtype_id'] = this.AddAccountParameterForm.value.fieldInputType;
      reqBody['inputvalue_format'] = this.treedata;
      if (this.specialFlag) {
        this.specialcharlabel = [];
        this.Selectspecial.forEach(element1 => {
          this.SpecialCharacter.forEach(element2 => {
            if (element2.value == element1) {
              this.specialcharlabel.push(element2);
            }
          });
        });
        reqBody['specialCharacter'] = this.specialcharlabel;
      }
      reqBody['MaxCharacters'] = this.AddAccountParameterForm.value.MaxCharacters;
      if (this.AddAccountParameterForm.value.mandatory == null) {
        reqBody['mandatory'] = false;
      }
      else {
        reqBody['mandatory'] = this.AddAccountParameterForm.value.mandatory;
      }

    }
    else if (this.AddAccountParameterForm.value.fieldInputType == '0c004e6a-3d82-11ed-b878-0242ac120002') {
      //textarea
      this.treedata = this.SelectTreevalue;
      reqBody['inputvalue_format'] = this.treedata;
      if (this.specialFlag) {
        this.specialcharlabel = [];
        this.Selectspecial.forEach(element1 => {
          this.SpecialCharacter.forEach(element2 => {
            if (element2.value == element1) {
              this.specialcharlabel.push(element2);
            }
          });
        });
        reqBody['specialCharacter'] = this.specialcharlabel;
      }
      reqBody['inputtype_id'] = this.AddAccountParameterForm.value.fieldInputType;
      reqBody['inputvalue_format'] = this.treedata;
      reqBody['MaxCharacters'] = this.AddAccountParameterForm.value.MaxCharacters;
      if (this.AddAccountParameterForm.value.mandatory == null) {
        reqBody['mandatory'] = false;
      }
      else {
        reqBody['mandatory'] = this.AddAccountParameterForm.value.mandatory;
      }

    }
    else if (this.AddAccountParameterForm.value.fieldInputType == '9ac9c6e2-3e38-11ed-b878-0242ac120002') {
      //dropdown
      reqBody['inputtype_id'] = this.AddAccountParameterForm.value.fieldInputType;
      const dropdownformat = this.AddAccountParameterForm.value.inputValueFormattext.split(',');
      this.dropdownvalues = [];
      dropdownformat.forEach(element => {
        const dropDownData = { "label": element, "value": element }
        this.dropdownvalues.push(dropDownData);
      });
      this.dropdownvalues = [...this.dropdownvalues];

      reqBody['inputvalue_format'] = this.dropdownvalues;
      if (this.AddAccountParameterForm.value.mandatory == null) {
        reqBody['mandatory'] = false;
      }
      else {
        reqBody['mandatory'] = this.AddAccountParameterForm.value.mandatory;
      }

    }
    else if (this.AddAccountParameterForm.value.fieldInputType == 'fc7400af-c135-4fda-b7b5-747dd3f6263d') {
      //range
      reqBody['inputtype_id'] = this.AddAccountParameterForm.value.fieldInputType;
      reqBody['inputvalue_format'] = [this.AddAccountParameterForm.value.inputValueFormatDropdown];
      if (this.AddAccountParameterForm.value.mandatory == null) {
        reqBody['mandatory'] = false;
      }
      else {
        reqBody['mandatory'] = this.AddAccountParameterForm.value.mandatory;
      }
    }
    else if (this.AddAccountParameterForm.value.fieldInputType == 'f959e7b2-3d81-11ed-b878-0242ac120002') {
      reqBody['inputtype_id'] = this.AddAccountParameterForm.value.fieldInputType;
    }
    reqBody['isExisting'] = false;
    const formValues = [];
    formValues.push(reqBody);
    this.ref.close(formValues);
  }

  getinputFieldTypeList(): void {
    this.fieldtype = [];
    this.pagesService.getFieldType().subscribe(results => {

      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
            type: null
          };
          data.label = element.field_lable;
          data.value = element.field_id;
          data.type = element.field_type;
          this.fieldtype.push(data);
        });
        this.fieldtype = [...this.fieldtype];
      }
    });
  }

  onSaveCheckFieldName(): void {
    const reqBody = this.AddAccountParameterForm.value?.fieldName;
    const formData = reqBody;
    const formValues = this.AddAccountParameterForm.value?.fieldName;
    const EntityId = this.SelectedEntity;
    this.pagesService.onCheckFieldName(formData, formValues, EntityId).subscribe({
      next:
        results => {
          if (results?.error?.code == 400 || results?.success?.code == 400) {
            this.nameFlag = true;
            document.getElementById('fieldName_id')!.style.borderColor = 'red';
          } else if (results.success.code == 200) {
            this.nameFlag = false;
            document.getElementById('fieldName_id')!.style.borderColor = 'grey';
            this.onSaveAddCustomField();
          }
        },
      error: () => {

      }
    });
  }

  getInputformat(): void {
    this.inputtypeId = this.AddAccountParameterForm.value.fieldInputType;
    this.inputformat = [];
    this.pagesService.getInputformat(this.inputtypeId).subscribe(results => {
      const responseData = results?.success?.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
            children: []
          };
          data.label = element.label;
          data.value = element.label;
          if (element?.children?.length > 0) {
            data.children = element.children;
          }
          this.inputformat.push(data);
        });
        this.inputformat = [...this.inputformat];
      }
    });
    this.specialcharacter();
  }

  getSpecialCharacter(): void {
    this.SpecialCharacter = [];
    this.pagesService.getInputformat(this.inputtypeId).subscribe(results => {
      const responseData = results?.success?.result[2].children;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null
          };
          data.label = element.label;
          data.value = element.value;
          this.SpecialCharacter.push(data);
        });
        this.SpecialCharacter = [...this.SpecialCharacter];
      }
    });
  }

  fieldtypevalidate() {
    this.input = this.AddAccountParameterForm.value.fieldInputType;
    this.fieldtype.forEach(element => {
      if (element.value == this.input) {
        this.inputType = element.type;
      }
    });

    if (this.inputType == 'text') {
      //text field
      this.treedata = [];
      this.maxlengthFlag = true;
      this.inputFlag = false;
      this.rangeFlag = false;
      this.AddAccountParameterForm.controls['inputValueFormatDropdown'].clearValidators();
      this.AddAccountParameterForm.controls['inputValueFormattext'].clearValidators();
      this.AddAccountParameterForm.controls['inputValueFormat'].reset();
      this.AddAccountParameterForm.controls['MaxCharacters'].reset();
      this.AddAccountParameterForm.controls['mandatory'].reset();
      this.AddAccountParameterForm.controls['MaxCharacters'].reset();
      this.AddAccountParameterForm.controls['inputValueFormatDropdown'].reset();
      this.AddAccountParameterForm.controls['inputValueFormattext'].reset();
      this.AddAccountParameterForm.controls['inputValueFormat'].setValidators(Validators.required);
      this.AddAccountParameterForm.controls['MaxCharacters'].setValidators(Validators.required);
      this.AddAccountParameterForm.controls['inputValueFormat'].updateValueAndValidity();
      this.AddAccountParameterForm.controls['MaxCharacters'].updateValueAndValidity();
      this.AddAccountParameterForm.controls['mandatory'].updateValueAndValidity();
      this.AddAccountParameterForm.controls['inputValueFormatDropdown'].updateValueAndValidity();
      this.AddAccountParameterForm.controls['inputValueFormattext'].updateValueAndValidity();
      document.getElementById('check')!.style.pointerEvents = 'auto';
      document.getElementById('check')!.style.opacity = '1';

    }
    else if (this.inputType == 'text_area') {
      //text area
      this.treedata = [];
      this.maxlengthFlag = true;
      this.inputFlag = false;
      this.rangeFlag = false;
      this.checkbox = false;
      this.AddAccountParameterForm.controls['inputValueFormatDropdown'].clearValidators();
      this.AddAccountParameterForm.controls['inputValueFormattext'].clearValidators();
      this.AddAccountParameterForm.controls['inputValueFormat'].reset();
      this.AddAccountParameterForm.controls['MaxCharacters'].reset();
      this.AddAccountParameterForm.controls['inputValueFormatDropdown'].reset();
      this.AddAccountParameterForm.controls['inputValueFormattext'].reset();
      this.AddAccountParameterForm.controls['mandatory'].reset();
      this.AddAccountParameterForm.controls['inputValueFormat'].setValidators(Validators.required);
      this.AddAccountParameterForm.controls['MaxCharacters'].setValidators(Validators.required);
      this.AddAccountParameterForm.controls['inputValueFormat'].updateValueAndValidity();
      this.AddAccountParameterForm.controls['MaxCharacters'].updateValueAndValidity();
      this.AddAccountParameterForm.controls['mandatory'].updateValueAndValidity();
      this.AddAccountParameterForm.controls['inputValueFormatDropdown'].updateValueAndValidity();
      this.AddAccountParameterForm.controls['inputValueFormattext'].updateValueAndValidity();
    }
    else if (this.inputType == 'checkbox') {
      this.inputFlag = false;
      this.maxlengthFlag = false;
      this.rangeFlag = false;
      this.checkbox = true;
      this.AddAccountParameterForm.controls['inputValueFormatDropdown'].clearValidators();
      this.AddAccountParameterForm.controls['inputValueFormattext'].clearValidators();
      this.AddAccountParameterForm.controls['inputValueFormat'].clearValidators();
      this.AddAccountParameterForm.controls['MaxCharacters'].clearValidators();
      this.AddAccountParameterForm.controls['inputValueFormatDropdown'].reset();
      this.AddAccountParameterForm.controls['inputValueFormattext'].reset();
      this.AddAccountParameterForm.controls['inputValueFormat'].reset();
      this.AddAccountParameterForm.controls['MaxCharacters'].reset();
      this.AddAccountParameterForm.controls['mandatory'].reset();
      this.AddAccountParameterForm.controls['inputValueFormat'].updateValueAndValidity();
      this.AddAccountParameterForm.controls['MaxCharacters'].updateValueAndValidity();
      this.AddAccountParameterForm.controls['mandatory'].updateValueAndValidity();
      this.AddAccountParameterForm.controls['inputValueFormatDropdown'].updateValueAndValidity();
      this.AddAccountParameterForm.controls['inputValueFormattext'].updateValueAndValidity();
    }
    else if (this.inputType == 'dropdown') {
      //dropdown
      this.inputFlag = true;
      this.maxlengthFlag = false;
      this.rangeFlag = false;
      this.checkbox = false;
      this.AddAccountParameterForm.controls['inputValueFormat'].clearValidators();
      this.AddAccountParameterForm.controls['inputValueFormatDropdown'].clearValidators();
      this.AddAccountParameterForm.controls['MaxCharacters'].clearValidators();
      this.AddAccountParameterForm.controls['inputValueFormat'].reset();
      this.AddAccountParameterForm.controls['MaxCharacters'].reset();
      this.AddAccountParameterForm.controls['mandatory'].reset();
      this.AddAccountParameterForm.controls['inputValueFormatDropdown'].reset();
      this.AddAccountParameterForm.controls['inputValueFormattext'].setValidators(Validators.required);
      this.AddAccountParameterForm.controls['inputValueFormattext'].updateValueAndValidity();
      this.AddAccountParameterForm.controls['MaxCharacters'].updateValueAndValidity();
      this.AddAccountParameterForm.controls['inputValueFormat'].updateValueAndValidity();
      this.AddAccountParameterForm.controls['inputValueFormatDropdown'].updateValueAndValidity();
      this.AddAccountParameterForm.controls['mandatory'].updateValueAndValidity();
    }
    else if (this.inputType == 'input_field_range') {
      this.AddAccountParameterForm.controls['inputValueFormat'].clearValidators();
      this.AddAccountParameterForm.controls['MaxCharacters'].clearValidators();
      this.AddAccountParameterForm.controls['inputValueFormattext'].clearValidators();
      this.AddAccountParameterForm.controls['inputValueFormat'].reset();
      this.AddAccountParameterForm.controls['MaxCharacters'].reset();
      this.AddAccountParameterForm.controls['inputValueFormattext'].reset();
      this.AddAccountParameterForm.controls['mandatory'].reset();
      this.AddAccountParameterForm.controls['inputValueFormatDropdown'].setValidators(Validators.required);
      this.AddAccountParameterForm.controls['inputValueFormatDropdown'].updateValueAndValidity();
      this.AddAccountParameterForm.controls['MaxCharacters'].updateValueAndValidity();
      this.AddAccountParameterForm.controls['inputValueFormat'].updateValueAndValidity();
      this.AddAccountParameterForm.controls['inputValueFormattext'].updateValueAndValidity();
      this.AddAccountParameterForm.controls['mandatory'].updateValueAndValidity();
      this.rangeFlag = true;
      this.inputFlag = false;
      this.maxlengthFlag = false;
      this.checkbox = false;
    }
  }

  checkInput(event: any) {
    if (this.AddAccountParameterForm.value.fieldInputType != null) {
      this.AddAccountParameterForm.get('inputValueFormat').enable();
    }
    if (this.newParamterDuplicityarr != null) {
      this.newParamterDuplicityarr.forEach(element => {
        if (element.toLowerCase() == this.AddAccountParameterForm.value.fieldName.toLowerCase()) {
          this.nameFlag = true;
        }
      });
    }
  }


}
