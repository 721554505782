import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PagesService } from '../pages.service';
import { MenuItem, MessageService } from 'primeng/api';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { CreateTenantComponent } from '../CreateTenant/CreateTenant.component';
import { EditTenantComponent } from '../EditTenant/EditTenant.component';
import { ApplicationTenantMappingComponent } from '../ApplicationTenantMapping/ApplicationTenantMapping.component';
@Component({
  selector: 'jhi-TenantListing',
  templateUrl: './TenantListing.component.html',
  styleUrls: ['./TenantListing.component.scss'],
  providers: [MessageService, DialogService],
})
export class TenantListingComponent implements OnInit {
  ref!: DynamicDialogRef;
  TenantListingForm = this.fb.group({
    textField: [null, []],
  });
  first = 0;

  rows = 10;
  messageFlag = true;
  isVisible = false;
  activeItem: any;
  GroupCount: any;
  UserCount: any;
  pageloadvalues: any;
  ccprimebreadcrumbList = [{ label: `Tenant Management` }, { label: `Tenants` }];
  homeiconccprimebreadcrumb = { icon: 'pi pi-home', routerLink: ['/'] };
  htmlSrc: any;
  searchList: any[] = [];
  tableList: any[] = [];
  tableList1: any[] = [];
  errorFlag: boolean = true;
  tablecols: any[] = [
    { field: 'name', header: 'Name' },
    { field: 'description', header: 'Description' },
    { field: 'users', header: 'Users' },
    { field: 'groups', header: 'Groups' },
    { field: 'policies', header: 'Policies' },
    { field: 'status', header: 'Status' },
  ];
  table: any[] = [];
  Hoveritems!: MenuItem[];
  selectedIndex: any;
  hoverIndex: any;
  deletemsg: any;
  appId: any;
  compId: any;
  roleId: any;
  activeItem1: any;
  fromChildToParent: any;
  constructor(
    public dialogService: DialogService,
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    private messageService: MessageService
  ) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    this.deletemsg = navigation?.extras?.state?.msg;
    if (this.deletemsg) {
      this.messageFlag = true;

    }
    if (data) {
      this.TenantListingForm.patchValue(JSON.parse(data));
    }
  }
  ngOnInit(): void {
    this.htmlSrc = '';
    this.gettableList();
  }

  gettableList(): void {
    this.pagesService.getTenantList().subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        this.tableList = responseData;
        this.tableList1 = responseData;
        this.errorFlag = false;
      } else {
        this.errorFlag = true;
      }
    });
  }
  TenantFilterMethod(event: any) {
    let filtered: any[] = [];
    let items: any;
    let cc;
    let query = event;
    if (query.length > 0) {
      this.tableList1.forEach(element => {
        items = element;
        if (items.tenantName?.toLowerCase().includes(query.toLowerCase()) || items.tenantDomain?.toLowerCase().includes(query.toLowerCase()) || items.fullName?.toLowerCase().includes(query.toLowerCase()) || items.email?.toLowerCase().includes(query.toLowerCase()) || items.userCount?.toLowerCase().includes(query.toLowerCase())) {
          filtered.push(items);
        }
      });
      this.tableList = filtered;
    }
    else {
      this.tableList = this.tableList1;
    }
  }
  onCcprimebutton($event: UntypedFormGroup): void { }

  highlight(index: any) {
    this.selectedIndex = index;
  }
  resetinviteUserccprimesplitbutton(): void {
    this.TenantListingForm.reset();
  }
  createtenant() {
    this.ref = this.dialogService.open(CreateTenantComponent, {
      data: {},
      header: 'Add New Tenant',
      width: '45%',
      contentStyle: { 'max-height': '516px', overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;

      if (msg == 'Tenant Details Saved Successfully') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.gettableList();
      }
      else if (msg == 'Tenant Creation Failed' || msg == 'Tenant name already exist') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
      else if (this.pagesService.createTenantSave) {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'Tenant Details Saved Successfully.' });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.gettableList();
        this.pagesService.createTenantSave = false;
      }
    });

  }
  mouseLeave() {
    this.hoverIndex = null;
    this.selectedIndex = null;
  }
  editTenant() {
    this.ref = this.dialogService.open(EditTenantComponent, {
      data: { editTenantName: this.activeItem1.tenantName, editUserId: this.activeItem1.userId, editEmail: this.activeItem1.email, editTenantDomain: this.activeItem1.tenantDomain, editTenantId: this.activeItem1.tenantId },
      header: 'Edit Tenant',
      width: '45%',
      contentStyle: { 'max-height': '516px', overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (this.pagesService.createTenantSave) {
        this.gettableList();
        this.pagesService.createTenantSave = false;
      }
      if (msg == 'Tenant Details Saved Successfully') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.gettableList();
      }
      else if (msg == 'Tenant Edit Failed' || msg == 'Tenant name already exist') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }

    });
  }
  mapUnmapApplication() {
    this.ref = this.dialogService.open(ApplicationTenantMappingComponent, {
      data: { mapTenantName: this.activeItem1.tenantName, mapTenantId: this.activeItem1.tenantId },
      header: 'Map/ Unmap',
      width: '65%',
      contentStyle: { 'max-height': '516px', overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Application mapping/unmapping saved successfully') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
      else if (msg == 'Application mapping/unmapping failed') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }

    });
  }
}
