import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PagesService } from '../pages.service';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';

@Component({
  selector: 'jhi-RegenerateToken',
  templateUrl: './RegenerateToken.component.html',
  styleUrls: ['./RegenerateToken.component.scss'],
  providers: [DialogService],
})
export class RegenerateTokenComponent implements OnInit {
  RegenerateTokenForm = this.fb.group({
    select: [null, [Validators.required]],
    textField: [null, [Validators.required]],
  });
  pageloadvalues: any;
  selectList: any[] = [];
  select1List: any[] = [];
  appId: any;
  constructor(private router: Router, protected activatedRoute: ActivatedRoute, private fb: UntypedFormBuilder, public pagesService: PagesService, public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.RegenerateTokenForm.patchValue(JSON.parse(data));
    }

  }
  ngOnInit(): void {
    this.appId = this.config?.data?.id;
  }
  onCcprimebutton1($event: UntypedFormGroup): void {
    this.ref.close();
  }
  onCcprimebutton2($event: UntypedFormGroup): void {
  }

}