<div class="layout-content"
    style="min-height:0vh;box-shadow: none;border:none;padding-bottom: 0px;margin-bottom: auto;">
    <form [formGroup]="RejectTransactionForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                <div class=" p-fluid   p-field    ">
                    <p>Are you sure you want to approve transaction ID <strong>{{this.transactionId}}</strong> request?
                    </p>
                    <label>Comments
                        <span class='invalid-feedback-astrik'>*</span>
                    </label><br>
                    <textarea style="border-color:#D3D5DB;font-size: 12px;width: 100%;" label="Comments" name="Comments"
                        formControlName="textarea" id="comment" cols="94" rows="3"></textarea>
                    <div class="p-error"
                        *ngIf="RejectTransactionForm.get('textarea')!.invalid && (RejectTransactionForm.get('textarea')!.dirty || RejectTransactionForm.get('textarea')!.touched)">
                        <div *ngIf="RejectTransactionForm.get('textarea')?.errors?.required">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium class="p-error">Comment is required.</medium>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right">
                <div class="btnwrap custom-save">
                    <button pButton type="button" class="p-button-brand2" (click)="onClose(RejectTransactionForm);"
                        label="Close">
                    </button>
                </div>
                <div class="btnwrap">
                    <button pButton class="p-button-primary" type="button"
                        (click)="onSaveRejectRequest(RejectTransactionForm);" label="Reject"
                        [disabled]="!RejectTransactionForm.valid">
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>