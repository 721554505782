import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PagesService } from '../pages.service';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { Clipboard } from '@angular/cdk/clipboard'

@Component({
  selector: 'jhi-TokenGenerated',
  templateUrl: './TokenGenerated.component.html',
  styleUrls: ['./TokenGenerated.component.scss'],
  providers: [DialogService],
})
export class TokenGeneratedComponent implements OnInit {
  TokenGeneratedForm = this.fb.group({
    select: [null, [Validators.required]],
    textField: [null, [Validators.required]],
  });
  pageloadvalues: any;
  selectList: any[] = [];
  select1List: any[] = [];
  appId: any;
  constructor(private router: Router, protected activatedRoute: ActivatedRoute, private fb: UntypedFormBuilder, public pagesService: PagesService, public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private clipboard: Clipboard) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.TokenGeneratedForm.patchValue(JSON.parse(data));
    }

  }
  ngOnInit(): void {
    this.appId = this.config?.data?.id;
  }
  onCcprimebutton1($event: UntypedFormGroup): void {
    this.ref.close();
  }
  onCcprimebutton2($event: UntypedFormGroup): void {
  }
  copyText() {
    this.clipboard.copy(this.TokenGeneratedForm.value.select);
  }
}