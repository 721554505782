<div class="layout-content custom-container">
    <form [formGroup]="EditAtrributeForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class=" p-col-12 p-p-0">
                <div class="ui-fluid p-formgrid p-grid">
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                        <div class=" p-fluid   p-field    ">
                            <label [ngClass]="{'field-required': EditAtrributeForm.get('select2')?.errors?.required}">
                                Type
                                <span class='invalid-feedback-astrik'>*</span></label>
                            <div class="p-fluid  p-field">
                                <p-dropdown [filter]="true" filterBy="label" id="selectid" name="select2"
                                    placeholder="Select" [options]="selectList" [autoDisplayFirst]="false"
                                    formControlName="select2" [(ngModel)]="this.attriType" maxlength="50"
                                    (onChange)="enableBtn1=false">
                                </p-dropdown>
                            </div>
                            <div class="p-error"
                                *ngIf="EditAtrributeForm.get('select2')!.invalid && (EditAtrributeForm.get('select2')!.dirty || EditAtrributeForm.get('select2')!.touched)">
                                <div *ngIf="EditAtrributeForm.get('select2')?.errors?.required">
                                    <label class="alert-icon alert-circle-icon"></label>
                                    <medium class="p-error">Type is required.</medium>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                        <div class=" p-fluid   p-field    ">
                            <label [ngClass]="{'field-required': EditAtrributeForm.get('textField')?.errors?.required}">
                                Name
                                <span class='invalid-feedback-astrik'>*</span></label>
                            <input [(ngModel)]="resourceName" type="text" id="textField_id1" name="textField_name"
                                formControlName="textField" pInputText placeholder="Enter name"
                                class="p-inputtext inputtextHeight  " maxlength="255"
                                pattern="^[a-zA-Z]+([ ]*[)a-zA-Z0-9-_(])*$"
                                (keyup)="enableBtn1=false;this.nameFlag=false;onColorchange()" />
                            <div class="p-error"
                                *ngIf="EditAtrributeForm.get('textField')!.invalid && (EditAtrributeForm.get('textField')!.dirty || EditAtrributeForm.get('textField')!.touched)">
                                <div *ngIf="EditAtrributeForm.get('textField')?.errors?.required">
                                    <label class="alert-icon alert-circle-icon"></label>
                                    <medium class="p-error">
                                        Name is required.</medium>
                                </div>
                                <div *ngIf="EditAtrributeForm.get('textField').errors.pattern">
                                    <label class="alert-icon alert-circle-icon"></label>
                                    <span class="p-error">Name should not start with special characters and Only Dash, Underscore,space, open and close bracket are allowed as special characters.</span>
                                </div>
                            </div>
                            <div class="invalid-feedback">
                                <label *ngIf="this.nameFlag">
                                    <div>
                                        <label class="alert-icon alert-circle-icon"></label>
                                        <medium class="p-error">Attribute Name already exists.
                                        </medium>
                                    </div>
                                </label>
                            </div>
                            <div class="invalid-feedback">
                                <label *ngIf="this.validationFlag">
                                    <div>
                                        <label class="alert-icon alert-circle-icon"></label>
                                        <medium class="p-error">{{errorMessage.substring(1,errorMessage.length-1)}}
                                        </medium>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                        <div class=" p-fluid   p-field    ">
                            <label>
                                Group
                            </label>
                            <div class="p-fluid  p-field">
                                <p-multiSelect id="select1id" name="select1" placeholder="No group selected"
                                    optionLabel="label" optionValue="value" [options]="select1List"
                                    formControlName="select1" [disabled]="true" [(ngModel)]="this.selectedGroupList">
                                    <ng-template let-each pTemplate="selectedItem">
                                        {{each.label}}
                                    </ng-template>
                                    <ng-template let-item pTemplate="each">
                                        <div class="drop-item" style="display: inline;">
                                            {{item.label}}
                                        </div>
                                    </ng-template>
                                </p-multiSelect>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">

                        <div class="p-fluid">
                            <label>
                                Access Type
                            </label>
                            <label tooltipPosition="right"
                                pTooltip="Public: Open access to application attributes (Assigned)<br> Private: Exclusive/Self access"
                                class="alert-icon alert-info-icon" [escape]="false"></label>
                            <div class="p-field p-fluid">
                                <p-selectButton id="selectSwitch_id" name="selectSwitch_name"
                                    [(ngModel)]="defaultselect_selectSwitch" [options]="selectSwitchList"
                                    formControlName="selectSwitch" styleClass="default" (onChange)="enableBtn1=false">
                                    <ng-template let-item>
                                        <span class="p-button-label">{{item.label}}</span>
                                    </ng-template>
                                </p-selectButton>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined p-left">
                        <div class="p-field-checkbox" style="margin-top: 32px;">
                            <p-checkbox [(ngModel)]="checked" binary="true" inputId="binary" formControlName="checkbox"
                                (onChange)="enableBtn1=false"></p-checkbox>
                            <label for="binary" style="font-size: 0.9rem;">Default Attribute</label>
                        </div>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right p-mt-1 p-mb-2">
                        <div class="btnwrap p-mr-3">
                            <button pButton type="button" class="p-button-brand2" (click)="onClose(EditAtrributeForm);"
                                label="Close">
                            </button>
                        </div>
                        <div class="btnwrap">
                            <button pButton class="p-button-primary" type="button"
                                [disabled]=" validationFlag || enableBtn1 || nameFlag || !EditAtrributeForm.valid"
                                (click)="onSaveCheckAttributeName(EditAtrributeForm);" label="Save">
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>