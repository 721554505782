<!--  Success Toast -->
<div style="background-color:#F6F8FA;margin-bottom: 25px;">
    <div class="p-d-flex p-jc-end">
        <p-messages key="myKey1" class="custom-alert-box" *ngIf="this.messageFlag"></p-messages>
    </div>
    <div class="p-col-fixed p-mt-1 p-ml-1 p-pr-0 custom-breadcrumb">
        <p-breadcrumb [model]="ccprimebreadcrumbList" [home]="homeiconccprimebreadcrumb">
        </p-breadcrumb>
    </div>


    <div style="padding: 10px 24px;">
        <div class="p-grid">
            <span class="p-col p-p-0">
                <h5 class="custom-head1">Roles Details</h5>
            </span>
        </div>
        <div class="user-header-details">
            <div class="p-col p-p-0 p-m-0 p-text-left">
                <span class="userDetailsFormat">
                    {{role.ROLE_NAME}}
                </span>
                <span class="changeUserTitle changeUser">
                    <p-dropdown class="d-inline-block" [(ngModel)]="selectedRole" [options]="roleList"
                        optionLabel="NAME" placeholder="Change role" [filter]="true" titleData="Change role"
                        pTooltip="Change role" tooltipPosition="top"
                        [style]="{ border: 'none', 'text-decoration': 'underline', color: '#375889' }"
                        [dropdownIcon]="false" (onChange)="unSaveChangesPopUp()" filterPlaceholder="Select Role">
                    </p-dropdown>
                </span>
                <div class="p-grid p-ai-center">
                    <div pTooltip="Role ID" class="user-id-details">{{role.PRIME_ROLE_ID}}</div>
                    <p-divider layout="vertical"></p-divider> <span class="user-header-txt">{{role.ROLE_STATUS}}</span>

                </div>
            </div>
        </div>

        <div>
            <p-overlayPanel #opImportRole [showCloseIcon]="true" [style]="{width: '200px'}" styleClass="overLay"
                (onShow)="loadImportRole()" #op>
                <div>
                    <div class="p-grid p-mb-3" style="position: relative; left: 11px;">
                        <p-dropdown [(ngModel)]="selectedRoleToImport" [options]="roleList" optionLabel="NAME"
                            optionValue="ID" showClear="true" placeholder="search role" [filter]="true">
                        </p-dropdown>
                    </div>
                    <div class="p-grid p-mb-3">
                        <div class="p-col-md-6">
                            <button type="button" pButton (click)="checkRoleData()" icon="pi pi-check" label="import"
                                class="ui-button-raised ui-button-success pull-right importselection"></button>
                        </div>
                        <div class="p-col-md-6">
                            <button type="button" pButton (click)="cancelImport($event, op)" label="Cancel"
                                class="ui-button-raised ui-button-danger pull-right importRoleCancel"></button>
                        </div>
                    </div>
                </div>
            </p-overlayPanel>
        </div>



        <div class="p-col-md-12 role-use-tab">
            <div class="p-grid p-ai-center user-sidetext p-d-flex p-jc-between">
                <div class="p-mr-3 p-d-flex p-ai-center">
                    <p class="userAtr p-mb-0 p-mr-2">Users</p>
                    <span><a [routerLink]="" (click)="addUser()" class="add-box-input"><u>+ Add New</u></a></span>
                </div>
                <div>
                    <button pButton type="button" icon="icon-filter" class="p-button-brand2"
                        (click)="userFilter.toggle($event);openFilterSideBar()"></button>
                </div>
            </div>
            <div class="p-grid" *ngIf="appliedUserFilter.length > 0">
                <div class="p-col p-pl-0">
                    <div class="p-ai-center">
                        <label>Filters Applied: </label>

                        <p-chip *ngFor="let element of appliedUserFilter"
                            (click)="setUserActiveTab('user');userFilter.toggle($event)" class="p-mr-2"
                            styleClass="p-chip-lg p-chip-blue medium-text" label="{{element.label}}">
                        </p-chip>

                        <span><label class="p-mb-0" (click)="clearAllUserFilters()"><a>Clear all</a></label></span>
                    </div>
                </div>
            </div>


            <div class="p-grid">
                <div class="p-col-12 p-pl-0 p-pr-0">
                    <p-table #dt [value]="usersList" [rowHover]="true" styleClass="p-datatable-gridlines"
                        [scrollable]="true" scrollHeight="241px" [resizableColumns]="true"
                        class="custom-height role-User">
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th scope="col">User Id</th>
                                <th scope="col">User Name</th>
                                <th scope="col">Updated On</th>
                                <th scope="col">User Status</th>
                            </tr>
                            <tr *ngIf="isAddNewUser" class="ent-bg">
                                <td style="width:28%">
                                    <div class="p-fluid p-mr-2">

                                        <label for="component" class="p-col-12 p-mb-0 p-mr-0 p-p-0 p-ml-3">User</label>
                                        <div class="p-ml-3 p-mb-3">
                                            <p-multiSelect name="user" [options]="addUserDropdown"
                                                [(ngModel)]="selectedUser" class="custom-chips custom-check"
                                                defaultLabel="Select User" appendTo="body" optionLabel="NAME_WITH_ID"
                                                maxSelectedLabels=0 [filter]="true" filterPlaceholder="Select User"
                                                (onChange)="isUserExist=false;">
                                                <ng-template let-each pTemplate="selectedItem">
                                                    {{each.NAME_WITH_ID}}
                                                </ng-template>
                                                <ng-template let-items pTemplate="item">
                                                    <div *ngIf="items?.NAME_WITH_ID?.length <= 35;">
                                                        {{items.NAME_WITH_ID}}
                                                    </div>
                                                    <div *ngIf="items?.NAME_WITH_ID?.length > 35;"
                                                        pTooltip="{{items.NAME_WITH_ID}}" tooltipPosition="bottom">
                                                        {{items?.NAME_WITH_ID?.substring(0,35) + "..." }}
                                                    </div>
                                                </ng-template>
                                            </p-multiSelect>
                                        </div>

                                    </div>
                                </td>
                                <td class="group-button-col p-ml-3 p-mt-3">
                                    <label for="service" class="p-col-sm-12 p-col-form-label form-control-sm"></label>
                                    <button pButton label="Add" class="p-mt-2" (click)="addUsersToRoleTable()"
                                        [ngClass]="{'anchor-disabled':selectedUser.length == 0 || isUserExist}"></button>

                                    <button pButton label="Discard"
                                        class="p-mt-2 p-button-secondary custom-discard p-ml-2"
                                        (click)="discardUser()"></button>
                                </td>
                                <td></td>
                                <td></td>

                            </tr>
                            <tr *ngIf="usersList.length == 0" class="p-jc-center">
                                <td colspan="4" class="p-p-0 p-text-center">
                                    <p-card>
                                        <div style="margin-top: 5%;height: 70px;">Click on 'Add new' to
                                            add
                                            user</div>
                                    </p-card>
                                </td>

                            </tr>

                        </ng-template>
                        <ng-template pTemplate="body" let-usersList let-rowIndex="rowIndex">
                            <tr>

                                <td class="role-Userid">
                                    <a routerLink="" (click)="navigateFirstName(usersList)">{{usersList.USER_ID}}</a>
                                </td>
                                <td>
                                    {{usersList.USER_NAME}}
                                </td>
                                <td>
                                    {{usersList.UPDATED_ON | dateFormatter}}
                                </td>
                                <td class="p-d-flex">
                                    {{usersList.STATUS}}

                                    <div class="desktop">
                                        <div class="button-details custom-hover-button">
                                            <button pButton type="button" class="p-button-strict p-button-info p-ml-5"
                                                pTooltip="Delete" icon="icon-trash" tooltipPosition="bottom"
                                                (click)="deleteRoleUserPopUp(usersList,rowIndex)">
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>


        <br>
        <br>
        <div class="p-grid sidetext p-ai-center">
            <div class="p-col-md-auto p-mr-3">
                <p class="userAtr p-mb-0">Entitlements</p>
            </div>

            <div>
                <span><a [routerLink]="" (click)="addEntitlement()" class="add-box-input"><u>+ Add
                            Entitlement</u></a></span>
            </div>


        </div>

        <div class="p-grid"
            *ngIf="appliedComponentFilter.length > 0 || appliedEntitlementFilter.length > 0 || appliedEntitlementStatusFilter.length > 0">
            <div class="p-col p-pl-0">
                <div class="p-ai-center">
                    <label>Filters Applied: </label>

                    <p-chip *ngFor="let status of appliedComponentFilter" (click)="setActiveEntitTab('COMPONENT')"
                        class="p-mr-2" styleClass="p-chip-lg p-chip-blue medium-text" label="{{status.label}}">
                    </p-chip>

                    <p-chip *ngFor="let type of appliedEntitlementFilter" (click)="setActiveEntitTab('ENTITLEMENT')"
                        class="p-mr-2" styleClass="p-chip-lg p-chip-blue medium-text" label="{{type.label}}">
                    </p-chip>

                    <p-chip *ngFor="let type of appliedEntitlementStatusFilter"
                        (click)="setActiveEntitTab('ENTITLEMENT_STATUS')" class="p-mr-2"
                        styleClass="p-chip-lg p-chip-blue medium-text" label="{{type.label}}">
                    </p-chip>

                    <span><label class="p-mb-0" (click)="clearAllEntitlementFilters()"><a>Clear
                                all</a></label></span>
                </div>
            </div>
        </div>


        <div class="p-grid">
            <div class="p-col-12 p-pl-0 p-pr-0">
                <p-table [value]="entitlementList" [rowHover]="true" styleClass="p-datatable-gridlines"
                    [scrollable]="true" scrollHeight="304px" [resizableColumns]="true"
                    class="custom-height role-User custom-table ciam">
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th id="tableheaderinfo2" style="max-width: 32px;"></th>
                            <th id="entID" class="role-Entid">Ent Id</th>
                            <th id="compName">Component Name</th>
                            <th id="entName">Entitlement Name</th>
                            <th id="updatedOn">Updated On</th>
                            <th id="entStatus">Entitlement Status</th>
                        </tr>
                        <tr *ngIf="isAddNewEntitlement" class="ent-bg p-p-3">
                            <td style="width:10px"></td>
                            <td style="width:28%">
                                <div class="p-fluid p-mr-2">
                                    <label for="component" class="p-col-12 p-mb-0 p-mr-0 p-p-0 p-ml-3">Component
                                        Name<span class="text-danger"> * </span></label>
                                    <div class="p-ml-3 p-mb-3">
                                        <p-dropdown [options]="componentDropdownList" [(ngModel)]="selectedComponent"
                                            optionLabel="COMP_NAME_WITH_ID" appendTo="body"
                                            placeholder="Select Component" (onChange)="getEntitlementData()"
                                            [style]="{ width: '18rem' }">
                                        </p-dropdown>
                                    </div>
                                </div>
                            </td>
                            <td style="width:28%">
                                <div class="p-fluid p-mr-2">
                                    <label for="ent" class="p-col-12 p-mb-0 p-mr-0 p-p-0 p-ml-3">Entitlement
                                        Name<span class="text-danger"> * </span></label>
                                    <div class="p-ml-3 p-mb-3">

                                        <p-multiSelect [options]="entitlementDropdownList"
                                            [(ngModel)]="selectedEntitlement" placeholder="Select Entitlement"
                                            optionLabel="NAME_WITH_ID" maxSelectedLabels=0 [filter]="true"
                                            appendTo="body" [disabled]="!isEntitlementDropdown"
                                            [style]="{ width: '18rem' }" (onChange)="isEntitlementExist=false">
                                            <ng-template let-entitlement pTemplate="item">
                                                <span *ngIf="entitlement.NAME_WITH_ID.length > 53; else other_content">
                                                    <span title="{{entitlement.NAME_WITH_ID}}"
                                                        class="over-content-entitlement-list title">{{entitlement.NAME_WITH_ID}}</span>
                                                </span>
                                                <ng-template #other_content>
                                                    <span
                                                        [ngClass]="{'text-grey': entitlement.STATUS === InactiveStatus}"
                                                        [pTooltip]="entitlement.STATUS === InactiveStatus ? 'Entitlement was deactivated by ' + entitlement.LATEST_ACTION_BY + ' on ' + (entitlement.LATEST_ACTION_DATE | dateFormatter) : null">
                                                        {{ entitlement.NAME_WITH_ID }}
                                                    </span>
                                                </ng-template>
                                            </ng-template>
                                        </p-multiSelect>
                                    </div>
                                </div>
                            </td>
                            <td class="group-button-col p-ml-3 p-mt-3 p-d-flex" style="width:max-content">
                                <button pButton label="Add Entitlement" class="p-mt-2" (click)="addEntitlementToTable()"
                                    [ngClass]="{'anchor-disabled':selectedEntitlement.length == 0 || isEntitlementExist}"></button>

                                <button pButton label="Discard" class="p-button-secondary custom-discard p-ml-2 p-pt-3"
                                    (click)="discardEntitlement()"></button>
                            </td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr *ngIf="!entitlementList.length > 0" class="p-jc-center">
                            <td colspan="6" class="p-p-0 p-text-center">
                                <p-card>
                                    <div style="margin-top: 5%;height: 70px;">
                                        Click on 'Add Entitlement'
                                        to
                                        add
                                        new
                                        entitlement</div>
                                </p-card>

                            </td>

                        </tr>
                        <tr *ngIf="isEntitlementExist">
                            <td colspan="5" class="warn-selection text-center">
                                <label class="l-icons info-img imgAttention"></label>
                                Entitlement
                                Already
                                Exists
                            </td>
                        </tr>
                    </ng-template>
                    <br>
                    <ng-template pTemplate="body" let-entitlement let-rowIndex="rowIndex">
                        <tr [ngClass]="{'bg-grey': entitlement.ENTITLEMENT_STATUS_NAME == InactiveStatus}">
                            <td style="max-width:32px">
                                <span class="info-ent" *ngIf="entitlement.ENTITLEMENT_STATUS_NAME == InactiveStatus"
                                    pTooltip="Entitlement was deactivated by {{entitlement.LATEST_ACTION_BY}} on {{entitlement.LATEST_ACTION_DATE | dateFormatter }}"
                                    tooltipPosition="top">
                                    <label class="codi-icons codiconInfo-icon"></label>

                                </span>
                            </td>
                            <td class="role-Entid">
                                <span class="table-text-align">
                                    <a routerLink=""
                                        (click)="navigateToEntDetailScreens(entitlement)">{{entitlement.COMPONENT_ID
                                        +
                                        '-' +
                                        entitlement.ENTITLEMENT_ID}}
                                    </a>
                                </span>
                            </td>
                            <td>
                                {{entitlement.COMPONENT_NAME}}
                            </td>
                            <td>
                                {{entitlement.ENTITLEMENT_NAME}}
                                <span *ngIf="entitlement.IS_ADMS_ENTITLEMENT == 1"><b><i> ADMS</i></b> </span>
                            </td>
                            <td>
                                {{entitlement.UPDATED_ON | dateFormatter}}
                            </td>
                            <td class="p-d-flex">
                                {{entitlement.STATUS_NAME}}
                                <div class="desktop">
                                    <div class="button-details custom-hover-button">
                                        <button pButton type="button" class="p-button-strict p-button-info p-ml-5"
                                            pTooltip="Delete" icon="icon-trash" tooltipPosition="bottom"
                                            (click)="deleteRoleEntitlementPopUp(entitlement,rowIndex)">
                                        </button>
                                    </div>
                                </div>
                            </td>


                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>


        <div class="user-details-footer-wrapper p-mb-0 p-mt-6">
            <div></div>
            <div class="p-grid p-mt-2">
                <div class="p-d-flex">
                    <div class="p-mt-3">
                        <label class="l-icons last-saved" style="margin: 0px;"></label>
                    </div>
                    <div class="p-p-2 p-mt-2">
                        <p class="saveDetails">Last Saved at {{lastUpdatedTime}}</p>
                    </div>
                    <div class="p-mt-2">
                        <button pButton type="submit" label="Back" class="p-button-secondary"
                            (click)="navigateToRoleListingScreen()"></button>
                    </div>
                    <div class="p-pl-2 p-pt-2 p-pb-2">
                        <button type="submit" pButton label="Save & Apply"
                            [ngClass]="{'anchor-disabled':isSaveAndApplyBtnDisabled}"
                            (click)="updateRoleDetails(true)"></button>
                    </div>
                </div>
            </div>
        </div>



        <p-dialog header="Confirm?" [modal]="true" [(visible)]="isConfirmDialog" [style]="{width: '30vw'}"
            [baseZIndex]="10000">
            <div class="p-grid p-fluid p-0">
                <div class="p-col-12 p-md-12 text-center">
                    <label class="l-icons attention-img"></label>
                    <br>
                    <label class="customlabel mb-0">Override Warning!</label>
                    <p>{{confirmMessage}}</p>
                </div>
            </div>
            <p-footer>
                <button pButton type="button" label="Cancel" (click)="cancelConfimDialog()"
                    class="ui-button-raised ui-button-danger btnCancel"></button>
                <button pButton type="button" label="Continue" class="ui-button-raised btnDelete"
                    (click)="onActionConfirmationClick()"></button>

            </p-footer>
        </p-dialog>

        <p-dialog header="Confirm?" [modal]="true" [(visible)]="isImportConfirmDialog" [style]="{width: '30vw'}"
            [baseZIndex]="10000">
            <div class="p-grid p-fluid p-0">
                <div class="p-col-12 p-md-12 text-center">
                    <label class="l-icons attention-img"></label>
                    <br>
                    <label class="customlabel mb-0">Override Warning!</label>
                    <p>{{confirmMessage}}</p>
                </div>
            </div>

            <p-footer>
                <button pButton type="button" label="Cancel" (click)="cancelConfimDialog()"
                    class="ui-button-raised ui-button-danger btnCancel"></button>
                <button pButton type="button" label="Continue" class="ui-button-raised btnDelete"
                    (click)="onActionConfirmationClick()"></button>

            </p-footer>
        </p-dialog>


        <div class="dialog-confirm">
            <p-dialog header="You have unsaved changes" [modal]="true" [(visible)]="onTabChange" styleClass="sm"
                class="p-pt-2" [baseZIndex]="10000" [closable]="false">
                <p>{{navigationMessage}}</p>
                <p-footer>
                    <button pButton type="button" label="Cancel" (click)="onCancel()"
                        class="p-button-secondary"></button>
                    <button pButton type="button" label="Discard Changes" (click)="onDiscardChange()"
                        class="p-button-secondary"></button>
                    <button pButton type="button" label="Save & Apply" [disabled]="isSaveAndApplyBtnDisabled"
                        [ngClass]="{'anchor-disabled':isSaveAndApplyBtnDisabled}" class="p-button-primary p-mr-0"
                        (click)="onSaveAndApply()"></button>
                </p-footer>
            </p-dialog>
        </div>

        <p-dialog header="The Entitlement is dependent on the following Entitlements." [(visible)]="isDependentDialog"
            [modal]="true" position="left" [style]="{width: '30vw'}" [baseZIndex]="10000">

            <p-table [columns]="dependentColumns" [value]="dependentList" [scrollable]="true" scrollHeight="200px"
                styleClass="p-datatable-gridlines">

                <ng-template pTemplate="colgroup">
                    <colgroup>
                        <col *ngFor="let col of dependentColumns" [style.width]="col.width">
                    </colgroup>
                </ng-template>

                <ng-template pTemplate="body" let-dependent let-rowIndex="rowIndex">
                    <tr>
                        <td>{{dependentComponentName}}</td>
                        <td>{{dependent.ENTITLEMENT_NAME}}</td>
                    </tr>
                </ng-template>
            </p-table>

            <p-footer class="mt-4">
                <button pButton type="button" label="Close" class="ui-button-raised ui-button-danger"
                    (click)="isDependentDialog = false"></button>
            </p-footer>
        </p-dialog>


        <div class="delete-entitlment-dialog">
            <p-dialog header="Remove Entitlement" [modal]="true" [(visible)]="isDeleteRoleEntitlementDialog"
                [baseZIndex]="10000">
                <div class="p-0">
                    <div class="text-center">
                        <p class="delete-role-content">
                            Currently, <b>{{entitlementUserList?.length}} users</b> have access to the entitlement
                            '{{
                            roleEntName}}' through the role <span
                                pTooltip={{role.ROLE_NAME}}>'{{role.ROLE_NAME}}'</span>.

                            The access will be removed for <b>{{selectedRecords?.length}} out of
                                {{entitlementUserList?.length}}
                                users.</b>
                        </p>
                    </div>
                </div>
                <h6 class="list-user-title user">List of Users</h6>
                <p-table #dt [value]="entitlementUserList" [scrollable]="true" scrollHeight="247px" [responsive]="true"
                    styleClass="ui-table-customers border-style p-datatable-gridlines" (onRowSelect)="onRowSelect()"
                    [(selection)]="selectedRecords">
                    <ng-template pTemplate="header" let-columns>
                        <tr>

                            <th scope="col" style="width: 21px !important;">
                                <span class="header-checkbox">

                                </span>
                            </th>

                            <th scope="col" style="width:50px">
                                <span>User Id</span>
                            </th>
                            <th scope="col" style="width: 200px;">
                                <span>User Name</span>
                            </th>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-data>
                        <tr>
                            <td class="table-checkbox-user-width" style="width: 21px !important">
                                <div style="top: -3px;position: relative;">
                                    <p-tableCheckbox class="p-2" [value]="data"
                                        (click)="onAllRowSelection()"></p-tableCheckbox>
                                </div>

                            </td>

                            <td style="width:50px">{{data.user_id}}</td>
                            <td style="width:200px">{{data.user_name}}</td>
                        </tr>
                    </ng-template>
                </p-table>
                <div class="btn-footer">
                    <span class="selected-box-text">
                        *Selected checkbox indicates access removed and unselected checkbox as access retained.
                    </span>
                    <div class="group-btn p-d-flex p-flex-row p-jc-end p-mt-3">
                        <button pButton type="button" label="Reset" (click)="resetRoleEnt()"
                            [disabled]="isRestButtonDisabled" [ngClass]="{'anchor-disabled':isRestButtonDisabled}"
                            class="p-mr-2">
                        </button>
                        <button pButton type="button" label="Cancel" (click)="isDeleteRoleEntitlementDialog = false"
                            class="p-mr-2 p-button-secondary">
                        </button>
                        <button pButton type="button" label="Ok"
                            (click)="onActionDeleteRoleEntitlement();isDeleteRoleEntitlementDialog = false">
                        </button>
                    </div>
                </div>
            </p-dialog>
        </div>

        <div class="delete-entitlment-dialog">
            <p-dialog
                header="Remove User '{{roleUserName}}-{{roleUserId}}' from the role '{{userRoleName}}-{{userRoleId}}'"
                [modal]="true" [(visible)]="isDeleteRoleUserDialog" [baseZIndex]="10000">
                <p-header>
                    <div class="p-d-flex p-ai-center">
                        <span class="delete-role-img">
                            <label class="delete-icons trash-img"></label>

                        </span>
                        <p class="p-m-auto" style="font-size: 18px;font-weight: 500;">Remove User
                            '{{roleUserName}}-{{roleUserId}}' from the role
                            '{{userRoleName}}-{{userRoleId}}'</p>
                    </div>
                </p-header>
                <div class="p-0">
                    <div class="text-center">
                        <p class="delete-role-content">
                            Currently, {{roleUserName}} has access to the <strong>{{userEntitlementList?.length}}
                                entitlements</strong> through the role <span
                                pTooltip={{role.ROLE_NAME}}>'{{userRoleName}}'</span>.

                            The access will be removed for <strong>{{this.selectedUserEntitlement?.length}} out of
                                {{userEntitlementList?.length}} entitlements.</strong>

                        </p>
                        <h6 class="list-user-title user">
                            List of Entitlements
                        </h6>
                    </div>
                </div>
                <div class="user-table">
                    <p-table [columns]="userEntitlementColumns" [value]="userEntitlementList"
                        styleClass="ui-table-customers" [(selection)]="selectedUserEntitlement" scrollHeight="247px"
                        [scrollable]="true" [style]="{ height: '100%',width:'100%',bottom:'37px'}">

                        <ng-template pTemplate="header" let-userEntitlementColumns>

                            <tr>
                                <th scope="col" style="width: 31px !important;">
                                    <span class="header-checkbox">

                                    </span>
                                </th>
                                <th scope="col" style="width: 80px !important;">
                                    Entitlement Id
                                </th>
                                <th scope="col" style="width: 200px !important;">
                                    Entitlement Name
                                </th>
                                <th scope="col" style="width: 200px !important;">
                                    Component Name
                                </th>
                            </tr>
                        </ng-template>

                        <ng-template pTemplate="body" let-entitlementList let-rowIndex="rowIndex">

                            <tr>
                                <td class="table-checkbox-width" style="width:31px !important;">
                                    <p-checkbox (onChange)="onEntitlementSelectionChange()"
                                        [(ngModel)]="selectedUserEntitlement" name="group" [value]="entitlementList">

                                    </p-checkbox>
                                </td>
                                <td style="width: 80px !important;">
                                    {{entitlementList.COMPONENT_ID + '-' + entitlementList.ENTITLEMENT_ID}}
                                </td>
                                <td style="width: 200px !important;">
                                    {{entitlementList.entitlementName}}
                                </td>
                                <td style="width: 200px !important;">
                                    {{entitlementList.COMPONENT_NAME}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <div class="btn-footer">
                    <span class="selected-box-text">
                        *Selected checkbox indicates access removed and unselected checkbox as access retained
                    </span>
                    <div class="group-btn p-d-flex p-flex-row p-jc-end p-mt-3">
                        <button pButton type="button" label="Reset" (click)="onResetRoleUser()"
                            [ngClass]="{'anchor-disabled':isRestButtonDisabled}" class="p-mr-2">
                        </button>
                        <button pButton type="button" label="Cancel" (click)="isDeleteRoleUserDialog = false"
                            class="p-mr-2 p-button-secondary">
                        </button>
                        <button pButton type="button" label="Ok" (click)="onActionDeleteRoleUser()">
                        </button>
                    </div>
                </div>
            </p-dialog>
        </div>
        <p-overlayPanel #userFilter class="overlayactionpart role-filter" (onHide)="sidebarCloseEvent()">
            <ng-template pTemplate>
                <div class="p-col-12 p-md-12 filtercard  p-p-0">
                    <div class="addOrgAttrs">

                        <div class="p-col-auto filter-size p-d-flex p-ai-baseline">
                            <i class="icon-filter p-mr-2"></i>
                            <h6>Filters</h6>
                        </div>

                        <div class="row mb-1">
                            <div class="col-md-12">
                                <div class="cri-border-bottom"></div>
                            </div>
                        </div>

                        <div class="row mb-1 user-panel">
                            <div class="col-md-12 accd">
                                <p-accordion expandIcon="pi pi-plus" collapseIcon="pi pi-minus">
                                    <p-accordionTab header="User Status" [(selected)]="activeUserTab.isStatusTab">
                                        <p-tree [value]="userStatusData" selectionMode="checkbox" [filter]="true"
                                            [(selection)]="selectedUserStatus" [style]="{'width':'100%'}">
                                        </p-tree>
                                    </p-accordionTab>
                                </p-accordion>
                            </div>
                        </div>

                        <div class="footer-over p-mt-2">
                            <button type="button" pButton
                                (click)="applyRoleUserFilter();discardUserFilter($event, userFilter)" label="Apply"
                                class="p-button-secondary p-button-sm apply-btn-filter"></button>
                        </div>
                    </div>
                </div>
            </ng-template>
        </p-overlayPanel>


        <p-dialog header="Unsaved Data!" [modal]="true" [(visible)]="isDBChanges" [closable]="true"
            [style]="{width: '55vw'}" [baseZIndex]="10000">
            <p-header>
                <span class="delete-role-img">
                    <label class="l-icons fluent-save-img"></label>
                </span>
            </p-header>
            <div class="p-grid p-fluid p-0">
                <div class="p-col-12 p-md-12 text-center">
                    <pre class="refresh-data-content">
             We have detected some changes were made on this page by another user.

             Please <b>refresh</b> the page to get the latest contents by clicking on below button and make the 
            
             changes again.
            </pre>
                </div>
            </div>
            <p-footer>
                <button pButton type="button" label="Ok" style="bottom: 9px;" class="ui-button-raised ui-button-danger"
                    (click)="refreshRoleDetails(selectedRole)"></button>
            </p-footer>
        </p-dialog>
    </div>
</div>