import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PagesService } from '../pages.service';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
@Component({
  selector: 'jhi-deleteattribute',
  templateUrl: './DeleteCustomField.component.html',
  styleUrls: ['./DeleteCustomField.component.scss'],
})
export class DeleteCustomFieldComponent implements OnInit {
  DeleteRoleForm = this.fb.group({
  });
  pageloadvalues: any;
  groupId: any;
  appId: any;
  resourceId: any;
  active: any;
  dName: any;
  Ucount: any;
  Gcount: any;
  flag1: boolean;
  fieldName: any;
  moduleID: any;
  fieldID: any;

  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    private messageService: MessageService,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.DeleteRoleForm.patchValue(JSON.parse(data));
    }
  }

  ngOnInit(): void {
    this.fieldID = this.config?.data?.id;
    this.fieldName = this.config?.data?.name;
    this.moduleID = this.config?.data?.moduleid;
  }

  onClickNo($event: UntypedFormGroup): void {
    this.ref.close();
  }

  deleteCustomField() {
    const reqBody = {}
    reqBody['tenantId'] = this.pagesService.tenantId;
    reqBody['moduleId'] = this.moduleID;
    reqBody['fieldId'] = this.fieldID;
    this.pagesService.deleteCustomfield(reqBody).subscribe({
      next: (results: any) => {
        this.ref.close("success");
      },
      error: error => {
        this.ref.close("failed");
      }
    });
  }

}