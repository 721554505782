<div class="layout-content custom-container">
    <div class="p-formgrid p-grid custom-create-tenant">
        <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-p-0  p-offset-0 left custom-height">
            <div class="p-mt-2 p-pl-2 p-pr-2">
                <button [disabled]="firstSave" pButton type="button" (click)="activeIndex1 = 0"
                    class="p-button-outlined p-button-lg tabButton custom-size" label="Tenant Details"
                    style="border-radius: 4px;"
                    [style.box-shadow]="activeIndex1 == 0? '1px 5px 20px #bdc0c9': '0px 1px 4px #bdc0c9'"
                    [style.border]="activeIndex1 == 0 ? '1px solid #396FC5' : '#add8e6'">
                    <div class="fa-2x">
                        <span class="fa-layers fa-fw">
                            <label class="c-icons personal-icon"></label>
                        </span>
                    </div>
                    <span class="p-button-icon p-button-icon-left reshot-icon-business-team" aria-hidden="true"></span>
                    <label class="d-icons check-icon custom-label" *ngIf="tab1"></label>
                </button>
                <button [disabled]="!firstSave" pButton type="button" (click)="activeIndex1 = 1"
                    style="border-radius: 4px;" label="Tenant Owner Details" id="panelbtn2"
                    class="p-button-outlined p-button-lg tabButton custom-roles custom-size"
                    [style.box-shadow]="activeIndex1 == 1? '1px 5px 20px #bdc0c9': '0px 1px 4px #bdc0c9'"
                    [style.border]="activeIndex1 == 1 ? '1px solid #396FC5' : '#add8e6'" style="width:214px">
                    <div class="fa-2x">
                        <span class="fa-layers fa-fw">
                            <label class="c-icons role-icon"></label>
                        </span>
                    </div>
                    <span class="p-button-icon p-button-icon-left reshot-icon-business-team" aria-hidden="true"></span>
                    <label class="d-icons check-icon custom-rolelabel" *ngIf="tab2"></label>
                </button>
            </div>
            <div class="tabview-custom">
                <p-tabView [(activeIndex)]="activeIndex1" styleClass="tabview-custom">
                    <p-tabPanel>
                        <form [formGroup]="CreateTenantDetailsForm" autocomplete="off" novalidate
                            style="padding-top: 14px;">
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 left">
                            </div>
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                                <div class=" p-fluid   p-field    ">
                                    <label
                                        [ngClass]="{'field-required': CreateTenantDetailsForm.get('tenantName')?.errors?.required}">
                                        Tenant Name
                                    </label> <span class='invalid-feedback-astrik'>*</span>
                                    <span class="p-input-icon-right" style="margin-bottom: 2px;">
                                        <i aria-hidden="true" class="d-icons disable-icon"
                                            style="margin-right: 4px;margin-top: -6px;" *ngIf="this.firstSave"></i>
                                        <input type="text" id="tenantName_id" name="tenant_name"
                                            formControlName="tenantName" placeholder="Enter Tenant Name"
                                            class="p-inputtext inputtextHeight" pattern="^([a-z0-9]+-)*[a-z0-9]+$"
                                            maxlength="64" minlength="3"
                                            (keyup)="this.defaultFlag=false;domainname();this.nameFlag=false;colorChange()"
                                            required />
                                    </span>
                                    <div
                                        *ngIf="this.defaultFlag && this.CreateTenantDetailsForm.value.tenantName==null">
                                        <label class="alert-icon alert-circle-icon1"></label><span
                                            style="font-size: 12px;font-weight: 400;color: #505870;vertical-align: middle;"
                                            class="p-ml-1">Use only lowercase alphanumeric characters & hyphen, don’t
                                            start & end with a hyphen</span>
                                    </div>
                                    <div class="p-error"
                                        *ngIf="CreateTenantDetailsForm.get('tenantName')!.invalid && (CreateTenantDetailsForm.get('tenantName')!.dirty || CreateTenantDetailsForm.get('tenantName')!.touched)">
                                        <div
                                            *ngIf="CreateTenantDetailsForm.get('tenantName')?.errors?.required && this.defaultFlag==false">
                                            <label class="alert-icon alert-circle-icon"></label>
                                            <medium class="p-error">Tenant name is required.</medium>
                                        </div>
                                        <div
                                            *ngIf="CreateTenantDetailsForm.get('tenantName')?.errors.minlength!=3 && CreateTenantDetailsForm.get('tenantName')?.errors.minlength!=null">
                                            <label class="alert-icon alert-circle-icon"></label>
                                            <medium class="p-error">Minimum 3 and maximum 64 characters are allowed.
                                            </medium>
                                        </div>
                                        <div
                                            *ngIf="CreateTenantDetailsForm.get('tenantName').errors.pattern && this.CreateTenantDetailsForm.value.tenantName!=null">
                                            <label class="alert-icon alert-circle-icon"></label>
                                            <medium class="p-error">Use only lowercase alphanumeric characters & hyphen,
                                                don’t start & end with a hyphen</medium>
                                        </div>
                                    </div>
                                    <div class="p-error">
                                        <label *ngIf="this.nameFlag" class="custom-codelabel">
                                            <div>
                                                <label class="alert-icon alert-circle-icon" for="rolename"></label>
                                                <medium id="attributeGroupName" class="p-error">
                                                    Tenant Name already exists!
                                                </medium>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                                <div class=" p-fluid   p-field    ">
                                    <label
                                        [ngClass]="{'field-required': CreateTenantDetailsForm.get('domainName')?.errors?.required}">
                                        Tenant Domain
                                    </label><span class='invalid-feedback-astrik'>*</span>
                                    <span>
                                        <i aria-hidden="true" class="d-icons disable-icon"
                                            style="margin-right: 4px;margin-top: -6px;" *ngIf="this.firstSave"></i>
                                        <input type="text" id="domainName_id" [(ngModel)]="domain" name="domain_name"
                                            formControlName="domainName" (keyup)="this.domainEditFlag=false;"
                                            placeholder=" Enter Domain Name" class="p-inputtext inputtextHeight"
                                            pattern="^(([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}" minlength="3"
                                            required />
                                    </span>
                                    <div class="p-error"
                                        *ngIf="CreateTenantDetailsForm.get('domainName')!.invalid && (CreateTenantDetailsForm.get('domainName')!.dirty || CreateTenantDetailsForm.get('domainName')!.touched)">
                                        <div *ngIf="CreateTenantDetailsForm.get('domainName')?.errors?.required">
                                            <label class="alert-icon alert-circle-icon"></label>
                                            <medium class="p-error">Tenant Domain is required.</medium>
                                        </div>
                                        <div *ngIf="CreateTenantDetailsForm.get('domainName').errors.pattern">
                                            <label class="alert-icon alert-circle-icon"></label>
                                            <medium class="p-error">Invalid Tenant Domain Format! </medium>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="p-grid p-col-12 p-mt-5 p-mb-4" style="padding-bottom: 10px;">
                                <div class="p-col p-p-0 p-mt-4 p-mr-3 p-p-0 custom_small_file">
                                    <label>Logo</label>
                                    <div class="custom_choose_file custom-tenant-file">
                                        <p-fileUpload id="newSolutionPageIconChooseFilebtn"
                                            name="newSolutionPageIconChooseFilebtn" #fileUploadPageIconChooseFilebtn
                                            chooseLabel="Choose File" customUpload="true" cancelLabel="Reset"
                                            [showUploadButton]="false" [showCancelButton]="false"
                                            (onSelect)="onSelectSolutionIcon($event)">
                                            <ng-template pTemplate="content">
                                                <ng-container
                                                    *ngIf="imageURL && (productPrimaryImage == '' || productPrimaryImage == undefined);then previewText1; else previewText">
                                                </ng-container>
                                                <ng-template #previewText1>
                                                    <label class="label_content">
                                                        <span
                                                            pTooltip="{{imageForToolTip.length > 30 ? imageForToolTip : ''}}"
                                                            tooltipPosition="left">
                                                            <ng-container
                                                                *ngIf="imageForToolTip.length > 30; else tooltip">
                                                                {{
                                                                imageURL.split("/")[4].substring(0,
                                                                30) +
                                                                "..." }}
                                                            </ng-container>
                                                            <ng-template #tooltip>
                                                                {{ imageURL.split("/")[4] }}
                                                            </ng-template>
                                                        </span>
                                                    </label>
                                                </ng-template>
                                                <ng-template #previewText>
                                                    <label
                                                        *ngIf="(productPrimaryImage == '' || productPrimaryImage == undefined) && !imagevalid"
                                                        class="label_content">
                                                        &nbsp;Add file JPEG, PNG or SVG Max:
                                                        1Mb
                                                    </label>
                                                </ng-template>
                                            </ng-template>
                                            <ng-template let-file pTemplate="file">
                                                <div class="file">
                                                    <ng-container
                                                        *ngIf="imageURL && (productPrimaryImage == '' || productPrimaryImage == undefined);then previewdata1; else previewdata">
                                                    </ng-container>
                                                    <ng-template #previewdata>
                                                        <span class="ml-2 fileName"
                                                            pTooltip="{{file.name.length > 30 ? file.name : ''}}"
                                                            tooltipPosition="left">
                                                            <ng-container *ngIf="file.name.length > 30; else tooltip">
                                                                {{ file.name.substring(0,30) +
                                                                "..." }}
                                                            </ng-container>
                                                            <ng-template #tooltip>
                                                                {{ file.name }}
                                                            </ng-template>
                                                        </span>
                                                        <span class="delete-action"
                                                            (click)="onRemoveIcon(event,fileUploadPageIconChooseFilebtn)">
                                                            <i aria-hidden="true" class="pi pi-times"></i>
                                                        </span>
                                                    </ng-template>
                                                    <ng-template #previewdata1></ng-template>
                                                </div>
                                            </ng-template>
                                        </p-fileUpload>
                                    </div>
                                    <div class="p-error" *ngIf="solImage">
                                        <medium>The following characters are not allowed: ~ # % & * &#123; &#125; \ : &lt; >
                                            ? / | ' "</medium>
                                    </div>
                                    <div class="p-error" *ngIf="solImageFileSize">
                                        <label class="alert-icon alert-circle-icon"></label>
                                        <medium>The maximum file size can be 1MB!</medium>
                                    </div>
                                    <div class="p-error" *ngIf="imagevalid">
                                        <label class="alert-icon alert-circle-icon"></label>
                                        <medium>Invalid image format!</medium>
                                    </div>
                                </div>
                                <div class="p-col-fixed p-p-0" style="width: 80px;">
                                    <ng-container
                                        *ngIf="imageURL && (productPrimaryImage == '' || productPrimaryImage == undefined);then previewimage1; else previewimage">
                                    </ng-container>
                                    <ng-template #previewimage1>
                                        <div class="custom_preview_file" style="margin-top: 2px;">
                                            <img alt="" src="{{BASE_URL + imageURL}}" />
                                        </div>
                                    </ng-template>
                                    <ng-template #previewimage>
                                        <div class="custom_preview_file custom_preview_file_small_screen"
                                            style="margin-top: 2px;background-color: #EBF3FE;">
                                            <img alt="" [src]="this.productPrimaryImage" *ngIf="!solImageFileSize" />
                                            <i aria-hidden="true"
                                                *ngIf=" productPrimaryImage == '' || productPrimaryImage == undefined || solImageFileSize"
                                                class="icon-image" style="left: 28px;"></i>
                                            <label
                                                *ngIf="productPrimaryImage == '' ||productPrimaryImage == undefined || solImageFileSize"
                                                style="left: -1px;">
                                                Preview
                                            </label>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right">
                                <div class="btnwrap p-mr-3">
                                    <button pButton type="button" class="p-button-brand2"
                                        (click)="onClose1(CreateTenantDetailsForm);" label="Close">
                                    </button>
                                </div>
                                <div class="btnwrap">
                                    <button pButton class="p-button-primary" id="save" type="button"
                                        (click)="onSaveCheckTenantName(CreateTenantDetailsForm);"
                                        [disabled]="nameFlag  || !CreateTenantDetailsForm.valid  || solImageFileSize || imagevalid"
                                        label="Save">
                                    </button>
                                </div>
                            </div>
                        </form>
                    </p-tabPanel>
                    <p-tabPanel>
                        <form [formGroup]="CreateTenantForm" autocomplete="off" novalidate style="padding-top: 14px;">
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 left">
                            </div>
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                                <div class=" p-fluid   p-field    ">
                                    <label
                                        [ngClass]="{'field-required': CreateTenantForm.get('firstName')?.errors?.required}">
                                        First Name
                                    </label> <span class='invalid-feedback-astrik'>*</span>
                                    <input type="text" id="firstName_id" name="first_name" formControlName="firstName"
                                        placeholder="Enter First name" class="p-inputtext inputtextHeight"
                                        pattern="^([a-zA-Z]+\s)*[a-zA-Z]+$" maxlength="50" required />
                                    <div class="p-error"
                                        *ngIf="CreateTenantForm.get('firstName')!.invalid && (CreateTenantForm.get('firstName')!.dirty || CreateTenantForm.get('firstName')!.touched)">
                                        <div *ngIf="CreateTenantForm.get('firstName')?.errors?.required">
                                            <label class="alert-icon alert-circle-icon"></label>
                                            <medium class="p-error">First name is required.</medium>
                                        </div>
                                        <div *ngIf="CreateTenantForm.get('firstName').errors.pattern">
                                            <label class="alert-icon alert-circle-icon"></label>
                                            <medium class="p-error">First name should not have Special Character and
                                                number. </medium>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                                <div class=" p-fluid   p-field    ">
                                    <label>
                                        Middle Name
                                    </label><input type="text" id="middleName_id" name="middle_name"
                                        formControlName="middleName" placeholder="Enter Middle Name"
                                        class="p-inputtext inputtextHeight" pattern="^([a-zA-Z]+\s)*[a-zA-Z]+$"
                                        maxlength="50" />

                                    <div class="invalid-feedback"
                                        *ngIf="CreateTenantForm.get('middleName')!.invalid &&(CreateTenantForm.get('middleName')!.dirty ||CreateTenantForm.get('middleName')!.touched)">
                                        <div *ngIf="CreateTenantForm.get('middleName').errors.pattern">
                                            <label class="alert-icon alert-circle-icon"></label>
                                            <medium class="p-error">Middle Name should not have Special
                                                Character and number. </medium>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                                <div class=" p-fluid   p-field    ">
                                    <label
                                        [ngClass]="{'field-required': CreateTenantForm.get('lastName')?.errors?.required}">
                                        Last Name
                                    </label><span class="invalid-feedback-astrik">*</span>
                                    <input type="text" id="lastName_id" name="last_name" formControlName="lastName"
                                        placeholder="Enter Last Name" class="p-inputtext inputtextHeight"
                                        pattern="^([a-zA-Z]+\s)*[a-zA-Z]+$" maxlength="50" required />
                                    <div class="invalid-feedback"
                                        *ngIf="CreateTenantForm.get('lastName')!.invalid &&(CreateTenantForm.get('lastName')!.dirty ||CreateTenantForm.get('lastName')!.touched)">
                                        <medium *ngIf="CreateTenantForm.get('lastName')?.errors?.required"
                                            class="p-error">
                                            <label class="alert-icon alert-circle-icon"></label>
                                            Last name is required!
                                        </medium>
                                        <div *ngIf="CreateTenantForm.get('lastName').errors.pattern">
                                            <label class="alert-icon alert-circle-icon"></label>
                                            <medium class="p-error">Last Name should not have Special
                                                Character and number.</medium>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                                <div class=" p-fluid   p-field    ">
                                    <label
                                        [ngClass]="{'field-required': CreateTenantForm.get('emailAddress')?.errors?.required}">
                                        Email
                                    </label><span class="invalid-feedback-astrik">*</span>
                                    <input pInputText type="email" id="emailAddress_id" name="emailAddress_name"
                                        formControlName="emailAddress" pInputText placeholder="Enter Email address"
                                        class="p-inputtext inputtextHeight" />
                                    <div class="invalid-feedback"
                                        *ngIf="CreateTenantForm.get('emailAddress')!.invalid &&(CreateTenantForm.get('emailAddress')!.dirty ||CreateTenantForm.get('emailAddress')!.touched)">
                                        <medium *ngIf="CreateTenantForm.get('emailAddress')?.errors?.required"
                                            class="p-error">
                                            <label class="alert-icon alert-circle-icon"></label>
                                            Email is required!
                                        </medium>
                                        <label *ngIf="CreateTenantForm.get('emailAddress')?.errors?.pattern"
                                            class="p-error">
                                            <div class="custom-font">
                                                <label class="alert-icon alert-circle-icon" for="rolename"></label>
                                                <medium id="rolename"
                                                    style="display: inline-block;vertical-align: bottom;"
                                                    class="p-error">Email must be a valid email address!
                                                </medium>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right p-mb-2">
                                <div class="btnwrap p-mr-3">
                                    <button pButton type="button" class="p-button-brand2"
                                        (click)="onClose(CreateTenantForm);" label="Close">
                                    </button>
                                </div>
                                <div class="btnwrap">
                                    <button pButton class="p-button-primary" id="save" type="button"
                                        (click)="onSaveTenantOwnerDetails();" label="Save"
                                        [disabled]="!CreateTenantForm.valid ">
                                    </button>
                                </div>
                            </div>
                        </form>
                    </p-tabPanel>
                </p-tabView>
            </div>
        </div>
    </div>
</div>