<div class="layout-content custom-container">
	<div class="p-fluid p-formgrid p-grid">
		<div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined">
			<p-tabView>
				<p-tabPanel>
					<ng-template pTemplate="header">
						<span class="label-badge">Add New Parameter</span>
					</ng-template>
					<form [formGroup]="AddAccountParameterForm" autocomplete="off" novalidate>
						<div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-pr-0 p-pl-0">
							<div class="p-fluid p-formgrid p-grid">
								<div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 p-pl-0 left">
									<div class="p-fluid p-field">
										<label
											[ngClass]="{'field-required': AddAccountParameterForm.get('fieldName')?.errors?.required}">
											Parameter Name
											<span class='invalid-feedback-astrik'>*</span></label>
										<input type="text" id="fieldName_id"
											pattern="^[A-Z]+[a-zA-Z0-9\s_-]*[a-zA-Z0-9]$" name="fieldName"
											formControlName="fieldName" pInputText placeholder="Enter Field name"
											class="p-inputtext inputtextHeight" maxlength="64"
											(keyup)="this.nameFlag=false;checkInput($event);colorChange();" />
										<div class="p-error"
											*ngIf="AddAccountParameterForm.get('fieldName')!.invalid && (AddAccountParameterForm.get('fieldName')!.dirty || AddAccountParameterForm.get('fieldName')!.touched)">
											<div *ngIf="AddAccountParameterForm.get('fieldName')?.errors?.required">
												<label class="alert-icon alert-circle-icon"></label>
												<medium class="p-error">
													Parameter name is required.</medium>
											</div>
											<div *ngIf="AddAccountParameterForm.get('fieldName').errors.pattern">
												<label class="alert-icon alert-circle-icon"></label>
												<medium class="p-error">Parameter Name should start with uppercase and
													only
													dash,
													underscore,
													space are allowed as special characters
												</medium>
											</div>
										</div>
										<div class="p-error" *ngIf="this.nameFlag">
											<label class="alert-icon alert-circle-icon" for="fieldname"></label>
											<span id="rolename" style="display:inline-block;vertical-align: bottom;"
												class="p-error">Field
												name already exists!
											</span>
										</div>
									</div>
								</div>
								<div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left">
									<div class="p-fluid p-field">
										<label>Input Type
											<span class='invalid-feedback-astrik'>*</span>
										</label>
										<p-dropdown id="selectid" name="fieldinputtype" placeholder="Select"
											[options]="fieldtype" [filter]="true" filterBy="label"
											[autoDisplayFirst]="false" formControlName="fieldInputType"
											(onChange)="fieldtypevalidate();getInputformat();checkInput($event)">
											<ng-template let-item pTemplate="item">
												<div *ngIf="item.label.length <= 35;">
													{{item.label}}
												</div>
												<div *ngIf="item.label.length > 35;" pTooltip="{{item.label}}"
													tooltipPosition="bottom">
													{{item.label.substring(0,35) + "..." }}
												</div>
											</ng-template>
											<ng-template let-item pTemplate="item">
												<div class="country-item">
													<div>{{item.label}}</div>
												</div>
											</ng-template>
										</p-dropdown>
										<div class="p-error"
											*ngIf="AddAccountParameterForm.get('fieldInputType')!.invalid && (AddAccountParameterForm.get('fieldInputType')!.dirty || AddAccountParameterForm.get('fieldInputType')!.touched)">
											<div
												*ngIf="AddAccountParameterForm.get('fieldInputType')?.errors?.required">
												<label class="alert-icon alert-circle-icon"></label>
												<medium class="p-error">Field Input Type is required.</medium>
											</div>
										</div>
									</div>
								</div>
								<div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left p-pr-0"
									id="check">
									<div class="p-fluid p-field" *ngIf="!this.checkbox">
										<label>
											Input Values/Format
											<span class='invalid-feedback-astrik'>*</span>
										</label>
										<input type="text" id="inputformat_id" name="inputformat"
											(keyup)="this.defaultFlag=false;" formControlName="inputValueFormattext"
											pInputText placeholder="Enter Array of Values to Input"
											class="p-inputtext inputtextHeight" *ngIf="this.inputFlag"
											pattern="^[a-zA-Z0-9!@#\$%\^\&*\?\)\(+=._-]+([a-zA-Z0-9!@#\$%\^\&*\?\)\(+=._-],[a-zA-Z0-9!@#\$%\^\&*\?\)\(+=._-]+)*[a-zA-Z0-9!@#\$%\^\&*\?\)\(+=._-]+$"
											required />
										<div
											*ngIf="this.defaultFlag && this.AddAccountParameterForm.value.inputformat==null && this.inputFlag">
											<label class="alert-icon alert-circle-icon1"></label><span
												style="font-size: 12px;font-weight: 400;color: #505870;vertical-align: middle;"
												class="p-ml-1">Use
												comma to seperate values</span>
										</div>
										<div class="invalid-feedback" *ngIf="AddAccountParameterForm.get('inputValueFormattext')!.invalid &&
						                            																	(AddAccountParameterForm.get('inputValueFormattext')!.dirty ||
						                                                                                                AddAccountParameterForm.get('inputValueFormattext')!.touched)
						                            																">
											<div
												*ngIf="AddAccountParameterForm.get('inputValueFormattext').errors.pattern">
												<label class="alert-icon alert-circle-icon"></label>
												<medium class="p-error">Please Enter comma separated value.
												</medium>
											</div>
										</div>
										<p-multiSelect filterBy="label" [(ngModel)]="SelectTreevalue"
											*ngIf="!this.inputFlag && !this.rangeFlag" id="userList" name="userList"
											filterPlaceHolder="Select" [options]="inputformat" [filter]="true"
											formControlName="inputValueFormat" optionLabel="label" optionValue="value"
											display="chip" resetFilterOnHide="true" placeholder="Select"
											class="custom-chips"
											(onChange)="specialcharacter();getSpecialCharacter()"></p-multiSelect>
										<p-dropdown id="select1id" name="inputformat" placeholder="Select"
											[options]="inputformat" [filter]="true" filterBy="label"
											[autoDisplayFirst]="false" formControlName="inputValueFormatDropdown"
											*ngIf="this.rangeFlag"></p-dropdown>
										<div class="p-error"
											*ngIf="AddAccountParameterForm.get('inputValueFormat')!.invalid && (AddAccountParameterForm.get('inputValueFormat')!.dirty || AddAccountParameterForm.get('inputValueFormat')!.touched)">
											<div
												*ngIf="AddAccountParameterForm.get('inputValueFormat')?.errors?.required">
												<label class="alert-icon alert-circle-icon"></label>
												<medium class="p-error">Input Values/Format is required.</medium>
											</div>
										</div>
									</div>
								</div>
								<div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-pl-0 p-offset-undefined left"
									*ngIf="this.specialFlag" id="maxchar" style="margin-top: 4px !important;">
									<label
										[ngClass]="{'field-required': AddAccountParameterForm.get('inputValueFormatdemo1')?.errors?.required}">
										Special Characters <span class='invalid-feedback-astrik'>*</span></label>

									<p-multiSelect filterBy="label" id="userList" [(ngModel)]="Selectspecial"
										name="userList" filterPlaceHolder="Select Input Format"
										[options]="SpecialCharacter" [filter]="true"
										formControlName="inputValueFormatdemo1" optionLabel="label" optionValue="value"
										display="chip" resetFilterOnHide="true" placeholder="Select Input Format"
										class="custom-chips">
									</p-multiSelect>
									<div class="p-error"
										*ngIf="AddAccountParameterForm.get('inputValueFormatdemo1')!.invalid && (AddAccountParameterForm.get('inputValueFormatdemo1')!.dirty || AddAccountParameterForm.get('inputValueFormatdemo1')!.touched)">
										<div
											*ngIf="AddAccountParameterForm.get('inputValueFormatdemo1')?.errors?.required">
											<label class="alert-icon alert-circle-icon"></label>
											<medium class="p-error">
												Special Characters is required.</medium>
										</div>
									</div>
								</div>
								<div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left p-pl-0 p-d-flex"
									*ngIf="this.maxlengthFlag" id="maxchar">
									<div class="p-pl-2" *ngIf="this.specialFlag">
									</div>
									<div>
										<label
											[ngClass]="{'field-required': AddAccountParameterForm.get('MaxCharacters')?.errors?.required}">
											Max. no. of characters
											<span class='invalid-feedback-astrik'>*</span></label>
										<input type="text" id="inputformat_id" name="maxcharacters"
											formControlName="MaxCharacters" pInputText
											placeholder="Enter Max. no. of characters"
											class="p-inputtext inputtextHeight" pattern="^[0-9]\d*$" required
											style="margin-top: 4px;" />
										<div class="p-error"
											*ngIf="AddAccountParameterForm.get('MaxCharacters')!.invalid && (AddAccountParameterForm.get('MaxCharacters')!.dirty || AddAccountParameterForm.get('MaxCharacters')!.touched)">
											<div *ngIf="AddAccountParameterForm.get('MaxCharacters')?.errors?.required">
												<label class="alert-icon alert-circle-icon"></label>
												<medium class="p-error">
													Max Characters is required.</medium>
											</div>
											<div *ngIf="AddAccountParameterForm.get('MaxCharacters').errors.pattern">
												<label class="alert-icon alert-circle-icon"></label>
												<medium class="p-error">Please enter numeric value.
												</medium>
											</div>
										</div>
									</div>
								</div>
								<div class="p-col-12 p-sm-12 p-md-12 p-lg-2 p-xl-2 p-offset-undefined left p-pl-0"
									id="mandatory" *ngIf="!this.checkbox">
									<div style="height: 32px;"
										*ngIf="this.inputType == 'text_area' || this.inputType == 'checkbox' || this.inputType == 'text'">
									</div>
									<div class="p-field-checkbox">
										<div class="p-pl-2"
											*ngIf="this.inputType == 'text_area' || this.inputType == 'checkbox' || this.inputType == 'text'">
										</div>
										<p-checkbox name="mandatory" [(ngModel)]="checkedmandatory" binary="true"
											inputId="binary" formControlName="mandatory">
										</p-checkbox>
										<label for="ny">Mandatory field</label>
									</div>
								</div>
								<div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right"
									style="margin-top: 200px;">
									<div class="btnwrap p-mr-3">
										<button pButton type="button" class="p-button-brand2" label="Close"
											(click)="onClose();">
										</button>
									</div>
									<div class="btnwrap">
										<button pButton class="p-button-primary" type="submit" label="Save"
											(click)="onSaveAddCustomField();"
											[disabled]="!AddAccountParameterForm.valid || this.nameFlag">
										</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</p-tabPanel>
				<p-tabPanel>
					<ng-template pTemplate="header">
						<span class="label-badge">Pre-defined Parameters</span>
					</ng-template>
					<form [formGroup]="ExistingParameterForm" autocomplete="off" novalidate>

						<div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-pr-0 p-pl-0">
							<div class="p-fluid p-formgrid p-grid">

								<div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left p-pl-0"
									id="check">
									<div class="p-fluid p-field">
										<label>
											Pre-defined Parameters
										</label>
										<p-multiSelect filterBy="label" [(ngModel)]="existingParameter"
											*ngIf="!this.inputFlag && !this.rangeFlag" id="userList" name="userList"
											filterPlaceHolder="Select" [options]="existingParameterList" [filter]="true"
											formControlName="existingParameter" optionLabel="label" optionValue="value"
											display="chip" resetFilterOnHide="true" placeholder="Select"
											class="custom-chips"></p-multiSelect>
										<div class="p-error"
											*ngIf="ExistingParameterForm.get('existingParameter')!.invalid && (ExistingParameterForm.get('existingParameter')!.dirty || ExistingParameterForm.get('existingParameter')!.touched)">
											<div
												*ngIf="ExistingParameterForm.get('existingParameter')?.errors?.required">
												<label class="alert-icon alert-circle-icon"></label>
												<medium class="p-error">Pre-defined Parameters is required.</medium>
											</div>
										</div>
									</div>
								</div>
								<div class="custom-errorFlagDiv" *ngIf="this.existingParameter.length==0">
									<label class="c-icons error-icon1"></label>
									<label class="e-error" style="margin-right: 0px;">Please Select
										Pre-defined Parameters</label><br>
									<br>
								</div>
								<div class="custom-errorFlagDiv" *ngIf="this.existingParameter.length>0">

								</div>
								<div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-mt-4 p-offset-undefined p-right">
									<div class="btnwrap p-mr-3">
										<button pButton type="button" class="p-button-brand2" label="Close"
											(click)="onClose();">
										</button>
									</div>
									<div class="btnwrap">
										<button pButton class="p-button-primary" type="submit" label="Save"
											(click)="onSaveExistingParameter();"
											[disabled]="!ExistingParameterForm.valid || this.nameFlag">
										</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</p-tabPanel>


			</p-tabView>
		</div>
	</div>
</div>