import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { PagesService } from '../pages.service';
@Component({
  selector: 'jhi-createattributegroup',
  templateUrl: './CreateAttributeGroup.component.html',
  styleUrls: ['./CreateAttributeGroup.component.scss'],
  providers: [DialogService],
})
export class CreateAttributeGroupComponent implements OnInit {
  CreateAttributeGroupForm = this.fb.group({
    textField: [null, [Validators.required]],
    textarea: [null, []],
  });
  pageloadvalues: any;
  nameFlag: boolean = false;
  enabledbtn1: boolean = false;
  appId: any;

  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public pagesService: PagesService) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.CreateAttributeGroupForm.patchValue(JSON.parse(data));
    }
  }

  ngOnInit(): void {
    this.appId = this.config?.data?.id;
  }

  onClose($event: UntypedFormGroup): void {
    this.ref.close();
  }

  colorChange() {
    document.getElementById('textField_id')!.style.borderColor = 'grey';
  }

  /**
   * CREATE ATTRIBUTE GROUP IN ATTRIBUTE&GROUP TAB 
   */

  //create attribute and group API
  createAttributeGroup(): void {
    const reqBody = {};
    reqBody['attributeGroupName'] = this.CreateAttributeGroupForm.value.textField;
    reqBody['description'] = this.CreateAttributeGroupForm.value.textarea;
    const formValues = reqBody;
    this.pagesService.onSaveCreateattributeGroup(formValues, this.appId).subscribe(
      {
        next: results => {
          this.ref.close("Attribute Group Created Successfully");
        },
        error: error => {
          if (error.error.error?.messageCode == "CIAM 105") {
            this.ref.close("Attribute group name already exist");
          }
          else {
            this.ref.close("Attribute Group Creation Failed");
          }
        }
      });
  }

  // Check availability of attribute and group name
  AttributeGroup($event: UntypedFormGroup) {
    this.onCheckAttributeGroupName($event);
  }

  onCheckAttributeGroupName($event: UntypedFormGroup): void {
    const reqBody = this.CreateAttributeGroupForm.value?.textField;
    const formData = reqBody;
    const formValues = this.CreateAttributeGroupForm.value?.textField;
    this.pagesService.onCheckAttributeGroupName(formData, formValues).subscribe(
      results => {
        if (results?.error?.code == 400 || results?.success?.code == 400) {
          this.nameFlag = true;
          this.enabledbtn1 = true;
          document.getElementById('textField_id')!.style.borderColor = 'red';
        } else if (results.success.code == 200) {
          this.nameFlag = false;
          document.getElementById('textField_id')!.style.borderColor = 'grey';
          this.createAttributeGroup();
          this.enabledbtn1 = true;
        }
      },
    );
  }

  CheckAttributeGroupName($event: UntypedFormGroup): void {
    const reqBody = this.CreateAttributeGroupForm.value?.textField;
    const formData = reqBody;
    const formValues = this.CreateAttributeGroupForm.value?.textField;
    this.pagesService.onCheckAttributeGroupName(formData, formValues).subscribe(
      results => {
        if (results?.error?.code == 400 || results?.success?.code == 400) {
          this.nameFlag = true;
          document.getElementById('textField_id')!.style.borderColor = 'red';
        } else if (results.success.code == 200) {
          this.nameFlag = false;
          document.getElementById('textField_id')!.style.borderColor = 'grey';
        }
      },
    );
  }

}