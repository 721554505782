<div class="layout-content pageStyle p-pb-0">
    <form [formGroup]="AddAsSubscriptionForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                <div class=" p-fluid   p-field    ">
                    <p>Are you sure you want to convert this plan as Subscription?</p>
                    <p><em><strong>Note:</strong>Once added as subscription, you won't be able to modify plan.</em></p>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right p-mb-2">
                <div class="btnwrap p-mr-3">
                    <button pButton type="button" class="p-button-brand2" (click)="onClickNo(AddAsSubscriptionForm);"
                        label="No"></button>
                </div>
                <div class="btnwrap">
                    <button pButton class="p-button-primary" type="button" (click)="navigateToAddSubscription();"
                        label="Yes"></button>
                </div>
            </div>
        </div>
    </form>
</div>