import { Injectable } from "@angular/core";
import { ACCEPT, APPROVE, APPROVED, APPROVE_ALL, CANCEL, MAP_USER, REJECT, REJECTED, REJECT_ALL, UNMAP_USER, USER_MANAGEMENT_COMPONENT_ID } from "app/constants";
import { LoggingEventService } from "app/logging-event.service";
import { ContextName, EventName, ItemName } from "app/shared/constants/logging-event.constants";
import { IEventContext, IEventData, IEventSubject } from "app/shared/interface/event.interface";
@Injectable({
    'providedIn':'root'
})

export class AccountUserMappingLogging {
    eventTypes: { [name: string]: number } = {};
    contextList: { [name: string]: number } = {};
    eventItems: { [name: string]: number } = {};
    constructor(
        private loggingService: LoggingEventService
    ) {
}

async preparAccountUserMappingInfo(userdetails: any) {
    const eventDataArray = [];
    let contextName = '';
    
    let eventData: IEventData = {
        componentId: 1,
        isEventContext: true,
        isEventSubject: true,
        eventTypeId: await this.loggingService.getEventId(EventName.MAP_UNMAP_USER),
        eventName:EventName.MAP_UNMAP_USER
    }


    let eventContextList: IEventContext[] = [];

    for (const u of userdetails.mapList1) {
        eventContextList.push(await this.loggingService.getEventContextData(ContextName.MAP_UNMAP_USER, u.value+' - '+u.label));
        
    }

    for (const u of userdetails.unmapList1) {
        eventContextList.push(await this.loggingService.getEventContextData(ContextName.MAP_UNMAP_USER, u.value+' - '+u.label));

    }

    eventContextList.push(await this.loggingService.getEventContextData(ContextName.MAP_UNMAP_USER,userdetails.accountId+' - '+userdetails.tenantName));
    eventContextList.push(await this.loggingService.getEventContextData(ContextName.MAP_UNMAP_USER,userdetails.userId+' - '+userdetails.userName));


    let eventSubjects:IEventSubject[]=[];

    if(userdetails.mapList1?.length>0){
        eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.MAP_UNMAP_USER,MAP_USER,UNMAP_USER));
    }

    if(userdetails.unmapList1?.length>0)
        {
            eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.MAP_UNMAP_USER,UNMAP_USER,MAP_USER));

        }


    eventData.eventContextList = eventContextList;
    eventData.eventSubjects=eventSubjects;
    eventDataArray.push(JSON.stringify(eventData));
    return JSON.stringify(eventDataArray);

}
}