import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
@Component({
  selector: 'jhi-manageuserfields',
  templateUrl: './ManageUserFields.component.html',
  styleUrls: ['./ManageUserFields.component.scss'],
})
export class ManageUserFieldsComponent implements OnInit {
  ManageUserFieldsForm = this.fb.group({
    checkbox: [false, []],
    textField: [null, []],
    select: [null, []],
    textField2: [null, []],
    select2: [null, []],
  });
  pageloadvalues: any;
  ccprimebreadcrumbList = [{ label: `User Management` }, { label: `Manage user fields` }];
  homeiconccprimebreadcrumb = { icon: 'pi pi-home', routerLink: ['/'] };
  htmlSrc: any;
  checkboxList: any[] = [
    { label: 'Middle Name', value: "'middleName'" },
    { label: 'Secondary Email address', value: "'secondEmail'" },
    { label: 'Secondary Mobile number', value: "'secondMobile'" },
    { label: 'Time Zone', value: "'timeZone'" },
    { label: 'Group', value: "'group'" },
    { label: 'Define Custom fields', value: "'custom'" },
  ];
  selectList: any[] = [];
  select2List: any[] = [];
  constructor(private router: Router, protected activatedRoute: ActivatedRoute, private fb: UntypedFormBuilder) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.ManageUserFieldsForm.patchValue(JSON.parse(data));
    }
  }
  ngOnInit(): void {
    this.htmlSrc = '';
  }
  onCcprimebutton2($event: UntypedFormGroup): void {}
  onCcprimebutton($event: UntypedFormGroup): void {}
  onCcprimebutton1($event: UntypedFormGroup): void {}
}
