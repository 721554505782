<div style="background-color: #F6F8FA !important;" (click)="mouseLeave()">
    <div class="p-d-flex p-jc-end">
        <p-messages key="myKey1" class="custom-alert-box" *ngIf="this.messageFlag"></p-messages>
        <p-messages key="myKey2" class="custom-alert-box" *ngIf="this.messageFlag1"></p-messages>
    </div>
    <div class="p-col-fixed p-mt-1 p-ml-1 p-pr-0 custom-breadcrumb">
        <p-breadcrumb [model]="ccprimebreadcrumbList" [home]="homeiconccprimebreadcrumb">
        </p-breadcrumb>
    </div>
    <form class="layout-content custom-form-layout" [formGroup]="ApplicationResourcesForm" autocomplete="off"
        novalidate>
        <div class="p-formgrid p-grid p-mb-6">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 left">
                <div>
                    <h5 class="custom-head1"> Application Resources</h5>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 left custom-tab-nav">
                <div class="docs-wrap">
                    <p-tabView [(activeIndex)]="ApplicationResourcesForm.value.tabview"
                        (onChange)="handleChangetabview($event)">
                        <p-tabPanel header="Resources Listing">
                            <div class="p-fluid p-formgrid p-grid">
                                <div class="p-col-12 p-sm-12 p-md-3 p-lg-3 p-xl-3 p-offset-0 left p-pl-0">
                                    <div class=" p-fluid   p-field ">
                                        <label
                                            [ngClass]="{'field-required': ApplicationResourcesForm.get('select')?.errors?.required}">
                                            Application
                                        </label> <span class='invalid-feedback-astrik'>*</span>
                                        <div class="p-fluid">
                                            <p-dropdown [filter]="true" filterBy="label" id="selectid" name="select"
                                                placeholder="Select" [options]="selectList" [autoDisplayFirst]="false"
                                                formControlName="select" (onChange)="select();getComponent();">
                                                <ng-template let-items pTemplate="item">
                                                    <div *ngIf="items?.label?.length &lt;= 35;">
                                                        {{items.label}}
                                                    </div>
                                                    <div *ngIf="items?.label?.length > 35;" pTooltip="{{items.label}}"
                                                        tooltipPosition="bottom">
                                                        {{items.label.substring(0,35) + "..." }}
                                                    </div>
                                                </ng-template>
                                            </p-dropdown>
                                        </div>
                                        <div class="invalid-feedback"
                                            *ngIf="ApplicationResourcesForm.get('select')!.invalid && (ApplicationResourcesForm.get('select')!.dirty || ApplicationResourcesForm.get('select')!.touched)">
                                            <div *ngIf="ApplicationResourcesForm.get('select')?.errors?.required">
                                                <label class="alert-icon alert-circle-icon"></label>
                                                <medium class="p-error">Application is required.</medium>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-12 p-sm-12 p-md-3 p-lg-3 p-xl-3 p-offset-undefined left">
                                    <div class=" p-fluid   p-field    ">
                                        <label
                                            [ngClass]="{'field-required': ApplicationResourcesForm.get('select1')?.errors?.required}">
                                            Component
                                        </label> <span class='invalid-feedback-astrik'>*</span>
                                        <div class="p-fluid">
                                            <p-dropdown [filter]="true" filterBy="label" id="select1id" name="select1"
                                                placeholder="Select" [options]="select1List" [autoDisplayFirst]="false"
                                                formControlName="select1" (onChange)="ResourceListing();">
                                                <ng-template let-items pTemplate="item">
                                                    <div *ngIf="items?.label?.length &lt;= 35;">
                                                        {{items.label}}
                                                    </div>
                                                    <div *ngIf="items?.label?.length > 35;" pTooltip="{{items.label}}"
                                                        tooltipPosition="bottom">
                                                        {{items.label.substring(0,35) + "..." }}
                                                    </div>
                                                </ng-template>
                                            </p-dropdown>
                                        </div>
                                        <div class="invalid-feedback"
                                            *ngIf="ApplicationResourcesForm.get('select1')!.invalid && (ApplicationResourcesForm.get('select1')!.dirty || ApplicationResourcesForm.get('select1')!.touched)">
                                            <div *ngIf="ApplicationResourcesForm.get('select1')?.errors?.required">
                                                <label class="alert-icon alert-circle-icon"></label>
                                                <medium class="p-error">Component is required.</medium>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="!flag2"
                                    class="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6 p-offset-undefined p-right p-pr-0">
                                    <div class=" p-fluid   p-field custom-search-fluid">
                                        <span class=" p-input-icon-left custom-search-span p-mr-3">
                                            <input type="text" (keydown.enter)="$event.preventDefault()"
                                                id="textField_id" name="textField_name" formControlName="textField"
                                                pInputText placeholder="Search"
                                                class="p-inputtext inputtextHeight custom-search-width "
                                                (input)="filterMethod($event.target.value)" />
                                            <i aria-hidden="true" class="pi pi-search"></i>
                                        </span>
                                        <div class="btnwrap custom-btn-height">
                                            <button pButton class="p-button-primary p-button" type="button"
                                                (click)="createResource();" label="Create">
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div class="custom-emptyImage-size" *ngIf="flag2">
                                    <label class="d1-icons group1-icon"></label>
                                    <label class="p-d-flex p-jc-center">Please select <span class="p-pl-1"
                                            style="font-weight: 500;">
                                            Application & Component</span></label>
                                </div>
                                <div
                                    class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left custom-map1 p-p-0">
                                    <div class="  p-field    ">
                                        <div class="mobilefriendlytable" responsiveLayout="stack"
                                            [ngClass]="{'content-section implementation': true}">
                                            <p-table #tid *ngIf="!flag2" name="table" [columns]="tablecols"
                                                [value]="tableList" [rows]="10"
                                                [globalFilterFields]="['resourceName','endPointUrl','method']"
                                                selectionMode="Multiple" [scrollable]="true" sortMode="single"
                                                [rowsPerPageOptions]="[10,25,50]" [paginator]="true"
                                                styleClass="p-datatable-customers custom-p-datatable-customers">
                                                <ng-template pTemplate="header">
                                                    <tr>
                                                        <th pSortableColumn='resourceName' scope="col"
                                                            style="width: 20%;">
                                                            Resource Name
                                                            <i class="p-sortable-column-icon icon-sort"
                                                                field='resourceName' ariaLabel="Activate to sort"
                                                                ariaLabelDesc="Activate to sort in descending order"
                                                                ariaLabelAsc="Activate to sort in ascending order"></i>
                                                            <p-columnFilter type="text" field="resourceName"
                                                                display="menu" class="p-ml-auto"></p-columnFilter>
                                                        </th>
                                                        <th pSortableColumn='endPointUrl' scope="col"
                                                            style="width: 30%;">
                                                            EndpointUrl
                                                            <i class="p-sortable-column-icon icon-sort"
                                                                field='endPointUrl' ariaLabel="Activate to sort"
                                                                ariaLabelDesc="Activate to sort in descending order"
                                                                ariaLabelAsc="Activate to sort in ascending order"></i>
                                                            <p-columnFilter type="text" field="endPointUrl"
                                                                display="menu" class="p-ml-auto"></p-columnFilter>
                                                        </th>
                                                        <th pSortableColumn='method' scope="col" style="width: 20%;">
                                                            Method
                                                            <i class="p-sortable-column-icon icon-sort" field='method'
                                                                ariaLabel="Activate to sort"
                                                                ariaLabelDesc="Activate to sort in descending order"
                                                                ariaLabelAsc="Activate to sort in ascending order"></i>
                                                            <p-columnFilter type="text" field="method" display="menu"
                                                                class="p-ml-auto"></p-columnFilter>
                                                        </th>
                                                        <th pSortableColumn='applicationRoleRequired' scope="col"
                                                            style="width: 22%;">
                                                            Application Role Required
                                                            <i class="p-sortable-column-icon icon-sort"
                                                                field='applicationRoleRequired'
                                                                ariaLabel="Activate to sort"
                                                                ariaLabelDesc="Activate to sort in descending order"
                                                                ariaLabelAsc="Activate to sort in ascending order"></i>
                                                            <p-columnFilter type="text" field="applicationRoleRequired"
                                                                display="menu" class="p-ml-auto"></p-columnFilter>
                                                        </th>
                                                        <th id="actionButton" style="width: 20%;">
                                                            <div class="p-d-flex p-jc-between p-ai-center"></div>
                                                        </th>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-rowData let-index="rowIndex">
                                                    <tr (mouseenter)="hoverIndex=index;highlight(index)"
                                                        (mouseleave)="hoverIndex=index;highlight(index)">
                                                        <td style="width: 20%;">
                                                            <div *ngIf="rowData.resourceName != null">
                                                                <span pTooltip="{{rowData.resourceName}}"
                                                                    tooltipPosition="bottom"
                                                                    *ngIf="rowData.resourceName?.length>=25">
                                                                    {{rowData.resourceName?.substring(0,25)+"..."
                                                                    }}</span>
                                                                <span
                                                                    *ngIf="rowData.resourceName?.length<25">{{rowData.resourceName}}</span>
                                                            </div>
                                                        </td>
                                                        <td class="custom-table-td" style="width: 30%;">
                                                            <span pTooltip="{{rowData.endPointUrl}}"
                                                                tooltipPosition="bottom"
                                                                *ngIf="rowData.endPointUrl?.length>=35">
                                                                {{rowData.endPointUrl?.substring(0,35)+"..."
                                                                }}</span>
                                                            <span
                                                                *ngIf="rowData.endPointUrl?.length<35">{{rowData.endPointUrl}}</span>
                                                        </td>
                                                        <td style="width: 20%;">{{rowData.method}}</td>
                                                        <td style="width: 20%;"><span
                                                                *ngIf="rowData.applicationRoleRequired==true">Yes</span>
                                                            <span
                                                                *ngIf="rowData.applicationRoleRequired==false">-</span>
                                                        </td>
                                                        <td style="width: 20%;">
                                                            <div class="button-details">
                                                                <button pButton tooltipPosition="bottom" pTooltip="Edit"
                                                                    style="width: 24px;height: 24px;padding: 0px;"
                                                                    icon="r-icons viewedit-icon"
                                                                    class="background-transparent"
                                                                    (click)="activeItem1=rowData;editResource();">
                                                                </button>
                                                                <button pButton tooltipPosition="bottom"
                                                                    pTooltip="Delete"
                                                                    style="width: 24px;height: 24px;padding: 0px;"
                                                                    icon="r-icons delete-icon"
                                                                    class="background-transparent"
                                                                    (click)="activeItem=rowData;deleteResource();">
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="paginatorright" let-state> Total Records:
                                                    {{state.totalRecords}} </ng-template>
                                                <ng-template pTemplate="emptymessage">
                                                    <tr>
                                                        <td colspan="7" class="p-p-0 p-text-center"> <p-card>
                                                                <div style="margin-top: 5%;height: 70px;">No
                                                                    matching records found</div>
                                                            </p-card> </td>
                                                    </tr>
                                                </ng-template>
                                            </p-table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </p-tabPanel>
                        <p-tabPanel header="Resources Mapping">
                            <div class="p-fluid p-formgrid p-grid">
                                <div class="p-col-12 p-sm-12 p-md-3 p-lg-3 p-xl-3 p-offset-0 left p-p-0 p-mr-3">
                                    <div class=" p-fluid   p-field    ">
                                        <label
                                            [ngClass]="{'field-required': ApplicationResourcesForm.get('select2')?.errors?.required}">
                                            Application
                                        </label> <span class='invalid-feedback-astrik'>*</span>
                                        <div class="p-fluid">
                                            <p-dropdown [filter]="true" filterBy="label" id="select2id" name="select2"
                                                placeholder="Select" [options]="select2List" [autoDisplayFirst]="false"
                                                formControlName="select2" (onChange)="getMappingComponent()"
                                                [showClear]="true">
                                                <ng-template let-items pTemplate="item">
                                                    <div *ngIf="items?.label?.length &lt;= 35;">
                                                        {{items.label}}
                                                    </div>
                                                    <div *ngIf="items?.label?.length > 35;" pTooltip="{{items.label}}"
                                                        tooltipPosition="bottom">
                                                        {{items.label.substring(0,35) + "..." }}
                                                    </div>
                                                </ng-template>
                                            </p-dropdown>
                                        </div>
                                        <div class="invalid-feedback"
                                            *ngIf="ApplicationResourcesForm.get('select2')!.invalid && (ApplicationResourcesForm.get('select2')!.dirty || ApplicationResourcesForm.get('select2')!.touched)">
                                            <div *ngIf="ApplicationResourcesForm.get('select2')?.errors?.required">
                                                <label class="alert-icon alert-circle-icon"></label>
                                                <medium class="p-error"> Application is required.</medium>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-12 p-sm-12 p-md-3 p-lg-3 p-xl-3 p-offset-undefined left p-p-0 p-mr-3">
                                    <div class=" p-fluid   p-field    ">
                                        <label
                                            [ngClass]="{'field-required': ApplicationResourcesForm.get('select3')?.errors?.required}">
                                            Component
                                        </label> <span class='invalid-feedback-astrik'>*</span>
                                        <div class="p-fluid">
                                            <p-dropdown [filter]="true" filterBy="label" id="select3id" name="select3"
                                                placeholder="Select" [options]="select3List" [autoDisplayFirst]="false"
                                                formControlName="select3" (onChange)="getRoles()" [showClear]="true">
                                                <ng-template let-items pTemplate="item">
                                                    <div *ngIf="items?.label?.length &lt;= 35;">
                                                        {{items.label}}
                                                    </div>
                                                    <div *ngIf="items?.label?.length > 35;" pTooltip="{{items.label}}"
                                                        tooltipPosition="bottom">
                                                        {{items.label.substring(0,35) + "..." }}
                                                    </div>
                                                </ng-template>
                                            </p-dropdown>
                                        </div>
                                        <div class="invalid-feedback"
                                            *ngIf="ApplicationResourcesForm.get('select3')!.invalid && (ApplicationResourcesForm.get('select3')!.dirty || ApplicationResourcesForm.get('select3')!.touched)">
                                            <div *ngIf="ApplicationResourcesForm.get('select3')?.errors?.required">
                                                <label class="alert-icon alert-circle-icon"></label>
                                                <medium class="p-error">Component is required.</medium>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-12 p-sm-12 p-md-3 p-lg-3 p-xl-3 p-offset-undefined left p-p-0 ">
                                    <div class=" p-fluid   p-field    ">
                                        <label
                                            [ngClass]="{'field-required': ApplicationResourcesForm.get('select4')?.errors?.required}">
                                            Application Role
                                        </label> <span class='invalid-feedback-astrik'>*</span>
                                        <div class="p-fluid">
                                            <p-dropdown [filter]="true" filterBy="label" id="select4id" name="select4"
                                                placeholder="Select" [options]="select4List" [filter]="true"
                                                [autoDisplayFirst]="false" formControlName="select4"
                                                (onChange)="getMappedDetails()" [showClear]="true">
                                                <ng-template let-items pTemplate="item">
                                                    <div *ngIf="items?.label?.length &lt;= 35;">
                                                        {{items.label}}
                                                    </div>
                                                    <div *ngIf="items?.label?.length > 35;" pTooltip="{{items.label}}"
                                                        tooltipPosition="bottom">
                                                        {{items.label.substring(0,35) + "..." }}
                                                    </div>
                                                </ng-template>
                                            </p-dropdown>
                                        </div>
                                        <div class="invalid-feedback"
                                            *ngIf="ApplicationResourcesForm.get('select4')!.invalid && (ApplicationResourcesForm.get('select4')!.dirty || ApplicationResourcesForm.get('select4')!.touched)">
                                            <div *ngIf="ApplicationResourcesForm.get('select4')?.errors?.required">
                                                <label class="alert-icon alert-circle-icon"></label>
                                                <medium class="p-error">Role is required.</medium>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-12 p-sm-12 p-md-3 p-lg-3 p-xl-3 p-offset-undefined left">
                                </div>
                                <div class="custom-emptyImage-size" *ngIf="flag1">
                                    <label class="d1-icons group1-icon"></label>
                                    <label class="p-d-flex p-jc-center">Please fill above <span class="p-pl-1"
                                            style="font-weight: 500;"> Mandatory
                                            Fields</span></label>
                                </div>
                                <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left p-p-0">
                                    <div class=" p-fluid   p-field    ">
                                        <div *ngIf="!flag1" [class]="styleClass" [ngStyle]="style"
                                            class="p-grid custom-picklist-with-checkbox"
                                            [ngClass]="{'p-picklist p-component p-widget p-helper-clearfix': true,'p-picklist-responsive': responsive}">
                                            <div class="p-col-fixed p-picklist-list-wrapper p-picklist-source-wrapper"
                                                [ngClass]="{'p-picklist-listwrapper-nocontrols':!showSourceControls}">
                                                <div class="p-picklist-header">
                                                    <div class="p-picklist-title">Resources
                                                        <span class="p-picklist-count">{{pickListList.length}}</span>
                                                    </div>
                                                </div>
                                                <div class="p-picklist-filter-container"
                                                    *ngIf="filterBy && showSourceFilter !== false">
                                                    <div class="p-picklist-filter">
                                                        <span class="p-input-icon-left" style="margin: 0px;">
                                                            <input #sourceFilter type="text" role="textbox"
                                                                (keydown.enter)="$event.preventDefault()"
                                                                class="p-picklist-filter-input p-inputtext inputtextHeight p-component"
                                                                placeholder="Search By Name"
                                                                (input)="filterMethodPick($event.target.value,pickListList1,'pick1source')"
                                                                [disabled]="disabled" formControlName="sourceFilter">
                                                            <i aria-hidden="true" class="pi pi-search"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                                <ul #sourcelist class="cdk-drop-list p-picklist-list p-picklist-source"
                                                    [ngClass]="{'p-picklist-highlight': listHighlightSource}"
                                                    [ngStyle]="sourceStyle">
                                                    <ng-template ngFor let-item [ngForOf]="pickListList"
                                                        [ngForTrackBy]="sourceTrackBy || trackBy" let-i="index"
                                                        let-l="last">
                                                        <li [ngClass]="{'cdk-drag p-picklist-item p-ripple':true,'p-state-highlight':isSelected(item,selectedItemsSource), 'p-state-disabled': disabled}"
                                                            [style.display]="isItemVisible(item, SOURCE_LIST) ? 'block' : 'none'">
                                                            <div class="product-item"
                                                                style="padding-top: 0rem;padding-bottom: 0rem;height: 25px;">
                                                                <span class="list-check image-container p-mr-2 p-mt-0">
                                                                    <p-checkbox name="groupname" [value]="isChecked"
                                                                        #picklistCheckboxes [(ngModel)]="item.isChecked"
                                                                        binary="true"
                                                                        (ngModelChange)="onItemClick($event,item,selectedItemsSource,onSourceSelect)"
                                                                        [ngModelOptions]="{standalone: true}">
                                                                    </p-checkbox>
                                                                </span>
                                                                <div class="product-list-detail">
                                                                    <p pTooltip="{{item.label?.length>= 30 ? item.label :
                                                                        ''}}" tooltipPosition="bottom">
                                                                        <ng-container
                                                                            *ngIf="item.label?.length > 30; else tooltip">
                                                                            {{item.label?.substring(0,30) +
                                                                            "..." }}
                                                                        </ng-container>
                                                                        <ng-template #tooltip>
                                                                            {{item.label}}
                                                                        </ng-template>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li class="p-picklist-droppoint" *ngIf="dragdrop&&l"
                                                            (dragover)="onDragOver($event, i + 1, SOURCE_LIST)"
                                                            (drop)="onDrop($event, i + 1, SOURCE_LIST)"
                                                            (dragleave)="onDragLeave($event, SOURCE_LIST)"
                                                            [ngClass]="{'p-picklist-droppoint-highlight': (i + 1 === dragOverItemIndexSource)}">

                                                        </li>
                                                    </ng-template>
                                                </ul>
                                            </div>
                                            <div class="p-col-fixed p-picklist-buttons p-picklist-transfer-buttons">
                                                <button type="button" pButton pRipple icon="pi pi-angle-right"
                                                    [disabled]="disabled"
                                                    class="p-ripple p-button p-component p-button-icon-only"
                                                    (click)="moveRight()">

                                                    <span class="p-ink"></span>
                                                </button>
                                                <button type="button" pButton icon="pi pi-angle-double-right"
                                                    [disabled]="disabled"
                                                    class="p-ripple p-button p-component p-button-icon-only"
                                                    (click)="moveAllRight()">

                                                    <span class="p-ink"></span>
                                                </button>
                                                <button type="button" pButton icon="pi pi-angle-left"
                                                    [disabled]="disabled"
                                                    class="p-ripple p-button p-component p-button-icon-only"
                                                    (click)="moveLeft()">

                                                    <span class="p-ink"></span>
                                                </button>
                                                <button type="button" pButton icon="pi pi-angle-double-left"
                                                    [disabled]="disabled"
                                                    class="p-ripple p-button p-component p-button-icon-only"
                                                    (click)="moveAllLeft()">

                                                    <span class="p-ink"></span>
                                                </button>
                                            </div>
                                            <div class="p-col-fixed p-picklist-list-wrapper p-picklist-target-wrapper"
                                                [ngClass]="{'p-picklist-listwrapper-nocontrols':!showTargetControls}">
                                                <div class="p-picklist-header">
                                                    <div class="p-picklist-title">Mapped Resources
                                                        <span
                                                            class="p-picklist-count">{{pickListtargetList.length}}</span>
                                                    </div>
                                                </div>
                                                <div class="p-picklist-filter-container"
                                                    *ngIf="filterBy && showSourceFilter !== false">
                                                    <div class="p-picklist-filter">
                                                        <span class="p-input-icon-left" style="margin: 0px;">
                                                            <input #sourceFilter type="text" role="textbox"
                                                                (keydown.enter)="$event.preventDefault()"
                                                                class="p-picklist-filter-input p-inputtext inputtextHeight p-component"
                                                                placeholder="Search By Name"
                                                                (input)="filterMethodPick($event.target.value,pickListtargetList1,'pick1target')"
                                                                [disabled]="disabled" formControlName="sourceFilter">
                                                            <i aria-hidden="true" class="pi pi-search"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                                <ul #targetlist cdkDropList
                                                    class="cdk-drop-list p-picklist-list p-picklist-target"
                                                    [ngClass]="{'p-picklist-highlight': listHighlightTarget}"
                                                    [ngStyle]="targetStyle">
                                                    <ng-template ngFor let-item [ngForOf]="pickListtargetList"
                                                        [ngForTrackBy]="targetTrackBy || trackBy" let-i="index"
                                                        let-l="last">
                                                        <li pRipple cdkDrag role="option"
                                                            [ngClass]="{'cdk-drag p-picklist-item p-ripple':true,'p-state-highlight':isSelected(item,selectedItemsTarget), 'p-state-disabled': disabled}"
                                                            [style.display]="isItemVisible(item, TARGET_LIST) ? 'flex' : 'none'">
                                                            <div class="product-item"
                                                                style="padding-top: 0rem;padding-bottom: 0rem;height: 25px;">
                                                                <span class="list-check image-container p-mr-2 p-mt-0">
                                                                    <p-checkbox name="groupname" [value]="isChecked"
                                                                        #picklistCheckboxes [(ngModel)]="item.isChecked"
                                                                        binary="true"
                                                                        (ngModelChange)="onItemClick($event,item,selectedItemsTarget,onTargetSelect)"
                                                                        [ngModelOptions]="{standalone: true}">
                                                                    </p-checkbox>
                                                                </span>
                                                                <div class="product-list-detail">
                                                                    <p pTooltip="{{item.label?.length>= 30 ? item.label :''}}"
                                                                        tooltipPosition="bottom">
                                                                        <ng-container
                                                                            *ngIf="item.label?.length > 30; else tooltip">
                                                                            {{item.label?.substring(0,30) +
                                                                            "..." }}
                                                                        </ng-container>
                                                                        <ng-template #tooltip>
                                                                            {{item.label}}
                                                                        </ng-template>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li class="p-picklist-droppoint" *ngIf="dragdrop&&l"
                                                            (dragover)="onDragOver($event, i + 1, TARGET_LIST)"
                                                            (drop)="onDrop($event, i + 1, TARGET_LIST)"
                                                            (dragleave)="onDragLeave($event, TARGET_LIST)"
                                                            [ngClass]="{'p-picklist-droppoint-highlight': (i + 1 === dragOverItemIndexTarget)}">
                                                        </li>
                                                    </ng-template>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right p-p-0"
                                    *ngIf="!flag1">
                                    <div class="btnwrap custom-picklist-btn">
                                        <button pButton class="p-button-primary p-button" type="button" [disabled]="btn"
                                            label="Save" (click)="onSave($event)">
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </p-tabPanel>
                    </p-tabView>
                </div>
            </div>
        </div>
    </form>
</div>