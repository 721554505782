import { Observable, from } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse, HttpHandler, HttpEvent } from '@angular/common/http';
import { SERVER_API_URL, REBAC_UM_ENTITLEMENT_FLAG } from '../../app.constants';
import { LoadingIndicatorService } from '../../loading-indicator.service';
import { UserManagementLoggingService } from 'app/pages/EditUser/user-management-logging.service';
import { EntitlementManagementLoggingService } from 'app/pages/ManageEntitlements/ManageEntitlement-logging.service';
import { RoleManagementLoggingService } from 'app/pages/ManageRoles/ManageRoles-logging.service';
import { ApprovalManagementLoggingService } from 'app/pages/ApprovalListing/approval-management-logging.service';
import { AccountUserMappingLogging } from 'app/pages/AccountUserMapping/AccountUserMapping-logging';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
  rebacFlag: string;
  constructor(
    private localStorage: LocalStorageService,
    private sessionStorage: SessionStorageService,
    private loadingIndicatorService: LoadingIndicatorService,
    private roleLoggingService: RoleManagementLoggingService,
    private entitlementLoggingService: EntitlementManagementLoggingService,
    private approvalLoggingService: ApprovalManagementLoggingService,
    private umLoggingService: UserManagementLoggingService,
    private accountUserMappingLogging: AccountUserMappingLogging
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.rebacFlag = REBAC_UM_ENTITLEMENT_FLAG + "";
    let req = {};
    req['body'] = request.body;
    req['url'] = request.url;
    localStorage.setItem("Request url", JSON.stringify(req));
    if (localStorage.getItem("tenant") != null) {
      request = request.clone({
        setHeaders: {
          tenantId: localStorage.getItem("tenant")
        }
      });
    }

    if (localStorage.getItem("applicationId") != null) {
      request = request.clone({
        setHeaders: {
          applicationId: localStorage.getItem("applicationId")
        }
      });
    }

    if (localStorage.getItem("componentId") != null) {
      request = request.clone({
        setHeaders: {
          componentId: localStorage.getItem("componentId")
        }
      });
    }
    if (this.rebacFlag == "true") {
      if (!request?.url || (request.url.startsWith('http') && !(SERVER_API_URL && request.url.startsWith(SERVER_API_URL)))) {
        let reqUrl = request.url;
        switch (true) {
          case reqUrl.includes('/registerEntitlement'):
            return from(this.entitlementLoggingService.prepareCreateEntEvtData(request.body)).pipe(
              mergeMap((eventData: any) => {
                return this.modifyRequestBody(request, next, eventData);
              })
            );
          case !reqUrl.includes('umManageEntitlements/updateEntitlementStatus') && reqUrl.includes('umManageEntitlements/updateEntitlement'):
            return from(this.entitlementLoggingService.prepareUpdateEntEvtData(request.body)).pipe(
              mergeMap((eventData: any) => {
                return this.modifyRequestBody(request, next, eventData);
              })
            );
          case reqUrl.includes('/umManageEntitlements/getEntitlementActivityLog'):
            return from(this.entitlementLoggingService.prepareEntitlementActivityLog(request.body)).pipe(
              mergeMap((eventData: any) => {
                return this.modifyFormDataRequest(request, next, eventData);
              })
            );
          case reqUrl.includes('/umManageEntitlements/getAllEntitlementList'):
            return from(this.entitlementLoggingService.prepareEntPageEventsData(request.body)).pipe(
              mergeMap((eventData: any) => {
                return this.modifyRequestBody(request, next, eventData);
              })
            );
          case reqUrl.includes('umManageEntitlements/addApprovals'):
            return from(this.entitlementLoggingService.prepareEditApproverLogData(request.body)).pipe(
              mergeMap((eventData: any) => {
                return this.modifyRequestBody(request, next, eventData);
              })
            );
          case reqUrl.includes('getViewApprovalList'):
            return from(this.entitlementLoggingService.prepareViewApprovalList(request.body)).pipe(
              mergeMap((eventData: any) => {
                return this.modifyFormDataRequest(request, next, eventData);
              })
            );

          case reqUrl.includes('/getAllActiveComponents'):
            return from(this.entitlementLoggingService.prepareViewManageEntitlement(request.body)).pipe(
              mergeMap((eventData: any) => {
                return this.modifyRequestBody(request, next, eventData);
              })
            );
          case reqUrl.includes('/getUserByEntitlement'):
            console.log("getUserByEntitlement");
            return from(this.entitlementLoggingService.prepareViewEntitlementdata(request.body)).pipe(
              mergeMap((eventData: any) => {
                return this.modifyRequestBody(request, next, eventData);
              })
            );
          case reqUrl.includes('/umManageEntitlementDetails/updateEntitlementDetails'):
            return from(this.entitlementLoggingService.prepareUpdateEntitlementDetails(request.body)).pipe(
              mergeMap((eventData: any) => {
                return this.modifyRequestBody(request, next, eventData);
              })
            );
          case reqUrl.includes('usermanagement/save-user-entitlements'):
            return from(this.entitlementLoggingService.prepareUploadEntitlements(request.body)).pipe(
              mergeMap((eventData: any) => {
                return this.modifyFormDataRequest(request, next, eventData);
              })
            );
          case reqUrl.includes('/umRoleDetails/updateRoleDetails'):
            return from(this.roleLoggingService.prepareEditRoleLogData(request.body)).pipe(
              mergeMap((eventData: any) => {
                console.log("eventData", eventData);
                return this.modifyRequestBody(request, next, eventData);
              })
            );
          case reqUrl.includes('umManageRole/getAllRoleList'):
            return from(this.roleLoggingService.prepareViewManageRoleListData(request.body)).pipe(
              mergeMap((eventData: any) => {
                return this.modifyRequestBody(request, next, eventData);
              })
            );
          case reqUrl.includes('/getAllUserListForRole'):
            return from(this.roleLoggingService.prepareViewRoleLogData(request.body)).pipe(
              mergeMap((eventData: any) => {
                return this.modifyRequestBody(request, next, eventData);
              })
            );
          case reqUrl.includes('/umManageApproval/approveAllEntitlements'):
            return from(this.approvalLoggingService.prepareApproveAllData(request.body)).pipe(
              mergeMap((eventData: any) => {
                return this.modifyRequestBody(request, next, eventData);
              })
            );
          case reqUrl.includes('/umManageApproval/approveEntitlement'):
            return from(this.approvalLoggingService.prepareApproveEntitlementData(request.body)).pipe(
              mergeMap((eventData: any) => {
                return this.modifyRequestBody(request, next, eventData);
              })
            );
          case reqUrl.includes('/umManageApproval/rejectEntitlement'):
            return from(this.approvalLoggingService.prepareRejectEntitlementData(request.body)).pipe(
              mergeMap((eventData: any) => {
                return this.modifyRequestBody(request, next, eventData);
              })
            );
          case reqUrl.includes('/umManageApproval/rejectAllEntitlements'):
            return from(this.approvalLoggingService.prepareRejectAllData(request.body)).pipe(
              mergeMap((eventData: any) => {
                return this.modifyRequestBody(request, next, eventData);
              })
            );
          case reqUrl.includes('/umManageApproval/updateOwnershipStatus'):
            return from(this.approvalLoggingService.prepareUpdateOwnershipStatus(request.body)).pipe(
              mergeMap((eventData: any) => {
                return this.modifyRequestBody(request, next, eventData);
              })
            );
          case reqUrl.includes('/getUserMappedAttributeList'):
            return from(this.umLoggingService.prepareViewUserData(request.body)).pipe(
              mergeMap((eventData: any) => {
                return this.modifyFormDataRequest(request, next, eventData);
              })
            );
          case reqUrl.includes('/umManageApproval/getUserEntitlementApprovals'):
            return from(this.umLoggingService.prepareUserEntitlementApprovals(request.body)).pipe(
              mergeMap((eventData: any) => {
                return this.modifyRequestBody(request, next, eventData);
              })
            );
          case reqUrl.includes('umUserDetails/updateUserMappings'):
            return from(this.umLoggingService.prepareEditUserActivityLog(request.body)).pipe(
              mergeMap((eventData: any) => {
                return this.modifyRequestBody(request, next, eventData);
              })
            );
            case reqUrl.includes('umManageRole/getAllRoleList'):
              return from(this.roleLoggingService.prepareViewManageRoleListData(request.body)).pipe(
                  mergeMap((eventData: any) => {
                      return this.modifyRequestBody(request, next, eventData);
                  })
              );
              case reqUrl.includes('/getAllUserListForRole'):
                return from(this.roleLoggingService.prepareViewRoleLogData(request.body)).pipe(
                    mergeMap((eventData: any) => {
                        return this.modifyRequestBody(request, next, eventData);
                    })
                );
                case reqUrl.includes('/umManageApproval/approveAllEntitlements'):
                  return from(this.approvalLoggingService.prepareApproveAllData(request.body)).pipe(
                      mergeMap((eventData: any) => {
                          return this.modifyRequestBody(request, next, eventData);
                      })
                  );
                  case reqUrl.includes('/umManageApproval/approveEntitlement'):
                    return from(this.approvalLoggingService.prepareApproveEntitlementData(request.body)).pipe(
                        mergeMap((eventData: any) => {
                            return this.modifyRequestBody(request, next, eventData);
                        })
                    );
                  case reqUrl.includes('/umManageApproval/rejectEntitlement'):
                    return from(this.approvalLoggingService.prepareRejectEntitlementData(request.body)).pipe(
                      mergeMap((eventData: any) => {
                        return this.modifyRequestBody(request, next, eventData);
                      })
                   );  
                  case reqUrl.includes('/umManageApproval/approveAllEntitlements'):
                    return from(this.approvalLoggingService.prepareApproveAllData(request.body)).pipe(
                        mergeMap((eventData: any) => {
                            return this.modifyRequestBody(request, next, eventData);
                        })
                  ); 
                  case reqUrl.includes('/umManageApproval/rejectAllEntitlements'):
                    return from(this.approvalLoggingService.prepareRejectAllData(request.body)).pipe(
                        mergeMap((eventData: any) => {
                            return this.modifyRequestBody(request, next, eventData);
                        })
                    ); 
                  case reqUrl.includes('/umManageApproval/updateOwnershipStatus'):
                    return from(this.approvalLoggingService.prepareUpdateOwnershipStatus(request.body)).pipe(
                        mergeMap((eventData: any) => {
                            return this.modifyRequestBody(request, next, eventData);
                        })
                    );
                    // case reqUrl.includes('/getUserMappedAttributeList'):
                    // return from(this.umLoggingService.prepareViewUserData(request.body)).pipe(
                    //     mergeMap((eventData: any) => {
                    //         return this.modifyFormDataRequest(request, next, eventData);
                    //     })
                    // );
                    case reqUrl.includes('/umManageApproval/getUserEntitlementApprovals'):
                    return from(this.umLoggingService.prepareUserEntitlementApprovals(request.body)).pipe(
                        mergeMap((eventData: any) => {
                            return this.modifyRequestBody(request, next, eventData);
                        })
                    );
                    case reqUrl.includes('umUserDetails/updateUserMappings'):
                      return from(this.umLoggingService.prepareEditUserActivityLog(request.body)).pipe(
                          mergeMap((eventData: any) => {
                              return this.modifyRequestBody(request, next, eventData);
                          })
                      );
                      // case reqUrl.includes('/map-unmap-user-to-account'):
                      //   return from(this.accountUserMappingLogging.preparAccountUserMappingInfo(request.body)).pipe(
                      //       mergeMap((eventData: any) => {
                      //           return this.modifyRequestBody(request, next, eventData);
                      //       })
                      //   );
                      //   case reqUrl.includes('/accounts/deactivate-account'):
                      //     return from(this.umLoggingService.prepareAccountStatus(request.body)).pipe(
                      //         mergeMap((eventData: any) => {
                      //             return this.modifyRequestBody(request, next, eventData);
                      //         })
                      //     );
                      //   case reqUrl.includes('/accounts/activate-account'):
                      //     return from(this.umLoggingService.prepareAccountStatus(request.body)).pipe(
                      //         mergeMap((eventData: any) => {
                      //             return this.modifyRequestBody(request, next, eventData);
                      //         })
                      //     );
                      //   case reqUrl.includes('/map-unmap-endpoints-with-role'):
                      //     return from(this.umLoggingService.prepareEndpointRoleMappingInfo(request.body)).pipe(
                      //         mergeMap((eventData: any) => {
                      //             return this.modifyRequestBody(request, next, eventData);
                      //         })
                      //     );
                      //   case reqUrl.includes('/map-unmap-application-to-tenant/tenantid/'):
                      //     return from(this.umLoggingService.prepareTenantMappingInfo(request.body)).pipe(
                      //         mergeMap((eventData: any) => {
                      //             return this.modifyRequestBody(request, next, eventData);
                      //         })
                      //     );
                      //   case reqUrl.includes('/edit-application-role/host-application-roleid/'):
                      //   return from(this.umLoggingService.prepareEditApplicationRoleInfo(request.body)).pipe(
                      //       mergeMap((eventData: any) => {
                      //           return this.modifyRequestBody(request, next, eventData);
                      //       })
                      //   );
                      // case reqUrl.includes('/edit-host-application-endpoint'):
                      //   return from(this.umLoggingService.prepareEditResourceEndpointInfo(request.body)).pipe(
                      //       mergeMap((eventData: any) => {
                      //           return this.modifyRequestBody(request, next, eventData);
                      //       })
                      //   );
                      //   case reqUrl.includes('/user/edit-user'):
                      //     return from(this.umLoggingService.prepareEditUserInfo(request.body)).pipe(
                      //         mergeMap((eventData: any) => {
                      //             return this.modifyRequestBody(request, next, eventData);
                      //         })
                      //     );
                      //     case reqUrl.includes('/user/create-user'):
                      //       return from(this.umLoggingService.prepareCreateUserLogData(request.body)).pipe(
                      //         mergeMap((eventData: any) => {
                      //           return this.modifyFormDataRequest(request, next, eventData);
                      //         })
                      //       );
                          case reqUrl.includes('/logging/logUserEvent'):
                            return from(this.umLoggingService.prepareLogUserEventData(request.body)).pipe(
                              mergeMap((eventData: any) => {
                                return this.modifyRequestBody(request, next, eventData);
                              })
                              );
                        
          default:
            return this.callApi(next, request);
        }
      }
    }
    else if (!request?.url || (request.url.startsWith('http') && !(SERVER_API_URL && request.url.startsWith(SERVER_API_URL)))) {
      this.loadingIndicatorService.onStarted(request);
      return next.handle(request).pipe(
        tap({
          next: (event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              this.loadingIndicatorService.onFinished(request);
            }
          },
          error: (err: any) => {
            if (err instanceof HttpErrorResponse) {
              this.loadingIndicatorService.onFinished(request);
            }
          }
        })
      );
    }

    const token = this.localStorage.retrieve('authenticationToken') || this.sessionStorage.retrieve('authenticationToken');
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token
        }
      });
    }

    this.loadingIndicatorService.onStarted(request);
    return next.handle(request).pipe(
      tap({
        next: (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.loadingIndicatorService.onFinished(request);
          }
        },
        error: (err: any) => {
          if (err instanceof HttpErrorResponse) {
            this.loadingIndicatorService.onFinished(request);
          }
        }
      })
    );
  }
  private callApi(next: HttpHandler, request: HttpRequest<any>): Observable<HttpEvent<any>> {
    if (request.url.indexOf("/validateFile") == -1 &&
      request.url.indexOf("/updateProportions") == -1 &&
      request.url.indexOf("/uploadHistoricFile") == -1 &&
      request.url.indexOf("/uploadFactorFile") == -1)
      this.loadingIndicatorService.onStarted(request);
    return next.handle(request).pipe(
      tap({
        next: (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.loadingIndicatorService.onFinished(request);
          }
        },
        error: (err: any) => {
          if (err instanceof HttpErrorResponse) {
            this.loadingIndicatorService.onFinished(request);
          }
        }
      })
    );
  }

  private modifyFormDataRequest(request: HttpRequest<any>, next: HttpHandler, eventData: string) {
    let body = request.body;
    const modifiedFormData = new FormData();
    body.forEach((value, key) => {
      modifiedFormData.append(key, value);
    });
    modifiedFormData.append('eventData', eventData);
    request = request.clone({ body: modifiedFormData });
    return this.callApi(next, request);
  }

  private modifyRequestBody(request: HttpRequest<any>, next: HttpHandler, eventData: string) {
    const modifiedBody = {
      ...request.body,
      eventData: eventData
    };
    request = request.clone({ body: modifiedBody });
    return this.callApi(next, request);
  }
}
