<div style="background-color:#F6F8FA;" (click)="mouseLeave()">
    <div class="p-d-flex p-jc-end">
        <p-messages key="myKey1" class="custom-alert-box" *ngIf="this.messageFlag"></p-messages>
    </div>
    <div class="p-col-fixed p-mt-1 p-ml-1 p-pr-0 custom-breadcrumb">
        <p-breadcrumb [model]="ccprimebreadcrumbList" [home]="homeiconccprimebreadcrumb">
        </p-breadcrumb>
    </div>
    <form class="layout-content custom-form-layout" [formGroup]="TenantListingForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                <div class="p-grid p-mb-3 p-mt-0">
                    <span class="p-col p-p-0">
                        <h5 class="custom-head1">Tenants</h5>
                    </span>
                </div>
                <div class="card custom-card p-mb-6">
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-p-0 p-offset-undefined left">
                        <div class=" p-fluid   p-field    ">
                            <div class="p-fluid p-formgrid p-grid" style="padding-top:8px ;">
                                <div class="p-col-12 p-p-0">
                                    <div *ngIf="this.errorFlag" class="custom-errorFlagDiv">
                                        <label class="c-icons error-icon"></label><br>
                                        <label class="e-error">No Tenant found</label><br>
                                        <br>
                                        <div class="btnwrap">
                                            <button pButton type="button" pButton label="Add New Tenant"
                                                (click)="createtenant();"
                                                class="p-button-outlined p-button-danger custom-button-padding"
                                                [disabled]="this.pagesService.tenantManagementReadOnlyFlag"></button>
                                        </div>
                                    </div>
                                    <div class="ui-fluid p-formgrid p-grid" *ngIf="!this.errorFlag">
                                        <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                                            <div class=" p-fluid   p-field    ">
                                                <span class=" p-input-icon-left">
                                                    <input type="text" (keydown.enter)="$event.preventDefault()"
                                                        id="textField_id" name="textField_name"
                                                        formControlName="textField" pInputText placeholder="Search"
                                                        class="p-inputtext inputtextHeight  "
                                                        (input)="TenantFilterMethod($event.target.value)"
                                                        style="width: 304px;" />
                                                    <i aria-hidden="true" class="pi pi-search"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-sm-12 p-md-12 p-lg-8 p-xl-8 p-offset-undefined right">
                                            <div class="p-col-fixed p-right p-mt-1">
                                                <span class="btnwrap" style="margin-right:-4px" *ngIf="!this.errorFlag">
                                                    <button pButton
                                                        class="p-button-primary p-button-sm custom-button-padding"
                                                        type="button" (click)="createtenant();" label="Add New Tenant"
                                                        [disabled]="this.pagesService.tenantManagementReadOnlyFlag">
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined p-right">
                                        </div>
                                        <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                                            <div class="  p-field    ">
                                                <div class="mobilefriendlytable" responsiveLayout="stack"
                                                    [ngClass]="{'content-section implementation': true}">

                                                    <p-table #tableid [value]="tableList" name="table"
                                                        [columns]="tablecols" styleClass="p-datatable-customers"
                                                        [rows]="10" [rowsPerPageOptions]="[10,25,50]"
                                                        selectionMode="Multiple" [paginator]="true" sortMode="single"
                                                        [scrollable]="true"
                                                        [globalFilterFields]="['tenantName','tenantDomain','fullName','email','userCount']">
                                                        <ng-template pTemplate="header">
                                                            <tr>
                                                                <th pSortableColumn="tenantName" scope="col"
                                                                    style="width:20%">
                                                                    <div class="p-d-flex p-jc-between p-ai-center">
                                                                        Tenant Name
                                                                        <i class="p-sortable-column-icon icon-sort"
                                                                            ariaLabel="Activate to sort"
                                                                            ariaLabelDesc="Activate to sort in descending order"
                                                                            ariaLabelAsc="Activate to sort in ascending order"
                                                                            field="tenantName"></i>
                                                                        <p-columnFilter type="text" field="tenantName"
                                                                            display="menu"
                                                                            class="p-ml-auto"></p-columnFilter>
                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="tenantDomain" scope="col"
                                                                    style="width:20%">
                                                                    <div class="p-d-flex p-jc-between p-ai-center">
                                                                        Tenant Domain
                                                                        <i class="p-sortable-column-icon icon-sort"
                                                                            ariaLabel="Activate to sort"
                                                                            ariaLabelDesc="Activate to sort in descending order"
                                                                            ariaLabelAsc="Activate to sort in ascending order"
                                                                            field="tenantDomain"></i>
                                                                        <p-columnFilter type="text" field="tenantDomain"
                                                                            display="menu"
                                                                            class="p-ml-auto"></p-columnFilter>
                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="fullName" scope="col"
                                                                    style="width:20%">
                                                                    <div class="p-d-flex p-jc-between p-ai-center">
                                                                        Tenant Owner name
                                                                        <i class="p-sortable-column-icon icon-sort"
                                                                            ariaLabel="Activate to sort"
                                                                            ariaLabelDesc="Activate to sort in descending order"
                                                                            ariaLabelAsc="Activate to sort in ascending order"
                                                                            field="fullName"></i>
                                                                        <p-columnFilter type="text" field="fullName"
                                                                            display="menu"
                                                                            class="p-ml-auto"></p-columnFilter>
                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="email" scope="col"
                                                                    style="width:20%">
                                                                    <div class="p-d-flex p-jc-between p-ai-center">
                                                                        Tenant Owner Email
                                                                        <i class="p-sortable-column-icon icon-sort"
                                                                            ariaLabel="Activate to sort"
                                                                            ariaLabelDesc="Activate to sort in descending order"
                                                                            ariaLabelAsc="Activate to sort in ascending order"
                                                                            field="email"></i>
                                                                        <p-columnFilter type="text" field="email"
                                                                            display="menu"
                                                                            class="p-ml-auto"></p-columnFilter>
                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="userCount" scope="col"
                                                                    style="width:10%">
                                                                    <div class="p-d-flex p-jc-between p-ai-center">
                                                                        Users
                                                                        <i class="p-sortable-column-icon icon-sort"
                                                                            ariaLabel="Activate to sort"
                                                                            ariaLabelDesc="Activate to sort in descending order"
                                                                            ariaLabelAsc="Activate to sort in ascending order"
                                                                            field="userCount"></i>
                                                                        <p-columnFilter type="text" field="userCount"
                                                                            display="menu"
                                                                            class="p-ml-auto"></p-columnFilter>
                                                                    </div>
                                                                </th>
                                                                <th id="actionButton" style="width: 10%;">
                                                                    <div></div>
                                                                </th>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-rowData let-index="rowIndex">
                                                            <tr (mouseenter)="hoverIndex=index;highlight(index)"
                                                                (mouseleave)="hoverIndex=index;highlight(index)"
                                                                [pSelectableRow]="rowData">
                                                                <td style="width:20%">
                                                                    <span *ngIf="rowData.tenantName!=null">
                                                                        <span pTooltip="{{rowData.tenantName}}"
                                                                            *ngIf="rowData.tenantName?.length >= 15"
                                                                            tooltipPosition="bottom">
                                                                            {{rowData.tenantName?.substring(0,15) +
                                                                            "..." }}
                                                                        </span>
                                                                        <span *ngIf="rowData.tenantName?.length < 15">
                                                                            {{rowData.tenantName}}
                                                                        </span>
                                                                    </span>
                                                                    <span *ngIf="rowData.tenantName==null">-</span>
                                                                </td>
                                                                <td class="custom-description" style="width:20%">
                                                                    <span *ngIf="rowData.tenantDomain!=null">
                                                                        <span pTooltip="{{rowData.tenantDomain}}"
                                                                            *ngIf="rowData.tenantDomain?.length >= 20"
                                                                            tooltipPosition="bottom">
                                                                            {{rowData.tenantDomain?.substring(0,20) +
                                                                            "..." }}
                                                                        </span>
                                                                        <span *ngIf="rowData.tenantDomain?.length < 20">
                                                                            {{rowData.tenantDomain}}
                                                                        </span>
                                                                    </span>
                                                                    <span *ngIf="rowData.tenantDomain==null">-</span>
                                                                    <!-- {{rowData.tenantDomain}} -->
                                                                </td>
                                                                <td style="width:20%">
                                                                    <span *ngIf="rowData.fullName!=null">
                                                                        <span pTooltip="{{rowData.fullName}}"
                                                                            *ngIf="rowData.fullName?.length >= 15"
                                                                            tooltipPosition="bottom">
                                                                            {{rowData.fullName?.substring(0,15) +
                                                                            "..." }}
                                                                        </span>
                                                                        <span *ngIf="rowData.fullName?.length < 15">
                                                                            {{rowData.fullName}}
                                                                        </span>
                                                                    </span>
                                                                    <span *ngIf="rowData.fullName==null">-</span>
                                                                    <!-- {{rowData.fullName}} -->
                                                                </td>
                                                                <td style="width:20%">
                                                                    <span
                                                                        *ngIf="rowData.email!=null">xxx&#64;{{rowData.email.split('@')[1]}}</span>
                                                                    <span *ngIf="rowData.email==null">-</span>
                                                                </td>
                                                                <td style="width:10%">
                                                                    <span
                                                                        *ngIf="rowData.userCount!=null">{{rowData.userCount}}</span>
                                                                    <span *ngIf="rowData.userCount==null">-</span>
                                                                    <!-- {{rowData.userCount}} -->
                                                                </td>
                                                                <td style="width: 10%; padding-bottom: 0px;">
                                                                    <div class="button-details">
                                                                        <button pButton tooltipPosition="bottom"
                                                                            style="width: 24px;height: 24px;padding: 0px;"
                                                                            icon="g-icons view-icon" pTooltip="Edit"
                                                                            class="background-transparent"
                                                                            (click)="activeItem1=rowData;editTenant();"
                                                                            [disabled]="this.pagesService.tenantManagementReadOnlyFlag">
                                                                        </button>
                                                                        <button pButton tooltipPosition="bottom"
                                                                            pTooltip="Map/ Unmap"
                                                                            style="width: 24px;height: 24px;padding: 0px;"
                                                                            icon="g-icons mapunmap-icon"
                                                                            class="background-transparent"
                                                                            (click)="activeItem1=rowData;mapUnmapApplication();">
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="paginatorright" let-state> <span
                                                                class="p-pl-4">Total
                                                                Records: {{state.totalRecords}}</span></ng-template>
                                                        <ng-template pTemplate="emptymessage">
                                                            <tr>
                                                                <td colspan="7" class="p-p-0 p-text-center"> <p-card>
                                                                        <div style="margin-top: 5%;height: 70px;">No
                                                                            matching records found</div>
                                                                    </p-card> </td>
                                                            </tr>
                                                        </ng-template>
                                                    </p-table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>