import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PagesService } from '../pages.service';
import { validateAllFormFields } from 'app/shared/util/reactiveform-util';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'jhi-addusergroup',
  templateUrl: './AddUserGroup.component.html',
  styleUrls: ['./AddUserGroup.component.scss'],
  providers: [MessageService],
})
export class AddUserGroupComponent implements OnInit {
  AddUserGroupForm = this.fb.group({
    displayName: [null, [Validators.required]],
    description: [null, []],
    selectGroup: [null, []],
    selectUsers: [null, []],
    selectRole: [null, []],
    userList: [null, []],
    roleList: [null, []],
    groupType: ['Simple', []],
  });
  messageFlag = true;
  activeIndex1: number = 0;
  activeIndex2: number = 0;
  clicked1 = false;
  clicked2 = true;
  clicked3 = true;
  clicked = true;
  enableBtn1 = true;
  enableBtn2 = true;
  enableBtn3 = true;
  tab1 = false;
  tab2 = false;
  tab3 = false;
  tab4 = false;
  oldGroupName: any;
  pageloadvalues: any;
  ccprimebreadcrumb1List = [{ label: `User Management` }, { label: `User Group`, routerLink: ['/pages/listgroups'] }, { label: `Add New Group` }];
  ccprimebreadcrumb1List1 = [
    { label: `User Management` },
    { label: `User Group`, routerLink: ['/pages/listgroups'] },
    { label: `Edit User Group` },
  ];
  homeiconccprimebreadcrumb1 = { icon: 'pi pi-home', routerLink: ['/'] };
  selectGroupList: any[] = [];
  selectUsersList: any[] = [];
  selectRoleList: any[] = [];
  firstSave = false;
  savedId: any;
  stateOptions: any[];
  userListList: any[] = [];
  roleListList: any[] = [];
  exUserList: any[] = [];
  newUserList: any[] = [];
  exRoleList: any[] = [];
  newRoleList: any[] = [];
  selectButtonValue: any;
  nameFlag: boolean = false;
  assignRoleCount = 0;
  deAssignRoleCount = 0;
  assignUserCount = 0;
  deAssignUserCount = 0;
  msg: string[] = [];
  msg1: string[] = [];
  oldDescription: any;
  wfFlag: boolean = false;

  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    private messageService: MessageService
  ) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.AddUserGroupForm.patchValue(JSON.parse(data));
    }
    this.stateOptions = [
      { label: 'Simple', value: 'Simple' },
      { label: 'Composite', value: 'Composite' },
    ];
  }
  ngOnInit(): void {
    this.getUserList();
    this.getroleList();
  }

  onCcprimebutton($event: UntypedFormGroup): void {
    $event.reset();
    this.AddUserGroupForm = this.fb.group(
      {
        groupType: ['Simple', []],
      }
    );
  }

  //first save groupname check
  createGroup($event: UntypedFormGroup) {
    if (!this.firstSave) {
      this.onCheckGroupName($event);
    } else if (this.oldGroupName != this.AddUserGroupForm.value?.displayName) {
      this.onCheckGroupName($event);
    }
    else {
      this.onEditGroup($event);
    }
  }

  colorChange() {
    document.getElementById('displayName_id')!.style.borderColor = 'grey';
  }

  //group name availability API
  onCheckGroupName($event: UntypedFormGroup): void {
    const reqBody = this.AddUserGroupForm.value?.displayName;
    const formData = reqBody;
    const formValues = this.AddUserGroupForm.value?.displayName;
    this.pagesService.onCheckGroupName(formData, formValues).subscribe(
      results => {
        if (results?.error?.code == 400 || results?.success?.code == 400) {
          this.nameFlag = true;
          this.enableBtn1 = true;
          document.getElementById('displayName_id')!.style.borderColor = 'red';
        } else if (results.success.code == 200) {
          this.nameFlag = false;
          document.getElementById('displayName_id')!.style.borderColor = 'grey';
          if (this.firstSave) {
            this.onEditGroup($event);
          } else {
            this.onFirstCreateGroup($event);
          }
        }
      },
    );
  }

  //Create group functionality
  onFirstCreateGroup($event: UntypedFormGroup): void {
    $event.updateValueAndValidity();
    if (!$event.valid) {
      validateAllFormFields($event);
      return;
    }
    const reqBody = {};
    reqBody['displayName'] = this.AddUserGroupForm.value?.displayName;
    reqBody['description'] = this.AddUserGroupForm.value?.description;
    reqBody['type'] = 'Simple';
    const formData = reqBody;
    const formValues = $event.value;
    this.messageFlag = true;
    this.pagesService.onAddUserGroup(formData, formValues).subscribe(
      {
        next: results => {
          this.oldGroupName = results.success.result.displayName;
          this.oldDescription = results.success.result.description;
          this.savedId = results.success.result.id;
          this.messageFlag = true;
          this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'Group created Successfully.' });
          setTimeout(() => {
            this.messageFlag = false;
          }, 3000);
          this.tab1 = true;
          this.showTab();
          this.clicked = false;
          this.enableBtn1 = true;
          this.firstSave = true;
          if (this.selectButtonValue == 'composite') {
            this.clicked2 = false;
          } else {
            this.clicked = false;
          }
          this.exUserList = [];
          this.exRoleList = [];
        },
        error: () => {
          this.messageFlag = true;
          this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Group failed to create. Please Try again.' });
          setTimeout(() => {
            this.messageFlag = false;
          }, 3000);
        }
      });
  }

  //Edit group functionality
  onEditGroup($event: UntypedFormGroup): void {
    $event.updateValueAndValidity();
    if (!$event.valid) {
      validateAllFormFields($event);
      return;
    }

    const reqBody = {};
    if (this.oldGroupName != this.AddUserGroupForm.value?.displayName) {
      reqBody['displayName'] = this.AddUserGroupForm.value?.displayName;
    }
    if (this.oldDescription != this.AddUserGroupForm.value?.description) {
      reqBody['description'] = this.AddUserGroupForm.value?.description;
    }
    reqBody['type'] = 'Simple';
    const formData = reqBody;
    this.messageFlag = true;
    this.pagesService.onEditUserGroup(formData, this.savedId).subscribe({
      next:
        results => {
          this.oldGroupName = results.success.result.displayName;
          this.oldDescription = results.success.result.description;
          this.messageFlag = true;
          this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'Group edited Successfully.' });
          setTimeout(() => {
            this.messageFlag = false;
          }, 3000);
          this.tab1 = true;
          this.enableBtn1 = true;
          this.showTab();
          if (this.selectButtonValue == 'composite') {
            this.clicked2 = false;
          } else {
            this.clicked = false;
          }
        },
      error: () => {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Failed to Edit Group. Please Try again.' });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
    });
  }

  // getting userlist in in the user group
  getUserList(): void {
    this.pagesService.getUserListingExD().subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.userListList.push(data);
        });
        this.userListList = [...this.userListList];
      }
    });
  }

  //getting role list in the user group
  getroleList(): void {
    this.pagesService.gettableDataRoles().subscribe(results => {
      const responseData = results.success.result.records;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.displayName;
          data.value = element.id;
          this.roleListList.push(data);
        });
        this.roleListList = [...this.roleListList];
      }
    });
  }

  onCcprimebutton19($event: UntypedFormGroup): void { }

  //getting existing user list
  getExUserList() {
    this.pagesService.getuserTableDataViewGroup(this.savedId).subscribe(results => {
      const responseData = results.success.result;
      this.exUserList = [];
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.firstName;
          data.value = element.id;
          this.exUserList.push(data.value);
        });
        this.exUserList = [...this.exUserList];
      }
    });
  }

  //getting new user list
  getNewUserList() {
    const responseData = this.AddUserGroupForm.value.userList;
    this.newUserList = [];
    if (responseData && responseData.length > 0) {
      responseData.forEach((element: any = {}) => {
        const data = {
          label: null,
          value: null,
        };
        data.label = element.firstName;
        data.value = element.id;
        this.newUserList.push(data.value);
      });
      this.newUserList = [...this.newUserList];
    }
  }

  // assigning users to user group functionality
  onAssignUsersToGroup($event: UntypedFormGroup): void {
    $event.updateValueAndValidity();
    this.newUserList = this.AddUserGroupForm.value.userList;
    const assignList = this.newUserList.filter(x => !this.exUserList.includes(x));
    const deassignList = this.exUserList.filter(x => !this.newUserList.includes(x));
    const reqBody = {};
    const ID = this.savedId;
    reqBody['tenantId'] = this.pagesService.tenantId;
    reqBody['groupId'] = ID;
    reqBody['assignList'] = assignList;
    reqBody['deAssignList'] = deassignList;
    this.messageFlag = true;
    const formValues = reqBody;
    this.pagesService.AssignUsersToGroups(formValues).subscribe(
      {
        next: results => {
          for (let i = 0; i < assignList.length; i++) {
            if (results.success.result.AssingUserToGroup[i]?.messageCode == 'CIAM 151') {
              this.assignUserCount = this.assignUserCount + 1;
              this.wfFlag = false;
            } else if (results.success.result.AssingUserToGroup[i]?.messageCode == 'CIAM 154') {
              this.assignUserCount = this.assignUserCount + 1;
              this.wfFlag = true;
            }
          }
          for (let i = 0; i < deassignList.length; i++) {
            if (results.success.result["De-AssingUserFromGroup"][i]?.messageCode == 'CIAM 152') {
              this.deAssignUserCount = this.deAssignUserCount + 1;
              this.wfFlag = false;
            } else if (results.success.result["De-AssingUserFromGroup"][i]?.messageCode == 'CIAM 154') {
              this.deAssignUserCount = this.deAssignUserCount + 1;
              this.wfFlag = true;
            }
          }
          if (this.wfFlag) {
            this.navigateToMyReq('Assign/Deassign User to User Group request has been sent for approval');

          }
          else if (!this.wfFlag) {
            this.setAssignUserToGroupResponse();
          }
        },
        error: error => {
          if (error.error?.error?.messageCode == "CIAM 105") {
            this.messageFlag = true;
            this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Request already available for approval.' });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
          }
          else {
            this.messageFlag = true;
            this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Failed to Assign Users.' });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
          }
        }
      });
  }
  setAssignUserToGroupResponse() {
    if (this.deAssignUserCount == 0 && this.assignUserCount > 0) {
      this.msg1.push(this.assignUserCount + ' User Assigned successfully');
    } else if (this.deAssignUserCount > 0 && this.assignUserCount > 0) {
      this.msg1.push(this.assignUserCount + ' User Assigned successfully');

      this.msg1.push(this.deAssignUserCount + ' User De-assigned successfully');
    } else if (this.deAssignUserCount > 0 && this.assignUserCount == 0) {
      this.msg1.push(this.deAssignUserCount + ' User De-assigned successfully');
    }
    this.messageFlag = true;
    this.clicked3 = false;
    this.messageService.add({ key: 'myKey1', severity: 'success', detail: this.msg1.join('\n') });
    setTimeout(() => {
      this.messageFlag = false;
      this.assignUserCount = 0;
      this.deAssignUserCount = 0;
      this.msg1 = [];
    }, 3000);
    this.tab3 = true;
    this.enableBtn2 = true;
    this.showTab();
    this.clicked2 = false;
    this.getExUserList();
  }

  //getting existing role list
  getExRoleList() {
    this.pagesService.getRoleByGroup(this.savedId).subscribe((results: any) => {
      const responseData = results.success.result;
      this.exRoleList = [];
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.exRoleList.push(data.value);
        });
        this.exRoleList = [...this.exRoleList];
      }
    });
  }

  //getting new role list
  getNewRoleList() {
    const responseData = this.AddUserGroupForm.value.roleList;
    this.newRoleList = [];
    if (responseData && responseData.length > 0) {
      responseData.forEach((element: any = {}) => {
        const data = {
          label: null,
          value: null,
        };
        data.label = element.first_name;
        data.value = element.id;
        this.newRoleList.push(data.value);
      });
      this.newRoleList = [...this.newRoleList];
    }
  }

  // assigning role to user group functionality
  assignRolesToGroup($event: UntypedFormGroup): void {
    $event.updateValueAndValidity();
    this.newRoleList = this.AddUserGroupForm.value.roleList;
    const assignList = this.newRoleList.filter(x => !this.exRoleList.includes(x));
    const deassignList = this.exRoleList.filter(x => !this.newRoleList.includes(x));
    const reqBody = {};
    reqBody['tenantId'] = this.pagesService.tenantId;
    reqBody['groupId'] = this.savedId;
    reqBody['assignList'] = assignList;
    reqBody['deAssignList'] = deassignList;
    const formValues = reqBody;
    this.messageFlag = true;
    this.pagesService.onSaveAssignRolesToGroup(formValues).subscribe(
      {
        next: results => {
          for (let i = 0; i < assignList.length; i++) {

            if (results.success.result.AssingRolesToGroup[i]?.messageCode == 'CIAM 151') {
              this.assignRoleCount = this.assignRoleCount + 1;
              this.wfFlag = false;
            }
            else if (results?.success?.result?.AssingRolesToGroup[i]?.messageCode == 'CIAM 154') {
              this.wfFlag = true;
            }
          }
          for (let i = 0; i < deassignList.length; i++) {
            if (results.success.result["De-AssingRolesFromGroup"][i]?.messageCode == 'CIAM 152') {
              this.deAssignRoleCount = this.deAssignRoleCount + 1;
              this.wfFlag = false;
            }
            else if (results?.success?.result["De-AssingRolesFromGroup"][i]?.messageCode == 'CIAM 154') {
              this.wfFlag = true;
            }
          }
          if (this.wfFlag) {
            this.navigateToMyReq('Assign/Deassign Role to User Group request has been sent for approval');
          }
          else {
            this.setAssignRoleToGroupResponse();

          }
        },
        error: error => {
          if (error.error?.error?.messageCode == "CIAM 105") {
            this.messageService.add({ key: 'myKey1', severity: 'error', detail: "Request already available for approval" });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
          }
          else {
            this.messageFlag = true;
            this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Failed to Assign Roles.' });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
          }
        }
      });
  }
  setAssignRoleToGroupResponse() {
    if (this.deAssignRoleCount == 0 && this.assignRoleCount > 0) {
      this.msg.push(this.assignRoleCount + ' Role Assigned successfully');
    } else if (this.deAssignRoleCount > 0 && this.assignRoleCount > 0) {
      this.msg.push(this.assignRoleCount + ' Role Assigned successfully');

      this.msg.push(this.deAssignRoleCount + ' Role De-assigned successfully');
    } else if (this.deAssignRoleCount > 0 && this.assignRoleCount == 0) {
      this.msg.push(this.deAssignRoleCount + ' Role De-assigned successfully');
    }

    this.messageFlag = true;
    this.clicked3 = false;
    this.messageService.add({ key: 'myKey1', severity: 'success', detail: this.msg.join('\n') });
    setTimeout(() => {
      this.messageFlag = false;
      this.assignRoleCount = 0;
      this.deAssignRoleCount = 0;
      this.msg = [];
    }, 3000);
    this.tab4 = true;
    this.showTab();
    this.enableBtn3 = true;
    this.clicked2 = false;
    this.getExRoleList();
  }

  navigateToMyReq(msg: any): void {
    const navigationExtras: NavigationExtras = {
      state: {
        msg: msg,
      },
    };
    this.router.navigate(['/pages/myrequestslisting'], navigationExtras);
  }

  showTab() {
    if (this.activeIndex1 === 0) {
      this.activeIndex1 = 2; //show users tab since composite tab disabled
    }
    else if (this.activeIndex1 === 2) {
      this.activeIndex1 = 3;
    }
    else if (this.activeIndex1 === 3) {
      this.activeIndex1 = 4;
    }
  }

  validation() {
    if (this.oldGroupName != this.AddUserGroupForm.value?.displayName) {
      this.enableBtn1 = false;
    }
    else {
      this.enableBtn1 = true;
    }
  }
}
