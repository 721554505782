import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { PagesService } from '../pages.service';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'jhi-createresources',
  templateUrl: './EditAttributeGroup.component.html',
  styleUrls: ['./EditAttributeGroup.component.scss'],
  providers: [DialogService],
})
export class EditAttributeGroupComponent implements OnInit {
  EditAttributeGroupForm = this.fb.group({
    textField: [null, [Validators.required]],
    textarea: [null, []],
  });
  pageloadvalues: any;
  nameFlag: boolean = false;
  groupId: any;
  Items: any;
  groupname: any;
  description: any;
  oldGroupName: any;
  enableBtn1 = true;

  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    private messageService: MessageService,
    public config: DynamicDialogConfig,
    public pagesService: PagesService) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.EditAttributeGroupForm.patchValue(JSON.parse(data));
    }

  }
  appId: any;
  ngOnInit(): void {
    this.groupId = this.config?.data?.grpId;
    this.appId = this.config?.data?.id;
    this.groupname = this.config?.data?.groupname;
    if (this.config?.data?.description == 'null') {
      this.description = '';
    }
    else {
      this.description = this.config?.data?.description;
    }
    this.oldGroupName = this.config?.data?.groupname;
  }

  onClose($event: UntypedFormGroup): void {
    this.ref.close();
  }

  EditAttributeGroup(): void {
    const data = { appId: this.appId, groupId: this.groupId };
    const reqBody = {};
    if (this.oldGroupName == this.EditAttributeGroupForm.value.textField) {
      reqBody['description'] = this.EditAttributeGroupForm.value.textarea;
    }
    else {
      reqBody['attributeGroupName'] = this.EditAttributeGroupForm.value.textField;
      reqBody['description'] = this.EditAttributeGroupForm.value.textarea;
    }
    const formValues = reqBody;
    this.pagesService.EditAttributeGroup(formValues, data).subscribe(
      {
        next: results => {
          this.ref.close("Attribute Group Saved Successfully");
        },
        error: () => {
          this.ref.close("Failed to Edit Attribute Group");
        }
      });
  }

  colorChange() {
    document.getElementById('textField_id')!.style.borderColor = 'grey';
  }

  onSaveCheckAttributeGroupName($event: UntypedFormGroup): void {
    if (this.oldGroupName != this.EditAttributeGroupForm.value.textField) {
      const reqBody = this.EditAttributeGroupForm.value?.textField;
      const formData = reqBody;
      const formValues = this.EditAttributeGroupForm.value?.textField;
      this.pagesService.onCheckAttributeGroupName(formData, formValues).subscribe(
        results => {
          if (results?.error?.code == 400 || results?.success?.code == 400) {
            this.nameFlag = true;
            this.enableBtn1 = true;
            document.getElementById('textField_id')!.style.borderColor = 'red';
          } else if (results.success.code == 200) {
            this.nameFlag = false;
            this.enableBtn1 = false;
            document.getElementById('textField_id')!.style.borderColor = 'grey';
            this.EditAttributeGroup();
          }
        },
      );
    }
    else {
      this.EditAttributeGroup();
    }
  }

  CheckAttributeGroupName($event: UntypedFormGroup): void {
    if (this.oldGroupName != this.EditAttributeGroupForm.value.textField) {
      const reqBody = this.EditAttributeGroupForm.value?.textField;
      const formData = reqBody;
      const formValues = this.EditAttributeGroupForm.value?.textField;
      this.pagesService.onCheckAttributeGroupName(formData, formValues).subscribe(
        results => {
          if (results?.error?.code == 400 || results?.success?.code == 400) {
            this.nameFlag = true;

            document.getElementById('textField_id')!.style.borderColor = 'red';
          } else if (results.success.code == 200) {
            this.nameFlag = false;
            document.getElementById('textField_id')!.style.borderColor = 'grey';
          }
        },
      );
    }
    else {
      document.getElementById('textField_id')!.style.borderColor = 'grey';
    }
  }

}


