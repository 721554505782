import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PagesService } from '../pages.service';
import { MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AddAccountParameterComponent } from '../AddAccountParameter/AddAccountParameter.component';
import { REBAC_UM_ENTITLEMENT_FLAG } from "app/app.constants";
import { SSO_ALIAS_NAME, IDENTITY_PROVIDER_USERNAME, SSO } from "app/constants";
import { EMAIL_REGEXP } from '../../constants';

@Component({
  selector: 'jhi-EditAccount',
  templateUrl: './EditAccount.component.html',
  styleUrls: ['./EditAccount.component.scss'],
  providers: [MessageService, DatePipe],
})
export class EditAccountComponent implements OnInit {
  EditAccountForm = this.fb.group({
    orgNameInput: [null, []],
    accountNameInput: ['', [Validators.required]],
    countryDropdown: ['', []],
    accountAddressArea: ['', []],
    orgNamedropdown: [null, []],
    firstName: [null, []],
    middleName: ['', []],
    lastName: [null,],
    emailAddress: [null, [, Validators.pattern(EMAIL_REGEXP.source)]],
    pinCodeInput: [null, []],
    accountTypeSwitch: [null, []],
    orgNameSwitch: [null, []],
    accountOwnerSwitch: [null, []],
    usersDropdown: [null, []],
  });
  messageFlag = true;
  activeIndex2: number = 0;
  pageloadvalues: any;
  ccprimebreadcrumbList1 = [{ label: `User Management` }, { label: `Users`, routerLink: ['/pages/userlisting'] }, { label: `Edit User` }];
  ccprimebreadcrumbList = [{ label: `User Management` }, { label: `Users`, routerLink: ['/pages/userlisting'] }, { label: `Add New User` }];
  homeiconccprimebreadcrumb = { icon: 'pi pi-home', routerLink: ['/'] };

  accountTypeSwitchList: any[] = [{ 'label': "Business", 'value': 'false' }, { 'label': "Personal", 'value': 'true' },];
  orgNameSwitchList: any[] = [{ 'label': "Existing", 'value': 'false' }, { 'label': "Add New", 'value': 'true' },];
  accountOwnerSwitch: any[] = [{ 'label': "Existing", 'value': 'false' }, { 'label': "Add New", 'value': 'true' },];
  firstSave = false;
  savedId: any;
  emailFlag: boolean = false;
  orgName: any;
  enableBtn1 = true;
  enableBtn2 = true;
  enableBtn3 = true;
  emailInvalidFlag: boolean = false;
  codes: any[] = [];
  checked = false;
  fullNameFlag = true;
  oldPhoneNumber: any;
  wfFlag = false;
  defaultselect_accountTypeSwitch: any;
  defaultselect_orgNameSwitch: any;
  defaultselect_accountOwnerSwitch: any;
  userNameCheckError: boolean = false;
  orgFlag: boolean = false;
  accountTypeFlag: boolean = false;
  autocompleteList: any[];
  accountOwnerSwitchFlag: boolean = false;
  options: any[];
  accountId: any;
  accountType: any;
  orgType: any;
  accountName: any;
  countryId: any;
  pinCode: any;
  accountAddress: any;
  accountOwnerId: any;
  orgId: any;
  userNameFlag: boolean = true;
  personalFlag: boolean = true;
  staticJson: any;
  customList: any[] = [];
  parseStaticJson: any[];
  bankTaxonomyList: any[] = [];
  serviceTaxonomyList: any[] = [];
  dynamicaMessage: string;
  dynamicMessage: string;
  checkboxArr: any[] = [];
  accountParameterJson: any;
  customFieldDto: any[] = [];
  emptyFlag: number;
  range: any;
  bankTaxonomyInputFormat: string;
  serviceTaxonomyInputFormat: string;
  sendCustomList: any[] = [];
  alreadySetValues: any[] = [];
  checkbox: boolean = true;
  newdate: any[] = [];
  accountPkId: any;
  configurableList: any;
  ipRangearr: any;
  existingParameterList: any;
  ipNum: any = 1;
  IpList: any = [];
  index: number = 0;
  checkIPCondition: boolean;
  masterSetIP = new Set();
  childSetIP = new Set();
  childStartCheckFlag: boolean;
  childEndCheckFlag: boolean;
  isValidIP: boolean = false;
  rebacFlag: string;
  ssoEnabled = false;
  SSO_ALIAS_NAME = SSO_ALIAS_NAME;
  IDENTITY_PROVIDER_USERNAME = IDENTITY_PROVIDER_USERNAME;
  SSO = SSO;
  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    private messageService: MessageService,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public ref1: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public datepipe: DatePipe
  ) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.EditAccountForm.patchValue(JSON.parse(data));
    }
    this.setDefaultValue_accountTypeSwitch('false');
    this.setDefaultValue_orgNameSwitch('false');
    this.setDefaultValue_accountOwnerSwitch('false');
  }
  toggle = true;
  status = 'Enable';
  enableDisableRule() {
    this.toggle = !this.toggle;
    this.status = this.toggle ? 'Enable' : 'Disable';
  }

  ngOnInit(): void {
    this.rebacFlag = REBAC_UM_ENTITLEMENT_FLAG + "";
    this.accountId = this.config?.data?.accountId;
    this.getCountryCode();
    this.gettableList();
    this.getAccountDetails();
    this.getTaxonomyDetails(1);
    this.getTaxonomyDetails(2);
    this.getExistingParameter();
  }
  valueChangeAccountOwner() {
    if (this.EditAccountForm.value.accountOwnerSwitch == "false") {
      this.accountOwnerSwitchFlag = false;
      this.EditAccountForm.controls['firstName'].clearValidators();
      this.EditAccountForm.controls['firstName'].updateValueAndValidity();
      this.EditAccountForm.controls['lastName'].clearValidators();;
      this.EditAccountForm.controls['lastName'].updateValueAndValidity();
      this.EditAccountForm.controls['emailAddress'].clearValidators();
      this.EditAccountForm.controls['emailAddress'].updateValueAndValidity();

    }
    else {
      this.accountOwnerSwitchFlag = true;
      this.EditAccountForm.controls['usersDropdown'].clearValidators();
      this.EditAccountForm.controls['usersDropdown'].updateValueAndValidity();
      this.EditAccountForm.controls['firstName'].setValidators(Validators.required);
      this.EditAccountForm.controls['firstName'].updateValueAndValidity();
      this.EditAccountForm.controls['lastName'].setValidators(Validators.required);;
      this.EditAccountForm.controls['lastName'].updateValueAndValidity();
      this.EditAccountForm.controls['emailAddress'].setValidators([Validators.required, Validators.email]);;
      this.EditAccountForm.controls['emailAddress'].updateValueAndValidity();
    }
  }
  getAccountDetails() {
    const reqBody = {};
    reqBody['tenantId'] = this.pagesService.tenantId;
    reqBody['accountId'] = this.accountId;
    this.pagesService.getEditAccountDetails(reqBody).subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData != null || responseData != undefined) {
        this.accountType = responseData.accountType;
        if (this.accountType == "Personal") {
          this.personalFlag = false;
        }
        else
          this.personalFlag = true;
        this.orgType = "Existing";
        this.accountPkId = responseData.accountPkId;
        this.orgName = responseData.organizationName;
        this.accountName = responseData.accountName;
        this.countryId = responseData.countryId;
        this.accountName = responseData.accountName;
        if (responseData.pinZipCode != "0")
          this.pinCode = responseData.pinZipCode;
        this.accountAddress = responseData.accountAddress;
        this.accountOwnerId = responseData.accountOwnerId;
        this.orgId = responseData.organisationId;
        this.accountParameterJson = JSON.parse(responseData?.accountParamJson);
      }
      this.existingParameterFetch();
      this.fetchConfigurableParamters();
    });


  }

  gettableList(): void {
    this.autocompleteList = [];
    this.pagesService.getUserListing().subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.autocompleteList.push(data);
        });
        this.autocompleteList = [...this.autocompleteList];
      }
    });
  }

  checkUserName() {
    const regularExpressionEmail = /(?=.{1,64}@)[A-Za-z0-9_-]+(\.[A-Za-z0-9_-]+)*@[^-][A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,})$/;
    const userNameValue = this.EditAccountForm.value.userName;
    const validateEnd = regularExpressionEmail.test(String(userNameValue).toLowerCase());
    if (validateEnd) {
      if (this.EditAccountForm.value.emailAddress == this.EditAccountForm.value.userName) {
        this.userNameCheckError = false;
        document.getElementById('userName_id')!.style.borderColor = 'grey';
      }
      else {
        this.userNameCheckError = true;
        document.getElementById('userName_id')!.style.borderColor = 'red';
      }
    }
    else {
      this.userNameCheckError = false;
      document.getElementById('userName_id')!.style.borderColor = 'grey';
    }
  }

  onClose($event: UntypedFormGroup): void {
    this.ref.close();
  }

  colorChange() {
    document.getElementById('emailAddress_id')!.style.borderColor = 'grey';
  }

  //email availability function
  onCheckEmail($event: UntypedFormGroup): void {
    const reqBody = this.EditAccountForm.value.emailAddress.toLowerCase();
    const formData = reqBody;
    const formValues = this.EditAccountForm.value.emailAddress.toLowerCase();
    this.pagesService.onCheckEmailId(formData, formValues).subscribe(
      results => {
        if (results?.error?.messageCode == 'CIAM 105' || results?.success?.messageCode == 'CIAM 105') {
          this.emailFlag = true;
          this.enableBtn1 = true;
          document.getElementById('emailAddress_id')!.style.borderColor = 'red';
        }
        if (results?.error?.messageCode == 'CIAM 201' || results?.success?.messageCode == 'CIAM 201') {
          this.emailInvalidFlag = true;
          this.enableBtn1 = true;
          document.getElementById('emailAddress_id')!.style.borderColor = 'red';
        }
        else if (results.success.code == 200) {
          this.emailFlag = false;
          this.enableBtn1 = false;
          document.getElementById('emailAddress_id')!.style.borderColor = 'grey';
        }
      }
    );
  }
  getCountryCode() {
    this.pagesService.getCountryCode().subscribe(
      results => {
        const responseData = results?.success?.result;
        if (responseData && responseData?.length > 0) {
          responseData.forEach((element: any = {}) => {
            const data = {
              label: null,
              value: null,
              name: null,
            };
            data.label = element.countryName;
            data.value = element.id;
            this.codes.push(data);
          });
          this.codes = [...this.codes];
        }
      });
  }
  setDefaultValue_accountTypeSwitch(value?: any) {
    if (this.accountTypeSwitchList.length && value) {
      this.defaultselect_accountTypeSwitch = value
      return this.defaultselect_accountTypeSwitch;
    }
    this.defaultselect_accountTypeSwitch = this.accountTypeSwitchList[0]?.value;
    return this.defaultselect_accountTypeSwitch

  }

  setDefaultValue_orgNameSwitch(value?: any) {
    if (this.orgNameSwitchList.length && value) {
      this.defaultselect_orgNameSwitch = value
      return this.defaultselect_orgNameSwitch;
    }
    this.defaultselect_orgNameSwitch = this.orgNameSwitchList[0]?.value;
    return this.defaultselect_orgNameSwitch;

  }
  setDefaultValue_accountOwnerSwitch(value?: any) {
    if (this.accountOwnerSwitch.length && value) {
      this.defaultselect_accountOwnerSwitch = value;
      return this.defaultselect_accountOwnerSwitch;
    }
    this.defaultselect_accountOwnerSwitch = this.accountOwnerSwitch[0]?.value;
    return this.defaultselect_accountOwnerSwitch
  }

  onSSOChange(event: any, fieldName: any) {
    if (fieldName == SSO && event.value.label === 'Enabled') {
      this.ssoEnabled = event.value.label === 'Enabled';
      this.EditAccountForm.controls['Identity Provider UserName'].enable();
      this.EditAccountForm.controls['SSO Alias Name'].enable();
      this.EditAccountForm.controls['Identity Provider UserName'].setValidators(Validators.required);
      this.EditAccountForm.controls['Identity Provider UserName'].updateValueAndValidity();
      this.EditAccountForm.controls['SSO Alias Name'].setValidators(Validators.required);
      this.EditAccountForm.controls['SSO Alias Name'].updateValueAndValidity();
    }
    else {
      this.ssoEnabled = false;
      this.EditAccountForm.controls['Identity Provider UserName'].disable();
      this.EditAccountForm.controls['SSO Alias Name'].disable();
      this.EditAccountForm.controls['SSO Alias Name'].reset();
      this.EditAccountForm.controls['Identity Provider UserName'].reset();
      this.EditAccountForm.controls['Identity Provider UserName'].clearValidators();
      this.EditAccountForm.controls['Identity Provider UserName'].updateValueAndValidity();
      this.EditAccountForm.controls['SSO Alias Name'].clearValidators();
      this.EditAccountForm.controls['SSO Alias Name'].updateValueAndValidity();
    }
  }

  //Edit User Functionality
  onEditAccount($event: UntypedFormGroup): void {
    const reqBody = {};
    reqBody['tenantId'] = this.pagesService.tenantId;
    reqBody['accountsId'] = this.accountId;
    reqBody['accountType'] = this.accountType;
    reqBody['orgId'] = this.orgId;
    if (this.EditAccountForm.value.accountNameInput.trim() == "") {
      reqBody['accountName'] = null;
    }
    else {
      reqBody['accountName'] = this.EditAccountForm.value.accountNameInput;
    }
    reqBody['countryId'] = this.EditAccountForm.value.countryDropdown;
    reqBody['pinCode'] = this.EditAccountForm.value.pinCodeInput;
    reqBody['accountAddress'] = this.EditAccountForm.value.accountAddressArea;
    if (this.rebacFlag == "false") {
      if (!this.accountOwnerSwitchFlag) {
        reqBody['isCreateNewOwner'] = "false";
        reqBody['accountOwnerId'] = this.EditAccountForm.value.usersDropdown;
      }
      else {
        reqBody['isCreateNewOwner'] = "true";
        reqBody['firstName'] = this.EditAccountForm.value.firstName;
        reqBody['middleName'] = this.EditAccountForm.value.middleName;
        reqBody['lastName'] = this.EditAccountForm.value.lastName;
        reqBody['email'] = this.EditAccountForm.value.emailAddress;

      }
    }


    if (this.customList && this.customList.length > 0) {
      this.customList.forEach((element: any = {}) => {
        const data = {
          id: null,
          fieldName: null,
          fieldInputtype: null,
          mandatoryField: false,
          maxCharacters: null,
          pattern: null,
          specialChar: [],
          inputFormat: null,
          isExisting: false,
          isConfigurable: false,
          fieldValue: null,
        };
        data.fieldName = element.fieldName;
        data.fieldInputtype = element.fieldInputtype;
        data.mandatoryField = element.mandatoryField;
        data.maxCharacters = element.maxCharacters;
        data.specialChar = element?.specialChar;
        data.inputFormat = element.inputFormat;
        data.isExisting = element.isExisting;
        data.isConfigurable = element.isConfigurable;
        data.id = data.fieldName;
        data.pattern = element.pattern;
        if (element.fieldInputtype == 'Dropdown') {
          let cc = this.EditAccountForm.value[element.fieldName];
          if (cc == "" || cc == null) {
            this.emptyFlag = 2;
          }
          else {
            data.fieldValue = cc;
          }

        }
        else if (element.inputFormat[0] == 'Date range') {
          data.fieldValue = JSON.stringify(this.EditAccountForm.value[element.fieldName]);
        }
        else if (element.inputFormat[0] == 'Text/Number range') {
          const reqRange = {};
          reqRange['start'] = this.EditAccountForm.value[element.fieldName + "_start"];
          reqRange['last'] = this.EditAccountForm.value[element.fieldName + "_end"];
          if (reqRange['start'] == null || reqRange['last'] == null) {

            data.fieldValue = null;
          }
          else {
            data.fieldValue = JSON.stringify(reqRange);
          }
        }
        else {
          data.fieldValue = this.EditAccountForm.value[element.fieldName];
        }
        this.customFieldDto.push(data);
      });
      this.customFieldDto = [...this.customFieldDto];
    }

    if (this.checkboxArr && this.checkboxArr.length > 0) {
      this.checkboxArr.forEach((element: any = {}) => {
        const data = {
          id: null,
          fieldName: null,
          fieldInputtype: null,
          fieldValue: null,
        };
        data.id = element.id;
        data.fieldName = element.fieldName;
        data.fieldInputtype = element.fieldInputtype;
        data.fieldValue = this.EditAccountForm.value[element.id];
        this.customFieldDto.push(data);
      });
      this.customFieldDto = [...this.customFieldDto];
    }
    //new configurable parameters
    if (this.configurableList && this.configurableList.length > 0) {
      this.configurableList.forEach((element: any = {}) => {
        const data = {
          id: null,
          fieldName: null,
          fieldInputtype: null,
          mandatoryField: false,
          maxCharacters: null,
          pattern: null,
          specialChar: [],
          inputFormat: [],
          isExisting: false,
          isConfigurable: true,
          fieldValue: null,
        };
        data.fieldName = element.fieldName;
        data.fieldInputtype = element.fieldInputtype;
        data.mandatoryField = element.mandatoryField;
        data.maxCharacters = element.maxCharacters;
        data.specialChar = element?.specialChar;
        data.inputFormat = element.inputFormat;
        data.isExisting = element.isExisting;
        data.isConfigurable = element.isConfigurable;
        data.id = data.fieldName;
        data.pattern = element.pattern;
        if (element.fieldInputtype == 'Dropdown') {
          let cc = this.EditAccountForm.value[element.fieldName];
          if (cc == "" || cc == null) {
            this.emptyFlag = 2;
          }
          else {
            data.fieldValue = cc;
          }

        }

        else {
          data.fieldValue = this.EditAccountForm.value[element.fieldName];
        }
        this.customFieldDto.push(data);
      });
      this.customFieldDto = [...this.customFieldDto];
    }
    if (this.ipRangearr && this.ipRangearr.length > 0) {
      this.ipRangearr.forEach((element: any = {}) => {
        const data = {
          id: null,
          fieldName: null,
          fieldInputtype: null,
          mandatoryField: false,
          maxCharacters: null,
          pattern: null,
          specialChar: [],
          inputFormat: [],
          isExisting: false,
          isConfigurable: true,
          fieldValue: null,
        };
        data.fieldName = element.fieldName;
        data.fieldInputtype = element.fieldInputtype;
        data.mandatoryField = element.mandatoryField;
        data.maxCharacters = element.maxCharacters;
        data.specialChar = element?.specialChar;
        data.inputFormat = element.inputFormat;
        data.isExisting = element.isExisting;
        data.isConfigurable = element.isConfigurable;
        data.id = element.id;
        data.pattern = element.pattern;
        data.fieldValue = this.EditAccountForm.value[element.id];
        this.customFieldDto.push(data);
      });
      this.customFieldDto = [...this.customFieldDto];
    }
    reqBody['accountsParameterJson'] = JSON.stringify(this.customFieldDto);
    const formData = reqBody;

    this.pagesService.createAccount(formData).subscribe(
      {
        next: results => {
          if (results?.success?.messageCode == "CIAM 755") {
            this.ref.close("Account details saved  successfully");
          }
          else if (results?.error?.messageCode == "CIAM 102") {
            this.userNameFlag = false;
            this.enableBtn1 = true;
          }

        },
        error: error => {
          if (error.error?.error?.messageCode == "CIAM 105") {
            this.fullNameFlag = false;
          }

          else {
            this.messageFlag = true;
            this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Failed to Edit User.' });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
          }
        }
      });
  }
  getTaxonomyDetails(index: any) {
    if (index == 1) {
      this.bankTaxonomyList = [];
    }
    else if (index == 2) {
      this.serviceTaxonomyList = [];
    }

    this.pagesService.getTaxonomyDropdown(index).subscribe(
      results => {
        const responseData = results?.success?.result;
        if (responseData && responseData?.length > 0) {
          responseData.forEach((element: any = {}) => {
            const data = {
              label: null,
              value: null,
            };
            data.label = element.label;
            data.value = element.value;
            if (index == 1) {
              this.bankTaxonomyList.push(data);
            }
            else if (index == 2) {
              this.serviceTaxonomyList.push(data);
            }

          });
          if (index == 1) {
            this.bankTaxonomyList = [...this.bankTaxonomyList];
          }
          else if (index == 2) {
            this.serviceTaxonomyList = [...this.serviceTaxonomyList];
          }
        }
      });
  }
  fetchParameters() {
    this.alreadySetValues.forEach(element => {
      this.customList.forEach(custom => {
        if (element.label == "Bank Taxonomy Item" && custom.fieldName == "Bank Taxonomy Item") {
          custom.fieldValue = element.value;
        }
      });
    });
    if (this.customList.length > 0) {
      if (this.staticJson[0]?.isExisting) {
        const custFieldName = [];
        const statFieldName = [];
        this.customList.forEach(customElement => {
          if (customElement.isExisting)
            custFieldName.push(customElement.fieldName);
        });
        this.staticJson.forEach(staticElement => {
          if (staticElement.isExisting) {
            statFieldName.push(staticElement.fieldName);
          }
        });
        const removeList = custFieldName.filter(x => !statFieldName.includes(x));
        this.deleteParameter(removeList);
        const assignList = statFieldName.filter(x => !custFieldName.includes(x));
        this.parseStaticJson = [];
        assignList.forEach(element => {
          this.staticJson.forEach(statElement => {
            if (statElement.fieldName == element) {
              this.parseStaticJson.push(statElement);
            }
          })
        });
        this.staticJson = this.parseStaticJson;
      }
      else if (this.staticJson.length == 0) {
        this.customList.forEach(element => {
          if (element.isExisting) {
            this.deleteParameter(element.id);
          }
        })
      }
    }

    this.staticJson.forEach((element: any = {}) => {
      const data = {
        id: null,
        fieldName: null,
        fieldInputtype: null,
        mandatoryField: null,
        maxCharacters: null,
        pattern: null,
        specialChar: null,
        inputFormat: null,
        isExisting: false,
        isConfigurable: false,
      };
      data.fieldName = element.fieldName;
      data.fieldInputtype = element.fieldLabel;
      data.mandatoryField = element.mandatory;
      data.maxCharacters = element.MaxCharacters;
      data.specialChar = element?.specialCharacter;
      data.inputFormat = element.inputvalue_format;
      data.isExisting = element.isExisting;
      data.isConfigurable = element.isConfigurable;
      data.id = data.fieldName;
      if (element.fieldLabel == 'Checkbox') {
        data.fieldInputtype = element.fieldLabel;
        this.EditAccountForm.addControl(data.id,
          this.fb.control(false, []));
      }
      else if (data.inputFormat[0] == 'Date range' && data.fieldInputtype == 'Input field in range') {
        this.EditAccountForm.addControl(data.id,
          this.fb.control(null, []));
      }
      else if (data.fieldName == 'Input field in range' && data.inputFormat[0] == 'Text/Number range') {
        if (data.mandatoryField) {
          this.EditAccountForm.addControl(data.id + "_start",
            this.fb.control(null, [Validators.required]));
          this.EditAccountForm.addControl(data.id + "_end",
            this.fb.control(null, [Validators.required]));
        }
        else {
          this.EditAccountForm.addControl(data.id + "_start",
            this.fb.control(null, []));
          this.EditAccountForm.addControl(data.id + "_end",
            this.fb.control(null, []));
        }
      }
      else {
        if (data.fieldInputtype == 'TextBox' || data.fieldInputtype == 'Text area' || data.fieldInputtype == 'Text') {
          if (data.inputFormat != "") {
            data.pattern = this.setValidationsPatterns(data.inputFormat, data.specialChar);
          }
        }
        if (data.mandatoryField) {
          this.EditAccountForm.addControl(data.id,
            this.fb.control(null, [Validators.required]));
        }
        else {
          this.EditAccountForm.addControl(data.id,
            this.fb.control(null, []));
        }

      }
      if (data.fieldInputtype == 'Checkbox') {
        this.checkboxArr.push(data);
      }
      else {
        this.customList.push(data);
      }

    });
    this.customList.forEach(element => {
      if (element.fieldName == "Service Line Taxonomy Item") {
        this.serviceTaxonomyInputFormat = "";
        element.inputFormat = this.serviceTaxonomyList;
      }
      else if (element.fieldName == "Bank Taxonomy Item") {
        this.bankTaxonomyInputFormat = "";
        element.inputFormat = this.bankTaxonomyList;
      }
    });
  }
  getExistingParameter(): void {
    this.existingParameterList = [];
    this.pagesService.getExistingParamter().subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.fieldname;
          data.value = JSON.parse(element.jsonTemplate);
          if (element.configurable && !element.userSpecific) {
            this.existingParameterList.push(data.value);
          }

        });
        this.existingParameterList = [...this.existingParameterList];
      }
    });
  }
  fetchConfigurableParamters() {
    this.configurableList = [];
    this.ipRangearr = [];
    this.accountParameterJson.forEach((element: any = {}) => {
      const data = {
        id: null,
        fieldName: null,
        fieldInputtype: null,
        mandatoryField: null,
        maxCharacters: null,
        pattern: null,
        specialChar: null,
        inputFormat: null,
        isExisting: false,
        isConfigurable: true,
        fieldValue: null,
      };
      data.fieldName = element.fieldName;
      data.fieldInputtype = element.fieldInputtype;
      data.mandatoryField = element.mandatoryField;
      data.maxCharacters = element.maxCharacters;
      data.specialChar = element?.specialChar;
      data.inputFormat = element.inputFormat;
      data.isExisting = element.isExisting;
      data.isConfigurable = element.isConfigurable;
      data.id = element.id;
      data.pattern = element.pattern;
      if (data.id == "SSO" && data.fieldValue == null) {
        data.fieldValue = { label: "Disabled", value: "Disabled" }
      }
      else {
        data.fieldValue = element.fieldValue;
      }
      if (element.isConfigurable) {
        if (data.fieldInputtype == 'TextBox' || data.fieldInputtype == 'Text' ||
          data.fieldInputtype == 'Text Range') {
          if (data.inputFormat != "") {
            data.pattern = this.setValidationsPatterns(data.inputFormat, data.specialChar);
          }
          this.EditAccountForm.addControl(data.id,
            this.fb.control(null, [Validators.required]));
        }
        else {
          this.EditAccountForm.addControl(data.id,
            this.fb.control(null, [Validators.required]));
        }

        if (data.fieldInputtype == 'Text Range') {
          const data1 = {
            index: null,
            startIpValue: null,
            endIpValue: null,
            fieldId: null
          }
          const IP4startValue = data.fieldValue.split('-')[0];
          const IP4endValue = data.fieldValue.split('-')[1];
          data1.index = this.index + 1;
          data1.startIpValue = IP4startValue;
          data1.endIpValue = IP4endValue;
          data1.fieldId = data.id;
          this.IpList.push(data1);
          this.ipRangearr.push(data);
        }
        else {
          this.configurableList.push(data);
        }
        this.configurableList.forEach(element => {
          if (element?.fieldValue?.value == "Disabled") {
            this.EditAccountForm.controls['Identity Provider UserName']?.disable();
            this.EditAccountForm.controls['SSO Alias Name']?.disable();
          }
        });
      }


    });
  }
  addIPrange() {
    this.ipNum = this.ipRangearr.length + 1;
    this.existingParameterList.forEach((element: any = {}) => {
      const data = {
        id: null,
        fieldName: null,
        fieldInputtype: null,
        mandatoryField: null,
        maxCharacters: null,
        pattern: null,
        specialChar: null,
        inputFormat: null,
        isExisting: false,
        isConfigurable: true,
        fieldValue: null,
      };
      data.fieldName = element.fieldName;
      data.fieldInputtype = element.fieldLabel;
      data.mandatoryField = element.mandatory;
      data.maxCharacters = element.MaxCharacters;
      data.specialChar = element?.specialCharacter;
      data.inputFormat = element.inputvalue_format;
      data.isExisting = element.isExisting;
      data.isConfigurable = element.isConfigurable;
      data.id = data.fieldName + this.ipNum;
      data.fieldValue = element.fieldValue;
      if (data.fieldInputtype == 'Text Range') {
        this.EditAccountForm.addControl(data.id,
          this.fb.control(null, [Validators.required]));
        this.ipRangearr.push(data);
      }
    });
  }
  deleteParameter(id: any) {
    for (let i = 0; i < this.customList.length; i++) {
      if (this.customList[i].id == id) {
        this.customList.splice(i, 1);
        this.EditAccountForm.removeControl(id);
      }

    }
    for (let i = 0; i < this.checkboxArr.length; i++) {
      if (this.checkboxArr[i].id == id) {
        this.checkboxArr.splice(i, 1);
        this.EditAccountForm.removeControl(id);
      }
    }
    for (let i = 0; i < this.ipRangearr.length; i++) {
      if (this.ipRangearr[i].id == id) {
        this.ipRangearr.splice(i, 1);
        this.EditAccountForm.removeControl(id);
      }
    }
  }
  setValidationsPatterns(format: any, specialChar: any): string {
    let upper = "";
    let lower = "";
    let num = "";
    let spCh = "";
    let pattern = "";
    this.dynamicaMessage = "";
    format?.forEach(element => {
      if (element == "Uppercase alphabet") {
        upper = "A-Z";
      }
      else if (element == "Lowercase alphabet") {
        lower = "a-z";
      }
      else if (element == "Numbers") {
        num = "0-9";
      }
      this.dynamicaMessage = this.dynamicaMessage + "&&" + element;
    });
    this.dynamicMessage = this.dynamicaMessage.slice(2);
    specialChar?.forEach(element => {
      spCh = spCh + "\\" + element?.value;
    });
    pattern = "^([" + lower + "" + upper + "" + num + "" + spCh + "]+\\s)*[" + lower + "" + upper + "" + num + "" + spCh + "]+$";
    return pattern;

  }
  showCreateAccountParameter() {
    this.sendCustomList = [];
    this.alreadySetValues = [];
    this.customList.forEach(element => {
      //to make fieldvalues null
      const data = {
        label: null,
        value: null,
      }
      data.label = element.fieldName;
      data.value = element.fieldValue;
      this.alreadySetValues.push(data);
    });
    this.customList.forEach(element => {
      if (element.isExisting) {
        if (element.fieldName == "Service Line Taxonomy Item") {
          element.inputFormat = this.serviceTaxonomyInputFormat;
        }
        else if (element.fieldName == "Bank Taxonomy Item") {
          element.inputFormat = this.bankTaxonomyInputFormat;
        }
      }
    });
    const data1 = {
      customList: this.customList,
      checkBoxArr: this.checkboxArr
    };

    this.ref1 = this.dialogService.open(AddAccountParameterComponent, {
      header: 'Add Parameter',
      width: '75%',
      height: '80%',
      data: data1,
      contentStyle: { 'border-radius': '4px' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.staticJson = [];
    this.ref1.onClose.subscribe(req => {
      if (req) {
        this.staticJson = req;
      }
      this.ref1.destroy();
      this.fetchParameters();
    });
  }
  existingParameterFetch() {
    if (this.customList.length > 0) {
      if (this.accountParameterJson[0].isExisting) {
        const custFieldName = [];
        const statFieldName = [];
        this.customList.forEach(customElement => {
          if (customElement.isExisting)
            custFieldName.push(customElement.fieldName);
        });
        this.accountParameterJson.forEach(staticElement => {
          if (staticElement.isExisting) {
            statFieldName.push(staticElement.fieldName);
          }
        });
        const removeList = custFieldName.filter(x => !statFieldName.includes(x));
        this.deleteParameter(removeList);
        const assignList = statFieldName.filter(x => !custFieldName.includes(x));
        this.parseStaticJson = [];
        assignList.forEach(element => {
          this.accountParameterJson.forEach(statElement => {
            if (statElement.fieldName == element) {
              this.parseStaticJson.push(statElement);
            }
          })
        });
        this.accountParameterJson = this.parseStaticJson;
      }
    }
    this.accountParameterJson.forEach((element: any = {}) => {
      const data = {
        id: null,
        fieldName: null,
        fieldInputtype: null,
        mandatoryField: false,
        maxCharacters: null,
        pattern: null,
        specialChar: [],
        inputFormat: [],
        isExisting: false,
        isConfigurable: false,
        fieldValue: null
      };
      data.fieldName = element.fieldName;
      data.fieldInputtype = element.fieldInputtype;
      data.mandatoryField = element.mandatoryField;
      data.maxCharacters = element.maxCharacters;
      data.specialChar = element?.specialChar;
      data.inputFormat = element.inputFormat;
      data.isExisting = element.isExisting;
      data.id = data.fieldName;
      data.isConfigurable = element.isConfigurable;
      data.fieldValue = element.fieldValue;
      data.pattern = element.pattern;
      if (!element.isConfigurable) {
        if (element.fieldName == "Service Line Taxonomy Item") {
          this.serviceTaxonomyInputFormat = "";
        }
        else if (element.fieldName == "Bank Taxonomy Item") {
          this.bankTaxonomyInputFormat = "";
          data.inputFormat = this.bankTaxonomyList;
        }
        if (data.fieldInputtype == 'Checkbox') {
          this.EditAccountForm.addControl(data.id,
            this.fb.control(false, []));
          this.EditAccountForm.controls[data.id].setValue(data.fieldValue);
        }
        else if (data.inputFormat[0] == 'Date range' && data.fieldInputtype == 'Input field in range') {
          this.EditAccountForm.addControl(data.id,
            this.fb.control(null, []));
          let date = [];
          this[data.id] = [];
          date = JSON.parse(data.fieldValue);
          date.forEach(dates => {
            this[data.id].push(new Date(dates));
          });
        }
        else if (data.fieldInputtype == 'Input field in range' && data.inputFormat[0] == 'Text/Number range') {
          this.range = JSON.parse(data.fieldValue);
          if (data.mandatoryField) {
            this.EditAccountForm.addControl(data.id + "_start",
              this.fb.control(null, [Validators.required]));
            this.EditAccountForm.addControl(data.id + "_end",
              this.fb.control(null, [Validators.required]));
          }
          else {
            this.EditAccountForm.addControl(data.id + "_start",
              this.fb.control(null, []));
            this.EditAccountForm.addControl(data.id + "_end",
              this.fb.control(null, []));
          }
        }
        else {
          if (data.fieldInputtype == 'TextBox' || data.fieldInputtype == 'Text area' || data.fieldInputtype == 'Text') {
            if (data.inputFormat == null) {
              data.pattern = this.setValidationsPatterns(data.inputFormat, data.specialChar);
            }
          }
          if (data.mandatoryField) {
            this.EditAccountForm.addControl(data.id,
              this.fb.control(null, [Validators.required]));
          }
          else {
            this.EditAccountForm.addControl(data.id,
              this.fb.control(null, []));
          }
        }
        if (data.fieldInputtype == 'Checkbox') {
          this.checkboxArr.push(data);
        }
        else {
          this.customList.push(data);
        }
      }

    });
    this.customList.forEach((element: any = {}) => {
      if (element.fieldName == SSO && element.fieldValue?.label === 'Enabled') {
        this.ssoEnabled = element.fieldValue.value === 'Enabled';
      }
    });
  }

  addressStartIpCheck(id: any) {
    this['IpFlag' + id] = false;
    this['duplicityFlag' + id] = false;
    this['greaterFlag' + id] = false;

    const regularExpressionIP4 = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)-(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;//NOSONAR
    const IP4startValue = this.EditAccountForm.value[id];


    const validateStart = regularExpressionIP4.test(String(IP4startValue).toLowerCase());
    if (IP4startValue == null || IP4startValue == "") {
      document.getElementById(id)!.style.borderColor = 'grey';
      this['duplicityFlag' + id] = false;
      this['IpFlag' + id] = false;
      this['greaterFlag' + id] = false;
      this.isValidIP = false;
    }
    else if (!validateStart && (IP4startValue != "" || IP4startValue != null)) {
      document.getElementById(id)!.style.borderColor = 'red';
      this['IpFlag' + id] = true;
      this['duplicityFlag' + id] = false;
      this['greaterFlag' + id] = false;
      this.isValidIP = true;

    }
    else {
      document.getElementById(id)!.style.borderColor = 'grey';
      this['IpFlag' + id] = false;
      this['greaterFlag' + id] = false;
      this.isValidIP = false;
      this['duplicityFlag' + id] = false;
      this.duplicityCheck(id);
    }

  }
  duplicityCheck(id: any) {
    this.isValidIP = false;
    this['greaterFlag' + id] = false
    this['duplicityFlag' + id] = false;

    const IP4startValue = this.EditAccountForm.value[id].split('-')[0];
    const IP4endValue = this.EditAccountForm.value[id].split('-')[1];
    if (this.IpList.length == 0) {
      const data = {
        index: null,
        startIpValue: null,
        endIpValue: null,
        fieldId: null
      }
      data.index = this.index + 1;
      data.startIpValue = this.EditAccountForm.value[id].split('-')[0];
      data.endIpValue = this.EditAccountForm.value[id].split('-')[1];
      data.fieldId = id;
      if (data.startIpValue == data.endIpValue) {
        this['duplicityFlag' + id] = true;
        this.isValidIP = true;
        document.getElementById(id)!.style.borderColor = 'red';
      } else if (data.startIpValue > data.endIpValue) {
        document.getElementById(id)!.style.borderColor = 'red';
        this['greaterFlag' + id] = true;
        this.isValidIP = true;

      } else {
        document.getElementById(id)!.style.borderColor = 'grey';
        this.IpList.push(data);
        this.IpList = [...this.IpList];
        this.isValidIP = false;

      }
    } else if (IP4startValue == IP4endValue) {
      this['duplicityFlag' + id] = true;
      document.getElementById(id)!.style.borderColor = 'red';
      this.isValidIP = true;
    } else if (IP4startValue > IP4endValue) {
      this['greaterFlag' + id] = true;
      document.getElementById(id)!.style.borderColor = 'red';
      this.isValidIP = true;
    } else {
      this.checkIPCondition = true;
      for (let i = 0; i < this.IpList.length; i++) {
        if (this.IpList[i].fieldId == id) {
          this.IpList.splice(i, 1);
          if (this.IpList[i].startIpValue == IP4startValue || this.IpList[i].endIpValue == IP4endValue || this.IpList[i].startIpValue == IP4endValue || this.IpList[i].endIpValue == IP4startValue) {
            this['duplicityFlag' + id] = true;
            document.getElementById(id)!.style.borderColor = 'red';
            this.isValidIP = true;
            this.checkIPCondition = false;
            break;
          }

        } else if (this.IpList[i].startIpValue == IP4startValue || this.IpList[i].endIpValue == IP4endValue || this.IpList[i].startIpValue == IP4endValue || this.IpList[i].endIpValue == IP4startValue) {
          this['duplicityFlag' + id] = true;
          document.getElementById(id)!.style.borderColor = 'red';
          this.isValidIP = true;
          this.checkIPCondition = false;
          break;
        }
      }
      if (this.checkIPCondition) {
        const data = {
          index: null,
          startIpValue: null,
          endIpValue: null,
          fieldId: null
        }
        data.index = this.index + 1;
        data.startIpValue = this.EditAccountForm.value[id].split('-')[0];
        data.endIpValue = this.EditAccountForm.value[id].split('-')[1];
        data.fieldId = id;
        this['duplicityFlag' + id] = false;
        this.IpList.push(data);
        this.IpList = [...this.IpList];
        this.isValidIP = false;

        document.getElementById(id)!.style.borderColor = 'grey';


      }
    }


  }

  ipTest(ipTest: any, arg1: string) {
    throw new Error('Method not implemented.');
  }
}
