import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { ConfirmationService, MessageService, MenuItem, Message } from 'primeng/api';
import { PagesService } from '../pages.service';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { DeactivateComponent } from '../Deactivate/Deactivate.component';
import { ActivateUserComponent } from '../ActivateUser/ActivateUser.component';
import { ForceLogOffComponent } from '../ForceLogOff/ForceLogOff.component';
import { DatePipe } from '@angular/common';
import { SuspendUserComponent } from '../SuspendUser/SuspendUser.component';
import { DeleteUserComponent } from '../DeleteUser/DeleteUser.component';
import { REBAC_UM_ENTITLEMENT_FLAG } from "app/app.constants";
import { ViewUserService } from './ViewUser.service';
import { DateFormatterPipe } from 'app/pipes/date-formatter.pipe';
import { TableModule } from 'primeng/table';
import {
  ALLOW,
  PENDING,
  DENY,
  ONE,
  ZERO,
  APPROVED,
  INTERNAL,
  NOT_APPROVED,
  AD_USER,
  DB_USER,
  INACTIVE,
  EXTERNAL_USER_TYPE,
  INTERNAL_USER_TYPE
} from 'app/constants';
import { MainComponent } from 'app/layouts/main/main.component';
@Component({
  selector: 'jhi-viewuser',
  templateUrl: './ViewUser.component.html',
  styleUrls: ['./ViewUser.component.scss'],
  providers: [MessageService, ConfirmationService, DialogService, DatePipe, DateFormatterPipe],
})
export class ViewUserComponent implements OnInit {
  items!: MenuItem[];
  items1: any[] = [];
  items2: any[] = [];
  flag1 = true;
  msgs: Message[] = [];
  messageFlag = true;
  ref!: DynamicDialogRef;
  ViewUserForm = this.fb.group({
    viewUserID: [null, []],
    userName: [null, []],
    createdOn: [null, []],
    statusMode: [null, []],
    primaryEmail: [null, []],
    contactNumber: [null, []],
    role: [null, []],
    groups: [null, []],
    ccprimesplitbutton: [null, []],
    firstName: [null, []],
    middleName: [null, []],
    lastName: [null, []],
    countryCode: [null, []],
    mirrorFlag: [null, []],
    dormancyReason: [null, []],
    userIdentity: [null, []],
    account: [null, []],
    entitlementSearch: [null, []],
  });
  pageloadvalues: any;
  ccprimebreadcrumbList = [{ label: `User Management` }, { label: `Users`, routerLink: ['/pages/userlisting'] }, { label: `View User` }];
  homeiconccprimebreadcrumb = { icon: 'pi pi-home', routerLink: ['/'] };
  htmlSrc: any;
  html2Src: any;
  html3Src: any;
  data: any;
  navigation: any;
  userID = '';
  ccprimesplitbuttonList: any[] = [];
  rolecount: any;
  groupcount: any;
  applicationCount: any;
  applicationId: string;
  uId: any;
  tableList: any[] = [];
  List1: any[] = [];
  List2: any[] = [];
  errorFlag: boolean;
  lab: string;
  len: any;
  uemail: any;
  roles: any;
  flagForceLogOff = 1;
  tableListdata: any[] = [];
  tableListdata1: any[] = [];
  current: any[] = [];
  tablelistFlag = false;
  userNameApplicationRole: any;
  customJSON: any;
  customList: any = [];
  fieldTypeList: any = [];
  cnt = 0;
  checked: boolean = false;
  value4: string;
  moduleID = "";
  checkboxArr: any = [];
  customFielDto = [];
  viewMore: boolean = true;
  viewToggleName: string;
  viewToggleIcon: string;
  otherDetails: any = [];
  noDetails: any = [];
  someArr: any = [];
  fullname: string;
  effectiveRoleListFlag = false;
  tableList1: any[] = [];
  tableList2: any[] = [];
  userType: any;
  selectList: any[] = [];
  respCustomList: any[] = [];
  tenantConfigId: any;
  SelectedEntity: any;
  tenantConfigFlag: boolean = false;
  selectFeatureList: any[] = [];
  featureList: any[] = [];
  userTypeselectedValues: any[] = [];
  userTypeList: any[] = [];
  inviteUserBtnFlag = true;
  createUserBtnFlag = true;
  deactivateUserBtnFlag = true;
  deleteUserBtnFlag = true;
  revokeUserBtnFlag = true;
  suspendUserBtnFlag = true;
  umPkId: any = null;
  rebacFlag: string;
  isExternal: any;
  //entitlement
  entitlementStatusDropdownList: any = [];
  entitlementStatusResponse: any = [];
  allowStatusId: any;
  pendingStatusId: any;
  componentDropdownList: any[] = [];
  bankList: any[] = [];
  originalBankList: any = [];
  entitlementList: any[] = [];
  originalEntitlementList: any[] = [];
  totalDependentCount: number = 0;
  deletedEntOfUser: any[] = [];
  isEmailSendFlag: any[] = [];
  loginUserName: string;
  userIdentity: string;
  entitlementCount: number = 0;
  emptyFlag: number = 0;
  attributeList: any = [];
  userParameterList: any[] = [];
  attributeTableList: any[] = [];
  userParamJson: any = [];
  routeFlag: boolean = false;
  userRoleList: any[] = [];
  originalUserRoleList: any[] = [];
  entRoleCount: number = 0;
  emailSendButtonLabel: string = "Send E-mail";
  emailSendButtonTooltip: string = "";
  userRoleColumns: { field: string; header: string; filterMatchMode: string; display: boolean; }[];
  selectedUserRoleData: any;
  InactiveStatus = INACTIVE;
  applicationList: any[] = [];
  tableEntitlementList: any;
  componentIdsSearch: any[];
  search: any;
  entitlementtable: any[];

  constructor(
    private confirmationService: ConfirmationService,
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    private messageService: MessageService,
    public dialogService: DialogService,
    public datepipe: DatePipe,
    private viewUserService: ViewUserService,
    private dateFormatterPipe: DateFormatterPipe,
    public main: MainComponent,
  ) {
    this.navigation = this.router.getCurrentNavigation();
    this.data = this.navigation?.extras?.state?.data;
    this.routeFlag = this.navigation?.extras?.state?.routeFlag;
    if (this.data && !this.routeFlag) {
      this.setPageProperties(JSON.parse(this.data));
    }
    if (this.data && this.routeFlag) {
      this.umPkId = this.data?.USER_ID;
    }
    let userName: string;
    userName = localStorage.getItem('username');
    if (userName.length > 0) {
      this.loginUserName = userName;
    } else {
      this.loginUserName = 'anonymousUser';
    }
  }
  ngOnInit(): void {
    if (this.routeFlag) {
      this.userID = this.data.ID;
    }
    this.rebacFlag = REBAC_UM_ENTITLEMENT_FLAG + "";
    this.onViewUserPageLoad();
    this.viewToggle();
    if (this.rebacFlag == "false") {
      this.accessDetails();
    }

    if (this.rebacFlag == "true") {
      this.userRoleColumns = [
        { field: 'Role ID', header: 'Role ID', filterMatchMode: 'contains', display: true },
        { field: 'Role Name', header: 'Role Name', filterMatchMode: 'contains', display: true },
        { field: 'Updated On', header: 'Updated On', filterMatchMode: 'contains', display: true }
      ];
      this.getEntitlementStatusList();
      this.fetchAttributeList();
    }
    this.htmlSrc = '';
    this.html2Src = '';
    this.html3Src = '';
    if (this.ViewUserForm.value.middleName == null) {
      this.fullname = this.ViewUserForm.value.firstName + " " + this.ViewUserForm.value.lastName;
    }
    else {
      this.fullname = this.ViewUserForm.value.firstName + " " + this.ViewUserForm.value.middleName + " " + this.ViewUserForm.value.lastName;
    }
    this.getTenantModuleConfigByTenantId();
    this.getApplicationList();
  }
  getEntityList(): void {
    this.pagesService.getEntity().subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          if (data.label == 'Users') {
            this.moduleID = data.value;
            this.fetchCustomField();
          }
        });
      }
    });
  }
  onViewUserPageLoad() {
    this.pagesService.onViewUserPageLoad(this.userID).subscribe((results: any) => {
      let pagePropBind = {
        viewUserID: results.success.result.id,
        userName: results.success.result.user_name,
        createdOn: results.success.result.createdDate,
        primaryEmail: results.success.result.emails[0]?.value,
        contactNumber: results.success.result.phoneNumbers[0]?.value,
        statusMode: results.success.result.user_status,
        firstName: results.success.result.first_name,
        middleName: results.success.result.middle_name,
        lastName: results.success.result.last_name,
        countryCode: results.success.result.phoneNumbers[0]?.countryCode,
        dormancyReason: results.success.result.dormancy_reason,
        mirrorFlag: 'viewMirror',
        umPkId: results.success.result.um_pk_id,
        account: results.success.result?.account?.label
      };
      this.respCustomList = results.success.result.customFieldDataDtoList;
      this.userType = results.success.result.isExternal;
      this.umPkId = results.success.result.um_pk_id;
      this.userParamJson = JSON.parse(results.success.result.userParamJson);
      if (results.success.result.userType == AD_USER) {
        this.userIdentity = "AD";
      }
      else if (results.success.result.userType == DB_USER) {
        this.userIdentity = "DB";
      }
      else {
        this.userIdentity = null;
      }
      this.pageloadvalues = pagePropBind;
      this.setPageProperties(pagePropBind);

      this.userNameApplicationRole = this.ViewUserForm.value.firstName + " " + this.ViewUserForm.value.lastName + " (" + this.ViewUserForm.value.primaryEmail + ")";
      this.otherDetails = [];

    });

    //getting roles on page load
    if (this.rebacFlag == "false") {
      const reqBody = {};
      reqBody['tenantId'] = this.pagesService.tenantId;
      reqBody['userId'] = this.userID;
      const formValues = reqBody;
      this.rolecount = 0;
      this.tableList1 = [];
      this.tableList2 = [];
      this.pagesService.getViewUserEffectiveRoles(formValues).subscribe((results: any) => {
        let responseData = [];
        if (results.hasOwnProperty("success")) {
          responseData = results.success.result;
        }
        else {
          responseData = [];
        }
        if (responseData && responseData.length > 0) {
          responseData.forEach((element: any = {}) => {
            const data = {
              roleId: null,
              roleName: null,
              groupList: null,
              newGroupList: null,
              tempGroupList: []
            };
            data.roleId = element.roleId;
            data.roleName = element.roleName;
            data.groupList = element.groupList;
            element.groupList.forEach((element1: any = {}) => {
              if (element1.groupName != null) {
                data.tempGroupList.push(element1.groupName);
              }
            });
            data.newGroupList = data.tempGroupList.toString();
            this.tableList1.push(data);
            this.tableList2.push(data);
          });
          this.tableList1 = [...this.tableList1];
          this.tableList2 = [...this.tableList2];
          this.effectiveRoleListFlag = true;
        } else {
          this.effectiveRoleListFlag = false;
        }
        if (this.tableList1?.length > 9) {
          this.rolecount = this.tableList1.length;
        }
        else if (this.tableList1?.length >= 1) {
          this.rolecount = "0" + this.tableList1.length;
        }
      });

      //getting groups on page load
      this.pagesService.getViewUserGroups(this.userID).subscribe((results: any) => {
        let pagePropBind = {
          groups: results?.success?.result,
        };
        this.groupcount = 0;
        if (pagePropBind?.groups?.length > 9) {
          this.groupcount = pagePropBind?.groups?.length;
        }
        else if (pagePropBind?.groups?.length >= 1) {
          this.groupcount = "0" + pagePropBind?.groups?.length;
        }
        this.pageloadvalues = pagePropBind;
        this.setPageProperties(pagePropBind);
      });
    }
    this.getEntityList();
  }

  setPageProperties(props: any): void {
    const pageData: any = {};
    const calendarControls = ['createdOn'];
    Object.keys(props).forEach(key => {
      if (calendarControls.includes(key)) {
        pageData[key] = new Date(props[key]);
      } else {
        pageData[key] = props[key];
      }
    });
    this.userID = pageData.id;
    this.isExternal = pageData.isExternal;
    this.umPkId = pageData.umPkId;
    this.ViewUserForm.patchValue(pageData);
    if (this.ViewUserForm.value.middleName == null) {
      this.fullname = this.ViewUserForm.value.firstName + " " + this.ViewUserForm.value.lastName;
    }
    else {
      this.fullname = this.ViewUserForm.value.firstName + " " + this.ViewUserForm.value.middleName + " " + this.ViewUserForm.value.lastName;
    }
  }

  //for edit user
  navigateToEdit(rowData: any): void {
    const navigationExtras: NavigationExtras = {
      state: {
        data: JSON.stringify(rowData),
        umPkId: this.umPkId,
        userType: this.userType ? EXTERNAL_USER_TYPE : INTERNAL_USER_TYPE
      },
    };
    this.router.navigate(['/pages/edituser'], navigationExtras);
  }

  // navigate to mirror user
  navigateMirrorUser(data: any): void {
    const navigationExtras: NavigationExtras = {
      state: {
        data: JSON.stringify(data),
      },
    };
    this.router.navigate(['/pages/mirroruser'], navigationExtras);
  }

  checkMenuOption(status: any) {
    this.items = [
      {
        label: 'Edit',

        command: e => {
          this.navigateToEdit(this.ViewUserForm.value);
          // logic
        },

      },
      {
        label: 'Activate',
        command: () => {
          this.activateUser();
        },
      },
      {
        label: 'Deactivate',
        command: () => {
          this.deactivateUser();
        },
      },

      // {
      //   label: 'Mirror User',
      //   command: e => {
      //     this.navigateMirrorUser(this.ViewUserForm.value);
      //     // logic
      //   },
      // },

      {
        label: 'Unlock User',
        disabled: true,
      },
      {
        label: 'Force Logoff',
        command: () => {
          this.ForceLogOff(this.ViewUserForm.value.viewUserID);
        },
      },
      {
        label: 'Suspend User',
        command: () => {
          this.suspendUser(this.ViewUserForm.value.viewUserID);
        },
      },
      {
        label: 'Delete User',
        command: () => {
          this.DeleteUser(this.ViewUserForm.value.viewUserID, this.fullname);
        },
      },
    ];
    if (status == 'STAGED') {
      this.items[1].visible = false;
      this.items[2].visible = false;
      this.items[4].visible = false;
      this.items[5].visible = false;
      this.items[6].visible = false;
    } else if (status == 'DEACTIVATED') {
      this.items[0].visible = false;
      this.items[2].visible = false;
      this.items[3].visible = false;
      this.items[4].visible = false;
      this.items[5].visible = false;
      this.items[6].visible = false;
      if (this.deleteUserBtnFlag) {
        this.items[7].visible = true;
      }
      else if (!this.deleteUserBtnFlag) {
        this.items[7].visible = false;
      }
    } else if (status == 'ACTIVE') {
      this.items[1].visible = false;
      this.items[4].visible = false;
      if (this.deactivateUserBtnFlag) {
        this.items[2].visible = true;
      }
      else if (!this.deactivateUserBtnFlag) {
        this.items[2].visible = false;
      }
      if (this.deleteUserBtnFlag) {
        this.items[7].visible = true;
      }
      else if (!this.deleteUserBtnFlag) {
        this.items[7].visible = false;
      }
      if (this.suspendUserBtnFlag) {
        this.items[6].visible = true;
      }
      else if (!this.suspendUserBtnFlag) {
        this.items[6].visible = false;
      }
    } else if (status == 'LOCKED') {
      this.items[1].visible = false;
      this.items[5].visible = false;
      if (this.deactivateUserBtnFlag) {
        this.items[2].visible = true;
      }
      else if (!this.deactivateUserBtnFlag) {
        this.items[2].visible = false;
      }
      if (this.deleteUserBtnFlag) {
        this.items[7].visible = true;
      }
      else if (!this.deleteUserBtnFlag) {
        this.items[7].visible = false;
      }
      if (this.suspendUserBtnFlag) {
        this.items[6].visible = true;
      }
      else if (!this.suspendUserBtnFlag) {
        this.items[6].visible = false;
      }
    } else if (status == 'SUSPENDED') {
      this.items[0].visible = false;
      this.items[4].visible = false;
      this.items[5].visible = false;
      this.items[6].visible = false;
      if (this.deleteUserBtnFlag) {
        this.items[7].visible = true;
      }
      else if (!this.deleteUserBtnFlag) {
        this.items[7].visible = false;
      }
      if (this.deactivateUserBtnFlag) {
        this.items[2].visible = true;
      }
      else if (!this.deactivateUserBtnFlag) {
        this.items[2].visible = false;
      }
    }
    if (this.pagesService.pageRebacFlag == 'true') {
      this.items[4].visible = false;
    }
    if (this.ViewUserForm.value.primaryEmail == this.uemail) {
      this.items[5].visible = false;
      this.items[6].visible = false;
      this.items[7].visible = false;
    }
  }

  //deactivate user dialog box
  deactivateUser() {
    const uid = [this.ViewUserForm.value.viewUserID];
    this.ref = this.dialogService.open(DeactivateComponent, {
      header: 'Deactivate User',
      data: { id: uid },
      width: '35%',
      contentStyle: { 'max-height': '170px', overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Failed to deactivate user') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
      else if (msg) {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
      this.userID = this.ViewUserForm.value.viewUserID;
      if (msg == 'User Deactivated Succesfully' || msg == 'Users Deactivated Succesfully') {
        this.rolecount = 0;
        this.onViewUserPageLoad();
        this.accessDetails();
      }
    });
  }

  //activate user dialog box
  activateUser() {
    const uid = [this.ViewUserForm.value.viewUserID];
    this.ref = this.dialogService.open(ActivateUserComponent, {
      header: 'Activate User',
      data: { id: uid },
      width: '35%',
      contentStyle: { 'max-height': '150px', overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg) {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
      this.userID = this.ViewUserForm.value.viewUserID;
      if (msg) {
        this.onViewUserPageLoad();
        this.accessDetails();
      }
    });
  }

  // force logoff dialog box
  ForceLogOff(id: any) {
    this.ref = this.dialogService.open(ForceLogOffComponent, {
      header: 'ForceLogOff User',
      data: { id: [id] },
      width: '460px',
      contentStyle: { 'max-height': '150px', overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg) {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }


    });
  }

  //suspend user dialog box
  suspendUser(id: any) {
    this.ref = this.dialogService.open(SuspendUserComponent, {
      header: 'Suspend User',
      data: { id: [id] },
      width: '35%',
      contentStyle: { 'max-height': '150px', overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'error') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: "Failed to suspend user" });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
      else if (msg) {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.userID = this.ViewUserForm.value.viewUserID;
        this.onViewUserPageLoad();
        this.accessDetails();
      }
    });
  }

  DeleteUser(id: any, name: any) {
    this.ref = this.dialogService.open(DeleteUserComponent, {
      header: 'Delete User',
      data: { id: [id], name: name },
      width: '35%',
      contentStyle: { 'max-height': '150px', overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Failed') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Failed to delete user.' });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);

      }
      else if (msg) {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.navigateToUserlist();
      }
      this.userID = this.ViewUserForm.value.viewUserID;
    });
  }

  navigateToUserlist(): void {
    const navigationExtras: NavigationExtras = {
      state: {
        msg: 'User Deleted Sucessfully',
      },
    };
    this.router.navigate(['/pages/userlisting'], navigationExtras);
  }
  /**
   * Application tab for access details
   */

  // getting application and roles of the user
  accessDetails(): void {
    const reqBody = {};
    reqBody['tenantId'] = this.pagesService.tenantId;
    reqBody['userId'] = this.userID;
    const formValues = reqBody;
    this.tableList = [];
    this.applicationCount = 0;
    this.pagesService.accessDetailUserApplicationRoles(formValues).subscribe({
      next: results => {
        const responseData = results?.success?.result;
        if (responseData?.length > 9) {
          this.applicationCount = responseData?.length;
        }
        else if (responseData?.length >= 1) {
          this.applicationCount = "0" + responseData?.length;
        }
        if (responseData == null || responseData == undefined) {
          this.tablelistFlag = true;
          this.applicationCount = 0;
        }
        this.tableList = [...responseData];
        for (let j = 0; j < responseData?.length; j++) {
          this['len' + j] = responseData[j]?.applicationRoles.length - 3;
          this['attrLen' + j] = responseData[j]?.attributes.length - 3;
          this['tableListdata' + j] = [];
          //for application roles
          if (responseData[j]?.applicationRoles && responseData[j]?.applicationRoles.length > 0) {
            responseData[j]?.applicationRoles.forEach((element: any = {}) => {
              const data = {
                label: null,
                value: null,
              };
              data.label = element.label;
              data.value = element.value;
              this['tableListdata' + j].push(data);
            });

            this['items1' + j] = [];
            if (this['tableListdata' + j].length > 0) {
              for (let i = 0; i < responseData[j]?.applicationRoles.length; i++) {
                if (i > 2) {
                  this['items1' + j].push({
                    label: this['tableListdata' + j][i]?.label,
                  })
                }
              }
            }
            this.List1 = [...this.List1];
            this.errorFlag = false;
          }
          else {
            this.errorFlag = true;
          }
        }

        //for attributes
        for (let j = 0; j < responseData?.length; j++) {
          this['attrLen' + j] = responseData[j]?.attributes.length - 3;
          this['tableListdata' + j] = [];
          //for application roles
          if (responseData[j]?.attributes && responseData[j]?.attributes.length > 0) {
            responseData[j]?.attributes.forEach((element: any = {}) => {
              const data = {
                label: null,
                value: null,
              };
              data.label = element.label;
              data.value = element.value;
              this['tableListdata' + j].push(data);
            });

            this['items2' + j] = [];
            if (this['tableListdata' + j].length > 0) {
              for (let i = 0; i < responseData[j]?.attributes.length; i++) {
                if (i > 2) {
                  this['items2' + j].push({
                    label: this['tableListdata' + j][i]?.label,
                  })
                }
              }
            }
            this.List2 = [...this.List2];
            this.errorFlag = false;
          }
          else {
            this.errorFlag = true;
          }
        }
      },
      error: error => {
        this.tablelistFlag = true;
      }
    });
  }

  //navigate to user mapping
  navigateUserMapping(data: any, selectedRole: any): void {
    const navigationExtras: NavigationExtras = {
      state: {
        data: JSON.stringify(data),
        viewUserFlag: true,
        user_mappingData: { appId: data.applicationId, Uname: this.userNameApplicationRole, uID: this.ViewUserForm.value.viewUserID, role: selectedRole },
      },
    };
    this.router.navigate(['/pages/applicationroleusermapping'], navigationExtras);
  }

  viewToggle() {
    this.viewMore = !this.viewMore;
    if (this.viewMore) {
      this.viewToggleName = "View Less";
      this.viewToggleIcon = "pi pi-chevron-up";
    }
    else {
      this.viewToggleName = "View More";
      this.viewToggleIcon = "icon-arrowDown";
    }
  }

  fetchCustomField() {
    this.customList = [];
    this.pagesService.getFetchCustomFieldListing(this.moduleID).subscribe(results => {
      const responseData = results?.success?.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            id: null,
            fieldName: null,
            fieldInputtype: null,
            inputFormat: null,
            mandatoryField: null,
            editableField: null,
            configJson: null,
            maxCharacters: null,
            pattern: null,
            dropdownValu: null,
          };
          data.id = element.id;
          data.fieldName = element.fieldName;
          this.customJSON = JSON.parse(element.fieldConfigJason);
          data.configJson = this.customJSON;
          data.fieldInputtype = data.configJson.fieldLabel;
          data.inputFormat = data.configJson.inputvalue_format;
          data.mandatoryField = data.configJson.mandatory;
          data.editableField = data.configJson.editable;
          data.maxCharacters = data.configJson.MaxCharacters;
          this.customList.push(data);
        });
        this.customList = [...this.customList];
        let cc = this.respCustomList;
        this.otherDetails = [];
        this.someArr = [];
        if (cc == null) {
          this.customList.forEach(element => {
            this.otherDetails.push({ label: element.fieldName, value: '-' });
          });
        }
        else {
          cc.forEach(element => {
            this.someArr.push(element.customFieldId);
          });
          this.customList.forEach(element1 => {
            if (this.someArr.includes(element1.id)) {
              cc.forEach(element2 => {
                if (element2.customFieldId == element1.id) {
                  if (element1.fieldInputtype == "Checkbox") {
                    let valCheck = element2.customFieldValue
                    valCheck = valCheck[0][0].toUpperCase() + valCheck.substr(1);
                    this.otherDetails.push({ label: element1.fieldName, value: valCheck });
                  }
                  else if (element1?.inputFormat[0] == 'Date range') {
                    let dd = JSON.parse(element2.customFieldValue);
                    let startDate = this.datepipe.transform(dd[0], 'dd-MM-yyyy');
                    let endDate = this.datepipe.transform(dd[1], 'dd-MM-yyyy');
                    this.otherDetails.push({ label: element1.fieldName, value: startDate + " to " + endDate });
                  }
                  else if (element1?.inputFormat[0] == 'Text/Number range') {
                    let dd = JSON.parse(element2.customFieldValue);
                    let startRange = dd.start;
                    let endRange = dd.last;
                    this.otherDetails.push({ label: element1.fieldName, value: startRange + " to " + endRange });
                  }
                  else {
                    this.otherDetails.push({ label: element1.fieldName, value: element2.customFieldValue });
                  }
                }
              });
            }
            else {
              this.otherDetails.push({ label: element1.fieldName, value: '-' });
            }
          });
        }
      }
    });
  }
  customFilter(event: any) {
    if (event.filters.groupList.length > 0) {
      this.groupFilterMethod(event);
    }
  }
  groupFilterMethod(event: any) {
    let filtered: any[] = [];
    const matMode = event.filters.groupList[0].matchMode;
    let items: any;
    let cc;
    let query = event.filters.groupList[0].value;
    if (query.length > 0) {
      if (matMode == "startsWith") {
        this.tableList2.forEach(element => {
          items = element;
          cc = items.groupList.length;
          for (let j = 0; j < cc; j++) {
            if (items.groupList[j].groupName?.toLowerCase().startsWith(query.toLowerCase())) {
              event.filteredValue.push(items);
            }
          }
        });
      }
      else if (matMode == "contains") {
        this.tableList2.forEach(element => {
          items = element;
          cc = items.groupList.length;
          for (let j = 0; j < cc; j++) {
            if (items.groupList[j].groupName?.toLowerCase().includes(query.toLowerCase())) {
              event.filteredValue.push(items);
            }
          }
        });
      }
      else if (matMode == "endsWith") {
        this.tableList2.forEach(element => {
          items = element;
          cc = items.groupList.length;
          for (let j = 0; j < cc; j++) {
            if (items.groupList[j].groupName?.toLowerCase().indexOf(query.toLowerCase(), items.groupList[j].groupName?.length - query.length) !== -1) {
              event.filteredValue.push(items);
            }
          }
        });
      }
      else if (matMode == "equals") {
        this.tableList2.forEach(element => {
          items = element;
          cc = items.groupList.length;
          for (let j = 0; j < cc; j++) {
            if (items.groupList[j].groupName?.toLowerCase() == query.toLowerCase()) {
              event.filteredValue.push(items);
            }
          }
        });
      }
      else {
        this.tableList1 = this.tableList2;
      }
    }
  }
  getTenantModuleConfigByTenantId(): void {
    this.pagesService.getTenantModuleConfigByTenantId(this.pagesService.tenantId).subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        this.SelectedEntity = results?.success?.result[0]?.moduleId;
        this.tenantConfigFlag = true;
        this.deactivateUserBtnFlag = false;
        this.deleteUserBtnFlag = false;
        this.suspendUserBtnFlag = false;
        this.tenantConfigId = results?.success?.result[0]?.tenantConfigId;
        this.selectFeatureList = results?.success?.result[0]?.featureList;
        this.userTypeselectedValues = results?.success?.result[0]?.usertypeList;
        this.getFeatureByModuleIdList();
      }
    });
  }
  getFeatureByModuleIdList(): void {
    this.pagesService.getFeatureByModuleId(this.SelectedEntity).subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
            id: null,
            isDeleted: false
          };
          data.label = element.label;
          data.value = element.value;
          this.featureList.push(data);
        });
        this.featureList = [...this.featureList];
        this.setFeatureValidation();
      }
    });
  }
  setFeatureValidation() {
    this.featureList.forEach(element1 => {
      this.selectFeatureList.forEach(element2 => {
        if (element2.isDeleted == 'false') {
          if (element2.featureId == element1.value) {
            if (element1.label == 'Deactivate User') {
              this.deactivateUserBtnFlag = true;
            }
            else if (element1.label == 'Delete User') {
              this.deleteUserBtnFlag = true;
            }
            else if (element1.label == 'Revoke User') {
              this.revokeUserBtnFlag = true;
            }
            else if (element1.label == 'Suspend User') {
              this.suspendUserBtnFlag = true;
            }
          }
        }
      });
    });
  }
  getEntitlementStatusList() {
    this.entitlementStatusDropdownList = [];
    this.viewUserService.getEntitlementStatusList().subscribe(
      {
        next: response => {
          let statusRes = response;
          this.entitlementStatusResponse = statusRes;
          let namesArray: string[] = [ALLOW, DENY];
          for (let i = 0; i < this.entitlementStatusResponse.length; i++) {
            if (namesArray.includes(this.entitlementStatusResponse[i].NAME)) {
              let res = statusRes[i];
              this.entitlementStatusDropdownList.push({ label: res.NAME, value: res.ID });
            }
          }

          this.allowStatusId = this.entitlementStatusDropdownList.filter(status => status.label == ALLOW)[0].value;
          this.pendingStatusId = this.entitlementStatusResponse.filter(status => status.NAME == PENDING)[0].ID;

          this.getComponentList();
        },
        error: err => {
          this.showErrorValidations(err.error);
        }
      });
  }
  showErrorValidations(message: string) {
    this.messageFlag = true;
    this.messageService.add({ key: 'myKey1', severity: 'error', detail: message });
    setTimeout(() => {
      this.messageFlag = false;
    }, 3000);
  }
  getComponentList() {
    this.viewUserService.getComponentList().subscribe(
      {
        next: response => {
          this.componentDropdownList = response;
          setTimeout(() => {
            this.getUserEntitlementList();
          }, 200);
        },
        error: err => {
          this.showErrorValidations(err.error);
        }
      });
  }
  async getUserEntitlementList() {
    this.bankList = [];
    this.originalBankList = [];
    this.entitlementList = [];
    this.originalEntitlementList = [];
    this.totalDependentCount = 0;
    this.deletedEntOfUser = [];
    this.isEmailSendFlag = [];
    const formData: FormData = new FormData();
    formData.append('userId', this.umPkId);
    if (this.umPkId == null) {
      //dont to anything;
    }
    else {

      await this.viewUserService.getUserEntitlementList(formData).subscribe(//NOSONAR
        {
          next: async response => { //NOSONAR
            let nonDeletedEntOfUser: any = [];
            nonDeletedEntOfUser = response['USER_ENTITLEMENTS'].filter(data => data['IS_DELETED'] == 'N');
            this.deletedEntOfUser = response['USER_ENTITLEMENTS'].filter(data => data['IS_DELETED'] == 'Y');
            let entitlementResponse = nonDeletedEntOfUser;
            for (let i = 0; i < entitlementResponse.length; i++) {
              entitlementResponse[i]['USER_TYPE_ID'] = response['USER_TYPE_ID'];
              entitlementResponse[i]['COMPONENT_NAME'] = this.componentDropdownList.filter(
                c => c.PRIME_COMPONENT_ID == entitlementResponse[i].COMPONENT_ID
              )[0].COMPONENT_NAME;

              if (
                entitlementResponse[i]['OVERALL_STATUS'] === PENDING ||
                (this.isExternal?.toString().toLowerCase() == INTERNAL?.toString().toLowerCase() &&
                  entitlementResponse[i].IS_ADMS_ENTITLEMENT == ONE &&
                  entitlementResponse[i].IS_ADMS_APPROVED == ZERO)
              ) {
                entitlementResponse[i]['OVERALL_STATUS'] = DENY;
              }

              if (entitlementResponse[i].DEPENDENT_LIST && entitlementResponse[i].DEPENDENT_LIST.length > 0) {
                this.totalDependentCount++;
                let dependenList = [];
                let dependenData = entitlementResponse[i].DEPENDENT_LIST.toString().split(',');
                for (let j = 0; j < dependenData.length; j++) {
                  let rowData = dependenData[j].toString().split(':');
                  let data = {
                    ENTITLEMENT_NAME: rowData[1]
                  };
                  dependenList.push(data);
                }
                entitlementResponse[i]['DEPENDENT_LIST'] = dependenList;
              }

              if (entitlementResponse[i].hasOwnProperty('SOURCE')) {
                entitlementResponse[i]['SOURCE'] = entitlementResponse[i]['SOURCE'].toString().replaceAll(',', ' \n ');
              }
              let approvalLevels = [];
              if (entitlementResponse[i].APPROVAL_LEVELS) {
                let approvalList = entitlementResponse[i].APPROVAL_LEVELS.toString().split(';');
                for (let k = 0; k < approvalList.length; k++) {
                  let rowData = approvalList[k].toString().split('|');
                  let data = {
                    USER_NAME: rowData[0],
                    LEVEL_NO: rowData[1],
                    STATUS: rowData[2],
                    ACTION_DATE: this.dateFormatterPipe.transform(rowData[3])
                  };
                  approvalLevels.push(data);
                }
                entitlementResponse[i]['APPROVAL_LEVELS'] = approvalLevels;
              }

              if (approvalLevels.length == 0) {
                entitlementResponse[i][
                  "selectedUmStatus"
                ] = this.entitlementStatusDropdownList.filter(
                  (s) => s.value == entitlementResponse[i].OVERALL_STATUS_ID
                )[0];
              }
              let rejectedApproval = approvalLevels.filter(
                (app) => app.STATUS == NOT_APPROVED
              );

              if (rejectedApproval.length > 0) {
                rejectedApproval[0]["COLOR"] = "#D6002A";
                rejectedApproval = approvalLevels
                  .filter((app) => app.STATUS == NOT_APPROVED)
                  .map((a) => {
                    if (this.loginUserName.toLowerCase() === approvalLevels[0]["USER_NAME"].toLowerCase()) {
                      (a['COLOR'] = '#e91224'); (a['TOOLTIP'] = 'Not Approved by ' + 'you' + ' on ' + a['ACTION_DATE']);
                    } else {
                      (a['COLOR'] = '#e91224'); (a['TOOLTIP'] = 'Not Approved by ' + a.USER_NAME + ' on ' + a['ACTION_DATE']);
                    }
                  });
                approvalLevels             //NOSONAR
                  .filter((app) => app.STATUS != NOT_APPROVED)
                  .map((a) => {
                    (a["COLOR"] = "#6e706e");
                    (a["TOOLTIP"] = "Approval process is disabled.");
                  });
              } else {
                let approvedApproval = approvalLevels
                  .filter((app) => app.STATUS == APPROVED)
                  .map((a) => {
                    if (
                      this.loginUserName.toLowerCase() ===
                      a["USER_NAME"].toLowerCase()
                    ) {
                      (a["COLOR"] = "#9AE198");
                      (a["TOOLTIP"] =
                        "Approved by " + "you" + " on " + a["ACTION_DATE"]);
                    } else {
                      (a["COLOR"] = "#9AE198");
                      (a["TOOLTIP"] =
                        "Approved by " + a.USER_NAME + " on " + a["ACTION_DATE"]);
                    }
                  });
                if (approvedApproval.length > 0) {
                  approvalLevels //NOSONAR
                    .filter((app) => app.STATUS != APPROVED)
                    .map((a) => {
                      if (
                        this.loginUserName.toLowerCase() ===
                        approvalLevels[0]["USER_NAME"].toLowerCase()
                      ) {
                        (a["COLOR"] = "#F4B40F");
                        (a["TOOLTIP"] =
                          " Raised by " + "you" + " on " + a["ACTION_DATE"]);
                      } else {
                        (a["COLOR"] = "#F4B40F");
                        (a["TOOLTIP"] =
                          " Raised by " +
                          a.USER_NAME +
                          " on " +
                          a["ACTION_DATE"]);
                      }
                    });
                } else {
                  let pendingApproval = approvalLevels.filter(
                    (app) => app.STATUS == PENDING
                  );
                  if (pendingApproval.length > 0) {
                    approvalLevels.map((a) => { //NOSONAR
                      if (
                        this.loginUserName.toLowerCase() ===
                        approvalLevels[0]["USER_NAME"].toLowerCase()
                      ) {
                        a["COLOR"] = "#F4B40F";
                        a["TOOLTIP"] =
                          " Raised by " +
                          "you" +
                          " on " +
                          pendingApproval[0].ACTION_DATE;
                      } else {
                        a["COLOR"] = "#F4B40F";
                        a["TOOLTIP"] =
                          " Raised by " +
                          pendingApproval[0].USER_NAME +
                          " on " +
                          pendingApproval[0].ACTION_DATE;
                      }
                    });
                  }
                }

                this.tableEntitlementList = entitlementResponse;
                this.entitlementList = entitlementResponse;
                this.entitlementtable = this.entitlementList;
                this.entitlementCount = this.entitlementList.length;
                this.originalEntitlementList = this.entitlementList;
                let localTime;
                if (this.entitlementList.length > 0) {
                  this.emptyFlag = 1;
                }
                else {
                  this.emptyFlag = 0;
                }
              }
            }
          },
          error: error => {
            this.showErrorValidations(error.error);
          }
        });
    }
    console.log(this.entitlementList);
    this.getUserRoleList();
  }
  navigateToDetailScreen(entitlementData) {
    this.router.navigateByUrl('/pages/entitlementsdetails', {
      state: { entitlement: entitlementData },
      skipLocationChange: false
    });
    let count = 2;
    this.main.items[count].expanded = true;
    this.main.items[1].expanded = false;
  }
  fetchAttributeList() {
    let reqBody = {};
    reqBody["tenantId"] = this.pagesService.tenantId;
    reqBody["userId"] = this.userID;
    const formValues = reqBody;
    this.pagesService.getAccountParameter(formValues).subscribe(results => {
      const responseData = results?.success?.result;
      let accountParamJson = JSON.parse(responseData?.accountParamJson);
      if (accountParamJson && accountParamJson.length > 0) {
        accountParamJson.forEach((element: any = {}) => {
          if (element.isConfigurable) {
            const data = {
              id: null,
              fieldName: null,
              fieldValue: null,
              fieldInputtype: null
            }
            data.id = element.id;
            data.fieldName = element.fieldName;

            data.fieldInputtype = element.fieldInputtype;
            if (data.fieldInputtype == "Dropdown") {
              data.fieldValue = element.fieldValue.value;
            }
            else {
              data.fieldValue = element.fieldValue;
            }
            this.attributeList.push(data);

          }
        });
      }
      this.getUserParameter();
    });
  }

  getUserParameter(): void {

    if (this.userParamJson && this.userParamJson.length > 0) {
      this.userParamJson.forEach((element: any = {}) => {
        const data = {
          id: null,
          fieldName: null,
          fieldValue: null,
          fieldInputtype: null
        }
        data.id = element.id;
        data.fieldName = element.fieldName;

        data.fieldInputtype = element.fieldInputtype;
        if (data.fieldInputtype == "Dropdown") {
          data.fieldValue = element.fieldValue.value;
        }
        else {
          data.fieldValue = element.fieldValue;
        }
        this.userParameterList.push(data);
      });
      this.checkOveriddenParam();
    }
    else {
      this.attributeTableList = this.attributeList;
    }
  }

  checkOveriddenParam() {
    let removeAccData = [];
    this.userParameterList.forEach(
      (element1, index) => {
        this.attributeList.forEach(
          (element2, index2) => {
            if (element1.id == element2.id) {
              if (element1.fieldInputtype == "Dropdown") {
                if (element1.fieldValue != element2.fieldValue) {
                  removeAccData.push(element1.id);
                  this.attributeTableList.push(element1);
                }
                else if (element1.fieldValue == element2.fieldValue) {
                  removeAccData.push(element1.id);
                  this.attributeTableList.push(element2);
                }
              }
              else {
                removeAccData.push(element1.id);
                this.attributeTableList.push(element1);
              }
            }
          }
        );
      }
    );
    if (removeAccData.length > 0) {
      let myNewArray;
      let myNewArray2;
      for (let i = 0; i < removeAccData.length; i++) {
        myNewArray = this.userParameterList.filter(function (item) { return item.id !== removeAccData[i] });

        myNewArray2 = this.attributeList.filter(function (item) { return item.id !== removeAccData[i] });

        this.userParameterList = myNewArray;
        this.attributeList = myNewArray2;
      }
      this.attributeTableList.push(...myNewArray2);
      this.attributeTableList.push(...myNewArray);

    }
    else {
      this.attributeTableList.push(...this.attributeList);
      this.attributeTableList.push(...this.userParameterList);
    }
  }
  getUserRoleList() {
    this.originalUserRoleList = [];
    this.userRoleList = [];
    const formData: FormData = new FormData();
    formData.append('userId', this.umPkId);
    this.viewUserService.getUserRoleList(formData).subscribe(
      {
        next: response => {
          response.forEach(
            res => (res.CREATED_DATE = this.datepipe.transform(res.CREATED_DATE, 'MMM d, y, h:mm:ss a'))
          );
          this.userRoleList = response;
          this.entRoleCount = response.length;
          this.originalUserRoleList = JSON.parse(JSON.stringify(this.userRoleList));
        },
        error: err => {
          this.showErrorValidations(err.error);
        }
      });
  }

  navigateToRoleDetailScreen(roleData) {
    this.selectedUserRoleData = roleData;
    this.selectedUserRoleData['PRIME_ROLE_ID'] = roleData.ID;
    this.selectedUserRoleData['ROLE_NAME'] = roleData.NAME;
    this.router.navigateByUrl('/pages/roledetails', {
      state: { role: roleData },
      skipLocationChange: false
    });
  }
  getApplicationList(): void {
    const reqBody = {};
    reqBody["tenantId"] = this.pagesService.tenantId;
    reqBody["userId"] = this.userID;
    this.pagesService.getMappedApplicationList(reqBody).subscribe((results) => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          this.applicationList.push(element.value);
        });
        this.applicationList = [...this.applicationList];
      }
    });
  }
  sendEmail() {
    const reqBody = {};
    reqBody['userId'] = this.ViewUserForm.value.viewUserID;
    reqBody['tenantId'] = this.pagesService.tenantId;
    reqBody['applicationIds'] = this.applicationList;
    if (this.rebacFlag == 'true' && this.entitlementList.length > 0 && (this.applicationList.length == 0 || !this.applicationList.includes(this.pagesService.applicationId, 0))) {
      console.log('Role not exists, adding application based on entitlmenet');
      this.applicationList.push(this.pagesService.applicationId);
    }
    const data = reqBody;
    this.pagesService.sendEmail(data).subscribe((results: any) => {
      this.messageFlag = true;
      this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'Email send successfully.' });
      setTimeout(() => {
        this.messageFlag = false;
      }, 3000);
    },
    );
  }
  entitlementFilterMethod(event: any) {
    let filtered: any[] = [];
    let items: any;
    let cc;
    let query = event;
    if (query.length > 0) {
      this.tableEntitlementList.forEach(element => {
        items = element;
        if (items.COMPONENT_ID?.toLowerCase().includes(query.toLowerCase()) || items.ENTITLEMENT_ID?.version.toLowerCase().includes(query.toLowerCase())
          || items.COMPONENT_NAME?.toLowerCase().includes(query.toLowerCase())) {
          filtered.push(items);
        }
      });
      this.entitlementList = filtered;
    }
    else {
      this.entitlementList = this.tableEntitlementList;
    }
  }
  searchEntitlement() {
    console.log("i am here")
    this.componentIdsSearch = [];
    let searchArray = [];
    searchArray = this.search.split(',');


    for (let i = 0; i < searchArray.length; i++) {
      let compIds = this.componentDropdownList
        .filter(comp => comp.COMPONENT_NAME.toLowerCase().includes(searchArray[i].toLowerCase()))
        .map(cid => cid.PRIME_COMPONENT_ID);
      if (compIds.length > 0) {
        this.componentIdsSearch.push(...compIds);
      }
    }
    this.getUserEntitlementList();
  }

  AppFilterMethod(event: any) {
    console.log(event);
    let filtered: any[] = [];
    let filtered1: any[] = [];
    let items: any;
    let cc;
    let query = event;
    //this.entitlementtable=this.entitlementList;
    if (query.length > 0) {
      this.entitlementtable.forEach(element => {
        items = element;
        console.log(items);
        console.log(query);
        console.log(items.IS_ADMS_ENTITLEMENT == 1 && query == 'adms')
        if (items.COMPONENT_NAME?.toLowerCase().includes(query.toLowerCase()) || items.ENTITLEMENT_NAME?.toLowerCase().includes(query.toLowerCase()) || items.OVERALL_STATUS?.toLowerCase().includes(query.toLowerCase())
          || items.OWNER_ENTITLEMENT_NAME?.toLowerCase().includes(query.toLowerCase()) || (items.IS_ADMS_ENTITLEMENT == 1 && query.toLowerCase() == 'adms')) {
          console.log("i am in items")
          filtered.push(items);
        }
      });
      this.entitlementList = filtered;


    }
    else {
      console.log("else");
      this.entitlementList = this.entitlementtable;
    }
  }
}
