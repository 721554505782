// These constants are injected via webpack environment variables.
// You can add more variables in webpack.common.js or in profile specific webpack.<dev|prod>.js files.
// If you change the values in the webpack config files, you need to re run webpack to update the application



import { environment } from 'src/environments/environment';

export const VERSION = "0.0.1-SNAPSHOT";
export const DEBUG_INFO_ENABLED = Boolean("development");
export const SERVER_API_URL = "https://customerconnect-dev.crisil.com/nexera-be";
export const SERVER_API_INTEGRATION_URL = "https://customerconnect-dev.crisil.com/integration-be/";
export const BUILD_TIMESTAMP = "";
export let KEY_CLOAK_URL = environment.KEY_CLOAK_URL;
export let KEY_CLOAK_CLIENT_ID = environment.KEY_CLOAK_CLIENT_ID;
export let profile = environment.profile;
export let IMAGE_PATH = environment.IMAGE_PATH;
export let REDIRECT_URI = 'http://localhost:4201/';
export let CIAM_UI_SERVICE_API_URL = environment.CIAM_UI_SERVICE_API_URL;
export let Fulkrum_Workspace_URL = environment.Fulkrum_Workspace_URL;
export let CIAM_ROLE_MIGRATION_FLAG = environment.CIAM_ROLE_MIGRATION_FLAG;
export let CIAM_UM_SERVER_API_URL = environment.CIAM_UM_SERVER_API_URL;
export let REBAC_UM_ENTITLEMENT_FLAG = environment.REBAC_UM_ENTITLEMENT_FLAG;
export let MULTI_TENANCY_ENABLE = environment.MULTI_TENANCY_ENABLE;
export let CIAM_UI_TENANT_ID = environment.CIAM_UI_TENANT_ID;
export let IS_SAAS_ENABLED = environment.IS_SAAS_ENABLED;
if (environment.MULTI_TENANCY_ENABLE.toString() == "true") {
    var realm = location?.hostname.slice(0, location?.hostname.indexOf('.')); //NOSONAR
}
else {
    var realm = environment.KEY_CLOAK_REALM_NAME; //NOSONAR
}
export let KEY_CLOAK_REALM_NAME = realm;

//export let LOGGING_SERVICE_URL = environment.LOGGING_SERVICE_URL;
export const EVENT_TYPE_LIST: string = "EVENT_TYPE_LIST";
export const ITEM_TYPE_LIST: string = "ITEM_TYPE_LIST";
export const CONTEXT_LIST: string = "CONTEXT_LIST";
export const PARENT_ID: string = "PARENT_ID";
export const USER_MANAGEMENT_COMPONENT_ID = 1;
export const USER_MANAGEMENT_SERVICE_URL = environment.CIAM_UM_SERVER_API_URL;
export const INTERNAL_SERVER_ERROR_MSG = "Internal server error. Please coordinate with IT support.";

