import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { PagesService } from '../pages.service';
@Component({
  selector: 'jhi-CreateAttributeType',
  templateUrl: './CreateAttributeType.component.html',
  styleUrls: ['./CreateAttributeType.component.scss'],
  providers: [DialogService],
})
export class CreateAttributeTypeComponent implements OnInit {
  CreateAttributeTypeForm = this.fb.group({
    textField2: [null, [Validators.required]],
    textField1: [null, [Validators.required]],
    textarea: [null, []],
  });
  pageloadvalues: any;
  nameFlag: boolean = false;
  enabledbtn1: boolean = false;
  defaultFlag: boolean = true;
  nameFlag1: boolean = false;
  appId: any;

  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public pagesService: PagesService) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.CreateAttributeTypeForm.patchValue(JSON.parse(data));
    }

  }

  ngOnInit(): void {
    this.appId = this.config?.data?.id;
  }

  onClose($event: UntypedFormGroup): void {
    this.ref.close();
  }

  colorChangeCode() {
    document.getElementById('textField_id2')!.style.borderColor = 'grey';
  }

  colorChangeName() {
    document.getElementById('textField_id1')!.style.borderColor = 'grey';
  }

  CreateAttributeType(): void {
    const reqBody = {};
    reqBody['code'] = this.CreateAttributeTypeForm.value.textField2;
    reqBody['attributeTypeName'] = this.CreateAttributeTypeForm.value.textField1;
    reqBody['description'] = this.CreateAttributeTypeForm.value.textarea;
    const data = { applicationId: this.appId };
    const formValues = reqBody;
    this.pagesService.onSaveCreateAttributeType(formValues, data).subscribe(
      {
        next: results => {
          this.ref.close("Attribute Type Created Successfully");
        },
        error: error => {
          if (error.error.error?.reason == "Application Attribute Type Name already exist") {
            this.ref.close("Attribute type name already exist");
          }
          if (error.error.error?.reason == "Application Attribute code already exist") {
            this.ref.close("Attribute code name already exist");
          }
          else {
            this.ref.close("Attribute type Creation Failed");
          }
        }
      });
  }

  AttributeTypeName($event: UntypedFormGroup) {
    this.onCheckAttributeTypeCode($event);
    this.onCheckAttributeTypeName($event);
  }

  //Check availability of attribute type name
  onCheckAttributeTypeName($event: UntypedFormGroup): void {
    const reqBody = {};
    reqBody['applicationId'] = this.appId;
    reqBody['attributeTypeName'] = this.CreateAttributeTypeForm.value.textField1;
    const formValues = reqBody;
    this.pagesService.onCheckAttributetypename(formValues).subscribe(
      results => {
        if (results?.error?.code == 400 || results?.success?.code == 400) {
          this.nameFlag1 = true;
          document.getElementById('textField_id1')!.style.borderColor = 'red';
        } else if (results.success.code == 200) {
          this.nameFlag1 = false;
          document.getElementById('textField_id1')!.style.borderColor = 'grey';
          this.enabledbtn1 = true;
        }
        if (!this.nameFlag && !this.nameFlag1) {
          this.CreateAttributeType();
        }
      },
    );
  }


  onCheckAttributeTypeCode($event: UntypedFormGroup): void {
    const reqBody = {};
    reqBody['applicationId'] = this.appId;
    reqBody['code'] = this.CreateAttributeTypeForm.value.textField2;
    const formValues = reqBody;
    this.pagesService.onCheckAttributetypecode(formValues).subscribe(
      results => {
        if (results?.error?.code == 400 || results?.success?.code == 400) {
          this.nameFlag = true;
          document.getElementById('textField_id2')!.style.borderColor = 'red';
        } else if (results.success.code == 200) {
          this.nameFlag = false;
          document.getElementById('textField_id2')!.style.borderColor = 'grey';
        }
      },
    );
  }


}