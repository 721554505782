<div class="layout-content custom-container">
    <div class="p-d-flex p-jc-end">
        <p-messages key="myKey1" class="custom-alert-box custom-msg" *ngIf="this.messageFlag"></p-messages>
    </div>
    <form [formGroup]="BulkActionCheckStatusForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left p-pl-0">
                    <div class=" p-fluid   p-field   p-mb-0 ">

                        <div class="ui-fluid p-formgrid">
                            <div class=" p-grid p-mt-0" *ngIf="errorFlag==false">
                                <div class="p-col-fixed p-p-0 p-col-4">
                                    <span class=" p-input-icon-left">
                                        <input type="text" (keydown.enter)="$event.preventDefault()" id="textField_id"
                                            name="textField_name" formControlName="textField" pInputText
                                            placeholder="Search"
                                            class="p-inputtext inputtextHeight custom-listings custom-inputtextHeight"
                                            (input)="globalFilterMethod($event)" />
                                        <i aria-hidden="true" class="pi pi-search"></i>
                                    </span>
                                </div>
                                <div class="p-col-12 p-sm-12 p-md-12 p-lg-2 p-xl-2 p-offset-undefined left">
                                </div>
                                <div class="p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6 p-offset-undefined p-right">
                                    <p-chip class="custom-status-chip" *ngIf="showChip" [removable]="true"
                                        (onRemove)="removeFilter();callUserList();">{{activeStatus}}
                                    </p-chip>
                                    <div class="btnwrap customn-filter-text" style="vertical-align: bottom;">
                                        <button pButton type="button" pButton
                                            class="p-button-secondary p-button-brand2 filterItemBtn custom-listing"
                                            (click)="menu.toggle($event)" appendTo="body">
                                            <i aria-hidden="true" class="e-icons status-icon"></i>
                                        </button>
                                        <p-tieredMenu #menu [model]="filterItems" [popup]="true"
                                            appendTo="body"></p-tieredMenu>
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left p-p-0">
                                <div class="  p-field    ">
                                    <div class="mobilefriendlytable" responsiveLayout="stack"
                                        [ngClass]="{'content-section implementation': true}">
                                        <p-table #tableid [value]="tableList" name="table" [columns]="tablecols"
                                            styleClass="p-datatable-customers custom-p-datatable-customers" [rows]="10"
                                            [rowsPerPageOptions]="[10,25,50]" selectionMode="Multiple" sortMode="single"
                                            [(selection)]="selectedRecords" (onFilter)="columnFilter($event)"
                                            [scrollable]="true" (sortFunction)="sortData($event)" [customSort]="true"
                                            [globalFilterFields]="['requestId','actionType','actionStatus','uploadedFileName','createdBy','initiatedOn','completedOn']">
                                            <ng-template pTemplate="header">
                                                <tr>
                                                    <th pSortableColumn="requestId" scope="col" style="max-width: 14%;">
                                                        <div class="p-d-flex p-jc-between p-ai-center">
                                                            Request ID
                                                            <i class="p-sortable-column-icon icon-sort"
                                                                ariaLabel="Activate to sort"
                                                                ariaLabelDesc="Activate to sort in descending order"
                                                                ariaLabelAsc="Activate to sort in ascending order"
                                                                field="requestId"></i>
                                                            <p-columnFilter type="numeric" field="requestId"
                                                                display="menu" class="p-ml-auto"></p-columnFilter>
                                                        </div>
                                                    </th>
                                                    <th pSortableColumn="actionType" scope="col"
                                                        style="max-width: 16%;">
                                                        <div class="p-d-flex p-jc-between p-ai-center">
                                                            Action Type
                                                            <i class="p-sortable-column-icon icon-sort"
                                                                ariaLabel="Activate to sort"
                                                                ariaLabelDesc="Activate to sort in descending order"
                                                                ariaLabelAsc="Activate to sort in ascending order"
                                                                field="actionType"></i>
                                                            <p-columnFilter type="text" field="actionType"
                                                                display="menu" class="p-ml-auto"></p-columnFilter>
                                                        </div>
                                                    </th>
                                                    <th pSortableColumn="actionStatus" scope="col"
                                                        style="max-width: 14%;">
                                                        <div class="p-d-flex p-jc-between p-ai-center">
                                                            Action Status
                                                            <i class="p-sortable-column-icon icon-sort"
                                                                ariaLabel="Activate to sort"
                                                                ariaLabelDesc="Activate to sort in descending order"
                                                                ariaLabelAsc="Activate to sort in ascending order"
                                                                field="actionStatus"></i>
                                                            <p-columnFilter field="actionStatus" display="menu"
                                                                class="p-ml-auto"></p-columnFilter>
                                                        </div>
                                                    </th>
                                                    <th pSortableColumn="uploadedFileName" scope="col"
                                                        style="max-width: 16%;">
                                                        <div class="p-d-flex p-jc-between p-ai-center">
                                                            Uploaded File Name
                                                            <i class="p-sortable-column-icon icon-sort"
                                                                ariaLabel="Activate to sort"
                                                                ariaLabelDesc="Activate to sort in descending order"
                                                                ariaLabelAsc="Activate to sort in ascending order"
                                                                field="uploadedFileName"></i>
                                                            <p-columnFilter type="text" field="uploadedFileName"
                                                                display="menu" class="p-ml-auto"></p-columnFilter>
                                                        </div>
                                                    </th>
                                                    <th pSortableColumn="createdBy" scope="col" style="max-width: 16%;">
                                                        <div class="p-d-flex p-jc-between p-ai-center">
                                                            Created By
                                                            <i class="p-sortable-column-icon icon-sort"
                                                                ariaLabel="Activate to sort"
                                                                ariaLabelDesc="Activate to sort in descending order"
                                                                ariaLabelAsc="Activate to sort in ascending order"
                                                                field="createdBy"></i>
                                                            <p-columnFilter type="text" field="createdBy" display="menu"
                                                                class="p-ml-auto"></p-columnFilter>
                                                        </div>
                                                    </th>
                                                    <th pSortableColumn="initiatedOnDate" scope="col"
                                                        style="max-width: 15%;">
                                                        <div class="p-d-flex p-jc-between p-ai-center">
                                                            Initiated On
                                                            <i class="p-sortable-column-icon icon-sort"
                                                                ariaLabel="Activate to sort"
                                                                ariaLabelDesc="Activate to sort in descending order"
                                                                ariaLabelAsc="Activate to sort in ascending order"
                                                                field="initiatedOnDate"></i>

                                                            <p-columnFilter field="initiatedOnDate" type="date"
                                                                display="menu" class="p-ml-auto"
                                                                [matchModeOptions]="matchModeOptions">
                                                            </p-columnFilter>
                                                        </div>
                                                    </th>
                                                    <th pSortableColumn="completedOnDate" scope="col">
                                                        <div class="p-d-flex p-jc-between p-ai-center">
                                                            Completed On
                                                            <i class="p-sortable-column-icon icon-sort"
                                                                ariaLabel="Activate to sort"
                                                                ariaLabelDesc="Activate to sort in descending order"
                                                                ariaLabelAsc="Activate to sort in ascending order"
                                                                field="completedOnDate"></i>
                                                            <p-columnFilter type="date" field="completedOnDate"
                                                                display="menu" class="p-ml-auto"
                                                                [matchModeOptions]="matchModeOptions"></p-columnFilter>
                                                        </div>
                                                    </th>
                                                    <th id="actionButton" style="width:5%;">
                                                        <div>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-rowData let-index="rowIndex">
                                                <tr [pSelectableRow]="rowData">
                                                    <td style="max-width: 14%;" class="middleNameRow">
                                                        <span
                                                            *ngIf="rowData.requestId!=null">{{rowData.requestId}}</span>
                                                        <span
                                                            *ngIf="rowData.requestId==null || rowData.requestId=='' || rowData.requestId==' '">-</span>
                                                    </td>
                                                    <td class="middleNameRow" style="max-width: 16%;">
                                                        <span
                                                            *ngIf="rowData.actionType!=null">{{rowData.actionType}}</span>
                                                        <span
                                                            *ngIf="rowData.actionType==null || rowData.actionType=='' || rowData.actionType==' '">-</span>
                                                    </td>
                                                    <td style="width: 14%;">
                                                        <div class="btnwrap" *ngIf="rowData.actionStatus!=null">
                                                            <button pButton type="button"
                                                                label="{{rowData.actionStatus}}"
                                                                style="background: transparent;"
                                                                [style.color]="rowData.actionStatus === 'Success' ? '#12813F' : rowData.actionStatus === 'In progress' ? '#396FC5' : rowData.actionStatus === 'Pending for Approval' ? '#CA8208': rowData.actionStatus === 'Failure' ? '#FF2953' : rowData.actionStatus === 'Rejected' ? '#FF2953' : 'black'"
                                                                [style.border]="rowData.actionStatus === 'Success' ? '1px solid #CFE6D8' : rowData.actionStatus === 'In progress' ? '1px solid #D8E6FD' : rowData.actionStatus === 'Pending for Approval' ? '1px solid #FDECCE': rowData.actionStatus === 'Failure' ? '1px solid #FF2953' : rowData.actionStatus === 'Rejected' ? '1px solid #FF2953' : '1px solid black'"
                                                                class=" p-button-outlined  statusRow">
                                                            </button>
                                                        </div>
                                                        <span
                                                            *ngIf="rowData.actionStatus==null || rowData.actionStatus=='' || rowData.actionStatus==' '">-</span>
                                                    </td>
                                                    <td class="emailRow" style="max-width: 16%;">
                                                        {{rowData.uploadedFileName}}
                                                    </td>
                                                    <td style="max-width: 16%;">
                                                        <span pTooltip="{{rowData.createdBy}}" tooltipPosition="bottom"
                                                            *ngIf="rowData.createdBy?.length>=15">{{rowData.createdBy?.substring(0,10)
                                                            +
                                                            "..." }}</span>
                                                        <span
                                                            *ngIf="rowData.createdBy?.length<15">{{rowData.createdBy}}</span>
                                                    </td>
                                                    <td style="max-width: 15%;">
                                                        <span
                                                            *ngIf="rowData.initiatedOn==null || rowData.initiatedOn=='' || rowData.initiatedOn==' '">-</span>
                                                        <span
                                                            pTooltip="{{rowData.initiatedOn | dateFormatter | date: 'MMM
                                                                                                                                d, y, h:mm:ss a' }}"
                                                            tooltipPosition="bottom"
                                                            *ngIf="rowData.initiatedOn?.length>=12">{{rowData.initiatedOn?.substring(0,12)
                                                            +
                                                            "..." }}</span>
                                                        <span
                                                            *ngIf="rowData.initiatedOn?.length<12">{{rowData.initiatedOn
                                                            | dateFormatter | date: 'MMM
                                                            d, y, h:mm:ss a' }}</span>
                                                    </td>
                                                    <td>
                                                        <span
                                                            *ngIf="rowData.completedOn==null || rowData.completedOn=='' || rowData.completedOn==' '">-</span>


                                                        <span
                                                            pTooltip="{{rowData.completedOn | dateFormatter | date: 'MMM
                                                                                                                                                                                        d, y, h:mm:ss a' }}"
                                                            tooltipPosition="bottom"
                                                            *ngIf="rowData.completedOn?.length>=12">{{rowData.completedOn?.substring(0,12)
                                                            +
                                                            "..." }}</span>
                                                        <span
                                                            *ngIf="rowData.completedOn?.length<12">{{rowData.completedOn
                                                            | dateFormatter | date: 'MMM
                                                            d, y, h:mm:ss a' }}</span>
                                                    </td>
                                                    <td class="p-jc-center" style="width:5%;">
                                                        <div class="button-details">
                                                            <button pButton tooltipPosition="top" pTooltip="Download"
                                                                class="p-button-strict p-button-info p-button-brand2"
                                                                (click)="downloadReport(rowData);"
                                                                *ngIf="rowData.actionStatus=='Success'||rowData.actionStatus=='Failure'||rowData.actionStatus=='Pending for Approval'">
                                                                <i class="icon-download"></i>
                                                            </button>

                                                        </div>
                                                    </td>
                                                </tr>
                                            </ng-template>

                                            <ng-template pTemplate="emptymessage">
                                                <tr>
                                                    <td colspan="8" class="p-p-0 p-text-center"> <p-card>
                                                            <div style="margin-top: 5%;height: 70px;">No
                                                                matching records found</div>
                                                        </p-card> </td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                        <div style="position: relative;">
                                            <p-paginator #pp [rows]="10" [totalRecords]="this.totalRecords"
                                                [rowsPerPageOptions]="[10,25,50]" class="p-d-inline-block"
                                                (onPageChange)="paginate($event,this.BulkActionCheckStatusForm.value.textField)"></p-paginator>
                                            <div class="p-d-inline-block" style="font-size: 13px;color: #505870;">
                                                Total Records: {{this.totalRecords}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>