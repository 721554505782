import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PagesService } from '../pages.service';
import { MenuItem, MessageService } from 'primeng/api';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { DeleteRoleComponent } from '../DeleteRole/DeleteRole.component';
import { GenerateTokenComponent } from '../GenerateToken/GenerateToken.component';
import { RegenerateTokenComponent } from '../RegenerateToken/RegenerateToken.component';

@Component({
  selector: 'jhi-APIAccessToken',
  templateUrl: './APIAccessToken.component.html',
  styleUrls: ['./APIAccessToken.component.scss'],
  providers: [MessageService, DialogService],
})
export class APIAccessTokenComponent implements OnInit {
  ref!: DynamicDialogRef;
  APIAccessTokenForm = this.fb.group({
    textField: [null, []],
  });
  first = 0;
  flag1 = false;
  rows = 10;
  messageFlag = true;
  isVisible = false;
  activeItem: any;
  GroupCount: any;
  UserCount: any;
  pageloadvalues: any;
  ccprimebreadcrumbList = [{ label: `Role Management`, routerLink: ['/pages/APIAccessToken'] }];
  homeiconccprimebreadcrumb = { icon: 'pi pi-home', routerLink: ['/'] };
  htmlSrc: any;
  searchList: any[] = [];
  tableList: any[] = [];
  errorFlag: boolean = true;
  tablecols: any[] = [
    { field: 'name', header: 'Name' },
    { field: 'description', header: 'Description' },
    { field: 'users', header: 'Users' },
    { field: 'groups', header: 'Groups' },
    { field: 'policies', header: 'Policies' },
    { field: 'status', header: 'Status' },
  ];
  table: any[] = [];
  Hoveritems!: MenuItem[];
  selectedIndex: any;
  hoverIndex: any;
  deletemsg: any;
  constructor(
    public dialogService: DialogService,
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    private messageService: MessageService
  ) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    this.deletemsg = navigation?.extras?.state?.msg;
    if (this.deletemsg) {
      this.messageFlag = true;
    }
    if (data) {
      this.APIAccessTokenForm.patchValue(JSON.parse(data));
    }
  }
  ngOnInit(): void {
    this.htmlSrc = '';
    this.Hoveritems = [
      {
        label: 'View & Edit',

        command: e => {
          this.navigateName(this.activeItem);
        },
      },

      {
        label: 'Delete',
        command: () => {
          this.DeleteToken();
        },
        disabled: true,
      },

      {
        label: 'Regenerate Token',
        command: () => {
          this.resetinviteUserccprimesplitbutton();
        },
      },
    ];
    this.gettableList();
  }


  gettableList(): void {
    this.pagesService.gettableDataRoles().subscribe(results => {
      const responseData = results.success.result.records;
      if (responseData && responseData.length > 0) {
        this.tableList = responseData;
        this.errorFlag = false;
      } else {
        this.errorFlag = true;
      }
      if (this.deletemsg) {

        this.messageService.add({ key: 'myKey1', severity: 'success', detail: this.deletemsg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
    });
  }

  navigateName(rowData: any): void {
    const navigationExtras: NavigationExtras = {
      state: {
        data: JSON.stringify(rowData),
      },
    };
    this.router.navigate(['/pages/viewrole'], navigationExtras);
  }

  onCcprimebutton1($event: UntypedFormGroup): void {
    this.router.navigate(['/pages/addAPIAccessToken']);
  }

  highlight(index: any) {
    this.selectedIndex = index;
  }

  resetinviteUserccprimesplitbutton(): void {
    this.APIAccessTokenForm.reset();
  }

  GenerateToken() {
    this.ref = this.dialogService.open(GenerateTokenComponent, {
      data: { id: this.APIAccessTokenForm.value.select },
      header: 'Generate',
      width: '50%',
      contentStyle: { 'max-height': '200px', overflow: 'visible', 'padding': '10px' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Attribute Group Created Successfully') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.gettableList();
      }
      else if (msg == 'Attribute Group Creation Failed' || msg == 'Attribute group name already exist') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
    });
  }

  RegenerateToken() {
    this.ref = this.dialogService.open(RegenerateTokenComponent, {
      data: { id: this.activeItem, dialogFlag: 'APIAccessToken' },
      header: 'Regenerate Token',
      width: '50%',
      contentStyle: { 'max-height': '200px', overflow: 'visible', 'padding': '10px' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'error') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Failed to assign users.' });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
      else if (msg) {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
    });
    this.gettableList();
  }

  DeleteToken() {
    this.ref = this.dialogService.open(DeleteRoleComponent, {
      data: { id: this.activeItem.id, name: this.activeItem.displayName, dialogFlag: 'APIAccessToken' },
      header: 'Delete Role',
      width: '50%',
      contentStyle: { 'max-height': '160px', overflow: 'visible' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'error') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Failed to assign users.' });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
      else if (msg) {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.gettableList();
      }
    });
  }

  mouseLeave() {
    this.hoverIndex = null;
    this.selectedIndex = null;
  }
}
