<div class="layout-content custom-container">
    <form [formGroup]="CreateApplicationForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 left">
            </div>
            <div class="p-col-6 p-sm-6 p-md-6 p-lg-6 p-xl-6 p-offset-undefined left">
                <div class=" p-fluid   p-field    ">
                    <label [ngClass]="{'field-required': CreateApplicationForm.get('textField1')?.errors?.required}">
                        Application Name
                        <span class='invalid-feedback-astrik'>*</span></label>
                    <input type="text" id="textField1_id" name="textField1_name" formControlName="textField1"
                        placeholder="Enter Application name" class="p-inputtext inputtextHeight"
                        pattern="^[^+=*\-)(&^%$#@!_].*$" (keyup)="this.nameFlag = false; colorChange();" />
                    <div class="p-error"
                        *ngIf="CreateApplicationForm.get('textField1')!.invalid && (CreateApplicationForm.get('textField1')!.dirty || CreateApplicationForm.get('textField1')!.touched)">
                        <div *ngIf="CreateApplicationForm.get('textField1')?.errors?.required">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium class="p-error">Application name is required.</medium>
                        </div>
                        <div *ngIf="CreateApplicationForm.get('textField1').errors.pattern">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium class="p-error">Application name should not starts with special characters.
                            </medium>
                        </div>
                        <div *ngIf="CreateApplicationForm.get('textField1').errors.maxlength">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium class="p-error">Application name should not exceed 255 characters.</medium>
                        </div>
                    </div>
                    <div class="p-error">
                        <label *ngIf="this.nameFlag" class="custom-codelabel">
                            <div>
                                <label class="alert-icon alert-circle-icon"></label>
                                <medium class="p-error">
                                    Application name already exists!
                                </medium>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
            <div class="p-col-6 p-sm-6 p-md-6 p-lg-6 p-xl-6 p-offset-undefined left">
                <div class=" p-fluid   p-field    ">
                    <label>
                        Application Version
                    </label>
                    <p-dropdown [filter]="true" filterBy="label" id="selectid" name="select"
                        placeholder="Select Application Version" [options]="selectList" [autoDisplayFirst]="false"
                        formControlName="select" filter="true" (onChange)="this.nameFlag = false; colorChange();">
                    </p-dropdown>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                <div class=" p-fluid   p-field    ">
                    <label>
                        Description
                    </label><textarea pInputTextarea id="textArea_id" name="textArea_name" formControlName="textarea"
                        rows="3" placeholder="Add description" maxlength="200" style="height:72px;"></textarea>
                </div>
            </div>
            <div class="p-col-12 p-p-0 p-mb-2">
                <div class=" p-grid">
                    <div class="p-col ">
                        <span class="p-d-flex p-mt-1">
                            <p-checkbox [(ngModel)]="checkedSaas" binary="true" inputId="binary"
                                formControlName="saasApplication" (onChange)="saasCheck()"></p-checkbox>
                            <label for="binary" class="p-ml-2 p-pt-1" style="pointer-events: none;">Saas
                                Application</label></span>
                    </div>
                    <div *ngIf="SaasEnabled" class="p-grid p-col-12 ">
                        <div class="p-grid p-col-12 p-pl-0 p-pr-0">
                            <div class="p-col p-p-0 p-mt-4 p-mr-3 p-p-0 custom_small_file">
                                <label>Logo<span class='invalid-feedback-astrik p-ml-0'>*</span></label>
                                <div class="custom_choose_file custom-tenant-file">
                                    <p-fileUpload id="newSolutionPageIconChooseFilebtn"
                                        name="newSolutionPageIconChooseFilebtn" #fileUploadPageIconChooseFilebtn
                                        chooseLabel="Choose File" customUpload="true" cancelLabel="Reset"
                                        [showUploadButton]="false" [showCancelButton]="false"
                                        (onSelect)="onSelectSolutionIcon($event)">
                                        <ng-template pTemplate="content">
                                            <ng-container
                                                *ngIf="imageURL && (productPrimaryImage == '' || productPrimaryImage == undefined);then previewText1; else previewText">
                                            </ng-container>
                                            <ng-template #previewText1>
                                                <label class="label_content">
                                                    <span
                                                        pTooltip="{{imageForToolTip.length > 30 ? imageForToolTip : ''}}"
                                                        tooltipPosition="left">
                                                        <ng-container *ngIf="imageForToolTip.length > 30; else tooltip">
                                                            {{
                                                            imageURL.split("/")[4].substring(0,
                                                            30) +
                                                            "..." }}
                                                        </ng-container>
                                                        <ng-template #tooltip>
                                                            {{ imageURL.split("/")[4] }}
                                                        </ng-template>
                                                    </span>
                                                </label>
                                            </ng-template>
                                            <ng-template #previewText>
                                                <label
                                                    *ngIf="(productPrimaryImage == '' || productPrimaryImage == undefined) && !imagevalid || imagePlacheholder"
                                                    class="label_content">
                                                    &nbsp;Add file JPEG, PNG or SVG Max:
                                                    5Mb
                                                </label>
                                            </ng-template>
                                        </ng-template>
                                        <ng-template let-file pTemplate="file">
                                            <div class="file">
                                                <ng-container
                                                    *ngIf="imageURL && (productPrimaryImage == '' || productPrimaryImage == undefined);then previewdata1; else previewdata">
                                                </ng-container>
                                                <ng-template #previewdata>
                                                    <span class="ml-2 fileName"
                                                        pTooltip="{{file.name.length > 30 ? file.name : ''}}"
                                                        tooltipPosition="left">
                                                        <ng-container *ngIf="file.name.length > 30; else tooltip">
                                                            {{ file.name.substring(0,30) +
                                                            "..." }}
                                                        </ng-container>
                                                        <ng-template #tooltip>
                                                            {{ file.name }}
                                                        </ng-template>
                                                    </span>
                                                    <span class="delete-action"
                                                        (click)="onRemoveIcon(event,fileUploadPageIconChooseFilebtn)">
                                                        <i aria-hidden="true" class="pi pi-times"></i>
                                                    </span>
                                                </ng-template>
                                                <ng-template #previewdata1></ng-template>
                                            </div>
                                        </ng-template>
                                    </p-fileUpload>
                                </div>
                                <div class="p-error" *ngIf="solImage">
                                    <medium>The following characters are not allowed: ~ # % & * &#123; &#125; \ : &lt; >
                                        ? / | ' "</medium>
                                </div>
                                <div class="p-error" *ngIf="solImageFileSize">
                                    <label class="alert-icon alert-circle-icon"></label>
                                    <medium>The maximum file size can be 1MB!</medium>
                                </div>
                                <div class="p-error" *ngIf="imagevalid">
                                    <label class="alert-icon alert-circle-icon"></label>
                                    <medium>Invalid image format!</medium>
                                </div>
                            </div>

                            <div class="p-col-fixed p-p-0" style="width: 80px;">
                                <ng-container
                                    *ngIf="imageURL && (productPrimaryImage == '' || productPrimaryImage == undefined);then previewimage1; else previewimage">
                                </ng-container>
                                <ng-template #previewimage1>
                                    <div class="custom_preview_file" style="margin-top: 0px;">

                                        <img alt="" src="{{BASE_URL+imageURL}}" width="76px" />
                                    </div>
                                </ng-template>
                                <ng-template #previewimage>
                                    <div class="custom_preview_file custom_preview_file_small_screen"
                                        style="margin-top: 6px;background-color: #EBF3FE;">
                                        <img alt="" [src]="this.productPrimaryImage" *ngIf="!solImageFileSize"
                                            width="76px" />
                                        <i aria-hidden="true"
                                            *ngIf=" productPrimaryImage == '' || productPrimaryImage == undefined || solImageFileSize"
                                            class="icon-image" style="left: 19px;"></i>
                                        <label
                                            *ngIf="productPrimaryImage == '' ||productPrimaryImage == undefined || solImageFileSize">
                                            Preview
                                        </label>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                        <div class="p-grid p-col-12 p-pl-0 p-pr-0">
                            <div class="p-col p-p-0 p-mt-4 p-mr-3 p-p-0 custom_small_file"
                                style="padding-right: 80px !important;">
                                <label>Demo/Informative Video</label>
                                <div class="custom_choose_file custom-tenant-file">
                                    <p-fileUpload id="newSolutionPageIconChooseFilebtn"
                                        name="newSolutionPageIconChooseFilebtn" #fileUploadPageIconChooseFilebtn1
                                        chooseLabel="Choose File" customUpload="true" cancelLabel="Reset"
                                        [showUploadButton]="false" [showCancelButton]="false"
                                        (onSelect)="onSelectVideo($event)">
                                        <ng-template pTemplate="content">
                                            <ng-container
                                                *ngIf="imageURL && (productPrimaryVideo == '' || productPrimaryVideo == undefined);then previewVideo1; else previewVideo">
                                            </ng-container>
                                            <ng-template #previewVideo1>
                                                <label class="label_content">
                                                    <span
                                                        pTooltip="{{imageForToolTip.length > 30 ? imageForToolTip : ''}}"
                                                        tooltipPosition="left">
                                                        <ng-container *ngIf="imageForToolTip.length > 30; else tooltip">
                                                            {{
                                                            imageURL.split("/")[4].substring(0,
                                                            30) +
                                                            "..." }}
                                                        </ng-container>
                                                        <ng-template #tooltip>
                                                            {{ imageURL.split("/")[4] }}
                                                        </ng-template>
                                                    </span>
                                                </label>
                                            </ng-template>
                                            <ng-template #previewVideo>
                                                <label
                                                    *ngIf="(productPrimaryVideo == '' || productPrimaryVideo == undefined) && !validVideo || placeHolderFlag"
                                                    class="label_content">
                                                    &nbsp;Upload only MP4 format. Max size:50MB
                                                </label>
                                            </ng-template>
                                        </ng-template>
                                        <ng-template let-file pTemplate="file">
                                            <div class="file">
                                                <ng-container
                                                    *ngIf="imageURL && (productPrimaryVideo == '' || productPrimaryVideo == undefined);then previewdata1; else previewdata">
                                                </ng-container>
                                                <ng-template #previewdata>
                                                    <span class="ml-2 fileName"
                                                        pTooltip="{{file.name.length > 30 ? file.name : ''}}"
                                                        tooltipPosition="left">
                                                        <ng-container *ngIf="file.name.length > 30; else tooltip">
                                                            {{ file.name.substring(0,30) +
                                                            "..." }}
                                                        </ng-container>
                                                        <ng-template #tooltip>
                                                            {{ file.name }}
                                                        </ng-template>
                                                    </span>
                                                    <span class="delete-action"
                                                        (click)="onRemoveVideo(event,fileUploadPageIconChooseFilebtn1)">
                                                        <i aria-hidden="true" class="pi pi-times"></i>
                                                    </span>
                                                </ng-template>
                                                <ng-template #previewdata1></ng-template>
                                            </div>
                                        </ng-template>
                                    </p-fileUpload>
                                </div>
                                <div class="p-error" *ngIf="solImage">
                                    <medium>The following characters are not allowed: ~ # % & * &#123; &#125; \ : &lt; >
                                        ? / | ' "</medium>
                                </div>
                                <div class="p-error" *ngIf="videoFileSize">
                                    <label class="alert-icon alert-circle-icon"></label>
                                    <medium>The maximum file size can be 50MB!</medium>
                                </div>
                                <div class="p-error" *ngIf="validVideo">
                                    <label class="alert-icon alert-circle-icon"></label>
                                    <medium>Invalid video format!</medium>
                                </div>
                            </div>
                        </div>

                        <div class="p-grid p-col-12 p-mt-2 p-mb-3 p-pl-0 p-pr-0 p-pt-3">
                            <span class="p-d-flex">
                                <p-checkbox [(ngModel)]="checkedStorage" binary="true" inputId="binary"
                                    formControlName="externalStorage" (onChange)="externalStorageCheck()"></p-checkbox>
                                <label for="binary" class="p-ml-2 p-pt-1" style="pointer-events: none;">External storage
                                    required</label></span>
                        </div>

                        <div class="p-fluid  p-col-6 p-pl-0" *ngIf="StorageEnabled">
                            <span class="d-block label-top"> <label
                                    [ngClass]="{'field-required': CreateApplicationForm.get('externalStorageType')?.errors?.required}">
                                    External storage type
                                    <span class='invalid-feedback-astrik'> *</span>
                                </label>
                            </span>
                            <p-dropdown id="application_id" #dd name="application_name" placeholder="Select"
                                [options]="storageList" autoZIndex="true" baseZIndex="1" [autoDisplayFirst]="false"
                                formControlName="externalStorageType" (onChange)="this.externalFlag=false"
                                [filter]="true">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="p-col-fixed p-right custom-btn-height p-col-12">
                        <div class="btnwrap p-mr-3">
                            <button pButton type="button" class="p-button-brand2 p-button"
                                (click)="onClose(CreateApplicationForm);" label="Close">
                            </button>
                        </div>
                        <div class="btnwrap">
                            <button pButton class="p-button" id="save" type="button"
                                (click)="Application(this.CreateApplicationForm.value);"
                                [disabled]="this.nameFlag || !CreateApplicationForm.valid || this.formValidFlag ||this.externalFlag"
                                label="Save">
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>