<div style="background-color:#F6F8FA;margin-bottom: 25px;">
  <div class="p-d-flex p-jc-end">
    <p-messages key="myKey1" class="custom-alert-box" *ngIf="this.messageFlag"></p-messages>
  </div>
  <div class="p-col-fixed p-mt-1 p-ml-1 p-pr-0 custom-breadcrumb">
    <p-breadcrumb [model]="ccprimebreadcrumbList" [home]="homeiconccprimebreadcrumb">
    </p-breadcrumb>
  </div>

  <div style="padding: 10px 24px;">
    <div class="p-grid">
      <span class="p-col p-p-0">
        <h5 class="custom-head1">Manage Entitlements</h5>
      </span>
    </div>
    <div class="layout-content p-p-0 ent-manage">
      <div class="p-grid">
        <div class="p-col-12 p-md-12 p-lg-12 p-p-0">
          <div class="p-col-12 p-p-0">
            <div class="custom-alert" role="alert" *ngIf="isSuccess">
              <span class="pi pi-check"></span> <span class="alert-txt">{{successMessage}}</span>
              <button type="button" class="alert-close" data-dismiss="alert" aria-label="Close"
                (click)="isSuccess=false">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
          <div class="p-col-12 p-md-12 p-lg-12 p-p-0">
            <div class="custom-alert" role="alert" *ngIf="isFailure">
              <span class="pi pi-exclamation-triangle"></span><span class="alert-txt">{{failureMessage}}</span>
              <button type="button" class="alert-close" data-dismiss="alert" aria-label="Close"
                (click)="isFailure=false">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        </div>
      </div>



      <div class="p-grid header-tabview p-pl-4 p-pr-4">
        <div class="p-col-2 p-col p-p-0 ent-count">
          <div class="total_ent-count">{{totalEntitlementCount}}</div>
          <div class="total_ent">Total Entitlements</div>
        </div>
        <div class="p-col-2 p-col p-p-0 ent-inactive">
          <div class="total_ent-count">{{totalInactiveEntitlementCount}}</div>
          <div class="total_ent">Inactive Entitlements</div>
        </div>
        <div class="p-p-0 p-col p-text-right p-mt-3 p-mr-3">
          <button pButton label="Entitlement" (click)="op.toggle($event)" iconPos="right" icon="pi pi-angle-down"
            class="p-button-secondary action-btn"></button>
          <p-overlayPanel #op [dismissable]="true" [showCloseIcon]="false"
            [style]="{width: '133px',height:'64px',overflow: 'hidden' }">
            <ng-template pTemplate>
              <div class="p-fileupload-tertiary p-fileupload-sm custom-hover"
                style="width: 133px;margin-top: -3%; margin-left: -16%;">
                <p-fileUpload styleClass="custom-color" chooseIcon="pi pi-upload" #uploadUserEntForm mode="basic"
                  name="demoEnt[]" accept=".csv,.xlsx" maxFileSize="10000000" [auto]="true" chooseLabel="Upload"
                  customUpload="true" title="Upload User Entitlements"
                  (uploadHandler)="uploadEntitlementFile($event, uploadUserEntForm)">
                </p-fileUpload>
              </div>
              <div class="custom-hover" style="width:133px; margin-left: -16%;">
                <button pButton type="button" label="Download" icon="pi pi-download"
                  class="p-button-info p-mr-2 custom-color-download p-button-sm"
                  (click)="downloadUserEntitlements()"></button>
              </div>
            </ng-template>
          </p-overlayPanel>
        </div>
        <div class=" p-p-0 p-mt-3 p-text-right">
          <button pButton label="Bulk Register Entitlement" (click)="op2.toggle($event)" iconPos="right"
            icon="pi pi-angle-down" class="p-button-secondary action-btn"></button>
          <p-overlayPanel #op2 [dismissable]="true" [showCloseIcon]="false"
            [style]="{width: '226px',height:'64px',overflow: 'hidden'}">
            <ng-template pTemplate>
              <div class="p-fileupload-tertiary p-fileupload-sm custom-hover"
                style="width:226px;margin-top: -3%; margin-left: -8%;">
                <p-fileUpload styleClass="custom-color" chooseIcon="pi pi-upload" #uploadEntForm mode="basic"
                  name="demo[]" accept=".csv,.xlsx" maxFileSize="10000000" [auto]="true" chooseLabel="Upload"
                  customUpload="true" title="Upload Entitlement"
                  (uploadHandler)="uploadRegisterEnt($event, uploadEntForm)">
                </p-fileUpload>
              </div>
              <div class="custom-hover" style="width:226px;margin-left: -8%;">
                <button pButton type="button" label="Download" icon="pi pi-download"
                  class="p-button-info p-mr-2 custom-color-download p-button-sm" styleClass="custom-color"
                  (click)="downloadRegisteredEnt()"></button>

              </div>
            </ng-template>
          </p-overlayPanel>
        </div>
        <div class="p-p-0 p-text-right new-ent-btn p-ml-3">
          <button pButton type="button" label="Register Entitlement" [disabled]="disableButtons"
            (click)="openEntitlementSidebar()" icon="icon-user-plus"></button>
        </div>
      </div>


      <div class="p-grid ent-search p-pb-1 p-pt-3 p-align-end">
        <div class="p-col p-p-0">
          <span class="p-input-icon-left p-mb-0">
            <i class="icon-search"></i>
            <input pInputText id="ent-srch" type="text" placeholder="Search" (keyup)="searchEntitlement()"
              [(ngModel)]="search" class="search-text" />
          </span>
        </div>
        <div class="p-col-auto p-pr-0">
          <div class="p-col-auto ui-label-calender pr-0">
            <div class="p-m-0 p-ml-auto p-0 pr-0 w-auto document-library-wrapper creation-content">
              <div class=" p-m-0 p-ml-auto p-0 pr-0 position-relative overView-calender-filter">
                <div class="p-col-auto">
                  <label style="font-size: 13px;">Creation Period</label> <br>
                  <p-calendar #dateFilter [(ngModel)]="creationPeriodRange" [readonlyInput]="true" selectionMode="range"
                    [showIcon]="true" dateFormat="dd M y" placeholder="All" [showButtonBar]="true"
                    [maxDate]="maxDateValue" class="custom-calendar" [disabledDates]="invalidDates"
                    (onSelect)="filterOnDateRange();addSelectedClass(); removeActiveClass()"
                    todayButtonStyleClass="calendar-today-button" (onClearClick)="clearSelectedList()"
                    (onShow)="addSelectedClass();setActiveFilterClass()">
                    <p-footer>
                      <div class="quick-filters-container">
                        <div (click)="onQuickFilterClick(7,0,0,0)">PAST WEEK</div>
                        <div (click)="onQuickFilterClick(0,1,0,1)">PAST MONTH</div>
                        <div (click)="onQuickFilterClick(0,3,0,2)">PAST 3 MONTHS</div>
                        <div (click)="onQuickFilterClick(0,6,0,3)">PAST 6 MONTHS</div>
                        <div (click)="onQuickFilterClick(0,0,1,4)">PAST YEAR</div>
                      </div>
                    </p-footer>
                  </p-calendar>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="p-col-md-1 custom-filter p-ml-3">
          <button pButton type="button" icon="icon-filter" class="p-button-brand2"
            (click)="entilementfliter.toggle($event);openFilterSideBar();"></button>
        </div>

      </div>



      <div class="p-grid">
        <div class="p-col p-pl-0 p-ai-center"
          *ngIf="appliedUserStatus.length > 0 || appliedEntilement.length > 0 || appliedOwnerEntitlement.length > 0 || appliedNotification.length > 0 || appliedDependent.length > 0 || appliedComponent.length > 0">
          <label>Filters Applied: </label>

          <p-chip *ngIf="appliedSearch" class="p-mr-2" styleClass="p-chip-lg p-chip-blue medium-text"
            label="{{appliedSearch}}">
          </p-chip>

          <p-chip *ngFor="let status of appliedUserStatus"
            (click)="setActiveTab('ENTITLEMENT_STATUS');entilementfliter.toggle($event)" class="p-mr-2"
            styleClass="p-chip-lg p-chip-blue medium-text" label="{{status.label}}">
          </p-chip>

          <p-chip *ngFor="let type of appliedEntilement"
            (click)="setActiveTab('ENTITLEMENT_NAME');entilementfliter.toggle($event)" class="p-mr-2"
            styleClass="p-chip-lg p-chip-blue medium-text" label="{{type.label}}">
          </p-chip>

          <p-chip *ngFor="let owner of appliedOwnerEntitlement"
            (click)="setActiveTab('OWNER_ENTITLEMENT');entilementfliter.toggle($event)" class="p-mr-2"
            styleClass="p-chip-lg p-chip-blue medium-text" label="{{owner.label}}">
          </p-chip>

          <p-chip *ngFor="let type of appliedNotification"
            (click)="setActiveTab('ENTITLEMENT_NOTIFICATIION');entilementfliter.toggle($event)" class="p-mr-2"
            styleClass="p-chip-lg p-chip-blue medium-text" label="{{type.label}}">
          </p-chip>

          <p-chip *ngFor="let status of appliedDependent"
            (click)="setActiveTab('ENTITLEMENT_DEPENDENT');entilementfliter.toggle($event)" class="p-mr-2"
            styleClass="p-chip-lg p-chip-blue medium-text" label="{{status.label}}">
          </p-chip>

          <p-chip class="p-mr-2" *ngFor="let type of appliedComponent"
            (click)="setActiveTab('ENTITLEMENT_COMPONENT');entilementfliter.toggle($event)"
            styleClass="p-chip-lg p-chip-blue medium-text" label="{{type.label}}">
          </p-chip>

          <span
            *ngIf="appliedUserStatus.length > 0 || appliedEntilement.length > 0 || appliedOwnerEntitlement.length > 0 || appliedNotification.length > 0 || appliedDependent.length > 0 || appliedComponent.length > 0"><label
              class="p-mb-0" (click)="clearAllFilters()"><a>Clear all</a></label>
          </span>
        </div>
        <div class="p-col-md-auto" *ngIf="this.selectedRecords.length > 0">
        </div>
      </div>



      <div class="p-grid ent-manage-table">
        <div class="p-col-12 p-pl-0 p-pr-0 p-mb-5">
          <div class="mobilefriendlytable" responsiveLayout="stack"
            [ngClass]="{'content-section implementation': true}">
            <p-table #dt [columns]="columns" [autoLayout]="true" [value]="entitlements"
              styleClass="p-datatable-gridlines" [paginator]="true" [rows]="10" [responsive]="true"
              [showCurrentPageReport]="true" currentPageReportTemplate="{first} - {last} of {totalRecords} Records"
              [rowsPerPageOptions]="rowPerPageOptions" [totalRecords]="totalRecords" [lazy]="true" [rowHover]="true"
              (onPage)="loadNextPaginatorGrid($event)" (onLazyLoad)="getEntitlementListWithPagination($event)"
              [(selection)]="selectedRecords" (onRowSelect)="onRowSelectionChange($event)"
              (onRowUnselect)="onRowSelectionChange($event)" (onHeaderCheckboxToggle)="onRowSelectionChange($event)">

              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">
                    <p-tableHeaderCheckbox class="table-header-checkbox p-pl-0"></p-tableHeaderCheckbox>
                  </th>
                  <th scope="col" style="min-width: 80px;">Ent ID.</th>
                  <th scope="col" *ngFor="let col of columns">
                    {{col.header}}
                  </th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-entitlement let-index="rowIndex">
                <tr [ngClass]="{
            'bg-lemon': entitlement.IS_APPROVAL_PENDING && entitlement.IS_APPROVAL_PENDING == 1,
            'bg-grey': entitlement.STATUS == 'Inactive'
          }">
                  <td>
                    <span class="text-data">
                      <span class="info-ent" *ngIf="entitlement.IS_APPROVAL_PENDING==1"
                        pTooltip="An ownership approval is pending on this entitlement" tooltipPosition="right">
                        <label class="l-icons codiconInfo-icon"></label>

                      </span>
                      <span class="info-ent" *ngIf="entitlement.STATUS == InactiveStatus"
                        pTooltip="Entitlement was deactivated by {{entitlement.LATEST_ACTION_BY}} on {{entitlement.LATEST_ACTION_DATE | dateFormatter }}"
                        tooltipPosition="top">
                        <label class="l-icons codiconInfo-icon"></label>
                      </span>
                    </span>
                  </td>
                  <td>
                    <p-tableCheckbox *ngIf="entitlement.IS_ADMS_ENTITLEMENT != 1" class="table-checkbox p-pl-0"
                      [value]="entitlement"></p-tableCheckbox>
                  </td>
                  <td style="word-break: break-word;">
                    <span><a routerLink="/pages/entitlementsdetails" (click)="navigateToDetailScreen(entitlement)"
                        style="text-decoration: none !important;">{{entitlement.COMPONENT_ID + '-' +
                        entitlement.ENTITLEMENT_ID}}
                      </a></span>
                  </td>
                  <td class="custom-overflow">{{entitlement.COMPONENT_NAME}}</td>
                  <td class="custom-overflow">
                    <span class="clipTxt" pTooltip="{{entitlement.ENTITLEMENT_NAME}}" tooltipPosition="bottom"
                      style="vertical-align:bottom;">
                      {{entitlement.ENTITLEMENT_NAME}}

                    </span>
                    <span *ngIf="entitlement.IS_ADMS_ENTITLEMENT == 1"><b><i>&nbsp; ADMS</i></b> </span>

                  </td>

                  <td class="custom-overflow">
                    <div>
                      <span class="clipTxt" pTooltip="{{entitlement.OWNER_ENTITLEMENT_NAME}}" tooltipPosition="bottom"
                        style="vertical-align:bottom;">
                        {{entitlement.OWNER_ENTITLEMENT_NAME}}
                      </span>

                      <span *ngIf="entitlement.IS_ADMS_OWNERSHIP_ENT == 1"><b><i>&nbsp; ADMS</i></b> </span>
                    </div>
                  </td>


                  <td>
                    <div class="over-content-notify-entitlement"
                      *ngIf="entitlement?.DESCRIPTION.length > 15;else other_content"
                      pTooltip={{entitlement.DESCRIPTION}} tooltipPosition="bottom">
                      {{entitlement.DESCRIPTION}}
                    </div>
                    <ng-template #other_content>{{entitlement.DESCRIPTION}}</ng-template>
                  </td>
                  <td style="width:8%">
                    <span class="status-dot p-mt-0"
                      [ngClass]="{'activeTest': (entitlement.STATUS ==='Active'), 'inactiveTest':(entitlement.STATUS === 'Inactive'), 'lockedTest':(entitlement.STATUS === 'Locked')}">
                    </span>
                    <span class="userTitle entitle-status">{{entitlement.STATUS}}</span>
                  </td>
                  <td class="custom-overflow" style="width:20%">
                    <span class="createdDate custom-date"> {{entitlement.CREATED_DATE | date: 'MMM d, y,
                      h:mm:ss
                      a'}} </span>
                    <div class="desktop">
                      <div class="button-details">
                        <span>
                          <button pButton type="button" class="background-transparent ml-0" pTooltip="Activate"
                            tooltipPosition="bottom"
                            (click)="openActiveStatusUpdateModal(entitlement.ID, 'Active' ,entitlement.ENTITLEMENT_NAME+' - '+entitlement.COMPONENT_ID+'-'+entitlement.ENTITLEMENT_ID);"
                            *ngIf="entitlement.STATUS ==InactiveStatus && selectedRecords.length == 0"
                            icon="activatebtnHover-icons activateEnt-icon">
                          </button>
                          <button pButton type="button" class="background-transparent ml-0" pTooltip="Deactivate"
                            tooltipPosition="bottom"
                            (click)="checkEntitlementOwnershipsInfo(entitlement.ID,entitlement.COMPONENT_ID, entitlement.ENTITLEMENT_ID, entitlement.ENTITLEMENT_NAME,'Deactive',entitlement.STATUS)"
                            *ngIf="entitlement.STATUS =='Active' && selectedRecords.length == 0 && entitlement.IS_ADMS_ENTITLEMENT != 1"
                            icon="btnHover-icons deactivateEnt-icon">
                          </button>

                          <button pButton type="button" pTooltip="log" tooltipPosition="bottom"
                            class="background-transparent" style="width: 20px;height: 20px;padding: 0px;"
                            (click)="loadActivityLog(loadActivityLog(entitlement.ID, entitlement.ENTITLEMENT_NAME, entitlement.STATUS, entitlement.COMPONENT_ID + '-' + entitlement.ENTITLEMENT_ID+' - '+entitlement.ENTITLEMENT_NAME,entitlement))"
                            icon="btnHover-icons file-icon">

                          </button>
                          <button pButton class="background-transparent ml-0" pTooltip="Edit Approvers"
                            tooltipPosition="bottom" style="width: 28px;height: 20px;padding: 0px;"
                            *ngIf="entitlement.IS_OWNER == 1 && (entitlement.IS_APPROVAL_TREE == 1 || entitlement.IS_APPROVAL_REQUIRED == 0)"
                            (click)="openEditApproversDialog(entitlement)" icon="btnHover-icons editApproval-icon">

                          </button>
                          <button pButton class="background-transparent ml-0" pTooltip="Edit Entitlement"
                            tooltipPosition="bottom" (click)="openEditEntitlementDialog(entitlement)"
                            style="width: 20px;height: 20px;padding: 0px;"
                            *ngIf="entitlement.IS_OWNER == 1 && (entitlement.IS_APPROVAL_TREE == 1 || entitlement.IS_APPROVAL_REQUIRED == 0) "
                            icon="btnHover-icons editEntitlement-icon">
                          </button>
                          <button pButton class="background-transparent ml-0 p-mr-0" pTooltip="View Approvers" label=' '
                            tooltipPosition="bottom" style="width: 28px;height: 20px;padding: 0px;"
                            *ngIf="entitlement.IS_OWNER == 0 && (entitlement.IS_APPROVAL_TREE == 1 ||  entitlement.IS_APPROVAL_REQUIRED == 0)"
                            (click)="onViewApprovalClicked(entitlement.ID, entitlement.ENTITLEMENT_ID, entitlement.ENTITLEMENT_NAME,entitlement.IS_ADMS_ENTITLEMENT, entitlement.COMPONENT_ID, entitlement.STATUS,entitlement.COMPONENT_NAME)"
                            icon="btnHover-icons view-icon">
                          </button>
                          <button pButton tooltipPosition="bottom" pTooltip="Map/ Unmap Endpoints"
                            *ngIf="entitlement.IS_OWNER == 1 && (entitlement.IS_APPROVAL_TREE == 1 || entitlement.IS_APPROVAL_REQUIRED == 0) "
                            style="width: 24px;height: 24px;padding: 0px;" icon="g-icons mapunmap-icon"
                            class="background-transparent p-ml-0" (click)="activeItem1=entitlement;mapUnmapEndpoint();">
                          </button>
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="9" class="p-p-0 p-text-center"> <p-card>
                      <div style="margin-top: 5%;height: 70px;">No
                        matching records found</div>
                    </p-card> </td>
                </tr>
              </ng-template>
              <div class="p-d-flex">
                <ng-template pTemplate="paginatorleft" let-pageData>
                  <div class="p-d-flex p-ai-center p-px-2 ember-view">
                    <input pInputText class="paginator-input" type="number" [min]="pageData.totalRecords == 0 ? 0 : 1"
                      [max]="pageData.totalRecords == 0 ? 0 : pageData.pageCount"
                      [disabled]="pageData.totalRecords == 0" [(ngModel)]="currentPage"
                      (keydown)="onPageInputChange(pageData)" (keyup)="onPageInputChange(pageData)"
                      (onkeypress)="onPageInputChange(pageData)" (change)="onPageInputChange(pageData)" />
                    <span class="p-ml-1">
                      of {{ pageData.totalRecords != 0 ? pageData.pageCount : 0 }}
                    </span>
                  </div>
                </ng-template>
                <ng-template pTemplate="paginatorright" let-pageData>
                  <div class="p-d-flex p-ai-center p-px-2">
                    <span class="p-ml-1">
                    </span>
                  </div>
                </ng-template>
              </div>
            </p-table>

          </div>
        </div>
      </div>
    </div>
    <p-overlayPanel #entilementfliter styleClass="overlayactionpart userfilter entilement-panel-content">
      <ng-template pTemplate>
        <div class="p-col-12 p-md-12 filtercard p-p-0">
          <div class="addOrgAttrs">

            <div class="p-col-auto filter-size p-d-flex p-ai-baseline">
              <i class="icon-filter p-mr-2"></i>
              <h6>Filters</h6>
            </div>

            <div class="grid b-1" *ngIf="filterMessage">
              <div class="col-12">
                <div class="alert alert-danger alert-dismissible fade show" role="alert">
                  <i class="fa fa-info-circle"></i> {{ filterMessage }}
                  <button type="button" class="close" data-dismiss="alert" aria-label="Close"
                    (click)="filterMessage = ''">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
            </div>

            <div class="p-grid p-mt-2 entitlement-panel">
              <div class="p-col-12 p-p-0 accd">
                <p-accordion expandIcon="pi pi-plus" collapseIcon="pi pi-minus" styleClass="accord_texts">
                  <p-accordionTab header="Component Name" [(selected)]="activeTab.isComponentTab">
                    <p-tree [value]="componentData" selectionMode="checkbox" [(selection)]="selectedComponent"
                      filter="true" [style]="{'width':'100%'}" class="tree_btn">
                    </p-tree>
                  </p-accordionTab>

                  <p-accordionTab header="Entitlement Name" [(selected)]="activeTab.isEntitlementTab">
                    <p-tree [value]="entitlementData" selectionMode="checkbox" [(selection)]="selectedEntilement"
                      filter="true" [style]="{'width':'100%'}" class="tree_btn"></p-tree>
                  </p-accordionTab>

                  <p-accordionTab header="My Ownerships" [(selected)]="activeTab.isOwnerEntitlementTab">
                    <p-tree [value]="ownerEntData" selectionMode="checkbox" [(selection)]="selectedOwnerEntitlement"
                      [style]="{'width':'100%'}" class="tree_btn"></p-tree>
                  </p-accordionTab>

                  <p-accordionTab header="Entitlement Status" [(selected)]="activeTab.isStatusTab">
                    <p-tree [value]="status" selectionMode="checkbox" [(selection)]="selectedStatus" filter="true"
                      [style]="{'width':'100%'}" class="tree_btn"></p-tree>
                  </p-accordionTab>
                </p-accordion>
              </div>
            </div>


            <div class="footer-over p-text-left p-mt-3">
              <button type="button" pButton (click)="applyFilter();discardOwnerFilter($event, entilementfliter)"
                label="Apply" class="p-button-secondary p-button-sm"></button>
            </div>
          </div>
        </div>
      </ng-template>
    </p-overlayPanel>


    <div class="dialog-confirm">
      <p-dialog [modal]="true" [(visible)]="isActivateConfirmDialog" styleClass="md" class="p-pt-2"
        [baseZIndex]="10000">
        <ng-template pTemplate="header">

          <span class="dialog-Title p-pl-2">Activate Entitlement</span>
        </ng-template>
        <p class="dialog-Content">{{actionMessage}}.</p>
        <p class="dialog-Content">{{confirmMessage}}</p>
        <p-footer>
          <button pButton type="button" label="Cancel" (click)="cancelConfimDialog()"
            class="p-button-raised p-button-secondary "></button>
          <button pButton type="button" label="Activate" class="p-button-primary p-mr-0 "
            (click)="onActionConfirmationClick()"></button>
        </p-footer>

      </p-dialog>

    </div>

    <p-sidebar [(visible)]="isActivityLog" [baseZIndex]="10000" class="sidebar-width" [showCloseIcon]="false"
      position="right">
      <div class="p-d-flex p-ai-center p-mb-2">

        <div class="p-mt-1"><i class="icon-user p-mr-3" style="font-size: 24px;"></i></div>
        <div class="p-pt-0 p-field p-fluid p-mb-0">
          <label class="label-lg p-medium p-mb-0">{{activityEntitlementName}}
            {{activityComponentId + '-' + activityEntitlementId}}</label><br>
          <label class="label-sm p-regular p-mb-0">{{activityComponentId + '-' + activityEntitlementId }}</label>
        </div>
      </div>
      <div class="p-col-12 p-mb-1 p-mt-1 p-pl-0">
        <label class="label-lg p-medium">Activity Log</label>
      </div>
      <div class="p-col-12 p-pl-0">
        <p-table [value]="activityLogList" scrollable="false" styleClass="tableContent">
          <ng-template pTemplate="body" let-resultset>
            <tr>
              <td class="activity-log-results">

                <label class="activelog_desc p-mt-1 label-nm p-medium">{{resultset.USER_NAME}}
                  {{resultset.DESCRIPTION}}{{activityComponentId + '-' + resultset.ENT_ID}}</label>
                <i class="icon-calender1 p-mr-1"></i>
                <label class="label-sm p-regular">{{resultset.CREATED_DATE }}</label>
              </td>
              <td class="p-fluid">
                <span
                  [ngClass]="{'activeTest1': (resultset.ENTITLEMENT_STATUS ==='Active'), 
                          'inactiveTest1':(resultset.ENTITLEMENT_STATUS === 'Inactive'),
                           'lockedTest1':(resultset.ENTITLEMENT_STATUS === 'Locked')}">{{resultset.ENTITLEMENT_STATUS}}</span>

              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div class="p-pl-2 p-text-right p-mt-3">
        <button type="button" pButton (click)="isActivityLog = false" label="Close" class="p-button-secondary"></button>
      </div>
    </p-sidebar>

    <div class="dialog-confirm">
      <p-dialog [modal]="true" [(visible)]="isConfirmDialog" styleClass="md" class="p-pt-2" [baseZIndex]="10000">
        <ng-template pTemplate="header">

          <span class="dialog-Title p-pl-2">{{activateLabel}} Entitlement</span>
        </ng-template>
        <p class="dialog-Content">{{actionMessage}}.</p>
        <p class="dialog-Content">{{confirmMessage}}</p>

        <p-footer>
          <button pButton type="button" label="Cancel" (click)="cancelConfimDialog()"
            class="p-button-raised p-button-secondary "></button>
          <button pButton type="button" label="{{activateLabel}}" class="p-button-primary p-mr-0 "
            (click)="onActionConfirmationClick()"></button>
        </p-footer>

      </p-dialog>

    </div>

    <!--Register Entitlements-->

    <p-sidebar [(visible)]="isAddNewEntitlement" [baseZIndex]="10000" position="right">
      <div class="img-entitlement p-d-flex">
        <label class="i-icons userprofile-icon register"></label>
        <span class="newUser_title p-ml-2">Register Entitlement</span>
      </div>

      <div class="row mb-1">
        <div class="col-md-12">
          <div class="cri-border-bottom"></div>
        </div>
      </div>
      <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="userCreationErrorMessage">
        <span class="sidebar-error-mess"> {{userCreationErrorMessage}}</span>
        <button type="button" class="close duplicate_ent_close-btn" data-dismiss="alert" aria-label="Close"
          (click)="userCreationErrorMessage=''">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="p-fluid">
        <div class="p-field p-fluid p-mb-0">
          <label for="component" class="component-el-space">Component Name<span class="invalid-feedback-astrik"> *
            </span></label>
          <p-dropdown [options]="componentDropdownList" [(ngModel)]="componentName" appendTo="body" [filter]="true"
            filterBy="componentName_withId" placeholder="Select Component" optionLabel="componentName_withId"
            bindValue="component_id" (onChange)="getDefaultOwnersByComponentId()">
          </p-dropdown>
          <label for="name" class="component-el-space">Entitlement Type<span class="text-danger"> *</span></label>
          <p-dropdown [disabled]="isDisableEntType" optionLabel="name" placeholder="Select Entitlement Type"
            [options]="entTypeList" [(ngModel)]="selectedEntType" name="selectedEntType" optionLabel="NAME"
            [style]="{width:'100%'}" (onChange)="onChangeEntType($event)">
          </p-dropdown>
        </div>

        <label for="name" class="component-el-space">Entitlement ID<span class="invalid-feedback-astrik"> *
          </span></label>

        <div class="p-d-flex ent-id-register">
          <div class="p-col-12">
            <div class="p-inputgroup inputgrp">
              <input type="number" numbersOnly pInputText maxlength="200" name="comp-id" disabled
                [(ngModel)]="componentName.component_id" [style.width]="'20%'"
                style="border-right: 1px solid #ced4da !important;" />
              <input type="number" id="ent_id" numbersOnly pInputText maxlength="200" name="ent-id"
                placeholder="Entitlement ID" [disabled]=isCompPresent [(ngModel)]="entitlementId_input"
                [style.width]="'80%'" [class.is-invalid]="entitlementErrorMessage" (keyup)="onKeySearch($event)"
                (blur)="onBlurEventCalled()" />
            </div>
          </div>
        </div>

        <span class="entitlement-error-msg" role="alert" *ngIf="entitlementErrorMessage">
          {{entitlementErrorMessage}}
        </span>

        <div class="p-field p-fluid p-mb-0">
          <label for="name" class="mt-3 component-ent-name">Entitlement Name<span class="invalid-feedback-astrik"> *
            </span></label>
          <div [ngClass]="{'disabled': (!isValidEntitlementId) || (isValidEntitlementId && isCourceGrainedEnt)}">
            <div *ngIf="!isCourceGrainedEnt">
              <input id="name" type="text" pInputText maxlength="200" name="name" placeholder="Enter Entitlement Name"
                [(ngModel)]="entitlementName" [style.width]="'100%'" />
            </div>
            <div *ngIf="isCourceGrainedEnt">
              <p-inputText pInputText maxlength="200" name="name" style="display: block !important;"
                [(ngModel)]="entitlementName" [style.width]="'100%'">{{entitlementName}}
                <span><b><i> - ADMS</i></b></span>
              </p-inputText>
            </div>
          </div>
        </div>
        <div [ngClass]="{'disabled': !isValidEntitlementId }">
          <div class="p-field p-fluid p-mb-0">
            <label for="name" class="component-el-space">Ownership Component<span class="invalid-feedback-astrik"> *
              </span></label>
            <p-dropdown appendTo="body" class="d-inline-block mr-2" placeholder="Select"
              [(ngModel)]="ownershipComponent" [options]="allActiveComponents" optionLabel="master_componentName_withId"
              [filter]="true" (onChange)="onChangeCompoent($event)">
            </p-dropdown>
          </div>
          <div class="p-field p-fluid  p-mb-0">
            <label for="name" class="component-el-space">Ownership Entitlement<span class="invalid-feedback-astrik"> *
              </span></label>
            <p-dropdown appendTo="body" class="d-inline-block mr-2" placeholder="Select"
              [(ngModel)]="ownershipEntitlement" [options]="activeEntitlementsOfComponent"
              optionLabel="master_entitlementName_withId" [filter]="true" (onChange)="onChangeEntitlement($event)">
            </p-dropdown>
          </div>
          <div class="p-field p-fluid p-mb-0">
            <label for="name" class="component-el-space">Description:</label>
            <textarea rows="5" cols="30" pInputTextarea [(ngModel)]="entitlementDescription"></textarea>
          </div>
        </div>
      </div>
      <div class="p-field p-fluid p-d-flex p-flex-row p-jc-end p-mt-3">
        <button type="button" pButton (click)="isAddNewEntitlement = false; clearRegisterEntitlementFields();"
          label="Discard" class="p-button-secondary p-mr-2"></button>
        <button type="button" pButton icon="icon-addDocument1"
          [ngClass]="{'anchor-disabled':entitlementName.trim().length == 0 || !componentName || this.entitlementId_input.toString().length==0 || !ownershipComponent || !ownershipEntitlement}"
          (click)="createNewEntitlement()" label="Register">
        </button>
      </div>
    </p-sidebar>



    <!--Register End Entitlements-->

    <div class="add-approval-dialog-select ent-add-approval">


      <p-dialog [modal]="true" [(visible)]="isNotificationEntitlementDialog" [style]="{width: '54vw',height:'auto'}"
        [baseZIndex]="10000" class="notify-ent-select">
        <p-header>
          <span class="select-owner-ent">Select Notification Entitlement</span>

        </p-header>

        <div class="p-grid owner-notify-search">
          <div class="p-col-11 p-pl-0 own-srch">
            <span class="p-input-icon-left p-field p-fluid">
              <i class="pi pi-search"></i>
              <input type="text" id="search-bar-owner" pInputText placeholder="Search by Entitlement ID, Name etc."
                (keyup.enter)="onNotificationSearchComponentClicked()" [(ngModel)]="searchNotificationEntitlement"
                class="p-inputtext" />
            </span>
          </div>
        </div>




        <div class="user-table">
          <p-table [columns]="notificationEntitlementColumns" [value]="entitlementsForNotification" [rowHover]="true"
            [(selection)]="selectedRecordsForNotification" styleClass="ui-table-customers" scrollHeight="300px"
            [scrollable]="true">

            <ng-template pTemplate="header" let-notificationEntitlementColumns>

              <tr>


                <th scope="col">

                  <span class="ent-id">
                    Entitlement Id
                  </span>
                </th>

                <th scope="col" *ngFor="let col of notificationEntitlementColumns" [style.width]="col.width">
                  {{col.header}}
                </th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-entitlementNotification let-rowIndex="rowIndex">

              <tr>

                <td>
                  <p-tableCheckbox class="p-mr-2" [value]="entitlementNotification"></p-tableCheckbox>
                  <span>P{{entitlementNotification.ID}}</span>
                </td>
                <td class="td-comp">{{entitlementNotification.COMPONENT_NAME}}</td>
                <td class="td-ent custom-overflow">{{entitlementNotification.ENTITLEMENT_NAME}}</td>
              </tr>
            </ng-template>
          </p-table>
        </div>

        <p-footer class="p-mt-4">
          <button pButton type="button" label="Cancel" class="p-button-secondary p-button-lg"
            (click)="OnNotificationEntitlementDialogClose()"></button>
          <button pButton type="button" label="Continue" class="p-button-lg"
            (click)="onNotificationEntitlementSelected()"></button>
        </p-footer>
      </p-dialog>
    </div>


    <div class="add-approval-dialog-select">
      <p-dialog [modal]="true" [(visible)]="isOwnerEntitlementDialog" [style]="{width: '54vw',height:'auto'}"
        [baseZIndex]="10000">
        <p-header>
          <span class="select-owner-ent">Select Owner Entitlement</span>
        </p-header>

        <div class="p-grid selectowner-search">
          <div class="p-col p-pl-0">
            <span class="p-input-icon-left p-field p-fluid">
              <i class="pi pi-search"></i>
              <input type="text" id="search-bar-owner" pInputText placeholder="Search by Entitlement ID, Name etc."
                (keyup.enter)="onOwnerSearchComponentClicked()" [(ngModel)]="searchOwnerEntitlement"
                class="p-inputtext" />
            </span>
          </div>
          <div class="p-col p-text-right p-pr-0">
            <button pButton type="button" icon="icon-filter" class="overView-calender-filter-icon p-pt-2"
              (click)="ownerEntilementfliter.toggle($event);openOwnerEntitlementFilterSideBar()">
            </button>
          </div>
        </div>

        <div class="p-grid">
          <div class="p-col p-pl-0">
            <div *ngIf="appliedOwnerEntilement.length > 0 || appliedOwnerComponent.length > 0" class="p-ai-center">
              <label>Filters Applied: </label>

              <p-chip *ngFor="let type of appliedOwnerEntilement"
                (click)="setOwnerEntitlementActiveTab('ENTITLEMENT_NAME')" class="p-mr-2"
                styleClass="p-chip-lg p-chip-blue medium-text" label="{{type.label}}">
              </p-chip>

              <p-chip *ngFor="let type of appliedOwnerComponent"
                (click)="setOwnerEntitlementActiveTab('ENTITLEMENT_COMPONENT')" class="p-mr-2"
                styleClass="p-chip-lg p-chip-blue medium-text" label="{{type.label}}">
              </p-chip>

              <span *ngIf="appliedOwnerEntilement.length > 0 || appliedOwnerComponent.length > 0"><label class="p-mb-0"
                  (click)="clearAllOwnerEntitlementFilters()"><a>Clear all</a></label></span>
            </div>
          </div>
        </div>
        <div class="p-col-md-auto" *ngIf="this.selectedRecords.length > 0">
        </div>
        <div class="user-table ">
          <p-table [columns]="ownerEntitlementColumns" [value]="entitlementsForOwner" styleClass="p-datatable-customers"
            scrollHeight="300px" [scrollable]="true">

            <ng-template pTemplate="header" let-ownerEntitlementColumns>

              <tr>


                <th scope="col" *ngFor="let col of ownerEntitlementColumns" [style.width]="col.width">
                  {{col.header}}
                </th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-entitlementOwner let-rowIndex="rowIndex">


              <tr>
                <td>
                  <p-checkbox (onChange)="onOwnerSelectionChange()" [(ngModel)]="selectedRecordsForOwner" name="group"
                    [value]="entitlementOwner" class="p-pr-2"></p-checkbox>
                  <span>P{{entitlementOwner.ID}}</span>
                </td>

                <td class="td-approval-comp">
                  {{entitlementOwner.COMPONENT_NAME}}</td>
                <td class="td-approval-ent custom-overflow">{{entitlementOwner.ENTITLEMENT_NAME}}</td>
              </tr>
            </ng-template>
          </p-table>
        </div>

        <p-footer class="p-mt-4">
          <button pButton type="button" label="Cancel" class="p-button-secondary p-button-lg"
            (click)="OnOwnerEntitlementDialogClose()"></button>
          <button pButton type="button" label="Continue" class="p-button-lg" (click)="onOwnerEntitlementSelected()"
            [disabled]="selectedRecordsForOwner?.length==0"></button>
        </p-footer>
      </p-dialog>
    </div>


    <p-card *ngIf="isFilterSelection" class="filter-selection entitlement-filter">
      <br>
      <div class="row mb-1" style="position: relative;top: 8px;">
        <div class="col-auto filter-icon" style="vertical-align: middle;">
          <i class="pi pi-filter"></i>
        </div>

        <div class="col" style="vertical-align: middle;">
          <h6 class="filter-title">Filters</h6>
        </div>

        <div class="col-auto" style="vertical-align: middle;">
          <button pButton type="button" icon="pi pi-times" (click)="cancelFilterSection()" styleClass="p-button-sm"
            class="filter-cancel-btn"></button>
        </div>
      </div>

      <div class="row mb-1">
        <div class="col-md-12">
          <div class="cri-border-bottom"></div>
        </div>
      </div>

      <div class="row mb-1" *ngIf="filterMessage">
        <div class="col-md-12">
          <div class="alert alert-danger alert-dismissible fade show" role="alert">
            <i class="fa fa-info-circle"></i> {{ filterMessage }}

            <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="filterMessage = ''">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>

      <div class="row mb-1">
        <div class="col-md-12">
          <p-accordion>
            <p-accordionTab header="Component Name" [(selected)]="activeTab.isComponentTab">
              <p-tree [value]="componentData" selectionMode="checkbox" [filter]="true"
                [(selection)]="selectedEntComponent" [style]="{'width':'100%'}">
              </p-tree>
            </p-accordionTab>

            <p-accordionTab header="Entitlement Name" [(selected)]="activeTab.isEntitlementTab">
              <p-tree [value]="entitlementData" selectionMode="checkbox" [filter]="true"
                [(selection)]="selectedEntilement" [style]="{'width':'100%'}"></p-tree>
            </p-accordionTab>

            <p-accordionTab header="My Ownerships" [(selected)]="activeTab.isOwnerEntitlementTab">
              <p-tree [value]="entitlementData" selectionMode="checkbox" [filter]="true"
                [(selection)]="selectedOwnerEntitlement" [style]="{'width':'100%'}"></p-tree>
            </p-accordionTab>

            <p-accordionTab header="Notification Entitlements" [(selected)]="activeTab.isNotificationEntTab">
              <p-tree [value]="entitlementData" selectionMode="checkbox" [filter]="true"
                [(selection)]="selectedNotification" [style]="{'width':'100%'}"></p-tree>
            </p-accordionTab>
            <p-accordionTab header="Dependent Entitlements" [(selected)]="activeTab.isDependenetEntitlementTab">
              <p-tree [value]="entitlementData" selectionMode="checkbox" [filter]="true"
                [(selection)]="selectedDependent" [style]="{'width':'100%'}"></p-tree>
            </p-accordionTab>

            <p-accordionTab header="Status" [(selected)]="activeTab.isStatusTab">
              <p-tree [value]="status" selectionMode="checkbox" [filter]="true" [(selection)]="selectedStatus"
                [style]="{'width':'100%'}"></p-tree>
            </p-accordionTab>
          </p-accordion>
        </div>
      </div>

      <br>

      <div class="row mb-1">
        <div class="col-md-12">
          <button type="button" pButton (click)="applyFilter()" icon="pi pi-check" label="Apply"
            class="ui-button-raised ui-button-success pull-right"></button>
        </div>
      </div>

      <br>
      <br>
    </p-card>





    <div class="add-approval-dialog">
      <p-dialog [(visible)]="isAddApproverDialog" [style]="{width: '96vw'}" [baseZIndex]="10000" [modal]="true">
        <p-header>

          <span class="add-approval-title">Add Approvals</span>
        </p-header>
        <div class="p-grid p-mb-1 add-approval-titles">
          <div class="p-col-md-3 overflow-text2 ofl-ent">
            <span class="entitlenamentTxt">Entitlement Name : </span>
            <span class="entitlenameData">{{selectedEntilementToApprove?.ENTITLEMENT_NAME}}</span>
            <span *ngIf="selectedEntilementToApprove?.IS_ADMS_ENTITLEMENT == 1"><b><i> ADMS</i></b> </span>
          </div>
          <div class="p-col-md-3 overflow-text2">
            <Span class="entitlenamentTxt">
              Component :
            </Span>
            <span class="entitlenameData">
              {{selectedEntilementToApprove?.COMPONENT_NAME}}
            </span>
          </div>
          <div class="p-col-md-2 overflow-text2">
            <span class="entitlenamentTxt">Entitlement ID : </span>
            <span class="entitlenameData">
              {{selectedEntilementToApprove?.COMPONENT_ID + '-' + selectedEntilementToApprove?.ENTITLEMENT_ID}}
            </span>
          </div>
          <div class="p-col-md-2">
            <p-checkbox [(ngModel)]="isNoApprovalRequired" binary="true" inputId="binary"
              class="add-checkbox p-pr-2"></p-checkbox>
            <label for="binary" class="entitlenamentTxt no-apr">No Approval Required</label>
          </div>
          <div class="p-col-md-2 p-text-right add-approval-titles-btn">
            <button pButton type="button" label="Discard" class="p-button-secondary  p-mr-2"
              (click)="discardAddApproval()"></button>
            <button pButton type="button" label="Submit" (click)="submitApprovals()"></button>
          </div>
        </div>
        <div class="p-col-md-2 p-ml-2">
          <button pButton
            [disabled]="isNoApprovalRequired || (isAddApproverDialog && targetEntitlement?.length>0 && targetEntitlement[addLevelTab?.length-1]?.length) == 0"
            label="Add Level" (click)="addLevel()" class="ui-button-danger add-level"
            [ngClass]="{'anchor-disabled':isNoApprovalRequired || (isAddApproverDialog && targetEntitlement?.length>0 && targetEntitlement[addLevelTab?.length-1]?.length) == 0}">
            <span class="add-level-icon">+</span>
          </button>
        </div>

        <div class="p-grid add-approval-picklist" disabled="isNoApprovalRequired"
          [ngClass]="{'disabled' : isNoApprovalRequired}">

          <div class="p-grid p-col-12">

            <div class="p-col-md-2 p-d-flex p-flex-row" *ngIf="isApprovalLevelDelete">
              <div class="bg-warning-approval p-2 level-del-msg">{{approvalMessage}}</div>
              <div class="approval-confirm-btn">
                <button pButton class="ui-button-raised p-mr-2 p-ml-2" (click)="deleteApprovalTab()"
                  label="Yes"></button>


                <button pButton class="ui-button-danger" (click)="isApprovalLevelDelete=false" label="No"></button>
              </div>

            </div>
            <div class="p-col-md-2 p-d-flex p-flex-row" *ngIf="isApprovalLastItemDelete">
              <div class="bg-warning-approval p-2 item-del-msg">{{approvalMessage}}</div>
              <div class="approval-confirm-btn">
                <button pButton class="ui-button-raised p-mr-2 p-ml-2" (click)="deleteLevelOnLastItemConfirmation()"
                  label="Yes"></button>
                <button pButton class="ui-button-danger" (click)="retrieveTheLevelItems()" label="No"></button>
              </div>
            </div>
          </div>
          <div class="p-col-12 picklistTab">
            <p-tabView styleClass="p-tabview-vertical" [controlClose]="true" [(activeIndex)]="selectedIndex"
              (onClose)="confirmApprovalLevelDelete($event)">
              <p-tabPanel header="Level {{i+1}}" *ngFor="let item of addLevelTab; let i = index"
                [selected]="i == selectedIndex" [closable]="addLevelTab?.length>1 && !isNoApprovalRequired">

                <div class="add-approval-table">

                  <p-pickList [source]="sourceEntitlement[i]" [target]="targetEntitlement[i]"
                    sourceHeader="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ID&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Entitlement Name&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Component Name"
                    targetHeader="&nbsp;&nbsp;&nbsp;&nbsp;ID&nbsp;&nbsp;|&nbsp;&nbsp;Entitlement Name&nbsp;&nbsp;|&nbsp;&nbsp;Component Name"
                    [disabled]="isNoApprovalRequired" [responsive]="true" [sourceStyle]="{'height':'25rem'}"
                    [targetStyle]="{'height':'25rem'}" (onMoveToSource)="validateLastItemInTarget(i, $event)"
                    filterBy="ENTITLEMENT_ID,ENTITLEMENT_NAME,COMPONENT_NAME,NAME_WITH_ADMS"
                    [showSourceControls]="false" [showTargetControls]="false"
                    sourceFilterPlaceholder="Search from avaliable entitlements"
                    targetFilterPlaceholder="Search from selected entitlements">


                    <ng-template let-item pTemplate="item">

                      <table aria-hidden="true" style="width: 100%;">
                        <tbody>
                          <tr>
                            <td class="td-addapr-id">{{item.COMPONENT_ID + '-' +
                              item.ENTITLEMENT_ID}}</td>

                            <td class="td-addapr-ent">
                              {{item.ENTITLEMENT_NAME}}
                              <span *ngIf="item.IS_ADMS_ENTITLEMENT == 1"><b><i> ADMS</i></b>
                              </span>
                            </td>
                            <td class="td-addapr-comp">
                              {{item.COMPONENT_NAME}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </ng-template>

                  </p-pickList>
                </div>
              </p-tabPanel>
            </p-tabView>

          </div>

        </div>
      </p-dialog>
    </div>



    <div class="view-approval-dialogbox">
      <p-dialog [(visible)]="isviewApprovalsDialog" [style]="{width: '90vw'}" [baseZIndex]="10000" [modal]="true"
        [maximizable]="false">
        <p-header>
          <span class="viewApr-title">View Approvals</span>
        </p-header>

        <div class="p-grid p-mb-1 view-approval-titles">
          <label class="p-mr-3">
            <span class="entitlenamentTxt">Entitlement Name: </span>
            <span class="entitlenameData">{{approvalEntitlementName}}</span>
            <span *ngIf="isAdmsEntitlement == 1"><b><i> ADMS</i></b> </span>
          </label>
          <label class="p-mr-3">
            <span class="entitlenamentTxt">Component: </span>
            <span class="entitlenameData">{{approvalComponentName}}</span>
          </label>
          <label class="p-mr-3">
            <span class="entitlenamentTxt">Entitlement ID: </span>
            <span class="entitlenameData">
              {{approvalComponentId + '-' + approvalEntitlementId }}
            </span>
          </label>
          <label class="p-mr-3">
            <span class="entitlenamentTxt">Status: </span>
            <span class="entitlenameData">{{approvalEntitlementStatus}}</span>
          </label>
          <div class="p-d-flex p-ai-center">

            <label for="binary" class="no-apr-view p-mb-0"><span class="no-apr-view-txt">Approval
                Required? </span></label>
            <p-checkbox [ngModel]="!isNoApprovalRequired" (ngModelChange)="isNoApprovalRequired = $event" binary="true" inputId="binary" [disabled]="true"
              class="view-checkbox p-pb-1 p-pr-2"></p-checkbox>
          </div>
        </div>

        <div class="p-grid view-apr-tabview">

          <div class="view-noapr" *ngIf="viewApprovalList?.length == 0">
            <span><strong>No approval process is required for this entitlement</strong> </span>
          </div>
          <div class="p-col-md-12 view-apr-table" *ngIf="viewApprovalList?.length > 0">
            <p-tabView styleClass="p-tabview-vertical" (onChange)="getLevelViewApprovals($event)">
              <p-tabPanel [header]="item" *ngFor="let item of levelTab; let i = index" [selected]="i == 0">
              </p-tabPanel>
              <div>
                <p-table [columns]="viewApprovals" [resizableColumns]="true" [autoLayout]="true"
                  [value]="viewApprovalList" [scrollable]="true" scrollHeight="400px"
                  styleClass="p-datatable-gridlines">
                  <ng-template pTemplate="colgroup">
                    <colgroup>
                      <col *ngFor="let col of viewApprovals">
                    </colgroup>
                  </ng-template>
                  <ng-template pTemplate="header" let-ownerEntitlementColumns>

                    <tr>
                      <th scope="col" *ngFor="let col of viewApprovals">
                        {{col.header}}
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-viewApprovals let-rowIndex="rowIndex">

                    <tr [ngClass]="{'bg-grey': viewApprovals.STATUS == InactiveStatus}">
                      <td>
                        <span class="info-ent" *ngIf="viewApprovals.STATUS == InactiveStatus"
                          pTooltip="Entitlement was deactivated by {{viewApprovals.LATEST_ACTION_BY}} on {{viewApprovals.LATEST_ACTION_DATE | dateFormatter}}"
                          tooltipPosition="top">
                          <label class="l-icons codiconInfo-icon"></label>
                        </span>
                        {{viewApprovals?.COMPONENT_ID + '-' + viewApprovals?.ENTITLEMENT_ID}}
                      </td>
                      <td>{{viewApprovals.ENTITLEMENT_NAME}}<span *ngIf="viewApprovals.IS_ADMS_ENTITLEMENT == 1"><b><i>
                              ADMS</i></b> </span></td>
                      <td>{{viewApprovals.COMPONENT_NAME}}</td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </p-tabView>
          </div>
        </div>

      </p-dialog>
    </div>



    <div class="edit-approval-dialog">
      <p-dialog [(visible)]="isEditApproverDialog" [style]="{width: '90vw'}" [baseZIndex]="10000" [modal]="true"
        (onHide)="pl.resetFilter()">
        <p-header>
          <label class="editApproval-icons p-mr-2 editApprovalHeader-icon"></label>
          <span class="EditApr-title">Edit Approval</span>
        </p-header>
        <div class="p-grid p-mb-1 edit-approval-titles">
          <div class="p-col-md-3 overflow-text2 ofl-edit">
            <span class="entitlenamentTxt">Entitlement Name : </span>
            <span class="entitlenameData">{{selectedEntilementToApprove?.ENTITLEMENT_NAME}} </span>
            <span *ngIf="selectedEntilementToApprove?.IS_ADMS_ENTITLEMENT == 1"><b><i> ADMS</i></b> </span>
          </div>
          <div class="p-col-md-3 overflow-text2 p-ml-3">
            <Span class="entitlenamentTxt">
              Component :
            </Span>
            <span class="entitlenameData">
              {{selectedEntilementToApprove?.COMPONENT_NAME}}
            </span>
          </div>
          <div class="p-col-md-2 overflow-text2 p-ml-3">
            <span class="entitlenamentTxt">Entitlement ID : </span>
            <span class="entitlenameData">
              {{selectedEntilementToApprove?.COMPONENT_ID + '-' +selectedEntilementToApprove?.ENTITLEMENT_ID}}
            </span>
          </div>
          <div class="p-col-md-2 p-ml-3 p-d-flex p-ai-center">
            <label for="binary" class="entitlenamentTxt no-apr-edit">Approval Required?</label>
            <p-checkbox class="edit-checkbox p-pr-2" [(ngModel)]="isNoApprovalRequired" binary="true"
              inputId="binary"></p-checkbox>

          </div>
          <div class="p-col-md-2 p-text-right edit-approval-titles-btn">
            <button pButton type="button" label="Discard" class="p-button-secondary  p-mr-2"
              (click)="discardEditApproval();pl.resetFilter()"></button>
            <button pButton type="button" label="Submit" (click)="submitApprovals()"></button>
          </div>
        </div>
        <div class="p-col-md-2">
          <button pButton
            [disabled]="!isNoApprovalRequired || (isEditApproverDialog && targetEntitlement?.length>0 && targetEntitlement[addLevelTab?.length-1]?.length) == 0"
            label="Add Level" (click)="editAddLevel()" class="p-button add-level"
            [ngClass]="{'anchor-disabled':isNoApprovalRequired==false || (isEditApproverDialog && targetEntitlement?.length>0 && targetEntitlement[addLevelTab?.length-1]?.length) == 0}">
            <span class="edit-level-icon">+</span>
          </button>
        </div>

        <div class="p-grid edit-approval-dialog level-edit" disabled="!isNoApprovalRequired"
          [ngClass]="{'disabled' : !isNoApprovalRequired}">
          <div class="p-grid p-col-12">
            <div class="p-col-md-2 p-d-flex p-flex-row" *ngIf="isApprovalLevelDelete">
              <div class="bg-warning-approval p-2 viewlevel-del-msg">{{approvalMessage}}</div>
              <div class="approval-confirm-btn">
                <button pButton class="ui-button-raised p-mr-2 p-ml-2" (click)="deleteEditApprovalTab()"
                  label="Yes"></button>
                <button pButton class="ui-button-danger" (click)="deleteEditApprovalNo()" label="No"></button>
              </div>
            </div>
            <div class="p-col-md-2 p-d-flex p-flex-row" *ngIf="isEntitlementsDeleted">
              <p-messages severity="error" styleClass="refresh-alert">
                <ng-template pTemplate>
                  <span class="p-message-icon pi pi-times-circle">
                  </span>
                  <span class="p-message-detail">
                    We have detected some changes were made on this page by another user. Please Refresh the
                    page to get the latest contents by clicking on ok button and make the changes again
                  </span>
                  <span class="undo-msg" (click)="refreshNow()">Refresh Now</span>
                  <button (click)="clearmsg()" type="button" pripple="" class="p-message-close p-link">
                    <i class="p-message-close-icon pi pi-times" (click)="cancelRefresh()"></i>
                  </button>
                </ng-template>
              </p-messages>
            </div>
            <div class="p-col-md-2 p-d-flex p-flex-row" *ngIf="isApprovalLastItemDelete">
              <div class="bg-warning-approval p-2 viewitem-del-msg">{{approvalMessage}}</div>
              <div class="approval-confirm-btn">
                <button pButton class="ui-button-raised p-mr-2 p-ml-2" (click)="deleteLevelOnLastItemConfirmation()"
                  label="Yes"></button>
                <button pButton class="ui-button-danger" (click)="retrieveTheLevelItems()" label="No"></button>
              </div>
            </div>
          </div>
          <div class="p-col-12 picklistEditTab">
            <p-tabView styleClass="p-tabview-vertical" [controlClose]="true" (onChange)="getLevelApprovals($event)"
              [(activeIndex)]="selectedIndex" (onClose)="confirmApprovalLevelDelete($event)">
              <p-tabPanel header="Level {{i+1}}" *ngFor="let item of addLevelTab; let i = index"
                [selected]="i == selectedIndex" [closable]="addLevelTab?.length>1 && isNoApprovalRequired">
                <div class="add-approval-table">
                  <div class="edit-approval-table">
                    <p-pickList #pl [source]="sourceEntitlement[i]" [target]="targetEntitlement[i]"
                      sourceHeader="&nbsp;&nbsp;&nbsp;&nbsp;ID&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Entitlement Name&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Component Name"
                      targetHeader="&nbsp;&nbsp;&nbsp;&nbsp;ID&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Entitlement Name&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Component Name"
                      [disabled]="!isNoApprovalRequired" [responsive]="true" [sourceStyle]="{'height':'25rem'}"
                      [targetStyle]="{'height':'25rem'}" (onMoveToSource)="validateLastItemInTarget(i, $event)"
                      filterBy="ENTITLEMENT_ID,ENTITLEMENT_NAME,COMPONENT_NAME,NAME_WITH_ADMS"
                      [showSourceControls]="false" [showTargetControls]="false"
                      sourceFilterPlaceholder="Search from avaliable entitlements"
                      targetFilterPlaceholder="Search from selected entitlements"
                      (onMoveToSource)="onItemMoveToSource($event,i+1)"
                      (onMoveToTarget)="onItemMoveToTarget($event,i+1)"
                      (onMoveAllToSource)="onItemMoveToSource($event,i+1)"
                      (onMoveAllToTarget)="onItemMoveToTarget($event,i+1)">
                      <ng-template let-item pTemplate="item">
                        <table aria-hidden="true">
                          <tbody>
                            <tr [ngClass]="{'bg-grey': item.STATUS == InactiveStatus}">
                              <span class="info-ent" *ngIf="item.STATUS == InactiveStatus"
                                pTooltip="Entitlement was deactivated by {{item.LATEST_ACTION_BY}} on {{item.LATEST_ACTION_DATE | dateFormatter}}"
                                tooltipPosition="top">
                                <label class="l-icons codiconInfo-icon"></label>
                              </span>
                              <td class="td-addapr-id">{{ item.COMPONENT_ID + '-' +
                                item.ENTITLEMENT_ID}}</td>

                              <td class="td-addapr-ent">
                                {{item.ENTITLEMENT_NAME}} <span *ngIf="item.IS_ADMS_ENTITLEMENT == 1"><b><i>
                                      ADMS</i></b>
                                </span></td>
                              <td class="td-addapr-comp">
                                {{item.COMPONENT_NAME}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </ng-template>

                    </p-pickList>
                  </div>
                </div>
              </p-tabPanel>
            </p-tabView>

          </div>

        </div>
      </p-dialog>
    </div>



    <p-overlayPanel #ownerEntilementfliter appendToBody="true"
      class="overlayactionpart userfilter entilement-panel-content select-owner-filter custom-fliter">
      <ng-template pTemplate>
        <div class="p-col-12 p-md-12 filtercard p-2 p-grid p-fluid">
          <div class="addOrgAttrs">
            <div class="p-grid p-mb-1">
              <div class="p-col-auto filter-icon">
                <i class="pi pi-filter"></i>
              </div>
              <div class="p-col">
                <h6 class="filter-title">Filters 2</h6>
              </div>


            </div>

            <div class="p-grid p-mb-1">
              <div class="p-col-md-12">
                <div class="cri-border-bottom"></div>
              </div>
            </div>

            <div class="p-grid p-mb-1" *ngIf="filterMessage">
              <div class="p-col-md-12">
                <div class="alert alert-danger alert-dismissible fade show" role="alert">
                  <i class="fa fa-info-circle"></i> {{ filterMessage }}
                  <button type="button" class="close" data-dismiss="alert" aria-label="Close"
                    (click)="filterMessage = ''">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
            </div>

            <div class="p-grid p-mb-1  entitlement-panel">
              <div class="p-col-md-12 accd">
                <p-accordion>
                  <p-accordionTab header="Component Name" [(selected)]="activeTab.isComponentTab">
                    <p-tree [value]="componentData" selectionMode="checkbox" [filter]="true"
                      [(selection)]="selectedComponent" [style]="{'width':'100%'}">
                    </p-tree>
                  </p-accordionTab>

                  <p-accordionTab header="Entitlement Name" [(selected)]="activeTab.isEntitlementTab">
                    <p-tree [value]="entitlementData" selectionMode="checkbox" [filter]="true"
                      [(selection)]="selectedEntilement" [style]="{'width':'100%'}"></p-tree>
                  </p-accordionTab>

                </p-accordion>
              </div>
            </div>


            <div class="footer-over">
              <button type="button" pButton
                (click)="applyOwnerFilterFilter();discardOwnerFilter($event, ownerEntilementfliter)" icon="pi pi-check"
                label="Apply" class="ui-button-raised ui-button-success pull-right"></button>
            </div>
          </div>
        </div>
      </ng-template>
    </p-overlayPanel>

    <!--Edit Entitlements-->

    <p-sidebar [(visible)]="isEditEntitlement" [baseZIndex]="10000" [showCloseIcon]="true" position="right">
      <div class="img-entitlement p-d-flex">
        <label class="i-icons userprofile-icon register"></label>
        <span class="newUser_title p-ml-2">Edit Entitlement</span>
      </div>
      <div class="row mb-1">
        <div class="col-md-12">
          <div class="cri-border-bottom"></div>
        </div>
      </div>
      <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="userCreationErrorMessage">
        {{userCreationErrorMessage}}
        <button type="button" class="close" data-dismiss="alert" aria-label="Close"
          (click)="userCreationErrorMessage=''">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div>
        <div class="p-field">
          <label for="component" class="component-el-space">Component Name<span class="invalid-feedback-astrik"> *
            </span></label>
          <p-dropdown [disabled]="true" [options]="editComponentDropdownList" [(ngModel)]="componentName.NAME"
            appendTo="body" optionLabel="NAME" bindValue="component_id" [style]="{width:'100%'}">
          </p-dropdown><br>
          <label for="name" class="component-el-space">Entitlement Type<span class="text-danger"> *</span></label>
          <p-dropdown [disabled]="true" optionLabel="name" placeholder="Select Entitlement Type" [options]="entTypeList"
            [(ngModel)]="selectedEntType" name="selectedEntType" optionLabel="NAME" [style]="{width:'100%'}">
          </p-dropdown><br>

          <label for="name" class="p-pb-0 p-mb-0 component-el-space">Entitlement ID<span
              class="invalid-feedback-astrik"> * </span></label><br>
          <div class="p-d-flex">
            <div class="p-col-12">
              <div class="p-inputgroup inputgrp">
                <input type="number" numbersOnly pInputText maxlength="200" name="editComponentId" disabled
                  [(ngModel)]="editComponentId" [style.width]="'20%'"
                  style="border-right: 1px solid #ced4da !important;" />
                <input type="number" numbersOnly pInputText maxlength="200" name="entitlementId_input" disabled
                  placeholder="Entitlement ID" [(ngModel)]="entitlementId_input" [style.width]="'80%'"
                  (keyup)="onKeySearch($event)" />
              </div>
            </div>
          </div>
          <label for="userName" class="component-ent-name">Entitlement Name<span class="invalid-feedback-astrik"> *
            </span></label>
          <div [ngClass]="{'disabled': isAdmsEntitlement == 1}">


            <div *ngIf="isAdmsEntitlement != 1">
              <input id="name" type="text" pInputText maxlength="200" name="name" placeholder="Enter Entitlement Name"
                [(ngModel)]="entitlementName" [style.width]="'100%'" />
            </div>
            <div *ngIf="isAdmsEntitlement == 1">
              <p-inputText pInputText maxlength="200" name="name" style="display: block !important;"
                [(ngModel)]="entitlementName" [style.width]="'100%'">{{entitlementName}}
                <span *ngIf="isAdmsEntitlement == 1"><b><i> - ADMS</i></b></span>
              </p-inputText>
            </div>



          </div>

          <br>
          <label for="name" class="component-ent-name">Ownership Component<span class="invalid-feedback-astrik"> *
            </span></label>
          <p-dropdown appendTo="body" class="d-inline-block mr-2" placeholder="Select" [(ngModel)]="ownershipComponent"
            [options]="allActiveComponents" [style]="{width:'100%'}" [disabled]="IS_APPROVAL_PENDING==1"
            optionLabel="master_componentName_withId" [filter]="true" (onChange)="onChangeCompoent($event)">
          </p-dropdown>
          <br>
          <label for="name" class="component-ent-name">Ownership Entitlement<span class="invalid-feedback-astrik"> *
            </span></label>
          <p-dropdown appendTo="body" class="d-inline-block mr-2" placeholder="Select"
            [(ngModel)]="ownershipEntitlement" [style]="{width:'100%'}" [disabled]="IS_APPROVAL_PENDING==1"
            [options]="activeEntitlementsOfComponent" optionLabel="master_entitlementName_withId" [filter]="true"
            (onChange)="onChangeEntitlement($event)">
          </p-dropdown>
          <br>
          <br>
          <label for="name" class="component-ent-name">Description:</label>
          <textarea rows="5" cols="30" pInputTextarea [(ngModel)]="entitlementDescription"
            [style]="{width:'100%'}"></textarea>
        </div>
      </div>
      <div class="p-d-flex p-flex-row p-jc-end">
        <button type="button" style="position: relative;width: 30%;" pButton (click)="isEditEntitlement = false;"
          label="Discard" class="p-button-secondary p-mr-2"></button>

        <button type="button" pButton
          [ngClass]="{'anchor-disabled':entitlementName.trim().length == 0 || !ownershipComponent || !ownershipEntitlement}"
          (click)="updateEntitlement();isEditEntitlement = false" class="ui-button-danger register-entitlement">
          <label class="dEdit-icons add-document"></label>
          Update
        </button>
      </div>
    </p-sidebar>
    <div>
      <p-dialog header="Delete Entitlement '{{selectedEntitlementForDelete}}'" [modal]="true"
        [(visible)]="isDeleteEntitlementPopup" [baseZIndex]="10000" styleClass="sm">
        <div class="p-0">
          <div class="text-center">
            <p
              *ngIf="OwnershipPendingcount > 0 && approverEntList.length == 0 && ownershipDependentEntList.length == 0">
              This entitlement is the part of the pending ownership transfer request, hence cannot be deleted.</p>
            <p
              *ngIf="approverEntList.length == 0 && ownershipDependentEntList.length == 0 && OwnershipPendingcount == 0">
              Are you sure you want to delete this Entitlement? </p>
          </div>
        </div>
        <div class="p-0">
          <div class="text-center">
            <p *ngIf="approverEntList.length > 0 && ownershipDependentEntList.length > 0 && OwnershipPendingcount > 0"
              class="title-textgrp">
              This entitlement is the ownership entitlement and the only approver entitlement in the only approval
              level for the below
              mentioned entitlements. Also, this entitlement is the part of the pending ownership transfer request
              and hence cannot be
              deleted. In order to be able to delete this entitlement you can set other entitlements as ownership
              and / or approver
              entitlements.
            </p>
          </div>
        </div>
        <div class="p-0">
          <div class="text-center">
            <p *ngIf="approverEntList.length > 0 && ownershipDependentEntList.length > 0 && OwnershipPendingcount == 0"
              class="title-textgrp">
              This entitlement is the ownership entitlement and the only approver entitlement in the only approval
              level for the below
              mentioned entitlements and hence cannot be deleted. In order to be able to delete this entitlement
              you can set other
              entitlements as ownership and / or approver entitlements.
            </p>
          </div>
        </div>
        <div class="p-0">
          <div class="text-center">
            <p *ngIf="ownershipDependentEntList.length > 0 && approverEntList.length == 0 && OwnershipPendingcount == 0"
              class="title-textgrp">
              This entitlement is the ownership entitlement for the below mentioned entitlements and hence cannot
              be deleted. In
              order to be able to delete this entitlement, you can set other entitlement as the ownership
              entitlement.
            </p>
          </div>
        </div>
        <div class="p-0">
          <div class="text-center">
            <p *ngIf="ownershipDependentEntList.length > 0 && approverEntList.length == 0 && OwnershipPendingcount > 0"
              class="title-textgrp">
              This entitlement is the ownership entitlement for the entitlements for the below mentioned
              entitlements. Also, this
              entitlement is the part of the pending ownership transfer request and hence cannot be deleted. In
              order to be able to
              delete this entitlement you can set other entitlements as ownership entitlement.
            </p>
          </div>
        </div>
        <div class="p-0">
          <div class="text-center" style="margin-top: 20px">
            <p *ngIf="approverEntList.length > 0 && ownershipDependentEntList.length > 0"
              style="margin-bottom: 12px !important;">
              <strong class="title-txt">List of entitlements for which '{{ selectedEntitlementForDelete }}' is
                ownership entitlement.</strong>
            </p>
          </div>
        </div>
        <div class="user-table">
          <p-table *ngIf="ownershipDependentEntList.length > 0" [value]="ownershipDependentEntList"
            styleClass="ui-table-customers" [scrollable]="true" scrollHeight="247px" [responsive]="true"
            [style]="{ height: '100%',width:'100%'}">
            <ng-template pTemplate="header">
              <tr>
                <th scope="col" style="width: 80px !important;">
                  Ent. ID
                </th>
                <th scope="col" style="width: 200px !important;">
                  Entitlement Name
                </th>
                <th scope="col" style="width: 200px !important;">
                  Component Name
                </th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-entitlement let-rowIndex="rowIndex">
              <tr>
                <td style="width: 80px !important;">
                  {{entitlement.COMPONENT_ID + '-' + entitlement.ENTITLEMENT_ID}}
                </td>
                <td style="width: 200px !important;">
                  <span class="cliplength" pTooltip="{{entitlement.ENTITLEMENT_NAME}}" tooltipPosition="top">
                    {{entitlement.ENTITLEMENT_NAME}}
                  </span>
                </td>
                <td style="width: 200px !important;">
                  {{entitlement.COMPONENT_NAME}}
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>

        <div class="p-0">
          <div class="text-center">
            <p *ngIf="approverEntList.length > 0 && ownershipDependentEntList.length == 0 && OwnershipPendingcount == 0"
              class="approver-text-block">
              This entitlement is the only approver entitlement in the only approval level for the below mentioned
              entitlements
              and hence cannot be deleted. In order to be able to delete this entitlement,
              you can set other entitlements as the approver entitlements.
            </p>
          </div>
        </div>
        <div class="p-0">
          <div class="text-center">
            <p *ngIf="approverEntList.length > 0 && ownershipDependentEntList.length == 0 && OwnershipPendingcount > 0"
              class="approver-text-block">
              This entitlement is the only approver entitlement in the only approval level for the below mentioned
              entitlements. Also, this entitlement is the part of the pending ownership transfer request and hence
              cannot be deleted. In order
              to be able to delete this entitlement you can set other entitlements as approver entitlements.
            </p>
          </div>
        </div>
        <div class="p-0">
          <div class="text-center" style="margin-top: 20px">
            <p *ngIf="approverEntList.length > 0 && ownershipDependentEntList.length > 0"
              style="margin-bottom: 12px !important;">
              <strong class="title-txt">List of entitlements for which '{{ selectedEntitlementForDelete }}' is
                only approver entitlement in the only approver level.</strong>
          </div>
        </div>
        <div class="user-table">
          <p-table *ngIf="approverEntList.length > 0" [value]="approverEntList" styleClass="ui-table-customers"
            [scrollable]="true" [responsive]="true" scrollHeight="247px" [style]="{ height: '100%',width:'100%'}">
            <ng-template pTemplate="header">
              <tr>
                <th scope="col" style="width: 80px !important;">
                  Ent. ID
                </th>
                <th scope="col" style="width: 200px !important;">
                  Entitlement Name
                </th>
                <th scope="col" style="width: 200px !important;">
                  Component Name
                </th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-entitlement let-rowIndex="rowIndex">
              <tr>
                <td style="width: 80px !important;">
                  {{entitlement.COMPONENT_ID + '-' + entitlement.ENTITLEMENT_ID}}
                </td>
                <td style="width: 200px !important;">
                  <span class="cliplength" pTooltip="{{entitlement.ENTITLEMENT_NAME}}" tooltipPosition="top">
                    {{entitlement.ENTITLEMENT_NAME}}
                  </span>

                </td>
                <td style="width: 200px !important;">
                  {{entitlement.COMPONENT_NAME}}
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <div *ngIf="approverEntList.length > 0 || ownershipDependentEntList.length > 0 || OwnershipPendingcount > 0"
          style="padding: 32px 0px 12px;">
          <div class="p-d-flex p-flex-row p-jc-end">
            <button class="p-button-secondary" pButton type="button" label="Ok"
              (click)="isDeleteEntitlementPopup=false">
            </button>
          </div>
        </div>
        <p-footer
          *ngIf="approverEntList.length == 0 && ownershipDependentEntList.length == 0 && OwnershipPendingcount == 0">
          <button pButton type="button" label="Cancel" (click)="isDeleteEntitlementPopup = false"
            class="p-button-secondary">
          </button>
          <button class="p-button-primary p-mr-0" pButton type="button" label="Delete"
            (click)="confirmDeleteEntitlement('single')">
          </button>
        </p-footer>
      </p-dialog>
    </div>

    <div [ngClass]="{'pop-up-size-change':approverEntList.length > 0 || ownershipDependentEntList.length > 0}">
      <p-dialog header="Deactivate Entitlement" [modal]="true" [(visible)]="isDeactivateConfirmDialog"
        [baseZIndex]="10000" styleClass="sm">
        <p class="text-danger p-mt-2">Deactivate Entitlement '{{selectedEntitlementForDeactivate}}'</p>
        <div class="p-0">
          <div class="text-center">
            <p *ngIf="OwnershipPendingcount > 0 && approverEntList.length == 0 && ownershipDependentEntList.length == 0 && entOwnerComponentNames.length==0"
              style="padding-top:6px">
              This entitlement is the part of the pending ownership transfer request, hence cannot be deactivated.
            </p>
            <p *ngIf="approverEntList.length == 0 && ownershipDependentEntList.length == 0 && OwnershipPendingcount == 0 && entOwnerComponentNames.length==0"
              style="padding-top:6px">
              Deactivating this entitlement will remove the access for the entitlement irrespective of its Source.
              <span class="warnmsg">Are you sure you want to continue? </span>
            </p>
          </div>
        </div>
        <div class="p-0">
          <div class="text-center">
            <p *ngIf="approverEntList.length > 0 && ownershipDependentEntList.length > 0 && OwnershipPendingcount > 0 && entOwnerComponentNames.length==0"
              class="title-textgrp">
              This entitlement is the ownership entitlement and the only approver entitlement in the only approval level
              for the below mentioned entitlements. Also, this entitlement is the part of the pending ownership transfer
              request and
              hence cannot be deactivated. In order to be able to deactivate this entitlement you can set other
              entitlements as ownership and / or approver entitlements.
            </p>
          </div>
        </div>
        <div class="p-0">
          <div class="text-center">
            <p *ngIf="approverEntList.length > 0 && ownershipDependentEntList.length == 0 && OwnershipPendingcount > 0 && entOwnerComponentNames.length==0"
              class="title-textgrp">
              This entitlement is the only approver entitlement in the only approval level for the below mentioned
              entitlements.
              Also, this entitlement is the part of the pending ownership transfer request and hence cannot be
              deactivated.
              In order to be able to deactivate this entitlement you can set other entitlements as approver
              entitlements.
            </p>
          </div>
        </div>
        <div class="p-0">
          <div class="text-center">
            <p *ngIf="approverEntList.length == 0 && ownershipDependentEntList.length > 0 && OwnershipPendingcount > 0"
              class="title-textgrp">
              This entitlement is the ownership entitlement for the below mentioned entitlements.
              Also, this entitlement is the part of the pending ownership transfer request and hence cannot be
              deactivated.
              In order to be able to deactivate this entitlement you can set other entitlements as ownership
              entitlement.
            </p>
          </div>
        </div>
        <div class="p-0">
          <div class="text-center">
            <p *ngIf="approverEntList.length > 0 && ownershipDependentEntList.length > 0 && OwnershipPendingcount == 0 && entOwnerComponentNames.length==0"
              class="title-textgrp">
              This entitlement is the ownership entitlement and the only approver entitlement in the only approval level
              for
              the below
              mentioned entitlements and hence cannot be deactivated. In order to be able to deactivate this entitlement
              you
              can set other
              entitlements as ownership and / or approver entitlements
            </p>
          </div>
        </div>
        <div class="p-0">
          <div class="text-center">
            <p *ngIf="ownershipDependentEntList.length > 0 && approverEntList.length == 0 && OwnershipPendingcount == 0 && entOwnerComponentNames.length>0"
              class="title-textgrp">
              This is an ownership entitlement in ({{entOwnerComponentNames}}) which is also an ownership entitlement
              for
              User Management (UM) for the following listed entitlements.
            </p>
          </div>
        </div>
        <div class="p-0">
          <div class="text-center">
            <p *ngIf="ownershipDependentEntList.length == 0 && approverEntList.length == 0 && OwnershipPendingcount == 0 && entOwnerComponentNames.length>0"
              class="title-textgrp">
              This is an ownership entitlement in ({{entOwnerComponentNames}}). Hence, this entitlement cannot be
              deactivated.
            </p>
          </div>
        </div>
        <div class="p-0">
          <div class="text-center">
            <p *ngIf="ownershipDependentEntList.length > 0 && approverEntList.length == 0 && OwnershipPendingcount == 0 && entOwnerComponentNames.length == 0"
              class="title-textgrp">
              This entitlement is the ownership entitlement for the below mentioned entitlements and hence cannot be
              deactivated. In
              order to be able to deactivate it, you can set another entitlement as ownership entitlement.
            </p>
          </div>
        </div>
        <div class="p-0">
          <div class="text-center">
            <p class="p-mb-2"
              *ngIf="OwnershipPendingcount > 0 && approverEntList.length == 0 && ownershipDependentEntList.length == 0 && entOwnerComponentNames.length>0">
              This is an ownership entitlement in ({{entOwnerComponentNames}}) and this entitlement is a part of pending
              transfer request.
            </p>
            <p
              *ngIf="OwnershipPendingcount > 0 && approverEntList.length == 0 && ownershipDependentEntList.length == 0 && entOwnerComponentNames.length>0">
              To deactivate this entitlement, you can set other entitlement as ownership entitlement in
              ({{entOwnerComponentNames}}) and wait for the transfer request to go through.
            </p>
          </div>
        </div>
        <div class="p-0">
          <div class="text-center">
            <p
              *ngIf="OwnershipPendingcount ==0 && approverEntList.length > 0 && ownershipDependentEntList.length > 0 && entOwnerComponentNames.length>0">
              This is an ownership entitlement in ({{entOwnerComponentNames}}) and User Management (UM) for the
              following
              listed
              entitlements also an only approver in the only approval level for the following listed entitlements.<br>
            </p>
          </div>
        </div>
        <div class="p-0">
          <div class="text-center" style="margin-top: 20px">
            <p *ngIf="approverEntList.length > 0 && ownershipDependentEntList.length > 0"
              style="margin-bottom: 12px !important;">
              <strong class="title-txt">List of entitlements for which '{{ selectedEntitlementForDeactivate }}' is
                ownership
                entitlement.</strong>
            </p>
          </div>
        </div>
        <div class="user-table">
          <p-table *ngIf="ownershipDependentEntList.length > 0" [value]="ownershipDependentEntList"
            styleClass="ui-table-customers" [responsive]="true" [style]="{ height: '100%',width:'100%'}">
            <ng-template pTemplate="header">
              <tr>
                <th id="entitlementID" style="width: 80px !important;">
                  Ent. ID
                </th>
                <th id="entitlementName" style="width: 200px !important;">
                  Entitlement Name
                </th>
                <th id="componentName" style="width: 200px !important;">
                  Component Name
                </th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-entitlement let-rowIndex="rowIndex">
              <tr>
                <td style="width: 80px !important;">
                  {{entitlement.COMPONENT_ID + '-' + entitlement.ENTITLEMENT_ID}}
                </td>
                <td style="width: 200px !important;">
                  <span class="cliplength" pTooltip="{{entitlement.ENTITLEMENT_NAME}}" tooltipPosition="top">
                    {{entitlement.ENTITLEMENT_NAME}}
                  </span>
                </td>
                <td style="width: 200px !important;">
                  {{entitlement.COMPONENT_NAME}}
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <div class="p-0">
          <div class="text-center">
            <p *ngIf="approverEntList.length > 0 && ownershipDependentEntList.length == 0 && OwnershipPendingcount == 0 && entOwnerComponentNames.length==0"
              class="approver-text-block">
              This entitlement is the only approver entitlement in the only approval level for the below mentioned
              entitlements and
              hence cannot be deactivated. In order to be able to deactivate it, you can set another entitlement as the
              approver
              entitlement.
            </p>
          </div>
        </div>
        <div class="p-0">
          <div class="text-center">
            <p *ngIf="approverEntList.length > 0 && ownershipDependentEntList.length == 0 && OwnershipPendingcount == 0 && entOwnerComponentNames.length>0"
              class="title-textgrp">
              This is an ownership entitlement in ({{entOwnerComponentNames}}) and an only approver in the only approval
              level for the following listed entitlements.
            </p>
          </div>
        </div>
        <div class="p-0">
          <div class="text-center">
            <p *ngIf="approverEntList.length > 0 && ownershipDependentEntList.length == 0 && OwnershipPendingcount > 0 && entOwnerComponentNames.length>0"
              class="title-textgrp">
              This is an ownership entitlement in ({{entOwnerComponentNames}}), an only approver in the only approval
              level for the following listed entitlements,
              also this entitlement is a part of pending transfer request.
            </p>
          </div>
        </div>
        <div class="p-0">
          <div class="text-center" style="margin-top: 20px;">
            <p *ngIf="OwnershipPendingcount>0 && approverEntList.length > 0 && ownershipDependentEntList.length > 0 && entOwnerComponentNames.length>0"
              style="margin-bottom: 12px !important;">
              <strong class="title-txt">List of entitlements for which '{{ selectedEntitlementForDeactivate }}' is the
                only approver entitlement in the only approver level.</strong>
            </p>
          </div>
        </div>
        <div class="p-0">
          <div class="text-center" style="margin-top: 20px;">
            <p *ngIf="approverEntList.length > 0 && ownershipDependentEntList.length > 0 && entOwnerComponentNames.length>0 && OwnershipPendingcount==0"
              style="margin-bottom: 12px !important;">
              style="margin-bottom: 12px !important;">
              <strong class="title-txt">List of entitlements for which '{{ selectedEntitlementForDeactivate }}' is only
                approver entitlement in the only approver level.</strong>
            </p>
          </div>
        </div>
        <div class="user-table">
          <p-table *ngIf="approverEntList.length > 0" [value]="approverEntList" styleClass="ui-table-customers"
            [responsive]="true" [style]="{ height: '100%',width:'100%'}">
            <ng-template pTemplate="header">
              <tr>
                <th id="entitlementID" style="width: 80px !important;">
                  Ent. ID
                </th>
                <th id="entitlementName" style="width: 200px !important;">
                  Entitlement Name
                </th>
                <th id="componentName" style="width: 200px !important;">
                  Component Name
                </th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-entitlement let-rowIndex="rowIndex">
              <tr>
                <td style="width: 80px !important;">
                  {{entitlement.COMPONENT_ID + '-' + entitlement.ENTITLEMENT_ID}}
                </td>
                <td style="width: 200px !important;">
                  <span class="cliplength" pTooltip="{{entitlement.ENTITLEMENT_NAME}}" tooltipPosition="top">
                    {{entitlement.ENTITLEMENT_NAME}}
                  </span>
                </td>
                <td style="width: 200px !important;">
                  {{entitlement.COMPONENT_NAME}}
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <div class="text-center">
          <p *ngIf="OwnershipPendingcount>0 && approverEntList.length == 0 && ownershipDependentEntList.length > 0 && entOwnerComponentNames.length>0"
            class="title-textgrp">
            To deactivate this entitlement, you can set other entitlement as ownership entitlement in both
            ({{entOwnerComponentNames}}) and UM and wait for the transfer request to go through.
          </p>
        </div>
        <div class="text-center">
          <p *ngIf="approverEntList.length > 0 && ownershipDependentEntList.length == 0 && OwnershipPendingcount == 0 && entOwnerComponentNames.length>0"
            class="title-textgrp">
            To Deactivate this entitlement, you can set other entitlement as ownership entitlement in
            ({{entOwnerComponentNames}}) and/or set other approver entitlements in UM.
          </p>
        </div>
        <div class="text-center">
          <p *ngIf="ownershipDependentEntList.length > 0 && approverEntList.length == 0 && OwnershipPendingcount == 0 && entOwnerComponentNames.length>0"
            class="title-textgrp">
            To deactivate this entitlement, you can set other entitlement as ownership entitlement in both
            ({{entOwnerComponentNames}}) and UM.
          </p>
        </div>
        <div class="text-center">
          <p *ngIf="OwnershipPendingcount > 0 && approverEntList.length > 0 && (ownershipDependentEntList.length > 0 || ownershipDependentEntList.length==0) && entOwnerComponentNames.length>0"
            class="title-textgrp">
            To deactivate this entitlement, you can set other entitlement as ownership entitlement in
            ({{entOwnerComponentNames}}) and UM, also and/or set other approver entitlements in UM,
            also wait for transfer request to go through.
          </p>
        </div>
        <div class="text-center">
          <p *ngIf="OwnershipPendingcount ==0 && approverEntList.length > 0 && ownershipDependentEntList.length > 0 && entOwnerComponentNames.length>0"
            class="title-textgrp">
            To Deactivate this entitlement, you can set other entitlement as ownership entitlement in
            ({{entOwnerComponentNames}}) and UM, also and/or set other approver entitlements in UM.
          </p>
        </div>
        <div
          *ngIf="approverEntList.length > 0 || ownershipDependentEntList.length > 0 || OwnershipPendingcount > 0 || entOwnerComponentNames.length>0"
          style="padding:20px 0px 20px">
          <div class="p-d-flex p-flex-row p-jc-end">
            <button class="p-button-secondary" pButton type="button" label="Ok" (click)="cancelConfimDialog()">
            </button>
          </div>
        </div>
        <p-footer
          *ngIf="approverEntList.length == 0 && ownershipDependentEntList.length == 0 && OwnershipPendingcount == 0 && entOwnerComponentNames.length==0">
          <button pButton type="button" label="Cancel" (click)="cancelConfimDialog()" class="p-button-secondary">
          </button>
          <button class="p-button-primary p-mr-0" pButton type="button" label="Deactivate"
            [ngClass]="{'anchor-disabled':approverEntList.length > 0 || ownershipDependentEntList.length > 0 || entOwnerComponentNames.length > 0 || OwnershipPendingcount>0 }"
            (click)="onActionConfirmationClick()">
          </button>
        </p-footer>
      </p-dialog>
    </div>

    <div>
      <p-dialog header="Delete Entitlements" [modal]="true" [(visible)]="isMultipleDeleteEntitlementPopup"
        [baseZIndex]="10000" styleClass="sm">
        <div class="p-0">
          <div class="text-center">
            <p *ngIf="sizeNonDeletedSelectedData == 0">You have selected {{sizeDeletedSelectedData}}
              entitlement<span *ngIf="sizeDeletedSelectedData > 1">s</span> to be deleted.</p>
            <p *ngIf="sizeNonDeletedSelectedData == 0">Are you sure you want to delete all of them ?</p>
          </div>
        </div>
        <div class="p-0">
          <div class="text-center">
            <p *ngIf="sizeDeletedSelectedData == 0 " class="title-textgrp">
              You have selected {{sizeNonDeletedSelectedData}} entitlement to be deleted.<br>
              However the selected entitlements can not be deleted as they are
              ownership entitlements and /or only approver entitlement in the only
              approval level and / or the part of pending ownership transfer
              request.
            </p>
          </div>
        </div>
        <div *ngIf="sizeDeletedSelectedData > 0 && sizeNonDeletedSelectedData > 0" class="p-0">
          <div class="text-center">
            <p class="title-textgrp">
              You have selected {{sizeSelectedData}} entitlements to be deleted.
              However the below mentioned {{sizeNonDeletedSelectedData}} entitlements will not be deleted as they
              are ownership
              entitlements and/or only approver entitlement in the only approver entitlement in the only approver
              level
              and/or are the part of pending ownership transfer request.
            </p>
          </div>
        </div>
        <div *ngIf="sizeDeletedSelectedData > 0 && sizeNonDeletedSelectedData > 0" class="user-table">
          <p-table [value]="nonDeletedEntitlementsList" styleClass="ui-table-customers" scrollHeight="247px"
            [scrollable]="true" [responsive]="true" [style]="{ height: '100%',width:'100%'}">
            <ng-template pTemplate="header">
              <tr>
                <th scope="col" style="width: 80px !important;">
                  Ent. ID
                </th>
                <th scope="col" style="width: 200px !important;">
                  Entitlement Name
                </th>
                <th scope="col" style="width: 200px !important;">
                  Component Name
                </th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-entitlement let-rowIndex="rowIndex">
              <tr>
                <td style="width: 80px !important;">
                  {{entitlement.COMPONENT_ID + '-' + entitlement.ENTITLEMENT_ID}}
                </td>
                <td style="width: 200px !important;">
                  <span class="cliplength" pTooltip="{{entitlement.ENTITLEMENT_NAME}}" tooltipPosition="top">
                    {{entitlement.ENTITLEMENT_NAME}}
                  </span>
                </td>
                <td style="width: 200px !important;">
                  {{entitlement.COMPONENT_NAME}}
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <div *ngIf="sizeDeletedSelectedData > 0 && sizeNonDeletedSelectedData > 0 " class="p-0">
          <div class="text-center">
            <p class="middle-text">
              The below mentioned {{sizeDeletedSelectedData}} entitlements will be deleted.
            </p>
          </div>
        </div>
        <div *ngIf="sizeDeletedSelectedData > 0 && sizeNonDeletedSelectedData > 0" class="user-table">
          <p-table [value]="deletedEntitlementsList" styleClass="ui-table-customers" scrollHeight="247px"
            [scrollable]="true" [responsive]="true" [style]="{ height: '100%',width:'100%'}">
            <ng-template pTemplate="header">
              <tr>
                <th scope="col" style="width: 80px !important;">
                  Ent. ID
                </th>
                <th scope="col" style="width: 200px !important;">
                  Entitlement Name
                </th>
                <th scope="col" style="width: 200px !important;">
                  Component Name
                </th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-entitlement let-rowIndex="rowIndex">
              <tr>
                <td style="width: 80px !important;">
                  {{entitlement.COMPONENT_ID + '-' + entitlement.ENTITLEMENT_ID}}
                </td>
                <td style="width: 200px !important;">
                  <span class="cliplength" pTooltip="{{entitlement.ENTITLEMENT_NAME}}" tooltipPosition="top">
                    {{entitlement.ENTITLEMENT_NAME}}
                  </span>

                </td>
                <td style="width: 200px !important;">
                  {{entitlement.COMPONENT_NAME}}
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <div *ngIf="sizeDeletedSelectedData == 0" style="padding: 32px 0px 12px;">
          <div class="p-d-flex p-flex-row p-jc-end">
            <button class="p-button-secondary" pButton type="button" label="Ok"
              (click)="isMultipleDeleteEntitlementPopup=false">
            </button>
          </div>
        </div>
        <p-footer
          *ngIf="sizeDeletedSelectedData > 0 && sizeNonDeletedSelectedData > 0 || sizeNonDeletedSelectedData == 0"
          class="float-right">
          <button pButton type="button" label="Cancel" (click)="isMultipleDeleteEntitlementPopup = false"
            class="p-button-secondary">
          </button>
          <button class="p-button-primary p-mr-0" pButton type="button" label="Delete"
            (click)="confirmDeleteEntitlement('mutiple')">
          </button>
        </p-footer>
      </p-dialog>
    </div>
  </div>
</div>