import { Component, ContentChildren, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MessageService, PrimeTemplate, FilterService } from 'primeng/api';
import { PagesService } from '../pages.service';
import { DomHandler } from "primeng/dom";
import { ObjectUtils } from '../object-utlis.service';
@Component({
  selector: 'jhi-usergroupmapping',
  templateUrl: './ApplicationRolesUserGroupMapping.component.html',
  styleUrls: ['./ApplicationRolesUserGroupMapping.component.scss'],
  providers: [MessageService, FilterService, ObjectUtils]
})
export class ApplicationRolesUserGroupMappingComponent implements OnInit {
  ApplicationRolesUserGroupMappingForm = this.fb.group({
    tabview: [0],
    autocomplete: [null, [Validators.required]],
    select2: [null, [Validators.required]],
    select: [null, [Validators.required]],
    select1: [null, [Validators.required]],
    pickList1: [null, []],
    pickList2: [null, []],
    sourceFilter: [null, []],
  });
  pageloadvalues: any;
  ccprimebreadcrumbList = [
    { label: `Authorization` },
    { label: `Application Roles` },
    { label: `User Group Mapping` },
  ]
  homeiconccprimebreadcrumb = { icon: 'pi pi-home', routerLink: ['/'] };
  htmlSrc: any;
  autocompleteList: any[] = [];
  selectList: any[] = [];
  select1List: any[] = [];
  select2List: any[] = [];
  pickList1List: any[] = [];
  pickList1List1: any[] = [];
  pickList1targetList: any[] = [];
  pickList1targetList1: any[] = [];
  pickList2List: any[] = [];
  pickList2targetList: any[] = [];
  text: any[] = [];
  messageFlag = true;
  results: string[] = [];
  options: any;
  tableList: any[] = [];
  appId: any;
  attId: any;
  groupId: any;
  flag1 = true;
  mapList: any[] = [];
  mapList1: any[] = [];
  mList: any[] = [];
  mList1: any[] = [];
  umList: any[] = [];
  umList1: any[] = [];
  exMapList: any[] = [];
  GroupExMapList: any[] = [];
  btn = true;
  btn1 = true;
  //new picklist
  isChecked: boolean = false;
  @ViewChildren("picklistCheckboxes") checkboxes: QueryList<ElementRef>;
  @Input() sourceHeader: string;
  @Input() targetHeader: string;
  @Input() responsive: boolean;
  @Input() filterBy: string = "brand";
  @Input() trackBy: Function = (index: number, item: any) => item;
  @Input() sourceTrackBy: Function;
  @Input() targetTrackBy: Function;
  @Input() showSourceFilter: boolean = true;
  @Input() showTargetFilter: boolean = true;
  @Input() metaKeySelection: boolean = true;
  @Input() style: any;
  @Input() styleClass: string;
  @Input() sourceStyle: any;
  @Input() targetStyle: any;
  @Input() showSourceControls: boolean = true;
  @Input() showTargetControls: boolean = true;
  @Input() sourceFilterPlaceholder: string;
  @Input() targetFilterPlaceholder: string;
  @Input() disabled: boolean = false;
  @Output() onMoveToSource: EventEmitter<any> = new EventEmitter();
  @Output() onMoveAllToSource: EventEmitter<any> = new EventEmitter();
  @Output() onMoveAllToTarget: EventEmitter<any> = new EventEmitter();
  @Output() onMoveToTarget: EventEmitter<any> = new EventEmitter();
  @Output() onSourceSelect: EventEmitter<any> = new EventEmitter();
  @Output() onTargetSelect: EventEmitter<any> = new EventEmitter();
  @ViewChild('sourcelist') listViewSourceChild: ElementRef;
  @ViewChild('targetlist') listViewTargetChild: ElementRef;
  @ViewChild('sourceFilter') sourceFilterViewChild: ElementRef;
  @ViewChild('targetFilter') targetFilterViewChild: ElementRef;
  @ContentChildren(PrimeTemplate) templates: QueryList<any>;
  public itemTemplate: TemplateRef<any>;
  public visibleOptionsSource: any[];
  public visibleOptionsTarget: any[];
  selectedItemsSource: any[] = [];
  selectedItemsTarget: any[] = [];
  reorderedListElement: any;
  movedUp: boolean;
  movedDown: boolean;
  itemTouched: boolean;
  filterValueSource: string;
  filterValueTarget: string;
  listHighlightTarget: boolean;
  listHighlightSource: boolean;
  readonly SOURCE_LIST = -1;
  readonly TARGET_LIST = 1;
  messageFlag1 = true;
  wfFlag = false;
  application: any;
  //end
  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    public pagesService: PagesService,
    private fb: UntypedFormBuilder,
    private messageService: MessageService) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.ApplicationRolesUserGroupMappingForm.patchValue(JSON.parse(data));
    }

  }
  ngOnInit(): void {
    this.getGroupList();
    this.ApplicationRolesUserGroupMappingForm.get('select').disable();
  }

  ngAfterContentInit() {
    this.templates.forEach((item) => {
      this.itemTemplate = item.template;
    });
  }

  ngAfterViewChecked() {
    if (this.movedUp || this.movedDown) {
      let listItems = DomHandler.find(this.reorderedListElement, 'li.p-state-highlight');
      let listItem;
      if (this.movedUp)
        listItem = listItems[0];
      else
        listItem = listItems[listItems.length - 1];

      DomHandler.scrollInView(this.reorderedListElement, listItem);
      this.movedUp = false;
      this.movedDown = false;
      this.reorderedListElement = null;
    }
  }

  onItemClick(event, item: any, selectedItems: any[], callback: EventEmitter<any>) {
    if (this.disabled) {
      return;
    }

    let index = this.findIndexInSelection(item, selectedItems);
    let selected = (index != -1);
    if (selected) {
      selectedItems.splice(index, 1);
    }
    else {
      selectedItems.push(item);
    }

    callback.emit({ originalEvent: event, items: selectedItems });

    this.itemTouched = false;
  }

  filterMethodPick(event: any, list: any, pickFlag: any) {
    let filtered: any[] = [];
    let items: any;
    let query = event;
    if (query.length > 0) {
      list.forEach(element => {
        items = element;
        if (items.label?.toLowerCase().includes(query.toLowerCase())) {
          filtered.push(items);
        }
      });
      if (pickFlag == 'pick1source') {
        this.pickList1List = filtered;
      }
      else if (pickFlag == 'pick1target') {
        this.pickList1targetList = filtered;
      }

    }
    else if (pickFlag == 'pick1source') {
      this.pickList1List = [];
      this.pickList1List = [...this.pickList1List1];
    }
    else if (pickFlag == 'pick1target') {
      this.pickList1targetList = [];
      this.pickList1targetList = [...this.pickList1targetList1];
    }
  }

  isItemVisible(item: any, listType: number): boolean {
    if (listType == this.SOURCE_LIST) {
      return this.isVisibleInList(this.visibleOptionsSource, item, this.filterValueSource);
    }
    else {
      return this.isVisibleInList(this.visibleOptionsTarget, item, this.filterValueTarget);
    }
  }

  isVisibleInList(data: any[], item: any, filterValue: string): boolean {
    if (filterValue?.trim().length) {
      data.forEach(element => {
        if (item == element) {
          return true;
        }
      });
    }
    else {
      return true;
    }
  }

  moveRight() {
    if (this.selectedItemsSource?.length) {
      this.selectedItemsSource.forEach(element => {
        let selectedItem = element;
        if (this.findIndexInList(selectedItem, this.pickList1targetList) == -1) {
          this.pickList1targetList.push(this.pickList1List.splice(this.findIndexInList(selectedItem, this.pickList1List), 1)[0]);
          this.pickList1targetList1.push(this.pickList1List1.splice(this.findIndexInList(selectedItem, this.pickList1List1), 1)[0]);
          this.btn = false;
        }
      });
      this.isChecked = false;
      this.onMoveToTarget.emit({
        items: this.selectedItemsSource
      });
      this.selectedItemsSource = [];
    }
    this.checkboxes.forEach((element) => {
      this.pickList1targetList.filter(z => z).forEach((x) => (x.isChecked = false));
    });
  }

  moveAllRight() {
    if (this.pickList1List) {
      let movedItems = [];
      this.pickList1targetList1 = [];
      for (let i = 0; i < this.pickList1List.length && this.isItemVisible(this.pickList1List[i], this.SOURCE_LIST); +i) {
        let removedItem = this.pickList1List.splice(i, 1)[0];
        this.pickList1List1.splice(this.findIndexInList(removedItem, this.pickList1List1), 1);
        this.pickList1targetList.push(removedItem);
        movedItems.push(removedItem);
        this.btn = false;
      }

      this.onMoveToTarget.emit({
        items: movedItems
      });

      this.onMoveAllToTarget.emit({
        items: movedItems
      });

      this.selectedItemsSource = [];
      this.pickList1targetList1 = [...this.pickList1targetList];
    }
    this.checkboxes.forEach((element) => {
      this.pickList1targetList.filter(z => z).forEach((x) => (x.isChecked = false));
    });
  }

  moveLeft() {
    if (this.selectedItemsTarget?.length) {
      this.selectedItemsTarget.forEach(element => {
        let selectedItem = element;
        if (this.findIndexInList(selectedItem, this.pickList1List) == -1) {
          this.pickList1List.push(this.pickList1targetList.splice(this.findIndexInList(selectedItem, this.pickList1targetList), 1)[0]);
          this.pickList1List1.push(this.pickList1targetList1.splice(this.findIndexInList(selectedItem, this.pickList1targetList1), 1)[0]);
          this.btn = false;
        }
      });
      this.isChecked = false;
      this.onMoveToSource.emit({
        items: this.selectedItemsTarget
      });

      this.selectedItemsTarget = [];
    }
    this.checkboxes.forEach((element) => {
      this.pickList1List.filter(z => z).forEach((x) => (x.isChecked = false));
    });
  }

  moveAllLeft() {
    if (this.pickList1targetList) {
      let movedItems = [];
      this.pickList1List1 = [];
      for (let i = 0; i < this.pickList1targetList.length && this.isItemVisible(this.pickList1targetList[i], this.TARGET_LIST); +i) {
        let removedItem = this.pickList1targetList.splice(i, 1)[0];
        this.pickList1targetList1.splice(this.findIndexInList(removedItem, this.pickList1targetList1), 1);
        this.pickList1List.push(removedItem);
        movedItems.push(removedItem);
        this.btn = false;
      }

      this.onMoveToSource.emit({
        items: movedItems
      });

      this.onMoveAllToSource.emit({
        items: movedItems
      });

      this.selectedItemsTarget = [];
      this.pickList1List1 = [...this.pickList1List];
    }
    this.checkboxes.forEach((element) => {
      this.pickList1List.filter(z => z).forEach((x) => (x.isChecked = false));
    });
  }

  isSelected(item: any, selectedItems: any[]) {
    return this.findIndexInSelection(item, selectedItems) != -1;
  }

  findIndexInSelection(item: any, selectedItems: any[]): number {
    return this.findIndexInList(item, selectedItems);
  }

  findIndexInList(item: any, list: any): number {
    let index: number = -1;

    if (list) {
      for (let i = 0; i < list.length; i++) {
        if (list[i] == item) {
          index = i;
          break;
        }
      }
    }

    return index;
  }

  handleChangetabview(e: any): void {
    this.ApplicationRolesUserGroupMappingForm.patchValue({ "tabview": e.index });
  }

  getGroupList(): void {
    this.pagesService.gettableDataListGroups().subscribe(results => {
      const responseData = results.success.result.records;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.displayName;
          data.value = element.id;
          this.select2List.push(data);
        });
        this.select2List = [...this.select2List];
      }
    });

  }


  getApplicationList(): void {
    this.ApplicationRolesUserGroupMappingForm.get('select').enable();
    this.selectList = [];
    this.pagesService.getApplicationListMapping().subscribe(results => {
      this.ApplicationRolesUserGroupMappingForm.controls.select.reset();
      this.flag1 = true;
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.selectList.push(data);
        });
        this.selectList = [...this.selectList];
      }
      this.application=this.selectList[0].value;
      this.ApplicationRolesUserGroupMappingForm.controls['select'].setValue(this.application);
      this.getMappedDetails();
    });
  }

  getMappedDetails() {
    this.flag1 = true;
    this.ApplicationRolesUserGroupMappingForm.controls.sourceFilter.reset();
    this.pickList1targetList = [];
    this.pickList1List = [];
    this.pickList1List1 = [];
    this.pickList1targetList1 = [];
    this.appId = this.ApplicationRolesUserGroupMappingForm.value.select;
    this.groupId = this.ApplicationRolesUserGroupMappingForm.value.select2;
    const groupReqData = { appId: this.appId, groupId: this.groupId };
    this.pagesService.getGroupMappedApplicationRoles(groupReqData).subscribe(results => {
      let responseData = [];
      if (results.hasOwnProperty("success")) {
        responseData = results.success.result;
      }
      else {
        responseData = [];
      }
      this.pickList1targetList = [];
      this.exMapList = [];
      if (responseData && responseData.length > 0) {
        this.flag1 = false;
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.pickList1targetList.push(data);
          this.exMapList.push(data.value);
        });
        this.pickList1targetList = [...this.pickList1targetList];
        this.pickList1targetList1 = [...this.pickList1targetList];
        this.exMapList = [...this.exMapList];
      }

    });

    this.pagesService.getGroupUnmappedApplicationRoles(groupReqData).subscribe(results => {
      const responseData = results?.success?.result;
      this.pickList1List = [];
      if (responseData && responseData.length > 0) {
        this.flag1 = false;
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.pickList1List.push(data);
        });
        this.pickList1List = [...this.pickList1List];
        this.pickList1List1 = [...this.pickList1List];
      }
    });
  }

  getExMapUnMapList() {
    this.exMapList = [];
    this.appId = this.ApplicationRolesUserGroupMappingForm.value.select;
    this.groupId = this.ApplicationRolesUserGroupMappingForm.value.select2;
    const exMapReqdata = { appId: this.appId, groupId: this.groupId };

    this.pagesService.getGroupMappedApplicationRoles(exMapReqdata).subscribe(results => {
      const responseData = results?.success?.result;
      if (responseData && responseData.length > 0) {
        this.exMapList = [];
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.exMapList.push(data.value);
        });
        this.exMapList = [...this.exMapList];
      }
    });
  }

  onSave() {
    this.messageFlag = true;
    this.messageFlag1 = true;
    this.appId = this.ApplicationRolesUserGroupMappingForm.value.select;
    this.groupId = this.ApplicationRolesUserGroupMappingForm.value.select2;
    this.mapList = this.pickList1targetList1;
    this.mList = [];
    this.mapList.forEach((element: any = {}) => {
      const data = {
        label: null,
        value: null,
      };
      data.label = element.label;
      data.value = element.value;
      this.mList.push(data.value);
    });
    this.mList = [...this.mList];
    const assignList = this.mList.filter(x => !this.exMapList.includes(x));
    const deassignList = this.exMapList.filter(x => !this.mList.includes(x));
    if (assignList.length == 0 && deassignList.length == 0) {
      this.messageFlag = true;
      this.messageFlag1 = true;
      this.messageService.add({ key: 'myKey2', severity: 'info', detail: 'No Application role To Map.' });
      this.btn = true;
      setTimeout(() => {
        this.messageFlag1 = false;
      }, 3000);

    }
    else {
      const reqBody = {};
      reqBody['tenantId'] = this.pagesService.tenantId;
      reqBody['applicationId'] = this.appId;
      reqBody['userGroupId'] = this.groupId;
      reqBody['assignList'] = assignList;
      reqBody['deAssignList'] = deassignList;
      const formValues = reqBody;
      this.pagesService.onSaveGroupApplicationRole(formValues).subscribe(
        {
          next: results => {
            this.setMapApplicationRoleToUserGroupWfFlag(results);
            this.setUnMapApplicationRoleToUserGroupWfFlag(results);

            if (this.wfFlag) {
              this.navigateToMyReq("Map/unmap application role to User group request has been sent for approval");
            } else {
              this.messageFlag = true;
              this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'Application role mapping/unmapping saved successfully.' });
              this.btn = true;
              setTimeout(() => {
                this.messageFlag = false;
              }, 3000);
              this.getExMapUnMapList();
            }
          },
          error: error => {
            if (error.error?.error?.messageCode == "CIAM 105") {
              this.messageService.add({ key: 'myKey1', severity: 'error', detail: "Request already available for approval" });
              setTimeout(() => {
                this.messageFlag = false;
              }, 3000);
            }
            else {
              this.messageFlag = true;
              this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Application role mapping failed.' });
              setTimeout(() => {
                this.messageFlag = false;
              }, 3000);
            }
          }
        });
    }
  }
  setUnMapApplicationRoleToUserGroupWfFlag(results: any) {
    for (let j = 0; j < results?.success?.result["Un-MapApplicationRoleFromUserGroup"]?.length; j++) {
      if (results?.success?.result["Un-MapApplicationRoleFromUserGroup"][j]?.messageCode == 'CIAM 673') {
        this.wfFlag = false;
      }
      else if (results?.success?.result["Un-MapApplicationRoleFromUserGroup"][j]?.messageCode == 'CIAM 154') {
        this.wfFlag = true;
      }
    }
  }
  setMapApplicationRoleToUserGroupWfFlag(results: any) {
    for (let i = 0; i < results?.success?.result?.MapApplicationRoleToUserGroup.length; i++) {
      if (results?.success?.result?.MapApplicationRoleToUserGroup[i]?.messageCode == 'CIAM 672') {
        this.wfFlag = false;
      }
      else if (results?.success?.result?.MapApplicationRoleToUserGroup[i]?.messageCode == 'CIAM 154') {
        this.wfFlag = true;
      }
    }
  }

  navigateToMyReq(msg: any): void {
    const navigationExtras: NavigationExtras = {
      state: {
        msg: msg,
      },
    };
    this.router.navigate(['/pages/myrequestslisting'], navigationExtras);
  }

}
