import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PagesService } from '../pages.service';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { DatePipe } from '@angular/common';
import { FilterMatchMode, MenuItem, SortEvent, MessageService } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { DateFormatterPipe } from 'app/pipes/date-formatter.pipe';
@Component({
    selector: 'jhi-bulkactioncheckstatus',
    templateUrl: './BulkActionCheckStatus.component.html',
    styleUrls: ['./BulkActionCheckStatus.component.scss'],
    providers: [DialogService, MessageService, DateFormatterPipe],
})
export class BulkActionCheckStatusComponent implements OnInit {
    BulkActionCheckStatusForm = this.fb.group({
        textField: [null, []],
        select: [null, []]
    });
    activeId: any[] = [];
    pageloadvalues: any;
    wfFlag = false;
    errorFlag: boolean = true;
    tableList: any[] = [];
    tableList1: any[] = [];
    selectedItems: any[] = [];
    actionDisable = true;
    userCount: any;
    selectedRecords: any[] = [];
    tablecols: any[] = [
        { field: 'firstName', header: 'First Name' },
        { field: 'middleName', header: 'Middle Name' },
        { field: 'lastName', header: 'Last Name' },
        { field: 'emailAddress', header: 'Deleted by' },
        { field: 'status', header: 'Deleted on' },
    ];
    statuses: { name: string; value: string; }[];
    matchModeOptions: { label: string; value: any; }[];
    searchStr: string;
    sortByColumn = '';
    sorting = '';
    totalRecords: number = 0;
    pageSize: number = 10; //default pageSize 10
    pageNumber: number = 1;
    oldTotalRec = 0;
    @ViewChild('pp') paginator: Paginator;
    customFilterFlag = false;
    filterReqObj = {};
    filterItems!: MenuItem[];
    showChip = false;
    checkDisable = false;
    activeItem: any;
    activeStatus: any;
    myDate = new Date();
    messageFlag: boolean = false;
    @ViewChild('tableid') customerTableRef: Table | undefined;
    constructor(
        private router: Router,
        protected activatedRoute: ActivatedRoute,
        private fb: UntypedFormBuilder,
        public pagesService: PagesService,
        public dialogService: DialogService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private datePipe: DatePipe,
        private messageService: MessageService,
        private dateFormatter: DateFormatterPipe
    ) {
        const navigation = this.router.getCurrentNavigation();
        const data = navigation?.extras?.state?.data;
        if (data) {
            this.BulkActionCheckStatusForm.patchValue(JSON.parse(data));
        }
    }
    ngOnInit(): void {
        this.matchModeOptions = [
            {
                label: 'Date Before',
                value: FilterMatchMode.DATE_BEFORE
            },
            {
                label: 'Date After',
                value: FilterMatchMode.DATE_AFTER
            },
        ];

        this.filterItems = [
            {
                label: 'Today',
                command: () => {
                    this.activeStatus = 'Today';
                    this.showChip = true;
                    this.checkDisable = true;
                    let reqBody2 = this.getRangeFilter(this.activeStatus);
                    this.showStatusFilter(reqBody2);
                },
            },

            {
                label: 'Last 10 days',
                command: () => {
                    this.activeStatus = 'Last 10 days';
                    this.showChip = true;
                    this.checkDisable = true;
                    let reqBody2 = this.getRangeFilter(this.activeStatus);
                    this.showStatusFilter(reqBody2);
                },

            },
            {
                label: 'Last 30 days',
                command: () => {
                    this.activeStatus = 'Last 30 days';
                    this.showChip = true;
                    this.checkDisable = true;
                    let reqBody2 = this.getRangeFilter(this.activeStatus);
                    this.showStatusFilter(reqBody2);
                },
            },
            {
                label: 'Last 1 year',
                command: () => {
                    this.activeStatus = 'Last 1 year';
                    this.showChip = true;
                    this.checkDisable = true;
                    let reqBody2 = this.getRangeFilter(this.activeStatus);
                    this.showStatusFilter(reqBody2);
                },
            }
        ];
        this.gettableList();
    }
    onCcprimebutton($event: UntypedFormGroup): void {
        this.ref.close();
    }
    gettableList(): void {
        this.actionDisable = true;
        const str = this.BulkActionCheckStatusForm.value.textField;
        if (str == null) {
            this.searchStr = '';
        }
        else {
            this.searchStr = str;
        }
        this.paginator?.changePage(0);
        let reqBody = {};
        reqBody['tenantId'] = this.pagesService.tenantId;
        reqBody['page'] = 1;
        reqBody['rows'] = 10;
        reqBody['serchString'] = this.searchStr;
        reqBody['sortByColumn'] = this.sortByColumn;
        reqBody['sorting'] = "descending";
        const data = reqBody;
        this.pagesService.bulkActionCheckStatusPagination(data).subscribe(results => {
            const responseData = results.success.result.records;
            if (responseData && responseData.length > 0) {
                this.totalRecords = results.success.result.recordsCount;
                this.oldTotalRec = results.success.result.recordsCount;
                this.convertToDateFormat(responseData);
                this.tableList = responseData;
                this.tableList1 = responseData;
                this.errorFlag = false;
            } else {
                this.errorFlag = true;
            }
        });

    }

    paginate(event, search) {
        let str = '';
        if (search != null) {
            str = search;
        }
        this.pageSize = event.rows;
        this.pageNumber = event.page + 1;
        let reqBody = {};
        reqBody['tenantId'] = this.pagesService.tenantId;
        reqBody['page'] = this.pageNumber;
        reqBody['rows'] = this.pageSize;
        reqBody['serchString'] = str;
        reqBody['sortByColumn'] = this.sortByColumn;
        if (this.sorting == "") {
            reqBody['sorting'] = "descending";
        }
        else {
            reqBody['sorting'] = this.sorting;
        }

        const data = reqBody;
        this.pagesService.bulkActionCheckStatusPagination(data).subscribe(results => {
            const responseData = results.success.result.records;
            this.convertToDateFormat(responseData);
            this.totalRecords = results.success.result.recordsCount;
            this.oldTotalRec = results.success.result.recordsCount;
            if (responseData && responseData.length > 0) {
                this.tableList = responseData;
                this.tableList1 = responseData;
            }
        });
    }

    globalFilterMethod(event: any) {
        let filtered: any[] = [];
        let items: any;
        let cc;
        let query = event.target.value;
        this.tableList = [];
        this.tableList1 = [];
        this.paginator.changePage(0);
        let reqBody = {};
        reqBody['tenantId'] = this.pagesService.tenantId;
        reqBody['page'] = 1;
        reqBody['rows'] = this.pageSize;
        reqBody['serchString'] = query;
        if (query == "") {
            reqBody['sorting'] = "descending";
        }
        const data = reqBody;
        this.pagesService.bulkActionCheckStatusPagination(data).subscribe({
            next: results => {
                const responseData = results.success.result.records;
                this.convertToDateFormat(responseData);
                this.totalRecords = results.success.result.recordsCount;
                this.oldTotalRec = results.success.result.recordsCount;
                this.tableList = responseData;
                this.tableList1 = responseData;
            },
            error: error => {
                this.totalRecords = 0;
                this.oldTotalRec = 0;
            }
        });
    }
    sortData(event: SortEvent) {
        let fieldName = event.field;
        let orderNum = event.order;
        let orderBy;
        if (orderNum == 1) {
            orderBy = "ascending";
        }
        else {
            orderBy = "descending";
        }
        let str = '';
        const search = this.BulkActionCheckStatusForm.value.textField;
        if (search != null) {
            str = search;
        }
        if (fieldName == 'initiatedOnDate') {
            fieldName = 'initiatedOn';
        }
        if (fieldName == 'completedOnDate') {
            fieldName = 'completedOn';
        }
        if (this.sortByColumn != fieldName || this.sorting != orderBy) {
            this.sortByColumn = fieldName;
            this.sorting = orderBy;
            let reqBody = {};
            reqBody['tenantId'] = this.pagesService.tenantId;
            reqBody['page'] = 1;
            reqBody['rows'] = this.pageSize;
            reqBody['serchString'] = str;
            reqBody['sortByColumn'] = fieldName;
            reqBody['sorting'] = orderBy;
            reqBody['filters'] = this.filterReqObj;
            const data = reqBody;
            this.pagesService.bulkActionCheckStatusPagination(data).subscribe(results => {

                const responseData = results.success.result.records;
                this.convertToDateFormat(responseData);
                this.totalRecords = results.success.result.recordsCount;
                this.oldTotalRec = results.success.result.recordsCount;
                this.tableList = responseData;
                this.tableList1 = responseData;
                this.customSort(event);
            });
        }


    }
    customSort(event: SortEvent) {
        event.data.sort((data1, data2) => {
            let value1 = data1[event.field];
            let value2 = data2[event.field];
            let result = null;

            if (value1 == null && value2 != null)
                result = -1;
            else if (value1 != null && value2 == null)
                result = 1;
            else if (value1 == null && value2 == null)
                result = 0;
            else if (typeof value1 === 'string' && typeof value2 === 'string')
                result = value1.localeCompare(value2);
            else {
                let res = (value1 > value2) ? 1 : 0;
                result = (value1 < value2) ? -1 : res;
            }
            return (event.order * result);
        });
    }
    columnFilter(event: any) {
        let oldFilter = event.filters;
        let newFilter = event.filters;
        let filterEvent = [...Object.entries(newFilter)];
        let filterBody = [];
        let finalObj = {};
        let cnt = 0;
        filterEvent.forEach((element: any = {}) => {
            let filterValue = element[1];
            filterBody = [];
            if (filterValue.length > 1) {
                if (filterValue[1]?.value == '') {
                    filterValue[1].value = null;
                }
            }
            if (filterValue[0]?.value == '') {
                filterValue[0].value = null;
            }
            if (filterValue[0]?.value != null) {
                filterBody.push(filterValue[0]);
                if (element[0] == 'initiatedOnDate') {
                    element[0] = 'initiatedOn';
                }
                if (element[0] == 'completedOnDate') {
                    element[0] = 'completedOn';
                }
                if (element[0] == 'requestId') {
                    filterValue[0].value = filterValue[0].value.toString();
                }
                if (filterValue[0].matchMode.includes('date')) {
                    filterValue[0].value = this.datePipe.transform(filterValue[0].value, 'yyyy-MM-dd 00:00:00');
                }
            }
            if (filterValue[1]?.value != null) {
                if (filterValue[1].matchMode.includes('date')) {
                    filterValue[1].value = this.datePipe.transform(filterValue[1].value, 'yyyy-MM-dd 00:00:00');
                }
                filterBody.push(filterValue[1]);
            }
            if (filterValue[1]?.value != null) {
                filterBody.push(filterValue[1]);
            }
            if (filterBody.length > 0) {
                finalObj[element[0]] = filterBody;
            }
        });
        let compareObj = JSON.stringify(this.filterReqObj) === JSON.stringify(finalObj);
        let str = '';
        const search = this.BulkActionCheckStatusForm.value.textField;
        if (search != null) {
            str = search;
        }
        let reqBody = {};
        if (!compareObj) {
            reqBody['tenantId'] = this.pagesService.tenantId;
            reqBody['page'] = 1;
            reqBody['rows'] = this.pageSize;
            reqBody['serchString'] = str;
            reqBody['filters'] = finalObj;
            reqBody['sortByColumn'] = this.sortByColumn;
            reqBody['sorting'] = this.sorting;
        }
        else {
            reqBody['tenantId'] = this.pagesService.tenantId;
            reqBody['page'] = this.pageNumber;
            reqBody['rows'] = this.pageSize;
            reqBody['serchString'] = str;
            reqBody['filters'] = finalObj;
            reqBody['sortByColumn'] = this.sortByColumn;
            reqBody['sorting'] = this.sorting;
        }

        const data = reqBody;
        if (Object.keys(finalObj).length > 0) {
            this.filterReqObj = finalObj;
            this.customFilterFlag = true;
            this.pagesService.bulkActionCheckStatusPagination(data).subscribe(results => {
                this.oldTotalRec = this.totalRecords;
                const responseData = results.success.result.records;
                this.convertToDateFormat(responseData);
                this.totalRecords = results.success.result.recordsCount;
                event.filteredValue.splice(0, event.filteredValue.length);
                responseData.forEach(element => {
                    event.filteredValue.push(element);
                });

            });
            event.filters = oldFilter;

        }
        else if (Object.keys(finalObj).length == 0) {
            this.totalRecords = this.oldTotalRec;
            if (this.customFilterFlag) {
                this.filterReqObj = {};
                this.customFilterFlag = false;
                let reqBody = {};
                reqBody['tenantId'] = this.pagesService.tenantId;
                reqBody['page'] = 1;
                reqBody['rows'] = this.pageSize;
                reqBody['serchString'] = str;
                reqBody['sorting'] = "descending";
                const data1 = reqBody;
                this.pagesService.bulkActionCheckStatusPagination(data1).subscribe(results => {
                    this.oldTotalRec = this.totalRecords;
                    const responseData = results.success.result.records;
                    this.convertToDateFormat(responseData);
                    this.totalRecords = results.success.result.recordsCount;
                    event.filteredValue.splice(0, event.filteredValue.length);
                    responseData.forEach(element => {
                        event.filteredValue.push(element);
                    });
                });
            }

        }

    }

    removeFilter() {
        this.actionDisable = true;
        this.checkDisable = false;
        this.showChip = false;
        this.BulkActionCheckStatusForm.controls.textField.reset();

    }
    showStatusFilter(status: any) {
        let finalObj = {};
        finalObj['initiatedOn'] = [status];
        let reqBody = {};
        reqBody['tenantId'] = this.pagesService.tenantId;
        reqBody['page'] = this.pageNumber;
        reqBody['rows'] = this.pageSize;
        reqBody['serchString'] = "";
        reqBody['filters'] = finalObj;
        reqBody['sortByColumn'] = this.sortByColumn;
        reqBody['sorting'] = this.sorting;
        const data = reqBody;
        this.tableList = [];
        this.pagesService.bulkActionCheckStatusPagination(data).subscribe(results => {
            const responseData = results.success.result.records;
            this.convertToDateFormat(responseData);
            if (responseData && responseData.length > 0) {
                this.totalRecords = results.success.result.recordsCount;
                this.oldTotalRec = results.success.result.recordsCount;
                this.tableList = responseData;
                this.tableList1 = responseData;
            }
        });

    }
    callUserList() {
        this.gettableList();
    }
    downloadReport(rowData: any) {
        const reqBody = {};
        reqBody['id'] = rowData.id;
        this.messageFlag = true;
        this.pagesService.bulkActionDownloadReport(reqBody).subscribe(
            (response: any) => {


                this.messageFlag = true;
                const blob = new Blob([response.body], { type: 'application/octet-stream' });
                let url = window.URL.createObjectURL(blob);
                let anchor = document.createElement("a");
                anchor.href = url;
                const fileVar1 = rowData.uploadedFileName?.substring(rowData.uploadedFileName.lastIndexOf('.') + 1);
                if (fileVar1 == "xlsx") {
                    anchor.download = rowData.uploadedFileName;
                }
                else {
                    anchor.download = fileVar1 + ".xlsx";
                }
                anchor.click();


                this.messageFlag = true;
                this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'File downloaded duccessfully.' });
                setTimeout(() => {
                    this.messageFlag = false;
                }, 3000);




            }
        );
    }

    convertToDateFormat(responseData: any) {
        responseData.forEach(
            customer => (customer.initiatedOn = this.datePipe.transform(customer.initiatedOn, 'MMM d, y, h:mm:ss a'))
        );
        responseData.forEach(
            customer1 => {
                if (customer1.completedOn.trim().length > 0) {
                    customer1.completedOn = this.datePipe.transform(customer1.completedOn, 'MMM d, y, h:mm:ss a')
                }
                else {
                    customer1.completedOn = null;
                }

            }

        );
    }
    getRangeFilter(filterLabel: any) {
        let reqBody1 = {};
        reqBody1['matchMode'] = "dateAfter";
        reqBody1['operator'] = "and";
        let mDate = this.datePipe.transform(this.myDate, 'yyyy-MM-dd 00:00:00');
        if (filterLabel == "Today") {
            reqBody1['value'] = mDate;
        }
        else if (filterLabel == "Last 10 days") {
            let temp = new Date(mDate);
            temp.setDate(temp.getDate() - 9);
            reqBody1['value'] = this.datePipe.transform(temp, 'yyyy-MM-dd 00:00:00');
        }
        else if (filterLabel == "Last 30 days") {
            let temp = new Date(mDate);
            temp.setDate(temp.getDate() - 29);
            reqBody1['value'] = this.datePipe.transform(temp, 'yyyy-MM-dd 00:00:00');
        }
        else if (filterLabel == "Last 1 year") {
            let temp = new Date(mDate);
            temp.setFullYear(new Date().getFullYear() - 1);
            reqBody1['value'] = this.datePipe.transform(temp, 'yyyy-MM-dd 00:00:00');
        }
        return reqBody1;

    }
}
