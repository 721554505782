<div class="layout-content"
    style="min-height:0vh;box-shadow: none;border:none;padding-bottom: 0px;margin-bottom: auto;">
    <form [formGroup]="TokenGeneratedForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class=" p-col-12">
                <div class="ui-fluid p-formgrid p-grid">
                </div>
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                    <p>Please copy before closing the window, as it cannot be retrieved later.</p>
                </div>
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-left">
                    <input type="text" id="textField_id" name="textField_name" formControlName="select" pInputText
                        placeholder="Generated Token" class="p-inputtext inputtextHeight input-width" />
                    <div class="btnwrap">
                        <button pButton class="p-button-primary" type="button" (click)="copyText()" label="Copy">
                        </button>
                    </div>
                </div><br>
            </div>
        </div>
    </form>
</div>