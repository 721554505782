export const ALERT_MSG_TIME_OUT = 10000;

export const SCENARIO_TYPE_INSTANTANEOUS = 1;
export const SCENARIO_TYPE_PROJECTION = 2;

export const SCENARIO_CLASSIFICATION_REGULATORY = 1;
export const SCENARIO_CLASSIFICATION_INTERNAL = 2;
export const SCENARIO_CLASSIFICATION_ADHOC = 3;

export const EXPANSION_CATEGORY_PREDETERMINED = 1;
export const EXPANSION_CATEGORY_MODEL_DRIVEN = 2;
export const EXPANSION_CATEGORY_BUSINESS_RULE = 3;

export const SCENARIO_DEFINED = 1;
export const SCENARIO_RISK_FACTOR_SELECTION = 2;
export const SCENARIO_SHOCKS = 3;
export const SCENARIO_OUTPUT = 4;
export const SCENARIO_REVIEW_INPROGRESS = 6;
export const SCENARIO_REJECTED = 8;
export const SCENARIO_APPROVED = 7;
export const MODEL_REVIEW_IN_PROGRESS = 24;
export const MODEL_REJECTED = 26;
export const MODEL_CONFIG = 22;
export const MODEL_APPROVED = 25;
export const SELECT_COM_MAXSIZE = 1000;

export const ANALYSIS_TYPE_SCENARIO = 'S';
export const ANALYSIS_TYPE_TIME_SERIES = 'T';
export const NUMBER = 'Number';
export const TEXT = 'Text';
export const DATEFORMAT = 'DateFormat';

export const TAXONOMY_GROUP_ID = 3;
export const BANK_DIMENSION_ID = 1;
export const SERVICE_LINE_DIMENSION_ID = 2;

export const SCENARIO_TABS = [
    {
        id: 1,
        name: 'Scenario Info',
        value: 'scenario/createScenario',
        status: SCENARIO_DEFINED
    },
    {
        id: 2,
        name: 'Risk Factor Sets',
        value: 'scenario/selectRiskFactor',
        status: SCENARIO_RISK_FACTOR_SELECTION
    },
    {
        id: 3,
        name: 'Set Shocks',
        value: 'scenario/scenarioShocks',
        status: SCENARIO_SHOCKS
    },
    {
        id: 4,
        name: 'Review Output',
        value: 'scenario/scenarioOutput',
        status: SCENARIO_OUTPUT
    }
];

export const MODEL_LIBRARY_TABS = [
    {
        id: 1,
        name: 'Model Definition',
        value: 'model/modelDefinition',
        status: 21
    },
    {
        id: 2,
        name: 'Model Configuration',
        value: 'model/modelConfig',
        status: 22
    },
    /*  {
        id: 3,
        name: 'Dependent Risk Factors',
        value: 'model/selectDependentRiskFactors',
        status: 23
    },
    {
        id: 4,
        name: 'Independent Risk Factors',
        value: 'model/selectInDependentRiskFactors',
        status: 24
    },
    {
        id: 5,
        name: 'Data Preparation',
        value: 'model/dataPreparation',
        status: 25
    }, */

    {
        id: 3,
        name: 'Review Model',
        value: 'model/modelReview',
        status: 23
    }
];

export const ANALYSIS_TABS = [
    {
        name: 'Scenario',
        value: 'S'
    },
    {
        name: 'Time Series',
        value: 'T'
    }
];

export const ANALYSIS_TYPE_COMPARISION = 1;
export const ANALYSIS_TYPE_PROPAGATION = 2;
export const ANALYSIS_TYPE_PREDICTION = 3;
export const ANALYSIS_TYPE_RATE_CURVE = 4;
export const ANALYSIS_TYPE_TIMESERIES = 5;
export const ANALYSIS_TYPE_HISTOGRAM = 6;
export const ANALYSIS_TYPE_ACF = 7;
export const ANALYSIS_TYPE_BOX = 8;
export const ANALYSIS_TYPE_RATE_CORRELATION = 9;

export const MODEL_LIB_RISK_FACTOR_TYPE_DEPENDENT = 'DEPENDENT';
export const MODEL_LIB_RISK_FACTOR_TYPE_INDEPENDENT = 'INDEPENDENT';

export const MODEL_METHODOLOGY_TYPE_CHALLENGER = 'CHALLENGER';
export const MODEL_METHODOLOGY_TYPE_CHAMPION = 'CHAMPION';

export const METHODOLOGY_INTERPOLATION = 13;
export const METHODOLOGY_PROXY = 14;
export const METHODOLOGY_USER_DEFINED = 15;
export const METHODOLOGY_QUANTILE = 16;

export const PROJECTION = 'Projection';
export const PROJECTION_ID = '2';

export const PREDETERMINED_LABEL = 'pre determined';
export const MODELDRIVEN_LABEL = 'model driven';
export const BUSINESSRULE_LABEL = 'business rule driven';
export const INTERPOLATION_LABEL = 'interpolation';
export const PROXY_LABEL = 'proxy';
export const USERDEFINED_LABEL = 'user defined';
export const QUANTILE_LABEL = 'quantile';
export const PCAGROUP_LABEL = 'pca group';
export const TRANSFORMATION_LOG = 1;
export const TRANSFORMATION_ABS = 2;
export const UNIT_PERCENTILE = '%';
export const UNIT_BPS = 'bps';

export const ASSUMPTION_TESTING = 'ASSUMPTION_TESTING';
export const ASSUMPTION_TESTING_TABLE = 'ASSUMPTION_TESTING_TABLE';
export const COEFFICIENT_STABILITY = 'COEFFICIENT_STABILITY';
export const PERFORMANCE_STABILITY_TRAINING = 'PERFORMANCE_STABILITY_TRAINING';
export const PERFORMANCE_STABILITY_TESTING = 'PERFORMANCE_STABILITY_TESTING';
export const IN_SAMPLE_FIT = 'IN_SAMPLE_FIT';
export const BACKTESTING_TRAINING = 'BACKTESTING_TRAINING';
export const BACKTESTING_TESTING = 'BACKTESTING_TESTING';

export const PAGE_SIZE = 10;

export const MODEL_GRAPH_INDEX_VS_NAME_MAP = {
    2: ASSUMPTION_TESTING_TABLE,
    3: ASSUMPTION_TESTING,
    5: COEFFICIENT_STABILITY,
    6: PERFORMANCE_STABILITY_TRAINING,
    7: PERFORMANCE_STABILITY_TESTING,
    8: IN_SAMPLE_FIT,
    9: BACKTESTING_TRAINING,
    10: BACKTESTING_TESTING
};
export const EXP_CATID_MODELDRIVEN = 2;
export const GLOBAL_EXP_MSG = 'Something went wrong due to network/conection issue, Please try after few seconds.';
export const MODEL_ERR_TEXT = 'unsatisfied,insignificance';
export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_FREQUENCY_VAL = '1';
export const TRANS_ERR_MSG =
    'Timeseries data for below riskfactors have negative values, hence log transformation cannot be applied. Please change the transformation type.';
export const DEFAULT_IP_LINEAR_ID = '1';
export const FREQ_ID_DAILY = 1;
export const FREQ_ID_WEEKLY = 2;
export const FILE_UPLOADED = 'File saved to server';
export const FILE_FINGERPRINT_STAGE = 'File staged';
export const FILE_UPLOAD_IN_PROGRESS = 'File upload in progress';
export const FILE_SUCCESSFULLY_SAVED = 'File successfully uploaded';
export const FILE_VALIDATION_STAGE = 'Validation in progress';
export const DISABLED = 'Disabled';
export const UNUSED = 'Unused';
export const COMPLETED = 'Completed';
export const FILE_APPROVED = 'File approved';
export const FILE_REJECTED = 'File rejected';
export const FILE_UPLOAD_FAILED = 'File upload is failed';
export const FILE_PARTIALLY_UPLOADED = 'File partially uploaded';
export const TEXT_TYPE = 'A-Za-z0-9@ �#$-_';
export const WARNING = 'Warning';
export const ERROR = 'Error';
export const INFO = 'Info';
export const FILE_LEVEL = 'Config';
export const FILE = 'File';
export const VALIDATION_RULE = 'validationRule';
export const CUSTOM_VALIDATIONS = ' Validations';
export const VALIDATION_EDITOR = 'Validation Editor';
export const EXISTS = 'exists';
export const SUCCESS = 'Success';
export const ALLRULES = 'allRules';
export const FAILED = 'Failed';
export const FILE_TYPE_CONFIG = 'config';
export const FILE_TYPE_NON_CONFIG = 'nonConfig';
export const INVALID_FORMULA = 'Invalid formula';
export const CONFIG_TYPE = 'Config Type';
export const MATCH_LOGIC = 'Matching % Logic';
export const VALIDATION_ENGINE = 'Validation Engine';
export const CONFIG_TYPE_CUSTOM = 'Custom';
export const CONFIG_TYPE_TEMPLATE = 'Template';
export const CONFIG_TYPE_GENERIC = 'Generic';
export const ALLOW = 'Allow';
export const DENY = 'Deny';
export const INHERIT_ALLOW = 'Inherit (Allow)';
export const INHERIT_DENY = 'Inherit (Deny)';
export const MULTIPLE = 'Multiple';
export const INHERIT_MULTIPLE = 'Inherit (Multiple)';
export const BANK = 'bank';
export const SERVICELINE = 'serviceLine';
export const ROLE = 'role';
export const ENTITLEMENTSTATUS = 'entitlementStatus';
export const IMPORT = 'import';
export const INHERIT = 'Inherit';
export const USER = 'user';
export const SERVICE_LINE = 'serviceLine';
export const ENTITLEMENT = 'entitlement';
export const COMPONENT = 'component';
export const BANK_SERVICE_LINE = 'Bank & Service Line';
export const ACTIVE = 'Active';
export const MNS = 'MNS';
export const INACTIVE = 'Inactive';
export const GLOBAL = 'global';
export const OVERRIDE = 'override';
export const ACTIVATE = 'Activate';
export const DEACTIVATE = 'Deactivate';
export const LOCKED = 'Locked';
export const ARCHIVED = 'Archived';
export const DELETE = 'Delete';
export const DELETE_PERMANENTLY = 'Delete Permanently';
export const UNLOCK = 'Unlock';
export const ARCHIVE = 'Archive';
export const ENTITLEMENT_STATUS = 'ENTITLEMENT_STATUS';
export const ENTITLEMENT_NAME = 'ENTITLEMENT_NAME';
export const OWNER_ENTITLEMENT = 'OWNER_ENTITLEMENT';
export const ENTITLEMENT_NOTIFICATIION = 'ENTITLEMENT_NOTIFICATIION';
export const ENTITLEMENT_DEPENDENT = 'ENTITLEMENT_DEPENDENT';
export const ENTITLEMENT_COMPONENT = 'ENTITLEMENT_COMPONENT';
export const ROLE_STATUS = 'ROLE_STATUS';
export const PENDING = 'Pending';
export const DEFAULT_ROLE = 'User Management';
export const SERVICE_LINE_TAXONOMY = 'Service Line Taxonomy';
export const OVERRIDDEN = 'Overridden';
export const INHERITED = 'Inherited';
export const NUMERIC = 'numeric';
export const DROPDOWN = 'dropdown';
export const ONE = 1;
export const TWO = 2;
export const TEN = 10;
export const ZERO = 0;
export const INTERNAL_EMAIL_SUFFIXES = ['COALITION', 'CRISIL', 'EXT-CRISIL', 'GREENWICH'];
export const CREATE_USER_FILE_EXTENSIONS = [
    'application/vnd.ms-excel',
    'image/jpeg',
    'image/png',
    'application/vnd.ms-outlook',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'text/plain',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
];
export const EMAIL_REGEXP = /^(?=.{1,64}@)[A-Za-z0-9_-]+(\.[A-Za-z0-9_-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,})$/;//NOSONAR
export const APPROVED = 'Approved';
export const REJECTED = 'Rejected';
export const REVOKED = 'Revoked';
export const IPRANGE_REGEXP = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;//NOSONAR
export const ADMS = 'ADMS';
export const CSV_FILE_EXTENSIONS = ['text/csv', 'application/csv', 'application/vnd.ms-excel', 'text/x-csv', 'application/x-csv'];
export const STATUS = 'status';
export const APPROVAL = 'approval';
export const SOURCE_ROLE = 'source';
export const RAISED_INDEPENDENT = 'Raised Indeoendently';
export const USER_NAME_REGEXP = /^[a-zA-Z0-9-_.]*$/;
export const ORGANIZATION_NAME_REGEXP = /^[a-zA-Z0-9-_.]+( [a-zA-Z0-9-_.]+)*$/;
export const INTERNAL = 'internal';
export const EXTERNAL = 'External';
export const NOT_APPROVED = 'Not Approved';
export const PENDING_FOR_ME = 'Pending for me';
export const ENTER_KEY_CODE = 13;
export const INTERNAL_USER_TYPE = 'Internal';
export const EXTERNAL_USER_TYPE = 'External';
export const DEFAULT_ONE = '1';
export const ENTITLEMENT_ID_REGEXP = ['-', '.', 'e', 'E', '+'];
export const DELETED = 'Deleted';
export const BULK = 'BULK';
export const SINGLE = 'SINGLE';


export const IDENTITY_PROVIDER = "IDENTITY_PROVIDER";
export const AZURE_IDENTITY_PROVIDER = "Azure Identity Provider";

export const CONFIG_LAVEL_GLOBAL = "GLOBAL";
export const CONFIG_LAVEL_TENANT = "TENANT";
export const CONFIG_LAVEL_TENANT_APPLICATION = "TENANT_APPLICATION";
export const AD_USER = "4f026b3c-159d-11ee-be56-0242ac120002";
export const DB_USER = "5b1ce0a0-159d-11ee-be56-0242ac120002";

export const UNCHECKED = 'Unchecked';
export const CHECKED = 'Checked';
export const ADD = 'add';
export const REMOVE = 'remove';
export const EDIT = 'edit';
export const ACCEPT = 'Accept';
export const CANCEL = 'Cancel';
export const REJECT = 'Reject';
export const APPROVE = 'Approve';
export const APPROVE_ALL = 'Approve All';
export const REJECT_ALL = 'Reject All';
export const REVOKE = 'Revoke';
export const SEPARATOR = '-';
export const USER_MANAGEMENT_COMPONENT_ID = 1;
export const COMPONENT_ID_REGEXP = ['-', '.', 'e', 'E', '+'];
export const COMPONENT_NAME_REGEXP = /^[a-zA-Z0-9-_.]+( [a-zA-Z0-9-_.]+)*$/;


export const FINE_GRAINED = 'Fine Grained';
export const COURSE_GRAINED = 'Course Grained';
export const IPRange = 'Iprange';
export const TAXONOMY = 'Taxonomy';
export const DATAMART = 'DataMart';
export const MAP_USER = 'Map User';
export const UNMAP_USER = 'UnMap User';
export const MAP_ENDPOINT = 'Map Endpoint';
export const UNMAP_ENDPOINT = 'UnMap Endpoint';
export const MAP_ACCOUNT = 'Map Account';
export const UNMAP_ACCOUNT = 'UnMap Account';




export const SSO_ALIAS_NAME = 'SSO Alias Name';
export const IDENTITY_PROVIDER_USERNAME = 'Identity Provider UserName';
export const SSO = 'SSO';
