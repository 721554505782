import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AccountService } from 'app/core/auth/account.service';
import { CIAM_UI_SERVICE_API_URL, CIAM_UM_SERVER_API_URL } from 'app/app.constants';
import { environment } from 'src/environments/environment';
import { KeycloakService } from 'keycloak-angular';
@Injectable({ providedIn: 'root' })
export class PagesService {
  getAttributeListByApplication(data: {}) {
    throw new Error('Method not implemented.');
  }
  rebacFlag = environment.REBAC_UM_ENTITLEMENT_FLAG + "";
  getListGroupsbySubscription() {
    throw new Error('Method not implemented.');
  }
  public createTenantSave: boolean = false;
  redirect = environment.IMAGE_PATH;
  clientID = environment.KEY_CLOAK_CLIENT_ID;
  public userId: any;
  public tenantId: any;
  public pageSuperAdminFlag = false;
  public pageCiamAdminFlag = false;
  public pageTenantAdminFlag = false;
  public pageAppAdminFlag = false;
  public pageCiamAuthFlag = false;
  public pageImportFlag = false;
  public pagePlanAdminFlag = false;
  public ciamHomeFlag = false;
  public pageWfApproverFlag = false;
  public readonlyFlag = false;
  public tenantName: any;
  public pageRebacFlag: string = "false";
  public realm: any;
  public applicationId: any = [];
  public isMailAutoTrigger: string;
  public userManagementReadOnlyFlag;
  public authorizationReadOnlyFlag;
  public tenantManagementReadOnlyFlag
  public applicationOnboardingReadOnlyFlag;
  public accountManagementReadOnlyFlag;
  public planAdminReadOnlyFlag;
  public roleUserManagementAccess;
  public authorizationAccess;
  public aprrovalReadOnlyFlag;
  public userStorage;
  public hasTenantAdminRole;
  public attributeTreeEnable = false;

  constructor(private accountService: AccountService, private http: HttpClient, private keycloakService: KeycloakService) {
    this.accountService.identity().subscribe(account => {
      if (account) {
        this.userId = account.login;
        console.log("I am here in pageeeeeeeeeeeee");
        console.log(this.userId);
      }
      if (localStorage?.getItem("navbarFlag") == null) {
        // in page service if condition
        //this.tenantId = environment.CIAM_UI_TENANT_ID;
        this.tenantId = environment.CIAM_UI_TENANT_ID;
        localStorage.setItem("tenant", this.tenantId);
      }
      if (localStorage?.getItem("navbarFlag") == 'Yes') {
        this.tenantId = localStorage.getItem("tenant");
      }

    });
  }

  onAddUser(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/user/create-user`, formValues);
  }
  onMirrorUser(formValues: any, data: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/user/create-user/tenantid/${this.tenantId}`, formValues);
  }
  gettableDataUserListing(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/user/get-users-by-tenantid/tenantid/${this.tenantId}`, {
      headers: headersList,
    });
  }

  onViewUserPageLoad(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/user/user-by-id/${data}`, { headers: headersList });
  }

  onCreateRoles(formValues: any, data: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/role/create-role/tenantis/${this.tenantId}`, formValues);
  }

  onAddUserGroup(formValues: any, data: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/group/create-group/${this.tenantId}`, formValues);
  }

  gettableDataRoles(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/roles/get-roles-by-tenantid/tenantid/${this.tenantId}`, {
      headers: headersList,
    });
  }

  gettableDataListGroups(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/groups/groups-by-tenantid/tenantid/${this.tenantId}`, {
      headers: headersList,
    });
  }

  getuserTableDataViewRole(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(
      CIAM_UI_SERVICE_API_URL + `/user/get-users-by-tenantid-roleid/tenantid/${this.tenantId}/roleid/${data}?page=1&rows=10000`,
      {
        headers: headersList,
      }
    );
  }

  onViewRolePageLoad(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/roles/id/${data}`, { headers: headersList });
  }

  getuserTableDataViewGroup(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/user/get-user-by-tenantid-groupid/tenantid/${this.tenantId}/groupid/${data}?page=1&rows=10000`, {
      headers: headersList,
    });
  }

  onViewGroupPageLoad(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/groups/id/${data}`, { headers: headersList });
  }

  onEditUser(formValues: any): Observable<any> {
    return this.http.put(CIAM_UI_SERVICE_API_URL + `/user/edit-user`, formValues);
  }

  onMirrorUserPageLoad(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/user/user-by-id/${data}`, { headers: headersList });
  }
  onEditUserPageLoad(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/user/user-by-id/${data}`, { headers: headersList });
  }

  onEditUserGroup(formValues: any, data: any): Observable<any> {
    return this.http.put(CIAM_UI_SERVICE_API_URL + `/group/edit-group/tenantid/${this.tenantId}/groupid/${data}`, formValues);
  }

  onEditGroupPageLoad(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/groups/id/${data}`, { headers: headersList });
  }

  onEditRole(formValues: any, data: any): Observable<any> {

    return this.http.put(CIAM_UI_SERVICE_API_URL + `/role/edit-role/tenantid/${this.tenantId}/roleId/${data}`, formValues);
  }

  onEditRolePageLoad(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/roles/id/${data}`, { headers: headersList });
  }

  onInviteUser(formValues: any, data: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/user/invite-users/tenantid/${this.tenantId}`, data);
  }
  onSaveAssignRolesToUser(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/user/assignRolesToUser`, formValues);
  }

  onSaveAssignGroup(formValues: any, data: any): Observable<any> {
    return this.http.post(
      CIAM_UI_SERVICE_API_URL + `/group/assign-group-to-user/tenantid/${this.tenantId}/userid/${data}`,
      formValues
    );
  }
  onSaveAssignUsersToRole(formValues: any, data: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/user/assignUsersToRole/tenantId/${this.tenantId}/roleid/${data}`, formValues);
  }
  onSaveAssignGroupsToRole(formValues: any, data: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/group/assingGroupsToRole/tenantid/${this.tenantId}/roleid/${data}`, formValues);
  }
  onSaveAssignRolesToGroup(formValues: any): Observable<any> {
    return this.http.post(
      CIAM_UI_SERVICE_API_URL + `/group/assingRolesToGroup`,
      formValues
    );
  }
  AssignUsersToRoles(formValues: any, data: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/user/assignUsersToRole/tenantId/${this.tenantId}/roleid/${data}`, formValues);
  }
  AssignUsersToGroups(formValues: any): Observable<any> {
    return this.http.post(
      CIAM_UI_SERVICE_API_URL + `/user/assign-user-to-group`,
      formValues
    );
  }

  onCheckEmailId(formValues: any, data: any): Observable<any> {
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/user/check-email-availability/tenantid/${this.tenantId}/${data}`, formValues);
  }
  onCheckGroupName(formValues: any, data: any): Observable<any> {
    return this.http.get(
      CIAM_UI_SERVICE_API_URL + `/groups/check-groupname-availability/tenantid/${this.tenantId}/${data}`,
      formValues
    );
  }
  onCheckRoleName(formValues: any, data: any): Observable<any> {
    return this.http.get(
      CIAM_UI_SERVICE_API_URL + `/roles/check-rolename-availability/tenantid/${this.tenantId}/${data}`,
      formValues
    );
  }

  Deactivate(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/user/deactivate-users`, formValues);
  }
  ActivateUser(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/user/activate-users`, formValues);
  }
  //unlock user
  unlockUser(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/user/unlock-users/tenantid/${this.tenantId}`, formValues);
  }
  getRoleByGroup(data: any): Observable<any> {
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/groups/get-roles/tenantid/${this.tenantId}/groupid/${data}`);
  }
  getGroupByRole(data: any): Observable<any> {
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/groups/get-groups/tenantid/${this.tenantId}/roleid/${data}`);
  }
  //User&Group Mapping
  getUserListing(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/user/get-all/tenantId/${this.tenantId}`, {
      headers: headersList,
    });
  }
  getApplicationListMapping(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/application/get-applications-by-tenantid/tenantId/${this.tenantId}`, {
      headers: headersList,
    });
  }

  getAttributeByApplication(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/application/getAttributesByApplicationId/tenantId/${this.tenantId}/applicationId/${data}`, {
      headers: headersList,
    });
  }

  getApplicationListMappingbyUserId(data: any): Observable<any> {
    console.log(this.userId);
    console.log(data);
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/application/get-applications-by-userid/tenantId/${this.tenantId}/userId/${data}`, {
      headers: headersList,
    });
  }
  getApplicationListMappingbyUserGroupId(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/application/get-applications-by-usergroupid/tenantId/${this.tenantId}/userGroupId/${data.gID}`, {
      headers: headersList,
    });
  }
  //UserMapping
  getUserMappedDetails(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/application/get-mapped-attributes/tenantId/${this.tenantId}/applicationId/${data.appId}/attributeId/${data.attId}/userid/${data.uId}`, {
      headers: headersList,
    });
  }
  getUserUnMappedDetails(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/application/get-attributes/tenantId/${this.tenantId}/applicationId/${data.appId}/attributeId/${data.attId}/userid/${data.uId}`, {
      headers: headersList,
    });
  }
  getUserGroupMappedDetails(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/application/get-mapped-attributes-groups/tenantId/${this.tenantId}/applicationId/${data.appId}/userid/${data.uId}`, {
      headers: headersList,
    });
  }
  getUserGroupUnMappedDetails(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/application/get-attributes-groups/tenantId/${this.tenantId}/applicationId/${data.appId}/userid/${data.uId}`, {
      headers: headersList,
    });
  }

  onSaveUserAttributeMapping(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/AttributeResource/map-unmap-attributes-to-user`,
      formValues
    );
  }
  onSaveUserAttributeGroupMapping(formValues: any, data: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/AttributeResource/map-unmap-attribute-group-to-user/tenantid/${this.tenantId}/applicationid/${data.appId}/userid/${data.uId}`,
      formValues
    );
  }

  //Group Mapping
  getGroupMappedAttributes(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/application/getMapped-AttributesByUserGroup/tenantId/${this.tenantId}/applicationId/${data.appId}/attributeId/${data.attId}/userGroupid/${data.groupId}`, {
      headers: headersList,
    });
  }

  getGroupUnMappedAttributes(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/application/getUnmapped-AttributesByUserGroup/tenantId/${this.tenantId}/applicationId/${data.appId}/attributeId/${data.attId}/userGroupid/${data.groupId}`, {
      headers: headersList,
    });
  }

  getGroupMappedAttributeGroups(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/application/getMapped-Attributes-groupsByUserGroup/tenantId/${this.tenantId}/applicationId/${data.appId}/userGroupid/${data.groupId}`, {
      headers: headersList,
    });
  }

  getGroupUnMappedAttributeGroups(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/application/getUnmapped-Attributes-groupsByUserGroup/tenantId/${this.tenantId}/applicationId/${data.appId}/userGroupid/${data.groupId}`, {
      headers: headersList,
    });
  }

  onSaveGroupAttributeMapping(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/AttributeResource/map-unmap-attribute-from-usergroup`,
      formValues
    );
  }
  onSaveGroupAttributeGroupMapping(formValues: any, data: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/AttributeResource/map-unmap-attributegroup-to-usergroup/tenantid/${this.tenantId}/applicationid/${data.appId}/groupId/${data.groupId}`,
      formValues
    );
  }

  //Application Resources
  getComponentApplication(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/components/get-all/tenantid/${this.tenantId}/applicationid/${data.appId}`, {
      headers: headersList,
    });
  }
  getMethodApplication(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/methods/get-all`, {
      headers: headersList,
    });
  }
  getResourceListing(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/endpoint/get-resourceEndpoints/tenantId/${this.tenantId}/applicationId/${data.appId}/componentId/${data.componentId}`, {
      headers: headersList,
    });
  }
  onSaveCreateResource(formValues: any, data: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/endpoints/create/tenantid/${this.tenantId}/applicationid/${data.appId}/componentid/${data.componentId}`,
      formValues
    );
  }
  onSaveCreateattributeGroup(formValues: any, data: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/attributegroup/create-attribute-group/tenantId/${this.tenantId}/applicationId/${data}`,
      formValues
    );
  }
  getViewUserRoles(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/roles/get-roles-by-userid/tenantid/${this.tenantId}/${data}?page=1&rows=10000`, { headers: headersList });
  }
  getViewUserGroups(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/groups/get-groups-by-tenantid-userid/tenantid/${this.tenantId}/userid/${data}?page=1&rows=10000`, { headers: headersList });
  }
  getApplicationRole(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/application/getApplicationRolesForResourceEndpointAndRole/tenantId/${this.tenantId}/applicationId/${data}`, {
      headers: headersList,
    });
  }
  getResourceUnMappedAttributes(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/endpoint/get-unmappedEndpoints/tenantId/${this.tenantId}/applicationId/${data.appId}/componentId/${data.compId}/roleId/${data.roleId}`, {
      headers: headersList,
    });
  }
  getResourceMappedAttributes(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/endpoint/get-mappedEndpoints/tenantId/${this.tenantId}/applicationId/${data.appId}/componentId/${data.compId}/roleId/${data.roleId}`, {
      headers: headersList,
    });
  }
  onSaveResourceMappingMapping(formValues: any, data: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/endpoints/map-unmap-endpoints-with-role/tenantid/${this.tenantId}/applicationid/${data.appId}/componentid/${data.compId}/roleid/${data.roleId}`,
      formValues
    );
  }
  onSaveEditResource(formValues: any, data: any): Observable<any> {
    return this.http.put(CIAM_UI_SERVICE_API_URL + `/endpoints/update/tenantid/${this.tenantId}/endpointid/${data.endpointID}`,
      formValues
    );
  }
  onSaveDeleteResource(data: any): Observable<any> {
    return this.http.delete(CIAM_UI_SERVICE_API_URL + `/endpoints/delete/tenantid/${this.tenantId}/applicationid/${data.app}/componentid/${data.com}/endpointid/${data.endpointID}`
    );
  }

  //Attribute&Group
  getAttributeGroupListing(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/attributegroup/get-attributesGroups-listing/tenantId/${this.tenantId}/applicationId/${data}`, { headers: headersList, });
  }
  deleteAttributeGroup(data: any): Observable<any> {
    return this.http.delete(CIAM_UI_SERVICE_API_URL + `/attributegroup/delete-attribute-group/tenantId/${this.tenantId}/applicationId/${data.appId}/attributeGroupId/${data.groupId}`
    );
  }
  EditAttributeGroup(formValues: any, data: any): Observable<any> {
    return this.http.put(CIAM_UI_SERVICE_API_URL + `/attributegroup/edit-attribute-group/tenantId/${this.tenantId}/applicationId/${data.appId}/attributeGroupId/${data.groupId}`,
      formValues
    );
  }

  getAttributeResourceListing(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/Resource/get-resources-by-tenantId-and-applicationId/tenantId/${this.tenantId}/applicationId/${data}`, { headers: headersList, });
  }
  getAllAttributeGroups(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/attributegroup/get-all-attributesgroups/tenantId/${this.tenantId}/applicationId/${data}`, { headers: headersList, });
  }
  onSaveCreateattribute(formValues: any, data: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/Resource/create-resource/tenantid/${this.tenantId}/applicationId/${data}`,
      formValues
    );
  }
  EditAttributeResource(formValues: any, data: any): Observable<any> {
    return this.http.put(CIAM_UI_SERVICE_API_URL + `/Resource/edit-resource/tenantid/${this.tenantId}/applicationId/${data.appId}/attributeResourceId/${data.resourceId}`,
      formValues
    );
  }
  deleteAttributeResource(data: any): Observable<any> {
    return this.http.delete(CIAM_UI_SERVICE_API_URL + `/Resource/delete-resource/tenantid/${this.tenantId}/applicationId/${data.appId}/attributeResourceId/${data.resourceId}`
    );

  }
  AttributeGroupListing(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/attributegroup/get-all-attributesgroups/tenantId/${this.tenantId}/applicationId/${data}`, { headers: headersList, });
  }

  getAttributeResourceUnMappedAttributes(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/application/get-unmapped-attributes/tenantid/${this.tenantId}/applicationid/${data.appId}/attribute-groupid/${data.groupID}`, {
      headers: headersList,
    });
  }
  getAttributeResourceMappedAttributes(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/application/get-mapped-attributes/tenantid/${this.tenantId}/applicationid/${data.appId}/attribute-groupid/${data.groupID}`, {
      headers: headersList,
    });
  }
  onSaveAttributeResourceMappingMapping(formValues: any, data: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/AttributeResource/map-unmap-attribute/tenantid/${this.tenantId}/applicationid/${data.appId}/groupId/${data.groupID}`,
      formValues
    );
  }
  deleteGroup(data: any): Observable<any> {
    return this.http.delete(CIAM_UI_SERVICE_API_URL + `/group/delete-group/tenantid/${this.tenantId}/groupid/${data}`
    );
  }
  deleteRole(data: any): Observable<any> {
    return this.http.delete(CIAM_UI_SERVICE_API_URL + `/role/delete-role/tenantid/${this.tenantId}/roleid/${data}`
    );
  }
  //ApplicationRole User Mapping
  getUnmappedApplicationRolesByUserID(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/roles/get-unmapped-applicationRolesByUsingUserId/tenantId/${this.tenantId}/applicationId/${data.appId}/userId/${data.uId}`, {
      headers: headersList,
    });
  }
  getMappedApplicationRolesByUserID(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/roles/get-mapped-applicationRolesByUsingUserId/tenantId/${this.tenantId}/applicationId/${data.appId}/userId/${data.uId}`, {
      headers: headersList,
    });
  }
  onSaveApplicationRoleUserMapping(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/user/map-unmap-applicationRole-to-user`,
      formValues
    );
  }

  //ApplicationRole Group Mapping
  getGroupUnmappedApplicationRoles(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/roles/get-unmapped-applicationRolesByUsingGroupId/tenantId/${this.tenantId}/applicationId/${data.appId}/groupId/${data.groupId}`, {
      headers: headersList,
    });
  }
  getGroupMappedApplicationRoles(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/roles/get-mapped-applicationRolesByUsingGroupId/tenantId/${this.tenantId}/applicationId/${data.appId}/groupId/${data.groupId}`, {
      headers: headersList,
    });
  }

  onSaveGroupApplicationRole(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/group/map-unmap-applicationRole-to-userGroup`,
      formValues
    );
  }
  getUserListingExD(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/user/get-all-active-users/tenantId/${this.tenantId}`, {
      headers: headersList,
    });
  }
  getUserDetailsByUserName(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/user/get-user-details/tenantid/${this.tenantId}/username/${data}`, {
      headers: headersList,
    });
  }

  //Security
  onSaveGenerateToken(formValues: any, data: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/group/map-unmap-applicationRole-to-userGroup/tenantid/${this.tenantId}/applicationId/${data.appId}/userGroupId/${data.groupId}`,
      formValues
    );
  }
  deleteToken(data: any): Observable<any> {
    return this.http.delete(CIAM_UI_SERVICE_API_URL + `/role/delete-role/tenantid/${this.tenantId}/roleid/${data}`
    );
  }
  forcelogoff(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/user/force-logoff/tenantid/${this.tenantId}/userId/${data}`, {
      headers: headersList,
    }
    );
  }
  onCheckAttributeGroupName(formValues: any, data: any): Observable<any> {
    return this.http.get(
      CIAM_UI_SERVICE_API_URL + `/application/checkAvailability-application-attributes-groupname/tenantid/${this.tenantId}/attributeGroupName/${data}`,
      formValues);
  }
  onCheckResourceName(formValues: any, data: any): Observable<any> {
    return this.http.get(
      CIAM_UI_SERVICE_API_URL + `/endpoint/checkAvailability-application-resource-name/applicationId/${data.appId}/resourceName/${data.resourceName}`, formValues
    );
  }
  onCheckAttributeName(formValues: any): Observable<any> {
    return this.http.post(
      CIAM_UI_SERVICE_API_URL + `/Resource/checkAvailability-application-attributes-name/tenantid/${this.tenantId}`,
      formValues);
  }
  getCountryCode(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/lookups/country-codes/get-all`, {
      headers: headersList,
    });
  }
  getPasswordPolicy(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/passwordpolicy/get-password-policy/tenantid/${this.tenantId}`, {
      headers: headersList,
    });
  }
  validatePasswordPolicy(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/passwordpolicy/validateNewPasswordPolicy/tenantid/${this.tenantId}`,
      formValues
    );
  }
  setNewPassword(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/user/set-new-password/tenantid/${this.tenantId}`,
      formValues
    );
  }
  getApplicationVersion(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/host-application/get-all-host-applications/label-value`, {
      headers: headersList,
    });
  }
  getComponentListing(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/applicationcomponents/get-application-component/applicationId/${data}`, { headers: headersList, });
  }
  getComponentTypeList(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/lookups/component-types/get-all`, {
      headers: headersList,
    });
  }
  getComponentClientProtocolList(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/lookups/client-protocal/get-all`, {
      headers: headersList,
    });
  }
  getComponentAccessTypeList(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/lookups/access-type/get-all`, {
      headers: headersList,
    });
  }
  onSaveCreateComponent(formValues: any, data: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/applicationcomponents/create-application-component/applicationId/${data}`,
      formValues
    );
  }
  EditComponent(formValues: any, data: any): Observable<any> {
    return this.http.put(CIAM_UI_SERVICE_API_URL + `/applicationcomponents/edit-applcation-component/applicationid/${data.appId}/applicationComponentId/${data.id}`,
      formValues
    );
  }

  //Application Onboarding

  //Application tab
  getHostApplicationlist(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/host-application/get-all-host-applications`, {
      headers: headersList,
    });
  }
  getHostApplicationVersion(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/host-application-version/get-all`, {
      headers: headersList,
    });
  }
  onSaveCreateHostApplication(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/host-application/create-host-application`,
      formValues
    );
  }

  onSaveEditHostApplication(formValues: any, data: any): Observable<any> {
    return this.http.put(CIAM_UI_SERVICE_API_URL + `/host-application/edit-host-application/applicationid/${data.applicationId}`,
      formValues
    );
  }
  onCheckApplicationName(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.post(
      CIAM_UI_SERVICE_API_URL + `/host-application/check-host-application-name-availability/application-name/${data.appId}`, {
      headers: headersList,
    });
  }

  onCheckApplicationNameVersionId(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(
      CIAM_UI_SERVICE_API_URL + `/host-application/check-host-application-name-availability/application-name/${data.appId}?versionId=${data.versionId}`, {
      headers: headersList,
    });
  }

  //Attributetype tab
  getAttributeTypeListing(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/applicationattributes/get-application-attributes/applicationId/${data}`, { headers: headersList, });
  }

  onSaveCreateAttributeType(formValues: any, data: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/applicationattributes/create-application-attribute/applicationId/${data.applicationId}`,
      formValues
    );
  }
  onSaveEditAttributeType(formValues: any, data: any): Observable<any> {
    return this.http.put(CIAM_UI_SERVICE_API_URL + `/applicationattributes/edit-applcation-attribute/applicationid/${data.applicationId}/applicationAttributeId/${data.attributeId}`,
      formValues
    );
  }
  onCheckAttributetypecode(formValues: any): Observable<any> {
    return this.http.post(
      CIAM_UI_SERVICE_API_URL + `/applicationattributes/checkAvailability-application-attributes-code`,
      formValues
    );
  }
  onCheckAttributetypename(formValues: any): Observable<any> {
    return this.http.post(
      CIAM_UI_SERVICE_API_URL + `/applicationattributes/checkAvailability-application-attributes-name`,
      formValues
    );
  }

  //Application Roles

  getApplicationRoleListing(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/host-application-role/get-hostapplication-roles-by-applicationid/${data}`, { headers: headersList, });
  }

  onSaveCreateApplicationRole(formValues: any, data: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/host-application-role/create-application-role/host-applicationid/${data}`,
      formValues
    );
  }

  onCheckApplicationRoleName(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(
      CIAM_UI_SERVICE_API_URL + `/host-application-role/check-host-application-rolename-availability/applicationid/${data.appId}/rolename/${data.rolename}`, {
      headers: headersList,
    });
  }
  onSaveEditApplicationRole(formValues: any, data: any): Observable<any> {
    return this.http.put(CIAM_UI_SERVICE_API_URL + `/host-application-role/edit-application-role/host-application-roleid/${data}`,
      formValues
    );
  }
  getTenantList(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(
      CIAM_UI_SERVICE_API_URL + `/tenant/get-all`, {
      headers: headersList,
    });
  }
  onSaveCreateTenant(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/tenants/create`,
      formValues
    );
  }

  onCheckTenantName(formValues: any): Observable<any> {
    return this.http.post(
      CIAM_UI_SERVICE_API_URL + `/tenants/check-tenant-name-availability`,
      formValues
    );
  }
  onSaveTenantOwnerDetails(formValues: any, data: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/tenants/create-tenant-owner/tenantid/${data}`,
      formValues
    );
  }
  onSaveEditTenantOwnerDetails(formValues: any, data: any): Observable<any> {
    if (data.ownerId == null) {
      return this.http.put(CIAM_UI_SERVICE_API_URL + `/tenants/edit-tenant-owner/tenantid/${data.tenantId}`,
        formValues
      );
    }
    else {
      return this.http.put(CIAM_UI_SERVICE_API_URL + `/tenants/edit-tenant-owner/tenantid/${data.tenantId}?ownerId=${data.ownerId}`,
        formValues
      );
    }
  }
  onSaveEditTenant(formValues: any): Observable<any> {
    return this.http.put(CIAM_UI_SERVICE_API_URL + `/tenants/edit-tenant`,
      formValues
    );
  }
  onTenantByTenantId(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(
      CIAM_UI_SERVICE_API_URL + `/tenants/tenant-by-id/${data}`, {
      headers: headersList,
    });
  }
  inviteEmailAvailability(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/user/invite-email-availability`,
      formValues
    );
  }

  //Access Detail View User Screen
  accessDetailUserApplicationRoles(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/user/get-user-application-access-details`,
      formValues
    );
  }
  inviteUserInApplication(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/user/invite-users-in-application`,
      formValues
    );
  }
  getEntity(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/customfields/entity/get-all`, {
      headers: headersList,
    });
  }
  getFieldType(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/customFields/fieldTypes/get-all`, {
      headers: headersList,
    });
  }
  getInputformat(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/custom/getFieldFormat/${data}`, {
      headers: headersList,
    });
  }
  onSaveAddCustomField(formValues: any, data: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/custom/create-custom-field/tenantid/${this.tenantId}/moduleId/${data}`, formValues);
  }
  onCheckFieldName(formValues: any, data: any, EntityId: any): Observable<any> {
    return this.http.get(
      CIAM_UI_SERVICE_API_URL + `/custom/check-field-duplicity/tenantId/${this.tenantId}/moduleId/${EntityId}/fieldName/${data}`,
      formValues
    );
  }

  //Application to Tenant Mapping
  getUnmapApplication(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(
      CIAM_UI_SERVICE_API_URL + `/application/get-unmapped-application/${data}`, {
      headers: headersList,
    });
  }

  getMappedApplication(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(
      CIAM_UI_SERVICE_API_URL + `/application/get-mapped-application/${data}`, {
      headers: headersList,
    });
  }

  saveMapUnmapApplicationToTenant(data: any, formValues: any): Observable<any> {
    return this.http.post(
      CIAM_UI_SERVICE_API_URL + `/application/map-unmap-application-to-tenant/tenantid/${data}`,
      formValues);
  }
  //tenantlist for user
  getTenantListByUsername(formValues: any): Observable<any> {
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/user/get-tenantIds-list-by-username/${formValues}`,
      formValues
    );
  }

  //tenant settings
  getManageCustomFieldListing(): Observable<any> {
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/custom/get-all-custom-field/tenantid/${this.tenantId}?page=1&rows=10000`,
    );
  }
  deleteCustomfield(formValues: any): Observable<any> {
    return this.http.delete(CIAM_UI_SERVICE_API_URL + `/custom/delete-custom-field`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }), body: formValues
    });
  }

  getFetchCustomFieldListing(data: any): Observable<any> {
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/custom/get-custom-fields-by-moduleId/tenantid/${this.tenantId}/moduleId/${data}`,
    );
  }

  getLastLoggedByUserId(data: any): Observable<any> {
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/user/getuserlastlogin/tenantId/${this.tenantId}/userId/${data}/clientId/${this.clientID}`,
    );
  }

  getApprovalsListing(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/workflow/get-workflow-transaction`, formValues
    );
  }

  saveApproveRejectRequest(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/workflow/approve-reject-request`, formValues
    );
  }
  getRequestsListing(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/workflow/get-workflow-my-requests`, formValues
    );
  }

  //Workflow Configuration

  getWorkflowConfiguration(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/workflow/getWorkflowConfiguration`,
      formValues
    );
  }

  getAllCiamAction(): Observable<any> {
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/workflow/ciam-action/get-all`,
    );
  }

  checkAvailabilityCiamAction(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/workflow/checkavailability-ciamAction`, formValues);

  }
  createWorkflowConfiguration(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/workflow/config-workflow`, formValues);
  }
  getTransactionDetails(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/workflow/view-transaction-attributes`, formValues);
  }

  suspendUser(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/user/suspend-users`, formValues);
  }

  getWorkflowApprovalStage(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/workflow/get-workflow-approval-stage-details`, formValues);
  }

  EnableDisableWorkflowConfiguration(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/workflow/enable-disable-workflow`,
      formValues
    );
  }

  getTenantSaasStatus(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/saas/getTenantSaasStatus`, formValues);
  }

  retryCallingInfraProvisioning(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/registration/retry-calling-infra-provisioning-api`, formValues);
  }
  getLastLoggedByUserIdMaster(data1: any, data: any): Observable<any> {
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/user/getuserlastlogin/tenantId/${data1}/userId/${data}/clientId/${this.clientID}`,
    );
  }
  getUserDetailsByUserNameCentral(data1: any, data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/user/get-user-details/tenantid/${data1}/username/${data}`, {
      headers: headersList,
    });

  }
  DeleteUser(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/user/delete-users`, formValues);
  }

  getDeletedUser(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/user/getDeletedUserList`, formValues);
  }
  getAttributeGroupByHostApplication(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/hostApplicationAttrGroup/getAttributeGroupByHostApplications`, formValues);
  }
  getAttributeByHostApplication(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/hostApplicationAttribute/getAttributeByHostApplications`, formValues);
  }
  revokeDeletedUser(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/user/revoke-users`, formValues);
  }

  getViewUserEffectiveRoles(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/roles/get-user-effective-roles`, formValues);
  }
  addPlan(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/plan/add-plan`, formValues);
  }
  getAllPlanAttributeConfig(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/Plan/getAllPlanAttributeConfig`, { headers: headersList });
  }
  savePlanAttribute(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/plan/add-plan-attributes`, formValues);
  }

  editPlanAttribute(formValues: any): Observable<any> {
    return this.http.put(CIAM_UI_SERVICE_API_URL + `/plan/edit-plan-attributes-value`, formValues);
  }
  getPlanList(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/Plan/get-plan-list`, formValues);
  }
  getPlanAttributeValue(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/Plan/viewPlanAttributes`, formValues);
  }
  getPlanDetails(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/Plan/get-plan/${data}`, { headers: headersList });
  }

  //getplan
  getPlanViewApplicationDetailsTab(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/Plan/get-plan/${data}`, { headers: headersList });
  }
  getViewPlanAttributes(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/Plan/viewPlanAttributes`, formValues);
  }
  editPlan(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/plan/edit-plan`, formValues);
  }

  //need to update this api
  getSubscriptionList(data: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/Plan/subscription/get-all-subscription`, data);
  }

  getPlanListByApplicationIds(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/Plan/get-planName-by-applicationIds`, formValues);
  }

  // Add Subscription
  getAccountsdropdown(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/accounts/get-all`, { headers: headersList });
  }
  addSubscription(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/Plan/subscription/add-subscription`, formValues);
  }
  editSubscription(formValues: any): Observable<any> {
    return this.http.put(CIAM_UI_SERVICE_API_URL + `/Plan/subscription/edit-subscription`, formValues);
  }
  getMappedSubscriptionUser(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/Plan/subscription/get-MappedUsersBySubscriptionId`,
      formValues
    );
  }
  getUnMappedSubscriptionUser(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/Plan/subscription/get-UnmappedUsersBySubscriptionId`,
      formValues
    );
  }
  getGroupbySubscription(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/Plan/subscription/get-UsergroupBySubscriptionId`, formValues);
  }
  onSaveMapUnmapSubscription(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/Plan/subscription/map-unmap-user-to-subscription`,
      formValues
    );
  }
  getUserSubscriptionLimit(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/Plan/subscription/get-UserLimitBySubscriptionId`, formValues);
  }
  //AUthentication
  getIPRangeConfigdropdown(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/cont-auth/IPcondition/get-all`, { headers: headersList });
  }
  //save
  saveIPrangeConfig(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/cont-auth/add-conditions-to-iprangeconfig`, formValues);
  }

  enableDisableAuthentication(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/cont-auth/enable-disable`, formValues);
  }
  /**
     * checkHostApplicationNameAvailability now comes with Post Request,
     *  As all the data can be sent as request body and not in endpoint.
     * Functionality will be same just MethodType of the HttpRequest is changed GET to POST
     * 
     * @param \{  applicationName:required, versionId:Optional(nullable)  } 
     * @returns Observable<any>
     * 
     */
  checkHostApplicationNameAvailability(formValues: any): Observable<any> {
    return this.http.post(
      CIAM_UI_SERVICE_API_URL + `/host-application/check-host-application-name-availability`,
      formValues
    );
  }
  savePasswordPolicy(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/passwordpolicy/savePasswordPolicy`, formValues);
  }
  getPasswordPolicyBytenantid(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/passwordpolicy/get-password-policy-by-tenantid`, formValues);
  }


  storageTypeList(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/lookups/storage-type/get-all`, {
      headers: headersList,
    });
  }
  applicationLogo(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(
      CIAM_UI_SERVICE_API_URL + `/lookups/files/${data}`, {
      headers: headersList,
    });
  }

  userTypeList(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/tenantModuleConfiguration/user-type/get-all`, {
      headers: headersList,
    });
  }

  getFeatureByModuleId(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/tenantModuleConfiguration/get-feature-by-module-id/${data}`, {
      headers: headersList,
    });
  }

  saveTenantModuleConfiguration(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/tenantModuleConfiguration/save-tenant-module-configuration`, formValues);
  }

  getTenantModuleConfigByTenantId(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/tenantModuleConfiguration/get-tenant-module-config-by-tenantid/${data}`, {
      headers: headersList,
    });
  }
  getSaasStatusComponent(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/host-application/is-saas-application/applicationId/${data}`, {
      headers: headersList,
    });
  }
  //getcontextualauthentication
  getContextualAuthentication(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/cont-auth/get-by-tenant-and-application`, formValues);
  }
  onDeleteContextualCondition(formValues: any): Observable<any> {
    return this.http.delete(CIAM_UI_SERVICE_API_URL + `/cont-auth/delete-cont-auth-condition`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }), body: formValues
    });
  }
  onDeleteContextualConditionConfigs(formValues: any): Observable<any> {
    return this.http.delete(CIAM_UI_SERVICE_API_URL + `/cont-auth/delete-cont-auth-condition-config`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }), body: formValues
    });
  }
  getUserDetails(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/user-service/get-user-details`, formValues);
  }

  getCiamApplicationDetails(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/application/get-ciam-application-details/tenantId/${this.tenantId}`, {
      headers: headersList,
    });
  }

  getAllUsersPagination(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/user/get-all-users-pagination`, formValues);
  }

  getHostComponentEndpoint(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/components/get-all-component-list`, formValues);
  }

  checkAvailabilityHostAppResourceName(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/hostApplicationEndpoint/checkAvailability-hostApplication-resource-name/hostApplicationId/${data.appId}/resourceName/${data.resourceName}`, {
      headers: headersList,
    });
  }
  createHostApplicationEndpoint(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/hostApplicationEndpoint/create-host-application-endpoint`, formValues);
  }

  editHostApplicationEndpoint(formValues: any): Observable<any> {
    return this.http.put(CIAM_UI_SERVICE_API_URL + `/hostApplicationEndpoint/edit-host-application-endpoint`, formValues);
  }

  deleteHostApplicationEndpoint(formValues: any): Observable<any> {
    return this.http.delete(CIAM_UI_SERVICE_API_URL + `/hostApplicationEndpoint/delete-host-endpoint`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }), body: formValues
    });
  }

  getHostApplicationEndpointPagination(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/hostApplicationEndpoint/get-all-resourceEndpoints-pagination`, formValues);
  }
  getComponentByHostApplication(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/components/get-all-component-list`, formValues);
  }
  getUnmapEndpoints(formValues: any): Observable<any> {
    return this.http.post(
      CIAM_UI_SERVICE_API_URL + `/hostApplicationEndpoint/get-unmappedHostEndpoints`, formValues);
  }
  getMappedEndpoints(formValues: any): Observable<any> {
    return this.http.post(
      CIAM_UI_SERVICE_API_URL + `/hostApplicationEndpoint/get-mappedHostEndpoints`, formValues);
  }
  saveMapUnmapEndpointToApplicationRole(formValues: any): Observable<any> {
    return this.http.post(
      CIAM_UI_SERVICE_API_URL + `/hostApplicationEndpoint/map-unmap-endpoints-with-role`, formValues);
  }


  /**
   * GetConfigurations(inputDataObj?:any) 
   * can be used to gey all the configuration(GLOBAL,TENANT and TENANT_APPLICATION level) based on input 
   *  
   * if nothing/null is provided in request/inputDataObj then it will fetch only gloabl lavel of configurations.
   * 
   *@param \{tenantId:string, applicationId:string}, 
  * @returns Observable<any>
  * 
  * @author c-amolb
   */
  getConfigurations(inputDataObj?: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.post(
      CIAM_UI_SERVICE_API_URL + `/lookups/get-configs`, inputDataObj, {
      headers: headersList
    });
  }

  getOwnerDetails(formValues: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.post(
      CIAM_UI_SERVICE_API_URL + `/applicationcomponents/get-ownership-details-by-primecomponentid`, formValues, {
      headers: headersList
    });
  }
  createAccount(formValues: any): Observable<any> {
    return this.http.post(
      CIAM_UI_SERVICE_API_URL + `/accounts/create-account`, formValues);
  }
  getOrganizationList(formValues: any): Observable<any> {
    return this.http.post(
      CIAM_UI_SERVICE_API_URL + `/org/getlist-organization`, formValues);
  }

  getAccountListingPagination(formValues: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.post(
      CIAM_UI_SERVICE_API_URL + `/accounts/get-all-accountsListing`, formValues, {
      headers: headersList
    });
  }
  getEditAccountDetails(formValues: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.post(
      CIAM_UI_SERVICE_API_URL + `/accounts/get-account-details-by-accountId-tenantId`, formValues, {
      headers: headersList
    });
  }

  getUnmappedUsers(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/accounts/get-UnmappedUsersByAccountId`,
      formValues
    );
  }
  getMappedUsers(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/accounts/get-MappedUsersByAccountId`,
      formValues
    );
  }
  saveMapUnmapAccountToUser(formValues: any): Observable<any> {
    return this.http.post(
      CIAM_UI_SERVICE_API_URL + `/accounts/map-unmap-user-to-account`,
      formValues);
  }
  getExistingParamter(): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/account-parameter/existing-account-parameter`, {
      headers: headersList,
    });
  }
  getAccountsdropdownByUserId(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/accounts/get-all-aacounts-by-user-id`, formValues);
  }

  getAccountTypeList(): Observable<any> {
    return this.http.get(
      CIAM_UI_SERVICE_API_URL + `/accounts/get-all-account-type`);
  }
  getTaxonomyDropdown(formValues: any): Observable<any> {
    return this.http.post(
      CIAM_UI_SERVICE_API_URL + `/account-parameter/taxonomy-dropdown`, formValues);
  }

  getComponentListingPagination(formValues: any): Observable<any> {
    if (this.rebacFlag == "true") {
      return this.http.post(CIAM_UI_SERVICE_API_URL + `/applicationcomponents/get-application-component-pagination`, formValues);
    }
    else {
      return this.http.post(CIAM_UI_SERVICE_API_URL + `/applicationcomponents/get-application-component-simple-pagination`, formValues);
    }
  }

  getComponentDetailsById(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/applicationcomponents/get-application-component-details`, formValues);
  }
  activateAccount(formValues: any): Observable<any> {
    return this.http.post(
      CIAM_UI_SERVICE_API_URL + `/accounts/activate-account`, formValues);
  }
  deactivateAccount(formValues: any): Observable<any> {
    return this.http.post(
      CIAM_UI_SERVICE_API_URL + `/accounts/deactivate-account`, formValues);
  }

  // bulk action api
  downloadTemplate(formValues: any): Observable<any> {
    return this.http.post(
      CIAM_UI_SERVICE_API_URL + `/user/downloadTemplate`, formValues, {
      observe: 'response',
      responseType: 'arraybuffer'
    });
  }
  bulkActionCheckStatusPagination(formValues: any): Observable<any> {
    return this.http.post(
      CIAM_UI_SERVICE_API_URL + `/user/check-status-bulk-action`, formValues);
  }
  bulkActionDownloadReport(formValues: any): Observable<any> {
    return this.http.post(
      CIAM_UI_SERVICE_API_URL + `/user/download-import-user-report`, formValues, {
      observe: 'response',
      responseType: 'arraybuffer'
    });
  }
  importUsers(formValues: any): Observable<any> {
    return this.http.post(
      CIAM_UI_SERVICE_API_URL + `/user/validate-import-user-from-template`, formValues);
  }
  getUnmapEndpointsByEntitlementID(formValues: any): Observable<any> {
    return this.http.post(
      CIAM_UI_SERVICE_API_URL + `/entitlements/get-UnmappedEndpointsByEntitlementId`, formValues);
  }
  getMappedEndpointsByEntitlementID(formValues: any): Observable<any> {
    return this.http.post(
      CIAM_UI_SERVICE_API_URL + `/entitlements/get-MappedEndpointsByEntitlementId`, formValues);
  }
  saveMapUnmapEndpointToEntitlement(formValues: any): Observable<any> {
    return this.http.post(
      CIAM_UI_SERVICE_API_URL + `/entitlements/map-unmap-endpoint-to-entitlement`, formValues);
  }

  getAccountParameter(data): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + '/accounts/get-account-parameter', data);
  }

  getUserParameter(data): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + '/user-params/get-user-parameters', data);
  }

  addUpdateUserAccountParamters(data): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + '/user-params/add-update-user-account-parameters', data);
  }
  CancelRequest(data): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + '/workflow/cancel-workflow-request', data);
  }
  downloadReport(formValues: any): Observable<any> {
    return this.http.post(
      CIAM_UI_SERVICE_API_URL + `/user/download-bulk-import-report-workflow`, formValues, {
      observe: 'response',
      responseType: 'arraybuffer'
    });
  }
  getAppRolesByTenantID(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/workflow/get-applicationRoles-by-tenantID`, formValues);
  }

  getHostAttributePagination(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/hostApplicationAttribute/get-host-attributes-pagination`, formValues);
  }

  onSaveCreateHostAttribute(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/host-application-attributes/create-host-application-attribute`, formValues);
  }

  getEmailconfiglist(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/host-application/get-email-configuration-list-by-applicationid`, formValues);
  }

  EditHostAttribute(formValues: any): Observable<any> {
    return this.http.put(CIAM_UI_SERVICE_API_URL + `/host-application-attributes/edit-host-application-attribute`, formValues);
  }


  deleteHostAttribute(formValues: any): Observable<any> {
    return this.http.delete(CIAM_UI_SERVICE_API_URL + `/host-application-attributes/delete-host-application-attribute`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }), body: formValues
    });
  }
  sendEmail(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/host-application/send-email-by-application`, formValues);
  }
  createTemplate(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/email-template/create-template`,
      formValues
    );
  }

  setDefaultEmailTemplate(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/email-template/set-default-email-template`,
      formValues
    );
  }
  reOrderPlan(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/plan/re-order-plan`,
      formValues
    );
  }
  deletePlan(formValues: any): Observable<any> {
    return this.http.delete(CIAM_UI_SERVICE_API_URL + `/plan/delete-plan`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }), body: formValues
    });
  }
  getMappedApplicationList(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/user/get-user-mapped-applications`, formValues);
  }
  getLastMailSentDetail(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/host-application/get-email-sent-details`, formValues);
  }
  //host group
  getHostAttributeGroupPagination(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/hostApplicationAttrGroup/get-host-attributes-group-pagination`, formValues);
  }
  onSaveCreateHostAttributeGroup(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/hostAttributeGroup/create-host-attribute-group`,
      formValues
    );
  }
  onEditHostAttributeGroup(formValues: any): Observable<any> {
    return this.http.put(CIAM_UI_SERVICE_API_URL + `/hostAttributeGroup/edit-host-attribute-group`,
      formValues
    );
  }
  deleteHostAttributeGroup(formValues: any): Observable<any> {
    return this.http.delete(CIAM_UI_SERVICE_API_URL + `/hostAttributeGroup/delete-host-attribute-group`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }), body: formValues
    });
  }
  getUnmappedHostAttributes(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/hostApplicationAttrGroup/get-unmapped-host-attributes`, formValues);
  }
  getMappedHostAttributes(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/hostApplicationAttrGroup/get-mapped-host-attributes`, formValues);
  }
  onSaveMapUnmapHostAttributeGroup(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/hostAttributeGroup/map-unmap-host-attribute-to-attribute-group`,
      formValues
    );
  }
  getTemplateDetailsByTemplateId(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/host-application/get-email-template`, formValues);
  }
  editTemplate(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/email-template/edit-template`,
      formValues
    );
  }
  manageRoleSelfServiceFlag(formValues: any): Observable<any> {
    return this.http.put(CIAM_UI_SERVICE_API_URL + `/host-application-role/self-service/enable-disable`,
      formValues
    );
  }
  addDeploymentModel(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/applicationcomponents/add-deployment-model`, formValues);
  }
  getDeploymentModel(data: any): Observable<any> {
    const headersList = new HttpHeaders();
    return this.http.get(CIAM_UI_SERVICE_API_URL + `/applicationcomponents/get-deployment-model/planId/${data}`, {
      headers: headersList,
    });
  }
  getOffersBySubscriptionId(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/Plan/subscription/get-offersBySubscriptionId`, formValues);
  }

  updateOffersForSubscription(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/Plan/subscription/update-offers-for-subscription`, formValues);
  }

  getPlanDetailsBySubscriptionId(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/Plan/subscription/get-planDetailsBySubscriptionId`, formValues);
  }

  logUserEvent(data: any): Observable<any> {
    return this.http.post(CIAM_UM_SERVER_API_URL + `logging/logUserEvent`, data);
  }
  getAttributeListByApplicationId(data: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/hostApplicationAttribute/get-host-attributes-pagination-tree`, data);
  }
  getAttributeListByApplicationIdLazy(data: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/hostApplicationAttribute/get-host-attributes-pagination-tree-lazy`, data);
  }

  getUnmappedHostAttributesTree(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/hostApplicationAttrGroup/get-all-host-attributes-tree-appId`, formValues);
  }

  getMappedHostAttributesTree(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/hostApplicationAttrGroup/get-mapped-host-attributes-tree-appId`, formValues);
  }

  getUnmapEmailTemplatesByApplicationId(data: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/host-application/get-unmap-email-templates-by-applicationid`,
      data
    );
  }
  onSaveCreateHostAttributeHierarchy(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/host-application-attributes/create-hierarchy-host-application-attribute`, formValues);
  }

  EdithierarchyHostAttribute(formValues: any): Observable<any> {
    return this.http.put(CIAM_UI_SERVICE_API_URL + `/host-application-attributes/edit-hierarchy-host-application-attribute`, formValues);
  }

  onViewHostAttributeHierarchy(formValues: any): Observable<any> {
    return this.http.post(CIAM_UI_SERVICE_API_URL + `/hostApplicationAttribute/viewAttributeById`, formValues);
  }


  downloadHostAttributeHierarchy(formValues: any): Observable<any> {
    return this.http.post(
      CIAM_UI_SERVICE_API_URL + `/hostApplicationAttribute/download-host-attributes-hierarchy`, formValues, {
      observe: 'response',
      responseType: 'arraybuffer'
    });
  }

}
