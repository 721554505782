import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { PagesService } from '../pages.service';
@Component({
  selector: 'jhi-EditAttributeType',
  templateUrl: './EditAttributeType.component.html',
  styleUrls: ['./EditAttributeType.component.scss'],
  providers: [DialogService],
})
export class EditAttributeTypeComponent implements OnInit {
  EditAttributeTypeForm = this.fb.group({
    textField: [null, [Validators.required]],
    textarea: [null, []],
    textField1: [null, [Validators.required]],
  });
  pageloadvalues: any;
  nameFlag: boolean = false;
  enableBtn1: boolean = true;
  defaultFlag: boolean = true;
  attrId: any;
  attrName: any;
  attrcode: any;
  desc: any;
  nameFlag1: boolean = false;
  oldAttrcode: any;
  oldAttrName: any;
  appId: any;

  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public pagesService: PagesService) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.EditAttributeTypeForm.patchValue(JSON.parse(data));
    }

  }

  ngOnInit(): void {
    this.appId = this.config?.data?.applicationid;
    this.attrId = this.config?.data?.attributeid;
    this.attrName = this.config?.data?.attributeName;
    this.attrcode = this.config?.data?.code;
    this.desc = this.config?.data?.description;
    this.oldAttrcode = this.config?.data?.code;
    this.oldAttrName = this.config?.data?.attributeName;
  }

  onClose($event: UntypedFormGroup): void {
    this.ref.close();
  }

  colorChangeCode() {
    document.getElementById('textField_id2')!.style.borderColor = 'grey';
  }

  colorChangeName() {
    document.getElementById('textField_id1')!.style.borderColor = 'grey';
  }

  EditAttributeType(): void {
    const reqBody = {};
    reqBody['code'] = this.EditAttributeTypeForm.value.textField;
    reqBody['attributeTypeName'] = this.EditAttributeTypeForm.value.textField1;
    reqBody['description'] = this.EditAttributeTypeForm.value.textarea;
    const formValues = reqBody;
    const data = { applicationId: this.appId, attributeId: this.attrId }
    this.pagesService.onSaveEditAttributeType(formValues, data).subscribe(
      {
        next: results => {
          this.ref.close("Attribute Type Details Saved Successfully");
        },
        error: error => {
          if (error.error.error?.reason == "Application Attribute Type Name already exist") {
            this.ref.close("Attribute type name already exist");
          }
          if (error.error.error?.reason == "Application Attribute code already exist") {
            this.ref.close("Attribute code name already exist");
          }
          else {
            this.ref.close("Attribute type Creation Failed");
          }
        }
      });
  }


  //Check Availability for Attribute type

  AttributeTypeName($event: UntypedFormGroup) {
    if (this.oldAttrName != this.EditAttributeTypeForm.value.textField1) {
      this.onCheckAttributeTypeName($event);
    }
    if (this.oldAttrcode != this.EditAttributeTypeForm.value.textField) {
      this.onCheckAttributeTypeCode($event);
    }
    if (this.oldAttrName == this.EditAttributeTypeForm.value.textField1 && this.oldAttrcode == this.EditAttributeTypeForm.value.textField) {
      this.EditAttributeType();
    }
  }

  onCheckAttributeTypeName($event: UntypedFormGroup): void {
    const reqBody = {};
    reqBody['applicationId'] = this.appId;
    reqBody['attributeTypeName'] = this.EditAttributeTypeForm.value.textField1;
    const formValues = reqBody;
    this.pagesService.onCheckAttributetypename(formValues).subscribe(
      results => {
        if (results?.error?.code == 400 || results?.success?.code == 400) {
          this.nameFlag1 = true;
          document.getElementById('textField_id1')!.style.borderColor = 'red';
        } else if (results?.success?.code == 200) {
          this.nameFlag1 = false;
          document.getElementById('textField_id1')!.style.borderColor = 'grey';
          if (!this.nameFlag && !this.nameFlag1 && this.oldAttrcode == this.EditAttributeTypeForm.value.textField) {
            this.EditAttributeType();
          }
        }
      },
    );
  }


  onCheckAttributeTypeCode($event: UntypedFormGroup): void {
    const reqBody = {};
    reqBody['applicationId'] = this.appId;
    reqBody['code'] = this.EditAttributeTypeForm.value.textField;
    const formValues = reqBody;
    this.pagesService.onCheckAttributetypecode(formValues).subscribe(
      results => {
        if (results?.error?.code == 400 || results?.success?.code == 400) {
          this.nameFlag = true;
          document.getElementById('textField_id2')!.style.borderColor = 'red';
        } else if (results?.success?.code == 200) {
          this.nameFlag = false;
          document.getElementById('textField_id2')!.style.borderColor = 'grey';
          if (!this.nameFlag && !this.nameFlag1) {
            this.EditAttributeType();
          }
        }
      },
    );
  }


}