import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { PagesService } from '../pages.service';
import { REBAC_UM_ENTITLEMENT_FLAG } from 'app/app.constants';
import { ManageEntitlementsService } from '../ManageEntitlements/ManageEntitlements.service';
@Component({
  selector: 'jhi-createattributegroup',
  templateUrl: './EditComponent.component.html',
  styleUrls: ['./EditComponent.component.scss'],
  providers: [DialogService],
})
export class EditComponent implements OnInit {
  EditComponentForm = this.fb.group({
    componentName: [null, [Validators.required]],
    componentType: [null, []],
    clientProtocol: [null, []],
    accessType: [null, []],
    rootURL: [null, []],
    redirectURL: [null, [Validators.required]],
    baseURL: [null, []],
    adminURL: [null, []],
    webOrigins: [null, []],
    backchannelURL: [null, []],
    description: [null, []],
    db_migration_checkbox: [false, []],
    cpuThreshold: [null, [Validators.required]],
    ramThreshold: [null, [Validators.required]],
    artifactType: [null, [Validators.required]],
    imagePath: [null, [Validators.required]],
    enterKey_dbUrl: [null, [Validators.required]],
    enterKey_dbUsername: [null, [Validators.required]],
    enterKey_dbPassword: [null, [Validators.required]],
    enterKey_idpUrl: [null, [Validators.required]],
    enterKey_idpRealm: [null, [Validators.required]],
    enterKey_idpClientId: [null, [Validators.required]],
    enterKey_idpClientSecret: [null, [Validators.required]],
    ownershipComponent: [null, []],
    ownershipEntitlement: [null, []],
    primeComponentId: [null, []],
    contextPath: [null, [Validators.required]],
    port: [null, [Validators.required]],
    withVolume: [false, []],
    createVolume: [false, []],
    createRole: [false, []],
    emailTemplate: [null, []]
  });

  pageloadvalues: any;
  nameFlag: boolean = false;
  enabledbtn1: boolean = false;
  selectList: any[] = [];
  selectList1: any[] = [];
  selectList2: any[] = [];
  resourceName: any;
  componentName: any;
  id: any;
  clientProtocalId: any;
  componentTypeId: any;
  accessTypeId: any;
  validRedirectUrl: any;
  rootUrl: any;
  baseUrl: any = "";
  adminUrl: any = "";
  backchannelLogoutUrl: any = "";
  webOrigins: any = "";
  description: any = "";
  enableBtn1 = true;
  db_migration_checkbox: any;
  enterKey_idpClientSecret: any;
  enterKey_idpClientId: any;
  enterKey_idpRealm: any;
  enterKey_idpUrl: any;
  enterKey_dbPassword: any;
  enterKey_dbUsername: any;
  enterKey_dbUrl: any;
  imagePath: any;
  artifactType: any;
  ramThreshold: any;
  cpuThreshold: any;
  envVariables: any;
  db_migrationPath: any;
  saasAppComponent: boolean = false;
  selectemailTempList: any[] = [];
  emailTemplateid:any;
  artifactList: any[] = [{
    "label": "image",
    "value": "image"
  },
  {
    "label": "jar",
    "value": "jar"
  }]
  imageURL: any;
  solImageFileSize: boolean;
  imagevalid: boolean;
  imagePlacheholder: boolean;
  formValidFlag: boolean;
  logo: File;
  imageFileFlag: boolean;
  default: boolean;
  solImage: boolean;
  productPrimaryImage: any;
  productPrimaryDBImage: any;
  saasFlag: boolean;
  dbLogo: File = null;
  dbImagevalid: boolean;
  dbVariables: any;
  dbUrl: any;
  dbformValidFlag: boolean;
  primeComponentId: any;
  rebacFlag: any;
  ownershipComponent: any;
  ownershipEntitlement: any;
  allActiveComponents: any = [];
  activeEntitlementsOfComponent: any = [];
  defaultOwner: any = [];
  contextPath: any;
  port: any;
  createVolume: any;
  createRole: any;
  withVolume: any;

  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public pagesService: PagesService,
    private entitlementService: ManageEntitlementsService,) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.EditComponentForm.patchValue(JSON.parse(data));
    }

  }

  appId: any;
  ngOnInit(): void {
    this.rebacFlag = REBAC_UM_ENTITLEMENT_FLAG + "";
    this.appId = this.config?.data?.id;
    this.getComponentTypeList();
    this.getComponentClientProtocolList();
    this.getComponentAccessTypeList();
    this.getSaasStatus();
    this.id = this.config?.data?.activeData.id;
    this.getComponentDetails();
    this.primeComponentId = this.config?.data?.activeData.componentId;
    console.log(this.primeComponentId, "PrimeId");
    console.log(this.config?.data?.activeData, "ActiveData");
    if (this.rebacFlag == "true") {
      this.getOwnerDetails(this.primeComponentId);
      this.getUnmapEmailTemplatesByApplicationId();
    }
    this.EditComponentForm.get('ownershipComponent').disable();
    this.EditComponentForm.get('ownershipEntitlement').disable();
    this.EditComponentForm.get('primeComponentId').disable();

  }

  public editReqSo() {
    const formdata = new FormData();
    formdata.append('componentName', this.EditComponentForm.value.componentName);
    if (this.EditComponentForm.value.componentType != null) {
      formdata.append('componentTypeId', this.EditComponentForm.value.componentType);
    }
    if (this.EditComponentForm.value.clientProtocol != null) {
      formdata.append('clientProtocalId', this.EditComponentForm.value.clientProtocol);
    }
    if (this.EditComponentForm.value.accessType != null)
      formdata.append('accessTypeId', this.EditComponentForm.value.accessType);
    formdata.append('rootUrl', this.EditComponentForm.value.rootURL);
    formdata.append('validRedirectUrl', this.EditComponentForm.value.redirectURL);
    formdata.append('baseUrl', this.EditComponentForm.value.baseURL);
    formdata.append('adminUrl', this.EditComponentForm.value.adminURL);
    formdata.append('webOrigins', this.EditComponentForm.value.webOrigins);
    formdata.append('backchannelLogoutUrl', this.EditComponentForm.value.backchannelURL);
    formdata.append('description', this.EditComponentForm.value.description);
    if (this.saasAppComponent) {
      formdata.append('dbMigrationRequired', this.EditComponentForm.value.db_migration_checkbox);
      if (this.EditComponentForm.value.db_migration_checkbox && this.dbLogo != undefined)
        formdata.append('dbMigrationFile', this.dbLogo);
      if (this.logo != undefined)
        formdata.append('envVarFile', this.logo);
      formdata.append('cpuThresholdValue', this.EditComponentForm.value.cpuThreshold);
      formdata.append('ramThresholdValue', this.EditComponentForm.value.ramThreshold);
      formdata.append('artifactType', this.EditComponentForm.value.artifactType);
      if (this.EditComponentForm.value.artifactType == "image")
        formdata.append('imagePath', this.EditComponentForm.value.imagePath);
      if (this.EditComponentForm.value.artifactType == "jar")
        formdata.append('artifactRepoPath', this.EditComponentForm.value.imagePath)
      formdata.append('dbUrl', this.EditComponentForm.value.enterKey_dbUrl);
      formdata.append('dbUsername', this.EditComponentForm.value.enterKey_dbUsername);
      formdata.append('dbPass', this.EditComponentForm.value.enterKey_dbPassword);
      formdata.append('idpUrl', this.EditComponentForm.value.enterKey_idpUrl);
      formdata.append('idpRealm', this.EditComponentForm.value.enterKey_idpRealm);
      formdata.append('idpClientId', this.EditComponentForm.value.enterKey_idpClientId);
      formdata.append('idpClientSecret', this.EditComponentForm.value.enterKey_idpClientSecret);
      formdata.append('saasAppComponent', "true");
      if (!this.EditComponentForm.value.withVolume) {
        this.EditComponentForm.value.withVolume = false;
      }
      if (!this.EditComponentForm.value.createVolume) {
        this.EditComponentForm.value.createVolume = false;
      }
      if (!this.EditComponentForm.value.createRole) {
        this.EditComponentForm.value.createRole = false;
      }
      formdata.append('withVolume', this.EditComponentForm.value.withVolume);
      formdata.append('createVolume', this.EditComponentForm.value.createVolume);
      formdata.append('createRole', this.EditComponentForm.value.createRole);
      formdata.append('port', this.EditComponentForm.value.port);
      formdata.append('contextpath', this.EditComponentForm.value.contextPath);
    }

    if (this.rebacFlag == "true") {
      formdata.append('emailTemplateId', this.EditComponentForm.value.emailTemplate);
    }

    return formdata;
  }

  EditComponent(): void {
    console.log(this.EditComponentForm);
    const data = { appId: this.appId, id: this.id };
    const formdata = this.editReqSo();
    this.pagesService.EditComponent(formdata, data).subscribe(
      {
        next: results => {
          this.ref.close("Application Component Details Saved Sucessfully");
        },
        error: error => {
          if (error.error.error?.messageCode == "CIAM 105") {
            this.nameFlag = true;
            document.getElementById('displayName_id')!.style.borderColor = 'red';
          }
          else {
            this.ref.close("Failed to Edit Application Component");
          }
        }
      });
  }

  getComponentTypeList(): void {
    this.pagesService.getComponentTypeList().subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.componentTypeName;
          data.value = element.id;
          this.selectList.push(data);
        });
        this.selectList = [...this.selectList];
      }
    });
  }

  onClose(): void {
    this.ref.close();
  }
  getSaasStatus() {
    this.pagesService.getSaasStatusComponent(this.appId).subscribe(results => {
      const responseData = results.success.result;
      if (responseData) {
        this.saasAppComponent = responseData;
      }
      if (!this.saasAppComponent) {
        this.EditComponentForm.controls['cpuThreshold'].clearValidators();
        this.EditComponentForm.controls['cpuThreshold'].updateValueAndValidity();
        this.EditComponentForm.controls['ramThreshold'].clearValidators();
        this.EditComponentForm.controls['ramThreshold'].updateValueAndValidity();
        this.EditComponentForm.controls['artifactType'].clearValidators();
        this.EditComponentForm.controls['artifactType'].updateValueAndValidity();
        this.EditComponentForm.controls['imagePath'].clearValidators();
        this.EditComponentForm.controls['imagePath'].updateValueAndValidity();
        this.EditComponentForm.controls['enterKey_dbUrl'].clearValidators();
        this.EditComponentForm.controls['enterKey_dbUrl'].updateValueAndValidity();
        this.EditComponentForm.controls['enterKey_dbPassword'].clearValidators();
        this.EditComponentForm.controls['enterKey_dbPassword'].updateValueAndValidity();
        this.EditComponentForm.controls['enterKey_dbUsername'].clearValidators();
        this.EditComponentForm.controls['enterKey_dbUsername'].updateValueAndValidity();
        this.EditComponentForm.controls['enterKey_idpRealm'].clearValidators();
        this.EditComponentForm.controls['enterKey_idpRealm'].updateValueAndValidity();
        this.EditComponentForm.controls['enterKey_idpUrl'].clearValidators();
        this.EditComponentForm.controls['enterKey_idpUrl'].updateValueAndValidity();
        this.EditComponentForm.controls['enterKey_idpClientId'].clearValidators();
        this.EditComponentForm.controls['enterKey_idpClientId'].updateValueAndValidity();
        this.EditComponentForm.controls['enterKey_idpClientSecret'].clearValidators();
        this.EditComponentForm.controls['enterKey_idpClientSecret'].updateValueAndValidity();
        this.EditComponentForm.controls['contextPath'].clearValidators();
        this.EditComponentForm.controls['contextPath'].updateValueAndValidity();
        this.EditComponentForm.controls['port'].clearValidators();
        this.EditComponentForm.controls['port'].updateValueAndValidity();
        this.formValidFlag = false;
        this.dbformValidFlag = false;
      }

    });

  }
  onSelectSolutionIcon(event: any): void {
    this.enableBtn1 = false;
    this.solImageFileSize = false;
    this.imagePlacheholder = false;
    this.imagevalid = false;
    this.formValidFlag = false;
    this.logo = new File([event.files[0]], event.files[0].name, {
      type: event.files[0].type
    });
    this.imageFileFlag = true;
    const isvalid = /(\.json)$/i;
    if (isvalid.test(this.logo.name)) {
      this.default = true;
      this.solImage = false;
      this.solImageFileSize = false;
      const regex = /[~#%&*{}:<>?/|'"]/g;
      this.productPrimaryImage = event.currentFiles[0].name;
      let res = regex.test(this.logo.name);
      if (res) {
        this.solImage = true;
      }
      else if (event.currentFiles[0].size > 5000000) {
        this.solImageFileSize = true;
        this.formValidFlag = true;
      }
      else {
        setTimeout(() => {
        }, 1000);

      }
    } else {
      this.imagevalid = true;
      this.formValidFlag = true;
    }
  }
  onSelectDbMigration(event: any): void {
    this.productPrimaryImage = "";
    this.enableBtn1 = false;
    this.solImageFileSize = false;
    this.imagePlacheholder = false;
    this.dbImagevalid = false;
    this.dbformValidFlag = false;
    this.dbLogo = new File([event.files[0]], event.files[0].name, {
      type: event.files[0].type
    });
    this.imageFileFlag = true;
    const isvalid = /(\.sql)$/i;
    if (isvalid.test(this.dbLogo.name)) {
      this.default = true;
      this.solImage = false;
      this.solImageFileSize = false;
      const regex = /[~#%&*{}:<>?/|'"]/g;
      this.productPrimaryDBImage = event.currentFiles[0].name;
      let res = regex.test(this.dbLogo.name);
      if (res) {
        this.solImage = true;
      }
      else if (event.currentFiles[0].size > 5000000) {
        this.solImageFileSize = true;
        this.dbformValidFlag = true;
      }
      else {
        setTimeout(() => {
        }, 1000);

      }
    } else {
      this.dbImagevalid = true;
      this.dbformValidFlag = true;
    }
  }

  getComponentClientProtocolList(): void {
    this.pagesService.getComponentClientProtocolList().subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.clientProtocalName;
          data.value = element.id;
          this.selectList1.push(data);
        });
        this.selectList1 = [...this.selectList1];
      }
    });
  }

  getComponentAccessTypeList(): void {
    this.pagesService.getComponentAccessTypeList().subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.accessTypeName;
          data.value = element.id;
          this.selectList2.push(data);
        });
        this.selectList2 = [...this.selectList2];
      }
    });
  }
  onRemoveEnvVariables(event: any, fileUploadPageIconChooseFilebtn): void {
    const index = fileUploadPageIconChooseFilebtn.files.indexOf(
      event
    );
    fileUploadPageIconChooseFilebtn.files.splice(index, 1);
    this.productPrimaryImage = "";
    this.imagevalid = false;
    this.formValidFlag = true;
    this.solImageFileSize = false;
    setTimeout(() => {
    }, 1000);
  }
  onRemoveDbMigration(event: any, fileUploadPageIconChooseFilebtn1): void {
    const index = fileUploadPageIconChooseFilebtn1.files.indexOf(
      event
    );
    fileUploadPageIconChooseFilebtn1.files.splice(index, 1);
    this.productPrimaryDBImage = "";
    this.dbImagevalid = false;
    this.dbformValidFlag = true;
    this.solImageFileSize = false;
    setTimeout(() => {
    }, 1000);
  }
  getOwnerDetails(componentId: any): void {
    const reqBody = {};
    reqBody['primeComponentId'] = componentId;
    this.pagesService.getOwnerDetails(reqBody).subscribe(results => {
      const responseData = results.success.result;
      this.ownershipComponent = responseData[0].ownershipComponent;
      console.log(this.ownershipComponent);
      this.ownershipEntitlement = responseData[0].ownershipEntitlement;
      this.primeComponentId = responseData[0].primeComponentId;
      console.log(this.primeComponentId);
      this.getAllActiveComponents();
      this.getActiveEntitlementsByComponentId(this.ownershipComponent);
    });
  }
  getAllActiveComponents() {
    let data = {};
    this.entitlementService.getAllActiveComponents(data).subscribe(
      response => {
        this.allActiveComponents = response;
      }
    );
  }
  getActiveEntitlementsByComponentId(componentId) {
    this.entitlementService.getActiveEntitlementsByComponentId(componentId).subscribe(
      response => {
        this.activeEntitlementsOfComponent = response;
        // this.ownershipEntitlement = this.activeEntitlementsOfComponent.filter(
        //   p => p.ID === this.defaultOwner.OWNER_ENTITLEMENT_ID
        // )[0];
      }
    );
  }

  getComponentDetails(): void {
    const reqBody = {};
    reqBody['componentId'] = this.id;
    this.pagesService.getComponentDetailsById(reqBody).subscribe(results => {
      const responseData = results.success.result[0];
      this.componentName = responseData?.componentName;
      this.componentTypeId = responseData?.componentType?.id;
      this.clientProtocalId = responseData?.clientProtocal?.id;
      this.accessTypeId = responseData?.accessType?.id;
      if (responseData?.rootUrl != "null" && responseData?.rootUrl != "undefined")
        this.rootUrl = responseData?.rootUrl;
      this.validRedirectUrl = responseData?.validRedirectUrl;
      if (responseData?.baseUrl != "null" && responseData?.baseUrl != "undefined")
        this.baseUrl = responseData?.baseUrl;
      if (responseData?.adminUrl != "null" && responseData?.adminUrl != "undefined")
        this.adminUrl = responseData?.adminUrl;
      if (responseData?.webOrigins != "null" && responseData?.webOrigins != "undefined")
        this.webOrigins = responseData?.webOrigins;
      if (responseData?.backchannelLogoutUrl != "null" && responseData?.backchannelLogoutUrl != "undefined")
        this.backchannelLogoutUrl = responseData?.backchannelLogoutUrl;
      if (responseData?.description != "null" && responseData?.description != "undefined")
        this.description = responseData?.description;
      this.db_migration_checkbox = responseData?.componentSaasDetails?.dbMigrationRequired;
      this.db_migrationPath = responseData?.componentSaasDetails?.dbMigrationFilePath;
      this.envVariables = responseData?.componentSaasDetails?.envVarFilePath;
      this.dbVariables = responseData?.componentSaasDetails?.dbMigrationFilePath;
      this.cpuThreshold = responseData?.componentSaasDetails?.cpuThresholdValue;
      this.ramThreshold = responseData?.componentSaasDetails?.ramThresholdValue;
      this.artifactType = responseData?.componentSaasDetails?.artifactType;
      if (this.artifactType == 'image') {
        this.imagePath = responseData?.componentSaasDetails?.imagePath;
      }
      if (this.artifactType == 'jar') {
        this.imagePath = responseData?.componentSaasDetails?.artifactRepoPath;
      }
      this.enterKey_dbUrl = responseData?.componentSaasDetails?.dbUrl;
      this.enterKey_dbUsername = responseData?.componentSaasDetails?.dbUsername;
      this.enterKey_dbPassword = responseData?.componentSaasDetails?.dbPass;
      this.enterKey_idpUrl = responseData?.componentSaasDetails?.idpUrl;
      this.enterKey_idpRealm = responseData?.componentSaasDetails?.idpRealm;
      this.enterKey_idpClientId = responseData?.componentSaasDetails?.idpClientId;
      this.enterKey_idpClientSecret = responseData?.componentSaasDetails?.idpClientSecret;
      this.saasAppComponent = responseData?.saasAppComponent;
      this.imageURL = this.envVariables;
      this.dbUrl = this.dbVariables;
      this.contextPath = responseData?.componentSaasDetails?.contextpath;
      this.port = responseData?.componentSaasDetails?.port;
      this.createVolume = responseData?.componentSaasDetails?.createVolume;
      this.createRole = responseData?.componentSaasDetails?.createRole;
      this.withVolume = responseData?.componentSaasDetails?.withVolume;



      const data = {
        label: null,
        value: null,
      };
      data.label = responseData?.emailTemplateName;
      data.value = responseData?.emailTemplateId;
      this.emailTemplateid=responseData?.emailTemplateId;
      if(responseData.emailTemplateName != null && responseData.emailTemplateId != null){
        this.selectemailTempList.push(data);
      }
      
    });
  }

  getUnmapEmailTemplatesByApplicationId(){
    let obj={
      "applicationId":this.appId
    };

    this.pagesService.getUnmapEmailTemplatesByApplicationId(obj).subscribe(
      results =>{
        const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.templateName;
          data.value = element.id;
          this.selectemailTempList.push(data);
        });
        this.selectemailTempList = [...this.selectemailTempList];
      }
        
      }
    )
  }
}