import { REBAC_UM_ENTITLEMENT_FLAG } from 'app/app.constants';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PagesService } from '../pages.service';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { PrimeNGConfig, MenuItem, MessageService, Message, SortEvent, ConfirmationService } from 'primeng/api';
import { MainComponent } from 'app/layouts/main/main.component';
import { ViewTransactionDetailsComponent } from '../ViewTransactionDetails/ViewTransactionDetails.component';
import { ApproveTransactionComponent } from '../ApproveTransaction/ApproveTransaction.component';
import { RejectTransactionComponent } from '../RejectTransaction/RejectTransaction.component';
import { DatePipe } from '@angular/common';
import { COMPONENT, ENTITLEMENT, ADMS, ALLOW, DENY, PENDING, APPROVED, NOT_APPROVED, PENDING_FOR_ME, ONE, INACTIVE, ACTIVE } from 'app/constants';
import { OnwershipApprovalsService } from './ApprovalListing.service';
import { assetUrl } from 'src/single-spa/asset-url';
import { DateFormatterPipe } from 'app/pipes/date-formatter.pipe';
import { Table } from 'primeng/table';


const parentNode = {
  data: {},
  children: [],
  selectable: true,
  expanded: true,
  key: 'N',
  label: 'Yes'
};


@Component({
  selector: 'jhi-ApprovalListing',
  templateUrl: './ApprovalListing.component.html',
  styleUrls: ['./ApprovalListing.component.scss'],
  providers: [MessageService, DialogService, ConfirmationService, DatePipe, DateFormatterPipe],
})
export class ApprovalListingComponent implements OnInit {
  ref!: DynamicDialogRef;
  ApprovalListingForm = this.fb.group({
    tabview: [0],
    textField: [null, []],
    ccprimesplitbutton: [null, []],
  });
  first = 0;
  rows = 10;
  isVisible = false;
  activeItem: any;
  activeStatus: any;
  showChip = false;
  arrayId: any[] = [];
  selectedItem!: any;
  id!: string;
  items!: MenuItem[];
  filterItems!: MenuItem[];
  msgs: Message[] = [];
  pageloadvalues: any;
  ccprimebreadcrumbList = [{ label: `My Account` }, { label: `My Approvals` }];
  homeiconccprimebreadcrumb = { icon: 'pi pi-home', routerLink: ['/'] };
  htmlSrc: any;
  searchList: any[] = [];
  ccprimesplitbuttonList: any[] = [];
  tableList: any[] = [];
  tableList1: any[] = [];
  tableList2: any[] = [];
  statusTable: any[] = [];
  tablecols: any[] = [
    { field: 'firstName', header: 'First Name' },
    { field: 'middleName', header: 'Middle Name' },
    { field: 'lastName', header: 'Last Name' },
    { field: 'emailAddress', header: 'Email Address' },
    { field: 'status', header: 'Status' },
  ];
  Hoveritems!: MenuItem[];
  selectedIndex: any;
  isDisplay: any;
  hoverIndex: any;
  selectedItems: any[] = [];
  messageFlag = true;
  activeId: any[] = [];
  selectedItems1: any[] = [];
  errorFlag: boolean = true;
  detail: string = '';
  actionDisable = true;
  checkDisable = false;
  uemail: any;
  roles: any;
  flagForceLogOff = 1;
  bulkActionItems: {}[];
  rebacFlag: any;

  //ownership approvals
  columns: any[] = [];
  isSuccess = false;
  isFailure = false;
  successMessage: string = '';
  failureMessage: string = '';
  totalRecords: number = 0;
  pageIndex: number = 1;
  pageSize: number = 10;
  rowPerPageOptions = [];
  currentPage: number = 1;
  approvalList: any[] = [];
  originalApprovalList: any[] = [];
  timeStamp: boolean;
  search: string = '';
  appliedComponentFilter: any = [];
  selectedComponentFilter: any = [];
  appliedEntitlementFilter: any = [];
  selectedEntitlementFilter: any = [];
  activeTab: any = {
    isComponentTab: false,
    isEntitlementTab: false
  };
  componentFilterData: any = [];
  entitlementFilterData: any = [];
  compId_concat: any = [];
  adms_entitlement: any = [];

  // entitlement approval
  search1: string = ''; //
  @ViewChild('dt') table: Table;
  columns1 = []; //
  columnsUserSpec = [];
  approvals: any;
  componentDropdownList: any = [];
  isFailure1: boolean = false;
  isSuccess1: boolean = false;
  successMessage1: string = '';
  failureMessage1: string = '';
  componentMap = new Map();
  approveAllFlag = false;
  rejectAllFlag = false;
  confirmationMsg: string = '';
  currentUserName: string = '';
  currentUserId: number = 0;
  entitlementId: number = 0;
  componentIdsSearch: any[] = [];
  componentData: any = [];
  entApprovalStatusDropdownList: any = [];
  status: any = [];
  approvalData: any;
  selectedComponent: any = '';
  selectedEntitlementStatus: any = [];
  lastSelectedEntitlementStatus: any = [];
  userId: any;
  appliedComponentFilter1: any = []; //
  appliedEntitlementStatusFilter: any = [];
  pendingStatusId: any;
  isApprovedFilter: boolean = false;
  isPendingFilter: boolean = true;
  pageIndex1: number = 1; //
  pageSize1: number = 10; //
  rowPerPageOptions1 = []; //
  totalRecords1: number = 0; //
  currentPage1: number = 1; //
  totoal_level_records: any[] = [];
  loginUserName: string;
  grantTree: any[] = [];
  selectedGrant: any[] = [];
  lastSelectedGrant: any[] = [];
  appliedGrant: any[] = [];
  tabIndex = 0;
  paginationFlag = 1;
  InactiveStatus = INACTIVE;
  entName: any;

  transform(value: string): string {
    return assetUrl(value);
  }
  assetUrl = assetUrl;

  constructor(
    private confirmationService: ConfirmationService,
    private primengConfig: PrimeNGConfig,
    public dialogService: DialogService,
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    private messageService: MessageService,
    private main: MainComponent,
    private datePipe: DatePipe,
    private onwershipApprovalsService: OnwershipApprovalsService,
    private dateFormatterPipe: DateFormatterPipe
  ) {
    const navigation = this.router.getCurrentNavigation();
    const data1 = navigation?.extras?.state?.data;
    if (data1) {
      this.ApprovalListingForm.patchValue(JSON.parse(data1));
    }
    let userName: string;
    userName = localStorage.getItem('username');
    if (userName.length > 0) {
      this.loginUserName = userName;
    } else {
      this.loginUserName = 'anonymousUser';
    }
  }
  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.gettableList();
    this.rebacFlag = REBAC_UM_ENTITLEMENT_FLAG + "";
  }
  handleChangetabview(e: any): void {
    this.ApprovalListingForm.patchValue({ "tabview": e.index });
    let index = e.index;
    this.tabIndex = index;
    let link;
    if (index == 1) {
      //entitlement approvals
      this.selectedGrant.push(parentNode);
      this.appliedGrant.push(parentNode);
      this.getDataWithPagination();
      this.getComponentList();
      this.loadEntApprovalStatusList();
      this.loadGrantFilterData();
    }
    else if (index == 2) {
      this.columns = [
        { field: 'ID', header: 'Entitlement Id', filterMatchMode: 'contains', display: true },
        { field: 'COMPONENT_NAME', header: 'Component', filterMatchMode: 'contains', display: true },
        { field: 'ENTITLMENT_NAME', header: 'Entitlement', filterMatchMode: 'contains', display: true },
        { field: 'OWNERSHIP_REQUEST_FROM', header: 'Ownership Request', filterMatchMode: 'contains', display: true }
      ];

      this.loadApprovalData();
    }
  }
  gettableList(): void {
    const reqBody = {};
    reqBody['tenantId'] = this.pagesService.tenantId;
    const formValues = reqBody;
    this.tableList = [];
    this.pagesService.getApprovalsListing(formValues).subscribe(results => {
      const responseData = results?.success?.result;
      responseData.forEach(
        customer => (customer.createdOn = this.datePipe.transform(customer.createdOn, 'MMM d, y, h:mm:ss a'))
      );
      if (responseData && responseData.length > 0) {
        this.tableList = responseData;
        this.tableList1 = responseData;
        this.errorFlag = false;
      } else {
        this.errorFlag = true;
      }
      if (this.ApprovalListingForm.value.textField != null) {
        this.filterMethod(this.ApprovalListingForm.value.textField);
      }
    });
  }

  showStatusFilter(status: any) {
    this.ApprovalListingForm.controls.textField.reset();
    let filtered: any[] = [];
    let items: any;
    let query = status;
    if (status != null) {
      this.tableList1.forEach(element => {
        items = element;
        if (items.user_status?.toLowerCase().includes(query.toLowerCase())) {
          filtered.push(items);
        }
      });
      this.tableList = filtered;
      this.statusTable = filtered;
    }
    else {
      this.tableList = this.tableList1;
    }
  }

  onCcprimebutton1($event: UntypedFormGroup): void {
    this.router.navigate(['/pages/adduser']);
  }

  viewTransactions() {
    this.ref = this.dialogService.open(ViewTransactionDetailsComponent, {
      header: 'Transaction Details',
      data: { id: this.activeItem.transactionId, action: this.activeItem.ciamAction },
      width: '50%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.ref.onClose.subscribe(msg => {

    });
  }

  approveTransaction(id: any) {
    this.ref = this.dialogService.open(ApproveTransactionComponent, {
      header: 'Approve Transaction Request ',
      data: { id: this.activeItem.transactionId },
      width: '55%',
      contentStyle: { 'max-height': '550px', overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == "success") {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: "Transaction Approved Successfully." });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
      else if (msg == "invalid request") {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: "User is not authorized to approve/reject this request." });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
      else if (msg == "failed") {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: "Transaction Approval Failed." });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
      if (msg) {
        this.gettableList();
      }
    });
  }

  rejectTransaction(id: any) {
    this.ref = this.dialogService.open(RejectTransactionComponent, {
      header: 'Reject Transaction Request',
      data: { id: this.activeItem.transactionId },
      width: '55%',
      contentStyle: { 'max-height': '550px', overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == "success") {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: "Tranaction Rejected Successfully." });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
      else if (msg == "invalid request") {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: "User is not authorized to approve/reject this request." });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
      else if (msg == "failed") {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: "Transaction rejection failed." });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
      if (msg) {
        this.gettableList();
      }
    });
  }

  //bulk action

  highlight(index: any) {
    this.selectedIndex = index;
  }

  mouseLeave() {
    this.hoverIndex = null;
    this.selectedIndex = null;
  }

  onRowSelect(event: any) {
    this.selectedItems.push(event.data.id);
    this.actionDisable = false;
  }

  onRowSelect1(event: any) {
    this.selectedItems1.push(event.data.id);
  }

  onRowUnselect(event: any) {
    const index: number = this.selectedItems.indexOf(event.data.id);
    if (index == 0) {
      this.actionDisable = true;
    }
    if (index !== -1) {
      this.selectedItems.splice(index, 1);
    }
  }

  filterMethod(event: any) {
    let filtered: any[] = [];
    let items: any;
    let cc;
    let query = event;
    if (query.length > 0) {
      this.tableList1.forEach(element => {
        items = element;
        if (items.transactionId?.toLowerCase().includes(query.toLowerCase()) || items.createdBy?.toLowerCase().includes(query.toLowerCase()) || items.createdOn?.toLowerCase().includes(query.toLowerCase()) || items.ciamAction?.toLowerCase().includes(query.toLowerCase()) || items.approvalStage == query) {
          filtered.push(items);
        }
      });
      this.tableList = filtered;
    }
    else {
      this.tableList = this.tableList1;
    }
  }


  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let value1: any;
      let value2: any;
      if (event.field == 'emails') {
        value1 = data1.emails[0]?.value;
        value2 = data2.emails[0]?.value;
      }
      else {
        value1 = data1[event.field];
        value2 = data2[event.field];
      }
      let result = null;
      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else {
        let res = value1 > value2 ? 1 : 0;
        result = (value1 < value2) ? -1 : res;
      }

      return (event.order * result);
    });
  }

  //ownership approvals
  loadApprovalData() {
    this.approvalList = [];
    this.originalApprovalList = [];
    let filter: any = {};
    if (this.search && this.search.trim().length > 0) {
      filter['SEARCH_TEXT'] = this.search;
    }
    if (this.selectedComponentFilter && this.selectedComponentFilter.length > 0) {
      let compIds = [];
      this.selectedComponentFilter.forEach(element => {
        compIds.push(element['data'].COMPONENT_ID);
      });
      filter['COMPONENT_IDS'] = compIds;
    }
    if (this.selectedEntitlementFilter && this.selectedEntitlementFilter.length > 0) {
      let entIds = [];
      this.selectedEntitlementFilter.forEach(element => {
        entIds.push(element['data'].ENTITLEMENT_ID);
      });
      filter['ENTITLEMENT_IDS'] = entIds;
    }
    this.onwershipApprovalsService.getEntitlementOwnerTransferRequests(filter).subscribe(
      {
        next: data => {
          this.approvalList = data['DATA'];
          this.originalApprovalList = JSON.parse(JSON.stringify(this.approvalList));
          console.log(this.originalApprovalList);
          this.loadFilterData(this.originalApprovalList);
        },
        error: err => {
          this.messageFlag = true;
          this.showErrorValidations(err.error);
        }
      });
  }

  updateStatus(ent, status) {
    let obj = {
      COMPONENT_ID: ent.COMPONENT_ID,
      ENTITLEMENT_ID: ent.ENTITLEMENT_ID,
      ENTITLEMENT_NAME: ent.ENTITLEMENT_NAME,
      OWNER_ID: ent.OWNER_ID,
      STATUS: status
    };
    this.messageFlag = true;
    this.onwershipApprovalsService.updateOwnerEntStatus(obj).subscribe(
      {
        next: res => {
          this.loadApprovalData();
          this.timeStamp = true;
          obj.STATUS = res.toString();
          this.messageFlag = true;
          this.showSuccessValidations('Ownership transfer request has been ' + status.toLowerCase() + ' successfully.');
        },
        error: err => {
          this.messageFlag = true;
          this.showErrorValidations(err.error);
        }
      });
  }
  imageUrl(url) {
    return assetUrl(url);
  }

  sidebarCloseEvent() {
    if (
      this.appliedComponentFilter.length != this.selectedComponentFilter.length ||
      this.appliedEntitlementFilter.length != this.selectedEntitlementFilter.length
    ) {
      this.selectedComponentFilter = this.appliedComponentFilter;
      this.selectedEntitlementFilter = this.appliedEntitlementFilter;
    }
    if (this.appliedComponentFilter.length == 0) {
      this.activeTab.isComponentTab = false;
    }
    if (this.appliedEntitlementFilter.length == 0) {
      this.activeTab.isEntitlementTab = false;
    }
  }

  discardOwnerFilter(event, element) {
    element.hide(event);
  }

  applyFilter(): void {
    this.appliedComponentFilter = this.selectedComponentFilter;
    this.appliedEntitlementFilter = this.selectedEntitlementFilter;
    let searchArray = [];
    if (this.search) {
      searchArray = this.search.split(',').map(x => x.trim());
    }
    if (this.selectedEntitlementFilter.length > 0 || this.selectedComponentFilter.length > 0 || this.search) {
      this.applyFilterSo(searchArray);
    } else {
      this.clearAllFilters();
    }
  }

  applyFilterSo(searchArray: any) {
    this.approvalList = this.originalApprovalList.filter(b => {
      if (
        this.selectedComponentFilter &&
        this.selectedComponentFilter.length > 0 &&
        !this.selectedComponentFilter.some(bf => bf.data.COMPONENT_ID == b.COMPONENT_ID)
      ) {
        return false;
      }
      if (
        this.selectedEntitlementFilter &&
        this.selectedEntitlementFilter.length > 0 &&
        !this.selectedEntitlementFilter.some(bf => b.ENTITLEMENT_ID == bf.data.ENTITLEMENT_ID)
      ) {
        return false;
      }
      if (
        searchArray &&
        searchArray.length > 0 &&
        !searchArray.some(
          bf =>
            b.COMPONENT_NAME.toLowerCase().includes(bf.toLowerCase()) ||
            b.ENT_OWNERSHIP_REQUEST_TO.toLowerCase().includes(bf.toLowerCase()) ||
            b.NAME_WITH_ADMS.toLowerCase().includes(bf.toLowerCase()) ||
            b.ENTITLEMENT_ID == bf
        )
      ) {
        return false;
      }
      return true;
    });
  }

  loadFilterData(dimensionList) {
    this.entitlementFilterData = [];
    this.componentFilterData = [];

    for (let parentIndex = 0; parentIndex < dimensionList.length; parentIndex++) {
      if (this.entitlementFilterData.filter(c => c['data'].ENTITLEMENT_ID == dimensionList[parentIndex].ENTITLEMENT_ID).length == 0) {
        if (dimensionList[parentIndex].IS_ADMS_ENTITLEMENT == 1) {
          this.adms_entitlement =
            dimensionList[parentIndex].ENTITLEMENT_ID + '-' + dimensionList[parentIndex].ENTITLEMENT_NAME + ' ' + ADMS;
        } else {
          this.adms_entitlement = dimensionList[parentIndex].ENTITLEMENT_ID + '-' + dimensionList[parentIndex].ENTITLEMENT_NAME;
        }

        const entitlementParentNode = {
          label: this.adms_entitlement,
          data: dimensionList[parentIndex],
          children: [],
          selectable: true,
          expanded: true,
          key: this.adms_entitlement
        };
        this.entitlementFilterData.push(entitlementParentNode);
      }

      if (this.componentFilterData.filter(c => c['data'].COMPONENT_ID == dimensionList[parentIndex].COMPONENT_ID).length == 0) {
        this.compId_concat = dimensionList[parentIndex].COMPONENT_ID + '-' + dimensionList[parentIndex].COMPONENT_NAME;

        const componentParentNode = {
          label: this.compId_concat,
          data: dimensionList[parentIndex],
          children: [],
          selectable: true,
          expanded: true,
          key: this.compId_concat
        };

        this.componentFilterData.push(componentParentNode);
      }
    }
  }

  clearAllFilters() {
    this.selectedComponentFilter = [];
    this.selectedEntitlementFilter = [];
    this.appliedComponentFilter = [];
    this.appliedEntitlementFilter = [];
    this.search = null;

    this.activeTab = {
      isComponentTab: false,
      isEntitlementTab: false
    };
    this.approvalList = this.originalApprovalList;
  }

  setActiveTab(activeTabName: any) {
    this.activeTab.isComponentTab = false;
    this.activeTab.isEntitlementTab = false;

    if (activeTabName == COMPONENT) {
      this.activeTab.isComponentTab = true;
    } else if (activeTabName == ENTITLEMENT) {
      this.activeTab.isEntitlementTab = true;
    }
  }

  showSuccessValidations(message: string) {
    this.messageFlag = true;
    this.messageService.add({ key: 'myKey1', severity: 'success', detail: message });
    setTimeout(() => {
      this.messageFlag = false;
    }, 3000);

  }

  showErrorValidations(message: string) {
    this.messageFlag = true;
    this.messageService.add({ key: 'myKey1', severity: 'error', detail: message });
    setTimeout(() => {
      this.messageFlag = false;
    }, 3000);


  }

  navigateToDetailScreen(entitlementData) {
    this.router.navigateByUrl('/pages/entitlementsdetails', {
      state: { entitlement: entitlementData },
      skipLocationChange: false
    });
  }

  //entitlement approval
  filterSo(filter: any, userId: any) {
    if (this.search1) {
      filter['SEARCH_TEXT'] = this.search1;
      if (this.componentIdsSearch && this.componentIdsSearch.length > 0) {
        filter['COMPONENT_IDS'] = this.componentIdsSearch;
      }
    }
    if (userId != null) {
      filter['USER_ID'] = userId;
    }

    if (this.selectedGrant && this.selectedGrant.length > 0) {
      let grantValues = [];
      this.selectedGrant.forEach(ele => {
        grantValues.push(ele.key);
      });
      filter['GRANT'] = grantValues;
    }
    return filter;
  }
  async loadEntitlementApprovalData(userId) {
    if (this.tabIndex == 1) {
      let userApproval = [];
      let filter: any = {};
      filter = this.filterSo(filter, userId);

      filter['pageIndex'] = this.pageIndex1;
      filter['pageSize'] = this.pageSize1;
      this.messageFlag = true;
      await this.onwershipApprovalsService.getEntitlementApprovalDetails(filter).subscribe({//NOSONAR
        next:
          data => {
            if (userId == null) {
              this.totalRecords1 = data['TOTAL_RECORDS'];
              this.approvals = data['APPROVAL_DATA'];
              let count = 0;
              this.rowPerPageOptions1 = [];

              if (this.totalRecords1 % 10 == 0) {
                count = this.totalRecords1 / 10;
              } else {
                count = Math.floor(this.totalRecords1 / 10) + 1;
              }
              for (let index = 1; index <= count; index++) {
                this.rowPerPageOptions1.push(index * 10);
              }
            } else {
              userApproval = data['APPROVAL_DATA'];
              let userIndex = this.approvals.findIndex(item => item.userId == this.currentUserId);
              this.approvals[userIndex] = userApproval[0];
              this.filterLevelData(this.approvals[userIndex].ENTITLEMENT_LIST, data['USER_TYPE_ID']);
            }

          },
        error: err => {
          this.messageFlag = true;
          this.showErrorValidations(err.error);
        }
      });
    }

  }

  getEntApproval(approval) {
    this.userId = approval.userId;
    this.approvalData = approval;
    this.selectedComponent = [];
    this.selectedEntitlementStatus = [];
    this.appliedComponentFilter1 = [];
    this.appliedEntitlementStatusFilter = [];
    this.selectedGrant = [];
    this.selectedGrant.push(parentNode);
    this.loadUserData();
  }

  searchApprovalData() {
    this.componentIdsSearch = [];
    let searchArray = [];
    searchArray = this.search1.split(',');
    searchArray.forEach(element => {
      let compIds = this.componentDropdownList
        .filter(comp => comp.COMPONENT_NAME.toLowerCase().includes(element.toLowerCase()))
        .map(cid => cid.PRIME_COMPONENT_ID);
      if (compIds.length > 0) {
        this.componentIdsSearch.push(...compIds);
      }
    });
    this.loadEntitlementApprovalData(null);
  }

  getComponentList() {
    this.messageFlag = true;
    this.onwershipApprovalsService.getComponentList().subscribe(
      {
        next: response => {
          this.componentDropdownList = response;
          this.componentDropdownList.forEach(element => {
            this.componentMap.set(element.ID, element.NAME);
          });
        },
        error: err => {
          this.messageFlag = true;
          this.showErrorValidations(err.error);
        }
      });
  }

  approveAll(approval) {
    this.currentUserName = approval.userName;
    this.currentUserId = approval.userId;
    this.rejectAllFlag = false;
    this.approveAllFlag = true;
    this.confirmationMsg = 'Are you sure you want to approve all the entitlement requests ';
  }

  rejectAll(approval) {
    this.currentUserName = approval.userName;
    this.currentUserId = approval.userId;
    this.approveAllFlag = false;
    this.rejectAllFlag = true;
    this.confirmationMsg = 'Are you sure you want to reject all the entitlement requests ';
  }

  async approve(approval) {
    this.approveAllFlag = false;
    this.rejectAllFlag = false;
    this.entName = approval.entitlementName;
    const userEntMap = {};
    this.currentUserId = approval.userId;
    userEntMap['userId'] = this.currentUserId;
    userEntMap['entId'] = approval.entitlementId;
    userEntMap['compId'] = approval.componentId;
    let levels = approval.levelStatus.split(',');
    let filterLevel = levels.filter(s => s.includes(ACTIVE)).map(s => s.split('*')[0]).join(' ');
    userEntMap['levelNo'] = filterLevel;
    userEntMap['id'] = approval.ID;
    userEntMap['entName'] = approval.entitlementName;
    this.messageFlag = true;
    this.onwershipApprovalsService.approveEntitlement(userEntMap).subscribe(
      {
        next: result => {
          if (result == 1) {
            this.loadEntitlementApprovalData(this.currentUserId);
            this.messageFlag = true;
            this.showSuccessValidations('Entitlement request approved successfully.');
          }
        },
        error: error => {
          this.messageFlag = true;
          this.showErrorValidations(error.error);
        }
      });
  }

  async reject(approval) {
    this.approveAllFlag = false;
    this.rejectAllFlag = false;
    this.entName = approval.entitlementName;
    const userEntMap = {};
    this.currentUserId = approval.userId;
    userEntMap['userId'] = this.currentUserId;
    userEntMap['entId'] = approval.entitlementId;
    userEntMap['compId'] = approval.componentId;
    let levels = approval.levelStatus.split(',');
    let filterLevel = levels.filter(s => s.includes(ACTIVE)).map(s => s.split('*')[0]).join(' ');
    userEntMap['levelNo'] = filterLevel;
    userEntMap['id'] = approval.ID;
    userEntMap['entName'] = approval.entitlementName;
    this.messageFlag = true;
    this.onwershipApprovalsService.rejectEntitlement(userEntMap).subscribe(
      {
        next: result => {
          if (result == 1) {
            this.loadEntitlementApprovalData(this.currentUserId);
            this.messageFlag = true;
            this.showSuccessValidations('Entitlement request rejected successfully.');
          }
        },
        error: error => {
          this.messageFlag = true;
          this.showErrorValidations(error.error);
        }
      });
  }

  async revoke(approval) {
    this.approveAllFlag = false;
    this.rejectAllFlag = false;
    const userEntMap = {};
    this.currentUserId = approval.userId;
    userEntMap['userId'] = this.currentUserId;
    userEntMap['entId'] = approval.entitlementId;
    userEntMap['compId'] = approval.componentId;
    userEntMap['levelNo'] = approval.levelNo;
    userEntMap['id'] = approval.ID;
    this.messageFlag = true;
    this.onwershipApprovalsService.revokeEntitlement(userEntMap).subscribe(
      {
        next: result => {
          if (result == 1) {
            this.loadUserData();
            this.messageFlag = true;
            this.showSuccessValidations('Entitlement request revoked successfully.');
          }
        },
        error: error => {
          this.messageFlag = true;
          this.showErrorValidations(error.error);
        }
      });
  }

  async onApproveAll() {
    let userEntMap = {};
    userEntMap['userId'] = this.currentUserId;
    let entitlements = this.approvals.filter(ent => ent.userId == this.currentUserId)[0].ENTITLEMENT_LIST;
    let filteredEntitlemnts = [];
    entitlements.forEach(a => {
      if (!this.checkLevelStatus(a.levelStatus)) {
        let levels = a.levelStatus.split(',');
        let filterLevel = levels.filter(s => s.includes(ACTIVE)).map(s => s.split('*')[0]).join(' ');
        a['levelNo'] = filterLevel;
        filteredEntitlemnts.push(a);
      }
    });
    userEntMap['compEntMap'] = filteredEntitlemnts;

    userEntMap['userLoginId'] = this.approvals.filter(ent => ent.userId == this.currentUserId)[0].loginId;
    this.messageFlag = true;
    await this.onwershipApprovalsService.approveAllEntitlements(userEntMap).subscribe(//NOSONAR
      {
        next: result => {
          if (result == 1) {
            this.approveAllFlag = false;
            this.loadEntitlementApprovalData(this.currentUserId);
            this.messageFlag = true;
            this.showSuccessValidations('All entitlement requests approved successfully.');
          }
        },
        error: error => {
          this.messageFlag = true;
          this.showErrorValidations(error.error);
        }
      });
  }


  async onRejectAll() {
    let userEntMap = {};
    userEntMap['userId'] = this.currentUserId;
    let entitlements = this.approvals.filter(ent => ent.userId == this.currentUserId)[0].ENTITLEMENT_LIST;
    let filteredEntitlemnts = [];
    entitlements.forEach(a => {
      if (!this.checkLevelStatus(a.levelStatus)) {
        let levels = a.levelStatus.split(',');
        let filterLevel = levels.filter(s => s.includes(ACTIVE)).map(s => s.split('*')[0]).join(' ');
        a['levelNo'] = filterLevel;
        filteredEntitlemnts.push(a);
      }
    });
    userEntMap['compEntMap'] = filteredEntitlemnts;
    userEntMap['userLoginId'] = this.approvals.filter(ent => ent.userId == this.currentUserId)[0].loginId;
    this.messageFlag = true;
    await this.onwershipApprovalsService.rejectAllEntitlements(userEntMap).subscribe( //NOSONAR
      {
        next: result => {
          if (result == 1) {
            this.rejectAllFlag = false;
            this.loadEntitlementApprovalData(this.currentUserId);
            this.messageFlag = true;
            this.showSuccessValidations('All entitlement requests rejected successfully.');
          }
        },
        error: error => {
          this.messageFlag = true;
          this.showErrorValidations(error.error);
        }
      });
  }


  openFilterSideBar() {
    if (this.status.length == 0) {
      this.loadEntApprovalStatusList();
    }
    if (this.componentData.length == 0) {
      this.loadComponentList();
    }
  }

  loadComponentList() {
    this.loadComponentFilterData(this.componentData, this.componentDropdownList);
  }

  loadStatusFilterData(mainFilterList: any, dimensionList: any) {
    for (let parentIndex = 0; parentIndex < dimensionList.length; parentIndex++) {
      const parentNode = {
        data: dimensionList[parentIndex],
        children: [],
        selectable: true,
        expanded: true,
        key: dimensionList[parentIndex].ID,
        label: dimensionList[parentIndex].NAME == ALLOW ? APPROVED : dimensionList[parentIndex].NAME == DENY ? NOT_APPROVED : PENDING_FOR_ME //NOSONAR
      };

      mainFilterList.push(parentNode);
      mainFilterList.sort((a, b) => a.label.localeCompare(b.label));
    }
  }

  loadComponentFilterData(mainFilterList: any, dimensionList: any) {
    for (let parentIndex = 0; parentIndex < dimensionList.length; parentIndex++) {
      const parentNode = {
        data: dimensionList[parentIndex],
        children: [],
        selectable: true,
        expanded: true,
        key: dimensionList[parentIndex].ID,
        label: dimensionList[parentIndex].COMP_NAME_WITH_ID
      };

      mainFilterList.push(parentNode);
    }
  }

  loadEntApprovalStatusList() {
    this.entApprovalStatusDropdownList = [];
    this.status = [];
    this.messageFlag = true;
    this.onwershipApprovalsService.getEntApprovalStatusList().subscribe(
      {
        next: response => {
          let enttilementStatusRes = response;

          this.pendingStatusId = enttilementStatusRes.filter(r => r.NAME == PENDING)[0];
          this.entApprovalStatusDropdownList = enttilementStatusRes.filter(
            r => r.NAME == ALLOW || r.NAME == DENY || r.NAME == PENDING
          );
          this.loadStatusFilterData(this.status, this.entApprovalStatusDropdownList);
        },
        error: err => {
          this.messageFlag = true;
          this.showErrorValidations(err.error);
        }
      });
  }

  applyFilter1(): void {
    this.appliedComponentFilter1 = this.selectedComponent;
    this.appliedEntitlementStatusFilter = this.selectedEntitlementStatus;
    this.appliedGrant = this.selectedGrant;
    this.isApprovedFilter = this.appliedEntitlementStatusFilter.filter(a => a.label == APPROVED).length > 0 ? true : false;//NOSONAR
    this.isPendingFilter = this.appliedEntitlementStatusFilter.filter(a => a.label == PENDING_FOR_ME || a.label == APPROVED || a.label == NOT_APPROVED).length > 0 ? true : false;//NOSONAR
    this.loadUserData();
  }

  loadUserData(): void {
    let filter = {};
    filter['USER_ID'] = this.userId;
    if (this.selectedComponent && this.selectedComponent.length > 0) {
      let componentIds = [];
      this.selectedComponent.forEach(element => {
        componentIds.push(element.data.PRIME_COMPONENT_ID);
      });
      filter['COMPONENT_ID_FILTER'] = componentIds;
    }

    if (this.selectedEntitlementStatus && this.selectedEntitlementStatus.length > 0) {
      let entStatusIds = [];
      this.selectedEntitlementStatus.forEach(element => {
        entStatusIds.push(element.key);
      });
      filter['STATUS_IDS'] = entStatusIds;
    } else {
      filter['STATUS_IDS'] = [this.pendingStatusId.ID];
      this.selectedEntitlementStatus.push(this.status.filter(x => x.data.NAME == PENDING)[0]);
      this.appliedEntitlementStatusFilter = this.selectedEntitlementStatus;
      this.lastSelectedEntitlementStatus.push(this.selectedEntitlementStatus[0]);
    }

    if (this.selectedGrant && this.selectedGrant.length > 0) {
      let grantValues = [];
      this.selectedGrant.forEach(ele => {
        grantValues.push(ele.key);
      });
      filter['GRANT'] = grantValues;
    }
    this.messageFlag = true;
    this.onwershipApprovalsService.getEntitlementFilterData(filter).subscribe(
      {
        next: response => {
          let approvals = this.approvals.filter(x => x.userId == this.userId)[0];
          approvals.ENTITLEMENT_LIST = response['ENTITLEMENT_LIST'];
          this.filterLevelData(approvals.ENTITLEMENT_LIST, response['USER_TYPE_ID']);
        },
        error: error => {
          this.messageFlag = true;
          this.showErrorValidations(error.error);
        }
      });
  }

  filterLevelData(levelData: any, userTypeId: any) {
    let obj: any = [];
    let data: any = [];
    let levelRecord: any = [];
    let list: any[];

    let approvalLevels: any = [];
    let approvals = this.approvals.filter(x => x.userId == this.userId)[0];
    let entitlementList = approvals.ENTITLEMENT_LIST;
    let isNotApprovedRecordExist: boolean = false;

    for (let i = 0; i < levelData.length; i++) {
      isNotApprovedRecordExist = false;
      this.totoal_level_records = levelData[i]['total_levels'].split('|');
      if (levelData[i].hasOwnProperty('SOURCE')) {
        levelData[i]['SOURCE'] = levelData[i]['SOURCE'].toString().replaceAll(',', ' \n ');
      }
      list = [];
      data = [];
      approvalLevels.push(entitlementList[i].levelNo);
      for (let j = 0; j < this.totoal_level_records.length; j++) {
        levelRecord = this.totoal_level_records[j].split('*');
        if (levelRecord[1] === PENDING) {
          levelRecord['COLOR'] = ' #FFBF00';
          if (this.loginUserName.toString().toLowerCase() === levelRecord[2].toLowerCase()) {
            levelRecord['TOOLTIP'] = ' Raised by ' + 'you' + ' on ' + levelRecord[3];
          } else {
            levelRecord['TOOLTIP'] = ' Raised by ' + levelRecord[2] + ' on ' + levelRecord[3];
          }
        } else if (levelRecord[1] === 'Approved') {
          levelRecord['COLOR'] = ' #3eb539';
          if (this.loginUserName.toString().toLowerCase() === levelRecord[2].toLowerCase()) {
            levelRecord['TOOLTIP'] = ' Approved by ' + 'you' + ' on ' + levelRecord[3];
          } else {
            levelRecord['TOOLTIP'] = ' Approved by ' + levelRecord[2] + ' on ' + levelRecord[3];
          }
        } else if (levelRecord[1] === NOT_APPROVED) {
          levelRecord['COLOR'] = ' #e91224';
          isNotApprovedRecordExist = true;
          if (this.loginUserName.toString().toLowerCase() === levelRecord[2].toLowerCase()) {
            levelRecord['TOOLTIP'] = ' Not Approved by ' + 'you' + ' on ' + levelRecord[3];
          } else {
            levelRecord['TOOLTIP'] = ' Not Approved by ' + levelRecord[2] + ' on ' + levelRecord[3];
          }
        }

        data.push(levelRecord);
        obj = {
          LEVEL_NO: data[j][0],
          STATUS: data[j][1],
          USER_NAME: data[j][2],
          DATE_TIME: data[j][3],
          COLOR: data[j].COLOR,
          TOOLTIP: data[j].TOOLTIP
        };
        list.push(obj);
        if (isNotApprovedRecordExist) {
          break;
        }
      }

      if (isNotApprovedRecordExist) {
        list = [];

        for (let j = 0; j < this.totoal_level_records.length; j++) {
          levelRecord = this.totoal_level_records[j].split('*');
          obj = {
            LEVEL_NO: levelRecord[0],
            STATUS: levelRecord[1],
            USER_NAME: levelRecord[2],
            DATE_TIME: this.dateFormatterPipe.transform(levelRecord[3])
          };
          if (levelRecord[1] != NOT_APPROVED) {
            (obj['COLOR'] = '#808080'); (obj['TOOLTIP'] = 'Approval process is disabled');
          } else {
            obj['COLOR'] = ' #e91224';
            if (this.loginUserName.toString().toLowerCase() === levelRecord[2].toLowerCase()) {
              obj['TOOLTIP'] = ' Not Approved by ' + 'you' + ' on ' + obj.DATE_TIME;
            } else {
              obj['TOOLTIP'] = ' Not Approved by ' + levelRecord[2] + ' on ' + obj.DATE_TIME;
            }
          }
          list.push(obj);
        }
      }
      approvals.ENTITLEMENT_LIST[i]['USER_TYPE_ID'] = userTypeId;
      approvals.ENTITLEMENT_LIST[i]['ADMS_TOOLTIP'] =
        levelData[i]['IS_ADMS_APPROVED'] == ONE.toString() ? 'ADMS Approved' : 'ADMS Not Approved';
      approvals.ENTITLEMENT_LIST[i]['IS_ADMS_ENTITLEMENT'] = levelData[i]['IS_ADMS_ENTITLEMENT'];
      approvals.ENTITLEMENT_LIST[i]['IS_ADMS_APPROVED'] = levelData[i]['IS_ADMS_APPROVED'];
      approvals.ENTITLEMENT_LIST[i]['DATA'] = list;
    }
    const splitAt = (index, xs) => [xs.slice(0, index), xs.slice(index)];

    let levels: any;
    let level: any;
    let levelSubData: any = [];
    let leveldata: any = [];
    for (let i = 0; i < approvalLevels.length; i++) {
      leveldata = [];
      levels = approvalLevels[i].split(' ');
      for (let j = 0; j < levels.length; j++) {
        levelSubData = [];
        level = splitAt(1, levels[j]);
        if (level[1] && level[j] != '-') {
          levelSubData['TOOLTIP'] = 'Level ' + level[1];
          levelSubData['lable'] = levels[j];
        } else {
          levelSubData['lable'] = levels[j];
        }
        leveldata.push(levelSubData);
      }
      approvals.ENTITLEMENT_LIST[i]['LEVEL'] = leveldata;
    }
  }

  sidebarCloseEvent1() {
    if (
      this.appliedComponentFilter1.length == this.selectedComponent.length ||
      this.appliedEntitlementStatusFilter.length == this.selectedEntitlementStatus.length
    ) {
      this.selectedComponent = this.appliedComponentFilter1;
      this.selectedEntitlementStatus = this.appliedEntitlementStatusFilter;
    } else {
      this.selectedComponent = '';
      this.selectedEntitlementStatus = '';
    }
  }

  discardApprovalFilter(event, element) {
    element.hide(event);
  }

  onPageInputChange(event) {
    if (this.currentPage1 && this.currentPage1 != this.pageIndex1) {
      if (this.currentPage1 > event.pageCount) {
        this.currentPage1 = Math.floor(this.currentPage1 / 10);
        return;
      }

      this.pageSize1 = event.rows;
      this.pageIndex1 = this.currentPage1;
      this.table.first = Math.ceil(event.totalRecords / event.pageCount) * (this.currentPage1 - 1);
      this.table.firstChange.emit(this.table.first);
      this.table.onLazyLoad.emit(this.table.createLazyLoadMetadata());
      this.loadEntitlementApprovalData(this.userId);
    } else {
      return;
    }
  }

  loadNextPaginatorGrid(event) {
    this.pageIndex1 = event.first / event.rows + 1;
    this.currentPage1 = event.first / event.rows + 1;
    this.pageSize1 = event.rows;
    this.paginationFlag = 0;
    this.getDataWithPagination();
  }

  getDataWithPagination() {
    if (this.paginationFlag == 1) {
      this.pageIndex1 = 1;
      this.pageSize1 = 10;
      this.loadEntitlementApprovalData(null);
    }
    else if (this.paginationFlag == 0) {
      this.loadEntitlementApprovalData(null);
    }

  }

  nodeSelect(event) {
    if (this.selectedEntitlementStatus.length > 1) {
      this.lastSelectedEntitlementStatus = [];
    }
  }

  nodeUnselect(event) {
    if (this.selectedEntitlementStatus.length == 1) {
      this.lastSelectedEntitlementStatus = [];
      this.lastSelectedEntitlementStatus.push(this.selectedEntitlementStatus[0]);
    }
    if (this.selectedEntitlementStatus.length == 0) {
      this.selectedEntitlementStatus.push(this.lastSelectedEntitlementStatus[0]);
    }
  }

  loadGrantFilterData() {
    this.grantTree.push(parentNode);

    const parentNode2 = {
      data: {},
      children: [],
      selectable: true,
      expanded: true,
      key: 'Y',
      label: 'No'
    };

    this.grantTree.push(parentNode2);

    this.lastSelectedGrant.push(parentNode);
  }

  grantNodeSelect() {
    if (this.selectedGrant.length > 1) {
      this.lastSelectedGrant = [];
    }
  }

  grantNodeUnselect() {
    if (this.selectedGrant.length == 1) {
      this.lastSelectedGrant = [];
      this.lastSelectedGrant.push(this.selectedGrant[0]);
    }
    if (this.selectedGrant.length == 0) {
      this.selectedGrant.push(this.lastSelectedGrant[0]);
    }
  }

  navigateToDetailScreen1(entitlementData) {
    let modifiedObject = {
      COMPONENT_ID: entitlementData.componentId,
      COMPONENT_NAME: entitlementData.componentName,
      ENTITLEMENT_ID: entitlementData.entitlementId,
      ENTITLEMENT_NAME: entitlementData.entitlementName,
      ENTITLEMENT_STATUS_ID: entitlementData.entitlementStatusId,
      ID: entitlementData.umEntitlementId,
      IS_ADMS_ENTITLEMENT: entitlementData.IS_ADMS_ENTITLEMENT,
      UPDATED_ON: entitlementData.updatedOn

    };
    this.router.navigateByUrl('/pages/entitlementsdetails', {
      state: { entitlement: modifiedObject },
      skipLocationChange: false
    });
  }

  downloadReport(rowData: any) {
    const reqBody = {};
    reqBody["tenantId"] = this.pagesService.tenantId;
    reqBody["transactionId"] = rowData.transactionId;
    this.messageFlag = true;
    this.pagesService.downloadReport(reqBody).subscribe(
      (response: any) => {
        this.messageFlag = true;
        const blob = new Blob([response.body], { type: 'application/octet-stream' });
        let url = window.URL.createObjectURL(blob);
        let anchor = document.createElement("a");
        anchor.download = "userReport.xlsx";
        setTimeout(() => {
          this.messageFlag = true;
          this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'File Downloaded Successfully.' });
          setTimeout(() => {
            this.messageFlag = false;
          }, 3000);
        }, 6000);

        anchor.href = url;
        anchor.click();

      }
    );
  }

  checkLevelStatus(levelStatus) {
    let status = levelStatus.split(',');
    let filterStatus = status.filter(s => s.toLowerCase().includes(this.InactiveStatus.toLowerCase()));
    return status.length == filterStatus.length;
  }
}
