
export interface IEventData {
    componentId: number;
    eventTypeId: number;
    eventSubjects?: IEventSubject[];
    eventContextList?: IEventContext[];
    isEventContext: boolean;
    isEventSubject: boolean;
    userName?: string;
    eventName: string
}

export interface IEventSubject {
    itemId?: number;
    oldValue?: string;
    newValue?: string;
}

export interface IEventContext {
    contextId?: number;
    value?: string;
    parentId?: number;
    contextName?:string
}

export enum ActionType {
    ADD = 'add',
    EDIT = 'edit',
    REMOVE = 'remove',
}

export interface IEditEntEvent {
    action: ActionType;
    LevelNo: number;
    value: string;
}

export interface IEditEntitlementApprover extends IEditEntEvent {
    levelActions: IEditEntEvent[];
}