import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { PagesService } from '../pages.service';
@Component({
  selector: 'jhi-editatrribute',
  templateUrl: './EditAtrribute.component.html',
  styleUrls: ['./EditAtrribute.component.scss'],
  providers: [DialogService],
})
export class EditAtrributeComponent implements OnInit {
  EditAtrributeForm = this.fb.group({
    select2: [null, [Validators.required]],
    textField: [null, [Validators.required]],
    select1: [null, []],
    selectSwitch: [null, []],
    checkbox: [null, []],
  });
  selectSwitchList: any[] = [{ 'label': "Public", 'value': 'false' }, { 'label': "Private", 'value': 'true' },];
  defaultselect_selectSwitch: any;
  checked: boolean;
  pageloadvalues: any;
  appId: any;
  resourceId: any;
  attributeId: any;
  attriType: any;
  resourceName: any;
  groups: any;
  selectList: any[] = [];
  selectedGroupList: any[] = [];
  selectedType: any[] = [];
  select1List: any[] = [];
  flag: any;
  oldGroupName: any;
  enableBtn1 = true;
  nameFlag: boolean = false;
  enabledbtn1: boolean = false;
  errorMessage = "";
  validationFlag = false;
  oldResourceName: any;

  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public pagesService: PagesService) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.EditAtrributeForm.patchValue(JSON.parse(data));
    }

  }
  ngOnInit(): void {
    this.appId = this.config?.data?.id;
    this.resourceId = this.config?.data?.activeData.id;
    this.resourceName = this.config?.data?.activeData.attribute_resource_name;
    this.oldResourceName = this.config?.data?.activeData.attribute_resource_name;
    this.oldGroupName = this.config?.data?.activeData.attribute_resource_name;
    this.getTypeList();
    this.attriType = this.config?.data?.activeData.attribute_name;
    this.getAttributeGroup();
    this.getSelectedGroupList();
    this.EditAtrributeForm.get('select1').disable();
    if (this.config?.data?.activeData.is_Default == "Yes") {
      this.checked = true;
    } else {
      this.checked = false;
    }
    if (this.config?.data?.activeData.is_Private == "Public") {
      this.defaultselect_selectSwitch = "false";
    } else {
      this.defaultselect_selectSwitch = "true";
    }
    this.setDefaultValue_selectSwitch(this.defaultselect_selectSwitch);
  }

  onClose($event: UntypedFormGroup): void {
    this.ref.close();
  }
  onColorchange() {
    document.getElementById('textField_id1')!.style.borderColor = 'grey';
  }
  onSaveCheckAttributeName($event: UntypedFormGroup): void {
    if (this.oldResourceName != this.EditAtrributeForm.value?.textField) {
      const reqBody = {};
      reqBody['attributeName'] = this.EditAtrributeForm.value?.textField;
      const formData = reqBody;
      this.pagesService.onCheckAttributeName(formData).subscribe(
        {
          next: results => {
            if (results?.error?.code == 400 || results?.success?.code == 400) {
              if (results?.error?.messageCode == "CIAM 105") {
                this.validationFlag = false;
                this.nameFlag = true;
                this.enableBtn1 = true;
                document.getElementById('textField_id1')!.style.borderColor = 'red';
              }

            } else if (results.success.code == 200) {
              this.validationFlag = false;
              this.nameFlag = false;
              this.enableBtn1 = false;
              document.getElementById('textField_id1')!.style.borderColor = 'grey';
              this.EditAttributeGroup();
            }
          },
          error: error => {
            if (error.error?.error?.messageCode == "[CIAM 115]") {
              this.validationFlag = true;
              this.nameFlag = false;
              this.enableBtn1 = true;
              document.getElementById('textField_id1')!.style.borderColor = 'red';
              this.errorMessage = error.error?.error?.reason;
            }
          }
        });
    }
    else {
      this.EditAttributeGroup();
    }
  }


  getTypeList(): void {
    this.pagesService.getAttributeByApplication(this.appId).subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.selectList.push(data.label);
          this.selectedType.push(data);
        });
        this.selectList = [...this.selectList];
        this.selectedType = [...this.selectedType];
      }
    });
  }

  getSelectedGroupList(): void {
    this.pagesService.getAttributeResourceListing(this.appId).subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          if (element.attribute_resource_name == this.resourceName) {
            element.attributeGroups.forEach(element2 => {
              data.label = element2.label;
              data.value = element2.value;
              this.selectedGroupList.push(data.value);
            });
          }
        });
        this.selectedGroupList = [...this.selectedGroupList];
      }
    });
  }

  getAttributeGroup(): void {
    this.select1List = [];
    this.pagesService.getAllAttributeGroups(this.appId).subscribe((results: any) => {

      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.select1List.push(data);
        });
        this.select1List = [...this.select1List];
      }
    });
  }

  EditAttributeGroup(): void {
    const data = { appId: this.appId, resourceId: this.resourceId };
    const reqBody = {};
    if (this.oldGroupName == this.EditAtrributeForm.value.textField) {
      for (let i = 0; i < this.selectedType.length; i++) {
        if (this.EditAtrributeForm.value.select2 == this.selectedType[i].label) {
          reqBody['attributeId'] = this.selectedType[i].value;
          break;
        }
      }
    }
    else {
      for (let i = 0; i < this.selectedType.length; i++) {
        if (this.EditAtrributeForm.value.select2 == this.selectedType[i].label) {
          reqBody['attributeId'] = this.selectedType[i].value;
          break;
        }
      }
      reqBody['attributeResourceName'] = this.EditAtrributeForm.value.textField;
    }
    reqBody['isDefault'] = this.checked;
    reqBody['isPrivate'] = this.EditAtrributeForm.value.selectSwitch;

    const formValues = reqBody;
    this.pagesService.EditAttributeResource(formValues, data).subscribe(
      {
        next: results => {
          this.ref.close("Attribute Edited Successfully");
        },
        error: () => {
          this.ref.close("Failed to Edit Attribute");
        }
      });
  }

  setDefaultValue_selectSwitch(value?: any) {
    if (this.selectSwitchList.length && value) {
      this.defaultselect_selectSwitch = value
      return this.defaultselect_selectSwitch;
    }
    this.defaultselect_selectSwitch = this.selectSwitchList[0]?.value;
    return this.defaultselect_selectSwitch;
  }

}