<div class="layout-content custom-container">
    <p-messages severity="info" *ngIf="messageFlag" class="custom-msg">
        <ng-template pTemplate>
            <span class="p-message-icon pi pi-info-circle custom-invite-msg"></span>
            <span class="p-message-detail">You can send invite to maximum 10 users at a time.</span>
            <button (click)="clearmsg()" type="button" pripple="" class="p-message-close p-link">
                <i aria-hidden="true" class="p-message-close-icon pi pi-times"></i>
            </button>
        </ng-template>
    </p-messages>
    <form [formGroup]="InviteUsersForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">

            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 left" style="height: 72px;">
                <div class=" p-fluid   p-field    ">
                    <label [ngClass]="{'field-required': InviteUsersForm.get('emailAddress')?.errors?.required}">
                        Email Address
                    </label>
                    <span class="invalid-feedback-astrik">*</span>
                    <span class="p-input-icon-right p-mb-0 p-d-inline-flex" style="width: 100%;">
                        <i aria-hidden="true" class="d-icons disable-icon" style="margin-right: -28px;margin-top: -6px;"
                            *ngIf="this.maxInviteeFlag"></i>
                        <input pInputText type="email" id="emailAddress_id" name="emailAddress_name"
                            formControlName="emailAddress" pInputText placeholder="Email address"
                            class="p-inputtext inputtextHeight p-mr-2"
                            (keyup)="domainFlag=false;duplicateFlag=false;alreadyInvitedFlag=false;"
                            style="height:32px;margin-bottom: 0px;" />
                        <button pButton type="button" icon="pi pi-plus" label="Add" class="p-button-secondary"
                            (click)="CheckEmailAvailability(InviteUsersForm);"
                            [disabled]="this.maxInviteeFlag || !InviteUsersForm.valid"
                            style="width:85px; padding: 4px 8px; height: 32px;"></button>
                    </span>
                    <div class="invalid-feedback"
                        *ngIf="InviteUsersForm.get('emailAddress')!.invalid && (InviteUsersForm.get('emailAddress')!.dirty || InviteUsersForm.get('emailAddress')!.touched)">
                        <label *ngIf="InviteUsersForm.get('emailAddress')?.errors?.required">
                            <div class="custom-font">
                                <label class="alert-icon alert-circle-icon" for="rolename"></label>
                                <medium id="rolename"
                                    style=" display: inline-block;vertical-align: bottom;font-size: 13px;"
                                    class="p-error">
                                    Email address is required!
                                </medium>
                            </div>
                        </label>
                        <label *ngIf="InviteUsersForm.get('emailAddress')?.errors?.pattern" class="p-error">
                            <div class="custom-font">
                                <label class="alert-icon alert-circle-icon" for="rolename"></label>
                                <medium id="rolename" style=" display: inline-block; vertical-align: bottom;"
                                    class="p-error">Email ID input is not in Email ID format</medium>
                            </div>
                        </label>
                    </div>

                    <div *ngIf="domainFlag">
                        <label class="alert-icon alert-circle-icon"></label>
                        <medium class="p-error">This is not a domain user</medium>
                    </div>
                    <div *ngIf="duplicateFlag">
                        <label class="alert-icon alert-circle-icon"></label>
                        <medium class="p-error">Duplicate email ID found</medium>
                    </div>
                    <div *ngIf="alreadyInvitedFlag">
                        <label class="alert-icon alert-circle-icon"></label>
                        <medium class="p-error">Email ID already invited</medium>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-8 p-xl-8 p-offset-0 p-left p-mt-2">
                <span class="p-left" style="font-size: 12px;font-style: italic;"><strong>Note:</strong> Only default
                    application
                    role will be mapped to user</span>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 p-right p-mt-2">
                <small class="p-right">{{this.tableList.length}}/10 invitees added</small>
            </div>

        </div>
    </form>
    <form [formGroup]="SendInviteUsersForm" autocomplete="off" novalidate #inviteid>
        <div class="p-formgrid p-grid">

            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-mb-4 p-offset-undefined left custom-invite-users">
                <div class="  p-field    ">
                    <div [ngClass]="{'content-section implementation': true}">
                        <p-table #tableid [value]="tableList" name="table" [columns]="tablecols" class="inviteDropdown"
                            styleClass="p-datatable-customers" [rows]="10" scrollHeight="225px" [scrollable]="true">
                            <ng-template pTemplate="header">
                                <tr style="height: 40px;">
                                    <th id="srNo" class="p-col-fixed" style="max-width: 45px;">
                                        <div class="p-d-flex p-jc-between p-ai-center">
                                            Sr.No
                                        </div>
                                    </th>
                                    <th id="emailId" class="p-col" style="max-width:290px;">
                                        <div class="p-d-flex p-jc-between p-ai-center">
                                            Email ID
                                        </div>
                                    </th>
                                    <th id="app" class="p-col">
                                        <div class="p-d-flex p-jc-between p-ai-center">
                                            Application
                                        </div>
                                    </th>
                                    <th id="app" class="p-col" *ngIf="this.pagesService.rebacFlag=='true'">
                                        <div class="p-d-flex p-jc-between p-ai-center">
                                            Account
                                        </div>
                                    </th>
                                    <th id="actionButton" class="p-col-fixed" style="max-width: 54px;">
                                        <div class="p-d-flex p-jc-between p-ai-center"></div>
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData let-index="rowIndex" let-count="count">
                                <tr [style.background-color]="selectedIndex == index ? '#EDF0F6' :  'white'">
                                    <td class="custom-displayName p-col-fixed" style="max-width: 45px;">
                                        {{index+1}}
                                    </td>

                                    <td class="custom-description p-col">
                                        {{rowData.email}}
                                    </td>
                                    <td class="custom-description p-col" style="max-width: 200px;">
                                        <div class="p-fluid  p-field custom-checkbox-color custom-multiselect"
                                            style="margin-bottom: 0px !important;">
                                            <p-multiSelect autoZIndex="true" baseZIndex="1" appendTo="body"
                                                class="custom-chips" id="select1id" name="select1"
                                                [(ngModel)]="selectedApp" filterPlaceHolder="Select Application"
                                                [options]="selectList" [filter]="true"
                                                formControlName="{{rowData.email}}" optionLabel="label"
                                                optionValue="value" display="chip" resetFilterOnHide="true"
                                                placeholder="Select Application" scrollHeight="100px"
                                                (onChange)="updateApplicationInArray(rowData.email);">
                                                <ng-template let-each pTemplate="selectedItem">
                                                    {{each.label}}
                                                </ng-template>
                                                <ng-template let-items pTemplate="item">
                                                    <div *ngIf="items?.label?.length &lt;= 35;">
                                                        {{items.label}}
                                                    </div>
                                                    <div *ngIf="items?.label?.length > 35;" pTooltip="{{items.label}}"
                                                        tooltipPosition="bottom">
                                                        {{items.label.substring(0,35) + "..." }}
                                                    </div>
                                                </ng-template>
                                            </p-multiSelect>
                                        </div>
                                    </td>
                                    <td class="custom-description p-col" style="max-width: 200px;min-width: 200px;" *ngIf="this.pagesService.rebacFlag=='true'">
                                            <div class="p-fluid  p-field custom-checkbox-color custom-multiselect"
                                            style="margin-bottom: 0px !important;">
                                            <p-dropdown id="selectid" name="select" autoZIndex="true" baseZIndex="1" appendTo="body"
                                                            placeholder="Select Account"
                                                            [options]="accountList" [filter]="true" filterBy="label"
                                                            [autoDisplayFirst]="false" formControlName="account" (onChange)="enableBtn1 = false ; "
                                                            >
                                                            <ng-template let-items pTemplate="item">
                                                                <div *ngIf="items?.label?.length &lt;=37;">
                                                                    {{items.label}}
                                                                </div>
                                                                <div *ngIf="items?.label?.length > 37;"
                                                                    pTooltip="{{items.label}}" tooltipPosition="bottom">
                                                                    {{items.label.substring(0,37) + "..." }}
                                                                </div>
                                                            </ng-template>
                                                        </p-dropdown>
                                                        </div>
                                    </td>
                                    <td class="p-col-fixed" style="max-width: 54px;">
                                        <div class="button-details">
                                            <button pButton tooltipPosition="bottom"
                                                style="width: 24px;height: 24px;padding: 0px;" icon="g-icons trash-icon"
                                                pTooltip="Delete" class="background-transparent"
                                                (click)="deleteEmail(index,rowData.email);">
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="7" class="p-p-0 p-text-center"> <p-card>
                                            <div style="height: 20px;">
                                                Add email address and select application for sending invite</div>
                                        </p-card> </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right p-mb-2">
                <div class="btnwrap p-mr-3">
                    <button pButton type="button" class="p-button-brand2 p-button"
                        (click)="onClose(SendInviteUsersForm);" label="Close">
                    </button>
                </div>
                <div class="btnwrap">
                    <button pButton class="p-button" type="button"
                        (click)="inviteUsersInApplications(SendInviteUsersForm);"
                        [disabled]="!SendInviteUsersForm.valid || this.tableList.length==0" label="Send Invite">
                    </button>
                </div>
            </div>
        </div>
    </form>

</div>