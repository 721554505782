import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { errorRoute } from './layouts/error/error.route';
import { navbarRoute } from './layouts/navbar/navbar.route';
import { headerRoute } from './layouts/header/header.route';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { APP_BASE_HREF } from '@angular/common';
const LAYOUT_ROUTES = [navbarRoute, headerRoute, ...errorRoute];

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: 'pages',
          loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
        }, {
          path: 'dashboard',
          loadChildren: () => import('./home/home.module').then(m => m.CiamHomeModule)
        }
        , { path: '**', component: EmptyRouteComponent },
        ...LAYOUT_ROUTES

      ],
      { useHash: true }
    )
  ],
  exports: [RouterModule],
  providers: [{ provide: APP_BASE_HREF, useValue: '/' }]
})
export class CiamAppRoutingModule { }
