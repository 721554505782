import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Message, MessageService, FilterService, TreeNode } from 'primeng/api';
import { PagesService } from '../pages.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { coerceStringArray } from '@angular/cdk/coercion';
@Component({
  selector: 'jhi-ViewHostHierarchyAttributes',
  templateUrl: './ViewHostHierarchyAttributes.component.html',
  styleUrls: ['./ViewHostHierarchyAttributes.component.scss'],
  providers: [MessageService, DialogService, FilterService],
})
export class ViewHostHierarchyAttributesComponent implements OnInit {

  ViewHostHierarchyAttributesForm = this.fb.group({

  });
  pageloadvalues: any;
  ccprimebreadcrumbList = [{ label: `Tenant Management` },
  { label: `Tenant Settings` },
  ]
  homeiconccprimebreadcrumb = { icon: 'pi pi-home', routerLink: ['/'] };
  appId: any;
  resourceId: any;
  resourceName: any;
  attributeID: any;
  accessType: any;
  isDefault: any;
  description: any;
  attributeType: any;
  pageSize: number = 10; //default pageSize 10
  pageNumber: number = 1;
  sortByColumn = '';
  sorting = '';
  totalRecords: number = 0;
  oldTotalRec = 0;
  files!: TreeNode[];
  attributeList: any[] = [];
  childList: any[] = [];
  loading: boolean = false;
  parentId: any;
  trreKey: any = 0;
  attributeTypeName: any;
  attrTypeId: any;
  searchStr: string;

  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public pagesService: PagesService,
    private cd: ChangeDetectorRef,
  ) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.ViewHostHierarchyAttributesForm.patchValue(JSON.parse(data));
    }

  }
  ngOnInit(): void {
    this.loading = true;
    this.resourceId = this.config?.data?.activeData.attributeId;
    this.appId = this.config?.data?.id;
    this.parentId = this.config?.data?.activeData.attributeResourceId;
    this.viewAttributebyId();
    setTimeout(() => {
      this.onNodeExpandOnInit(this.parentId);
      this.loading = false;
      this.cd.markForCheck();
    }, 2000);
  }
  viewAttributebyId() {
    const reqBody = {};
    console.log("i am here");
    reqBody['attributeId'] = this.resourceId;
    reqBody['applicationId'] = this.appId;
    console.log(reqBody);
    this.pagesService.onViewHostAttributeHierarchy(reqBody).subscribe(
      results => {
        const responseData = results.success.result;
        console.log(responseData);
        if (responseData.attributeResourceId == null) {
          this.attributeID = "null";
        }
        else {
          this.attributeID = responseData.attributeResourceId;
        }
        this.resourceName = responseData.attributeResourceName;
        this.description = responseData.description;
        console.log(responseData.default);
        if (responseData.default == true) {
          this.isDefault = "true"
        }
        else {
          this.isDefault = "false"
        }
        if (responseData.private == true) {
          this.accessType = 'Private';
        }
        else {
          console.log("i am here");
          this.accessType = 'Public';
        }
        this.attributeType = this.config?.data?.activeData.attributeTypeName;

      }
    )
  }

  onClose($event: UntypedFormGroup): void {
    this.ref.close();
  }
  onNodeExpandOnInit(resourceId: any) {
    this.loading = true;
    // const node = event.node;
    // const node = event.node;
    let reqBody = {};
    reqBody['applicationId'] = this.appId;
    reqBody['page'] = 1;
    reqBody['rows'] = this.pageSize;
    reqBody['searchString'] = this.searchStr;
    reqBody['sortByColumn'] = this.sortByColumn;
    reqBody['parentId'] = resourceId;
    reqBody['sorting'] = this.sorting;
    const data = reqBody;
    this.pagesService.getAttributeListByApplicationIdLazy(data).subscribe(results => {
      const responseData = results?.success?.result?.records;
      // this.attributeTotalRecords = results.success.result.recordsCount;
      this.oldTotalRec = results?.success?.result?.recordsCount;
      // if (responseData && responseData.length > 0) {
      //   this.attributeList = responseData;
      //   // this.files=this.attributeList;
      //   this.childList = responseData;
      //   this.treeLazy(event);
      //   // this.errorFlag = false;
      // }
      // console.log("res", responseData);
      if (responseData && responseData?.length > 0) {
        responseData.forEach((element: any = {}) => {
          // console.log("element", element.data);
          const treedata = {
            label: null,
            data: null,
            key: null,
            leaf: false
          };
          treedata.label = element.data.attributeResourceName;
          treedata.data = element.data.attributeResourceId;
          treedata.key = this.trreKey;
          // console.log("703", treedata);
          this.attributeList.push(treedata);
        });
        this.attributeList = [...this.attributeList];
        this.attributeList = [...this.attributeList];
        // console.log(this.attributeList);
        this.loading = false;
      }
    });
  }

  nodeExpand(event: any) {
    this.loading = true;
    // const node = event.node;
    // const node = event.node;
    // console.log(event);
    let reqBody = {};
    reqBody['applicationId'] = this.appId;
    reqBody['page'] = 1;
    reqBody['rows'] = this.pageSize;
    reqBody['searchString'] = this.searchStr;
    reqBody['sortByColumn'] = this.sortByColumn;
    reqBody['parentId'] = event.node.data;
    reqBody['sorting'] = this.sorting;
    const data = reqBody;
    if (!event.node.children) {
      this.loading = true;
      setTimeout(() => {
        event.node.children = [];
        this.pagesService.getAttributeListByApplicationIdLazy(data).subscribe(results => {
          const responseData = results?.success?.result?.records;
          // this.attributeTotalRecords = results.success.result.recordsCount;
          this.oldTotalRec = results?.success?.result?.recordsCount;
          // if (responseData && responseData.length > 0) {
          //   this.attributeList = responseData;
          //   // this.files=this.attributeList;
          //   this.childList = responseData;
          //   this.treeLazy(event);
          //   // this.errorFlag = false;
          // }
          // console.log("res", responseData);
          if (responseData && responseData?.length > 0) {
            for (let i = 0; i < responseData.length; i++) {
              // responseData.forEach((element: any = {}) => {
              // console.log("element", responseData[i].data);
              const treedata = {
                label: null,
                data: null,
                key: null,
                leaf: false
              };
              treedata.label = responseData[i].data.attributeResourceName;
              treedata.data = responseData[i].data.attributeResourceId;
              treedata.key = event.node.key;
              // console.log("703", treedata);
              event.node.children.push(treedata);
            };
            this.loading = false;
            // this.attributeList = [...this.attributeList];
            // this.attributeList = [...this.attributeList];
            // console.log(this.attributeList);
          }
          else {
            event.node.children = [];
            this.loading = false;
            event.node.leaf = true;
          }
        });
        // console.log(this.attributeList);
        this.cd.markForCheck();
      }, 500);
    }
  }



}
