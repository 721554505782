import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { PagesService } from '../pages.service';
import { MessageService } from 'primeng/api';
interface City {
  name: string,
  code: string
}
@Component({
  selector: 'jhi-EmailTemplateMapping',
  templateUrl: './EmailTemplateMapping.component.html',
  styleUrls: ['./EmailTemplateMapping.component.scss'],
  providers: [MessageService, DialogService],
})
export class EmailTemplateMappingComponent implements OnInit {
  EmailTemplateMappingForm = this.fb.group({
    subject: [null, [Validators.required]],
    select: [null, []]

  });
  pageloadvalues: any;
  fonts: City[];
  Sizes: City[];
  editorContent: string = '';
  checked: boolean;
  selectList: any[];
  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private fb: UntypedFormBuilder,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public pagesService: PagesService) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.EmailTemplateMappingForm.patchValue(JSON.parse(data));
    }
  }

  ngOnInit(): void {
    this.fonts = [
      { name: 'Arial', code: 'NY' },
      { name: 'Roboto', code: 'RM' },
      { name: 'sans-serif', code: 'LDN' },
      { name: 'Segoe UI', code: 'PRS' },
      { name: 'Verdana', code: 'IST' }
    ];
    this.Sizes = [
      { name: 'Small', code: 'NY' },
      { name: 'Medium', code: 'RM' },
      { name: 'large', code: 'LDN' },
      { name: 'x-large', code: 'PRS' },
      { name: 'x-large', code: 'IST' }
    ];
  }

  onClose($event: UntypedFormGroup): void {
    this.ref.close();
  }

  onUpload(event) {
    this.messageService.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
  }
  removeFiles(file, uploaders) {
    const index = uploaders._files.indexOf(file);
    uploaders._files.splice(index, 1);

  }
  clearEditor() {
    this.editorContent = '';
  }
  getApplicationVersionList(): void {
    this.selectList = [];
    this.pagesService.getApplicationVersion().subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.selectList.push(data);
        });
        this.selectList = [...this.selectList];
      }
    });
  }

}