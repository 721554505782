import { Component, ContentChildren, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Message, MessageService, PrimeNGConfig, PrimeTemplate, FilterService } from 'primeng/api';
import { PagesService } from '../pages.service';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { CreateAttributeGroupComponent } from '../CreateAttributeGroup/CreateAttributeGroup.component';
import { EditAttributeGroupComponent } from '../EditAttributeGroup/EditAttributeGroup.component';
import { DeleteAttributeGroupComponent } from '../DeleteAttributeGroup/DeleteAttributeGroup.component';
import { CreateAttributeComponent } from '../CreateAttribute/CreateAttribute.component';
import { EditAtrributeComponent } from '../EditAtrribute/EditAtrribute.component';
import { DeleteAttributeComponent } from '../DeleteAttribute/DeleteAttribute.component';
import { DomHandler } from "primeng/dom";
import { ObjectUtils } from '../object-utlis.service';
@Component({
  selector: 'jhi-attributesandgroups',
  templateUrl: './AttributesandGroups.component.html',
  styleUrls: ['./AttributesandGroups.component.scss'],
  providers: [MessageService, DialogService, FilterService, ObjectUtils],
})
export class AttributesandGroupsComponent implements OnInit {
  ref!: DynamicDialogRef;
  AttributesandGroupsForm = this.fb.group({
    tabview: [0],
    select: [null, [Validators.required]],
    textField: [null, []],
    textField1: [null, []],
    select1: [null, [Validators.required]],
    search1: [null, []],
    select2: [null, [Validators.required]],
    select3: [null, [Validators.required]],
    pickList: [null, []],
    textField2: [null, []],
    sourceFilter: [null, []],
  });
  pageloadvalues: any;
  ccprimebreadcrumbList = [
    { label: `Authorization` },
    { label: `Application attributes` },
    { label: `Attribute & Group` },
  ]
  homeiconccprimebreadcrumb = { icon: 'pi pi-home', routerLink: ['/'] };
  selectList: any[] = [];
  searchList: any[] = [];
  tableList: any[] = [];
  tableList2: any[] = [];
  tableList3: any[] = [];
  tablecols: any[] = [{ field: "type", header: "Type" },
  { field: "name", header: "Name" },
  { field: "group", header: "Group" },
  ];
  select1List: any[] = [];
  search1List: any[] = [];
  table1List: any[] = [];
  table1cols: any[] = [{ field: "type", header: "Type" },
  { field: "name", header: "Name" },
  { field: "group", header: "Group" },
  ];
  select2List: any[] = [];
  select3List: any[] = [];
  pickListList: any[] = [];
  pickListList1: any[] = [];
  messageFlag = true;
  applicationId: any;
  resourceApplicationId: any;
  msgs: Message[] = [];
  messageFlag1 = true;
  pickListtargetList: any[] = [];
  pickListtargetList1: any[] = [];
  tableList1: any[] = [];
  btn = true;
  activeItem: any;
  activeItem1: any;
  selectedIndex: any;
  flag2 = true;
  flag1 = true;
  flag3 = true;
  groupID: any[] = [];
  appId: any[] = [];
  exMapList: any[] = [];
  mapList: any[] = [];
  mList: any[] = [];
  groupArr: [];

  //newpicklist
  isChecked: boolean = false;
  @ViewChildren("picklistCheckboxes") checkboxes: QueryList<ElementRef>;
  @Input() sourceHeader: string;
  @Input() targetHeader: string;
  @Input() responsive: boolean;
  @Input() filterBy: string = "brand";
  @Input() trackBy: Function = (index: number, item: any) => item;
  @Input() sourceTrackBy: Function;
  @Input() targetTrackBy: Function;
  @Input() showSourceFilter: boolean = true;
  @Input() showTargetFilter: boolean = true;
  @Input() metaKeySelection: boolean = true;
  @Input() style: any;
  @Input() styleClass: string;
  @Input() sourceStyle: any;
  @Input() targetStyle: any;
  @Input() showSourceControls: boolean = true;
  @Input() showTargetControls: boolean = true;
  @Input() sourceFilterPlaceholder: string;
  @Input() targetFilterPlaceholder: string;
  @Input() disabled: boolean = false;
  @Output() onMoveToSource: EventEmitter<any> = new EventEmitter();
  @Output() onMoveAllToSource: EventEmitter<any> = new EventEmitter();
  @Output() onMoveAllToTarget: EventEmitter<any> = new EventEmitter();
  @Output() onMoveToTarget: EventEmitter<any> = new EventEmitter();
  @Output() onSourceSelect: EventEmitter<any> = new EventEmitter();
  @Output() onTargetSelect: EventEmitter<any> = new EventEmitter();
  @ViewChild('sourcelist') listViewSourceChild: ElementRef;
  @ViewChild('targetlist') listViewTargetChild: ElementRef;
  @ViewChild('sourceFilter') sourceFilterViewChild: ElementRef;
  @ViewChild('targetFilter') targetFilterViewChild: ElementRef;
  @ContentChildren(PrimeTemplate) templates: QueryList<any>;
  public itemTemplate: TemplateRef<any>;
  public visibleOptionsSource: any[];
  public visibleOptionsTarget: any[];
  selectedItemsSource: any[] = [];
  selectedItemsTarget: any[] = [];
  reorderedListElement: any;
  movedUp: boolean;
  movedDown: boolean;
  itemTouched: boolean;
  filterValueSource: string;
  filterValueTarget: string;
  listHighlightTarget: boolean;
  listHighlightSource: boolean;
  readonly SOURCE_LIST = -1;
  readonly TARGET_LIST = 1;
  userId: any;
  //end

  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public dialogService: DialogService,
    public pagesService: PagesService,
    private messageService: MessageService,
    private config: PrimeNGConfig
  ) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.AttributesandGroupsForm.patchValue(JSON.parse(data));
    }
  }

  ngOnInit(): void {
    this.getAttributeResourceApplicationList();
    this.AttributesandGroupsForm.get('select3').disable();
  }

  ngAfterContentInit() {
    this.templates.forEach((item) => {
      this.itemTemplate = item.template;
    });
  }

  ngAfterViewChecked() {
    if (this.movedUp || this.movedDown) {
      let listItems = DomHandler.find(this.reorderedListElement, 'li.p-state-highlight');
      let listItem;
      if (this.movedUp)
        listItem = listItems[0];
      else
        listItem = listItems[listItems.length - 1];
      DomHandler.scrollInView(this.reorderedListElement, listItem);
      this.movedUp = false;
      this.movedDown = false;
      this.reorderedListElement = null;
    }
  }

  onItemClick(event, item: any, selectedItems: any[], callback: EventEmitter<any>) {
    if (this.disabled) {
      return;
    }

    let index = this.findIndexInSelection(item, selectedItems);
    let selected = (index != -1);
    if (selected) {
      selectedItems.splice(index, 1);
    }
    else {
      selectedItems.push(item);
    }

    callback.emit({ originalEvent: event, items: selectedItems });
    this.itemTouched = false;
  }

  filterMethodPick(event: any, list: any, pickFlag: any) {
    let filtered: any[] = [];
    let items: any;
    let query = event;
    if (query.length > 0) {
      list.forEach(element => {
        items = element;
        if (items.name?.toLowerCase().includes(query.toLowerCase()) || items.isPrivate?.toLowerCase().includes(query.toLowerCase())) {
          filtered.push(items);
        }
      });
      if (pickFlag == 'pick1source') {
        this.pickListList = filtered;
      }
      else if (pickFlag == 'pick1target') {
        this.pickListtargetList = filtered;
      }
    }
    else if (pickFlag == 'pick1source') {
      this.pickListList = [];
      this.pickListList = [...this.pickListList1];
    }
    else if (pickFlag == 'pick1target') {
      this.pickListtargetList = [];
      this.pickListtargetList = [...this.pickListtargetList1];
    }
  }

  isItemVisible(item: any, listType: number): boolean {
    if (listType == this.SOURCE_LIST) {
      return this.isVisibleInList(this.visibleOptionsSource, item, this.filterValueSource);
    }
    else {
      return this.isVisibleInList(this.visibleOptionsTarget, item, this.filterValueTarget);
    }
  }

  isVisibleInList(data: any[], item: any, filterValue: string): boolean {
    if (filterValue?.trim().length) {
      data.forEach(element => {
        if (item == element) {
          return true;
        }
      });
    }
    else {
      return true;
    }
  }

  moveRight() {
    if (this.selectedItemsSource?.length) {
      this.selectedItemsSource.forEach(element => {
        let selectedItem = element;
        if (this.findIndexInList(selectedItem, this.pickListtargetList) == -1) {
          this.pickListtargetList.push(this.pickListList.splice(this.findIndexInList(selectedItem, this.pickListList), 1)[0]);
          this.pickListtargetList1.push(this.pickListList1.splice(this.findIndexInList(selectedItem, this.pickListList1), 1)[0]);
          this.btn = false;
        }
      });
      this.isChecked = false;
      this.onMoveToTarget.emit({
        items: this.selectedItemsSource
      });
      this.selectedItemsSource = [];
    }
    this.checkboxes.forEach((element) => {
      this.pickListtargetList.filter(z => z).forEach((x) => (x.isChecked = false));
    });
  }

  moveAllRight() {
    if (this.pickListList) {
      let movedItems = [];
      this.pickListtargetList1 = [];
      for (let i = 0; i < this.pickListList.length && this.isItemVisible(this.pickListList[i], this.SOURCE_LIST); +i) {
        let removedItem = this.pickListList.splice(i, 1)[0];
        this.pickListList1.splice(this.findIndexInList(removedItem, this.pickListList1), 1);
        this.pickListtargetList.push(removedItem);
        movedItems.push(removedItem);
        this.btn = false;
      }

      this.onMoveToTarget.emit({
        items: movedItems
      });

      this.onMoveAllToTarget.emit({
        items: movedItems
      });

      this.selectedItemsSource = [];
      this.pickListtargetList1 = [...this.pickListtargetList];
    }
    this.checkboxes.forEach((element) => {
      this.pickListtargetList.filter(z => z).forEach((x) => (x.isChecked = false));
    });
  }

  moveLeft() {
    if (this.selectedItemsTarget?.length) {
      this.selectedItemsTarget.forEach(element => {
        let selectedItem = element;
        if (this.findIndexInList(selectedItem, this.pickListList) == -1) {
          this.pickListList.push(this.pickListtargetList.splice(this.findIndexInList(selectedItem, this.pickListtargetList), 1)[0]);
          this.pickListList1.push(this.pickListtargetList1.splice(this.findIndexInList(selectedItem, this.pickListtargetList1), 1)[0]);
          this.btn = false;
        }
      });
      this.isChecked = false;
      this.onMoveToSource.emit({
        items: this.selectedItemsTarget
      });

      this.selectedItemsTarget = [];
    }
    this.checkboxes.forEach((element) => {
      this.pickListList.filter(z => z).forEach((x) => (x.isChecked = false));
    });
  }

  moveAllLeft() {
    if (this.pickListtargetList) {
      let movedItems = [];
      this.pickListList1 = [];
      for (let i = 0; i < this.pickListtargetList.length && this.isItemVisible(this.pickListtargetList[i], this.TARGET_LIST); +i) {
        let removedItem = this.pickListtargetList.splice(i, 1)[0];
        this.pickListtargetList1.splice(this.findIndexInList(removedItem, this.pickListtargetList1), 1);
        this.pickListList.push(removedItem);
        movedItems.push(removedItem);
        this.btn = false;
      }

      this.onMoveToSource.emit({
        items: movedItems
      });

      this.onMoveAllToSource.emit({
        items: movedItems
      });

      this.selectedItemsTarget = [];
      this.pickListList1 = [...this.pickListList];
    }
    this.checkboxes.forEach((element) => {
      this.pickListList.filter(z => z).forEach((x) => (x.isChecked = false));
    });
  }

  isSelected(item: any, selectedItems: any[]) {
    return this.findIndexInSelection(item, selectedItems) != -1;
  }

  findIndexInSelection(item: any, selectedItems: any[]): number {
    return this.findIndexInList(item, selectedItems);
  }

  findIndexInList(item: any, list: any): number {
    let index: number = -1;

    if (list) {
      for (let i = 0; i < list.length; i++) {
        if (list[i] == item) {
          index = i;
          break;
        }
      }
    }

    return index;
  }

  handleChangetabview(e: any): void {
    this.AttributesandGroupsForm.patchValue({ "tabview": e.index });
  }

  highlight(index: any) {
    this.selectedIndex = index;
  }

  mouseLeave() {
    this.selectedIndex = null;
  }

  /**
   * Attribute Tab
   */

  //Attribute tab application list API - dropdown
  getAttributeResourceApplicationList(): void {
    this.userId=this.pagesService.userId;
    console.log(this.userId);
    this.pagesService.getApplicationListMappingbyUserId(this.userId).subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.select1List.push(data);
        });
        this.select1List = [...this.select1List];
        this.selectList = [...this.select1List];
        this.select2List = [...this.select1List];
      }
    });
  }

  // Attribute listing API -table
  AttributeResourceListing(): void {
    this.tableList2 = [];
    this.tableList3 = [];
    this.flag1 = false;
    this.resourceApplicationId = this.AttributesandGroupsForm.value.select1;
    this.pagesService.getAttributeResourceListing(this.resourceApplicationId).subscribe((results: any) => {

      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            attribute_resource_name: null,
            attribute_name: null,
            attributeGroups: null,
            id: null,
            groupList: null,
            newGroupList: [],
            is_Default: null,
            is_Private: null
          };
          data.attribute_resource_name = element.attribute_resource_name;
          data.attribute_name = element.attribute_name;
          data.attributeGroups = element.attributeGroups;
          data.id = element.id;
          if (element.is_Default == "true") {
            data.is_Default = "Yes";
          }
          else {
            data.is_Default = "No";
          }

          if (element.is_Private == "true") {
            data.is_Private = "Private";
          }
          else {
            data.is_Private = "Public";
          }
          element.attributeGroups.forEach((element1: any = {}) => {
            data.newGroupList.push(element1.label);
          });
          data.groupList = data.newGroupList.toString();
          this.tableList2.push(data);
          this.tableList3.push(data);
        });
        this.tableList2 = [...this.tableList2];
        this.tableList3 = [...this.tableList3];
      }
    });

  }

  public forFilterSo(items: any, cc: any, query: any) {
    let filtered: any[] = [];
    for (let j = 0; j < cc; j++) {
      if (items.attributeGroups[j].label?.toLowerCase().includes(query.toLowerCase())) {
        filtered.push(items);
      }
    }
    return filtered;
  }
  // attribute listing filter -search
  resourceFilterMethod(event: any) {
    let filtered: any[] = [];
    let items: any;
    let cc;
    let query = event;
    if (query.length > 0) {
      this.tableList3.forEach(element => {
        items = element;
        cc = items.attributeGroups.length;
        if (items.attribute_name?.toLowerCase().includes(query.toLowerCase()) || items.attribute_resource_name?.toLowerCase().includes(query.toLowerCase()) || items.is_Private?.toLowerCase().includes(query.toLowerCase()) || items.is_Default?.toLowerCase().includes(query.toLowerCase())) {
          filtered.push(items);
        }
        else {
          filtered = this.forFilterSo(items, cc, query);
        }
      });
      this.tableList2 = filtered;
    }
    else {
      this.tableList2 = this.tableList3;
    }
  }

  //function to call group filter
  customFilter(event: any) {
    if (event?.filters?.attributeGroups?.length > 0) {
      this.groupFilterMethod(event);
    }
  }

  // filter for column Group
  groupFilterMethod(event: any) {
    let filtered: any[] = [];
    const matMode = event.filters.attributeGroups[0].matchMode;
    let items: any;
    let cc;
    let query = event.filters.attributeGroups[0].value;
    if (query.length > 0) {
      if (matMode == "startsWith") {
        this.tableList3.forEach(element => {
          items = element;
          cc = items.attributeGroups.length;
          for (let j = 0; j < cc; j++) {
            if (items.attributeGroups[j].label?.toLowerCase().startsWith(query.toLowerCase())) {
              event.filteredValue.push(items);
            }
          }
        });
      }
      else if (matMode == "contains") {
        this.tableList3.forEach(element => {
          items = element;
          cc = items.attributeGroups.length;
          for (let j = 0; j < cc; j++) {
            if (items.attributeGroups[j].label?.toLowerCase().includes(query.toLowerCase())) {
              event.filteredValue.push(items);
            }
          }
        });
      }
      else if (matMode == "endsWith") {
        this.tableList3.forEach(element => {
          items = element;
          cc = items.attributeGroups.length;
          for (let j = 0; j < cc; j++) {
            if (items.attributeGroups[j].label?.toLowerCase().indexOf(query.toLowerCase(), items.attributeGroups[j].label?.length - query.length) !== -1) {
              event.filteredValue.push(items);
            }
          }
        });
      }
      else if (matMode == "equals") {
        this.tableList3.forEach(element => {
          items = element;
          cc = items.attributeGroups.length;
          for (let j = 0; j < cc; j++) {
            if (items.attributeGroups[j].label?.toLowerCase() == query.toLowerCase()) {
              event.filteredValue.push(items);
            }
          }
        });
      }
      else {
        this.tableList2 = this.tableList3;
      }
    }
  }

  //create attribute API -dialog box
  createAttribute() {
    this.ref = this.dialogService.open(CreateAttributeComponent, {
      data: { id: this.AttributesandGroupsForm.value.select1 },
      header: 'Create Attribute',
      width: '60%',
      contentStyle: { 'max-height': '400px', overflow: 'visible' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Attribute Created Successfully') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.AttributesandGroupsForm.controls.select3.reset();
        this.flag3 = true;
        this.AttributeResourceListing();
      }
      else if (msg == 'Attribute Creation Failed' || msg == 'Attribute resource name already exist') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
    });
  }

  //edit attribute API -dialog box
  EditAttribute() {
    this.ref = this.dialogService.open(EditAtrributeComponent, {
      data: { id: this.AttributesandGroupsForm.value.select1, activeData: this.activeItem1 },
      header: 'Edit Attribute',
      width: '60%',
      contentStyle: { 'max-height': '350px', overflow: 'visible' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Attribute Edited Successfully') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.AttributesandGroupsForm.controls.select3.reset();
        this.flag3 = true;
        this.AttributeResourceListing();
      }
      else if (msg == 'Failed to Edit Attribute') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
    });
  }

  //Delete Attribute API- dialog box
  DeleteAttribute() {
    this.ref = this.dialogService.open(DeleteAttributeComponent, {
      data: { id: this.AttributesandGroupsForm.value.select1, activeData: this.activeItem1 },
      header: 'Delete Attribute',
      width: '30%',
      contentStyle: { 'max-height': '350px', overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Attribute Deleted Successfully') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.AttributesandGroupsForm.controls.select3.reset();
        this.flag3 = true;
        this.AttributeResourceListing();
      }
      else if (msg == 'Failed to Delete Attribute') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
    });
  }

  /**
   * ATTRIBUTE GROUP TAB
   */

  // attribute&group application list API- dropdown
  getAttributeAndGroupApplicationList(): void {
    this.pagesService.getApplicationListMapping().subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.selectList.push(data);
        });
        this.selectList = [...this.selectList];
      }
    });
  }

  //attribute& group listing API -table
  AttributeGroupListing(): void {
    this.flag2 = false;
    this.applicationId = this.AttributesandGroupsForm.value.select;
    this.pagesService.getAttributeGroupListing(this.applicationId).subscribe((results: any) => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        this.tableList = responseData;
        this.tableList1 = responseData;
      }
    });
  }

  // filter method for attribute&group list
  filterMethod(event: any) {
    let filtered: any[] = [];
    let items: any;
    let cc;
    let query = event;
    if (query.length > 0) {
      this.tableList1.forEach(element => {
        items = element;
        if (items.attributeGroupName?.toLowerCase().includes(query.toLowerCase()) || items.attributeGroupDescription?.toLowerCase().includes(query.toLowerCase()) || items.assignedAttributeCount == query) {
          filtered.push(items);
        }
      });
      this.tableList = filtered;
    }
    else {
      this.tableList = this.tableList1;
    }
  }

  // create attribute&group API - dialogbox
  show() {
    this.ref = this.dialogService.open(CreateAttributeGroupComponent, {
      data: { id: this.AttributesandGroupsForm.value.select },
      header: 'Create Attribute Group',
      width: '50%',
      contentStyle: { 'max-height': '350px', overflow: 'visible' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Attribute Group Created Successfully') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.AttributesandGroupsForm.controls.select3.reset();
        this.flag3 = true;
        this.AttributeGroupListing();
      }
      else if (msg == 'Attribute Group Creation Failed' || msg == 'Attribute group name already exist') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
    });

  }

  // edit attribute&group API - dialogbox
  Edit() {
    this.ref = this.dialogService.open(EditAttributeGroupComponent, {
      data: { id: this.AttributesandGroupsForm.value.select, grpId: this.activeItem.attributeGroupId, groupname: this.activeItem.attributeGroupName, description: this.activeItem.attributeGroupDescription },
      header: 'Edit Attribute Group',
      width: '50%',
      contentStyle: { 'max-height': '350px', overflow: 'visible' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Attribute Group Saved Successfully') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.AttributesandGroupsForm.controls.select2.reset();
        this.AttributesandGroupsForm.get('select3').disable();
        this.AttributesandGroupsForm.controls.select3.reset();
        this.flag3 = true;
        this.AttributeGroupListing();
      }
      else if (msg == 'Failed to Edit Attribute Group') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
    });
  }

  // delete attribute&group API - dialogbox
  Delete() {
    this.ref = this.dialogService.open(DeleteAttributeGroupComponent, {
      data: { id: this.AttributesandGroupsForm.value.select, grpid: this.activeItem.attributeGroupId, groupname: this.activeItem.attributeGroupName, assignedAttributeCount: this.activeItem.assignedAttributeCount },
      header: 'Delete Attribute Group',
      width: '30%',
      contentStyle: { 'max-height': '350px', overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Attribute Group Deleted Successfully') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.AttributesandGroupsForm.controls.select2.reset();
        this.AttributesandGroupsForm.get('select3').disable();
        this.AttributesandGroupsForm.controls.select3.reset();
        this.flag3 = true;
        this.AttributeGroupListing();
      }
      else if (msg == 'Failed to Delete Attribute Group') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
    });
  }


  /**
   * MAPPING TAB
   */

  //mapping application list API -dropdown
  getMappingApplicationList(): void {
    this.select2List = [];
    this.pagesService.getApplicationListMapping().subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.select2List.push(data);
        });
        this.select2List = [...this.select2List];
      }
    });
  }

  //attribute group listing API - dropdown
  getAttributeGroupListing(): void {
    this.AttributesandGroupsForm.get('select3').enable();
    this.applicationId = this.AttributesandGroupsForm.value.select2;
    this.AttributesandGroupsForm.controls.select3.reset();
    this.flag3 = true;
    this.select3List = [];
    this.pagesService.AttributeGroupListing(this.applicationId).subscribe((results: any) => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.select3List.push(data);
        });
        this.select3List = [...this.select3List];
      }
    });
  }

  //picklist
  getMappedDetails() {
    this.flag3 = true;
    this.AttributesandGroupsForm.controls.sourceFilter.reset();
    this.pickListList = [];
    this.pickListtargetList = [];
    this.pickListList1 = [];
    this.pickListtargetList1 = [];
    this.appId = this.AttributesandGroupsForm.value.select2;
    this.groupID = this.AttributesandGroupsForm.value.select3;
    const mapReqData = { appId: this.appId, groupID: this.groupID };
    this.pagesService.getAttributeResourceMappedAttributes(mapReqData).subscribe(results => {
      const responseData = results?.success?.result;
      this.pickListtargetList = [];
      if (responseData && responseData.length > 0) {
        this.flag3 = false;
        responseData.forEach((element: any = {}) => {
          const data = {
            name: null,
            id: null,
            type: null,
            isPrivate: null
          };
          data.name = element.name;
          data.id = element.id;
          data.type = element.type;
          if (element.isPrivate) {
            data.isPrivate = "Private";
          }
          else {
            data.isPrivate = "Public";
          }
          this.pickListtargetList.push(data);
        });
        this.pickListtargetList = [...this.pickListtargetList];
        this.pickListtargetList1 = [...this.pickListtargetList];

      }

    });

    this.pagesService.getAttributeResourceUnMappedAttributes(mapReqData).subscribe(results => {
      const responseData = results?.success?.result;
      this.pickListList = [];
      if (responseData && responseData.length > 0) {
        this.flag3 = false;
        responseData.forEach((element: any = {}) => {
          const data = {
            name: null,
            id: null,
            type: null,
            isPrivate: null
          };
          data.name = element.name;
          data.id = element.id;
          data.type = element.type;
          if (element.isPrivate) {
            data.isPrivate = "Private";
          }
          else {
            data.isPrivate = "Public";
          }

          this.pickListList.push(data);
        });
        this.pickListList = [...this.pickListList];
        this.pickListList1 = [...this.pickListList];
      }
    });
    this.getExMapUnMapList();
  }

  getExMapUnMapList() {
    this.exMapList = [];
    this.appId = this.AttributesandGroupsForm.value.select2;
    this.groupID = this.AttributesandGroupsForm.value.select3;
    const exMapData = { appId: this.appId, groupID: this.groupID };

    this.pagesService.getAttributeResourceMappedAttributes(exMapData).subscribe(results => {
      const responseData = results?.success?.result;
      if (responseData && responseData.length > 0) {
        this.exMapList = [];
        responseData.forEach((element: any = {}) => {
          const data = {
            name: null,
            id: null,
            type: null,
            isPrivate: null
          };
          data.name = element.name;
          data.id = element.id;
          data.type = element.type;
          if (element.isPrivate) {
            data.isPrivate = "Private";
          }
          else {
            data.isPrivate = "Public";
          }
          this.exMapList.push(data.id);
        });
        this.exMapList = [...this.exMapList];
      }
    });
  }

  //saving mapped unmapped final list
  onSave() {
    this.messageFlag = true;
    this.messageFlag1 = true;
    this.appId = this.AttributesandGroupsForm.value.select2;
    this.groupID = this.AttributesandGroupsForm.value.select3;
    const saveData = { appId: this.appId, groupID: this.groupID };
    this.mapList = this.pickListtargetList1;
    this.mList = [];
    this.mapList.forEach((element: any = {}) => {
      const data = {
        name: null,
        id: null,
        type: null,
      };
      data.name = element.name;
      data.id = element.id;
      data.type = element.type;
      this.mList.push(data.id);
    });
    this.mList = [...this.mList];
    const assignList = this.mList.filter(x => !this.exMapList.includes(x));
    const deassignList = this.exMapList.filter(x => !this.mList.includes(x));
    if (assignList.length == 0 && deassignList.length == 0) {
      this.messageFlag = true;
      this.messageFlag1 = true;
      this.messageService.add({ key: 'myKey2', severity: 'info', detail: 'No Resources To Map.' });
      this.btn = true;
      setTimeout(() => {
        this.messageFlag1 = false;
      }, 3000);

    }
    else {
      const reqBody = {};
      reqBody['mapList'] = assignList;
      reqBody['unmapList'] = deassignList;
      const formValues = reqBody;
      this.messageFlag = true;
      this.pagesService.onSaveAttributeResourceMappingMapping(formValues, saveData).subscribe(
        {
          next: results => {
            this.messageFlag = true;
            this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'Attribute group mapping/unmapping saved successfully.' });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
            this.btn = true;
            this.getExMapUnMapList();
            this.AttributesandGroupsForm.controls.select1.reset();
            this.AttributesandGroupsForm.controls.select.reset();
            this.flag1 = true;
            this.flag2 = true;
          },
          error: () => {
            this.messageFlag = true;
            this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Attribute group mapping/unmapping failed.' });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
          }
        });
    }
  }

}