<div class="layout-content custom-container">
    <form [formGroup]="InviteEmailForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 left">
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                <div class=" p-fluid   p-field    ">
                    <label [ngClass]="{'field-required': InviteEmailForm.get('templateName')?.errors?.required}">
                        Template Name
                        <span class='invalid-feedback-astrik'>*</span></label>
                    <input type="text" id="templateName_id" name="templateName_name" formControlName="templateName"
                        class="p-inputtext inputtextHeight" />
                    <div class="p-error"
                        *ngIf="InviteEmailForm.get('templateName')!.invalid && (InviteEmailForm.get('templateName')!.dirty || InviteEmailForm.get('templateName')!.touched)">
                        <div *ngIf="InviteEmailForm.get('templateName')?.errors?.required">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium class="p-error">Template Name is required.</medium>
                        </div>

                    </div>

                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                <div class=" p-fluid   p-field    ">
                    <label [ngClass]="{'field-required': InviteEmailForm.get('subject')?.errors?.required}">
                        Subject
                        <span class='invalid-feedback-astrik'>*</span></label>
                    <input type="text" id="subject_id" name="subject_name" formControlName="subject"
                        class="p-inputtext inputtextHeight" />
                    <div class="p-error"
                        *ngIf="InviteEmailForm.get('subject')!.invalid && (InviteEmailForm.get('subject')!.dirty || InviteEmailForm.get('subject')!.touched)">
                        <div *ngIf="InviteEmailForm.get('subject')?.errors?.required">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium class="p-error">Subject is required.</medium>
                        </div>

                    </div>

                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-pb-2 p-offset-undefined left">
                <label>
                    Email Body
                    <span class='invalid-feedback-astrik'>*</span></label>
                    <label tooltipPosition="right"
                        pTooltip="<ul style='padding-left: 12px;margin-bottom: 3px;' ><li ><span style='color:white'>##user_fullName##</span><span style='color:#B6B6B6'> e.g Simran Chandwale</span></li><li><span style='color:white'>##user_firstName##</span><span style='color:#B6B6B6'> e.g. Simran</span></li><li ><span style='color:white'>##user_credential##</span><span style='color:#B6B6B6'> e.g User login credential will be displayed</span></li><li><span style='color:white'>##logo##</span><span style='color:#B6B6B6'> e.g. selected logo will be displayed (logo must be uploaded)</span></li><li ><span style='color:white'>##banner## </span> <span style='color:#B6B6B6'> e.g. selected banner will be displayed (banner must be uploaded)</span></li><li><span style='color:white'>##componentName##</span> <span style='color:#B6B6B6'> e.g mapped componentName will appear else remain blank</span></li><li><span style='color:white'>##tenantName##</span><span style='color:#B6B6B6'> e.g tenant name will appear</span></li><li><span style='color:white'>##applicationName##</span><span style='color:#B6B6B6'> e.g application name will appear</span></li><li><span style='color:white'>##appLink##</span><span style='color:#B6B6B6'> e.g application link will be displayed (link must be entered)</span></li></ul>"
                        class="alert-icon alert-circle-icon1" [escape]="false"></label>
                <p-editor [style]="{'height':'170px'}" class="editor-section" formControlName="emailBody">
                </p-editor>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-pb-2 p-offset-undefined left">
                <label>
                    Signature
                    <span class='invalid-feedback-astrik'>*</span></label>
                    <label tooltipPosition="right"
                    pTooltip="<ul style='padding-left: 12px;margin-bottom: 3px;' ><li> <span style='color:white'>##user_fullName##</span><span style='color:#B6B6B6'> e.g Simran Chandwale</span></li></ul>"
                    class="alert-icon alert-circle-icon1" [escape]="false"></label>
                <p-editor [style]="{'height':'100px'}" class="editor-section" formControlName="sign"></p-editor>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-mt-2 p-offset-undefined left custom_small_file">

                <label>Logo</label>
                <div class="custom_choose_file custom-tenant-file">
                    <p-fileUpload id="newSolutionPageIconChooseFilebtn" name="newSolutionPageIconChooseFilebtn"
                    #fileUploadPageIconChooseFilebtn1 chooseLabel="Choose File" customUpload="true"
                    cancelLabel="Reset" [showUploadButton]="false" [showCancelButton]="false"
                    (onSelect)="onSelectLogo($event)">
                    <ng-template pTemplate="content">
                        <ng-container
                            *ngIf="imageURL && (productPrimaryImage == '' || productPrimaryImage == undefined);then previewText1; else previewText">
                        </ng-container>
                        <ng-template #previewText1>
                            <label class="label_content">
                                <span pTooltip="{{imageForToolTip.length > 28 ? imageForToolTip : ''}}"
                                    tooltipPosition="left">
                                    <ng-container *ngIf="imageForToolTip.length > 28; else tooltip">
                                        {{
                                        imageURL.split("/")[4].substring(0,
                                        30) +
                                        "..." }}
                                    </ng-container>
                                    <ng-template #tooltip>
                                        {{ imageURL.split("/")[4] }}
                                    </ng-template>
                                </span>
                            </label>
                        </ng-template>
                        <ng-template #previewText>
                            <label
                                *ngIf="(productPrimaryImage == '' || productPrimaryImage == undefined) && !imageFileFlag"
                                class="label_content">
                                &nbsp;File type: jpg,png,svg,Max size 5MB.
                            </label>
                        </ng-template>
                    </ng-template>
                    <ng-template let-file pTemplate="file">
                        <div class="file">
                            <ng-container
                                *ngIf="imageURL && (productPrimaryImage == '' || productPrimaryImage == undefined);then previewdata1; else previewdata">
                            </ng-container>
                            <ng-template #previewdata>
                                <span class="ml-2 fileName"
                                    pTooltip="{{file.name.length >28 ? file.name : ''}}"
                                    tooltipPosition="left">
                                    <ng-container *ngIf="file.name.length > 28; else tooltip">
                                        {{ file.name.substring(0,28) +
                                        "..." }}
                                    </ng-container>
                                    <ng-template #tooltip>
                                        {{ file.name }}
                                    </ng-template>
                                </span>
                                <span class="delete-action"
                                    (click)="onRemoveIcon(event,fileUploadPageIconChooseFilebtn1)">
                                    <i aria-hidden="true" class="pi pi-times"></i>
                                </span>
                            </ng-template>
                            <ng-template #previewdata1></ng-template>
                        </div>
                    </ng-template>
                </p-fileUpload>
                </div>
                <div class="p-error" *ngIf="solImageFileSize">
                    <label class="alert-icon alert-circle-icon"></label>
                    <medium>The maximum file size can be 5MB!</medium>
                </div>
                <div class="p-error" *ngIf="imagevalid">
                    <label class="alert-icon alert-circle-icon"></label>
                    <medium>Invalid image format!</medium>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-mt-2 p-offset-undefined left custom_small_file">

                <label>Baner</label>
                <div class="custom_choose_file custom-tenant-file">
                    <p-fileUpload id="newSolutionPageIconChooseFilebtn" name="newSolutionPageIconChooseFilebtn"
                    #fileUploadPageIconChooseFilebtn chooseLabel="Choose File" customUpload="true"
                    cancelLabel="Reset" [showUploadButton]="false" [showCancelButton]="false"
                    (onSelect)="onSelectBaner($event)">
                    <ng-template pTemplate="content">
                        <ng-container
                            *ngIf="imageURL && (productPrimaryImage1 == '' || productPrimaryImage1 == undefined);then previewText1; else previewText">
                        </ng-container>
                        <ng-template #previewText1>
                            <label class="label_content">
                                <span pTooltip="{{imageForToolTip.length > 28 ? imageForToolTip : ''}}"
                                    tooltipPosition="left">
                                    <ng-container *ngIf="imageForToolTip.length > 28; else tooltip">
                                        {{
                                        imageURL.split("/")[4].substring(0,
                                        30) +
                                        "..." }}
                                    </ng-container>
                                    <ng-template #tooltip>
                                        {{ imageURL.split("/")[4] }}
                                    </ng-template>
                                </span>
                            </label>
                        </ng-template>
                        <ng-template #previewText>
                            <label
                                *ngIf="(productPrimaryImage1 == '' || productPrimaryImage1 == undefined) && !imageFileFlag1"
                                class="label_content">
                                &nbsp;File type: jpg,png,svg,Max size 5MB.
                            </label>
                        </ng-template>
                    </ng-template>
                    <ng-template let-file pTemplate="file">
                        <div class="file">
                            <ng-container
                                *ngIf="imageURL && (productPrimaryImage1 == '' || productPrimaryImage1 == undefined);then previewdata1; else previewdata">
                            </ng-container>
                            <ng-template #previewdata>
                                <span class="ml-2 fileName"
                                    pTooltip="{{file.name.length >28 ? file.name : ''}}"
                                    tooltipPosition="left">
                                    <ng-container *ngIf="file.name.length > 28; else tooltip">
                                        {{ file.name.substring(0,28) +
                                        "..." }}
                                    </ng-container>
                                    <ng-template #tooltip>
                                        {{ file.name }}
                                    </ng-template>
                                </span>
                                <span class="delete-action"
                                    (click)="onRemoveBaner(event,fileUploadPageIconChooseFilebtn)">
                                    <i aria-hidden="true" class="pi pi-times"></i>
                                </span>
                            </ng-template>
                            <ng-template #previewdata1></ng-template>
                        </div>
                    </ng-template>
                </p-fileUpload>
                </div>
                <div class="p-error" *ngIf="solImageFileSize1">
                    <label class="alert-icon alert-circle-icon"></label>
                    <medium>The maximum file size can be 5MB!</medium>
                </div>
                <div class="p-error" *ngIf="imagevalid1">
                    <label class="alert-icon alert-circle-icon"></label>
                    <medium>Invalid image format!</medium>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-mt-2 left">
                <div class=" p-fluid   p-field    ">
                    <label [ngClass]="{'field-required': InviteEmailForm.get('appLink')?.errors?.required}">
                        Application Link
                        <span class='invalid-feedback-astrik'>*</span></label>
                    <input type="text" id="textField1_id" name="appLink_name" formControlName="appLink"
                        placeholder="Enter application link" class="p-inputtext inputtextHeight" />
                    <div class="p-error"
                        *ngIf="InviteEmailForm.get('appLink')!.invalid && (InviteEmailForm.get('appLink')!.dirty || InviteEmailForm.get('appLink')!.touched)">
                        <div *ngIf="InviteEmailForm.get('appLink')?.errors?.required">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium class="p-error">Application link is required.</medium>
                        </div>

                    </div>

                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                <div class="p-field-checkbox p-mt-1">
                    <p-checkbox [(ngModel)]="checked" binary="true" inputId="binary"
                        formControlName="checkbox"></p-checkbox>
                    <label for="binary">Default Template</label>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                <div class="p-col-fixed p-right custom-btn-height p-pt-2">
                    <div class="btnwrap p-mr-3">
                        <button pButton type="button" class="p-button-brand2 p-button"
                            (click)="onClose(InviteEmailForm);" label="Close">
                        </button>
                    </div>
                    <div class="btnwrap">
                        <button pButton class="p-button" id="save" type="button" [disabled]="!InviteEmailForm.valid || solImageFileSize1|| imagevalid1 ||solImageFileSize||imagevalid"
                            label="Save" (click)="CreateTemplate()">
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>