import { Injectable } from "@angular/core";
import { ACTIVATE, ACTIVE, ARCHIVED, DEACTIVATE, DELETE, DELETE_PERMANENTLY, INACTIVE, MAP_ACCOUNT, MAP_ENDPOINT, SINGLE, UNLOCK, UNMAP_ACCOUNT, UNMAP_ENDPOINT, USER_MANAGEMENT_COMPONENT_ID } from "app/constants";
import { LoggingEventService } from "app/logging-event.service";
import { ContextName, EventName, ItemName, Operations, attributeName } from "app/shared/constants/logging-event.constants";
import { IEventContext, IEventData, IEventSubject } from "app/shared/interface/event.interface";

@Injectable({
    'providedIn': 'root'
})
export class UserManagementLoggingService {

    eventTypes: { [name: string]: number } = {};
    contextList: { [name: string]: number } = {};
    eventItems: { [name: string]: number } = {};
    constructor(
        private loggingService: LoggingEventService
    ) {
    }

    async prepareViewUserData(formData: FormData) {
        const eventDataArray = [];
        let contextValue = formData.get('userId').toString() + ' - ' + formData.get('loginId').toString();//NOSONAR
        if (formData.get('changeUserFlag').toString() == 'true') {//NOSONAR
            let eventData: IEventData = await this.loggingService.getEventData(EventName.CHANGE_USER, false, true, USER_MANAGEMENT_COMPONENT_ID, ContextName.VIEW_USER_DETAILS_PAGE, contextValue);
            eventDataArray.push(JSON.stringify(eventData));
        }
        else {
            let eventData: IEventData = await this.loggingService.getEventData(EventName.EDIT_USER, false, true, USER_MANAGEMENT_COMPONENT_ID, ContextName.VIEW_USER_DETAILS_PAGE, contextValue);
            eventDataArray.push(JSON.stringify(eventData));
        }
        return JSON.stringify(eventDataArray);
    }

    async prepareUploadEntitlements(formData: FormData) {
        const eventDataArray = [];
        let file = formData.get('file') as File;
        if (file) {
            let filename = file.name;
            let eventDataForMaker: IEventData = await this.loggingService.getEventData(EventName.ENTITLEMENT_UPLOAD_MAKER, true, true, USER_MANAGEMENT_COMPONENT_ID, ContextName.UPLOAD_ENTITLEMENTS_FILE);
            let eventDataForChecker: IEventData = await this.loggingService.getEventData(EventName.ENTITLEMENT_UPLOAD_CHECKER, true, true, USER_MANAGEMENT_COMPONENT_ID, ContextName.UPLOAD_ENTITLEMENTS_FILE);

            let eventSubjects: IEventSubject[] = [await this.loggingService.getEventSubjectData(ItemName.FILE_NAME, filename)];

            eventDataForMaker.eventSubjects = eventSubjects;
            eventDataArray.push(JSON.stringify(eventDataForMaker));

            eventDataForChecker.eventSubjects = eventSubjects;
            eventDataArray.push(JSON.stringify(eventDataForChecker));

            return JSON.stringify(eventDataArray);
        }
        else {
            return 'No file found in the FormData';
        }

    }

    async prepareViewManageRoleListData(filter: any) {
        const eventDataArray = [];
        const eventData: IEventData = await this.loggingService.getEventData(EventName.MANAGE_ROLE, false, true, USER_MANAGEMENT_COMPONENT_ID, ContextName.VIEW_MANAGE_ROLE_PAGE);
        eventDataArray.push(JSON.stringify(eventData));
        return JSON.stringify(eventDataArray);
    }

    async prepareEditUserActivityLog(userDetails: any) {
        const eventDataArray = [];
        let attr: { [id: number]: string } = {};
        userDetails.previousAttrList.forEach(a => {
            attr[a.ID] = a.attributeValue;
        });
        let eventData:IEventData=await this.loggingService.getEventData(EventName.EDIT_USER,true,true,USER_MANAGEMENT_COMPONENT_ID,ContextName.EDIT_USER,userDetails.USER_ID_WITH_LOGIN_ID);
        let eventSubjects:IEventSubject[]=[]; 
        for (const e of userDetails?.ENTITLEMENT_LIST) {
            eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.ADD_ENTITLEMENT,e.COMPONENT_ID+'-'+e.ENTITLEMENT_ID+' - '+e.ENTITLEMENT_NAME+'*'+ userDetails.USER_FULL_NAME+'-'+userDetails.USER_TYPE+'-'+userDetails.USER_STATUS));
        }
        for (const e of userDetails?.DELETE_USER_ENTITLEMENT_ID_LIST)  {
            eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.REMOVE_ENTITLEMENT,null,e.COMPONENT_ID+'-'+e.ENTITLEMENT_ID+' - '+e.ENTITLEMENT_NAME+'*'+ userDetails.USER_FULL_NAME+'-'+userDetails.USER_TYPE+'-'+userDetails.USER_STATUS));
        }
        for (const r of userDetails.USER_ROLE_LIST) {
            eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.ADD_ROLE, r.ID + ' - ' + r.NAME));
        }
        for (const r of userDetails.DELETED_ROLE_INFO) {
            eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.DELETE_ROLE, null, r.ID + ' - ' + r.NAME));
        }

        // for (const r of userDetails?.DELETED_ENT_LIST_ASSOCIATED_WITH_ROLE) {
        //     eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.REMOVE_ENTITLEMENT_ACCESS,null,userDetails.USER_ID+'*'+userDetails.LOGIN_ID+'*'+r.COMPONENT_ID+'*'+r.ENTITLEMENT_ID+'*'+r.ENTITLEMENT_NAME+'#'+ userDetails.USER_FULL_NAME+'-'+userDetails.USER_TYPE+'-'+userDetails.USER_STATUS));
        // }
        // for (const r of userDetails?.ADDED_ENT_LIST_ASSOCIATED_WITH_ROLE) {
        //     eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.ADD_ENTITLEMENT_ACCESS,userDetails.USER_ID+'*'+userDetails.LOGIN_ID+'*'+r.COMPONENT_ID+'*'+r.ENTITLEMENT_ID+'*'+r.ENTITLEMENT_NAME+'#'+ userDetails.USER_FULL_NAME+'-'+userDetails.USER_TYPE+'-'+userDetails.USER_STATUS,null));
        // }

        for (const a of userDetails.USER_ATTRIBUTE_LIST) {
            let attr = userDetails.previousAttrList.find(data => data.attributeName === a.attributeName && data.ID === a.id);
            if (attr && attr.attributeValue != a.attributeValue) {
                eventSubjects.push(await this.loggingService.getEventSubjectData(Operations.EDIT + ' ' + attributeName[a.attributeName], a.attributeValue, attr.attributeValue));
            } else if (!attr) {
                eventSubjects.push(await this.loggingService.getEventSubjectData(Operations.ADD + ' ' + attributeName[a.attributeName], a.attributeValue, null));
            }
        }

        for (const a of userDetails.USER_ATTRIBUTE_DELETED_LIST) {
            eventSubjects.push(await this.loggingService.getEventSubjectData(Operations.DELETE + ' ' + attributeName[a.attributeName], null, a.attributeValue));
        }
        eventData.eventSubjects = eventSubjects;
        eventDataArray.push(JSON.stringify(eventData));
        return JSON.stringify(eventDataArray);
    }

    async prepareUserEntitlementApprovals(filter: any) {
        const eventDataArray = [];
        const eventData: IEventData = await this.loggingService.getEventData(EventName.EXPAND_USER_FOR_APPROVAL, false, true, USER_MANAGEMENT_COMPONENT_ID, ContextName.EXPAND_USER_FOR_APPROVAL, filter['USER_ID'] + '-' + filter['LOGIN_ID']);
        eventDataArray.push(JSON.stringify(eventData));
        return JSON.stringify(eventDataArray);
    }

    async prepareAccountStatus(data: any) {
        const eventDataArray = [];
        let eventContextList: IEventContext[] = [];
        let eventSubjects:IEventSubject[]=[];
    
        if(data.action=='Activate')
        {
            let eventData: IEventData = {
                componentId: 1,
                isEventContext: true,
                isEventSubject: true,
                eventTypeId: await this.loggingService.getEventId(EventName.ACTIVATE_ACCOUNT),
                eventName:EventName.ACTIVATE_ACCOUNT
            }
            eventContextList.push(
                await this.loggingService.getEventContextData(ContextName.ACTIVATE_ACCOUNT, data.accountIds+' - '+data.accountName),
                await this.loggingService.getEventContextData(ContextName.ACTIVATE_ACCOUNT, data.userId+' - '+data.userName));
            
            eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.ACTIVATE_ACCOUNT,ACTIVATE,DEACTIVATE));
           
            eventData.eventContextList = eventContextList;
            eventData.eventSubjects=eventSubjects;
            eventDataArray.push(JSON.stringify(eventData));

    }
    else if(data.action=='Deactivate')
        {
            let eventData: IEventData = {
                componentId: 1,
                isEventContext: true,
                isEventSubject: true,
                eventTypeId: await this.loggingService.getEventId(EventName.DEACTIVATE_ACCOUNT),
                eventName:EventName.DEACTIVATE_ACCOUNT
            }
            eventContextList.push(
                await this.loggingService.getEventContextData(ContextName.DEACTIVATE_ACCOUNT, data.accountIds+' - '+data.accountName),
                await this.loggingService.getEventContextData(ContextName.DEACTIVATE_ACCOUNT, data.userId+' - '+data.userName));
            
            eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.DEACTIVATE_ACCOUNT,DEACTIVATE,ACTIVATE));
           
            eventData.eventContextList = eventContextList;
            eventData.eventSubjects=eventSubjects;
            eventDataArray.push(JSON.stringify(eventData));

    }
        return JSON.stringify(eventDataArray);
    }

    async prepareEndpointRoleMappingInfo(roledetails: any) {
        const eventDataArray = [];
        let contextName = '';
        
        let eventData: IEventData = {
            componentId: 1,
            isEventContext: true,
            isEventSubject: true,
            eventTypeId: await this.loggingService.getEventId(EventName.MAP_UNMAP_ENDPOINT),
            eventName:EventName.MAP_UNMAP_ENDPOINT
        }
    
    
        let eventContextList: IEventContext[] = [];
    
        for (const u of roledetails.mapList1) {
            eventContextList.push(await this.loggingService.getEventContextData(ContextName.MAP_UNMAP_ENDPOINT, u.value+' - '+u.label));
            
        }
    
        for (const u of roledetails.unmapList1) {
            eventContextList.push(await this.loggingService.getEventContextData(ContextName.MAP_UNMAP_ENDPOINT, u.value+' - '+u.label));
    
        }
    
        eventContextList.push(await this.loggingService.getEventContextData(ContextName.MAP_UNMAP_ENDPOINT,roledetails.applicationRoleId+' - '+roledetails.applicationRoleName));
        eventContextList.push(await this.loggingService.getEventContextData(ContextName.MAP_UNMAP_ENDPOINT,roledetails.userId+' - '+roledetails.userName));
    
    
        let eventSubjects:IEventSubject[]=[];
    
        if(roledetails.mapList1?.length>0){
            eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.MAP_UNMAP_ENDPOINT,MAP_ENDPOINT,UNMAP_ENDPOINT));
        }
    
        if(roledetails.unmapList1?.length>0)
            {
                eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.MAP_UNMAP_ENDPOINT,UNMAP_ENDPOINT,MAP_ENDPOINT));
    
            }
    
    
        eventData.eventContextList = eventContextList;
        eventData.eventSubjects=eventSubjects;
        eventDataArray.push(JSON.stringify(eventData));
        return JSON.stringify(eventDataArray);
    
    }

    async prepareTenantMappingInfo(tenantDetails: any) {
        const eventDataArray = [];

        let eventData: IEventData = {
            componentId: 1,
            isEventContext: true,
            isEventSubject: true,
            eventTypeId: await this.loggingService.getEventId(EventName.MAP_UNMAP_ACCOUNT),
            eventName:EventName.MAP_UNMAP_ACCOUNT
        }
    
    
        let eventContextList: IEventContext[] = [];
    
        for (const u of tenantDetails.mapList1) {
            eventContextList.push(await this.loggingService.getEventContextData(ContextName.MAP_UNMAP_ACCOUNT, u.value+' - '+u.label));
            
        }
    
        for (const u of tenantDetails.unmapList1) {
            eventContextList.push(await this.loggingService.getEventContextData(ContextName.MAP_UNMAP_ACCOUNT, u.value+' - '+u.label));
    
        }
    
        eventContextList.push(
        await this.loggingService.getEventContextData(ContextName.MAP_UNMAP_ACCOUNT,tenantDetails.tenantId+' - '+tenantDetails.tenantName),);
        await this.loggingService.getEventContextData(ContextName.MAP_UNMAP_ACCOUNT,tenantDetails.userId+' - '+tenantDetails.userName);
    
    
        let eventSubjects:IEventSubject[]=[];
    
        if(tenantDetails.mapList1?.length > 0){
            eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.MAP_UNMAP_ACCOUNT,MAP_ACCOUNT,UNMAP_ACCOUNT));
        }
    
        if(tenantDetails.unmapList1?.length > 0)
        {
            eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.MAP_UNMAP_ACCOUNT,UNMAP_ACCOUNT,MAP_ACCOUNT));

        }

    
        eventData.eventContextList = eventContextList;
        eventData.eventSubjects=eventSubjects;
        eventDataArray.push(JSON.stringify(eventData));
        return JSON.stringify(eventDataArray);
    
    }

    async prepareEditApplicationRoleInfo(data: any) {
        const eventDataArray = [];
        let eventData: IEventData = {
            componentId: 1,
            isEventContext: true,
            isEventSubject: true,
            eventTypeId: await this.loggingService.getEventId(EventName.EDIT_APPLICATION_ROLE),
            eventName:EventName.EDIT_APPLICATION_ROLE
        }
    
    
        let eventContextList: IEventContext[] = [];
        eventContextList.push(
            await this.loggingService.getEventContextData(ContextName.EDIT_APPLICATION_ROLE,data.roleId+' - '+data.roleName),
            await this.loggingService.getEventContextData(ContextName.EDIT_APPLICATION_ROLE,data.userId+' - '+data.userName));
        

            let eventSubjects:IEventSubject[]=[];
            if(data.description!=data.prevdescription)
            {
                eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.EDIT_DESCRIPTION,data.description,data.prevdescription));

            }
            if(data.default!=data.prevdefaultRole)
            {
                eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.EDIT_DEFAULT_ROLE,data.default,data.prevdefaultRole));

            }
        eventData.eventContextList = eventContextList;
        eventData.eventSubjects=eventSubjects;
        eventDataArray.push(JSON.stringify(eventData));
        return JSON.stringify(eventDataArray);
    }

    async prepareEditResourceEndpointInfo(data: any) {
        const eventDataArray = [];
        let eventData: IEventData = {
            componentId: 1,
            isEventContext: true,
            isEventSubject: true,
            eventTypeId: await this.loggingService.getEventId(EventName.EDIT_RESOURCE_ENDPOINT),
            eventName:EventName.EDIT_APPLICATION_ROLE
        }
    
    
        let eventContextList: IEventContext[] = [];
        eventContextList.push(
            await this.loggingService.getEventContextData(ContextName.EDIT_RESOURCE_ENDPOINT,data.endpointId+' - '+data.resourceName),
            await this.loggingService.getEventContextData(ContextName.EDIT_RESOURCE_ENDPOINT,data.userId+' - '+data.userName));
        

            let eventSubjects:IEventSubject[]=[];
            if(data.hasOwnProperty('resourceName'))
            {
                eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.EDIT_RESOURCENAME,data.resourceName,data.prevData.oldResourceName));

            }
            if(data.hasOwnProperty('endPointUrl'))
            {
                eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.EDIT_ENDPOINT_URL,data.endPointUrl,data.prevData.oldEndpointUrl));

            }
            if(data.hasOwnProperty('componentId'))
            {
                eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.EDIT_ENDPOINT_URL,data.componentId,data.prevData.oldComponent));

            }
            if(data.hasOwnProperty('method'))
            {
                eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.EDIT_ENDPOINT_URL,data.method,data.prevData.oldMethod));

            }
            if(data.applicationRoleRequired!=data.prevData.oldapplicationRoleRequired)
                {
                    eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.EDIT_APPLICATION_ROLE_REQUIRED,data.applicationRoleRequired,data.prevData.oldapplicationRoleRequired));
    
                }

        eventData.eventContextList = eventContextList;
        eventData.eventSubjects=eventSubjects;
        eventDataArray.push(JSON.stringify(eventData));
        return JSON.stringify(eventDataArray);
    }


    async prepareEditUserInfo(userDetails: any) {
        const eventDataArray = [];
        let attr: { [id: string]: string } = {};
        userDetails.prevattributeTableList.forEach(a => {
            attr[a.id] = a.fieldValue;
        });
        const jsonObject = JSON.parse(userDetails.userParamJson);

        let eventData:IEventData=await this.loggingService.getEventData(EventName.EDIT_USER,true,true,USER_MANAGEMENT_COMPONENT_ID,ContextName.EDIT_USER,userDetails.USER_ID_WITH_LOGIN_ID);
        let eventSubjects:IEventSubject[]=[]; 
        // for (const a of jsonObject){
        //     let attr=userDetails.prevattributeTableList.find(data=>data.fieldName===a.fieldName && data.id===a.id);

        //     if(attr.fieldName=='Multifactor Authentication')
        //         {
        //             if(attr && attr.fieldValue.value!=a.fieldValue.value){
                
        //             eventSubjects.push(await this.loggingService.getEventSubjectData(Operations.EDIT+' '+a.fieldName,a.fieldValue.value,attr.fieldValue.value));
        //             }
        //     }
        //     else{
        //         if(attr && attr.fieldValue!=a.fieldValue){
        //         eventSubjects.push(await this.loggingService.getEventSubjectData(Operations.EDIT+' '+a.fieldName,a.fieldValue,attr.fieldValue));

        //     }
        //     else if(!attr){
        //         eventSubjects.push(await this.loggingService.getEventSubjectData(Operations.ADD+' '+a.fieldName,a.fieldValue,null));
        //     }
        //  } 
            
        // }
        for (const a of jsonObject) {
            let attr = userDetails.prevattributeTableList.find(data => data.fieldName === a.fieldName && data.id === a.id);
        
            if (attr && attr.fieldName === 'Multifactor Authentication' && attr.fieldValue.value !== a.fieldValue.value) {
                eventSubjects.push(await this.loggingService.getEventSubjectData(Operations.EDIT + ' ' + 'Multi-Factor Authentication', a.fieldValue.value, attr.fieldValue.value));
            } else if (attr && attr.fieldName != 'Multifactor Authentication'&& attr && attr.fieldValue !== a.fieldValue) {
                eventSubjects.push(await this.loggingService.getEventSubjectData(Operations.EDIT + ' ' + a.fieldName, a.fieldValue, attr.fieldValue));
            } else if (!attr) {
                eventSubjects.push(await this.loggingService.getEventSubjectData(Operations.ADD + ' ' + a.fieldName, a.fieldValue, null));
            }
        }
        
        for (const a of userDetails.prevattributeTableList){
            let deleteattr=userDetails.attributeTableList.find(data=>data.fieldName===a.fieldName && data.id===a.id);

            if(!deleteattr){
                eventSubjects.push(await this.loggingService.getEventSubjectData(Operations.DELETE+' '+a.fieldName,null,a.fieldValue));
            }
    }
        eventData.eventSubjects=eventSubjects;
        eventDataArray.push(JSON.stringify(eventData));
        return JSON.stringify(eventDataArray);
}

async prepareCreateUserLogData(data:any){
    const eventDataArray = [];
    const userType = data.isExternal === "true" ? "External" : "Internal";
    const emailId= data.emails.map(emailObj => emailObj.value);

    
    let eventData:IEventData=await this.loggingService.getEventData(EventName.CREATE_USER,true,true,USER_MANAGEMENT_COMPONENT_ID,ContextName.VIEW_CREATE_USER_PAGE);
    let eventSubjects:IEventSubject[]=[
        await this.loggingService.getEventSubjectData(ItemName.NAME,data.full_name),
        await this.loggingService.getEventSubjectData(ItemName.USER_NAME,data.user_name),
        await this.loggingService.getEventSubjectData(ItemName.EMAIL_ID,emailId),
        await this.loggingService.getEventSubjectData(ItemName.TENANT_ID,data.tenantId),
        await this.loggingService.getEventSubjectData(ItemName.USER_TYPE,userType)
    ];
    eventData.eventSubjects=eventSubjects;
    eventDataArray.push(JSON.stringify(eventData));
    return JSON.stringify(eventDataArray);
}

async prepareLogUserEventData(data:any){
    const eventDataArray = [];
     if(data.ContextName==ContextName.VIEW_MANAGE_USERS_PAGE && data.EventName==EventName.MANAGE_USERS || data.ContextName==ContextName.FILTER_BY_USER_STATUS && data.EventName==EventName.MANAGE_USERS){
        
        let eventData:IEventData;
         eventData=await this.loggingService.getEventData(data.EventName,false,true,USER_MANAGEMENT_COMPONENT_ID,data.ContextName,data.value!=null?data.value:null);
         eventDataArray.push(JSON.stringify(eventData));
         return JSON.stringify(eventDataArray);
    }else if(data.ContextName==ContextName.ACTIVATE_USER && data.EventName==EventName.ACTIVATE_USER || 
        data.ContextName==ContextName.DEACTIVATE_USER && data.EventName==EventName.DEACTIVATE_USER ||
        data.ContextName==ContextName.SUSPEND_USER && data.EventName==EventName.SUSPEND_USER||
        data.ContextName==ContextName.FORCE_LOG_OFF_USER && data.EventName==EventName.FORCE_LOG_OFF_USER||
        data.ContextName==ContextName.UNLOCK_USER && data.EventName==EventName.UNLOCK_USER||
        data.ContextName==ContextName.DELETE_USER && data.EventName==EventName.DELETE_USER
    ){
        let eventContextList: IEventContext[] = [];

        let eventData: IEventData = {
            componentId: 1,
            isEventContext: true,
            isEventSubject: true,
            eventTypeId: await this.loggingService.getEventId(data.EventName),
            eventName:data.EventName
        }
        //for (const u of data.value) {
            const contextData = await this.loggingService.getEventContextData(data.ContextName, data.value.umPkId+' - '+data.value.userName);
            eventContextList.push(contextData);
       // }
        let eventSubjectList: IEventSubject[] = [];
        //for (const u of data.value) {
            const subjectData = await this.loggingService.getEventSubjectData(data.EventName,data.newValue,data.value.userStatus);
            eventSubjectList.push(subjectData);
        //}
        eventData.eventContextList = eventContextList;
        eventData.eventSubjects = eventSubjectList;
        eventDataArray.push(JSON.stringify(eventData));
        return JSON.stringify(eventDataArray);
    }else if(data.ContextName==ContextName.EDIT_USER && data.EventName==EventName.EDIT_USER){
        let attr: { [id: string]: string } = {};
        data.value.prevattributeTableList.forEach(a => {
            attr[a.id] = a.fieldValue;
        });
        const jsonObject = JSON.parse(data.value.userParamJson);

        let eventData:IEventData=await this.loggingService.getEventData(EventName.EDIT_USER,true,true,USER_MANAGEMENT_COMPONENT_ID,ContextName.EDIT_USER,data.value.USER_ID_WITH_LOGIN_ID+' - '+data.value.org_name);
        let eventSubjects:IEventSubject[]=[]; 
        // for (const a of jsonObject){
        //     let attr=userDetails.prevattributeTableList.find(data=>data.fieldName===a.fieldName && data.id===a.id);

        //     if(attr.fieldName=='Multifactor Authentication')
        //         {
        //             if(attr && attr.fieldValue.value!=a.fieldValue.value){
                
        //             eventSubjects.push(await this.loggingService.getEventSubjectData(Operations.EDIT+' '+a.fieldName,a.fieldValue.value,attr.fieldValue.value));
        //             }
        //     }
        //     else{
        //         if(attr && attr.fieldValue!=a.fieldValue){
        //         eventSubjects.push(await this.loggingService.getEventSubjectData(Operations.EDIT+' '+a.fieldName,a.fieldValue,attr.fieldValue));

        //     }
        //     else if(!attr){
        //         eventSubjects.push(await this.loggingService.getEventSubjectData(Operations.ADD+' '+a.fieldName,a.fieldValue,null));
        //     }
        //  } 
            
        // }
        for (const a of jsonObject) {
            let attr = data.value.prevattributeTableList.find(data => data.fieldName === a.fieldName && data.id === a.id);
        
            if (attr && attr.fieldName === 'Multifactor Authentication' && attr.fieldValue.value !== a.fieldValue.value) {
                eventSubjects.push(await this.loggingService.getEventSubjectData(Operations.EDIT + ' ' + 'Multi-Factor Authentication', a.fieldValue.value, attr.fieldValue.value));
            } else if (attr && attr.fieldName != 'Multifactor Authentication'&& attr && attr.fieldValue !== a.fieldValue) {
                eventSubjects.push(await this.loggingService.getEventSubjectData(Operations.EDIT + ' ' + a.fieldName, a.fieldValue, attr.fieldValue));
            } else if (!attr) {
                eventSubjects.push(await this.loggingService.getEventSubjectData(Operations.ADD + ' ' + a.fieldName, a.fieldValue, null));
            }
        }
        
        for (const a of data.value.prevattributeTableList){
            let deleteattr=data.value.attributeTableList.find(data=>data.fieldName===a.fieldName && data.id===a.id);

            if(!deleteattr){
                eventSubjects.push(await this.loggingService.getEventSubjectData(Operations.DELETE+' '+a.fieldName,null,a.fieldValue));
            }
    }
        eventData.eventSubjects=eventSubjects;
        eventDataArray.push(JSON.stringify(eventData));
        return JSON.stringify(eventDataArray);
    }else if(data.ContextName==ContextName.VIEW_CREATE_USER_PAGE && data.EventName==EventName.CREATE_USER){
        const userType = data.value.isExternal === "true" ? "External" : "Internal";
        const emailId= data.value.emails.map(emailObj => emailObj.value)[0];
    
        
        let eventData:IEventData=await this.loggingService.getEventData(EventName.CREATE_USER,true,true,USER_MANAGEMENT_COMPONENT_ID,ContextName.VIEW_CREATE_USER_PAGE);
        let eventSubjects:IEventSubject[]=[];

            eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.NAME,data.value.full_name));
            eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.USER_NAME,data.value.user_name));
            eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.EMAIL_ID,emailId));
            eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.USER_TYPE,userType));
            eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.ORGANIZATION_NAME,data.value.org_name));
        eventData.eventSubjects=eventSubjects;
        eventDataArray.push(JSON.stringify(eventData));
        return JSON.stringify(eventDataArray);
    }
    
    // let eventSubjects:IEventSubject[]=[
    //     await this.loggingService.getEventSubjectData(ItemName.NAME,data.full_name),
    //     await this.loggingService.getEventSubjectData(ItemName.USER_NAME,data.user_name),
    //     await this.loggingService.getEventSubjectData(ItemName.EMAIL_ID,emailId),
    //     await this.loggingService.getEventSubjectData(ItemName.TENANT_ID,data.tenantId),
    //     await this.loggingService.getEventSubjectData(ItemName.USER_TYPE,userType)
    // ];
    //eventData.eventSubjects=eventSubjects;

}
}


