import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PagesService } from '../../pages.service';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { error } from 'console';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'jhi-DownloadAttribute',
  templateUrl: './DownloadAttribute.component.html',
  styleUrls: ['./DownloadAttribute.component.scss'],
  providers: [MessageService, DialogService],
})
export class DownloadAttributeComponent implements OnInit {
  DownloadAttributeForm = this.fb.group({
    select: [null, [Validators.required]],
    selectedAttributeTypes: [null, []],
  });
  pageloadvalues: any;

  configData:any;
  applicationId:any;
  attributeTypeList: any[] = [];
  messageFlag = true;
  disableSubmitButoon=true;


  selectList: any[] = [];
  userListList: any[] = [];
  selectedUsersList: any[] = [];
  exUserList: any[] = [];
  newUserList: any[] = [];
  savedId: any;
  msg = '';
  cnt: any;
  successCount = 0;
  failCount = 0;
  dialogFlag: any;
  ID: any;
  selectedName: any;
  enableBtn = true;
  usercnt: any;
  wfFlag: boolean = false;

  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    private messageService: MessageService,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    const navigation = this.router.getCurrentNavigation();
    const data1 = navigation?.extras?.state?.data;
    if (data1) {
      this.DownloadAttributeForm.patchValue(JSON.parse(data1));
    }
  }

  ngOnInit(): void {
    
    
    this.configData=this.config?.data;
    this.applicationId=this.configData?.applicationId;
    
    console.log("this.config?.data?.applicationId: "+this.config?.data?.applicationId);

    
    if(this.applicationId){
      
      this.loadAttributeTypes();


      
    }else{
      console.log("Application ID not selected Close dialoag ");
      this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Application not selected.' });
      this.msg='error';
      this.ref.close(this.msg);
    }


    
  }

  onClose($event: UntypedFormGroup): void {
    this.ref.close();
  }

  //ATTRIBUTE TYPE TAB
  // get attribute list API
  loadAttributeTypes(): void {
    
    this.pagesService.getAttributeTypeListing(this.applicationId).subscribe((results: any) => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        this.attributeTypeList = responseData;
      }
    });

  }

  enableSubmitButton(){
    let list = this.DownloadAttributeForm.value.selectedAttributeTypes;
    if(list!=null && list.length>0){
      this.disableSubmitButoon=false;
      
    }else{
      this.disableSubmitButoon=true;
    }
    
  }
  
  downloadHostAttributeHierarchy( ): void {
    
    let requestBody = {};
    
    requestBody["applicationId"]=this.applicationId;    
    requestBody["attributeTypeIds"]=this.DownloadAttributeForm.value.selectedAttributeTypes;
    this.pagesService.downloadHostAttributeHierarchy(requestBody).subscribe(
      {
      next: results => {
      
      const blob = new Blob([results.body], { type: 'application/octet-stream' });
      let url = window.URL.createObjectURL(blob);
      let anchor = document.createElement("a");
      this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'Attribute hierarchy exported Successfully.' });
      anchor.href = url;
      let d = new Date();
      let timestamp = new Date(d.getTime() - (d.getTimezoneOffset()*60*1000)).toISOString().replace('T','_');
      timestamp = timestamp.substring(0,timestamp.length-1);
      anchor.download = 'Export_Attributes_Hierarchy_'+timestamp+'.xlsx';
      
      anchor.click();

      let apiResponse = { type:'success', message:'Attribute hierarchy exported Successfully.' }
     // this.msg='Attribute hierarchy exported Successfully.';
      this.ref.close(apiResponse);
    },
    error: (err:HttpErrorResponse) => {
      
      let responseStatusCode = err.status;
      let errorMessage = 'Failed to export attributes.';

      if(responseStatusCode==400){
        errorMessage="Attribute types cannot be null or empty";
        
      }else if(responseStatusCode==404){
        errorMessage="Attributes not found for selected attribute type";
      }
      
      let apiResponse = { type:'error', message:errorMessage }
      
      this.ref.close(apiResponse);
    }});

    

  }

  // assigning user to role and usergroups functionality
  AssignUsersToRoleandUserGroup($event: UntypedFormGroup): void {
    $event.updateValueAndValidity();
    this.newUserList = this.DownloadAttributeForm.value.userList;
    const assignList = this.newUserList.filter(x => !this.exUserList.includes(x));
    const formValues = $event.value.userList;
    const req = { assignList: assignList, deAssignList: [] };
    this.cnt = formValues.length;
    if (this.dialogFlag == 'roles' || this.dialogFlag == 'viewroles')
    // assigning user to roles API
    {
      this.pagesService.AssignUsersToRoles(req, this.ID).subscribe(
        {
          next: results => {
            for (let i = 0; i < this.cnt; i++) {
              if (results.success.result.AssingUsersToRole[i]?.messageCode == 'CIAM 151') {
                this.successCount = this.successCount + 1;
              } else if (results.success.result.AssingUsersToRole[i]?.messageCode == 'CIAM 111') {
                this.failCount = this.failCount + 1;
              }
              else if (results.success.result.AssingUsersToRole[i]?.messageCode == 'CIAM 101') {
                this.failCount = this.failCount + 1;
              }
            }
            if (this.failCount == 0 && this.dialogFlag == 'roles' && this.successCount > 0) {
              this.msg = this.successCount + ' users have been assigned to ' + this.savedId.displayName;
            } else if (this.failCount == 0 && this.dialogFlag == 'viewroles' && this.successCount > 0) {
              this.msg = this.successCount + ' users have been assigned to ' + this.savedId.roleName;
            } else if (this.failCount > 0 && this.dialogFlag == 'viewroles' && this.successCount > 0) {
              this.msg = this.successCount + ' users have been assigned to ' + this.savedId.roleName + ' and ' + this.failCount + ' failed';
            } else if (this.failCount > 0 && this.dialogFlag == 'roles' && this.successCount > 0) {
              this.msg =
                this.successCount + ' users have been assigned to ' + this.savedId.displayName + ' and ' + this.failCount + ' failed';
            } else if (this.successCount == 0) {
              this.msg = 'error';
            }
            this.ref.close(this.msg);
          },
          error: () => {
            this.msg = 'error';
            this.ref.close(this.msg);
          }
        });
    } else if (this.dialogFlag == 'group' || this.dialogFlag == 'viewgroup')
    // assigning users to user groups API
    {
      const reqBody = {};
      reqBody['tenantId'] = this.pagesService.tenantId;
      reqBody['groupId'] = this.ID;
      reqBody['assignList'] = assignList;
      reqBody['deAssignList'] = [];
      this.pagesService.AssignUsersToGroups(reqBody).subscribe(
        {
          next: results => {
            for (let i = 0; i < this.cnt; i++) {
              if (results.success.result.AssingUserToGroup[i]?.messageCode == 'CIAM 151') {
                this.successCount = this.successCount + 1;
                this.wfFlag = false;
              } else if (results.success.result.AssingUserToGroup[i]?.messageCode == 'CIAM 111') {
                this.failCount = this.failCount + 1;
                this.wfFlag = false;
              } else if (results.success.result.AssingUserToGroup[i]?.messageCode == 'CIAM 154') {
                this.failCount = this.failCount + 1;
                this.wfFlag = true;
              }
            }
            if (this.wfFlag) {
              this.ref.close();
              this.navigateToMyReq('Assign/Deassign User to User Group request has been sent for approval');
            }
            else if (!this.wfFlag) {
              if ((this.failCount == 0 && this.dialogFlag == 'group' && this.successCount > 0) || (this.failCount == 0 && this.dialogFlag == 'viewgroup' && this.successCount > 0)) {
                this.msg = this.successCount + ' users have been assigned to ' + this.savedId.displayName;
              }
              else if ((this.failCount > 0 && this.dialogFlag == 'viewgroup' && this.successCount > 0) || (this.failCount > 0 && this.dialogFlag == 'group' && this.successCount > 0)) {
                this.msg = this.successCount + ' users have been assigned to ' + this.savedId.displayName + ' and ' + this.failCount + ' failed';
              }
              else if (this.successCount == 0) {
                this.msg = 'error';
              }

              this.ref.close(this.msg);
            }
          },
          error: error => {
            if (error.error?.error?.messageCode == "CIAM 105") {
              this.ref.close('Request already available for approval');
            }
            else {
              this.ref.close('Failed to assign user');
            }
          }
        });
    }
  }

  navigateToMyReq(msg: any): void {
    const navigationExtras: NavigationExtras = {
      state: {
        msg: msg,
      },
    };
    this.router.navigate(['/pages/myrequestslisting'], navigationExtras);
  }

  // selected user list of roles to disable
  getSelectedRoleUserList(): void {
    this.pagesService.getuserTableDataViewRole(this.ID).subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
            inactive: null,
          };
          data.label = element.full_name;
          data.value = element.id;
          data.inactive = true;
          this.selectedUsersList.push(data.value);
        });
        this.selectedUsersList = [...this.selectedUsersList];
        this.exUserList = this.selectedUsersList;
      }
    });
    this.getuserList();
  }

  // selected user list of usergroups to disable
  getSelectedGroupUserList(): void {
    this.pagesService.getuserTableDataViewGroup(this.ID).subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.full_name;
          data.value = element.id;
          this.selectedUsersList.push(data.value);
        });
        this.selectedUsersList = [...this.selectedUsersList];
      }
    });
    this.getuserList();
  }

  //disabling the already selected users
  getuserList(): void {
    this.pagesService.getUserListingExD().subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
            inactive: null,
          };
          data.label = element.label?.replace(/\s+/g, ' ').trim();
          data.value = element.value;
          for (let i = 0; i < responseData.length; i++) {
            if (data.value == this.selectedUsersList[i]) {
              data.inactive = true;
              break;
            }
            else {
              data.inactive = false;
            }
          }
          this.userListList.push(data);
        });
        this.userListList = [...this.userListList];
      }
    });
  }

  // get existing userlist in roles
  getExRoleUserList() {
    this.pagesService.getuserTableDataViewRole(this.ID).subscribe(results => {
      const responseData = results.success.result;
      this.exUserList = [];
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.displayName;
          data.value = element.id;
          this.exUserList.push(data.value);
        });
        this.exUserList = [...this.exUserList];
      }
    });
  }

  //getting existing user list of usergroups
  getExGroupUserList() {
    this.pagesService.getuserTableDataViewGroup(this.ID).subscribe(results => {
      const responseData = results.success.result;
      this.exUserList = [];
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.first_name;
          data.value = element.id;
          this.exUserList.push(data.value);
        });
        this.exUserList = [...this.exUserList];
      }
    });
  }

}
