<div class="layout-content" style="background-color:#F6F8FA;" (click)="mouseLeave()">
    <div class="p-d-flex p-jc-end">
        <p-messages key="myKey1" class="custom-alert-box" *ngIf="this.messageFlag"></p-messages>
    </div>
    <form [formGroup]="APIAccessTokenForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class=" p-col-12">
                <div class="ui-fluid p-formgrid p-grid">
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 left">
                        <div class=" p-fluid   p-field    ">
                            <p-breadcrumb [model]="ccprimebreadcrumbList" [home]="homeiconccprimebreadcrumb">
                            </p-breadcrumb>
                        </div>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                        <h5 class="custom-head1">API Access Token</h5>
                        <div class="card">
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                                <div class=" p-fluid   p-field    ">
                                    <div class="p-fluid p-formgrid p-grid" style="padding-top:8px ;">
                                        <div class="p-col-12">
                                            <div *ngIf="flag1" class="div-icon">
                                                <label class="d1-icons group1-icon"></label><br>
                                                <label class="e-error">No Access Token API Found</label><br>
                                                <br>
                                                <div class="btnwrap">
                                                    <button pButton type="button" pButton label="Add New Role"
                                                        (click)="GenerateToken();"
                                                        class="p-button-outlined p-button-danger"></button>
                                                </div>
                                            </div>
                                            <div class="ui-fluid p-formgrid p-grid" *ngIf="!flag1">
                                                <div
                                                    class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                                                    <div class=" p-fluid   p-field    ">
                                                        <span class=" p-input-icon-left">
                                                            <input type="text" id="textField_id" name="textField_name"
                                                                formControlName="textField" pInputText
                                                                placeholder="Search"
                                                                class="p-inputtext inputtextHeight  "
                                                                (input)="tableid.filterGlobal($event.target.value, 'contains')"
                                                                style="width: 304px;" />
                                                            <i aria-hidden="true" class="pi pi-search"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div
                                                    class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                                                </div>
                                                <div
                                                    class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined p-right">
                                                    <div class="btnwrap">
                                                        <button pButton class="p-button-primary" type="button"
                                                            (click)="GenerateToken();" label="Generate Token">
                                                        </button>
                                                    </div>
                                                </div>
                                                <div
                                                    class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                                                    <div class="  p-field    ">
                                                        <div class="mobilefriendlytable" responsiveLayout="stack"
                                                            [ngClass]="{'content-section implementation': true}">
                                                            <p-table #tableid [value]="tableList" name="table"
                                                                [(selection)]="table" [columns]="tablecols"
                                                                styleClass="p-datatable-customers" [rows]="10"
                                                                [showCurrentPageReport]="true"
                                                                [rowsPerPageOptions]="[10,25,50]" [paginator]="true"
                                                                [showCurrentPageReport]="true" sortMode="single"
                                                                scrollHeight="250px" [scrollable]="true"
                                                                [globalFilterFields]="['name','description','displayName']">
                                                                <ng-template pTemplate="header">
                                                                    <tr>
                                                                        <th pSortableColumn="displayName" scope="col">
                                                                            <div
                                                                                class="p-d-flex p-jc-between p-ai-center">
                                                                                Token Name
                                                                                <i class="p-sortable-column-icon icon-sort"
                                                                                    ariaLabel="Activate to sort"
                                                                                    ariaLabelDesc="Activate to sort in descending order"
                                                                                    ariaLabelAsc="Activate to sort in ascending order"
                                                                                    field="displayName"></i>
                                                                                <p-columnFilter type="text"
                                                                                    field="displayName" display="menu"
                                                                                    class="p-ml-auto"></p-columnFilter>
                                                                            </div>
                                                                        </th>
                                                                        <th pSortableColumn="description" scope="col">
                                                                            <div
                                                                                class="p-d-flex p-jc-between p-ai-center">
                                                                                Created By
                                                                                <i class="p-sortable-column-icon icon-sort"
                                                                                    ariaLabel="Activate to sort"
                                                                                    ariaLabelDesc="Activate to sort in descending order"
                                                                                    ariaLabelAsc="Activate to sort in ascending order"
                                                                                    field="description"></i>
                                                                                <p-columnFilter type="text"
                                                                                    field="description" display="menu"
                                                                                    class="p-ml-auto"></p-columnFilter>
                                                                            </div>
                                                                        </th>
                                                                        <th pSortableColumn="description" scope="col">
                                                                            <div
                                                                                class="p-d-flex p-jc-between p-ai-center">
                                                                                Expiry Date
                                                                                <i class="p-sortable-column-icon icon-sort"
                                                                                    ariaLabel="Activate to sort"
                                                                                    ariaLabelDesc="Activate to sort in descending order"
                                                                                    ariaLabelAsc="Activate to sort in ascending order"
                                                                                    field="description"></i>
                                                                                <p-columnFilter type="text"
                                                                                    field="description" display="menu"
                                                                                    class="p-ml-auto"></p-columnFilter>
                                                                            </div>
                                                                        </th>
                                                                        <th id="actionButton" style="width: 17%;">
                                                                            <div
                                                                                class="p-d-flex p-jc-between p-ai-center">
                                                                            </div>
                                                                        </th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-rowData
                                                                    let-index="rowIndex">
                                                                    <tr style="height: 40px"
                                                                        (mouseenter)="hoverIndex=index;highlight(index)"
                                                                        (mouseleave)="hoverIndex=index;highlight(index)"
                                                                        [pSelectableRow]="rowData">
                                                                        <td (click)="navigateName(rowData)"
                                                                            style="color: #4A6DA8;font-weight: 500;">
                                                                            {{rowData.displayName}}</td>
                                                                        <td pTooltip="{{rowData.description}}"
                                                                            tooltipPosition="bottom"
                                                                            style="color: rgba(41, 41, 41, 0.8);font-weight: 400;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                                                                            {{rowData.description}}</td>
                                                                        <td pTooltip="{{rowData.description}}"
                                                                            tooltipPosition="bottom"
                                                                            style="color: rgba(41, 41, 41, 0.8);font-weight: 400;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                                                                            {{rowData.description}}</td>
                                                                        <td style="width: 17%;">
                                                                            <div class="button-details">
                                                                                <button pButton tooltipPosition="bottom"
                                                                                    style="width: 24px;height: 24px;padding: 0px;"
                                                                                    icon="g-icons regenerate-icon"
                                                                                    pTooltip="Regenerate Token"
                                                                                    class="background-transparent"
                                                                                    (click)="activeItem=rowData;RegenerateToken();">
                                                                                </button>
                                                                                <button pButton tooltipPosition="bottom"
                                                                                    pTooltip="Delete"
                                                                                    style="width: 24px;height: 24px;padding: 0px;"
                                                                                    icon="g-icons trash-icon"
                                                                                    class="background-transparent"
                                                                                    (click)="activeItem=rowData;show1();">
                                                                                </button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage">
                                                                    <tr>
                                                                        <td colspan="7" class="p-p-0 p-text-center">
                                                                            <p-card>
                                                                                <div
                                                                                    style="margin-top: 5%;height: 70px;">
                                                                                    No
                                                                                    matching records found</div>
                                                                            </p-card>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>