<div class="header1">
    <div *ngIf="sublogo">
        <div *ngIf="crossBtn" class="iconlogosub">
            <img [src]="'images/light-logo.png' | assetUrl" alt="logo" height="38" class="iconlogo">
        </div>
    </div>
    <p-menubar [model]='items'>
        <ng-template pTemplate="start">
            <button class="mobiletoggle mthide" *ngIf="crossBtn" #btn type="button" pButton icon="pi pi-times" label="" (click)="menu.hide(); menuclose()"></button>
            <button class="mobiletoggle mtshow" #btn type="button" pButton icon="pi pi-list" label="" (click)="menu.show($event);submenucheck();enableCrossBtn()"></button>
            <img [src]="'images/light-logo.png' | assetUrl" height="40" class="iconlogo" alt="logo">
        </ng-template>
        <ng-template pTemplate="end">
            <p-dropdown [options]="tenants" placeholder="Default Tenant" [filter]="true"></p-dropdown>
            <button *ngIf='webView' #btn class="profileBtn" iconPos="right" pButton icon="pi pi-angle-down" label="{{userName}}" (click)="openOverLay.toggle($event)"></button>

            <p-overlayPanel #openOverLay [style]="{width: '254px'}">
                <ng-template pTemplate>

                    <div class="flex profile-container p-3">
                        <span>
                            <h6>{{userName}}</h6>
                            <label>Myron User</label>
                        </span>
                    </div>
                    <div class="logout-container">
                        <button type="button" pButton class="p-button-info p-button p-component" icon="pi pi-sign-out" label="Log out" (click)="logout()"></button>
                    </div>
                </ng-template>
            </p-overlayPanel>
        </ng-template>
    </p-menubar>
    <div #mydiv (click)="submenucheck()">
        <p-slideMenu #menu [model]="items" styleClass='addRemove' [popup]="true" [appendTo]="mydiv" [menuWidth]="screenWidth" [viewportHeight]="screenHeight">
        </p-slideMenu>
    </div>
</div>