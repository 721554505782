<div class="layout-content custom-container">
    <form [formGroup]="AddCustomFieldForm" autocomplete="off" novalidate>
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                <div class="p-fluid p-field">
                    <label>
                        Entity
                        <span class='invalid-feedback-astrik'>*</span>
                    </label>
                    <p-dropdown [(ngModel)]="SelectedEntity" id="select1id" name="Entity" placeholder="Select"
                        [options]="selectList" [filter]="true" filterBy="label" [autoDisplayFirst]="false"
                        formControlName="Entity" (onChange)="getinputFieldTypeList();checkInput($event)">
                        <ng-template let-item pTemplate="item">
                            <div *ngIf="item.label.length <= 35;">
                                {{item.label}}
                            </div>
                            <div *ngIf="item.label.length > 35;" pTooltip="{{item.label}}" tooltipPosition="bottom">
                                {{item.label.substring(0,35) + "..." }}
                            </div>
                        </ng-template>
                    </p-dropdown>
                    <div class="p-error"
                        *ngIf="AddCustomFieldForm.get('Entity')!.invalid && (AddCustomFieldForm.get('Entity')!.dirty || AddCustomFieldForm.get('Entity')!.touched)">
                        <div *ngIf="AddCustomFieldForm.get('Entity')?.errors?.required">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium class="p-error">Entity is required.</medium>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left">
                <div class="p-fluid p-field">
                    <label [ngClass]="{'field-required': AddCustomFieldForm.get('fieldName')?.errors?.required}">
                        Field Name
                        <span class='invalid-feedback-astrik'>*</span></label>
                    <input type="text" id="fieldName_id" pattern="^[A-Z]+[a-zA-Z0-9\s_-]*[a-zA-Z0-9]$" name="fieldName"
                        formControlName="fieldName" pInputText placeholder="Field name"
                        class="p-inputtext inputtextHeight" maxlength="64"
                        (keyup)="this.nameFlag=false;checkInput($event);colorChange();" />
                    <div class="p-error"
                        *ngIf="AddCustomFieldForm.get('fieldName')!.invalid && (AddCustomFieldForm.get('fieldName')!.dirty || AddCustomFieldForm.get('fieldName')!.touched)">
                        <div *ngIf="AddCustomFieldForm.get('fieldName')?.errors?.required">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium class="p-error">
                                Field name is required.</medium>
                        </div>
                        <div *ngIf="AddCustomFieldForm.get('fieldName').errors.pattern">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium class="p-error">Field Name should start with uppercase and only dash, underscore,
                                space are allowed as special characters
                            </medium>
                        </div>
                    </div>
                    <div class="p-error" *ngIf="this.nameFlag">
                        <label class="alert-icon alert-circle-icon" for="fieldname"></label>
                        <span id="rolename" style="display:inline-block;vertical-align: bottom;" class="p-error">Field
                            name already exists!
                        </span>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left">
                <div class="p-fluid p-field">
                    <label>Field Input Type
                        <span class='invalid-feedback-astrik'>*</span>
                    </label>
                    <p-dropdown id="selectid" name="fieldinputtype" placeholder="Select" [options]="fieldtype"
                        [filter]="true" filterBy="label" [autoDisplayFirst]="false" formControlName="fieldInputType"
                        (onChange)="fieldtypevalidate();getInputformat();checkInput($event)">
                        <ng-template let-item pTemplate="item">
                            <div *ngIf="item.label.length <= 35;">
                                {{item.label}}
                            </div>
                            <div *ngIf="item.label.length > 35;" pTooltip="{{item.label}}" tooltipPosition="bottom">
                                {{item.label.substring(0,35) + "..." }}
                            </div>
                        </ng-template>
                        <ng-template let-item pTemplate="item">
                            <div class="country-item">
                                <div>{{item.label}}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                    <div class="p-error"
                        *ngIf="AddCustomFieldForm.get('fieldInputType')!.invalid && (AddCustomFieldForm.get('fieldInputType')!.dirty || AddCustomFieldForm.get('fieldInputType')!.touched)">
                        <div *ngIf="AddCustomFieldForm.get('fieldInputType')?.errors?.required">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium class="p-error">Field Input Type is required.</medium>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left" id="check"
                *ngIf="!this.checkbox">
                <div class="p-fluid p-field">
                    <label>
                        Input Values/Format
                        <span class='invalid-feedback-astrik'>*</span>
                    </label>
                    <input type="text" id="inputformat_id" name="inputformat" (keyup)="this.defaultFlag=false;"
                        formControlName="inputValueFormattext" pInputText placeholder="Enter Array of Values to Input"
                        class="p-inputtext inputtextHeight" *ngIf="this.inputFlag"
                        pattern="^[a-zA-Z0-9!@#\$%\^\&*\?\)\(+=._-]+([a-zA-Z0-9!@#\$%\^\&*\?\)\(+=._-],[a-zA-Z0-9!@#\$%\^\&*\?\)\(+=._-]+)*[a-zA-Z0-9!@#\$%\^\&*\?\)\(+=._-]+$"
                        required />
                    <div *ngIf="this.defaultFlag && this.AddCustomFieldForm.value.inputformat==null && this.inputFlag">
                        <label class="alert-icon alert-circle-icon1"></label><span
                            style="font-size: 12px;font-weight: 400;color: #505870;vertical-align: middle;"
                            class="p-ml-1">Use comma to seperate values</span>
                    </div>
                    <div class="invalid-feedback" *ngIf="AddCustomFieldForm.get('inputValueFormattext')!.invalid &&
                            																	(AddCustomFieldForm.get('inputValueFormattext')!.dirty ||
                                                                                                AddCustomFieldForm.get('inputValueFormattext')!.touched)
                            																">
                        <div *ngIf="AddCustomFieldForm.get('inputValueFormattext').errors.pattern">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium class="p-error">Please Enter comma separated value.
                            </medium>
                        </div>
                    </div>
                    <p-multiSelect filterBy="label" [(ngModel)]="SelectTreevalue"
                        *ngIf="!this.inputFlag && !this.rangeFlag" id="userList" name="userList"
                        filterPlaceHolder="Select Input Format" [options]="inputformat" [filter]="true"
                        formControlName="inputValueFormat" optionLabel="label" optionValue="value" display="chip"
                        resetFilterOnHide="true" placeholder="Select Input Format" class="custom-chips"
                        (onChange)="specialcharacter();getSpecialCharacter()"></p-multiSelect>
                    <p-dropdown id="select1id" name="inputformat" placeholder="Select" [options]="inputformat"
                        [filter]="true" filterBy="label" [autoDisplayFirst]="false"
                        formControlName="inputValueFormatDropdown" *ngIf="this.rangeFlag"></p-dropdown>
                    <div class="p-error"
                        *ngIf="AddCustomFieldForm.get('inputValueFormat')!.invalid && (AddCustomFieldForm.get('inputValueFormat')!.dirty || AddCustomFieldForm.get('inputValueFormat')!.touched)">
                        <div *ngIf="AddCustomFieldForm.get('inputValueFormat')?.errors?.required">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium class="p-error">Input Values/Format is required.</medium>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left" *ngIf="this.specialFlag"
                id="maxchar" style="margin-top: 4px !important;">
                <label
                    [ngClass]="{'field-required': AddCustomFieldForm.get('inputValueFormatdemo1')?.errors?.required}">
                    Special Characters <span class='invalid-feedback-astrik'>*</span></label>

                <p-multiSelect filterBy="label" id="userList" [(ngModel)]="Selectspecial" name="userList"
                    filterPlaceHolder="Select Input Format" [options]="SpecialCharacter" [filter]="true"
                    formControlName="inputValueFormatdemo1" optionLabel="label" optionValue="value" display="chip"
                    resetFilterOnHide="true" placeholder="Select Input Format" class="custom-chips">
                </p-multiSelect>
                <div class="p-error"
                    *ngIf="AddCustomFieldForm.get('inputValueFormatdemo1')!.invalid && (AddCustomFieldForm.get('inputValueFormatdemo1')!.dirty || AddCustomFieldForm.get('inputValueFormatdemo1')!.touched)">
                    <div *ngIf="AddCustomFieldForm.get('inputValueFormatdemo1')?.errors?.required">
                        <label class="alert-icon alert-circle-icon"></label>
                        <medium class="p-error">
                            Special Characters is required.</medium>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left" *ngIf="this.maxlengthFlag"
                id="maxchar">
                <label [ngClass]="{'field-required': AddCustomFieldForm.get('MaxCharacters')?.errors?.required}">
                    Max. no. of characters
                    <span class='invalid-feedback-astrik'>*</span></label>
                <input type="text" id="inputformat_id" name="maxcharacters" formControlName="MaxCharacters" pInputText
                    placeholder="Enter Max. no. of characters" class="p-inputtext inputtextHeight" pattern="^[0-9]\d*$"
                    required style="margin-top: 4px;" />
                <div class="p-error"
                    *ngIf="AddCustomFieldForm.get('MaxCharacters')!.invalid && (AddCustomFieldForm.get('MaxCharacters')!.dirty || AddCustomFieldForm.get('MaxCharacters')!.touched)">
                    <div *ngIf="AddCustomFieldForm.get('MaxCharacters')?.errors?.required">
                        <label class="alert-icon alert-circle-icon"></label>
                        <medium class="p-error">
                            Max Characters is required.</medium>
                    </div>
                    <div *ngIf="AddCustomFieldForm.get('MaxCharacters').errors.pattern">
                        <label class="alert-icon alert-circle-icon"></label>
                        <medium class="p-error">Please enter numeric value.
                        </medium>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-2 p-xl-2 p-offset-undefined left p-pt-5" id="mandatory"
                *ngIf="!this.checkbox" style="margin-top: 8px;">
                <div class="p-field-checkbox">
                    <p-checkbox name="mandatory" [(ngModel)]="checkedmandatory" binary="true" inputId="binary"
                        formControlName="mandatory">
                    </p-checkbox>
                    <label for="ny">Mandatory field</label>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-2 p-xl-2 p-offset-undefined left p-pt-5" id="editable"
                *ngIf="!this.checkbox" style="margin-top: 8px;">
                <div class="p-field-checkbox">
                    <p-checkbox name="editable" [(ngModel)]="checkededitable" binary="true" inputId="binary"
                        formControlName="editable">
                    </p-checkbox>
                    <label for="sf">Editable field</label>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right p-mb-2">
                <div class="btnwrap p-mr-3">
                    <button pButton type="button" class="p-button-brand2" label="Close" (click)="onClose();">
                    </button>
                </div>
                <div class="btnwrap">
                    <button pButton class="p-button-primary" type="submit" label="Save"
                        (click)="onSaveCheckFieldName();" [disabled]="!AddCustomFieldForm.valid || this.nameFlag">
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>