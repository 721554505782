<div class="layout-content custom-container">
    <form [formGroup]="DeleteAttributeForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class=" p-col-12">
                <div class="ui-fluid p-formgrid p-grid">
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left p-pl-0 p-pr-0">
                        <div class=" p-fluid   p-field    ">
                            <p>Are you sure you want to
                                delete the selected Attribute?</p>
                        </div>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right p-pl-0 p-pr-0">
                        <div class="btnwrap p-mr-2">
                            <button pButton type="button" class="p-button-brand2"
                                (click)="onClickNo(DeleteAttributeForm);" label="No">
                            </button>
                        </div>
                        <div class="btnwrap">
                            <button pButton class="p-button-primary" type="button" (click)="deleteAttributeResource();"
                                label="Yes">
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>