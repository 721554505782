<div class="layout-content custom-container">
    <p-messages severity="info" *ngIf="messageFlag" class="custom-msg">
        <ng-template pTemplate>
            <span class="p-message-icon pi pi-info-circle"></span>
            <span class="p-message-detail" style="font-size: 13px;">Please note that any edit action would impact the
                existing records.</span>
            <button (click)="clearmsg()" type="button" pripple="" class="p-message-close p-link">
                <i aria-hidden="true" class="p-message-close-icon pi pi-times"></i>
            </button>
        </ng-template>
    </p-messages>
    <form [formGroup]="EditCustomFieldForm" autocomplete="off" novalidate>
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-col-12 p-p-0 p-mt-3">
                <div class="ui-fluid p-formgrid p-grid">
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                        <div class="p-fluid p-field">
                            <label>
                                Entity
                                <span class='invalid-feedback-astrik'>*</span>
                            </label>
                            <p-dropdown id="select1id" name="Entity" placeholder="{{this.entityname}}" [filter]="true"
                                filterBy="label" [autoDisplayFirst]="false" formControlName="Entity">
                            </p-dropdown>
                            <div class="p-error"
                                *ngIf="EditCustomFieldForm.get('Entity')!.invalid && (EditCustomFieldForm.get('Entity')!.dirty || EditCustomFieldForm.get('Entity')!.touched)">
                                <div *ngIf="EditCustomFieldForm.get('Entity')?.errors?.required">
                                    <label class="alert-icon alert-circle-icon"></label>
                                    <medium class="p-error">Entity is required.</medium>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left">
                        <div class="p-fluid p-field">
                            <label
                                [ngClass]="{'field-required': EditCustomFieldForm.get('fieldName')?.errors?.required}">
                                Field Name
                                <span class='invalid-feedback-astrik'>*</span></label>
                            <input type="text" id="fieldName_id" pattern="^[A-Z]+[a-zA-Z0-9\s_-]*[a-zA-Z0-9]$"
                                name="fieldName" [(ngModel)]="fieldname" formControlName="fieldName" pInputText
                                placeholder="Field name" class="p-inputtext inputtextHeight" maxlength="64"
                                (keyup)="this.nameFlag=false;this.enablebtn=true;colorChange();" />
                            <div class="p-error"
                                *ngIf="EditCustomFieldForm.get('fieldName')!.invalid && (EditCustomFieldForm.get('fieldName')!.dirty || EditCustomFieldForm.get('fieldName')!.touched)">
                                <div *ngIf="EditCustomFieldForm.get('fieldName')?.errors?.required">
                                    <label class="alert-icon alert-circle-icon"></label>
                                    <medium class="p-error">
                                        Field name is required.</medium>
                                </div>
                                <div *ngIf="EditCustomFieldForm.get('fieldName').errors.pattern">
                                    <label class="alert-icon alert-circle-icon"></label>
                                    <medium class="p-error">Field Name should start with uppercase and only dash,
                                        underscore, space are allowed as special characters
                                    </medium>
                                </div>
                            </div>
                            <div class="p-error" *ngIf="this.nameFlag">
                                <label class="alert-icon alert-circle-icon" for="fieldname"></label>
                                <span id="rolename" style="display:inline-block;vertical-align: bottom;"
                                    class="p-error">Field name already exists!
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left">
                        <div class="p-fluid p-field">
                            <label>Field Input Type
                                <span class='invalid-feedback-astrik'>*</span>
                            </label>
                            <p-dropdown id="selectid" name="fieldinputtype" placeholder="{{this.field_type}}"
                                [filter]="true" filterBy="label" [autoDisplayFirst]="false"
                                formControlName="fieldInputType">
                            </p-dropdown>
                            <div class="p-error"
                                *ngIf="EditCustomFieldForm.get('fieldInputType')!.invalid && (EditCustomFieldForm.get('fieldInputType')!.dirty || EditCustomFieldForm.get('fieldInputType')!.touched)">
                                <div *ngIf="EditCustomFieldForm.get('fieldInputType')?.errors?.required">
                                    <label class="alert-icon alert-circle-icon"></label>
                                    <medium class="p-error">Field Input Type is required.</medium>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left" id="check"
                        *ngIf="!this.checkbox">
                        <div class="p-fluid p-field">
                            <label>
                                Input Values/Format
                                <span class='invalid-feedback-astrik'>*</span>
                            </label>
                            <input type="text" id="inputformat_id" [(ngModel)]="dropdownData" name="inputformat"
                                (keyup)="this.defaultFlag=false;" formControlName="inputValueFormattext" pInputText
                                placeholder="Enter Array of Values to Input" class="p-inputtext inputtextHeight"
                                *ngIf="this.inputFlag"
                                pattern="^[a-zA-Z0-9!@#\$%\^\&*\?\)\(+=._-]+([a-zA-Z0-9!@#\$%\^\&*\?\)\(+=._-],[a-zA-Z0-9!@#\$%\^\&*\?\)\(+=._-]+)*[a-zA-Z0-9!@#\$%\^\&*\?\)\(+=._-]+$"
                                (keyup)="this.enablebtn=true" required />
                            <div
                                *ngIf="this.defaultFlag && this.EditCustomFieldForm.value.inputformat==null && this.inputFlag">
                                <label class="alert-icon alert-circle-icon"></label>
                                <small id="username1-help">Use comma to seperate values</small>
                            </div>
                            <div class="invalid-feedback"
                                *ngIf="EditCustomFieldForm.get('inputValueFormattext')!.invalid &&(EditCustomFieldForm.get('inputValueFormattext')!.dirty ||EditCustomFieldForm.get('inputValueFormattext')!.touched)">
                                <div *ngIf="EditCustomFieldForm.get('inputValueFormattext').errors.pattern">
                                    <label class="alert-icon alert-circle-icon"></label>
                                    <medium class="p-error">Please Enter comma separated value.
                                    </medium>
                                </div>
                            </div>
                            <p-multiSelect filterBy="label" [(ngModel)]="SelectTreevalue" id="userList" name="userList"
                                *ngIf="!this.inputFlag && !this.rangeFlag" filterPlaceHolder="Select Input Format"
                                [options]="inputformat" [filter]="true" formControlName="inputValueFormat"
                                optionLabel="label" optionValue="value" display="chip" resetFilterOnHide="true"
                                placeholder="Select Input Format" class="custom-chips"
                                (onChange)="specialcharacter();this.enablebtn=true">
                            </p-multiSelect>
                            <p-dropdown id="select1id" [(ngModel)]="rangeFormatData" name="inputformat"
                                placeholder="Select" [options]="formatRange" [filter]="true" filterBy="label"
                                [autoDisplayFirst]="false" formControlName="inputValueFormatDropdown"
                                *ngIf="this.rangeFlag" (onChange)="this.enablebtn=true"></p-dropdown>
                            <div class="p-error"
                                *ngIf="EditCustomFieldForm.get('inputValueFormat')!.invalid && (EditCustomFieldForm.get('inputValueFormat')!.dirty || EditCustomFieldForm.get('inputValueFormat')!.touched)">
                                <div *ngIf="EditCustomFieldForm.get('inputValueFormat')?.errors?.required">
                                    <label class="alert-icon alert-circle-icon"></label>
                                    <medium class="p-error">Input Values/Format is required.</medium>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left" *ngIf="this.specialFlag"
                        id="maxchar" class="p-mt-1">
                        <label
                            [ngClass]="{'field-required': EditCustomFieldForm.get('inputValueFormatdemo1')?.errors?.required}">
                            Special Characters <span class='invalid-feedback-astrik'>*</span></label>
                        <p-multiSelect filterBy="label" id="userList" name="userList" [(ngModel)]="Selectspecial"
                            filterPlaceHolder="Select Special Characters" [options]="SpecialCharacter" [filter]="true"
                            formControlName="inputValueFormatdemo1" optionLabel="label" optionValue="value"
                            display="chip" resetFilterOnHide="true" placeholder="Select Input Format"
                            class="custom-chips" (onChange)="this.enablebtn=true">
                        </p-multiSelect>
                        <div class="p-error"
                            *ngIf="EditCustomFieldForm.get('inputValueFormatdemo1')!.invalid && (EditCustomFieldForm.get('inputValueFormatdemo1')!.dirty || EditCustomFieldForm.get('inputValueFormatdemo1')!.touched)">
                            <div *ngIf="EditCustomFieldForm.get('inputValueFormatdemo1')?.errors?.required">
                                <label class="alert-icon alert-circle-icon"></label>
                                <medium class="p-error">
                                    Special Characters is required.</medium>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left"
                        *ngIf="this.maxlengthFlag" id="maxchar">
                        <label
                            [ngClass]="{'field-required': EditCustomFieldForm.get('MaxCharacters')?.errors?.required}">
                            Max. no. of characters
                            <span class='invalid-feedback-astrik'>*</span></label>
                        <input type="text" id="inputformat_id" [(ngModel)]="MaxCharacters" name="maxcharacters"
                            formControlName="MaxCharacters" pInputText placeholder="Enter Max. no. of characters"
                            class="p-inputtext inputtextHeight" pattern="^[0-9]\d*$" required style="margin-top: 4px;"
                            (keyup)="this.enablebtn=true" />
                        <div class="p-error"
                            *ngIf="EditCustomFieldForm.get('MaxCharacters')!.invalid && (EditCustomFieldForm.get('MaxCharacters')!.dirty || EditCustomFieldForm.get('MaxCharacters')!.touched)">
                            <div *ngIf="EditCustomFieldForm.get('MaxCharacters')?.errors?.required">
                                <label class="alert-icon alert-circle-icon"></label>
                                <medium class="p-error">
                                    Max Characters is required.</medium>
                            </div>
                            <div *ngIf="EditCustomFieldForm.get('MaxCharacters').errors.pattern">
                                <label class="alert-icon alert-circle-icon"></label>
                                <medium class="p-error">Please enter numeric value.
                                </medium>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-2 p-xl-2 p-offset-undefined left p-pt-5" id="mandatory"
                        *ngIf="!this.checkbox" style="margin-top: 8px;">
                        <div class="p-field-checkbox">
                            <p-checkbox name="mandatory" [(ngModel)]="checkedmandatory" binary="true" inputId="binary"
                                formControlName="mandatory" (onChange)="this.enablebtn=true">
                            </p-checkbox>
                            <label for="ny">Mandatory field</label>
                        </div>
                    </div>
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-2 p-xl-2 p-offset-undefined left p-pt-5" id="editable"
                        *ngIf="!this.checkbox" style="margin-top: 8px;">
                        <div class="p-field-checkbox">
                            <p-checkbox name="editable" [(ngModel)]="checkededitable" binary="true" inputId="binary"
                                formControlName="editable" (onChange)="this.enablebtn=true">
                            </p-checkbox>
                            <label for="sf">Editable field</label>
                        </div>
                    </div>
                    <div
                        class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right custom-btn-height p-mb-2">
                        <div class="btnwrap p-pr-3">
                            <button pButton type="button" class="p-button-brand2" label="Close" (click)="onClose();">
                            </button>
                        </div>
                        <div class="btnwrap">
                            <button pButton class="p-button-primary" type="submit" label="Save"
                                (click)="onSaveCheckFieldName();"
                                [disabled]="!EditCustomFieldForm.valid || this.nameFlag || !this.enablebtn">
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>