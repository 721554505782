import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PagesService } from '../pages.service';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { EMAIL_REGEXP } from '../../constants';
@Component({
  selector: 'jhi-inviteusers',
  templateUrl: './InviteUsers.component.html',
  styleUrls: ['./InviteUsers.component.scss'],
  providers: [MessageService, DialogService],
})
export class InviteUsersComponent implements OnInit {
  InviteUsersForm = this.fb.group({
    emailAddress: [null, [Validators.required, Validators.pattern(EMAIL_REGEXP.source)]],
    select1: [null, []]
  });
  SendInviteUsersForm = this.fb.group({
    account: [null, []]
  });
  tablecols: any[] = [
    { field: 'SrNo', header: 'Sr.No' },
    { field: 'emailId', header: 'Email Id' },
    { field: ' Application', header: ' Application' },
  ];
  pageloadvalues: any;
  emailData = "";
  str = "";
  cnt: any;
  successCount = 0;
  domainCount = 0;
  duplicateCount = 0;
  invalidEmailCount = 0;
  domainEmail = [];
  domainFlag = false;
  duplicateEmail = [];
  duplicateFlag = false;
  invalidEmail = [];
  invalidEmailFlag = false;
  alreadyInvitedFlag = false;
  maxInviteeFlag = false;
  msg: string[] = [];
  errorMessage = '';
  values: number[] = [];
  chipEmailFlag = false;
  countEmail = 0;
  chipDuplicateEmail = false;
  failCount = 0;
  tableList: any[] = [];
  selectList: any[] = [];
  messageFlag = true;
  currentIndex: number = -1;
  count = 0;
  wfFlag = false;
  selectedApp: any[] = [];
  accountList: any[] = [];
  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    private messageService: MessageService,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.InviteUsersForm.patchValue(JSON.parse(data));
      this.SendInviteUsersForm.patchValue(JSON.parse(data));
    }
  }
  ngOnInit(): void {
    this.getApplicationList();
    // this.showInfo();
    if (this.pagesService.rebacFlag == 'true') {
      this.onAccountsdropdown();
      this.SendInviteUsersForm.controls['account'].setValidators(Validators.required);
    }
  }
  showInfo() {
    this.messageFlag = true;
    setTimeout(() => {
      this.messageFlag = false;
    }, 5000);
  }
  clearmsg() {
    this.messageFlag = false;
  }
  onClose($event: UntypedFormGroup): void {
    this.ref.close("Close");
  }
  onCcprimebutton1($event: UntypedFormGroup): void {
    let reqBody = {};
    this.duplicateEmail = [];
    this.domainEmail = [];
    this.invalidEmail = [];
    reqBody['emails'] = this.InviteUsersForm.value.emailAddress.toLowerCase();
    const formData = reqBody;
    const formValues = $event.value.emailAddress;
    this.cnt = formValues.length;
    this.pagesService.onInviteUser(formData, formValues).subscribe(
      {
        next: results => {
          for (let i = 0; i < this.cnt; i++) {
            if (results.success.result[i].messageCode == 'CIAM 253') {
              this.successCount = this.successCount + 1;
            }
            else if (results.success.result[i].messageCode == 'CIAM 105') {
              this.duplicateEmail.push(results.success.result[i].email);
              this.duplicateCount = this.duplicateCount + 1;
              this.duplicateFlag = true;
            }
            else if (results.success.result[i].messageCode == 'CIAM 202') {
              this.domainEmail.push(results.success.result[i].email);
              this.domainCount = this.domainCount + 1;
              this.domainFlag = true;
            }
            else if (results.success.result[i].messageCode == 'CIAM 201') {
              this.invalidEmail.push(results.success.result[i].email);
              this.invalidEmailCount = this.invalidEmailCount + 1;
              this.invalidEmailFlag = true;
            }
          }

          if (this.duplicateCount > 0 || this.domainCount > 0 || this.invalidEmailCount > 0) {
            this.InviteUsersForm.setErrors({ 'invalid': true });
          }
          if (this.successCount == 0 && this.duplicateCount == 0 && this.domainCount == 0 && this.invalidEmailCount == 0) {
            this.msg.push('error');
          }
          if (this.successCount > 0) {
            this.failCount = this.duplicateCount + this.domainCount + this.invalidEmailCount;
            if (this.failCount > 0) {
              this.msg.push(this.successCount + " user invited successfully, " + this.failCount + " failed");
            }
            else if (this.failCount == 0) {
              this.msg.push(this.successCount + " user invited successfully");
            }
            this.ref.close(this.msg);
          }
        },
        error: () => {
          this.msg.push('error');
          this.ref.close(this.msg);
        }
      });
  }
  checkDuplicateEmail(arr) {
    return new Set(arr).size !== arr.length;
  }
  checkInput(event: any) {
    this.errorMessage = '';

    const len = this.InviteUsersForm.value.emailAddress.length;

    if (this.checkDuplicateEmail(this.InviteUsersForm.value.emailAddress)) {

      this.chipDuplicateEmail = true;
      this.InviteUsersForm.setErrors({ 'invalid': true });
      this.values.pop();
    }
    else {

      this.chipDuplicateEmail = false;
    }


    if (!this.validateEmail(event.value)) {
      this.chipEmailFlag = true;
      this.InviteUsersForm.setErrors({ 'invalid': true });
      this.errorMessage = ' Email address invalid . Please check';
      this.values.pop();
    }
    else if (this.validateEmail(event.value)) {
      for (let i = 0; i < len; i++) {
        if (!this.validateEmail(this.InviteUsersForm.value.emailAddress[i])) {
          this.chipEmailFlag = true;
          this.errorMessage = ' Email address invalid . Please check';
          this.InviteUsersForm.setErrors({ 'invalid': true });
          this.values.pop();
        }
      }

    }
  }
  validateEmail(value: any) {
    const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;//NOSONAR
    return regularExpression.test(String(value).toLowerCase());
  }
  removeMsg(event: any) {
    const len = this.InviteUsersForm.value.emailAddress.length;
    if (len == 0) {
      this.errorMessage = '';
      this.domainFlag = false;
      this.duplicateFlag = false;
      this.invalidEmailFlag = false;
    }
    if (len >= 1) {
      this.errorMessage = '';
      this.domainFlag = false;
      this.duplicateFlag = false;
      this.invalidEmailFlag = false;
      if (this.checkDuplicateEmail(this.InviteUsersForm.value.emailAddress)) {
        this.chipDuplicateEmail = true;
        this.InviteUsersForm.setErrors({ 'invalid': true });
        this.values.pop();
      }
      else {
        this.chipDuplicateEmail = false;
      }
      for (let i = 0; i < len; i++) {
        if (!this.validateEmail(this.InviteUsersForm.value.emailAddress[i])) {
          this.chipEmailFlag = true;
          this.errorMessage = ' Email address invalid . Please check';
          this.InviteUsersForm.setErrors({ 'invalid': true });
          this.values.pop();
        }
      }
    }

  }

  //uncomment this
  getApplicationList(): void {
    this.pagesService.getApplicationListMapping().subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.selectList.push(data);
        });
        this.selectList = [...this.selectList];
        if (this.selectList.length == 1) {
          this.selectedApp.push(this.selectList[0].value);
        }
      }
    });
  }
  CheckEmailAvailability($event: UntypedFormGroup): void {
    const reqBody = {};
    reqBody['tenantId'] = this.pagesService.tenantId;
    reqBody['email'] = this.InviteUsersForm.value?.emailAddress.toLowerCase();
    const formData = reqBody;
    this.pagesService.inviteEmailAvailability(formData).subscribe(
      {
        next: results => {
          if (results?.error?.messageCode == "CIAM 202") {
            this.domainFlag = true;
            this.InviteUsersForm.setErrors({ 'invalid': true });
            document.getElementById('emailAddress_id')!.style.borderColor = 'red';

          }
          else if (this.checkDuplicateId(this.InviteUsersForm.value?.emailAddress)) {
            this.duplicateFlag = true;
            this.InviteUsersForm.setErrors({ 'invalid': true });
            document.getElementById('emailAddress_id')!.style.borderColor = 'red';
          }
          else if (results?.success?.messageCode == "CIAM 155") {
            document.getElementById('emailAddress_id')!.style.borderColor = 'grey';
            if (this.SendInviteUsersForm.controls[this.InviteUsersForm.value?.emailAddress]) {
              this.SendInviteUsersForm.controls[this.InviteUsersForm.value?.emailAddress].setValidators(Validators.required);
            }
            else {
              this.SendInviteUsersForm.addControl(this.InviteUsersForm.value?.emailAddress,
                this.fb.control('', Validators.required));
            }
            this.SendInviteUsersForm.controls[this.InviteUsersForm.value?.emailAddress].updateValueAndValidity();
            this.tableList.push({ email: this.InviteUsersForm.value?.emailAddress, applications: [] });
            this.InviteUsersForm.controls.emailAddress.reset();
            if (this.tableList.length == 10) {
              this.InviteUsersForm.controls['emailAddress'].disable();
              this.maxInviteeFlag = true;
            }
          }
        },
        error: error => {
          if (error.error?.error?.messageCode == "CIAM 207") {
            this.alreadyInvitedFlag = true;
            this.InviteUsersForm.setErrors({ 'invalid': true });
            document.getElementById('emailAddress_id')!.style.borderColor = 'red';
          }
          else if (error.error?.error?.messageCode == "[CIAM 201]") {
            this.invalidEmailFlag = true;
            this.InviteUsersForm.setErrors({ 'invalid': true });
            document.getElementById('emailAddress_id')!.style.borderColor = 'red';
          }
        }
      });
  }
  updateApplicationInArray(emailid: any) {
    let ee = emailid;
    this.tableList.forEach(element => {
      if (element.email == ee) {
        element.applications = this.SendInviteUsersForm.value[ee];
      }
    });
  }
  deleteEmail(index: any, emailid: any) {
    this.currentIndex = index;
    this.tableList.splice(index, 1);
    this.SendInviteUsersForm.controls[emailid].clearValidators();
    this.SendInviteUsersForm.controls[emailid].reset();
    this.SendInviteUsersForm.controls[emailid].updateValueAndValidity();
    if (this.tableList.length == 9) {
      this.InviteUsersForm.controls['emailAddress'].enable();
      this.maxInviteeFlag = false;
    }
    if (this.tableList.length == 0) {
      this.SendInviteUsersForm.setErrors({ 'invalid': true });
    }
  }
  checkDuplicateId(emailid: any) {
    for (let i = 0; i < this.tableList.length; i++) {
      if (this.tableList[i].email == emailid) {
        return true;
      }
    }
  }
  inviteUsersInApplications($event: UntypedFormGroup): void {
    const reqBody = {};
    if (this.selectList.length == 1) {
      this.tableList.forEach(element => {
        element.applications = this.selectedApp;
      });
    }
    reqBody['tenantId'] = this.pagesService.tenantId;
    reqBody['inviteUsers'] = this.tableList;
    if (this.pagesService.rebacFlag == 'true') {
      reqBody['accountId'] = this.SendInviteUsersForm.value.account;
    }
    const formData = reqBody;
    this.pagesService.inviteUserInApplication(formData).subscribe(
      {
        next: results => {
          this.inviteUserResponse(results);
        }
        ,
        error: () => {
          this.msg.push('error');
          this.ref.close(this.msg);
        }
      });
  }
  inviteUserResponse(results: any) {
    let duplicateCnt = 0;
    for (let i = 0; i < this.tableList.length; i++) {
      if (results.success.result[i].messageCode == 'CIAM 253') {
        this.successCount = this.successCount + 1;
        this.wfFlag = false;
      }
      else if (results.success.result[i].messageCode == 'CIAM 154') {
        this.wfFlag = true;
      }
      else if (results.success.result[i].messageCode == 'CIAM 105') {
        duplicateCnt = duplicateCnt + 1;
      }
    }
    if (duplicateCnt >= 1 && !this.wfFlag) {
      this.msg.push("duplicateEntry")
      this.ref.close(this.msg);
    }
    else if (this.wfFlag) {
      this.ref.close("Close");
      this.navigateToMyReq('Invite user request has been sent for approval');
    }
    else if (!this.wfFlag) {
      if (this.successCount > 0) {
        this.msg.push(this.successCount + " user invited successfully");
      }
      this.ref.close(this.msg);
    }
  }
  navigateToMyReq(msg: any): void {
    const navigationExtras: NavigationExtras = {
      state: {
        msg: msg,
      },
    };
    this.router.navigate(['/pages/myrequestslisting'], navigationExtras);
  }
  onAccountsdropdown() {
    this.pagesService.getAccountsdropdown().subscribe(results => {
      const responseData = results.success?.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.accountList.push(data);
        });
        this.accountList = [...this.accountList];
      }
    });
  }
}
