<header>

    <nav class="navbar navbar-expand mb-1 mt-0">
        <div class="container-fluid">
            <a class="navbar-brand logo sub-logo" routerLink="/" (click)="collapseNavbar()">
                <span class="logo-img"></span>
                <span jhiTranslate="global.title" class="navbar-title">ciam</span>
            </a>
            <div class="navbar-collapse collapse" id="navbarResponsive" [ngbCollapse]="isNavbarCollapsed" [ngSwitch]="isAuthenticated()">
                <ul class="navbar-nav ml-auto">
                    <li ngbDropdown class="nav-item dropdown pointer theme-switch-wrapper" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <input type="checkbox" (change)="switchTheme($event)" class="checkbox" id="checkbox" />
                        <label for="checkbox" class="label mt-2 mb-0">
                            <i aria-hidden="true" class="fas fa-moon"></i>
                            <i aria-hidden="true" class="fas fa-sun"></i>
                            <div class="ball"></div>
                        </label>
                    </li>
                    <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                        <a class="nav-link" routerLink="/" (click)="collapseNavbar()">
                            <span>
                                <span>Home</span>
                            </span>
                        </a>
                    </li>
                    
                    <li ngbDropdown class="nav-item dropdown pointer" display="dynamic" *ngIf="languages && languages.length > 1">
                        <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="languagesnavBarDropdown">
                            <span>
                                <fa-icon icon="flag"></fa-icon>
                                <span jhiTranslate="global.menu.language">Language</span>
                            </span>
                        </a>
                        <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="languagesnavBarDropdown">
                            <li *ngFor="let language of languages">
                                <a class="dropdown-item" [jhiActiveMenu]="language" href="javascript:void(0);" (click)="changeLanguage(language);collapseNavbar();">{{ language | findLanguageFromKey }}</a>
                            </li>
                        </ul>
                    </li>
                    <li ngbDropdown class="nav-item dropdown pointer" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="account-menu">
                            <span>
                                <span>
                                    {{userName}}
                                </span>
                            </span>
                        </a>
                        <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="account-menu">
                            <li *ngSwitchCase="true">
                                <a class="dropdown-item" (click)="logout()" id="logout">
                                    <span>Sign out</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</header>