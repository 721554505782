<div class="layout-content custom-container">
    <form [formGroup]="DeletePlanForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                <div class=" p-fluid   p-field ">
                    <p>Are you sure you want to delete '<span style="font-weight: 500;">{{this.planName}}</span>'?
                    </p>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right p-mb-2">
                <div class="btnwrap custom-save">
                    <button pButton type="button" class="p-button-brand2" (click)="onClickNo(DeletePlanForm);"
                        label="No">
                    </button>
                </div>
                <div class="btnwrap">
                    <button pButton class="p-button-primary" type="button" (click)="deletePlan();" label="Yes">
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>