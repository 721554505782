import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { PagesService } from '../pages.service';
import { Fulkrum_Workspace_URL } from 'app/app.constants';
@Component({
  selector: 'jhi-EditApplication',
  templateUrl: './EditApplication.component.html',
  styleUrls: ['./EditApplication.component.scss'],
  providers: [DialogService],
})
export class EditApplicationComponent implements OnInit {
  EditApplicationForm = this.fb.group({
    select: [null, []],
    textField1: [null, [Validators.required, Validators.maxLength(255)]],
    textarea: [null, []],
    checkbox: [false, []],
    externalStorage: [null, []],
    saasApplication: [null, []],
    externalStorageType: [null, []],
  });
  BASE_URL: string = Fulkrum_Workspace_URL;
  pageloadvalues: any;
  selectList: any[] = [];
  checked: boolean = false;
  nameFlag: boolean = false;
  enabledbtn1: boolean = false;
  enableBtn1: boolean = true;
  applicationId: any;
  name: any;
  version: any;
  description: any;
  saas: any;
  oldAppName: any;
  oldVersionId: any;
  oldDescription: any;
  oldSaas: any;
  logo: File;
  imageFileFlag: boolean;
  default: boolean;
  solImage: boolean;
  solImageFileSize: boolean;
  productPrimaryImage: any;
  formValidFlag: boolean = false;
  imagevalid: boolean;
  video: File;
  videoFileSize: boolean;
  productPrimaryVideo: any;
  validVideo: boolean;
  checkedSaas: boolean;
  SaasEnabled: boolean;
  checkedStorage: boolean;
  StorageEnabled: boolean;
  externalFlag: boolean = false;
  placeHolderFlag: boolean = true;
  imagePlacheholder: boolean = true;
  storageList: any[] = [];
  externalStorageRequired: any;
  storageType: any;
  logoFilePath: any;
  videoFilePath: any;
  imageURL: File;
  videoURL: File;
  videoValidFlag: boolean = false;
  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public pagesService: PagesService) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.EditApplicationForm.patchValue(JSON.parse(data));
    }
  }

  ngOnInit(): void {
    this.getApplicationVersionList();
    this.storageTypeList();
    this.applicationId = this.config?.data?.id;
    this.name = this.config?.data?.appName;
    this.oldAppName = this.config?.data?.appName;
    this.oldVersionId = this.config?.data?.appversion;
    this.version = this.config?.data?.appversion;
    this.description = this.config?.data?.appdesc;
    this.oldDescription = this.config?.data?.appdesc;
    this.checkedSaas = this.config?.data?.saasApp;
    this.oldSaas = this.config?.data?.saasApp;
    this.checkedStorage = this.config?.data?.externalStorageRequired;
    this.storageType = this.config?.data?.storageType;
    this.logoFilePath = this.config?.data?.logoFilePath;
    this.videoURL = this.config?.data?.videoFilePath;
    this.imageURL = this.config?.data?.logoFilePath;
    this.productPrimaryVideo = this.imageURL;
    this.placeHolderFlag = true;
    this.saasCheck();
    this.externalStorageCheck();
  }
  // getApplicationLogo() {
  //   this.pagesService.applicationLogo(this.logoFilePath).subscribe((results: any) => {
  //     this.imageURL = results.logo;
  //     console.log(this.imageURL," Image URL Response");
  //   });
  // }
  saasCheck() {
    if (this.checkedSaas) {
      this.SaasEnabled = true;
      if (this.config?.data?.logoFilePath == "" || this.config?.data?.logoFilePath == undefined) {
        this.formValidFlag = true;
      }

    } else {
      this.externalFlag = false
      this.SaasEnabled = false;
      this.checkedStorage = false;
      this.formValidFlag = false;
      this.productPrimaryImage = "";
      this.productPrimaryVideo = "";
      this.EditApplicationForm.controls['externalStorageType'].clearValidators();
      this.EditApplicationForm.controls['externalStorage'].clearValidators();
      this.EditApplicationForm.controls['externalStorage'].reset();
      this.EditApplicationForm.controls['externalStorage'].updateValueAndValidity();
      this.EditApplicationForm.controls['externalStorageType'].reset();
      this.EditApplicationForm.controls['externalStorageType'].updateValueAndValidity();
      this.StorageEnabled = false;

    }
  }
  externalStorageCheck() {
    if (this.checkedStorage) {
      this.StorageEnabled = true;
      this.EditApplicationForm.controls['externalStorageType'].setValidators(Validators.required);
      this.EditApplicationForm.controls['externalStorageType'].updateValueAndValidity();
    } else {
      this.externalFlag = false;
      this.StorageEnabled = false;
      this.EditApplicationForm.controls['externalStorageType'].clearValidators();
      this.EditApplicationForm.controls['externalStorageType'].reset();
      this.EditApplicationForm.controls['externalStorageType'].updateValueAndValidity();
    }
  }

  onSelectSolutionIcon(event: any): void {
    this.enableBtn1 = false;
    this.solImageFileSize = false;
    this.imagePlacheholder = false;
    this.imagevalid = false;
    this.formValidFlag = false;
    this.logo = new File([event.files[0]], event.files[0].name, {
      type: event.files[0].type
    });
    this.imageFileFlag = true;
    const isvalid = /(\.jpg|\.jpeg|\.png|\.svg)$/i;
    if (isvalid.test(this.logo.name)) {
      this.default = true;
      this.solImage = false;
      this.solImageFileSize = false;
      const regex = /[~#%&*{}:<>?/|'"]/g;
      this.productPrimaryImage = event.currentFiles[0].objectURL;
      let res = regex.test(this.logo.name);
      if (res) {
        this.solImage = true;
      }
      else if (event.currentFiles[0].size > 5000000) {
        this.solImageFileSize = true;
        this.formValidFlag = true;
      }
      else {
        setTimeout(() => {
        }, 1000);

      }
    } else {
      this.imagevalid = true;
      this.formValidFlag = true;
    }
  }

  onSelectVideo(event: any): void {
    this.enableBtn1 = false;
    this.videoValidFlag = false;
    this.videoFileSize = false;
    this.validVideo = false;
    this.video = new File([event.files[0]], event.files[0].name, {
      type: event.files[0].type
    });
    this.imageFileFlag = true;
    const isvalid = /(\.mp4)$/i;
    if (isvalid.test(this.video.name)) {
      this.default = true;
      this.videoFileSize = false;
      const regex = /[~#%&*{}:<>?/|'"]/g;
      this.productPrimaryVideo = event.currentFiles[0].name;
      this.placeHolderFlag = false;
      let res = regex.test(this.video.name);
      if (res) {
        this.solImage = true;
        this.videoValidFlag = false;
        this.placeHolderFlag = true;
      }
      else if (event.currentFiles[0].size > 50000000) {
        this.videoFileSize = true;
        this.videoValidFlag = true;
        this.placeHolderFlag = false;
      }
      else {
        setTimeout(() => {
        }, 1000);
      }
    } else {
      this.validVideo = true;
      this.videoValidFlag = true;
      this.placeHolderFlag = false;
    }
  }

  onRemoveIcon(event: any, fileUploadPageIconChooseFilebtn): void {
    const index = fileUploadPageIconChooseFilebtn.files.indexOf(
      event
    );
    fileUploadPageIconChooseFilebtn.files.splice(index, 1);
    this.productPrimaryImage = "";
    this.imagevalid = false;
    this.formValidFlag = true;
    this.solImageFileSize = false;
    setTimeout(() => {
    }, 1000);
  }

  onRemoveVideo(event: any, fileUploadPageIconChooseFilebtn1): void {
    const index = fileUploadPageIconChooseFilebtn1.files.indexOf(
      event
    );
    fileUploadPageIconChooseFilebtn1.files.splice(index, 1);
    this.productPrimaryVideo = "";
    this.validVideo = false;
    this.videoValidFlag = false;
    this.videoFileSize = false;
    this.placeHolderFlag = true;
    setTimeout(() => {
    }, 1000);
  }

  onClose($event: UntypedFormGroup): void {
    this.ref.close();
  }

  colorChange() {
    document.getElementById('textField1_id')!.style.borderColor = 'grey';
  }

  getApplicationVersionList(): void {
    this.pagesService.getHostApplicationVersion().subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.selectList.push(data);
        });
        this.selectList = [...this.selectList];
      }
    });
  }


  EditApplication(): void {
    const formdata = new FormData();
    this.applicationId = this.config?.data?.id;
    const data = { applicationId: this.applicationId };
    const reqBody = {};
    reqBody['id'] = this.applicationId;
    formdata.append('id', this.applicationId);
    if (this.oldAppName != this.EditApplicationForm.value.textField1) {
      formdata.append('applicationName', this.EditApplicationForm.value.textField1);
    }
    if (this.oldVersionId != this.EditApplicationForm.value.select) {
      formdata.append('applicationVersionId', this.EditApplicationForm.value.select);
    }
    if (this.oldDescription != this.EditApplicationForm.value.textarea) {
      formdata.append('description', this.EditApplicationForm.value.textarea);
    }

    if (!this.checkedSaas) {
      this.checkedStorage = false;
    }
    formdata.append('saasApplication', JSON.stringify(this.checkedSaas));

    formdata.append('externalStorageRequired', JSON.stringify(this.checkedStorage));


    if (this.checkedSaas) {
      if (this.video != undefined) {
        formdata.append('videoFile', this.video);
      }
      if (this.logo != undefined) {
        formdata.append('logoFile', this.logo);
      }
      if (this.checkedStorage) {
        formdata.append('storageTypeId', this.EditApplicationForm.value.externalStorageType);
      }
    }
    this.pagesService.onSaveEditHostApplication(formdata, data).subscribe(
      {
        next: results => {
          this.ref.close("Application Details Saved Successfully");
        },
        error: error => {
          if (error.error.error?.messageCode == "CIAM 105") {
            this.ref.close("Application Name already exist");
          }
          else {
            this.ref.close("Application Editing Failed");
          }
        }
      });
  }

  //Check Availability of Application Name
  Application() {
    if (this.oldAppName != this.EditApplicationForm.value.textField1 || this.oldVersionId != this.EditApplicationForm.value.select) {
      this.hostAppNameCheckAvailbility();
    }
    else {
      this.EditApplication();
    }

  }
  hostAppNameCheckAvailbility(): void {
    const reqBody = {};

    if (this.oldAppName != this.EditApplicationForm.value.textField1) {
      reqBody['applicationName'] = this.EditApplicationForm.value.textField1;
    }

    if (this.oldVersionId != this.EditApplicationForm.value.select) {
      reqBody['applicationName'] = this.EditApplicationForm.value.textField1;
      reqBody['versionId'] = this.EditApplicationForm.value.select;
    }

    this.pagesService.checkHostApplicationNameAvailability(reqBody).subscribe(
      results => {
        if (results?.error?.code == 400 || results?.success?.code == 400) {
          this.nameFlag = true;
          document.getElementById('textField1_id')!.style.borderColor = 'red';
        } else if (results.success.code == 200) {
          this.nameFlag = false;
          document.getElementById('textField1_id')!.style.borderColor = 'grey';
          this.EditApplication();
          this.enabledbtn1 = true;
        }
      }
    );
  }
  storageTypeList(): void {
    this.pagesService.storageTypeList().subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.storageTypeName;
          data.value = element.id;
          this.storageList.push(data);
        });
        this.storageList = [...this.storageList];
      }
    });
  }


}