import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AddUserComponent } from './AddUser/AddUser.component';
import { ManageUserFieldsComponent } from './ManageUserFields/ManageUserFields.component';
import { UserListingComponent } from './UserListing/UserListing.component';
import { ViewUserComponent } from './ViewUser/ViewUser.component';
import { InviteUsersComponent } from './InviteUsers/InviteUsers.component';
import { AddRolesComponent } from './AddRoles/AddRoles.component';
import { AddUserGroupComponent } from './AddUserGroup/AddUserGroup.component';
import { RolesComponent } from './Roles/Roles.component';
import { ListGroupsComponent } from './ListGroups/ListGroups.component';
import { ViewRoleComponent } from './ViewRole/ViewRole.component';
import { ViewGroupComponent } from './ViewGroup/ViewGroup.component';
import { AssignUsersComponent } from './AssignUsers/AssignUsers.component';
import { EditUserComponent } from './EditUser/EditUser.component';
import { EditGroupComponent } from './EditGroup/EditGroup.component';
import { EditRoleComponent } from './EditRole/EditRole.component';
import { UserGroupMappingComponent } from './UserGroupMapping/UserGroupMapping.component';
import { UserMappingComponent } from './UserMapping/UserMapping.component';
import { MirrorUserComponent } from './MirrorUser/MirrorUser.component';
import { DeactivateComponent } from './Deactivate/Deactivate.component';
import { ActivateUserComponent } from './ActivateUser/ActivateUser.component';
import { ForceLogOffComponent } from './ForceLogOff/ForceLogOff.component';
import { SuspendUserComponent } from './SuspendUser/SuspendUser.component';
import { UnlockUserComponent } from './UnlockUser/UnlockUser.component';
import { ApplicationOnboardingComponent } from './ApplicationOnboarding/ApplicationOnboarding.component';
import { ApplicationResourcesComponent } from './ApplicationResources/ApplicationResources.component';
import { AttributesandGroupsComponent } from './AttributesandGroups/AttributesandGroups.component';
import { RouterModule } from '@angular/router';
import { PAGES_ROUTE } from './pages.route';
import { CiamSharedModule } from '../shared/shared.module';
import { CreateResourcesComponent } from './CreateResources/CreateResources.component';
import { CreateAttributeComponent } from './CreateAttribute/CreateAttribute.component';
import { DeleteApplicationResourceComponent } from './DeleteApplicationResource/DeleteApplicationResource.component';
import { EditApplicationResourceComponent } from './EditApplicationResource/EditApplicationResource.component';
import { DeleteAttributeGroupComponent } from './DeleteAttributeGroup/DeleteAttributeGroup.component';
import { EditAttributeGroupComponent } from './EditAttributeGroup/EditAttributeGroup.component';
import { EditAtrributeComponent } from './EditAtrribute/EditAtrribute.component';
import { DeleteAttributeComponent } from './DeleteAttribute/DeleteAttribute.component';
import { CreateAttributeGroupComponent } from './CreateAttributeGroup/CreateAttributeGroup.component';
import { ApplicationRolesUserMappingComponent } from './ApplicationRolesUserMapping/ApplicationRolesUserMapping.component';
import { ApplicationRolesUserGroupMappingComponent } from './ApplicationRolesUserGroupMapping/ApplicationRolesUserGroupMapping.component';
import { DeleteGroupComponent } from './DeleteGroup/DeleteGroup.component';
import { DeleteRoleComponent } from './DeleteRole/DeleteRole.component';
import { MyAccountComponent } from './MyAccount/MyAccount.component';
import { APIAccessTokenComponent } from './APIAccessToken/APIAccessToken.component';
import { GenerateTokenComponent } from './GenerateToken/GenerateToken.component';
import { RegenerateTokenComponent } from './RegenerateToken/RegenerateToken.component';
import { TokenGeneratedComponent } from './TokenGenerated/TokenGenerated.component';
import { DeleteTokenComponent } from './DeleteToken/DeleteToken.component';
import { CreateComponent } from './CreateComponent/CreateComponent.component';
import { EditComponent } from './EditComponent/EditComponent.component';
import { CreateApplicationComponent } from './CreateApplication/CreateApplication.component';
import { EditApplicationComponent } from './EditApplication/EditApplication.component';
import { CreateAttributeTypeComponent } from './CreateAttributeType/CreateAttributeType.component';
import { EditAttributeTypeComponent } from './EditAttributeType/EditAttributeType.component';
import { CreateApplicationRoleComponent } from './CreateApplicationRole/CreateApplicationRole.component';
import { EditApplicationRoleComponent } from './EditApplicationRole/EditApplicationRole.component';
import { TenantListingComponent } from './TenantListing/TenantListing.component';
import { CreateTenantComponent } from './CreateTenant/CreateTenant.component';
import { EditTenantComponent } from './EditTenant/EditTenant.component';
import { ApplicationTenantMappingComponent } from './ApplicationTenantMapping/ApplicationTenantMapping.component';
import { TenantSettingsListingComponent } from './TenantSettingsListing/TenantSettingsListing.component';
import { AddCustomField } from './AddCustomField/AddCustomField.component';
import { DeleteCustomFieldComponent } from './DeleteCustomField/DeleteCustomField.component';
import { EditCustomField } from './EditCustomField/EditCustomField.component';
import { WorkflowConfigurationListingComponent } from './WorkflowConfigurationListing/WorkflowConfigurationListing.component';
import { CreateWorkflowConfigurationComponent } from './CreateWorkflowConfiguration/CreateWorkflowConfiguration.component';
import { ApprovalListingComponent } from './ApprovalListing/ApprovalListing.component';
import { ViewTransactionDetailsComponent } from './ViewTransactionDetails/ViewTransactionDetails.component';
import { ApproveTransactionComponent } from './ApproveTransaction/ApproveTransaction.component';
import { RejectTransactionComponent } from './RejectTransaction/RejectTransaction.component';
import { MyRequestsListingComponent } from './MyRequestsListing/MyRequestsListing.component';
import { EditWorkflowConfigurationComponent } from './EditWorkflowConfiguration/EditWorkflowConfiguration.component';
import { DeleteUserComponent } from './DeleteUser/DeleteUser.component';
import { RevokeDeletedUserComponent } from './RevokeDeletedUser/RevokeDeletedUser.component';
import { ViewWorkflowConfigurationDetailsComponent } from './ViewWorkflowConfigurationDetails/ViewWorkflowConfigurationDetails.component';
import { PlanListingComponent } from './planListing/planListing.component';
import { AddPlanComponent } from './AddPlan/AddPlan.component';
import { EditPlanComponent } from './EditPlan/EditPlan.component';
import { ViewApplicationPlanComponent } from './ViewApplicationPlan/ViewApplicationPlan.component';
import { AddSubscriptionComponent } from './AddSubscription/AddSubscription.component';
import { ManageSubscriptionComponent } from './ManageSubscription/ManageSubscription.component';
import { AddAsSubscriptionComponent } from './AddAsSubscription/AddAsSubscription.component';
import { EditSubscriptionComponent } from './EditSubscription/EditSubscription.component';
import { AuthenticationComponent } from './Authentication/Authentication.component';
import { CreateHostResourcesEndpointComponent } from './CreateHostResourcesEndpoint/CreateHostResourcesEndpoint.component';
import { EditHostApplicationResourceComponent } from './EditHostApplicationResource/EditHostApplicationResource.component';
import { DeleteHostApplicationEndpointComponent } from './DeleteHostApplicationEndpoint/DeleteHostApplicationEndpoint.component';
import { EndpointMappingComponent } from './EndpointMapping/EndpointMapping.component';
import { ManageEntitlementsComponent } from './ManageEntitlements/ManageEntitlements.component';
import { EntitlementsDetailsComponent } from './EntitlementDetails/EntitlementsDetails.component';
import { DateFormatterPipe } from 'app/pipes/date-formatter.pipe';
import { ClipTextDirective } from 'app/directives/clip-text.directive';
import { AccountManagementComponent } from './accountManagement/accountManagement.component';
import { CreateAccountComponent } from './CreateAccount/CreateAccount.component';
import { EditAccountComponent } from './EditAccount/EditAccount.component';
import { AccountUserMappingComponent } from './AccountUserMapping/AccountUserMapping.component';
import { AddAccountParameterComponent } from './AddAccountParameter/AddAccountParameter.component';
import { DeactivateAccountComponent } from './DeactivateAccount/DeactivateAccount.component';
import { ActivateAccountComponent } from './ActivateAccount/ActivateAccount.component';
import { ImportUserComponent } from './ImportUser/ImportUser.component';
import { BulkActionCheckStatusComponent } from './BulkActionCheckStatus/BulkActionCheckStatus.component';
import { EntitlementEndpointMappingComponent } from './EntitlementEndpointMapping/EntitlementEndpointMapping.component';
import { CancelRequestComponent } from './CancelRequest/CancelRequest.component';
import { RoleManagementComponent } from './ManageRoles/ManageRoles.component';
import { RoleDetailsComponent } from './ManageRolesDetails/ManageRolesDetails.component';
import { InviteEmailComponent } from './InviteEmail/InviteEmail.component';
import { CreateHostAttributeComponent } from './CreateHostAttribute/CreateHostAttribute.component';
import { EmailTemplateMappingComponent } from './EmailTemplateMapping/EmailTemplateMapping.component';
import { EditHostAttributeComponent } from './EditHostAttribute/EditHostAttribute.component';
import { DeleteHostAttributeComponent } from './DeleteHostAttribute/DeleteHostAttribute.component';
import { DeletePlanComponent } from './DeletePlan/DeletePlan.component';
import { ReorderPlanComponent } from './ReorderPlan/ReorderPlan.component';
import { CreateHostAttributeGroupComponent } from './CreateHostAttributeGroup/CreateHostAttributeGroup.component';
import { EditHostAttributeGroupComponent } from './EditHostAttributeGroup/EditHostAttributeGroup.component';
import { DeleteHostAttributeGroupComponent } from './DeleteHostAttributeGroup/DeleteHostAttributeGroup.component';
import { HostAttributeGroupMappingComponent } from './HostAttributeGroupMapping/HostAttributeGroupMapping.component';
import { EditEmailTemplateComponent } from './EditEmailTemplate/EditEmailTemplate.component';
import { ViewHostHierarchyAttributesComponent } from './ViewHostHierarchyAttributes/ViewHostHierarchyAttributes.component';
import { DownloadAttributeComponent } from './ApplicationOnboarding/DownloadAttribute/DownloadAttribute.component';
import { AddNextLevelHierarchyAttributesComponent } from './AddNextLevelHierarchyAttributes/AddNextLevelHierarchyAttributes.component';

@NgModule({
  declarations: [
    AddUserComponent,
    ManageUserFieldsComponent,
    UserListingComponent,
    ViewUserComponent,
    InviteUsersComponent,
    AddRolesComponent,
    AddUserGroupComponent,
    RolesComponent,
    ListGroupsComponent,
    ViewRoleComponent,
    ViewGroupComponent,
    AssignUsersComponent,
    EditUserComponent,
    EditGroupComponent,
    EditRoleComponent,
    UserGroupMappingComponent,
    UserMappingComponent,
    MirrorUserComponent,
    DeactivateComponent,
    ActivateUserComponent,
    ForceLogOffComponent,
    SuspendUserComponent,
    UnlockUserComponent,
    ApplicationOnboardingComponent,
    ApplicationResourcesComponent,
    AttributesandGroupsComponent,
    CreateResourcesComponent,
    CreateAttributeComponent,
    DeleteApplicationResourceComponent,
    EditApplicationResourceComponent,
    CreateAttributeGroupComponent,
    DeleteAttributeComponent,
    EditAtrributeComponent,
    DeleteAttributeGroupComponent,
    EditAttributeGroupComponent,
    ApplicationRolesUserGroupMappingComponent,
    ApplicationRolesUserMappingComponent,
    DeleteRoleComponent,
    DeleteGroupComponent,
    MyAccountComponent,
    APIAccessTokenComponent,
    GenerateTokenComponent,
    RegenerateTokenComponent,
    TokenGeneratedComponent,
    DeleteTokenComponent,
    CreateComponent,
    EditComponent,
    CreateApplicationComponent,
    EditApplicationComponent,
    CreateAttributeTypeComponent,
    EditAttributeTypeComponent,
    CreateApplicationRoleComponent,
    EditApplicationRoleComponent,
    TenantListingComponent,
    CreateTenantComponent,
    EditTenantComponent,
    ApplicationTenantMappingComponent,
    TenantSettingsListingComponent,
    AddCustomField,
    DeleteCustomFieldComponent,
    EditCustomField,
    WorkflowConfigurationListingComponent,
    CreateWorkflowConfigurationComponent,
    ApprovalListingComponent,
    ViewTransactionDetailsComponent,
    ApproveTransactionComponent,
    RejectTransactionComponent,
    MyRequestsListingComponent,
    EditWorkflowConfigurationComponent,
    DeleteUserComponent,
    RevokeDeletedUserComponent,
    ViewWorkflowConfigurationDetailsComponent,
    PlanListingComponent,
    AddPlanComponent,
    EditPlanComponent,
    ViewApplicationPlanComponent,
    AddSubscriptionComponent,
    ManageSubscriptionComponent,
    AddAsSubscriptionComponent,
    EditSubscriptionComponent,
    AuthenticationComponent,
    CreateHostResourcesEndpointComponent,
    EditHostApplicationResourceComponent,
    DeleteHostApplicationEndpointComponent,
    EndpointMappingComponent,
    ManageEntitlementsComponent,
    EntitlementsDetailsComponent,
    DateFormatterPipe,
    ClipTextDirective,
    AccountManagementComponent,
    CreateAccountComponent,
    EditAccountComponent,
    DateFormatterPipe,
    AccountUserMappingComponent,
    AddAccountParameterComponent,
    DeactivateAccountComponent,
    ActivateAccountComponent,
    ImportUserComponent,
    BulkActionCheckStatusComponent,
    EntitlementEndpointMappingComponent,
    CancelRequestComponent,
    RoleManagementComponent,
    RoleDetailsComponent,
    InviteEmailComponent,
    CreateHostAttributeComponent,
    EmailTemplateMappingComponent,
    EditHostAttributeComponent,
    DeleteHostAttributeComponent,
    DeletePlanComponent,
    ReorderPlanComponent,
    CreateHostAttributeGroupComponent,
    EditHostAttributeGroupComponent,
    DeleteHostAttributeGroupComponent,
    HostAttributeGroupMappingComponent,
    EditEmailTemplateComponent,
    ViewHostHierarchyAttributesComponent,
    DownloadAttributeComponent,
    AddNextLevelHierarchyAttributesComponent
  ],
  imports: [ReactiveFormsModule, CiamSharedModule, RouterModule.forChild(PAGES_ROUTE)],
  providers: [],
})
export class PagesModule { }
