<div class="layout-content custom-container">
    <div class="p-formgrid p-grid p-mt-3">
        <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-p-0 p-offset-0 left">
            <div class="p-pl-2 p-pr-2">
                <button pButton type="button" (click)="activeIndex1 = 0"
                    class="p-button-outlined p-button-lg tabButton custom-size p-mr-3" label="Tenant Details"
                    [style.box-shadow]="activeIndex1 == 0 ? '1px 5px 20px #bdc0c9' : '0px 1px 4px #bdc0c9'"
                    [style.border]="activeIndex1 == 0 ? '1px solid #396FC5' : '#add8e6'" style="width: 170px;">
                    <div class="fa-2x">
                        <span class="fa-layers fa-fw">
                            <label class="c-icons personal-icon"></label>
                        </span>
                    </div>
                    <span class="p-button-icon p-button-icon-left reshot-icon-business-team" aria-hidden="true"></span>
                    <label class="d-icons check-icon custom-label" *ngIf="tab1"></label>
                </button>
                <button pButton type="button" [disabled]="this.pagesService.pageTenantAdminFlag==false"
                    (click)="activeIndex1 = 1" label="Tenant Owner Details" id="panelbtn2"
                    class="p-button-outlined p-button-lg tabButton custom-roles custom-size"
                    [style.box-shadow]="activeIndex1 == 1? '1px 5px 20px #bdc0c9': '0px 1px 4px #bdc0c9'"
                    [style.border]="activeIndex1 == 1 ? '1px solid #396FC5' : '#add8e6'" style="width: 214px;">
                    <div class="fa-2x">
                        <span class="fa-layers fa-fw">
                            <label class="c-icons role-icon"></label>
                        </span>
                    </div>
                    <span class="p-button-icon p-button-icon-left reshot-icon-business-team" aria-hidden="true"></span>
                    <label class="d-icons check-icon custom-rolelabel" *ngIf="tab2"></label>
                </button>
            </div>
            <div class="tabview-custom">
                <p-tabView [(activeIndex)]="activeIndex1" styleClass="tabview-custom">
                    <p-tabPanel>
                        <form [formGroup]="EditTenantForm" autocomplete="off" novalidate style="padding-top: 14px;">
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 left">
                            </div>
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                                <div class=" p-fluid   p-field    ">
                                    <label
                                        [ngClass]="{'field-required': EditTenantForm.get('tenantName')?.errors?.required}">
                                        Tenant Name
                                    </label> <span class='invalid-feedback-astrik'>*</span>
                                    <span class="p-input-icon-right">
                                        <i aria-hidden="true" class="d-icons disable-icon"
                                            style="margin-right: 4px;margin-top: -9px;"></i>
                                        <input type="text" id="tenantName_id" [(ngModel)]="editTenantName"
                                            name="tenantName_name" formControlName="tenantName"
                                            placeholder="Tenant Name" class="p-inputtext inputtextHeight"
                                            pattern="^[a-z0-9](-[a-z0-9])?$" maxlength="64" minlength="3" />
                                    </span>
                                </div>
                            </div>
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                                <div class=" p-fluid   p-field    ">
                                    <label>
                                        Tenant Domain
                                    </label>
                                    <span class="p-input-icon-right">
                                        <i aria-hidden="true" class="d-icons disable-icon"
                                            style="margin-right: 4px;margin-top: -9px;"></i>
                                        <input type="text" id="domainName_id" [(ngModel)]="editTenantDomain"
                                            name="domain_name" formControlName="domainName" placeholder="Tenant Domain"
                                            class="p-inputtext inputtextHeight" />
                                    </span>
                                </div>
                            </div>
                            <div class="p-grid p-col-12 p-mt-5 p-mb-4" style="padding-bottom: 10px;">
                                <div class="p-col p-p-0 p-mt-4 p-mr-3 custom_small_file">
                                    <label>Logo</label>
                                    <div class="custom_choose_file">
                                        <p-fileUpload id="newSolutionPageIconChooseFilebtn"
                                            name="newSolutionPageIconChooseFilebtn" #fileUploadPageIconChooseFilebtn
                                            chooseLabel="Choose File" customUpload="true" cancelLabel="Reset"
                                            [showUploadButton]="false" [showCancelButton]="false"
                                            (onSelect)="onSelectSolutionIcon($event)">
                                            <ng-template pTemplate="content">
                                                <ng-container
                                                    *ngIf="imageURL && (productPrimaryImage == '' || productPrimaryImage == undefined);then previewText1; else previewText">
                                                </ng-container>
                                                <ng-template #previewText1>
                                                    <label style="color:#505870;"
                                                        class="label_content">{{imageURL.split("/")[4]}}
                                                        <span
                                                            pTooltip="{{imageForToolTip?.length > 20 ? imageForToolTip : ''}}"
                                                            tooltipPosition="left">
                                                            {{
                                                            imageURL +
                                                            "..." }}
                                                            <ng-template #tooltip>
                                                                {{ imageURL.split("/")[4] }}
                                                            </ng-template>
                                                        </span>
                                                    </label>
                                                </ng-template>
                                                <ng-template #previewText>
                                                    <label
                                                        *ngIf="(productPrimaryImage == '' || productPrimaryImage == undefined) && !imagevalid"
                                                        class="label_content">

                                                        &nbsp;Add file JPEG, PNG Max:
                                                        1Mb
                                                    </label>
                                                </ng-template>
                                            </ng-template>
                                            <ng-template let-file pTemplate="file">
                                                <div class="file">
                                                    <ng-container
                                                        *ngIf="imageURL && (productPrimaryImage == '' || productPrimaryImage == undefined);then previewdata1; else previewdata">
                                                    </ng-container>
                                                    <ng-template #previewdata>
                                                        <span class="ml-2 fileName"
                                                            pTooltip="{{file.name?.length > 20 ? file.name : ''}}"
                                                            tooltipPosition="left">
                                                            <ng-container *ngIf="file.name?.length > 20; else tooltip">
                                                                {{ file.name.substring(0,20) +
                                                                "..." }}
                                                            </ng-container>
                                                            <ng-template #tooltip>
                                                                {{ file.name }}
                                                            </ng-template>
                                                        </span>
                                                        <span class="delete-action"
                                                            (click)="onRemoveIcon(event,fileUploadPageIconChooseFilebtn)">
                                                            <i aria-hidden="true" class="pi pi-times"></i>
                                                        </span>
                                                    </ng-template>
                                                    <ng-template #previewdata1></ng-template>
                                                </div>
                                            </ng-template>
                                        </p-fileUpload>
                                    </div>
                                    <div class="p-error" *ngIf="solImage">
                                        <medium>The following characters are not allowed: ~ # % & * &#123; &#125; \ : &lt; >
                                            ? / | ' "</medium>
                                    </div>
                                    <div class="p-error" *ngIf="solImageFileSize">
                                        <label class="alert-icon alert-circle-icon"></label>
                                        <medium>The maximum file size can be 1MB!</medium>
                                    </div>
                                    <div class="p-error" *ngIf="imagevalid">
                                        <label class="alert-icon alert-circle-icon"></label>
                                        <medium>Invalid image format!</medium>
                                    </div>
                                </div>
                                <div class="p-col-fixed p-p-0" style="width: 80px;">
                                    <ng-container
                                        *ngIf="imageURL && (productPrimaryImage == '' || productPrimaryImage == undefined);then previewimage1; else previewimage">
                                    </ng-container>
                                    <ng-template #previewimage1>
                                        <div class="custom_preview_file" style="margin-top: 2px;">
                                            <img alt="" src="{{BASE_URL + imageURL}}" />
                                        </div>
                                    </ng-template>
                                    <ng-template #previewimage>
                                        <div class="custom_preview_file custom_preview_file_small_screen"
                                            style="margin-top: 2px;background-color: #EBF3FE;">
                                            <img alt="" [src]="this.productPrimaryImage" *ngIf="!solImageFileSize" />
                                            <i aria-hidden="true" *ngIf=" productPrimaryImage == '' ||
                                                    productPrimaryImage == undefined || solImageFileSize"
                                                class="icon-image" style="left: 28px;"></i>
                                            <label *ngIf="
                                                    productPrimaryImage == '' ||
                                                    productPrimaryImage == undefined || solImageFileSize
                                                " style="left: -1px;">
                                                Preview
                                            </label>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right">
                                <div class="btnwrap p-mr-3">
                                    <button pButton type="button" class="p-button-brand2"
                                        (click)="onClose(EditTenantForm);" label="Close">
                                    </button>
                                </div>
                                <div class="btnwrap">
                                    <button pButton class="p-button-primary" id="save" type="button"
                                        (click)="EditTenant();" label="Save"
                                        [disabled]="this.changeFlag || solImageFileSize || imagevalid">
                                    </button>
                                </div>
                            </div>
                        </form>
                    </p-tabPanel>
                    <p-tabPanel>
                        <form [formGroup]="EditTenantOwnerForm" autocomplete="off" novalidate
                            style="padding-top: 14px;">
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 left">
                            </div>
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                                <div class=" p-fluid   p-field    ">
                                    <label
                                        [ngClass]="{'field-required': EditTenantOwnerForm.get('firstName')?.errors?.required}">
                                        First Name
                                    </label> <span class='invalid-feedback-astrik'>*</span>
                                    <input type="text" id="firstName_id" (keyup)="editChangeFlag=false;valid()"
                                        name="firstName_name" formControlName="firstName" placeholder="Enter First name"
                                        class="p-inputtext inputtextHeight" pattern="^([a-zA-Z]+\s)*[a-zA-Z]+$"
                                        maxlength="50" />
                                    <div class="p-error"
                                        *ngIf="EditTenantOwnerForm.get('firstName')!.invalid && (EditTenantOwnerForm.get('firstName')!.dirty || EditTenantOwnerForm.get('firstName')!.touched)">
                                        <div *ngIf="EditTenantOwnerForm.get('firstName')?.errors?.required">
                                            <label class="alert-icon alert-circle-icon"></label>
                                            <medium class="p-error">First name is required.</medium>
                                        </div>
                                        <div *ngIf="EditTenantOwnerForm.get('firstName').errors.pattern">
                                            <label class="alert-icon alert-circle-icon"></label>
                                            <medium class="p-error">First name should not have Special Character and
                                                numbers.</medium>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                                <div class=" p-fluid   p-field    ">
                                    <label>
                                        Middle Name
                                    </label>
                                    <input type="text" id="middleName_id" name="middle_name"
                                        formControlName="middleName" placeholder="Enter Middle Name"
                                        class="p-inputtext inputtextHeight" pattern="^([a-zA-Z]+\s)*[a-zA-Z]+$"
                                        maxlength="50" (keyup)="editChangeFlag=false;valid()" />

                                    <div class="invalid-feedback" *ngIf="
                                                                EditTenantOwnerForm.get('middleName')!.invalid &&
																	(EditTenantOwnerForm.get('middleName')!.dirty ||
                                                                    EditTenantOwnerForm.get('middleName')!.touched)
																">
                                        <div *ngIf="
                                                                    EditTenantOwnerForm.get('middleName').errors.pattern
																	">
                                            <label class="alert-icon alert-circle-icon"></label>
                                            <medium class="p-error">Middle Name should not have Special
                                                Character and numbers.</medium>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                                <div class=" p-fluid   p-field    ">
                                    <label [ngClass]="{
                                        'field-required': EditTenantOwnerForm.get('lastName')
                                            ?.errors?.required
                                    }">
                                        Last Name
                                    </label><span class="invalid-feedback-astrik">*</span>
                                    <input type="text" id="lastName_id" name="last_name" formControlName="lastName"
                                        placeholder="Enter Last Name" class="p-inputtext inputtextHeight"
                                        pattern="^([a-zA-Z]+\s)*[a-zA-Z]+$" maxlength="50"
                                        (keyup)="editChangeFlag=false;valid()" />
                                    <div class="invalid-feedback" *ngIf="
                                    EditTenantOwnerForm.get('lastName')!.invalid &&
                                        (EditTenantOwnerForm.get('lastName')!.dirty ||
                                        EditTenantOwnerForm.get('lastName')!.touched)
                                    ">
                                        <medium *ngIf="
                                        EditTenantOwnerForm.get('lastName')?.errors
                                                ?.required
                                        " class="p-error">
                                            <label class="alert-icon alert-circle-icon"></label>
                                            Last name is required!
                                        </medium>
                                        <div *ngIf="
                                        EditTenantOwnerForm.get('lastName').errors.pattern
                                        ">
                                            <label class="alert-icon alert-circle-icon"></label>
                                            <medium class="p-error">Last Name should not have Special
                                                Character and numbers.</medium>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                                <div class=" p-fluid   p-field    ">
                                    <label [ngClass]="{
                                        'field-required': EditTenantOwnerForm.get('emailAddress')
                                            ?.errors?.required
                                    }">
                                        Email
                                    </label> <span class='invalid-feedback-astrik'>*</span>
                                    <input pInputText type="email" id="emailAddress_id" name="emailAddress_name"
                                        formControlName="emailAddress" pInputText placeholder="Email address"
                                        class="p-inputtext inputtextHeight" (keyup)="editChangeFlag=false;valid()" />

                                    <div class="invalid-feedback" *ngIf="
                                    EditTenantOwnerForm.get('emailAddress')!.invalid &&
                                        (EditTenantOwnerForm.get('emailAddress')!.dirty ||
                                        EditTenantOwnerForm.get('emailAddress')!.touched)
                                    ">
                                        <medium *ngIf="
                                        EditTenantOwnerForm.get('emailAddress')?.errors
                                                ?.required
                                        " class="p-error">
                                            <label class="alert-icon alert-circle-icon"></label>
                                            Email is required!
                                        </medium>
                                        <label *ngIf="
                                        EditTenantOwnerForm.get('emailAddress')?.errors
                                                ?.pattern
                                        " class="p-error">
                                            <div class="custom-font">
                                                <label class="alert-icon alert-circle-icon" for="rolename"></label>
                                                <medium id="rolename" style="
                                                    display: inline-block;
                                                    vertical-align: bottom;
                                                " class="p-error">Email must be a valid email address!
                                                </medium>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right p-mb-2">
                                <div class="btnwrap p-mr-3">
                                    <button pButton type="button" class="p-button-brand2"
                                        (click)="onCcprimebutton1(EditTenantOwnerForm);" label="Close">
                                    </button>
                                </div>
                                <div class="btnwrap">
                                    <button pButton class="p-button-primary" id="save" type="button"
                                        (click)="onSaveUser(EditTenantOwnerForm);"
                                        [disabled]="!EditTenantOwnerForm.valid || enablebtn1" label="Save">
                                    </button>
                                </div>
                            </div>
                        </form>
                    </p-tabPanel>
                </p-tabView>
            </div>
        </div>
    </div>
</div>