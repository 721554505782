import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PagesService } from '../pages.service';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'jhi-CreateWorkflowConfiguration',
  templateUrl: './CreateWorkflowConfiguration.component.html',
  styleUrls: ['./CreateWorkflowConfiguration.component.scss'],
  providers: [MessageService, DialogService],
})
export class CreateWorkflowConfigurationComponent implements OnInit {
  CreateWorkflowConfigurationForm = this.fb.group({
    select: [null, [Validators.required]],
    select1: [null, [Validators.required]],
    select2: [null, [Validators.required]],
    autoRejectDays: [null, [Validators.required]],
  });
  pageloadvalues: any;
  selectList: any[] = [];
  select1List: any[] = [];
  select2List: any[] = [];
  appId: any;
  componentId: any;
  dropdown: boolean;
  app: any;
  com: any;
  enableBtn = false;
  nameFlag = false;
  roleFlag = false;
  approverNum = 0;
  approvalRole: any[] = [];
  flag = 0;
  invalidAt = 1;
  approvalSeq: number;
  wfApprovalStageDto: any[] = [];
  approvalTypeSelected: string;
  autoRejectDaysModel: string;
  emptyFlag = false;
  roleMigrationFlag: string;

  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    private messageService: MessageService,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.CreateWorkflowConfigurationForm.patchValue(JSON.parse(data));
    }
  }

  ngOnInit(): void {
    this.roleMigrationFlag = environment.CIAM_ROLE_MIGRATION_FLAG + "";
    this.getCiamAction();
    this.getApprovalType();
    this.getNumberOfApprover();
    this.getroleList();
    this.autoRejectDaysModel = "30";
    this.CreateWorkflowConfigurationForm.get('select1').disable();
    this.CreateWorkflowConfigurationForm.get('select2').disable();
  }

  onClose($event: UntypedFormGroup): void {
    this.ref.close();
  }

  getCiamAction(): void {
    this.pagesService.getAllCiamAction().subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.selectList.push(data);
        });
        this.selectList = [...this.selectList];
      }
    });
  }

  getApprovalType() {
    this.select2List.push({ label: "Parallel", value: "Parallel" }, { label: "Sequential", value: "Sequential" });
  }


  getApprovalTypeSelection() {
    if (this.CreateWorkflowConfigurationForm.value.select1 == "1") {
      this.approvalTypeSelected = "Sequential";
      this.CreateWorkflowConfigurationForm.get('select2').disable();
    }
    else {
      this.CreateWorkflowConfigurationForm.controls['select2'].reset();
      this.CreateWorkflowConfigurationForm.get('select2').enable();
    }
  }

  getNumberOfApprover(): void {
    this.select1List = [];
    for (let i = 1; i <= 5; i++) {
      this.select1List.push({ label: i, value: i });
    }
  }

  addApprover() {
    if (this.approverNum > 0) {
      const newNum = this.CreateWorkflowConfigurationForm.value.select1;
      if (newNum < this.approverNum) {
        //if changed number is less than the previous number
        this.enableBtn = false;
        this["roleFlag" + this.invalidAt] = false;
        for (let j = newNum + 1; j <= this.approverNum; j++) {
          this.CreateWorkflowConfigurationForm.controls["Approver" + j].clearValidators();
          this.CreateWorkflowConfigurationForm.controls["Approver" + j].reset();
          this.CreateWorkflowConfigurationForm.controls["Approver" + j].updateValueAndValidity();
        }
        this.approverNum = this.CreateWorkflowConfigurationForm.value.select1;
      }
      else if (newNum > this.approverNum) {
        //if changed number is greater than the previous number
        this.formControlSo(newNum);
        this.approverNum = this.CreateWorkflowConfigurationForm.value.select1;
      }
    }
    else {
      //selecting the number first time
      this.approverNum = this.CreateWorkflowConfigurationForm.value.select1;
      for (let i = 1; i <= this.approverNum; i++) {
        this.CreateWorkflowConfigurationForm.addControl("Approver" + i,
          this.fb.control('', Validators.required));
      }
    }
  }

  formControlSo(newNum: any) {
    for (let i = this.approverNum; i <= newNum; i++) {
      if (this.CreateWorkflowConfigurationForm.controls["Approver" + i]) {
        this.CreateWorkflowConfigurationForm.controls["Approver" + i].setValidators(Validators.required);
        this.CreateWorkflowConfigurationForm.controls["Approver" + i].updateValueAndValidity();
      }
      else {
        this.CreateWorkflowConfigurationForm.addControl("Approver" + i,
          this.fb.control('', Validators.required));
        this.CreateWorkflowConfigurationForm.controls["Approver" + i].updateValueAndValidity();
      }
    }
  }

  createRange(number) {
    // create range for approver
    return new Array(number).fill(0)
      .map((n, index) => index + 1);
  }


  forResponseSo(responseData: any) {
    if (responseData && responseData.length > 0) {
      responseData.forEach((element: any = {}) => {
        const data = {
          label: null,
          value: null,
        };
        if (element.label == null || element.label == "" || element.label == "empty") {
          this.emptyFlag = true;
        }
        else {
          data.label = element.label;
          data.value = element.label;
          this.approvalRole.push(data);
        }
      });
      this.approvalRole = [...this.approvalRole];
    }
  }

  getroleList(): void {
    if (this.roleMigrationFlag == "true") {
      const reqBody = {};
      reqBody['tenantId'] = this.pagesService.tenantId;
      const formData = reqBody;
      this.pagesService.getAppRolesByTenantID(formData).subscribe(results => {
        const responseData = results?.success?.result;
        this.forResponseSo(responseData);
      });
    }
    else {
      this.pagesService.gettableDataRoles().subscribe(results => {
        const responseData = results?.success?.result?.records;
        if (responseData && responseData.length > 0) {
          responseData.forEach((element: any = {}) => {
            const data = {
              label: null,
              value: null,
            };
            if (element.displayName == null || element.displayName == "" || element.displayName == "empty") {
              this.emptyFlag = true;
            }
            else {
              data.label = element.displayName;
              data.value = element.displayName;
              this.approvalRole.push(data);
            }
          });
          this.approvalRole = [...this.approvalRole];
        }
      });
    }
  }

  onCheckAvailabilityCiamAction(): void {
    const reqBody = {};
    reqBody['tenantId'] = this.pagesService.tenantId;
    reqBody['ciamAction'] = this.CreateWorkflowConfigurationForm.value?.select;
    const formData = reqBody;
    this.pagesService.checkAvailabilityCiamAction(formData).subscribe(
      results => {
        if (results?.error?.code == 400 || results?.success?.code == 400) {
          this.nameFlag = true;
          document.getElementById('selectid')!.style.borderColor = 'red';
          document.getElementById('selectid')!.classList.add('ng-invalid');
          document.getElementById('selectid')!.classList.add('ng-dirty');
        } else if (results.success.code == 200) {
          this.nameFlag = false;
          this.CreateWorkflowConfigurationForm.get('select1').enable();
          document.getElementById('selectid')!.style.borderColor = 'grey';
          document.getElementById('selectid')!.classList.remove('ng-invalid');
          document.getElementById('selectid')!.classList.remove('ng-dirty');
        }
      }
    );
  }

  checkApproveSo(i: any) {
    for (let j = 1; j <= this.approverNum; j++) {
      if (i != j) {
        if ((this.CreateWorkflowConfigurationForm.value["Approver" + j] == null || this.CreateWorkflowConfigurationForm.value["Approver" + j] == "" || this.CreateWorkflowConfigurationForm.value["Approver" + i] == "empty") && (this.CreateWorkflowConfigurationForm.value["Approver" + j] == null || this.CreateWorkflowConfigurationForm.value["Approver" + i] == "" || this.CreateWorkflowConfigurationForm.value["Approver" + i] == "empty")) {
          this.emptyFlag = true;
        }
        else if (this.CreateWorkflowConfigurationForm.value["Approver" + i] == this.CreateWorkflowConfigurationForm.value["Approver" + j]) {
          this.flag = 1;
          this.invalidAt = j;
          break;
        }
      }
    }
  }

  checkApproverRole(index: any) {
    this.enableBtn = false;
    this["roleFlag" + this.invalidAt] = false;
    document.getElementById('select_Approver' + this.invalidAt)!.classList.remove('ng-invalid');
    document.getElementById('select_Approver' + this.invalidAt)!.classList.remove('ng-dirty');
    this.invalidAt = 1;
    for (let i = 1; i <= this.approverNum; i++) {
      this.flag = 0;
      this.invalidAt = 1;
      this.checkApproveSo(i);

      if (this.flag == 1) {
        break;
      }
      else {
        this.flag = 0;
      }
    }

    if (this.flag == 1) {
      this.enableBtn = true;
      this["roleFlag" + this.invalidAt] = true;
      document.getElementById('select_Approver' + this.invalidAt)!.classList.add('ng-touched');
      document.getElementById('select_Approver' + this.invalidAt)!.classList.add('ng-invalid');
      document.getElementById('select_Approver' + this.invalidAt)!.classList.add('ng-dirty');
    }
    else {
      this.enableBtn = false;
      this["roleFlag" + this.invalidAt] = false;
      document.getElementById('select_Approver' + this.invalidAt)!.classList.remove('ng-invalid');
      document.getElementById('select_Approver' + this.invalidAt)!.classList.remove('ng-dirty');
    }
  }

  onSave() {
    let role = [];
    for (let i = 1; i <= this.approverNum; i++) {
      role.push(this.CreateWorkflowConfigurationForm.value["Approver" + i]);
    }
    for (let j = 1; j <= role.length; j++) {
      const data = {
        approvalSeq: null,
        approvalRole: null,
        approvalType: null,
        minApprovals: "1"
      };
      data.approvalSeq = j;
      data.approvalRole = role[j - 1];
      data.approvalType = this.approvalTypeSelected;
      this.wfApprovalStageDto.push(data);
    }
    this.wfApprovalStageDto = [...this.wfApprovalStageDto];
    const reqBody = {};
    reqBody['modulesId'] = this.CreateWorkflowConfigurationForm.value.select;
    reqBody['approvalNo'] = this.CreateWorkflowConfigurationForm.value.select1;
    reqBody['approvalType'] = this.approvalTypeSelected;
    reqBody['tenantId'] = this.pagesService.tenantId;
    reqBody['wfApprovalStageDto'] = this.wfApprovalStageDto;
    reqBody['autoRejectDays'] = this.CreateWorkflowConfigurationForm.value.autoRejectDays;
    const formValues = reqBody;
    this.pagesService.createWorkflowConfiguration(formValues).subscribe(
      {
        next: results => {
          this.ref.close("Workflow configuration added successfully.");
        },
        error: error => {
          if (error.error.error?.messageCode == "CIAM 105") {
            this.ref.close("Workflow configuration name already exist.");
          }
          else {
            this.ref.close("Workflow configuration Creation Failed.");
          }
        }
      });
  }

}