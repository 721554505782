import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Message, MessageService, FilterService } from 'primeng/api';
import { PagesService } from '../pages.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
@Component({
  selector: 'jhi-ViewApplicationPlan',
  templateUrl: './ViewApplicationPlan.component.html',
  styleUrls: ['./ViewApplicationPlan.component.scss'],
  providers: [MessageService, DialogService, FilterService],
})
export class ViewApplicationPlanComponent implements OnInit {
  ref!: DynamicDialogRef;
  ViewApplicationPlanForm = this.fb.group({
    tabview: [0],
    select: [null, [Validators.required]],
    applicationSearch: [null, []],
    textField1: [null, []],
    componentSearch: [null, []],
    select1: [null, [Validators.required]],
    search1: [null, []],
    select2: [null, [Validators.required]],
    select3: [null, [Validators.required]],
    pickList: [null, []],
    textField2: [null, []],
    inputswitch: [null, []],
  });
  pageloadvalues: any;
  ccprimebreadcrumbList = [{ label: `Tenant Management` },
  { label: `Tenant Settings` },
  ]
  homeiconccprimebreadcrumb = { icon: 'pi pi-home', routerLink: ['/'] };
  selectList: any[] = [];
  searchList: any[] = [];
  tableList: any[] = [];
  tableList2: any[] = [];
  tableList3: any[] = [];
  tableList4: any[] = [];
  tableList5: any[] = [];
  tableList6: any[] = [];
  tableList7: any[] = [];
  tablecols: any[] = [{ field: "type", header: "Type" },
  { field: "name", header: "Name" },
  { field: "group", header: "Group" },
  ];
  select1List: any[] = [];
  search1List: any[] = [];
  table1List: any[] = [];
  table1cols: any[] = [{ field: "type", header: "Type" },
  { field: "name", header: "Name" },
  { field: "group", header: "Group" },
  ];
  applicationList: any[] = [];
  select3List: any[] = [];
  pickListList: any[] = [];
  pickListList1: any[] = [];
  messageFlag = true;
  applicationId: any;
  resourceApplicationId: any;
  msgs: Message[] = [];

  pickListtargetList: any[] = [];
  pickListtargetList1: any[] = [];
  tableList1: any[] = [];
  btn = true;
  dropdown = true;
  activeItem: any;
  activeItem1: any;
  activeItem2: any;
  selectedIndex: any;
  flag2 = true;
  flag1 = true;
  flag3 = true;
  groupID: any[] = [];
  appId: any[] = [];
  exMapList: any[] = [];
  mapList: any[] = [];
  mList: any[] = [];
  groupArr: [];
  errorFlag = true;
  planErrorFlag = true;
  varDefault = "Default";
  checked1: boolean = false;
  someArr: any = [];
  flag = 1;
  filterFlag = false;
  specialFlag: boolean = false;
  colors: any = ['p-avatar-color1', 'p-avatar-color2', 'p-avatar-color3', 'p-avatar-color4', 'p-avatar-color5', 'p-avatar-color6', 'p-avatar-color7', 'p-avatar-color8'];
  productfeatures: { featureName: string; description: string; saasApplication: boolean; deleted: boolean; }[];
  planAttributesList: any = [];
  planId: any;
  Nameplan: any;
  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public dialogService: DialogService,

    public config: DynamicDialogConfig,
    public pagesService: PagesService,
    private messageService: MessageService,
  ) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.ViewApplicationPlanForm.patchValue(JSON.parse(data));
    }

  }
  ngOnInit(): void {
    this.planId = this.config?.data?.id;
    this.getPlanApplicationDetails();
    this.getPlanAttributesDetails();

  }

  getPlanApplicationDetails(): void {
    this.pagesService.getPlanViewApplicationDetailsTab(this.planId).subscribe(results => {
      const responseData = results.success.result.features;
      this.applicationList = responseData;
      if (responseData.applicationName == null)
        this.errorFlag = false;

      this.Nameplan = results.success.result.planName;
    });

  }

  getPlanAttributesDetails(): void {
    let reqBody = {};
    reqBody['planid'] = this.planId;
    const formvalues = reqBody;
    this.pagesService.getViewPlanAttributes(formvalues).subscribe(results => {
      const responseData = results.success.result;

      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          if (element.symbol == 'nos' || element.symbol == null) {
            data.label = element.displayName;
            if (element.symbol == null) {
              data.value = element.value;
            }
            else
              data.value = element.value + "/" + element.perUnitType;
          }

          else {
            data.label = element.displayName + "(" + element.symbol + ")";
            data.value = element.value + "/" + element.perUnitType;
          }


          this.planAttributesList.push(data);
        });
        this.planAttributesList = [...this.planAttributesList];
      }
      else {
        this.planErrorFlag = false;
      }
    });

  }
  getColor(i: any) {
    let count = 0
    if (i < this.colors?.length) {
      return this.colors[i]
    }

    if (i >= this.colors?.length) {
      for (let j = 0; j <= i; j++) {
        if (j % this.colors?.length == 0 && j != 0) {
          count++;
        }
      }
      return this.colors[i - (this.colors?.length * count)]
    }
  }


  highlight(index: any) {
    this.selectedIndex = index;
  }
  mouseLeave() {
    this.selectedIndex = null;
  }

  handleChangetabview(e: any): void {
    this.ViewApplicationPlanForm.patchValue({ "tabview": e.index });
    this.ViewApplicationPlanForm.controls.applicationSearch.reset();
    this.ViewApplicationPlanForm.controls.textField1.reset();
  }






}
