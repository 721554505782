<div style="background-color:#F6F8FA;" (click)="mouseLeave()">
    <div class="p-d-flex p-jc-end">
        <p-messages key="myKey1" class="custom-alert-box" *ngIf="this.messageFlag"></p-messages>
    </div>
    <div class="p-col-fixed p-mt-1 p-ml-1 p-pr-0 custom-breadcrumb">
        <p-breadcrumb [model]="ccprimebreadcrumbList" [home]="homeiconccprimebreadcrumb">
        </p-breadcrumb>
    </div>



    <form class="layout-content custom-form-layout" [formGroup]="WorkflowConfigurationListingForm" autocomplete="off"
        novalidate>
        <div class="p-formgrid p-grid">

            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                <div class="p-grid">
                    <span class="p-col p-p-0">
                        <h5 class="custom-head1">Workflow Configuration</h5>
                    </span>
                </div>
                <div class="card custom-card">
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                        <div class=" p-fluid   p-field    ">

                            <div class="p-fluid p-formgrid p-grid" style="padding-top:8px ;">
                                <div class="p-col-12 p-p-0">
                                    <div *ngIf="this.errorFlag" class="custom-errorFlagDiv">

                                        <label class="c-icons error-icon"></label><br>
                                        <label class="e-error">No Workflow Configuration found</label><br>
                                        <br>
                                        <div class="btnwrap">
                                            <button pButton type="button" pButton label="Add New Workflow"
                                                (click)="showWorkflow();" class="p-button-outlined p-button-danger"
                                                [disabled]="this.pagesService.tenantManagementReadOnlyFlag"></button>
                                        </div>
                                    </div>
                                    <div class="ui-fluid p-formgrid" *ngIf="!this.errorFlag">

                                        <div class=" p-grid p-mt-0">
                                            <div class="p-col-fixed p-p-0 p-col-4">
                                                <span class=" p-input-icon-left">
                                                    <input type="text" (keydown.enter)="$event.preventDefault()"
                                                        id="textField_id" name="textField_name"
                                                        formControlName="textField" pInputText placeholder="Search"
                                                        class="p-inputtext inputtextHeight"
                                                        (input)="WorkFlowFilterMethod($event.target.value)"
                                                        style="height: 28px;" />
                                                    <i aria-hidden="true" class="pi pi-search"></i>
                                                </span>
                                            </div>
                                            <div class="p-col p-p-0 p-text-right custom-btn">
                                                <span class="btnwrap" *ngIf="!this.errorFlag">
                                                    <button pButton
                                                        class="p-button-primary p-button-sm custom-button-padding"
                                                        type="button" (click)="showWorkflow();" label="Add New Workflow"
                                                        [disabled]="this.pagesService.tenantManagementReadOnlyFlag">
                                                    </button>
                                                </span>
                                            </div>
                                        </div>

                                        <div
                                            class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left p-p-0">
                                            <div class="  p-field    ">
                                                <div class="mobilefriendlytable" responsiveLayout="stack"
                                                    [ngClass]="{'content-section implementation': true}">

                                                    <p-table #tableid [value]="tableList" name="table"
                                                        [columns]="tablecols"
                                                        styleClass="p-datatable-customers custom-p-datatable-customers"
                                                        [rows]="10" [rowsPerPageOptions]="[10,25,50]"
                                                        selectionMode="Multiple" [paginator]="true" sortMode="single"
                                                        [scrollable]="true"
                                                        [globalFilterFields]="['ciamAction','noOfApprovers','approvalType','numOfPendingTrans']">
                                                        <ng-template pTemplate="header">
                                                            <tr>
                                                                <th pSortableColumn="ciamAction" scope="col"
                                                                    style="width: 20%;">
                                                                    <div class="p-d-flex p-jc-between p-ai-center">
                                                                        CIAM Action
                                                                        <i class="p-sortable-column-icon icon-sort"
                                                                            ariaLabel="Activate to sort"
                                                                            ariaLabelDesc="Activate to sort in descending order"
                                                                            ariaLabelAsc="Activate to sort in ascending order"
                                                                            field="ciamAction"></i>
                                                                        <p-columnFilter type="text" field="ciamAction"
                                                                            display="menu"
                                                                            class="p-ml-auto"></p-columnFilter>
                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="noOfApprovers" scope="col"
                                                                    style="width: 20%;">
                                                                    <div class="p-d-flex p-jc-between p-ai-center">
                                                                        No. of Approvers
                                                                        <i class="p-sortable-column-icon icon-sort"
                                                                            ariaLabel="Activate to sort"
                                                                            ariaLabelDesc="Activate to sort in descending order"
                                                                            ariaLabelAsc="Activate to sort in ascending order"
                                                                            field="noOfApprovers"></i>
                                                                        <p-columnFilter type="text"
                                                                            field="noOfApprovers" display="menu"
                                                                            class="p-ml-auto"></p-columnFilter>
                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="approvalType" scope="col"
                                                                    style="width: 20%;">
                                                                    <div class="p-d-flex p-jc-between p-ai-center">
                                                                        Approval Type
                                                                        <i class="p-sortable-column-icon icon-sort"
                                                                            ariaLabel="Activate to sort"
                                                                            ariaLabelDesc="Activate to sort in descending order"
                                                                            ariaLabelAsc="Activate to sort in ascending order"
                                                                            field="approvalType"></i>
                                                                        <p-columnFilter type="text" field="approvalType"
                                                                            display="menu"
                                                                            class="p-ml-auto"></p-columnFilter>
                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="numOfPendingTrans" scope="col"
                                                                    style="width: 20%;">
                                                                    <div class="p-d-flex p-jc-between p-ai-center">
                                                                        No of Pending Transaction
                                                                        <i class="p-sortable-column-icon icon-sort"
                                                                            ariaLabel="Activate to sort"
                                                                            ariaLabelDesc="Activate to sort in descending order"
                                                                            ariaLabelAsc="Activate to sort in ascending order"
                                                                            field="numOfPendingTrans"></i>
                                                                        <p-columnFilter type="text"
                                                                            field="numOfPendingTrans" display="menu"
                                                                            class="p-ml-auto"></p-columnFilter>
                                                                    </div>
                                                                </th>
                                                                <th id="actionButton" style="width: 15%;">
                                                                    <div></div>
                                                                </th>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-rowData let-index="rowIndex">
                                                            <tr (mouseenter)="hoverIndex=index;highlight(index)"
                                                                (mouseleave)="hoverIndex=index;highlight(index)"
                                                                [pSelectableRow]="rowData">
                                                                <td [ngClass]="{ disable: rowData.isDeleted==true }"
                                                                    class="custom-description" style="width: 20%;">
                                                                    <span pTooltip="{{rowData.ciamAction}}"
                                                                        *ngIf="rowData.ciamAction?.length >= 25"
                                                                        tooltipPosition="bottom">
                                                                        {{rowData.ciamAction?.substring(0,25) +
                                                                        "..." }}
                                                                    </span>
                                                                    <span *ngIf="rowData.ciamAction?.length < 25">
                                                                        {{rowData.ciamAction}}
                                                                    </span>
                                                                </td>
                                                                <td [ngClass]="{ disable: rowData.isDeleted==true }"
                                                                    class="custom-description" style="width: 20%;">
                                                                    {{rowData.noOfApprovers}}
                                                                </td>
                                                                <td [ngClass]="{ disable: rowData.isDeleted==true }"
                                                                    class="custom-description" style="width: 20%;">
                                                                    {{rowData.approvalType}}
                                                                </td>
                                                                <td [ngClass]="{ disable: rowData.isDeleted==true }"
                                                                    class="custom-description" style="width: 20%;">
                                                                    {{rowData.numOfPendingTrans}}</td>
                                                                <td style="width: 15%;">
                                                                    <div class="button-details">
                                                                        <button pButton tooltipPosition="top"
                                                                            pTooltip="Workflow Configuration Details"
                                                                            style="width: 24px;height: 24px;padding: 0px;"
                                                                            icon="g-icons viewWorkflow-icon"
                                                                            class="background-transparent"
                                                                            (click)="activeItem=rowData;viewWorkflow();">
                                                                        </button>
                                                                        <div style="line-height: normal;margin-right: 5px;margin-top: -5px;"
                                                                            pTooltip="{{rowData.numOfPendingTrans>0 ? 'Workflow Configuration <br> cannot be edited due <br> to pending transaction' : 'Edit'}}"
                                                                            tooltipPosition="top" [escape]="false"
                                                                            *ngIf="rowData.isDeleted==false">
                                                                            <button pButton
                                                                                style="width: 20px;height: 20px;padding: 0px;margin: 0px;"
                                                                                icon="g-icons view-icon"
                                                                                class="background-transparent"
                                                                                (click)="activeItem=rowData;editWorkflowConfiguration();"
                                                                                [disabled]="rowData.numOfPendingTrans>0 || this.pagesService.tenantManagementReadOnlyFlag">
                                                                            </button>
                                                                        </div>
                                                                        <div
                                                                            style="height: 20px;width: 20px;padding: 0px;margin-left: 5px;margin-top: 3px;">

                                                                            <p-inputSwitch
                                                                                [(ngModel)]="this['checked1'+index]"
                                                                                pTooltip="{{rowData.numOfPendingTrans>0 ?  'Workflow Configuration <br> cannot be disabled due <br> to pending transaction.' : rowData.isDeleted==true  ? 'Enable': rowData.isDeleted==false ? 'Disable' :''}}"
                                                                                tooltipPosition="top" [escape]="false"
                                                                                formControlName="inputswitch{{index}}"
                                                                                styleClass="p-inputswitch-sm"
                                                                                class="p-mr-2 custom-switch"
                                                                                (onChange)="activeItem=rowData;EnableDisableWorkflow(index)"
                                                                                [disabled]="this.pagesService.tenantManagementReadOnlyFlag">
                                                                            </p-inputSwitch>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="paginatorright" let-state><span
                                                                class="p-pl-4">Total
                                                                Records: {{state.totalRecords}}</span></ng-template>
                                                        <ng-template pTemplate="emptymessage">
                                                            <tr>
                                                                <td colspan="7" class="p-p-0 p-text-center"> <p-card>
                                                                        <div style="margin-top: 5%;height: 70px;">No
                                                                            matching records found</div>
                                                                    </p-card> </td>
                                                            </tr>
                                                        </ng-template>
                                                    </p-table>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </form>
</div>