<div class="layout-content custom-container">
    <form [formGroup]="EditHostApplicationResourceForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 p-left p-mt-2 p-pb-1">
                <span class="p-left" style="font-size: 12px;font-style: italic;"><strong>Note:</strong>
                    Disabling "application role required" would impact the roles already mapped to this endpoint.
                    This endpoint would be unmapped to existing roles.</span>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                <div class=" p-fluid   p-field    ">
                    <label
                        [ngClass]="{'field-required': EditHostApplicationResourceForm.get('select')?.errors?.required}">
                        Application
                        <span class='invalid-feedback-astrik'>*</span> </label>
                    <div class="p-fluid  p-field">
                        <p-dropdown [filter]="true" filterBy="label" [(ngModel)]="app" [disabled]="true" id="selectid"
                            name="select" placeholder="Application" [options]="selectList" [autoDisplayFirst]="false"
                            formControlName="select">
                        </p-dropdown>
                    </div>
                    <div class="p-error"
                        *ngIf="EditHostApplicationResourceForm.get('select')!.invalid && (EditHostApplicationResourceForm.get('select')!.dirty || EditHostApplicationResourceForm.get('select')!.touched)">
                        <div *ngIf="EditHostApplicationResourceForm.get('select')?.errors?.required">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium class="p-error"> Application is required.</medium>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                <div class=" p-fluid   p-field    ">
                    <label
                        [ngClass]="{'field-required': EditHostApplicationResourceForm.get('select1')?.errors?.required}">
                        Component
                        <span class='invalid-feedback-astrik'>*</span> </label>
                    <div class="p-fluid  p-field">
                        <p-dropdown [filter]="true" filterBy="label" (onChange)="enableBtn1=false || enableBtn=false"
                            [(ngModel)]="com" id="select1id" name="select1" placeholder="Component"
                            [options]="select1List" [autoDisplayFirst]="false" formControlName="select1">
                            <ng-template let-items pTemplate="item">
                                <div *ngIf="items?.label?.length &lt;= 35;">
                                    {{items.label}}
                                </div>
                                <div *ngIf="items?.label?.length > 35;" pTooltip="{{items.label}}"
                                    tooltipPosition="bottom">
                                    {{items.label.substring(0,35) + "..." }}
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                    <div class="p-error"
                        *ngIf="EditHostApplicationResourceForm.get('select1')!.invalid && (EditHostApplicationResourceForm.get('select1')!.dirty || EditHostApplicationResourceForm.get('select1')!.touched)">
                        <div *ngIf="EditHostApplicationResourceForm.get('select1')?.errors?.required">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium class="p-error">Component is required.</medium>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                <div class=" p-fluid   p-field    ">
                    <label
                        [ngClass]="{'field-required': EditHostApplicationResourceForm.get('textField2')?.errors?.required}">
                        Resource Name
                        <span class='invalid-feedback-astrik'>*</span></label>
                    <input [(ngModel)]="ep" type="text" id="textField2_id" pattern="^[^\s]+(\s+[^\s]+)*$" maxlength="50"
                        name="textField2_name" formControlName="textField2"
                        (keyup)="enableBtn1=false;this.nameFlag=false;enableBtn=false;colorChange();" pInputText
                        placeholder="Enter resource name" class="p-inputtext inputtextHeight  " />
                    <div class="p-error"
                        *ngIf="EditHostApplicationResourceForm.get('textField2')!.invalid && (EditHostApplicationResourceForm.get('textField2')!.dirty || EditHostApplicationResourceForm.get('textField2')!.touched)">
                        <div *ngIf="EditHostApplicationResourceForm.get('textField2')?.errors?.required">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium class="p-error">Resource name is required.</medium>
                        </div>
                        <div *ngIf="EditHostApplicationResourceForm.get('textField2').errors.pattern">
                            <span class="p-error">No space allowed</span>
                        </div>
                    </div>
                    <div class="invalid-feedback">
                        <label *ngIf="this.nameFlag" class="p-error">
                            <div>
                                <label class="alert-icon alert-circle-icon" for="rolename"></label>
                                <medium class="p-error">Resource Name already exists.
                                </medium>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                <div class=" p-fluid   p-field    ">
                    <label
                        [ngClass]="{'field-required': EditHostApplicationResourceForm.get('textField1')?.errors?.required}">
                        Endpoint URL
                        <span class='invalid-feedback-astrik'>*</span></label>
                    <input [(ngModel)]="desc" (keyup)="enableBtn1=false || enableBtn=false" type="text"
                        id="textField1_id" name="textField1_name" formControlName="textField1" pattern="^[^\s]*$"
                        pInputText placeholder="Enter endpoint URL" class="p-inputtext inputtextHeight  " />

                    <div class="p-error"
                        *ngIf="EditHostApplicationResourceForm.get('textField1')!.invalid && (EditHostApplicationResourceForm.get('textField1')!.dirty || EditHostApplicationResourceForm.get('textField1')!.touched)">
                        <div *ngIf="EditHostApplicationResourceForm.get('textField1')?.errors?.required">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium class="p-error">Endpoint URL is required.</medium>
                        </div>
                        <div *ngIf="EditHostApplicationResourceForm.get('textField1').errors.pattern">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium class="p-error">No Space allowed</medium>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                <div class=" p-fluid   p-field    ">
                    <label
                        [ngClass]="{'field-required': EditHostApplicationResourceForm.get('select2')?.errors?.required}">
                        Method
                        <span class='invalid-feedback-astrik'>*</span></label>
                    <div class="p-fluid  p-field">
                        <p-dropdown [filter]="true" filterBy="label" [(ngModel)]="met" id="select2id"
                            (onChange)="enableBtn1=false || enableBtn=false" name="select2" placeholder="Select"
                            [options]="select2List" [autoDisplayFirst]="false" formControlName="select2">
                        </p-dropdown>
                    </div>
                    <div class="p-error"
                        *ngIf="EditHostApplicationResourceForm.get('select2')!.invalid && (EditHostApplicationResourceForm.get('select2')!.dirty || EditHostApplicationResourceForm.get('select2')!.touched)">
                        <div *ngIf="EditHostApplicationResourceForm.get('select2')?.errors?.required">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium class="p-error">Method is required.</medium>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left" style="margin-top: 35px;">
                <div class="p-field-checkbox">
                    <p-checkbox name="applicationRoleRequired" [(ngModel)]="applicationRoleRequired"
                        (onChange)="enableBtn1=false || enableBtn=false" binary="true" inputId="binary"
                        formControlName="applicationRoleRequired">
                    </p-checkbox>
                    <label for="sf">Application Role Required</label>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right p-mt-3 p-mb-2">
                <div class="btnwrap p-mr-3">
                    <button pButton type="button" class="p-button-brand2"
                        (click)="onClose(EditHostApplicationResourceForm);" label="Close">
                    </button>
                </div>
                <div class="btnwrap">
                    <button pButton class="p-button-primary" type="button" (click)="onSaveCheckResourceName();"
                        [disabled]="enableBtn1||enableBtn||nameFlag || !EditHostApplicationResourceForm.valid"
                        label="Save">
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>