import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PagesService } from '../pages.service';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';

@Component({
  selector: 'jhi-deactiverole',
  templateUrl: './AddAsSubscription.component.html',
  styleUrls: ['./AddAsSubscription.component.scss'],
})

export class AddAsSubscriptionComponent implements OnInit {
  AddAsSubscriptionForm = this.fb.group({});
  activeId: any[] = [];
  pageloadvalues: any;
  wfFlag = false;
  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.AddAsSubscriptionForm?.patchValue(JSON.parse(data));
    }
  }

  ngOnInit(): void {
    this.activeId = this.config?.data?.planId;
  }

  onClickNo($event: UntypedFormGroup): void {
    this.ref.close();
  }


  navigateToAddSubscription(): void {
    const navigationExtras: NavigationExtras = {
      state: {
        planData: this.activeId,
      },
    };
    this.ref.close();
    this.router.navigate(['/pages/addSubscription'], navigationExtras);

  }
}
