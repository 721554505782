import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PagesService } from '../pages.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Message, SortEvent, PrimeNGConfig, MenuItem, MessageService, ConfirmationService, FilterMatchMode } from 'primeng/api';
import { DeactivateComponent } from '../Deactivate/Deactivate.component';
import { ActivateUserComponent } from '../ActivateUser/ActivateUser.component';
import { InviteUsersComponent } from '../InviteUsers/InviteUsers.component';
import { SuspendUserComponent } from '../SuspendUser/SuspendUser.component';
import { ForceLogOffComponent } from '../ForceLogOff/ForceLogOff.component';
import { UnlockUserComponent } from '../UnlockUser/UnlockUser.component';
import { RevokeDeletedUserComponent } from '../RevokeDeletedUser/RevokeDeletedUser.component';
import { MainComponent } from 'app/layouts/main/main.component';
import { DeleteUserComponent } from '../DeleteUser/DeleteUser.component';
import { Paginator } from 'primeng/paginator';
import { ManageEntitlementsService } from '../ManageEntitlements/ManageEntitlements.service';
import { REBAC_UM_ENTITLEMENT_FLAG } from "app/app.constants";
import { DatePipe } from '@angular/common';
import { ImportUserComponent } from '../ImportUser/ImportUser.component';
import { BulkActionCheckStatusComponent } from '../BulkActionCheckStatus/BulkActionCheckStatus.component';
import { MainComponentService } from 'app/layouts/main/main.component.service';
import { ContextName, EventName } from 'app/shared/constants/logging-event.constants';
@Component({
  selector: 'jhi-userlisting',
  templateUrl: './UserListing.component.html',
  styleUrls: ['./UserListing.component.scss'],
  providers: [MessageService, DialogService, ConfirmationService, DatePipe],
})
export class UserListingComponent implements OnInit {
  ref!: DynamicDialogRef;
  UserListingForm = this.fb.group({
    textField: [null, []],
    ccprimesplitbutton: [null, []],
  });
  first = 0;
  rows = 10;
  isVisible = false;
  activeItem: any;
  activeStatus: any;
  showChip = false;
  arrayId: any[] = [];
  selectedItem!: any;
  id!: string;
  items!: MenuItem[];
  filterItems!: MenuItem[];
  msgs: Message[] = [];
  pageloadvalues: any;
  ccprimebreadcrumbList = [{ label: `User Management` }, { label: `Users` }];
  homeiconccprimebreadcrumb = { icon: 'pi pi-home', routerLink: ['/'] };
  htmlSrc: any;
  searchList: any[] = [];
  ccprimesplitbuttonList: any[] = [];
  tableList: any[] = [];
  tableList1: any[] = [];
  tableList2: any[] = [];
  statusTable: any[] = [];
  tablecols: any[] = [
    { field: 'firstName', header: 'First Name' },
    { field: 'middleName', header: 'Middle Name' },
    { field: 'lastName', header: 'Last Name' },
    { field: 'emailAddress', header: 'Email Address' },
    { field: 'status', header: 'Status' },
  ];
  table: any[] = [];
  Hoveritems!: MenuItem[];
  selectedIndex: any;
  isDisplay: any;
  hoverIndex: any;
  selectedItems: any[] = [];
  messageFlag = true;
  activeId: any[] = [];
  selectedItems1: any[] = [];
  errorFlag: boolean = true;
  detail: string = '';
  actionDisable = true;
  checkDisable = false;
  uemail: any;
  roles: any;
  flagForceLogOff = 1;
  approvalMsg: any;
  bulkActionItems: {}[];
  selectedRecords: any[] = [];
  selectList: any[] = [];
  tenantConfigId: any;
  SelectedEntity: any;
  tenantConfigFlag: boolean = false;
  selectFeatureList: any[] = [];
  featureList: any[] = [];
  userTypeselectedValues: any[] = [];
  userTypeList: any[] = [];
  inviteUserBtnFlag = true;
  createUserBtnFlag = true;
  deactivateUserBtnFlag = true;
  deleteUserBtnFlag = true;
  revokeUserBtnFlag = true;
  suspendUserBtnFlag = true;
  searchStr: string;
  sortByColumn = '';
  sorting = '';
  totalRecords: number = 0;
  pageSize: number = 10; //default pageSize 10
  pageNumber: number = 1;
  oldTotalRec = 0;
  @ViewChild('pp') paginator: Paginator;
  customFilterFlag = false;
  filterReqObj = {};
  rebacFlag: string;
  userTypes: { label: string; value: string; }[];
  dbType: { label: string; value: string; }[];
  matchModeOptions: { label: string; value: any; }[];
  reqId: any;
  messageFlag1: boolean = false;
  umPkId: any;
  statusFilter: {};

  constructor(
    private confirmationService: ConfirmationService,
    private primengConfig: PrimeNGConfig,
    public dialogService: DialogService,
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    private messageService: MessageService,
    private main: MainComponent,
    private entitlementService: ManageEntitlementsService,
    private datePipe: DatePipe
  ) {
    const navigation = this.router.getCurrentNavigation();
    const data1 = navigation?.extras?.state?.data;
    this.approvalMsg = navigation?.extras?.state?.msg;

    if (data1) {
      this.UserListingForm.patchValue(JSON.parse(data1));
    }
  }

  ngOnInit(): void {
    this.userTypes = [
      { label: 'External', value: 'External' },
      { label: 'Internal', value: 'Internal' }
    ]
    this.matchModeOptions = [
      {
        label: 'Date Before',
        value: FilterMatchMode.DATE_BEFORE
      },
      {
        label: 'Date After',
        value: FilterMatchMode.DATE_AFTER
      },
    ];
    this.dbType = [
      { label: 'DB', value: 'DB' },
      { label: 'AD', value: 'AD' }
    ]
    this.rebacFlag = REBAC_UM_ENTITLEMENT_FLAG + "";
    this.primengConfig.ripple = true;
    this.htmlSrc = '';
    if (this.approvalMsg) {
      setTimeout(() => {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: this.approvalMsg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }, 1000);
    }
    this.gettableList();
    this.filterItems = [
      {
        label: 'Active',
        command: () => {
          this.activeStatus = 'ACTIVE';
          this.showChip = true;
          this.checkDisable = true;
          this.showStatusFilter(this.activeStatus);
        },
      },

      {
        label: 'Deactivated',
        command: () => {
          this.activeStatus = 'DEACTIVATED';
          this.showChip = true;
          this.checkDisable = true;
          this.showStatusFilter(this.activeStatus);
        },

      },
      {
        label: 'Locked',
        command: () => {
          this.activeStatus = 'LOCKED';
          this.showChip = true;
          this.checkDisable = true;
          this.showStatusFilter(this.activeStatus);
        },
      },
      {
        label: 'Staged',
        command: () => {
          this.activeStatus = 'STAGED';
          this.showChip = true;
          this.checkDisable = true;
          this.showStatusFilter(this.activeStatus);
        },
      },
      {
        label: 'Suspended',
        command: () => {
          this.activeStatus = 'SUSPENDED';
          this.showChip = true;
          this.checkDisable = true;
          this.showStatusFilter(this.activeStatus);
        },
      },
    ];
    this.getEntityList();
    this.getTenantModuleConfigByTenantId();
    this.logEvent(EventName.MANAGE_USERS,ContextName.VIEW_MANAGE_USERS_PAGE,null);
  }
  gettableList(): void {
    this.actionDisable = true;
    this.checkDisable = false;
    this.showChip = false;
    this.UserListingForm.controls.textField.reset();
    const str = this.UserListingForm.value.textField;
    if (str == null) {
      this.searchStr = '';
    }
    else {
      this.searchStr = str;
    }
    this.paginator?.changePage(0);
    let reqBody = {};
    reqBody['tenantId'] = this.pagesService.tenantId;
    reqBody['page'] = 1;
    reqBody['rows'] = 10;
    reqBody['serchString'] = this.searchStr;
    reqBody['sortByColumn'] = this.sortByColumn;
    reqBody['sorting'] = "descending";
    const data = reqBody;
    this.pagesService.getAllUsersPagination(data).subscribe(results => {
      const responseData = results.success.result.records;
      if (responseData && responseData.length > 0) {
        this.totalRecords = results.success.result.recordsCount;
        this.oldTotalRec = results.success.result.recordsCount;
        responseData.forEach(
          customer => (customer.createdOn = new Date(customer.createdOn))
        );
        this.tableList = responseData;
        this.tableList1 = responseData;
        this.tableList2 = responseData;
        this.errorFlag = false;
      } else {
        this.errorFlag = true;
      }
      if (this.UserListingForm.value.textField != null) {
        this.filterMethod(this.UserListingForm.value.textField);
      }
    });

  }
  paginate(event, search) {
    let str = '';
    if (search != null) {
      str = search;
    }
    this.pageSize = event.rows;
    this.pageNumber = event.page + 1;
    let reqBody = {};
    reqBody['tenantId'] = this.pagesService.tenantId;
    reqBody['page'] = this.pageNumber;
    reqBody['rows'] = this.pageSize;
    reqBody['serchString'] = str;
    reqBody['sortByColumn'] = this.sortByColumn;
    if (this.statusFilter != null) {
      reqBody['filters'] = this.statusFilter;
    }

    if (this.sorting == "") {
      reqBody['sorting'] = "descending";
    }
    else {
      reqBody['sorting'] = this.sorting;
    }

    const data = reqBody;
    this.pagesService.getAllUsersPagination(data).subscribe(results => {
      const responseData = results.success.result.records;
      responseData.forEach(
        customer => (customer.createdOn = new Date(customer.createdOn))
      );
      this.totalRecords = results.success.result.recordsCount;
      this.oldTotalRec = results.success.result.recordsCount;
      if (responseData && responseData.length > 0) {
        this.tableList = responseData;
        this.tableList1 = responseData;
      }
    });
  }
  navigateFirstName(rowData: any): void {
    const navigationExtras: NavigationExtras = {
      state: {
        data: JSON.stringify(rowData),
      },
    };
    this.router.navigate(['/pages/viewuser'], navigationExtras);
  }

  navigateToEdit(rowData: any): void {
    this.umPkId = rowData.umPkId;
    const navigationExtras: NavigationExtras = {
      state: {
        data: JSON.stringify(rowData),
        umPkId: this.umPkId
      },
    };
    this.router.navigate(['/pages/edituser'], navigationExtras);
  }

  navigateMirrorUser(rowData: any): void {
    const navigationExtras: NavigationExtras = {
      state: {
        data: JSON.stringify(rowData),
      },
    };
    this.router.navigate(['/pages/mirroruser'], navigationExtras);
  }

  resetinviteUserccprimesplitbutton(): void {
    this.UserListingForm.reset();
  }

  resetdeactivateccprimesplitbutton(): void {
    this.UserListingForm.reset();
  }

  resetunlockUserccprimesplitbutton(): void {
    this.UserListingForm.reset();
  }

  onCcprimesplitbutton($event: UntypedFormGroup): void { }
  onCcprimebutton1($event: UntypedFormGroup): void {
    this.router.navigate(['/pages/adduser']);
  }

  show() {
    this.ref = this.dialogService.open(InviteUsersComponent, {
      header: 'Invite User',
      width: '65%',
      contentStyle: { 'max-height': '450px', 'overflow': 'visible' },//overflow: 'visible' for dropdown, auto for height scroll.
      baseZIndex: 1000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg[0] == 'error') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: "Failed to invite user" });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
      else if (msg[0] == 'duplicateEntry') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: "Request already available for approval" });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
      else if (msg == 'Close') {
        //don't show message
      }
      else if (msg) {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 8000);
        this.gettableList();
        this.removeFilter();
      }
    });
  }

  confirmHover1(id: any,rowData:any) {
    this.ref = this.dialogService.open(DeactivateComponent, {
      header: 'Deactivate User',
      data: { id: [id],rowData:rowData },
      width: '35%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Failed to deactivate user') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: "Failed to deactivate user" });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
      else if (msg) {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
      if (msg) {
        this.gettableList();
      }
    });
  }

  confirmHover2(id: any,rowData:any) {
    this.ref = this.dialogService.open(ActivateUserComponent, {
      header: 'Activate User',
      data: { id: [id],rowData:rowData},
      width: '35%',
      contentStyle: { 'max-height': '150px', overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Failed to activate user') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: "Failed to activate user" });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
      else if (msg) {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
      if (msg) {
        this.gettableList();
      }
    });
  }

  confirmMultiple1() {
    this.ref = this.dialogService.open(DeactivateComponent, {
      header: 'Deactivate User',
      data: { id: this.selectedItems },
      width: '35%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Failed to deactivate user') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: "Failed to deactivate user" });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
      else if (msg) {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
      this.selectedItems = [];
      if (msg) {
        this.gettableList();
        this.removeFilter();
      }
    });
  }

  confirmMultiple2() {
    this.ref = this.dialogService.open(ActivateUserComponent, {
      header: 'Activate User',
      data: { id: this.selectedItems },
      width: '35%',
      contentStyle: { 'max-height': '150px', overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Failed to activate user') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: "Failed to activate user" });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
      else if (msg) {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
      this.selectedItems = [];
      if (msg) {
        this.gettableList();
      }
    });
  }

  SuspendUser(id: any,rowData:any) {
    this.ref = this.dialogService.open(SuspendUserComponent, {
      header: 'Suspend User',
      data: { id: [id],rowData:rowData },
      width: '35%',
      contentStyle: { 'max-height': '150px', overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'error') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: "Failed to suspend user" });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
      else if (msg) {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.selectedItems = [];
        this.gettableList();
        this.removeFilter();
      }

    });
  }

  ForceLogOff(id: any,rowData) {
    this.ref = this.dialogService.open(ForceLogOffComponent, {
      header: 'ForceLogOff User',
      data: { id: [id] ,rowData:rowData},
      width: '460px',
      contentStyle: { 'max-height': '150px', overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Failed to Logged off User') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: "Failed to Logged off User" });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
      else if (msg) {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
      this.selectedItems = [];
      if (msg) {
        this.removeFilter();
        this.gettableList();
      }
    });
  }

  unlockUser(id: any,rowData) {
    this.ref = this.dialogService.open(UnlockUserComponent, {
      header: 'Unlock User',
      data: { id: [id] ,rowData:rowData},
      width: '25%',
      contentStyle: { 'max-height': '150px', overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'User unlocked successfully') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.gettableList();
      }
      else if (msg == 'User unlock failed') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
    });
  }


  highlight(index: any) {
    this.selectedIndex = index;
  }
  mouseLeave() {
    this.hoverIndex = null;
    this.selectedIndex = null;
  }
  onRowSelect(event: any) {
    this.selectedItems.push(event.data.id);
    this.actionDisable = false;
  }
  onRowSelect1(event: any) {
    this.selectedItems1.push(event.data.id);
  }
  onRowUnselect(event: any) {
    const index: number = this.selectedItems.indexOf(event.data.id);
    if (index !== -1) {
      this.selectedItems.splice(index, 1);
    }
    if (this.selectedItems.length == 0) {
      this.actionDisable = true;
    }
  }
  onSelectAllUsers() {
    this.selectedItems = [];
    this.selectedRecords.forEach(element => {
      this.selectedItems.push(element.id);
    });
    if (this.selectedItems.length > 0) {
      this.actionDisable = false;
    } else {
      this.actionDisable = true;
    }
  }
  checkItemOption() {
    if (this.selectedItems.length == 0) {
      this.actionDisable = true;
    } else {
      this.items = [
        {
          label: 'Activate',
          command: () => {
            this.confirmMultiple2();
          },
        },
        {
          label: 'Deactivate',
          command: () => {
            this.confirmMultiple1();
          },
        },
        {
          label: 'Suspend User',
          command: () => {
          },
          disabled: true,
        },
      ];
      if (this.activeStatus == 'ACTIVE' || this.activeStatus == 'LOCKED') {
        this.items[0].visible = false;
        this.items[2].visible = false;
        if (this.deactivateUserBtnFlag) {
          this.items[1].visible = true;
        }
        else {
          this.items[1].visible = false;
        }
      }
      else if (this.activeStatus == 'DEACTIVATED') {
        this.items[1].visible = false;
      }
      else if (this.activeStatus == 'STAGED') {
        this.items[0].visible = false;
        this.items[1].visible = false;
      }



    }
  }

  filterMethod(event: any) {
    if (this.checkDisable) {  //if status state is enabled
      this.tableList2 = this.statusTable;
      let filtered: any[] = [];
      let items: any;
      let cc;
      let query = event;
      if (query?.length > 0) {
        this.tableList2.forEach(element => {
          items = element;
          cc = element.firstName + " " + element.lastName;

          if (items.fullName?.toLowerCase().includes(query.toLowerCase()) || cc?.toLowerCase().includes(query.toLowerCase()) || items.email?.toLowerCase().includes(query.toLowerCase()) || items.userStatus?.toLowerCase().includes(query.toLowerCase()) || items.middleName?.toLowerCase().includes(query.toLowerCase())) {
            filtered.push(items);
          }
        });
        this.tableList = filtered;
      }
      else {
        this.tableList = this.statusTable;
      }
    }
    else {   //if status state is false
      let filtered: any[] = [];
      let items: any;
      let cc;
      let query = event;
      if (query?.length > 0) {
        this.tableList1.forEach(element => {
          items = element;
          cc = element.firstName + " " + element.lastName;
          if (items.fullName?.toLowerCase().includes(query.toLowerCase()) || cc?.toLowerCase().includes(query.toLowerCase()) || items.email?.toLowerCase().includes(query.toLowerCase()) || items.userStatus?.toLowerCase().includes(query.toLowerCase()) || items.middleName?.toLowerCase().includes(query.toLowerCase())) {
            filtered.push(items);
          }
        });
        this.tableList = filtered;
      }
      else {
        this.tableList = this.tableList1;
      }
    }
  }



  removeFilter() {
    this.actionDisable = true;
    this.checkDisable = false;
    this.showChip = false;
    this.UserListingForm.controls.textField.reset();
    this.statusFilter = null;
    document.getElementById('fullNamePosition_id').classList.remove("fullNamePosition");
  }
  callUserList() {
    this.gettableList();
  }
  showRevokeUser() {
    this.ref = this.dialogService.open(RevokeDeletedUserComponent, {
      header: 'Revoke User',
      width: '80%',
      height: '90%',
      contentStyle: { 'overflow': 'visible' },//overflow: 'visible' for dropdown, auto for height scroll. max-height:500px
      baseZIndex: 1000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'error') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: "Failed to revoke User" });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
      else if (msg == 'This type of user creation is disabled for Tenant') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
      else if (msg) {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 8000);
        this.gettableList();
        this.removeFilter();
      }
    });
  }
  getEntityList(): void {
    this.selectList = [];
    this.pagesService.getEntity().subscribe(results => {
      const responseData = results.success?.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
            inactive: true
          };
          data.label = element.label;
          data.value = element.value;
          if (data.label == 'Users') {
            this.SelectedEntity = data.value;
          }
          this.selectList.push(data);
        });
        this.selectList = [...this.selectList];
      }
    });
  }
  getUserTypeList(): void {
    this.pagesService.userTypeList().subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null
          };
          data.label = element.label;
          data.value = element.value;
          this.userTypeList.push(data);
        });
        this.userTypeList = [...this.userTypeList];
      }
      this.setUserTypeValidation();
    });
  }

  setUserTypeValidation() {
    this.userTypeList.forEach(element1 => {
      this.userTypeselectedValues.forEach(element2 => {
        if (element2.isDeleted == 'false') {
          if (element2.userTypeId == element1.value) {
            if (element1.label == 'AD User') {
              this.inviteUserBtnFlag = true;
            }
            else if (element1.label == 'DB User') {
              this.createUserBtnFlag = true;
            }
          }
        }
      });
    });
  }

  setFeatureValidation() {
    this.featureList.forEach(element1 => {
      this.selectFeatureList.forEach(element2 => {
        if (element2.isDeleted == 'false') {
          if (element2.featureId == element1.value) {
            if (element1.label == 'Deactivate User') {
              this.deactivateUserBtnFlag = true;
            }
            else if (element1.label == 'Delete user') {
              this.deleteUserBtnFlag = true;
            }
            else if (element1.label == 'Revoke user') {
              this.revokeUserBtnFlag = true;
            }
            else if (element1.label == 'Suspend user') {
              this.suspendUserBtnFlag = true;
            }
          }
        }
      });
    });
  }

  getFeatureByModuleIdList(): void {
    this.pagesService.getFeatureByModuleId(this.SelectedEntity).subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
            id: null,
            isDeleted: false
          };
          data.label = element.label;
          data.value = element.value;
          this.featureList.push(data);
        });
        this.featureList = [...this.featureList];
        this.setFeatureValidation();
      }
    });
  }
  getTenantModuleConfigByTenantId(): void {
    this.pagesService.getTenantModuleConfigByTenantId(this.pagesService.tenantId).subscribe(results => {
      const responseData = results.success?.result;
      if (responseData && responseData.length > 0) {
        this.SelectedEntity = results?.success?.result[0]?.moduleId;
        this.tenantConfigFlag = true;
        this.inviteUserBtnFlag = false;
        this.createUserBtnFlag = false;
        this.deactivateUserBtnFlag = false;
        this.deleteUserBtnFlag = false;
        this.revokeUserBtnFlag = false;
        this.suspendUserBtnFlag = false;
        this.tenantConfigId = results?.success?.result[0]?.tenantConfigId;
        this.selectFeatureList = results?.success?.result[0]?.featureList;
        this.userTypeselectedValues = results?.success?.result[0]?.usertypeList;
        this.getUserTypeList();
        this.getFeatureByModuleIdList();
        this.pagesService.isMailAutoTrigger = results?.success?.result[0]?.isMailAutoTrigger;
        this.pagesService.userStorage=results?.success?.result[0]?.userStorage;
      }
    });
  }
  DeleteUser(rowData: any) {
    const fullName = rowData.firstName + " " + rowData.middleName + " " + rowData.lastName;
    this.ref = this.dialogService.open(DeleteUserComponent, {
      header: 'Delete User',
      data: { id: [rowData.id], name: fullName, rowData:rowData },
      width: '35%',
      contentStyle: { 'max-height': '150px', overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Failed') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Failed to delete user.' });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);

      }
      else if (msg) {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'User deleted Successfully' });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.gettableList();
      }
    });
  }
  UserFilterMethod(event: any) {
    let filtered: any[] = [];
    let items: any;
    let cc;
    let query = event.target.value;
    this.tableList = [];
    this.tableList1 = [];
    this.paginator.changePage(0);
    let reqBody = {};
    reqBody['tenantId'] = this.pagesService.tenantId;
    reqBody['page'] = 1;
    reqBody['rows'] = this.pageSize;
    reqBody['serchString'] = query;
    if (query == "") {
      reqBody['sorting'] = "descending";
    }
    const data = reqBody;
    this.pagesService.getAllUsersPagination(data).subscribe({
      next: results => {
        const responseData = results.success.result.records;
        responseData.forEach(
          customer => (customer.createdOn = new Date(customer.createdOn))
        );
        this.totalRecords = results.success.result.recordsCount;
        this.oldTotalRec = results.success.result.recordsCount;
        this.tableList = responseData;
        this.tableList1 = responseData;
      },
      error: error => {
        this.totalRecords = 0;
        this.oldTotalRec = 0;
      }
    });
  }
  sortData(event: SortEvent) {
    let fieldName = event.field;
    let orderNum = event.order;
    let orderBy;
    if (orderNum == 1) {
      orderBy = "ascending";
    }
    else {
      orderBy = "descending";
    }
    let str = '';
    const search = this.UserListingForm.value.textField;
    if (search != null) {
      str = search;
    }
    if (fieldName == 'firstName') {
      fieldName = 'firstName';
    }
    if (fieldName == 'middleName') {
      fieldName = 'middleName';
    }
    else if (fieldName == 'lastName') {
      fieldName = 'lastName';
    }
    else if (fieldName == 'email') {
      fieldName = 'email';
    }
    else if (fieldName == 'userName') {
      fieldName = 'userName';
    }
    else if (fieldName == 'userStatus') {
      fieldName = 'userStatus';
    }
    if (this.sortByColumn != fieldName || this.sorting != orderBy) {
      this.sortByColumn = fieldName;
      this.sorting = orderBy;
      let reqBody = {};
      reqBody['tenantId'] = this.pagesService.tenantId;
      reqBody['page'] = 1;
      reqBody['rows'] = this.pageSize;
      reqBody['serchString'] = str;
      reqBody['sortByColumn'] = fieldName;
      reqBody['sorting'] = orderBy;
      reqBody['filters'] = {};
      const data = reqBody;
      this.pagesService.getAllUsersPagination(data).subscribe(results => {

        const responseData = results.success.result.records;
        responseData.forEach(
          customer => (customer.createdOn = new Date(customer.createdOn))
        );
        this.totalRecords = results.success.result.recordsCount;
        this.oldTotalRec = results.success.result.recordsCount;
        this.tableList = responseData;
        this.customSort(event);
      });
    }


  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else {
        let res = (value1 > value2) ? 1 : 0;
        result = (value1 < value2) ? -1 : res;
      }
      return (event.order * result);
    });
  }
  columnFilter(event: any) {
    let oldFilter = event.filters;
    let newFilter = event.filters;
    let filterEvent = [...Object.entries(newFilter)];
    let filterBody = [];
    let finalObj = {};
    let cnt = 0;
    filterEvent.forEach((element: any = {}) => {
      let filterValue = element[1];
      filterBody = [];
      if (filterValue.length > 1) {
        if (filterValue[1]?.value == '') {
          filterValue[1].value = null;
        }
      }
      if (filterValue[0]?.value == '') {
        filterValue[0].value = null;
      }
      if (filterValue[0]?.value != null) {
        filterBody.push(filterValue[0]);
        if (element[0] == 'umPkId') {
          filterValue[0].value = filterValue[0].value.toString();
        }

      }
      if (filterValue[0]?.value != null) {
        filterBody.push(filterValue[0]);
        if (filterValue[0].matchMode.includes('date')) {
          filterValue[0].value = this.datePipe.transform(filterValue[0].value, 'yyyy-MM-dd 00:00:00');
        }
      }
      if (filterValue[1]?.value != null) {
        if (filterValue[1].matchMode.includes('date')) {
          filterValue[1].value = this.datePipe.transform(filterValue[1].value, 'yyyy-MM-dd 00:00:00');
        }
        filterBody.push(filterValue[1]);
      }
      if (filterValue[1]?.value != null) {
        filterBody.push(filterValue[1]);
      }
      if (filterBody.length > 0) {
        finalObj[element[0]] = filterBody;
      }
    });
    let compareObj = JSON.stringify(this.filterReqObj) === JSON.stringify(finalObj);

    let str = '';
    const search = this.UserListingForm.value.textField;
    if (search != null) {
      str = search;
    }
    let reqBody = {};
    if (!compareObj) {
      reqBody['tenantId'] = this.pagesService.tenantId;
      reqBody['page'] = 1;
      reqBody['rows'] = this.pageSize;
      reqBody['serchString'] = str;
      reqBody['filters'] = finalObj;
      reqBody['sortByColumn'] = this.sortByColumn;
      reqBody['sorting'] = this.sorting;
    }
    else {
      reqBody['tenantId'] = this.pagesService.tenantId;
      reqBody['page'] = this.pageNumber;
      reqBody['rows'] = this.pageSize;
      reqBody['serchString'] = str;
      reqBody['filters'] = finalObj;
      reqBody['sortByColumn'] = this.sortByColumn;
      reqBody['sorting'] = this.sorting;
    }

    const data = reqBody;
    if (Object.keys(finalObj).length > 0) {
      this.filterReqObj = finalObj;
      this.customFilterFlag = true;
      this.pagesService.getAllUsersPagination(data).subscribe(results => {
        this.oldTotalRec = this.totalRecords;
        const responseData = results.success.result.records;
        responseData.forEach(
          customer => (customer.createdOn = new Date(customer.createdOn))
        );
        this.totalRecords = results.success.result.recordsCount;
        event.filteredValue.splice(0, event.filteredValue.length);
        responseData.forEach(element => {
          event.filteredValue.push(element);
        });

      });
      event.filters = oldFilter;

    }
    else {
      this.totalRecords = this.oldTotalRec;
      if (this.customFilterFlag) {
        this.customFilterFlag = false;
        let reqBody = {};
        reqBody['tenantId'] = this.pagesService.tenantId;
        reqBody['page'] = 1;
        reqBody['rows'] = this.pageSize;
        reqBody['serchString'] = str;
        reqBody['sorting'] = "descending";
        const data1 = reqBody;
        this.pagesService.getAllUsersPagination(data1).subscribe(results => {
          this.oldTotalRec = this.totalRecords;
          const responseData = results.success.result.records;
          responseData.forEach(
            customer => (customer.createdOn = new Date(customer.createdOn))
          );
          this.totalRecords = results.success.result.recordsCount;
          event.filteredValue.splice(0, event.filteredValue.length);
          responseData.forEach(element => {
            event.filteredValue.push(element);
          });
        });
      }

    }

  }
  showStatusFilter(status: any) {
    let finalObj = {};
    let reqBody1 = {};
    reqBody1['value'] = status;
    reqBody1['matchMode'] = "startsWith";
    reqBody1['operator'] = "and";
    finalObj['userStatus'] = [reqBody1];
    this.statusFilter = finalObj;
    let reqBody = {};
    reqBody['tenantId'] = this.pagesService.tenantId;
    reqBody['page'] = this.pageNumber;
    reqBody['rows'] = this.pageSize;
    reqBody['serchString'] = "";
    reqBody['filters'] = finalObj;
    reqBody['sortByColumn'] = this.sortByColumn;
    reqBody['sorting'] = this.sorting;
    const data = reqBody;
    this.tableList = [];
    document.getElementById('fullNamePosition_id').classList.add("fullNamePosition");
    this.pagesService.getAllUsersPagination(data).subscribe(results => {
      const responseData = results.success.result.records;
      responseData.forEach(
        customer => (customer.createdOn = new Date(customer.createdOn))
      );
      if (responseData && responseData.length > 0) {
        this.totalRecords = results.success.result.recordsCount;
        this.oldTotalRec = results.success.result.recordsCount;
        this.tableList = responseData;
        this.tableList1 = responseData;
        this.tableList2 = responseData;
      }
    });

    this.logEvent(EventName.MANAGE_USERS,ContextName.FILTER_BY_USER_STATUS,status);


  }
  showImportUser() {
    this.ref = this.dialogService.open(ImportUserComponent, {
      header: 'Import User',
      width: '630px',
      height: '306px',
      contentStyle: { 'border-radius': '4px' },
      baseZIndex: 10000,
    });

    this.ref.onClose.subscribe(msg => {
      if (msg != undefined) {
        this.reqId = msg;
        this.messageFlag1 = true;
        setTimeout(() => {
          this.messageFlag1 = false;
        }, 3000);
        this.gettableList();
      }
    }
    );

  }
  showBulkActionCheckStatus() {
    this.ref = this.dialogService.open(BulkActionCheckStatusComponent, {
      header: 'Bulk Action Status',
      width: '92%',
      height: '90%',
      contentStyle: { 'overflow': 'visible' },//overflow: 'visible' for dropdown, auto for height scroll. max-height:500px
      baseZIndex: 1000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'error') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: "Failed to check bulk action status" });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
    });


  }
  clearmsg() {
    this.messageFlag1 = false;
  }

  logEvent(eventName,eventContextName,value){
    const data={
      "ContextName":eventContextName,
      "value":value,
      "EventName":eventName
    };
    this.pagesService.logUserEvent(data).subscribe(resp =>{
      //console.log(resp);
      
    },
            error => {
              console.log("Error >> ",ContextName.VIEW_MANAGE_USERS_PAGE,":", error);
            });
  }
}
