import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CIAM_UM_SERVER_API_URL } from 'app/app.constants';

@Injectable({
    providedIn: 'root'
})
export class ViewUserService {
    constructor(private http: HttpClient) { }

    getEntitlementStatusList(): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageEntitlements/getEntitlementStatusList', {});
    }
    getComponentList(): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageEntitlements/getComponentList_New', {});
    }
    getUserEntitlementList(data) {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umUserDetails/getUserEntitlementList', data);
    }
    getUserRoleList(data): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umUserDetails/getUserRoleList', data);
    }


}
