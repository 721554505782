import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PagesService } from '../pages.service';
import { MenuItem, MessageService } from 'primeng/api';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { AssignUsersComponent } from '../AssignUsers/AssignUsers.component';
import { DeleteRoleComponent } from '../DeleteRole/DeleteRole.component';

@Component({
  selector: 'jhi-roles',
  templateUrl: './Roles.component.html',
  styleUrls: ['./Roles.component.scss'],
  providers: [MessageService, DialogService],
})
export class RolesComponent implements OnInit {
  ref!: DynamicDialogRef;
  RolesForm = this.fb.group({
    textField: [null, []],
  });
  rows = 10;
  messageFlag = true;
  isVisible = false;
  activeItem: any;
  GroupCount: any;
  UserCount: any;
  pageloadvalues: any;
  ccprimebreadcrumbList = [{ label: `User Management` }, { label: `Roles`, routerLink: ['/pages/roles'] }];
  homeiconccprimebreadcrumb = { icon: 'pi pi-home', routerLink: ['/'] };
  htmlSrc: any;
  searchList: any[] = [];
  tableList: any[] = [];
  tableList1: any[] = [];
  errorFlag: boolean = true;
  tablecols: any[] = [
    { field: 'name', header: 'Name' },
    { field: 'description', header: 'Description' },
    { field: 'users', header: 'Users' },
    { field: 'groups', header: 'Groups' },
    { field: 'policies', header: 'Policies' },
    { field: 'status', header: 'Status' },
  ];
  table: any[] = [];
  Hoveritems!: MenuItem[];
  selectedIndex: any;
  hoverIndex: any;
  deletemsg: any;
  constructor(
    public dialogService: DialogService,
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    private messageService: MessageService
  ) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    this.deletemsg = navigation?.extras?.state?.msg;
    if (data) {
      this.RolesForm.patchValue(JSON.parse(data));
    }
  }
  ngOnInit(): void {
    this.htmlSrc = '';
    this.Hoveritems = [
      {
        label: 'View & Edit',

        command: e => {
          this.navigateViewEditRoleName(this.activeItem);
        },
      },

      {
        label: 'Delete',
        command: () => {
          this.DeleteRole();
        },
        disabled: true,
      },

      {
        label: 'Assign User',
        command: () => {
          this.resetinviteUserccprimesplitbutton();
          this.show();
        },
      },
    ];
    if (this.deletemsg) {
      setTimeout(() => {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: this.deletemsg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 2000);
      }, 1000);
    }
    this.gettableList();
  }

  //navigating to view role 
  navigateViewEditRoleName(rowData: any): void {
    const navigationExtras: NavigationExtras = {
      state: {
        data: JSON.stringify(rowData),
      },
    };
    this.router.navigate(['/pages/viewrole'], navigationExtras);
  }

  //get role list API
  gettableList(): void {
    this.pagesService.gettableDataRoles().subscribe(results => {
      const responseData = results.success.result.records;
      if (responseData && responseData.length > 0) {
        this.tableList = responseData;
        this.tableList1 = responseData;
        this.errorFlag = false;
      } else {
        this.errorFlag = true;
      }
    });
  }

  //search functionality
  RoleFilterMethod(event: any) {
    let filtered: any[] = [];
    let items: any;
    let cc;
    let query = event;
    if (query.length > 0) {
      this.tableList1.forEach(element => {
        items = element;
        if (items.displayName?.toLowerCase().includes(query.toLowerCase()) || items.description?.toLowerCase().includes(query.toLowerCase())) {
          filtered.push(items);
        }
      });
      this.tableList = filtered;
    }
    else {
      this.tableList = this.tableList1;
    }
  }

  onCcprimebutton($event: UntypedFormGroup): void { }
  onAddRole($event: UntypedFormGroup): void {
    this.router.navigate(['/pages/addroles']);
  }
  highlight(index: any) {
    this.selectedIndex = index;
  }
  resetinviteUserccprimesplitbutton(): void {
    this.RolesForm.reset();
  }

  //Assign user dialog box 
  show() {
    this.ref = this.dialogService.open(AssignUsersComponent, {
      data: { id: this.activeItem, dialogFlag: 'roles' },
      header: 'Assign Users',
      width: '50%',
      contentStyle: { 'max-height': '160px', overflow: 'visible' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'error') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Failed to assign users.' });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
      else if (msg) {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.gettableList();
      }
    });
  }

  // delete role dialog box
  DeleteRole() {
    this.ref = this.dialogService.open(DeleteRoleComponent, {
      data: { id: this.activeItem.id, name: this.activeItem.displayName, dialogFlag: 'roles' },
      header: 'Delete Role',
      width: '400px',
      contentStyle: { 'max-height': '160px', overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'failed') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Failed to Delete Role.' });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
      else if (msg == 'success') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'Role Deleted Successfully.' });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.gettableList();
      }
    });

  }
  mouseLeave() {
    this.hoverIndex = null;
    this.selectedIndex = null;
  }
}
