import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { PagesService } from '../pages.service';
import { MessageService } from 'primeng/api';
import { Fulkrum_Workspace_URL } from 'app/app.constants';
interface City {
  name: string,
  code: string
}
@Component({
  selector: 'jhi-EditEmailTemplate',
  templateUrl: './EditEmailTemplate.component.html',
  styleUrls: ['./EditEmailTemplate.component.scss'],
  providers: [MessageService, DialogService],
})
export class EditEmailTemplateComponent implements OnInit {
  EditEmailTemplateForm = this.fb.group({
    subject: [null, [Validators.required]],
    appLink: [null, [Validators.required]],
    templateName: [null, [Validators.required]],
    sign: [null, [Validators.required]],
    emailBody: [null, [Validators.required]]
  });
  pageloadvalues: any;
  fonts: City[];
  Sizes: City[];
  editorContent: string = '';
  BASE_URL: string = Fulkrum_Workspace_URL;
  checked: boolean = false;
  solImageFileSize: boolean;
  imagePlacheholder: boolean;
  imagevalid: boolean;
  default: boolean;
  solImage: boolean;
  imageFileFlag: boolean;
  logo: File;
  formValidFlag: boolean;
  productPrimaryImage: any;
  imageFileFlag1: boolean;
  solImageFileSize1: boolean;
  imagePlacheholder1: boolean;
  imagevalid1: boolean;
  formValidFlag1: boolean;
  solImage1: boolean;
  productPrimaryImage1: any;
  baner: File;
  appId: any;
  isDefault: string;
  emailTemplate: string;
  templateId: any;
  templateName: any;
  subject: any;
  emailBody: any;
  signature: any;
  applicationLink: any;
  enableBtn1 = true;
  oldTemplateName: any;
  oldSubject: any;
  oldEmailBody: any;
  oldSignature: any;
  oldApplicationLink: any;
  oldDefault: any;
  imageURL: File;
  bannerPath: File;
  logoPath: any;
  bannerURL: File;
  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private fb: UntypedFormBuilder,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public pagesService: PagesService) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.EditEmailTemplateForm.patchValue(JSON.parse(data));
    }
  }

  ngOnInit(): void {
    this.templateId = this.config?.data?.activeData?.id;
    this.emailTemplate = '<p>Hello world</p>';
    this.getTemplateDetailsById();
    this.appId = this.config?.data?.id;
    this.fonts = [
      { name: 'Arial', code: 'NY' },
      { name: 'Roboto', code: 'RM' },
      { name: 'sans-serif', code: 'LDN' },
      { name: 'Segoe UI', code: 'PRS' },
      { name: 'Verdana', code: 'IST' }
    ];
    this.Sizes = [
      { name: 'Small', code: 'NY' },
      { name: 'Medium', code: 'RM' },
      { name: 'large', code: 'LDN' },
      { name: 'x-large', code: 'PRS' },
      { name: 'x-large', code: 'IST' }
    ];

  }

  onClose($event: UntypedFormGroup): void {
    this.ref.close();
  }

  onUpload(event) {
    this.messageService.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
  }
  removeFiles(file, uploaders) {
    const index = uploaders._files.indexOf(file);
    uploaders._files.splice(index, 1);

  }
  clearEditor() {
    this.editorContent = '';
  }
  editTemplate(): void {
    const reqBody = {};
    const formdata = new FormData();
    formdata.append('templateId', this.templateId);
    if (this.oldTemplateName != this.EditEmailTemplateForm.value.templateName) {
      formdata.append('templateName', this.EditEmailTemplateForm.value.templateName);
    }
    if (this.oldSubject != this.EditEmailTemplateForm.value.subject) {
      formdata.append('subject', this.EditEmailTemplateForm.value.subject);
    }
    if (this.oldEmailBody != this.EditEmailTemplateForm.value.emailBody) {
      formdata.append('emailBody', this.EditEmailTemplateForm.value.emailBody);

    }
    if (this.oldSignature != this.EditEmailTemplateForm.value.sign) {
      formdata.append('signature', this.EditEmailTemplateForm.value.sign);

    }
    this.isDefault = this.checked + " ";
    if (this.oldApplicationLink != this.EditEmailTemplateForm.value.appLink) {
      formdata.append('applicationLink', this.EditEmailTemplateForm.value.appLink);
    }
    if (this.logo != undefined || this.logo != null) {
      formdata.append('logo', this.logo);
    }
    if (this.baner != undefined || this.baner != null) {
      formdata.append('banner', this.baner);
    }
    this.pagesService.editTemplate(formdata).subscribe({
      next: results => {
        this.ref.close("Email template details saved successfully");
      },
      error: error => {

        this.ref.close("Failed to Edit Template");

      }
    });
  }
  onSelectLogo(event: any): void {
    this.solImageFileSize = false;
    this.imagePlacheholder = false;
    this.imagevalid = false;
    this.formValidFlag = false;
    this.logo = new File([event.files[0]], event.files[0].name, {
      type: event.files[0].type
    });
    this.imageFileFlag = true;
    const isvalid = /(\.jpg|\.jpeg|\.png|\.svg)$/i;
    if (isvalid.test(this.logo.name)) {
      this.default = true;
      this.solImageFileSize = false;
      const regex = /[~#%&*{}:<>?/|'"]/g;
      this.productPrimaryImage = event.currentFiles[0].objectURL;
      let res = regex.test(this.logo.name);
      if (res) {
        this.solImage = true;
      }
      else if (event.currentFiles[0].size > 5000000) {
        this.solImageFileSize = true;
        this.formValidFlag = true;
      }
      else {
        setTimeout(() => {
        }, 1000);

      }
    } else {
      this.enableBtn1 = false;
      this.imagevalid = true;
      this.formValidFlag = true;
    }
    console.log(this.enableBtn1);
  }
  onRemoveIcon(event: any, fileUploadPageIconChooseFilebtn): void {
    const index = fileUploadPageIconChooseFilebtn.files.indexOf(
      event
    );
    fileUploadPageIconChooseFilebtn.files.splice(index, 1);
    this.productPrimaryImage = "";
    this.imagevalid = false;
    this.solImageFileSize = false;
    setTimeout(() => {
    }, 1000);
  }
  onSelectBaner(event: any): void {
    this.solImageFileSize1 = false;
    this.imagePlacheholder1 = false;
    this.imagevalid1 = false;
    this.formValidFlag1 = false;
    this.baner = new File([event.files[0]], event.files[0].name, {
      type: event.files[0].type
    });
    this.imageFileFlag1 = true;
    const isvalid = /(\.jpg|\.jpeg|\.png|\.svg)$/i;
    if (isvalid.test(this.baner.name)) {
      this.default = true;
      this.solImage1 = false;
      this.solImageFileSize1 = false;
      const regex = /[~#%&*{}:<>?/|'"]/g;
      this.productPrimaryImage1 = event.currentFiles[0].objectURL;
      let res = regex.test(this.baner.name);
      if (res) {
        this.solImage = true;
      }
      else if (event.currentFiles[0].size > 5000000) {
        this.solImageFileSize1 = true;
        this.formValidFlag1 = true;
      }
      else {
        setTimeout(() => {
        }, 1000);

      }
    } else {
      this.enableBtn1 = false;
      this.imagevalid1 = true;
      this.formValidFlag1 = true;
    }
    console.log(this.enableBtn1);
  }
  onRemoveBaner(event: any, fileUploadPageIconChooseFilebtn1): void {
    const index = fileUploadPageIconChooseFilebtn1.files.indexOf(
      event
    );
    fileUploadPageIconChooseFilebtn1.files.splice(index, 1);
    this.productPrimaryImage1 = "";
    this.imagevalid1 = false;
    this.solImageFileSize1 = false;
    setTimeout(() => {
    }, 1000);
  }
  getTemplateDetailsById() {
    const reqBody = {};
    reqBody['templateId'] = this.templateId;

    const data = reqBody;
    this.pagesService.getTemplateDetailsByTemplateId(data).subscribe(results => {
      const responseData = results.success.result;
      this.templateName = responseData.templateName;
      this.oldTemplateName = responseData.templateName;
      this.subject = responseData.subject;
      this.oldSubject = responseData.subject;
      this.emailBody = responseData.emailBody;
      this.oldEmailBody = responseData.emailBody;
      this.signature = responseData.signature;
      this.oldSignature = responseData.signature;
      this.applicationLink = responseData.applicationLink;
      this.oldApplicationLink = responseData.applicationLink;
      this.isDefault = responseData.isDefault;
      this.oldDefault = responseData.isDefault;
      this.logoPath = responseData?.logo;
      this.bannerURL = responseData?.banner;
      if (this.logoPath != null) {
        this.imageFileFlag = true;
      }
      if (this.bannerURL != null) {
        this.imageFileFlag1 = true;
      }
      this.imageURL = this.logoPath;
      console.log(this.bannerURL, "Banner url");
      this.bannerPath = responseData?.banner;
    });
  }

}