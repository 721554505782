import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CIAM_UM_SERVER_API_URL } from 'app/app.constants';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RoleManagementService {
    constructor(private http: HttpClient) { }

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    getRoleList(filter: any): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageRole/getAllRoleList', filter);
    }

    getRoleStatusList(formData): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageRole/getRoleStatusList', formData);
    }

    createNewRole(data: any): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageRole/createRole', data);
    }

    updateRoleStatus(data: any): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageRole/updateRoleStatus', data);
    }

    getRoleActivityLog(data: any): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageRole/getRoleActivityLog', data);
    }
}
