import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PagesService } from '../pages.service';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { MainComponent } from 'app/layouts/main/main.component';
import { ContextName, EventName } from 'app/shared/constants/logging-event.constants';
@Component({
  selector: 'jhi-deleteattribute',
  templateUrl: './DeleteUser.component.html',
  styleUrls: ['./DeleteUser.component.scss'],
})
export class DeleteUserComponent implements OnInit {
  DeleteUserForm = this.fb.group({
  });
  pageloadvalues: any;
  groupId: any;
  appId: any;
  resourceId: any;
  active: any;
  dName: any;
  Ucount: any;
  Gcount: any;
  flag1: boolean;
  userID: any;
  name: any;
  wfFlag: boolean;
  stompClient: any;
  deleteUserData:any[]=[];
  constructor(private router: Router, protected activatedRoute: ActivatedRoute, private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    private messageService: MessageService,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.DeleteUserForm.patchValue(JSON.parse(data));
    }

  }
  ngOnInit(): void {
    this.name = this.config?.data?.name;
    this.deleteUserData = this.config?.data?.rowData;
  }

  onClickNo($event: UntypedFormGroup): void {
    this.ref.close();
  }

  getuserTableList(): void {
    this.pagesService.getuserTableDataViewRole(this.active).subscribe(results => {
      if (results?.error?.messageCode == "CIAM 200") {
        this.Ucount = 0;
      }
      else if (results?.success?.messageCode == "CIAM 250") {
        const responseData = results.success.result;
        this.Ucount = responseData.length;
      }

    });
  }
  getGroupList(): void {
    this.pagesService.getGroupByRole(this.active).subscribe(results => {
      if (results?.error?.messageCode == "CIAM 300") {
        this.Gcount = 0;
      }
      else if (results?.success?.messageCode == "CIAM 350") {
        const responseData = results.success.result;
        this.Gcount = responseData.length;
      }
    }
    );
  }

  deleteRole() {
    this.userID = this.config?.data?.id;
    const reqBody = {};
    reqBody["tenantId"] = this.pagesService.tenantId;
    reqBody["userIds"] = this.userID;
    const formData = reqBody;
    this.pagesService.DeleteUser(formData).subscribe({
      next: (results: any) => {
        let cnt = 0;
        let kk = results.success.result;
        kk.forEach(element => {
          if (element.messageCode == 'CIAM 154') {
            cnt = cnt + 1;
            this.wfFlag = true;
          }
          else if (element.messageCode == 'CIAM 260') {
            this.wfFlag = false;
          }
        });

        if (this.wfFlag) {
          this.ref.close();
          this.navigateToMyReq('Delete user request has been sent for approval');

        }
        else if (!this.wfFlag) {
          this.send();
          sessionStorage.removeItem('previousUrl');
          this.ref.close('Sucess');

        }
        this.logEvent(EventName.DELETE_USER,ContextName.DELETE_USER,this.deleteUserData);
      }
      ,
      error: error => {
        if (error.error?.error?.messageCode == "CIAM 105") {
          this.ref.close('Request already available for approval');
        }
        else {
          this.ref.close('Failed');
        }
      }
    });


  }
  navigateToMyReq(msg: any): void {
    const navigationExtras: NavigationExtras = {
      state: {
        msg: msg,
      },
    };
    this.router.navigate(['/pages/myrequestslisting'], navigationExtras);
  }

  send() {
    const data: any = this.config?.data?.id;
    this.stompClient = MainComponent.stompCl;
    const obj = {
      tenantId: this.pagesService.tenantId,
      userId: data?.[0]
    }
    this.stompClient.send("/ciam/v1/user/force-logoff-notification", {}, JSON.stringify(obj));
  }

  logEvent(eventName,eventContextName,value){
    const data={
      "ContextName":eventContextName,
      "value":value,
      "EventName":eventName,
      "newValue":'DELETE USER'
    };
    this.pagesService.logUserEvent(data).subscribe(resp =>{
      //console.log(resp);
      
    },
            error => {
              console.log("Error >> ",ContextName.VIEW_MANAGE_USERS_PAGE,":", error);
            });
  }
}