import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { PagesService } from '../pages.service';
import { MenuItem, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ViewApplicationPlanComponent } from '../ViewApplicationPlan/ViewApplicationPlan.component';
import { DeletePlanComponent } from '../DeletePlan/DeletePlan.component';
import { ReorderPlanComponent } from '../ReorderPlan/ReorderPlan.component';

@Component({
  selector: 'jhi-roles',
  templateUrl: './planListing.component.html',
  styleUrls: ['./planListing.component.scss'],
  providers: [MessageService, DialogService],
})
export class PlanListingComponent implements OnInit {
  ref!: DynamicDialogRef;
  planForm = this.fb.group({
    textField1: [null, []],
    application: [null, [Validators.required]],
  });
  rows = 10;
  messageFlag = true;
  isVisible = false;
  activeItem: any;
  GroupCount: any;
  UserCount: any;
  pageloadvalues: any;
  ccprimebreadcrumbList = [{ label: `Plans & Subscription` }, { label: `Application plans`, routerLink: ['/pages/roles'] }];
  homeiconccprimebreadcrumb = { icon: 'pi pi-home', routerLink: ['/'] };
  htmlSrc: any;
  searchList: any[] = [];
  tableList: any[] = [];
  tableList1: any[] = [];
  errorFlag: boolean = false;
  tablecols: any[] = [
    { field: 'name', header: 'Name' },
    { field: 'description', header: 'Description' },
    { field: 'users', header: 'Users' },
    { field: 'groups', header: 'Groups' },
    { field: 'policies', header: 'Policies' },
    { field: 'status', header: 'Status' },
  ];
  table: any[] = [];
  Hoveritems!: MenuItem[];
  selectedIndex: any;
  hoverIndex: any;
  deletemsg: any;
  selectList: any[];
  customFliterFlag = true;
  errorFlag2: any = true;
  applicationValue: any;
  constructor(
    public dialogService: DialogService,
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    private messageService: MessageService
  ) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    this.deletemsg = navigation?.extras?.state?.msg;
    if (data) {
      this.planForm.patchValue(JSON.parse(data));
    }
  }
  ngOnInit(): void {
    this.getApplicationVersionList();
  }
  onAddplan() {
    this.router.navigate(['/pages/addPlan']);
  }


  getApplicationVersionList(): void {
    this.selectList = [];
    this.pagesService.getApplicationVersion().subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null
          };
          data.label = element.label;
          data.value = element.value;
          this.selectList.push(data);
        });
        this.selectList = [...this.selectList];
      }
    });
  }
  gettableList(): void {
    this.applicationValue = this.planForm.value.application;
    if (this.planForm.value.application[0] == null || this.planForm.value.application[0] == '' || this.planForm.value.application[0] == undefined) {
      this.errorFlag2 = true;
      this.errorFlag = false;
    } else {
      this.tableList = [];
      this.tableList1 = [];
      const reqBody = {}
      reqBody['applications'] = [this.planForm.value.application];
      const formValues = reqBody;
      this.pagesService.getPlanList(formValues).subscribe(results => {

        const responseData = results?.success?.result;
        if (responseData && responseData?.length > 0) {
          responseData.forEach((element: any = {}) => {
            const data = {
              planId: null,
              planName: null,
              createdBy: null,
              applicationList: null,
              price: null,
              availableSubscription: null,
              appList: null,
              newAppList: [],
              lastModifiedBy: null,
              lastModifiedDate: null,
              orderNumber: null
            };
            data.planId = element.planId;
            data.planName = element.planName;
            data.createdBy = element.createdBy;
            data.applicationList = element.applicationList;
            data.price = element.price;
            data.lastModifiedBy = element.lastModifiedBy;
            data.lastModifiedDate = element.lastModifiedDate;
            data.orderNumber = element.orderNumber;
            data.availableSubscription = element.availableSubscription;
            element.applicationList.forEach((element1: any = {}) => {
              data.newAppList.push(element1.applicationName);
            });
            data.appList = data.newAppList.toString();
            this.tableList.push(data);
            this.errorFlag = false;
            this.errorFlag2 = false;
          });
          this.tableList.sort((first, second) => {
            // return 0, positive, or negative value according to difference of the year
            // If difference is positive, sort() method will swap the objects, otherwise not.
            // If users want to sort array in the decreasing order, just return second.joinedYear - first.joinedYear
            return first.orderNumber - second.orderNumber;
          });
          this.tableList = [...this.tableList];
          this.tableList1 = [...this.tableList];
        } else {
          this.errorFlag = true;
          this.errorFlag2 = false;
        }
        if (results?.error?.code == '400') {
          this.errorFlag = true;
          this.errorFlag2 = false;
        }
      });
    }
  }
  navigateEditPlan(rowData: any): void {
    let application: any
    this.selectList.forEach(element => {
      if (element.value == this.planForm.value.application) {
        application = {
          applicationId: this.applicationValue,
          applicationName: element.label
        }
      }
    });
    rowData.applicationList = application;
    const navigationExtras: NavigationExtras = {
      state: {
        data: JSON.stringify(rowData),
      },
    };
    this.router.navigate(['/pages/editPlan'], navigationExtras);
  }
  CustomFilterMethod(event: any) {
    let filtered: any[] = [];
    let items: any;
    let cc;
    let query = event;
    if (query.length > 0) {
      this.tableList1.forEach(element => {
        items = element;
        cc = items.applicationList.length;
        if (items.planName?.toLowerCase().includes(query.toLowerCase()) || items.createdBy?.toLowerCase().includes(query.toLowerCase()) || items.price?.toLowerCase().includes(query.toLowerCase())) {
          filtered.push(items);
        }
        else {
          for (let j = 0; j < cc; j++) {
            if (items.applicationList[j].applicationName?.toLowerCase().includes(query.toLowerCase())) {
              filtered.push(items);
              break;
            }
          }
        }
      });
      this.tableList = filtered;
    }
    else {
      this.tableList = this.tableList1;
    }
  }
  //function to call group filter
  customFilter(event: any) {
    if (event.filters.applicationList.length > 0) {
      this.planFilterMethod(event);
    }
  }


  viewPlan(id: any) {
    this.ref = this.dialogService.open(ViewApplicationPlanComponent, {
      header: 'Plan Details',
      data: { id },
      width: '80%',
      height: '85%',
      contentStyle: { 'overflow': 'auto' },//overflow visible for dropdown, auto for height scroll. max height 500px
      baseZIndex: 1000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg) {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
      if (msg) {
        this.gettableList();
      }
    });
  }
  // filter for column Group
  planFilterMethod(event: any) {
    let filtered: any[] = [];
    const matMode = event.filters.applicationList[0].matchMode;
    let items: any;
    let cc;
    let query = event.filters.applicationList[0].value;
    if (query.length > 0) {
      if (matMode == "startsWith") {
        this.tableList1.forEach(element => {
          items = element;
          cc = items.applicationList.length;
          for (let j = 0; j < cc; j++) {
            if (items.applicationList[j].applicationName?.toLowerCase().startsWith(query.toLowerCase())) {
              event.filteredValue.push(items);
            }
          }
        });
      }
      else if (matMode == "contains") {
        this.tableList1.forEach(element => {
          items = element;
          cc = items.applicationList.length;
          for (let j = 0; j < cc; j++) {
            if (items.applicationList[j].applicationName?.toLowerCase().includes(query.toLowerCase())) {
              event.filteredValue.push(items);
            }
          }
        });
      }
      else if (matMode == "endsWith") {
        this.tableList1.forEach(element => {
          items = element;
          cc = items.applicationList.length;
          for (let j = 0; j < cc; j++) {
            if (items.applicationList[j].applicationName?.toLowerCase().indexOf(query.toLowerCase(), items.applicationList[j].label?.length - query.length) !== -1) {
              event.filteredValue.push(items);
            }
          }
        });
      }
      else if (matMode == "equals") {
        this.tableList1.forEach(element => {
          items = element;
          cc = items.applicationList.length;
          for (let j = 0; j < cc; j++) {
            if (items.applicationList[j].applicationName?.toLowerCase() == query.toLowerCase()) {
              event.filteredValue.push(items);
            }
          }
        });
      }
      else {
        this.tableList = this.tableList1;
      }
    }
  }
  DeletePlan(rowData: any) {
    this.ref = this.dialogService.open(DeletePlanComponent, {
      data: rowData,
      header: 'Delete Plan',
      width: '30%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Plan Deleted Successfully') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.gettableList();
      }
      else if (msg == 'Failed to Delete Plan') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
    });
  }

  onReorderPlan() {
    this.ref = this.dialogService.open(ReorderPlanComponent, {
      data: [this.planForm.value.application],
      header: 'Re-order Plan',
      width: '66%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Plan Re-ordered Successfully') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.gettableList();
      }
      else if (msg == 'Failed to Re-order Plans') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
    });
  }
}
