import { PagesService } from 'app/pages/pages.service';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { LoginService } from 'app/core/login/login.service';
import { AccountService } from 'app/core/auth/account.service';
import { Account } from 'app/core/user/account.model';
import { MainComponent } from 'app/layouts/main/main.component';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from "@angular/router";
import { KEY_CLOAK_REALM_NAME, REBAC_UM_ENTITLEMENT_FLAG } from 'app/app.constants';
@Component({
  selector: 'jhi-main',
  templateUrl: './home.component.html',
  styleUrls: ['home.scss'],
  providers: [MessageService]
})
export class HomeComponent implements OnInit {
  account: Account | null = null;
  hideFlag: boolean;
  redirect: any;
  uname: any;
  userID: any;
  userName: any;
  lastLoggedDate: any;
  statusArrList: any[] = [];
  tableList: any[] = [];
  tableList1: any[] = [];
  selectedIndex: any;
  letters = '0123456789ABCDEF';
  colors: any = ['p-avatar-color1', 'p-avatar-color2', 'p-avatar-color3', 'p-avatar-color4', 'p-avatar-color5', 'p-avatar-color6', 'p-avatar-color7', 'p-avatar-color8'];
  appCount: any;
  messageFlag = false;
  messageFlag1 = false;
  infoMessage = "Please wait, deployment usually takes 10 to 15 minutes.";
  cntPending = 0;
  homeFlag = false;
  homeCiamAdminFlag = false;
  homeSuperAdminFlag = false;
  homeTenantAdminFlag = false;
  homeAppAdminFlag = false;
  homeCiamAuthFlag = false;
  homeWfApproverFlag = false;
  homePlanAdminFlag = false;
  tenantList: any[] = [];
  rebac: string;
  multiTenancyFlag: string;
  realm: string;
  rebacFlag: string;
  constructor(
    private router: Router,
    private accountService: AccountService,
    private loginService: LoginService,
    public pagesService: PagesService,
    private main: MainComponent,
    private route: ActivatedRoute,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.rebacFlag = REBAC_UM_ENTITLEMENT_FLAG + "";
    this.multiTenancyFlag = environment.MULTI_TENANCY_ENABLE + "";
    if (this.multiTenancyFlag == "true") {
      this.realm = location?.hostname.slice(0, location?.hostname.indexOf('.'));
      this.pagesService.realm = this.realm;
      environment.KEY_CLOAK_REALM_NAME = this.realm;
      MainComponent.changedRealm = this.realm;
    }
    else {
      this.realm = KEY_CLOAK_REALM_NAME;
      this.pagesService.realm = this.realm;
      MainComponent.changedRealm = this.realm;
    }
    console.log("relam " + this.realm);
    if (this.rebacFlag == "false") {
      this.getTenantSaasStatus();
    }
    this.hideFlag = true;
    this.redirect = environment.IMAGE_PATH;
    if (localStorage?.getItem("navbarFlag") == null) {
      this.main.items = [];
      const migrationRoleFlag = environment.CIAM_ROLE_MIGRATION_FLAG + "";
      if (migrationRoleFlag == "true") {
        this.main.getCiamApplicationDetails();
      }
      else {
        this.main.getRoles();
      }

    }
    if (localStorage?.getItem("navbarFlag") == 'Yes') {
      this.pagesService.tenantId = localStorage.getItem("tenant");
      this.pagesService.tenantName = localStorage.getItem("tenantName");
      this.main.items = [];
      const migrationRoleFlag = environment.CIAM_ROLE_MIGRATION_FLAG + "";
      if (migrationRoleFlag == "true") {
        this.main.getCiamApplicationDetails();
      }
      else {
        this.main.getRoles();
      }
    }


    if (this.pagesService.tenantId == null || this.pagesService.tenantId == undefined) {

      this.getTenantList();
    }

    this.accountService.identity().subscribe(account => (this.account = account));
    if (this.pagesService.pageRebacFlag == "false") {
      this.getValue();
    }
  }

  isAuthenticated(): boolean {
    return this.accountService.isAuthenticated();
  }

  login(): void {
    this.loginService.login();
  }

  getValue() {
    this.uname = JSON.parse(localStorage.getItem('profile'));
    let uid = this.uname.username;
    let reqBody = {};
    reqBody['tenantId'] = this.pagesService.tenantId;
    reqBody['username'] = uid;
    const formValues = reqBody;
    this.pagesService.getUserDetails(formValues).subscribe((results: any) => {
      this.userID = results?.success?.result?.userId;
      this.pagesService.userId = this.userID;
      this.pagesService.getLastLoggedByUserId(this.userID).subscribe((lastLoginResults: any) => {
        this.lastLoggedDate = lastLoginResults?.success?.result;
      });
    });
  }

  getLastLoggedInCentral() {
    this.uname = JSON.parse(localStorage?.getItem('profile'));
    let uid = this.uname.username;
    let tenantid = localStorage?.getItem("masterTenantId");
    this.pagesService.getUserDetailsByUserNameCentral(tenantid, uid).subscribe((results: any) => {
      this.userID = results?.success?.result?.id;
      this.pagesService.getLastLoggedByUserIdMaster(tenantid, this.userID).subscribe((lastLoginResults: any) => {
        this.lastLoggedDate = lastLoginResults?.success?.result;
      });
    });
  }

  getTenantSaasStatus() {
    this.tableList = [];
    this.tableList1 = [];
    this.statusArrList = [];
    this.uname = JSON.parse(localStorage.getItem('profile'));
    let reqBody = {};
    reqBody['user_email'] = this.uname?.email;
    const formValues = reqBody;
    this.appCount = 0;
    this.cntPending = 0;
    this.pagesService.getTenantSaasStatus(formValues).subscribe((results: any) => {
      let arrList = results?.success?.result;
      if (arrList && arrList.length > 0) {
        arrList.forEach(element => {
          let cnt = 0;
          let pendingCnt = 0;
          element.statusList.forEach(statuss => {
            if (statuss.status.includes('success')) {
              cnt = cnt + 1;
            }
            else {
              pendingCnt = pendingCnt + 1;
            }
          });
          if (cnt == 5) {
            this.statusArrList.push(element);
            this.appCount = this.appCount + 1;
          }
          else if (cnt < 5) {
            this.tableList.push(element);
            this.tableList1.push(element);
          }
          if (pendingCnt > 0) {
            this.cntPending = this.cntPending + 1;
          }
        });
        this.getAppCount();
      }
    });
  }
  getAppCount() {
    if (this.appCount < 9) {
      this.appCount = "0" + this.appCount;
    }
    if (this.cntPending > 0) {
      this.messageFlag1 = true;
    }
    else {
      this.messageFlag1 = false;
    }
    if (this.appCount > 0 || this.cntPending > 0) {
      this.pagesService.ciamHomeFlag = true;
    }
  }

  highlight(index: any) {
    this.selectedIndex = index;
  }

  getInitials(nameString) {
    const fullName = nameString.split(' ');
    const initials = fullName.shift().charAt(0);
    return initials.toUpperCase();
  }

  getInitialName(nameString) {
    const fullName = nameString.split(' ');
    if (fullName.length == 3) {
      const initials = fullName.shift().charAt(0) + fullName[0].charAt(0) + fullName.pop().charAt(0);
      return initials.toUpperCase();
    }
    else if (fullName.length == 2) {
      const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
      return initials.toUpperCase();
    }
    else if (fullName.length == 1) {
      const initials = fullName.shift().charAt(0);
      return initials.toUpperCase();
    }
  }

  getColor(i) {
    let count = 0
    if (i < this.colors.length) {
      return this.colors[i]
    }

    if (i >= this.colors.length) {
      for (let j = 0; j <= i; j++) {
        if (j % this.colors.length == 0 && j != 0) {
          count++;
        }
      }
      return this.colors[i - (this.colors.length * count)]
    }

  }


  //search functionality
  AppFilterMethod(event: any) {
    let filtered: any[] = [];
    let items: any;
    let cc;
    let query = event;
    if (query.length > 0) {
      this.tableList1.forEach(element => {
        items = element;
        if (items.tenantName?.toLowerCase().includes(query.toLowerCase()) || items.applicationName?.toLowerCase().includes(query.toLowerCase())) {
          filtered.push(items);
        }
      });
      this.tableList = filtered;
    }
    else {
      this.tableList = this.tableList1;
    }
  }

  RetryInfraProvisioning(rowData: any) {
    const reqBody = {};
    reqBody['tenantId'] = rowData.tenantId;
    reqBody['applicationId'] = rowData.appId;
    const formValues = reqBody;
    this.messageFlag = true;
    this.pagesService.retryCallingInfraProvisioning(formValues).subscribe({
      next: (results: any) => {
        this.getTenantSaasStatus();
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: "Onboarding of " + rowData.applicationName + " reinitiated." });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      },
      error: error => {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: "Failed to retry." });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
    });
  }

  routeToApp(rootUrl: any) {
    window.open(rootUrl, "_blank");
  }

  clearmsg() {
    this.messageFlag1 = false;
  }

  getHomeCardRoles() {
    this.homeCiamAdminFlag = this.pagesService.pageCiamAdminFlag;
    this.homeAppAdminFlag = this.pagesService.pageAppAdminFlag;
    this.homeCiamAuthFlag = this.pagesService.pageCiamAuthFlag;
    this.homeSuperAdminFlag = this.pagesService.pageSuperAdminFlag;
    this.homeTenantAdminFlag = this.pagesService.pageTenantAdminFlag;
    this.homeWfApproverFlag = this.pagesService.pageWfApproverFlag;
    this.homePlanAdminFlag = this.pagesService.pagePlanAdminFlag;
  }

  getTenantList() {
    this.userName = localStorage.getItem("username");
    const uName = this.userName;
    this.pagesService.getTenantListByUsername(uName).subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          if (element.label == this.realm) {
            this.pagesService.tenantId = element.value;

          }
          this.tenantList.push(data);
        });
        this.tenantList = [...this.tenantList];
        const migrationRoleFlag = environment.CIAM_ROLE_MIGRATION_FLAG + "";
        if (migrationRoleFlag == "true") {
          this.main.getCiamApplicationDetails();
        }
        else {
          this.main.getRoles();
        }

      }
    });
  }

  // routeToPage(url: any, count: any) {
  //   this.router.navigate(['/pages/' + url]);
  //   this.main.items[count].expanded = true;
  //   this.main.items[0].expanded = false;
  // }
  routeToPage(url: any, count: any) {
    this.rebac = this.pagesService.pageRebacFlag;
    if (this.rebac == 'true' && url == 'attribute&group') {
      this.router.navigate(['/pages/' + 'manageentitlements']);
      this.main.items[count].expanded = true;
      this.main.items[0].expanded = false;
    } else if (url == 'attribute&group') {
      this.router.navigate(['/pages/' + 'attribute&group']);
      this.main.items[count].expanded = true;
      this.main.items[0].expanded = false;
    } else {
      this.router.navigate(['/pages/' + url]);
      this.main.items[count].expanded = true;
      this.main.items[0].expanded = false;
    }
  }
}
