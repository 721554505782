import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PagesService } from '../pages.service';
import { MenuItem, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AssignUsersComponent } from '../AssignUsers/AssignUsers.component';
import { DeleteGroupComponent } from '../DeleteGroup/DeleteGroup.component';

@Component({
  selector: 'jhi-listgroups',
  templateUrl: './ListGroups.component.html',
  styleUrls: ['./ListGroups.component.scss'],
  providers: [MessageService, DialogService],
})
export class ListGroupsComponent implements OnInit {
  ref!: DynamicDialogRef;
  ListGroupsForm = this.fb.group({
    textField: [null, []],
  });
  first = 0;
  rows = 10;
  messageFlag = true;
  hoverIndex: any;
  isVisible = false;
  activeItem: any;
  pageloadvalues: any;
  ccprimebreadcrumbList = [{ label: `User Management` }, { label: `User Groups` }];
  homeiconccprimebreadcrumb = { icon: 'pi pi-home', routerLink: ['/'] };
  htmlSrc: any;
  groupcount: any;
  errorFlag: boolean = true;
  searchList: any[] = [];
  tableList: any[] = [];
  tableList1: any[] = [];
  tablecols: any[] = [
    { field: 'name', header: 'Name' },
    { field: 'description', header: 'Description' },
    { field: 'users', header: 'Users' },
    { field: 'roles', header: 'Roles' },
    { field: 'status', header: 'Status' },
  ];
  table: any[] = [];
  Hoveritems!: MenuItem[];
  selectedIndex: any;
  deletemsg: any;

  constructor(
    public dialogService: DialogService,
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    private messageService: MessageService
  ) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    this.deletemsg = navigation?.extras?.state?.msg;
    if (this.deletemsg) {
      this.messageFlag = true;

    }
    if (data) {
      this.ListGroupsForm.patchValue(JSON.parse(data));
    }
  }

  ngOnInit(): void {
    this.Hoveritems = [
      {
        label: 'View & Edit',

        command: e => {
          this.navigateGroupName(this.activeItem);
        },
      },

      {
        label: 'Delete',
        command: () => {
          this.DeleteGroup();
        },
      },

      {
        label: 'Assign User',
        command: () => {
          this.resetinviteUserccprimesplitbutton();
          this.show();
        },
      },
    ];
    this.gettableList();
  }

  toggleDisplay() {
    this.isVisible = !this.isVisible;
  }

  // navigate to view group - view&edit button
  navigateGroupName(rowData: any): void {
    const navigationExtras: NavigationExtras = {
      state: {
        data: JSON.stringify(rowData),
      },
    };
    this.router.navigate(['/pages/viewgroup'], navigationExtras);
  }

  highlight(index: any) {
    this.selectedIndex = index;
  }

  //list of groups API
  gettableList(): void {
    this.pagesService.gettableDataListGroups().subscribe(results => {
      const responseData = results.success.result.records;
      this.groupcount = results.success.result.recordsCount;
      if (responseData && responseData.length > 0) {
        this.tableList = responseData;
        this.tableList1 = responseData;
        this.errorFlag = false;
      } else {
        this.errorFlag = true;
      }
      if (this.deletemsg) {

        this.messageService.add({ key: 'myKey1', severity: 'success', detail: this.deletemsg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
    });
  }

  // group filter functionality -search
  GroupFilterMethod(event: any) {
    let filtered: any[] = [];
    let items: any;
    let cc;
    let query = event;
    if (query.length > 0) {
      this.tableList1.forEach(element => {
        items = element;
        if (items.displayName?.toLowerCase().includes(query.toLowerCase()) || items.description?.toLowerCase().includes(query.toLowerCase())) {
          filtered.push(items);
        }
      });
      this.tableList = filtered;
    }
    else {
      this.tableList = this.tableList1;
    }
  }


  onAddGroup($event: UntypedFormGroup): void {
    this.router.navigate(['/pages/addusergroup']);
  }

  resetinviteUserccprimesplitbutton(): void {
    this.ListGroupsForm.reset();
  }

  //assign user -dialog box
  show() {
    this.ref = this.dialogService.open(AssignUsersComponent, {
      data: { id: this.activeItem, dialogFlag: 'group' },
      header: 'Assign Users',
      width: '50%',
      contentStyle: { 'max-height': '160px', overflow: 'visible' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'error' || msg == 'Failed to assign user') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Failed to assign users.' });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
      else if (msg) {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.gettableList();
      }
    });
  }

  //delete group -dialog box
  DeleteGroup() {
    this.ref = this.dialogService.open(DeleteGroupComponent, {
      data: { id: this.activeItem.id, name: this.activeItem.displayName, dialogFlag: 'group' },
      header: 'Delete Group',
      width: '30%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Group Deleted Successfully') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
        this.gettableList();
      }
      else if (msg == 'Failed to Delete Group') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);
      }
    });
  }
  mouseLeave() {
    this.hoverIndex = null;
    this.selectedIndex = null;
  }

}
