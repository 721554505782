import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PagesService } from '../pages.service';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
@Component({
  selector: 'jhi-viewworkflow',
  templateUrl: './ViewWorkflowConfigurationDetails.component.html',
  styleUrls: ['./ViewWorkflowConfigurationDetails.component.scss'],
})
export class ViewWorkflowConfigurationDetailsComponent implements OnInit {
  ViewWorkflowConfigurationDetailsForm = this.fb.group({});
  activeId: any[] = [];
  pageloadvalues: any;
  transactionId: any;
  firstName: any;
  lastName: any;
  username: any;
  attributeList: any[] = [];
  configData: any;
  ciamActionLabel: any;
  noOfApproversLabel: any;
  autoRejectDaysModel: any;
  approvalType: any;
  approvalStage: any[] = [];

  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    private messageService: MessageService,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.ViewWorkflowConfigurationDetailsForm.patchValue(JSON.parse(data));
    }
  }

  ngOnInit(): void {
    this.configData = this.config?.data?.activeData;
    this.ciamActionLabel = this.config?.data?.activeData?.ciamAction;
    this.noOfApproversLabel = this.config?.data?.activeData?.noOfApprovers;
    this.autoRejectDaysModel = this.config?.data?.activeData?.autoRejectDays;
    this.approvalType = this.config?.data?.activeData?.approvalType;
    this.getApprovalStage();
  }

  onCcprimebutton($event: UntypedFormGroup): void {
    this.ref.close();
  }

  getApprovalStage(): void {
    let reqBody = {};
    reqBody['workflowAppConfigId'] = this.config?.data?.activeData?.wfId;
    reqBody['tenantId'] = this.pagesService.tenantId;
    const formValues = reqBody;
    this.pagesService.getWorkflowApprovalStage(formValues).subscribe(results => {
      const responseData = results?.success?.result;
      let count = 0;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            approvalSeq: null,
            approvalRole: null,
          };
          data.approvalSeq = element.approvalSeq;
          data.approvalRole = element.approvalRole;
          this.approvalStage.push(data);
        });
        this.approvalStage = [...this.approvalStage];
      }
    });
  }

}
