import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PagesService } from '../pages.service';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
@Component({
  selector: 'jhi-reorderplan',
  templateUrl: './ReorderPlan.component.html',
  styleUrls: ['./ReorderPlan.component.scss'],
})
export class ReorderPlanComponent implements OnInit {
  ReorderPlanForm = this.fb.group({
  });
  pageloadvalues: any;
  groupId: any;
  activeItem: any;
  dName: any;
  Ucount: any;
  Rcount: any;
  planName: any;
  planId: any;
  tableList: any[] = [];
  tableList1: any[] = [];
  applications: any;
  changedPlanOrders: any[] = [];
  reorder: boolean = true;

  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    private messageService: MessageService,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.ReorderPlanForm.patchValue(JSON.parse(data));
    }
  }

  ngOnInit(): void {
    this.applications = this.config?.data;
    this.gettableList();
  }

  onClickNo($event: UntypedFormGroup): void {
    this.ref.close();
  }

  /**
   * delete  usergroup 
   */


  checkChange() {
    this.changedPlanOrders = [];
    for (let i = 0; i <= this.tableList.length; i++) {
      if (this.tableList1[i]?.planId != this.tableList[i]?.planId) {
        if (this.tableList[i]?.orderNumber != this.tableList1[i].orderNumber) {
          this.changedPlanOrders.push({ planId: this.tableList[i].planId, orderNumber: this.tableList1[i].orderNumber });
        }
      }
    }
    this.changedPlanOrders = [...this.changedPlanOrders];
    if (this.changedPlanOrders.length == 0) {
      this.reorder = true;
    }
    else {
      this.reorder = false;
    }
  }
  reOrderPlan() {
    this.changedPlanOrders = [];
    for (let i = 0; i <= this.tableList.length; i++) {
      if (this.tableList1[i]?.planId != this.tableList[i]?.planId) {
        if (this.tableList[i]?.orderNumber != this.tableList1[i].orderNumber) {
          this.changedPlanOrders.push({ planId: this.tableList[i].planId, orderNumber: this.tableList1[i].orderNumber });
        }
      }
    }
    this.changedPlanOrders = [...this.changedPlanOrders];
    const reqBody = {};
    reqBody["changedPlanOrders"] = this.changedPlanOrders;
    this.pagesService.reOrderPlan(reqBody).subscribe(
      {
        next: results => {
          if (results.success.code == 200) {
            this.ref.close("Plan Re-ordered Successfully");
          }
        },
        error: () => {
          this.ref.close("Failed to Re-order Plans");
        }
      });
  }

  gettableList(): void {
    this.tableList = [];
    this.tableList1 = [];
    const reqBody = {}
    reqBody['applications'] = this.applications;
    const formValues = reqBody;
    this.pagesService.getPlanList(formValues).subscribe(results => {

      const responseData = results?.success?.result;
      if (responseData && responseData?.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            planId: null,
            planName: null,
            createdBy: null,
            applicationList: null,
            price: null,
            availableSubscription: null,
            appList: null,
            newAppList: [],
            lastModifiedBy: null,
            lastModifiedDate: null,
            orderNumber: null
          };
          data.planId = element.planId;
          data.planName = element.planName;
          data.createdBy = element.createdBy;
          data.applicationList = element.applicationList;
          data.price = element.price;
          data.lastModifiedBy = element.lastModifiedBy;
          data.lastModifiedDate = element.lastModifiedDate;
          data.orderNumber = element.orderNumber;
          data.availableSubscription = element.availableSubscription;
          element.applicationList.forEach((element1: any = {}) => {
            data.newAppList.push(element1.applicationName);
          });
          data.appList = data.newAppList.toString();
          this.tableList.push(data);
        });
        this.tableList.sort((first, second) => {
          // return 0, positive, or negative value according to difference of the year
          // If difference is positive, sort() method will swap the objects, otherwise not.
          // If users want to sort array in the decreasing order, just return second.joinedYear - first.joinedYear
          return first.orderNumber - second.orderNumber;
        });
        this.tableList = [...this.tableList];
        this.tableList1 = [...this.tableList];
      }
    });
  }
}