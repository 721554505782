import { Component, OnInit, RendererFactory2, Renderer2 } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router, ActivatedRouteSnapshot, NavigationEnd, NavigationError } from "@angular/router";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { KeycloakService } from 'keycloak-angular';
import { AccountService } from "app/core/auth/account.service";
import { LoadingIndicatorService } from "app/loading-indicator.service";
import { NgxSpinnerService } from "ngx-spinner";
import { MenuItem } from "primeng/api";
import * as SockJS from 'sockjs-client';
import { Stomp } from "@stomp/stompjs";
import { PagesService } from "app/pages/pages.service";
import { environment } from "src/environments/environment";
import { CIAM_UI_SERVICE_API_URL, CONTEXT_LIST, EVENT_TYPE_LIST, ITEM_TYPE_LIST, KEY_CLOAK_REALM_NAME, PARENT_ID, REBAC_UM_ENTITLEMENT_FLAG } from "app/app.constants";
import { DatePipe } from "@angular/common";
import { USER_MANAGEMENT_COMPONENT_ID } from "app/constants";
import { LoggingEventService } from "app/logging-event.service";
import { EventName, ContextName } from "app/shared/constants/logging-event.constants";
import { IEventData, IEventContext } from "app/shared/interface/event.interface";
import { MainComponentService } from "./main.component.service";

@Component({
	selector: "ciam-root",
	templateUrl: "./main.component.html",
	styleUrls: ["./main.component.scss"],
})
export class MainComponent implements OnInit {
	private renderer: Renderer2;
	loading = false;
	items!: MenuItem[];
	expanditems: any[] = [];
	roles: any;
	collapseitems: any[] = [];
	columnstoggle: boolean = false;
	activeTab: any;
	userToggle: boolean = false;
	flag = 1;
	flag1 = false;
	stompClient: any
	newUserEmail: any
	static stompCl: any;
	superAdminFlag = false;
	ciamAdminFlag = false;
	tenantAdminFlag = false;
	appAdminFlag = false;
	ciamAuthFlag = false;
	planAdminFlag = false;
	wfApproverFlag = false;
	userName: string;
	tenantList: any[] = [];
	redirect: any;
	uname: any;
	userID: any;
	importFlag: boolean = false;
	static selectedTenant: any;
	appId: string;
	applicationId: any;
	componentId: any;
	loaderFlag = 0;
	rebacFlag: string;
	roleMigrationFlag: string;
	appName: any;
	multiTenancyFlag: string;
	realm: string;
	static changedRealm: any;
	readOnlyFlag: boolean;
	userManagement: boolean;
	authorizationAccess: boolean;
	userObj: any;
	constructor(
		private accountService: AccountService,
		private titleService: Title,
		private router: Router,
		public pagesService: PagesService,
		private translateService: TranslateService,
		rootRenderer: RendererFactory2,
		private loadingIndicatorService: LoadingIndicatorService,
		private spinner: NgxSpinnerService,
		private keycloakservice: KeycloakService,
		private loggingService: LoggingEventService,
		private mainComponentService: MainComponentService
	) {

		const load = document.getElementById('app-loading');
		if (load) {
			document.getElementById('app-loading')!.style.display = 'none';
		}

		this.loadingIndicatorService.onLoadingChanged.subscribe(
			(isLoading: boolean) => {
				this.loading = isLoading;
				const reqData = JSON.parse(localStorage.getItem("Request url"));
				if (reqData.url.includes("validateNewPasswordPolicy")) {
					this.loaderFlag = 1;
				}
				else if (reqData.url.includes("get-all-subscription") && reqData.body.serchString.length > 0) {
					this.loaderFlag = 2;
				}
				else if (reqData.url.includes("get-all-users-pagination") && reqData.body.serchString.length > 0) {
					this.loaderFlag = 3;
				}
				else if (reqData.url.includes("get-all-resourceEndpoints-pagination") && reqData.body.serchString.length > 0) {
					this.loaderFlag = 4;
				}
				else if (reqData.url.includes("umManageEntitlements/getEntitlementList") && reqData.body?.SEARCH_TEXT?.length > 0) {
					this.loaderFlag = 5;
				}
				else if (reqData.url.includes("umManageApproval/getEntitlementOwnerTransferRequests") && reqData.body?.SEARCH_TEXT?.length > 0) {
					this.loaderFlag = 6;
				}
				else if (reqData.url.includes("umManageApproval/getApprovalDetails") && reqData.body?.SEARCH_TEXT?.length > 0) {
					this.loaderFlag = 7;
				}
				else if (reqData.url.includes("get-application-component-pagination") && reqData.body?.searchString?.length > 0) {
					this.loaderFlag = 8;
				}
				else if (reqData.url.includes("get-all-accountsListing") && reqData.body?.serchString?.length > 0) {
					this.loaderFlag = 9;
				}
				else if (reqData.url.includes("check-status-bulk-action") && reqData.body?.serchString?.length > 0) {
					this.loaderFlag = 10;
				}
				else if (reqData.url.includes("umManageEntitlements/getAllEntitlementList") && reqData.body?.SEARCH_TEXT?.length > 0) {
					this.loaderFlag = 11;
				}
				else if (reqData.url.includes("umManageRole/getAllRoleList") && reqData.body?.SEARCH_TEXT?.length > 0) {
					this.loaderFlag = 12;
				}
				else if (reqData.url.includes("/hostApplicationAttribute/get-host-attributes-pagination") && reqData.body?.searchString?.length > 0) {
					this.loaderFlag = 13;
				}
				else if (reqData.url.includes("/user/getuserlastlogin")) {
					this.loaderFlag = 14;
					localStorage.setItem("Request url", JSON.stringify({ "body": null, "url": "url" }));
				}
				// else if (reqData.url.includes("searchLDAPUsers")) {
				// 	this.loaderFlag = 15;
				// }
				else if (this.loading) {
					this.spinner.show();
				}
				else {
					this.spinner.hide();
				}
			}
		);
		this.renderer = rootRenderer.createRenderer(
			document.querySelector("html"),
			null
		);
	}

	ngOnInit(): void {
		this.multiTenancyFlag = environment.MULTI_TENANCY_ENABLE + "";
		if (this.multiTenancyFlag == "true") {
			this.realm = location?.hostname.slice(0, location?.hostname.indexOf('.'));
			this.pagesService.realm = this.realm;
			environment.KEY_CLOAK_REALM_NAME = this.realm;
			MainComponent.changedRealm = this.realm;
		}
		else {
			this.realm = KEY_CLOAK_REALM_NAME;
			this.pagesService.realm = this.realm;
			MainComponent.changedRealm = this.realm;
		}
		console.log("relam " + this.realm);
		this.roleMigrationFlag = environment.CIAM_ROLE_MIGRATION_FLAG + "";
		this.rebacFlag = REBAC_UM_ENTITLEMENT_FLAG + "";
		this.pagesService.pageRebacFlag = this.rebacFlag;
		this.redirect = environment.IMAGE_PATH;
		if (localStorage?.getItem("navbarFlag") == null) {
			setTimeout(() => {
				this.getTenantList();
			}, 2000);

		}
		if (localStorage?.getItem("navbarFlag") == 'Yes') {
			this.pagesService.tenantId = localStorage.getItem("tenant");
			this.pagesService.tenantName = localStorage.getItem("tenantName");
		}
		if (this.pagesService.tenantId == null || this.pagesService.tenantId == undefined) {
			setTimeout(() => {
				this.getTenantList();
			}, 2000);
		}
		this.connect();
		document.getElementById("sidebar")!.classList.add("expanded");
		this.accountService.identity().subscribe();

		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.updateTitle();
			}
			if (event instanceof NavigationError && event.error.status === 404) {
				this.router.navigate(["/404"]);
			}
		});

		this.translateService.onLangChange.subscribe(
			(langChangeEvent: LangChangeEvent) => {
				this.updateTitle();

				this.renderer.setAttribute(
					document.querySelector("html"),
					"lang",
					langChangeEvent.lang
				);
			}
		);

		//this.getEventData(true);
		//this.logEventData();

		if (this.rebacFlag == "true" && localStorage?.getItem("navbarFlag") == null) {
			document.getElementById("sidebar")!.classList.add("sidebarheight");
		}
	}

	getCiamApplicationDetails() {
		this.pagesService.getCiamApplicationDetails().subscribe((results: any) => {
			if (results.success.result == null) {
				console.log("Ciam appId Not found")
			}
			this.appName = results.success.result.applicationName;
			this.applicationId = results.success.result.applicationId;
			this.pagesService.applicationId = this.applicationId;
			this.componentId = results.success.result.componentId;
			localStorage.setItem("applicationId", this.applicationId);
			localStorage.setItem("componentId", this.componentId);
			this.getRoles();
		});
	}
	getApplicationRoles() {
		this.roles = [];
		this.items = [];
		this.uname = JSON.parse(localStorage.getItem('profile'));
		let uid = this.uname.username;
		let reqBody = {};
		reqBody['tenantId'] = this.pagesService.tenantId;
		reqBody['username'] = uid;
		const formValues = reqBody;
		this.pagesService.getUserDetails(formValues).subscribe((results: any) => {
			this.userID = results.success.result.userId;
			this.pagesService.userId = this.userID;
			let application = results.success.result.application;
			application.forEach((element: any = {}) => {
				if (element.id == this.applicationId) {
					let appRoles = element.applicationLevelRoles;
					if (appRoles && appRoles.length > 0) {
						appRoles.forEach((element1: any = {}) => {
							const data = {
								displayName: null,
								value: null,
							};
							data.displayName = element1.roleName;
							data.value = element1.id;
							this.roles.push(data);
						});
						this.roles = [...this.roles];
					}

				}

			});
			this.setDefaultIndex();
		});


	}

	getRoles() {
		this.roles = [];
		this.items = [];
		this.uname = JSON.parse(localStorage.getItem('profile'));
		let uid = this.uname.username;
		let reqBody = {};
		reqBody['tenantId'] = this.pagesService.tenantId;
		reqBody['username'] = uid;
		const formValues = reqBody;
		this.pagesService.getUserDetails(formValues).subscribe((results: any) => {
			this.userID = results.success.result.userId;
			this.pagesService.userId = this.userID;
			if (this.roleMigrationFlag == "false") { //tenant roles
				let tenantRoles = results.success.result.tenantLevelRoles;
				if (tenantRoles && tenantRoles.length > 0) {
					tenantRoles.forEach((element1: any = {}) => {
						const data = {
							displayName: null,
							value: null,
						};
						data.displayName = element1.roleName;
						data.value = element1.id;
						this.roles.push(data);
					});
					this.roles = [...this.roles];
				}
			}
			else if (this.roleMigrationFlag == "true") { //app based roles
				let application = results.success.result.application;
				application.forEach((element: any = {}) => {
					if (element.id == this.applicationId) {
						let appRoles = element.applicationLevelRoles;
						if (appRoles && appRoles.length > 0) {
							appRoles.forEach((element1: any = {}) => {
								const data = {
									displayName: null,
									value: null,
								};
								data.displayName = element1.roleName;
								data.value = element1.id;
								this.roles.push(data);

							});
							this.roles = [...this.roles];
						}
					}
				});

			}


			this.setDefaultIndex();
			this.logEventData();
		});

	}

	importUser() {
		this.importFlag = this.pagesService.pageImportFlag;
		setTimeout(() => {
			this.pagesService.pageImportFlag = false;
			this.importFlag = false;
		}, 3000);
	}

	setDefaultIndex() {

		this.superAdminFlag = false;
		this.ciamAdminFlag = false;
		this.tenantAdminFlag = false;
		this.appAdminFlag = false;
		this.ciamAuthFlag = false;
		this.wfApproverFlag = false;
		this.planAdminFlag = false;
		this.pagesService.pageSuperAdminFlag = false;
		this.pagesService.pageCiamAdminFlag = false;
		this.pagesService.pageTenantAdminFlag = false;
		this.pagesService.pageAppAdminFlag = false;
		this.pagesService.pageCiamAuthFlag = false;
		this.pagesService.pageWfApproverFlag = false;
		this.pagesService.pagePlanAdminFlag = false;
		this.pagesService.readonlyFlag = false;
		this.items = [
			{
				label: "CIAM Home",
				icon: "icon-home",
				routerLink: ["/"],
				routerLinkActiveOptions: true,
			},
			{
				label: "User Management",
				escape: false,
				icon: "c-icons user-icon",
				items: [
					{
						label: "Users",
						routerLink: ["/pages/userlisting"],
					},
					{
						label: "User Groups",
						routerLink: ["/pages/listgroups"],
					},
					{
						label: "Roles",
						routerLink: ["/pages/roles"],
					}
				],
			},
			{
				label: "Authorization",
				icon: "icon-lock-1",
				items: [
					{
						label: "Application Attributes",
						items: [
							{
								label: "Attribute & Group",
								routerLink: ["/pages/attribute&group"],

							},
							{
								label: "User Mapping",
								routerLink: ["/pages/usermapping"],
							},
							{
								label: "User Group Mapping",
								routerLink: ["/pages/usergroupmapping"],
							},
						],
					},
					{
						label: "Application Roles",
						items: [
							{
								label: "User Mapping",
								routerLink: ["/pages/applicationroleusermapping"],

							},
							{
								label: "User Group Mapping",
								routerLink: ["/pages/applicationroleusergroupmapping"],

							},
						],
					},
					{
						label: "Manage Entitlements",
						routerLink: ["/pages/manageentitlements"],
					},
					{
						label: "Manage Roles",
						routerLink: ["/pages/manageroles"],
					},
					// {
					// 	label: "Policy",
					// 	disabled: true,
					// },
					// {
					// 	label: "Permission",
					// 	disabled: true,
					// },
				],
			},
			{
				label: "Applications",
				icon: "icon-display",
				items: [
					{
						label: "Application Onboarding",
						routerLink: ["/pages/applicationonboarding"],
					},
					// {
					// 	label: "Application Store",
					// 	disabled: true,
					// },
				],
			},
			{
				label: "Tenant Management",
				icon: "icon-card",
				items: [
					{
						label: "Tenants",
						routerLink: ["/pages/tenantlisting"],
					},
					{
						label: "Tenant Settings",
						routerLink: ["/pages/tenantsettinglisting"],
					},
					{
						label: "Workflow Configuration",
						routerLink: ["/pages/WorkflowConfigurationListing"],
					},
					{
						label: "Authentication",
						routerLink: ["/pages/authentication"],
					},
				],
			},
			{
				label: "Plans & Subscription",
				icon: "icon-subscriptions",
				items: [
					{
						label: "Application Plans",
						routerLink: ["/pages/planListing"],
						// disabled: true,
					},
					{
						label: "Manage Subscriptions",
						routerLink: ["/pages/manageSubscription"],
						// disabled: true,
					},

				],
			},
			{
				label: "Account Management",
				icon: "icon-fileSettings",
				routerLink: ["/pages/accountManagement"],
			},
			// {
			// 	label: "Subscriptions",
			// 	icon: "c-icons sub-icon",
			// 	disabled: true,
			// },
			// {
			// 	label: "Security",
			// 	icon: "c-icons security-icon",
			// 	items: [
			// 		{
			// 			label: " API Access Token",
			// 			routerLink: ["/pages/ApiAccessToken"],
			// 			disabled: true,
			// 		},
			// 	],
			// 	//disabled: true,
			// },
			// {
			// 	label: "Reports",
			// 	icon: "c-icons report-icon",
			// 	disabled: true,
			// items: [
			// 	{
			// 	   label: "Access Reports",
			// 		//disabled: true,
			// 	},
			// 		],
			// },
			{
				label: "My Account",
				icon: "icon-userSettings",
				items: [
					{
						label: "Settings",
						routerLink: ["/pages/myaccount"],
						//disabled: true,
					},

					{
						label: "My Approvals",
						routerLink: ["/pages/approvals"],
						//disabled: true,
					},
					{
						label: "My Requests",
						routerLink: ["/pages/myrequestslisting"],
						//disabled: true,
					},
				],
				//disabled: true,
			},
		];

		this.setMenuExpanded();


		// 	//New
		this.roles.forEach(element => {
			if (element.displayName == 'ROLE_CIAM_SUPER_ADMIN') {
				this.superAdminFlag = true;
				this.pagesService.pageSuperAdminFlag = true;
			}
			if (element.displayName == 'ROLE_CIAM_ADMIN') {
				this.ciamAdminFlag = true;
				this.pagesService.pageCiamAdminFlag = true;
			}
			if (element.displayName == 'ROLE_TENANT_ADMIN') {
				this.tenantAdminFlag = true;
				this.pagesService.pageTenantAdminFlag = true;
			}
			if (element.displayName == 'ROLE_AUTH' || element.displayName == 'ROLE_CIAM_AUTH') {
				this.ciamAuthFlag = true;
				this.pagesService.pageCiamAuthFlag = true;
			}
			if (element.displayName == 'ROLE_CIAM_APP_ADMIN') {
				this.appAdminFlag = true;
				this.pagesService.pageAppAdminFlag = true;
			}
			if (element.displayName == 'ROLE_CIAM_APPROVER') {
				this.wfApproverFlag = true;
				this.pagesService.pageWfApproverFlag = true;
			}
			if (element.displayName == 'ROLE_PLAN_ADMIN') {
				this.planAdminFlag = true;
				this.pagesService.pagePlanAdminFlag = true;
			}

			if (element.displayName == 'ROLE_READ_ONLY') {
				this.pagesService.readonlyFlag = true;
				this.readOnlyFlag = true;
			}
			if (element.displayName == 'ROLE_USER_MGMT') {
				this.pagesService.roleUserManagementAccess = true;
				this.userManagement = true;
			}

			if (element.displayName == 'ROLE_AUTHORIZATION') {
				this.pagesService.authorizationAccess = true;
				this.authorizationAccess = true;
			}
		});

		this.pagesService.ciamHomeFlag = false;
		this.showAllSidebarMenu();
		this.readonlyFunctionality();
		this.tenantAdminRoleCheck();
	}
	setMenuExpanded() {
		if (location.href.slice(-5).includes("ciam")) {
			this.items[0].expanded = true;
		}
		else {
			this.items[this.items.length - 1].expanded = true;
		}
	}


	readonlyFunctionality() {
		if ((!this.superAdminFlag && !this.tenantAdminFlag && !this.userManagement) && this.readOnlyFlag) {
			this.pagesService.userManagementReadOnlyFlag = true;
		}
		if ((!this.tenantAdminFlag && !this.appAdminFlag && !this.authorizationAccess) && this.readOnlyFlag) {
			this.pagesService.authorizationReadOnlyFlag = true;
		}
		if (!this.appAdminFlag && this.readOnlyFlag) {
			this.pagesService.applicationOnboardingReadOnlyFlag = true;
			this.pagesService.accountManagementReadOnlyFlag = true;
		}
		if (!this.ciamAdminFlag && !this.tenantAdminFlag && this.readOnlyFlag) {
			this.pagesService.tenantManagementReadOnlyFlag = true;
		}
		if (!this.planAdminFlag && this.readOnlyFlag) {
			this.pagesService.planAdminReadOnlyFlag = true;
		}
		if (!this.wfApproverFlag && this.readOnlyFlag) {
			this.pagesService.aprrovalReadOnlyFlag = true;
		}
	}
	tenantAdminRoleCheck() {
		if (this.tenantAdminFlag) {
			this.pagesService.hasTenantAdminRole = true;
		} else {
			this.pagesService.hasTenantAdminRole = false;
		}
	}
	showAllSidebarMenu() {
		console.log(this.roles);
		for (let j = 0; j < this.items.length; j++) {
			if (this.items[j].label == "CIAM Home" && (this.superAdminFlag || this.ciamAdminFlag || this.ciamAuthFlag || this.appAdminFlag || this.tenantAdminFlag || this.wfApproverFlag)) {
				this.items[j].visible = true;
			}
			else if (this.items[j].label == "User Management" && (this.tenantAdminFlag || this.superAdminFlag || this.readOnlyFlag || this.userManagement)) {
				this.items[j].visible = true;
				for (let k = 0; k < this.items[j].items?.length; k++) {
					if ((this.items[j].items[k].label == "Users" && this.tenantAdminFlag) || (this.items[j].items[k].label == "Users" && this.readOnlyFlag) || (this.items[j].items[k].label == "Users" && this.userManagement)) {
						this.items[j].items[k].disabled = false;
					}
					else if ((this.items[j].items[k].label == "User Groups" && this.tenantAdminFlag && this.rebacFlag == "false") || (this.items[j].items[k].label == "User Groups" && this.readOnlyFlag && this.rebacFlag == "false") || (this.items[j].items[k].label == "User Groups" && this.userManagement && this.rebacFlag == "false")) {
						this.items[j].items[k].disabled = false;
					}
					else if (this.items[j].items[k].label == "User Groups" && this.rebacFlag == "true") {
						this.items[j].items[k].visible = false;
					}
					else if (this.items[j].items[k].label == "Roles" && (this.tenantAdminFlag || this.superAdminFlag || this.readOnlyFlag || this.userManagement) && this.rebacFlag == "false") {
						this.items[j].items[k].disabled = false;
					}
					else if (this.items[j].items[k].label == "Roles" && this.rebacFlag == "true") {
						this.items[j].items[k].visible = false;
					}
					else {
						this.items[j].items[k].disabled = true;
					}
				}
			}
			else if (this.items[j].label == "Identity Providers" && this.tenantAdminFlag) {
				this.items[j].visible = true;
			}
			else if ((this.items[j].label == "Authorization" && (this.tenantAdminFlag || this.appAdminFlag || this.authorizationAccess)) || (this.items[j].label == "Authorization" && this.readOnlyFlag)) {
				this.items[j].visible = true;
				for (let k = 0; k < this.items[j].items?.length; k++) {
					if ((this.items[j].items[k].label == "Application Attributes" && this.tenantAdminFlag && this.rebacFlag == "false") || (this.items[j].items[k].label == "Application Attributes" && this.readOnlyFlag && this.rebacFlag == "false") || (this.items[j].items[k].label == "Application Attributes" && this.authorizationAccess && this.rebacFlag == "false")) {
						this.items[j].items[k].disabled = false;
					} else if (this.items[j].items[k].label == "Application Attributes" && this.rebacFlag == "true") {
						this.items[j].items[k].visible = false;
					}
					else if ((this.items[j].items[k].label == "Application Roles" && this.tenantAdminFlag && this.rebacFlag == "false") || (this.items[j].items[k].label == "Application Roles" && this.readOnlyFlag && this.rebacFlag == "false") || (this.items[j].items[k].label == "Application Roles" && this.authorizationAccess && this.rebacFlag == "false")) {
						this.items[j].items[k].disabled = false;
					} else if (this.items[j].items[k].label == "Application Roles" && this.rebacFlag == "true") {
						this.items[j].items[k].visible = false;
					}
					else if (this.items[j].items[k].label == "Manage Entitlements" && (this.tenantAdminFlag || this.appAdminFlag) && this.rebacFlag == "true") {
						this.items[j].items[k].disabled = false;
					}
					else if (this.items[j].items[k].label == "Manage Entitlements" && (this.tenantAdminFlag || this.appAdminFlag || this.readOnlyFlag || this.authorizationAccess) && this.rebacFlag == "false") {
						this.items[j].items[k].visible = false;
					}
					else if (this.items[j].items[k].label == "Manage Roles" && (this.tenantAdminFlag || this.appAdminFlag) && this.rebacFlag == "true") {
						this.items[j].items[k].disabled = false;
					}
					else if (this.items[j].items[k].label == "Manage Roles" && (this.tenantAdminFlag || this.appAdminFlag || this.readOnlyFlag || this.authorizationAccess) && this.rebacFlag == "false") {
						this.items[j].items[k].visible = false;
					}
					else if (this.items[j].items[k].label == "Application Resources" && (this.tenantAdminFlag || this.appAdminFlag)) {
						this.items[j].items[k].visible = false;
					}
					else {
						this.items[j].items[k].disabled = true;
					}
				}
			}
			else if ((this.items[j].label == "Applications" && this.appAdminFlag) || (this.items[j].label == "Applications" && this.readOnlyFlag)) {
				this.items[j].visible = true;
				for (let k = 0; k < this.items[j].items?.length; k++) {
					if ((this.items[j].items[k].label == "Application Onboarding" && this.appAdminFlag) || (this.items[j].items[k].label == "Application Onboarding" && this.readOnlyFlag)) {
						this.items[j].items[k].disabled = false;
					}
					// else if(this.items[j].items[k].label == "Application store" && (this.tenantAdminFlag || this.appAdminFlag)){
					// 	this.items[j].items[k].disabled=false;
					// }
					else {
						this.items[j].items[k].disabled = true;
					}
				}
			}
			else if ((this.items[j].label == "Tenant Management" && (this.ciamAdminFlag || this.tenantAdminFlag)) || (this.items[j].label == "Tenant Management" && this.readOnlyFlag)) {
				this.items[j].visible = true;
				for (let k = 0; k < this.items[j].items?.length; k++) {
					if ((this.items[j].items[k].label == "Tenants" && this.ciamAdminFlag) || (this.items[j].items[k].label == "Tenants" && this.readOnlyFlag)) {
						this.items[j].items[k].disabled = false;
					}
					else if ((this.items[j].items[k].label == "Tenant Settings" && this.tenantAdminFlag) || (this.items[j].items[k].label == "Tenant Settings" && this.readOnlyFlag)) {
						this.items[j].items[k].disabled = false;
					}
					else if ((this.items[j].items[k].label == "Workflow Configuration" && this.tenantAdminFlag) || (this.items[j].items[k].label == "Workflow Configuration" && this.readOnlyFlag)) {
						this.items[j].items[k].disabled = false;
					}
					else if ((this.items[j].items[k].label == "Authentication" && this.tenantAdminFlag) || (this.items[j].items[k].label == "Authentication" && this.readOnlyFlag)) {
						this.items[j].items[k].disabled = false;
					}
					else {
						this.items[j].items[k].disabled = true;
					}
				}
			}
			else if ((this.items[j].label == "Plans & Subscription" && this.planAdminFlag) || (this.items[j].label == "Plans & Subscription" && this.readOnlyFlag)) {
				this.items[j].visible = true;
				for (let k = 0; k < this.items[j].items?.length; k++) {
					if ((this.items[j].items[k].label == "Application Plans" && this.planAdminFlag) || (this.items[j].items[k].label == "Application Plans" && this.readOnlyFlag)) {
						this.items[j].items[k].disabled = false;
					}

					else if ((this.items[j].items[k].label == "Manage Subscriptions" && this.planAdminFlag) || (this.items[j].items[k].label == "Manage Subscriptions" && this.readOnlyFlag)) {
						this.items[j].items[k].disabled = false;
					}
					else {
						this.items[j].items[k].disabled = true;
					}
				}
			}
			else if (this.items[j].label == "Security" && this.tenantAdminFlag) {
				this.items[j].visible = true;
			}
			else if (this.items[j].label == "My Account" && (this.superAdminFlag || this.ciamAdminFlag || this.ciamAuthFlag || this.appAdminFlag || this.tenantAdminFlag || this.wfApproverFlag || this.readOnlyFlag)) {
				this.items[j].visible = true;
				for (let k = 0; k < this.items[j].items?.length; k++) {
					if (this.items[j].items[k].label == "Settings" && (this.superAdminFlag || this.ciamAdminFlag || this.ciamAuthFlag || this.appAdminFlag || this.tenantAdminFlag || this.wfApproverFlag)) {
						this.items[j].items[k].disabled = false;
					}
					else if (this.items[j].items[k].label == "My Approvals" && (this.wfApproverFlag || this.readOnlyFlag)) {
						this.items[j].items[k].disabled = false;
					}
					else if (this.items[j].items[k].label == "My Requests" && (this.superAdminFlag || this.ciamAdminFlag || this.appAdminFlag || this.tenantAdminFlag || this.wfApproverFlag || this.ciamAuthFlag || this.readOnlyFlag)) {
						this.items[j].items[k].disabled = false;
					}
					else {
						this.items[j].items[k].disabled = true;
					}
				}
			}
			else if (this.items[j].label == "Account Management" && (this.appAdminFlag || this.tenantAdminFlag || this.readOnlyFlag)) {
				this.items[j].visible = true;
			}
			else {
				this.items[j].visible = false;
			}
		}
	}
	private getPageTitle(routeSnapshot: ActivatedRouteSnapshot): string {
		let title: string =
			routeSnapshot?.data["pageTitle"]
				? routeSnapshot.data["pageTitle"]
				: "";
		if (routeSnapshot.firstChild) {
			title = this.getPageTitle(routeSnapshot.firstChild) || title;
		}
		return title;
	}

	connect() {
		let ws = new SockJS(CIAM_UI_SERVICE_API_URL + '/gs-guide-websocket');
		this.stompClient = Stomp.over(ws);
		MainComponent.stompCl = this.stompClient;
		const _this = this;
		_this.stompClient.connect({}, function (frame) {
			_this.stompClient.subscribe("/topic/greetings", function (sdkEvent) {
				_this.onMessageReceived(sdkEvent);
			});
		}, this.errorCallBack);
	}
	errorCallBack(error) {
		setTimeout(() => {
			this.connect();
		}, 5000);
	}
	disconnect() {
		if (this.stompClient !== null) {
			this.stompClient.disconnect();
			sessionStorage.removeItem('previousUrl');
		}
	}
	onMessageReceived(message) {
		const obj = JSON.parse(message.body);
		const newUserId = obj.userId;
		const prof = JSON.parse(localStorage.getItem('profile'));
		const oldUserEmail = prof.email;
		this.pagesService.onViewUserPageLoad(newUserId).subscribe({
			next: (results: any) => {
				this.newUserEmail = results.success.result.emails[0]?.value;
				if (this.newUserEmail == oldUserEmail) {
					this.disconnect();
					this.logout();
				}
			},
			error: () => {

			}
		}
		);
	}

	logout() {
		sessionStorage.removeItem('previousUrl');
		this.keycloakservice.login();
	}
	collapse() {
		this.columnstoggle = !this.columnstoggle;
		if (this.columnstoggle) {
			document.getElementById("content")!.classList.remove("expand");
			document.getElementById("sidebar")!.classList.remove("expanded");
			document.getElementById("content")!.classList.add("collapsed");
			this.columnstoggle = true;
		} else {
			document.getElementById("content")!.classList.add("expand");
			document.getElementById("sidebar")!.classList.add("expanded");
			document.getElementById("content")!.classList.remove("collapsed");
			this.columnstoggle = false;
		}
	}
	private updateTitle(): void {
		let pageTitle = this.getPageTitle(this.router.routerState.snapshot.root);
		if (!pageTitle) {
			pageTitle = "global.title";
		}
		this.translateService
			.get(pageTitle)
			.subscribe((title) => this.titleService.setTitle(title));
	}
	getTenantList() {
		this.userName = localStorage.getItem("username");
		const uName = this.userName;
		this.pagesService.getTenantListByUsername(uName).subscribe(results => {
			const responseData = results.success.result;
			if (responseData && responseData.length > 0) {
				responseData.forEach((element: any = {}) => {
					const data = {
						label: null,
						value: null,
					};
					data.label = element.label;
					data.value = element.value;
					if (element.label == this.realm) {
						this.pagesService.tenantId = element.value;
					}
					this.tenantList.push(data);
				});
				this.tenantList = [...this.tenantList];
				const migrationRoleFlag = environment.CIAM_ROLE_MIGRATION_FLAG + "";
				if (migrationRoleFlag == "true") {
					this.getCiamApplicationDetails();
				}
				else {
					this.getRoles();
				}
			}
		});
	}

	private getEventData(flag: boolean) {
		const formData: FormData = new FormData();
		formData.append('componentId', '1');
		this.mainComponentService.getEventData(formData).subscribe({
			next: async (response) => {
				if (response) {
					localStorage.setItem(EVENT_TYPE_LIST, JSON.stringify(response.EVENT_TYPE_LIST));
					localStorage.setItem(ITEM_TYPE_LIST, JSON.stringify(response.ITEM_TYPE_LIST));
					localStorage.setItem(CONTEXT_LIST, JSON.stringify(response.CONTEXT_LIST));
					if (flag) {
						//await this.prepareAndSaveLoggingData();
						this.getUserDetailsForLogging()
					}
				}
			},
			error: (err) => {
				console.log("Error", err);
			}
		});
	}


	private async prepareAndSaveLoggingData() {
		let userObj = this.userObj
		let eventData: IEventData = {
			eventTypeId: await this.loggingService.getEventId(EventName.LOGIN),
			componentId: USER_MANAGEMENT_COMPONENT_ID,
			isEventContext: true,
			isEventSubject: false,
			eventName: EventName.LOGIN
		};
		let eventContext: IEventContext[] = [
			await this.loggingService.getEventContextDataWithName(ContextName.USER_ID, userObj.ID),
			await this.loggingService.getEventContextDataWithName(ContextName.SESSION_START_TIME, ((new DatePipe('en-US')).transform(new Date(), 'yyyy-MM-dd HH:mm:ss')).toString()),
			await this.loggingService.getEventContextDataWithName(ContextName.IP_ADDRESS, 'Pending'),
			await this.loggingService.getEventContextDataWithName(ContextName.BROWSER, this.getBrowserName()),
			await this.loggingService.getEventContextDataWithName(ContextName.USER_NAME, localStorage.getItem("username")),
			await this.loggingService.getEventContextDataWithName(ContextName.USER_TYPE, userObj.USER_TYPE),
			await this.loggingService.getEventContextDataWithName(ContextName.EMAIL_ID, userObj.EMAIL_ID),
			await this.loggingService.getEventContextDataWithName(ContextName.ORGANIZATION, userObj.ORG_NAME),
			await this.loggingService.getEventContextDataWithName(ContextName.ORGANIZATION_ID, 'Pending'),
			await this.loggingService.getEventContextDataWithName(ContextName.NAME, userObj.NAME),
			await this.loggingService.getEventContextDataWithName(ContextName.STATUS, userObj.USER_STATUS),
			await this.loggingService.getEventContextDataWithName(ContextName.SESSION_ID, localStorage.getItem("sessionId")),
		];
		eventData.eventContextList = eventContext;

		let obj: any = {
			eventData: JSON.stringify(eventData),
			userName: this.userName,
			componentId: USER_MANAGEMENT_COMPONENT_ID,
			ORG_NAME: userObj.ORG_NAME
		};

		this.mainComponentService.logUserLoginData(obj).subscribe({
			next: (v) => {
				if (v) {
					localStorage.setItem(PARENT_ID, v['ParentId']);
				}
			},
			error: (e) => console.error(e),
			complete: () => console.info('complete')
		})

	}

	private getBrowserName(): string {
		const userAgent = window.navigator.userAgent;
		if (userAgent.includes('Chrome')) {
			return 'Google Chrome';
		} else if (userAgent.includes('Firefox')) {
			return 'Mozilla Firefox';
		} else if (userAgent.includes('Safari')) {
			return 'Apple Safari';
		} else if (userAgent.includes('Edge')) {
			return 'Microsoft Edge';
		} else if (userAgent.includes('Opera') || userAgent.includes('OPR')) {
			return 'Opera';
		} else if (userAgent.includes('Trident') || userAgent.includes('MSIE')) {
			return 'Internet Explorer';
		} else {
			return 'Unknown';
		}
	}

	logEventData() {
		this.getEventData(true);
	}

	getUserDetailsForLogging() {
		const formData: FormData = new FormData();
		formData.append('userName', localStorage.getItem("username").toString());
		this.mainComponentService.getUserDetailsForLogging(formData).subscribe({
			next: (response) => {
				this.userObj = response;
				this.prepareAndSaveLoggingData();
			},
			error: (err) => {
				console.log("Error", err);
			}


		});
	}
}
