<div class="layout-content custom-container">
    <form [formGroup]="CreateComponentForm" autocomplete="off" novalidate>
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left">
                <div class="p-fluid p-field">
                    <label [ngClass]="{'field-required': CreateComponentForm.get('componentName')?.errors?.required}">
                        Component Name
                        <span class='invalid-feedback-astrik'>*</span></label>
                    <input type="text" id="displayName_id" pattern="^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$"
                        name="component_name" formControlName="componentName" pInputText placeholder="Component name"
                        class="p-inputtext inputtextHeight" maxlength="50" (keyup)="this.nameFlag = false;" />
                    <div class="p-error"
                        *ngIf="CreateComponentForm.get('componentName')!.invalid && (CreateComponentForm.get('componentName')!.dirty || CreateComponentForm.get('componentName')!.touched)">
                        <div *ngIf="CreateComponentForm.get('componentName')?.errors?.required">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium class="p-error">
                                Component name is required.</medium>
                        </div>
                        <div *ngIf="CreateComponentForm.get('componentName')?.errors?.pattern">
                            <label class="alert-icon alert-circle-icon"></label>
                            Please enter valid name.
                        </div>
                    </div>
                    <div class="invalid-feedback">
                        <label *ngIf="this.nameFlag">
                            <div>
                                <label class="alert-icon alert-circle-icon"></label>
                                <medium class="p-error">Component Name already exists.
                                </medium>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                <div class="p-fluid p-field">
                    <label>
                        Component Type
                    </label>
                    <p-dropdown id="select1id" name="componentype" placeholder="Select" [options]="selectList"
                        [filter]="true" filterBy="label" [autoDisplayFirst]="false" formControlName="componentType">
                        <ng-template let-item pTemplate="item">
                            <div *ngIf="item.label.length <= 35;">
                                {{item.label}}
                            </div>
                            <div *ngIf="item.label.length > 35;" pTooltip="{{item.label}}" tooltipPosition="bottom">
                                {{item.label.substring(0,35) + "..." }}
                            </div>
                        </ng-template>
                    </p-dropdown>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left">
                <div class="p-fluid p-field">
                    <label>Client Protocol </label>
                    <p-dropdown id="selectid" name="clientprotocol" placeholder="Select" [options]="selectList1"
                        [filter]="true" filterBy="label" [autoDisplayFirst]="false" formControlName="clientProtocol">
                        <ng-template let-item pTemplate="item">
                            <div *ngIf="item.label.length <= 35;">
                                {{item.label}}
                            </div>
                            <div *ngIf="item.label.length > 35;" pTooltip="{{item.label}}" tooltipPosition="bottom">
                                {{item.label.substring(0,35) + "..." }}
                            </div>
                        </ng-template>
                    </p-dropdown>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                <div class="p-fluid p-field">
                    <label>
                        Access Type
                    </label>
                    <p-dropdown id="selectid" name="Accesstype" placeholder="Select" [options]="selectList2"
                        [filter]="true" filterBy="label" [autoDisplayFirst]="false" formControlName="accessType">
                        <ng-template let-item pTemplate="item">
                            <div *ngIf="item.label.length &lt;= 35;">
                                {{item.label}}
                            </div>
                            <div *ngIf="item.label.length > 35;" pTooltip="{{item.label}}" tooltipPosition="bottom">
                                {{item.label.substring(0,35) + "..." }}
                            </div>
                        </ng-template>
                    </p-dropdown>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                <div class="p-fluid p-field">
                    <label>
                        Root URL
                    </label>
                    <input type="text" id="rootURL_id" name="rootURL" formControlName="rootURL" pInputText
                        placeholder="Enter Root URL" class="p-inputtext inputtextHeight"
                        pattern="(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})" />
                    <div class="p-error"
                        *ngIf="CreateComponentForm.get('rootURL')!.invalid && (CreateComponentForm.get('rootURL')!.dirty || CreateComponentForm.get('rootURL')!.touched)">
                        <div *ngIf="CreateComponentForm.get('rootURL').errors.pattern">
                            <label class="alert-icon alert-circle-icon"></label>
                            <span class="p-error">Invalid URL format!</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                <div class="p-fluid p-field">
                    <label [ngClass]="{'field-required': CreateComponentForm.get('redirectURL')?.errors?.required}">
                        Valid redirect URL
                        <span class='invalid-feedback-astrik'>*</span></label>
                    <input type="text" id="redirectURL_id" name="redirectURL"
                        pattern="(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})"
                        formControlName="redirectURL" pInputText placeholder=" Enter Valid redirect URL"
                        class="p-inputtext inputtextHeight " />
                    <div class="p-error"
                        *ngIf="CreateComponentForm.get('redirectURL')!.invalid && (CreateComponentForm.get('redirectURL')!.dirty || CreateComponentForm.get('redirectURL')!.touched)">
                        <div *ngIf="CreateComponentForm.get('redirectURL')?.errors?.required">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium class="p-error"> Valid redirect URL is required.</medium>
                        </div>
                        <div *ngIf="CreateComponentForm.get('redirectURL').errors.pattern">
                            <label class="alert-icon alert-circle-icon"></label>
                            <span class="p-error">Invalid URL format!</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left">
                <div class="p-fluid p-field">
                    <label>
                        Base URL
                    </label>
                    <input type="text" id="baseURL_id" name="baseeURL" formControlName="baseURL" pInputText
                        placeholder="Enter Base URL" class="p-inputtext inputtextHeight"
                        pattern="(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})" />
                    <div class="p-error"
                        *ngIf="CreateComponentForm.get('baseURL')!.invalid && (CreateComponentForm.get('baseURL')!.dirty || CreateComponentForm.get('baseURL')!.touched)">
                        <div *ngIf="CreateComponentForm.get('baseURL').errors.pattern">
                            <label class="alert-icon alert-circle-icon"></label>
                            <span class="p-error">Invalid URL format!</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                <div class="p-fluid p-field">
                    <label>
                        Admin URL
                    </label>
                    <input type="text" id="adminURL_id" name="adminURL" formControlName="adminURL" pInputText
                        placeholder="Enter Admin URL" class="p-inputtext inputtextHeight"
                        pattern="(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})" />
                    <div class="p-error"
                        *ngIf="CreateComponentForm.get('adminURL')!.invalid && (CreateComponentForm.get('adminURL')!.dirty || CreateComponentForm.get('adminURL')!.touched)">
                        <div *ngIf="CreateComponentForm.get('adminURL').errors.pattern">
                            <label class="alert-icon alert-circle-icon"></label>
                            <span class="p-error">Invalid URL format!</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left">
                <div class="p-fluid p-field">
                    <label>
                        Web Origins
                    </label>
                    <input type="text" id="weborigins_id" name="weborigins" formControlName="webOrigins" pInputText
                        placeholder="Enter Web Origins" class="p-inputtext inputtextHeight  " />
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                <div class="p-fluid p-field">
                    <label>
                        BackChannel Logout URL
                    </label>
                    <input type="text" id="backchannelURL_id" name="backchannelURL" formControlName="backchannelURL"
                        pInputText placeholder="Enter Backchannel logout URL" class="p-inputtext inputtextHeight  "
                        pattern="(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})" />
                    <div class="p-error"
                        *ngIf="CreateComponentForm.get('backchannelURL')!.invalid && (CreateComponentForm.get('backchannelURL')!.dirty || CreateComponentForm.get('backchannelURL')!.touched)">
                        <div *ngIf="CreateComponentForm.get('backchannelURL').errors.pattern">
                            <label class="alert-icon alert-circle-icon"></label>
                            <span class="p-error">Invalid URL format!</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="p-col-12 p-sm-12 p-md-12 p-lg-8 p-xl-8 p-offset-undefined left">
                <div class=" p-fluid   p-field    ">
                    <label>
                        Description
                    </label>
                    <textarea pInputTextarea id="description_id" placeholder="Add description" name="description_name"
                        formControlName="description"></textarea>
                </div>
            </div>


            <div *ngIf="this.rebacFlag=='true'" class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
               <div class=" p-fluid   p-field    ">
                <label>Choose Email Template</label>
                <p-dropdown id="emailTemplateid" name="emailTemplate" formControlName="emailTemplate" placeholder="Select email template"
                            [options]="selectemailTempList" [filter]="true" filterBy="label" [autoDisplayFirst]="false"
                            >
                            <ng-template let-items pTemplate="item">
                                <div *ngIf="items?.label?.length &lt;=37;">
                                    {{items.label}}
                                </div>
                                <div *ngIf="items?.label?.length > 37;" pTooltip="{{items.label}}"
                                    tooltipPosition="bottom">
                                    {{items.label.substring(0,37) + "..." }}
                                </div>
                            </ng-template>
                        </p-dropdown>
               </div>   
            </div>



            <!-- Additional Entitlement form -->
            <div *ngIf="this.rebacFlag=='true'" class="p-fluid p-formgrid p-grid p-col">
                <div id="line_div" style=" border-bottom: 1px solid #E9EAED;"
                    class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left p-pl-0">
                </div>
                <div id='saas_heading_div'
                    class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 left p-pl-0 p-pr-0 p-mt-2">
                    <h6>Entitlement Details</h6>
                </div>
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left p-pl-0">
                    <div class="p-fluid p-field">
                        <label
                            [ngClass]="{'field-required': CreateComponentForm.get('ownershipComponent')?.errors?.required}">
                            Ownership component
                            <span class='invalid-feedback-astrik'>*</span></label>

                        <p-dropdown id="ownershipComponent_id" name="ownershipComponent" placeholder="Select"
                            [(ngModel)]="ownershipComponent" optionLabel="master_componentName_withId"
                            [options]="allActiveComponents" [filter]="true" filterBy="master_componentName_withId"
                            [autoDisplayFirst]="false" formControlName="ownershipComponent" resetFilterOnHide="true"
                            (onChange)="onChangeCompoent($event)" autoZIndex="true" baseZIndex="1">
                        </p-dropdown>
                        <div class="invalid-feedback"
                            *ngIf="CreateComponentForm.get('ownershipComponent')!.invalid && (CreateComponentForm.get('ownershipComponent')!.dirty || CreateComponentForm.get('ownershipComponent')!.touched) ">
                            <div *ngIf="CreateComponentForm.get('ownershipComponent')?.errors?.required">
                                <label class="alert-icon alert-circle-icon"></label>
                                <medium class="p-error">Ownership component is required</medium>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left">
                    <div class="p-fluid p-field">
                        <label
                            [ngClass]="{'field-required': CreateComponentForm.get('ownershipEntitlement')?.errors?.required}">
                            Ownership entitlement
                            <span class='invalid-feedback-astrik'>*</span></label>

                        <p-dropdown id="ownershipEntitlement_id" name="ownershipEntitlement" placeholder="Select"
                            [(ngModel)]="ownershipEntitlement" [options]="activeEntitlementsOfComponent" [filter]="true"
                            filterBy="master_entitlementName_withId" [autoDisplayFirst]="false"
                            formControlName="ownershipEntitlement" optionLabel="master_entitlementName_withId">

                        </p-dropdown>
                    </div>
                </div>
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left p-pr-0">
                    <div class="p-fluid p-field">
                        <label
                            [ngClass]="{'field-required': CreateComponentForm.get('primeComponentId')?.errors?.required}">
                            Component ID
                            <span class='invalid-feedback-astrik'>*</span></label>
                        <input type="text" id="primeComponentId_id"
                            pattern="^([1-9]|[0-9][0-9]||[0-9][0-9][0-9]|[0-4][0-9][0-9][0-9]|[0-5][0-1][0-1][0-9]|512[0])$"
                            name="primeComponentId" formControlName="primeComponentId" pInputText
                            placeholder="Enter Number" class="p-inputtext inputtextHeight" maxlength="50"
                            (keyup)="this.nameFlag2 = false;" />
                        <div class="p-error"
                            *ngIf="CreateComponentForm.get('primeComponentId')!.invalid && (CreateComponentForm.get('primeComponentId')!.dirty || CreateComponentForm.get('primeComponentId')!.touched)">
                            <div *ngIf="CreateComponentForm.get('primeComponentId')?.errors?.required">
                                <label class="alert-icon alert-circle-icon"></label>
                                <medium class="p-error">
                                    Component ID is required.</medium>
                            </div>
                            <div *ngIf="CreateComponentForm.get('primeComponentId')?.errors?.pattern">
                                <label class="alert-icon alert-circle-icon"></label>
                                Please enter valid Component ID.
                            </div>
                        </div>
                        <div class="invalid-feedback">
                            <label *ngIf="this.nameFlag2">
                                <div>
                                    <label class="alert-icon alert-circle-icon"></label>
                                    <medium class="p-error">Component ID already exists.
                                    </medium>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Additional Saas form -->

            <div *ngIf="this.saasFlag" class="p-fluid p-formgrid p-grid">
                <div id="line_div" style=" border-bottom: 1px solid #E9EAED;"
                    class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left p-pl-0">
                </div>
                <div id='saas_heading_div' class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 left">
                    <h6>Additional Details For SaaS Application</h6>
                </div>

                <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left p-mt-5">

                    <div class="p-formgroup-inline">
                        <div class=" p-field-checkbox normal">
                            <p-checkbox id="db_migration" binary="true" inputId="binary"
                                formControlName="db_migration_checkbox" (onSelect)="onCheckdbMigration();">
                            </p-checkbox>
                            <label style="font-size: 0.87rem;" for="binary">DB migration required</label>
                        </div>
                    </div>

                </div>
                <div *ngIf="this.CreateComponentForm.value.db_migration_checkbox==true"
                    class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                    <div class="p-fluid p-field">
                        <label>DB Migration path<span class='invalid-feedback-astrik p-ml-0'>*</span></label>
                        <div class="custom_choose_file custom-tenant-file">
                            <p-fileUpload id="newSolutionPageIconChooseFilebtn" name="newSolutionPageIconChooseFilebtn"
                                #fileUploadPageIconChooseFilebtn1 chooseLabel="Choose File" customUpload="true"
                                cancelLabel="Reset" [showUploadButton]="false" [showCancelButton]="false"
                                (onSelect)="onSelectDbMigration($event)">
                                <ng-template pTemplate="content">
                                    <ng-container
                                        *ngIf="imageURL && (productPrimaryImage == '' || productPrimaryImage == undefined);then previewText1; else previewText">
                                    </ng-container>
                                    <ng-template #previewText1>
                                        <label class="label_content">
                                            <span pTooltip="{{imageForToolTip.length > 30 ? imageForToolTip : ''}}"
                                                tooltipPosition="left">
                                                <ng-container *ngIf="imageForToolTip.length > 30; else tooltip">
                                                    {{
                                                    imageURL.split("/")[4].substring(0,
                                                    30) +
                                                    "..." }}
                                                </ng-container>
                                                <ng-template #tooltip>
                                                    {{ imageURL.split("/")[4] }}
                                                </ng-template>
                                            </span>
                                        </label>
                                    </ng-template>
                                    <ng-template #previewText>
                                        <label
                                            *ngIf="(productPrimaryImage == '' || productPrimaryImage == undefined) && !dbImagevalid || dbImagePlacheholder"
                                            class="label_content">
                                            &nbsp;Add SQL file Max:
                                            10Mb
                                        </label>
                                    </ng-template>
                                </ng-template>
                                <ng-template let-file pTemplate="file">
                                    <div class="file">
                                        <ng-container
                                            *ngIf="imageURL && (productPrimaryImage == '' || productPrimaryImage == undefined);then previewdata1; else previewdata">
                                        </ng-container>
                                        <ng-template #previewdata>
                                            <span class="ml-2 fileName"
                                                pTooltip="{{file.name.length > 15 ? file.name : ''}}"
                                                tooltipPosition="left">
                                                <ng-container *ngIf="file.name.length > 15; else tooltip">
                                                    {{ file.name.substring(0,15) +
                                                    "..." }}
                                                </ng-container>
                                                <ng-template #tooltip>
                                                    {{ file.name }}
                                                </ng-template>
                                            </span>
                                            <span class="delete-action"
                                                (click)="onRemoveDbMigration(event,fileUploadPageIconChooseFilebtn1)">
                                                <i aria-hidden="true" class="pi pi-times"></i>
                                            </span>
                                        </ng-template>
                                        <ng-template #previewdata1></ng-template>
                                    </div>
                                </ng-template>
                            </p-fileUpload>
                        </div>
                        <div class="p-error" *ngIf="solImage">
                            <medium>The following characters are not allowed: ~ # % & * &#123; &#125; \ : &lt; >
                                ? / | ' "</medium>
                        </div>
                        <div class="p-error" *ngIf="solImageFileSize">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium>The maximum file size can be 10MB!</medium>
                        </div>
                        <div class="p-error" *ngIf="dbImagevalid">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium>Invalid file format!</medium>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left">
                    <div class="p-fluid p-field">
                        <label>Environmental variables<span class='invalid-feedback-astrik p-ml-0'>*</span></label>
                        <div class="custom_choose_file custom-tenant-file">
                            <p-fileUpload id="newSolutionPageIconChooseFilebtn" name="newSolutionPageIconChooseFilebtn"
                                #fileUploadPageIconChooseFilebtn chooseLabel="Choose File" customUpload="true"
                                cancelLabel="Reset" [showUploadButton]="false" [showCancelButton]="false"
                                (onSelect)="onSelectEnvVariables($event)">
                                <ng-template pTemplate="content">
                                    <ng-container
                                        *ngIf="imageURL && (productPrimaryImage == '' || productPrimaryImage == undefined);then previewText1; else previewText">
                                    </ng-container>
                                    <ng-template #previewText1>
                                        <label class="label_content">
                                            <span pTooltip="{{imageForToolTip.length > 30 ? imageForToolTip : ''}}"
                                                tooltipPosition="left">
                                                <ng-container *ngIf="imageForToolTip.length > 30; else tooltip">
                                                    {{
                                                    imageURL.split("/")[4].substring(0,
                                                    30) +
                                                    "..." }}
                                                </ng-container>
                                                <ng-template #tooltip>
                                                    {{ imageURL.split("/")[4] }}
                                                </ng-template>
                                            </span>
                                        </label>
                                    </ng-template>
                                    <ng-template #previewText>
                                        <label
                                            *ngIf="(productPrimaryImage == '' || productPrimaryImage == undefined) && !imagevalid || imagePlacheholder"
                                            class="label_content">
                                            &nbsp;Add JSON file Max:
                                            5Mb
                                        </label>
                                    </ng-template>
                                </ng-template>
                                <ng-template let-file pTemplate="file">
                                    <div class="file">
                                        <ng-container
                                            *ngIf="imageURL && (productPrimaryImage == '' || productPrimaryImage == undefined);then previewdata1; else previewdata">
                                        </ng-container>
                                        <ng-template #previewdata>
                                            <span class="ml-2 fileName"
                                                pTooltip="{{file.name.length > 15 ? file.name : ''}}"
                                                tooltipPosition="left">
                                                <ng-container *ngIf="file.name.length > 15; else tooltip">
                                                    {{ file.name.substring(0,15) +
                                                    "..." }}
                                                </ng-container>
                                                <ng-template #tooltip>
                                                    {{ file.name }}
                                                </ng-template>
                                            </span>
                                            <span class="delete-action"
                                                (click)="onRemoveEnvVariables(event,fileUploadPageIconChooseFilebtn)">
                                                <i aria-hidden="true" class="pi pi-times"></i>
                                            </span>
                                        </ng-template>
                                        <ng-template #previewdata1></ng-template>
                                    </div>
                                </ng-template>
                            </p-fileUpload>
                        </div>
                        <div class="p-error" *ngIf="solImage">
                            <medium>The following characters are not allowed: ~ # % & * &#123; &#125; \ : &lt; >
                                ? / | ' "</medium>
                        </div>
                        <div class="p-error" *ngIf="solImageFileSize">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium>The maximum file size can be 5MB!</medium>
                        </div>
                        <div class="p-error" *ngIf="imagevalid">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium>Invalid file format!</medium>
                        </div>
                    </div>
                </div>
                <div *ngIf="this.CreateComponentForm.value.db_migration_checkbox!=true"
                    class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left "></div>


                <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left ">
                    <div class="p-fluid p-field">
                        <label
                            [ngClass]="{'field-required': CreateComponentForm.get('cpuThreshold')?.errors?.required}">
                            Auto-scaling CPU threshold(Max:10,000 m)
                            <span class='invalid-feedback-astrik'>*</span></label>
                        <input type="text" id="cpuThreshold_id"
                            pattern="^([1-9]|[0-9][0-9]||[0-9][0-9][0-9]|[0-9][0-9][0-9][0-9]|1000[0])$"
                            name="component_name" formControlName="cpuThreshold" pInputText placeholder="Enter Number"
                            class="p-inputtext inputtextHeight" maxlength="50" />
                        <div class="p-error"
                            *ngIf="CreateComponentForm.get('cpuThreshold')!.invalid && (CreateComponentForm.get('cpuThreshold')!.dirty || CreateComponentForm.get('cpuThreshold')!.touched)">
                            <div *ngIf="CreateComponentForm.get('cpuThreshold')?.errors?.required">
                                <label class="alert-icon alert-circle-icon"></label>
                                <medium class="p-error">
                                    CPU threshold is required.</medium>
                            </div>
                            <div *ngIf="CreateComponentForm.get('cpuThreshold')?.errors?.pattern">
                                <label class="alert-icon alert-circle-icon"></label>
                                Please enter valid threshold.
                            </div>
                        </div>
                    </div>

                </div>
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                    <div class="p-fluid p-field">
                        <label
                            [ngClass]="{'field-required': CreateComponentForm.get('ramThreshold')?.errors?.required}">
                            Auto-scaling RAM threshold(Max:5120 mb)
                            <span class='invalid-feedback-astrik'>*</span></label>
                        <input type="text" id="ramThreshold_id"
                            pattern="^([1-9]|[0-9][0-9]||[0-9][0-9][0-9]|[0-4][0-9][0-9][0-9]|[0-5][0-1][0-1][0-9]|512[0])$"
                            name="component_name" formControlName="ramThreshold" pInputText placeholder="Enter Number"
                            class="p-inputtext inputtextHeight" maxlength="50" />
                        <div class="p-error"
                            *ngIf="CreateComponentForm.get('ramThreshold')!.invalid && (CreateComponentForm.get('ramThreshold')!.dirty || CreateComponentForm.get('ramThreshold')!.touched)">
                            <div *ngIf="CreateComponentForm.get('ramThreshold')?.errors?.required">
                                <label class="alert-icon alert-circle-icon"></label>
                                <medium class="p-error">
                                    Ram threshold is required.</medium>
                            </div>
                            <div *ngIf="CreateComponentForm.get('ramThreshold')?.errors?.pattern">
                                <label class="alert-icon alert-circle-icon"></label>
                                Please enter valid threshold.
                            </div>
                        </div>
                    </div>
                </div>
                <!-- dropdown -->
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left">
                    <div class="p-fluid p-field">
                        <label
                            [ngClass]="{'field-required': CreateComponentForm.get('artifactType')?.errors?.required}">
                            Artifact Type
                            <span class='invalid-feedback-astrik'>*</span></label>

                        <p-dropdown id="artifactType_id" name="artifactType" placeholder="Select"
                            [options]="artifactList" [filter]="true" filterBy="label" [autoDisplayFirst]="false"
                            formControlName="artifactType">
                            <ng-template let-item pTemplate="item">
                                <div *ngIf="item.label.length <= 35;">
                                    {{item.label}}
                                </div>
                                <div *ngIf="item.label.length > 35;" pTooltip="{{item.label}}" tooltipPosition="bottom">
                                    {{item.label.substring(0,35) + "..." }}
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left ">
                    <div class="p-fluid p-field">
                        <label *ngIf="this.CreateComponentForm.value.artifactType==null "
                            [ngClass]="{'field-required': CreateComponentForm.get('imagePath')?.errors?.required}">
                            Path
                            <span class='invalid-feedback-astrik'>*</span></label>
                        <label *ngIf="this.CreateComponentForm.value.artifactType=='image' "
                            [ngClass]="{'field-required': CreateComponentForm.get('imagePath')?.errors?.required}">
                            Image Path
                            <span class='invalid-feedback-astrik'>*</span></label>
                        <label *ngIf="this.CreateComponentForm.value.artifactType=='jar' "
                            [ngClass]="{'field-required': CreateComponentForm.get('imagePath')?.errors?.required}">
                            JFrog/Nexus Path
                            <span class='invalid-feedback-astrik'>*</span></label>
                        <input type="text" id="imagePath_id" name="component_name" formControlName="imagePath"
                            pInputText placeholder="Enter image path" class="p-inputtext inputtextHeight"
                            maxlength="255" />
                        <div class="p-error"
                            *ngIf="CreateComponentForm.get('imagePath')!.invalid && (CreateComponentForm.get('imagePath')!.dirty || CreateComponentForm.get('imagePath')!.touched)">
                            <div *ngIf="CreateComponentForm.get('imagePath')?.errors?.required">
                                <label class="alert-icon alert-circle-icon"></label>
                                <medium class="p-error">
                                    Path is required.</medium>
                            </div>
                            <div *ngIf="CreateComponentForm.get('imagePath')?.errors?.pattern">
                                <label class="alert-icon alert-circle-icon"></label>
                                Please enter valid Path.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left ">
                    <div class="p-fluid p-field">
                        <label [ngClass]="{'field-required': CreateComponentForm.get('contextPath')?.errors?.required}">
                            Context Path
                            <span class='invalid-feedback-astrik'>*</span></label>
                        <input type="text" id="contextPath_id" name="contextPath_name" formControlName="contextPath"
                            pInputText placeholder="Enter context path" class="p-inputtext inputtextHeight"
                            maxlength="255" />
                        <div class="p-error"
                            *ngIf="CreateComponentForm.get('contextPath')!.invalid && (CreateComponentForm.get('contextPath')!.dirty || CreateComponentForm.get('contextPath')!.touched)">
                            <div *ngIf="CreateComponentForm.get('contextPath')?.errors?.required">
                                <label class="alert-icon alert-circle-icon"></label>
                                <medium class="p-error">
                                    Path is required.</medium>
                            </div>
                            <div *ngIf="CreateComponentForm.get('contextPath')?.errors?.pattern">
                                <label class="alert-icon alert-circle-icon"></label>
                                Please enter valid Path.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left ">
                    <div class="p-fluid p-field">
                        <label [ngClass]="{'field-required': CreateComponentForm.get('port')?.errors?.required}">
                            Port
                            <span class='invalid-feedback-astrik'>*</span></label>
                        <input type="text" id="port_id" name="port_name" formControlName="port" pInputText
                            placeholder="Enter port" class="p-inputtext inputtextHeight" pattern="^[0-9]\d*$"
                            maxlength="255" />
                        <div class="p-error"
                            *ngIf="CreateComponentForm.get('port')!.invalid && (CreateComponentForm.get('port')!.dirty || CreateComponentForm.get('port')!.touched)">
                            <div *ngIf="CreateComponentForm.get('port')?.errors?.required">
                                <label class="alert-icon alert-circle-icon"></label>
                                <medium class="p-error">
                                    Port is required.</medium>
                            </div>
                            <div *ngIf="CreateComponentForm.get('port')?.errors?.pattern">
                                <label class="alert-icon alert-circle-icon"></label>
                                Please enter valid Port.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                    <div class="p-d-inline-flex">
                        <span class="d-block label-top"> <label>
                                Create Role
                            </label>
                        </span>
                        <div class="p-fieldp-fluid">
                            <p-inputSwitch [(ngModel)]="this.createRole" formControlName="createRole"
                                styleClass="p-inputswitch-md" class="p-mr-2 custom-switch">
                            </p-inputSwitch>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                    <div class="p-d-inline-flex">
                        <span class="d-block label-top"> <label>
                                Create Volume
                            </label>
                        </span>
                        <div class="p-fieldp-fluid">
                            <p-inputSwitch [(ngModel)]="this.createVolume" formControlName="createVolume"
                                styleClass="p-inputswitch-md" class="p-mr-2 custom-switch">
                            </p-inputSwitch>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left p-mb-3">
                    <div class="p-d-inline-flex">
                        <span class="d-block label-top"> <label>
                                With Volume
                            </label>
                        </span>
                        <div class="p-fieldp-fluid">
                            <p-inputSwitch [(ngModel)]="this.withVolume" formControlName="withVolume"
                                styleClass="p-inputswitch-md" class="p-mr-2 custom-switch">
                            </p-inputSwitch>
                        </div>
                    </div>
                </div>
                <br>
                <!-- key -->
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3 p-offset-0 left">
                    <label>Global Environment</label>
                </div>
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3 p-offset-0 left">
                    <label>Reference/Example</label>
                </div>
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6 p-offset-0 left">
                    <label>Enter Key</label>
                </div>
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3 p-offset-0 left">
                    <label [ngClass]="{'field-required': CreateComponentForm.get('enterKey_dbUrl')?.errors?.required}">
                        DataBase Url
                        <span class='invalid-feedback-astrik'>*</span></label>
                </div>
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3 p-offset-0 left">
                    <label style="color:grey">APP_DB_URL</label>
                </div>
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6 p-offset-0 left">
                    <div class="p-fluid p-field">
                        <input type="text" id="enterKey_dbUrl" pattern="^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$"
                            name="component_name" formControlName="enterKey_dbUrl" pInputText placeholder="Enter"
                            class="p-inputtext inputtextHeight" maxlength="255" />
                        <div class="p-error"
                            *ngIf="CreateComponentForm.get('enterKey_dbUrl')!.invalid && (CreateComponentForm.get('enterKey_dbUrl')!.dirty || CreateComponentForm.get('enterKey_dbUrl')!.touched)">
                            <div *ngIf="CreateComponentForm.get('enterKey_dbUrl')?.errors?.required">
                                <label class="alert-icon alert-circle-icon"></label>
                                <medium class="p-error">
                                    Database Url is required.</medium>
                            </div>
                            <div *ngIf="CreateComponentForm.get('enterKey_dbUrl')?.errors?.pattern">
                                <label class="alert-icon alert-circle-icon"></label>
                                Please enter valid Database Url.
                            </div>
                        </div>

                    </div>
                </div>
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3 p-offset-0 left">
                    <label
                        [ngClass]="{'field-required': CreateComponentForm.get('enterKey_dbUsername')?.errors?.required}">
                        Database username
                        <span class='invalid-feedback-astrik'>*</span></label>
                </div>
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3 p-offset-0 left">
                    <label style="color:grey">APP_DB_USERNAME</label>
                </div>
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6 p-offset-0 left">
                    <div class="p-fluid p-field">
                        <input type="text" id="enterKey_dbUsername" pattern="^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$"
                            name="component_name" formControlName="enterKey_dbUsername" pInputText placeholder="Enter"
                            class="p-inputtext inputtextHeight" maxlength="255" />
                        <div class="p-error"
                            *ngIf="CreateComponentForm.get('enterKey_dbUsername')!.invalid && (CreateComponentForm.get('enterKey_dbUsername')!.dirty || CreateComponentForm.get('enterKey_dbUsername')!.touched)">
                            <div *ngIf="CreateComponentForm.get('enterKey_dbUsername')?.errors?.required">
                                <label class="alert-icon alert-circle-icon"></label>
                                <medium class="p-error">
                                    Database username is required.</medium>
                            </div>
                            <div *ngIf="CreateComponentForm.get('enterKey_dbUsername')?.errors?.pattern">
                                <label class="alert-icon alert-circle-icon"></label>
                                Please enter valid Database username.
                            </div>
                        </div>

                    </div>
                </div>
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3 p-offset-0 left">
                    <label
                        [ngClass]="{'field-required': CreateComponentForm.get('enterKey_dbPassword')?.errors?.required}">
                        Database password
                        <span class='invalid-feedback-astrik'>*</span></label>
                </div>
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3 p-offset-0 left">
                    <label style="color:grey">APP_DB_PASSWORD</label>
                </div>
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6 p-offset-0 left">
                    <div class="p-fluid p-field">
                        <input type="text" id="enterKey_dbPassword" pattern="^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$"
                            name="component_name" formControlName="enterKey_dbPassword" pInputText placeholder="Enter"
                            class="p-inputtext inputtextHeight" maxlength="255" />
                        <div class="p-error"
                            *ngIf="CreateComponentForm.get('enterKey_dbPassword')!.invalid && (CreateComponentForm.get('enterKey_dbPassword')!.dirty || CreateComponentForm.get('enterKey_dbPassword')!.touched)">
                            <div *ngIf="CreateComponentForm.get('enterKey_dbPassword')?.errors?.required">
                                <label class="alert-icon alert-circle-icon"></label>
                                <medium class="p-error">
                                    Database password is required.</medium>
                            </div>
                            <div *ngIf="CreateComponentForm.get('enterKey_dbPassword')?.errors?.pattern">
                                <label class="alert-icon alert-circle-icon"></label>
                                Please enter valid Database password.
                            </div>
                        </div>

                    </div>
                </div>
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3 p-offset-0 left">
                    <label [ngClass]="{'field-required': CreateComponentForm.get('enterKey_idpUrl')?.errors?.required}">
                        IDP Url
                        <span class='invalid-feedback-astrik'>*</span></label>
                </div>
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3 p-offset-0 left">
                    <label style="color:grey">APP_KEYCLOAK_URL</label>
                </div>
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6 p-offset-0 left">
                    <div class="p-fluid p-field">
                        <input type="text" id="enterKey_idpUrl" pattern="^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$"
                            name="component_name" formControlName="enterKey_idpUrl" pInputText placeholder="Enter"
                            class="p-inputtext inputtextHeight" maxlength="255" />
                        <div class="p-error"
                            *ngIf="CreateComponentForm.get('enterKey_idpUrl')!.invalid && (CreateComponentForm.get('enterKey_idpUrl')!.dirty || CreateComponentForm.get('enterKey_idpUrl')!.touched)">
                            <div *ngIf="CreateComponentForm.get('enterKey_idpUrl')?.errors?.required">
                                <label class="alert-icon alert-circle-icon"></label>
                                <medium class="p-error">
                                    IDP url is required.</medium>
                            </div>
                            <div *ngIf="CreateComponentForm.get('enterKey_idpUrl')?.errors?.pattern">
                                <label class="alert-icon alert-circle-icon"></label>
                                Please enter valid IDP url.
                            </div>
                        </div>

                    </div>
                </div>
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3 p-offset-0 left">
                    <label
                        [ngClass]="{'field-required': CreateComponentForm.get('enterKey_idpRealm')?.errors?.required}">
                        IDP realm
                        <span class='invalid-feedback-astrik'>*</span></label>
                </div>
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3 p-offset-0 left">
                    <label style="color:grey">APP_KEYCLOAK_REALM</label>
                </div>
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6 p-offset-0 left">
                    <div class="p-fluid p-field">
                        <input type="text" id="enterKey_idpRealm" pattern="^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$"
                            name="component_name" formControlName="enterKey_idpRealm" pInputText placeholder="Enter"
                            class="p-inputtext inputtextHeight" maxlength="255" />
                        <div class="p-error"
                            *ngIf="CreateComponentForm.get('enterKey_idpRealm')!.invalid && (CreateComponentForm.get('enterKey_idpRealm')!.dirty || CreateComponentForm.get('enterKey_idpRealm')!.touched)">
                            <div *ngIf="CreateComponentForm.get('enterKey_idpRealm')?.errors?.required">
                                <label class="alert-icon alert-circle-icon"></label>
                                <medium class="p-error">
                                    IDP realm is required.</medium>
                            </div>
                            <div *ngIf="CreateComponentForm.get('enterKey_idpRealm')?.errors?.pattern">
                                <label class="alert-icon alert-circle-icon"></label>
                                Please enter valid IDP realm.
                            </div>
                        </div>

                    </div>
                </div>
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3 p-offset-0 left">
                    <label
                        [ngClass]="{'field-required': CreateComponentForm.get('enterKey_idpClientId')?.errors?.required}">
                        IDP client ID
                        <span class='invalid-feedback-astrik'>*</span></label>
                </div>
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3 p-offset-0 left">
                    <label style="color:grey">APP_KEYCLOAK_CLIENT_ID</label>
                </div>
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6 p-offset-0 left">
                    <div class="p-fluid p-field">
                        <input type="text" id="enterKey_idpClientId" pattern="^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$"
                            name="component_name" formControlName="enterKey_idpClientId" pInputText placeholder="Enter"
                            class="p-inputtext inputtextHeight" maxlength="255" />
                        <div class="p-error"
                            *ngIf="CreateComponentForm.get('enterKey_idpClientId')!.invalid && (CreateComponentForm.get('enterKey_idpClientId')!.dirty || CreateComponentForm.get('enterKey_idpClientId')!.touched)">
                            <div *ngIf="CreateComponentForm.get('enterKey_idpClientId')?.errors?.required">
                                <label class="alert-icon alert-circle-icon"></label>
                                <medium class="p-error">
                                    IDP Client id is required.</medium>
                            </div>
                            <div *ngIf="CreateComponentForm.get('enterKey_idpClientId')?.errors?.pattern">
                                <label class="alert-icon alert-circle-icon"></label>
                                Please enter valid IDP Client id.
                            </div>
                        </div>

                    </div>
                </div>
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3 p-offset-0 left">
                    <label
                        [ngClass]="{'field-required': CreateComponentForm.get('enterKey_idpClientSecret')?.errors?.required}">
                        IDP Client Secret
                        <span class='invalid-feedback-astrik'>*</span></label>
                </div>
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-3 p-xl-3 p-offset-0 left">
                    <label style="color:grey">
                        APP_KEYCLOAK_CLIENT_SECRET</label>
                </div>
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6 p-offset-0 left">
                    <div class="p-fluid p-field">
                        <input type="text" id="enterKey_idpClientSecret" pattern="^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$"
                            name="component_name" formControlName="enterKey_idpClientSecret" pInputText
                            placeholder="Enter" class="p-inputtext inputtextHeight" maxlength="255" />
                        <div class="p-error"
                            *ngIf="CreateComponentForm.get('enterKey_idpClientSecret')!.invalid && (CreateComponentForm.get('enterKey_idpClientSecret')!.dirty || CreateComponentForm.get('enterKey_idpClientSecret')!.touched)">
                            <div *ngIf="CreateComponentForm.get('enterKey_idpClientSecret')?.errors?.required">
                                <label class="alert-icon alert-circle-icon"></label>
                                <medium class="p-error">
                                    IDP client secret is required.</medium>
                            </div>
                            <div *ngIf="CreateComponentForm.get('enterKey_idpClientSecret')?.errors?.pattern">
                                <label class="alert-icon alert-circle-icon"></label>
                                Please enter valid IDP client secret.
                            </div>
                        </div>

                    </div>
                </div>

            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right p-mb-2 p-pt-3">
                <div class="btnwrap p-mr-3">
                    <button pButton type="button" class="p-button-brand2" label="Close" (click)="onClose();">
                    </button>
                </div>
                <div class="btnwrap">
                    <button pButton class="p-button-primary" type="submit" label="Save" (click)="createComponent();"
                        [disabled]="!CreateComponentForm.valid || this.nameFlag ||this.formValidFlag || this.dbformValidFlag">
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>