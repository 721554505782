import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CIAM_UM_SERVER_API_URL } from 'app/app.constants';

@Injectable({
    providedIn: 'root'
})
export class OnwershipApprovalsService {
    constructor(private http: HttpClient) { }

    getEntitlementApprovalDetails(obj): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageApproval/getApprovalDetails', obj);
    }

    getEntitlementOwnerTransferRequests(data): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageApproval/getEntitlementOwnerTransferRequests', data);
    }

    updateOwnerEntStatus(obj): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageApproval/updateOwnershipStatus', obj);
    }

    getEntApprovalStatusList(): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageEntitlements/getEntitlementStatusList', {});
    }

    getEntitlementFilterData(filter: any): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageApproval/getUserEntitlementApprovals', filter);
    }

    approveAllEntitlements(obj) {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageApproval/approveAllEntitlements', obj);
    }

    rejectAllEntitlements(obj) {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageApproval/rejectAllEntitlements', obj);
    }

    approveEntitlement(obj) {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageApproval/approveEntitlement', obj);
    }

    rejectEntitlement(obj) {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageApproval/rejectEntitlement', obj);
    }

    revokeEntitlement(obj) {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageApproval/revokeEntitlement', obj);
    }
    getComponentList(): Observable<any> {
        return this.http.post(CIAM_UM_SERVER_API_URL + 'umManageEntitlements/getComponentList_New', {});
    }
}
