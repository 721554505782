import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PagesService } from '../pages.service';
import { MessageService } from 'primeng/api';
import { MainComponent } from 'app/layouts/main/main.component';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { ContextName, EventName } from 'app/shared/constants/logging-event.constants';
@Component({
  selector: 'jhi-SuspendUser',
  templateUrl: './SuspendUser.component.html',
  styleUrls: ['./SuspendUser.component.scss'],
  providers: [MessageService, DialogService],
})
export class SuspendUserComponent implements OnInit {
  SuspendUserForm = this.fb.group({});
  arrayId: any[] = [];
  wfFlag = false;
  stompClient: any;
  suspendUserData:any[]=[];
  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    private messageService: MessageService,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.SuspendUserForm.patchValue(JSON.parse(data));
    }
  }
  ngOnInit(): void {
    this.arrayId = this.config?.data?.id;
    this.suspendUserData = this.config?.data?.rowData;
  }
  onClickNo($event: UntypedFormGroup): void {
    this.ref.close();
  }

  onClickYes($event: UntypedFormGroup): void {
    const reqBody = {};
    reqBody["tenantId"] = this.pagesService.tenantId;
    reqBody["userIds"] = this.arrayId;
    const formData = reqBody;
    this.pagesService.suspendUser(formData).subscribe({
      next: results => {
        let cnt = 0;
        let kk = results.success.result;
        kk.forEach(element => {
          if (element.messageCode == 'CIAM 154') {
            cnt = cnt + 1;
            this.wfFlag = true;
          }
          else if (element.messageCode == 'CIAM 262') {
            this.wfFlag = false;
          }
        });

        if (this.wfFlag) {
          this.ref.close();
          this.navigateToMyReq('Suspend user request has been sent for approval');
        }
        else if (!this.wfFlag) {
          this.ref.close('User Suspended Succesfully');
          this.send();
        }
        this.logEvent(EventName.SUSPEND_USER,ContextName.SUSPEND_USER,this.suspendUserData);
      }
      ,
      error: error => {
        if (error.error?.error?.messageCode == "CIAM 105") {
          this.ref.close('Request already available for approval');
        }
        else {
          this.ref.close('error');
        }
      }
    });
  }

  navigateToMyReq(msg: any): void {
    const navigationExtras: NavigationExtras = {
      state: {
        msg: msg,
      },
    };
    this.router.navigate(['/pages/myrequestslisting'], navigationExtras);
  }

  send() {
    const data: any = this.config?.data?.id;
    this.stompClient = MainComponent.stompCl;
    const obj = {
      tenantId: this.pagesService.tenantId,
      userId: data?.[0]
    }
    this.stompClient.send("/ciam/v1/user/force-logoff-notification", {}, JSON.stringify(obj));
  }

  logEvent(eventName,eventContextName,value){
    const data={
      "ContextName":eventContextName,
      "value":value,
      "EventName":eventName,
      "newValue":'SUSPEND USER'
    };
    this.pagesService.logUserEvent(data).subscribe(resp =>{
      //console.log(resp);
      
    },
            error => {
              console.log("Error >> ",ContextName.VIEW_MANAGE_USERS_PAGE,":", error);
            });
  }
}
