<div style="background-color: #F6F8FA;" (click)="mouseLeave()">
    <div class="p-d-flex p-jc-end">
        <p-messages key="myKey1" class="custom-alert-box" *ngIf="this.messageFlag"></p-messages>
    </div>
    <div class="p-col-fixed p-mt-1 p-ml-1 p-pr-0 custom-breadcrumb">
        <p-breadcrumb [model]="ccprimebreadcrumbList" [home]="homeiconccprimebreadcrumb">
        </p-breadcrumb>
    </div>
    <form class="layout-content custom-form-layout" [formGroup]="MyRequestsListingForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid custom-checkbox-color">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                <div class="p-grid p-mb-1">
                    <span class="p-col p-p-0">
                        <h5 class="custom-head1">My Requests</h5>
                    </span>
                </div>
                <div class="card custom-card p-mb-6">
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left p-mt-2 p-p-0">
                        <div class=" p-fluid   p-field    ">
                            <div class="p-fluid p-formgrid p-grid" style="padding-top:8px ;">
                                <div class="p-col-12 p-p-0">
                                    <div *ngIf="this.errorFlag" class="errorFlag">
                                        <label class="c-icons error-icon"></label><br>
                                        <label class="e-error">No Transactions found</label><br>
                                        <br>
                                    </div>
                                    <div class="ui-fluid p-formgrid p-grid" *ngIf="!this.errorFlag">
                                        <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                                            <div class=" p-fluid   p-field    ">
                                                <span class=" p-input-icon-left">
                                                    <input type="text" (keydown.enter)="$event.preventDefault()"
                                                        id="textField_id" name="textField_name"
                                                        formControlName="textField" pInputText placeholder="Search"
                                                        class="p-inputtext inputtextHeight custom-listings custom-inputtextHeight"
                                                        (input)="filterMethod($event.target.value)" />
                                                    <i aria-hidden="true" class="pi pi-search"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="p-col-12 p-sm-12 p-md-12 p-lg-2 p-xl-2 p-offset-undefined left">
                                        </div>
                                        <div class="p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6 p-offset-undefined p-right">
                                        </div>
                                        <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                                            <div class="  p-field    ">
                                                <div class="mobilefriendlytable" responsiveLayout="stack"
                                                    [ngClass]="{'content-section implementation': true}">
                                                    <p-table #tableid [value]="tableList" name="table"
                                                        [columns]="tablecols"
                                                        styleClass="p-datatable-customers custom-p-datatable-customers"
                                                        [rows]="10" [rowsPerPageOptions]="[10,25,50]"
                                                        selectionMode="Multiple" [paginator]="true" sortMode="single"
                                                        [scrollable]="true"
                                                        [globalFilterFields]="['transactionId','createdBy','createdDate','ciamAction','approvalStatus']">
                                                        <ng-template pTemplate="header">
                                                            <tr>
                                                                <th pSortableColumn="transactionId" scope="col"
                                                                    style="width: 20%">
                                                                    <div class="p-d-flex p-jc-between p-ai-center">
                                                                        Transaction ID
                                                                        <i class="p-sortable-column-icon icon-sort"
                                                                            field="transactionId"
                                                                            ariaLabel="Activate to sort"
                                                                            ariaLabelDesc="Activate to sort in descending order"
                                                                            ariaLabelAsc="Activate to sort in ascending order"></i>
                                                                        <p-columnFilter type="text"
                                                                            field="transactionId" display="menu"
                                                                            class="p-ml-auto"></p-columnFilter>
                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="ciamAction" scope="col"
                                                                    style="width: 25%;">
                                                                    <div class="p-d-flex p-jc-between p-ai-center">
                                                                        CIAM Action
                                                                        <i class="p-sortable-column-icon icon-sort"
                                                                            field="ciamAction"
                                                                            ariaLabel="Activate to sort"
                                                                            ariaLabelDesc="Activate to sort in descending order"
                                                                            ariaLabelAsc="Activate to sort in ascending order"></i>
                                                                        <p-columnFilter type="text" field="ciamAction"
                                                                            display="menu"
                                                                            class="p-ml-auto"></p-columnFilter>
                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="approvalStatus" scope="col"
                                                                    style="width: 20%;">
                                                                    <div class="p-d-flex p-jc-between p-ai-center">
                                                                        Approval Status
                                                                        <i class="p-sortable-column-icon icon-sort"
                                                                            field="approvalStatus"
                                                                            ariaLabel="Activate to sort"
                                                                            ariaLabelDesc="Activate to sort in descending order"
                                                                            ariaLabelAsc="Activate to sort in ascending order"></i>
                                                                        <p-columnFilter field="approvalStatus"
                                                                            display="menu"
                                                                            class="p-ml-auto"></p-columnFilter>
                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="createdDate" scope="col"
                                                                    style="width: 19%;">
                                                                    <div class="p-d-flex p-jc-between p-ai-center">
                                                                        Created On
                                                                        <i class="p-sortable-column-icon icon-sort"
                                                                            field="createdDate"
                                                                            ariaLabel="Activate to sort"
                                                                            ariaLabelDesc="Activate to sort in descending order"
                                                                            ariaLabelAsc="Activate to sort in ascending order"></i>
                                                                        <p-columnFilter field="createdDate"
                                                                            display="menu"
                                                                            class="p-ml-auto"></p-columnFilter>
                                                                    </div>
                                                                </th>
                                                                <th id="actionButton" style="width: 10%;">
                                                                    <div class="p-d-flex p-jc-between p-ai-center">
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-rowData let-index="rowIndex">
                                                            <tr (mouseenter)="hoverIndex=index;highlight(index)"
                                                                (mouseleave)="hoverIndex=index;highlight(index)"
                                                                [pSelectableRow]="rowData">
                                                                <td style="width: 20%">
                                                                    <span pTooltip="{{rowData.transactionId}}"
                                                                        *ngIf="rowData.transactionId?.length >= 20"
                                                                        tooltipPosition="bottom">
                                                                        {{rowData.transactionId?.substring(0,20) +
                                                                        "..." }}
                                                                    </span>
                                                                    <span *ngIf="rowData.transactionId?.length < 20">
                                                                        {{rowData.transactionId}}
                                                                    </span>
                                                                </td>
                                                                <td class="emailRow" style="width: 25%;">
                                                                    <span pTooltip="{{rowData.ciamAction}}"
                                                                        *ngIf="rowData.ciamAction?.length >= 35"
                                                                        tooltipPosition="bottom">
                                                                        {{rowData.ciamAction?.substring(0,35) + "..." }}
                                                                    </span>
                                                                    <span *ngIf="rowData.ciamAction?.length < 35">
                                                                        {{rowData.ciamAction}}
                                                                    </span>
                                                                </td>
                                                                <td class="button-status" style="width: 20%;">
                                                                    <div class="btnwrap">
                                                                        <button pButton type="button"
                                                                            label="{{rowData.approvalStatus }}"
                                                                            style="background: transparent;"
                                                                            [style.color]=" rowData.approvalStatus === 'Rejected' ? '#D6002A' :  rowData.approvalStatus === 'Approved' ? '#12813F' : rowData.approvalStatus === 'Pending'  ? '#CA8208' : 'black'"
                                                                            [style.border]="rowData.approvalStatus === 'Rejected' ? '1px solid #F7CCD4' : rowData.approvalStatus === 'Approved' ? '1px solid #CFE6D8' : rowData.approvalStatus === 'Pending' ? '1px solid #FDECCE' : '1px solid black'"
                                                                            class=" p-button-outlined  statusRow">
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                                <td style="width: 19%;font-weight:400;color:#505870">
                                                                    {{rowData.createdDate | dateFormatter | date: 'MMM
                                                                    d, y, h:mm:ss a'}}
                                                                </td>
                                                                <td style="width: 10%;">
                                                                    <div class="button-details">
                                                                        <button pButton tooltipPosition="top"
                                                                            pTooltip="User Imported File"
                                                                            class="p-button-strict p-button-info p-button-brand2"
                                                                            (click)="activeItem=rowData;downloadReport(rowData);"
                                                                            *ngIf="rowData.ciamAction=='Bulk Action-Import User'">
                                                                            <i class="icon-download"></i>
                                                                        </button>
                                                                        <button pButton tooltipPosition="bottom"
                                                                            pTooltip="View transaction details"
                                                                            style="width: 24px;height: 24px;padding: 0px;"
                                                                            icon="d-icons view-icon"
                                                                            class="background-transparent"
                                                                            (click)="activeItem=rowData;viewTransactions();">
                                                                        </button>
                                                                        <button pButton tooltipPosition="top"
                                                                            pTooltip="Cancel Request"
                                                                            style="width: 24px;height: 24px;padding: 0px;"
                                                                            icon="d-icons reject-icon"
                                                                            class="background-transparent"
                                                                            (click)="activeItem=rowData;cancelRequest();"
                                                                            *ngIf="rowData.approvalStatus === 'Pending'">
                                                                        </button>

                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="paginatorright" let-state><span
                                                                class="p-pl-4">Total
                                                                Records: {{state.totalRecords}}</span></ng-template>
                                                        <ng-template pTemplate="emptymessage">
                                                            <tr>
                                                                <td colspan="7" class="p-p-0 p-text-center"> <p-card>
                                                                        <div style="margin-top: 5%;height: 70px;">No
                                                                            matching records found</div>
                                                                    </p-card> </td>
                                                            </tr>
                                                        </ng-template>
                                                    </p-table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>