<div class="layout-content custom-container">
    <form [formGroup]="EditApplicationRoleForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 left">
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                <div class=" p-fluid   p-field    ">
                    <label [ngClass]="{'field-required': EditApplicationRoleForm.get('textField')?.errors?.required}">
                        Application Role Name
                        <span class='invalid-feedback-astrik'>*</span></label>
                        <input [(ngModel)]="this.appName" type="text" id="rolename_id" name="textField_name"
                            formControlName="textField" maxlength="50" placeholder="Application role name"
                            class="p-inputtext" (keyup)="this.enableBtn1=false;this.nameFlag=false;"/>
                    <div class="p-error"
                        *ngIf="EditApplicationRoleForm.get('textField')!.invalid && (EditApplicationRoleForm.get('textField')!.dirty || EditApplicationRoleForm.get('textField')!.touched)">
                        <div *ngIf="EditApplicationRoleForm.get('textField')?.errors?.required">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium class="p-error">Role name is required.</medium>
                        </div>
                    </div>
                    <div class="p-error" *ngIf="this.nameFlag">
                                <label class="alert-icon alert-circle-icon"></label>
                                <medium class="p-error">{{this.errorMsg}}
                                </medium>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                <div class=" p-fluid   p-field    ">
                    <label>
                        Description
                    </label><textarea [(ngModel)]="this.description" pInputTextarea id="textArea_id"
                        name="textArea_name" formControlName="textarea" rows="3" placeholder="Add description"
                        maxlength="200" (keyup)="this.enableBtn1=false;"></textarea>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined p-left p-mb-2">
                <div class="p-field-checkbox p-checkbox-sm p-mt-1">
                    <p-checkbox [(ngModel)]="this.defaultRole" name="group1" binary="true" formControlName="checkbox"
                        inputId="binary" (onChange)="this.enableBtn1=false;">
                    </p-checkbox>
                    <label for="binary" style="margin-top: 1%;">Default Role</label>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-8 p-xl-8 p-offset-undefined p-right p-mb-2">
                <div class="btnwrap p-mr-3">
                    <button pButton type="button" class="p-button-brand2" (click)="onClose(EditApplicationRoleForm);"
                        label="Close">
                    </button>
                </div>
                <div class="btnwrap">
                    <button pButton class="p-button-primary" id="save" type="button" (click)="EditApplicationRole();"
                        [disabled]="enableBtn1 || !EditApplicationRoleForm.valid || nameFlag" label="Save">
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>