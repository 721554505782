import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PagesService } from '../pages.service';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
@Component({
  selector: 'jhi-CreateHostAttribute',
  templateUrl: './CreateHostAttribute.component.html',
  styleUrls: ['./CreateHostAttribute.component.scss'],
  providers: [DialogService],
})
export class CreateHostAttributeComponent implements OnInit {
  CreateHostAttributeForm = this.fb.group({
    select: [null, [Validators.required]],
    textField: [null, [Validators.required]],
    select1: [[], []],
    selectSwitch: [null, []],
    checkbox: [null, []],
    description: [null, []],
    AttributeId: [null, []],
  });
  selectSwitchList: any[] = [{ 'label': "Public", 'value': 'false' }, { 'label': "Private", 'value': 'true' },];
  defaultselect_selectSwitch: any;
  pageloadvalues: any;
  selectList: any[] = [];
  select1List: any[] = [];
  appId: any;
  enableBtn = false;
  nameFlag = false;
  resourceName: any;
  errorMessage = "";
  validationFlag = false;
  checked: boolean = false;
  idFlag: boolean;
  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.CreateHostAttributeForm.patchValue(JSON.parse(data));
    }
    this.setDefaultValue_selectSwitch('true');
  }
  ngOnInit(): void {
    if(this.pagesService.attributeTreeEnable){
      this.CreateHostAttributeForm.controls['AttributeId'].setValidators(Validators.required);
    }
    this.appId = this.config?.data?.id;
    this.getTypeList();
    this.getAttributeGroup();
  }

  onClose($event: UntypedFormGroup): void {
    this.ref.close();
  }

  colorChange() {
    document.getElementById('textField_id1')!.style.borderColor = 'grey';
  }

  colorAttributeChange() {
    document.getElementById('AttributeId_id1')!.style.borderColor = 'grey';
  }


  //get type list API -dropdown
  getTypeList(): void {
    this.selectList = [];
    this.pagesService.getAttributeTypeListing(this.appId).subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.attributeTypeName;
          data.value = element.id;
          this.selectList.push(data);
        });
        this.selectList = [...this.selectList];
      }
    });
  }

  //get attribute group list API -dropdown
  getAttributeGroup(): void {
    this.select1List = [];
    const reqBody = {};
    reqBody["applicationId"] = this.appId;
    this.pagesService.getAttributeGroupByHostApplication(reqBody).subscribe((results: any) => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.select1List.push(data);
        });
        this.select1List = [...this.select1List];
      }
    });
  }

  //create attribute resource
  CreateHostAttributeResource(): void {
    const reqBody = {};
    if (this.checked) {
      reqBody['isDefault'] = this.checked;
    }
    else {
      reqBody['isDefault'] = false;
    }
    let isPrivate = this.CreateHostAttributeForm.value.selectSwitch;
    if (isPrivate == "true") {
      reqBody['isPrivate'] = true;
    }
    else {
      reqBody['isPrivate'] = false;
    }
    reqBody['attributeTypeId'] = this.CreateHostAttributeForm.value.select;
    reqBody['attributeResourceName'] = this.CreateHostAttributeForm.value.textField;
    reqBody['attributeGroupList'] = this.CreateHostAttributeForm.value.select1;
    reqBody['applicationId'] = this.appId;
    const formValues = reqBody;
    this.pagesService.onSaveCreateHostAttribute(formValues).subscribe(
      {
        next: results => {
          this.ref.close("Attribute Created Successfully");
        },
        error: error => {
          if (error.error.error?.messageCode == "CIAM 105") {
            if (error.error?.error?.messageCode == "CIAM 105") {
              this.validationFlag = false;
              this.nameFlag = true;
              this.enableBtn = true;
              document.getElementById('textField_id1')!.style.borderColor = 'red';
            }
          }
          else if (error.error?.error?.messageCode == "[CIAM 115]") {
            this.validationFlag = true;
            this.nameFlag = false;
            this.enableBtn = true;
            document.getElementById('textField_id1')!.style.borderColor = 'red';
            this.errorMessage = error.error?.error?.reason;
          }
          else {
            this.ref.close("Attribute Creation Failed");
          }
        }
      });
  }

  CreatehierarchyHostAttributeResource(): void {
    const reqBody = {};
    if (this.checked) {
      reqBody['isDefault'] = this.checked;
    }
    else {
      reqBody['isDefault'] = false;
    }
    let isPrivate = this.CreateHostAttributeForm.value.selectSwitch;
    if (isPrivate == "true") {
      reqBody['isPrivate'] = true;
    }
    else {
      reqBody['isPrivate'] = false;
    }
    reqBody['attributeTypeId'] = this.CreateHostAttributeForm.value.select;
    reqBody['attributeResourceName'] = this.CreateHostAttributeForm.value.textField;
    reqBody['attributeGroupList'] = this.CreateHostAttributeForm.value.select1;
    reqBody['applicationId'] = this.appId;
    reqBody['attributeResourceId'] = this.CreateHostAttributeForm.value.AttributeId;
    reqBody['parentId'] = 0;
    reqBody['description']=this.CreateHostAttributeForm.value.description;
    const formValues = reqBody;
    this.pagesService.onSaveCreateHostAttributeHierarchy(formValues).subscribe(
      {
        next: results => {
          this.ref.close("Attribute Created Successfully");
        },
        error: error => {
          if (error.error.error?.messageCode == "CIAM 105") {
            if (error.error?.error?.reason == "Attribute resource name already exist") {
              this.validationFlag = false;
              this.nameFlag = true;
              this.enableBtn = true;
              document.getElementById('textField_id1')!.style.borderColor = 'red';
            }
            else if (error.error?.error?.reason == "Attribute resource id already exist") {
              this.validationFlag = false;
              this.idFlag = true;
              this.enableBtn = true;
              document.getElementById('AttributeId_id1')!.style.borderColor = 'red';
            }
          }
          else if (error.error?.error?.messageCode == "[CIAM 115]") {
            this.validationFlag = true;
            this.nameFlag = false;
            this.enableBtn = true;
            document.getElementById('textField_id1')!.style.borderColor = 'red';
            this.errorMessage = error.error?.error?.reason;
          }
          else {
            this.ref.close("Attribute Creation Failed");
          }
        }
      });
  }

  //Check availability of attribute name
  // onSaveCheckAttributeName($event: UntypedFormGroup): void {
  //   const reqBody = {};
  //   reqBody['attributeName'] = this.CreateHostAttributeForm.value?.textField;
  //   const formData = reqBody;
  //   this.pagesService.onCheckAttributeName(formData).subscribe(
  //     results => {
  //       if (results?.error?.code == 400 || results?.success?.code == 400) {
  //         if (results?.error?.messageCode == "CIAM 105") {
  //           this.validationFlag = false;
  //           this.nameFlag = true;
  //           this.enableBtn = true;
  //           document.getElementById('textField_id1')!.style.borderColor = 'red';
  //         }
  //       } else if (results.success.code == 200) {
  //         this.validationFlag = false;
  //         this.nameFlag = false;
  //         this.enableBtn = false;
  //         document.getElementById('textField_id1')!.style.borderColor = 'grey';
  //         this.CreateHostAttributeResource();
  //       }
  //     },
  //     error => {
  //       if (error.error?.error?.messageCode == "[CIAM 115]") {
  //         this.validationFlag = true;
  //         this.nameFlag = false;
  //         this.enableBtn = true;
  //         document.getElementById('textField_id1')!.style.borderColor = 'red';
  //         this.errorMessage = error.error?.error?.reason;
  //       }
  //     }
  //   );
  // }

  // CheckAttributeName($event: UntypedFormGroup): void {
  //   const reqBody = {};
  //   reqBody['attributeName'] = this.CreateHostAttributeForm.value?.textField;
  //   const formData = reqBody;
  //   this.pagesService.onCheckAttributeName(formData).subscribe(
  //     results => {
  //       if (results?.error?.code == 400 || results?.success?.code == 400) {
  //         if (results?.error?.messageCode == "CIAM 105") {
  //           this.validationFlag = false;
  //           this.nameFlag = true;
  //           this.enableBtn = true;
  //           document.getElementById('textField_id1')!.style.borderColor = 'red';
  //         }
  //       } else if (results.success.code == 200) {
  //         this.validationFlag = false;
  //         this.nameFlag = false;
  //         this.enableBtn = false;
  //         document.getElementById('textField_id1')!.style.borderColor = 'grey';
  //       }
  //     },
  //     error => {
  //       if (error.error?.error?.messageCode == "[CIAM 115]") {
  //         this.validationFlag = true;
  //         this.nameFlag = false;
  //         this.enableBtn = true;
  //         document.getElementById('textField_id1')!.style.borderColor = 'red';
  //         this.errorMessage = error.error?.error?.reason;
  //       }
  //     }
  //   );
  // }

  setDefaultValue_selectSwitch(value?: any) {
    if (this.selectSwitchList.length && value) {
      this.defaultselect_selectSwitch = value;
      return this.defaultselect_selectSwitch;
    }
    this.defaultselect_selectSwitch = this.selectSwitchList[0]?.value
    return this.defaultselect_selectSwitch;
  }

}