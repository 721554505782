import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PagesService } from '../pages.service';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
@Component({
  selector: 'jhi-edithostapplicationresource',
  templateUrl: './EditHostApplicationResource.component.html',
  styleUrls: ['./EditHostApplicationResource.component.scss'],
  providers: [MessageService, DialogService],
})
export class EditHostApplicationResourceComponent implements OnInit {
  EditHostApplicationResourceForm = this.fb.group({
    select: [null, [Validators.required]],
    select1: [null, [Validators.required]],
    textField2: [null, [Validators.required]],
    textField1: [null, [Validators.required]],
    select2: [null, [Validators.required]],
    applicationRoleRequired: [null, []]
  });
  pageloadvalues: any;
  selectList: any[] = [];
  select1List: any[] = [];
  select2List: any[] = [];
  appId: any;
  componentId: any;
  activeItem: any;
  met: any;
  desc: any;
  ep: any;
  app: any;
  com: any;
  endpointID: any;
  eId: any;
  enableBtn1 = true;
  enableBtn = true;
  nameFlag = false;
  resourceName: any;
  oldResourceName: any;
  oldEndpointUrl: any;
  oldComponent: any;
  oldMethod: any;
  applicationRoleRequired: any;
  oldapplicationRoleRequired: any;
  prevData: any = {}; 

  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    private messageService: MessageService,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.EditHostApplicationResourceForm.patchValue(JSON.parse(data));
    }

  }
  ngOnInit(): void {
    this.getApplicationList();
    this.getMethod();
    this.oldResourceName = this.config?.data?.ep;
    this.oldEndpointUrl = this.config?.data?.desc;
    this.oldComponent = this.com = this.config?.data?.comId;
    this.oldMethod = this.met = this.config?.data?.method1;
    this.oldapplicationRoleRequired = this.config?.data?.roleRequired;
    this.applicationRoleRequired = this.config?.data?.roleRequired;
    this.ep = this.config?.data?.ep;
    this.desc = this.config?.data?.desc;
    this.met = this.config?.data?.method1;
    this.app = this.config?.data?.appId;
    this.com = this.config?.data?.comId;
    this.getComponent();
    this.EditHostApplicationResourceForm.get('select').disable();
  }
  onClose($event: UntypedFormGroup): void {
    this.ref.close();
  }

  getApplicationList(): void {
    this.pagesService.getApplicationVersion().subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.selectList.push(data);
        });
        this.selectList = [...this.selectList];
      }
    });

  }

  getComponent(): void {
    this.appId = this.app;
    const compData = { applicationId: this.appId };
    this.pagesService.getHostComponentEndpoint(compData).subscribe(results => {
      const responseData = results.success.result;
      this.select1List = [];
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.select1List.push(data);
        });
        this.select1List = [...this.select1List];
      }
    });
  }

  colorChange() {
    document.getElementById('textField2_id')!.style.borderColor = 'grey';
  }

  onSaveCheckResourceName(): void {
    if (this.oldResourceName != this.EditHostApplicationResourceForm.value?.textField2) {
      this.appId = this.app;
      this.resourceName = this.EditHostApplicationResourceForm.value?.textField2;
      const data = { appId: this.appId, resourceName: this.resourceName };
      this.pagesService.checkAvailabilityHostAppResourceName(data).subscribe(
        results => {
          if (results?.error?.code == 400 || results?.success?.code == 400) {
            this.nameFlag = true;
            document.getElementById('textField2_id')!.style.borderColor = 'red';
          } else if (results.success.code == 200) {
            this.nameFlag = false;
            document.getElementById('textField2_id')!.style.borderColor = 'grey';
            this.onSave();
          }
        }
      );
    }
    else {
      this.onSave();
    }

  }


  getMethod(): void {
    this.pagesService.getMethodApplication().subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.label;
          this.select2List.push(data);
        });
        this.select2List = [...this.select2List];
      }
    });
  }
  onSave() {
    this.endpointID = this.config?.data?.endID;
    const reqBody = {};
    reqBody['applicationId'] = this.appId;
    reqBody['componentId'] = this.com;
    reqBody['endpointId'] = this.endpointID;
    reqBody['applicationRoleRequired'] = this.EditHostApplicationResourceForm.value.applicationRoleRequired;
    if (this.oldResourceName != this.EditHostApplicationResourceForm.value.textField2) {
      reqBody['resourceName'] = this.EditHostApplicationResourceForm.value.textField2;
    }
    if (this.oldEndpointUrl != this.EditHostApplicationResourceForm.value.textField1) {
      reqBody['endPointUrl'] = this.EditHostApplicationResourceForm.value.textField1;
    }
    if (this.oldComponent != this.EditHostApplicationResourceForm.value.select1) {
      reqBody['componentId'] = this.EditHostApplicationResourceForm.value.select1;
    }
    if (this.oldMethod != this.EditHostApplicationResourceForm.value.select2) {
      reqBody['method'] = this.EditHostApplicationResourceForm.value.select2;
    }
    this.prevData = {
      oldResourceName: this.oldResourceName,
      oldEndpointUrl: this.oldEndpointUrl,
      oldComponent: this.oldComponent,
      oldMethod:this.oldMethod,
      oldapplicationRoleRequired:this.oldapplicationRoleRequired
    };
    reqBody['prevData']=this.prevData;
    const formValues = reqBody;
    this.pagesService.editHostApplicationEndpoint(formValues).subscribe(
      {
        next: results => {
          this.ref.close("Resource Edited Successfully");
        },
        error: error => {
          if (error.error.error?.messageCode == "CIAM 105") {
            this.ref.close("Resource name already exist");
          }
          else {
            this.ref.close("Resource Edit Failed");
          }
        }
      });
  }
}