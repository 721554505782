import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

export const validateAllFormFields = (formGroup: UntypedFormGroup): void => {
  Object.keys(formGroup.controls).forEach(field => {
    const control = formGroup.get(field);
    if (control instanceof UntypedFormControl) {
      control.markAsTouched({ onlySelf: true });
    } else if (control instanceof UntypedFormGroup) {
      validateAllFormFields(control);
    }
  });
};

export const atLeastOneSelectBoxRequired = (): any => {
  return (control: UntypedFormGroup): any => {
    const controls = control.controls;
    if (controls) {
      const theOne = Object.keys(controls).findIndex(key => controls[key].value !== '' && controls[key].value);
      if (theOne === -1) {
        return {
          atLeastOneRequired: {
            text: 'At least one should be selected',
          },
        };
      }
    }
  };
};

export const addValidators = (validate: any): any => {
  const listOfValidators = [];
  if (validate.required) {
    if (validate.type === 'ccprimecheckbox') {
      listOfValidators.push(Validators.requiredTrue);
    } else if (validate.type === 'ccprimeemail') {
      listOfValidators.push(Validators.required);
      listOfValidators.push(Validators.email);
    } else {
      listOfValidators.push(Validators.required);
    }
    if (validate.minLength && validate.minLength !== 0 && validate.minLength !== 0) {
      listOfValidators.push(Validators.minLength(Number(validate.minLength)));
    }
    if (validate.maxLength && validate.maxLength !== 0 && validate.maxLength !== 0) {
      listOfValidators.push(Validators.maxLength(Number(validate.maxLength)));
    }
    if (validate.min && validate.min !== '') {
      listOfValidators.push(Validators.min(Number(validate.min)));
    }
    if (validate.max && validate.max !== '') {
      listOfValidators.push(Validators.max(Number(validate.max)));
    }
    if (validate.pattern && validate.pattern.trim() !== '') {
      listOfValidators.push(Validators.pattern(validate.pattern));
    }
  }
  return listOfValidators;
};
