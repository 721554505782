<ngx-spinner size="medium" type="ball-grid-beat">
	<p style="color: white;margin-top: 100px;">
		Loading...
	</p>
</ngx-spinner>
<section class="layout-wrapper ciam-ui">
	<div class="layout-main" style="overflow: hidden;">
		<div class="sidebarMenu" id="sidebar" [ngClass]="{ 'is-collapsed': columnstoggle }">
			<button pButton type="button" label="" aria-label="false" (click)="collapse()"
				icon="pi pi-angle-double-left" class="menu-button" *ngIf="!columnstoggle"
				style="margin: auto;"></button>
			<button pButton type="button" label="" aria-label='false' (click)='collapse()' class="menubutton"
				pTooltip="Menu" icon="c-icons menu-icon" *ngIf="columnstoggle"
				style="margin: 0 0 8px 0px;width: 47px;"></button>
			<p-panelMenu class="custom_sidebar1" [model]="items" [multiple]="false"></p-panelMenu>
		</div>
		<div class="layout-content-wrapper expand" id="content"
			style="overflow-y:visible; overflow-x:hidden;padding-bottom:5%;background-color: #f6f8fa;">
			<p style="background: transparent; color: transparent;position: absolute;">Release v0.8.5</p>
			<router-outlet></router-outlet>
			<router-outlet name="popup"></router-outlet>
		</div>
	</div>
</section>