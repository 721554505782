import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PagesService } from '../pages.service';
import { Message, MessageService } from 'primeng/api';
import { MultiSelect } from 'primeng/multiselect';
import { Dropdown } from 'primeng/dropdown';
import { AddAsSubscriptionComponent } from '../AddAsSubscription/AddAsSubscription.component';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'jhi-plandetails',
  templateUrl: './EditPlan.component.html',
  styleUrls: ['./EditPlan.component.scss'],
  providers: [MessageService, DialogService]
})
export class EditPlanComponent implements OnInit {
  defaultValue: boolean = false;
  pageloadvalues: any;
  paperColor: any;
  plotColor: any;
  layoutTextColor: any;
  agTheme: any;
  tab1: boolean = false;
  tab2: boolean = false;
  activeIndex1: number = 0;
  activeIndex2: number = 0;
  clicked1 = true;
  clicked2 = false;
  planAttributeList: any[] = [];
  planId: any;
  changedValueFlag = false;
  ref!: DynamicDialogRef;
  ccprimebreadcrumbList = [
    { label: `Application plans`, routerLink: ['/pages/planListing'] },
    { label: `Edit plan`, routerLink: ['/pages/EditPlan'] },
  ]
  homeiconccprimebreadcrumb = { icon: 'pi pi-home', routerLink: ['/'] };
  clipPropertycolumns_2_1_ccprimeheading_1: string = '';
  selectSwitchList: any[] = [{ 'label': "Attribute Type", 'value': 'attributeType' }, { 'label': "Attribute Group", 'value': 'attributeGroup' },];
  defaultselect_selectSwitch: any;
  attributeTypeList: any[];
  clipPropertyplanAtrributes: string = '';
  clipPropertypriceUnitValue: string = '';

  clipPropertyperUnitType: string = '';
  messageFlag: boolean;
  messageFlag1: boolean = false;
  nameFlag: boolean = false;
  planAttributeDto = [];
  applicationRoleLabelValue: MultiSelect;
  dd: Dropdown;
  PlanDetailsForm = this.fb.group({
    columns_2_1_ccprimeheading_1: [null],
    planName: [null, [Validators.required]],
    planDescription: [null],
    application: [null, [Validators.required]],
    selectSwitch: [null],
    attributeGroup: [null, []],
    attributeType: [null, []],
    attribute: [null, []],
    featureDescription: [null],
    applicationRoles: [null],
    featureName: [null],
    featureId: [null],
  }
  );
  PlanAttributeForm = this.fb.group({
    deploymentType: [null, []],
    storageType: [null, []],
    tenantType: [null, []],
    deploymentSubType: [null, []],
    accountNumber: [null, []],
    region: [null, []],
    role: [null, []],
    vpcName: [null, []],
    subnet: [null, []],
    clusterName: [null, []],
    nodeFamily: [null, []],
    efsPath: [null, []],
    s3ArnRole: [null, []],
    s3Secret: [null, []],
    s3Key: [null, []],
    s3SecretVaultPath: [null, []],
    redisUrl: [null, []],
    redisVaultPath: [null, []],
    redisUsername: [null, []],
    redisPassword: [null, []],
    rdsType: [null, []],
    rdsHost: [null, []],
    rdsPort: [null, []],
    rdsUsername: [null, []],
    rdsPassword: [null, []],
    rdsVaultPath: [null, []],
    rdsFamily: [null, []],
    enablePodAutoScalling: [null, []],
    efsStyle: [null, []],
    redisStyle: [null, []],
    s3Style: [null, []],
    rdsStyle: [null, []],
    rdsDBName: [null, []]
  }
  );

  deploymentTypeList: any[] = [{ 'label': "Bridge", 'value': 'Bridge' }, { 'label': "Full Stack Pool", 'value': 'FULLSTACK_POOL' }, { 'label': "Full Stack Silo", 'value': 'FULLSTACK_SILO' }];
  deploymentSubTypeList: any[] = [{ 'label': "Siloed (Dedicated)", 'value': 'SILOED_VPC_CLUSTER' }, { 'label': "Pooled (Shared)", 'value': 'POOLED_VPC_CLUSTER' }];
  tenantTypeList: any[] = [{ 'label': "Siloed (Dedicated)", 'value': 'Siloed' }, { 'label': "Pooled (Shared)", 'value': 'Pooled' }];
  storageTypeList: any[] = [{ 'label': "Cluster Per Tenant", 'value': 'Cluster Per Tenant' }, { 'label': "Name Space Per Tenant", 'value': 'Name Space Per Tenant' }];
  regionList: any[] = [{ 'label': "Cluster Per Tenant", 'value': 'Cluster Per Tenant' }, { 'label': "Name Space Per Tenant", 'value': 'Name Space Per Tenant' }];
  nodeFamilyList: any[] = [{ 'label': "C5 Large", 'value': 'c5.large' }];
  selectSwitchListSaas: any[] = [{ 'label': "Internal", 'value': 'false' }, { 'label': "External", 'value': 'true' },];
  rdsTypeList: any[] = [{ 'label': "My SQL", 'value': 'mysql' }, { 'label': "Oracle", 'value': 'oracle' },];
  rdsFamilyList: any[] = [{ 'label': "DB M5 Large", 'value': 'db.m5.large' }];
  styleList: any[] = [{ 'label': "Shared", 'value': 'shared' }, { 'label': "Dedicated", 'value': 'dedicated' },];
  clicked: boolean = false;
  selectList: any[];
  selectList1: any[];
  applicationId: any;
  applicationRoleList: any[];
  applicationLabel: any;
  applicationValue: any;
  attributeTypeValue: any;
  attributeTypeLabel: any;
  applicationRoles: any;
  applicationRole: any[];
  applicationRoleLabel: any[];
  attributeList: any[];
  attributeGroupList: any[];
  planList: {
    selectType: any;
    id: any;
    application: any;
    applicationRole: any;
    attributeType: any;
    attributeTypeName: any;
    attribute: any; attributeGroup: any; featureDescription: any;
    featureName: any;
  }[] = [];
  currentIndex: number = -1;
  common: any = [];
  duplicityFlagApplicationRole: boolean = false;
  duplicityFlagAttributeGroup: boolean = false;
  duplicityFlagAttribute: boolean = false;
  duplicityFlagfeatureName: boolean = false;
  featureReqBody: any;
  defaultFlag: boolean = true;
  firstSave: boolean = false;
  enableBtn1 = false;
  msgs1: Message[];
  data: any;
  planAttrFlag = true;
  enablePlanButton: boolean = true;
  numberFlag = false;
  enableAddSusbcriptionBtn = false;
  planList1: { selectType: any; id: any; application: any; applicationRole: any; attributeType: any; attributeTypeName: any; attribute: any; attributeGroup: any; featureDescription: any; featureName: any; }[];
  editDisable: boolean = false;
  row: any;
  appValue: any[] = [];
  duplicityApplication: boolean = false;
  deployDto: any[] = [];
  deploymentType: any;
  deploymentSubType: any;
  accountNumber: any;
  region: any;
  role: any;
  vpcName: any;
  efsPath: any;
  subnet: any;
  clusterName: any;
  nodeFamily: any;
  enablePodAutoScalling: any;
  efsStyle: any;
  s3Style: any;
  s3ArnRole: any;
  s3Secret: any;
  s3Key: any;
  s3SecretVaultPath: any;
  redisStyle: any;
  redisUrl: any;
  redisVaultPath: any;
  redisUsername: any;
  redisPassword: any;
  rdsStyle: any;
  rdsType: any;
  rdsHost: any;
  rdsPort: any;
  rdsUsername: any;
  rdsPassword: any;
  rdsDBName: any;
  rdsVaultPath: any;
  rdsFamily: any;
  saasEnable: string;
  constructor(private router: Router, protected activatedRoute: ActivatedRoute, private fb: UntypedFormBuilder, public pagesService: PagesService, private messageService: MessageService, public dialogService: DialogService) {
    const navigation = this.router.getCurrentNavigation();
    this.data = navigation?.extras?.state?.data;
    if (this.data) {
      this.PlanDetailsForm.patchValue(JSON.parse(this.data));
      this.PlanAttributeForm.patchValue(JSON.parse(this.data));
      this.setPageProperties(JSON.parse(this.data));
    }
    this.setDefaultValue_selectSwitch('attributeType');

  }

  ngOnInit(): void {
    this.saasEnable = environment.IS_SAAS_ENABLED + "";
    console.log("this is the form value");
    console.log(this.PlanDetailsForm);
    this.getApplicationVersionList();
    this.getPlanList();
    this.getPlanAttributeValueList();
    this.checkStepValidations();
    this.PlanDetailsForm.get('planName').disable();
    this.PlanDetailsForm.get('attribute').disable();
    this.PlanDetailsForm.controls['attributeGroup'].clearValidators();
    this.PlanDetailsForm.controls['attributeGroup'].reset();
    this.PlanDetailsForm.controls['attributeGroup'].updateValueAndValidity();
    this.PlanDetailsForm.controls['attributeType'].updateValueAndValidity();
    this.data = JSON.parse(this.data);
    this.getDeploymentModel();
    if (this.saasEnable == 'true') {
      this.PlanAttributeForm.controls['deploymentType'].setValidators(Validators.required);
      this.PlanAttributeForm.controls['deploymentSubType'].setValidators(Validators.required);
      this.PlanAttributeForm.controls['nodeFamily'].setValidators(Validators.required);
      this.PlanAttributeForm.controls['rdsType'].setValidators(Validators.required);
      this.PlanAttributeForm.controls['rdsHost'].setValidators(Validators.required);
      this.PlanAttributeForm.controls['rdsPort'].setValidators(Validators.required);
      this.PlanAttributeForm.controls['rdsUsername'].setValidators(Validators.required);
      this.PlanAttributeForm.controls['rdsPassword'].setValidators(Validators.required);
      this.PlanAttributeForm.controls['rdsFamily'].setValidators(Validators.required);
      this.PlanAttributeForm.controls['efsStyle'].setValidators(Validators.required);
      this.PlanAttributeForm.controls['redisStyle'].setValidators(Validators.required);
      this.PlanAttributeForm.controls['s3Style'].setValidators(Validators.required);
      this.PlanAttributeForm.controls['rdsStyle'].setValidators(Validators.required);
      this.PlanAttributeForm.controls['rdsDBName'].setValidators(Validators.required);
      console.log(this.PlanAttributeForm);
    }
    else {
      this.enableBtn1 = true;
    }
    console.log(this.PlanAttributeForm);
    console.log(this.enableBtn1);
  }

  checkStepValidations() {
    console.log(this.PlanDetailsForm);
    this.editDisable = false;
    this.duplicityFlagfeatureName = false;
    if (this.PlanDetailsForm.value.attributeType != null) {
      this.PlanDetailsForm.get('attribute').enable();
    }
  }

  getApplicationVersionList(): void {
    this.selectList = [];
    this.pagesService.getApplicationVersion().subscribe({
      next: results => {
        const responseData = results.success.result;
        if (responseData && responseData.length > 0) {
          responseData.forEach((element: any = {}) => {
            const data = {
              applicationName: null,
              applicationId: null
            };
            data.applicationName = element.label;
            data.applicationId = element.value;
            this.selectList.push(data);
          });
          this.selectList = [...this.selectList];
        }
      },
    });
  }
  getPlanList(): void {
    this.planList = [];
    this.pagesService.getPlanDetails(this.planId).subscribe(results => {
      const responseData = results.success.result;
      let pagePropBind = {
        planName: results?.success?.result?.planName,
        planDescription: results?.success?.result?.planDescription,
        application: this.data.applicationList
      };
      this.getApplicationRoleList(this.data.applicationList.applicationId);
      this.getApplicationAttributeGroupList(this.data.applicationList.applicationId);
      this.checkStepValidations();
      const planFeature = responseData.features;
      this.PlanDetailsForm.patchValue(pagePropBind);
      if (planFeature && planFeature.length > 0) {
        planFeature.forEach((element: any = {}) => {
          const data = {
            id: null,
            selectType: null,
            application: null,
            applicationRole: [],
            attributeType: null,
            attributeTypeName: null,
            attribute: [],
            attributeGroup: [],
            featureDescription: null,
            featureName: null

          };

          const applicationconst = {
            applicationName: element?.applicationName,
            applicationId: element?.applicationId
          }
          data.id = element?.id;
          data.application = applicationconst;

          data.attributeTypeName = element?.attributeTypeName;
          data.attributeType = element?.attributeType;
          data.featureDescription = element?.featureDescription;
          data.featureName = element?.featureName;

          const attributeGroupConst = element?.attributeGroups;
          if (attributeGroupConst.length > 0) {
            attributeGroupConst.forEach((element1: any = {}) => {
              const data1 =
              {
                attributeGroupName: null,
                attributeGroupId: null,
                featureAttributeGroupId: null
              }
              data1.featureAttributeGroupId = element1?.featureAttributeGroupId;
              data1.attributeGroupName = element1?.attributeGroupName;
              data1.attributeGroupId = element1?.attributeGroupId;
              data?.attributeGroup.push(data1);

            });
            data.attributeGroup = [...data?.attributeGroup]; //NOSONAR
          }
          const attributeConst = element?.attributes;
          if (attributeConst.length > 0) {
            attributeConst.forEach((element2: any = {}) => {
              const data2 =
              {
                featureAttributeId: null,
                attributeName: null,
                attributeId: null
              }
              data2.featureAttributeId = element2?.featureAttributeId;
              data2.attributeName = element2?.attributeName;
              data2.attributeId = element2?.attributeId;

              data.attribute.push(data2);
            });
            data.attribute = [...data.attribute];
          }
          const applicationRoleConst = element?.applicationRoles;

          applicationRoleConst.forEach((element3: any = {}) => {
            const data3 =
            {
              applicationRoleName: null,
              applicationRoleId: null,
              featureApplicationRoleId: null
            }
            data3.featureApplicationRoleId = element3?.featureApplicationRoleId;
            data3.applicationRoleName = element3?.applicationRoleName;
            data3.applicationRoleId = element3?.applicationRoleId;
            data.applicationRole.push(data3);
          });
          data.applicationRole = [...data.applicationRole];
          this.planList.push(data);
        });
        this.planList = [...this.planList];

      }
    });

  }
  onAddButton($event: UntypedFormGroup): void {
    let reqBody: any = {};
    this.duplicityFlagApplicationRole = false;
    this.duplicityFlagAttributeGroup = false;
    this.duplicityFlagAttribute = false;
    this.duplicityFlagfeatureName = false;

    reqBody = {
      selectType: this.PlanDetailsForm?.value?.selectSwitch,
      application: this.PlanDetailsForm?.value?.application,
      applicationRole: this.PlanDetailsForm?.value?.applicationRoles,
      attributeType: this.PlanDetailsForm?.value?.attributeType?.attributeTypeId,
      attributeTypeName: this.PlanDetailsForm?.value?.attributeType?.attributeTypeName,
      attribute: this.PlanDetailsForm?.value?.attribute,
      attributeGroup: this.PlanDetailsForm?.value?.attributeGroup,
      featureDescription: this.PlanDetailsForm?.value?.featureDescription,
      featureName: this.PlanDetailsForm?.value?.featureName,
    };
    this.applicationRoles = this.PlanDetailsForm?.value?.applicationRoles;
    if (this.planList.length > 0) {
      this.planList.forEach(element => {
        if (element?.featureName == reqBody?.featureName && element?.featureName != null) {
          this.common.push(reqBody?.featureName);
          this.duplicityFlagfeatureName = true;
        }
        if (element?.application?.applicationName == reqBody.application.applicationName) {
          element?.applicationRole?.forEach((array1: any) => {
            reqBody?.applicationRole?.forEach((array2: any) => {
              if (array1.applicationRoleName == array2.applicationRoleName) {
                this.common.push(array1.applicationRoleName);
                this.duplicityFlagApplicationRole = true;
              }
              else {
                this.duplicityFlagApplicationRole = false;
              }
            })
          });
          element?.attributeGroup?.forEach((array1: any) => {
            reqBody?.attributeGroup?.forEach((array2: any) => {
              if (array1.attributeGroupName == array2.attributeGroupName) {
                this.common.push(array1.attributeGroupName);
                this.duplicityFlagAttributeGroup = true;
              }
            })
          });
          element?.attribute?.forEach((array1: any) => {
            reqBody?.attribute?.forEach((array2: any) => {
              if (array1.attributeName == array2.attributeName) {
                this.common.push(array1.attributeName);
                this.duplicityFlagAttribute = true;
              }
            })
          });
        }
      });
    }
    //application role duplicity and attributetype duplicity
    if (!this.duplicityFlagApplicationRole && !this.duplicityFlagAttribute && !this.duplicityFlagAttributeGroup && !this.duplicityFlagfeatureName) {
      this.planList.push(reqBody);
      this.planList1 = this.planList;
      this.enablePlanButton = false;
      this.setDefaultValue_selectSwitch();
      this.PlanDetailsForm.controls.application.reset();
      this.PlanDetailsForm.controls.applicationRoles.reset();
      this.PlanDetailsForm.controls.attributeType.reset();
      this.PlanDetailsForm.controls.attribute.reset();
      this.PlanDetailsForm.controls.attributeGroup.reset();
      this.PlanDetailsForm.controls.featureDescription.reset();
      this.PlanDetailsForm.controls.featureName.reset();

      this.duplicityFlagAttribute = false;
      this.PlanDetailsForm.controls['attributeGroup'].clearValidators();
      this.PlanDetailsForm.controls['attributeGroup'].reset();
      this.PlanDetailsForm.controls['attributeGroup'].updateValueAndValidity();
      this.PlanDetailsForm.controls['attributeType'].updateValueAndValidity();
    }



  }

  deletePlan(index: any) {
    if (this.PlanDetailsForm.value != null) {
      this.PlanDetailsForm.reset();
      this.PlanDetailsForm.controls['planName'].setValue(this.data.planName);
      this.PlanDetailsForm.controls['selectSwitch'].setValue('attributeType');
    }
    this.currentIndex = index;
    this.planList.splice(index, 1);
    if (this.planList.length > 0) {
      this.enablePlanButton = false;
    }
    else {
      this.enablePlanButton = true;
    }
    this.planList1 = this.planList;
  }

  selectAttributeTypeGroup() {
    if (this.PlanDetailsForm.value.selectSwitch == 'attributeType') {
      this.duplicityFlagAttributeGroup = false;
      this.PlanDetailsForm.controls['attributeGroup'].clearValidators();
      this.PlanDetailsForm.controls['attributeGroup'].reset();
      this.PlanDetailsForm.controls['attributeGroup'].updateValueAndValidity();
      this.PlanDetailsForm.controls['attributeType'].updateValueAndValidity();

    } else if (this.PlanDetailsForm.value.selectSwitch == 'attributeGroup') {
      this.duplicityFlagAttribute = false;
      this.PlanDetailsForm.controls['attributeType'].clearValidators();
      this.PlanDetailsForm.controls['attributeType'].reset();
      this.PlanDetailsForm.controls['attributeType'].updateValueAndValidity();
      this.PlanDetailsForm.controls['attribute'].reset();
      this.PlanDetailsForm.controls['attribute'].updateValueAndValidity();

    }
  }

  getAttributeTypeList(applicationId: any): void {
    this.attributeTypeList = [];
    this.pagesService.getAttributeTypeListing(applicationId).subscribe((results: any) => {
      const responseData = results?.success?.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            attributeTypeName: null,
            attributeTypeId: null,
          };
          data.attributeTypeName = element.attributeTypeName;
          data.attributeTypeId = element.id;
          this.attributeTypeList.push(data);
        });
        this.attributeTypeList = [...this.attributeTypeList];
      }
    });
  }
  customFilter(event: any) {
    if (event.filters?.attributeGroup?.length > 0) {
      this.groupFilterMethod(event);

    } else {
      this.attributeFilterMethod(event);
    }
  }
  // filter for column Group
  groupFilterMethod(event: any) {
    let filtered: any[] = [];
    const matMode = event.filters.attributeGroup[0].matchMode;
    let items: any;
    let cc;
    let query = event.filters.attributeGroup[0].attributeGroupId;
    if (query?.length > 0) {
      if (matMode == "startsWith") {
        this.planList.forEach(element => {
          items = element;
          cc = items.attributeGroup?.length;
          //due to break foreach not used
          for (let j = 0; j < cc; j++) {
            if (items.attributeGroup[j].attributeGroupName?.toLowerCase().startsWith(query.toLowerCase())) {
              event.filteredValue.push(items);
              break;
            }
          }
        });
      }
      else if (matMode == "contains") {
        this.planList.forEach(element => {
          items = element;
          cc = items.attributeGroup?.length;
          //due to break foreach not used
          for (let j = 0; j < cc; j++) {
            if (items.attributeGroup[j].attributeGroupName?.toLowerCase().includes(query.toLowerCase())) {
              event.filteredValue.push(items);
              break;
            }
          }
        });
      }
      else if (matMode == "endsWith") {
        this.planList.forEach(element => {
          items = element;
          cc = items.attributeGroup?.length;
          //due to break foreach not used
          for (let j = 0; j < cc; j++) {
            if (items.attributeGroup[j].attributeGroupName?.toLowerCase().indexOf(query.toLowerCase(), items.attributeGroup[j].attributeGroupName?.length - query.length) !== -1) {
              event.filteredValue.push(items);
              break;
            }
          }
        });
      }
      else if (matMode == "equals") {
        this.planList.forEach(element => {
          items = element;
          cc = items.attributeGroup?.length;
          //due to break foreach not used
          for (let j = 0; j < cc; j++) {
            if (items.attributeGroup[j].attributeGroupName?.toLowerCase() == query.toLowerCase()) {
              event.filteredValue.push(items);
              break;
            }
          }
        });
      }
      else {
        this.planList = this.planList1;
      }
    }
  }
  attributeFilterMethod(event: any) {
    let filtered: any[] = [];
    const matMode = event.filters.attribute[0].matchMode;
    let items: any;
    let cc;
    let query = event.filters.attribute[0].attributeId;
    if (query?.length > 0) {
      if (matMode == "startsWith") {
        this.planList.forEach(element => {
          items = element;
          cc = items.attribute.length;
          for (let j = 0; j < cc; j++) {
            if (items.attribute[j].attributeName?.toLowerCase().startsWith(query.toLowerCase())) {
              event.filteredValue.push(items);
            }
          }
        });
      }
      else if (matMode == "contains") {
        this.planList.forEach(element => {
          items = element;
          cc = items.attribute.length;
          for (let j = 0; j < cc; j++) {
            if (items.attribute[j].attributeName?.toLowerCase().includes(query.toLowerCase())) {
              event.filteredValue.push(items);
            }
          }
        });
      }
      else if (matMode == "endsWith") {
        this.planList.forEach(element => {
          items = element;
          cc = items.attribute.length;
          for (let j = 0; j < cc; j++) {
            if (items.attribute[j].attributeName?.toLowerCase().indexOf(query.toLowerCase(), items.attribute[j].attributeName?.length - query.length) !== -1) {
              event.filteredValue.push(items);
            }
          }
        });
      }
      else if (matMode == "equals") {
        this.planList.forEach(element => {
          items = element;
          cc = items.attribute.length;
          for (let j = 0; j < cc; j++) {
            if (items.attribute[j].attributeName?.toLowerCase() == query.toLowerCase()) {
              event.filteredValue.push(items);
            }
          }
        });
      }
      else {
        this.planList = this.planList1;
      }
    }
  }
  getApplicationRoleList(applicationvalue: any): void {
    this.PlanDetailsForm.controls.applicationRoles.reset();
    this.PlanDetailsForm.controls.applicationRoles.updateValueAndValidity();
    this.applicationRoleList = [];
    this.PlanDetailsForm.controls.attribute.reset();
    this.PlanDetailsForm.controls.attribute.updateValueAndValidity();
    this.attributeList = [];
    this.PlanDetailsForm.get('attribute').disable();
    this.pagesService.getApplicationRoleListing(applicationvalue).subscribe((results: any) => {
      const responseData = results?.success?.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            applicationRoleName: null,
            applicationRoleId: null,
          };
          data.applicationRoleName = element.applicationRoleName;
          data.applicationRoleId = element.id;
          this.applicationRoleList.push(data);
        });
        this.applicationRoleList = [...this.applicationRoleList];
      }
    });
    this.getAttributeTypeList(applicationvalue);
  }

  getApplicationAttributeList(applicationValue: any, attributetypevalue: any): void {
    this.attributeList = [];
    const reqBody = {};
    reqBody["applicationId"] = applicationValue;
    reqBody["attributeId"] = attributetypevalue;
    this.pagesService.getAttributeByHostApplication(reqBody).subscribe((results: any) => {
      const responseData = results?.success?.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            attributeName: null,
            attributeId: null,
          };
          data.attributeName = element.label;
          data.attributeId = element.value;
          this.attributeList.push(data);
        });
        this.attributeList = [...this.attributeList];
      }
    });
  }

  getApplicationAttributeGroupList(applicationValue: any): void {
    this.attributeGroupList = [];
    const reqBody = {};
    reqBody["applicationId"] = applicationValue;
    this.pagesService.getAttributeGroupByHostApplication(reqBody).subscribe((results: any) => {
      const responseData = results?.success?.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            attributeGroupName: null,
            attributeGroupId: null,
          };
          data.attributeGroupName = element.label;
          data.attributeGroupId = element.value;
          this.attributeGroupList.push(data);
        });
        this.attributeGroupList = [...this.attributeGroupList];
      }
    });
  }

  onClearButton($event: UntypedFormGroup): void {
    this.planList = [];
    this.PlanDetailsForm.reset();
    this.duplicityFlagApplicationRole = false;
    this.duplicityFlagAttributeGroup = false;
    this.duplicityFlagfeatureName = false;
    this.duplicityFlagAttribute = false;
  }

  onSaveButton($event: UntypedFormGroup): void {
    if (this.planList.length > 0) {
      this.featureReqBody = [];
      const reqBody = {};
      this.planList.forEach(element => {
        const features = {};

        if (element.id && element.id == this.PlanDetailsForm.value.featureId) {
          features["id"] = element.id;
          features["applicationId"] = this.PlanDetailsForm.value.application?.applicationId;
          features["applicationName"] = this.PlanDetailsForm.value.application?.applicationName;
          if (this.PlanDetailsForm.value.attributeType?.attributeTypeId != null && this.PlanDetailsForm.value.attributeType?.attributeTypeName != null) {
            features["attributeType"] = this.PlanDetailsForm.value.attributeType.attributeTypeId;
            features["attributeTypeName"] = this.PlanDetailsForm.value.attributeType.attributeTypeName;
            if (this.PlanDetailsForm.value.attribute != null) {
              let attrReqbody = [];
              if (this.PlanDetailsForm.value.attribute.length > 0) {
                this.PlanDetailsForm.value.attribute.forEach(element => {
                  attrReqbody.push(element);
                });
                attrReqbody = [...attrReqbody];
              }
              features["attributes"] = attrReqbody;
            }
          }

          else if (this.PlanDetailsForm.value.attributeGroup != null) {
            let attrGroupReqBody = [];
            if (this.PlanDetailsForm.value.attributeGroup.length > 0) {
              this.PlanDetailsForm.value.attributeGroup.forEach(element => {
                attrGroupReqBody.push(element);
              });
              attrGroupReqBody = [...attrGroupReqBody];
            }
            features["attributeGroups"] = attrGroupReqBody;
          }

          let applicationReqbody = [];
          if (this.PlanDetailsForm.value.applicationRoles?.length > 0) {
            this.PlanDetailsForm.value.applicationRoles.forEach(element => {
              applicationReqbody.push(element);
            });
            applicationReqbody = [...applicationReqbody];
          }
          features["applicationRoles"] = applicationReqbody;
          features["featureDescription"] = this.PlanDetailsForm.value.featureDescription;
          features["featureName"] = this.PlanDetailsForm.value.featureName;
          this.featureReqBody.push(features);
          this.featureReqBody = [...this.featureReqBody];
        }
        else {
          features["id"] = element?.id;
          features["applicationId"] = element.application.applicationId;
          features["applicationName"] = element.application.applicationName;
          features["attributeType"] = element.attributeType;
          features["attributeTypeName"] = element.attributeTypeName;
          if (element?.attribute?.length > 0) {
            features["attributes"] = element.attribute;
          }
          if (element?.attributeGroup?.length > 0) {
            features["attributeGroups"] = element.attributeGroup;
          }
          features["applicationRoles"] = element.applicationRole;
          features["featureDescription"] = element.featureDescription;
          features["featureName"] = element.featureName;
          this.featureReqBody.push(features);
          this.featureReqBody = [...this.featureReqBody];
        }
      });
      reqBody["id"] = this.planId;
      reqBody["planName"] = this.PlanDetailsForm.value.planName;
      reqBody["planDescription"] = this.PlanDetailsForm.value.planDescription;
      reqBody["features"] = this.featureReqBody;
      this.messageFlag = true;
      this.pagesService.editPlan(reqBody).subscribe(
        {
          next: results => {
            if (results.success.code == 200) {
              this.nameFlag = false;
              this.messageFlag = true;
              this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'Application plan details saved successfully.' });
              setTimeout(() => {
                this.messageFlag = false;
              }, 3000);
              this.enablePlanButton = true;
              this.PlanDetailsForm.controls.application.reset();
              this.PlanDetailsForm.controls.applicationRoles.reset();
              this.PlanDetailsForm.controls.attributeType.reset();
              this.PlanDetailsForm.controls.attribute.reset();
              this.PlanDetailsForm.controls.attributeGroup.reset();
              this.PlanDetailsForm.controls.featureDescription.reset();
              this.PlanDetailsForm.controls.featureName.reset();

              this.duplicityFlagAttribute = false;
              this.PlanDetailsForm.controls['attributeGroup'].clearValidators();
              this.PlanDetailsForm.controls['attributeGroup'].reset();
              this.PlanDetailsForm.controls['attributeGroup'].updateValueAndValidity();
              this.PlanDetailsForm.controls['attributeType'].updateValueAndValidity();
              this.PlanDetailsForm.get('attribute').disable();
              this.planId = results.success.result.id;
              this.clicked2 = false;
              this.tab1 = true;
              this.showTab();
              this.getPlanList();
              this.enablePlanButton = true;
              (<HTMLInputElement>document.getElementById("saveButton_id")).disabled = true;
            }

          },
          error: error => {
            if (error.error?.error?.reason == "Application role already added in Plan") {
              this.duplicityFlagApplicationRole = true;
              this.onSelect();
            }
            else if (error.error?.error?.reason == "Attributes already added in Plan") {
              this.duplicityFlagAttribute = true;
              this.onSelect();
            }
            else if (error.error?.error?.reason == "Attributes group already added in Plan") {
              this.duplicityFlagAttributeGroup = true;
              this.onSelect();
            }
            else if (error.error?.error?.reason == "Application already added in Plan") {
              this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Feature with same detail already exists.' });
              setTimeout(() => {
                this.messageFlag = false;
              }, 3000);
            }
          }
        });
    }
    console.log(this.PlanDetailsForm);
  }

  handleChangecolumns_2_2_card_2_columns_2_2_card_2_columns_2_2_card_2_columns_2_2_tabview_1_1_1_1_1(e: any): void {
    this.PlanDetailsForm.patchValue({ "columns_2_2_card_2_columns_2_2_card_2_columns_2_2_card_2_columns_2_2_tabview_1_1_1_1_1": e.index });
  }

  onClipConvertingcolumns_2_1_ccprimeheading_1() {
    let title = 'Add New Plan'
    this.clipPropertycolumns_2_1_ccprimeheading_1 = title.substring(0, +1) + '...';
  }


  onClipConvertingplanAtrributes() {
    let title = 'Plan Attributes'
    this.clipPropertyplanAtrributes = title.substring(0, +1) + '...';
  }

  onClipConvertingpriceUnitValue() {
    let title = 'Value'
    this.clipPropertypriceUnitValue = title.substring(0, +1) + '...';
  }

  onClipConvertingperUnitType() {
    let title = 'Per Unit Type'
    this.clipPropertyperUnitType = title.substring(0, +1) + '...';
  }

  /*
  * Default Select select button
  */

  setDefaultValue_selectSwitch(value?: any) {
    if (this.selectSwitchList.length && value) {
      this.defaultselect_selectSwitch = value;
      return this.defaultselect_selectSwitch
    }
    this.defaultselect_selectSwitch = this.selectSwitchList[0]?.value
    return this.defaultselect_selectSwitch;
  }

  conditionalLogicCheck(actualValue: any, checkValue: any): boolean {
    if (actualValue && Array.isArray(actualValue)) {
      if (checkValue && typeof checkValue === 'string') {
        const array = checkValue.toString().split(',');
        let valuePresent = true;
        array.forEach((element: any) => {
          if (!actualValue.includes(element)) {
            valuePresent = false;
          }
        });
        return valuePresent;
      } else {
        return actualValue.includes(checkValue);
      }
    }
    return actualValue === checkValue;
  }

  showTab() {
    if (this.activeIndex1 === 0) {
      this.activeIndex1 = 1;
    }
  }

  fetchPlanAttribute() {
    this.pagesService.getAllPlanAttributeConfig().subscribe(results => {
      const responseData = results?.success?.result;
      let pagePropBind = {};
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            id: null,
            fieldType: null,
            typeName: null,
            symbol: null,
            typeId: null,
            defaultOptionValue: null,
            unitId: null,
            planAttributeId: null,
            orderBy: null,
            perUnitType: null,
            fieldTypeId: null,
            optionsValue: null,
            showLimitedOption: null,
            planAttributeName: null,
            unit: null,
            defaultValue: null,
            optionValueList: [],
            planValueId: null
          };
          data.id = element.planAttributeConfigId;
          data.typeName = element.typeName;
          data.symbol = element.symbol;
          data.fieldType = element.fieldType;
          data.defaultValue = element.defaultValue;
          if (data.typeName == 'currency' || data.typeName == 'memory') {
            data.planAttributeName = element.planAttributeName + " (" + data.symbol + ")";
          }
          else {
            data.planAttributeName = element.planAttributeName;
          }


          if (data.fieldType == 'number') {
            data.perUnitType = element.perUnitType;
            let unitTypeList = data.perUnitType.split(",");
            if (unitTypeList && unitTypeList.length > 0) {
              unitTypeList.forEach(element1 => {
                const data1 = { "label": element1, "value": element1 }
                data.optionValueList.push(data1);
                if (element.defaultOptionValue == element1) {
                  data.defaultOptionValue = element.defaultOptionValue;
                }
              });
            }

            this.PlanAttributeForm.addControl(data.id + "_input", this.fb.control('', Validators.required));
            this.PlanAttributeForm.addControl(data.id + "_dropdown", this.fb.control('', Validators.required));
            if (!this.planAttrFlag) {
              data.planValueId = this[element.planAttributeConfigId + '_planValueId'];
              pagePropBind[data.id + "_input"] = this[data.id + '_savedInput'];
              pagePropBind[data.id + "_dropdown"] = this[data.id + '_savedDropdown'];
              this[data.id + 'oldValueInput'] = this[data.id + '_savedInput'];
              this[data.id + 'oldValueDropdown'] = this[data.id + '_savedDropdown'];
            }
            else {
              pagePropBind[data.id + "_input"] = data.defaultValue;
              pagePropBind[data.id + "_dropdown"] = data.defaultOptionValue;
              this[data.id + 'oldValueInput'] = data.defaultValue;
              this[data.id + 'oldValueDropdown'] = data.defaultOptionValue;
            }
          }
          else if (data.fieldType == 'checkbox') {
            data.optionsValue = element.optionsValue;
            let checkBoxList = data.optionsValue.split(",");
            checkBoxList.forEach(element2 => {
              const data1 = { "label": element2, "value": element2 }
              data.optionValueList.push(data1);
            });
            this.PlanAttributeForm.addControl(data.id, this.fb.control('', Validators.required));
            if (!this.planAttrFlag) {
              data.planValueId = this[element.planAttributeConfigId + '_planValueId'];
              this[data.id] = this[data.id + '_saved'];
              this[data.id + '_oldCheckbox'] = this[data.id + '_saved'];
            }
            else {
              data.defaultOptionValue = element.defaultOptionValue;
              this[data.id] = [data.defaultOptionValue];
              this[data.id + '_oldCheckbox'] = [data.defaultOptionValue];
            }

          }
          else if (data.fieldType == 'radiobutton') {
            data.optionsValue = element.optionsValue;
            let radioButtonList = data.optionsValue.split(",");
            radioButtonList.forEach(element => {
              const data1 = { "label": element, "value": element }
              data.optionValueList.push(data1);
            });
            this.PlanAttributeForm.addControl(data.id, this.fb.control('', Validators.required));
            if (!this.planAttrFlag) {
              data.planValueId = this[element.planAttributeConfigId + '_planValueId'];
              this[data.id] = this[data.id + '_saved'];
              this[data.id + '_oldRadiobutton'] = this[data.id + '_saved'];
            }
            else {
              data.defaultOptionValue = element.defaultOptionValue;
              this[data.id] = [data.defaultOptionValue];
              this[data.id + '_oldRadiobutton'] = [data.defaultOptionValue];
            }

          }
          else if (data.fieldType == 'boolean') {

            this.PlanAttributeForm.addControl(data.id, this.fb.control('', Validators.required));
            if (!this.planAttrFlag) {
              data.planValueId = this[element.planAttributeConfigId + '_planValueId'];
              if (this[data.id + '_saved'] == "true") {
                this[data.id] = true;
              }
              else if (this[data.id + '_saved'] == "false") {
                this[data.id] = false;
              }
              this[data.id + '_oldBoolean'] = this[data.id];
            }
            else {
              data.id = element.planAttributeConfigId;
              if (element.defaultOptionValue == "true") {
                this[data.id] = true;
              }
              else if (element.defaultOptionValue == "false") {
                this[data.id] = false;
              }
              this[data.id + '_oldBoolean'] = this[data.id];
            }

          }
          this.planAttributeList.push(data);
        });

      }
      this.PlanAttributeForm.patchValue(pagePropBind);
      this.planAttributeList = [...this.planAttributeList];
    });
  }
  getPlanAttributeValueList(): void {
    const reqBody: any = {};
    reqBody["planid"] = this.planId;
    const formData = reqBody;
    this.pagesService.getPlanAttributeValue(formData).subscribe(results => {
      const responseData = results.success.result;
      if (responseData.length > 0) {
        this.planAttrFlag = false;
        this.enableAddSusbcriptionBtn = false;
        this.enableBtn1 = true;
        responseData.forEach((element: any = {}) => {
          const data = {
            value: null,
            perUnitType: null,
            planAttributeConfigId: null,
            fieldType: null,
            planValueId: null
          };
          data.value = element.value;
          data.perUnitType = element.perUnitType;
          data.planAttributeConfigId = element.planAttributeConfigId;
          data.planValueId = element.planValueId;
          this[data.planAttributeConfigId + '_planValueId'] = data.planValueId;
          if (element.fieldType == 'checkbox' || element.fieldType == 'radiobutton') {
            const valuesss = element.value;
            let checkBoxList = valuesss.split(",");
            this[data.planAttributeConfigId + '_saved'] = checkBoxList;
          }
          else if (element.fieldType == 'number') {
            this[data.planAttributeConfigId + '_savedInput'] = data.value;
            this[data.planAttributeConfigId + '_savedDropdown'] = data.perUnitType;
          }
          else if (element.fieldType == 'boolean') {
            this[data.planAttributeConfigId + '_saved'] = data.value;
          }
        });
        this.fetchPlanAttribute();
      }
      else {
        this.planAttrFlag = true;
        this.enableBtn1 = false;
        this.enableAddSusbcriptionBtn = true;
        this.fetchPlanAttribute();
      }
    });
  }
  checkValidation(item: any) {
    this.planAttributeList.forEach(element1 => {
      if (element1.fieldType == 'checkbox') {
        if (item == 'None') {
          if (this[element1.id].includes('None') && this[element1.id].length > 0) {
            this[element1.id].splice(0, this[element1.id].length - 1);
          }
        }
        else if (this[element1.id].length == 0) {
          this[element1.id].push('None');
        }
        else if (this[element1.id].length > 0) {
          if (item != 'None') {
            let index = this[element1.id].indexOf('None');
            if (index == 0) {
              this[element1.id].splice(index, 1);
            }
          }
        }
      }
    });
  }
  checkOldNewValue() {
    if (!this.planAttrFlag || this.firstSave) {
      if (this.planAttributeList && this.planAttributeList.length > 0) {
        this.changedValueFlag = true;
        this.planAttributeList.forEach((element: any = {}) => {
          if (element.fieldType == 'number') {

            if ((this[element.id + 'oldValueInput'] != this.PlanAttributeForm.value[element.id + "_input"]) || (this[element.id + 'oldValueDropdown'] != this.PlanAttributeForm.value[element.id + "_dropdown"])) {
              this.changedValueFlag = false;
            }
          }

          else if (element.fieldType == 'checkbox') {
            const assignList = this[element.id].filter(x => !this[element.id + '_oldCheckbox'].includes(x));
            if (assignList.length > 0) {
              this.changedValueFlag = false;
            }
          }
          else if (element.fieldType == 'radiobutton') {
            if (this[element.id + '_oldRadiobutton'] != this[element.id]) {
              this.changedValueFlag = false;
            }
          }
          else if (element.fieldType == 'boolean') {

            if (this[element.id + '_oldBoolean'] != this[element.id]) {
              this.changedValueFlag = false;
            }

          }
        });
        if (this.changedValueFlag) {
          this.enableBtn1 = true;
          this.enableAddSusbcriptionBtn = false;
        }
        else {
          this.enableBtn1 = false;
          this.enableAddSusbcriptionBtn = true;
        }
      }
    }


  }
  createPlanAttribute() {
    if (!this.planAttrFlag) {
      this.onEditPlanAttribute();
    }
    else if (this.firstSave) {
      this.onEditPlanAttribute();
    }
    else {
      this.onSavePlanAttribute();
    }
  }
  onSavePlanAttribute(): void {
    this.planAttributeDto = [];
    const reqBody = {};
    if (this.planAttributeList && this.planAttributeList.length > 0) {
      this.planAttributeList.forEach((element: any = {}) => {
        const data = {
          planId: null,
          planAttributeConfigId: null,
          value: null,
          perUnitType: null,
        };
        data.planAttributeConfigId = element.id;
        data.planId = this.planId;
        if (element.fieldType == 'number') {
          data.value = this.PlanAttributeForm.value[element.id + "_input"];
          data.perUnitType = this.PlanAttributeForm.value[element.id + "_dropdown"];
        }

        else if (element.fieldType == 'checkbox') {
          data.value = this[element.id].toString();
        }
        else if (element.fieldType == 'radiobutton') {
          data.value = this[element.id].toString();
        }
        else if (element.fieldType == 'boolean') {
          data.value = this[element.id].toString();

        }
        this.planAttributeDto.push(data);
      });
      this.planAttributeDto = [...this.planAttributeDto];
    }
    reqBody['planAttributeValueDto'] = this.planAttributeDto;
    const formValues = reqBody;
    this.messageFlag1 = true;
    this.pagesService.savePlanAttribute(formValues).subscribe({
      next: results => {
        const responseData = results?.success?.result;
        this.messageFlag1 = true;
        this.messageService.add({ key: 'myKey2', severity: 'success', detail: 'Plan attribute saved successfully.' });
        setTimeout(() => {
          this.messageFlag1 = false;
        }, 3000);
        this.planAttributeList.forEach(element1 => {
          responseData.forEach(element2 => {
            if (element1.id == element2.planAttributeConfigId) {
              element1.planValueId = element2.id;
            }
          });
        });
        this.setNewPlanAttributeValues(responseData);
        this.firstSave = true;
        this.enableBtn1 = true;
        this.tab2 = true;
        this.enableAddSusbcriptionBtn = false;
      },
      error: () => {
        this.messageFlag1 = true;
        this.messageService.add({ key: 'myKey2', severity: 'error', detail: 'Failed to save plan attribute.' });
        setTimeout(() => {
          this.messageFlag1 = false;
        }, 3000);
      }
    });


  }
  onEditPlanAttribute(): void {
    this.planAttributeDto = [];
    const reqBody = {};
    if (this.planAttributeList && this.planAttributeList.length > 0) {
      this.planAttributeList.forEach((element: any = {}) => {
        const data = {
          id: null,
          planId: null,
          planAttributeConfigId: null,
          value: null,
          perUnitType: null,
        };
        data.id = element.planValueId;
        data.planAttributeConfigId = element.id;
        data.planId = this.planId;
        if (element.fieldType == 'number') {
          data.value = this.PlanAttributeForm.value[element.id + "_input"];
          data.perUnitType = this.PlanAttributeForm.value[element.id + "_dropdown"];
        }

        else if (element.fieldType == 'checkbox') {
          data.value = this[element.id].toString();
        }
        else if (element.fieldType == 'radiobutton') {
          data.value = this[element.id].toString();
        }
        else if (element.fieldType == 'boolean') {
          data.value = this[element.id].toString();

        }
        this.planAttributeDto.push(data);
      });
      this.planAttributeDto = [...this.planAttributeDto];
    }
    reqBody['editPlanAttributeValueDto'] = this.planAttributeDto;
    const formValues = reqBody;
    this.messageFlag1 = true;
    this.pagesService.editPlanAttribute(formValues).subscribe({
      next: results => {
        const responseData = results?.success?.result;
        this.messageFlag1 = true;
        this.messageService.add({ key: 'myKey2', severity: 'success', detail: 'Plan attribute saved successfully.' });
        setTimeout(() => {
          this.messageFlag1 = false;
        }, 3000);
        this.enableBtn1 = true;
        this.tab2 = true;
        this.enableAddSusbcriptionBtn = false;
        this.setNewPlanAttributeValues(responseData);
      },
      error: () => {
        this.messageFlag1 = true;
        this.messageService.add({ key: 'myKey2', severity: 'error', detail: 'Failed to save plan attribute.' });
        setTimeout(() => {
          this.messageFlag1 = false;
        }, 3000);
      }
    });

  }
  setNewPlanAttributeValues(responseData: any) {
    this.planAttributeList.forEach(element1 => {
      responseData.forEach(element2 => {
        if (element1.fieldType == 'number') {
          if (element1.id == element2.planAttributeConfigId) {
            this[element1.id + 'oldValueInput'] = element2.value;
            this[element1.id + 'oldValueDropdown'] = element2.perUnitType;
          }
        }
        else if (element1.fieldType == 'checkbox') {
          if (element1.id == element2.planAttributeConfigId) {
            this[element1.id + '_oldCheckbox'] = element2.value.split(",");
          }
        }
        else if (element1.fieldType == 'radiobutton') {
          if (element1.id == element2.planAttributeConfigId) {
            this[element1.id + '_oldRadiobutton'] = element2.value;
          }
        }
        if (element1.fieldType == 'boolean') {
          if (element1.id == element2.planAttributeConfigId) {
            if (element2.value == "false") {
              this[element1.id + '_oldBoolean'] = false;
            }
            else {
              this[element1.id + '_oldBoolean'] = true;
            }
          }
        }
      });
    });
  }
  setPageProperties(props: any): void {
    const pageData: any = {};
    const calendarControls = ['createdOn'];
    Object.keys(props).forEach(key => {
      if (calendarControls.includes(key)) {
        pageData[key] = new Date(props[key]);
      } else {
        pageData[key] = props[key];
      }
    });
    this.planId = pageData.planId;
    this.PlanAttributeForm.patchValue(pageData);
    this.PlanDetailsForm.patchValue(pageData);
  }
  addAsSubscription() {
    this.ref = this.dialogService.open(AddAsSubscriptionComponent, {
      header: 'Add As Subscription',
      data: { planId: this.planId },
      width: '38%',
      contentStyle: { 'max-height': '160px', 'border-radius': '4px', overflow: 'auto' },
      baseZIndex: 10000,
    });
    this.messageFlag = true;
    this.ref.onClose.subscribe(msg => {
      this.messageFlag = true;
      if (msg == 'Failed') {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'error', detail: 'Failed to delete user.' });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);

      }
      else if (msg) {
        this.messageFlag = true;
        this.messageService.add({ key: 'myKey1', severity: 'success', detail: msg });
        setTimeout(() => {
          this.messageFlag = false;
        }, 3000);

      }

    });
  }

  editPlan(rowData: any) {
    this.row = rowData;
    this.editDisable = true;
    this.PlanDetailsForm.get('applicationRoles').enable();
    this.PlanDetailsForm.get('attributeType').enable();
    this.getApplicationRoleList(rowData.application.applicationId);
    let applicationRoleList = [];
    rowData.applicationRole.forEach(element => {
      const data = {
        applicationRoleName: null,
        applicationRoleId: null,
      }
      data.applicationRoleName = element.applicationRoleName;
      data.applicationRoleId = element.applicationRoleId;
      applicationRoleList.push(data);
    });
    applicationRoleList = [...applicationRoleList];
    let attributeGroupList = [];
    let attributeListEdit = [];
    if (rowData.attributeGroup?.length != 0 && rowData.attributeGroup != null) {
      this.getApplicationAttributeGroupList(rowData.application.applicationId);
      this.PlanDetailsForm.controls['selectSwitch'].setValue("attributeGroup");
      this.PlanDetailsForm.controls['selectSwitch'].updateValueAndValidity();
      this.conditionalLogicCheck(this.PlanDetailsForm.value.selectSwitch, 'attributeGroup');
      this.PlanDetailsForm.get('attributeGroup').enable();
      rowData.attributeGroup.forEach(element => {
        const data = {
          attributeGroupName: null,
          attributeGroupId: null,
        }
        data.attributeGroupName = element.attributeGroupName;
        data.attributeGroupId = element.attributeGroupId;
        attributeGroupList.push(data);
      });
      attributeGroupList = [...attributeGroupList];
    }
    else if (rowData.attributeType != null) {
      this.getApplicationAttributeList(rowData.application.applicationId, rowData.attributeType);
      this.PlanDetailsForm.controls['selectSwitch'].setValue("attributeType");
      this.PlanDetailsForm.controls['selectSwitch'].updateValueAndValidity();
      this.conditionalLogicCheck(this.PlanDetailsForm.value.selectSwitch, 'attributeType');
      this.PlanDetailsForm.get('attributeType').enable();
      this.PlanDetailsForm.get('attribute').enable();
      this.PlanDetailsForm.controls['attribute'].updateValueAndValidity();
      rowData.attribute.forEach(element => {
        const data = {
          attributeName: null,
          attributeId: null,
        }
        data.attributeName = element.attributeName;
        data.attributeId = element.attributeId;
        attributeListEdit.push(data);
      });
      attributeListEdit = [...attributeListEdit];
    }
    let pagePropBind1 = {
      featureId: rowData.id,
      featureName: rowData.featureName,
      featureDescription: rowData.featureDescription,
      application: rowData.application,
      applicationRoles: applicationRoleList,
      attributeType: {
        attributeTypeName: rowData.attributeTypeName,
        attributeTypeId: rowData.attributeType
      },
      attribute: attributeListEdit,
      attributeGroup: attributeGroupList,
    }
    if (rowData.attributeType != null) {
      this.attributeTypeValue = pagePropBind1.attributeType;
    }
    this.PlanDetailsForm.patchValue(pagePropBind1);
  }
  onSelect() {
    if (this.duplicityFlagApplicationRole || this.duplicityFlagAttribute || this.duplicityFlagAttributeGroup || !this.editDisable) {
      (<HTMLInputElement>document.getElementById("saveButton_id")).disabled = true;
    }
    else if (this.PlanDetailsForm.valid)
      (<HTMLInputElement>document.getElementById("saveButton_id")).disabled = false;

  }

  onAddDeployModel(): void {
    const reqBody = {};
    const accountDetails = {};
    const vpcDetails = {};
    const clusterDetails = {};
    const clusterRequirment = {};
    const externalStorage = {};
    const cacheStorage = {};
    const database = {};
    accountDetails['accountNumber'] = this.PlanAttributeForm.value.accountNumber;
    accountDetails['region'] = this.PlanAttributeForm.value.region;
    accountDetails['role'] = this.PlanAttributeForm.value.role;
    vpcDetails['vpcName'] = this.PlanAttributeForm.value.vpcName;
    vpcDetails['subnet'] = this.PlanAttributeForm.value.subnet;
    vpcDetails['vpcName'] = this.PlanAttributeForm.value.vpcName;
    clusterDetails['cluterName'] = this.PlanAttributeForm.value.clusterName;
    clusterRequirment['nodeFamily'] = this.PlanAttributeForm.value.nodeFamily;
    clusterRequirment['podautoscaling'] = this.PlanAttributeForm.value.enablePodAutoScalling;
    externalStorage['EFSStyle'] = this.PlanAttributeForm.value.efsStyle;
    externalStorage['EFSPath'] = this.PlanAttributeForm.value.efsPath;
    externalStorage['S3Style'] = this.PlanAttributeForm.value.s3Style;
    externalStorage['S3ARNRole'] = this.PlanAttributeForm.value.s3ArnRole;
    externalStorage['S3Secrect'] = this.PlanAttributeForm.value.s3Secret;
    externalStorage['S3Key'] = this.PlanAttributeForm.value.s3Key;
    externalStorage['S3SecrectVaultPath'] = this.PlanAttributeForm.value.s3SecretVaultPath;
    cacheStorage['REDISStyle'] = this.PlanAttributeForm.value.redisStyle;
    cacheStorage['REDISUrl'] = this.PlanAttributeForm.value.redisUrl;
    cacheStorage['REDISVaultPath'] = this.PlanAttributeForm.value.redisVaultPath;
    cacheStorage['REDISUsername'] = this.PlanAttributeForm.value.redisUsername;
    cacheStorage['REDISPassword'] = this.PlanAttributeForm.value.redisPassword;
    database['RDSStyle'] = this.PlanAttributeForm.value.rdsStyle;
    database['RDSHOST'] = this.PlanAttributeForm.value.rdsHost;
    database['RDSPORT'] = this.PlanAttributeForm.value.rdsPort;
    database['RDSUsername'] = this.PlanAttributeForm.value.rdsUsername;
    database['RDSPassword'] = this.PlanAttributeForm.value.rdsPassword;
    database['RDSType'] = this.PlanAttributeForm.value.rdsType;
    database['RDSDBName'] = this.PlanAttributeForm.value.rdsDBName;
    database['RDSVaultPath'] = this.PlanAttributeForm.value.rdsVaultPath;
    database['RDSFamily'] = this.PlanAttributeForm.value.rdsFamily;

    reqBody['type'] = this.PlanAttributeForm.value.deploymentType;
    reqBody['subType'] = this.PlanAttributeForm.value.deploymentSubType;

    reqBody['accountDetails'] = accountDetails;
    reqBody['vpcDetails'] = vpcDetails;
    reqBody['clusterDetails'] = clusterDetails;
    reqBody['clusterRequirment'] = clusterRequirment;
    reqBody['externalStorage'] = externalStorage;
    reqBody['cacheStorage'] = cacheStorage;
    reqBody['database'] = database;

    const reqJson = JSON.stringify(reqBody);
    const mainReq = {};
    mainReq['planId'] = this.planId;
    mainReq['json'] = reqJson;
    // console.log(mainReq);
    const formValues = mainReq;
    this.messageFlag1 = true;
    this.pagesService.addDeploymentModel(formValues).subscribe({
      next: results => {
        const responseData = results?.success?.result;
        // this.messageFlag1 = true;
        // this.messageService.add({ key: 'myKey2', severity: 'success', detail: 'Plan attribute saved successfully.' });
        // setTimeout(() => {
        //   this.messageFlag1 = false;
        // }, 3000);
        // this.planAttributeList.forEach(element1 => {
        //   responseData.forEach(element2 => {
        //     if (element1.id == element2.planAttributeConfigId) {
        //       element1.planValueId = element2.id;
        //     }
        //   });
        // });
        // this.setNewPlanAttributeValues(responseData);
      },
      error: () => {
        this.messageFlag1 = true;
        this.messageService.add({ key: 'myKey2', severity: 'error', detail: 'Failed to save deployment model.' });
        setTimeout(() => {
          this.messageFlag1 = false;
        }, 3000);
      }
    });
  }

  getDeploymentModel(): void {
    const reqBody: any = {};
    // console.log(this.planId)
    this.pagesService.getDeploymentModel(this.planId).subscribe(results => {
      const responseData = results.success.result;
      // console.log(responseData);
      const json = JSON.parse(responseData.detailsJson);
      // console.log(json);
      this.deploymentType = json.type;
      this.deploymentSubType = json.subType;
      this.accountNumber = json.accountDetails.accountNumber;
      this.region = json.accountDetails.region;
      this.role = json.accountDetails.role;
      this.vpcName = json.vpcDetails.vpcName;
      this.subnet = json.vpcDetails.subnet;
      this.clusterName = json.clusterDetails.cluterName;
      this.nodeFamily = json.clusterRequirment.nodeFamily;
      this.enablePodAutoScalling = json.clusterRequirment.podautoscaling;
      this.efsStyle = json.externalStorage.EFSStyle;
      this.efsPath = json.externalStorage.EFSPath;
      this.s3Style = json.externalStorage.S3Style;
      this.s3ArnRole = json.externalStorage.S3ARNRole;
      this.s3Secret = json.externalStorage.S3Secrect;
      this.s3Key = json.externalStorage.S3Key;
      this.s3SecretVaultPath = json.externalStorage.S3SecrectVaultPath;
      this.redisStyle = json.cacheStorage.REDISStyle;
      this.redisUrl = json.cacheStorage.REDISUrl;
      this.redisVaultPath = json.cacheStorage.REDISVaultPath;
      this.redisUsername = json.cacheStorage.REDISUsername;
      this.redisPassword = json.cacheStorage.REDISPassword;
      this.rdsStyle = json.database.RDSStyle;
      this.rdsType = json.database.RDSType;
      this.rdsHost = json.database.RDSHOST;
      this.rdsPort = json.database.RDSPORT;
      this.rdsUsername = json.database.RDSUsername;
      this.rdsPassword = json.database.RDSPassword;
      this.rdsDBName = json.database.RDSDBName;
      this.rdsVaultPath = json.database.RDSVaultPath;
      this.rdsFamily = json.database.RDSFamily;
    });
  }
}