import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PagesService } from '../pages.service';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { MainComponent } from 'app/layouts/main/main.component';
import { ContextName, EventName } from 'app/shared/constants/logging-event.constants';
@Component({
  selector: 'jhi-ForceLogOff',
  templateUrl: './ForceLogOff.component.html',
  styleUrls: ['./ForceLogOff.component.scss'],
})
export class ForceLogOffComponent implements OnInit {
  ForceLogOffForm = this.fb.group({});
  activeId: any[] = [];
  pageloadvalues: any;
  stompClient: any
  enableBtn: boolean = false;
  forceLofOffUserData:any[]=[];
  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    private messageService: MessageService,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.ForceLogOffForm.patchValue(JSON.parse(data));
    }
  }
  ngOnInit(): void {
    this.enableBtn = false;
    this.forceLofOffUserData = this.config?.data?.rowData;
  }

  onClickNo($event: UntypedFormGroup): void {
    this.ref.close();
  }

  forcelogoff() {
    const data: any = this.config?.data?.id;
    this.pagesService.forcelogoff(data).subscribe({
      next: (results: any) => {
        this.send();
        sessionStorage.removeItem('previousUrl');
        this.ref.close("User Logged off Successfully.");
        this.logEvent(EventName.FORCE_LOG_OFF_USER,ContextName.FORCE_LOG_OFF_USER,this.forceLofOffUserData);

      },
      error: () => {
        this.ref.close("Failed to Logged off User");
      }
    });

  }

  send() {
    const data: any = this.config?.data?.id;
    this.stompClient = MainComponent.stompCl;
    const obj = {
      tenantId: this.pagesService.tenantId,
      userId: data?.[0]
    }
    this.stompClient.send("/ciam/v1/user/force-logoff-notification", {}, JSON.stringify(obj));
  }

  logEvent(eventName,eventContextName,value){
    const data={
      "ContextName":eventContextName,
      "value":value,
      "EventName":eventName,
      "newValue":'FORCE LOG OFF USER'
    };
    this.pagesService.logUserEvent(data).subscribe(resp =>{
      //console.log(resp);
      
    },
            error => {
              console.log("Error >> ",ContextName.VIEW_MANAGE_USERS_PAGE,":", error);
            });
  }
}
