import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PagesService } from '../pages.service';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'jhi-deactiverole',
  templateUrl: './RevokeDeletedUser.component.html',
  styleUrls: ['./RevokeDeletedUser.component.scss'],
})
export class RevokeDeletedUserComponent implements OnInit {
  RevokeDeletedUserForm = this.fb.group({
    textField: [null, []]
  });
  activeId: any[] = [];
  pageloadvalues: any;
  wfFlag = false;
  errorFlag: boolean = true;
  tableList: any[] = [];
  tableList1: any[] = [];
  selectedItems: any[] = [];
  actionDisable = true;
  userCount: any;
  selectedRecords: any[] = [];
  tablecols: any[] = [
    { field: 'firstName', header: 'First Name' },
    { field: 'middleName', header: 'Middle Name' },
    { field: 'lastName', header: 'Last Name' },
    { field: 'emailAddress', header: 'Deleted by' },
    { field: 'status', header: 'Deleted on' },
  ];
  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private datePipe: DatePipe
  ) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.RevokeDeletedUserForm.patchValue(JSON.parse(data));
    }
  }
  ngOnInit(): void {
    this.gettableList();
  }
  onCcprimebutton($event: UntypedFormGroup): void {
    this.ref.close();
  }
  gettableList(): void {
    let reqBody = {};
    reqBody['tenant_id'] = this.pagesService.tenantId;
    const formValues = reqBody;
    this.pagesService.getDeletedUser(formValues).subscribe(results => {
      let responseData;
      if (results.hasOwnProperty("success")) {
        responseData = results?.success?.result?.records;
        responseData.forEach(
          customer => (customer.lastModifiedDate = this.datePipe.transform(customer.lastModifiedDate, 'MMM d, y, h:mm a'))
        );
        if (responseData && responseData.length > 0) {
          this.tableList = responseData;
          this.tableList1 = responseData;
          this.errorFlag = false;
        }
      }
      else {
        responseData = results.error.code;
        if (responseData == "400") {
          this.tableList = [];
          this.tableList1 = [];
          this.errorFlag = true;
        }

      }
    });

  }
  filterMethod(event: any) {

    let filtered: any[] = [];
    let items: any;
    let cc;
    let query = event;
    if (query?.length > 0) {
      this.tableList1.forEach(element => {
        items = element;
        cc = element.firstName + " " + element.lastName;
        if (items.fullName?.toLowerCase().includes(query.toLowerCase()) || cc?.toLowerCase().includes(query.toLowerCase()) || items.middleName?.toLowerCase().includes(query.toLowerCase()) || items.lastModifiedDate?.toLowerCase().includes(query.toLowerCase()) || items.lastmodifiedBy?.toLowerCase().includes(query.toLowerCase()) || items.email?.toLowerCase().includes(query.toLowerCase())) {
          filtered.push(items);
        }
      });
      this.tableList = filtered;
    }
    else {
      this.tableList = this.tableList1;
    }

  }
  onRowSelect(event: any) {
    this.selectedItems.push(event.data.id);
    this.actionDisable = false;

  }
  onRowUnselect(event: any) {
    const index: number = this.selectedItems.indexOf(event.data.id);

    if (index !== -1) {
      this.selectedItems.splice(index, 1);
    }
    if (this.selectedItems.length == 0) {
      this.actionDisable = true;
    }
  }

  onSelectAllUsers() {
    this.selectedItems = [];
    this.selectedRecords.forEach(element => {
      this.selectedItems.push(element.id);
    });
    if (this.selectedItems.length > 0) {
      this.actionDisable = false;
    } else {
      this.actionDisable = true;
    }
  }
  //Revoke user API functionm
  revokeUsers($event: UntypedFormGroup): void {
    const reqBody = {};
    reqBody["tenantId"] = this.pagesService.tenantId;
    reqBody["userIds"] = this.selectedItems;
    const formData = reqBody;
    this.pagesService.revokeDeletedUser(formData).subscribe({
      next: results => {
        let cnt = 0;
        let kk = results.success.result;
        let configFlag = 0;
        kk.forEach(element => {
          if (element.messageCode == 'CIAM 154') {
            this.wfFlag = true;
          }
          else if (element.messageCode == 'CIAM 261') {
            cnt = cnt + 1;
            this.wfFlag = false;
          }
          else if (element.messageCode == "CIAM 113") {
            configFlag = configFlag + 1;
          }
        });

        if (this.wfFlag) {
          this.ref.close();
          this.navigateToMyReq('Revoke user request has been sent for approval');
        }
        else if (!this.wfFlag) {
          if (this.selectedItems.length >= 1) {
            if (cnt < 10 && cnt >= 1) {
              this.userCount = "0" + cnt;
            }
            else {
              this.userCount = cnt;
            }
            if (cnt >= 1) {
              this.ref.close(this.userCount + ' users revoked successfully.');
            }
            else if (configFlag >= 1 && cnt == 0) {
              this.ref.close('This type of user creation is disabled for Tenant');
            }

          } else if (cnt >= 1) {
            this.ref.close(cnt + ' users revoked successfully.');
          }
          else if (configFlag >= 1 && cnt == 0) {
            this.ref.close('This type of user creation is disabled for Tenant');
          }
        }
      }
      ,
      error: error => {
        if (error.error?.error?.messageCode == "CIAM 105") {
          this.ref.close('Request already available for approval');
        }
        else {
          this.ref.close('error');
        }
      }
    });
  }
  navigateToMyReq(msg: any): void {
    const navigationExtras: NavigationExtras = {
      state: {
        msg: msg,
      },
    };
    this.router.navigate(['/pages/myrequestslisting'], navigationExtras);
  }
}
