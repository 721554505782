<div class="layout-content custom-container">
    <form [formGroup]="AssignUsersForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                <div class=" p-fluid   p-field    ">
                    <label [ngClass]="{'field-required': AssignUsersForm.get('select')?.errors?.required}">
                        Assigning Users to <span style="color: #4A6DA8;font-weight: 500;">{{this.selectedName}}</span>
                    </label>
                    <div class="p-fluid  p-field custom-checkbox-color">
                        <p-multiSelect filterBy="label" id="userList" name="userList" filterPlaceHolder="Select Users"
                            [options]="userListList" [filter]="true" formControlName="userList" optionLabel="label"
                            optionValue="value" selectedItemsLabel="{0} Users selected" display="chip"
                            resetFilterOnHide="true" placeholder="Select Users" class="custom-chips"
                            (onChange)="enableBtn=false" optionDisabled="inactive" [maxSelectedLabels]="5">
                            <ng-template let-each pTemplate="selectedItem" style="z-index: -1;">
                                {{each.label}}
                            </ng-template>
                            <ng-template let-item pTemplate="each">
                                <div class="drop-item" style="display: inline;">
                                    {{item.label}}
                                </div>
                            </ng-template>
                        </p-multiSelect>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right p-mb-2">
                <div class="btnwrap">
                    <button pButton type="button" class="p-button-brand2 p-button p-mr-3"
                        (click)="onClose(AssignUsersForm);" label="Cancel">
                    </button>
                </div>
                <div class="btnwrap">
                    <button pButton class="p-button" type="button"
                        (click)=" AssignUsersToRoleandUserGroup(AssignUsersForm);" label="Assign"
                        [disabled]="enableBtn">
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>