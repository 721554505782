import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { PagesService } from '../pages.service';
import { REBAC_UM_ENTITLEMENT_FLAG } from 'app/app.constants';
import { ManageEntitlementsService } from '../ManageEntitlements/ManageEntitlements.service';
@Component({
  selector: 'jhi-createattributegroup',
  templateUrl: './CreateComponent.component.html',
  styleUrls: ['./CreateComponent.component.scss'],
  providers: [DialogService],
})
export class CreateComponent implements OnInit {
  CreateComponentForm = this.fb.group({
    componentName: [null, [Validators.required]],
    componentType: [null, []],
    clientProtocol: [null, []],
    accessType: [null, []],
    rootURL: [null, []],
    redirectURL: [null, [Validators.required]],
    baseURL: [null, []],
    adminURL: [null, []],
    webOrigins: [null, []],
    backchannelURL: [null, []],
    description: [null, []],
    db_migration_checkbox: [false, []],
    cpuThreshold: [null, [Validators.required]],
    ramThreshold: [null, [Validators.required]],
    artifactType: [null, [Validators.required]],
    imagePath: [null, [Validators.required]],
    enterKey_dbUrl: [null, [Validators.required]],
    enterKey_dbUsername: [null, [Validators.required]],
    enterKey_dbPassword: [null, [Validators.required]],
    enterKey_idpUrl: [null, [Validators.required]],
    enterKey_idpRealm: [null, [Validators.required]],
    enterKey_idpClientId: [null, [Validators.required]],
    enterKey_idpClientSecret: [null, [Validators.required]],
    ownershipComponent: [null, [Validators.required]],
    ownershipEntitlement: [null, [Validators.required]],
    primeComponentId: [null, [Validators.required]],
    contextPath: [null, [Validators.required]],
    port: [null, [Validators.required]],
    withVolume: [false, []],
    createVolume: [false, []],
    createRole: [false, []],
    emailTemplate: [null, []],
  });

  pageloadvalues: any;
  nameFlag: boolean = false;
  enabledbtn1: boolean = false;
  selectList: any[] = [];
  selectList1: any[] = [];
  selectList2: any[] = [];

  selectemailTempList: any[] = [];
  appId: any;
  artifactList: any[] = [{
    "label": "image",
    "value": "image"
  },
  {
    "label": "jar",
    "value": "jar"
  }]
  solImageFileSize: boolean;
  imagePlacheholder: boolean;
  imagevalid: boolean;
  formValidFlag: boolean;
  logo: File;
  imageFileFlag: boolean;
  default: boolean;
  solImage: boolean;
  productPrimaryImage: any;
  dbLogo: File;
  dbImagevalid: boolean = false;
  saasFlag: boolean = false;
  dbformValidFlag: boolean = false;
  dbImagePlacheholder: boolean;
  rebacFlag: any;
  allActiveComponents: any = [];
  ownershipComponent: any;
  activeEntitlementsOfComponent: any = [];
  ownershipEntitlement: any;
  defaultOwner: any = [];
  nameFlag2: boolean = false;

  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public pagesService: PagesService,
    private entitlementService: ManageEntitlementsService,) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.CreateComponentForm.patchValue(JSON.parse(data));
    }
  }

  ngOnInit(): void {
    this.rebacFlag = REBAC_UM_ENTITLEMENT_FLAG + "";
    this.appId = this.config?.data?.id;
    this.getComponentTypeList();
    this.getComponentClientProtocolList();
    this.getComponentAccessTypeList();
    this.getSaasStatus();
    this.CreateComponentForm.get('ownershipEntitlement').disable();
    if (this.rebacFlag == "false") {
      this.CreateComponentForm.controls['ownershipComponent'].clearValidators();
      this.CreateComponentForm.controls['ownershipComponent'].updateValueAndValidity();
      this.CreateComponentForm.controls['ownershipEntitlement'].clearValidators();
      this.CreateComponentForm.controls['ownershipEntitlement'].updateValueAndValidity();
      this.CreateComponentForm.controls['primeComponentId'].clearValidators();
      this.CreateComponentForm.controls['primeComponentId'].updateValueAndValidity();
    }
    else if (this.rebacFlag == "true") {
      this.CreateComponentForm.controls["redirectURL"].setValue("https://localtesting.test/");
      this.getAllActiveComponents();
      this.getUnmapEmailTemplatesByApplicationId();
    }
    
  }

  getSaasStatus() {
    this.pagesService.getSaasStatusComponent(this.appId).subscribe(results => {
      const responseData = results.success.result;
      if (responseData) {
        this.saasFlag = responseData;
        this.CreateComponentForm.value.isSaasAppComponent = this.saasFlag;
      }
      if (!this.saasFlag) {
        this.CreateComponentForm.controls['cpuThreshold'].clearValidators();
        this.CreateComponentForm.controls['cpuThreshold'].updateValueAndValidity();
        this.CreateComponentForm.controls['ramThreshold'].clearValidators();
        this.CreateComponentForm.controls['ramThreshold'].updateValueAndValidity();
        this.CreateComponentForm.controls['artifactType'].clearValidators();
        this.CreateComponentForm.controls['artifactType'].updateValueAndValidity();
        this.CreateComponentForm.controls['imagePath'].clearValidators();
        this.CreateComponentForm.controls['imagePath'].updateValueAndValidity();
        this.CreateComponentForm.controls['enterKey_dbUrl'].clearValidators();
        this.CreateComponentForm.controls['enterKey_dbUrl'].updateValueAndValidity();
        this.CreateComponentForm.controls['enterKey_dbPassword'].clearValidators();
        this.CreateComponentForm.controls['enterKey_dbPassword'].updateValueAndValidity();
        this.CreateComponentForm.controls['enterKey_dbUsername'].clearValidators();
        this.CreateComponentForm.controls['enterKey_dbUsername'].updateValueAndValidity();
        this.CreateComponentForm.controls['enterKey_idpRealm'].clearValidators();
        this.CreateComponentForm.controls['enterKey_idpRealm'].updateValueAndValidity();
        this.CreateComponentForm.controls['enterKey_idpUrl'].clearValidators();
        this.CreateComponentForm.controls['enterKey_idpUrl'].updateValueAndValidity();
        this.CreateComponentForm.controls['enterKey_idpClientId'].clearValidators();
        this.CreateComponentForm.controls['enterKey_idpClientId'].updateValueAndValidity();
        this.CreateComponentForm.controls['enterKey_idpClientSecret'].clearValidators();
        this.CreateComponentForm.controls['enterKey_idpClientSecret'].updateValueAndValidity();
        this.CreateComponentForm.controls['contextPath'].clearValidators();
        this.CreateComponentForm.controls['contextPath'].updateValueAndValidity();
        this.CreateComponentForm.controls['port'].clearValidators();
        this.CreateComponentForm.controls['port'].updateValueAndValidity();
        this.formValidFlag = false;
        this.dbformValidFlag = false;
      }

    });

  }
  /**
   * Create Component in component tab of Application onboarding
   */

  //get component type list API -dropdown

  getComponentTypeList(): void {
    this.pagesService.getComponentTypeList().subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.componentTypeName;
          data.value = element.id;
          this.selectList.push(data);
        });
        this.selectList = [...this.selectList];
      }
    });
  }

  onClose(): void {
    this.ref.close();
  }

  //get client protocol list API -dropdown
  getComponentClientProtocolList(): void {
    this.pagesService.getComponentClientProtocolList().subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.clientProtocalName;
          data.value = element.id;
          this.selectList1.push(data);
        });
        this.selectList1 = [...this.selectList1];
      }
    });
  }

  //get access type list API -dropdown
  getComponentAccessTypeList(): void {
    this.pagesService.getComponentAccessTypeList().subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.accessTypeName;
          data.value = element.id;
          this.selectList2.push(data);
        });
        this.selectList2 = [...this.selectList2];
      }
    });
  }
  onSelectEnvVariables(event: any): void {
    this.solImageFileSize = false;
    this.imagePlacheholder = false;
    this.imagevalid = false;
    this.formValidFlag = false;
    this.logo = new File([event.files[0]], event.files[0].name, {
      type: event.files[0].type
    });
    this.imageFileFlag = true;
    const isvalid = /(\.json)$/i;
    if (isvalid.test(this.logo.name)) {
      this.default = true;
      this.solImage = false;
      this.solImageFileSize = false;
      const regex = /[~#%&*{}:<>?/|'"]/g;
      this.productPrimaryImage = event.currentFiles[0].objectURL;
      let res = regex.test(this.logo.name);
      if (res) {
        this.solImage = true;
      }
      else if (event.currentFiles[0].size > 500000) {
        this.solImageFileSize = true;
        this.formValidFlag = true;
      }
      else {
        setTimeout(() => {
        }, 1000);

      }
    } else {
      this.imagevalid = true;
      this.formValidFlag = true;
    }
  }

  console() {
    console.log("form ", this.CreateComponentForm)
  }
  onSelectDbMigration(event: any): void {
    this.solImageFileSize = false;
    this.dbImagePlacheholder = false;
    this.dbImagevalid = false;
    this.dbformValidFlag = false;
    this.dbLogo = new File([event.files[0]], event.files[0].name, {
      type: event.files[0].type
    });
    this.imageFileFlag = true;
    const isvalid = /(\.sql)$/i;
    if (isvalid.test(this.dbLogo.name)) {
      this.default = true;
      this.solImage = false;
      this.solImageFileSize = false;
      const regex = /[~#%&*{}:<>?/|'"]/g;
      this.productPrimaryImage = event.currentFiles[0].objectURL;
      let res = regex.test(this.dbLogo.name);
      if (res) {
        this.solImage = true;
      }
      else if (event.currentFiles[0].size > 1000000) {
        this.solImageFileSize = true;
        this.dbformValidFlag = true;
      }
      else {
        setTimeout(() => {
        }, 1000);

      }
    } else {
      this.dbImagevalid = true;
      this.dbformValidFlag = true;
    }
  }
  //create component API 

  onRemoveEnvVariables(event: any, fileUploadPageIconChooseFilebtn): void {
    const index = fileUploadPageIconChooseFilebtn.files.indexOf(
      event
    );
    fileUploadPageIconChooseFilebtn.files.splice(index, 1);
    this.productPrimaryImage = "";
    this.imagevalid = false;
    this.formValidFlag = true;
    this.solImageFileSize = false;
    setTimeout(() => {
    }, 1000);
  }
  onRemoveDbMigration(event: any, fileUploadPageIconChooseFilebtn1): void {
    const index = fileUploadPageIconChooseFilebtn1.files.indexOf(
      event
    );
    fileUploadPageIconChooseFilebtn1.files.splice(index, 1);
    this.productPrimaryImage = "";
    this.dbImagevalid = false;
    this.dbformValidFlag = true;
    this.solImageFileSize = false;
    setTimeout(() => {
    }, 1000);
  }

  public formDataReqSo() {
    const formdata = new FormData();
    formdata.append('componentName', this.CreateComponentForm.value.componentName);
    if (this.CreateComponentForm.value.componentType != null) {
      formdata.append('componentTypeId', this.CreateComponentForm.value.componentType);
    }
    if (this.CreateComponentForm.value.clientProtocol != null) {
      formdata.append('clientProtocalId', this.CreateComponentForm.value.clientProtocol);
    }
    if (this.CreateComponentForm.value.accessType != null)
      formdata.append('accessTypeId', this.CreateComponentForm.value.accessType);
    formdata.append('rootUrl', this.CreateComponentForm.value.rootURL);
    formdata.append('validRedirectUrl', this.CreateComponentForm.value.redirectURL);
    formdata.append('baseUrl', this.CreateComponentForm.value.baseURL);
    formdata.append('adminUrl', this.CreateComponentForm.value.adminURL);
    formdata.append('webOrigins', this.CreateComponentForm.value.webOrigins);
    formdata.append('backchannelLogoutUrl', this.CreateComponentForm.value.backchannelURL);
    formdata.append('description', this.CreateComponentForm.value.description);
    if (this.saasFlag) {
      if (!this.CreateComponentForm.value.withVolume) {
        this.CreateComponentForm.value.withVolume = false;
      }
      if (!this.CreateComponentForm.value.createVolume) {
        this.CreateComponentForm.value.createVolume = false;
      }
      if (!this.CreateComponentForm.value.createRole) {
        this.CreateComponentForm.value.createRole = false;
      }
      formdata.append('withVolume', this.CreateComponentForm.value.withVolume);
      formdata.append('createVolume', this.CreateComponentForm.value.createVolume);
      formdata.append('createRole', this.CreateComponentForm.value.createRole);
      formdata.append('port', this.CreateComponentForm.value.port);
      formdata.append('contextpath', this.CreateComponentForm.value.contextPath);
      formdata.append('dbMigrationRequired', this.CreateComponentForm.value.db_migration_checkbox);
      if (this.CreateComponentForm.value.db_migration_checkbox)
        formdata.append('dbMigrationFile', this.dbLogo);
      formdata.append('envVarFile', this.logo);
      formdata.append('cpuThresholdValue', this.CreateComponentForm.value.cpuThreshold);
      formdata.append('ramThresholdValue', this.CreateComponentForm.value.ramThreshold);
      formdata.append('artifactType', this.CreateComponentForm.value.artifactType);
      if (this.CreateComponentForm.value.artifactType == "image")
        formdata.append('imagePath', this.CreateComponentForm.value.imagePath);
      if (this.CreateComponentForm.value.artifactType == "jar")
        formdata.append('artifactRepoPath', this.CreateComponentForm.value.imagePath)
      formdata.append('dbUrl', this.CreateComponentForm.value.enterKey_dbUrl);
      formdata.append('dbUsername', this.CreateComponentForm.value.enterKey_dbUsername);
      formdata.append('dbPass', this.CreateComponentForm.value.enterKey_dbPassword);
      formdata.append('idpUrl', this.CreateComponentForm.value.enterKey_idpUrl);
      formdata.append('idpRealm', this.CreateComponentForm.value.enterKey_idpRealm);
      formdata.append('idpClientId', this.CreateComponentForm.value.enterKey_idpClientId);
      formdata.append('idpClientSecret', this.CreateComponentForm.value.enterKey_idpClientSecret);
      formdata.append('saasAppComponent', "true");
    }
    if (this.rebacFlag == "true") {
      formdata.append('ownershipComponent', this.CreateComponentForm.value.ownershipComponent.PRIME_COMPONENT_ID);
      formdata.append('ownershipEntitlement', this.CreateComponentForm.value.ownershipEntitlement.ENTITLEMENT_ID);
      formdata.append('primeComponentId', this.CreateComponentForm.value.primeComponentId);
      formdata.append('emailTemplateId', this.CreateComponentForm.value.emailTemplate);
    }
    return formdata;
  }
  createComponent(): void {
    const reqBody = {};
    const formdata = this.formDataReqSo();
    console.log(formdata);
    this.pagesService.onSaveCreateComponent(formdata, this.appId).subscribe(
      {
        next: results => {
          if (results.error?.messageCode == "CIAM 105") {
            this.nameFlag2 = true;
            document.getElementById('primeComponentId_id')!.style.borderColor = 'red';
          }
          else {
            this.ref.close("Application Component Created Successfully");
          }

        },
        error: error => {
          if (error.error.error?.messageCode == "CIAM 105") {
            this.nameFlag = true;
            document.getElementById('displayName_id')!.style.borderColor = 'red';
          }
          else {
            this.ref.close("Application Component Creation Failed");
          }
        }
      });
  }

  getAllActiveComponents() {
    let data = {};
    this.entitlementService.getAllActiveComponents(data).subscribe(
      response => {
        this.allActiveComponents = response;
      }
    );
  }

  onChangeCompoent(event) {
    this.CreateComponentForm.get('ownershipEntitlement').enable();
    this.ownershipComponent = event.value;
    this.getActiveEntitlementsByComponentId(this.ownershipComponent.PRIME_COMPONENT_ID);
  }
  getActiveEntitlementsByComponentId(componentId) {
    this.entitlementService.getActiveEntitlementsByComponentId(componentId).subscribe(
      response => {
        this.activeEntitlementsOfComponent = response;
        this.ownershipEntitlement = this.activeEntitlementsOfComponent.filter(
          p => p.ID === this.defaultOwner.OWNER_ENTITLEMENT_ID
        )[0];
      }
    );
  }

  getUnmapEmailTemplatesByApplicationId(){
    let obj={
      "applicationId":this.appId
    };

    this.pagesService.getUnmapEmailTemplatesByApplicationId(obj).subscribe(
      results =>{
        const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.templateName;
          data.value = element.id;
          this.selectemailTempList.push(data);
        });
        this.selectemailTempList = [...this.selectemailTempList];
      }
        
      }
    )
  }
}