import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PagesService } from '../pages.service';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
@Component({
  selector: 'jhi-deleteattribute',
  templateUrl: './CancelRequest.component.html',
  styleUrls: ['./CancelRequest.component.scss'],
})
export class CancelRequestComponent implements OnInit {
  CancelRequestForm = this.fb.group({
  });
  transactionId: any;
  constructor(private router: Router, protected activatedRoute: ActivatedRoute, private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    private messageService: MessageService,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.CancelRequestForm.patchValue(JSON.parse(data));
    }

  }
  ngOnInit(): void {
    this.transactionId = this.config?.data?.id;
  }

  onClickNo($event: UntypedFormGroup): void {
    this.ref.close();
  }

  deleteRole() {
    this.transactionId = this.config?.data?.id;
    const reqBody = {};
    reqBody["tenantId"] = this.pagesService.tenantId;
    reqBody["transactionId"] = this.transactionId;
    const formData = reqBody;
    this.pagesService.CancelRequest(formData).subscribe({
      next: (results: any) => {
        this.ref.close('Sucess');
      },
      error: error => {
        this.ref.close('Failed');
      }
    });


  }

}