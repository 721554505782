import { Injectable } from "@angular/core";
import { USER_MANAGEMENT_COMPONENT_ID } from "app/constants";
import { LoggingEventService } from "app/logging-event.service";
import { ContextName, EventName, ItemName } from "app/shared/constants/logging-event.constants";
import { IEventContext, IEventData, IEventSubject } from "app/shared/interface/event.interface";

@Injectable({
    'providedIn': 'root'
})
export class RoleManagementLoggingService {
    eventTypes: { [name: string]: number } = {};
    contextList: { [name: string]: number } = {};
    eventItems: { [name: string]: number } = {};
    constructor(
        private loggingService: LoggingEventService
    ) {
    }

    async prepareSaveRoleLogData(data) {
        const eventDataArray = [];
        let eventData: IEventData = await this.loggingService.getEventData(EventName.CREATE_NEW_ROLE, true, true, USER_MANAGEMENT_COMPONENT_ID, ContextName.VIEW_CREATE_ROLE_PAGE);
        let eventSubjects: IEventSubject[] = [
            await this.loggingService.getEventSubjectData(ItemName.ROLE_NAME, data.get('roleName').toString())
        ];
        eventData.eventSubjects = eventSubjects;
        eventDataArray.push(JSON.stringify(eventData));
        return JSON.stringify(eventDataArray);
    }

    async prepareEditRoleLogData(roleDetails:any){
        try{
        const eventDataArray = [];
        let eventData:IEventData=await this.loggingService.getEventData(EventName.EDIT_ROLE,true,true,USER_MANAGEMENT_COMPONENT_ID,ContextName.EDIT_ROLE,roleDetails.ROLE_ID+' - '+roleDetails.ROLE_NAME);
        let eventSubjects:IEventSubject[]=[];
        for (const e of roleDetails?.ENTITLEMENT_LIST){
            eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.ADD_ENTITLEMENT,e.COMPONENT_ID+'-'+e.ENTITLEMENT_ID+' - '+e.ENTITLEMENT_NAME,null));
            for(const u of roleDetails?.ROLE_USERS_LIST){
                let userType=u.USER_TYPE_ID==1?'Internal':'External';
                eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.ADD_ENTITLEMENT_ACCESS,u.USER_ID+'*'+u.LOGIN_ID+'*'+e.COMPONENT_ID+'*'+e.ENTITLEMENT_ID+'*'+e.ENTITLEMENT_NAME+'#'+u.USER_NAME+'-'+ userType +'-'+u.STATUS));
                //eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.ADD_ENTITLEMENT_ACCESS,u.USER_ID+'*'+u.LOGIN_ID+'*'+e.COMPONENT_ID+'*'+e.ENTITLEMENT_ID+'*'+e.ENTITLEMENT_NAME,null));
            }
        }
        for (const e of roleDetails?.DELETE_ROLE_ENTITLEMENT){
            eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.REMOVE_ENTITLEMENT,null,e.COMPONENT_ID+'-'+e.ENTITLEMENT_ID+' - '+e.ENTITLEMENT_NAME));
        }
        for (const u of roleDetails?.DELETE_ROLE_USER){
            eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.DELETE_USER,null,u.USER_ID+' - '+u.LOGIN_ID));
        }
        
                for (const u of roleDetails?.ROLE_USER_LIST){
                    eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.ADD_USER,u.USER_ID+' - '+u.LOGIN_ID,null));
        
                    for(let i=0;i<roleDetails.ROLE_ENT_LIST.length;i++){
                        eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.ADD_ENTITLEMENT_ACCESS,u.USER_ID+'*'+u.LOGIN_ID+'*'+roleDetails.ROLE_ENT_LIST[i].COMPONENT_ID+'*'+roleDetails.ROLE_ENT_LIST[i].ENTITLEMENT_ID+'*'+roleDetails.ROLE_ENT_LIST[i].ENTITLEMENT_NAME,null));
                    }
                }

                for(const e of roleDetails?.USER_DELETE_ENT_LIST){
                    eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.REMOVE_ENTITLEMENT_ACCESS,null,e.USER_ID+'*'+e.LOGIN_ID+'*'+e.COMPONENT_ID+'*'+e.ENTITLEMENT_ID+'*'+e.ENTITLEMENT_NAME));
                }
                for(const e of roleDetails?.ROLE_DELETED_USERS_LIST){
                    eventSubjects.push(await this.loggingService.getEventSubjectData(ItemName.REMOVE_ENTITLEMENT_ACCESS,null,e.USER_ID+'*'+e.LOGIN_ID+'*'+e.COMPONENT_ID+'*'+e.ENTITLEMENT_ID+'*'+e.ENTITLEMENT_NAME));
                }
                eventData.eventSubjects=eventSubjects;
                eventDataArray.push(JSON.stringify(eventData));
                return JSON.stringify(eventDataArray);
            }catch(error){
                console.error('error >>>>>',error);
            }
        


        
 
    }

    async prepareViewManageRoleListData(filter: any) {
        const eventDataArray = [];
        if ((!(filter.hasOwnProperty('SEARCH_TEXT'))) && (filter.isRolesPageChanged == 'false') && (filter.pageIndex == 1)) {
            let eventData: IEventData = await this.loggingService.getEventData(EventName.MANAGE_ROLE, false, true, USER_MANAGEMENT_COMPONENT_ID, ContextName.VIEW_MANAGE_ROLE_PAGE);
            eventDataArray.push(JSON.stringify(eventData));
        }
        if (filter.hasOwnProperty('SEARCH_TEXT') || filter.SEARCH_TEXT != null || filter.SEARCH_TEXT != undefined) {
            let eventData: IEventData = await this.loggingService.getEventData(EventName.SEARCH_ROLE, true, true, USER_MANAGEMENT_COMPONENT_ID, ContextName.SEARCH_DATA);
            let eventSubjects: IEventSubject[] = [
                await this.loggingService.getEventSubjectData(ItemName.SEARCH_DATA, filter.SEARCH_TEXT)
            ];
            eventData.eventSubjects = eventSubjects;
            eventDataArray.push(JSON.stringify(eventData));
        }
        if (filter.isRolesPageChanged == 'true' || filter.pageIndex != 1) {
            let eventData: IEventData = {
                eventTypeId: await this.loggingService.getEventId(EventName.PAGINATION_MANAGE_ROLES),
                componentId: USER_MANAGEMENT_COMPONENT_ID,
                isEventSubject: false,
                isEventContext: true,
                eventName: EventName.PAGINATION_MANAGE_ROLES
            };
            let eventContextListData: IEventContext[] = [];
            eventContextListData.push(
                await this.loggingService.getEventContextData(ContextName.PAGE_NUMBER, filter.pageIndex),
                await this.loggingService.getEventContextData(ContextName.PAGE_SIZE, filter.pageSize));
            eventData.eventContextList = eventContextListData;
            eventDataArray.push(JSON.stringify(eventData));
        }
        return JSON.stringify(eventDataArray);
    }
    async prepareViewRoleLogData(filter: any) {
        const eventDataArray = [];
        if (filter.changeRoleFlag.toString() == 'true') {
            let eventData: IEventData = await this.loggingService.getEventData(EventName.CHANGE_ROLE, false, true, USER_MANAGEMENT_COMPONENT_ID, ContextName.VIEW_ROLE_DETAILS_PAGE, filter.roleId + ' - ' + filter.roleName);
            eventDataArray.push(JSON.stringify(eventData));
            return JSON.stringify(eventDataArray);
        } else {
            let eventData: IEventData = await this.loggingService.getEventData(EventName.EDIT_ROLE, false, true, USER_MANAGEMENT_COMPONENT_ID, ContextName.VIEW_ROLE_DETAILS_PAGE, filter.roleId + ' - ' + filter.roleName);
            eventDataArray.push(JSON.stringify(eventData));
            return JSON.stringify(eventDataArray);
        }
    }
}