<div>
    <div class="p-d-flex p-jc-end">
        <p-messages key="myKey1" class="custom-alert-box" *ngIf="this.messageFlag"></p-messages>
    </div>
    <div class="p-col-fixed p-mt-1 p-ml-1 p-pr-0 custom-breadcrumb">
        <p-breadcrumb [model]="ccprimebreadcrumb1List" [home]="homeiconccprimebreadcrumb1">
        </p-breadcrumb>
    </div>
    <form class="layout-content custom-form-layout" [formGroup]="EditUserForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6 p-offset-0 left">
                <h5 class="custom-head1">Edit User</h5>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 left custom-card-shadow"
                style="margin-bottom: 32px;">
                <p-card>
                    <!-- TAB VIEW -->
                    <div class="p-pl-2 p-pr-2 p-mt-2">
                        <button pButton type="button" (click)="activeIndex1 = 0"
                            class="p-button-outlined p-button-lg tabButton personal-details" label="Personal Details"
                            [style.box-shadow]="activeIndex1 == 0 ? '1px 5px 20px #bdc0c9' :  '0px 1px 4px #bdc0c9'"
                            [style.border]="activeIndex1 == 0 ? '1px solid #396FC5' :  '#add8e6'"
                            [style.color]=" activeIndex1 == 0 ? '#396FC5' : '#6C7387'">
                            <div class="fa-2x">
                                <span class="fa-layers fa-fw">
                                    <label class="c-icons personal-icon"></label>
                                </span>
                            </div>
                            <span class="p-button-icon p-button-icon-left reshot-icon-business-team"
                                aria-hidden="true"></span>
                            <label class="d-icons check-icon personal-details-label" width="14" height="14"
                                *ngIf="tab1"></label>
                        </button>
                        <button pButton type="button" (click)="activeIndex1 = 1" label="Roles" id="panelbtn2"
                            class="p-button-outlined p-button-lg tabButton roles"
                            [style.box-shadow]="activeIndex1 == 1 ? '1px 5px 20px #bdc0c9' :  '0px 1px 4px #bdc0c9'"
                            [style.border]="activeIndex1 == 1 ? '1px solid  #396FC5' :  '#add8e6'"
                            [style.color]=" activeIndex1 == 1 ? '#396FC5' : '#6C7387'"
                            *ngIf="this.pagesService.pageRebacFlag=='false'">
                            <div class="fa-2x">
                                <span class="fa-layers fa-fw">
                                    <label class="c-icons role-icon"></label>
                                </span>
                            </div>
                            <span class="p-button-icon p-button-icon-left reshot-icon-business-team"
                                aria-hidden="true"></span>
                            <label class="d-icons check-icon roles-label" width="14" height="14" *ngIf="tab2"></label>
                        </button>
                        <button pButton type="button" (click)="activeIndex1 = 2" label="Group" id="panelbtn3"
                            class="p-button-outlined p-button-lg tabButton group"
                            [style.box-shadow]="activeIndex1 == 2 ? '1px 5px 20px #bdc0c9' :  '0px 1px 4px #bdc0c9'"
                            [style.border]="activeIndex1 == 2 ? '1px solid  #396FC5' :  '#add8e6'"
                            [style.color]=" activeIndex1 == 2 ? '#396FC5' : '#6C7387'"
                            *ngIf="this.pagesService.pageRebacFlag=='false'">
                            <div class="fa-2x">
                                <span class="fa-layers fa-fw">
                                    <label class="c-icons group-icon"></label>
                                </span>
                            </div>
                            <span class="p-button-icon p-button-icon-left reshot-icon-business-team"
                                aria-hidden="true"></span>
                            <label class="d-icons check-icon group-label" width="14" height="14" *ngIf="tab3"></label>
                        </button>
                        <button pButton type="button" (click)="activeIndex1 = 3" label="Entitlement Details"
                            id="panelbtn4" class="p-button-outlined p-button-lg tabButton entDetails"
                            [style.box-shadow]="activeIndex1 == 3 ? '1px 5px 20px #bdc0c9' :  '0px 1px 4px #bdc0c9'"
                            [style.border]="activeIndex1 == 3 ? '1px solid  #396FC5' :  '#add8e6'"
                            [style.color]=" activeIndex1 == 3 ? '#396FC5' : '#6C7387'"
                            *ngIf="this.pagesService.pageRebacFlag=='true'">
                            <div class="fa-2x">
                                <span class="fa-layers fa-fw">
                                    <label class="entD-icons entitlementDetails-icon"></label>
                                </span>
                            </div>
                            <span class="p-button-icon p-button-icon-left reshot-icon-business-team"
                                aria-hidden="true"></span>
                            <label class="d-icons check-icon entDetails-label" width="14" height="14"
                                *ngIf="tab4"></label>
                        </button>
                    </div>
                    <div class="tabview-custom">
                        <p-tabView [(activeIndex)]="activeIndex1" styleClass="tabview-custom">
                            <p-tabPanel>
                                <div class="p-fluid p-formgrid p-grid p-mt-3">
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 left">
                                        <h5 style="font-size: 14px;">User Details</h5>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left">
                                        <div class=" p-fluid   p-field    ">
                                            <label
                                                [ngClass]="{'field-required': EditUserForm.get('firstName')?.errors?.required}">
                                                First Name
                                            </label> <span class='invalid-feedback-astrik'>*</span>
                                            <input type="text" id="firstName_id" name="firstName_name"
                                                formControlName="firstName" pInputText placeholder="First name"
                                                class="p-inputtext inputtextHeight  "
                                                pattern="^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$" maxlength="50"
                                                (keyup)="enableBtn1=false" />
                                            <div class="p-error"
                                                *ngIf="EditUserForm.get('firstName')!.invalid && (EditUserForm.get('firstName')!.dirty || EditUserForm.get('firstName')!.touched)">
                                                <div *ngIf="EditUserForm.get('firstName')?.errors?.required">
                                                    <label class="alert-icon alert-circle-icon"></label>
                                                    <span class="p-error">First name is required.</span>
                                                </div>
                                                <div *ngIf="EditUserForm.get('firstName').errors.pattern">
                                                    <label class="alert-icon alert-circle-icon"></label>
                                                    <span class="p-error">First Name should not have Special Character
                                                        and should not exceed 50 characters</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                                        <div class=" p-fluid   p-field    ">
                                            <label>
                                                Middle Name
                                            </label>
                                            <input type="text" id="middleName_id" name="middleName_name"
                                                formControlName="middleName" pInputText placeholder="Middle name"
                                                class="p-inputtext inputtextHeight  "
                                                pattern="^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$" maxlength="50"
                                                (keyup)="enableBtn1=false" />
                                            <div class="p-error"
                                                *ngIf="EditUserForm.get('middleName')!.invalid && (EditUserForm.get('middleName')!.dirty || EditUserForm.get('middleName')!.touched)">
                                                <div *ngIf="EditUserForm.get('middleName').errors.pattern">
                                                    <label class="alert-icon alert-circle-icon"></label>
                                                    <span class="p-error">Middle Name should not have Special Character
                                                        and should not exceed 50 characters</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                                        <div class=" p-fluid   p-field    ">
                                            <label
                                                [ngClass]="{'field-required': EditUserForm.get('lastName')?.errors?.required}">
                                                Last Name
                                            </label> <span class='invalid-feedback-astrik'>*</span>
                                            <input type="text" id="lastName_id" name="lastName_name"
                                                formControlName="lastName" pInputText placeholder="Last name"
                                                class="p-inputtext inputtextHeight  "
                                                pattern="^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$" maxlength="50"
                                                (keyup)="enableBtn1=false" />
                                            <div class="p-error"
                                                *ngIf="EditUserForm.get('lastName')!.invalid && (EditUserForm.get('lastName')!.dirty || EditUserForm.get('lastName')!.touched)">
                                                <div *ngIf="EditUserForm.get('lastName')?.errors?.required">
                                                    <label class="alert-icon alert-circle-icon"></label>
                                                    <span class="p-error">Last name is required.</span>
                                                </div>
                                                <div *ngIf="EditUserForm.get('lastName').errors.pattern">
                                                    <label class="alert-icon alert-circle-icon"></label>
                                                    <span class="p-error">Last Name should not have Special Character
                                                        and should not exceed 50 characters</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                                        <div class="p-fluid p-field">
                                            <label
                                                [ngClass]="{'field-required': EditUserForm.get('emailAddress')?.errors?.required}">
                                                Email Address
                                            </label>
                                            <span class="invalid-feedback-astrik">*</span>
                                            <span class="p-input-icon-right p-mb-0">
                                                <input pInputText type="email" id="emailAddress_id"
                                                    *ngIf="this.rebacFlag=='false'" style="margin-bottom: 0px;"
                                                    name="emailAddress_name" formControlName="emailAddress" pInputText
                                                    placeholder="Email address" class="p-inputtext inputtextHeight"
                                                    (keyup)="this.emailFlag = false;this.emailInvalidFlag = false;enableBtn1=false;colorChange();onCheckEmail();" />
                                                <span *ngIf="this.rebacFlag=='true'" class="p-d-flex">
                                                    <input pInputText type="email" id="emailAddress_id"
                                                        style="margin-bottom: 0px;" name="emailAddress_name"
                                                        formControlName="emailAddress" pInputText
                                                        placeholder="Email address" class="p-inputtext inputtextHeight"
                                                        (keyup)="this.emailFlag = false;this.emailInvalidFlag = false;enableBtn1=false;onCheckEmail();" />
                                                </span>
                                            </span>

                                            <div class="invalid-feedback"
                                                *ngIf="EditUserForm.get('emailAddress')!.invalid &&(EditUserForm.get('emailAddress')!.dirty ||EditUserForm.get('emailAddress')!.touched)">
                                                <label *ngIf="EditUserForm.get('emailAddress')?.errors?.required">
                                                    <div class="custom-font">
                                                        <label class="alert-icon alert-circle-icon"
                                                            for="rolename"></label>
                                                        <medium id="rolename"
                                                            style="display: inline-block;vertical-align: bottom;"
                                                            class="p-error">
                                                            Email address is required!
                                                        </medium>
                                                    </div>
                                                </label>
                                                <label
                                                    *ngIf="EditUserForm.get('emailAddress')?.errors?.pattern && this.emailInvalidFlag==false"
                                                    class="p-error">
                                                    <div class="custom-font">
                                                        <label class="alert-icon alert-circle-icon"
                                                            for="rolename"></label>
                                                        <medium id="rolename"
                                                            style="display: inline-block;vertical-align: bottom;"
                                                            class="p-error">Email ID input is not in Email ID format
                                                        </medium>
                                                    </div>
                                                </label>
                                            </div>
                                            <div class="invalid-feedback">
                                                <label *ngIf="this.emailFlag" class="p-error">
                                                    <div class="custom-font">
                                                        <label class="alert-icon alert-circle-icon"
                                                            for="rolename"></label>
                                                        <medium id="email" class="p-error">
                                                            Email address already exists!
                                                        </medium>
                                                    </div>
                                                </label>
                                                <label *ngIf="this.emailInvalidFlag" class="p-error">
                                                    <div class="custom-font">
                                                        <label class="alert-icon alert-circle-icon"
                                                            for="rolename"></label>
                                                        <medium id="email" class="p-error">
                                                            Email ID input is not in Email ID format
                                                        </medium>
                                                    </div>
                                                </label>
                                            </div>
                                            <div>
                                                <!-- <label *ngIf="this.noUserFound" class="p-error">
													<div class="custom-font">
														<label class="alert-icon alert-circle-icon"
															for="rolename"></label>
														<medium id="email" class="p-error">
															User Not Found
														</medium>
													</div>
												</label>
												<label *ngIf="this.userFound" class="p-error">
													<div class="custom-font">
														<label class="d-icons check-icon custom-label"
															style="position: relative;left: 0;"></label>
														<medium id="email" style="color: green;">
															User Found
														</medium>
													</div>
												</label> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left custom-user-input">
                                        <div class=" p-fluid   p-field    ">
                                            <label>
                                                Mobile number
                                            </label>
                                            <div class="inputtext-medium custom-drop">
                                                <p-dropdown [filter]="true" filterBy="name,label" id="codeid"
                                                    name="code" placeholder="code" [options]="codes"
                                                    [autoDisplayFirst]="false" formControlName="countryCode"
                                                    (onChange)="CheckNumber('setCode')" [showClear]="true"
                                                    class="dropdownHeight">
                                                    <ng-template let-item pTemplate="item">
                                                        <div class="drop-item" style="display: inline;"
                                                            *ngIf="item.name.length <= 35;">
                                                            {{item.label}} {{ item.name }}
                                                        </div>
                                                        <div class="drop-item" style="display: inline;"
                                                            *ngIf="item.name.length > 35;" pTooltip="{{item.name}}"
                                                            tooltipPosition="bottom">
                                                            {{item.label}} {{ item.name.substring(0,35) + "..." }}
                                                        </div>
                                                    </ng-template>
                                                </p-dropdown>
                                                <span class="p-input-icon-right" style="width:195%">
                                                    <input type="text" id="phoneNumbers_id" name="phoneNumbers_name"
                                                        formControlName="phoneNumbers" pInputText
                                                        placeholder="Mobile number"
                                                        class="p-inputtext inputtextHeight  " pattern="[0-9]{1,}"
                                                        minLength="1" (keyup)="CheckNumber('setNumber')" />
                                                </span>
                                            </div>
                                            <div class="invalid-feedback" *ngIf="
                                                                EditUserForm.get('phoneNumbers')!.invalid &&
																	(EditUserForm.get('phoneNumbers')!.dirty ||
                                                                    EditUserForm.get('phoneNumbers')!.touched)
																">
                                                <div *ngIf="
                                                                    EditUserForm.get('phoneNumbers').errors
																			.pattern
																	">
                                                    <label class="alert-icon alert-circle-icon"></label>
                                                    <medium class="p-error">Phone Number should not have special
                                                        characters &
                                                        alphabets</medium>
                                                </div>
                                            </div>
                                            <div class="invalid-feedback">
                                                <div *ngIf="
																	this.codeFlag
																">
                                                    <label class="alert-icon alert-circle-icon"></label>
                                                    <medium class="p-error">Country code is required</medium>
                                                </div>
                                                <div *ngIf="
																	this.phoneNumberFlag
																">
                                                    <label class="alert-icon alert-circle-icon"></label>
                                                    <medium class="p-error">Phone Number is required</medium>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left"
                                        *ngIf="this.rebacFlag=='false'">

                                        <div class="p-fluid">
                                            <label
                                                [ngClass]="{'field-required': EditUserForm.get('selectSwitch')?.errors?.required}"
                                                *ngIf="isExternal==null">
                                                User Type
                                            </label>
                                            <label *ngIf="isExternal!=null">
                                                User Type
                                            </label>

                                            <span class='invalid-feedback-astrik' *ngIf="isExternal==null">*</span>
                                            <div class="p-field p-fluid">
                                                <p-selectButton id="selectSwitch_id" name="selectSwitch_name"
                                                    [(ngModel)]="isExternal" [options]="selectSwitchList"
                                                    formControlName="selectSwitch" styleClass="default"
                                                    (onChange)="enableBtn1=false;emailDisableEnable();">
                                                    <ng-template let-item>
                                                        <span class="p-button-label">{{item.label}}</span>
                                                    </ng-template>
                                                </p-selectButton>
                                                <div class="p-error"
                                                    *ngIf="EditUserForm.get('selectSwitch')!.invalid && (EditUserForm.get('selectSwitch')!.dirty || EditUserForm.get('selectSwitch')!.touched) || isExternal==null">
                                                    <div *ngIf="EditUserForm.get('selectSwitch')?.errors?.required">
                                                        <label class="alert-icon alert-circle-icon"></label>
                                                        <span class="p-error">Please select one</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left">
                                        <div class=" p-fluid   p-field    ">
                                            <label>
                                                Username
                                            </label>
                                            <input type="text" id="userName_id" name="userName_name"
                                                formControlName="userName" pInputText placeholder="user name"
                                                class="p-inputtext inputtextHeight  " maxlength="50"
                                                (keyup)="enableBtn1=false ; checkUserName()" />
                                            <div class="invalid-feedback">
                                                <div *ngIf="this.userNameCheckError">
                                                    <label class="alert-icon alert-circle-icon"></label>
                                                    <medium class="p-error">Invalid field format
                                                    </medium>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left"
                                        *ngIf="this.rebacFlag=='true'">
                                        <div class=" p-fluid   p-field    ">
                                            <label
                                                [ngClass]="{'field-required': EditUserForm.get('account')?.errors?.required}">
                                                Account
                                            </label>
                                            <span class="invalid-feedback-astrik">*</span>
                                            <p-dropdown id="selectid" name="select" placeholder="No Account Map"
                                                [options]="accountList" [filter]="true" filterBy="label"
                                                [autoDisplayFirst]="false" formControlName="account" [disabled]="true">
                                                <ng-template let-items pTemplate="item">
                                                    <div *ngIf="items?.label?.length &lt;=37;">
                                                        {{items.label}}
                                                    </div>
                                                    <div *ngIf="items?.label?.length > 37;" pTooltip="{{items.label}}"
                                                        tooltipPosition="bottom">
                                                        {{items.label.substring(0,37) + "..." }}
                                                    </div>
                                                </ng-template>
                                            </p-dropdown>
                                        </div>
                                    </div>

                                    <!-- <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left" *ngIf="this.rebacFlag=='true'">
										<div class=" p-fluid   p-field    ">
											<label
												[ngClass]="{'field-required': EditUserForm.get('account')?.errors?.required}">
												Account
											</label>
											<span class="invalid-feedback-astrik">*</span>
														<p-dropdown id="selectid" name="select"
														placeholder="Select Account"
														[options]="accountList" [filter]="true" filterBy="label"
														[autoDisplayFirst]="false" formControlName="account" (onChange)="enableBtn1 = false ; "
														disabled>
														<ng-template let-items pTemplate="item">
															<div *ngIf="items?.label?.length &lt;=37;">
																{{items.label}}
															</div>
															<div *ngIf="items?.label?.length > 37;"
																pTooltip="{{items.label}}" tooltipPosition="bottom">
																{{items.label.substring(0,37) + "..." }}
															</div>
														</ng-template>
													</p-dropdown>
													<div class="invalid-feedback"
												*ngIf="EditUserForm.get('account')!.invalid &&(EditUserForm.get('account')!.dirty ||EditUserForm.get('account')!.touched)">
												<medium *ngIf="EditUserForm.get('account')?.errors?.required"
													class="p-error">
													<label class="alert-icon alert-circle-icon"></label>
													Account name is required!
												</medium>
											</div>
										</div>
									</div> -->


                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 left"
                                        *ngIf="customList.length>0">
                                        <h5 style="font-size: 14px;">Custom Fields</h5>
                                    </div>
                                    <div *ngFor="let customField of customList;let index=index;let count=count"
                                        class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined">
                                        <div *ngIf="customField.fieldInputtype=='Dropdown'">
                                            <div class="p-fluid p-field">
                                                <label *ngIf="customField.mandatoryField==true" [ngClass]="{
																			'field-required': EditUserForm.get(customField.id)?.errors?.required
																			}">
                                                    {{customField.fieldName}}
                                                </label>
                                                <label *ngIf="customField.mandatoryField==false">
                                                    {{customField.fieldName}}
                                                </label>
                                                <span class="invalid-feedback-astrik"
                                                    *ngIf="customField.mandatoryField==true">*</span>
                                                <div class="p-fluid p-field">
                                                    <p-dropdown id="{{customField.id}}id" name="{{customField.id}}"
                                                        placeholder="Select" (onChange)="this.enableBtn1=false;"
                                                        [options]="customField.inputFormat"
                                                        formControlName="{{customField.id}}" [autoDisplayFirst]="false"
                                                        [required]="customField.mandatoryField==true">
                                                    </p-dropdown>
                                                    <div class="invalid-feedback"
                                                        *ngIf="EditUserForm.get(customField.id)!.invalid && (EditUserForm.get(customField.id)!.dirty || EditUserForm.get(customField.id)!.touched) ">
                                                        <div *ngIf="EditUserForm.get(customField.id)?.errors?.required">
                                                            <label class="alert-icon alert-circle-icon"></label>
                                                            <medium class="p-error">This field is required</medium>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="customField.fieldInputtype=='Text'">
                                            <div class="p-fluid p-field">
                                                <label *ngIf="customField.mandatoryField==true" [ngClass]="{
																		'field-required': EditUserForm.get(customField.id)?.errors?.required
																		}">
                                                    {{customField.fieldName}}
                                                </label>
                                                <label *ngIf="customField.mandatoryField==false">
                                                    {{customField.fieldName}}
                                                </label>
                                                <span class="invalid-feedback-astrik"
                                                    *ngIf="customField.mandatoryField==true">*</span>
                                                <div class="p-fluid p-field">
                                                    <input type="text" id="{{customField.id}}_id"
                                                        name="{{customField.id}}_name" pInputText
                                                        formControlName="{{customField.id}}"
                                                        placeholder="Enter {{customField.fieldName}}"
                                                        class="p-inputtext inputtextHeight"
                                                        maxlength="{{customField.maxCharacters}}"
                                                        [required]="customField.mandatoryField==true"
                                                        [pattern]="customField.pattern" (keyup)="enableBtn1 = false" />
                                                    <div class="invalid-feedback"
                                                        *ngIf="EditUserForm.get(customField.id)!.invalid && (EditUserForm.get(customField.id)!.dirty || EditUserForm.get(customField.id)!.touched) ">

                                                        <div *ngIf="EditUserForm.get(customField.id)?.errors?.required">
                                                            <label class="alert-icon alert-circle-icon"></label>
                                                            <medium class="p-error">This field is required</medium>
                                                        </div>
                                                        <div *ngIf="EditUserForm.get(customField.id)?.errors?.pattern">
                                                            <label class="alert-icon alert-circle-icon"></label>
                                                            <medium class="p-error">Invalid field format</medium>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            *ngIf="customField.fieldInputtype=='Input field in range' && customField.inputFormat[0]=='Date range'">
                                            <div class="p-fluid p-field">
                                                <label *ngIf="customField.mandatoryField==true" [ngClass]="{
																			'field-required': EditUserForm.get(customField.id)?.errors?.required
																			}">
                                                    {{customField.fieldName}}
                                                </label>
                                                <label *ngIf="customField.mandatoryField==false">
                                                    {{customField.fieldName}}
                                                </label>
                                                <span class="invalid-feedback-astrik"
                                                    *ngIf="customField.mandatoryField==true">*</span>
                                                <div class="p-fluid p-field">
                                                    <p-calendar class="custom-calender" selectionMode="range"
                                                        dataString="string" [(ngModel)]="this[customField.id]"
                                                        showButtonBar="true" [showIcon]="true" dateFormat="dd-mm-yy"
                                                        inputId="dateformat" placeholder="Select date"
                                                        formControlName="{{customField.id}}"
                                                        [required]="customField.mandatoryField==true"
                                                        (onSelect)="enableBtn1 = false">
                                                    </p-calendar>
                                                    <div class="invalid-feedback"
                                                        *ngIf="EditUserForm.get(customField.id)!.invalid && (EditUserForm.get(customField.id)!.dirty || EditUserForm.get(customField.id)!.touched) ">

                                                        <div *ngIf="EditUserForm.get(customField.id)?.errors?.required">
                                                            <label class="alert-icon alert-circle-icon"></label>
                                                            <medium class="p-error">This field is required</medium>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="customField.fieldInputtype=='Text area'">
                                            <div class="p-fluid p-field">
                                                <label *ngIf="customField.mandatoryField==true" [ngClass]="{
																			'field-required': EditUserForm.get(customField.id)?.errors?.required
																			}">
                                                    {{customField.fieldName}}
                                                </label>
                                                <label *ngIf="customField.mandatoryField==false">
                                                    {{customField.fieldName}}
                                                </label>
                                                <span class="invalid-feedback-astrik"
                                                    *ngIf="customField.mandatoryField==true">*</span>
                                                <div class="p-fluid p-field">
                                                    <textarea pInputTextarea id="{{customField.id}}_id"
                                                        name="{{customField.id}}_name" rows="3"
                                                        formControlName="{{customField.id}}"
                                                        maxlength="{{customField.maxCharacters}}"
                                                        [required]="customField.mandatoryField==true"
                                                        [pattern]="customField.pattern"
                                                        (keyup)="enableBtn1 = false"></textarea>
                                                    <div class="invalid-feedback"
                                                        *ngIf="EditUserForm.get(customField.id)!.invalid && (EditUserForm.get(customField.id)!.dirty || EditUserForm.get(customField.id)!.touched) ">
                                                        <div *ngIf="EditUserForm.get(customField.id)?.errors?.required">
                                                            <label class="alert-icon alert-circle-icon"></label>
                                                            <medium class="p-error">This field is required</medium>
                                                        </div>
                                                        <div *ngIf="EditUserForm.get(customField.id)?.errors?.pattern">
                                                            <label class="alert-icon alert-circle-icon"></label>
                                                            <medium class="p-error">Invalid field format</medium>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            *ngIf="customField.fieldInputtype=='Input field in range' && customField.inputFormat[0]=='Text/Number range'">
                                            <div class="p-fluid p-field">
                                                <label *ngIf="customField.mandatoryField==true" [ngClass]="{
																			'field-required': EditUserForm.get(customField.id+'_start')?.errors?.required && EditUserForm.get(customField.id+'_end')?.errors?.required
																			}">
                                                    {{customField.fieldName}}
                                                </label>
                                                <label *ngIf="customField.mandatoryField==false">
                                                    {{customField.fieldName}}
                                                </label>
                                                <span class="invalid-feedback-astrik"
                                                    *ngIf="customField.mandatoryField==true">*</span>
                                                <div class="inputtext-medium custom-drop">
                                                    <span class="p-input-icon-right p-mr-2" style="width:70%">
                                                        <input type="text" id="{{customField.id}}_id"
                                                            name="{{customField.id}}_name"
                                                            formControlName="{{customField.id}}_start" pInputText
                                                            placeholder="Enter start range"
                                                            class="p-inputtext inputtextHeight "
                                                            [required]="customField.mandatoryField==true"
                                                            (keyup)="enableBtn1 = false" />
                                                    </span>
                                                    <span>-</span>
                                                    <span class="p-input-icon-right p-ml-2" style="width:70%">
                                                        <input type="text" id="{{customField.id}}_id"
                                                            name="{{customField.id}}_name"
                                                            formControlName="{{customField.id}}_end" pInputText
                                                            [required]="customField.mandatoryField==true"
                                                            placeholder="Enter last range"
                                                            class="p-inputtext inputtextHeight"
                                                            (keyup)="enableBtn1 = false" />
                                                    </span>
                                                </div>
                                                <div class="invalid-feedback"
                                                    *ngIf="(EditUserForm.get(customField.id+'_start')!.invalid && (EditUserForm.get(customField.id+'_start')!.dirty || EditUserForm.get(customField.id+'_start')!.touched)) ||  (EditUserForm.get(customField.id+'_end')!.invalid && (EditUserForm.get(customField.id+'_end')!.dirty || EditUserForm.get(customField.id+'_end')!.touched))">
                                                    <div
                                                        *ngIf="(EditUserForm.get(customField.id+'_start')?.errors?.required) || (EditUserForm.get(customField.id+'_end')?.errors?.required)">
                                                        <label class="alert-icon alert-circle-icon"></label>
                                                        <medium class="p-error">This field is required</medium>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined">
                                        <div *ngFor="let customField of checkboxArr;let index=index;let count=count"
                                            class="p-col-12 p-lg-2 p-lg-4 p-xl-4 p-p-0">
                                            <div class="p-fluid p-formgrid p-grid"
                                                *ngIf="customField.fieldInputtype=='Checkbox'">
                                                <div class="p-field-checkbox p-mt-1">
                                                    <p-checkbox [(ngModel)]="this[customField.id]" binary="true"
                                                        name="{{customField.id}}" inputId="binary"
                                                        (onChange)="this.enableBtn1=false;"
                                                        formControlName="{{customField.id}}">
                                                    </p-checkbox>
                                                    <label for="binary">{{customField.fieldName}}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 left"
                                        *ngIf="this.rebacFlag=='true'">
                                        <h5 style="font-size: 14px;">Configuration <a [routerLink]=""
                                                class="add-box-input"
                                                [ngClass]="{'anchor-disabled' : this.accountStatus != 'ACTIVE' || this.USER_STATUS== 'SUSPENDED'}"
                                                (click)="addattributes.toggle($event); openAddAttribute();"
                                                *ngIf="attributeTableList.length>0"><u>+
                                                    Add Parameter</u></a></h5>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 left"
                                        *ngIf="this.rebacFlag=='true'">
                                        <p-table styleClass="p-datatable-gridlines table-height"
                                            [columns]="attributeColumns" [value]="attributeTableList"
                                            [scrollable]="true" [rowHover]="true" scrollHeight="240px"
                                            responsiveLayout="true" [showCurrentPageReport]="true"
                                            class="custom-height custom-table ciam">


                                            <ng-template pTemplate="header" let-columns>
                                                <tr>
                                                    <th scope="col" *ngFor="let col of attributeColumns">
                                                        {{ col.header }}
                                                    </th>
                                                </tr>
                                            </ng-template>

                                            <ng-template pTemplate="body" let-attribute let-rowIndex="rowIndex"
                                                let-index="index">
                                                <tr>
                                                    <td class="attrb-text" *ngIf="attribute.inherited == true"
                                                        style="width: 35%;">
                                                        <label class="inherited-icon-css inherited-icon"></label>

                                                        <label class="attrb-text" *ngIf="attribute.mandatoryField==true"
                                                            [ngClass]="{
                                                            																			'field-required': EditUserForm.get(attribute.id)?.errors?.required
                                                            																			}">
                                                            {{attribute.fieldName}}
                                                        </label>
                                                        <label class="attrb-text"
                                                            *ngIf="attribute.mandatoryField==false">
                                                            {{attribute.fieldName}}
                                                        </label>


                                                        <span class="text-danger"
                                                            *ngIf="attribute.mandatoryField == true"> *</span>
                                                    </td>
                                                    <td class="attrb-text" *ngIf="attribute.inherited == false"
                                                        style="width: 35%;">
                                                        <i class="icon-user-plus p-mr-2"></i>
                                                        {{ attribute.fieldName }}<span class="text-danger"
                                                            *ngIf="attribute.mandatoryField == true">*</span>
                                                    </td>
                                                    <td class="attrb-text" *ngIf="attribute.inherited == null"
                                                        style="width: 35%;">
                                                        <i class="icon-user-plus p-mr-2"></i>
                                                        {{ attribute.fieldName }}<span class="text-danger"
                                                            *ngIf="attribute.mandatoryField == true">*</span>
                                                    </td>

                                                    <td *ngIf="attribute && attribute.fieldInputtype == 'Text Range'"
                                                        style="width: 35%;">

                                                        <input
                                                            *ngIf="attribute.fieldName == 'IPRange' && attribute.inherited == false"
                                                            type="text" class="p-inputtext-sm" pInputText
                                                            style="width:100% !important" id="{{attribute.id}}"
                                                            name="{{attribute.id}}" minlength="1" maxlength="100"
                                                            formControlName="{{attribute.id}}"
                                                            [required]="attribute.mandatoryField==true"
                                                            (change)="addressStartIpCheck(attribute.id);" />
                                                        <input
                                                            *ngIf="attribute.fieldName == 'IPRange' && attribute.inherited == null"
                                                            type="text" class="p-inputtext-sm" pInputText
                                                            style="width:100% !important" id="{{attribute.id}}"
                                                            name="{{attribute.id}}" minlength="1" maxlength="100"
                                                            formControlName="{{attribute.id}}"
                                                            [required]="attribute.mandatoryField==true"
                                                            (change)="addressStartIpCheck(attribute.id);" />
                                                        <span
                                                            *ngIf="attribute.fieldName == 'IPRange' && attribute.inherited == true"
                                                            class="att-values">{{
                                                            attribute.fieldValue }}</span>

                                                        <div class="invalid-feedback"
                                                            *ngIf="EditUserForm.get(attribute.id)!.invalid && (EditUserForm.get(attribute.id)!.dirty || EditUserForm.get(attribute.id)!.touched) ">

                                                            <div
                                                                *ngIf="EditUserForm.get(attribute.id)?.errors?.required">
                                                                <label class="alert-icon alert-circle-icon"></label>
                                                                <medium class="p-error">This field is required</medium>
                                                            </div>

                                                        </div>
                                                        <div *ngIf="this['IpFlag' + attribute.id]">
                                                            <label class="alert-icon alert-circle-icon"></label>
                                                            <medium class="p-error">Invalid field format.
                                                            </medium>
                                                        </div>
                                                        <div *ngIf="this['duplicityFlag' + attribute.id]">
                                                            <label class="alert-icon alert-circle-icon"></label>
                                                            <medium class="p-error">Duplicate IP Range.
                                                            </medium>
                                                        </div>
                                                        <div *ngIf="this['greaterFlag' + attribute.id]">
                                                            <label class="alert-icon alert-circle-icon"></label>
                                                            <medium class="p-error">Start IP cannot be greater than end
                                                                IP.
                                                            </medium>
                                                        </div>
                                                    </td>
                                                    <td *ngIf="attribute && attribute.fieldInputtype == 'Text'"
                                                        style="width: 35%;">


                                                        <input
                                                            *ngIf="attribute.fieldName != 'IPRange' && attribute.inherited == true"
                                                            type="text" class="p-inputtext-sm" pInputText
                                                            style="width:100% !important" id="{{attribute.id}}id"
                                                            name="{{attribute.id}}" minlength="1" maxlength="100"
                                                            formControlName="{{attribute.id}}"
                                                            [required]="attribute.mandatoryField==true"
                                                            [pattern]="attribute.pattern"
                                                            (change)="onInputChange(attribute.id,index);this.enableBtn1=false;" />


                                                        <input
                                                            *ngIf="attribute.fieldName != 'IPRange' && attribute.inherited == null"
                                                            type="text" class="p-inputtext-sm" pInputText
                                                            style="width:100% !important" id="{{attribute.id}}id"
                                                            name="{{attribute.id}}" minlength="1" maxlength="100"
                                                            formControlName="{{attribute.id}}"
                                                            [pattern]="attribute.pattern"
                                                            [required]="attribute.mandatoryField==true"
                                                            (keyup)="onInputChange();this.enableBtn1=false;" />
                                                        <input
                                                            *ngIf="attribute.fieldName != 'IPRange' && attribute.inherited == false"
                                                            type="text" class="p-inputtext-sm" pInputText
                                                            style="width:100% !important" id="{{attribute.id}}id"
                                                            name="{{attribute.id}}" minlength="1" maxlength="100"
                                                            formControlName="{{attribute.id}}"
                                                            [pattern]="attribute.pattern"
                                                            [required]="attribute.mandatoryField==true"
                                                            (keyup)="onInputChange();this.enableBtn1=false;" />
                                                        <div class="invalid-feedback"
                                                            *ngIf="EditUserForm.get(attribute.id)!.invalid && (EditUserForm.get(attribute.id)!.dirty || EditUserForm.get(attribute.id)!.touched) ">

                                                            <div
                                                                *ngIf="EditUserForm.get(attribute.id)?.errors?.required">
                                                                <label class="alert-icon alert-circle-icon"></label>
                                                                <medium class="p-error">This field is required</medium>
                                                            </div>
                                                            <div
                                                                *ngIf="EditUserForm.get(attribute.id)?.errors?.pattern">
                                                                <label class="alert-icon alert-circle-icon"></label>
                                                                <medium class="p-error">Invalid field format</medium>
                                                            </div>
                                                        </div>


                                                    </td>

                                                    <td *ngIf="attribute && attribute.fieldInputtype == 'Dropdown'"
                                                        style="width: 35%;">
                                                        <div class="p-col-12 p-p-0">

                                                            <p-dropdown id="{{attribute.id}}id" name="{{attribute.id}}"
                                                                placeholder="Select" [options]="attribute.inputFormat"
                                                                formControlName="{{attribute.id}}"
                                                                [autoDisplayFirst]="false" autoZIndex="true"
                                                                baseZIndex="1" appendTo="body"
                                                                [required]="attribute.mandatoryField==true"
                                                                optionLabel="label" optionValue="value"
                                                                (onChange)="onInputChange();this.enableBtn1=false;"></p-dropdown>
                                                            <div class="invalid-feedback"
                                                                *ngIf="EditUserForm.get(attribute.id)!.invalid && (EditUserForm.get(attribute.id)!.dirty || EditUserForm.get(attribute.id)!.touched) ">
                                                                <div
                                                                    *ngIf="EditUserForm.get(attribute.id)?.errors?.required">
                                                                    <label class="alert-icon alert-circle-icon"></label>
                                                                    <medium class="p-error">This field is required
                                                                    </medium>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <td *ngIf="attribute && attribute.fieldInputtype == 'numeric'"
                                                        style="width: 35%;">
                                                        <input *ngIf="attribute.inherited == false" type="number"
                                                            pattern="^[0-9]*$" class="p-inputtext-sm" pInputText
                                                            #inputText="ngModel" style="width:100% !important"
                                                            [(ngModel)]="attribute.attributeValue"
                                                            (ngModelChange)="updateAttrStatus(attribute.ID)"
                                                            minlength="1" maxlength="100"
                                                            formControlName="{{attribute.id}}"
                                                            [pattern]="attribute.pattern"
                                                            (change)="onInputChange();this.enableBtn1=false;" />

                                                        <span *ngIf="attribute.inherited == true" class="att-values">{{
                                                            attribute.attributeValue }}</span>
                                                    </td>

                                                    <td *ngIf="attribute && attribute.fieldInputtype == 'tree'"
                                                        style="width: 35%;"></td>

                                                    <td style="position: relative;">
                                                        <span *ngIf="attribute.inherited == true">Inherited</span>
                                                        <span *ngIf="attribute.inherited == false">
                                                            Overriden
                                                        </span>
                                                        <span *ngIf="attribute.inherited == null">

                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span *ngIf="attribute.isDeleteAble==true">
                                                            <div class="action-button actionHover">
                                                                <span class="action-buttons action-buttons-table">
                                                                    <button pButton type="button" pTooltip="Delete"
                                                                        aria-label="false" icon="icon-trash"
                                                                        class="p-button-strict p-button-info reasonButton"
                                                                        tooltipPosition="top"
                                                                        (click)="deleteAttribute(rowIndex, attribute)"></button>
                                                                </span>
                                                            </div>
                                                        </span>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="emptymessage">
                                                <tr>
                                                    <td colspan="10" class="p-p-0 p-text-center"> <p-card>
                                                            <div style="margin-top: 5%;height: 70px;">
                                                                No records found</div>
                                                        </p-card> </td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </div>

                                    <p-overlayPanel #addattributes
                                        class="overlayactionpart overlayaction addattributes">
                                        <ng-template pTemplate>
                                            <div class="filterMessage p-grid p-fluid" *ngIf="filterMessage">
                                                <div class="p-col-12 p-md-12">
                                                    <div class="alert alert-danger alert-dismissible fade show"
                                                        role="alert">
                                                        <i class="fa fa-info-circle"></i> {{ filterMessage }}
                                                        <button type="button" class="close" data-dismiss="alert"
                                                            aria-label="Close" (click)="filterMessage = ''">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="p-col-12 p-md-12 filtercard p-p-0 p-grid p-fluid">

                                                <div class="addOrgAttrs accd user-attr">

                                                    <div>
                                                        <label>User Attributes</label>
                                                        <p-tree [value]="userAttributeData" selectionMode="single"
                                                            [filter]="true" [(selection)]="selectedUserAttribute"
                                                            [style]="{ width: '100%' }"
                                                            (onNodeSelect)="onUserAttrSelected($event)">

                                                        </p-tree>
                                                    </div>

                                                    <div class="footer-over p-mt-2 p-right">

                                                        <div class="btnwrap p-mr-3">
                                                            <button pButton type="button" class="p-button-brand2"
                                                                label="Cancel" (click)="addattributes.hide();">
                                                            </button>
                                                        </div>
                                                        <div class="btnwrap">
                                                            <button pButton class="p-button-primary" type="submit"
                                                                label="Add"
                                                                [disabled]="selectedUserAttribute?.length==0"
                                                                (click)="applyUserAttributeFilter();addattributes.hide();">
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </ng-template>
                                    </p-overlayPanel>

                                    <div
                                        class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right custom-btn-height p-mt-3">
                                        <div class="btnwrap">
                                            <button pButton class="p-button" type="submit" (click)="onEditUser();"
                                                [disabled]="emailFlag|| enableBtn1 || !EditUserForm.valid || this.userNameCheckError || isInvalidIpRange"
                                                label="Save">
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </p-tabPanel>
                            <p-tabPanel>
                                <div class="p-fluid p-formgrid p-grid p-mt-3">
                                    <div class="p-col-12 p-sm-12 p-md-6 p-lg-4 p-xl-4 p-offset-0 left">
                                        <div class=" p-fluid   p-field    ">
                                            <label>
                                                Role
                                            </label>
                                            <div class="p-fluid  p-field custom-checkbox-color">
                                                <p-multiSelect id="roleList" name="roleList"
                                                    filterPlaceHolder="Select Roles" [options]="roleListList"
                                                    [filter]="true" formControlName="roleList" class="custom-chips"
                                                    [(ngModel)]="this.selectedRole" optionLabel="label"
                                                    optionValue="value" display="chip" resetFilterOnHide="true"
                                                    placeholder="Select Roles" (onChange)="enableBtn2=false"
                                                    appendTo="body">
                                                    <ng-template let-each pTemplate="selectedItem">
                                                        {{each.label}}
                                                    </ng-template>
                                                    <ng-template let-items pTemplate="item">
                                                        <div *ngIf="items?.label?.length <= 25;">
                                                            {{items.label}}
                                                        </div>
                                                        <div *ngIf="items?.label?.length > 25;"
                                                            pTooltip="{{items.label}}" tooltipPosition="bottom">
                                                            {{items.label.substring(0,25) + "..." }}
                                                        </div>
                                                    </ng-template>
                                                </p-multiSelect>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                                    </div>
                                    <div
                                        class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right p-mt-3 p-mb-2">

                                        <div class="btnwrap">
                                            <button pButton class="p-button" type="submit"
                                                (click)="assignRoles(EditUserForm);" [disabled]="enableBtn2"
                                                label="Save">
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </p-tabPanel>
                            <p-tabPanel>
                                <div class="p-fluid p-formgrid p-grid p-mt-3">
                                    <div class="p-col-12 p-sm-12 p-md-6 p-lg-4 p-xl-4 p-offset-0 left">
                                        <div class=" p-fluid   p-field    ">
                                            <label>
                                                Group
                                            </label>
                                            <div class="p-fluid  p-field">
                                                <p-multiSelect id="groupList" name="groupList"
                                                    filterPlaceHolder="Select Groups" [options]="groupListList"
                                                    [filter]="true" formControlName="groupList" class="custom-chips"
                                                    [(ngModel)]="this.selectedGroup" optionLabel="label"
                                                    optionValue="value" selectedItemsLabel="{0} Groups selected"
                                                    display="chip" resetFilterOnHide="true" placeholder="Select Groups"
                                                    (onChange)="enableBtn3=false">
                                                    <ng-template let-each pTemplate="selectedItem">
                                                        {{each.label}}
                                                    </ng-template>
                                                    <ng-template let-items pTemplate="item">
                                                        <div *ngIf="items?.label?.length <= 25;">
                                                            {{items.label}}
                                                        </div>
                                                        <div *ngIf="items?.label?.length > 25;"
                                                            pTooltip="{{items.label}}" tooltipPosition="bottom">
                                                            {{items.label.substring(0,25) + "..." }}
                                                        </div>
                                                    </ng-template>
                                                </p-multiSelect>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                                    </div>
                                    <div
                                        class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right p-mb-2">
                                        <div class="btnwrap">
                                            <button pButton class="p-button" type="submit" [disabled]="enableBtn3"
                                                (click)="assignGroups(EditUserForm);" label="Save">
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </p-tabPanel>
                            <p-tabPanel>

                                <div class="p-grid user-attribute overflow-attributetable-scroll">
                                    <div class="p-col-12 p-pt-0 p-pl-0 p-pr-0">
                                        <div class="p-grid p-mb-2">
                                            <div class="p-col-md-auto p-mr-3">
                                                <p class="p-mb-0 p-mt-1">Roles</p>
                                            </div>
                                            <div class="p-ml-2 p-mt-1">
                                                <a [routerLink]="" class="add-box-input" (click)="addUserRole()"
                                                    [ngClass]="{ 'anchor-disabled': this.accountStatus != 'ACTIVE' || this.USER_STATUS == 'SUSPENDED'}">
                                                    <u>+ Add Role</u></a>
                                            </div>
                                        </div>

                                        <p-table [columns]="userRoleColumns" [value]="userRoleList" [scrollable]="true"
                                            scrollHeight="240px" [rowHover]="true" [resizableColumns]="true"
                                            styleClass="p-datatable-gridlines" class="custom-height role-table"
                                            styleClass="p-datatable-gridlines">
                                            <ng-template pTemplate="colgroup">
                                                <colgroup>
                                                    <col *ngFor="let col of userRoleColumns"
                                                        [style.width]="col.width" />
                                                </colgroup>
                                            </ng-template>

                                            <ng-template pTemplate="header" let-columns>
                                                <tr>
                                                    <th id="tableHeader" *ngFor="let col of userRoleColumns"
                                                        [style.width]="col.width">
                                                        {{ col.header }}
                                                    </th>
                                                </tr>
                                                <tr *ngIf="isAddNewUserRole" class="role-bg">


                                                    <td style="width:28%">
                                                        <label for="component"
                                                            class="p-col-12 p-mb-0 p-mr-0 p-p-0 p-ml-3">Role</label>
                                                        <div class="p-ml-3 p-mb-3">
                                                            <p-dropdown [options]="userRoleDropdown"
                                                                [(ngModel)]="selectedUserRole" optionLabel="NAME"
                                                                appendTo="body" filterBy="label"
                                                                placeholder="Select Role" [filter]="true"
                                                                formControlName="selectedEntRole"
                                                                (onChange)="getUserRoleEntitlementData(selectedUserRole)"
                                                                class="dropdown-width">
                                                            </p-dropdown>
                                                        </div>
                                                    </td>
                                                    <td class="p-ml-6 p-pl-2">
                                                        <label for="ent"
                                                            class="p-col-sm-12 role-ent">Entitlement</label>
                                                        <div class="">
                                                            <label for="ent" class="p-col-sm-12">Total Entitlement:
                                                                <span *ngIf="entitlementCount != 0"><a [routerLink]=""
                                                                        class="entCount"
                                                                        (click)="op.toggle($event)">{{entitlementCount}}</a></span>
                                                                <span *ngIf="entitlementCount == 0"
                                                                    pTooltip="No entitlements assigned yet"
                                                                    tooltipPosition="top"
                                                                    tooltipStyleClass="user-role-tooltip">
                                                                    {{ entitlementCount }}</span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td class="p-mt-3 p-ml-2">
                                                        <label for="service"
                                                            class="p-col-sm-12 p-col-form-label form-control-sm"></label>
                                                        <button pButton label="Add"
                                                            [ngClass]="{ 'anchor-disabled': !selectedUserRole.PRIME_ROLE_ID }"
                                                            (click)="addUserRoleToTable()" class="p-mt-2"></button>

                                                        <button pButton label="Discard"
                                                            class="p-mt-2 p-button-secondary custom-discard"
                                                            (click)="discardUserRole()"></button>
                                                    </td>
                                                </tr>
                                            </ng-template>

                                            <ng-template pTemplate="body" let-role let-rowIndex="rowIndex">
                                                <tr>
                                                    <td>
                                                        <a routerLink="" (click)="navigateToDetailScreen(role)"
                                                            style="text-decoration: none !important;">{{role.ID}}
                                                        </a>

                                                    </td>
                                                    <td class="att-values">{{ role.NAME }}</td>
                                                    <td style="position: relative;">
                                                        <div class="action-button actionHover">
                                                            <span class="action-buttons"
                                                                [ngClass]="{ 'anchor-disabled': this.USER_STATUS == 'Deleted' || this.USER_STATUS== 'Archived' }">
                                                                <button pButton
                                                                    class="p-button-strict p-button-info p-ml-5 p-pt-0"
                                                                    pTooltip="Delete" tooltipPosition="bottom"
                                                                    (click)="deleteRole(role,rowIndex)"
                                                                    icon="icon-trash"
                                                                    [ngClass]="{'anchor-disabled' : this.accountStatus != 'ACTIVE' || this.USER_STATUS== 'Archived'}">

                                                                </button>
                                                            </span>
                                                        </div>
                                                        <span class="user-date">{{ role.CREATED_DATE | dateFormatter
                                                            }}</span>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="emptymessage">
                                                <tr>
                                                    <td colspan="3" class="p-p-0 p-text-center">
                                                        <p-card>
                                                            <div style="margin-top: 5%;height: 70px;">
                                                                No records found
                                                            </div>
                                                        </p-card>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </div>
                                </div>
                                <div *ngIf="isOrgAssigned == 1 && isEntitlementDisabled && isOrganizationAttr == 0">
                                    <span>The mandatory attributes rows are marked as <span
                                            class="text-danger">*</span>.You need to fill them in
                                        order to proceed
                                        further</span>
                                </div>

                                <div class="entitlements-wrapper">
                                    <div class="p-grid">
                                        <div class="p-col-6 p-mt-2 p-pl-0 p-pr-0 p-d-flex">
                                            <p class="p-mr-3 p-mt-1 p-mb-0 entilt-positon">Entitlements</p>
                                            <a [routerLink]="" (click)="addEntitlement()" class="add-box-input"
                                                [ngClass]="{ 'anchor-disabled': isEntitlementDisabled == true || this.USER_STATUS == 'Deleted' || this.USER_STATUS== 'Archived' ||  this.accountStatus != 'ACTIVE' }"><u>+
                                                    Add Entitlement</u></a>
                                        </div>
                                        <div class="p-col-6 p-pr-0 p-text-right">
                                            <div class="p-d-flex p-jc-end">
                                                <div class="inputtext-normal">
                                                    <span class="p-input-icon-left">
                                                        <i class="icon-search"></i>
                                                        <input type="text" id="search-ent-bar" pInputText
                                                            placeholder="Search" (keyup.enter)="loadEntitlementData()"
                                                            [(ngModel)]="search" formControlName="entitlementSearch" />
                                                    </span>
                                                </div>
                                                <button pButton type="button" icon="icon-filter" appendTo="body"
                                                    class="p-ml-2 p-button-brand2 p-mb-3" [disabled]="disableButtons"
                                                    (click)="entilementfliter.toggle($event); openEntitlementFilterSideBar()"></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="p-grid p-pb-2" *ngIf="
                                                appliedComponentFilter.length > 0 ||
                                                appliedEntitlementFilter.length > 0 ||
                                                appliedStatusFilter.length > 0 ||
                                                appliedSourceFilter.length>0||
                                                appliedRaisedIndependentDataFilter.length>0||
                                                appliedNoApprovalRequired
                                            ">
                                    <div class="p-col p-pl-0">
                                        <div class="p-ai-center">
                                            <label>Filters Applied: </label>

                                            <p-chip *ngFor="let status of appliedComponentFilter"
                                                (click)="setActiveEntitTab('component'); entilementfliter.toggle($event)"
                                                class="p-mr-2" styleClass="p-chip-lg p-chip-blue medium-text"
                                                label="{{ status.label }}"></p-chip>

                                            <p-chip *ngFor="let type of appliedEntitlementFilter"
                                                (click)="setActiveEntitTab('entitlement'); entilementfliter.toggle($event)"
                                                class="p-mr-2" styleClass="p-chip-lg p-chip-blue medium-text"
                                                label="{{ type.label }}"></p-chip>

                                            <p-chip *ngFor="let type of appliedSourceFilter"
                                                (click)="setActiveEntitTab('source_role'); entilementfliter.toggle($event)"
                                                class="p-mr-2" styleClass="p-chip-lg p-chip-blue medium-text"
                                                label="{{ type.label }}"></p-chip>
                                            <p-chip *ngFor="let type of appliedRaisedIndependentDataFilter"
                                                (click)="setActiveEntitTab('raised_independent'); entilementfliter.toggle($event)"
                                                class="p-mr-2" styleClass="p-chip-lg p-chip-blue medium-text"
                                                label="{{ type.label }}"></p-chip>
                                            <p-chip *ngFor="let type of appliedStatusFilter"
                                                (click)="setActiveEntitTab('status'); entilementfliter.toggle($event)"
                                                class="p-mr-2" styleClass="p-chip-lg p-chip-blue medium-text"
                                                label="{{ type.label }}"></p-chip>

                                            <p-chip *ngFor="let type of appliedNoApprovalRequired"
                                                (click)="setActiveEntitTab('approval'); entilementfliter.toggle($event)"
                                                class="p-mr-2" styleClass="p-chip-lg p-chip-blue medium-text"
                                                label="{{ type.label }}"></p-chip>


                                            <span><label class="p-mb-0" (click)="clearAllEntitlementFilters()"><a>Clear
                                                        all</a></label></span>
                                        </div>
                                    </div>
                                </div>

                                <div class="p-grid">
                                    <div class="p-col-12 p-p-0 overflow-table-scroll">
                                        <p-table [columns]="entitlementColumns" [value]="entitlementList"
                                            [rowHover]="true" [scrollable]="true" styleClass="p-datatable-gridlines"
                                            class="entitlement-table custom-height custom-table"
                                            [resizableColumns]="true" scrollHeight="240px">
                                            <ng-template pTemplate="colgroup" let-columns>
                                                <colgroup>
                                                    <col *ngFor="let col of entitlementColumns" />
                                                </colgroup>
                                            </ng-template>
                                            <ng-template pTemplate="header" let-columns>
                                                <tr>
                                                    <th id="tableHeaderinfo" style="max-width: 33px;"></th>
                                                    <th id="entID">Ent Id</th>
                                                    <th id="compName">Component Name</th>
                                                    <th id="entName">Entitlement Name</th>
                                                    <th id="source">Source</th>
                                                    <th id="status">Status</th>
                                                    <th id="ownership">Ownership</th>
                                                    <th id="approvalTree">Approval Tree</th>
                                                    <th id="action">Action</th>
                                                </tr>
                                                <tr *ngIf="isAddNewEntitlement" class="ent-bg p-p-3">
                                                    <td style="width:10px"></td>
                                                    <td style="width:28%">
                                                        <div class="p-fluid p-mr-2">
                                                            <label for="component"
                                                                class="p-col-12 p-mb-0 p-mr-0 p-p-0 p-ml-3">Component
                                                                Name<span class="text-danger"> *
                                                                </span></label>
                                                            <div class="p-ml-3 p-mb-3">
                                                                <p-dropdown [options]="componentDropdownList"
                                                                    [(ngModel)]="selectedComponent" [filter]="true"
                                                                    filterBy="COMP_NAME_WITH_ID"
                                                                    optionLabel="COMP_NAME_WITH_ID" appendTo="body"
                                                                    placeholder="Select Component"
                                                                    formControlName="selectedComponent"
                                                                    (onChange)="getEntitlementData()"
                                                                    [style]="{ width: '18rem' }">
                                                                    <ng-template let-items pTemplate="item">
                                                                        <div
                                                                            *ngIf="items?.COMP_NAME_WITH_ID?.length &lt;=37;">
                                                                            {{items.COMP_NAME_WITH_ID}}
                                                                        </div>
                                                                        <div *ngIf="items?.COMP_NAME_WITH_ID?.length > 37;"
                                                                            pTooltip="{{items.COMP_NAME_WITH_ID}}"
                                                                            tooltipPosition="bottom">
                                                                            {{items.COMP_NAME_WITH_ID.substring(0,37) +
                                                                            "..." }}
                                                                        </div>
                                                                    </ng-template>
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td style="width:28%">
                                                        <div class="p-fluid p-mr-2">
                                                            <label for="ent"
                                                                class="p-col-12 p-mb-0 p-mr-0 p-p-0 p-ml-3">Entitlement
                                                                Name<span class="text-danger">
                                                                    *
                                                                </span></label>
                                                            <div class="p-ml-3 p-mb-3">
                                                                <p-multiSelect [options]="entitlementDropdownList"
                                                                    [(ngModel)]="selectedEntitlement"
                                                                    placeholder="Select Entitlement"
                                                                    formControlName="selectedEntitlementDropdown"
                                                                    optionLabel="NAME_WITH_ID" appendTo="body"
                                                                    maxSelectedLabels="0" [style]="{ width: '18rem' }"
                                                                    [filter]="true" [disabled]="isEntitlementDropdown"
                                                                    (onChange)="isEntitlementExist = false">
                                                                    <ng-template let-entitlement pTemplate="item">
                                                                        <span
                                                                            *ngIf="entitlement.NAME_WITH_ID.length > 53; else other_content">
                                                                            <span title="{{ entitlement.NAME_WITH_ID }}"
                                                                                class="over-content-entitlement-list title">{{
                                                                                entitlement.NAME_WITH_ID }}</span>
                                                                        </span>
                                                                        <ng-template #other_content>

                                                                            <span
                                                                                [ngClass]="{'text-grey': entitlement.STATUS === InactiveStatus}"
                                                                                [pTooltip]="entitlement.STATUS === InactiveStatus ? 'Entitlement was deactivated by ' + entitlement.LATEST_ACTION_BY + ' on ' + (entitlement.LATEST_ACTION_DATE | dateFormatter) : null">
                                                                                {{ entitlement.NAME_WITH_ID }}
                                                                            </span>
                                                                        </ng-template>
                                                                    </ng-template>
                                                                </p-multiSelect>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="group-button-col p-ml-3 p-mt-3 p-d-flex"
                                                        style="width:max-content">
                                                        <button pButton label="Add Entitlement"
                                                            class="p-mt-2 p-mb-3 p-mb-4"
                                                            (click)="addEntitlementToTable()"
                                                            [ngClass]="{ 'anchor-disabled': selectedEntitlement.length == 0 || isEntitlementExist }"></button>

                                                        <button pButton label="Discard"
                                                            class="p-button-secondary custom-discard p-ml-2 p-pb-3"
                                                            (click)="discardEntitlement()"></button>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>

                                                </tr>
                                                <tr *ngIf="!entitlementList.length > 0">
                                                    <td colspan="9">
                                                        <div class="empty-entitlement-list-wrapper"
                                                            style="margin-top: 33px;">
                                                            <label
                                                                class="ent-icons entitlementAdd-icon titleImg"></label>
                                                            <p class="title-entitlement sub-title-entitlement">No
                                                                Entitlements added</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="isEntitlementExist">
                                                    <td colspan="3" class="text-center warn-selection">
                                                        <label class="ent-icons attention-icon"></label>
                                                        Entitlement
                                                        Already Exists
                                                    </td>
                                                </tr>
                                            </ng-template>

                                            <ng-template pTemplate="body" let-entitlement let-rowIndex="rowIndex">
                                                <tr [ngClass]="{'bg-grey': entitlement.ENTSTATUS == InactiveStatus}">
                                                    <td style="max-width: 33px;">
                                                        <span class="info-ent"
                                                            *ngIf="entitlement.ENTSTATUS == InactiveStatus"
                                                            pTooltip="Entitlement was deactivated by {{entitlement.LATEST_ACTION_BY}} on {{entitlement.LATEST_ACTION_DATE | dateFormatter}}"
                                                            tooltipPosition="top">
                                                            <label class="codi-icons codiconInfo-icon"></label>
                                                        </span>
                                                    </td>
                                                    <td>

                                                        <span><a routerLink=""
                                                                (click)="navigateToEntDetailScreens(entitlement)">{{entitlement.COMPONENT_ID
                                                                +
                                                                '-' + entitlement.ENTITLEMENT_ID }}
                                                            </a></span>
                                                    </td>

                                                    <td>{{entitlement.COMPONENT_NAME}}</td>
                                                    <td class="att-values">
                                                        <span pTooltip="{{ entitlement.ENTITLEMENT_NAME }}"
                                                            class="clipTxt" style="vertical-align: bottom;"
                                                            tooltipPosition="top">
                                                            {{entitlement.ENTITLEMENT_NAME}}
                                                        </span>

                                                        <span
                                                            *ngIf="entitlement.IS_ADMS_ENTITLEMENT == 1"><b><i>&nbsp;ADMS</i></b>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <button pButton [disabled]="entitlement.IS_USER_DEFINED == 0"
                                                            [ngClass]="{'anchor-disabled': entitlement.IS_USER_DEFINED == 0  ? true : false }"
                                                            pTooltip="Raised independently"
                                                            class="p-button-brand2 p-button-strict p-button-info p-ml-2 p-mr-2"
                                                            tooltipPosition="top" icon="icon-raisedIndependently">
                                                        </button>
                                                        <button pButton
                                                            [disabled]="!entitlement.SOURCE || entitlement.SOURCE.length == 0"
                                                            class="p-button-brand2 p-button-strict p-button-info"
                                                            icon="icon-multipleRoles" [pTooltip]="entitlement.SOURCE"
                                                            data-html="true" tooltipPosition="top">

                                                        </button>
                                                    </td>
                                                    <td>
                                                        <div *ngIf="(entitlement.OVERALL_STATUS!='Allow')">
                                                            <span class="ml-1"> Deny</span>
                                                        </div>
                                                        <div *ngIf="(entitlement.OVERALL_STATUS =='Allow')">
                                                            <span class="ml-1"> Allow</span>
                                                        </div>

                                                    </td>
                                                    <td>
                                                        <span *ngIf="entitlement.OWNER_UM_ENTITLEMENT_ID">
                                                            {{entitlement.OWNER_ENTITLEMENT_NAME}}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            <span
                                                                *ngIf="entitlement.USER_TYPE_ID == 1 && entitlement.IS_ADMS_APPROVED == 1 && entitlement.IS_ADMS_ENTITLEMENT == 1"
                                                                tooltipPosition="top" pTooltip="ADMS Approved">
                                                                <label
                                                                    class="entApprovalTree-icons admsGreenIndi-icon"></label>
                                                            </span>
                                                            <span
                                                                *ngIf="entitlement.USER_TYPE_ID == 1 && entitlement.IS_ADMS_APPROVED == 0 && entitlement.IS_ADMS_ENTITLEMENT == 1"
                                                                tooltipPosition="top" pTooltip="ADMS Not Approved">
                                                                <label
                                                                    class="entApprovalTree-icons admsRedIndi-icon"></label>
                                                            </span>
                                                            &nbsp;

                                                            <span
                                                                *ngIf="(entitlement.IS_APPROVAL_REQUIRED && entitlement.IS_APPROVAL_REQUIRED===1) || (entitlement.APPROVAL_LEVELS)">
                                                                <span
                                                                    *ngFor="let approval of entitlement.APPROVAL_LEVELS">
                                                                    <span class="icon-circle p-mr-1"
                                                                        [ngStyle]="{'background-color': approval?.COLOR}"
                                                                        [pTooltip]=approval.TOOLTIP></span>
                                                                </span>
                                                            </span>

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <button pButton type="button"
                                                            class="p-button-brand2 p-button-strict p-button-info"
                                                            pTooltip="Delete"
                                                            [ngClass]="{'anchor-disabled' : this.accountStatus != 'ACTIVE' || this.USER_STATUS== 'Archived'|| this.USER_STATUS == 'Deleted'}"
                                                            tooltipPosition="bottom"
                                                            (click)="deleteEntitlementPopUp(entitlement)"
                                                            icon="icon-trash">

                                                        </button>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </div>

                                    <div class="legends">
                                        <span class="p-mr-3 legend-key">Key:</span>
                                        <span class="dot-for-approv p-mr-1"></span>
                                        <span class="p-mr-3" class="legend-title ml-1">Approved</span>
                                        <span class="dot-for-pending p-ml-3 p-mr-1"></span>
                                        <span class="p-mr-3" class="legend-title ml-1">Pending</span>
                                        <span class="dot-for-reject p-ml-3 p-mr-1"></span>
                                        <span class="p-mr-3" class="legend-title ml-1">Not Approved</span>
                                        <span class="dot-for-disable p-ml-3 p-mr-1"></span>
                                        <span class="p-mr-3" class="legend-title ml-1">Approval disabled</span>
                                        <span class="p-ml-2"><label style="vertical-align: middle;"
                                                class="soure-img entLengend-icons carbonMobileCheck-icon"></label></span>
                                        <span class="p-mr-2" class="legend-title p-ml-1">Raised independently</span>
                                        <span class="p-ml-2"><label style="vertical-align: middle;"
                                                class="soure-img entLengend-icons carbonDataCheck-icon"></label></span>
                                        <span class="p-ml-2" class="legend-title p-ml-1">Access through Roles</span>

                                    </div>
                                </div>

                                <div class="user-details-footer-wrapper" style="margin: 28px -18px -4px;">
                                    <div></div>
                                    <div class="p-grid p-mt-2">
                                        <div class="p-d-flex">
                                            <div class="p-mt-3">
                                                <label *ngIf="timeStamp" class="last-icons lastSavedTimeStamp-icon"
                                                    style="margin: 0px;"></label>

                                            </div>
                                            <div class="p-p-2 p-mt-2">
                                                <p *ngIf="timeStamp" class="saveDetails">Last Saved at {{
                                                    userDetailsResp | dateFormatter}}</p>
                                            </div>
                                            <div class="p-mt-2 p-pr-1">
                                                <button pButton type="submit" label="Back" class="p-button-secondary"
                                                    (click)="onDiscardConfirm()">
                                                </button>
                                            </div>
                                            <div class="p-pl-2 p-pt-2 p-pb-2">
                                                <span *ngIf="this.enabledTooltip">
                                                    <button pButton type="button" [pTooltip]="lastMailSent"
                                                        tooltipPosition="top" label={{emailSendButtonLabel}}
                                                        class="p-mr-2 ui-button-raised ui-button-danger"
                                                        (click)="sendEmail()">
                                                    </button>
                                                </span>
                                                <span *ngIf="!this.enabledTooltip">

                                                    <button pButton type="button" tooltipPosition="top"
                                                        label={{emailSendButtonLabel}}
                                                        class="p-mr-2 ui-button-raised ui-button-danger"
                                                        (click)="sendEmail()">
                                                    </button>
                                                </span>
                                                <button pButton type="button" label="Save & Apply"
                                                    class="ui-button-raised ui-button-danger"
                                                    [disabled]="isSaveAndApplyBtnDisabled || this.accountStatus != 'ACTIVE' || isInvalidIpRange || this.USER_STATUS == 'Deleted' || this.USER_STATUS== 'Archived' "
                                                    [ngClass]="{ 'anchor-disabled': isSaveAndApplyBtnDisabled }"
                                                    (click)="updateUserDetails(true)"></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="dialog-confirm">
                                    <p-dialog header="You have unsaved changes" [modal]="true" [(visible)]="onTabChange"
                                        [baseZIndex]="10000" [closable]="false" styleClass="sm">
                                        <p class="dialog-Content"> {{ navigationMessage }}</p>
                                        <p-footer>
                                            <button pButton type="button" label="Cancel" (click)="onCancel()"
                                                class="p-button-secondary"></button>
                                            <button pButton type="button" label="Discard Changes"
                                                (click)="onDiscardChange()" class="p-button-secondary"></button>
                                            <button pButton type="button" label="Save & Apply"
                                                [disabled]="isSaveAndApplyBtnDisabled"
                                                [ngClass]="{ 'anchor-disabled': isSaveAndApplyBtnDisabled }"
                                                class="p-button-primary p-mr-0" (click)="onSaveAndApply()"></button>
                                        </p-footer>
                                    </p-dialog>
                                </div>


                                <p-dialog header="Override Warning!" [modal]="true" [(visible)]="isImportConfirmDialog"
                                    styleClass="dialogTest" [style]="{ width: '40vw' }" [baseZIndex]="10000"
                                    class="small_popups">
                                    <div class="p-grid p-fluid p-0">
                                        <div class="p-col-12 p-md-12 text-center">
                                            <label class="entWarning-icons attention-icon"></label><br />
                                            <label class="customlabel mb-0">Override Warning!</label>
                                            <p>{{ confirmMessage }}</p>
                                        </div>
                                    </div>

                                    <p-footer>
                                        <button *ngIf="!isServiceLineBlank" pButton type="button" label="Cancel"
                                            (click)="cancelConfimDialog()"
                                            class="p-button-secondary btnCancel"></button>
                                        <button pButton type="button" label="Continue" class=" btnCont"
                                            (click)="onActionConfirmationClick()"></button>
                                    </p-footer>
                                </p-dialog>

                                <p-overlayPanel #entilementfliter styleClass="overlayactionpart userfilters">
                                    <ng-template pTemplate>
                                        <div class="p-col-12 p-md-12 filtercard  p-p-0">
                                            <div class="addOrgAttrs">
                                                <div class="p-grid p-d-inline-block">
                                                    <div class="p-col-auto">
                                                        <h6>Filters</h6>
                                                    </div>


                                                    <div class="p-grid p-mt-2 ent_user user-panel">
                                                        <div class="p-col-12 p-p-0 accd">
                                                            <p-accordion [multiple]="true" styleClass="accord_texts"
                                                                expandIcon="pi pi-plus" collapseIcon="pi pi-minus">
                                                                <p-accordionTab header="Component"
                                                                    [(selected)]="activeEntitlementTab.isComponentTab">
                                                                    <p-tree [value]="componentFilterDropdown"
                                                                        #manageUserComp selectionMode="checkbox"
                                                                        class="tree_btn" filter="true"
                                                                        [(selection)]="selectedComponentFilter"
                                                                        [style]="{ width: '100%' }">
                                                                    </p-tree>
                                                                </p-accordionTab>

                                                                <p-accordionTab header="Entitlement"
                                                                    [(selected)]="activeEntitlementTab.isEntitlementTab">
                                                                    <p-tree [value]="entitlementFilterDropdown"
                                                                        #manageUserEnt selectionMode="checkbox"
                                                                        class="tree_btn"
                                                                        [(selection)]="selectedEntitlementFilter"
                                                                        filter="true"
                                                                        [style]="{ width: '100%' }"></p-tree>
                                                                </p-accordionTab>
                                                                <p-accordionTab header="Source"
                                                                    [(selected)]="activeEntitlementTab.isSourceTab">
                                                                    <p-accordionTab header="Roles"
                                                                        [(selected)]="activeEntitlementTab.isSourceTab">
                                                                        <p-tree [value]="sourceFilterDropdown"
                                                                            #manageUserSource selectionMode="checkbox"
                                                                            class="tree_btn"
                                                                            [(selection)]="selectedSourceFilter"
                                                                            filter="true"
                                                                            [style]="{ width: '100%' }"></p-tree>
                                                                    </p-accordionTab>
                                                                    <p-tree [value]="isRaisedIndependentlyData"
                                                                        #manageUserRaisedIndependent
                                                                        selectionMode="checkbox"
                                                                        [(selection)]="selectedRaisedIndependentlyDataFilter"
                                                                        [style]="{ width: '100%' }"
                                                                        class="tree_btn"></p-tree>
                                                                </p-accordionTab>
                                                                <p-accordionTab header="Status"
                                                                    [(selected)]="activeEntitlementTab.isStatusTab">
                                                                    <p-tree [value]="statusFilterDropdown"
                                                                        #manageUserStatus selectionMode="checkbox"
                                                                        class="tree_btn" filter="true"
                                                                        [(selection)]="selectedStatusFilter"
                                                                        [style]="{ width: '100%' }">
                                                                    </p-tree>
                                                                </p-accordionTab>
                                                                <p-accordionTab header="Approval Required "
                                                                    [(selected)]="activeEntitlementTab.isApprovalTab">
                                                                    <p-tree [value]="isNoApprovalRequiredData"
                                                                        selectionMode="checkbox"
                                                                        [(selection)]="selectedNoApprovalRequired"
                                                                        [style]="{ width: '100%' }"
                                                                        class="tree_btn"></p-tree>
                                                                </p-accordionTab>
                                                            </p-accordion>
                                                        </div>
                                                    </div>

                                                    <div class="row mb-1">
                                                        <div class="col-md-12">
                                                            <div class="cri-border-bottom"></div>
                                                        </div>
                                                    </div>

                                                    <div class="row mb-1" *ngIf="filterMessage">
                                                        <div class="col-md-12">
                                                            <div class="alert alert-danger alert-dismissible fade show"
                                                                role="alert">
                                                                <i class="fa fa-info-circle"></i> {{
                                                                filterMessage }}
                                                                <button type="button" class="close" data-dismiss="alert"
                                                                    aria-label="Close" (click)="filterMessage = ''">
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="footer-over p-mt-3">
                                                        <button type="button" pButton
                                                            (click)="applyEntitlementFilter(); discardOwnerFilter($event, entilementfliter)"
                                                            label="Apply"
                                                            class="p-button-secondary p-button-sm"></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </p-overlayPanel>

                                <p-dialog header="The Entitlement is dependent on the following Entitlements"
                                    [modal]="true" [(visible)]="isDependentDialog" position="left"
                                    [style]="{ width: '50vw' }" [baseZIndex]="10000">

                                    <p-table [columns]="dependentColumns" [value]="dependentList" [scrollable]="true"
                                        scrollHeight="200px" styleClass="p-datatable-gridlines">
                                        <ng-template pTemplate="colgroup">
                                            <colgroup>
                                                <col *ngFor="let col of dependentColumns" />
                                            </colgroup>
                                        </ng-template>

                                        <ng-template pTemplate="body" let-dependent let-rowIndex="rowIndex">
                                            <tr>
                                                <td>{{ dependentComponentName }}</td>
                                                <td>{{ dependent.ENTITLEMENT_NAME }}</td>
                                            </tr>
                                        </ng-template>
                                    </p-table>

                                    <p-footer class="mt-4">
                                        <button pButton type="button" label="Close"
                                            class="ui-button-raised ui-button-danger"
                                            (click)="isDependentDialog = false"></button>
                                    </p-footer>
                                </p-dialog>

                                <div class="dialog-confirm">
                                    <p-dialog [modal]="true" [(visible)]="discardPopUp" styleClass="md"
                                        class="small_popups" [baseZIndex]="10000" [style]="{width: '40vw'}">
                                        <ng-template pTemplate="header">
                                            <label class="entWarning-icons confirmCircle-icon"></label>
                                            <b class="p-ml-2">Confirm</b>
                                        </ng-template>
                                        <p class="dialog-Content">{{ confirmMessage }}</p>
                                        <p-footer>
                                            <button pButton type="button" label="Cancel" (click)="discardPopUp = false"
                                                class="p-button-raised p-button-secondary "></button>
                                            <button pButton type="button" label="Confirm" (click)="onDiscard()"
                                                routerLink="" class="p-button-primary p-mr-0 "></button>
                                        </p-footer>
                                    </p-dialog>
                                </div>

                                <div class="dialog-confirm">
                                    <p-dialog [modal]="true" [(visible)]="discardPopUp" styleClass="md"
                                        class="small_popups" [baseZIndex]="10000" [style]="{width: '40vw'}">
                                        <ng-template pTemplate="header">
                                            <label class="unsavedChanges-icon unsaved-icon"></label>

                                            <b class=" p-ml-2">unsaved changes</b>
                                        </ng-template>
                                        <p class="dialog-Content">You have unsaved changes</p>
                                        <p class="dialog-Content"> {{confirmMessage}}</p>
                                        <p-footer>
                                            <button pButton type="button" label="Cancel" (click)="discardPopUp = false"
                                                class="p-button-raised p-button-secondary"></button>
                                            <button pButton type="button" label="Confirm"
                                                class="p-button-primary p-mr-0" (click)="onDiscard()"></button>
                                        </p-footer>
                                    </p-dialog>
                                </div>



                                <p-overlayPanel #op [showCloseIcon]="false" [style]="{width: '450px'}"
                                    class="rolesoverlayaction Role-table">
                                    <ng-template pTemplate>
                                        <b class="mt-3">
                                            <span class="assign-sub-title">
                                                Grant-Yes And Approved
                                            </span>
                                        </b>
                                        <div class="mt-3">
                                            <p *ngFor='let ent of roleEntitlementSection?.GRANT_YES_AND_APPROVED'
                                                class="assign-title mb-3">
                                                {{ent.COMPONENT_ID + '-' + ent.ENTITLEMENT_ID + ' ' +
                                                ent.ENTITLEMENT_NAME}}
                                                <span *ngIf="ent.IS_ADMS_ENTITLEMENT == 1"><b><i>&nbsp;ADMS</i></b>
                                                </span>
                                            </p>
                                        </div>
                                        <b class="mt-3">
                                            <span class="assign-sub-title">
                                                Grant-Yes And Not Approved
                                            </span>
                                        </b>
                                        <div class="mt-3">
                                            <p *ngFor='let ent of roleEntitlementSection?.GRANT_YES_AND_NOT_APPROVED'
                                                class="assign-title mb-3">
                                                {{ent.COMPONENT_ID + '-' + ent.ENTITLEMENT_ID + ' ' +
                                                ent.ENTITLEMENT_NAME}}
                                                <span *ngIf="ent.IS_ADMS_ENTITLEMENT == 1"><b><i>&nbsp;
                                                            ADMS</i></b> </span>
                                            </p>
                                        </div>
                                        <b class="mt-3">
                                            <span class="assign-sub-title">
                                                Grant-No And Approved
                                            </span>
                                        </b>
                                        <div class="mt-2">
                                            <p *ngFor='let ent of roleEntitlementSection?.GRANT_NO_AND_APPROVED'
                                                class="assign-title mb-3">
                                                {{ent.COMPONENT_ID + '-' + ent.ENTITLEMENT_ID + ' ' +
                                                ent.ENTITLEMENT_NAME}}
                                                <span *ngIf="ent.IS_ADMS_ENTITLEMENT == 1"><b><i>&nbsp;
                                                            ADMS</i></b> </span>
                                            </p>
                                        </div>
                                        <b class="mt-3">
                                            <span class="assign-sub-title">
                                                Grant-No And Not Approved
                                            </span>
                                        </b>
                                        <div class="mt-2">
                                            <p *ngFor='let ent of roleEntitlementSection?.GRANT_NO_AND_NOT_APPROVED'
                                                class="assign-title mb-3">
                                                {{ent.COMPONENT_ID + '-' + ent.ENTITLEMENT_ID + ' ' +
                                                ent.ENTITLEMENT_NAME}}
                                                <span *ngIf="ent.IS_ADMS_ENTITLEMENT == 1"><b><i>&nbsp;
                                                            ADMS</i></b> </span>
                                            </p>
                                        </div>
                                    </ng-template>
                                </p-overlayPanel>
                                <div>
                                    <p-dialog header="Delete role {{roleNameWithId}} from the user {{userWithIdName}}"
                                        styleClass="md" [modal]="true" [(visible)]="roleDeletePopUp"
                                        [baseZIndex]="10000">
                                        <p>Currently {{roleUserName}} has access for {{ totalSourceCount }}
                                            entitlements through the
                                            role '{{ role_Name }}'.</p>
                                        <p>The access for {{sourceCountWithRole}} out of those
                                            {{totalSourceCount}} entitlements will
                                            be removed for {{roleUserName}}, as they do
                                            not have access raised independently nor through any other role.</p>
                                        <p-footer class="btn-footer">
                                            <button pButton type="button" label="Cancel"
                                                (click)="roleDeletePopUp = false" class="p-button-secondary">
                                            </button>
                                            <button pButton type="button" label="Delete" (click)="confirmDeleteRole()">
                                            </button>
                                        </p-footer>
                                    </p-dialog>
                                </div>

                                <p-dialog header="Delete Entitlement" [modal]="true" [(visible)]="isConfirmDialog"
                                    [baseZIndex]="10000" styleClass="sm">
                                    <p>This action will Delete the Entitlement.</p>
                                    <p>{{confirmMessage}}</p>
                                    <p-footer>


                                        <button pButton type="button" label="Cancel" (click)="cancelConfimDialog()"
                                            class="p-button-secondary"></button>
                                        <button pButton type="button" label="Delete" class="p-button-primary p-mr-0"
                                            (click)="deleteEntitlementToTable()"></button>
                                    </p-footer>
                                </p-dialog>

                            </p-tabPanel>
                        </p-tabView>
                    </div>
                    <!--Close Tab view-->
                </p-card>
            </div>
        </div>
        <p-dialog [(visible)]="showAttributeValueFlag" [modal]="true" [baseZIndex]="10000"
            class="dialog-confirm user-contact" styleClass="sm" (onHide)="onCancelAttributeValue()">
            <ng-template pTemplate="header">
                <span>
                    <span class="dialog-Title" style="margin-left:-4px;">Add
                        {{ attributeName }}</span>
                </span>
            </ng-template>


            <div class="p-grid">
                <div class="p-col-12 p-p-0  p-mr-2" *ngFor="let field of userAttrColumnList; let index = index">

                    <div *ngIf="field && field.fieldLabel == 'Text Range'">
                        <label class="customlabels">{{ field.fieldName }} <span class="text-danger"
                                *ngIf="field.mandatoryField == true">*</span></label>
                        <div class="p-mb-3">
                            <input type="text" pInputText style="width:100%;" minlength="1" maxlength="100"
                                formControlName="{{field.id}}_dialog" id="{{field.id}}_dialog" name="{{field.id}}"
                                (change)="addressStartIpCheckDialog(field.id)" />

                            <div *ngIf="this['IpFlag_Dialog' + field.id]">
                                <label class="alert-icon alert-circle-icon"></label>
                                <medium class="p-error">Invalid field format.
                                </medium>
                            </div>
                            <div *ngIf="this['duplicityFlag_Dialog' + field.id]">
                                <label class="alert-icon alert-circle-icon"></label>
                                <medium class="p-error">Duplicate IP Range.
                                </medium>
                            </div>
                            <div *ngIf="this['greaterFlag_Dialog' + field.id]">
                                <label class="alert-icon alert-circle-icon"></label>
                                <medium class="p-error">Start IP cannot be greater than end
                                    IP.
                                </medium>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="field && field.fieldLabel == 'Text'">
                        <label class="customlabels">{{ field.fieldName }} <span class="text-danger"
                                *ngIf="field.mandatoryField == true">*</span></label>
                        <div class="p-mb-3">
                            <input type="text" *ngIf="field.fieldName == 'IPRange'" pInputText style="width:100%;"
                                name="{{ field.fieldName }}" minlength="1" maxlength="100"
                                formControlName="{{field.id}}_dialog" (keyup)="dialogInputChange(field)" />

                            <input type="text" *ngIf="field.fieldName != 'IPRange'" pInputText style="width:100%"
                                name="{{ field.fieldName }}" minlength="1" maxlength="100" [pattern]="field.pattern"
                                formControlName="{{field.id}}_dialog" (keyup)="dialogInputChange(field)" />
                        </div>
                    </div>
                    <div *ngIf="field && field.fieldLabel == 'numeric'">
                        <label class="customlabels">{{ field.fieldName }}</label><span class="text-danger"
                            *ngIf="field.mandatoryField == 1">*</span>
                        <div class="p-mb-3">
                            <input type="number" pInputText #inputText="ngModel" pattern="^[0-9]*$"
                                name="{{ field.fieldName }}" formControlName="{{field.id}}_dialog"
                                (keyup)="dialogInputChange(field.fieldName)" style="width:100%" minlength="1"
                                maxlength="100" />
                        </div>
                    </div>
                    <div *ngIf="field && field.fieldLabel == 'Dropdown'">
                        <label class="customlabels">{{ field.fieldName }}</label><span class="text-danger"
                            *ngIf="field.isMandatory == 1">*</span>
                        <div class="p-mb-3">
                            <p-dropdown class="d-inline-block mr-2" formControlName="{{field.id}}_dialog"
                                [options]="field.inputFormat" optionLabel="label" style="width:100%" [filter]="true"
                                placeholder="Select">
                            </p-dropdown>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template pTemplate="footer" class="p-pt-0">
                <div class="p-col-12 p-text-right p-p-0">
                    <button type="button" pButton (click)="onCancelAttributeValue()" label="Close"
                        class="p-button-brand2"></button>
                    <button type="button" pButton label="Save" (click)="onConfirmAttributeValue()" class="p-mr-0"
                        [disabled]="isInvalidIpRangeDialog"></button>
                </div>
            </ng-template>

        </p-dialog>
        <p-dialog header="Delete Attribute : {{attributeName}}" [modal]="true" [(visible)]="deleteUserAttribute"
            [baseZIndex]="10000" styleClass="sm">
            <p>{{confirmMessage}}</p>
            <p-footer>
                <button pButton type="button" label="Delete" class="p-button-brand2"
                    (click)="onConfirmPopup()"></button>
                <button pButton type="button" label="Cancel" (click)="onCancelUserPopup()"></button>
            </p-footer>
        </p-dialog>
    </form>
</div>