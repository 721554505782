<div style="background-color: #F6F8FA;" (click)="mouseLeave()">
    <div class="p-d-flex p-jc-end">
        <p-messages key="myKey1" class="custom-alert-box" *ngIf="this.messageFlag"></p-messages>
        <p-messages key="myKey2" class="custom-alert-box" *ngIf="this.messageFlag1"></p-messages>
    </div>
    <div class="p-col-fixed p-mt-1 p-ml-1 p-pr-0 custom-breadcrumb">
        <p-breadcrumb [model]="ccprimebreadcrumbList" [home]="homeiconccprimebreadcrumb">
        </p-breadcrumb>
    </div>
    <form class="layout-content custom-form-layout" [formGroup]="AttributesandGroupsForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                <div>
                    <h5 class="custom-head1">Attribute &amp; Group</h5>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left custom-tab-nav">
                <p-tabView [(activeIndex)]="AttributesandGroupsForm.value.tabview"
                    (onChange)="handleChangetabview($event)">
                    <p-tabPanel header="Attribute">
                        <div class="p-fluid p-formgrid p-grid p-mb-2">
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left p-p-0">
                                <div class=" p-fluid   p-field    ">
                                    <label
                                        [ngClass]="{'field-required': AttributesandGroupsForm.get('select1')?.errors?.required}">
                                        Application
                                    </label> <span class='invalid-feedback-astrik'>*</span>
                                    <div class="p-fluid">
                                        <p-dropdown id="select1id" name="select1" placeholder="Select"
                                            [options]="select1List" [filter]="true" filterBy="label"
                                            [autoDisplayFirst]="false" formControlName="select1"
                                            (onChange)="AttributeResourceListing();">
                                            <ng-template let-item pTemplate="item">
                                                <div *ngIf="item.label.length &lt;= 35;">
                                                    {{item.label}}
                                                </div>
                                                <div *ngIf="item.label.length > 35;" pTooltip="{{item.label}}"
                                                    tooltipPosition="top">
                                                    {{item.label.substring(0,35) + "..." }}
                                                </div>
                                            </ng-template>
                                        </p-dropdown>
                                    </div>
                                    <div class="invalid-feedback"
                                        *ngIf="AttributesandGroupsForm.get('select1')!.invalid && (AttributesandGroupsForm.get('select1')!.dirty || AttributesandGroupsForm.get('select1')!.touched)">
                                        <div *ngIf="AttributesandGroupsForm.get('select1')?.errors?.required">
                                            <label class="alert-icon alert-circle-icon"></label>
                                            <medium class="p-error">Application is required.</medium>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!flag1"
                                class="p-col-12 p-sm-12 p-md-12 p-lg-8 p-xl-8 p-offset-undefined p-right p-p-0">
                                <div class=" p-fluid   p-field    " class="custom-attr-fluid">

                                    <span class=" p-input-icon-left custom-search-span p-mr-3">
                                        <input type="text" (keydown.enter)="$event.preventDefault()" id="textField_id"
                                            name="textField_name" formControlName="textField" pInputText
                                            placeholder="Search"
                                            class="p-inputtext inputtextHeight custom-search-width "
                                            (input)="resourceFilterMethod($event.target.value)" />
                                        <i aria-hidden="true" class="pi pi-search"></i>
                                    </span>

                                    <div class="btnwrap custom-btn-height">
                                        <button pButton class="p-button-primary p-button" type="button"
                                            (click)="createAttribute()" label="Create" disabled>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="" *ngIf="flag1" class="custom-Attrtable-div">
                                <label class="d-icons group-icon"></label>
                            </div>
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-p-0 p-offset-undefined left">
                                <div class="  p-field">
                                    <div [ngClass]="{'content-section implementation': true}" *ngIf="!flag1">
                                        <p-table #tableid1 name="table1" styleClass="p-datatable-gridlines"
                                            [columns]="tablecols" [value]="tableList2" [paginator]="true" [rows]="10"
                                            selectionMode="Multiple"
                                            [globalFilterFields]="['attribute_name','attribute_resource_name','groupList','is_Default','is_Private']"
                                            [scrollable]="true" sortMode="single" [rowsPerPageOptions]="[10,25,50]"
                                            [paginator]="true" selectionMode="Multiple"
                                            (onFilter)="customFilter($event)">
                                            <ng-template pTemplate="header">
                                                <tr>
                                                    <th pSortableColumn='attribute_name' scope="col"
                                                        style="width:15% ;">
                                                        Type
                                                        <i class="p-sortable-column-icon icon-sort"
                                                            field='attribute_name' ariaLabel="Activate to sort"
                                                            ariaLabelDesc="Activate to sort in descending order"
                                                            ariaLabelAsc="Activate to sort in ascending order"></i>
                                                        <p-columnFilter type="text" field="attribute_name"
                                                            display="menu" class="p-ml-auto"></p-columnFilter>
                                                    </th>
                                                    <th pSortableColumn='attribute_resource_name' scope="col"
                                                        style="width:20% ;">
                                                        Name
                                                        <i class="p-sortable-column-icon icon-sort"
                                                            field='attribute_resource_name' ariaLabel="Activate to sort"
                                                            ariaLabelDesc="Activate to sort in descending order"
                                                            ariaLabelAsc="Activate to sort in ascending order"></i>
                                                        <p-columnFilter type="text" field="attribute_resource_name"
                                                            display="menu" class="p-ml-auto"></p-columnFilter>
                                                    </th>
                                                    <th pSortableColumn='groupList' scope="col" style="width: 20%;">
                                                        Group
                                                        <i class="p-sortable-column-icon icon-sort" field='groupList'
                                                            ariaLabel="Activate to sort"
                                                            ariaLabelDesc="Activate to sort in descending order"
                                                            ariaLabelAsc="Activate to sort in ascending order"></i>
                                                        <p-columnFilter type="text" field="groupList" display="menu"
                                                            class="p-ml-auto">
                                                        </p-columnFilter>
                                                    </th>
                                                    <th pSortableColumn='is_Private' scope="col" style="width: 16%;">
                                                        Access Type
                                                        <i class="p-sortable-column-icon icon-sort" field='is_Private'
                                                            ariaLabel="Activate to sort"
                                                            ariaLabelDesc="Activate to sort in descending order"
                                                            ariaLabelAsc="Activate to sort in ascending order"></i>
                                                        <p-columnFilter type="text" field="is_Private" display="menu"
                                                            class="p-ml-auto">
                                                        </p-columnFilter>
                                                    </th>
                                                    <th pSortableColumn='is_Default' scope="col" style="width: 18%;">
                                                        Default Attribute
                                                        <i class="p-sortable-column-icon icon-sort" field='is_Default'
                                                            ariaLabel="Activate to sort"
                                                            ariaLabelDesc="Activate to sort in descending order"
                                                            ariaLabelAsc="Activate to sort in ascending order"></i>
                                                        <p-columnFilter type="text" field="is_Default" display="menu"
                                                            class="p-ml-auto">
                                                        </p-columnFilter>
                                                    </th>
                                                    <th id="actionButton" style="width:20% ;">
                                                        <div class="p-d-flex p-jc-between p-ai-center"></div>
                                                    </th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-rowData let-index="rowIndex">
                                                <tr (mouseenter)="hoverIndex=index;highlight(index)"
                                                    (mouseleave)="hoverIndex=index;highlight(index)">
                                                    <td style="width:15% ;">
                                                        {{rowData.attribute_name}}
                                                    </td>
                                                    <td class="custom-Attrname-td" style="width:20% ;">
                                                        <span pTooltip="{{rowData.attribute_resource_name}}"
                                                            *ngIf="rowData.attribute_resource_name?.length >= 20"
                                                            tooltipPosition="top">
                                                            {{rowData.attribute_resource_name?.substring(0,20) +
                                                            "..." }}
                                                        </span>
                                                        <span *ngIf="rowData.attribute_resource_name?.length < 20">
                                                            {{rowData.attribute_resource_name}}
                                                        </span>
                                                    </td>
                                                    <td style="display: block;">
                                                        <span *ngIf="rowData.groupList!=''">{{rowData.groupList}}</span>
                                                        <span *ngIf="rowData.groupList==''">-</span>
                                                    </td>
                                                    <td class="custom-Attrname-td">
                                                        {{rowData.is_Private}}
                                                    </td>
                                                    <td class="custom-Attrname-td">
                                                        {{rowData.is_Default}}
                                                    </td>
                                                    <td class="p-jc-center" style="width:20% ;">
                                                        <div class="button-details custom-button-Details">
                                                            <button pButton tooltipPosition="top"
                                                                style="width: 24px;height: 20px;padding: 0px;margin-top: 4px;"
                                                                icon="g-icons viewedit-icon" pTooltip="Edit"
                                                                class="background-transparent"
                                                                (click)="activeItem1=rowData;EditAttribute();" disabled>
                                                            </button>
                                                            <button pButton tooltipPosition="top"
                                                                style="width: 24px;height: 20px;padding: 0px;margin-top: 4px;"
                                                                icon="g-icons delete-icon" pTooltip="Delete"
                                                                class="background-transparent"
                                                                (click)="activeItem1=rowData;DeleteAttribute();"
                                                                disabled>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="paginatorright" let-state> Total Records:
                                                {{state.totalRecords}} </ng-template>
                                            <ng-template pTemplate="emptymessage">
                                                <tr>
                                                    <td colspan="7" class="p-p-0 p-text-center"> <p-card>
                                                            <div style="margin-top: 5%;height: 70px;">No
                                                                matching records found</div>
                                                        </p-card> </td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </p-tabPanel>
                    <p-tabPanel header="Attribute group">
                        <div class="p-fluid p-formgrid p-grid p-mb-2">
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 left p-p-0">
                                <div class=" p-fluid   p-field    ">
                                    <label
                                        [ngClass]="{'field-required': AttributesandGroupsForm.get('select')?.errors?.required}">
                                        Application
                                    </label> <span class='invalid-feedback-astrik'>*</span>
                                    <div class="p-fluid">
                                        <p-dropdown id="selectid" name="select" placeholder="Select a Application"
                                            placeholder="Select" [options]="selectList" [filter]="true" filterBy="label"
                                            [autoDisplayFirst]="false" formControlName="select"
                                            (onChange)="AttributeGroupListing();">
                                            <ng-template let-item pTemplate="item">
                                                <div *ngIf="item.label.length &lt;= 35;">
                                                    {{item.label}}
                                                </div>
                                                <div *ngIf="item.label.length > 35;" pTooltip="{{item.label}}"
                                                    tooltipPosition="top">
                                                    {{item.label.substring(0,35) + "..." }}
                                                </div>
                                            </ng-template>
                                        </p-dropdown>
                                    </div>
                                    <div class="invalid-feedback"
                                        *ngIf="AttributesandGroupsForm.get('select')!.invalid && (AttributesandGroupsForm.get('select')!.dirty || AttributesandGroupsForm.get('select')!.touched)">
                                        <div *ngIf="AttributesandGroupsForm.get('select')?.errors?.required">
                                            <label class="alert-icon alert-circle-icon"></label>
                                            <medium class="p-error">Application is required.</medium>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!flag2"
                                class="p-col-12 p-sm-12 p-md-12 p-lg-8 p-xl-8 p-p-0 p-offset-undefined p-right">
                                <div class=" p-fluid   p-field    " class="custom-attr-fluid">
                                    <span class=" p-input-icon-left custom-search-span p-mr-3">
                                        <input type="text" (keydown.enter)="$event.preventDefault()" id="textField_id1"
                                            name="textField_name1" formControlName="textField1" pInputText
                                            placeholder="Search"
                                            class="p-inputtext inputtextHeight custom-search-width "
                                            (input)="filterMethod($event.target.value)" />
                                        <i aria-hidden="true" class="pi pi-search"></i>
                                    </span>
                                    <div class="btnwrap custom-btn-height">
                                        <button pButton class="p-button-primary p-button" type="button" (click)="show()"
                                            label="Create" disabled>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="" *ngIf="flag2" class="custom-Attrtable-div">
                                <label class="d-icons group-icon"></label>
                            </div>
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-p-0 p-offset-undefined left">
                                <div class="p-field">
                                    <div [ngClass]="{'content-section implementation': true}" *ngIf="!flag2">
                                        <p-table #tableid name="table" [columns]="tablecols" [value]="tableList"
                                            [rows]="10"
                                            [globalFilterFields]="['attributeGroupName','assignedAttributeCount','attributeGroupDescription']"
                                            [scrollable]="true" sortMode="single" [rowsPerPageOptions]="[10,25,50]"
                                            [paginator]="true" selectionMode="Multiple">
                                            <ng-template pTemplate="header">
                                                <tr>
                                                    <th pSortableColumn='attributeGroupName' scope="col"
                                                        style="width:30% ;">
                                                        Attribute Group Name
                                                        <i class="p-sortable-column-icon icon-sort"
                                                            field='attributeGroupName' ariaLabel="Activate to sort"
                                                            ariaLabelDesc="Activate to sort in descending order"
                                                            ariaLabelAsc="Activate to sort in ascending order"></i>
                                                        <p-columnFilter type="text" field="attributeGroupName"
                                                            display="menu" class="p-ml-auto"></p-columnFilter>
                                                    </th>
                                                    <th pSortableColumn='assignedAttributeCount' scope="col"
                                                        style="width:20% ;">
                                                        Attribute Assigned
                                                        <i class="p-sortable-column-icon icon-sort"
                                                            field='assignedAttributeCount' ariaLabel="Activate to sort"
                                                            ariaLabelDesc="Activate to sort in descending order"
                                                            ariaLabelAsc="Activate to sort in ascending order"></i>
                                                        <p-columnFilter type="text" field="assignedAttributeCount"
                                                            display="menu" class="p-ml-auto"></p-columnFilter>
                                                    </th>
                                                    <th pSortableColumn='attributeGroupDescription' scope="col"
                                                        style="width:35% ;">
                                                        Description
                                                        <i class="p-sortable-column-icon icon-sort"
                                                            field='attributeGroupDescription'
                                                            ariaLabel="Activate to sort"
                                                            ariaLabelDesc="Activate to sort in descending order"
                                                            ariaLabelAsc="Activate to sort in ascending order"></i>
                                                        <p-columnFilter type="text" field="attributeGroupDescription"
                                                            display="menu" class="p-ml-auto"></p-columnFilter>
                                                    </th>
                                                    <th id="actionButton2" style="width:20% ;">
                                                        <div class="p-d-flex p-jc-between p-ai-center"></div>
                                                    </th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-rowData let-index="rowIndex">
                                                <tr (mouseenter)="hoverIndex=index;highlight(index)"
                                                    (mouseleave)="hoverIndex=index;highlight(index)">
                                                    <td style="width:30% ;">{{rowData.attributeGroupName}} </td>
                                                    <td class="custom-Attrname-td" style="width:20% ;">
                                                        {{rowData.assignedAttributeCount}}
                                                    </td>
                                                    <td class="custom-description" style="width:35% ;">
                                                        <div *ngIf="rowData.attributeGroupDescription == 'null' ">
                                                            <span> </span>
                                                        </div>
                                                        <div *ngIf="rowData.attributeGroupDescription != 'null' ">
                                                            <span pTooltip="{{rowData.attributeGroupDescription}}"
                                                                *ngIf="rowData.attributeGroupDescription?.length >= 35"
                                                                tooltipPosition="top">
                                                                {{rowData.attributeGroupDescription?.substring(0,35) +
                                                                "..." }}
                                                            </span>
                                                            <span
                                                                *ngIf="rowData.attributeGroupDescription?.length < 35">
                                                                {{rowData.attributeGroupDescription}}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td class="p-jc-center" style="width:20% ;">
                                                        <div class="button-details custom-button-Details">
                                                            <button pButton tooltipPosition="top"
                                                                style="width: 24px;height: 24px;padding: 0px;"
                                                                icon="g-icons viewedit-icon" pTooltip="Edit"
                                                                class="background-transparent"
                                                                (click)="activeItem=rowData;Edit()" disabled>
                                                            </button>
                                                            <button pButton tooltipPosition="top"
                                                                style="width: 24px;height: 24px;padding: 0px;"
                                                                icon="g-icons delete-icon" pTooltip="Delete"
                                                                class="background-transparent"
                                                                (click)="activeItem=rowData;Delete()" disabled>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="paginatorright" let-state> Total Records:
                                                {{state.totalRecords}} </ng-template>
                                            <ng-template pTemplate="emptymessage">
                                                <tr>
                                                    <td colspan="7" class="p-p-0 p-text-center"> <p-card>
                                                            <div style="margin-top: 5%;height: 70px;">No
                                                                matching records found</div>
                                                        </p-card> </td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </p-tabPanel>
                    <p-tabPanel header="Mapping">
                        <div class="p-fluid p-formgrid p-grid p-mb-2">
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-0 p-p-0 left p-mr-3">
                                <div class=" p-fluid   p-field    ">
                                    <label
                                        [ngClass]="{'field-required': AttributesandGroupsForm.get('select2')?.errors?.required}">
                                        Application
                                    </label> <span class='invalid-feedback-astrik'>*</span>
                                    <div class="p-fluid">
                                        <p-dropdown [showClear]="true" [filter]="true" filterBy="label" id="select2id"
                                            name="select2" placeholder="Select a Application"
                                            (onChange)="getAttributeGroupListing();" [options]="select2List"
                                            [autoDisplayFirst]="false" formControlName="select2">
                                            <ng-template let-items pTemplate="item">
                                                <div *ngIf="items.label.length &lt;= 35;">
                                                    {{items.label}}
                                                </div>
                                                <div *ngIf="items.label.length > 35;" pTooltip="{{items.label}}"
                                                    tooltipPosition="top">
                                                    {{items.label.substring(0,35) + "..." }}
                                                </div>
                                            </ng-template>
                                        </p-dropdown>
                                    </div>
                                    <div class="invalid-feedback"
                                        *ngIf="AttributesandGroupsForm.get('select2')!.invalid && (AttributesandGroupsForm.get('select2')!.dirty || AttributesandGroupsForm.get('select2')!.touched)">
                                        <div *ngIf="AttributesandGroupsForm.get('select2')?.errors?.required">
                                            <label class="alert-icon alert-circle-icon"></label>
                                            <medium class="p-error">Application is required.</medium>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left p-p-0">
                                <div class=" p-fluid   p-field    ">
                                    <label
                                        [ngClass]="{'field-required': AttributesandGroupsForm.get('select3')?.errors?.required}">
                                        Attribute Group Name
                                    </label><span class='invalid-feedback-astrik'>*</span>
                                    <div class="p-fluid">
                                        <p-dropdown [showClear]="true" [filter]="true" filterBy="label" id="select3id"
                                            name="select3" placeholder="Select a Attribute Group"
                                            [options]="select3List" [autoDisplayFirst]="false"
                                            (onChange)="getMappedDetails();" formControlName="select3">
                                            <ng-template let-items pTemplate="item">
                                                <div *ngIf="items.label.length &lt;= 35;">
                                                    {{items.label}}
                                                </div>
                                                <div *ngIf="items.label.length > 35;" pTooltip="{{items.label}}"
                                                    tooltipPosition="top">
                                                    {{items.label.substring(0,35) + "..." }}
                                                </div>
                                            </ng-template>
                                        </p-dropdown>
                                    </div>
                                    <div class="invalid-feedback"
                                        *ngIf="AttributesandGroupsForm.get('select3')!.invalid && (AttributesandGroupsForm.get('select3')!.dirty || AttributesandGroupsForm.get('select3')!.touched)">
                                        <div *ngIf="AttributesandGroupsForm.get('select3')?.errors?.required">
                                            <label class="alert-icon alert-circle-icon"></label>
                                            <medium class="p-error">Attribute Group name is required.</medium>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-offset-undefined left">
                            </div>
                            <div class="custom-Attrtable-div" *ngIf="flag3">
                                <label class="d1-icons group1-icon"></label>
                                <label class="e-error">Please fill above&nbsp;<strong>Mandatory fields.
                                    </strong>&nbsp;</label>
                            </div>
                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-p-0 p-offset-undefined left">
                                <div *ngIf="!flag3" class=" p-fluid   p-field    ">
                                    <div [class]="styleClass" [ngStyle]="style"
                                        class=" p-grid custom-picklist-with-checkbox"
                                        [ngClass]="{'p-picklist p-component p-widget p-helper-clearfix': true,'p-picklist-responsive': responsive}">
                                        <div class="p-col-fixed p-picklist-list-wrapper p-picklist-source-wrapper"
                                            [ngClass]="{'p-picklist-listwrapper-nocontrols':!showSourceControls}">
                                            <div class="p-picklist-header">
                                                <div class="p-picklist-title">Attributes
                                                    <span class="p-picklist-count">{{pickListList.length}}</span>
                                                </div>
                                            </div>
                                            <div class="p-picklist-filter-container"
                                                *ngIf="filterBy && showSourceFilter !== false">
                                                <div class="p-picklist-filter">
                                                    <span class="p-input-icon-left" style="margin: 0px;">
                                                        <input #sourceFilter type="text" role="textbox"
                                                            (keydown.enter)="$event.preventDefault()"
                                                            class="p-picklist-filter-input p-inputtext inputtextHeight p-component"
                                                            placeholder="Search By Name"
                                                            (input)="filterMethodPick($event.target.value,pickListList1,'pick1source')"
                                                            [disabled]="disabled" formControlName="sourceFilter">
                                                        <i aria-hidden="true" class="pi pi-search"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <ul #sourcelist class="cdk-drop-list p-picklist-list p-picklist-source"
                                                [ngClass]="{'p-picklist-highlight': listHighlightSource}"
                                                [ngStyle]="sourceStyle">
                                                <ng-template ngFor let-item [ngForOf]="pickListList"
                                                    [ngForTrackBy]="sourceTrackBy || trackBy" let-i="index"
                                                    let-l="last">
                                                    <li [ngClass]="{'cdk-drag p-picklist-item p-ripple':true,'p-state-highlight':isSelected(item,selectedItemsSource), 'p-state-disabled': disabled}"
                                                        [style.display]="isItemVisible(item, SOURCE_LIST) ? 'block' : 'none'"
                                                        style="margin-right: 4px;">
                                                        <div class="product-item"
                                                            style="padding-top: 0rem;padding-bottom: 0rem;height: 25px;">
                                                            <span class="list-check image-container p-mr-2 p-mt-0">
                                                                <p-checkbox name="groupname" [value]="isChecked"
                                                                    #picklistCheckboxes [(ngModel)]="item.isChecked"
                                                                    binary="true"
                                                                    (ngModelChange)="onItemClick($event,item,selectedItemsSource,onSourceSelect)"
                                                                    [ngModelOptions]="{standalone: true}">
                                                                </p-checkbox>
                                                            </span>
                                                            <div class="product-list-detail p-d-flex">
                                                                <div class="p-col p-pl-0 pClass"
                                                                    pTooltip="{{item.name}}"
                                                                    *ngIf="item?.name?.length >= 32"
                                                                    tooltipPosition="top">
                                                                    {{item?.name?.substring(0,32) +
                                                                    "..."}}</div>
                                                                <div class="p-col p-pl-0 pClass"
                                                                    *ngIf="item?.name?.length < 32">
                                                                    {{item?.name}}</div>
                                                                <div class="p-col-fixed pClass">{{item.isPrivate}}</div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li class="p-picklist-droppoint" *ngIf="dragdrop&&l"
                                                        (dragover)="onDragOver($event, i + 1, SOURCE_LIST)"
                                                        (drop)="onDrop($event, i + 1, SOURCE_LIST)"
                                                        (dragleave)="onDragLeave($event, SOURCE_LIST)"
                                                        [ngClass]="{'p-picklist-droppoint-highlight': (i + 1 === dragOverItemIndexSource)}">
                                                    </li>
                                                </ng-template>
                                            </ul>
                                        </div>
                                        <div class="p-col-fixed p-picklist-buttons p-picklist-transfer-buttons">
                                            <button type="button" pButton pRipple icon="pi pi-angle-right"
                                                class="p-ripple p-button p-component p-button-icon-only"
                                                (click)="moveRight()">

                                                <span class="p-ink"></span>
                                            </button>
                                            <button type="button" pButton icon="pi pi-angle-double-right"
                                                class="p-ripple p-button p-component p-button-icon-only"
                                                (click)="moveAllRight()">

                                                <span class="p-ink"></span>
                                            </button>
                                            <button type="button" pButton icon="pi pi-angle-left"
                                                class="p-ripple p-button p-component p-button-icon-only"
                                                (click)="moveLeft()">

                                                <span class="p-ink"></span>
                                            </button>
                                            <button type="button" pButton icon="pi pi-angle-double-left"
                                                class="p-ripple p-button p-component p-button-icon-only"
                                                (click)="moveAllLeft()">

                                                <span class="p-ink"></span>
                                            </button>
                                        </div>
                                        <div class="p-col-fixed p-picklist-list-wrapper p-picklist-target-wrapper"
                                            [ngClass]="{'p-picklist-listwrapper-nocontrols':!showTargetControls}">
                                            <div class="p-picklist-header">
                                                <div class="p-picklist-title">Mapped Attributes
                                                    <span class="p-picklist-count">{{ pickListtargetList.length}}</span>
                                                </div>
                                            </div>
                                            <div class="p-picklist-filter-container"
                                                *ngIf="filterBy && showSourceFilter !== false">
                                                <div class="p-picklist-filter">
                                                    <span class="p-input-icon-left" style="margin: 0px;">
                                                        <input #sourceFilter type="text" role="textbox"
                                                            (keydown.enter)="$event.preventDefault()"
                                                            class="p-picklist-filter-input p-inputtext inputtextHeight p-component"
                                                            placeholder="Search By Name"
                                                            (input)="filterMethodPick($event.target.value,pickListtargetList1,'pick1target')"
                                                            [disabled]="disabled" formControlName="sourceFilter">
                                                        <i aria-hidden="true" class="pi pi-search"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <ul #targetlist cdkDropList
                                                class="cdk-drop-list p-picklist-list p-picklist-target"
                                                [ngClass]="{'p-picklist-highlight': listHighlightTarget}"
                                                [ngStyle]="targetStyle">
                                                <ng-template ngFor let-item [ngForOf]="pickListtargetList"
                                                    [ngForTrackBy]="targetTrackBy || trackBy" let-i="index"
                                                    let-l="last">
                                                    <li pRipple cdkDrag role="option"
                                                        [ngClass]="{'cdk-drag p-picklist-item p-ripple':true,'p-state-highlight':isSelected(item,selectedItemsTarget), 'p-state-disabled': disabled}"
                                                        [style.display]="isItemVisible(item, TARGET_LIST) ? 'flex' : 'none'"
                                                        style="margin-right: 4px;">
                                                        <div class="product-item"
                                                            style="padding-top: 0rem;padding-bottom: 0rem;height: 25px;">
                                                            <span class="list-check image-container p-mr-2 p-mt-0">
                                                                <p-checkbox name="groupname" [value]="isChecked"
                                                                    #picklistCheckboxes [(ngModel)]="item.isChecked"
                                                                    binary="true"
                                                                    (ngModelChange)="onItemClick($event,item,selectedItemsTarget,onTargetSelect)"
                                                                    [ngModelOptions]="{standalone: true}">
                                                                </p-checkbox>
                                                            </span>
                                                            <div class="product-list-detail p-d-flex">
                                                                <div class="p-col p-pl-0 pClass"
                                                                    pTooltip="{{item.name}}"
                                                                    *ngIf="item?.name?.length >= 32"
                                                                    tooltipPosition="top">
                                                                    {{item?.name?.substring(0,32) +
                                                                    "..."}}</div>
                                                                <div class="p-col p-pl-0 pClass"
                                                                    *ngIf="item?.name?.length < 32">
                                                                    {{item?.name}}</div>
                                                                <div class="p-col-fixed pClass">{{item.isPrivate}}</div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li class="p-picklist-droppoint" *ngIf="dragdrop&&l"
                                                        (dragover)="onDragOver($event, i + 1, TARGET_LIST)"
                                                        (drop)="onDrop($event, i + 1, TARGET_LIST)"
                                                        (dragleave)="onDragLeave($event, TARGET_LIST)"
                                                        [ngClass]="{'p-picklist-droppoint-highlight': (i + 1 === dragOverItemIndexTarget)}">
                                                    </li>
                                                </ng-template>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-p-0 p-offset-undefined p-right p-mb-4">
                                <div class="btnwrap custom-picklist-btn">
                                    <button *ngIf="!flag3" pButton class="p-button-primary p-button" type="button"
                                         label="Save" (click)="onSave($event)">
                                    </button>
                                </div>
                            </div>
                        </div>
                    </p-tabPanel>
                </p-tabView>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-mb-4 p-offset-undefined left">
            </div>
        </div>
    </form>
</div>