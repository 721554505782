<div style="background-color:#F6F8FA;" (click)="mouseLeave()">
    <div class="p-d-flex p-jc-end">
        <p-messages key="myKey1" class="custom-alert-box" *ngIf="this.messageFlag"></p-messages>
    </div>
    <div class="p-col-fixed p-mt-1 p-ml-1 p-pr-0 custom-breadcrumb">
        <p-breadcrumb [model]="ccprimebreadcrumbList" [home]="homeiconccprimebreadcrumb">
        </p-breadcrumb>
    </div>
    <div class="layout-content p-pt-0 custom-form-layout">
        <div class=" p-col-12">
            <div class="ui-fluid p-formgrid p-grid">
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left p-p-0">
                    <h5 class="custom-head1">View Role</h5>
                    <div style="height: auto;width: 100%;">
                        <p-card class="cardBoxShadow">
                            <div class="p-grid p-mt-0">
                                <div class="p-col-3">
                                    <span class="role-style"
                                        style="font-size:16px;word-break: break-word;"><strong>{{this.ViewRoleForm.value.roleName}}</strong></span><br>
                                    <span style="font-size:13px;">Created on: {{this.ViewRoleForm.value.createdOn
                                        | date:'d MMM y'}}</span><br>
                                    <span style="font-size:13px;" *ngIf="this.ViewRoleForm.value.roleType != null">Type:
                                        <div class="btnwrap">
                                            <button style="font-size:13px;" pButton type="button" label="Simple"
                                                style="border-radius:32px;font-size: 11px;font-weight: 500;padding: 2px 8px;height:25px;box-shadow: none;"
                                                [style.color]=" '#12813F' " [style.border]=" '1px solid #CFE6D8' "
                                                class=" p-button-outlined  ">{{this.ViewRoleForm.value.roleType}}
                                            </button>
                                        </div>
                                    </span>
                                </div>
                                <div class="p-col">
                                    <span><strong>About</strong></span><br>
                                    <span
                                        style="word-break: break-word;">{{this.ViewRoleForm.value.roleDescription}}</span>
                                </div>
                                <div class="p-col-fixed p-text-right p-p-0" style="width: 50px">
                                    <button #btn type="button" pButton icon="pi pi-ellipsis-v"
                                        (click)="menu.toggle($event)" style="margin-left:60px;" appendTo="body"
                                        style="background-color:#ffffff;border-color: #D3D5DB;box-shadow: none;border-radius: 4px;width:30px;height:30px;color:#3A435E;" [disabled]="this.pagesService.userManagementReadOnlyFlag"></button>
                                    <p-tieredMenu #menu [model]="items" [popup]="true" appendTo="body">
                                    </p-tieredMenu>
                                </div>
                            </div>
                        </p-card>
                    </div>
                    <div style="margin-top: 10px;width: 100%;margin-bottom: 60px;">
                        <p-card class="cardBoxShadow">
                            <span class="role-style" style="font-size:14px;">Role Details</span>
                            <p-tabView>
                                <p-tabPanel [disabled]="this.pagesService.pageTenantAdminFlag==false && this.pagesService.userManagementReadOnlyFlag==false">
                                    <ng-template pTemplate="header">
                                        <span class="label-badge">Users Assigned</span>
                                        <p-badge [value]="this.usercount" styleClass="mr-2"></p-badge>
                                    </ng-template>
                                    <div class="mobilefriendlytable"
                                        [ngClass]="{'content-section implementation': true}">
                                        <label *ngIf="userTableList.length==0"
                                            style="margin-top: 14px;font-size: 15px;">
                                            No Users Assigned!
                                        </label>
                                        <p-table #userTableid name="userTable" *ngIf="userTableList.length > 0"
                                            styleClass="p-datatable-gridlines" [columns]="userTablecols"
                                            [value]="userTableList" [rows]="5" [scrollable]="true" sortMode="single"
                                            [paginator]="true" [rowsPerPageOptions]="[5,10,15]">
                                            <ng-template pTemplate="header">
                                                <tr>
                                                    <th pSortableColumn='firstName' scope="col">
                                                        First Name
                                                        <i class="p-sortable-column-icon icon-sort" [field]='firstName'
                                                            ariaLabel="Activate to sort"
                                                            ariaLabelDesc="Activate to sort in descending order"
                                                            ariaLabelAsc="Activate to sort in ascending order"></i>
                                                        <p-columnFilter type="text" field="firstName" display="menu"
                                                            class="p-ml-auto"></p-columnFilter>
                                                    </th>
                                                    <th pSortableColumn='lastName' scope="col">
                                                        Last Name
                                                        <i class="p-sortable-column-icon icon-sort" field='lastName'
                                                            ariaLabel="Activate to sort"
                                                            ariaLabelDesc="Activate to sort in descending order"
                                                            ariaLabelAsc="Activate to sort in ascending order"></i>
                                                        <p-columnFilter type="text" field="lastName" display="menu"
                                                            class="p-ml-auto"></p-columnFilter>
                                                    </th>
                                                    <th pSortableColumn='email' scope="col">
                                                        Email Address
                                                        <i class="p-sortable-column-icon icon-sort" field='email'
                                                            ariaLabel="Activate to sort"
                                                            ariaLabelDesc="Activate to sort in descending order"
                                                            ariaLabelAsc="Activate to sort in ascending order"></i>
                                                        <p-columnFilter type="text" field="email" display="menu"
                                                            class="p-ml-auto"></p-columnFilter>
                                                    </th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-rowData let-index="rowIndex">
                                                <tr style="height:40px" (mouseenter)="hoverIndex=index;highlight(index)"
                                                    (mouseleave)="hoverIndex=index;highlight(index)"
                                                    [pSelectableRow]="rowData">
                                                    <td>
                                                        {{rowData.firstName}}
                                                    </td>
                                                    <td style="color: rgba(41, 41, 41, 0.8);font-weight: 400;">

                                                        {{rowData.lastName}}
                                                    </td>
                                                    <td>
                                                        {{rowData.email}}
                                                    </td>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="paginatorright" let-state> Total Records:
                                                {{state.totalRecords}} </ng-template>
                                        </p-table>
                                    </div>
                                </p-tabPanel>
                                <p-tabPanel [disabled]="this.pagesService.pageTenantAdminFlag==false && this.pagesService.userManagementReadOnlyFlag==false">
                                    <ng-template pTemplate="header">
                                        <span class="label-badge">Group Assigned</span>
                                        <p-badge [value]="this.groupcounts" styleClass="mr-2"></p-badge>
                                    </ng-template>
                                    <p-chip *ngFor="let roleee of this.groupDetails;" styleClass="mr-2 mb-2"
                                        class="custom-view-chip p-pr-2">{{roleee.label}}</p-chip>
                                    <label *ngIf="this.groupDetails.length==0"
                                        style="margin-top: 14px;font-size: 15px;">
                                        No Groups Assigned!
                                    </label>
                                </p-tabPanel>
                            </p-tabView>
                        </p-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>