import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DataService {
    private cancelStatus = new BehaviorSubject<any>('0');
    private loaderStatus = new Subject<any>();

    sendMessage(message: any) {
        console.log('SendMessage called----------------');

        this.cancelStatus.next(message);
    }

    getMessage(): Observable<any> {
        console.log('getMessage called----------------');

        return this.cancelStatus.asObservable();
    }
    setIsLoader(message: boolean) {
        this.loaderStatus.next(message);
    }

    getIsLoader(): Observable<any> {
        return this.loaderStatus.asObservable();
    }
}
