import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { PagesService } from '../pages.service';
@Component({
  selector: 'jhi-createattributegroup',
  templateUrl: './EditCustomField.component.html',
  styleUrls: ['./EditCustomField.component.scss'],
  providers: [DialogService],
})
export class EditCustomField implements OnInit {
  EditCustomFieldForm = this.fb.group({
    Entity: [null, [Validators.required]],
    fieldName: [null, [Validators.required]],
    fieldInputType: [null, [Validators.required]],
    inputValueFormat: [null, []],
    inputValueFormat1: [null, []],
    inputValueFormattext: [null, []],
    inputValueFormatDropdown: [null, []],
    MaxCharacters: [null, []],
    editable: [false, []],
    mandatory: [false, []],
    inputValueFormatmultiselect: [null, []],
    inputValueFormatdemo: [null, []],
    specialCharacter: [null, []],
    inputValueFormatdemo1: [null, []],
  });
  pageloadvalues: any;
  nameFlag: boolean = false;
  enabledbtn1: boolean = false;
  inputFlag: boolean = false;
  maxlengthFlag: boolean = false;
  rangeFlag: boolean = false;
  selectList: any[] = [];
  selectList1: any[] = [];
  selectList2: any[] = [];
  SelectTreevalue: any[] = [];
  fieldtype: any[] = [];
  inputStep: boolean = true;
  inputformat: any[] = [];
  inputtypeId: any;
  checkededitable: boolean = false;
  checkedmandatory: boolean = false;
  inputType: any;
  checkbox: boolean = false;
  dropdownvalues: any[];
  entitylabel: any;
  fieldlabel: any;
  defaultFlag: boolean = true;
  messageFlag = true;
  treedata: any[] = [];
  input: any;
  fieldID: any;
  fieldName: any;
  moduleID: any;
  fieldname: any;
  entityname: any;
  field_type: any;
  inputtype_id: any;
  inputFormatid: any;
  inputFormatData: any;
  inputformatDropdown: any;
  dropdownData: any;
  editableField: any;
  mandatoryField: any;
  MaxCharacters: any;
  inputformatRange: any;
  rangeFormatData: any = "";
  formatRange: any[] = [];
  treeSelectdata: any[] = [];
  SelectTree: any[];
  multiselect: any[];
  dataFormat: { label: string; value: string; }[];
  SpecialCharacter: { label: string; value: string; }[];
  specialFlag: boolean = false;
  Selectspecial: any[] = [];
  specialCharacter: any;
  specialcharlabel: any[];
  special: any;
  enablebtn: boolean = false;
  oldFieldName: any;

  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public pagesService: PagesService) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.EditCustomFieldForm.patchValue(JSON.parse(data));
    }

  }

  ngOnInit(): void {
    this.showInfo();
    this.moduleID = this.config?.data?.moduleid;
    this.field_type = this.config?.data?.fieldtype;
    this.inputtypeId = this.config?.data?.inputtype_id;
    this.special = this.config?.data?.specialCharacter;
    this.EditCustomFieldForm.get('Entity').disable();
    this.EditCustomFieldForm.get('fieldInputType').disable();
    this.fieldID = this.config?.data?.id;
    this.fieldname = this.config?.data?.name;
    this.oldFieldName = this.config?.data?.name;
    this.getInputformatrange();
    this.entityname = this.config?.data?.entityName;
    this.getInputformat();
    this.SelectTreevalue = this.config?.data?.inputFormatData;
    this.getSpecialCharacter();
    this.fieldtypevalidate();
    this.multiselect = [
      { label: 'Numbers', value: 'Numbers' },
      { label: 'Lowercase alphabet', value: 'RM' },
      { label: 'Uppercase alphabet', value: 'LDN' },
      { label: 'Underscore (_)', value: 'IST' },
      { label: 'Colon (:)', value: 'PRS' },
      { label: 'At (@)', value: 'Numbers' },
      { label: 'Comma (,)', value: 'RM' },
      { label: 'Hash (#)', value: 'LDN' },
      { label: 'Hyphen (-)', value: 'IST' },
      { label: 'Asterisk (*)', value: 'PRS' }
    ];
    this.dataFormat = [
      { label: 'Numbers', value: 'Numbers' },
      { label: 'Lowercase alphabet', value: 'RM' },
      { label: 'Uppercase alphabet', value: 'LDN' },
      { label: 'Special Character', value: 'Special Character' },
    ];
  }

  showInfo() {
    this.messageFlag = true;
    setTimeout(() => {
    }, 5000);
  }

  clearmsg() {
    this.messageFlag = false;
  }

  colorChange() {
    document.getElementById('fieldName_id')!.style.borderColor = 'grey';
  }

  specialcharacter() {
    this.formatData = this.EditCustomFieldForm.value.inputValueFormat;

    for (let i = 0; i < this.formatData.length; i++) {
      if (this.formatData[i] == "Special charecters") {
        this.specialFlag = true;
        this.EditCustomFieldForm.controls['inputValueFormatdemo1'].setValidators(Validators.required);
        this.EditCustomFieldForm.controls['inputValueFormatdemo1'].updateValueAndValidity();
        break;
      } else {
        this.specialFlag = false;
        this.EditCustomFieldForm.controls['inputValueFormatdemo1'].clearValidators();
        this.EditCustomFieldForm.controls['inputValueFormatdemo1'].reset();
      }
    }
  }

  formatData(formatData: any) {
    throw new Error('Method not implemented.');
  }

  onClose(): void {
    this.ref.close();
  }

  onSaveAddCustomField() {
    const reqBody = {};
    reqBody['entityId'] = this.moduleID;
    reqBody['entityName'] = this.entityname;
    reqBody['fieldLabel'] = this.field_type;
    if (this.inputtypeId == 'ec6becd6-3d6c-11ed-b878-0242ac120002') {
      //text
      this.treedata = this.SelectTreevalue;
      if (this.specialFlag) {
        this.specialcharlabel = [];
        this.Selectspecial.forEach(element1 => {
          this.SpecialCharacter.forEach(element2 => {
            if (element2.value == element1) {
              this.specialcharlabel.push(element2);
            }
          });
        });
        reqBody['specialCharacter'] = this.specialcharlabel;
      }
      reqBody['inputtype_id'] = this.inputtypeId;
      reqBody['inputvalue_format'] = this.treedata;
      reqBody['MaxCharacters'] = this.EditCustomFieldForm.value.MaxCharacters;
      if (this.EditCustomFieldForm.value.mandatory == null) {
        reqBody['mandatory'] = false;
      }
      else {
        reqBody['mandatory'] = this.EditCustomFieldForm.value.mandatory;
      }
      if (this.EditCustomFieldForm.value.editable == null) {
        reqBody['editable'] = false;
      }
      else {
        reqBody['editable'] = this.EditCustomFieldForm.value.editable;
      }
    } else if (this.inputtypeId == '0c004e6a-3d82-11ed-b878-0242ac120002') {
      //textarea
      this.treedata = this.SelectTreevalue;
      reqBody['inputtype_id'] = this.inputtypeId;
      reqBody['inputvalue_format'] = this.treedata;
      if (this.specialFlag) {
        this.specialcharlabel = [];
        this.Selectspecial.forEach(element1 => {
          this.SpecialCharacter.forEach(element2 => {
            if (element2.value == element1) {
              this.specialcharlabel.push(element2);
            }
          });
        });
        reqBody['specialCharacter'] = this.specialcharlabel;
      }
      reqBody['MaxCharacters'] = this.EditCustomFieldForm.value.MaxCharacters;
      if (this.EditCustomFieldForm.value.mandatory == null) {
        reqBody['mandatory'] = false;
      }
      else {
        reqBody['mandatory'] = this.EditCustomFieldForm.value.mandatory;
      }
      if (this.EditCustomFieldForm.value.editable == null) {
        reqBody['editable'] = false;
      }
      else {
        reqBody['editable'] = this.EditCustomFieldForm.value.editable;
      }
    } else if (this.inputtypeId == '9ac9c6e2-3e38-11ed-b878-0242ac120002') {
      //dropdown
      reqBody['inputtype_id'] = this.inputtypeId;
      const dropdownformat = this.EditCustomFieldForm.value.inputValueFormattext.split(',');
      this.dropdownvalues = [];
      dropdownformat.forEach(element => {
        const dropDownData = { "label": element }
        this.dropdownvalues.push(dropDownData);
      });
      this.dropdownvalues = [...this.dropdownvalues];
      reqBody['inputvalue_format'] = this.dropdownvalues;
      if (this.EditCustomFieldForm.value.mandatory == null) {
        reqBody['mandatory'] = false;
      }
      else {
        reqBody['mandatory'] = this.EditCustomFieldForm.value.mandatory;
      }
      if (this.EditCustomFieldForm.value.editable == null) {
        reqBody['editable'] = false;
      }
      else {
        reqBody['editable'] = this.EditCustomFieldForm.value.editable;
      }
    } else if (this.inputtypeId == 'fc7400af-c135-4fda-b7b5-747dd3f6263d') {
      //range
      reqBody['inputtype_id'] = this.inputtypeId;
      reqBody['inputvalue_format'] = [this.EditCustomFieldForm.value.inputValueFormatDropdown];
      if (this.EditCustomFieldForm.value.mandatory == null) {
        reqBody['mandatory'] = false;
      }
      else {
        reqBody['mandatory'] = this.EditCustomFieldForm.value.mandatory;
      }
      if (this.EditCustomFieldForm.value.editable == null) {
        reqBody['editable'] = false;
      }
      else {
        reqBody['editable'] = this.EditCustomFieldForm.value.editable;
      }
    } else if (this.inputtypeId == 'f959e7b2-3d81-11ed-b878-0242ac120002') {
      reqBody['inputtype_id'] = this.inputtypeId;
    }
    const reqBodySave = {};
    reqBodySave['id'] = this.fieldID;
    reqBodySave['active'] = "true";
    reqBodySave['fieldName'] = this.EditCustomFieldForm.value?.fieldName;
    reqBodySave['configJson'] = JSON.stringify(reqBody);
    const data = this.moduleID;
    const formValues = reqBodySave;
    this.pagesService.onSaveAddCustomField(formValues, data).subscribe(
      {
        next: results => {
          this.ref.close("Custom field added successfully");
        },
        error: error => {
          this.ref.close("Custom field Creation Failed");
        }
      });
  }

  data() {
    this.treeSelectdata = this.SelectTree;
  }

  getEntityList(): void {
    this.selectList = [];
    this.pagesService.getEntity().subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.selectList.push(data);
        });
        this.selectList = [...this.selectList];
      }
    });
  }

  getinputFieldTypeList(): void {
    this.pagesService.getFieldType().subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
            type: null
          };
          data.label = element.field_lable;
          data.value = element.field_id;
          data.type = element.field_type;
          this.fieldtype.push(data);
        });
        this.fieldtype = [...this.fieldtype];
      }
    });
    this.inputtypeId = this.config?.data?.inputtype_id;
    this.fieldtypevalidate();
  }


  onSaveCheckFieldName(): void {
    if (this.oldFieldName != this.EditCustomFieldForm.value?.fieldName) {
      const reqBody = this.EditCustomFieldForm.value?.fieldName;
      const formData = reqBody;
      const formValues = this.EditCustomFieldForm.value?.fieldName;
      const EntityId = this.moduleID;
      this.pagesService.onCheckFieldName(formData, formValues, EntityId).subscribe(
        results => {
          if (results?.error?.code == 400 || results?.success?.code == 400) {
            this.nameFlag = true;
            document.getElementById('fieldName_id')!.style.borderColor = 'red';
          } else if (results.success.code == 200) {
            this.nameFlag = false;
            document.getElementById('fieldName_id')!.style.borderColor = 'grey';
            this.onSaveAddCustomField();
          }
        }
      );
    } else {
      this.onSaveAddCustomField();
    }
  }

  getInputformat(): void {
    this.inputformat = [];
    this.pagesService.getInputformat(this.inputtypeId).subscribe(results => {
      const responseData = results?.success?.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
            children: [],
            parent: []

          };
          data.label = element.label;
          data.value = element.label;
          this.inputformat.push(data);
        });
        this.inputformat = [...this.inputformat];
      }
    });
  }

  getSpecialCharacter(): void {
    this.SpecialCharacter = [];
    this.pagesService.getInputformat(this.inputtypeId).subscribe(results => {
      const responseData = results?.success?.result[2]?.children;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null
          };
          data.label = element.label;
          data.value = element.value;
          this.SpecialCharacter.push(data);
        });
        this.SpecialCharacter = [...this.SpecialCharacter];
      }
    });
  }

  getInputformatrange(): void {
    this.inputformat = [];
    this.pagesService.getInputformat(this.inputtypeId).subscribe(results => {
      const responseData = results?.success?.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null
          };
          data.label = element.label;
          data.value = element.label;
          this.formatRange.push(data);
        });
        this.formatRange = [...this.formatRange];
      }
    });
  }

  formValueSo() {
    for (let i = 0; i < this.inputformatDropdown.length; i++) {
      if (i == 0) {
        this.dropdownData = this.inputformatDropdown[i].label + ",";
      } else if (i == this.inputformatDropdown.length - 1) {
        this.dropdownData += this.inputformatDropdown[i].label;
      }
      else {
        this.dropdownData += this.inputformatDropdown[i].label + ",";
      }
    }
  }

  fieldtypevalidate() {
    this.inputType = this.field_type;
    if (this.inputType == 'Text') {
      //text field
      this.treedata = [];
      this.maxlengthFlag = true;
      this.inputFlag = false;
      this.rangeFlag = false;
      if (this.SelectTreevalue.includes('Special charecters')) {
        this.specialFlag = true;
        this.EditCustomFieldForm.controls['inputValueFormatdemo1'].setValidators(Validators.required);
        this.EditCustomFieldForm.controls['inputValueFormatdemo1'].updateValueAndValidity();
      }
      this.EditCustomFieldForm.controls['inputValueFormatDropdown'].clearValidators();
      this.EditCustomFieldForm.controls['inputValueFormattext'].clearValidators();
      this.EditCustomFieldForm.controls['inputValueFormat'].reset();
      this.EditCustomFieldForm.controls['MaxCharacters'].reset();
      this.EditCustomFieldForm.controls['mandatory'].reset();
      this.EditCustomFieldForm.controls['editable'].reset();
      this.EditCustomFieldForm.controls['MaxCharacters'].reset();
      this.EditCustomFieldForm.controls['inputValueFormatDropdown'].reset();
      this.EditCustomFieldForm.controls['inputValueFormattext'].reset();
      this.EditCustomFieldForm.controls['inputValueFormat'].setValidators(Validators.required);
      this.EditCustomFieldForm.controls['MaxCharacters'].setValidators(Validators.required);
      this.EditCustomFieldForm.controls['inputValueFormat'].updateValueAndValidity();
      this.EditCustomFieldForm.controls['MaxCharacters'].updateValueAndValidity();
      this.EditCustomFieldForm.controls['mandatory'].updateValueAndValidity();
      this.EditCustomFieldForm.controls['editable'].updateValueAndValidity();
      this.EditCustomFieldForm.controls['inputValueFormatDropdown'].updateValueAndValidity();
      this.EditCustomFieldForm.controls['inputValueFormattext'].updateValueAndValidity();
      this.checkededitable = this.config?.data?.editableField;
      this.checkedmandatory = this.config?.data?.mandatoryField;
      this.MaxCharacters = this.config?.data?.MaxCharacters;
      this.SelectTreevalue = this.config?.data?.inputFormatData;
      this.special?.forEach(element => {
        this.Selectspecial.push(element.value);
      });
      this.specialcharacter();
    }
    else if (this.inputType == 'Text area') {
      //text area
      this.treedata = [];
      this.maxlengthFlag = true;
      this.inputFlag = false;
      this.rangeFlag = false;
      this.checkbox = false;
      if (this.SelectTreevalue.includes('Special charecters')) {
        this.specialFlag = true;
        this.EditCustomFieldForm.controls['inputValueFormatdemo1'].setValidators(Validators.required);
        this.EditCustomFieldForm.controls['inputValueFormatdemo1'].updateValueAndValidity();
      }
      this.EditCustomFieldForm.controls['inputValueFormatDropdown'].clearValidators();
      this.EditCustomFieldForm.controls['inputValueFormattext'].clearValidators();
      this.EditCustomFieldForm.controls['inputValueFormat'].reset();
      this.EditCustomFieldForm.controls['MaxCharacters'].reset();
      this.EditCustomFieldForm.controls['inputValueFormatDropdown'].reset();
      this.EditCustomFieldForm.controls['inputValueFormattext'].reset();
      this.EditCustomFieldForm.controls['mandatory'].reset();
      this.EditCustomFieldForm.controls['editable'].reset();
      this.EditCustomFieldForm.controls['inputValueFormat'].setValidators(Validators.required);
      this.EditCustomFieldForm.controls['MaxCharacters'].setValidators(Validators.required);
      this.EditCustomFieldForm.controls['inputValueFormat'].updateValueAndValidity();
      this.EditCustomFieldForm.controls['MaxCharacters'].updateValueAndValidity();
      this.EditCustomFieldForm.controls['mandatory'].updateValueAndValidity();
      this.EditCustomFieldForm.controls['editable'].updateValueAndValidity();
      this.EditCustomFieldForm.controls['inputValueFormatDropdown'].updateValueAndValidity();
      this.EditCustomFieldForm.controls['inputValueFormattext'].updateValueAndValidity();
      this.SelectTreevalue = this.config?.data?.inputFormatData;
      this.checkededitable = this.config?.data?.editableField;
      this.checkedmandatory = this.config?.data?.mandatoryField;
      this.MaxCharacters = this.config?.data?.MaxCharacters;
      this.special?.forEach(element => {
        this.Selectspecial.push(element.value);
      });
      this.specialcharacter();
    }
    else if (this.inputType == 'Checkbox') {
      this.inputFlag = false;
      this.maxlengthFlag = false;
      this.rangeFlag = false;
      this.checkbox = true;
      this.specialFlag = false;
      this.EditCustomFieldForm.controls['inputValueFormatDropdown'].clearValidators();
      this.EditCustomFieldForm.controls['inputValueFormattext'].clearValidators();
      this.EditCustomFieldForm.controls['inputValueFormat'].clearValidators();
      this.EditCustomFieldForm.controls['MaxCharacters'].clearValidators();
      this.EditCustomFieldForm.controls['inputValueFormatDropdown'].reset();
      this.EditCustomFieldForm.controls['inputValueFormattext'].reset();
      this.EditCustomFieldForm.controls['inputValueFormat'].reset();
      this.EditCustomFieldForm.controls['MaxCharacters'].reset();
      this.EditCustomFieldForm.controls['mandatory'].reset();
      this.EditCustomFieldForm.controls['editable'].reset();
      this.EditCustomFieldForm.controls['inputValueFormat'].updateValueAndValidity();
      this.EditCustomFieldForm.controls['MaxCharacters'].updateValueAndValidity();
      this.EditCustomFieldForm.controls['mandatory'].updateValueAndValidity();
      this.EditCustomFieldForm.controls['editable'].updateValueAndValidity();
      this.EditCustomFieldForm.controls['inputValueFormatDropdown'].updateValueAndValidity();
      this.EditCustomFieldForm.controls['inputValueFormattext'].updateValueAndValidity();
      this.EditCustomFieldForm.controls['inputValueFormatdemo1'].clearValidators();
      this.EditCustomFieldForm.controls['inputValueFormatdemo1'].reset();
    }
    else if (this.inputType == 'Dropdown') {
      //dropdown
      this.inputFlag = true;
      this.maxlengthFlag = false;
      this.rangeFlag = false;
      this.checkbox = false;
      this.specialFlag = false;
      this.EditCustomFieldForm.controls['inputValueFormat'].clearValidators();
      this.EditCustomFieldForm.controls['inputValueFormatDropdown'].clearValidators();
      this.EditCustomFieldForm.controls['MaxCharacters'].clearValidators();
      this.EditCustomFieldForm.controls['inputValueFormat'].reset();
      this.EditCustomFieldForm.controls['MaxCharacters'].reset();
      this.EditCustomFieldForm.controls['mandatory'].reset();
      this.EditCustomFieldForm.controls['editable'].reset();
      this.EditCustomFieldForm.controls['inputValueFormatDropdown'].reset();
      this.EditCustomFieldForm.controls['inputValueFormattext'].setValidators(Validators.required);
      this.EditCustomFieldForm.controls['inputValueFormattext'].updateValueAndValidity();
      this.EditCustomFieldForm.controls['MaxCharacters'].updateValueAndValidity();
      this.EditCustomFieldForm.controls['inputValueFormat'].updateValueAndValidity();
      this.EditCustomFieldForm.controls['inputValueFormatDropdown'].updateValueAndValidity();
      this.EditCustomFieldForm.controls['mandatory'].updateValueAndValidity();
      this.EditCustomFieldForm.controls['editable'].updateValueAndValidity();
      this.EditCustomFieldForm.controls['inputValueFormatdemo1'].clearValidators();
      this.EditCustomFieldForm.controls['inputValueFormatdemo1'].reset();
      this.inputformatDropdown = this.config?.data?.inputFormatData;
      this.formValueSo();
      this.checkededitable = this.config?.data?.editableField;
      this.checkedmandatory = this.config?.data?.mandatoryField;
    }
    else if (this.inputType == 'Input field in range') {
      this.EditCustomFieldForm.controls['inputValueFormat'].clearValidators();
      this.EditCustomFieldForm.controls['MaxCharacters'].clearValidators();
      this.EditCustomFieldForm.controls['inputValueFormattext'].clearValidators();
      this.EditCustomFieldForm.controls['inputValueFormat'].reset();
      this.EditCustomFieldForm.controls['MaxCharacters'].reset();
      this.EditCustomFieldForm.controls['inputValueFormattext'].reset();
      this.EditCustomFieldForm.controls['mandatory'].reset();
      this.EditCustomFieldForm.controls['editable'].reset();
      this.EditCustomFieldForm.controls['inputValueFormatDropdown'].setValidators(Validators.required);
      this.EditCustomFieldForm.controls['inputValueFormatDropdown'].updateValueAndValidity();
      this.EditCustomFieldForm.controls['MaxCharacters'].updateValueAndValidity();
      this.EditCustomFieldForm.controls['inputValueFormat'].updateValueAndValidity();
      this.EditCustomFieldForm.controls['inputValueFormattext'].updateValueAndValidity();
      this.EditCustomFieldForm.controls['mandatory'].updateValueAndValidity();
      this.EditCustomFieldForm.controls['editable'].updateValueAndValidity();
      this.EditCustomFieldForm.controls['inputValueFormatdemo1'].clearValidators();
      this.EditCustomFieldForm.controls['inputValueFormatdemo1'].reset();
      this.rangeFlag = true;
      this.inputFlag = false;
      this.maxlengthFlag = false;
      this.checkbox = false;
      this.specialFlag = false;
      this.checkededitable = this.config?.data?.editableField;
      this.checkedmandatory = this.config?.data?.mandatoryField;
      this.inputformatRange = this.config?.data?.inputFormatData;
      setTimeout(() => {
        this.formatRange.forEach(element => {
          if (element.label == this.inputformatRange[0]) {
            this.rangeFormatData = element.value;
          }
        });
      }, 1000);

    }
  }


}