<div class="layout-content custom-container">
    <form [formGroup]="EditHostAttributeGroupForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 left">
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                <div class=" p-fluid   p-field    ">
                    <label
                        [ngClass]="{'field-required': EditHostAttributeGroupForm.get('textField')?.errors?.required}">
                        Group Name
                        <span class='invalid-feedback-astrik'>*</span></label>
                    <input type="text" [(ngModel)]="groupname" id="textField_id" name="textField_name"
                        formControlName="textField" pInputText placeholder="Enter group name"
                        class="p-inputtext inputtextHeight  " pattern="^([a-zA-Z0-9]+[-_\s])*[a-zA-Z0-9]+$"
                        maxlength="50" (keyup)="enableBtn1=false;this.nameFlag = false;colorChange();" />

                    <div class="p-error"
                        *ngIf="EditHostAttributeGroupForm.get('textField')!.invalid && (EditHostAttributeGroupForm.get('textField')!.dirty || EditHostAttributeGroupForm.get('textField')!.touched)">
                        <div *ngIf="EditHostAttributeGroupForm.get('textField')?.errors?.required">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium class="p-error">Group name is required.</medium>
                        </div>
                        <div *ngIf="EditHostAttributeGroupForm.get('textField').errors.pattern">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium class="p-error">Name should not start/end with special characters and Only Dash,
                                Underscore & space are allowed as special characters </medium>
                        </div>
                    </div>
                    <div class="invalid-feedback">
                        <label *ngIf="this.nameFlag" class="p-error">
                            <div class="custom-font">
                                <label class="alert-icon alert-circle-icon" for="rolename"></label>
                                <medium id="email" class="p-error">
                                    Attribute Group Name already exists!
                                </medium>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                <div class=" p-fluid   p-field    ">
                    <label>
                        Description
                    </label><textarea [(ngModel)]="description" pInputTextarea id="textArea_id" name="textArea_name"
                        formControlName="textarea" rows="3" placeholder="Add description" maxlength="200"
                        (keyup)="enableBtn1=false"></textarea>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right p-mb-2">
                <div class="btnwrap p-mr-3">
                    <button pButton type="button" class="p-button-brand2" (click)="onClose(EditHostAttributeGroupForm);"
                        label="Close">
                    </button>
                </div>
                <div class="btnwrap">
                    <button pButton class="p-button-primary" type="button"
                        [disabled]=" nameFlag || enableBtn1 || !EditHostAttributeGroupForm.valid"
                        (click)="onSaveCheckAttributeGroupName(this.EditHostAttributeGroupForm.value);" label="Save">
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>