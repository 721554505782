import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PagesService } from '../pages.service';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { MainComponent } from 'app/layouts/main/main.component';
@Component({
  selector: 'jhi-DeactivateAccount',
  templateUrl: './DeactivateAccount.component.html',
  styleUrls: ['./DeactivateAccount.component.scss'],
  providers: [MessageService, DialogService],
})
export class DeactivateAccountComponent implements OnInit {
  DeactivateAccountForm = this.fb.group({});
  arrayId: any[] = [];
  wfFlag = false;
  stompClient: any;
  accountName: any;
  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    private messageService: MessageService,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.DeactivateAccountForm.patchValue(JSON.parse(data));
    }
  }

  ngOnInit(): void {
    this.arrayId = this.config?.data?.accId;
    this.accountName = this.config?.data?.accName;
  }

  onClickNo($event: UntypedFormGroup): void {
    this.ref.close();
  }

  /**
   * 
   * DeactivateAccount USER IN USER MANAGEMENT
   */
  onClickYes($event: UntypedFormGroup): void {
    const reqBody = {};
    //let userObj = JSON.parse(localStorage.getItem("userObject")); 
    let username=localStorage.getItem("username");
    reqBody["tenantId"] = this.pagesService.tenantId;
    reqBody["accountIds"] = [this.arrayId];
    reqBody['accountName']=this.accountName;
    reqBody['action']='Deactivate';
    //reqBody['userId']=userObj.ID;
    reqBody['userName']=username;
    const formData = reqBody;
    this.pagesService.deactivateAccount(formData).subscribe({
      next: results => {
        let cnt = 0;
        let kk = results.success.result;
        kk.forEach(element => {
          if (element.messageCode == 'CIAM 154') {
            cnt = cnt + 1;
            this.wfFlag = true;
          }
          else if (element.messageCode == 'CIAM 254') {
            this.wfFlag = false;
          }
        });

        if (this.wfFlag) {
          this.ref.close();
          this.navigateToMyReq('Deactivate Account  request has been sent for approval');
        }
        else if (!this.wfFlag) {
          this.ref.close('Account Deactivated Succesfully');
        }
      }
      ,
      error: error => {
        if (error.error?.error?.messageCode == "CIAM 105") {
          this.ref.close('Request already available for approval');
        }
        else {
          this.ref.close('Failed to Deactivate Account ');
        }
      }
    });
  }

  navigateToMyReq(msg: any): void {
    const navigationExtras: NavigationExtras = {
      state: {
        msg: msg,
      },
    };
    this.router.navigate(['/pages/myrequestslisting'], navigationExtras);
  }
  send() {
    const data: any = this.config?.data?.id;
    this.stompClient = MainComponent.stompCl;
    const obj = {
      tenantId: this.pagesService.tenantId,
      userId: data?.[0]
    }
    this.stompClient.send("/ciam/v1/user/force-logoff-notification", {}, JSON.stringify(obj));
  }
}
