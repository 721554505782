import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { PagesService } from '../pages.service';
@Component({
  selector: 'jhi-edithostatrribute',
  templateUrl: './EditHostAttribute.component.html',
  styleUrls: ['./EditHostAttribute.component.scss'],
  providers: [DialogService],
})
export class EditHostAttributeComponent implements OnInit {
  EditHostAttributeForm = this.fb.group({
    select2: [null, [Validators.required]],
    textField: [null, [Validators.required]],
    select1: [null, []],
    selectSwitch: [null, []],
    checkbox: [null, []],
    description:[null, []],
    AttributeId: [null, []],
  });
  selectSwitchList: any[] = [{ 'label': "Public", 'value': 'false' }, { 'label': "Private", 'value': 'true' },];
  defaultselect_selectSwitch: any;
  checked: boolean;
  pageloadvalues: any;
  appId: any;
  resourceId: any;
  attributeId: any;
  attriType: any;
  resourceName: any;
  groups: any[]=[];
  selectList: any[] = [];
  selectedGroupList: any[] = [];
  selectedType: any[] = [];
  select1List: any[] = [];
  flag: any;
  oldGroupName: any;
  enableBtn1 = true;
  nameFlag: boolean = false;
  enabledbtn1: boolean = false;
  errorMessage = "";
  validationFlag = false;
  oldResourceName: any;
  oldAttrType: any;
  oldChecked: boolean;
  oldDefaultselect_selectSwitch: any;
  attributeGroup: any;
  attributeID: string;
  idFlag: boolean;
  description: any;

  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public pagesService: PagesService) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.EditHostAttributeForm.patchValue(JSON.parse(data));
    }

  }
  ngOnInit(): void {
    if(this.pagesService.attributeTreeEnable){
      this.EditHostAttributeForm.controls['AttributeId'].setValidators(Validators.required);
    }
    this.appId = this.config?.data?.id;
    this.resourceId = this.config?.data?.activeData.attributeId;
    this.resourceName = this.config?.data?.activeData.attributeName;
    this.oldResourceName = this.config?.data?.activeData.attributeName;
    this.attributeGroup = this.config?.data?.activeData.attributeGroups;
    if(this.pagesService.attributeTreeEnable){
      this.viewAttributebyId();
      this.getTypeList();
      this.attributeGroup = this.config?.data?.activeData.attributeGroupName;
      this.attributeGroup=this.attributeGroup.toString().split(',');
      console.log(this.config?.data?.activeData.attributeGroupName);
      console.log(this.attributeGroup.toString().split(','));
      // const array=this.attributeGroup.toString().split(',');
      // array.forEach((element: any) => {
      //   this.groups.push(element);
      // });
      // console.log(this.groups);
      // this.getAttributeGroup();
      // this.getSelectedGroupList();
      // this.EditHostAttributeForm.get('select1').disable();
      // this.setDefaultValue_selectSwitch(this.defaultselect_selectSwitch);
      // this.resourceName = this.config?.data?.activeData.attributeResourceName;
      // this.oldResourceName = this.config?.data?.activeData.attributeResourceName;
    }
    
    this.attributeID=this.config?.data?.activeData.attributeResourceId;
    this.oldGroupName = this.config?.data?.activeData.attributeName;
    this.getTypeList();
    this.attriType = this.config?.data?.activeData.attributeTypeName;
    this.oldAttrType = this.config?.data?.activeData.attributeTypeName;
    this.getAttributeGroup();
    this.getSelectedGroupList();
    this.EditHostAttributeForm.get('select1').disable();
    if (this.config?.data?.activeData.isDefault == "Yes") {
      this.checked = true;
    } else {
      this.checked = false;
    }
    if (this.config?.data?.activeData.isPrivate == "Public") {
      this.defaultselect_selectSwitch = "false";
    } else {
      this.defaultselect_selectSwitch = "true";
    }
    
  }

  onClose($event: UntypedFormGroup): void {
    this.ref.close();
  }
  onColorchange() {
    document.getElementById('textField_id1')!.style.borderColor = 'grey';
  }

  onAttributeIdColorchange() {
    document.getElementById('AttributeId_id1')!.style.borderColor = 'grey';
  }


  getTypeList(): void {
    this.pagesService.getAttributeTypeListing(this.appId).subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.attributeTypeName;
          data.value = element.id;
          this.selectList.push(data.label);
          this.selectedType.push(data);
        });
        this.selectList = [...this.selectList];
        this.selectedType = [...this.selectedType];
      }
    });
  }

  getSelectedGroupList(): void {
    const reqBody = {};
    reqBody["applicationId"] = this.appId;
    this.pagesService.getAttributeGroupByHostApplication(reqBody).subscribe(results => {
      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          this.attributeGroup.forEach(a => {
            if (element.label == a) {
              // element.attributeGroups.forEach(element2 => {
              data.label = element.label;
              data.value = element.value;
              this.selectedGroupList.push(data.value);
              // });
            }
          })

        });
        this.selectedGroupList = [...this.selectedGroupList];
      }
    });
  }

  getAttributeGroup(): void {
    this.select1List = [];
    const reqBody = {};
    reqBody["applicationId"] = this.appId;
    this.pagesService.getAttributeGroupByHostApplication(reqBody).subscribe((results: any) => {

      const responseData = results.success.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.select1List.push(data);
        });
        this.select1List = [...this.select1List];
      }
    });
  }

  EditHostAttribute(): void {
    const reqBody = {};
    reqBody['applicationId'] = this.appId;
    reqBody['attributeId'] = this.resourceId;
    for (let i = 0; i < this.selectedType.length; i++) {
      if (this.EditHostAttributeForm.value.select2 == this.selectedType[i].label) {
        reqBody['attributeTypeId'] = this.selectedType[i].value;
        break;
      }
    }
    if (this.oldResourceName != this.EditHostAttributeForm.value.textField) {
      reqBody['attributeResourceName'] = this.EditHostAttributeForm.value.textField;
    }

    reqBody['isDefault'] = this.checked;
    reqBody['isPrivate'] = this.EditHostAttributeForm.value.selectSwitch;

    const formValues = reqBody;
    this.pagesService.EditHostAttribute(formValues).subscribe(
      {
        next: results => {
          this.ref.close("Attribute Edited Successfully");
        },
        error: error => {
          if (error.error?.error?.messageCode == "CIAM 105") {
            this.validationFlag = false;
            this.nameFlag = true;
            this.enableBtn1 = true;
            document.getElementById('textField_id1')!.style.borderColor = 'red';
          }
          else if (error.error?.error?.messageCode == "[CIAM 115]") {
            this.validationFlag = true;
            this.nameFlag = false;
            this.enableBtn1 = true;
            document.getElementById('textField_id1')!.style.borderColor = 'red';
            this.errorMessage = error.error?.error?.reason;
          }
          else {
            this.ref.close("Failed to Edit Attribute");
          }
        }
      });
  }
  EditHierarchyHostAttribute(): void {
    const reqBody = {};
    reqBody['applicationId'] = this.appId;
    reqBody['attributeId'] = this.resourceId;
    reqBody['attributeResourceId']=this.EditHostAttributeForm.value.AttributeId;
    for (let i = 0; i < this.selectedType.length; i++) {
      if (this.EditHostAttributeForm.value.select2 == this.selectedType[i].label) {
        reqBody['attributeTypeId'] = this.selectedType[i].value;
        break;
      }
    }
    if (this.oldResourceName != this.EditHostAttributeForm.value.textField) {
      reqBody['attributeResourceName'] = this.EditHostAttributeForm.value.textField;
    }

    reqBody['isDefault'] = this.checked;
    reqBody['isPrivate'] = this.EditHostAttributeForm.value.selectSwitch;
    reqBody['description']=this.EditHostAttributeForm.value.description;

    const formValues = reqBody;
    this.pagesService.EdithierarchyHostAttribute(formValues).subscribe(
      {
        next: results => {
          this.ref.close("Attribute Edited Successfully");
        },
        error: error => {
          if (error.error?.error?.messageCode == "CIAM 105") {
            this.validationFlag = false;
            this.nameFlag = true;
            this.enableBtn1 = true;
            document.getElementById('textField_id1')!.style.borderColor = 'red';
          }
          else if (error.error?.error?.reason == "Attribute resource id already exist") {
            this.validationFlag = false;
            this.idFlag = true;
            this.enableBtn1 = true;
            document.getElementById('AttributeId_id1')!.style.borderColor = 'red';
          }
          else if (error.error?.error?.messageCode == "[CIAM 115]") {
            this.validationFlag = true;
            this.nameFlag = false;
            this.enableBtn1 = true;
            document.getElementById('textField_id1')!.style.borderColor = 'red';
            this.errorMessage = error.error?.error?.reason;
          }
          else {
            this.ref.close("Failed to Edit Attribute");
          }
        }
      });
  }


  setDefaultValue_selectSwitch(value?: any) {
    if (this.selectSwitchList.length && value) {
      this.defaultselect_selectSwitch = value
      return this.defaultselect_selectSwitch;
    }
    this.defaultselect_selectSwitch = this.selectSwitchList[0]?.value;
    return this.defaultselect_selectSwitch;
  }
  viewAttributebyId() {
    const reqBody = {};
    console.log("i am here");
    reqBody['attributeId'] = this.resourceId;
    reqBody['applicationId'] = this.appId;
    console.log(reqBody);
    this.pagesService.onViewHostAttributeHierarchy(reqBody).subscribe(
      results => {
        const responseData = results.success.result;
        console.log(responseData);
        if (responseData.attributeResourceId == null) {
          this.attributeID = "null";
        }
        else {
          this.attributeID = responseData.attributeResourceId;
        }
        this.resourceName = responseData.attributeResourceName;
        this.description = responseData.description;
        console.log(responseData.default);
        if (responseData.default == true) {
          this.checked=true
        }
        else {
          this.checked =false
        }
        if (responseData.private == true) {
          this.defaultselect_selectSwitch = "true";
        }
        else {
          this.defaultselect_selectSwitch = "false";
        }
        this.attriType = this.config?.data?.activeData.attributeTypeName;

      }
    )
  }
}