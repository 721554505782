<div>
    <form class="layout-content custom-form-layout" style="padding-left: 0px !important;padding-right: 0px !important;"
        [formGroup]="ViewApplicationPlanForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">

            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left p-mt-3 p-mb-3">
                <div>
                    <span>Plan Name: <strong>{{this.Nameplan}}</strong></span>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left custom-tab-nav">
                <p-tabView [(activeIndex)]="ViewApplicationPlanForm.value.tabview"
                    (onChange)="handleChangetabview($event)">
                    <p-tabPanel header="Application Details">
                        <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-p-0 p-offset-undefined left">
                            <div class="p-fluid p-formgrid p-grid">
                                <div class="p-col-12 p-p-0">
                                    <div
                                        class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left p-p-0 p-mb-6 p-mt-2">
                                        <div class="  p-field    ">
                                            <span *ngFor="let rowData of applicationList;index as i">
                                                <div style="min-height: 85px;">
                                                    <span>
                                                        <span>{{i+1}}. </span>
                                                        <span>
                                                            Application Name:
                                                            <strong>{{rowData.applicationName}}</strong>
                                                            <span *ngIf="rowData.applicationName==null">-</span></span>
                                                        <div class="p-pt-2 p-d-flex" style="font-size:13px;">
                                                            <div style="padding-left:14px;">Feature Name:
                                                            </div>
                                                            <div tooltipPosition="bottom" style="padding-left:5px;"
                                                                pTooltip="{{rowData.featureName?.length >= 80 ? rowData.featureName : ''}}">
                                                                <span *ngIf="rowData.featureName==null">-</span>
                                                                {{rowData.featureName}}
                                                            </div>
                                                        </div>
                                                        <div class="p-pt-2 p-d-flex" style="font-size:13px;">
                                                            <div style="padding-left:14px;">Features:
                                                            </div>
                                                            <div tooltipPosition="bottom" style="padding-left:5px;"
                                                                pTooltip="{{rowData.featureDescription?.length >= 80 ? rowData.featureDescription : ''}}">
                                                                <span *ngIf="rowData.featureDescription==null">-</span>
                                                                {{rowData.featureDescription}}
                                                            </div>
                                                        </div>
                                                    </span>
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </p-tabPanel>
                    <p-tabPanel header="Plan Attributes">
                        <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-p-0 p-offset-undefined left">
                            <div *ngIf="this.planErrorFlag==false" style="text-align: center;">
                                <label class="c-icons emptyPlan-icon p-mt-3"
                                    style="height: 100px;width: 100px;"></label><br>
                                <label class="e-error p-mb-3">No Plan Attributes are available</label>
                            </div>
                            <div *ngIf="this.planErrorFlag==true">
                                <span *ngFor="let result of planAttributesList; ">
                                    <ul>
                                        <li>
                                            <span>{{result.label}} : </span>
                                            <span><strong>{{result.value}}</strong></span>
                                        </li>
                                    </ul>
                                </span>
                            </div>
                        </div>
                    </p-tabPanel>
                </p-tabView>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
            </div>
        </div>
    </form>
</div>