import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { PagesService } from '../pages.service';
@Component({
  selector: 'jhi-EditApplicationRole',
  templateUrl: './EditApplicationRole.component.html',
  styleUrls: ['./EditApplicationRole.component.scss'],
  providers: [DialogService],
})
export class EditApplicationRoleComponent implements OnInit {
  EditApplicationRoleForm = this.fb.group({
    textField: [null, [Validators.required]],
    textarea: [null, []],
    checkbox: [null, []],
  });
  pageloadvalues: any;
  nameFlag: boolean = false;
  enabledbtn1: boolean = false;
  appId: any;
  appRoleId: any;
  appName: any;
  description: any;
  defaultRole: any;
  enableBtn1 = true;
  prevDescription: any;
  prevdefaultRole: any;
  errorMsg: any;

  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public pagesService: PagesService) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.EditApplicationRoleForm.patchValue(JSON.parse(data));
    }
  }

  ngOnInit(): void {
    this.appId = this.config?.data?.id;
    this.appRoleId = this.config?.data?.activeData?.id;
    this.appName = this.config?.data?.activeData?.applicationRoleName;
    this.description = this.config?.data?.activeData?.description;
    this.prevDescription = this.config?.data?.activeData?.description;
    this.defaultRole = this.config?.data?.activeData?.default;
    this.prevdefaultRole = this.config?.data?.activeData?.default;
    // this.EditApplicationRoleForm.get('textField').disable();
  }

  onClose($event: UntypedFormGroup): void {
    this.ref.close();
  }

  EditApplicationRole(): void {
    const reqBody = {};
    //let userObj = JSON.parse(localStorage.getItem("userObject")); 
    let username=localStorage.getItem("username");
    reqBody['applicationRoleName']=this.EditApplicationRoleForm.value.textField;
    reqBody['description'] = this.EditApplicationRoleForm.value.textarea;
    reqBody['default'] = this.EditApplicationRoleForm.value.checkbox;
    reqBody['prevdescription']=this.prevDescription;
    reqBody['prevdefaultRole']=this.prevdefaultRole;
    reqBody['roleId'] = this.appRoleId;
    reqBody['roleName'] = this.appName;
    //reqBody['userId']=userObj.ID;
    reqBody['userName']=username;
    const formValues = reqBody;
    this.pagesService.onSaveEditApplicationRole(formValues, this.appRoleId).subscribe(
      {
        next: results => {
          this.ref.close("success");
        },
        error: error => {
          if (error.error?.error?.reason != "" || error.error?.error?.reason != null || error.error?.error?.reason != undefined){
            this.nameFlag=true;
            this.errorMsg=error.error?.error?.reason;
          }
        }
      });
  }

}