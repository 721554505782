<div class="layout-content custom-container">
    <form [formGroup]="DeleteHostApplicationEndpointForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class=" p-col-12">
                <div class="ui-fluid p-formgrid p-grid">
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left p-pl-0 p-pr-0 p-mb-3">
                        <div class=" p-fluid   p-field    ">
                            <p>Are you sure you want to
                                delete '<b>{{activeItem}}</b>' resource?</p>
                        </div>
                    </div>
                    <div
                        class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right custom-btn-height p-pl-0 p-pr-0">
                        <div class="btnwrap p-mr-2 p-pr-1">
                            <button pButton type="button" class="p-button-brand2"
                                (click)="onClickNo(DeleteHostApplicationEndpointForm);" label="No">
                            </button>
                        </div>
                        <div class="btnwrap">
                            <button pButton class="p-button-primary" type="button" (click)="onSave()" label="Yes">
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>