<div class="layout-content custom-container">
    <form [formGroup]="CreateAttributeGroupForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-0 left">
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                <div class=" p-fluid   p-field    ">
                    <label [ngClass]="{'field-required': CreateAttributeGroupForm.get('textField')?.errors?.required}">
                        Group Name
                        <span class='invalid-feedback-astrik'>*</span> </label>
                    <input type="text" id="textField_id" name="textField_name" formControlName="textField"
                        placeholder="Enter group name" class="p-inputtext inputtextHeight"
                        pattern="^([a-zA-Z0-9]+[-_\s])*[a-zA-Z0-9]+$" maxlength="50"
                        (keyup)="this.nameFlag = false;colorChange();" />
                    <div class="p-error"
                        *ngIf="CreateAttributeGroupForm.get('textField')!.invalid && (CreateAttributeGroupForm.get('textField')!.dirty || CreateAttributeGroupForm.get('textField')!.touched)">
                        <div *ngIf="CreateAttributeGroupForm.get('textField')?.errors?.required">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium class="p-error">Group name is required.</medium>
                        </div>
                        <div *ngIf="CreateAttributeGroupForm.get('textField').errors.pattern">
                            <label class="alert-icon alert-circle-icon"></label>
                            <medium class="p-error">Name should not start/end with special characters and Only Dash,
                                Underscore & space are allowed as special characters </medium>
                        </div>
                    </div>
                    <div class="p-error">
                        <label *ngIf="this.nameFlag" class="custom-codelabel">
                            <div>
                                <label class="alert-icon alert-circle-icon" for="rolename"></label>
                                <medium id="attributeGroupName" class="p-error">
                                    Attribute Group Name already exists!
                                </medium>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                <div class=" p-fluid   p-field    ">
                    <label>
                        Description
                    </label><textarea pInputTextarea id="textArea_id" name="textArea_name" formControlName="textarea"
                        rows="3" placeholder="Add description" maxlength="200"></textarea>
                </div>
            </div>
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined p-right p-mb-2">
                <div class="btnwrap p-mr-3">
                    <button pButton type="button" class="p-button-brand2" (click)="onClose(CreateAttributeGroupForm);"
                        label="Close">
                    </button>
                </div>
                <div class="btnwrap">
                    <button pButton class="p-button-primary" id="save" type="button"
                        (click)="onCheckAttributeGroupName(this.CreateAttributeGroupForm.value);"
                        [disabled]="nameFlag || !CreateAttributeGroupForm.valid" label="Save">
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>