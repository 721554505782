import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PagesService } from '../pages.service';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
@Component({
  selector: 'jhi-DeleteHostApplicationEndpoint',
  templateUrl: './DeleteHostApplicationEndpoint.component.html',
  styleUrls: ['./DeleteHostApplicationEndpoint.component.scss'],
})
export class DeleteHostApplicationEndpointComponent implements OnInit {
  DeleteHostApplicationEndpointForm = this.fb.group({
  });
  pageloadvalues: any;
  htmlSrc: any;
  activeItem: any;
  eID: any;
  appId: any;
  comId: any;
  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    private messageService: MessageService,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.DeleteHostApplicationEndpointForm.patchValue(JSON.parse(data));
    }
  }

  ngOnInit(): void {
    this.activeItem = this.config?.data?.id;
  }

  onClickNo($event: UntypedFormGroup): void {
    this.ref.close();
  }

  /**
   * DELETE APPLICATION IN RESOURCE LISTING
   */

  //delete application 
  onSave() {
    this.eID = this.config?.data?.eID;
    this.appId = this.config?.data?.appId;
    this.comId = this.config?.data?.comId;
    let reqBody = {};
    reqBody['endpointId'] = this.eID;
    reqBody['applicationId'] = this.appId;
    reqBody['componentId'] = this.comId;
    const formValues = reqBody;
    this.pagesService.deleteHostApplicationEndpoint(formValues).subscribe(
      {
        next: results => {
          this.ref.close("Resource Deleted Successfully");
        },
        error: () => {
          this.ref.close("Resource Deletion Failed");
        }
      });
  }

}