<div style="background-color:#F6F8FA;" (click)="mouseLeave()">
    <div class="p-d-flex p-jc-end">
        <p-messages key="myKey1" class="custom-alert-box" *ngIf="this.messageFlag"></p-messages>
    </div>
    <div class="p-col-fixed p-mt-1 p-ml-1 p-pr-0 custom-breadcrumb">
        <p-breadcrumb [model]="ccprimebreadcrumbList" [home]="homeiconccprimebreadcrumb">
        </p-breadcrumb>
    </div>
    <form class="layout-content p-pr-4 custom-form-layout" [formGroup]="ListGroupsForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                <h5 class="custom-head1">User Groups</h5>
                <div class="card custom-card p-mb-6" (onclick)="toggleDisplay()">
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                        <div class=" p-fluid   p-field    ">
                            <div class="p-fluid p-formgrid p-grid" style="padding-top:8px ;">
                                <div class="p-col-12 p-p-0">
                                    <div *ngIf="this.errorFlag" class="custom-errorFlag">

                                        <label class="c-icons error-icon"></label><br>
                                        <label class="e-error">No User group found</label><br>
                                        <br>
                                        <div class="btnwrap">
                                            <button pButton type="button" pButton label="Add New Group"
                                                class="p-button-sm" (click)="onAddGroup(ListGroupsForm);"
                                                [disabled]="this.pagesService.userManagementReadOnlyFlag"></button>
                                        </div>
                                    </div>
                                    <div class="ui-fluid p-formgrid" *ngIf="!this.errorFlag">
                                        <div class=" p-grid p-mt-0">
                                            <div class="p-col-fixed p-p-0 p-col-4">
                                                <span class=" p-input-icon-left">
                                                    <input type="text" (keydown.enter)="$event.preventDefault()"
                                                        id="textField_id" name="textField_name"
                                                        formControlName="textField" pInputText placeholder="Search"
                                                        class="p-inputtext inputtextHeight "
                                                        (input)="GroupFilterMethod($event.target.value)"
                                                        style="height: 28px;" />
                                                    <i aria-hidden="true" class="pi pi-search"></i>
                                                </span>
                                            </div>
                                            <div class="p-col p-p-0 p-text-right custom-btn">
                                                <span class="btnwrap" *ngIf="!this.errorFlag">
                                                    <button pButton class="p-button-sm custom-button-padding"
                                                        type="button" (click)="onAddGroup(ListGroupsForm);"
                                                        label="Add New Group"
                                                        [disabled]="this.pagesService.userManagementReadOnlyFlag">
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left p-p-0">
                                            <div class="  p-field    ">
                                                <div [ngClass]="{'content-section implementation': true}">
                                                    <p-table #tableid [value]="tableList" name="table"
                                                        [columns]="tablecols"
                                                        styleClass="p-datatable-customers custom-p-datatable-customers"
                                                        [rows]="10" [rowsPerPageOptions]="[10,25,50]"
                                                        (onRowSelect)="onRowSelect($event)" selectionMode="Multiple"
                                                        [paginator]="true" sortMode="single" [scrollable]="true"
                                                        [globalFilterFields]="['name','description','displayName']">
                                                        <ng-template pTemplate="header">
                                                            <tr>
                                                                <th pSortableColumn="displayName" scope="col"
                                                                    style="width: 30%;">
                                                                    <div class="p-d-flex p-jc-between p-ai-center">
                                                                        Name
                                                                        <i class="p-sortable-column-icon icon-sort"
                                                                            ariaLabel="Activate to sort"
                                                                            ariaLabelDesc="Activate to sort in descending order"
                                                                            ariaLabelAsc="Activate to sort in ascending order"
                                                                            field="displayName"></i>
                                                                        <p-columnFilter type="text" field="displayName"
                                                                            display="menu"
                                                                            class="p-ml-auto"></p-columnFilter>
                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="description" scope="col"
                                                                    style="width: 55%;">
                                                                    <div class="p-d-flex p-jc-between p-ai-center">
                                                                        Description
                                                                        <i class="p-sortable-column-icon icon-sort"
                                                                            ariaLabel="Activate to sort"
                                                                            ariaLabelDesc="Activate to sort in descending order"
                                                                            ariaLabelAsc="Activate to sort in ascending order"
                                                                            field="description"></i>
                                                                        <p-columnFilter type="text" field="description"
                                                                            display="menu"
                                                                            class="p-ml-auto"></p-columnFilter>
                                                                    </div>
                                                                </th>
                                                                <th id="actionButton" style="width: 20%;">
                                                                    <div class="p-d-flex p-jc-between p-ai-center">
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-rowData let-index="rowIndex">
                                                            <tr (mouseenter)="hoverIndex=index;highlight(index)"
                                                                (mouseleave)="hoverIndex=index;highlight(index)"
                                                                [pSelectableRow]="rowData">
                                                                <td (click)="navigateGroupName(rowData)"
                                                                    class="custom-displayName" style="width: 30%;">
                                                                    <span pTooltip="{{rowData.displayName}}"
                                                                        *ngIf="rowData.displayName?.length >= 30"
                                                                        tooltipPosition="bottom">
                                                                        {{rowData.displayName?.substring(0,30) +
                                                                        "..." }}
                                                                    </span>
                                                                    <span *ngIf="rowData.displayName?.length < 30">
                                                                        {{rowData.displayName}}
                                                                    </span>
                                                                </td>
                                                                <td class="custom-description" style="width: 55%;">
                                                                    <span *ngIf="rowData.description==null">-</span>
                                                                    <div *ngIf="rowData.description != null">
                                                                        <span pTooltip="{{rowData.description}}"
                                                                            *ngIf="rowData.description?.length >= 50"
                                                                            tooltipPosition="bottom">
                                                                            {{rowData.description?.substring(0,50) +
                                                                            "..." }}
                                                                        </span>
                                                                        <span *ngIf="rowData.description?.length < 50">
                                                                            {{rowData.description}}
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                                <td class="p-jc-center" style="width: 20%;">
                                                                    <div class="button-details custom-button-Details">
                                                                        <button pButton tooltipPosition="bottom"
                                                                            style="width: 24px;height: 24px;padding: 0px;"
                                                                            icon="g-icons view-icon"
                                                                            pTooltip="View & Edit"
                                                                            class="background-transparent"
                                                                            (click)="navigateGroupName(rowData);"
                                                                            [disabled]="this.pagesService.userManagementReadOnlyFlag">
                                                                        </button>
                                                                        <button pButton tooltipPosition="bottom"
                                                                            pTooltip="Delete"
                                                                            style="width: 24px;height: 24px;padding: 0px;"
                                                                            icon="g-icons trash-icon"
                                                                            class="background-transparent"
                                                                            (click)="activeItem=rowData;DeleteGroup();"
                                                                            [disabled]="this.pagesService.userManagementReadOnlyFlag">
                                                                        </button>
                                                                        <button pButton tooltipPosition="bottom"
                                                                            style="width: 24px;height: 24px;padding: 0px;"
                                                                            icon="g-icons assign-icon"
                                                                            pTooltip="Assign User"
                                                                            class="background-transparent"
                                                                            (click)="activeItem=rowData;show();"
                                                                            [disabled]="this.pagesService.userManagementReadOnlyFlag">
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="paginatorright" let-state> <span
                                                                class="p-pl-4">Total
                                                                Records: {{state.totalRecords}}</span></ng-template>
                                                        <ng-template pTemplate="emptymessage">
                                                            <tr>
                                                                <td colspan="7" class="p-p-0 p-text-center"> <p-card>
                                                                        <div style="margin-top: 5%;height: 70px;">No
                                                                            matching records found</div>
                                                                    </p-card> </td>
                                                            </tr>
                                                        </ng-template>
                                                    </p-table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>