import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PagesService } from '../pages.service';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';

@Component({
  selector: 'jhi-deactiverole',
  templateUrl: './ActivateAccount.component.html',
  styleUrls: ['./ActivateAccount.component.scss'],
})

export class ActivateAccountComponent implements OnInit {
  ActivateAccountForm = this.fb.group({});
  activeId: any[] = [];
  pageloadvalues: any;
  wfFlag = false;
  accountName: any;
  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public pagesService: PagesService,
    // private messageService: MessageService,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.ActivateAccountForm?.patchValue(JSON.parse(data));
    }
  }

  ngOnInit(): void {
    this.activeId = this.config?.data?.accId;
    this.accountName = this.config?.data?.accName;
  }

  onClickNo($event: UntypedFormGroup): void {
    this.ref.close();
  }

  //Activate user API function
  onClickYes($event: UntypedFormGroup): void {
    const reqBody = {};
    //let userObj = JSON.parse(localStorage.getItem("userObject")); 
    let username=localStorage.getItem("username");

    reqBody["tenantId"] = this.pagesService?.tenantId;
    reqBody["accountIds"] = [this.activeId];
    reqBody['accountName']=this.accountName;
    reqBody['action']='Activate';
    //reqBody['userId']=userObj.ID;
    reqBody['userName']=username;
    const formData = reqBody;
    this.pagesService.activateAccount(formData).subscribe({
      next: results => {
        let cnt = 0;
        let kk = results?.success?.result;

        for (let i = 0; i < kk?.length; i++) {
          if (kk[i].messageCode == 'CIAM 154') {
            cnt = cnt + 1;
            this.wfFlag = true;
          }
          else if (kk[i].messageCode == 'CIAM 254') {
            this.wfFlag = false;
          }
        }

        if (this.wfFlag) {
          this.ref.close();
          this.navigateToMyReq('Activate user request has been sent for approval');
        }
        else if (!this.wfFlag) {
          this.ref.close('Account Activated Succesfully');
        }
      },
      error: error => {
        if (error.error?.error?.messageCode == "CIAM 105") {
          this.ref.close('Request already available for approval');
        }
        else {
          this.ref.close('Failed to activate account');
        }
      }
    });
  }

  navigateToMyReq(msg: any): void {
    const navigationExtras: NavigationExtras = {
      state: {
        msg: msg,
      },
    };
    this.router.navigate(['/pages/myrequestslisting'], navigationExtras);
  }
}
