import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
    selector: '[appClipText]'
})
export class ClipTextDirective {
    @Input() clipLength: number = 10;
    clipText: string = '';

    constructor(private elementRef: ElementRef, private rendere: Renderer2) { }

    ngAfterContentInit(): void {
        if (this.clipLength < this.elementRef.nativeElement.innerHTML.length) {
            this.clipText = this.elementRef.nativeElement.innerHTML;
            this.rendere.setProperty(this.elementRef.nativeElement, 'innerHTML', this.clipText.substring(0, this.clipLength) + ' ...');
        }
    }
}
