import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { Message, MessageService, PrimeNGConfig, FilterService } from 'primeng/api';
import { PagesService } from '../pages.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ObjectUtils } from '../object-utlis.service';
@Component({
  selector: 'jhi-Authentication',
  templateUrl: './Authentication.component.html',
  styleUrls: ['./Authentication.component.scss'],
  providers: [MessageService, DialogService, FilterService, ObjectUtils],
})
export class AuthenticationComponent implements OnInit {
  ref!: DynamicDialogRef;
  AuthenticationForm = this.fb.group({
    tabview: [0],
    application: [null, [Validators.required]],
    enableSwitch: [null, []],

  });
  IPRangeConfigurationForm = this.fb.group({
  });
  IPversionForm = this.fb.group({
  });
  CIDRForm = this.fb.group({
  });

  PasswordPolicyForm = this.fb.group({
    min_length: ["8", [Validators.required]],
    max_lenght: ["64", [Validators.required]],
    wrong_password_attempts: ["3", [Validators.required]],
    expire_password: ['', []],
    min_special_char: ['', []],
    min_uppercase_char: ['', []],
    min_numbers: ['', []],
    required_special_char_starts: [false, []],
    required_uppercase_char_starts: [false, []],
    required_number_char_starts: [false, []],
  });


  pageloadvalues: any;
  pageloadvalues1: any;
  cidrPageloadvalues: any;
  ccprimebreadcrumbList = [
    { label: `Tenant Mangement` },
    { label: `Authentication` },
  ]
  homeiconccprimebreadcrumb = { icon: 'pi pi-home', routerLink: ['/'] };
  selectList: any[] = [];
  applicationList: any[] = [];
  conditionList1: any[] = [];
  actionTakenArray: any[] = [{ label: "Required MFA", value: "REQUIRED_MFA" },
  { label: "Block Sign In", value: "BLOCK_SIGN_IN" },
  { label: "Allow Sign In", value: "ALLOW_SIGN_IN" }];
  Ip_radiobuttondefaultvalue1: any = "ALLOW_SIGN_IN";
  Cidr_radiobuttondefaultvalue1: any = "ALLOW_SIGN_IN";
  Behavior_radiobuttondefaultvalue1: any = "ALLOW_SIGN_IN";

  staticIPversion: any[] = [
    {
      "value": "IPV4",
      "label": "IPV4"
    },
    {
      "value": "IPV6",
      "label": "IPV6"
    },
  ];
  policyArray: any[] = [
    {
      "value": "1",
      "label": "min_length"
    },
    {
      "value": "2",
      "label": "min_uppercase_char"
    },
    {
      "value": "3",
      "label": "min_special_char"
    }
  ];
  messageFlag = true;
  applicationId: any;
  defaultNum = 1;
  msgs: Message[] = [];
  messageFlag1 = true;
  dropdown = true;
  selectedIndex: any;
  flag1 = true;
  appId: any[] = [];
  groupArr: [];
  linkIp_checked1 = false;
  Ip_checked1 = false;
  Cidr_checked1 = false;
  behavior_checked1 = false;
  cnum: any;
  conditionFlag = false;
  conditionList: any[] = [];
  accountList: any[] = [];
  enableValue = false;
  defaultIPnum = 1;
  defaultCIDRnum = 1;
  currenntValueCondition: any;
  enableBtn: boolean = true;
  passwordEnableBtn: boolean = false;
  apiDuplicity: boolean = false;
  valueCheckFlag: boolean = false;
  @ViewChild('myinput ') input: ElementRef;
  policies: any;
  maxvalueCheckFlag: boolean = false;
  passwordattemptsValueCheckFlag: boolean = false;
  zeroFlag: boolean = false;
  minDefaultvalue: any = 8
  maxDefaultvalue: any = 64;
  minCheck: boolean = false;
  additionCheck: boolean = false;
  specialCharStart: any;
  numberCharStart: any;
  uppercaseCharStart: any;
  firstSave: boolean = false;
  maxAddCheck: boolean = false;
  maxSpecialCheck: boolean = false;
  maxUpperCheck: boolean = false;
  maxNumbersCheck: boolean = false;
  ngModelFlag: boolean = false;
  responseCondition: any;
  authenticationId: any;
  valueRecieve: number;
  behaviorAuthConfigId: any;
  iprangeAuthConfigId: any;
  cidrAuthConfigId: any;
  behaviorConditionId: any;
  iprangeConditionId: any;
  cidrConditionId: any;
  radioFlag: boolean = false;
  exAccountList: any[] = [];
  newAccountList: any[] = [];
  exAccountList1: any[] = [];
  exAccountList2: any[] = [];
  exAccountList3: any[] = [];
  firstSaveForCheck: boolean;

  //end

  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    public dialogService: DialogService,
    public pagesService: PagesService,
    private messageService: MessageService,
    private config: PrimeNGConfig
  ) {
    const navigation = this.router.getCurrentNavigation();
    const data = navigation?.extras?.state?.data;
    if (data) {
      this.AuthenticationForm.patchValue(JSON.parse(data));
    }
  }

  ngOnInit(): void {
    if (this.pagesService.tenantManagementReadOnlyFlag) {
      this.AuthenticationForm.get("enableSwitch").disable();
    }
    this.onAccountsdropdown();
    this.getApplicationList();
    this.getIPRangeConfigdropdown();
    this.getPasswordPolicyBytenantidonPageLoad();
    this.IPRangeConfigurationForm.addControl("condition" + this.defaultNum, this.fb.control('', Validators.required));
  }

  cosoleCheckForm() {
    console.log("IPCFORM", this.IPRangeConfigurationForm);
    console.log("IPVFORM", this.IPversionForm);
    console.log("CIDRFORM", this.CIDRForm);
    console.log("AUTHFORM", this, this.AuthenticationForm);
  }
  onAccountsdropdown() {
    this.pagesService.getAccountsdropdown().subscribe(results => {
      const responseData = results.success?.result;
      if (responseData && responseData.length > 0) {
        responseData.forEach((element: any = {}) => {
          const data = {
            displayName: null,
            id: null,
          };
          data.displayName = element.label;
          data.id = element.value;
          this.accountList.push(data);
        });
        this.accountList = [...this.accountList];
      }
    });
  }

  changeLinkAccount(num: any, form: any) {
    let linkaccount = "";
    let accountValue = "";
    if (form == "IPversionForm") {
      linkaccount = "linkAccountIP"
      accountValue = "accountIP"
    }
    else if (form == "CIDRForm") {
      linkaccount = "linkAccountCIDR"
      accountValue = "accountCIDR"
    }
    else if (form == "IPRangeConfigurationForm") {
      linkaccount = "linkAccountBH"
      accountValue = "accountBH"
    }
    if (this[form].value[linkaccount + num] == null) {
      this[form].value[linkaccount + num] = false;
    }
    if (this[form].value[linkaccount + num]) {
      this[form].controls[accountValue + num].setValidators(Validators.required);
    }
    else if (!this[form].value[linkaccount + num]) {
      this[form].controls[accountValue + num].clearValidators();
      this[form].controls[accountValue + num].reset();
    }
    this[form].updateValueAndValidity();
  }

  handleChangetabview(e: any): void {
    this.AuthenticationForm.patchValue({ "tabview": e.index });
  }

  highlight(index: any) {
    this.selectedIndex = index;
  }

  mouseLeave() {
    this.selectedIndex = null;
  }
  //Application List Dropdown
  getApplicationList(): void {
    this.pagesService.getApplicationListMapping().subscribe(results => {
      const responseData = results?.success?.result;
      if (responseData && responseData.length > 0) {
        responseData?.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
          };
          data.label = element.label;
          data.value = element.value;
          this.applicationList.push(data);
        });
        this.applicationList = [...this.applicationList];
      }
    });
  }

  //evaluate contextual authentication
  enableDisableEvaluateAuthentication() {
    const reqbody = {};
    this.messageFlag = true;
    reqbody['tenantId'] = this.pagesService.tenantId;
    reqbody['applicationId'] = this.AuthenticationForm.value.application;
    reqbody['enableAuthentication'] = this.AuthenticationForm.value.enableSwitch;
    this.pagesService.enableDisableAuthentication(reqbody).subscribe(
      {
        next: results => {
          if (results.success.code == 200) {
            this.messageFlag = true;
            this.enableBtn = true;
            this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'Authentication details saved successfully.' });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
          }
        },
      });
  }
  //getting Condition List
  getIPRangeConfigdropdown(): void {
    this['conditionList' + this.defaultNum] = [];
    this.pagesService.getIPRangeConfigdropdown().subscribe(results => {
      const responseData = results?.success?.result;
      if (responseData && responseData.length > 0) {
        responseData?.forEach((element: any = {}) => {
          const data = {
            label: null,
            value: null,
            inactive: false
          };
          data.label = element.label;
          data.value = element.value;
          data.inactive = false;
          this.conditionList.push(data);
          this['conditionList' + this.defaultNum].push(data);
        });
        this.conditionList = [...this.conditionList];
        this['conditionList' + this.defaultNum] = [...this['conditionList' + this.defaultNum]];
      }
    });
  }
  conditionDropdownData(): void {
    this['conditionList' + this.defaultNum] = [];
    this['accountList' + this.defaultNum] = [];
    this.conditionList.forEach((element: any = {}) => {
      this['conditionList' + this.defaultNum].push(element);
      this['accountList' + this.defaultNum].push(this.accountList);
    });
    this['conditionList' + this.defaultNum] = [...this['conditionList' + this.defaultNum]];

  }
  onChangeOfConditionsDropdown(num: any) {
    this.onFirstchange();
    this.disablingConditions();
    this.deleteControlsOnDropdownChange(num);
  }

  //on first select of condition
  onFirstchange() {
    if (this.defaultNum >= 3) {
      this.conditionFlag = true;
    }
    else {
      this.conditionFlag = false;
    }
    if (this.IPRangeConfigurationForm.value['condition' + this.defaultNum] == 'BEHAVIOUR_CHANGE') {
      this.IPRangeConfigurationForm.addControl("linkAccountBH" + this.defaultNum, this.fb.control(false, []));
      this.IPRangeConfigurationForm.addControl("accountBH" + this.defaultNum, this.fb.control('', []));
      this.IPRangeConfigurationForm.addControl("notifyMailBehavior" + this.defaultNum, this.fb.control('', []));
      this.IPRangeConfigurationForm.addControl("actionTakenBehavior" + this.defaultNum, this.fb.control('REQUIRED_MFA', Validators.required));
      this['behavior_checked' + this.defaultNum] = false;
      this['Behavior_radiobuttondefaultvalue' + this.defaultNum] = "ALLOW_SIGN_IN";
    }
    if (this.IPRangeConfigurationForm.value['condition' + this.defaultNum] == 'ADD_IP_IP_RANGE') {
      this.IPversionForm.addControl("linkAccountIP" + this.defaultIPnum, this.fb.control(false, []));
      this.IPversionForm.addControl("accountIP" + this.defaultIPnum, this.fb.control('', []));
      this.IPversionForm.addControl("ipVersion" + this.defaultIPnum, this.fb.control('', Validators.required));
      this.IPversionForm.addControl("startIP" + this.defaultIPnum, this.fb.control('', Validators.required));
      this.IPversionForm.addControl("endIP" + this.defaultIPnum, this.fb.control('', Validators.required));
      this.IPversionForm.addControl("actionTakenIP" + this.defaultIPnum, this.fb.control('REQUIRED_MFA', Validators.required));
      this.IPversionForm.addControl("notifyMailIP" + this.defaultIPnum, this.fb.control('', []));
      this.IPversionForm.controls['startIP' + this.defaultIPnum].disable();
      this.IPversionForm.controls['endIP' + this.defaultIPnum].disable();
    }
    if (this.IPRangeConfigurationForm.value['condition' + this.defaultNum] == 'ADD_CIDR_RANGE') {
      this.CIDRForm.addControl("linkAccountCIDR" + this.defaultCIDRnum, this.fb.control(false, []));
      this.CIDRForm.addControl("accountCIDR" + this.defaultCIDRnum, this.fb.control('', []));
      this.CIDRForm.addControl("rangeCIDR" + this.defaultCIDRnum, this.fb.control('', Validators.required));
      this.CIDRForm.addControl("actionTakenCIDR" + this.defaultCIDRnum, this.fb.control('REQUIRED_MFA', Validators.required));
      this.CIDRForm.addControl("notifyMailCIDR" + this.defaultCIDRnum, this.fb.control('', []));
    }
  }

  /**
   * IP VERSION FUNCTIONALITY
   */

  createIPversionRange(number: any) {
    return new Array(number).fill(0)
      .map((n, index) => index + 1);
  }
  //adding multiple IP'S

  addIPVersion() {
    this.defaultIPnum = this.defaultIPnum + 1;
    this['Ip_checked' + this.defaultIPnum] = false;
    this['Ip_radiobuttondefaultvalue' + this.defaultIPnum] = "ALLOW_SIGN_IN";
    this.IPversionForm.updateValueAndValidity();
    this.IPversionForm.addControl("linkAccountIP" + this.defaultIPnum, this.fb.control(false, []));
    this.IPversionForm.addControl("accountIP" + this.defaultIPnum, this.fb.control('', []));
    this.IPversionForm.addControl("ipVersion" + this.defaultIPnum, this.fb.control('', Validators.required));
    this.IPversionForm.addControl("startIP" + this.defaultIPnum, this.fb.control('', Validators.required));
    this.IPversionForm.addControl("endIP" + this.defaultIPnum, this.fb.control('', Validators.required));
    this.IPversionForm.addControl("actionTakenIP" + this.defaultIPnum, this.fb.control('REQUIRED_MFA', Validators.required));
    this.IPversionForm.addControl("notifyMailIP" + this.defaultIPnum, this.fb.control('', []));
    if (!this.radioFlag) {
      this.IPversionForm.controls['startIP' + this.defaultIPnum].disable();
      this.IPversionForm.controls['endIP' + this.defaultIPnum].disable();
    }
    else
      this.IPversionForm.addControl("editIdIp" + this.defaultIPnum, this.fb.control('', []));

  }

  //onSelect of ipversion dropdown
  enablingStartEnd(inum: any) {
    this.IPversionForm.controls['startIP' + inum].enable();
    this.IPversionForm.controls['endIP' + inum].enable();
    this.IPversionForm.controls['startIP' + inum].reset();
    this.IPversionForm.controls['endIP' + inum].reset();
    this['startIPPattern' + inum] = false;
    this['endIPPattern' + inum] = false;
    this['startDuplicity' + inum] = false;
    this['ipDuplicity' + inum] = false;
    this['comparingIP' + inum] = false;
  }

  //comparing start and end ip
  comparingIP(inum: any) {
    this['comparingIP' + inum] = false;
    this.valueRecieve = this.customComparator(this.IPversionForm.value['startIP' + inum], this.IPversionForm.value['endIP' + inum]);
    if (this.IPversionForm.value['endIP' + inum] != null && !this['endIPPattern' + inum]) {

      if (this.valueRecieve == 1) {
        this['comparingIP' + inum] = true;
        this.IPversionForm.setErrors({ 'invalid': true });
      }
    }


  }
  customComparator(a: any, b: any) {

    // Breaking into the octets   
    let octetsA = a?.split(".");
    let octetsB = b?.split(".");
    // Condition if the IP Address
    // is same then return 0
    if (octetsA == octetsB)
      return 0
    else if (parseInt(octetsA[0]) > parseInt(octetsB[0]))
      return 1
    else if (parseInt(octetsA[0]) < parseInt(octetsB[0]))
      return -1
    else if (parseInt(octetsA[1]) > parseInt(octetsB[1]))
      return 1
    else if (parseInt(octetsA[1]) < parseInt(octetsB[1]))
      return -1
    else if (parseInt(octetsA[2]) > parseInt(octetsB[2]))
      return 1
    else if (parseInt(octetsA[2]) < parseInt(octetsB[2]))
      return -1
    else if (parseInt(octetsA[3]) > parseInt(octetsB[3]))
      return 1
    else if (parseInt(octetsA[3]) < parseInt(octetsB[3]))
      return -1

  }

  // DUPLICITY CHECK FOR RANGES
  duplicityCheckIPrange(inum: any) {
    this['ipDuplicity' + inum] = false;
    this['startDuplicity' + inum] = false;
    document.getElementById('endIP' + inum)!.style.borderColor = 'grey';
    document.getElementById('startIP' + inum)!.style.borderColor = 'grey';
    //for start and end of same 
    if (this.IPversionForm.value['startIP' + inum] == this.IPversionForm.value['endIP' + inum]) {
      document.getElementById('endIP' + inum)!.style.borderColor = 'red';
      this['ipDuplicity' + inum] = true;
      this.IPversionForm.setErrors({ 'invalid': true });

    }
    else {
      document.getElementById('endIP' + inum)!.style.borderColor = 'grey';
      this['ipDuplicity' + inum] = false;
    }
    // for multiple start check
    for (let i = 1; i <= this.defaultIPnum; i++) {
      if (i != inum && this.IPversionForm.value['startIP' + inum] == this.IPversionForm.value['startIP' + i]) {
        document.getElementById('startIP' + inum)!.style.borderColor = 'red';
        this['startDuplicity' + inum] = true;
        this.IPversionForm.setErrors({ 'invalid': true });

      }
      else if (this.IPversionForm.value['startIP' + inum] != this.IPversionForm.value['startIP' + i]) {
        document.getElementById('startIP' + inum)!.style.borderColor = 'grey';
        this['startDuplicity' + inum] = false;

      }
    }
  }

  addSo(IP4endValue: any, inum: any, validateEnd: any) {
    if (IP4endValue == null || IP4endValue == "") {
      document.getElementById('endIP' + inum)!.style.borderColor = 'grey';
      this['endIPPattern' + inum] = false;
      this.IPversionForm.setErrors(null);
    }
    else if (!validateEnd && (IP4endValue != "" || IP4endValue != null)) {
      document.getElementById('endIP' + inum)!.style.borderColor = 'red';
      this['endIPPattern' + inum] = true;
      this.IPversionForm.setErrors({ 'invalid': true });
    }
    else {
      document.getElementById('endIP' + inum)!.style.borderColor = 'grey';
    }
  }
  //checking end address
  addressEndIpCheck(inum: any) {
    if (this.IPversionForm.value['ipVersion' + inum] == 'IPV4') {

      const regularExpressionIP4 = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;//NOSONAR
      const IP4endValue = this.IPversionForm.value['endIP' + inum];
      const validateEnd = regularExpressionIP4.test(String(IP4endValue).toLowerCase());
      this['endIPPattern' + inum] = false;
      if (IP4endValue != null) {
        this.addSo(IP4endValue, inum, validateEnd);
      }
    }
    else if (this.IPversionForm.value['ipVersion' + inum] == "IPV6") {

      const regularExpressionIP6 = /^(?:[A-Fa-f0-9]{1,4}:){7}[A-Fa-f0-9]{1,4}$/;
      const IP6value = this.IPversionForm.value['endIP' + inum];

      const validate = regularExpressionIP6.test(String(IP6value).toLowerCase());

      this['endIPPattern' + inum] = false;
      if (IP6value == null || IP6value == "" || validate) {
        document.getElementById('endIP' + inum)!.style.borderColor = 'grey';
        this['endIPPattern' + inum] = false;
        this.IPversionForm.setErrors(null);
      }
      else if (!validate && (IP6value != "" || IP6value != null)) {
        document.getElementById('endIP' + inum)!.style.borderColor = 'red';
        this['endIPPattern' + inum] = true;
        this.IPversionForm.setErrors({ 'invalid': true });
      }
      else {
        document.getElementById('endIP' + inum)!.style.borderColor = 'grey';
      }
    }
  }
  //checking start address
  addressStartIpCheck(inum: any) {
    if (this.IPversionForm.value['ipVersion' + inum] == 'IPV4') {
      this.checkStartAddressIPV4(inum);
    }
    else if (this.IPversionForm.value['ipVersion' + inum] == "IPV6") {
      this.checkStartAddressIPV6(inum);
    }

  }
  checkStartAddressIPV6(inum: any) {
    const regularExpressionIP6 = /^(?:[A-Fa-f0-9]{1,4}:){7}[A-Fa-f0-9]{1,4}$/;
    const IP6value = this.IPversionForm.value['startIP' + inum];

    const validate = regularExpressionIP6.test(String(IP6value).toLowerCase());

    this['startIPPattern' + inum] = false;
    if (IP6value == null || IP6value == "" || validate) {
      document.getElementById('startIP' + inum)!.style.borderColor = 'grey';
      this['startIPPattern' + inum] = false;
      this.IPversionForm.setErrors(null);
    }
    else if (!validate && (IP6value != "" || IP6value != null)) {
      document.getElementById('startIP' + inum)!.style.borderColor = 'red';
      this['startIPPattern' + inum] = true;
      this.IPversionForm.setErrors({ 'invalid': true });
    }
    else {
      document.getElementById('startIP' + inum)!.style.borderColor = 'grey';
    }
  }
  checkStartAddressIPV4(inum: any) {
    const regularExpressionIP4 = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;//NOSONAR
    const IP4startValue = this.IPversionForm.value['startIP' + inum];
    const validateStart = regularExpressionIP4.test(String(IP4startValue).toLowerCase());
    this['startIPPattern' + inum] = false;
    if (IP4startValue == null || IP4startValue == "") {
      document.getElementById('startIP' + inum)!.style.borderColor = 'grey';
      this['startIPPattern' + inum] = false;
      this.IPversionForm.setErrors(null);
    }
    else if (!validateStart && (IP4startValue != "" || IP4startValue != null)) {
      document.getElementById('startIP' + inum)!.style.borderColor = 'red';
      this['startIPPattern' + inum] = true;
      this.IPversionForm.setErrors({ 'invalid': true });
    }
    else {
      document.getElementById('startIP' + inum)!.style.borderColor = 'grey';
    }
  }
  //deleteing multiple IP's
  deleteIPVersion(inum: any) {
    if (this.defaultIPnum > 1) {
      for (let i = inum + 1; i <= this.defaultIPnum; i++) {
        this.IPversionForm.controls["ipVersion" + (i - 1)].reset();
        this.IPversionForm.controls["ipVersion" + (i - 1)].setValue(this.IPversionForm.controls["ipVersion" + i].value);
        this.IPversionForm.controls["ipVersion" + (i - 1)].updateValueAndValidity();
        this.IPversionForm.controls["startIP" + (i - 1)].reset();
        this.IPversionForm.controls["startIP" + (i - 1)].setValue(this.IPversionForm.controls["startIP" + i].value);
        this.IPversionForm.controls["startIP" + (i - 1)].updateValueAndValidity();
        this.IPversionForm.controls["endIP" + (i - 1)].reset();
        this.IPversionForm.controls["endIP" + (i - 1)].setValue(this.IPversionForm.controls["endIP" + i].value);
        this.IPversionForm.controls["endIP" + (i - 1)].updateValueAndValidity();
        this.IPversionForm.controls["actionTakenIP" + (i - 1)].reset();
        this.IPversionForm.controls["actionTakenIP" + (i - 1)].setValue(this.IPversionForm.controls["actionTakenIP" + i].value);
        this.IPversionForm.controls["actionTakenIP" + (i - 1)].updateValueAndValidity();
        this.IPversionForm.controls["notifyMailIP" + (i - 1)].reset();
        this.IPversionForm.controls["notifyMailIP" + (i - 1)].setValue(this.IPversionForm.controls["notifyMailIP" + i].value);
        this.IPversionForm.controls["notifyMailIP" + (i - 1)].updateValueAndValidity();
        this.IPversionForm.controls["accountIP" + (i - 1)].reset();
        this.IPversionForm.controls["accountIP" + (i - 1)].setValue(this.IPversionForm.controls["accountIP" + i].value);
        this.IPversionForm.controls["accountIP" + (i - 1)].updateValueAndValidity();
        this.IPversionForm.controls["linkAccountIP" + (i - 1)].reset();
        this.IPversionForm.controls["linkAccountIP" + (i - 1)].setValue(this.IPversionForm.controls["linkAccountIP" + i].value);
        this.IPversionForm.controls["linkAccountIP" + (i - 1)].updateValueAndValidity();
      }
      if (this.ngModelFlag) {
        this.onDeleteContextualConditionConfigs(this.iprangeAuthConfigId, this.iprangeConditionId, this.IPversionForm.value['editIdIp' + this.defaultIPnum]);
        this.IPversionForm.removeControl('editIdIp' + this.defaultIPnum);
      }
      this.IPversionForm.removeControl("ipVersion" + this.defaultIPnum);
      this.IPversionForm.removeControl("startIP" + this.defaultIPnum);
      this.IPversionForm.removeControl("endIP" + this.defaultIPnum);
      this.IPversionForm.removeControl("actionTakenIP" + this.defaultIPnum);
      this.IPversionForm.removeControl("notifyMailIP" + this.defaultIPnum);
      this.IPversionForm.removeControl("linkAccountIP" + this.defaultIPnum);
      this.IPversionForm.removeControl("accountIP" + this.defaultIPnum);
      this.defaultIPnum = this.defaultIPnum - 1;
    }
  }

  /**
   * CIDR RANGE FUNCTIONALITY
   */

  createCIDRRange(number: any) {
    return new Array(number).fill(0)
      .map((n, index) => index + 1);
  }

  //adding CIDR
  addCIDR() {
    this.defaultCIDRnum = this.defaultCIDRnum + 1;
    this['disableCIDRAdd' + this.defaultCIDRnum] = true;
    this['Cidr_checked' + this.defaultCIDRnum] = false;
    this['Cidr_radiobuttondefaultvalue' + this.defaultCIDRnum] = "ALLOW_SIGN_IN";
    this.CIDRForm.addControl("linkAccountCIDR" + this.defaultCIDRnum, this.fb.control(false, []));
    this.CIDRForm.addControl("accountCIDR" + this.defaultCIDRnum, this.fb.control('', []));
    this.CIDRForm.addControl("rangeCIDR" + this.defaultCIDRnum, this.fb.control('', Validators.required));
    this.CIDRForm.addControl("actionTakenCIDR" + this.defaultCIDRnum, this.fb.control('REQUIRED_MFA', Validators.required));
    this.CIDRForm.addControl("notifyMailCIDR" + this.defaultCIDRnum, this.fb.control('', []));
    if (this.ngModelFlag)
      this.CIDRForm.addControl("editIdCidr" + this.defaultCIDRnum, this.fb.control('', []));

  }

  //Duplicity check for multiple ranges
  duplicityCheckCIDR(rangeValue: any, cnum: any) {
    this['cidrDuplicity' + cnum] = false;
    document.getElementById('rangeCIDR' + cnum)!.style.borderColor = 'grey';
    for (let i = 1; i <= this.defaultCIDRnum; i++) {
      if (i != cnum && rangeValue == this.CIDRForm.value['rangeCIDR' + i]) {
        document.getElementById('rangeCIDR' + cnum)!.style.borderColor = 'red';
        this['cidrDuplicity' + cnum] = true;
        this.CIDRForm.setErrors({ 'invalid': true });
      }
      else if (rangeValue != this.CIDRForm.value['rangeCIDR' + i]) {
        document.getElementById('rangeCIDR' + i)!.style.borderColor = 'grey';
        this['cidrDuplicity' + i] = false;
      }
    }
  }

  //address validation
  addressCIDRCheck(range: any, cnum: any) {
    if (range != null) {
      this['cidrRangePattern' + cnum] = false;
      const regularExpressionIP4 = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}\/([0-9]|[1-2]{1}[0-9]|[3][0-2])$/;//NOSONAR
      const regularExpressionIP6 = /^(?:[A-Fa-f0-9]{1,4}:){7}[A-Fa-f0-9]{1,4}\/([0-9]|[1-2]{1}[0-9]|[3][0-2])$/;//NOSONAR
      const validateIP4 = regularExpressionIP4.test(String(range).toLowerCase());
      const validateIP6 = regularExpressionIP6.test(String(range).toLowerCase());
      if (range == null || range == "") {
        document.getElementById('rangeCIDR' + cnum)!.style.borderColor = 'grey';
        this['cidrRangePattern' + cnum] = false;
        this.CIDRForm.setErrors(null);
        this['disableCIDRAdd' + this.defaultCIDRnum] = true;
      }
      else if (!(validateIP4 || validateIP6)) {
        document.getElementById('rangeCIDR' + cnum)!.style.borderColor = 'red';
        this['cidrRangePattern' + cnum] = true;
        this.CIDRForm.setErrors({ 'invalid': true });
        this['disableCIDRAdd' + this.defaultCIDRnum] = true;
      }
      else {
        document.getElementById('rangeCIDR' + cnum)!.style.borderColor = 'grey';
        this['disableCIDRAdd' + this.defaultCIDRnum] = false;
      }
    }
  }
  //delete cidr range
  deleteCIDR(cnum: any) {
    if (this.defaultCIDRnum > 1) {
      for (let i = cnum + 1; i <= this.defaultCIDRnum; i++) {
        this.CIDRForm.controls["rangeCIDR" + (i - 1)].reset();
        this.CIDRForm.controls["rangeCIDR" + (i - 1)].setValue(this.CIDRForm.controls["rangeCIDR" + i].value);
        this.CIDRForm.controls["rangeCIDR" + (i - 1)].updateValueAndValidity();
        this.CIDRForm.controls["actionTakenCIDR" + (i - 1)].reset();
        this.CIDRForm.controls["actionTakenCIDR" + (i - 1)].setValue(this.CIDRForm.controls["actionTakenCIDR" + i].value);
        this.CIDRForm.controls["actionTakenCIDR" + (i - 1)].updateValueAndValidity();
        this.CIDRForm.controls["notifyMailCIDR" + (i - 1)].reset();
        this.CIDRForm.controls["notifyMailCIDR" + (i - 1)].setValue(this.CIDRForm.controls["notifyMailCIDR" + i].value);
        this.CIDRForm.controls["notifyMailCIDR" + (i - 1)].updateValueAndValidity();
        this.CIDRForm.controls["accountCIDR" + (i - 1)].reset();
        this.CIDRForm.controls["accountCIDR" + (i - 1)].setValue(this.CIDRForm.controls["accountCIDR" + i].value);
        this.CIDRForm.controls["accountCIDR" + (i - 1)].updateValueAndValidity();
        this.CIDRForm.controls["linkAccountCIDR" + (i - 1)].reset();
        this.CIDRForm.controls["linkAccountCIDR" + (i - 1)].setValue(this.CIDRForm.controls["linkAccountCIDR" + i].value);
        this.CIDRForm.controls["linkAccountCIDR" + (i - 1)].updateValueAndValidity();
      }
      if (this.ngModelFlag) {
        this.onDeleteContextualConditionConfigs(this.iprangeAuthConfigId, this.iprangeConditionId, this.CIDRForm.value['editIdCidr' + this.defaultCIDRnum]);
        this.CIDRForm.removeControl('editIdCidr' + this.defaultCIDRnum);
      }
      this.CIDRForm.removeControl("accountCIDR" + this.defaultCIDRnum);
      this.CIDRForm.removeControl("linkAccountCIDR" + this.defaultCIDRnum);
      this.CIDRForm.removeControl("rangeCIDR" + this.defaultCIDRnum);
      this.CIDRForm.removeControl("actionTakenCIDR" + this.defaultCIDRnum);
      this.CIDRForm.removeControl("notifyMailCIDR" + this.defaultCIDRnum);
      this.defaultCIDRnum = this.defaultCIDRnum - 1;
      if (this.CIDRForm.value['rangeCIDR'] != " ") {
        this['disableCIDRAdd' + this.defaultCIDRnum] = false;
      }
      else {
        this['disableCIDRAdd' + this.defaultCIDRnum] = true;
      }

    }
  }
  /** 
   *  CONDITION FUNCTIONALITY 
   * */

  createRange(number: any) {
    return new Array(number).fill(0)
      .map((n, index) => index + 1);
  }
  //adding Multiple condition
  addCondition() {
    this.defaultNum = this.defaultNum + 1;
    if (this.defaultNum >= 3) {
      this.conditionFlag = true;
    }
    else {
      this.conditionFlag = false;
    }
    this.conditionDropdownData();
    this.IPRangeConfigurationForm.addControl("condition" + this.defaultNum, this.fb.control('', Validators.required));

    for (let i = 1; i <= this.defaultNum; i++) {
      const dummyValue = this.IPRangeConfigurationForm.value["condition" + i];
      for (let k = 1; k <= this.defaultNum; k++) {
        if (i != k) {
          for (let j = 0; j < this['conditionList' + this.defaultNum].length; j++) {
            if (this.IPRangeConfigurationForm.value['condition' + k] == "") {
              if (dummyValue == this['conditionList' + this.defaultNum][j].value) {
                this['conditionList' + this.defaultNum][j].inactive = true;
              }
            }
            else if (dummyValue == this.IPRangeConfigurationForm.value['condition' + k]) {
              this['conditionList' + this.defaultNum][j].inactive = true;
            }
          }
        }
      }
    }

  }

  delIPSo() {
    for (let ip = 1; ip <= this.defaultIPnum; ip++) {
      this.IPversionForm.removeControl("ipVersion" + ip);
      this.IPversionForm.removeControl("startIP" + ip);
      this.IPversionForm.removeControl("endIP" + ip);
      this.IPversionForm.removeControl("actionTakenIP" + ip);
      this.IPversionForm.removeControl("notifyMailIP" + ip);
      this.IPversionForm.removeControl("linkAccountIP" + ip);
      this.IPversionForm.removeControl("accountIP" + ip);
      if (this.ngModelFlag) {
        this.onDeleteContextualCondition(this.iprangeAuthConfigId, this.iprangeConditionId);
        this.IPversionForm.removeControl('editIdIp' + ip);
      }
      this['startIPPattern' + ip] = false;
      this['endIPPattern' + ip] = false;
      this['ipDuplicity' + ip] = false;
      this['startDuplicity' + ip] = false;
    }
    this.defaultIPnum = 1;
  }

  delCIDRSo() {
    for (let cidr = 1; cidr <= this.defaultCIDRnum; cidr++) {
      this.CIDRForm.removeControl("accountCIDR" + cidr);
      this.CIDRForm.removeControl("linkAccountCIDR" + cidr);
      this.CIDRForm.removeControl("rangeCIDR" + cidr);
      this.CIDRForm.removeControl("actionTakenCIDR" + cidr);
      this.CIDRForm.removeControl("notifyMailCIDR" + cidr);
      if (this.ngModelFlag) {
        this.onDeleteContextualCondition(this.cidrAuthConfigId, this.cidrConditionId);
        this.CIDRForm.removeControl('editIdCidr' + cidr);
      }
      this['cidrDuplicity' + cidr] = false;
    }
    this.defaultCIDRnum = 1;
  }

  //deleting condition
  deleteCondition(num: any) {
    if (this.defaultNum > 1) {
      //removing controls
      if (this.IPRangeConfigurationForm.value['condition' + num] == 'ADD_IP_IP_RANGE') {
        this.delIPSo();
      }
      else if (this.IPRangeConfigurationForm.value['condition' + num] == 'ADD_CIDR_RANGE') {
        this.delCIDRSo();
      }
      else {
        this.IPRangeConfigurationForm.removeControl("actionTakenBehavior" + num);
        this.IPRangeConfigurationForm.removeControl("notifyMailIPBehavior" + num);
        this.IPRangeConfigurationForm.removeControl("linkAccountBH" + num);
        this.IPRangeConfigurationForm.removeControl("accountBH" + num);
        if (this.ngModelFlag) {
          this.onDeleteContextualCondition(this.behaviorAuthConfigId, this.behaviorConditionId);
          this.IPRangeConfigurationForm.removeControl('editIdbehavior' + num);
        }
      }
      for (let i = num + 1; i <= this.defaultNum; i++) {
        //switching the values from n to n-1
        this.IPRangeConfigurationForm.controls['condition' + (i - 1)].setValue(this.IPRangeConfigurationForm.value['condition' + i]);
      }
      this.IPRangeConfigurationForm.removeControl("condition" + this.defaultNum);
      this.disablingConditions();
      this.defaultNum = this.defaultNum - 1;
      if (this.defaultNum <= 3) {
        this.conditionFlag = false;
      }
    }

  }
  //disabling selected condition
  disablingConditions() {
    //disable dropdown value
    for (let i = 1; i <= this.defaultNum; i++) {
      const dummyValuee = this.IPRangeConfigurationForm.value["condition" + i];
      for (let k = 1; k <= this.defaultNum; k++) {
        const dummyValue = this.IPRangeConfigurationForm.value["condition" + k];
        if (i != k) {
          for (let j = 0; j < this['conditionList' + i].length; j++) {
            if (!(i == 2 || i == 3)) {
              this['conditionList' + i][j].inactive = false;
            }
            if (this.IPRangeConfigurationForm.value['condition' + i] == "") {
              if (dummyValue == this['conditionList' + i][j].value) {
                this['conditionList' + i][j].inactive = true;
              }
            }
            else {
              if (dummyValue == this['conditionList' + i][j].value) {
                this['conditionList' + i][j].inactive = true;
              }
              if (!(i == 2 || i == 3)) {
                if (dummyValuee == this['conditionList' + k][j].value) {
                  this['conditionList' + k][j].inactive = true;
                }
              }

            }
          }
        }
      }
    }
  }

  delControlsIPCIDERSo() {
    if (!this['conditionArr'].includes('BEHAVIOUR_CHANGE')) {
      for (let j = 1; j <= this.defaultNum; j++) {
        this.IPRangeConfigurationForm.removeControl("linkAccountBH" + j);
        this.IPRangeConfigurationForm.removeControl("accountBH" + j);
        this.IPRangeConfigurationForm.removeControl("actionTakenBehavior" + j);
        this.IPRangeConfigurationForm.removeControl("notifyMailBehavior" + j);
        if (this.ngModelFlag) {
          this.IPRangeConfigurationForm.removeControl('editIdbehavior' + j);
        }
      }
    }
    if (!this['conditionArr'].includes('ADD_IP_IP_RANGE')) {
      for (let ip = 1; ip <= this.defaultIPnum; ip++) {
        this.IPversionForm.removeControl("ipVersion" + ip);
        this.IPversionForm.removeControl("startIP" + ip);
        this.IPversionForm.removeControl("endIP" + ip);
        this.IPversionForm.removeControl("actionTakenIP" + ip);
        this.IPversionForm.removeControl("notifyMailIP" + ip);
        this.IPversionForm.removeControl("linkAccountIP" + ip);
        this.IPversionForm.removeControl("accountIP" + ip);
        this['startIPPattern' + ip] = false;
        this['endIPPattern' + ip] = false;
        this['ipDuplicity' + ip] = false;
        this['startDuplicity' + ip] = false;
        if (this.ngModelFlag) {
          this.IPversionForm.removeControl('editIdIp' + ip);
        }
      }
      this.defaultIPnum = 1;
    }
  }
  //deleting controls  on drop down selection
  deleteControlsOnDropdownChange(num: any) {
    this['conditionArr'] = [];
    for (let i = 1; i <= this.defaultNum; i++) {
      this['conditionArr'].push(this.IPRangeConfigurationForm.value["condition" + i]);
    }

    this.delControlsIPCIDERSo();

    if (!this['conditionArr'].includes('ADD_CIDR_RANGE')) {
      for (let cidr = 1; cidr <= this.defaultCIDRnum; cidr++) {
        this.CIDRForm.removeControl("accountCIDR" + cidr);
        this.CIDRForm.removeControl("linkAccountCIDR" + cidr);
        this.CIDRForm.removeControl("rangeCIDR" + cidr);
        this.CIDRForm.removeControl("actionTakenCIDR" + cidr);
        this.CIDRForm.removeControl("notifyMailCIDR" + cidr);
        this['cidrDuplicity' + cidr] = false;
        if (this.ngModelFlag) {
          this.CIDRForm.removeControl('editIdCidr' + cidr);
        }
      }
      this.defaultCIDRnum = 1;
    }
    if (this['conditionArr'].includes(this.IPRangeConfigurationForm.value['condition' + num])) {
      if (this.IPRangeConfigurationForm.value['condition' + num] == 'ADD_CIDR_RANGE') {
        this['disableCIDRAdd' + this.defaultCIDRnum] = true;
        this['Cidr_checked' + this.defaultCIDRnum] = false;
        this['Cidr_radiobuttondefaultvalue' + this.defaultCIDRnum] = "ALLOW_SIGN_IN";
        this.CIDRForm.addControl("linkAccountCIDR" + this.defaultCIDRnum, this.fb.control(false, []));
        this.CIDRForm.addControl("accountCIDR" + this.defaultCIDRnum, this.fb.control('', []));
        this.CIDRForm.addControl("rangeCIDR" + this.defaultCIDRnum, this.fb.control('', Validators.required));
        this.CIDRForm.addControl("actionTakenCIDR" + this.defaultCIDRnum, this.fb.control('REQUIRED_MFA', Validators.required));
        this.CIDRForm.addControl("notifyMailCIDR" + this.defaultCIDRnum, this.fb.control('', []));
      }
      else if (this.IPRangeConfigurationForm.value['condition' + num] == 'ADD_IP_IP_RANGE') {
        this['Ip_checked' + this.defaultIPnum] = false;
        this['Ip_radiobuttondefaultvalue' + this.defaultIPnum] = "ALLOW_SIGN_IN";
        this.IPversionForm.addControl("linkAccountIP" + this.defaultIPnum, this.fb.control(false, []));
        this.IPversionForm.addControl("accountIP" + this.defaultIPnum, this.fb.control('', []));
        this.IPversionForm.addControl("ipVersion" + this.defaultIPnum, this.fb.control('', Validators.required));
        this.IPversionForm.addControl("startIP" + this.defaultIPnum, this.fb.control('', Validators.required));
        this.IPversionForm.addControl("endIP" + this.defaultIPnum, this.fb.control('', Validators.required));
        this.IPversionForm.addControl("actionTakenIP" + this.defaultIPnum, this.fb.control('REQUIRED_MFA', Validators.required));
        this.IPversionForm.addControl("notifyMailIP" + this.defaultIPnum, this.fb.control('', []));
      }
      else if (this.IPRangeConfigurationForm.value['condition' + num] == 'BEHAVIOUR_CHANGE') {
        this.IPRangeConfigurationForm.addControl("linkAccountBH" + this.defaultNum, this.fb.control(false, []));
        this.IPRangeConfigurationForm.addControl("accountBH" + this.defaultNum, this.fb.control('', []));
        this.IPRangeConfigurationForm.addControl("notifyMailBehavior" + this.defaultNum, this.fb.control('', []));
        this.IPRangeConfigurationForm.addControl("actionTakenBehavior" + this.defaultNum, this.fb.control('REQUIRED_MFA', Validators.required));
        this['behavior_checked' + this.defaultNum] = false;
        this['Behavior_radiobuttondefaultvalue' + this.defaultNum] = "ALLOW_SIGN_IN";
      }
    }
  }

  //addtionCheck
  addCheck(field: any) {
    this.maxAddCheck = false;
    this.maxSpecialCheck = false;
    this.maxUpperCheck = false;
    this.maxNumbersCheck = false;
    let addValue = Number(this.PasswordPolicyForm.value.min_special_char) + Number(this.PasswordPolicyForm.value.min_uppercase_char) + Number(this.PasswordPolicyForm.value.min_numbers);
    if (addValue > Number(this.PasswordPolicyForm.value.max_lenght)) {
      this.additionCheck = true;
      if (field == "maxLength") {
        this.maxAddCheck = true;
      }
      else if (field == "special") {
        this.maxSpecialCheck = true;
      }
      else if (field == "upper") {
        this.maxUpperCheck = true;
      }
      else if (field == "numbers") {
        this.maxNumbersCheck = true;
      }
    }
    else {
      this.additionCheck = false;
    }
  }
  //reseting value on Application Change
  resetIPSo() {
    for (let ip = 1; ip <= this.defaultIPnum; ip++) {
      this.IPversionForm.removeControl("ipVersion" + ip);
      this.IPversionForm.removeControl("startIP" + ip);
      this.IPversionForm.removeControl("endIP" + ip);
      this.IPversionForm.removeControl("actionTakenIP" + ip);
      this.IPversionForm.removeControl("notifyMailIP" + ip);
      this.IPversionForm.removeControl("editIdIp" + ip);
      this.IPversionForm.removeControl("linkAccountIP" + ip);
      this.IPversionForm.removeControl("accountIP" + ip);
      this['startIPPattern' + ip] = false;
      this['endIPPattern' + ip] = false;
      this['ipDuplicity' + ip] = false;
      this['startDuplicity' + ip] = false;
    }
    this.defaultIPnum = 1;
  }

  resetCIDRSo() {
    for (let cidr = 1; cidr <= this.defaultCIDRnum; cidr++) {
      this.CIDRForm.removeControl("accountCIDR" + cidr);
      this.CIDRForm.removeControl("linkAccountCIDR" + cidr);
      this.CIDRForm.removeControl("rangeCIDR" + cidr);
      this.CIDRForm.removeControl("actionTakenCIDR" + cidr);
      this.CIDRForm.removeControl("notifyMailCIDR" + cidr);
      this.CIDRForm.removeControl("editIdCidr" + cidr);
      this['cidrDuplicity' + cidr] = false;
      this['cidrRangePattern' + cidr] = false;
    }
    this.defaultCIDRnum = 1;
  }

  resetValue() {
    if (this.defaultNum >= 1) {
      for (let i = 1; i <= this.defaultNum; i++) {
        if (this.IPRangeConfigurationForm.value['condition' + i] == "ADD_IP_IP_RANGE") {
          this.resetIPSo();
        }
        else if (this.IPRangeConfigurationForm.value['condition' + i] == "ADD_CIDR_RANGE") {
          this.resetCIDRSo();
        }
        else {
          this.IPRangeConfigurationForm.removeControl("linkAccountBH" + i);
          this.IPRangeConfigurationForm.removeControl("accountBH" + i);
          this.IPRangeConfigurationForm.removeControl("actionTakenBehavior" + i);
          this.IPRangeConfigurationForm.removeControl("notifyMailBehavior" + i);
          this.IPRangeConfigurationForm.removeControl("editIdbehavior" + i);
        }
        if (i > 1) {
          this.IPRangeConfigurationForm.removeControl("condition" + i);
        }
        else {
          this.IPRangeConfigurationForm.controls['condition' + i].reset();
          this.IPRangeConfigurationForm.controls['condition' + i].updateValueAndValidity();
        }
        for (let u = 0; u < this['conditionList' + i].length; u++) {
          this['conditionList' + i][u].inactive = false;
        }
      }
    }
    this.defaultNum = 1;
    this.flag1 = false;
    this.enableValue = false;
  }

  //on save configuration check values
  onSaveCheck() {
    if (this.ngModelFlag)
      this.editAuthentication();
    else
      this.saveAuthentication();
  }
  //Saving Authentication Values
  saveAuthentication() {
    const parent = {};
    parent['tenantId'] = this.pagesService?.tenantId;
    parent['applicationId'] = this.AuthenticationForm.value.application;
    const ipConfigurations = [];
    for (let i = 1; i <= this.defaultNum; i++) {
      const reqBodyChild1 = {};
      reqBodyChild1['ipConfigs'] = [];
      reqBodyChild1['conditionType'] = this.IPRangeConfigurationForm.value['condition' + i];
      if (reqBodyChild1['conditionType'] == "ADD_IP_IP_RANGE") {
        for (let ip = 1; ip <= this.defaultIPnum; ip++) {
          const reqBodyChild2 = {};
          if (this.IPversionForm.value['linkAccountIP' + ip] == null) {
            this.IPversionForm.value['linkAccountIP' + ip] = false;
          }
          reqBodyChild2['isAccountLinked'] = this.IPversionForm.value['linkAccountIP' + ip];
          if (this.IPversionForm.value['linkAccountIP' + ip])
            reqBodyChild2['accountId'] = this.IPversionForm.value['accountIP' + ip];
          reqBodyChild2['ipVersion'] = this.IPversionForm.value['ipVersion' + ip];
          reqBodyChild2['startIp'] = this.IPversionForm.value['startIP' + ip];
          reqBodyChild2['endIp'] = this.IPversionForm.value['endIP' + ip];
          reqBodyChild2['actionTaken'] = this.IPversionForm.value['actionTakenIP' + ip];
          reqBodyChild2['notifyUserThroughMail'] = this.IPversionForm.value['notifyMailIP' + ip];
          reqBodyChild1['ipConfigs'].push(reqBodyChild2);
        }
      }
      else if (reqBodyChild1['conditionType'] == "ADD_CIDR_RANGE") {
        for (let c = 1; c <= this.defaultCIDRnum; c++) {
          const reqBodyChild2 = {};
          if (this.CIDRForm.value['linkAccountCIDR' + c] == null) {
            this.CIDRForm.value['linkAccountCIDR' + c] = false;
          }
          reqBodyChild2['isAccountLinked'] = this.CIDRForm.value['linkAccountCIDR' + c];
          if (this.CIDRForm.value['linkAccountCIDR' + c])
            reqBodyChild2['accountId'] = this.CIDRForm.value['accountCIDR' + c];
          reqBodyChild2['cidrRange'] = this.CIDRForm.value['rangeCIDR' + c];
          reqBodyChild2['actionTaken'] = this.CIDRForm.value['actionTakenCIDR' + c];
          reqBodyChild2['notifyUserThroughMail'] = this.CIDRForm.value['notifyMailCIDR' + c];
          reqBodyChild1['ipConfigs'].push(reqBodyChild2);
        }
      }
      else if (reqBodyChild1['conditionType'] == "BEHAVIOUR_CHANGE") {
        const reqBodyChild2 = {};
        if (this.IPRangeConfigurationForm.value['linkAccountBH' + i] == null) {
          this.IPRangeConfigurationForm.value['linkAccountBH' + i] = false;
        }
        reqBodyChild2['isAccountLinked'] = this.IPRangeConfigurationForm.value['linkAccountBH' + i];
        if (this.IPRangeConfigurationForm.value['linkAccountBH' + i])
          reqBodyChild2['accountId'] = this.IPRangeConfigurationForm.value['accountBH' + i];
        reqBodyChild2['actionTaken'] = this.IPRangeConfigurationForm.value['actionTakenBehavior' + i];
        reqBodyChild2['notifyUserThroughMail'] = this.IPRangeConfigurationForm.value['notifyMailBehavior' + i];
        reqBodyChild1['ipConfigs'].push(reqBodyChild2);
      }
      ipConfigurations.push(reqBodyChild1);
    }
    parent['ipConfigurations'] = ipConfigurations;
    this.pagesService.saveIPrangeConfig(parent).subscribe(
      {
        next: results => {
          if (results.success.code == 200) {

            this.enableBtn = true;
            this.firstSaveForCheck = true;
            this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'Authentication details saved successfully.' });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
            this.IPRangeConfigurationForm.reset();
            this.IPversionForm.reset();
            this.CIDRForm.reset();
            this.getContextualAuthentication();

          }
        },
        error: error => {
          if (error.error?.error?.messageCode == "CIAM NotSaved") {
            this.apiDuplicity = true;
          }
        }
      });

  }

  //edit authentication
  editAuthentication() {
    const parent = {};
    parent['tenantId'] = this.pagesService.tenantId;
    parent['applicationId'] = this.AuthenticationForm.value.application;
    parent['id'] = this.authenticationId;
    const ipConfigurations = [];
    for (let i = 1; i <= this.defaultNum; i++) {
      const reqBodyChild1 = {};
      reqBodyChild1['ipConfigs'] = [];
      reqBodyChild1['conditionType'] = this.IPRangeConfigurationForm.value['condition' + i];
      if (reqBodyChild1['conditionType'] == "ADD_IP_IP_RANGE") {
        for (let ip = 1; ip <= this.defaultIPnum; ip++) {
          const reqBodyChild2 = {};
          if (this.IPversionForm.value['linkAccountIP' + ip] == null) {
            this.IPversionForm.value['linkAccountIP' + ip] = false;
          }
          reqBodyChild2['isAccountLinked'] = this.IPversionForm.value['linkAccountIP' + ip];
          if (this.IPversionForm.value['linkAccountIP' + ip])
            reqBodyChild2['accountId'] = this.IPversionForm.value['accountIP' + ip];
          reqBodyChild2['id'] = this.IPversionForm.value['editIdIp' + ip];
          reqBodyChild2['ipVersion'] = this.IPversionForm.value['ipVersion' + ip];
          reqBodyChild2['startIp'] = this.IPversionForm.value['startIP' + ip];
          reqBodyChild2['endIp'] = this.IPversionForm.value['endIP' + ip];
          reqBodyChild2['actionTaken'] = this.IPversionForm.value['actionTakenIP' + ip];
          reqBodyChild2['notifyUserThroughMail'] = this.IPversionForm.value['notifyMailIP' + ip];
          reqBodyChild1['ipConfigs'].push(reqBodyChild2);
        }
      }
      else if (reqBodyChild1['conditionType'] == "ADD_CIDR_RANGE") {
        for (let c = 1; c <= this.defaultCIDRnum; c++) {
          const reqBodyChild2 = {};
          if (this.CIDRForm.value['linkAccountCIDR' + c] == null) {
            this.CIDRForm.value['linkAccountCIDR' + c] = false;
          }
          reqBodyChild2['isAccountLinked'] = this.CIDRForm.value['linkAccountCIDR' + c];
          if (this.CIDRForm.value['linkAccountCIDR' + c])
            reqBodyChild2['accountId'] = this.CIDRForm.value['accountCIDR' + c];
          reqBodyChild2['id'] = this.CIDRForm.value['editIdCidr' + c];
          reqBodyChild2['cidrRange'] = this.CIDRForm.value['rangeCIDR' + c];
          reqBodyChild2['actionTaken'] = this.CIDRForm.value['actionTakenCIDR' + c];
          reqBodyChild2['notifyUserThroughMail'] = this.CIDRForm.value['notifyMailCIDR' + c];
          reqBodyChild1['ipConfigs'].push(reqBodyChild2);
        }
      }
      else if (reqBodyChild1['conditionType'] == "BEHAVIOUR_CHANGE") {
        const reqBodyChild2 = {};
        if (this.IPRangeConfigurationForm.value['linkAccountBH' + i] == null) {
          this.IPRangeConfigurationForm.value['linkAccountBH' + i] = false;
        }
        reqBodyChild2['isAccountLinked'] = this.IPRangeConfigurationForm.value['linkAccountBH' + i];
        if (this.IPRangeConfigurationForm.value['linkAccountBH' + i])
          reqBodyChild2['accountId'] = this.IPRangeConfigurationForm.value['accountBH' + i];
        reqBodyChild2['id'] = this.IPRangeConfigurationForm.value['editIdbehavior' + i];
        reqBodyChild2['actionTaken'] = this.IPRangeConfigurationForm.value['actionTakenBehavior' + i];
        reqBodyChild2['notifyUserThroughMail'] = this.IPRangeConfigurationForm.value['notifyMailBehavior' + i];
        reqBodyChild1['ipConfigs'].push(reqBodyChild2);
      }
      ipConfigurations.push(reqBodyChild1);
    }
    parent['ipConfigurations'] = ipConfigurations;

    this.messageFlag = true;
    this.pagesService.saveIPrangeConfig(parent).subscribe(
      {
        next: results => {
          if (results.success.code == 200) {
            this.enableBtn = true;
            this.firstSaveForCheck = true;
            this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'Authentication details saved successfully.' });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);

            this.IPRangeConfigurationForm.reset();
            this.IPversionForm.reset();
            this.CIDRForm.reset();
            this.getContextualAuthentication();
          }
        },
        error: error => {
          if (error.error?.error?.messageCode == "CIAM NotSaved") {
            this.apiDuplicity = true;
          }
          if (error.error?.error?.code == 400) {
            this.messageService.add({ key: 'myKey1', severity: 'error', detail: error.error?.error?.reason });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
          }
        }
      });
  }

  //getContextualAuthentication
  getContextualAuthentication() {
    this.IPRangeConfigurationForm.reset();
    this.IPversionForm.reset();
    this.CIDRForm.reset();
    this.enableBtn = true;
    const reqBody = {};
    reqBody['tenantId'] = this.pagesService.tenantId;
    reqBody['applicationId'] = this.AuthenticationForm.value.application;
    let ipRangeconfigurationPropBind = {};
    let ipVersionPropBind = {};
    let cidrPropBind = {};
    this.pagesService.getContextualAuthentication(reqBody).subscribe(
      {
        next: (results: any) => {
          const responseData = results.success?.result.ipConfigurations;
          this.enableValue = results.success?.result.isEnabled;
          this.authenticationId = results.success?.result.id;
          if (responseData != null) {
            this.firstSaveForCheck = true;
            this.defaultNum = 0;
            this.ngModelFlag = true;
            this.radioFlag = true;
            if (responseData.length >= 3)
              this.conditionFlag = true;
            else
              this.conditionFlag = false;
            for (let i = 0; i < responseData.length; i++) {
              this.responseCondition = responseData[i].conditionType;
              if (this.responseCondition == "BEHAVIOUR_CHANGE") {
                this.behaviorAuthConfigId = responseData[i].authConfigId;
                this.behaviorConditionId = responseData[i].ipConfigs[0].authConditionId;
                this.addCondition();
                ipRangeconfigurationPropBind['condition' + this.defaultNum] = this.responseCondition;
                this.IPRangeConfigurationForm.addControl("linkAccountBH" + this.defaultNum, this.fb.control(false, []));
                this.IPRangeConfigurationForm.addControl("accountBH" + this.defaultNum, this.fb.control('', []));
                this.IPRangeConfigurationForm.addControl("editIdbehavior" + this.defaultNum, this.fb.control('', []));
                this.IPRangeConfigurationForm.addControl("notifyMailBehavior" + this.defaultNum, this.fb.control('', []));
                this.IPRangeConfigurationForm.addControl("actionTakenBehavior" + this.defaultNum, this.fb.control('REQUIRED_MFA', Validators.required));
                if (responseData[i].ipConfigs[0].isAccountLinked == null) {
                  ipRangeconfigurationPropBind['linkAccountBH' + this.defaultNum] = false;
                }
                else {
                  ipRangeconfigurationPropBind['linkAccountBH' + this.defaultNum] = responseData[i].ipConfigs[0].isAccountLinked;
                }
                if (responseData[i].ipConfigs[0].mappedAccount != null)
                  ipRangeconfigurationPropBind['accountBH' + this.defaultNum] = responseData[i].ipConfigs[0].mappedAccount?.id;
                ipRangeconfigurationPropBind['editIdbehavior' + this.defaultNum] = responseData[i].ipConfigs[0].id;
                ipRangeconfigurationPropBind['notifyMailBehavior' + this.defaultNum] = responseData[i].ipConfigs[0].notifyUserThroughMail;
                ipRangeconfigurationPropBind['actionTakenBehavior' + this.defaultNum] = responseData[i].ipConfigs[0].actionTaken;
              }
              else if (this.responseCondition == 'ADD_IP_IP_RANGE') {
                this.iprangeAuthConfigId = responseData[i].authConfigId;
                this.iprangeConditionId = responseData[i].ipConfigs[0].authConditionId;
                this.addCondition();
                this.defaultIPnum = 0;
                ipRangeconfigurationPropBind['condition' + this.defaultNum] = this.responseCondition;
                for (let j = 0; j < responseData[i].ipConfigs.length; j++) {
                  this.addIPVersion();
                  if (responseData[i].ipConfigs[j].isAccountLinked == null) {
                    ipVersionPropBind['linkAccountIP' + this.defaultIPnum] = false;
                  }
                  else {
                    ipVersionPropBind['linkAccountIP' + this.defaultIPnum] = responseData[i].ipConfigs[j].isAccountLinked;
                  }
                  if (responseData[i].ipConfigs[0].mappedAccount != null)
                    ipVersionPropBind['accountIP' + this.defaultIPnum] = responseData[i].ipConfigs[j].mappedAccount?.id;
                  ipVersionPropBind['editIdIp' + (this.defaultIPnum)] = responseData[i].ipConfigs[j].id;
                  ipVersionPropBind['ipVersion' + (this.defaultIPnum)] = responseData[i].ipConfigs[j].ipVersion;
                  ipVersionPropBind['startIP' + (this.defaultIPnum)] = responseData[i].ipConfigs[j].startIp;
                  ipVersionPropBind['endIP' + (this.defaultIPnum)] = responseData[i].ipConfigs[j].endIp;
                  ipVersionPropBind['actionTakenIP' + (this.defaultIPnum)] = responseData[i].ipConfigs[j].actionTaken;
                  ipVersionPropBind['notifyMailIP' + (this.defaultIPnum)] = responseData[i].ipConfigs[j].notifyUserThroughMail;
                }
              }
              else if (this.responseCondition == 'ADD_CIDR_RANGE') {
                this.cidrAuthConfigId = responseData[i].authConfigId;
                this.cidrConditionId = responseData[i].ipConfigs[0].authConditionId;
                this.addCondition();
                this.defaultCIDRnum = 0;
                ipRangeconfigurationPropBind['condition' + this.defaultNum] = this.responseCondition;
                for (let j = 0; j < responseData[i].ipConfigs.length; j++) {
                  this.addCIDR();
                  if (responseData[i].ipConfigs[j].isAccountLinked == null) {
                    cidrPropBind['linkAccountCIDR' + this.defaultCIDRnum] = false;
                  }
                  else {
                    cidrPropBind['linkAccountCIDR' + this.defaultCIDRnum] = responseData[i].ipConfigs[j].isAccountLinked;
                  }
                  if (responseData[i].ipConfigs[0].mappedAccount != null)
                    cidrPropBind['accountCIDR' + this.defaultCIDRnum] = responseData[i].ipConfigs[j].mappedAccount?.id;
                  cidrPropBind['editIdCidr' + (this.defaultCIDRnum)] = responseData[i].ipConfigs[j].id;
                  cidrPropBind['rangeCIDR' + (this.defaultCIDRnum)] = responseData[i].ipConfigs[j].cidrRange;
                  cidrPropBind['actionTakenCIDR' + (this.defaultCIDRnum)] = responseData[i].ipConfigs[j].actionTaken;
                  cidrPropBind['notifyMailCIDR' + (this.defaultCIDRnum)] = responseData[i].ipConfigs[j].notifyUserThroughMail;
                }
              }
              this.pageloadvalues = ipRangeconfigurationPropBind;
              this.pageloadvalues1 = ipVersionPropBind;
              this.cidrPageloadvalues = cidrPropBind;
              this.IPversionForm.patchValue(ipVersionPropBind);
              this.CIDRForm.patchValue(cidrPropBind);
              this.IPRangeConfigurationForm.patchValue(ipRangeconfigurationPropBind);
              this.disablingConditions();
            }
            if (this.CIDRForm.value['rangeCIDR' + this.defaultCIDRnum] != " ") {
              this['disableCIDRAdd' + this.defaultCIDRnum] = false;
            }
            else {
              this['disableCIDRAdd' + this.defaultCIDRnum] = true;
            }
          }
          else {
            this.firstSaveForCheck = false;
          }

          this.radioFlag = false;
        },
        error: error => {
          if (error?.error?.error?.messageCode == "Authentication not found") {
            this.firstSave = false;
          }
        }
      });
  }

  //delete condition
  onDeleteContextualCondition(authConfigId: any, conditionId: any) {
    const reqbody = {};
    reqbody['tenantId'] = this.pagesService.tenantId;
    reqbody['applicationId'] = this.AuthenticationForm.value.application;
    reqbody['authConfigId'] = authConfigId;
    reqbody['conditionId'] = conditionId;
    this.pagesService.onDeleteContextualCondition(reqbody).subscribe(

    );
  }
  onDeleteContextualConditionConfigs(authConfigId: any, conditionId: any, configId: any) {
    const reqbody = {};
    reqbody['tenantId'] = this.pagesService.tenantId;
    reqbody['applicationId'] = this.AuthenticationForm.value.application;
    reqbody['authConfigId'] = authConfigId;
    reqbody['conditionId'] = conditionId;
    reqbody['configId'] = configId;
    this.pagesService.onDeleteContextualConditionConfigs(reqbody).subscribe(

    );
  }


  /**
   * The password Policy tab
   */
  checkPassword() {
    if (Number(this.PasswordPolicyForm.value.wrong_password_attempts) < 3 && this.PasswordPolicyForm.value.wrong_password_attempts != '') {
      this.passwordattemptsValueCheckFlag = true;
    }
    else {
      this.passwordattemptsValueCheckFlag = false;
    }
  }


  //min and max check
  minMaxCheck() {
    if (+this.PasswordPolicyForm.value.min_length > +this.PasswordPolicyForm.value.max_lenght) {
      if (+this.PasswordPolicyForm.value.min_length > 8 && +this.PasswordPolicyForm.value.min_length < 264 && this.PasswordPolicyForm.value.min_length != null) {
        this.minCheck = true;
      }
      if (+this.PasswordPolicyForm.value.max_lenght > 8 && +this.PasswordPolicyForm.value.max_lenght < 265 && this.PasswordPolicyForm.value.max_lenght != null) {
        this.minCheck = true;
      }
    }
    else {
      this.minCheck = false;
    }
  }

  public getPropbindSo(responseData: any, pagePropBind: any) {
    for (let i = 0; i < responseData.length; i++) {
      if (responseData[i].configType == 'min_length') {
        pagePropBind['min_length'] = responseData[i].providedValue;
      }
      else if (responseData[i].configType == 'max_lenght') {
        pagePropBind['max_lenght'] = responseData[i].providedValue;
      }
      else if (responseData[i].configType == 'min_numbers') {
        pagePropBind['min_numbers'] = responseData[i].providedValue;
      }
      else if (responseData[i].configType == 'expire_password') {
        pagePropBind['expire_password'] = responseData[i].providedValue;
      }
      else if (responseData[i].configType == 'min_special_char') {
        pagePropBind['min_special_char'] = responseData[i].providedValue;
      }
      else if (responseData[i].configType == 'min_uppercase_char') {
        pagePropBind['min_uppercase_char'] = responseData[i].providedValue;
      }
      else if (responseData[i].configType == 'wrong_password_attempts') {
        pagePropBind['wrong_password_attempts'] = responseData[i].providedValue;
      }
      else if (responseData[i].configType == 'required_special_char_starts') {
        if (responseData[i].providedValue == "true")
          pagePropBind['required_special_char_starts'] = true;
      }
      else if (responseData[i].configType == 'required_number_char_starts') {
        if (responseData[i].providedValue == "true")
          pagePropBind['required_number_char_starts'] = true;

      }
      else if (responseData[i].configType == 'required_uppercase_char_starts') {
        if (responseData[i].providedValue == "true")
          pagePropBind['required_uppercase_char_starts'] = true;
      }
    }
    return pagePropBind;
  }

  getPasswordPolicyBytenantidonPageLoad() {
    const reqBody = {};
    reqBody['tenant_id'] = this.pagesService.tenantId;
    let pagePropBind = {};
    this.pagesService.getPasswordPolicyBytenantid(reqBody).subscribe({
      next: (results: any) => {
        const responseData = results.success?.result;
        if (responseData != null)
          this.passwordEnableBtn = true;
        pagePropBind = this.getPropbindSo(responseData, pagePropBind);

        this.pageloadvalues = pagePropBind;
        this.PasswordPolicyForm.patchValue(pagePropBind);
      },
      error: error => {
        if (error.error?.error?.messageCode == "Password policy not found") {
          this.firstSave = false;
        }
      }
    });
  }

  savePasswordPolicy() {
    const result = Object.entries(this.PasswordPolicyForm.value).map(([config_name, config_value]) => ({ config_name, config_value }));
    const reqBody = {};
    reqBody['tenant_id'] = this.pagesService.tenantId;
    reqBody['configTypeValue'] = [];
    for (let i = 0; i < result.length; i++) {
      const reqBody1 = {};
      if (result[i].config_value != null) {
        reqBody1['config_name'] = result[i].config_name;
        reqBody1['config_value'] = result[i].config_value;
        reqBody['configTypeValue'].push(reqBody1)
      }
    }
    this.messageFlag = true;
    this.pagesService.savePasswordPolicy(reqBody).subscribe(
      {
        next: results => {
          if (results?.error?.code == 400 || results?.success?.code == 400) {
            if (results?.error?.messageCode == "CIAM 105") {
              this.passwordEnableBtn = true;
            }
          } else if (results.success.code == 200) {
            this.passwordEnableBtn = true;
            this.messageFlag = true;
            this.messageService.add({ key: 'myKey1', severity: 'success', detail: 'Password details saved successfully' });
            setTimeout(() => {
              this.messageFlag = false;
            }, 3000);
          }
        },
        error: error => {
          if (error.error?.error?.messageCode == "CIAM NotSaved") {
            this.apiDuplicity = true;
          }
        }
      });
  }

  console(num: any) {
    console.log(this.IPversionForm.value['accountIP' + num]);
  }
}