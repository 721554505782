<div style="background-color:#F6F8FA;">
    <div class="p-d-flex p-jc-end">
        <p-messages key="myKey1" class="custom-alert-box" *ngIf="this.messageFlag"></p-messages>
    </div>
    <div class="p-col-fixed p-mt-1 p-ml-1 p-pr-0 custom-breadcrumb">
        <p-breadcrumb [model]="ccprimebreadcrumbList" [home]="homeiconccprimebreadcrumb">
        </p-breadcrumb>
    </div>
    <form class="layout-content custom-form-layout" [formGroup]="planForm" autocomplete="off" novalidate>
        <div class="p-formgrid p-grid">
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                <div class="p-grid">
                    <span class="p-col p-p-0">
                        <h5 class="custom-head1">Application Plans</h5>
                    </span>
                </div>
                <div class="card custom-card p-mb-6">
                    <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left">
                        <div class=" p-fluid   p-field    ">
                            <div class="p-fluid p-formgrid p-grid" style="padding-top:8px ;">
                                <div class="p-col-12 p-p-0">

                                    <div class="ui-fluid p-formgrid">
                                        <div class=" p-col-12 p-p-0">
                                            <div class="ui-fluid p-formgrid p-grid">
                                                <div
                                                    class="p-col-12 p-sm-12 p-md-12 p-lg-4 p-xl-4 p-p-0 p-offset-0 left">
                                                    <div class=" p-fluid   p-field    ">
                                                        <label
                                                            [ngClass]="{'field-required': planForm.get('planForm')?.errors?.required}">
                                                            Application
                                                        </label> <span class='invalid-feedback-astrik'>*</span>
                                                        <div class="p-fluid">
                                                            <p-multiSelect id="selectid" name="select"
                                                                style="display: none;"
                                                                placeholder="Select a Application" placeholder="Select"
                                                                [options]="selectList" [filter]="true" filterBy="label"
                                                                formControlName="application" display="chip"
                                                                class="custom-chips" (onChange)="gettableList()">
                                                                <ng-template let-items pTemplate="item">
                                                                    <div *ngIf="items?.label?.length &lt;=37;">
                                                                        {{items.label}}
                                                                    </div>
                                                                    <div *ngIf="items?.label?.length > 37;"
                                                                        pTooltip="{{items.label}}"
                                                                        tooltipPosition="bottom">
                                                                        {{items.label.substring(0,37) + "..." }}
                                                                    </div>
                                                                </ng-template>
                                                            </p-multiSelect>
                                                            <p-dropdown id="applicationid" name="application"
                                                                placeholder="Select a Application" placeholder="Select"
                                                                [options]="selectList" [filter]="true" filterBy="label"
                                                                [autoDisplayFirst]="false" formControlName="application"
                                                                (onChange)="gettableList()">
                                                                <ng-template let-items pTemplate="item">
                                                                    <div *ngIf="items?.label?.length &lt;=37;">
                                                                        {{items.label}}
                                                                    </div>
                                                                    <div *ngIf="items?.label?.length > 37;"
                                                                        pTooltip="{{items.label}}"
                                                                        tooltipPosition="bottom">
                                                                        {{items.label.substring(0,37) + "..." }}
                                                                    </div>
                                                                </ng-template>
                                                            </p-dropdown>
                                                        </div>
                                                        <div class="invalid-feedback"
                                                            *ngIf="planForm.get('application')!.invalid && (planForm.get('application')!.dirty || planForm.get('application')!.touched)">
                                                            <div *ngIf="planForm.get('application')?.errors?.required">
                                                                <label class="alert-icon alert-circle-icon"></label>
                                                                <medium class="p-error">Application is required.
                                                                </medium>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    class="p-col-12 p-sm-12 p-md-12 p-lg-8 p-xl-8 p-p-0 p-offset-undefined p-right">
                                                    <div class=" p-fluid   p-field    " class="custom-attr-fluid">
                                                        <span class=" p-input-icon-left custom-search-span p-mr-3"
                                                            *ngIf="!this.errorFlag && !this.errorFlag2">
                                                            <input type="text" (keydown.enter)="$event.preventDefault()"
                                                                id="textField_id1" name="textField_name1"
                                                                formControlName="textField1" pInputText
                                                                placeholder="Search" class="p-inputtext inputtextHeight"
                                                                (input)="CustomFilterMethod($event.target.value)" />
                                                            <i aria-hidden="true" class="pi pi-search"></i>
                                                        </span>
                                                        <div class="btnwrap custom-btn-height p-mr-2"
                                                            *ngIf="!this.errorFlag && !this.errorFlag2 && this.tableList.length>1">
                                                            <button pButton class="p-button-secondary p-button"
                                                                type="button" (click)="onReorderPlan();"
                                                                label="Re-order Plan"
                                                                [disabled]="this.pagesService.planAdminReadOnlyFlag">
                                                            </button>
                                                        </div>
                                                        <div class="btnwrap custom-btn-height"
                                                            *ngIf="!this.errorFlag && !this.errorFlag2">
                                                            <button pButton class="p-button-primary p-button"
                                                                type="button" (click)="onAddplan(planForm);"
                                                                label="Add New Plan"
                                                                [disabled]="this.pagesService.planAdminReadOnlyFlag">
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="this.errorFlag" class="custom-errorFlagDiv">
                                                <label class="c-icons error-icon"></label>
                                                <label class="e-error" style="margin-right: 0px;">No Application Plans
                                                    Found</label><br>
                                                <div class="btnwrap p-mt-2">
                                                    <button pButton type="button" pButton label="Add New Plan"
                                                        (click)="onAddplan(planForm);"
                                                        class="p-button-outlined p-button-danger"></button>
                                                </div>
                                            </div>
                                            <div *ngIf="this.errorFlag2" class="custom-errorFlagDiv">
                                                <label class="c-icons error-icon1"></label>
                                                <label class="e-error" style="margin-right: 0px;">Please Select
                                                    Application</label><br>
                                                <br>
                                            </div>
                                            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-offset-undefined left p-p-0"
                                                *ngIf="!this.errorFlag && !this.errorFlag2">
                                                <div class="  p-field    ">
                                                    <div class="mobilefriendlytable" responsiveLayout="stack"
                                                        [ngClass]="{'content-section implementation': true}">
                                                        <p-table #tableid [value]="tableList" name="table"
                                                            [columns]="tablecols"
                                                            styleClass="p-datatable-customers custom-p-datatable-customers"
                                                            [rows]="10" [rowsPerPageOptions]="[10,25,50]"
                                                            selectionMode="Multiple" [paginator]="true"
                                                            sortMode="single" [scrollable]="true"
                                                            [globalFilterFields]="['planName','appList','createdBy','price','lastModifiedDate','lastModifiedBy']">
                                                            <ng-template pTemplate="header">
                                                                <tr>
                                                                    <th pSortableColumn="planName" scope="col"
                                                                        style="min-width: 200px;max-width: 200px;width: 200px;"
                                                                        pFrozenColumn>
                                                                        <div class="p-d-flex p-jc-between p-ai-center">
                                                                            Plan Name
                                                                            <i class="p-sortable-column-icon icon-sort"
                                                                                ariaLabel="Activate to sort"
                                                                                ariaLabelDesc="Activate to sort in descending order"
                                                                                ariaLabelAsc="Activate to sort in ascending order"
                                                                                field="planName"></i>
                                                                            <p-columnFilter type="text" field="planName"
                                                                                display="menu"
                                                                                class="p-ml-auto"></p-columnFilter>
                                                                        </div>
                                                                    </th>
                                                                    <th pSortableColumn="appList" scope="col"
                                                                        style="min-width: 250px;width: 250px;">
                                                                        <div class="p-d-flex p-jc-between p-ai-center">
                                                                            Application
                                                                            <i class="p-sortable-column-icon icon-sort"
                                                                                ariaLabel="Activate to sort"
                                                                                ariaLabelDesc="Activate to sort in descending order"
                                                                                ariaLabelAsc="Activate to sort in ascending order"
                                                                                field="appList"></i>
                                                                            <p-columnFilter type="text" field="appList"
                                                                                display="menu" class="p-ml-auto">
                                                                            </p-columnFilter>
                                                                        </div>
                                                                    </th>
                                                                    <th pSortableColumn="price" scope="col"
                                                                        style="min-width: 150px;width: 150px;">
                                                                        <div class="p-d-flex p-jc-between p-ai-center">
                                                                            Price
                                                                            <i class="p-sortable-column-icon icon-sort"
                                                                                ariaLabel="Activate to sort"
                                                                                ariaLabelDesc="Activate to sort in descending order"
                                                                                ariaLabelAsc="Activate to sort in ascending order"
                                                                                field="price"></i>
                                                                            <p-columnFilter type="text" field="price"
                                                                                display="menu"
                                                                                class="p-ml-auto"></p-columnFilter>
                                                                        </div>
                                                                    </th>
                                                                    <th pSortableColumn="createdBy" scope="col"
                                                                        style="min-width: 150px;width: 150px;">
                                                                        <div class="p-d-flex p-jc-between p-ai-center">
                                                                            Created By
                                                                            <i class="p-sortable-column-icon icon-sort"
                                                                                ariaLabel="Activate to sort"
                                                                                ariaLabelDesc="Activate to sort in descending order"
                                                                                ariaLabelAsc="Activate to sort in ascending order"
                                                                                field="createdBy"></i>
                                                                            <p-columnFilter type="text"
                                                                                field="createdBy" display="menu"
                                                                                class="p-ml-auto">
                                                                            </p-columnFilter>
                                                                        </div>
                                                                    </th>
                                                                    <th pSortableColumn="lastModifiedDate" scope="col"
                                                                        style="min-width:250px;width:250px;">
                                                                        <div class="p-d-flex p-jc-between p-ai-center">
                                                                            Updated On
                                                                            <i class="p-sortable-column-icon icon-sort"
                                                                                ariaLabel="Activate to sort"
                                                                                ariaLabelDesc="Activate to sort in descending order"
                                                                                ariaLabelAsc="Activate to sort in ascending order"
                                                                                field="lastModifiedDate"></i>
                                                                            <p-columnFilter type="date"
                                                                                field="lastModifiedDate" display="menu"
                                                                                [matchModeOptions]="matchModeOptions"
                                                                                class="p-ml-auto"></p-columnFilter>
                                                                        </div>
                                                                    </th>
                                                                    <th pSortableColumn="lastModifiedBy" scope="col"
                                                                        style="min-width:200px;width:200px;">
                                                                        <div class="p-d-flex p-jc-between p-ai-center">
                                                                            Updated By
                                                                            <i class="p-sortable-column-icon icon-sort"
                                                                                ariaLabel="Activate to sort"
                                                                                ariaLabelDesc="Activate to sort in descending order"
                                                                                ariaLabelAsc="Activate to sort in ascending order"
                                                                                field="lastModifiedBy"></i>
                                                                            <p-columnFilter type="text"
                                                                                field="lastModifiedBy" display="menu"
                                                                                class="p-ml-auto"></p-columnFilter>
                                                                        </div>
                                                                    </th>
                                                                    <th id="actionButton"
                                                                        style="min-width: 160px;width: 160px;">
                                                                        <div></div>
                                                                    </th>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-rowData
                                                                let-index="rowIndex">
                                                                <tr (mouseenter)="hoverIndex=index;"
                                                                    (mouseleave)="hoverIndex=index;"
                                                                    [pSelectableRow]="rowData">
                                                                    <td alignFrozen="left" pFrozenColumn>
                                                                        <span pTooltip="{{rowData.planName}}"
                                                                            *ngIf="rowData.planName?.length >= 30"
                                                                            tooltipPosition="bottom">
                                                                            {{rowData.planName?.substring(0,30) +
                                                                            "..." }}
                                                                        </span>
                                                                        <span *ngIf="rowData.planName?.length < 30">
                                                                            {{rowData.planName}}
                                                                        </span>
                                                                    </td>
                                                                    <td
                                                                        style="display: block;color:#1A2136;font-weight: 400;">

                                                                        {{rowData.appList}}
                                                                    </td>
                                                                    <td>
                                                                        <span
                                                                            *ngIf="rowData?.price==null || rowData?.price==''">-</span>
                                                                        <div *ngIf="rowData.price != null">
                                                                            <span pTooltip="{{rowData.price}}"
                                                                                *ngIf="rowData.price?.length >= 30"
                                                                                tooltipPosition="bottom">
                                                                                {{rowData.price?.substring(0,30) +
                                                                                "..." }}
                                                                            </span>
                                                                            <span *ngIf="rowData.price?.length < 30">
                                                                                {{rowData.price}}
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div *ngIf="rowData.createdBy != null">
                                                                            <span pTooltip="{{rowData.createdBy}}"
                                                                                *ngIf="rowData.createdBy?.length >= 30"
                                                                                tooltipPosition="bottom">
                                                                                {{rowData.createdBy?.substring(0,30) +
                                                                                "..." }}
                                                                            </span>
                                                                            <span
                                                                                *ngIf="rowData.createdBy?.length < 30">
                                                                                {{rowData.createdBy}}
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                    <td class="emailRow">
                                                                        <span
                                                                            *ngIf="rowData.lastModifiedDate!=null">{{rowData.lastModifiedDate
                                                                            | date:'MMM d, y, h:mm:ss a'}}</span>
                                                                        <span
                                                                            *ngIf="rowData.lastModifiedDate==null">-</span>
                                                                    </td>
                                                                    <td class="emailRow">
                                                                        <span
                                                                            *ngIf="rowData.lastModifiedBy!=null">{{rowData.lastModifiedBy}}</span>
                                                                        <span
                                                                            *ngIf="rowData.lastModifiedBy==null || rowData.lastModifiedBy==''">-</span>
                                                                    </td>
                                                                    <td class="p-jc-center" pFrozenColumn
                                                                        [frozen]="true" alignFrozen="right"
                                                                        [ngClass]="{'frozen' : true}"
                                                                        style="padding:0px ;background: transparent;">
                                                                        <div class="button-details"
                                                                            style="align-items: baseline;background: #F5F9FF;">
                                                                            <button pButton tooltipPosition="top"
                                                                                pTooltip="Plan Details"
                                                                                style="width: 24px;height: 24px;padding: 0px;"
                                                                                icon="v-icons plan-icon"
                                                                                class="background-transparent"
                                                                                (click)="activeItem=rowData;viewPlan(rowData.planId);">
                                                                            </button>
                                                                            <div style="line-height: normal"
                                                                                pTooltip="Edit Plan"
                                                                                tooltipPosition="top">
                                                                                <button pButton tooltipPosition="bottom"
                                                                                    style="width: 24px;height: 24px;padding: 0px;"
                                                                                    icon="g-icons view-icon"
                                                                                    class="background-transparent"
                                                                                    (click)="navigateEditPlan(rowData);"
                                                                                    [disabled]="this.pagesService.planAdminReadOnlyFlag">
                                                                                </button>
                                                                            </div>
                                                                            <div style="line-height: normal;margin-right: 5px;"
                                                                                pTooltip="{{rowData.availableSubscription>0 ? 'Plan cannot be deleted,
                                                                                as it is linked with 
                                                                                an active subscription' : 'Delete Plan'}}"
                                                                                tooltipPosition="top">
                                                                                <button pButton tooltipPosition="bottom"
                                                                                    style="width: 24px;height: 24px;padding: 0px;"
                                                                                    icon="g-icons trash-icon"
                                                                                    class="background-transparent"
                                                                                    (click)="DeletePlan(rowData);"
                                                                                    [disabled]="rowData.availableSubscription>0 || this.pagesService.planAdminReadOnlyFlag">
                                                                                </button>
                                                                            </div>


                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="paginatorright" let-state> <span
                                                                    class="p-pl-4">Total
                                                                    Records: {{state.totalRecords}}</span></ng-template>
                                                            <ng-template pTemplate="emptymessage">
                                                                <tr>
                                                                    <td colspan="8" class="p-p-0 p-text-center">
                                                                        <p-card>
                                                                            <div style="margin-top: 5%;height: 70px;">No
                                                                                matching records found</div>
                                                                        </p-card>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>